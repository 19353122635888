import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Typography, ListItemText, ListItemIcon, ListItem, List, Button, Grid, IconButton } from '@mui/material';
import { BlockedListIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import MenuButton from '../../component-modals/layout/MenuButton';
import { useIntl } from 'react-intl';

const Account: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();

  return (
    <Grid className={'component-preferences settings-page'}>
      <Grid item xl={12} xs={12} sm={12}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left', p: { xs: '20px 0px', sm: '20px 43px' } }}>
            <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
          </Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <Grid sx={{ width: '70%', m: 'auto', p: '40px 0 100px' }}>
          <Grid className={'title'} item xl={12} xs={12} sm={12}>
            <Typography sx={{ fontSize: '2em', fontWeight: '800', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>{`${intl.formatMessage({ id: 'Settings_Accounts_Title' })}`}</Typography>
          </Grid>
          <Grid className={'settings-list'} item xl={12} xs={12} sm={12}>
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ justifyContent: 'center', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>
                  <BlockedListIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Button
                      component={Link}
                      to={{
                        pathname: '/settings/blocked_accounts',
                      }}
                    >
                      <Typography className={'title'} sx={{ color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>
                        {`${intl.formatMessage({ id: 'Settings_Blocked_Accounts' })}`}
                      </Typography>
                    </Button>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Account;
