import React from 'react';
import Moment from 'react-moment';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { IntlProvider, useIntl } from 'react-intl';
import _ from 'lodash';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectPersonalWallets, selectBusinessWallets } from '../../reducers/walletSlice';

import { PAYMENT_METHODS, BLOCKCHAIN_CURRENCIES, CURRENCIES } from '../../utilities/utilities';

import { Grow, AppBar, Tab, Tabs, Alert, Skeleton, Typography, Fab, Button, Snackbar, ListItemSecondaryAction, ListItemText, ListItem, List, ListItemIcon, MenuItem, Menu, IconButton, Box, Grid } from '@mui/material';
import { Delete, ContentCopy, LockOpen, Lock, MoreVert } from '@mui/icons-material';
import { CardPaymentIcon, UpdateBalanceIcon, WalletIcon, FivezerLogoIcon } from '../../img/icons/Icons';

import BackButton from '../../component-modals/common/BackButton';
import Modal from '../../component-modals/layout/Modal';
import SpendingCodeModal from '../../component-modals/purchase/SpendingCodeModal';
import { Edit } from '@mui/icons-material';
import EditBlockchainAccountModal from '../../component-modals/wallet/EditBlockchainAccountModal';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';

interface IBlockchainAccount {
  account: { id: string };
  wallet: { id: string; type: string };
}

const BlockchainAccount: React.FC = () => {
  const FIND_BLOCKCHAIN_ACCOUNT_BY_ID_QUERY = gql`
    query findBlockchainAccountByID($account: inputFindBlockchainAccount!) {
      findBlockchainAccountByID(account: $account) {
        id
        title
        status
        wallet_addresses
        balance
        type
        accepted_currencies
        assets {
          ... on TokenInvoice {
            __typename
            id
            token_id
          }
        }
        sent_txs {
          id
          status
          execution_date
          confirmed_at
        }
        received_txs {
          id
          status
          execution_date
          confirmed_at
        }
      }
    }
  `;

  const DELETE_BLOCKCHAIN_ACCOUNT_MUTATION = gql`
    mutation deleteBlockchainAccount($account: inputDeleteBlockchainAccount!) {
      deleteBlockchainAccount(account: $account)
    }
  `;

  const intl = useIntl();
  const navigate = useNavigate();
  const location: any = useLocation();
  const state: IBlockchainAccount = location?.state;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerPersonalWallets = useAppSelector(selectPersonalWallets);
  const loggedPlayerBusinessWallets = useAppSelector(selectBusinessWallets);

  const [wallet] = React.useState((state?.wallet?.type === 'BUSINESS' ? loggedPlayerBusinessWallets : loggedPlayerPersonalWallets)?.find((wallet: any) => wallet.id === state?.wallet?.id));

  const [blockchainAccount, setBlockchainAccount] = React.useState(UNDEFINED_AS_ANY);

  const [lastTimeUpdated, setLastTimeUpdated] = React.useState(Date.now());
  const [copied, setCopied] = React.useState(false);
  const [editAccount, setEditAccount] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openLock, setOpenLock] = React.useState(false);
  const [isLocked, setIsLocked] = React.useState(false);
  const [openLockConfirmation, setOpenLockConfirmation] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(anchorEl);

  const blockchain_account_tabs = [
    {
      label: 'All Transactions',
      icon: '',
      fragment: (
        <Box>
          <AllTransactions txs={_.concat(blockchainAccount?.sent_txs, blockchainAccount?.received_txs)?.filter((tx: any) => tx !== undefined)} />
        </Box>
      ),
    },
    {
      label: 'Tx Sent',
      icon: '',
      fragment: (
        <Box>
          <TransactionsSent txs={blockchainAccount?.sent_txs?.filter((tx: any) => tx?.issuer?.id === loggedPlayer?.id)} />
        </Box>
      ),
    },
    {
      label: 'Tx Received',
      icon: '',
      fragment: (
        <Box>
          <TransactionsReceived txs={blockchainAccount?.received_txs?.filter((tx: any) => tx?.issuer?.id !== loggedPlayer?.id)} />
        </Box>
      ),
    },
    {
      label: 'Tx Pending',
      icon: '',
      fragment: (
        <Box>
          <TransactionsPending txs={_.concat(blockchainAccount?.sent_txs, blockchainAccount?.received_txs)?.filter((tx: any) => tx?.status === 'PENDING')} />
        </Box>
      ),
    },
  ];

  const [deleteBlockchainAccount, deletedBlockchainAccount] = useMutation(DELETE_BLOCKCHAIN_ACCOUNT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedBlockchainAccount = useQuery(FIND_BLOCKCHAIN_ACCOUNT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      account: {
        id: state?.account?.id,
        wallet_id: state?.wallet?.id,
      },
    },
    pollInterval: loggedPlayer?.id ? 3000 : undefined,
  });

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleOpenLock = () => {
    setOpenLock(true);
  };

  const handleIsLocked = () => {
    handleClose();

    if (blockchainAccount?.status === 'locked') {
      // REPLACE BY API CALL TO UPDATE PAYMENT METHOD STATUS TO UNLOCKED
      const ba = blockchainAccount;
      ba.status = 'available';
      setBlockchainAccount(ba);
      console.log('Blockchain Account ' + blockchainAccount?.label + ' unlocked!');
    } else {
      // REPLACE BY API CALL TO UPDATE PAYMENT METHOD STATUS TO LOCKED
      const ba = blockchainAccount;
      ba.status = 'locked';
      setBlockchainAccount(ba);
      console.log('Blockchain Account ' + blockchainAccount?.label + ' locked!');
    }
    setIsLocked(true);
  };

  const handleOpenLockConfirmation = () => {
    setOpenLockConfirmation(true);
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleIsLockedConfirmed = () => {
    handleIsLocked();
  };

  const handleClose = () => {
    setOpenLockConfirmation(false);
    setOpenLock(false);
    setAnchorEl(null);
    setOpenConfirmation(false);
    setOpenDelete(false);
  };

  const handleEditAccount = () => {
    handleClose();
    setEditAccount(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleUpdateBalance = async () => {
    const result = await loadedBlockchainAccount.refetch({
      account: {
        id: state?.account?.id,
        wallet_id: wallet?.id,
      },
    });
    setBlockchainAccount(result.data?.findBlockchainAccountByID);
    setLastTimeUpdated(Date.now());
  };

  const handleDeleteAccount = async () => {
    try {
      handleClose();
      await deleteBlockchainAccount({
        variables: {
          account: {
            id: blockchainAccount?.id,
            logged_user_id: loggedPlayer?.id,
            wallet_id: state?.wallet?.id,
          },
        },
      });

      navigate(`/w/wallet`, { replace: true, state: { wallet: { id: wallet?.id, type: wallet?.type } } });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCopyTrackingNumber = (ref: string) => {
    navigator.clipboard
      .writeText(ref)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((e) => console.error(e));
  };

  React.useEffect(() => {
    if ((blockchainAccount === undefined && loadedBlockchainAccount.called && loadedBlockchainAccount.data?.findBlockchainAccountByID) || !_.isEqual(blockchainAccount, loadedBlockchainAccount.data?.findBlockchainAccountByID)) {
      setBlockchainAccount(loadedBlockchainAccount.data?.findBlockchainAccountByID);
    }
  }, [blockchainAccount, loadedBlockchainAccount]);

  return (
    <Grid container className={'component-preferences bank-account-page dark-mode'} sx={{ display: 'inline-block', height: '100%' }}>
      <Grid item xl={12} xs={12} sm={12}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xl={6} xs={6} sm={6} className={'back-button'} sx={{ textAlign: 'left' }}>
            <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
          </Grid>
          <Grid item xl={6} xs={6} sm={6} className={'options'} sx={{ textAlign: 'right', p: '0 20px' }}>
            <IconButton onClick={handleOpenOptions} disabled={blockchainAccount === undefined}>
              <MoreVert />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={optionsOpen} onClose={handleCloseOptions} PaperProps={{}}>
              <MenuItem onClick={handleEditAccount}>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                Edit account
              </MenuItem>
              {blockchainAccount?.status === 'BLOCKED' ? (
                <MenuItem onClick={handleOpenLock}>
                  <ListItemIcon>
                    <LockOpen />
                  </ListItemIcon>
                  Unlock account
                </MenuItem>
              ) : (
                <MenuItem onClick={handleOpenLock}>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  Temporarily lock account
                </MenuItem>
              )}
              <MenuItem
                component={Link}
                to={{ pathname: '/bk/order_bank_card' }}
                state={{
                  wallet: {
                    id: wallet?.id,
                    type: wallet?.type,
                  },
                  account: { id: blockchainAccount?.id },
                }}
                disabled={blockchainAccount?.status === 'BLOCKED'}
              >
                <ListItemIcon>
                  <CardPaymentIcon />
                </ListItemIcon>
                Order bank card
              </MenuItem>
              <MenuItem onClick={handleOpenDelete}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                Remove account
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <IntlProvider locale="en" defaultLocale="en">
          <Grid item xl={12} xs={12} sm={12} className={'title'} sx={{ p: '0 5%' }}>
            <h1>
              {blockchainAccount?.title}{' '}
              <Typography
                sx={{
                  'color': 'rgba(247, 251, 250, .3) !important',
                  'display': 'flex',
                  'alignItems': 'center',
                  '& svg': { fontSize: '2em' },
                }}
              >
                ({PAYMENT_METHODS?.find((p: any) => p?.name === 'LIPAY')?.icon_white}&nbsp;Account)
              </Typography>
            </h1>
          </Grid>

          <Grid item xl={12} xs={12} sm={12} className={'label'} sx={{ textAlign: 'center' }}>
            <h1>
              {blockchainAccount?.wallet_addresses[0] !== undefined ? (
                <Grid>
                  {`${blockchainAccount?.wallet_addresses[0]?.substring(0, 10)}****${blockchainAccount?.wallet_addresses[0]?.substring(blockchainAccount?.wallet_addresses[0]?.length - 5, blockchainAccount?.wallet_addresses[0]?.length)}`}
                  {copied ? (
                    <Grow in={copied} style={{ transformOrigin: '0 0 0' }} {...(copied ? { timeout: 1000 } : {})}>
                      <Typography component="span" sx={{ color: '#1faf92 !important', fontWeight: '100' }}>
                        &nbsp;copied!
                      </Typography>
                    </Grow>
                  ) : (
                    <IconButton onClick={() => handleCopyTrackingNumber(blockchainAccount?.wallet_addresses[0])}>
                      <ContentCopy />
                    </IconButton>
                  )}
                </Grid>
              ) : (
                <Skeleton
                  variant="text"
                  sx={{
                    bgcolor: 'rgba(247, 251, 250, .2)',
                    m: 'auto',
                    width: '40%',
                  }}
                />
              )}
            </h1>
            <Typography className={'status'} sx={{ textTransform: 'uppercase' }}>
              {`${intl.formatMessage({ id: 'Global_Status' })}`}:{' '}
              <strong>
                {blockchainAccount?.status !== undefined ? (
                  blockchainAccount?.status
                ) : (
                  <Skeleton
                    variant="text"
                    sx={{
                      bgcolor: 'rgba(247, 251, 250, .2)',
                      m: 'auto',
                      width: '40%',
                    }}
                  />
                )}
              </strong>
            </Typography>
          </Grid>

          <Grid container className={'balance'} sx={{ textAlign: 'center', p: '15px 0' }}>
            <Grid item xl={12} xs={12} sm={12} className={'current-balance'} sx={{ fontSize: '1.6em' }}>
              <strong>
                {blockchainAccount?.balance !== undefined ? (
                  `${_.concat(CURRENCIES, BLOCKCHAIN_CURRENCIES).find((sym: any) => sym.value === 'MAT')?.sign} ${blockchainAccount?.balance?.toLocaleString(undefined, {
                    minimumSignificantDigits: 1,
                    maximumSignificantDigits: 8,
                  })}`
                ) : (
                  <Skeleton
                    variant="text"
                    sx={{
                      bgcolor: 'rgba(247, 251, 250, .2)',
                      m: 'auto',
                      width: '40%',
                    }}
                  />
                )}
              </strong>
            </Grid>
            <Grid
              item
              xl={12}
              xs={12}
              sm={12}
              className={'last-updated'}
              sx={{
                fontSize: '.8em',
                color: 'rgba(247, 251, 250, .3)',
              }}
            >
              last updated <Moment fromNow>{lastTimeUpdated}</Moment>
              <br />
              <Fab
                onClick={handleUpdateBalance}
                sx={{
                  'bgcolor': 'transparent',
                  'boxShadow': 'none',
                  'color': 'rgba(247, 251, 250, .3)',
                  ':hover': {
                    bgcolor: 'inherit',
                    color: 'rgba(247, 251, 250, 1)',
                  },
                }}
              >
                <UpdateBalanceIcon />
              </Fab>
            </Grid>
          </Grid>
        </IntlProvider>

        <Grid container>
          <AppBar position="static" sx={{ bgcolor: 'transparent' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={'fullWidth'}
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              centered
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'rgba(31, 175, 146, 1)',
                },
              }}
            >
              {blockchain_account_tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  icon={tab.icon}
                  {...a11yProps(index)}
                  sx={{
                    'color': 'rgba(247, 251, 250, .3) !important',
                    '&.Mui-selected': {
                      color: 'rgba(247, 251, 250, 1) !important',
                    },
                    ':hover': {
                      color: 'rgba(247, 251, 250, 1) !important',
                    },
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          {blockchain_account_tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {tab.fragment}
            </TabPanel>
          ))}
        </Grid>
      </Grid>

      <SpendingCodeModal actionOne={handleDeleteAccount} actionTwo={handleClose} open={openConfirmation} />
      <DeleteConfirmationModal title={`Remove ${blockchainAccount?.title}`} open={openDelete} entityId={blockchainAccount?.id} entityName={`${blockchainAccount?.title}`} actionOne={handleOpenConfirmation} actionOneTitle={'Confirm removal'} actionTwo={handleClose} actionTwoTitle={'Cancel'} loading={deletedBlockchainAccount.loading} />
      <EditBlockchainAccountModal account={blockchainAccount} wallet_id={wallet?.id} open={editAccount} handleClose={() => setEditAccount(false)} />
      <Modal
        title={blockchainAccount?.status === 'BLOCKED' ? `Unlock ${blockchainAccount?.title}` : `Lock ${blockchainAccount?.title}`}
        open={openLock}
        icon={<WalletIcon />}
        content={blockchainAccount?.status === 'BLOCKED' ? `Do you really want to unlock ${blockchainAccount?.title}?` : `Do you really want to lock ${blockchainAccount?.title}?`}
        actionOneTitle={blockchainAccount?.status === 'BLOCKED' ? `Yes, unlock` : `Yes, lock`}
        actionOne={handleOpenLockConfirmation}
        actionTwoTitle={'No, go back'}
        actionTwo={handleClose}
      />
      <SpendingCodeModal actionOne={handleIsLockedConfirmed} actionTwo={handleClose} open={openLockConfirmation} />
      {isLocked ? (
        <Snackbar open={isLocked} autoHideDuration={2000} onClose={() => setIsLocked(!isLocked)}>
          <Alert severity="info">{blockchainAccount?.status === 'BLOCKED' ? `${blockchainAccount?.title} is locked.` : `${blockchainAccount?.title} is unlocked.`}</Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </Grid>
  );
};

function AllTransactions(props: { txs: any }) {
  return (
    <Grid container>
      <Grid className={'transactions all-transactions'} item xl={12} xs={12} sm={12}>
        {props?.txs?.length > 0 ? (
          <List>
            {props?.txs?.map((tx: any, index: number) => (
              <ListItem key={index} button>
                <ListItemText
                  primary={
                    <Moment format="dddd DD MMMM YYYY HH:mm" className={'tx-date'}>
                      {tx?.execution_date}
                    </Moment>
                  }
                  secondary={
                    <span className={'tx-title'}>
                      <strong>{tx?.title}</strong>By{' '}
                      <Button
                        component={Link}
                        to={{
                          pathname: `/u/${tx?.issuer?.id}`,
                        }}
                        state={{ userId: tx?.issuer?.id }}
                      >
                        {tx?.issuer?.username}
                      </Button>
                    </span>
                  }
                />
                <ListItemSecondaryAction className={'tx-amount'}>{tx?.currency + tx?.amount}</ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="h3"
            sx={{
              p: '50px 20px 0',
              fontSize: '1.3em',
              textAlign: 'center',
            }}
          >
            No transactions
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function TransactionsSent(props: { txs: any }) {
  return (
    <Grid container>
      <Grid className={'transactions transactions-by-me'} item xl={12} xs={12} sm={12}>
        {props?.txs?.length > 0 ? (
          <List>
            {props?.txs?.map((tx: any, index: number) => (
              <ListItem key={index} button>
                <ListItemText
                  primary={
                    <Moment format="dddd DD MMMM YYYY HH:mm" className={'tx-date'}>
                      {tx?.execution_date}
                    </Moment>
                  }
                  secondary={
                    <span className={'tx-title'}>
                      <strong>{tx?.title}</strong>By{' '}
                      <Button
                        component={Link}
                        to={{
                          pathname: `/u/${tx?.issuer?.id}`,
                        }}
                        state={{ userId: tx?.issuer?.id }}
                      >
                        {tx?.issuer?.username}
                      </Button>
                    </span>
                  }
                />
                <ListItemSecondaryAction className={'tx-amount'}>{tx?.currency + tx?.amount}</ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="h3"
            sx={{
              p: '50px 20px 0',
              fontSize: '1.3em',
              textAlign: 'center',
            }}
          >
            No transactions
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function TransactionsReceived(props: { txs: any }) {
  return (
    <Grid container>
      <Grid className={'transactions transactions-by-me'} item xl={12} xs={12} sm={12}>
        {props?.txs?.length > 0 ? (
          <List>
            {props?.txs?.map((tx: any, index: number) => (
              <ListItem key={index} button>
                <ListItemText
                  primary={
                    <Moment format="dddd DD MMMM YYYY HH:MM" className={'tx-date'}>
                      {tx?.execution_date}
                    </Moment>
                  }
                  secondary={
                    <span className={'tx-title'}>
                      <strong>{tx?.title}</strong>By{' '}
                      <Button
                        component={Link}
                        to={{
                          pathname: `/u/${tx?.issuer?.id}`,
                        }}
                        state={{ userId: tx?.issuer?.id }}
                      >
                        {tx?.issuer?.username}
                      </Button>
                    </span>
                  }
                />
                <ListItemSecondaryAction className={'tx-amount'}>{tx?.currency + tx?.amount}</ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="h3"
            sx={{
              p: '50px 20px 0',
              fontSize: '1.3em',
              textAlign: 'center',
            }}
          >
            No transactions
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function TransactionsPending(props: { txs: any }) {
  return (
    <Grid container>
      <Grid className={'transactions transactions-by-me'} item xl={12} xs={12} sm={12}>
        {props?.txs?.length > 0 ? (
          <List>
            {props?.txs?.map((tx: any, index: number) => (
              <ListItem key={index} button>
                <ListItemText
                  primary={
                    <Moment format="dddd DD MMMM YYYY HH:mm" className={'tx-date'}>
                      {tx?.execution_date}
                    </Moment>
                  }
                  secondary={
                    <span className={'tx-title'}>
                      <strong>{tx?.title}</strong>By{' '}
                      <Button
                        component={Link}
                        to={{
                          pathname: `/u/${tx?.issuer?.id}`,
                        }}
                        state={{ userId: tx?.issuer?.id }}
                      >
                        {tx?.issuer?.username}
                      </Button>
                    </span>
                  }
                />
                <ListItemSecondaryAction className={'tx-amount'}>{tx?.currency + tx?.amount}</ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="h3"
            sx={{
              p: '50px 20px 0',
              fontSize: '1.3em',
              textAlign: 'center',
            }}
          >
            No transactions
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} key={index}>
      {value === index && (
        <Grid item xl={12} xs={12} sm={12} key={index} component={'span'}>
          <Grid key={index}>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default BlockchainAccount;
