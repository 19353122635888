import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Button, ListItemText, ListItemIcon, ListItem, List, Grid, Typography, IconButton } from '@mui/material';
import { HammerIcon, TranslateIcon, WalletIcon, PersonIcon, SupportAgentIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import MenuButton from '../../component-modals/layout/MenuButton';
import LogoutButton from '../../component-modals/common/LogoutButton';
import { Logout } from '@mui/icons-material';
import { useIntl } from 'react-intl';

const Settings: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();

  const settings = [
    { title: intl.formatMessage({ id: 'Settings_Account' }), icon: <PersonIcon />, link: '/account' },
    { title: intl.formatMessage({ id: 'Settings_Wallets' }), icon: <WalletIcon />, link: '/wallets' },
    { title: intl.formatMessage({ id: 'Settings_Languages' }), icon: <TranslateIcon />, link: '/languages' },
    { title: intl.formatMessage({ id: 'Settings_Helpdesk' }), icon: <SupportAgentIcon />, link: '/contact_us' },
    {
      title: intl.formatMessage({ id: 'Settings_Legal_Mentions' }),
      icon: <HammerIcon />,
      link: '/legal_mentions',
    },
    {
      title: intl.formatMessage({ id: 'Settings_Logout' }),
      icon: <Logout />,
      link: '/logout',
    },
  ];

  return (
    <Grid container className={'component-preferences settings-page'}>
      <Grid container>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left', p: { xs: '20px 0px', sm: '20px 43px' } }}>
          <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        </Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      <Grid id="max-width" sx={{ p: '0 10%' }} item xl={12} xs={12} sm={12}>
        <Grid sx={{ width: '100%', m: 'auto', p: '30px 0 100px' }}>
          <Grid className={'title'} item xl={12} xs={12} sm={12}>
            <Typography sx={{ fontSize: '2em', fontWeight: '800', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>{intl.formatMessage({ id: 'Settings_Title' })}</Typography>
          </Grid>
          <Grid className={'settings-list'} item xl={12} xs={12} sm={12}>
            <List sx={{ '& .logout-button': { color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)', fontWeight: '700', fontSize: '1.4em', textTransform: 'capitalize' } }}>
              {settings.map((setting: any) => (
                <ListItem key={setting.title}>
                  <ListItemIcon sx={{ justifyContent: 'center', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>{setting.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      setting.link !== '/logout' ? (
                        <Button
                          component={Link}
                          to={{
                            pathname: (setting.link === '/legal_mentions' ? '' : '/settings') + setting.link,
                          }}
                        >
                          <Typography
                            className={'title'}
                            sx={{
                              color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)',
                              fontWeight: '600',
                              textTransform: 'capitalize',
                              fontSize: '1.5em',
                            }}
                          >
                            {setting.title}
                          </Typography>
                        </Button>
                      ) : (
                        <LogoutButton showIcon={false} />
                      )
                    }
                    sx={{ color: 'rgba(247, 251, 250, 1)' }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Settings;
