import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, Divider, Fab, Grid, Avatar, TextField, Stack, CircularProgress, Autocomplete, Button, Switch } from '@mui/material';
import { Clear, Close, Send } from '@mui/icons-material';
import { ARRAY_AS_ANY, CountryType, DESCRIPTION_AS_ANY, IMAGE_LIST_AS_ANY, INITIAL_COUNTRY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer, selectPlayerProfiles } from '../../reducers/playerSlice';
import { Controller, useForm } from 'react-hook-form';
import LoadingComponent from '../common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { customAlphabet } from 'nanoid';
import { COUNTRIES, SPORT_TEAM_REG, SPORT_TEAM_TYPES, SPORT_TYPES } from '../../utilities/utilities';
import { handleUpload } from '../../helpers';
import InputSelectAvailabilitiesFeature from '../common/InputSelectAvailabilitiesFeature';
import InputDescriptionsFeature from '../common/InputDescriptionsFeature';
import UploadIMGFeature from '../common/UploadIMGFeature';
import { Link } from 'react-router-dom';

interface IAddSportTeamModal {
  handleClose: any;
  open: any;
}

const AddSportTeamModal: React.FC<IAddSportTeamModal> = (props: IAddSportTeamModal) => {
  const CREATE_FOOTBALL_TEAM_MUTATION = gql`
    mutation createFootballTeam($team: inputCreateFootballTeam!) {
      createFootballTeam(team: $team) {
        id
        short_id
        name
      }
    }
  `;

  const FIND_FOOTBALL_TEAM_ALIAS_QUERY = gql`
    query findFootballTeamName($name: String!) {
      findFootballTeamName(name: $name) {
        id
      }
    }
  `;

  const SEND_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerProfiles = useAppSelector(selectPlayerProfiles);
  const session = useAppSelector(selectSession);
  const intl = useIntl();

  const {
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      shortname: '',
      is_private: false,
      descriptions: ARRAY_AS_ANY,
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',
      address_title: '',
      street_name: '',
      secondary_street_name: '',
      postcode: '',
      city: '',
      county: '',
      state: '',
      country: INITIAL_COUNTRY,
      type: 'LEAGUE',
      best_foot: 'RF',
      jersey_number: 0,
      day: 'MONDAY',
      hour: 6,
      availabilities: ARRAY_AS_ANY,
      sport_type: 'FOOTBALL',
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();
  const football_team_nanoid = customAlphabet(SPORT_TEAM_REG, 21);

  const [country, setCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [progress, setProgress] = React.useState(0);
  const [avatar, setAvatar] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [avatarUrl, setAvatarUrl] = React.useState([]);
  const [cover, setCover] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [coverUrl, setCoverUrl] = React.useState([]);

  const [createFootballTeam, newFootballTeam] = useMutation(CREATE_FOOTBALL_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendNotification, isSent] = useMutation(SEND_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const existingFootballName = useQuery(FIND_FOOTBALL_TEAM_ALIAS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { name: inputFields.name },
  });

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setCountry(country);
      setValue('country', country);
    }
  };

  const onChangeAvatar = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setAvatar(imageList);
  };

  const onChangeCover = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setCover(imageList);
  };

  const handleTeamType = async (type: string) => {
    if (type !== null) {
      setValue('type', type);
    }
  };

  const handleCreateTeam = async () => {
    try {
      setProgress(1);

      if (avatar[0]?.file) {
        const avatarPromise = await handleUpload(avatar[0]?.file, avatarUrl, setAvatarUrl, session, loggedPlayer.id);
        if (avatarPromise) await Promise.all(avatarPromise);
      }

      if (cover[0]?.file) {
        const coverPromise = await handleUpload(cover[0]?.file, coverUrl, setCoverUrl, session, loggedPlayer.id);
        if (coverPromise) await Promise.all(coverPromise);
      }

      const result = await createFootballTeam({
        variables: {
          team: {
            player_id: loggedPlayer?.id,
            footballer_id: loggedPlayer?.profiles?.find((p: any) => p?.__typename === 'Footballer')?.id,
            short_id: football_team_nanoid(),
            name: inputFields.name,
            shortname: inputFields.shortname,
            is_private: inputFields.is_private,
            type: inputFields.type,
            bios: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
            avatar: {
              filename: avatar[0]?.file ? avatar[0].file.name : '',
              type: avatar[0]?.file ? avatar[0].file.type : '',
              size: avatar[0]?.file ? avatar[0].file.size : 0,
              path: avatarUrl[0],
            },
            cover: {
              filename: cover[0]?.file ? cover[0].file.name : '',
              type: cover[0]?.file ? cover[0].file.type : '',
              size: cover[0]?.file ? cover[0].file.size : 0,
              path: coverUrl[0],
            },
            originate_location: {
              title: inputFields.address_title,
              street_name: inputFields.street_name,
              secondary_street_name: inputFields.secondary_street_name,
              postcode: inputFields.postcode,
              city: inputFields.city,
              county: inputFields.county,
              state: inputFields.state,
              country_code: country.value,
              country: country.label,
              continent: country.continent,
            },
            availabilities: _.uniq(inputFields.availabilities?.map((a: any) => a?.day))?.map((day: any) => {
              return {
                day: day,
                allday: false,
                timeslots: inputFields.availabilities?.filter((a: any) => a?.day === day)?.map((a: any) => a?.hour),
              };
            }),
          },
        },
      });

      if (result.data?.createFootballTeam !== undefined) {
        try {
          await sendNotification({
            variables: {
              notification: {
                title: `${intl.formatMessage({ id: 'Notification_New_Team_Title' })}`,
                content: `${intl.formatMessage({ id: 'Notification_New_Team_Content_1' })} ${result.data?.createFootballTeam?.name}! ${intl.formatMessage({ id: 'Notification_New_Team_Content_2' })}`,
                actions: [`VIEW_FOOTBALL_TEAM_PROFILE_@_${result.data?.createFootballTeam?.short_id}`],
                sender_id: loggedPlayer?.id,
                receivers_ids: [loggedPlayer?.id],
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }

      if (result.data?.createFootballTeam?.short_id) {
        handleClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
    if (inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');

    if (
      inputFields.type === 'LEAGUE' &&
      ((inputFields.sport_type === 'FOOTBALL' &&
        inputFields.type === 'LEAGUE' &&
        loggedPlayerProfiles
          ?.map((p: any) => {
            return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'FootballTeam') || p?.player_positions?.find((po: any) => po?.team_player?.type === 'LEAGUE');
          })
          ?.filter((o: any) => o)?.length > 0) ||
        (inputFields.sport_type === 'BASKETBALL' &&
          inputFields.type === 'LEAGUE' &&
          loggedPlayerProfiles
            ?.map((p: any) => {
              return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'BasketballTeam') || p?.player_positions?.find((po: any) => po?.team_player?.type === 'LEAGUE');
            })
            ?.filter((o: any) => o)?.length > 0))
    ) {
      setValue('type', 'FRIENDLY');
    }
  }, [inputFields, setValue]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        disabled={newFootballTeam.loading}
        className={newFootballTeam.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'FormNewTeam_Title' })}`}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      {newFootballTeam.loading ? (
        <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>Loading team lineup, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} />
      ) : (
        <>
          <DialogContent sx={{ pl: '0', pr: '0' }}>
            {loggedPlayer?.profiles?.length === 0 ? (
              <Grid id="max-width" sx={{ textAlign: 'center' }}>
                <Typography sx={{ m: '30px 0', p: '0 10%', fontSize: '1.3em' }}>
                  <FormattedMessage id="FormNewTeam_Create_Profile_Label" />
                </Typography>

                <Fab variant="extended" size="small" className="button-green" component={Link} sx={{ mt: '20px' }} to={`/new_footballer`}>
                  <FormattedMessage id="FormNewTeam_Create_Profile" />
                </Fab>
              </Grid>
            ) : (
              <form className={'form-root'}>
                <UploadIMGFeature pictures={cover} setter_pictures={onChangeCover} max_number={1} ratio="cover" media_type="cover" />

                <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ textAlign: 'center', p: '10px 0', height: avatar?.length > 0 && cover?.length > 0 ? { xs: '70px', sm: '100px', md: '200px' } : 'auto' }}>
                  <UploadIMGFeature pictures={avatar} setter_pictures={onChangeAvatar} hasCover={cover?.length > 0} max_number={1} ratio="avatar" media_type="avatar" />
                </Stack>

                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 20px' }}>
                  <FormattedMessage id="FormNewTeam_Sport_Type_Title" />
                </Typography>
                <Grid id="max-width" className={'position field-bottom-space'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
                  <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                    {SPORT_TYPES.map((type: any) => (
                      <Button
                        key={type.value}
                        value={type.value}
                        sx={{
                          'height': '110px',
                          'width': '150px',
                          'm': '0 20px 0',
                          'display': 'inline-block',
                          'borderRadius': '10px',
                          'border': inputFields.sport_type === type.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                          'color': inputFields.sport_type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                          '& svg': { fontSize: '40px', color: inputFields.sport_type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                        }}
                      >
                        {type?.icon}
                        <br />
                        {`${intl.formatMessage({ id: type.label })}`}
                        {type.value === 'FOOTBALL' ? (
                          ''
                        ) : (
                          <>
                            <br />
                            <Typography sx={{ fontSize: '.7em' }}>
                              <FormattedMessage id="FormNewTeam_Sport_Type_Coming" />
                            </Typography>
                          </>
                        )}
                      </Button>
                    ))}
                  </Stack>
                </Grid>

                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 0px' }}>
                  <FormattedMessage id="FormNewTeam_Category_Title" />
                </Typography>
                <Typography id="max-width" variant="caption" sx={{ p: '0px 10% 20px', display: 'block' }}>
                  <FormattedMessage id="FormNewTeam_Category_Subtitle_1" />
                  {inputFields.sport_type === 'FOOTBALL' &&
                  loggedPlayerProfiles
                    ?.map((p: any) => {
                      return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'FootballTeam') || p?.player_positions?.find((po: any) => po?.team_player?.type === 'LEAGUE');
                    })
                    ?.filter((o: any) => o)?.length > 0 ? (
                    <>
                      <br />
                      <Typography variant="caption" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        {loggedPlayerProfiles?.map((p: any) => {
                          return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'FootballTeam');
                        })[0]?.name ? (
                          <FormattedMessage id="FormNewTeam_Category_Subtitle_2" />
                        ) : (
                          <FormattedMessage id="FormNewTeam_Category_Subtitle_3" />
                        )}
                        &nbsp;
                        <Avatar
                          src={
                            loggedPlayerProfiles?.map((p: any) => {
                              return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'FootballTeam');
                            })[0]?.avatar?.path || loggedPlayerProfiles?.map((p: any) => p?.player_positions?.find((po: any) => po?.team_player?.type === 'LEAGUE'))[0]?.team_player?.avatar?.path
                          }
                          sx={{
                            height: '30px',
                            width: '30px',
                          }}
                        />
                        &nbsp;
                        <Typography sx={{ fontWeight: '700' }}>
                          {loggedPlayerProfiles?.map((p: any) => {
                            return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'FootballTeam');
                          })[0]?.name || loggedPlayerProfiles?.map((p: any) => p?.player_positions?.find((po: any) => po?.team_player?.type === 'LEAGUE'))[0]?.team_player?.name}
                        </Typography>
                        {loggedPlayerProfiles?.map((p: any) => {
                          return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'FootballTeam');
                        })[0]?.name ? (
                          <>
                            &nbsp;
                            <FormattedMessage id="FormNewTeam_Category_Subtitle_4" />
                          </>
                        ) : (
                          ''
                        )}
                      </Typography>
                    </>
                  ) : (
                    ''
                  )}
                  {inputFields.sport_type === 'BASKETBALL' &&
                  loggedPlayerProfiles
                    ?.map((p: any) => {
                      return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'BasketballTeam');
                    })
                    ?.filter((o: any) => o)?.length > 0 ? (
                    <>
                      <br />
                      <Typography variant="caption" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        <FormattedMessage id="FormNewTeam_Category_Subtitle_5" />
                        &nbsp;
                        <Avatar
                          src={
                            loggedPlayerProfiles?.map((p: any) => {
                              return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'BasketballTeam');
                            })[0]?.avatar?.path
                          }
                        />
                        &nbsp;
                        {
                          loggedPlayerProfiles?.map((p: any) => {
                            return p?.sport_teams?.find((t: any) => t?.type === 'LEAGUE' && t?.__typename === 'BasketballTeam');
                          })[0]?.name
                        }
                        &nbsp;
                        <FormattedMessage id="FormNewTeam_Category_Subtitle_6" />
                      </Typography>
                    </>
                  ) : (
                    ''
                  )}
                </Typography>

                <Grid id="max-width" className={'position field-bottom-space'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
                  <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                    {SPORT_TEAM_TYPES.map((type: any) => (
                      <Button
                        key={type.value}
                        value={type.value}
                        onClick={() => handleTeamType(type.value)}
                        sx={{
                          'height': '110px',
                          'width': '150px',
                          'm': '0 20px 0',
                          'display': 'inline-block',
                          'borderRadius': '10px',
                          'border': inputFields.type === type.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                          'color': inputFields.type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                          '& svg': { fontSize: '40px', color: inputFields.type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                        }}
                      >
                        {type?.icon}
                        <br />
                        {`${intl.formatMessage({ id: type.label })}`}
                      </Button>
                    ))}
                  </Stack>
                </Grid>

                <Grid id="max-width" sx={{ p: '50px 10% 0' }}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'FormNewTeam_Name_Title' })}`}
                        placeholder={`${intl.formatMessage({ id: 'FormNewTeam_Name_Placeholder' })}`}
                        variant="outlined"
                        fullWidth
                        required
                        className={'field-bottom-space'}
                        value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
                        error={errors?.name?.type !== undefined}
                        helperText={errors?.name !== undefined || (inputFields.type === 'LEAGUE' && existingFootballName.data?.findFootballTeamName?.length > 0) ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormNewTeam_Name_1' })}` : `${intl.formatMessage({ id: 'FormNewTeam_Name_2' })}`) : `${field.value} ${intl.formatMessage({ id: 'FormNewTeam_Name_3' })}`}` : ''}
                      />
                    )}
                  />

                  <Controller
                    name="shortname"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: 3,
                      minLength: 3,
                      pattern: /^[A-Za-z0-9_]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'FormNewTeam_Shortname_Title' })}`}
                        placeholder={`${intl.formatMessage({ id: 'FormNewTeam_Shortname_Placeholder' })}`}
                        variant="outlined"
                        fullWidth
                        className={'field-bottom-space'}
                        value={field.value?.toUpperCase()}
                        error={errors?.shortname?.type !== undefined}
                        helperText={errors?.shortname !== undefined ? `${errors?.shortname?.type ? (field.value.length < 3 ? `${intl.formatMessage({ id: 'FormNewTeam_Shortname_1' })}` : `${intl.formatMessage({ id: 'FormNewTeam_Shortname_2' })}`) : ``}` : ''}
                      />
                    )}
                  />

                  <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                    {`${intl.formatMessage({ id: 'FormNewTeam_Privacy_Title' })}`}
                  </Typography>

                  <Typography>{inputFields.is_private ? `${intl.formatMessage({ id: 'FormNewTeam_Privacy_1' })}` : `${intl.formatMessage({ id: 'FormNewTeam_Privacy_2' })}`}</Typography>
                  <Controller
                    name="is_private"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('is_private', value)} inputProps={{ 'aria-label': 'controlled' }} />}
                  />

                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: '1.4em',
                      fontWeight: '700',
                      m: '40px 0 20px',
                    }}
                  >
                    {`${intl.formatMessage({ id: 'FormNewTeam_Bio_Title' })}`}
                  </Typography>
                  <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

                  <Typography sx={{ fontWeight: '700', p: '30px 0 20px' }}>{`${intl.formatMessage({ id: 'FormNewTeam_Area_Title' })}`}</Typography>
                  <Controller
                    name="address_title"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      max: 30,
                      pattern: /^[a-zA-Z0-9-\s]*$/,
                    }}
                    render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'FormNewTeam_Area_1' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewTeam_Area_2' })}`} variant="outlined" fullWidth required value={field.value} error={errors?.address_title !== undefined} helperText={errors?.address_title !== undefined ? `${errors?.address_title?.message}` : ''} className={'field-bottom-space'} />}
                  />

                  <Controller
                    control={control}
                    name="country"
                    render={({ field }: any) => (
                      <Autocomplete
                        {...field}
                        options={COUNTRIES}
                        value={country}
                        className={'country-select'}
                        onChange={(event: any, value: any) => onChangeCountry(event, value)}
                        autoHighlight
                        getOptionLabel={(option: CountryType) => option.label}
                        isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                        renderOption={(props: any, option: CountryType) => (
                          <Grid
                            component="li"
                            sx={{
                              '& > img': {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                            {option.label} ({option.value})
                          </Grid>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${intl.formatMessage({ id: 'Global_Address_Country' })}`}
                            value={country}
                            required
                            variant="outlined"
                            className={'field-bottom-space'}
                            error={errors?.country !== undefined}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {/* <Controller
                name="street_name"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label="Street address" autoComplete="address-line1" placeholder="Write your team's street address" variant="outlined" fullWidth value={field.value} error={errors?.street_name !== undefined} helperText={errors?.street_name !== undefined ? `${errors?.street_name?.message}` : ''} className={'field-bottom-space'} />}
              />

              <Controller
                name="secondary_street_name"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label="Street address (complement)" autoComplete="address-line2" placeholder="Complement of team's street address" variant="outlined" fullWidth value={field.value} error={errors?.secondary_street_name !== undefined} helperText={errors?.secondary_street_name !== undefined ? `${errors?.secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} className={'field-bottom-space'} />}
              /> */}

                  <Controller
                    name="postcode"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[a-zA-Z0-9-\s]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'Global_Address_Postcode_1' })}`}
                        required
                        placeholder={`${intl.formatMessage({ id: 'Global_Address_Postcode_2' })}`}
                        autoComplete="postal-code"
                        variant="outlined"
                        value={_.replace(field.value, /[^a-zA-Z0-9-\s]+/g, '')}
                        error={errors?.postcode !== undefined}
                        helperText={errors?.postcode !== undefined ? `${errors?.postcode?.message}` : ''}
                        className={'field-bottom-space'}
                        sx={{
                          mr: { xs: '0', sm: '3%', md: '5%' },
                          width: {
                            xs: '100%',
                            sm: '47%',
                            md: '45%',
                          },
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[a-zA-Z0-9-\s]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'Global_Address_City_1' })}`}
                        required
                        placeholder={`${intl.formatMessage({ id: 'Global_Address_City_2' })}`}
                        autoComplete="address-level2"
                        variant="outlined"
                        value={field.value}
                        error={errors?.city !== undefined}
                        helperText={errors?.city !== undefined ? `${errors?.city?.message}` : ''}
                        className={'field-bottom-space'}
                        sx={{
                          ml: { xs: '0', sm: '3%', md: '5%' },
                          width: {
                            xs: '100%',
                            sm: '47%',
                            md: '45%',
                          },
                        }}
                      />
                    )}
                  />

                  {/* <Controller
                name="county"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label="County"
                    placeholder="Team's county"
                    variant="outlined"
                    value={field.value}
                    error={errors?.county !== undefined}
                    helperText={errors?.county !== undefined ? `${errors?.county?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      mr: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="state"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label="State"
                    autoComplete="address-level1"
                    placeholder="Team's state"
                    variant="outlined"
                    value={field.value}
                    error={errors?.state !== undefined}
                    helperText={errors?.state !== undefined ? `${errors?.state?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      ml: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              /> */}
                </Grid>

                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 0px' }}>
                  {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_1' })}`}
                </Typography>
                <Typography id="max-width" variant="caption" sx={{ p: '0px 10% 20px', display: 'block' }}>
                  {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_2' })}`}
                </Typography>
                <InputSelectAvailabilitiesFeature setter={setValue} input={inputFields.availabilities} />

                <Grid id="max-width" className={'actions'} sx={{ p: '80px 10% 0' }}>
                  <Button className={newFootballTeam.loading || isSent.loading || progress > 0 ? 'button-disabled' : 'button-cancel'} disabled={newFootballTeam.loading || isSent.loading || progress > 0} onClick={() => navigate(-1)} endIcon={<Clear />}>
                    {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
                  </Button>

                  <LoadingButton
                    onClick={handleCreateTeam}
                    className={inputFields.availabilities?.length === 0 || inputFields.name === '' || (inputFields.type === 'LEAGUE' && existingFootballName.data?.findFootballTeamName?.length > 0) || inputFields.address_title === '' || inputFields.city === '' || inputFields.postcode === '' ? 'button-disabled' : 'button-green'}
                    disabled={inputFields.availabilities?.length === 0 || inputFields.name === '' || (inputFields.type === 'LEAGUE' && existingFootballName.data?.findFootballTeamName?.length > 0) || inputFields.address_title === '' || inputFields.city === '' || inputFields.postcode === ''}
                    loading={newFootballTeam.loading || isSent.loading || progress > 0}
                    loadingPosition="end"
                    endIcon={<Send />}
                    sx={{ m: '0 10px' }}
                  >
                    {`${intl.formatMessage({ id: 'FormNewTeam_Button_Create' })}`}
                  </LoadingButton>
                </Grid>
              </form>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

AddSportTeamModal.propTypes = {
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

AddSportTeamModal.defaultProps = {};

export default AddSportTeamModal;
