import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import ViewTransactionModal from '../../component-modals/purchase/ViewTransactionModal';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { useIntl } from 'react-intl';

interface IListTransactions {
  txs: any[];
}

const ListTransactions: React.FC<IListTransactions> = (props: IListTransactions) => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const [selectedTx, setSelectedTx] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();

  const ALL_OWNERS = _.concat(loggedPlayer?.id);

  const handleOpenTX = (tx_id: any) => {
    setSelectedTx(tx_id);
    setOpen(true);
  };

  const handleCloseTX = () => {
    setSelectedTx('');
    setOpen(false);
  };

  return (
    <Grid container id="max-width" className={'transactions all-transactions'} sx={{ pb: '150px' }}>
      {!props.txs[0] || props.txs?.length === 0 ? (
        <Typography
          variant="h3"
          sx={{
            p: '50px 20px 0',
            fontSize: '1.3em',
            fontWeight: '100',
            textAlign: 'center',
            width: '100%',
            color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15, 15, 15, .5)',
          }}
        >
          {`${intl.formatMessage({ id: 'AllTransactions_No_Tx' })}`}
        </Typography>
      ) : (
        <List sx={{ width: '100%' }}>
          {_.unionBy(props.txs, 'id')?.map((tx: any, index: number) => (
            <Grid key={index} onClick={() => handleOpenTX(tx?.id)}>
              <ListItem sx={{ display: { xs: 'inline-block', sm: 'flex' }, textAlign: { xs: 'center', sm: 'left' } }}>
                <ListItemAvatar>
                  {ALL_OWNERS?.find((id: string) => id === tx?.receiver_wallet?.owner?.id) ? (
                    <Avatar
                      component={Link}
                      to={tx?.sender_wallet?.owner?.username ? `/u/${tx?.sender_wallet?.owner?.username}` : `/${tx?.sender_wallet?.owner?.name}`}
                      alt={tx?.sender_wallet?.owner?.label || tx?.sender_wallet?.owner?.username}
                      src={tx?.sender_wallet?.owner?.logo?.path || tx?.sender_wallet?.owner?.avatar?.path}
                      sx={{
                        m: { xs: '0 auto', sm: '0 auto 0 0' },
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                      }}
                    />
                  ) : (
                    <Avatar
                      component={Link}
                      to={tx?.receiver_wallet?.owner?.username ? `/u/${tx?.receiver_wallet?.owner?.username}` : `/${tx?.receiver_wallet?.owner?.name}`}
                      alt={tx?.receiver_wallet?.owner?.label || tx?.receiver_wallet?.owner?.username}
                      src={tx?.receiver_wallet?.owner?.logo?.path || tx?.receiver_wallet?.owner?.avatar?.path}
                      sx={{
                        m: { xs: '0 auto', sm: '0 auto 0 0' },
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                      }}
                    />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        sx={{
                          textDecoration: 'none',
                          fontWeight: '700',
                          color: 'rgba(247, 251, 250,.4)',
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        {ALL_OWNERS?.find((id: string) => id === tx?.receiver_wallet?.owner?.id) ? <strong>{tx?.sender_wallet?.owner?.label || tx?.sender_wallet?.owner?.username}</strong> : <strong>{tx?.receiver_wallet?.owner?.label || tx?.receiver_wallet?.owner?.username}</strong>}
                        <Typography variant="caption">
                          {ALL_OWNERS?.find((id: string) => id === tx?.receiver_wallet?.owner?.id) ? <>&nbsp;{`${intl.formatMessage({ id: 'AllTransactions_Sent' })}`}&nbsp;</> : <>&nbsp;{`${intl.formatMessage({ id: 'AllTransactions_Received' })}`}&nbsp;</>}
                          <Moment fromNow>{tx?.execution_date}</Moment>
                        </Typography>
                        {tx?.receiver_blockchain_account?.id ? (
                          <>
                            <br />
                            <Typography variant="caption">
                              Funds
                              {ALL_OWNERS?.find((id: string) => id === tx?.receiver_wallet?.owner?.id) ? <>&nbsp;received in&nbsp;</> : <>&nbsp;sent to&nbsp;</>}
                              <Typography
                                variant="caption"
                                component={ALL_OWNERS?.find((id: string) => id === tx?.receiver_wallet?.owner?.id) ? Link : 'span'}
                                to={`/bc/account`}
                                state={ALL_OWNERS?.find((id: string) => id === tx?.receiver_wallet?.owner?.id) ? { account: { id: tx?.receiver_blockchain_account?.id }, wallet: { id: tx?.receiver_blockchain_account?.wallets?.[0]?.id, type: tx?.receiver_blockchain_account?.wallets?.[0]?.type } } : undefined}
                                sx={{ fontWeight: '800', textDecoration: 'none', color: 'inherit' }}
                              >
                                {tx?.receiver_blockchain_account?.title}
                              </Typography>
                            </Typography>
                          </>
                        ) : (
                          ''
                        )}
                      </Typography>
                    </>
                  }
                  secondary={
                    <Typography variant="caption">
                      <Typography className={tx?.status === 'PENDING' ? '' : tx?.status} variant="caption" sx={{ fontWeight: '800' }}>
                        {tx?.status?.replaceAll('_', ' ')}
                      </Typography>
                      &nbsp;-&nbsp;{_.truncate(tx?.tx_reference, { length: 20 })}
                    </Typography>
                  }
                />

                <Typography sx={{ textAlign: { xs: 'center', sm: 'right' }, fontWeight: '700' }}>
                  {tx?.value?.currency?.currency_code} {parseFloat(tx?.value?.value?.toFixed(tx?.value?.value?.toString()?.length > 5 ? 3 : 0))?.toLocaleString()}
                </Typography>
              </ListItem>
              <Divider variant="inset" component="li" sx={{ ml: { xs: '0', sm: '72px' }, bgcolor: 'rgba(247, 251, 250, 0.3)' }} />
            </Grid>
          ))}
          <ViewTransactionModal tx_id={selectedTx} open={open} handleClose={handleCloseTX} />
        </List>
      )}
    </Grid>
  );
};

ListTransactions.propTypes = {
  txs: PropTypes.any.isRequired,
};

ListTransactions.defaultProps = {
  txs: [],
};

export default ListTransactions;
