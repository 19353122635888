import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RootState } from '../redux/store';

export interface WalletsState {
  personal_wallets: any;
  business_wallets: any;
  associated_payment_methods: any;
}

const initialState: WalletsState = {
  personal_wallets: [],
  business_wallets: [],
  associated_payment_methods: [],
};

export const walletSlice = createSlice({
  name: 'wallets',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storePersonalWallets: (state, action: PayloadAction<any>) => {
      state.personal_wallets = action.payload;
    },
    storeBusinessWallets: (state, action: PayloadAction<any>) => {
      state.business_wallets = action.payload;
    },
    storeAssociatedPaymentMethods: (state, action: PayloadAction<any>) => {
      state.associated_payment_methods = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.personal_wallets = [];
      state.business_wallets = [];
      state.associated_payment_methods = [];
    });
  },
});

export const { storePersonalWallets, storeBusinessWallets, storeAssociatedPaymentMethods } = walletSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPersonalWallets = (state: RootState) => state.player_wallets.personal_wallets;
export const selectBusinessWallets = (state: RootState) => state.player_wallets.business_wallets;
export const selectAssociatedPaymentMethods = (state: RootState) => state.player_wallets.associated_payment_methods;

export default walletSlice.reducer;
