import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { AppContext } from '../App';

const useEventLog = () => {
  const CREATE_EVENT_LOG_MUTATION = gql`
    mutation createEventLog($event: inputCreateEventLog!) {
      createEventLog(event: $event) {
        id
      }
    }
  `;

  const appContext = React.useContext(AppContext);

  const [createEventLog, newLog] = useMutation(CREATE_EVENT_LOG_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });

  const handleSendLog = async (message: string, path?: string, app_client_id?: string) => {
    try {
      await createEventLog({
        variables: {
          event: {
            app_client_id: app_client_id,
            message: message.toString(),
            path: window.location.href,
            country_code: appContext?.currentPosition?.country?.code,
          },
        },
      });
    } catch (e: any) {
      console.log(e);
      await createEventLog({
        variables: {
          event: {
            message: e.toString(),
            path: window.location.href,
            country_code: appContext?.currentPosition?.country?.code,
          },
        },
      });
    }
  };

  return {
    handleSendLog,
    newLog,
  };
};

export default useEventLog;
