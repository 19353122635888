import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Tour from 'reactour';
import Moment from 'react-moment';
import _ from 'lodash';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Chip, Stack, AlertTitle, Alert, ListItemIcon, MenuItem, Menu, Typography, Fab, Button, IconButton, Grid } from '@mui/material';
import { ArrowBack, Delete, Help, MoreVert, Edit, ThumbUp, Add } from '@mui/icons-material';
import { LockIcon, VisaCardIcon, AmexCardIcon, MasterCardIcon, PacCardIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectPersonalWallets, storePersonalWallets } from '../../reducers/walletSlice';
import { PAYMENT_METHODS, ALTERNATIVE_PAYMENTS } from '../../utilities/utilities';
import NewKYCModal from '../../component-modals/features/NewKYCModal';
import EditWalletModal from '../../component-modals/wallet/EditWalletModal';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import MenuButton from '../../component-modals/layout/MenuButton';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import SpendingCodeModal from '../../component-modals/purchase/SpendingCodeModal';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

interface IWallet {
  wallet: { id: string; type: string };
  bank_card?: { id: string };
}

const Wallet: React.FC = () => {
  const FIND_ACCOUNT_PERSONAL_WALLET_BY_ID_QUERY = gql`
    query findAccountPersonalWalletByID($account: inputAccountWalletByID!) {
      findAccountPersonalWalletByID(account: $account) {
        id
        title
        status
        type
        is_default
        owner {
          ... on User {
            __typename
            id
            username
            owner {
              firstname
              lastname
            }
          }
          ... on Player {
            __typename
            id
            username
            owner {
              firstname
              lastname
            }
          }
          ... on Company {
            __typename
            id
            name
            label
          }
        }
        bank_accounts {
          id
          type
          status
          title
          account_number
          accepted_currencies
        }
        blockchain_accounts {
          id
          type
          title
          wallet_id
          wallet_addresses
          status
          accepted_currencies
        }
        beneficiaries {
          id
          firstname
          lastname
        }
        cards {
          ... on BankCard {
            __typename
            id
            network
            card_number
            status
          }
          ... on BlockchainCard {
            __typename
            id
            network
            card_number
            status
          }
        }
        payment_methods {
          id
          name
          label
          is_default
          status
          payment_source {
            ... on BankCard {
              __typename
              id
              network
              status
              card_number
            }
            ... on BankAccount {
              __typename
              id
              status
              accepted_currencies
            }
            ... on BlockchainCard {
              __typename
              id
              network
              status
              card_number
            }
            ... on BlockchainAccount {
              __typename
              id
              wallet_id
              wallet_addresses
              status
              accepted_currencies
            }
            ... on Cash {
              __typename
              id
              name
              status
            }
            ... on Gold {
              __typename
              id
              name
              status
            }
            ... on Waste {
              __typename
              id
              name
              status
            }
          }
          associates {
            ... on User {
              __typename
              id
              username
              avatar {
                filename
                path
              }
            }
          }
        }
        alternative_payment_sources {
          ... on Cash {
            __typename
            id
            name
            status
          }
          ... on Gold {
            __typename
            id
            name
            status
          }
          ... on Waste {
            __typename
            id
            name
            status
          }
        }
        sent_transactions {
          id
          tx_reference
        }
        received_transactions {
          id
          tx_reference
        }
        donations {
          id
        }
        kyc_cases {
          id
          status
          created_at
          audited_at
        }
      }
    }
  `;

  const SELECT_DEFAULT_PAYMENT_METHOD_MUTATION = gql`
    mutation selectDefaultPaymentMethod($payment_method: inputSelectDefaultPM!) {
      selectDefaultPaymentMethod(payment_method: $payment_method)
    }
  `;

  const DELETE_WALLET_MUTATION = gql`
    mutation deleteWallet($wallet: inputDeleteWallet!) {
      deleteWallet(wallet: $wallet)
    }
  `;

  const location: any = useLocation();
  const navigate = useNavigate();
  const state: IWallet = location?.state;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerPersonalWallets = useAppSelector(selectPersonalWallets);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [wallet, setWallet] = React.useState(UNDEFINED_AS_ANY);
  const [updateNow, setUpdateNow] = React.useState(false);
  const [newKyc, setNewKyc] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const [openEditWallet, setOpenEditWallet] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [deleteWallet, deletedWallet] = useMutation(DELETE_WALLET_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [selectDefaultPaymentMethod, updatedPaymentMethod] = useMutation(SELECT_DEFAULT_PAYMENT_METHOD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedAccountWallet = useQuery(FIND_ACCOUNT_PERSONAL_WALLET_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      account: {
        id: loggedPlayer?.id,
        username: loggedPlayer?.username,
        wallet_id: state?.wallet?.id,
      },
    },
    pollInterval: loggedPlayer?.id ? 3000 : undefined,
  });

  const steps = [
    {
      selector: '.step1',
      content: 'This is my first Step',
    },
    {
      selector: '.step2',
      content: 'This is my second Step',
    },
  ];

  const USER_BLOCKCHAIN_ACCOUNTS = [
    {
      type: 'Lipay',
      accounts: _.concat(
        [
          {
            content: 'Wallet_New_BA',
            link: '/bc/new_blockchain_account',
            id: '',
          },
        ],
        wallet?.blockchain_accounts
      ),
    },
  ];

  const USER_BANK_CARDS = [
    {
      type: 'Bank cards',
      cards: _.concat(
        [
          {
            content: 'Wallet_New_BC',
            link: '/bk/add_bank_card',
            id: '',
          },
        ],
        wallet?.cards.filter((c: any) => c.__typename === 'BankCard')
      ),
    },
  ];

  const USER_PAYMENT_METHODS = [
    {
      type: 'Payment methods',
      paymentMethods: _.concat(
        [
          {
            content: 'Wallet_New_PM',
            link: '/pm/add_payment_method',
            id: '',
          },
        ],
        wallet?.payment_methods
      ),
    },
  ];

  const handleOpenDeleteWallet = () => {
    setOpenDelete(true);
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleDeleteWallet = async () => {
    try {
      const result = await deleteWallet({
        variables: {
          wallet: {
            id: wallet?.id,
            owner_id: wallet?.owner?.id,
          },
        },
      });
      handleClose();

      if (result.data?.deleteWallet > 0) {
        navigate('/w/wallets', { replace: true });
      }
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTour = () => {
    setIsTourOpen(true);
    handleClose();
  };

  const handleOpenEditWallet = () => {
    setOpenEditWallet(true);
    handleClose();
  };

  const handleCloseEditWallet = () => {
    setOpenEditWallet(false);
  };

  React.useEffect(() => {
    if (!wallet && loadedAccountWallet.data?.findAccountPersonalWalletByID) {
      setWallet(loadedAccountWallet.data?.findAccountPersonalWalletByID);
    }

    if (updateNow || ((!wallet?.sent_transactions.length || !wallet?.received_transactions.length) && loadedAccountWallet.called && (!loadedAccountWallet.data?.findAccountPersonalWalletByID || !loadedAccountWallet.data?.findBusinessWalletByID))) {
      setWallet(state?.wallet?.type === 'BUSINESS' ? loadedAccountWallet.data?.findUserBusinessWalletByID : loadedAccountWallet.data?.findAccountPersonalWalletByID);

      if (state?.wallet?.type === 'PERSONAL') {
        const oldWallets = loggedPlayerPersonalWallets;
        const newPersonalWallet = _.merge(
          oldWallets.find((w: any) => w.id === wallet?.id),
          wallet
        );
        const newWallets = _.concat(
          loggedPlayerPersonalWallets.filter((w: any) => w.id !== wallet?.id),
          newPersonalWallet
        ).filter((o: any) => o.id !== undefined);
        if (!_.isEqual(loggedPlayerPersonalWallets, newWallets)) {
          try {
            dispatch(storePersonalWallets(newWallets));
          } catch (e) {
            console.log(e);
          }
        }
      }

      (async () => {
        if (wallet?.payment_methods?.length >= 1 && wallet?.payment_methods?.filter((pm: any) => pm?.is_default)?.length === 0 && !updatedPaymentMethod.called) {
          if (wallet?.payment_methods?.filter((pm: any) => pm?.name === 'LIPAY')) {
            await selectDefaultPaymentMethod({
              variables: {
                payment_method: {
                  id: wallet?.payment_methods?.find((pm: any) => pm?.name === 'LIPAY')?.id,
                  wallet_id: wallet?.id,
                },
              },
            });
          } else {
            await selectDefaultPaymentMethod({
              variables: {
                payment_method: {
                  id: wallet?.payment_methods[0]?.id,
                  wallet_id: wallet?.id,
                },
              },
            });
          }
        }
      })();

      setUpdateNow(false);
    }
  }, [loggedPlayerPersonalWallets, updateNow, updatedPaymentMethod, state, wallet, loadedAccountWallet, setWallet, dispatch, selectDefaultPaymentMethod]);

  return (
    <Grid container className={'component-preferences wallet-page dark-mode'} sx={{ pb: '100px', minHeight: '100%' }}>
      <Grid item xl={12} xs={12} sm={12}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <Grid container className={'actions'} sx={{ alignItems: 'center' }}>
          <Grid item xl={6} xs={6} sm={6} className={'back-button'} sx={{ textAlign: 'left' }}>
            <IconButton onClick={() => navigate('/w/wallets')} className={'button-back'}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xl={6} xs={6} sm={6} className={'menu-icon'} sx={{ textAlign: 'right', p: '0 20px' }}>
            <Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <MoreVert />
            </IconButton>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleTour}>
                <ListItemIcon>
                  <Help />
                </ListItemIcon>
                {`${intl.formatMessage({ id: 'Wallet_How_To' })}`}
              </MenuItem>

              {wallet?.kyc_cases[0]?.status === 'SUCCESSFUL' && wallet?.status === 'VERIFIED' ? (
                <MenuItem onClick={handleOpenEditWallet}>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'Wallet_Edit_Button' })}`}
                </MenuItem>
              ) : (
                ''
              )}
              <EditWalletModal wallet={wallet} handleClose={handleCloseEditWallet} open={openEditWallet} />

              {wallet?.kyc_cases[0]?.status === 'SUCCESSFUL' && wallet?.status === 'VERIFIED' ? (
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'Wallet_Lock_Button' })}`}
                </MenuItem>
              ) : (
                ''
              )}

              <MenuItem onClick={handleOpenDeleteWallet}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                {`${intl.formatMessage({ id: 'Wallet_Delete_Button' })}`}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        {!wallet || !state?.wallet?.id || loadedAccountWallet.loading || (loadedAccountWallet.data?.findAccountPersonalWalletByID && loadedAccountWallet.data?.findUserBusinessWalletByID) ? (
          <LoadingComponent amount={1} text={<Typography sx={{ textAlign: 'center' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} />
        ) : (
          <Grid>
            <Grid container className={'title'}>
              <Grid item xl={12} xs={12} sm={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '2em', fontWeight: '800' }}>{wallet?.title}</Typography>
                <Typography sx={{ fontSize: '1em', fontWeight: '300' }}>
                  {`${intl.formatMessage({ id: 'Wallet_Own_By' })}`}&nbsp;
                  {wallet?.type === 'BUSINESS' ? (
                    <Typography variant="caption" sx={{ fontSize: '1em', fontWeight: '800' }}>
                      {wallet?.owner?.label ?? wallet?.owner?.name}
                    </Typography>
                  ) : (
                    <Typography variant="caption" sx={{ fontSize: '1em', fontWeight: '800' }}>
                      {wallet?.owner?.owner?.firstname ? `${wallet?.owner?.owner?.firstname} ${wallet?.owner?.owner?.lastname}` : wallet?.owner?.username}
                    </Typography>
                  )}
                  &nbsp;
                  {wallet?.is_default ? `${intl.formatMessage({ id: 'Wallet_Default' })}` : ''}
                </Typography>
              </Grid>
            </Grid>

            {wallet?.blockchain_accounts?.length === 0 ? (
              <Alert icon={<ThumbUp />} severity="info" variant="outlined" sx={{ width: '80%', maxWidth: '500px', bgcolor: 'rgb(7,19,24)', color: 'rgb(3, 169, 244)', m: '20px auto 0' }}>
                {`${intl.formatMessage({ id: 'Wallet_Lipay_Info' })}`}
              </Alert>
            ) : (
              ''
            )}

            {wallet?.kyc_cases[0]?.status === 'SUCCESSFUL' ? (
              <Grid item xl={12} xs={12} sm={12} className={'list-container'}>
                {wallet?.bank_accounts?.length + wallet?.blockchain_accounts?.length + wallet?.cards?.length === 0 ? (
                  ''
                ) : (
                  <>
                    {USER_PAYMENT_METHODS.map((assetType: any, index: any) => (
                      <Grid container key={index} className={'container'}>
                        <Grid item xl={12} xs={12} sm={12} className={'list-title'}>
                          <Typography
                            variant="h3"
                            sx={{
                              fontSize: '1.3em',
                              fontWeight: '800',
                              p: '40px 5% 10px',
                            }}
                          >
                            {assetType.type}
                          </Typography>
                        </Grid>
                        <Stack
                          direction="row"
                          className={'list-items'}
                          sx={{
                            'overflow': 'auto',
                            '&::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }}
                        >
                          {assetType.paymentMethods.map((paymentMethod: any, index: number) => (
                            <Grid key={index} className={'item'}>
                              <Grid
                                container
                                className={'content'}
                                sx={{
                                  minHeight: '100%',
                                }}
                              >
                                <Grid item xl={12} xs={12} sm={12}>
                                  {paymentMethod?.id !== '' ? (
                                    <Grid
                                      sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        m: '0 10px',
                                        backgroundColor: 'rgba(247, 251, 250, 1)',
                                        borderRadius: '4px',
                                        height: '100%',
                                        width: '200px',
                                      }}
                                    >
                                      <Grid
                                        container
                                        component={Link}
                                        to={{
                                          pathname: `/pm/payment_method`,
                                        }}
                                        state={{
                                          wallet: {
                                            id: wallet?.id,
                                            type: wallet?.type,
                                          },
                                          payment_method: {
                                            id: paymentMethod?.id,
                                          },
                                        }}
                                        sx={{ color: 'rgb(15,15,15)', textDecoration: 'none', p: '0 10px', height: '100%' }}
                                      >
                                        {paymentMethod?.is_default ? (
                                          <Grid container sx={{ justifyContent: 'center', pt: '5px' }}>
                                            <Chip label="DEFAULT" size="small" />
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                        <Stack direction="column" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                          <Grid sx={{ '& > svg': { fontSize: '50px' } }}>
                                            {PAYMENT_METHODS?.find((pm: any) => pm?.name === paymentMethod?.name)?.icon}
                                            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '700' }}>
                                              {paymentMethod?.payment_source?.name ? <>{ALTERNATIVE_PAYMENTS?.find((a: any) => a?.name === paymentMethod?.payment_source?.name)?.icon}&nbsp;</> : ''}
                                              {paymentMethod?.payment_source?.accepted_currencies ? paymentMethod?.payment_source.accepted_currencies : ''}
                                              {paymentMethod?.payment_source?.network ? paymentMethod?.payment_source?.network : ''}
                                              {paymentMethod?.payment_source?.name ? ALTERNATIVE_PAYMENTS?.find((a: any) => a?.name === paymentMethod?.payment_source?.name)?.name?.replaceAll('_', ' ') : ''}
                                            </Typography>
                                          </Grid>

                                          {paymentMethod?.payment_source?.wallet_addresses ? `${paymentMethod?.payment_source?.wallet_addresses[0]?.substring(0, 7)?.toUpperCase()}***${paymentMethod?.payment_source?.wallet_addresses[0]?.substring(paymentMethod?.payment_source?.wallet_addresses[0].length - 4, paymentMethod?.payment_source?.wallet_addresses[0].length)?.toUpperCase()}` : ''}
                                          {paymentMethod?.payment_source?.card_number ? `${paymentMethod?.payment_source?.card_number?.substring(0, 4)}***${paymentMethod?.payment_source?.card_number?.substring(paymentMethod?.payment_source?.card_number.length - 3, paymentMethod?.payment_source?.card_number.length)}` : ''}
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Button
                                      component={Link}
                                      to={{
                                        pathname: paymentMethod?.link,
                                      }}
                                      state={{
                                        wallet: {
                                          id: wallet?.id,
                                          type: wallet?.type,
                                        },
                                      }}
                                      sx={{
                                        'height': '150px',
                                        'width': '150px',
                                        'justifyContent': 'left',
                                        'm': '0 30px',
                                        'color': '#FFF !important',
                                        'backgroundColor': 'rgba(255, 255, 255, .04)',
                                        ':hover': {
                                          backgroundColor: 'rgba(199, 240, 11, .5) !important',
                                        },
                                      }}
                                    >
                                      <Add />
                                      &nbsp;
                                      <h3 className={paymentMethod?.title === '' ? 'green-title' : ''}>{`${intl.formatMessage({ id: paymentMethod?.content })}`}</h3>
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Stack>
                      </Grid>
                    ))}
                  </>
                )}

                {USER_BLOCKCHAIN_ACCOUNTS.map((assetType: any, index: any) => (
                  <Grid container key={index} className={'container'}>
                    <Grid item xl={12} xs={12} sm={12} className={'list-title'}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: '1.3em',
                          fontWeight: '800',
                          p: '40px 5% 10px',
                        }}
                      >
                        {assetType.type} accounts {assetType.accounts.length <= 1 ? <Typography variant="caption">{`${intl.formatMessage({ id: 'Wallet_Meta_Recommened' })}`}</Typography> : ''}
                      </Typography>
                    </Grid>
                    <Stack
                      direction="row"
                      className={'list-items'}
                      sx={{
                        'overflow': 'auto',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      {assetType.accounts.map((account: any) => (
                        <Grid key={account.id} className={'item'}>
                          <Grid
                            container
                            className={'content'}
                            sx={{
                              minHeight: '100%',
                            }}
                          >
                            <Grid item xl={12} xs={12} sm={12}>
                              {account?.id !== '' ? (
                                <Grid
                                  sx={{
                                    textAlign: 'center',
                                    m: '0 10px',
                                    backgroundColor: 'rgba(247, 251, 250, 1)',
                                    borderRadius: '4px',
                                    height: '100%',
                                    display: 'flex',
                                    width: '200px',
                                  }}
                                >
                                  <Button
                                    className={'entity-name'}
                                    component={Link}
                                    to={{
                                      pathname: `/bc/account`,
                                    }}
                                    state={{
                                      account: {
                                        id: account.id,
                                      },
                                      wallet: {
                                        id: wallet?.id,
                                      },
                                    }}
                                    sx={{
                                      color: '#000',
                                      width: '100%',
                                      p: '0 10px',
                                    }}
                                  >
                                    <Stack direction="column">
                                      <strong>{account?.title}</strong>
                                      {`${account?.wallet_addresses[0]?.substring(0, 7)}***${account?.wallet_addresses[0]?.substring(account?.wallet_addresses[0]?.length - 4, account?.wallet_addresses[0]?.length)}`}
                                    </Stack>
                                  </Button>
                                </Grid>
                              ) : (
                                <Button
                                  component={Link}
                                  to={{
                                    pathname: account.link,
                                  }}
                                  state={{
                                    wallet: {
                                      id: wallet?.id,
                                      type: wallet?.type,
                                      owner: wallet?.owner,
                                    },
                                  }}
                                  sx={{
                                    'height': '150px',
                                    'width': '150px',
                                    'justifyContent': 'left',
                                    'm': '0 30px',
                                    'color': '#FFF !important',
                                    'backgroundColor': 'rgba(255, 255, 255, .04)',
                                    ':hover': {
                                      backgroundColor: 'rgba(199, 240, 11, .5) !important',
                                    },
                                  }}
                                >
                                  <Add />
                                  &nbsp;
                                  <h3 className={account?.account_number === '' ? 'green-title' : ''}>{`${intl.formatMessage({ id: account?.content })}`}</h3>
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Stack>
                  </Grid>
                ))}

                {USER_BANK_CARDS.map((assetType: any, index: any) => (
                  <Grid container key={index} className={'container'}>
                    <Grid item xl={12} xs={12} sm={12} className={'list-title'}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: '1.3em',
                          fontWeight: '800',
                          p: '40px 5% 10px',
                        }}
                      >
                        {assetType.type}
                      </Typography>
                    </Grid>
                    <Stack
                      direction="row"
                      className={'list-items'}
                      sx={{
                        'overflow': 'auto',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      {assetType.cards.map((card: any) => (
                        <Grid key={card?.id} className={'item'}>
                          {/* {card?.id === '' ? '' : (
                                                    <ImageListItemBar
                                                        actionPosition="right"
                                                        actionIcon={card?.status === 'locked' ? <IconButton onClick={() => handleLockOpen(card?.id)}><Lock /></IconButton> : <IconButton onClick={() => handleLock(card?.id)}><LockOpen /></IconButton>}
                                                    />
                                                )} */}
                          <Grid
                            container
                            className={'content'}
                            sx={{
                              minHeight: '100%',
                            }}
                          >
                            <Grid item xl={12} xs={12} sm={12}>
                              {card?.id !== '' ? (
                                <Grid
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    m: '0 10px',
                                    backgroundColor: 'rgba(247, 251, 250, 1)',
                                    borderRadius: '4px',
                                    height: '100%',
                                    display: 'flex',
                                    width: '200px',
                                  }}
                                >
                                  <Grid
                                    className={'entity-name'}
                                    component={Link}
                                    to={{
                                      pathname: `/bk/card`,
                                    }}
                                    state={{
                                      wallet: {
                                        id: wallet?.id,
                                        type: wallet?.type,
                                      },
                                      card: {
                                        id: card?.id,
                                      },
                                    }}
                                    sx={{
                                      width: '100%',
                                      p: '0 10px',
                                      color: 'rgb(15,15,15)',
                                      textDecoration: 'none',
                                    }}
                                  >
                                    <Stack direction="column">
                                      {card?.network === 'VISA' ? (
                                        <VisaCardIcon
                                          sx={{
                                            width: '100%',
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {card?.network === 'AMEX' ? (
                                        <AmexCardIcon
                                          sx={{
                                            width: '100%',
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {card?.network === 'MASTERCARD' ? (
                                        <MasterCardIcon
                                          sx={{
                                            width: '100%',
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {card?.network === 'PAC' ? (
                                        <PacCardIcon
                                          sx={{
                                            width: '100%',
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      <strong>{card?.network}</strong>
                                      {`${card?.card_number?.substring(0, 4)}***${card?.card_number?.substring(card?.card_number.length - 3, card?.card_number.length)}`}
                                    </Stack>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Button
                                  component={Link}
                                  to={{
                                    pathname: card.link,
                                  }}
                                  state={{
                                    wallet: {
                                      id: wallet?.id,
                                      type: wallet?.type,
                                    },
                                  }}
                                  sx={{
                                    'height': '150px',
                                    'width': '150px',
                                    'justifyContent': 'left',
                                    'm': '0 30px',
                                    'color': '#FFF !important',
                                    'backgroundColor': 'rgba(255, 255, 255, .04)',
                                    ':hover': {
                                      backgroundColor: 'rgba(199, 240, 11, .5) !important',
                                    },
                                  }}
                                >
                                  <Add />
                                  &nbsp;
                                  <h3 className={card?.card_number === '' ? 'green-title' : ''}>{`${intl.formatMessage({ id: card?.content })}`}</h3>
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xl={12} xs={12} sm={12}>
                {wallet?.kyc_cases[0]?.status === 'PENDING' ? (
                  <Alert severity="info" variant="filled" sx={{ m: '20px 30px' }}>
                    <AlertTitle sx={{ fontWeight: '700' }}>{`${intl.formatMessage({ id: 'Wallet_Unavailable_Title' })}`}</AlertTitle>
                    {`${intl.formatMessage({ id: 'Wallet_Unavailable_Label' })}`}
                  </Alert>
                ) : (
                  ''
                )}

                {wallet?.kyc_cases[0]?.status === 'WAITING_VERIFICATION' ? (
                  <Alert severity="warning" variant="filled" sx={{ m: '20px 30px' }}>
                    <AlertTitle sx={{ fontWeight: '700' }}>
                      {`${intl.formatMessage({ id: 'Wallet_KYC_Wait_Title' })}`}&nbsp;({`${intl.formatMessage({ id: 'Wallet_KYC_Wait_Label_3' })}`}&nbsp;<Moment fromNow>{wallet?.kyc_cases[0]?.created_at}</Moment>)
                    </AlertTitle>
                    {`${intl.formatMessage({ id: 'Wallet_KYC_Wait_Label_1' })}`}
                    <br />
                    {`${intl.formatMessage({ id: 'Wallet_KYC_Wait_Label_2' })}`}
                  </Alert>
                ) : (
                  ''
                )}

                {wallet?.kyc_cases[0]?.status === undefined || wallet?.kyc_cases[0]?.status === 'UNSUCCESSFUL' || wallet?.kyc_cases[0]?.status === 'UNVALID' ? (
                  <Alert severity="error" variant="filled" sx={{ m: '20px 30px' }}>
                    <AlertTitle sx={{ fontWeight: '700' }}>{`${intl.formatMessage({ id: 'Wallet_KYC_Failed_Title' })}`}</AlertTitle>
                    {`${intl.formatMessage({ id: 'Wallet_KYC_Failed_Label_1' })}`}
                    <br />
                    {`${intl.formatMessage({ id: 'Wallet_KYC_Failed_Label_2' })}`}
                    <Grid
                      sx={{
                        p: '20px 0',
                        textAlign: 'center',
                      }}
                    >
                      <Fab
                        variant="extended"
                        onClick={() => setNewKyc(true)}
                        className="button-white"
                        size="small"
                        sx={{
                          background: 'transparent',
                          boxShadow: 'none',
                        }}
                      >
                        {`${intl.formatMessage({ id: 'Wallet_Button_New_KYC' })}`}
                      </Fab>
                    </Grid>
                  </Alert>
                ) : (
                  ''
                )}
              </Grid>
            )}
          </Grid>
        )}

        <DeleteConfirmationModal title={`${intl.formatMessage({ id: 'Global_Permanent_Delete_Title' })} ${wallet?.title}`} open={openDelete} entityId={wallet?.id} entityName={`${wallet?.title}`} actionOne={handleOpenConfirmation} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={handleClose} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} loading={deletedWallet.loading} />
        <SpendingCodeModal actionOne={handleDeleteWallet} actionTwo={handleClose} open={openConfirmation} />
        <NewKYCModal title={`${wallet?.title}${intl.formatMessage({ id: 'Wallet_New_KYC_Title' })}`} wallet={wallet} open={newKyc} handleClose={() => setNewKyc(false)} />
      </Grid>
    </Grid>
  );
};

export default Wallet;
