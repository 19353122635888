import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { Logout } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { persistor } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

const LogoutButton = (props: { showIcon?: boolean }) => {
  const PLAYER_LOGOUT_MUTATION = gql`
    mutation endSession($session: inputSession!) {
      endSession(session: $session) {
        id
        status
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [endSession, loggedOutPlayer] = useMutation(PLAYER_LOGOUT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const navigate = useNavigate();

  const logoutNow = async (session: any) => {
    await endSession({
      variables: {
        session: { id: session.id },
      },
    });

    persistor.purge();
    navigate('/', { replace: true });
  };

  return (
    <LoadingButton onClick={() => logoutNow(session)} className="logout-button" loading={loggedOutPlayer.loading} loadingPosition="start" variant="text" sx={{ 'color': 'inherit !important', 'fontWeight': 'inherit !important', '&:hover': { background: 'transparent !important' } }} startIcon={loggedOutPlayer.loading ? <CircularProgress size={20} /> : <>{!props.showIcon ? undefined : <Logout />}</>} disabled={loggedOutPlayer.data || session.id === undefined}>
      {loggedOutPlayer.loading ? `${intl.formatMessage({ id: 'Settings_Logging_Out' })}` : `${intl.formatMessage({ id: 'Settings_Logout' })}`}
    </LoadingButton>
  );
};

export default LogoutButton;
