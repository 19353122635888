import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Add, Delete } from '@mui/icons-material';
import { Fab, Stack, Grid, MenuItem, TextField, Typography, IconButton } from '@mui/material';
import { TROPHY_RANKS } from '../../utilities/utilities';
import { Controller, useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { selectPlayer } from '../../reducers/playerSlice';
import { useIntl } from 'react-intl';

interface IInputTrophiesFeature {
  tournament_id?: string;
  trophies: any[];
  setter: any;
  control: any;
}

const InputTrophiesFeature: React.FC<IInputTrophiesFeature> = (props: IInputTrophiesFeature) => {
  const DELETE_TROPHY_MUTATION = gql`
    mutation deleteTrophy($trophy: inputDeleteTrophy!) {
      deleteTrophy(trophy: $trophy)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      note: '',
      rank: '',
    },
  });
  const inputFields = watch();

  const [deleteTrophy, deletedTrophy] = useMutation(DELETE_TROPHY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleAddTrophy = () => {
    const newTrophy = {
      name: inputFields.name,
      note: inputFields.note,
      rank: inputFields.rank,
    };

    const newList = _.concat(newTrophy, props.trophies)?.filter((i: any) => i);

    props.setter('trophies', newList);
    setValue('name', '');
    setValue('rank', '');
  };

  const handleDeleteTrophy = async (trophy: any) => {
    try {
      if (trophy?.id) {
        await deleteTrophy({
          variables: {
            trophy: {
              id: trophy?.id,
              player_id: loggedPlayer?.id,
            },
          },
        });

        const newList = props.trophies?.filter((t: any) => t?.id !== trophy?.id);
        props.setter('trophies', newList);
      } else if (!trophy?.id) {
        const newList = props.trophies?.filter((t: any) => !_.isMatch(t, trophy));
        props.setter('trophies', newList);
      } else {
        console.log('dont-do-anything');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {props.trophies?.length === 0 ? (
        <Typography
          variant="h3"
          sx={{
            p: '10px 20px 30px',
            fontSize: '1.3em',
            textAlign: 'center',
            width: '100%',
            color: 'rgba(247, 251, 250, .3) !important',
          }}
        >
          {`${intl.formatMessage({ id: 'Global_No_Trophies' })}`}
        </Typography>
      ) : (
        <Grid container sx={{ overflow: 'auto', justifyContent: { xs: 'start', sm: 'center' } }}>
          <Stack
            direction="row"
            sx={{
              m: '20px 0',
              width: '100%',
              justifyContent: { xs: 'start', lg: 'center' },
              display: 'inline-flex',
            }}
          >
            {props.trophies?.map((t: any, index: number) => (
              <Grid key={index} sx={{ minWidth: '200px', maxWidth: '350px', textAlign: 'center', m: '0 10px', bgcolor: 'rgba(247, 251, 250, .1)', borderRadius: '10px', p: '10px' }}>
                <IconButton disabled={deletedTrophy.loading} className={deletedTrophy.loading ? 'button-disabled' : 'button-red-reversed'} onClick={() => handleDeleteTrophy(t)}>
                  <Delete />
                </IconButton>

                <Grid sx={{ '& .MuiSvgIcon-root': { fontSize: '4em' } }}>
                  {TROPHY_RANKS?.find((r: any) => r.value === t?.rank)?.icon}
                  <Typography sx={{ fontSize: '1.3em', fontWeight: '800' }}>{t?.name}</Typography>
                  <Typography variant="caption">{t?.note}</Typography>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Grid>
      )}

      <Controller
        name="rank"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field }: any) => (
          <TextField {...field} value={field.value} select fullWidth className={'field-bottom-space'} label={`${intl.formatMessage({ id: 'InputTrophiesFeature_Rank_Label' })}`} variant="outlined">
            {TROPHY_RANKS.filter((r: any) => !props.trophies?.find((t: any) => t?.rank === r?.value && r.value !== 'IRON')).map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {`${intl.formatMessage({ id: option.label })}`}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Controller
        name="name"
        control={control}
        rules={{
          required: true,
          maxLength: 30,
          minLength: 4,
          pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
        }}
        render={({ field }: any) => (
          <TextField
            {...field}
            label={`${intl.formatMessage({ id: 'InputTrophiesFeature_Name_Label' })}`}
            placeholder={`${intl.formatMessage({ id: 'InputTrophiesFeature_Name_Placeholder' })}`}
            variant="outlined"
            fullWidth
            required
            className={'field-bottom-space'}
            value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
            error={errors?.name?.type !== undefined}
            helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'InputTrophiesFeature_Name_Helper_1' })}` : `${intl.formatMessage({ id: 'InputTrophiesFeature_Name_Helper_2' })}`) : ``}` : ''}
          />
        )}
      />

      <Controller
        name="note"
        control={control}
        rules={{
          required: true,
          maxLength: 30,
          minLength: 4,
          pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
        }}
        render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'InputTrophiesFeature_Note_Label' })}`} placeholder={`${intl.formatMessage({ id: 'InputTrophiesFeature_Note_Placeholder' })}`} variant="outlined" fullWidth className={'field-bottom-space'} value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, '')} error={errors?.note?.type !== undefined} />}
      />

      <Grid container sx={{ justifyContent: 'center' }}>
        <Fab variant="extended" size="small" disabled={deletedTrophy.loading || inputFields.name === '' || inputFields.note === '' || inputFields.rank === ''} className={deletedTrophy.loading || inputFields.name === '' || inputFields.note === '' || inputFields.rank === '' ? 'button-disabled' : 'button-green-reverse'} onClick={handleAddTrophy}>
          <Add />
          &nbsp;{`${intl.formatMessage({ id: 'InputTrophiesFeature_Add_Trophy' })}`}
        </Fab>
      </Grid>
    </>
  );
};

InputTrophiesFeature.propTypes = {
  trophies: PropTypes.array.isRequired,
  setter: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
};

InputTrophiesFeature.defaultProps = {};

export default InputTrophiesFeature;
