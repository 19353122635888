import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { Fab, Grid, Typography, Dialog, DialogTitle, DialogContent, TextField, InputAdornment, MenuItem, Button } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { ARRAY_AS_ANY, DESCRIPTION_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { BLOCKCHAIN_CURRENCIES } from '../../utilities/utilities';
import InputDescriptionsFeature from '../common/InputDescriptionsFeature';
import { LoadingButton } from '@mui/lab';

interface INewSponsorshipModal {
  subject_id: string;
  subject_title: string;
  handleClose: any;
  open: boolean;
}

const NewSponsorshipModal: React.FC<INewSponsorshipModal> = (props: INewSponsorshipModal) => {
  const CREATE_SPONSORSHIP_MUTATION = gql`
    mutation createSponsorship($sponsorship: inputCreateSponsorship!) {
      createSponsorship(sponsorship: $sponsorship) {
        id
      }
    }
  `;

  const FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY = gql`
    query findPaymentMethodsByCompanyAccountID($company: inputFindPaymentMethodsByCompanyAccountID!) {
      findPaymentMethodsByCompanyAccountID(company: $company) {
        id
        name
        label
        status
        payment_source {
          ... on BankCard {
            __typename
            id
            network
          }
          ... on BankAccount {
            __typename
            id
            title
          }
          ... on BlockchainCard {
            __typename
            id
            network
          }
          ... on BlockchainAccount {
            __typename
            id
            title
          }
          ... on Cash {
            __typename
            id
            name
          }
          ... on Gold {
            __typename
            id
            name
          }
          ... on Waste {
            __typename
            id
            name
          }
        }
        wallets {
          id
          title
          is_default
        }
      }
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      title: '',
      language: 'GB',
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',
      descriptions: ARRAY_AS_ANY,
      value: 0,
      currency: 'MAT',
    },
  });
  const inputFields = watch();
  const [createSponsorship, newSponsorship] = useMutation(CREATE_SPONSORSHIP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedReceiverPaymentMethods = useQuery(FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !loggedPlayer?.id,
    variables: {
      company: {
        id: undefined,
        logged_account_id: loggedPlayer?.id,
      },
    },
  });

  const found_receiver_pm = loadedReceiverPaymentMethods.data?.findPaymentMethodsByCompanyAccountID?.find((pm: any) => pm?.wallets?.[0]?.is_default);

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  const handleCreateSponsorship = async () => {
    try {
      await createSponsorship({
        variables: {
          sponsorship: {
            title: inputFields.title,
            author_id: loggedPlayer?.id,
            descriptions: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
            price: {
              value: parseFloat(inputFields.value.toString()),
              currency: inputFields.currency,
            },
            subject_id: props.subject_id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  React.useEffect(() => {
    if (inputFields.value <= -1) setValue('value', 0);
    if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
    if (inputFields.descriptions?.length > 0 && inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');
  }, [props, inputFields, setValue]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: {
          p: '0',
          width: '85%',
          position: 'absolute',
          top: '5%',
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Grid container>
          <Fab
            size="small"
            onClick={handleClose}
            className="button-cancel"
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              m: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>New sponsorship for {props.subject_title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={'form-page'} sx={{ bgcolor: 'transparent', p: '0', minHeight: 'auto' }}>
          <form className={'form-root'}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: true,
                maxLength: 30,
                minLength: 4,
                pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
              }}
              render={({ field }: any) => <TextField {...field} label={`Title`} fullWidth value={_.trimStart(field.value)} className={'field-bottom-space'} />}
            />

            <Typography
              variant="h3"
              sx={{
                fontSize: '1.4em',
                fontWeight: '700',
                m: '40px 0 20px',
              }}
            >
              {`${intl.formatMessage({ id: 'NewSponsorshipModal_Descriptions_Title' })}`}
            </Typography>
            <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

            <Typography
              variant="h3"
              sx={{
                fontSize: '1.2em',
                fontWeight: '700',
                m: '40px 0 20px',
              }}
            >
              {`${intl.formatMessage({ id: 'NewSponsorshipModal_Price_Title' })}`}
            </Typography>
            <Controller
              name="value"
              control={control}
              rules={{
                required: true,
                min: 1,
                max: 100000000,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label={`${intl.formatMessage({ id: 'NewSponsorshipModal_Price_Value_1' })}`}
                  placeholder={'0'}
                  required
                  type="number"
                  variant="outlined"
                  value={field.value <= -1 ? 0 : field.value && field.value > 100000000 ? 100000000 : field.value}
                  sx={{
                    mr: {
                      xs: '0',
                      sm: '3%',
                      md: '3%',
                    },
                    width: {
                      xs: '100%',
                      sm: '64%',
                      md: '64%',
                    },
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{inputFields.currency}</InputAdornment>,
                  }}
                  className={'field-bottom-space'}
                />
              )}
            />

            <Controller
              name="currency"
              control={control}
              rules={{
                required: true,
                pattern: /^[0-9a-zA-Z]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  value={field.value}
                  select
                  SelectProps={{
                    MenuProps: {
                      sx: {
                        zIndex: '1000000000',
                      },
                    },
                  }}
                  required
                  label={`${intl.formatMessage({ id: 'NewSponsorshipModal_Price_Currency' })}`}
                  placeholder="Service currency"
                  variant="outlined"
                  sx={{
                    ml: {
                      xs: '0',
                      sm: '3%',
                      md: '3%',
                    },
                    width: {
                      xs: '100%',
                      sm: '30%',
                      md: '30%',
                    },
                  }}
                >
                  {_.concat(BLOCKCHAIN_CURRENCIES).map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${intl.formatMessage({ id: option.label })}`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Grid container sx={{ mt: '40px' }}>
              {!found_receiver_pm ? (
                <Typography className="WARNING" sx={{ width: '100%', textAlign: 'center', p: '10px 0' }}>
                  <strong>{loggedPlayer?.username}</strong> does not have a valid payment method in default wallet
                </Typography>
              ) : (
                ''
              )}

              <Button onClick={handleClose} className="button-cancel">{`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}</Button>

              <LoadingButton
                onClick={handleCreateSponsorship}
                loading={newSponsorship.loading}
                loadingPosition="end"
                endIcon={<Send />}
                disabled={!found_receiver_pm || inputFields.title === '' || inputFields.value <= 0 || inputFields.descriptions?.length === 0 || inputFields.descriptions[0]?.content?.length === 0}
                className={!found_receiver_pm || inputFields.title === '' || inputFields.value <= 0 || inputFields.descriptions?.length === 0 || inputFields.descriptions[0]?.content?.length === 0 ? 'button-disabled' : 'button-green'}
              >{`${intl.formatMessage({
                id: 'NewSponsorshipModal_Button_Create',
              })}`}</LoadingButton>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

NewSponsorshipModal.propTypes = {
  subject_id: PropTypes.string.isRequired,
  subject_title: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

NewSponsorshipModal.defaultProps = {
  subject_id: '',
  subject_title: '',
};

export default NewSponsorshipModal;
