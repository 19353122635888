import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';

import BackButton from '../../component-modals/common/BackButton';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import { Link } from 'react-router-dom';
import MenuButton from '../../component-modals/layout/MenuButton';
import LegalMentionsContent from './LegalMentionContent';
import { useIntl } from 'react-intl';

const LegalMentions: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  return (
    <Grid className={'component-preferences settings-page legal-mentions-page'}>
      <Grid container>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}>
          <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={!loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        </Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      <Grid container id="max-width" sx={{ p: '0 5% 150px', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>
        <Grid className={'title'} item xl={12} xs={12} sm={12}>
          <Typography sx={{ fontSize: '2em', fontWeight: '800', p: '20px 0' }}>{`${intl.formatMessage({ id: 'Settings_Legal_Mentions_Title' })}`}</Typography>
        </Grid>
        <LegalMentionsContent />
      </Grid>
    </Grid>
  );
};

export default LegalMentions;
