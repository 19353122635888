import React from 'react';
import { AppBar, Avatar, AvatarGroup, Card, CardContent, CardMedia, CircularProgress, Fab, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import SwipeableViews from 'react-swipeable-views';
import { ChallengeIcon, FiltersIcon, FivezerLogoIcon, HistoryIcon } from '../../img/icons/Icons';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { ARRAY_AS_ANY, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import MenuButton from '../../component-modals/layout/MenuButton';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { COUNTRIES, ONE_DAY_MILLISEC, TO_TIMESTAMP } from '../../utilities/utilities';
import TopBarActionsButton from '../../component-modals/layout/TopBarActionsButton';
import { AppContext } from '../../App';
import { selectSession } from '../../reducers/sessionSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import TournamentFiltersModal from '../../component-modals/tournament/TournamentFiltersModal';
import { Add } from '@mui/icons-material';
import Moment from 'react-moment';

interface ITournaments {
  index?: number;
}

const Tournaments: React.FC = () => {
  const FIND_TOURNAMENTS_BY_OPTIONS_QUERY = gql`
    query findTournamentsByOptions($tournament: inputFindTournamentsByOptions!) {
      findTournamentsByOptions(tournament: $tournament) {
        id
        short_id
        title
        status
        sport
        start_date
        end_date
        is_private
        organisators {
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              logo {
                filename
                path
              }
            }
          }
        }
        main_location {
          id
          title
          coordinates
          country_code
          country {
            name
          }
          continent {
            name
          }
        }
        team_candidates {
          id
          name
          team {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              shortname
              avatar {
                path
              }
            }
          }
        }
        phase_playoffs {
          id
          stage_name
          game {
            id
            short_id
            complex {
              id
              name
              location {
                id
                country_code
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            arena {
              id
              name
              pictures {
                path
              }
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();

  const location: any = useLocation();
  const state: ITournaments = location?.state;
  const appContext = React.useContext(AppContext);

  const [upcomings, setUpcomings] = React.useState(UNDEFINED_AS_ANY);
  const [pasts, setPasts] = React.useState(UNDEFINED_AS_ANY);
  const [value, setValueIndex] = React.useState(state?.index ? state?.index : 0);
  const [offsetUpcomings] = React.useState(0);
  const [offsetPasts] = React.useState(0);
  const [openUpcomingFilters, setOpenUpcomingFilters] = React.useState(false);
  const [openPastFilters, setOpenPastFilters] = React.useState(false);
  const CURRENT_DATE = new Date();
  const DATE_PLUS_6_M = new Date().setMonth(CURRENT_DATE.getMonth() + 6);
  const DATE_MIN_6_M = new Date().setMonth(CURRENT_DATE.getMonth() - 6);

  const { watch, setValue } = useForm({
    defaultValues: {
      upcoming_city: UNDEFINED_AS_ANY,
      upcoming_country: COUNTRIES?.find((c: any) => c?.value === appContext?.currentPosition?.country?.code) || INITIAL_COUNTRY,
      upcoming_sport_types: ARRAY_AS_ANY,
      upcoming_start_date: CURRENT_DATE,
      upcoming_end_date: new Date(DATE_PLUS_6_M),
      upcoming_complex: UNDEFINED_AS_ANY,
      upcoming_field: '',

      past_city: UNDEFINED_AS_ANY,
      past_country: COUNTRIES?.find((c: any) => c?.value === appContext?.currentPosition?.country?.code) || INITIAL_COUNTRY,
      past_sport_types: ARRAY_AS_ANY,
      past_start_date: CURRENT_DATE,
      past_end_date: new Date(DATE_MIN_6_M),
      past_complex: UNDEFINED_AS_ANY,
      past_field: '',
    },
  });
  const inputFields = watch();

  const loadedUpcomings = useQuery(FIND_TOURNAMENTS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      tournament: {
        offset: offsetUpcomings,
        sport_types: inputFields.upcoming_sport_types,
        city: inputFields.upcoming_city?.name ? _.deburr(inputFields.upcoming_city?.name) : undefined,
        country: inputFields.upcoming_country?.value,
        continent: inputFields.upcoming_country?.continent,
        start_date: TO_TIMESTAMP(inputFields.upcoming_start_date?.toString()),
        end_date: TO_TIMESTAMP(inputFields.upcoming_end_date?.toString()),
        complex_id: inputFields?.upcoming_complex ? inputFields.upcoming_complex?.id : undefined,
        field_id: inputFields?.upcoming_field ? inputFields.upcoming_field : undefined,
      },
    },
  });

  const loadedPasts = useQuery(FIND_TOURNAMENTS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      tournament: {
        offset: offsetPasts,
        sport_types: inputFields.past_sport_types,
        city: inputFields.past_city?.name ? _.deburr(inputFields.past_city?.name) : undefined,
        country: inputFields.past_country?.value,
        continent: inputFields.past_country?.continent,
        start_date: parseFloat(TO_TIMESTAMP(inputFields.past_start_date)?.toString()),
        end_date: parseFloat(TO_TIMESTAMP(inputFields.past_end_date)?.toString()),
        complex_id: inputFields?.past_complex ? inputFields.past_complex?.id : undefined,
        field_id: inputFields?.past_field ? inputFields.past_field : undefined,
      },
    },
  });

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValueIndex(newValue);
    handleChangeIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValueIndex(index);
  };

  const handleRefetchUpcomings = async () => {
    try {
      const result = await loadedUpcomings.refetch({
        tournament: {
          offset: offsetUpcomings,
          sport_types: inputFields.upcoming_sport_types,
          city: inputFields.upcoming_city?.name ? _.deburr(inputFields.upcoming_city?.name) : undefined,
          country: inputFields.upcoming_country?.value,
          continent: inputFields.upcoming_country?.continent,
          start_date: inputFields.upcoming_start_date ? TO_TIMESTAMP(inputFields.upcoming_start_date?.toString()) : undefined,
          end_date: inputFields.upcoming_end_date ? TO_TIMESTAMP(inputFields.upcoming_end_date?.toString()) : undefined,
          complex_id: inputFields?.upcoming_complex ? inputFields.upcoming_complex?.id : undefined,
          field_id: inputFields?.upcoming_field ? inputFields.upcoming_field : undefined,
        },
      });

      if (result) {
        const newList = result.data?.findTournamentsByOptions;
        setUpcomings(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefetchPasts = async () => {
    try {
      const result = await loadedPasts.refetch({
        challenge: {
          offset: offsetPasts,
          sport_types: inputFields.past_sport_types,
          city: inputFields.past_city?.name ? _.deburr(inputFields.past_city?.name) : undefined,
          country: inputFields.past_country?.value,
          continent: inputFields.past_country?.continent,
          start_date: inputFields.past_start_date ? TO_TIMESTAMP(inputFields.past_start_date?.toString()) : undefined,
          end_date: inputFields.past_end_date ? TO_TIMESTAMP(inputFields.past_end_date?.toString()) : undefined,
          complex_id: inputFields?.past_complex ? inputFields.past_complex?.id : undefined,
          field_id: inputFields?.past_field ? inputFields.past_field : undefined,
        },
      });

      if (result) {
        const newList = result.data?.findTournamentsByOptions;
        setPasts(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenChallengeFilters = () => {
    setOpenUpcomingFilters(true);
  };

  const handleCloseUpcomingFilters = async () => {
    setOpenUpcomingFilters(false);
    await handleRefetchUpcomings();
  };

  const handleOpenOpponentFilters = () => {
    setOpenPastFilters(true);
  };

  const handleClosePastFilters = async () => {
    setOpenPastFilters(false);
    await handleRefetchPasts();
  };

  const handleLoadMoreChallenges = async () => {
    try {
      const current_upcomings = upcomings;

      const result = await loadedUpcomings.refetch({
        tournament: {
          offset: offsetUpcomings + upcomings?.length,
          sport_types: inputFields.upcoming_sport_types,
          city: inputFields.upcoming_city?.name ? _.deburr(inputFields.upcoming_city?.name) : undefined,
          country: inputFields.upcoming_country?.value,
          continent: inputFields.upcoming_country?.continent,
          start_date: inputFields.upcoming_start_date ? TO_TIMESTAMP(inputFields.upcoming_start_date?.toString()) : undefined,
          end_date: inputFields.upcoming_end_date ? TO_TIMESTAMP(inputFields.upcoming_end_date?.toString()) : undefined,
          complex_id: inputFields?.upcoming_complex ? inputFields.upcoming_complex?.id : undefined,
          field_id: inputFields?.upcoming_field ? inputFields.upcoming_field : undefined,
        },
      });

      if (result) {
        const newList = _.uniqBy(_.concat(current_upcomings, result.data?.findTournamentsByOptions), 'id');
        setUpcomings(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoadMoreOpponents = async () => {
    try {
      const current_pasts = pasts;

      const result = await loadedPasts.refetch({
        challenge: {
          offset: offsetPasts + pasts?.length,
          sport_types: inputFields.past_sport_types,
          city: inputFields.past_city?.name ? _.deburr(inputFields.past_city?.name) : undefined,
          country: inputFields.past_country?.value,
          continent: inputFields.past_country?.continent,
          start_date: inputFields.past_start_date ? TO_TIMESTAMP(inputFields.past_start_date?.toString()) : undefined,
          end_date: inputFields.past_end_date ? TO_TIMESTAMP(inputFields.past_end_date?.toString()) : undefined,
          complex_id: inputFields?.past_complex ? inputFields.past_complex?.id : undefined,
          field_id: inputFields?.past_field ? inputFields.past_field : undefined,
        },
      });

      if (result) {
        const newList = _.uniqBy(_.concat(current_pasts, result.data?.findTournamentsByOptions), 'id');
        setPasts(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!upcomings && loadedUpcomings.called && loadedUpcomings.data?.findTournamentsByOptions) {
      setUpcomings(loadedUpcomings.data?.findTournamentsByOptions);
    }

    if (!pasts && loadedPasts.called && loadedPasts.data?.findTournamentsByOptions) {
      setPasts(loadedPasts.data?.findTournamentsByOptions);
    }
  }, [pasts, loadedPasts, setPasts, upcomings, loadedUpcomings, setUpcomings]);

  return (
    <Grid className={`component-team team-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ p: '0 0 150px', minHeight: '100%' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'Tournaments_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid container>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}></Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ position: 'relative', zIndex: '10000', display: 'inline-flex', alignItems: 'center', justifyContent: 'right', p: '20px 43px' }}>
          <TopBarActionsButton />
          <MenuButton />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`}>
        <AppBar position="sticky" sx={{ mt: '0px' }}>
          <Tabs value={value} onChange={handleChange} scrollButtons="auto" aria-label="full width tabs example" variant={'fullWidth'} centered>
            <Tab
              label={`${intl.formatMessage({ id: 'Tournaments_New_Tournaments_Tab' })}`}
              icon={<ChallengeIcon />}
              {...a11yProps(0)}
              style={{
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            />
            <Tab
              label={`${intl.formatMessage({ id: 'Tournaments_Old_Tournaments_Tab' })}`}
              icon={<HistoryIcon />}
              {...a11yProps(1)}
              style={{
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={'ltr'}>
            {(loadedUpcomings.error && loadedUpcomings.called) || loadedUpcomings.loading ? (
              <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
            ) : (
              <Grid container className={'list-challenges'} sx={{ p: { xs: '20px 5% 150px', md: '20px 5% 150px', alignItems: 'center' } }}>
                <IconButton onClick={handleOpenChallengeFilters}>
                  <FiltersIcon />
                </IconButton>
                &nbsp;&nbsp;
                <Typography sx={{ 'display': 'inline-flex', 'alignItems': 'baseline', 'fontSize': { xs: '1.1em', sm: '2em' }, 'color': 'rgba(247, 251, 250, 1) !important', 'fontWeight': '800', '& img': { width: 'auto', height: { xs: '20px', sm: '20px', md: '20px', xl: '20px' } } }}>
                  {inputFields.upcoming_country?.label}
                  {inputFields.upcoming_city?.name ? <>,&nbsp;{inputFields.upcoming_city?.name}</> : ''}
                  &nbsp;
                  <img loading="lazy" width="20" src={`https://flagcdn.com/w160/${inputFields.upcoming_country?.value?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w320/${inputFields.upcoming_country?.value?.toLowerCase()}.png 2x`} alt="" />
                </Typography>
                <TournamentFiltersModal complex={inputFields.upcoming_complex} field={inputFields.upcoming_field} start_date={inputFields.upcoming_start_date} end_date={inputFields.upcoming_end_date} country={inputFields.upcoming_country} city={inputFields.upcoming_city} setter={setValue} setter_prefix="upcoming_" open={openUpcomingFilters} handleClose={handleCloseUpcomingFilters} />
                <Grid container>
                  {upcomings !== undefined && upcomings?.length > 0 ? (
                    <>
                      {upcomings?.map((tournament: any) => (
                        <Grid key={tournament?.id} item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ p: { xs: '20px 10px 0', sm: '20px 30px 0' }, display: 'inline-flex', alignItems: 'center' }}>
                          <Card
                            className={'card'}
                            sx={{
                              bgcolor: 'rgba(247, 251, 250, .1)',
                              p: '0px',
                              width: '100%',
                              borderRadius: '20px',
                              m: '0 auto 30px',
                            }}
                          >
                            <CardMedia
                              sx={{
                                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.3) 0%,rgba(0,0,0,.9) 50%), url(${tournament?.phase_playoffs?.find((p: any) => p?.game?.arena?.pictures?.length > 0)?.game?.arena?.pictures[0]?.path})`,
                              }}
                            >
                              <CardContent className={'content'} sx={{ textAlign: 'center', p: '60px 0 !important' }}>
                                <Typography
                                  component={Link}
                                  to={`/tournament/${tournament?.short_id}`}
                                  sx={{
                                    'fontWeight': '800',
                                    'fontSize': '1.9em',
                                    'lineHeight': '20px',
                                    'textDecoration': 'none',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    '&:hover': {
                                      color: 'rgba(251, 88, 2, 1)',
                                    },
                                  }}
                                >
                                  {tournament?.title}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: '600',
                                    fontSize: '.8em',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {tournament?.sport?.replaceAll('_', ' ')}
                                  <br />
                                  <Grid container sx={{ justifyContent: 'center' }}>
                                    <Moment format="D MMM YYYY">{tournament?.start_date}</Moment>
                                    {tournament?.start_date + ONE_DAY_MILLISEC > tournament?.end_date ? (
                                      <>
                                        &nbsp;[<Moment format="HH:mm">{tournament?.start_date}</Moment>
                                        &nbsp;-&gt;&nbsp;
                                        <Moment format="HH:mm">{tournament?.end_date}</Moment>]
                                      </>
                                    ) : (
                                      <>
                                        &nbsp;-&nbsp;
                                        <Moment format="D MMM YYYY">{tournament?.end_date}</Moment>
                                      </>
                                    )}
                                  </Grid>
                                </Typography>
                                <Grid
                                  container
                                  sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: '10px 0',
                                  }}
                                >
                                  <Typography variant="caption" className={'owners'} sx={{ pr: '5px', color: 'rgba(247, 251, 250, 1)' }}>
                                    <FormattedMessage id="Tournament_By" />
                                  </Typography>
                                  <AvatarGroup max={10} className={'owner-avatars'} sx={{ display: 'inline-flex' }}>
                                    {tournament?.organisators?.map((owner: any, index: number) => (
                                      <Avatar
                                        key={index}
                                        alt={owner.username}
                                        src={owner.avatar.path}
                                        component={Link}
                                        to={`/u/${owner.username}`}
                                        sx={{
                                          width: '24px',
                                          height: '24px',
                                          bgcolor: 'rgba(255, 255, 255, 1)',
                                          borderWidth: '1px !important',
                                        }}
                                      />
                                    ))}
                                  </AvatarGroup>
                                </Grid>

                                {tournament?.team_candidates?.length === 0 ? (
                                  ''
                                ) : (
                                  <Grid item xl={12} xs={12} sm={12} className={'primary-info'} sx={{ position: 'relative', textAlign: 'center' }}>
                                    <Typography sx={{ width: '100%', textTransform: 'uppercase', textAlign: 'center' }}>
                                      {tournament?.team_candidates?.length}&nbsp;{tournament?.team_candidates?.length > 1 ? `${intl.formatMessage({ id: 'Tournaments_Candidates' })}` : `${intl.formatMessage({ id: 'Tournaments_Candidate' })}`}
                                    </Typography>
                                    <Grid container sx={{ justifyContent: 'center' }}>
                                      <AvatarGroup max={9}>
                                        {tournament?.team_candidates?.map((p: any, index: number) => (
                                          <Tooltip key={index} title={p?.name} sx={{ display: 'block' }}>
                                            <Avatar alt={p?.name} src={p?.team?.avatar?.path} sx={{ m: 'auto !important' }} />
                                          </Tooltip>
                                        ))}
                                      </AvatarGroup>
                                    </Grid>
                                  </Grid>
                                )}
                              </CardContent>
                            </CardMedia>
                          </Card>
                        </Grid>
                      ))}
                      <Grid container sx={{ justifyContent: 'center' }}>
                        {loadedUpcomings.data?.findTournamentsByOptions?.length === 0 ? (
                          ''
                        ) : (
                          <Fab className="button-green-reverse" size="small" variant="extended" sx={{ boxShadow: 'none' }} onClick={handleLoadMoreChallenges}>
                            <Add />
                            &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Load_More' })}`}
                          </Fab>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <Grid sx={{ textAlign: 'center', pt: '20px', minHeight: '40vh', width: '100%' }}>
                      {loadedUpcomings.error ? (
                        <Typography sx={{ fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>{`${intl.formatMessage({ id: 'Tournaments_No_Tournaments_Found' })}`}</Typography>
                      ) : (
                        <Typography sx={{ fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>
                          {`${intl.formatMessage({ id: 'Tournaments_No_Tournament_In' })}`} {inputFields.upcoming_city?.name ? inputFields.upcoming_city?.name : inputFields.upcoming_country?.label} {`${intl.formatMessage({ id: 'Tournaments_No_Tournament_Yet' })}`}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </TabPanel>

          <TabPanel value={value} index={1} dir={'ltr'}>
            {(loadedPasts.error && loadedPasts.called) || loadedPasts.loading ? (
              <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
            ) : (
              <Grid container className={'list-opponents'} sx={{ p: { xs: '20px 5% 150px', sm: '20px 5% 150px', alignItems: 'center' } }}>
                <IconButton onClick={handleOpenOpponentFilters}>
                  <FiltersIcon />
                </IconButton>
                &nbsp; &nbsp;
                <Typography sx={{ 'display': 'inline-flex', 'alignItems': 'baseline', 'fontSize': { xs: '1.1em', sm: '2em' }, 'color': 'rgba(247, 251, 250, 1) !important', 'fontWeight': '800', '& img': { width: 'auto', height: { xs: '20px', sm: '20px', md: '20px', xl: '20px' } } }}>
                  {inputFields.past_country?.label}
                  {inputFields.past_city?.name ? <>,&nbsp;{inputFields.past_city?.name}</> : ''}
                  &nbsp;
                  <img loading="lazy" width="20" src={`https://flagcdn.com/w160/${inputFields.past_country?.value?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w320/${inputFields.past_country?.value?.toLowerCase()}.png 2x`} alt="" />
                </Typography>
                <TournamentFiltersModal complex={inputFields.past_complex} field={inputFields.past_field} start_date={inputFields.past_start_date} end_date={inputFields.past_end_date} country={inputFields.past_country} city={inputFields.past_city} setter={setValue} setter_prefix="past_" open={openPastFilters} handleClose={handleClosePastFilters} />
                <Grid container>
                  {pasts !== undefined && pasts?.length > 0 ? (
                    <>
                      {pasts?.map((tournament: any) => (
                        <Grid key={tournament?.id} item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ p: { xs: '20px 10px 0', sm: '20px 30px 0' }, display: 'inline-flex', alignItems: 'center' }}>
                          <Card
                            className={'card'}
                            sx={{
                              bgcolor: 'rgba(247, 251, 250, .1)',
                              p: '0px',
                              width: '100%',
                              borderRadius: '20px',
                              m: '0 auto 30px',
                            }}
                          >
                            <CardMedia
                              sx={{
                                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.3) 0%,rgba(0,0,0,.9) 50%), url(${tournament?.phase_playoffs?.find((p: any) => p?.game?.arena?.pictures?.length > 0)?.game?.arena?.pictures[0]?.path})`,
                              }}
                            >
                              <CardContent className={'content'} sx={{ textAlign: 'center', p: '60px 0 !important' }}>
                                <Typography
                                  component={Link}
                                  to={`/tournament/${tournament?.short_id}`}
                                  sx={{
                                    'fontWeight': '800',
                                    'fontSize': '1.9em',
                                    'lineHeight': '20px',
                                    'textDecoration': 'none',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    '&:hover': {
                                      color: 'rgba(251, 88, 2, 1)',
                                    },
                                  }}
                                >
                                  {tournament?.title}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: '600',
                                    fontSize: '.8em',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {tournament?.sport?.replaceAll('_', ' ')}
                                  <br />
                                  <Grid container sx={{ justifyContent: 'center' }}>
                                    <Moment format="D MMM YYYY">{tournament?.start_date}</Moment>
                                    {tournament?.start_date + ONE_DAY_MILLISEC > tournament?.end_date ? (
                                      <>
                                        &nbsp;[<Moment format="HH:mm">{tournament?.start_date}</Moment>
                                        &nbsp;-&gt;&nbsp;
                                        <Moment format="HH:mm">{tournament?.end_date}</Moment>]
                                      </>
                                    ) : (
                                      <>
                                        &nbsp;-&nbsp;
                                        <Moment format="D MMM YYYY">{tournament?.end_date}</Moment>
                                      </>
                                    )}
                                  </Grid>
                                </Typography>
                                <Grid
                                  container
                                  sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: '10px 0',
                                  }}
                                >
                                  <Typography variant="caption" className={'owners'} sx={{ pr: '5px', color: 'rgba(247, 251, 250, 1)' }}>
                                    <FormattedMessage id="Tournament_By" />
                                  </Typography>
                                  <AvatarGroup max={10} className={'owner-avatars'} sx={{ display: 'inline-flex' }}>
                                    {tournament?.organisators?.map((owner: any, index: number) => (
                                      <Avatar
                                        key={index}
                                        alt={owner.username}
                                        src={owner.avatar.path}
                                        component={Link}
                                        to={`/u/${owner.username}`}
                                        sx={{
                                          width: '24px',
                                          height: '24px',
                                          bgcolor: 'rgba(255, 255, 255, 1)',
                                          borderWidth: '1px !important',
                                        }}
                                      />
                                    ))}
                                  </AvatarGroup>
                                </Grid>

                                {tournament?.team_candidates?.length === 0 ? (
                                  ''
                                ) : (
                                  <Grid item xl={12} xs={12} sm={12} className={'primary-info'} sx={{ position: 'relative', textAlign: 'center' }}>
                                    <Typography sx={{ width: '100%', textTransform: 'uppercase', textAlign: 'center' }}>
                                      {tournament?.team_candidates?.length}&nbsp;{tournament?.team_candidates?.length > 1 ? `${intl.formatMessage({ id: 'Tournaments_Candidates' })}` : `${intl.formatMessage({ id: 'Tournaments_Candidate' })}`}
                                    </Typography>
                                    <Grid container sx={{ justifyContent: 'center' }}>
                                      <AvatarGroup max={9}>
                                        {tournament?.team_candidates?.map((p: any, index: number) => (
                                          <Tooltip key={index} title={p?.name} sx={{ display: 'block' }}>
                                            <Avatar alt={p?.name} src={p?.team?.avatar?.path} sx={{ m: 'auto !important' }} />
                                          </Tooltip>
                                        ))}
                                      </AvatarGroup>
                                    </Grid>
                                  </Grid>
                                )}
                              </CardContent>
                            </CardMedia>
                          </Card>
                        </Grid>
                      ))}
                      <Grid container sx={{ justifyContent: 'center' }}>
                        {loadedPasts.data?.findTournamentsByOptions?.length === 0 ? (
                          ''
                        ) : (
                          <Fab className="button-green-reverse" size="small" variant="extended" sx={{ boxShadow: 'none' }} onClick={handleLoadMoreOpponents}>
                            <Add />
                            &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Load_More' })}`}
                          </Fab>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <Grid sx={{ minHeight: '40vh', textAlign: 'center', width: '100%' }}>
                      {loadedPasts.error ? (
                        <Typography sx={{ fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>{`${intl.formatMessage({ id: 'Tournaments_No_Tournaments_Found' })}`}</Typography>
                      ) : (
                        <Typography sx={{ fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>
                          {`${intl.formatMessage({ id: 'Tournaments_No_Tournament_In' })}`} {inputFields.past_city?.name ? inputFields.past_city?.name : inputFields.past_country?.label} {`${intl.formatMessage({ id: 'Tournaments_No_Tournament_Yet' })}`}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default Tournaments;
