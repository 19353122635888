import React from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { useQuery, useMutation, gql } from '@apollo/client';
import _ from 'lodash';
import { Avatar, Card, CardHeader, CardActions, Typography, Grid, Fab, CircularProgress, Divider } from '@mui/material';
import { Add } from '@mui/icons-material';

import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

export default function ListNotifications(props: { dark_mode: boolean; receiver_id?: string }) {
  try {
    const FIND_NOTIFICATIONS_QUERY = gql`
      query findNotificationsByReceiverID($receiver: inputFindNotificationsByReceiverID!) {
        findNotificationsByReceiverID(receiver: $receiver) {
          id
          title
          status
          icon
          content
          actions
          created_at
          received_at
          receivers {
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
          }
          sender {
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Company {
              __typename
              # id
              # name
              # label
              # logo {
              #   path
              # }
            }
          }
        }
      }
    `;

    const HANDLE_NOTIFICATION_MUTATION = gql`
      mutation handleNotification($notification_id: ID!) {
        handleNotification(notification_id: $notification_id)
      }
    `;

    const CONFIRM_FLEX_CO_PUBLISH_MUTATION = gql`
      mutation confirmCoFlex($flex: inputConfirmCoFlex!) {
        confirmCoFlex(flex: $flex)
      }
    `;

    const ACCEPT_NEW_TEAM_PLAYER_BY_TEAM_LINEUP_MUTATION = gql`
      mutation acceptNewTeamPlayerByTeamLineup($team_lineup: inputAcceptNewTeamPlayerByTeamLineup!) {
        acceptNewTeamPlayerByTeamLineup(team_lineup: $team_lineup)
      }
    `;

    const ADD_SPORT_PLAYERS_TO_SPORT_TEAM_MUTATION = gql`
      mutation addSportPlayersToSportTeam($team: inputAddSportPlayersToSportTeam!) {
        addSportPlayersToSportTeam(team: $team)
      }
    `;

    const navigate = useNavigate();
    const session = useAppSelector(selectSession);
    const loggedPlayer = useAppSelector(selectPlayer);
    const intl = useIntl();

    const [notifications, setNotifications] = React.useState(UNDEFINED_AS_ANY);
    const [offset, setOffset] = React.useState(0);

    const [handleNotification, isHandled] = useMutation(HANDLE_NOTIFICATION_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [confirmCoFlex, hasConfirmed] = useMutation(CONFIRM_FLEX_CO_PUBLISH_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [acceptNewTeamPlayerByTeamLineup, updatedTeamLineup] = useMutation(ACCEPT_NEW_TEAM_PLAYER_BY_TEAM_LINEUP_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [addSportPlayersToSportTeam, newTeamPlayers] = useMutation(ADD_SPORT_PLAYERS_TO_SPORT_TEAM_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });

    const loadedNotifications = useQuery(FIND_NOTIFICATIONS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key || (!props.receiver_id && !loggedPlayer?.id),
      variables: {
        receiver: {
          id: props.receiver_id || loggedPlayer?.id,
          offset: offset,
        },
      },
      pollInterval: loggedPlayer?.id ? 3000 : undefined,
    });

    const handleLoadMoreNotifications = async () => {
      try {
        const result = await loadedNotifications.refetch({
          receiver: {
            id: props.receiver_id || loggedPlayer?.id,
            offset: offset,
          },
        });
        const newList = _.concat(notifications, result.data?.findNotificationsByReceiverID);
        setNotifications(newList);
        setOffset(offset + result.data?.findNotificationsByReceiverID?.length);
      } catch (e) {
        console.log(e);
      }
    };

    const handleNotificationStatus = async (notification_id: string) => {
      try {
        const result = await handleNotification({
          variables: {
            notification_id: notification_id,
          },
        });

        if (result) {
          const index = _.findIndex(notifications, { id: notification_id });
          const updated_notification = _.merge(
            _.omit(
              notifications.find((n: any) => n?.id === notification_id),
              'status'
            ),
            { status: 'HANDLED' }
          );
          const newList = _.concat(
            _.slice(
              notifications.filter((n: any) => n?.id !== notification_id),
              0,
              index
            ),
            updated_notification,
            _.slice(
              notifications.filter((n: any) => n?.id !== notification_id),
              index
            )
          );
          setNotifications(newList);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleRejectConnection = async (notification_id: string) => {
      try {
        await handleNotificationStatus(notification_id);
      } catch (e) {
        console.error(e);
      }
    };

    const handleAcceptCoPublishing = async (notification_id: string, flex_id: string, co_author_id: string) => {
      try {
        await confirmCoFlex({
          variables: {
            flex: {
              id: flex_id,
              co_author_id: co_author_id,
            },
          },
        });
        await handleNotificationStatus(notification_id);
      } catch (e) {
        console.error(e);
      }
    };

    const handleAcceptNewTeamLinePlayer = async (notification_id: string, profile_id: string, teamlineup_id: string) => {
      try {
        await acceptNewTeamPlayerByTeamLineup({
          variables: {
            team_lineup: {
              id: teamlineup_id,
              profile_id: profile_id,
            },
          },
        });
        await handleNotificationStatus(notification_id);
      } catch (e) {
        console.error(e);
      }
    };

    const handleRejectNewTeamLinePlayer = async (notification_id: string) => {
      try {
        await handleNotificationStatus(notification_id);
      } catch (e) {
        console.error(e);
      }
    };

    const handleAcceptNewTeamPlayer = async (notification_id: string, player_id: string, team_id: string) => {
      try {
        await addSportPlayersToSportTeam({
          variables: {
            team: {
              new_sport_players_ids: [player_id],
              id: team_id,
              team_sport: 'FootballTeam',
            },
          },
        });
        await handleNotificationStatus(notification_id);
      } catch (e) {
        console.log(e);
      }
    };

    const handleRejectNewTeamPlayer = async (notification_id: string) => {
      try {
        await handleNotificationStatus(notification_id);
      } catch (e) {
        console.error(e);
      }
    };

    const handleAction = async (action: string, notification_id: string) => {
      if (_.split(action, '_@_', 2)[0] === 'VIEW_REQUEST_MATCHMAKING') {
        navigate(`/r/${_.split(action, '_@_', 2)[1]}`);
      }

      if (_.split(action, '_@_', 2)[0] === 'VIEW_TOURNAMENT') {
        navigate(`/tournament/${_.split(action, '_@_', 2)[1]}`);
      }

      if (_.split(action, '_@_', 2)[0] === 'REJECT_CONNECTION') {
        handleRejectConnection(notification_id);
      }

      if (_.split(action, '_@_', 2)[0] === 'VIEW_COMPANY') {
        navigate(`/${_.split(action, '_@_', 2)[1]}`);
      }

      if (_.split(action, '_@_', 2)[0] === 'ACCEPT_FLEX_CO_PUBLISH') {
        handleAcceptCoPublishing(notification_id, _.split(_.split(action, '_@_', 2)[1], '_&_', 2)[0], _.split(_.split(action, '_@_', 2)[1], '_&_', 2)[1]);
      }

      if (_.split(action, '_@_', 2)[0] === 'VIEW_PLAYER_PROFILE') {
        navigate(`/u/${_.split(action, '_@_', 2)[1]}`);
      }

      if (_.split(action, '_@_', 2)[0] === 'VIEW_FOOTBALL_TEAM_PROFILE') {
        navigate(`/football_team/${_.split(action, '_@_', 2)[1]}`);
      }

      if (_.split(action, '_@_', 2)[0] === 'VIEW_CHALLENGE') {
        navigate(`/challenge/${_.split(action, '_@_', 2)[1]}`);
      }

      if (_.split(action, '_@_', 2)[0] === 'ACCEPT_NEW_TEAM_LINEUP_PLAYER') {
        handleAcceptNewTeamLinePlayer(notification_id, _.split(_.split(action, '_@_', 2)[1], '_&_', 2)[0], _.split(_.split(action, '_@_', 2)[1], '_&_', 2)[1]);
      }

      if (_.split(action, '_@_', 2)[0] === 'DECLINE_NEW_TEAM_LINEUP_PLAYER') {
        handleRejectNewTeamLinePlayer(notification_id);
      }

      if (_.split(action, '_@_', 2)[0] === 'ACCEPT_NEW_TEAM_PLAYER') {
        handleAcceptNewTeamPlayer(notification_id, _.split(_.split(action, '_@_', 2)[1], '_&_', 2)[0], _.split(_.split(action, '_@_', 2)[1], '_&_', 2)[1]);
      }

      if (_.split(action, '_@_', 2)[0] === 'DECLINE_NEW_TEAM_PLAYER') {
        handleRejectNewTeamPlayer(notification_id);
      }
    };

    React.useEffect(() => {
      if (!notifications && loadedNotifications.data?.findNotificationsByReceiverID && loadedNotifications.data?.findNotificationsByReceiverID?.length > 0) {
        setNotifications(loadedNotifications.data?.findNotificationsByReceiverID);
      }

      if (offset === 0 && notifications?.length > 1) {
        setOffset(notifications?.length);
      }
    }, [notifications, loadedNotifications, offset, setOffset, setNotifications]);

    // if (loadedNotifications.error) return <Error content={loadedNotifications.error} status={400} />;
    if (loadedNotifications.loading)
      return (
        <LoadingComponent
          amount={1}
          text={
            <Typography variant={'h6'} sx={{ textAlign: 'center', fontWeight: '100', color: 'rgba(168, 202, 65, .3)' }}>
              <CircularProgress className="green-loader" size={15} />
              &nbsp;&nbsp;{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
            </Typography>
          }
        />
      );

    return (
      <>
        <Grid container className={'component-preview company-notifications'}>
          <Grid item xl={12} xs={12} sm={12}>
            {notifications?.length === 0 || notifications === undefined ? (
              <Typography
                variant="h3"
                sx={{
                  p: '50px 20px 0',
                  fontSize: '1.3em',
                  textAlign: 'center',
                  color: 'rgba(247, 251, 250, 0.3) !important',
                }}
              >
                {`${intl.formatMessage({ id: 'Global_Button_No_Notifications' })}`}
              </Typography>
            ) : (
              <Grid container className={'links'} sx={{ justifyContent: 'center', mt: '0px' }}>
                {isHandled.loading || newTeamPlayers.loading || hasConfirmed.loading ? (
                  <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress className="green-loader" size={15} />
                    &nbsp;&nbsp;
                    <Typography variant="caption" sx={{ color: 'rgba(168, 202, 65, 0.3) !important' }}>
                      {`${intl.formatMessage({ id: 'Global_Button_Loading' })}`}
                    </Typography>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xl={12} xs={12} sm={12} sx={{ pt: '20px' }}>
                  {notifications?.map((msg: any, index: any) => (
                    <Card
                      key={index}
                      sx={{
                        bgcolor: 'transparent !important',
                        p: '5px',
                        width: '85%',
                        borderRadius: '5px',
                        margin: '0 auto 0px',
                        boxShadow: 'none',
                        textAlign: 'left',
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar src={msg?.sender?.avatar?.path || msg?.receiver?.avatar?.path || msg?.receiver?.logo?.path} alt={msg?.id} />
                          // _.split(msg?.actions[0], '_@_', 2)[0].includes('CONNECTION') ? <ConnectWithoutContact sx={{ color: 'rgba(247, 251, 250, .6)' }} /> : <Info sx={{ color: 'rgba(247, 251, 250, .6)' }} />
                        }
                        // action={
                        //   <IconButton aria-label="settings">
                        //     <MoreVert />
                        //   </IconButton>
                        // }
                        title={
                          <Grid>
                            {msg?.title}
                            &nbsp;&nbsp;&nbsp;
                            <Typography variant="caption">{msg?.created_at ? <Moment fromNow>{msg?.created_at}</Moment> : ''}</Typography>
                          </Grid>
                        }
                        subheader={msg?.content}
                        titleTypographyProps={{
                          color: 'rgba(247, 251, 250, 1)',
                          fontWeight: '700',
                        }}
                        subheaderTypographyProps={{
                          color: 'rgba(247, 251, 250, .8)',
                        }}
                      />

                      <CardActions sx={{ display: msg?.actions?.length >= 3 ? 'inline-block' : 'inline-flex', justifyContent: 'start', p: '0 10% 10px' }}>
                        {msg?.actions
                          ?.filter((a: any) => !(msg?.status === 'HANDLED' && !_.split(a, '_@_', 2)[0].includes('VIEW')))
                          ?.map((action: string, index: number) => (
                            <Fab
                              variant="extended"
                              size="small"
                              className={
                                msg?.status === 'HANDLED' && !_.split(action, '_@_', 2)[0].includes('VIEW')
                                  ? 'button-disabled'
                                  : '' || _.split(action, '_@_', 2)[0].includes('ACCEPT')
                                  ? 'button-green-reverse'
                                  : '' || _.split(action, '_@_', 2)[0].includes('REJECT')
                                  ? 'button-red-reversed'
                                  : '' || _.split(action, '_@_', 2)[0].includes('DECLINE')
                                  ? 'button-red-reversed'
                                  : '' || (!_.split(action, '_@_', 2)[0].includes('REJECT') && !_.split(action, '_@_', 2)[0].includes('ACCEPT'))
                                  ? `${props.dark_mode ? 'button-cancel' : 'button-cancel'}`
                                  : ''
                              }
                              disabled={updatedTeamLineup.loading || (msg?.status === 'HANDLED' && !_.split(action, '_@_', 2)[0].includes('VIEW'))}
                              sx={{
                                fontSize: '.7em',
                                color: 'rgba(247, 251, 250, .1)',
                                bgcolor: 'transparent',
                                boxShadow: 'none',
                                m: '0 5px 10px',
                                border: 'none !important',
                              }}
                              key={index}
                              onClick={() => handleAction(action, msg?.id)}
                            >
                              {`${intl.formatMessage({ id: _.capitalize(_.split(_.split(action, '_@_', 2)[0], '_')[0]) })}`}
                              {_.split(action, '_@_', 2)[0]
                                ?.replaceAll('_', ' ')
                                ?.replace(_.split(_.split(action, '_@_', 2)[0], '_')[0], '')}
                            </Fab>
                          ))}
                      </CardActions>

                      <Divider sx={{ bgcolor: 'rgba(247, 251, 250, .1)' }} />
                    </Card>
                  ))}
                </Grid>

                {loadedNotifications.data?.findNotificationsByReceiverID?.length === 0 ? (
                  ''
                ) : (
                  <Fab
                    sx={{
                      'color': 'rgba(247, 251, 250, 1)',
                      'bgcolor': 'transparent',
                      'boxShadow': 'none',
                      ':hover': {
                        bgcolor: 'transparent',
                        color: 'rgba(168, 202, 65, 1)',
                      },
                    }}
                    variant="extended"
                    onClick={handleLoadMoreNotifications}
                  >
                    {updatedTeamLineup.loading || loadedNotifications.loading || isHandled.loading || newTeamPlayers.loading || hasConfirmed.loading ? (
                      <Grid container sx={{ alignItems: 'center' }}>
                        <CircularProgress className="green-loader" size={15} />
                        &nbsp;&nbsp;{`${intl.formatMessage({ id: 'Global_Button_Loading' })}`}
                      </Grid>
                    ) : (
                      <Grid container sx={{ alignItems: 'center' }}>
                        <Add sx={{ color: 'rgba(168, 202, 65, 1)' }} />
                        &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Load_More' })}`}
                      </Grid>
                    )}
                  </Fab>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
}
