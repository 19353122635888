import React from 'react';
import { CardMedia, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { FIELD_SIZES, FIELD_TYPES, SPORT_TYPES } from '../../utilities/utilities';
import { useIntl } from 'react-intl';

interface IListPublishedFields {
  fields: any[];
}

const ListPublishedFields: React.FC<IListPublishedFields> = (props: IListPublishedFields) => {
  const intl = useIntl();

  return (
    <Grid container id="max-width" className={'component-history-player'}>
      {!props.fields || props.fields?.length === 0 ? (
        !props.fields ? (
          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
        ) : (
          <Typography
            variant="h3"
            sx={{
              p: '50px 100px 0',
              fontSize: '1.3em',
              textAlign: 'center',
              color: 'rgba(247, 251, 250, 0.3)',
              display: 'inline-block',
              width: '100%',
              minHeight: '20vh',
            }}
          >
            {`${intl.formatMessage({ id: 'Global_No_Fields' })}`}
          </Typography>
        )
      ) : (
        <Stack direction="column" sx={{ width: '100%', p: { xs: '0 5%', sm: '0' } }}>
          {[...props.fields]?.reverse()?.map((p: any, index: number) => (
            <CardMedia
              key={index}
              sx={{
                display: { xs: 'inline-block', sm: 'inline-flex' },
                justifyContent: 'start',
                alignItems: 'center',
                m: '0 0 30px',
                p: '35px 30px',
                borderRadius: '10px',
                width: '100%',
                bgcolor: 'rgba(247, 251, 250, .05)',
                backgroundImage: p?.pictures[0]?.path ? `linear-gradient(to bottom, rgba(15, 15, 15 , .6) 0%,rgba(15, 15, 15 , 1) 95%), url(${p?.pictures[0]?.path})` : '',
              }}
            >
              <Grid sx={{ textAlign: { xs: 'center', sm: 'left' }, pl: '10px', width: '100%' }}>
                <Typography
                  component={Link}
                  to={`/fd/${p?.short_id}`}
                  sx={{
                    'fontWeight': '800',
                    'fontSize': '1.5em',
                    'lineHeight': '22px',
                    'width': '100%',
                    'display': 'inline-block',
                    'color': 'inherit',
                    'textDecoration': 'none',
                    '&:hover': {
                      color: 'rgba(251, 88, 2, 1)',
                    },
                  }}
                >
                  {p?.name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '300',
                    fontSize: { xs: '.9em', sm: '1em' },
                    lineHeight: '22px',
                    color: 'rgba(247, 251, 250, 1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'center', sm: 'start' },
                    pb: '10px',
                  }}
                >
                  {p?.location?.city?.name}, {p?.location?.country?.name}
                  &nbsp;
                  <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${p?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${p?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                </Typography>

                <Typography variant="caption" sx={{ width: '100%', display: 'inline-block' }}>
                  Sport:&nbsp;
                  <strong>
                    {SPORT_TYPES.filter((t: any) => p?.sports?.find((s: any) => s === t?.value))?.map((s: any, index: number) => {
                      if (index === 0) {
                        return `${intl.formatMessage({ id: s?.label })}`;
                      } else {
                        return `, ${intl.formatMessage({ id: s?.label })}`;
                      }
                    })}
                  </strong>
                </Typography>

                <Typography variant="caption" sx={{ width: '100%', display: 'inline-block' }}>
                  {`${intl.formatMessage({ id: 'Field_Type_Label' })}`}:&nbsp;<strong>{`${intl.formatMessage({ id: FIELD_TYPES.find((t: any) => t?.value === p?.type)?.label ?? 'Global_NA' })}`}</strong>
                </Typography>
                <Typography variant="caption" sx={{ width: '100%', display: 'inline-block' }}>
                  {`${intl.formatMessage({ id: 'Field_Size_Label' })}`}:&nbsp;<strong>{`${intl.formatMessage({ id: FIELD_SIZES.find((s: any) => s?.value === p?.size)?.label ?? 'Global_NA' })}`}</strong>
                </Typography>

                <Grid sx={{ display: { xs: 'inline-block', sm: 'inline-flex' } }}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'rgba(247, 251, 250, 1)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'start' },
                    }}
                  >
                    {_.replace(p?.team_player?.__typename, 'Team', ` Team`)}
                  </Typography>
                </Grid>
              </Grid>
            </CardMedia>
          ))}
        </Stack>
      )}
    </Grid>
  );
};

export default ListPublishedFields;
