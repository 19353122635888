import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { Divider, ListItemAvatar, ListItem, ListItemText, List, Avatar, IconButton, TextField, Grid, Typography } from '@mui/material';
import { FivezerLogoIcon, SearchIcon } from '../../img/icons/Icons';

import TopBar from '../../component-modals/layout/TopBar';
import BackButton from '../../component-modals/common/BackButton';
import { selectPlayer } from '../../reducers/playerSlice';

const ListContacts: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const [searchInput, setSearchInput] = React.useState('');

  const ALL_CONTACTS = loggedPlayer?.followers?.sort((a: any, b: any) => {
    return a?.username.localeCompare(b?.username);
  });

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  return (
    <Grid className={`component-contacts conversation-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`}>
      <TopBar position={'static'} backgroundColor={'transparent'} color={'#F7FBFA'} logo={<FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />} />

      <Grid container id="max-width">
        <Grid item xl={6} xs={6} sm={6} className={'back-button'} sx={{ textAlign: 'left' }}>
          <BackButton />
        </Grid>
        <Grid item xl={6} xs={6} sm={6} className={'menu-section'} sx={{ textAlign: 'right' }}></Grid>
      </Grid>

      <Typography
        variant="h1"
        id="max-width"
        sx={{
          fontSize: '2em',
          fontWeight: '700',
          p: '0px 10% 20px',
        }}
      >
        List contacts ({ALL_CONTACTS?.length || 0})
      </Typography>

      <Grid id="max-width" className={'search'} item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
        <TextField
          placeholder="Search by a username or lastname..."
          id="field-search-conversation"
          value={searchInput}
          onChange={handleSearchQuery}
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
            sx: {
              color: 'rgba(247, 251, 250, 1)',
              border: 'solid 1px rgba(247, 251, 250, .3)',
              bgcolor: 'rgba(247, 251, 250, .1)',
            },
          }}
          InputLabelProps={{
            sx: {
              color: 'rgba(247, 251, 250, 1)',
              bgcolor: 'rgb(15,15,15)',
              p: '0 6px',
              borderRadius: '5px',
            },
          }}
          variant="outlined"
        />
      </Grid>

      <Grid id="max-width" className={'contacts'} item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
        {ALL_CONTACTS?.length === 0 ? (
          <Typography sx={{ textAlign: 'center', width: '100%', fontSize: '1.3em', fontWeight: '100', p: '30px 0' }}>No contact yet</Typography>
        ) : (
          <List>
            {ALL_CONTACTS?.filter((contact: any) => contact?.owner?.lastname?.includes(searchInput) || contact?.username?.includes(searchInput))?.map((contact: any) => (
              <Grid key={contact?.id}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={contact?.username} src={contact?.avatar?.path} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        component={Link}
                        to={{
                          pathname: `/u/${contact?.username}`,
                        }}
                        sx={{ textDecoration: 'none', color: 'rgba(247, 251, 250, 1)', fontSize: '1.3em', fontWeight: '700' }}
                      >{`${contact?.owner?.lastname || ''} ${contact?.owner?.firstname || ''} ${contact?.username}`}</Typography>
                    }
                    secondary={`@${contact?.username}`}
                    secondaryTypographyProps={{ color: 'rgba(247, 251, 250, 0.3) !important' }}
                  />
                </ListItem>
                <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)' }} />
              </Grid>
            ))}
          </List>
        )}
      </Grid>
      {/* <NavButton menuActions={ListContactsMenuItems} /> */}
    </Grid>
  );
};

export default ListContacts;
