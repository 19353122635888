import React from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Grid, Typography } from '@mui/material';
import ViewTournamentPlayoffGameModal from './ViewTournamentPlayoffGameModal';
import { TOURNAMENT_PLAYOFF_STAGES } from '../../utilities/utilities';
import { useIntl } from 'react-intl';
import Moment from 'react-moment';
import { VersusIcon } from '../../img/icons/Icons';

export default function TournamentPlayoffTable(props: { tournament_id: string; onSelectionChange?: any }) {
  const FIND_TOURNAMENT_BY_ID_QUERY = gql`
    query findTournamentByID($id: ID!) {
      findTournamentByID(id: $id) {
        id
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        main_location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
        selected_teams {
          id
          name
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
        }
        phase_groups {
          id
          name
          teams {
            id
            name
            players {
              id
              status
              team_number
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
              }
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
          rank {
            id
            points
            goals
            team {
              id
              name
              players {
                id
                status
                team_number
                team_player {
                  ... on FootballTeam {
                    __typename
                    id
                    name
                  }
                }
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    avatar {
                      path
                    }
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    avatar {
                      path
                    }
                    player {
                      id
                    }
                  }
                }
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
          games {
            id
            short_id
            title
          }
        }
        phase_playoffs {
          id
          stage_name
          position
          game {
            id
            short_id
            title
            status
            start_date
            end_date
            home_team {
              id
              name
              team {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
            }
            away_team {
              id
              name
              team {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
            }
            complex {
              id
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            arena {
              id
              name
              type
              size
              rank
              pictures {
                path
              }
              feedbacks {
                id
              }
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            results {
              id
              statistics {
                id
                nb_goals
                team_player {
                  id
                  team_player {
                    ... on FootballTeam {
                      __typename
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const intl = useIntl();

  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);
  const [game, setGame] = React.useState(UNDEFINED_AS_ANY);
  const [nextGame, setNextGame] = React.useState(UNDEFINED_AS_ANY);
  const [openViewGame, setOpenViewGame] = React.useState(false);

  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      id: props.tournament_id,
    },
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedTournament.refetch({
        id: props.tournament_id,
      });

      if (result) {
        setTournament(result.data?.findTournamentByID);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenViewGame = (game: any) => {
    setGame(game);
    setOpenViewGame(true);

    const nextGame = FIND_NEXT_GAME(BUILD_TOURNAMENT_ORGANIGRAM(tournament?.phase_playoffs), game);
    setNextGame(nextGame);
  };

  const handleCloseViewGame = async () => {
    try {
      await handleRefetch();
      setGame(UNDEFINED_AS_ANY);
      setOpenViewGame(false);
    } catch (e) {
      console.error(e);
    }
  };

  const NODE_TEMPLATE = (node: any) => {
    return (
      <Grid className="flex flex-column" sx={{ minWidth: '200px', borderRadius: '5px !important' }}>
        <div className="flex flex-column align-items-center">
          <Typography sx={{ fontWeight: '900', color: 'rgba(15, 15, 15, 1) !important' }}>
            {intl.formatMessage({ id: TOURNAMENT_PLAYOFF_STAGES?.find((s: any) => s?.value === node?.data?.stage_name)?.label })} {node?.data?.stage_name === 'FINAL_2' || node?.data?.game ? '' : node.label}
          </Typography>
          {!node?.data?.game ? (
            <Typography variant="caption" className="INFO" sx={{ textTransform: 'uppercase', fontWeight: '800' }}>
              {intl.formatMessage({ id: 'TournamentPlayoff_Click_Schedule' })}
            </Typography>
          ) : (
            <>
              {node.data?.game?.results ? (
                <Typography sx={{ fontSize: '.8em', color: 'rgba(15, 15, 15, 1) !important', display: 'inline-flex', alignItems: 'center', lineHeight: '15px' }}>
                  {node.data?.game?.home_team?.name}&nbsp;
                  <Typography component={'span'} sx={{ fontWeight: '800', fontSize: '1.5em', color: 'inherit !important' }}>
                    {_.sum(node.data?.game?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === node.data?.game?.home_team?.team?.id)?.map((s: any) => s?.nb_goals))}
                  </Typography>
                  <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '20px', sm: '20px' } }} />
                  <Typography component={'span'} sx={{ fontWeight: '800', fontSize: '1.5em', color: 'inherit !important' }}>
                    {_.sum(node.data?.game?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === node.data?.game?.away_team?.team?.id)?.map((s: any) => s?.nb_goals))}
                  </Typography>
                  &nbsp;{node.data?.game?.away_team?.name}
                </Typography>
              ) : (
                <Grid>
                  <Typography sx={{ fontSize: '.7em', color: 'rgba(15, 15, 15, 1) !important' }}>
                    <Moment format="d MMM yyyy HH:mm">{node.data?.game?.start_date}</Moment>
                  </Typography>
                  <Typography sx={{ fontSize: '1em', color: 'rgba(15, 15, 15, 1) !important', lineHeight: '15px', mt: '10px' }}>
                    {node.data?.game?.home_team?.name ?? `${intl.formatMessage({ id: 'TournamentPlayoff_Team' })}`}
                    <br />
                    <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '20px', sm: '20px' } }} />
                    <br />
                    {node.data?.game?.away_team?.name ?? `${intl.formatMessage({ id: 'TournamentPlayoff_Team' })}`}
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </div>
      </Grid>
    );
  };

  const BUILD_TOURNAMENT_ORGANIGRAM = (inputArray: []) => {
    const groupedByStage = _.groupBy(inputArray, 'stage_name') as any;
    const result = [];

    // Helper function to recursively build the structure
    function buildStructure(groupedByStage: any): any[] {
      const stages = _.orderBy(groupedByStage, 'position')?.filter((s: any) => s?.length > 1);
      if (!stages) {
        return [];
      }

      const stage_a = stages.map((s: any) => _.chunk(_.orderBy(s, 'position'), Math.ceil(s.length / 2))[0]).flat();
      const stage_b = stages.map((s: any) => _.chunk(_.orderBy(s, 'position'), Math.ceil(s.length / 2))[1]).flat();

      const list_finals = _.orderBy(_.uniqBy(stages.flat(), 'stage_name'), (s) => parseInt(s?.stage_name?.replaceAll('FINAL_', '')))?.map((s: any) => s?.stage_name);
      let result: any = [];

      list_finals?.map((stage: any) => {
        const s_a = stage_a?.filter((s: any) => s?.stage_name === stage);
        const s_b = stage_b?.filter((s: any) => s?.stage_name === stage);

        if (stage === 'FINAL_4') {
          const new_children = _.concat(
            s_a.map((s: any) => {
              if (!result[0]?.children) {
                return [
                  ...result,
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              }
            }),
            s_b.map((s: any) => {
              if (!result[0]?.children) {
                return [
                  ...result,
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              }
            })
          ).flat();

          const new_parent = [...result, new_children].flat(100);

          result = new_parent;
        }

        if (stage === 'FINAL_8') {
          result = result?.map((s: any, index: number) => {
            const stage_a = _.slice(s_a, index * 2, index * 2 + 2);
            const stage_b = s_b;
            const all_stages = _.concat(stage_a, stage_b);

            const new_children = _.concat(
              (index < all_stages.length - 1 ? stage_a : []).map((s: any) => {
                if (!result[0]?.children) {
                  return [
                    ...result,
                    {
                      label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                      expanded: true,
                      stage: s.stage_name,
                      data: s,
                    },
                  ];
                }
              }),
              (index < all_stages.length - 1 ? [] : stage_b).map((s: any) => {
                if (!result[0]?.children) {
                  return [
                    ...result,
                    {
                      label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                      expanded: true,
                      stage: s.stage_name,
                      data: s,
                    },
                  ];
                }
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children };

            return new_parent;
          });
        }

        if (stage === 'FINAL_16') {
          const result_f8_a_1 = result[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(s_a, index * 2, index * 2 + 2);
            const stage_a_2 = _.slice(_.slice(s_a, 0), index * 2, index * 2 + 2);

            const new_children_a = _.concat(
              (index < _.concat(stage_a_1, stage_a_2).length - 1 ? stage_a_1 : []).map((s: any) => {
                if (!result[0]?.children[0]?.children) {
                  return [
                    {
                      label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                      expanded: true,
                      stage: s.stage_name,
                      data: s,
                    },
                  ];
                }
              }),
              (index < _.concat(stage_a_1, stage_a_2).length - 1 ? [] : stage_a_2).map((s: any) => {
                if (!result[0]?.children[0]?.children) {
                  return [
                    {
                      label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                      expanded: true,
                      stage: s.stage_name,
                      data: s,
                    },
                  ];
                }
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a };

            return new_parent;
          });

          const result_f8_a_2 = result[1]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(s_b, index * 2, index * 2 + 2);
            const stage_b_2 = _.slice(_.slice(s_b, 0), index * 2, index * 2 + 2);

            const new_children_b = _.concat(
              (index < _.concat(stage_b_1, stage_b_2).length - 1 ? stage_b_1 : []).map((s: any) => {
                if (!result[1]?.children[0]?.children) {
                  return [
                    {
                      label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                      expanded: true,
                      stage: s.stage_name,
                      data: s,
                    },
                  ];
                }
              }),
              (index < _.concat(stage_b_1, stage_b_2).length - 1 ? [] : stage_b_2).map((s: any) => {
                if (!result[1]?.children[0]?.children) {
                  return [
                    {
                      label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                      expanded: true,
                      stage: s.stage_name,
                      data: s,
                    },
                  ];
                }
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_b };

            return new_parent;
          });

          const all_result_f8 = _.concat([result_f8_a_1], [result_f8_a_2]);

          result = result
            .map((s: any) => {
              return { ...s, children: all_result_f8[s?.data?.position - 1] };
            })
            .flat();
        }

        if (stage === 'FINAL_32') {
          const result_f16_a_1 = result[0]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(s_a, index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f16_a_2 = result[0]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_a_3 = _.slice(_.slice(s_a, 4), index * 2, index * 2 + 2);

            const new_children_a_2 = _.concat(
              stage_a_3.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_2 };

            return new_parent;
          });

          const result_f16_b_1 = result[1]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(s_b, index * 2, index * 2 + 2);

            const new_children_b_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_b_1 };

            return new_parent;
          });

          const result_f16_b_2 = result[1]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_b_3 = _.slice(_.slice(s_b, 4), index * 2, index * 2 + 2);

            const new_children_b_2 = _.concat(
              stage_b_3.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_b_2 };

            return new_parent;
          });

          const all_result_f16 = _.concat([result_f16_a_1], [result_f16_a_2], [result_f16_b_1], [result_f16_b_2]);

          const new_f8 = _.chunk(
            result
              .map((s_1: any) =>
                s_1?.children?.map((s_2: any) => {
                  return { ...s_2, children: all_result_f16[s_2?.data?.position - 1] };
                })
              )
              .flat(),
            2
          );

          const result_f8_a_1 = new_f8[0];
          const result_f8_a_2 = new_f8[1];

          result = result
            .map((s: any, index: number) => {
              return { ...s, children: index === 0 ? result_f8_a_1 : result_f8_a_2 };
            })
            .flat();
        }

        if (stage === 'FINAL_64') {
          const result_f32_a_1 = result[0]?.children[0]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(s_a, index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f32_a_2 = result[0]?.children[0]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 4), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f32_a_3 = result[0]?.children[1]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 8), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f32_a_4 = result[0]?.children[1]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 12), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f32_b_1 = result[1]?.children[0]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(s_b, index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f32_b_2 = result[1]?.children[0]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 4), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f32_b_3 = result[1]?.children[1]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 8), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f32_b_4 = result[1]?.children[1]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 12), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const all_result_f32 = _.concat([result_f32_a_1], [result_f32_a_2], [result_f32_a_3], [result_f32_a_4], [result_f32_b_1], [result_f32_b_2], [result_f32_b_3], [result_f32_b_4]);

          const all_result_f16 = _.chunk(
            result
              .map((s_1: any) =>
                s_1?.children?.map((s_2: any) =>
                  s_2?.children?.map((s_3: any) => {
                    return { ...s_3, children: all_result_f32[s_3?.data?.position - 1] };
                  })
                )
              )
              .flat(2),
            2
          );

          const new_f8 = _.chunk(
            result
              .map((s_1: any) =>
                s_1?.children?.map((s_2: any) => {
                  return { ...s_2, children: all_result_f16[s_2?.data?.position - 1] };
                })
              )
              .flat(),
            2
          );

          const result_f8_a_1 = new_f8[0];
          const result_f8_a_2 = new_f8[1];

          result = result
            .map((s: any, index: number) => {
              return { ...s, children: index === 0 ? result_f8_a_1 : result_f8_a_2 };
            })
            .flat();
        }

        if (stage === 'FINAL_128') {
          const result_f64_a_1 = result[0]?.children[0]?.children[0]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(s_a, index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_a_2 = result[0]?.children[0]?.children[0]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 4), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_a_3 = result[0]?.children[0]?.children[1]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 8), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_a_4 = result[0]?.children[0]?.children[1]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 12), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_a_5 = result[0]?.children[1]?.children[0]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 16), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_a_6 = result[0]?.children[1]?.children[0]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 20), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_a_7 = result[0]?.children[1]?.children[1]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 24), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_a_8 = result[0]?.children[1]?.children[1]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_a_1 = _.slice(_.slice(s_a, 28), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_a_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_1 = result[1]?.children[0]?.children[0]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(s_b, index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_2 = result[1]?.children[0]?.children[0]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 4), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_3 = result[1]?.children[0]?.children[1]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 8), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_4 = result[1]?.children[0]?.children[1]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 12), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_5 = result[1]?.children[1]?.children[0]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 16), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_6 = result[1]?.children[1]?.children[0]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 20), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_7 = result[1]?.children[1]?.children[1]?.children[0]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 24), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const result_f64_b_8 = result[1]?.children[1]?.children[1]?.children[1]?.children?.map((s: any, index: number) => {
            const stage_b_1 = _.slice(_.slice(s_b, 28), index * 2, index * 2 + 2);

            const new_children_a_1 = _.concat(
              stage_b_1.map((s: any) => {
                return [
                  {
                    label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #${s?.position}`,
                    expanded: true,
                    stage: s.stage_name,
                    data: s,
                  },
                ];
              })
            )
              ?.flat(1)
              ?.filter((c: any) => c?.stage === stage);

            const new_parent = { ...s, children: new_children_a_1 };

            return new_parent;
          });

          const all_result_f64 = _.concat([result_f64_a_1], [result_f64_a_2], [result_f64_a_3], [result_f64_a_4], [result_f64_a_5], [result_f64_a_6], [result_f64_a_7], [result_f64_a_8], [result_f64_b_1], [result_f64_b_2], [result_f64_b_3], [result_f64_b_4], [result_f64_b_5], [result_f64_b_6], [result_f64_b_7], [result_f64_b_8]).flat();

          _.chunk(
            result
              .map((s_1: any) =>
                s_1?.children?.map((s_2: any) =>
                  s_2?.children?.map((s_3: any) => {
                    s_3?.children?.map((s_4: any) => {
                      s_4?.children?.map((s_5: any) => {
                        return _.merge(s_5, all_result_f64[s_5?.data?.position - 1]);
                      });
                    });
                  })
                )
              )
              .flat(2),
            2
          );
        }
      });

      return result.flat(100);
    }

    // Start building the structure from the FINAL_2 stage
    result.push({
      label: `${intl.formatMessage({ id: 'TournamentPlayoff_Match' })} #1 (Final)`,
      expanded: true,
      stage: groupedByStage['FINAL_2']?.[0]?.stage_name,
      data: groupedByStage['FINAL_2']?.[0],
      children: buildStructure(groupedByStage) ?? [],
    });

    return result;
  };

  const FIND_NEXT_GAME = (obj: any, target: any) => {
    let parent = null;

    // DO NOT REMOVE KEY ARGUMENT
    function findInObject(currentObj: any, parentObj: any, _key: any) {
      console.log(_key);
      _.forOwn(currentObj, (value, prop) => {
        if (_.isEqual(value, target)) {
          parent = parentObj;
          return false; // Break out of the loop
        } else if (_.isObject(value)) {
          findInObject(value, currentObj, prop);
        }
      });
    }

    findInObject(obj, null, null);
    return parent;
  };

  React.useEffect(() => {
    if (!_.isMatch(tournament, loadedTournament.data?.findTournamentByID) || (tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByID)) {
      setTournament(loadedTournament.data?.findTournamentByID);
    }
  }, [tournament, loadedTournament]);

  return (
    <>
      {tournament ? (
        <Grid sx={{ '& .p-organizationchart-node-content': { borderRadius: '10px' } }}>
          <OrganizationChart value={BUILD_TOURNAMENT_ORGANIGRAM(tournament?.phase_playoffs)} selectionMode="single" onSelectionChange={(e) => handleOpenViewGame(e.data)} nodeTemplate={NODE_TEMPLATE} />
          <ViewTournamentPlayoffGameModal open={openViewGame} game_id={game?.data?.id} next_game={nextGame} current_game={game} handleClose={handleCloseViewGame} />
        </Grid>
      ) : (
        ''
      )}
    </>
  );
}
