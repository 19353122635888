import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { Grid, TextField, Fab, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Visibility, VisibilityOff, Close } from '@mui/icons-material';
import { selectSession } from '../../reducers/sessionSlice';
import useEventLog from '../../hooks/useEventLog';
import { MIN_CODE_LENGTH } from '../../utilities/utilities';
import { selectPlayer } from '../../reducers/playerSlice';

export default function InputSpendingCodeModal(props: { icon?: any; setter: any; input: string; control: any; actionOne: any; actionTwo: any; open: boolean }) {
  const VERIFICATION_SPENDING_PASSWORD_MUTATION = gql`
    mutation verificationSpendingPassword($user: inputVerificationSpendingPassword!) {
      verificationSpendingPassword(user: $user)
    }
  `;

  const { handleSendLog } = useEventLog();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [hidePassword, setHidePassword] = React.useState(true);
  const [didTry, setDidTry] = React.useState(false);

  const [verificationSpendingPassword, verificationResult] = useMutation(VERIFICATION_SPENDING_PASSWORD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    props.setter('spending_code', '');
    props.actionTwo();
  };

  const handleConfirmSpendingCode = async () => {
    try {
      if (props.input?.length >= MIN_CODE_LENGTH) {
        try {
          const result = await verificationSpendingPassword({
            variables: {
              user: {
                code: props.input,
                logged_user_id: loggedPlayer?.id,
                logged_user_username: loggedPlayer?.username,
              },
            },
          });

          props.actionOne(result.data?.verificationSpendingPassword);
          props.actionTwo();

          setDidTry(result.data?.verificationSpendingPassword);
          if (result.data?.verificationSpendingPassword === false) {
            props.setter('spending_code', '');
          }
        } catch (e: any) {
          console.error(e);
          handleSendLog(e.toString(), session?.app_client?.id);
        }
      }
    } catch (e: any) {
      console.error(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  React.useEffect(() => {
    (async () => {
      // if (didTry && props.input !== '' && verificationResult.data?.verificationSpendingPassword !== undefined) {
      //   props.actionOne(verificationResult.data?.verificationSpendingPassword);
      //   props.actionTwo();
      // }

      if (!props.open && didTry) {
        setDidTry(false);
        props.setter('spending_code', '');
      }
    })();
  }, [props, didTry, loggedPlayer, verificationSpendingPassword, verificationResult]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-transaction-confirmation-modal modal-feature'}
      PaperProps={{ sx: { p: '20px 5%', textAlign: 'center', minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' } } }}
      sx={{
        zIndex: '1000000000',
      }}
    >
      <Fab size="small" onClick={handleCancel} sx={{ boxShadow: 'none', bgcolor: 'transparent' }}>
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ p: '0', textTransform: 'uppercase' }}>
        <strong>Spending Code</strong>
      </DialogTitle>
      <DialogContent sx={{ p: '0' }}>
        <Grid sx={{ p: '30px 0' }}>
          <form>
            <Controller
              name="spending_code"
              control={props.control}
              defaultValue=""
              rules={{ required: true, minLength: MIN_CODE_LENGTH, maxLength: 255 }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label="Please enter your spending code"
                  type={hidePassword ? 'password' : 'text'}
                  fullWidth
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    sx: {
                      'fontWeight': '800',
                      'fontSize': '2em',
                      '& input': { textAlign: 'center' },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                          {hidePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pt: '0' }}>
        <Button onClick={handleConfirmSpendingCode} className={props.input === '' || props.input?.length < MIN_CODE_LENGTH ? 'button-disabled' : 'button-green'} disabled={props.input === '' || props.input?.length < MIN_CODE_LENGTH}>
          Confirm
        </Button>
        <Button onClick={handleCancel} className={'actionTwo'}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

InputSpendingCodeModal.propTypes = {
  icon: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.bool.isRequired,
};

InputSpendingCodeModal.defaultProps = {};
