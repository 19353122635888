import React from 'react';
import { CardMedia, Fab, Grid, IconButton, Typography } from '@mui/material';

import BackButton from '../../component-modals/common/BackButton';
import { FivezerLogoIcon, TeamsIcon } from '../../img/icons/Icons';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import MenuButton from '../../component-modals/layout/MenuButton';

const JoinComplexNetwork: React.FC = () => {
  return (
    <Grid className={'component-preferences settings-page legal-mentions-page'}>
      <CardMedia
        sx={{
          textAlign: 'center',
          p: '0 0 100px',
          width: '100%',
          backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15 , .6) 0%,rgba(15, 15, 15 , 1) 95%), url(../../../../img/join-us.jpg)`,
        }}
      >
        <Grid sx={{ textAlign: 'center', p: '20px 0 0' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ height: '70px', width: 'auto' }} />
          </IconButton>
        </Grid>

        <Grid container id="max-width" sx={{ textAlign: 'center', p: '0' }}>
          <Grid item xl={6} xs={6} sm={6} sx={{ textAlign: 'left' }}>
            <BackButton color={'rgba(247, 251, 250, 1)'} hoverColor={'rgba(247, 251, 250, 1)'} hoverBGColor={'rgba(247, 251, 250, .3)'} />
          </Grid>
          <Grid item xl={6} xs={6} sm={6} sx={{ textAlign: 'right' }}>
            <MenuButton />
          </Grid>

          <Grid className={'title'} item xl={12} xs={12} sm={12}>
            <Typography variant="h1" sx={{ p: '30px 10% 0', fontSize: '2em', fontWeight: 'bold', color: 'rgba(199, 240, 11, 1)' }}>
              <FormattedMessage id="JoinComplexNetwork_1" />
            </Typography>
            <Typography variant="h2" sx={{ p: '0 10%', fontSize: '1.5em', fontWeight: '500', color: 'rgba(199, 240, 11, 1)' }}>
              <FormattedMessage id="JoinComplexNetwork_1_bis" />
            </Typography>
          </Grid>
          <Grid className={'content'} sx={{ color: 'rgba(247, 251, 250, 1)' }} item xl={12} xs={12} sm={12}>
            <Typography sx={{ p: '20px 10% 40px', fontSize: '1.1em' }}>
              <FormattedMessage id="JoinComplexNetwork_2" />
              <br />
              <br />
              <FormattedMessage id="JoinComplexNetwork_3" />
              <br />
              <br />
              <FormattedMessage id="JoinComplexNetwork_4" />
              <br />
              <br />
              <FormattedMessage id="JoinComplexNetwork_5" />
              <br />
              <br />
              <FormattedMessage id="JoinComplexNetwork_6" />
              <br />
              <FormattedMessage id="JoinComplexNetwork_6_bis" />
            </Typography>
            <Fab className="button-orange" variant="extended" component={'a'} href={`https://${window.location.hostname.includes('beta') ? 'beta.' : undefined || window.location.hostname.includes('dev') ? 'dev.' : undefined || window.location.hostname.includes('staging') ? 'staging.' : undefined || window.location.hostname.includes('prod') ? 'prod.' : ''}lilenek.com/join_complex_owner_network`} target="_blank" sx={{ 'textDecoration': 'none', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
              <TeamsIcon />
              &nbsp;Join network
            </Fab>
          </Grid>
        </Grid>
      </CardMedia>
    </Grid>
  );
};

export default JoinComplexNetwork;
