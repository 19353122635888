import React from 'react';
import _ from 'lodash';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectPlayer, storePlayer } from '../../reducers/playerSlice';
import { SwipeableDrawer, Typography, Fab, Grid, TextField, ListItem, ListItemAvatar, Avatar, List, Divider, Button } from '@mui/material';
import { FiltersIcon } from '../../img/icons/Icons';
import { ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import { LoadingButton } from '@mui/lab';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

interface ISearchPlayers {
  handleClose: any;
}

const SearchPlayers: React.FC<ISearchPlayers> = (props: ISearchPlayers) => {
  try {
    const SEARCH_PLAYERS_QUERY = gql`
      query searchPlayers($player: inputSearchUsers!) {
        searchPlayers(player: $player) {
          id
          username
          avatar {
            path
          }
          cover {
            path
          }
          owner {
            firstname
            lastname
          }
          bio
        }
      }
    `;

    const FOLLOW_USER_MUTATION = gql`
      mutation followUser($request: inputRequestFollowing!) {
        followUser(request: $request)
      }
    `;

    const UNFOLLOW_USER_MUTATION = gql`
      mutation unfollowUser($request: inputRequestFollowing!) {
        unfollowUser(request: $request)
      }
    `;

    const FIND_PLAYER_BY_ID_QUERY = gql`
      query findPlayerByID($id: ID!) {
        findPlayerByID(id: $id) {
          id
          username
          mobile
          bio
          roles
          permissions
          status
          email {
            address
            status
          }
          profiles {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              avatar {
                path
              }
            }
          }
          contacts {
            ... on Footballer {
              __typename
              id
              alias
            }
            ... on Basketballer {
              __typename
              id
              alias
            }
          }
          kyc_cases {
            id
            reference
            status
            created_at
            audited_at
            incorporation_file {
              id
              filename
              path
            }
            user_front_id {
              id
              filename
              path
            }
            user_back_id {
              id
              filename
              path
            }
            user_id_selfie {
              id
              filename
              path
            }
            user_residential_proof {
              id
              filename
              path
            }
            pid {
              id_number
              type
              status
            }
          }
          avatar {
            id
            filename
            type
            encoding
            size
            path
          }
          cover {
            id
            filename
            type
            encoding
            size
            path
          }
          social_medias {
            id
            name
            handle
            link
          }
          app_configuration {
            id
            is_dark_mode
            two_facts_mode
            prefered_language
          }
          followings {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
          }
          followers {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
          owner {
            id
            firstname
            lastname
            date_of_birth
            gender
            emails {
              address
              status
            }
            player_account {
              id
              status
              username
              avatar {
                path
              }
              profiles {
                ... on Footballer {
                  __typename
                  id
                  alias
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                }
              }
              social_medias {
                id
                name
                handle
                link
              }
            }
            complex_owner_account {
              id
              status
              username
              avatar {
                path
              }
              five_complexes {
                id
              }
              social_medias {
                id
                name
                handle
                link
              }
            }
            transporter_account {
              id
              status
              username
              avatar {
                path
              }
            }
            health_worker_account {
              id
              status
              username
              avatar {
                path
              }
            }
          }
          locations {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
      }
    `;

    const dispatch = useAppDispatch();
    const session = useAppSelector(selectSession);
    const intl = useIntl();
    const loggedPlayer = useAppSelector(selectPlayer);
    const [openFilters, setOpenFilters] = React.useState(false);
    const [users, setPlayers] = React.useState(ARRAY_AS_ANY);

    const { control, watch, setValue } = useForm({
      defaultValues: {
        name: '',
        limit: 100,
      },
    });
    const inputFields = watch();

    const [followUser, followedUser] = useMutation(FOLLOW_USER_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [unfollowUser, unfollowedUser] = useMutation(UNFOLLOW_USER_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const loadedPlayers = useQuery(SEARCH_PLAYERS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key,
      variables: {
        player: {
          name: inputFields.name,
          limit: inputFields.limit,
        },
      },
    });
    const loadedPlayer = useQuery(FIND_PLAYER_BY_ID_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key || !loggedPlayer?.id,
      variables: { id: loggedPlayer?.id },
    });

    const handleClose = () => {
      props.handleClose();
    };

    const handleRefetchPlayers = async () => {
      try {
        const result = await loadedPlayers.refetch({
          player: {
            name: inputFields.name,
            limit: inputFields.limit,
          },
        });

        if (result) {
          setPlayers(result.data?.searchPlayers);
        }
      } catch (e) {
        console.group(e);
      }
    };

    const handleRefetchPlayer = async () => {
      try {
        const result = await loadedPlayer.refetch({
          id: loggedPlayer?.id,
        });

        if (result) {
          try {
            dispatch(storePlayer(result?.data?.findPlayerByID));
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {
        console.group(e);
      }
    };

    const handleReset = () => {
      setValue('name', '');
      setOpenFilters(false);
    };

    const handleOpenFilters = async () => {
      try {
        setOpenFilters(true);
      } catch (e) {
        console.log(e);
      }
    };

    const handleCloseFilters = async () => {
      try {
        setOpenFilters(false);
        await handleRefetchPlayers();
      } catch (e) {
        console.log(e);
      }
    };

    const handleFollow = async (toFollow: any) => {
      if (toFollow && toFollow?.id !== loggedPlayer?.id) {
        await followUser({
          variables: {
            request: {
              follower: {
                id: loggedPlayer?.id,
                username: loggedPlayer?.username,
                type: loggedPlayer?.__typename,
              },
              followed: {
                id: toFollow.id,
                username: toFollow.username,
                type: toFollow.__typename,
              },
            },
          },
        });

        await handleRefetchPlayer();
      }
    };

    const handleUnfollow = async (toUnfollow: any) => {
      if (toUnfollow && toUnfollow?.id !== loggedPlayer?.id) {
        await unfollowUser({
          variables: {
            request: {
              follower: {
                id: loggedPlayer?.id,
                username: loggedPlayer?.username,
                type: loggedPlayer?.__typename,
              },
              followed: {
                id: toUnfollow.id,
                username: toUnfollow.username,
                type: toUnfollow.__typename,
              },
            },
          },
        });

        await handleRefetchPlayer();
      }
    };

    React.useEffect(() => {
      if (users?.lenth === 0 && loadedPlayers.called && loadedPlayers.data?.searchPlayers?.length > 0) {
        setPlayers(loadedPlayers.data?.searchPlayers);
      }
    }, [users, setPlayers, loadedPlayers]);

    return (
      <>
        <Grid container sx={{ justifyContent: 'center', mt: '20px' }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Controller name="name" control={control} rules={{ required: true }} render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'Global_Write_Username' })}`} variant="outlined" sx={{ width: { xs: '80%', md: '70%' }, mb: '40px' }} value={_.trimStart(field.value)} className={'field-bottom-space'} />} />
            <Fab onClick={handleOpenFilters} className="button-cancel" sx={{ boxShadow: 'none' }}>
              <FiltersIcon />
            </Fab>
          </Grid>

          <SwipeableDrawer anchor={'left'} open={openFilters} onClose={handleCloseFilters} onOpen={handleOpenFilters} sx={{ zIndex: '10000' }} PaperProps={{ sx: { width: '50%' } }}>
            <Grid sx={{ p: '20px' }}>
              <Typography sx={{ display: 'inline-block', width: '100%', textAlign: 'center', textTransform: 'uppercase', fontWeight: '700', fontSize: '1.2em' }}>{`${intl.formatMessage({ id: 'Global_Filters_Title' })}`}</Typography>

              <Grid container sx={{ justifyContent: 'center' }}>
                <Button onClick={handleReset}>{`${intl.formatMessage({ id: 'Global_Write_Reset_All' })}`}</Button>
              </Grid>
            </Grid>
          </SwipeableDrawer>
        </Grid>
        <Grid container sx={{ mt: '0px' }}>
          {loadedPlayers.data?.searchPlayers?.length === 0 ? <Typography sx={{ textAlign: 'center', display: 'inline-block', width: '100%', color: 'rgba(247, 251, 250, .4)' }}>{`${intl.formatMessage({ id: 'Global_No_User_Found_Title' })}`}</Typography> : ''}
          <List sx={{ width: '100%' }}>
            {loadedPlayers.data?.searchPlayers?.map((u: any, index: number) => (
              <Grid key={index}>
                <ListItem
                  alignItems="flex-start"
                  secondaryAction={
                    loggedPlayer?.id === u?.id ? undefined : loggedPlayer?.followings?.find((f: any) => f?.id === u?.id) ? (
                      <LoadingButton loading={unfollowedUser.loading} onClick={() => handleUnfollow(u)} disabled={unfollowedUser.loading} className={unfollowedUser.loading ? 'button-disable' : 'button-green-reverse'} sx={{ boxShadow: 'none', border: 'none !important', fontSize: '.7em' }}>
                        {`${intl.formatMessage({ id: 'Global_Button_Unfollow' })}`}
                      </LoadingButton>
                    ) : (
                      <LoadingButton loading={followedUser.loading} onClick={() => handleFollow(u)} disabled={followedUser.loading} className={followedUser.loading ? 'button-disable' : 'button-green-reverse'} sx={{ boxShadow: 'none', border: 'none !important', fontSize: '.7em' }}>
                        {`${intl.formatMessage({ id: 'Global_Button_Follow' })}`}
                      </LoadingButton>
                    )
                  }
                  sx={{
                    'display': { xs: 'inline-block', sm: 'flex' },
                    'alignItems': 'center',
                    'p': { xs: '10px', sm: '' },
                    '& .MuiListItemSecondaryAction-root': {
                      position: { xs: 'relative', sm: 'absolute' },
                      top: { xs: '0', sm: '50%' },
                      right: { xs: '0', sm: '' },
                      textAlign: { xs: 'center', sm: '' },
                      transform: { xs: 'initial', sm: '' },
                    },
                  }}
                >
                  <ListItemAvatar sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    <Avatar alt={u?.username} src={u?.avatar?.path} onClick={handleClose} component={Link} to={`/u/${u?.username}`} sx={{ m: { xs: 'auto', sm: '' } }} />
                  </ListItemAvatar>
                  <Grid container sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography onClick={handleClose} component={Link} to={`/u/${u?.username}`} sx={{ fontWeight: '800', textDecoration: 'none', color: 'rgba(247, 251, 250, 1)', display: 'inline-block', width: '100%' }}>
                        {u?.owner?.firstname || u?.username} {u?.owner?.lastname?.toUpperCase() || ''}
                      </Typography>{' '}
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'rgba(247, 251, 250, 1)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: { xs: 'center', sm: 'start' },
                        }}
                      >
                        {loggedPlayer?.followers?.find((f: any) => f?.id === u?.id) ? `${intl.formatMessage({ id: 'Global_Follows_You' })}` : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider variant="inset" sx={{ bgcolor: 'rgba(247, 251, 250, .1)', m: { xs: 'auto', sm: '0 0 0 72px' } }} />
              </Grid>
            ))}
          </List>
        </Grid>
      </>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default React.memo(SearchPlayers);
