import React from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { TranslateIcon } from '../../img/icons/Icons';
import { Clear } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { LANGUAGES } from '../../utilities/utilities';
import _ from 'lodash';
import { gql, useMutation, useQuery } from '@apollo/client';
import { selectPlayer, storePlayer, switchUserLanguage } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { FormattedMessage, useIntl } from 'react-intl';

interface ISwitchLanguageButton {
  flag_only?: boolean;
  flag_icon?: boolean;
}

const SwitchLanguageButton: React.FC<ISwitchLanguageButton> = (props: ISwitchLanguageButton) => {
  const SELECT_PREFERED_LANGUAGE_MUTATION = gql`
    mutation selectPreferedLanguage($account: inputSelectPreferedLangue!) {
      selectPreferedLanguage(account: $account)
    }
  `;

  const FIND_PLAYER_BY_ID_QUERY = gql`
    query findPlayerByID($id: ID!) {
      findPlayerByID(id: $id) {
        id
        username
        mobile
        bio
        roles
        permissions
        status
        email {
          address
          status
        }
        profiles {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
        }
        contacts {
          ... on Footballer {
            __typename
            id
            alias
          }
          ... on Basketballer {
            __typename
            id
            alias
          }
        }
        kyc_cases {
          id
          reference
          status
          created_at
          audited_at
          incorporation_file {
            id
            filename
            path
          }
          user_front_id {
            id
            filename
            path
          }
          user_back_id {
            id
            filename
            path
          }
          user_id_selfie {
            id
            filename
            path
          }
          user_residential_proof {
            id
            filename
            path
          }
          pid {
            id_number
            type
            status
          }
        }
        avatar {
          id
          filename
          type
          encoding
          size
          path
        }
        cover {
          id
          filename
          type
          encoding
          size
          path
        }
        social_medias {
          id
          name
          handle
          link
        }
        app_configuration {
          id
          is_dark_mode
          two_facts_mode
          prefered_language
        }
        followings {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
        }
        followers {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
        }
        owner {
          id
          firstname
          lastname
          gender
          emails {
            address
            status
          }
          player_account {
            id
            status
            username
            avatar {
              path
            }
            profiles {
              ... on Footballer {
                __typename
                id
                alias
              }
              ... on Basketballer {
                __typename
                id
                alias
              }
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          complex_owner_account {
            id
            status
            username
            avatar {
              path
            }
            five_complexes {
              id
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          transporter_account {
            id
            status
            username
            avatar {
              path
            }
          }
          health_worker_account {
            id
            status
            username
            avatar {
              path
            }
          }
        }
        locations {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [language, setLanguage] = React.useState(LANGUAGES.find((lang: any) => lang.value === loggedPlayer?.app_configuration?.prefered_language));
  const [open, setOpen] = React.useState(false);
  const [selectPreferedLanguage, updatedLanguage] = useMutation(SELECT_PREFERED_LANGUAGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedPlayer = useQuery(FIND_PLAYER_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { id: loggedPlayer?.id },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedPlayer.refetch({
        id: loggedPlayer?.id,
      });

      if (result) {
        dispatch(storePlayer(result?.data?.findPlayerByID));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggle = async (selected_language: any) => {
    try {
      await selectPreferedLanguage({
        variables: {
          account: {
            id: loggedPlayer?.id,
            new_language: selected_language?.value,
          },
        },
      });
      handleRefetch();
      setLanguage(selected_language);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSwitchLanguage = async (selected_language: any) => {
    try {
      dispatch(
        switchUserLanguage({
          app_configuration: {
            prefered_language: selected_language?.value,
          },
        })
      );
      setLanguage(selected_language);
      handleClose();
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  return (
    <>
      <>
        {props.flag_only ? (
          <Fab onClick={() => setOpen(true)} className="button-green-reverse" sx={{ boxShadow: 'none', border: 'none !important' }}>
            <img
              loading="lazy"
              width="20"
              src={(loggedPlayer?.app_configuration?.prefered_language || 'GB') !== 'AU' ? `https://flagcdn.com/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png`}
              srcSet={(loggedPlayer?.app_configuration?.prefered_language || 'GB') !== 'AU' ? `https://flagcdn.com/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png 2x`}
              alt=""
            />
          </Fab>
        ) : (
          <Button variant="text" onClick={() => setOpen(true)} sx={{ color: 'inherit !important', fontSize: 'inherit !important', textTransform: 'inherit', p: '0' }}>
            {props.flag_icon ? (
              <img
                loading="lazy"
                width="20"
                src={(loggedPlayer?.app_configuration?.prefered_language || 'GB') !== 'AU' ? `https://flagcdn.com/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png`}
                srcSet={(loggedPlayer?.app_configuration?.prefered_language || 'GB') !== 'AU' ? `https://flagcdn.com/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language || 'GB', '_', 1)[0].toLowerCase()}.png 2x`}
                alt=""
              />
            ) : (
              <TranslateIcon sx={{ fontSize: '1.2em !important' }} />
            )}
            &nbsp;
            <FormattedMessage id="SwitchLanguageButton_Button_Title" />
          </Button>
        )}
      </>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={'component-modal modal-feature'}
        PaperProps={{
          sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
        }}
        sx={{
          zIndex: '1000000000',
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
          <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'SwitchLanguageButton_Title' })}`}</Typography>
          <Typography variant="caption">
            {`${intl.formatMessage({ id: 'SwitchLanguageButton_Current_Lang' })}`}: &nbsp;
            <strong>{LANGUAGES.find((lang: any) => lang.value === loggedPlayer?.app_configuration?.prefered_language)?.label}</strong>
            &nbsp;
            <img
              loading="lazy"
              width="20"
              src={loggedPlayer?.app_configuration?.prefered_language !== 'AU' ? `https://flagcdn.com/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png`}
              srcSet={loggedPlayer?.app_configuration?.prefered_language !== 'AU' ? `https://flagcdn.com/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png 2x`}
              alt=""
            />
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ m: '0 0 20px' }} component="span">
            <Grid className={'languages'} item xl={12} xs={12} sm={12}>
              <List>
                {LANGUAGES.sort((a: any, b: any) => a.label - b.label).map((lang: any, index: number) => (
                  <ListItem key={index} disabled={lang.value !== 'FR' && lang.value !== 'GB' && lang.value !== 'NL' && !lang.label?.includes('_')}>
                    <ListItemText
                      id={lang.value}
                      primary={
                        <Grid sx={{ fontWeight: lang.label?.includes('_') ? '700' : '500' }}>
                          {_.split(lang.value, '_', 1)[0].toLowerCase() === 'subheader' ? (
                            ''
                          ) : (
                            <img loading="lazy" width="20" src={lang.value !== 'AU' ? `https://flagcdn.com/w20/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png`} srcSet={lang.value !== 'AU' ? `https://flagcdn.com/w40/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                          )}
                          &nbsp;
                          {lang.label?.replaceAll('_', ' ')}
                          {lang.label?.includes('_') ? <Divider variant="fullWidth" /> : ''}
                        </Grid>
                      }
                      sx={{ color: 'rgba(247, 251, 250, 1)' }}
                    />

                    <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
                      {lang.label?.includes('_') ? (
                        ''
                      ) : (
                        <Checkbox
                          edge="end"
                          disabled={updatedLanguage.loading || (lang.value !== 'FR' && lang.value !== 'GB' && lang.value !== 'NL')}
                          onChange={loggedPlayer?.id ? () => handleToggle(lang) : () => handleSwitchLanguage(lang)}
                          checked={language !== undefined ? language.value === lang.value : false}
                          inputProps={{
                            'aria-labelledby': lang.value,
                          }}
                          sx={{ color: lang.value !== 'FR' && lang.value !== 'GB' && lang.value !== 'NL' ? 'rgba(247, 251, 250, .1) !important' : 'rgba(247, 251, 250, 1) !important' }}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
          <Button onClick={handleClose} className={'actionTwo button-cancel'} endIcon={<Clear />}>
            {`${intl.formatMessage({ id: 'Global_Button_Close' })}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SwitchLanguageButton.propTypes = {};

export default SwitchLanguageButton;
