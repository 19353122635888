import React from 'react';
import { Alert, Button } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

const MissingProfileAlert: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const location = useLocation();

  return (
    <>
      {loggedPlayer?.profiles?.length === 0 && location.pathname !== '/new_footballer' ? (
        <Alert
          severity="info"
          sx={{
            'display': { xs: 'inline-block', sm: 'flex' },
            'textAlign': 'center',
            '& .MuiAlert-icon': { display: { xs: 'block', sm: 'flex' } },
            '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' } },
          }}
          action={
            <Button size="small" className="button-blue" component={Link} to="/new_footballer">
              {`${intl.formatMessage({ id: 'Global_Missing_Footballer_Profile_Button_1' })}`}
            </Button>
          }
        >
          {`${intl.formatMessage({ id: 'Global_Missing_Footballer_Profile_Label_1' })}`}
        </Alert>
      ) : (
        ''
      )}
    </>
  );
};

MissingProfileAlert.propTypes = {};

export default MissingProfileAlert;
