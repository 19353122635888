import React from 'react';
import { Avatar, Button, Card, CardContent, CardMedia, CircularProgress, Fab, Fade, Grid, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';

import { MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FivezerLogoIcon, LocationIcon, TeamsIcon } from '../../img/icons/Icons';
import { gql, useQuery } from '@apollo/client';
import { ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import Moment from 'react-moment';
import _ from 'lodash';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import TopBarActionsButton from '../../component-modals/layout/TopBarActionsButton';
import { useIntl } from 'react-intl';
import { SPORT_TEAM_TYPES } from '../../utilities/utilities';

const Teams: React.FC = () => {
  const FIND_SPORT_TEAMS_BY_PLAYER_ID_QUERY = gql`
    query findSportTeamsByPlayerID($player: inputFindSportTeamsByPlayerID!) {
      findSportTeamsByPlayerID(player: $player) {
        ... on FootballTeam {
          id
          short_id
          name
          shortname
          type
          avatar {
            path
          }
          cover {
            path
          }
          availabilities {
            day
            timeslots
          }
          originate_location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          created_at
          updated_at
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [leagueTeams, setLeagueTeams] = React.useState(ARRAY_AS_ANY);
  const [friendlyTeams, setFriendlyTeams] = React.useState(ARRAY_AS_ANY);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const loadedSportTeam = useQuery(FIND_SPORT_TEAMS_BY_PLAYER_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      player: {
        id: loggedPlayer?.id,
      },
    },
    pollInterval: session.status !== 'ALIVE' || !loggedPlayer?.id ? undefined : 300000,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (
      (leagueTeams?.length === 0 && loadedSportTeam.data?.findSportTeamsByPlayerID && loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type === 'LEAGUE')?.length > 0) ||
      (leagueTeams?.length > 0 &&
        !_.isMatch(
          leagueTeams,
          loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type === 'LEAGUE')
        ))
    ) {
      setLeagueTeams(loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type === 'LEAGUE'));
    }

    if (
      (friendlyTeams?.length === 0 && loadedSportTeam.data?.findSportTeamsByPlayerID && loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type !== 'LEAGUE')?.length > 0) ||
      (friendlyTeams?.length > 0 &&
        !_.isMatch(
          friendlyTeams,
          loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type !== 'LEAGUE')
        ))
    ) {
      setFriendlyTeams(loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type !== 'LEAGUE'));
    }
  }, [leagueTeams, friendlyTeams, loadedSportTeam]);

  return (
    <Grid className={`component-team team-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ p: '0 0 150px', minHeight: '100%' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'Teams_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid container>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}></Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ position: 'relative', zIndex: '100000', display: 'inline-flex', justifyContent: 'right', alignItems: 'center', p: '20px 43px' }}>
          <TopBarActionsButton />
          <MenuButton />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', p: '0px 30px 20px' }}></Grid>
        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            textAlign: 'right',
            p: '0px 30px 20px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Grid>
            <IconButton id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </Grid>

          <Menu
            id="fade-menu"
            keepMounted
            MenuListProps={{
              'aria-labelledby': 'fade-button',
              'dense': true,
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {loggedPlayer?.id ? (
              <Grid>
                {loggedPlayer?.email?.status !== 'VERIFIED' ? (
                  ''
                ) : (
                  <MenuItem component={Link} to="/new_team">
                    <Button
                      variant="text"
                      sx={{
                        '&:hover': {
                          background: 'transparent !important',
                        },
                      }}
                      startIcon={<TeamsIcon />}
                    >
                      {`${intl.formatMessage({ id: 'Teams_Button_New_Team' })}`}
                    </Button>
                  </MenuItem>
                )}
              </Grid>
            ) : (
              ''
            )}
          </Menu>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sx={{ position: 'relative', zIndex: '100' }}>
        {(!leagueTeams && !friendlyTeams) || loadedSportTeam.loading ? (
          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
        ) : (
          <>
            {leagueTeams?.length === 0 && friendlyTeams?.length === 0 ? (
              <>
                <CardMedia
                  sx={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: '0 0 100px',
                    height: '40vh',
                    width: '100%',
                    backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15 , .6) 0%,rgba(15, 15, 15 , 1) 95%), url(../../../../img/menu77.jpg)`,
                  }}
                >
                  {loggedPlayer?.email?.status !== 'VERIFIED' ? (
                    <Fab variant="extended" size="small" className="button-green-reverse" sx={{ boxShadow: 'none' }}>
                      {`${intl.formatMessage({ id: 'Teams_Verify_Email' })}`}
                    </Fab>
                  ) : (
                    <Fab component={Link} to={'/new_team'} variant="extended" size="small" className="button-green-reverse" sx={{ boxShadow: 'none' }}>
                      <TeamsIcon />
                      &nbsp;&nbsp;{`${intl.formatMessage({ id: 'Teams_New_Team' })}`}
                    </Fab>
                  )}
                </CardMedia>
              </>
            ) : (
              <>
                <Grid item xs={12} md={12} lg={12} sx={{ p: { xs: '0', sm: '0' } }}>
                  {leagueTeams?.map((team: any) => (
                    <Card
                      key={team?.id}
                      className={'card'}
                      sx={{
                        bgcolor: 'rgba(247, 251, 250, .1)',
                        p: '0px',
                        width: '100%',
                        m: '0 auto 30px',
                      }}
                    >
                      <CardMedia
                        sx={{
                          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${team?.cover?.path})`,
                        }}
                      >
                        <CardContent className={'content'} sx={{ textAlign: 'center', p: '60px 0 !important' }}>
                          {team?.avatar?.path ? (
                            <Avatar
                              src={team?.avatar?.path}
                              component={Link}
                              to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                              sx={{
                                m: 'auto',
                                width: '100px',
                                height: '100px',
                              }}
                            />
                          ) : (
                            <Skeleton className="white-skeloton" variant="circular" width="100px" height="100px" sx={{ m: 'auto' }} />
                          )}
                          {leagueTeams?.length === 0 ? (
                            ''
                          ) : (
                            <>
                              <Typography
                                component={Link}
                                to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                sx={{
                                  'fontWeight': '800',
                                  'fontSize': '1.9em',
                                  'textDecoration': 'none',
                                  'color': 'rgba(247, 251, 250, 1)',
                                  '&:hover': {
                                    color: 'rgba(251, 88, 2, 1)',
                                  },
                                }}
                              >
                                {team?.name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: '600',
                                  fontSize: '.8em',
                                  textTransform: 'uppercase',
                                }}
                              >
                                {`${intl.formatMessage({ id: SPORT_TEAM_TYPES.find((t: any) => t.value === team?.type)?.label })}`}&nbsp;{_.replace(team?.__typename, 'Team', ' Team')}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: 'rgba(247, 251, 250, 1)',
                                  width: '100%',
                                  display: 'inline-block',
                                }}
                              >
                                {`${intl.formatMessage({ id: 'Global_Since' })}`}&nbsp;<Moment format="D MMM YYYY">{team?.created_at}</Moment>
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: 'rgba(247, 251, 250, 1)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <LocationIcon
                                  sx={{
                                    color: 'rgba(31, 175, 146, .5)',
                                  }}
                                />
                                &nbsp;
                                {team?.originate_location?.city?.name}, {team?.originate_location?.country?.name}
                                &nbsp;
                                <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${team?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${team?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                              </Typography>
                            </>
                          )}
                        </CardContent>
                      </CardMedia>
                    </Card>
                  ))}
                </Grid>
                <Grid container sx={{ p: { xs: '0', sm: '0' } }}>
                  {friendlyTeams?.map((team: any) => (
                    <Grid key={team?.id} item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: '0 30px' }}>
                      <Card
                        className={'card'}
                        sx={{
                          bgcolor: 'rgba(247, 251, 250, .1)',
                          p: '0px',
                          width: '100%',
                          borderRadius: '20px',
                          m: '0 auto 30px',
                        }}
                      >
                        <CardMedia
                          sx={{
                            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${team?.cover?.path})`,
                          }}
                        >
                          <CardContent className={'content'} sx={{ textAlign: 'center', p: '30px 0 !important' }}>
                            {team?.avatar?.path ? (
                              <Avatar
                                src={team?.avatar?.path}
                                component={Link}
                                to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                sx={{
                                  m: 'auto',
                                  width: '100px',
                                  height: '100px',
                                }}
                              />
                            ) : (
                              <Skeleton className="white-skeloton" variant="circular" width="100px" height="100px" sx={{ m: 'auto' }} />
                            )}
                            {friendlyTeams?.length === 0 ? (
                              ''
                            ) : (
                              <>
                                <Typography
                                  component={Link}
                                  to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                  sx={{
                                    'fontWeight': '800',
                                    'fontSize': '1.9em',
                                    'textDecoration': 'none',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    '&:hover': {
                                      color: 'rgba(251, 88, 2, 1)',
                                    },
                                  }}
                                >
                                  {team?.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: '600',
                                    fontSize: '.8em',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {`${intl.formatMessage({ id: SPORT_TEAM_TYPES.find((t: any) => t.value === team?.type)?.label })}`}&nbsp;{_.replace(team?.__typename, 'Team', ` Team`)}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: 'rgba(247, 251, 250, 1)',
                                    width: '100%',
                                    display: 'inline-block',
                                  }}
                                >
                                  {`${intl.formatMessage({ id: 'Global_Since' })}`}&nbsp;<Moment format="D MMM YYYY">{team?.created_at}</Moment>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: 'rgba(247, 251, 250, 1)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <LocationIcon
                                    sx={{
                                      color: 'rgba(31, 175, 146, .5)',
                                    }}
                                  />
                                  &nbsp;
                                  {team?.originate_location?.city?.name}, {team?.originate_location?.country?.name}
                                  &nbsp;
                                  <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${team?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${team?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                </Typography>
                              </>
                            )}
                          </CardContent>
                        </CardMedia>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Teams;
