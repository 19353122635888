import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogContent, DialogActions, Button, Fab, Avatar, Grid, TextField, MenuItem } from '@mui/material';
import { Send, Clear, Close, Delete } from '@mui/icons-material';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Controller, useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { useNavigate } from 'react-router-dom';
import useEventLog from '../../hooks/useEventLog';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';

export default function EditFlexModal(props: { handleClose: any; open: any; flex_id: string }) {
  const FIND_FLEX_TO_EDIT_BY_ID_QUERY = gql`
    query findFlexToEditByID($id: ID!) {
      findFlexToEditByID(id: $id) {
        id
        short_id
        caption
        pictures {
          id
          path
        }
        author {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
      }
    }
  `;

  const EDIT_FLEX_MUTATION = gql`
    mutation editFlex($flex: inputEditFlex!) {
      editFlex(flex: $flex) {
        id
        short_id
      }
    }
  `;

  const DELETE_FLEX_MUTATION = gql`
    mutation deleteFlex($flex: inputDeleteFlex!) {
      deleteFlex(flex: $flex)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();

  const navigate = useNavigate();
  const { handleSendLog } = useEventLog();

  const [openDeleteFlex, setOpenDeleteFlex] = React.useState(false);
  const [flex, setFlex] = React.useState(UNDEFINED_AS_ANY);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      caption: UNDEFINED_AS_ANY,
      flexer_id: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [editFlex, updatedFlex] = useMutation(EDIT_FLEX_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteFlex, deletedFlex] = useMutation(DELETE_FLEX_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedFlex = useQuery(FIND_FLEX_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.open || !props.flex_id,
    variables: { id: props.flex_id },
  });

  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
    type: loggedPlayer?.__typename,
  });

  const handleCloseDelete = () => {
    setOpenDeleteFlex(false);
  };

  const handleEditFlex = async () => {
    try {
      const result = await editFlex({
        variables: {
          flex: {
            id: flex?.id,
            caption: _.trim(inputFields.caption),
          },
        },
      });

      if (result) {
        handleClose();
      }
    } catch (e: any) {
      console.error(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleDeleteFlex = async (flex_id: string) => {
    try {
      const result = await deleteFlex({
        variables: {
          flex: {
            logged_user_id: loggedPlayer?.id,
            logged_user_username: loggedPlayer?.username,
            id: flex_id,
          },
        },
      });

      if (result.data?.deleteFlex > 0) {
        navigate(`/profile`, { replace: true, state: { flex: { id: flex?.id, is_removed: true } } });
        handleClose();
      }
    } catch (e: any) {
      handleClose();
      console.error(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleClose = () => {
    props.handleClose();
  };

  React.useEffect(() => {
    (async () => {
      if (props.open && flex === undefined && loadedFlex.data?.findFlexToEditByID) {
        setFlex(loadedFlex.data?.findFlexToEditByID);
      }

      if (flex) {
        if (!inputFields.caption && flex?.caption) setValue('caption', flex?.caption);
        if (!inputFields.flexer_id && flex?.author?.id) setValue('flexer_id', flex?.author?.id);
      }

      if (!props.open && flex) {
        setFlex(UNDEFINED_AS_ANY);
      }
    })();
  }, [flex, loadedFlex, inputFields, setValue, props]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>

      <DialogContent>
        <form className={'form-root'}>
          <Grid id="max-width" sx={{ p: '0px 10% 0' }}>
            <Controller
              name="caption"
              control={control}
              rules={{ required: false }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label={`${intl.formatMessage({ id: 'NewFlexModal_Caption_Label' })}`}
                  fullWidth
                  value={_.trimStart(field.value)}
                  error={inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300)}
                  helperText={inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300) ? `${intl.formatMessage({ id: 'NewFlexModal_Caption_Helper_1' })} ${ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300} max` : ''}
                  multiline
                  minRows={3}
                  sx={{ mb: '20px' }}
                />
              )}
            />

            <Controller
              name="flexer_id"
              control={control}
              render={({ field }: any) => (
                <TextField {...field} label={`${intl.formatMessage({ id: 'NewFlexModal_Flexer_Label' })} ${ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.name}`} select variant="outlined" fullWidth>
                  {ALL_FLEXERS.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar src={option?.avatar} />
                        &nbsp;&nbsp;
                        <strong>{option.name}</strong>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </form>
        <DeleteConfirmationModal open={openDeleteFlex} title={`${intl.formatMessage({ id: 'Global_Button_Delete' })} ${_.truncate(flex?.short_id)}`} entityName={'flex'} entityId={flex?.id} loading={deletedFlex.loading} actionOne={handleDeleteFlex} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={handleCloseDelete} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} />
      </DialogContent>
      <DialogActions sx={{ p: '0px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton className={'button-red-reversed'} loading={deletedFlex.loading} fullWidth loadingPosition="end" endIcon={<Delete />} onClick={() => setOpenDeleteFlex(true)}>
          {`${intl.formatMessage({ id: 'Global_Button_Delete' })}`}
        </LoadingButton>

        <Button onClick={handleClose} className={'actionTwo button-cancel'} fullWidth endIcon={<Clear />}>
          {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
        </Button>

        <LoadingButton onClick={handleEditFlex} className={inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300) ? 'button-disabled' : 'button-green'} disabled={inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300)} loading={updatedFlex.loading} fullWidth loadingPosition="end" endIcon={<Send />} sx={{ m: '0 10px' }}>
          {`${intl.formatMessage({ id: 'Global_Button_Save_Updates' })}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditFlexModal.propTypes = {
  tournament_id: PropTypes.string,
  challenge_id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
};

EditFlexModal.defaultProps = {
  tournament_id: '',
  challenge_id: '',
};
