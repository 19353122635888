import React from 'react';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { Button, Grid, TextField, CircularProgress, Fab, Typography, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { LoadingButton } from '@mui/lab';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';

export default function EditBlockchainAccountModal(props: { account: any; wallet_id: string; open: any; handleClose: any }) {
  const EDIT_BLOCKCHAIN_ACCOUNT_MUTATION = gql`
    mutation editBlockchainAccount($account: inputEditBlockchainAccount!) {
      editBlockchainAccount(account: $account)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedUser = useAppSelector(selectPlayer);
  const [account, setAccount] = React.useState(UNDEFINED_AS_ANY);

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      title: ' ',
    },
  });
  const inputFields = watch();

  const [editBlockchainAccount, updatedAccount] = useMutation(EDIT_BLOCKCHAIN_ACCOUNT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleEditBlockchainAccount = async () => {
    try {
      await editBlockchainAccount({
        variables: {
          account: {
            wallet_id: props.wallet_id,
            logged_user_id: loggedUser?.id,
            id: account?.id,
            title: _.trim(inputFields?.title),
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (account === undefined) {
      setAccount(props.account);
    }

    if (account) {
      if (inputFields.title === ' ') setValue('title', account?.title);
    }

    if (updatedAccount.called && updatedAccount.data?.editBlockchainAccount && props.open) {
      reset();
      props.handleClose();
    }
  }, [props, inputFields, setValue, updatedAccount, reset, account]);

  return (
    <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'} BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, .2)' } }} PaperProps={{ sx: { width: '100%' } }} keepMounted={false} onClose={() => props.handleClose('backdropClick' || 'escapeKeyDown')}>
      <Fab size="small" onClick={props.handleClose} sx={{ boxShadow: 'none', bgcolor: 'transparent', ml: '2%', mt: '2%' }}>
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{account?.title}</Typography>
      </DialogTitle>
      <DialogContent>
        {account === undefined ? (
          <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} className="green-loader" />
          </Grid>
        ) : (
          <DialogContentText id="alert-dialog-description" component={'div'}>
            <Grid sx={{ p: '30px 0' }}>
              <form>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 5000,
                    minLength: 200,
                  }}
                  render={({ field }: any) => <TextField {...field} label="Title" placeholder={'My Lipay account'} variant="outlined" fullWidth className={'field-bottom-space'} error={errors?.title?.type !== undefined} helperText={errors?.title?.type !== undefined ? (field.value.length < 200 ? 'Content must be at least 10 characters' : `There is an unauthorized character in ${field.value}`) : ''} />}
                />
              </form>

              <Grid container sx={{ justifyContent: 'start' }}>
                <Button className="button-cancel" disabled={updatedAccount.loading} onClick={props.handleClose}>
                  Cancel
                </Button>
                <LoadingButton className="button-green" onClick={handleEditBlockchainAccount} loading={updatedAccount.loading}>
                  Save updates
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}

EditBlockchainAccountModal.propTypes = {
  request_id: PropTypes.any,
  open: PropTypes.any,
};

EditBlockchainAccountModal.defaultProps = {
  loading: false,
};
