import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Dialog, DialogContent, Divider, Grid, IconButton, InputAdornment, List, MenuItem, TextField, Typography } from '@mui/material';
import LivestreamComment from '../../components/contacts/LivestreamComment';
import { selectPlayer } from '../../reducers/playerSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';
import useEventLog from '../../hooks/useEventLog';
import { gql, useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { MOODS } from '../../utilities/utilities';
import { Send } from '@mui/icons-material';

interface IViewCommentsModal {
  entity_id: string;
  comments: any[];
  status: string;
  handleClose: any;
  open: boolean;
  handleRefetch?: any;
}

const ViewCommentsModal: React.FC<IViewCommentsModal> = (props: IViewCommentsModal) => {
  const CREATE_COMMENT_MUTATION = gql`
    mutation createComment($comment: inputCreateComment!) {
      createComment(comment: $comment) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const { handleSendLog } = useEventLog();
  const intl = useIntl();

  const { control, watch, setValue } = useForm({
    defaultValues: {
      flexer_id: '',
      comment_input: '',
      selected_mood: 'NEUTRAL',
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const [createComment, newComment] = useMutation(CREATE_COMMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleChangeMood = (value: any) => {
    setValue('selected_mood', value);
  };

  const handleCreateComment = async () => {
    try {
      const result = await createComment({
        variables: {
          comment: {
            entity_id: props.entity_id,
            mood: inputFields.selected_mood,
            content: inputFields.comment_input,
            author_id: inputFields.flexer_id,
          },
        },
      });

      if (result.data?.createComment) {
        setValue('comment_input', '');
        if (props.handleRefetch) {
          await props.handleRefetch();
        }
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  React.useEffect(() => {
    if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
  }, [inputFields.flexer_id]);

  return (
    <Dialog open={props.open} onClose={handleClose} PaperProps={{ sx: { bgcolor: 'rgba(255, 255, 255, .8)', p: '0', minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' } } }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      <DialogContent sx={{ p: '0' }}>
        {props?.comments?.length === 0 ? (
          <Typography
            variant="h3"
            sx={{
              p: '50px 20px',
              fontSize: '1.3em',
              fontWeight: '100',
              textAlign: 'center',
              width: '100%',
              color: 'rgba(15, 15, 15, .5)',
            }}
          >
            No comments
          </Typography>
        ) : (
          <Grid item xs={12} sm={12} md={12} xl={12} sx={{ overflow: 'auto', maxHeight: '600px' }}>
            <List
              sx={{
                width: '100%',
                color: 'rgba(15, 15, 15, 1)',
                p: '0 5%',
              }}
            >
              {props?.comments?.map((c: any) => (
                <LivestreamComment key={c?.id} comment={c} status={props?.status} />
              ))}
            </List>
          </Grid>
        )}
        {!session?.token?.key ? (
          ''
        ) : (
          <>
            <Divider variant="middle" />
            <Controller
              name="flexer_id"
              control={control}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  // label={`As`}
                  select
                  variant="outlined"
                  className="no-borders"
                  SelectProps={{
                    IconComponent: undefined,
                    sx: {
                      'color': 'inherit',
                      'border': 'none',
                      'bgcolor': 'transparent',
                      '& #mui-component-select-flexer_id': { p: '10px 0' },
                      '&::-ms-expand': {
                        display: 'none',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'inherit',
                      p: '0 6px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      width: '100%',
                    },
                  }}
                  sx={{ m: '0', p: '0', width: 'auto' }}
                >
                  {ALL_FLEXERS.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          src={option?.avatar}
                          sx={{
                            width: '25px',
                            height: '25px',
                          }}
                        />
                        &nbsp;&nbsp;
                        <Typography variant="caption">{option.name}</Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="comment_input"
              control={control}
              rules={{ required: false, pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._[]\s-]{5,60}$/ }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  placeholder="Write a comment..."
                  fullWidth
                  value={_.trimStart(field.value)}
                  multiline
                  minRows={1}
                  disabled={newComment.loading}
                  InputProps={{
                    startAdornment: (
                      <Controller
                        control={control}
                        name="selected_mood"
                        render={({ field }: any) => (
                          <TextField {...field} value={field.value} select variant="outlined" SelectProps={{ MenuProps: { sx: { '& li .MuiGrid-root': { justifyContent: 'start', fontWeight: '500', color: 'rgba(15, 15, 15, 1)' } } } }}>
                            {MOODS?.map((option) => (
                              <MenuItem key={option?.value} value={option?.value} onClick={() => handleChangeMood(option?.value)}>
                                <Grid container sx={{ justifyContent: 'center', fontWeight: '700', color: 'rgba(15,15,15, .4)' }}>
                                  {option?.icon}&nbsp;&nbsp;
                                  {intl.formatMessage({ id: option?.label })}
                                </Grid>
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ justifyContent: 'center', alignItems: 'center', p: { xs: '40px 0 10px', sm: '0' } }}>
                        <IconButton onClick={handleCreateComment}>
                          <Send />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'color': 'rgba(15,15,15, 1)', 'mt': '0', 'mb': '0', '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& textarea': { borderRadius: '10px', bgcolor: 'rgba(15,15,15, .04)', p: '10px 0px 10px 5px' } },
                  }}
                  sx={{ mb: '0px', bgcolor: 'rgba(15,15,15, 0)', borderRadius: '10px' }}
                />
              )}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

ViewCommentsModal.propTypes = {
  comments: PropTypes.array.isRequired,
  handleClose: PropTypes.any.isRequired,
  entity_id: PropTypes.string.isRequired,
  handleRefetch: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

ViewCommentsModal.defaultProps = {
  open: false,
};

export default React.memo(ViewCommentsModal);
