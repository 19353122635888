import React from 'react';
import { Alert, AppBar, Avatar, AvatarGroup, Box, Button, Card, CardHeader, CardMedia, CircularProgress, Divider, Fab, Fade, Grid, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { OverviewIcon, TeamsIcon, CaptainIcon, LocationIcon, FivezerLogoIcon, StatisticsIcon, VersusIcon, GoalsIcon, SucceedPassesIcon, GamesPlayedIcon, ManOfTheMatchIcon, TacklesIcon, AverageDistanceIcon, AverageMaxSpeedIcon, NotificationIcon, ChatsIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { Add, Delete, Edit, MoreVert } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import Error from '../../component-modals/common/Error';
import { ALL_SOCIAL_MEDIAS, ONE_DAY_MILLISEC, SPORT_TEAM_TYPES } from '../../utilities/utilities';
import { useForm } from 'react-hook-form';
import AddNewTeamPlayerModal from '../../component-modals/team/AddNewTeamPlayerModal';
import ViewAvailabilitiesModal from '../../component-modals/common/ViewAvailabilitiesModal';
import PickTeamPlayerNumberModal from '../../component-modals/team/PickTeamPlayerNumberModal';
import Moment from 'react-moment';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import EditTeamLineupModal from '../../component-modals/team/EditTeamLineupModal';
import ViewTeamLineupModal from '../../component-modals/team/ViewTeamLineupModal';
import MenuButton from '../../component-modals/layout/MenuButton';
import ScrollToTop from '../../component-modals/common/ScrollTop';
import ListNotifications from '../profile/ListNotifications';
import { LoadingButton } from '@mui/lab';
import FollowersDrawer from '../../component-modals/profile/FollowersDrawer';
import ShowFootballTeamQRCode from '../../component-modals/features/ShowFootballTeamQRCode';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { selectSession } from '../../reducers/sessionSlice';
import AddTeamLineupModal from '../../component-modals/team/AddTeamLineupModal';
import NumberFormatter from '../../component-modals/layout/NumberFormatter';
import { REACT_APP_CUSTOM_MAP_URL } from '../../config';
import { useIntl } from 'react-intl';
import SelectDescriptionButton from '../../component-modals/layout/SelectDescriptionButton';
import MessengerModal from '../../component-modals/messenger/MessengerModal';
import AddNewTeamManagerModal from '../../component-modals/team/AddNewTeamManagerModal';
import FootballCardModal from '../../component-modals/profile/FootballCardModal';
import LongTextFormatter from '../../component-modals/layout/LongTextFormatter';

const FootballTeam: React.FC = () => {
  const FIND_FOOTBALL_TEAM_BY_ID_QUERY = gql`
    query findFootballTeamByID($short_id: String!) {
      findFootballTeamByID(short_id: $short_id) {
        id
        short_id
        name
        shortname
        status
        type
        is_private
        team_founder {
          id
          alias
          player {
            id
            username
          }
        }
        avatar {
          path
        }
        cover {
          path
        }
        bios {
          language
          content
        }
        managers {
          id
          status
          username
          avatar {
            path
          }
          cover {
            path
          }
        }
        players {
          id
          status
          team_number
          player {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
              cover {
                path
              }
              player {
                id
                username
              }
            }
          }
        }
        captains {
          id
          status
          team_number
          player {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
                username
              }
            }
          }
        }
        availabilities {
          day
          timeslots
        }
        team_lineups {
          id
          name
          players {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                cover {
                  path
                }
                player {
                  id
                  username
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                cover {
                  path
                }
                player {
                  id
                  username
                }
              }
            }
          }
          home_challenges {
            id
            short_id
            title
            status
            type
            start_date
            end_date
            complex {
              id
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            arena {
              id
              name
              type
              size
              pictures {
                path
              }
            }
            booking {
              id
              short_id
              status
              title
              start_date
              end_date
            }
          }
          away_challenges {
            id
            short_id
            title
            status
            type
            start_date
            end_date
            complex {
              id
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            arena {
              id
              name
              type
              size
              pictures {
                path
              }
            }
            booking {
              id
              short_id
              status
              title
              start_date
              end_date
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              players {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                    avatar {
                      path
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                    avatar {
                      path
                    }
                  }
                }
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                    avatar {
                      path
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                    avatar {
                      path
                    }
                  }
                }
              }
            }
          }
        }
        followers {
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        originate_location {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
        social_medias {
          id
          name
          handle
          link
        }
      }
    }
  `;

  const FIND_FOOTBALL_TEAM_STATS_BY_ID_QUERY = gql`
    query findFootballTeamStatsByID($short_id: String!) {
      findFootballTeamStatsByID(short_id: $short_id) {
        id
        short_id
        name
        team_lineups {
          id
          home_challenges {
            id
            short_id
            title
            status
            arena {
              id
              name
              type
              size
              pictures {
                path
              }
              complex {
                id
                name
              }
              location {
                id
                title
                coordinates
                country_code
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            results {
              id
              status
              winner_team {
                id
              }
              statistics {
                id
                nb_goals
                player {
                  ... on Footballer {
                    __typename
                    id
                    sport_teams {
                      ... on FootballTeam {
                        __typename
                        id
                      }
                    }
                  }
                }
                given_assists {
                  id
                  nb_assists
                }
              }
            }
          }
          away_challenges {
            id
            short_id
            title
            status
            arena {
              id
              name
              type
              size
              pictures {
                path
              }
              complex {
                id
                name
              }
              location {
                id
                title
                coordinates
                country_code
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            results {
              id
              status
              winner_team {
                id
              }
              statistics {
                id
                nb_goals
                player {
                  ... on Footballer {
                    __typename
                    id
                    sport_teams {
                      ... on FootballTeam {
                        __typename
                        id
                      }
                    }
                  }
                }
                given_assists {
                  id
                  nb_assists
                }
              }
            }
          }
        }
        followers {
          ... on User {
            __typename
            id
          }
          ... on Player {
            __typename
            id
          }
          ... on FootballTeam {
            __typename
            id
          }
        }
      }
    }
  `;

  const DELETE_TEAM_PLAYER_MUTATION = gql`
    mutation deleteTeamPlayer($team_player: inputDeleteTeamPlayer!) {
      deleteTeamPlayer(team_player: $team_player)
    }
  `;

  const ADD_TEAM_CAPTAIN_TO_SPORT_TEAM_MUTATION = gql`
    mutation addTeamCaptainToSportTeam($team_player: inputAddTeamCaptainToSportTeam!) {
      addTeamCaptainToSportTeam(team_player: $team_player)
    }
  `;

  const DELETE_TEAM_CAPTAIN_TO_SPORT_TEAM_MUTATION = gql`
    mutation deleteTeamCaptainToSportTeam($team_player: inputDeleteTeamCaptainToSportTeam!) {
      deleteTeamCaptainToSportTeam(team_player: $team_player)
    }
  `;

  const FIND_CHALLENGES_BY_TEAM_ID_QUERY = gql`
    query findChallengesByTeamID($team: inputFindChallengesByTeamID!) {
      findChallengesByTeamID(team: $team) {
        id
        short_id
        title
        status
        type
        start_date
        end_date
        is_private
        complex {
          id
          name
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        arena {
          id
          name
          type
          size
          pictures {
            path
          }
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
      }
    }
  `;

  const FOLLOW_ENTITY_MUTATION = gql`
    mutation followEntity($request: inputEntityFollowing!) {
      followEntity(request: $request)
    }
  `;

  const UNFOLLOW_ENTITY_MUTATION = gql`
    mutation unfollowEntity($request: inputEntityFollowing!) {
      unfollowEntity(request: $request)
    }
  `;

  const FIND_WORLD_LEAGUE_RANK_BY_SPORT_TEAM_ID = gql`
    query findWorldLeagueRankBySportTeamID($id: ID!, $type: String!) {
      findWorldLeagueRankBySportTeamID(id: $id, type: $type)
    }
  `;

  const FIND_WORLD_FRIENDLY_RANK_BY_SPORT_TEAM_ID = gql`
    query findWorldFriendlyRankBySportTeamID($id: ID!, $type: String!) {
      findWorldFriendlyRankBySportTeamID(id: $id, type: $type)
    }
  `;

  const DELETE_TEAM_MANAGER_TO_SPORT_TEAM_MUTATION = gql`
    mutation deleteTeamManagerToSportTeam($team: inputDeleteSportTeamManager!) {
      deleteTeamManagerToSportTeam(team: $team)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const { teamId } = useParams();
  const intl = useIntl();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [team, setTeam] = React.useState(UNDEFINED_AS_ANY);
  const [challenges, setChallenges] = React.useState(ARRAY_AS_ANY);
  const [value, setIndex] = React.useState(0);
  const [openNewTeamPlayer, setOpenNewTeamPlayer] = React.useState(false);
  const [openViewTeamLineup, setOpenViewTeamLineup] = React.useState(false);
  const [openEditTeamLineup, setOpenEditTeamLineup] = React.useState(false);
  const [openPickNumber, setOpenPickNumber] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorPlayerSettings, setAnchorPlayerSettings] = React.useState<null | HTMLElement>(null);
  const openPlayerSettings = Boolean(anchorPlayerSettings);
  const [offsetChallenges, setOffsetChallenges] = React.useState(0);
  const [openFollowersList, setOpenFollowersList] = React.useState(false);
  const [showQRC, setShowQRC] = React.useState(false);
  const [openNewTeamLineup, setOpenNewTeamLineup] = React.useState(false);
  const [openSendMessage, setOpenSendMessage] = React.useState(false);
  const [openNewTeamManager, setOpenNewTeamManager] = React.useState(false);
  const [openViewCard, setOpenViewCard] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      description_language: '',
      selected_player: UNDEFINED_AS_ANY,
      selected_team_lineup: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [addTeamCaptainToSportTeam, newTeamCaptain] = useMutation(ADD_TEAM_CAPTAIN_TO_SPORT_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteTeamCaptainToSportTeam, deletedTeamCaptain] = useMutation(DELETE_TEAM_CAPTAIN_TO_SPORT_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteTeamPlayer, deletedTeamPlayer] = useMutation(DELETE_TEAM_PLAYER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [followEntity, followedEntity] = useMutation(FOLLOW_ENTITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unfollowEntity, unfollowedEntity] = useMutation(UNFOLLOW_ENTITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteTeamManagerToSportTeam, updatedTeamManagers] = useMutation(DELETE_TEAM_MANAGER_TO_SPORT_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedFootballTeam = useQuery(FIND_FOOTBALL_TEAM_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      short_id: teamId,
    },
    pollInterval: loggedPlayer?.id ? 300000 : undefined,
  });
  const loadedFootballTeamStats = useQuery(FIND_FOOTBALL_TEAM_STATS_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      short_id: teamId,
    },
    pollInterval: loggedPlayer?.id ? 300000 : undefined,
  });
  const loadedChallenges = useQuery(FIND_CHALLENGES_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team: {
        id: team?.id,
        offset: offsetChallenges,
      },
    },
  });
  const loadedWorldRankProfile = useQuery(team?.type === 'LEAGUE' ? FIND_WORLD_LEAGUE_RANK_BY_SPORT_TEAM_ID : FIND_WORLD_FRIENDLY_RANK_BY_SPORT_TEAM_ID, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !team?.id || !team?.type,
    variables: {
      id: team?.id,
      type: team?.__typename,
    },
  });

  const SelectedIcon = L.icon({
    iconUrl: team?.avatar?.path || '../../img/active-marker-icon.png',
    shadowUrl: iconShadow,
    iconSize: [32, 32],
  });

  const TEAM_STATS = loadedFootballTeamStats.data?.findFootballTeamStatsByID;
  const ALL_TEAM_LINEUPS = TEAM_STATS?.team_lineups;
  const ALL_GAMES = ALL_TEAM_LINEUPS?.map((l: any) => _.concat(l?.home_challenges, l?.away_challenges))?.flat();
  const NB_GAMES = ALL_GAMES?.filter((g: any) => g?.results)?.length;
  const NB_GAMES_WON = ALL_GAMES?.filter((g: any) => g?.results)?.filter((c: any) => ALL_TEAM_LINEUPS?.find((t: any) => t?.id === c?.results?.winner_team?.id))?.length;
  const NB_GAMES_LOST = ALL_GAMES?.filter((g: any) => g?.results)?.filter((c: any) => c?.results && !ALL_TEAM_LINEUPS?.find((t: any) => t?.id === c?.results?.winner_team?.id))?.length;
  const NB_GAMES_DRAW = ALL_GAMES?.filter((g: any) => g?.results)?.filter((c: any) => c?.results && !c?.results?.winner_team?.id)?.length;
  const ALL_GAME_STATISTICS = ALL_GAMES?.map((g: any) => g?.results?.statistics)?.flat();
  const NB_SCORED_GOALS = _.sum(ALL_GAME_STATISTICS?.filter((s: any) => s?.player?.sport_teams?.find((t: any) => t?.id === team?.id))?.map((s: any) => s?.nb_goals));
  const NB_CONCEDED_GOALS = _.sum(ALL_GAME_STATISTICS?.filter((s: any) => !s?.player?.sport_teams?.find((t: any) => t?.id === team?.id))?.map((s: any) => s?.nb_goals));
  const NB_FOLLOWERS = TEAM_STATS?.followers?.length;
  const NB_ASSISTS = _.sum(
    ALL_GAME_STATISTICS?.map((s: any) => s?.given_assists)
      ?.flat()
      ?.map((a: any) => a?.nb_assists)
  );
  const WORLD_RANK = loadedWorldRankProfile.data?.findWorldLeagueRankBySportTeamID;

  const handleRefetch = async () => {
    try {
      const result = await loadedFootballTeam.refetch({
        short_id: teamId,
      });

      if (result) {
        setTeam(result.data?.findFootballTeamByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFollowTeam = async (team_id: string) => {
    try {
      await followEntity({
        variables: {
          request: {
            follower_id: loggedPlayer?.id,
            follower_type: loggedPlayer?.__typename,
            followed_id: team_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUnfollowTeam = async (team_id: string) => {
    try {
      await unfollowEntity({
        variables: {
          request: {
            follower_id: loggedPlayer?.id,
            follower_type: loggedPlayer?.__typename,
            followed_id: team_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddCaptain = async (team_player_id: string) => {
    try {
      if (team_player_id) {
        await addTeamCaptainToSportTeam({
          variables: {
            team_player: {
              id: team_player_id,
              team_id: team?.id,
              team_sport: team?.__typename,
            },
          },
        });
      }
      handleClose();
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteCaptain = async (team_player_id: string) => {
    try {
      if (team_player_id) {
        await deleteTeamCaptainToSportTeam({
          variables: {
            team_player: {
              id: team_player_id,
              team_id: team?.id,
              team_sport: team?.__typename,
            },
          },
        });
      }
      handleClose();
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteTeamPlayer = async (team_player_id: string) => {
    try {
      if (team_player_id) {
        await deleteTeamPlayer({
          variables: {
            team_player: {
              id: team_player_id,
            },
          },
        });
      }
      handleClose();
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenPickNumber = async () => {
    try {
      setOpenPickNumber(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClosePickNumber = async () => {
    try {
      setOpenPickNumber(false);
      handleClose();
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setIndex(index);
  };

  const handleChangeIndexToLineup = () => {
    setIndex(3);
  };

  const handleChangeIndexToHome = async () => {
    try {
      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      if (team?.team_lineups?.length === 0) {
        setIndex(0);
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenPlayerSettings = (event: React.MouseEvent<HTMLElement>, selected_player: any) => {
    setValue('selected_player', selected_player);
    setAnchorPlayerSettings(event.currentTarget);
  };

  const handleClose = () => {
    setValue('selected_player', UNDEFINED_AS_ANY);
    setAnchorEl(null);
    setAnchorPlayerSettings(null);
  };

  const handleSendMessage = () => {
    handleClose();
    setOpenSendMessage(true);
  };

  const handleOpenDrawer = (isOpen: boolean) => {
    setOpenFollowersList(isOpen);
  };

  const handleOpenNewTeamPlayer = () => {
    setOpenNewTeamPlayer(true);
  };

  const handleCloseNewTeamPlayer = async () => {
    try {
      await handleRefetch();
      setOpenNewTeamPlayer(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenNewTeamManager = () => {
    setOpenNewTeamManager(true);
  };

  const handleCloseNewTeamManager = async () => {
    try {
      await handleRefetch();
      setOpenNewTeamManager(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteNewTeamPlayer = async (player_id: string) => {
    try {
      await deleteTeamManagerToSportTeam({
        variables: {
          team: {
            player_id: player_id,
            id: team?.id,
            sport: team?.__typename,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenNewTeamLineup = () => {
    setOpenNewTeamLineup(true);
  };

  const handleCloseNewTeamLineup = async () => {
    try {
      await handleRefetch();
      setOpenNewTeamLineup(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenEditTeamLineup = (team_lineup_id: string) => {
    setOpenEditTeamLineup(true);
    setValue('selected_team_lineup', team_lineup_id);
  };

  const handleCloseEditTeamLineup = async () => {
    await handleRefetch();
    setOpenEditTeamLineup(false);
    setValue('selected_team_lineup', UNDEFINED_AS_ANY);
  };

  const handleOpenViewTeamLineup = (team_lineup_id: string) => {
    setOpenViewTeamLineup(true);
    setValue('selected_team_lineup', team_lineup_id);
  };

  const handleCloseViewTeamLineup = async () => {
    try {
      await handleRefetch();
      setOpenViewTeamLineup(false);
      setValue('selected_team_lineup', UNDEFINED_AS_ANY);
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoadMoreChallenges = async () => {
    try {
      const result = await loadedChallenges.refetch({
        team: {
          id: team?.id,
          offset: offsetChallenges,
        },
      });
      const newList = _.concat(challenges, result.data?.findChallengesByTeamID);
      setChallenges(newList);
      setOffsetChallenges(offsetChallenges + result.data?.findChallengesByTeamID?.length);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenCard = (player: any) => {
    setOpenViewCard(true);
    setValue('selected_player', player);
  };

  const handleCloseCard = () => {
    setOpenViewCard(false);
    setValue('selected_player', UNDEFINED_AS_ANY);
  };

  React.useEffect(() => {
    if ((!team && loadedFootballTeam.called && loadedFootballTeam.data?.findFootballTeamByID) || !_.isMatch(team, loadedFootballTeam.data?.findFootballTeamByID)) {
      setTeam(loadedFootballTeam.data?.findFootballTeamByID);
    }

    if (inputFields.description_language === '' && team?.bios[0]?.language !== undefined) setValue('description_language', team?.bios[0]?.language);

    if (challenges?.length === 0 && loadedChallenges.data?.findChallengesByTeamID && loadedChallenges.data?.findChallengesByTeamID?.length > 0) {
      setChallenges(loadedChallenges.data?.findChallengesByTeamID);
    }

    if (offsetChallenges === 0 && challenges?.length > 1) {
      setOffsetChallenges(challenges?.length);
    }
  }, [team, loadedFootballTeam, challenges, loadedChallenges, offsetChallenges]);

  return (
    <Grid className={`component-team team-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ p: '0 0 150px', minHeight: '100%' }}>
      <Helmet>
        <title>{team ? team?.name : `Football Team`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid item xs={12} md={12} lg={12}>
        {!team || team?.status !== 'ACTIVE' || loadedFootballTeam.loading ? (
          <Grid item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
            {(!team || team?.status !== 'ACTIVE') && !loadedFootballTeam.loading ? <Error title={`${teamId}`} status={loadedFootballTeam.error ? 504 : 404} content={loadedFootballTeam.error ? '' : `${intl.formatMessage({ id: 'FootballTeam_Error_1' })}`} /> : ''}

            {loadedFootballTeam.loading && !team ? <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}
          </Grid>
        ) : (
          <Grid>
            {(team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) && team?.captains?.length === 0 && team?.players?.length > 0 ? (
              <Alert
                severity="warning"
                variant="filled"
                sx={{
                  'width': '100%',
                  'display': { xs: 'inline-block', sm: 'flex' },
                  'textAlign': 'center',
                  '& .MuiAlert-icon': { display: { xs: 'inline', sm: 'flex' }, m: '0 12px' },
                  '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' }, m: 'auto', width: { xs: '100%', sm: '50%' } },
                }}
              >
                {`${intl.formatMessage({ id: 'FootballTeam_Alert_1' })}`}
              </Alert>
            ) : (
              ''
            )}

            {(team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) && team?.captains?.length === 0 && team?.players?.length === 0 ? (
              <Alert
                severity="warning"
                variant="filled"
                sx={{
                  'width': '100%',
                  'display': { xs: 'inline-block', sm: 'flex' },
                  'textAlign': 'center',
                  '& .MuiAlert-icon': { display: { xs: 'inline', sm: 'flex' }, m: '0 12px' },
                  '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' }, m: 'auto', width: { xs: '100%', sm: '50%' } },
                }}
              >
                {`${intl.formatMessage({ id: 'FootballTeam_Alert_2' })}`}
              </Alert>
            ) : (
              ''
            )}

            <CardMedia
              src={team?.cover?.path}
              sx={{
                backgroundSize: 'contain',
                backgroundPosition: 'top',
                backgroundImage: `linear-gradient(to right, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 15%, rgba(0,0,0,0) 85%, rgba(0,0,0,.5) 100%), url(${team?.cover?.path})`,
              }}
            >
              <Grid container>
                <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'center' }}></Grid>
                <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
                  <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                    <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'right', p: '0 40px' }}>
                  <MenuButton />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', p: '0 10px' }}>
                  <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{
                    textAlign: 'right',
                    p: '0 10px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <Box>
                    <IconButton id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                      <MoreVert />
                    </IconButton>
                  </Box>

                  <Menu
                    id="fade-menu"
                    keepMounted
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                      'dense': true,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    {team?.bios?.length <= 1 ? (
                      ''
                    ) : (
                      <MenuItem>
                        <SelectDescriptionButton control={control} descriptions={team?.bios} />
                      </MenuItem>
                    )}

                    {(team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) && loggedPlayer?.id ? (
                      <Grid>
                        {loggedPlayer?.email?.status !== 'VERIFIED' ? (
                          ''
                        ) : (
                          <MenuItem component={Link} to="/football_team/edit" state={{ team: { id: team?.id } }}>
                            <Button
                              variant="text"
                              sx={{
                                '&:hover': {
                                  background: 'transparent !important',
                                },
                              }}
                              startIcon={<Edit />}
                            >
                              {`${intl.formatMessage({ id: 'FootballTeam_Button_Edit' })}`}
                            </Button>
                          </MenuItem>
                        )}
                      </Grid>
                    ) : (
                      ''
                    )}

                    <ShareURLButton
                      button={
                        <MenuItem>
                          <Button
                            variant="text"
                            sx={{
                              '&:hover': {
                                background: 'transparent !important',
                              },
                            }}
                            startIcon={
                              <Avatar
                                src={team?.avatar?.path}
                                sx={{
                                  height: { xs: '20px' },
                                  width: { xs: '20px' },
                                }}
                              />
                            }
                          >
                            {`${intl.formatMessage({ id: 'Global_Button_Share' })}`}&nbsp;
                            {team?.__typename?.replace('Team', ' Team')}
                          </Button>
                        </MenuItem>
                      }
                      title={team?.name}
                      url={window.location.href}
                      img={team?.avatar?.cover || team?.avatar?.path}
                    />
                  </Menu>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  m: 'auto',
                  width: '100%',
                  backgroundImage: {
                    xs: `linear-gradient(to bottom, rgba(15,15,15,0) 0%, rgba(15,15,15,1) 7%)`,
                    sm: `linear-gradient(to bottom, rgba(15,15,15,0) 5%, rgba(15,15,15,1) 25%)`,
                    md: `linear-gradient(to bottom, rgba(15,15,15,0) 2%, rgba(15,15,15,1) 37%)`,
                    lm: `linear-gradient(to bottom, rgba(15,15,15,0) 15%, rgba(15,15,15,1) 40%)`,
                  },
                  p: {
                    xs: '0px 5% 50px',
                    sm: '0px 5% 50px',
                    md: '0px 20% 50px',
                    lm: '0px 20% 50px',
                    lx: '0px 20% 50px',
                  },
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} className={'avatar'} sx={{ textAlign: 'center', pr: '0' }}>
                  <Avatar
                    onClick={() => setShowQRC(true)}
                    src={team?.avatar?.path}
                    sx={{
                      'background': 'transparent',
                      'boxSizing': 'border-box',
                      'border': 'solid 1px rgba(247, 251, 250, .4)',
                      'height': { xs: 100, sm: 150, md: 200, xl: 250 },
                      'width': { xs: 100, sm: 150, md: 200, xl: 250 },
                      'm': 'auto',
                      '& img': { p: '0px', borderRadius: '100%' },
                    }}
                  />
                  <ShowFootballTeamQRCode open={showQRC} handleClose={() => setShowQRC(false)} team_name={team?.short_id} logo={team?.avatar?.path} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={'profile-info'}
                  sx={{
                    p: '0',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h2" sx={{ m: '0', position: 'relative', bottom: '-40px', left: { xs: '-10%', sm: '-10%', md: '-30%' }, fontSize: { xs: '3em', sm: '3.5em', md: '5em' }, fontWeight: '800', display: 'inline-block', color: 'transparent', textTransform: 'uppercase', WebkitTextStroke: '1px rgba(255, 255, 255, .2)', whiteSpace: 'nowrap' }}>
                    {`${team?.name}`}
                  </Typography>
                  <Typography variant="h1" sx={{ m: '0', fontSize: '2.2em', fontWeight: '800 !important', display: 'block' }}>
                    {`${team?.name}`}
                  </Typography>
                  <Stack direction="row" className={'social_medias'} sx={{ display: 'inline-flex', alignItems: 'center', mb: '40px' }}>
                    <Typography variant="body1" sx={{ color: 'rgba(247, 251, 250, .4) !important', m: '0', fontSize: '1em', fontWeight: '600', textTransform: 'uppercase', display: 'inline-block', width: '100%' }}>
                      {`${intl.formatMessage({ id: SPORT_TEAM_TYPES?.find((t: any) => t.value === team?.type)?.label })}`}
                    </Typography>
                  </Stack>
                  <AvatarGroup sx={{ justifyContent: 'center' }}>
                    {_.uniqBy(team?.players, 'id')
                      ?.filter((p: any) => p?.status === 'ACTIVE')
                      ?.map((p: any, index: number) => (
                        <Grid key={index} onClick={() => handleOpenCard(p)} sx={{ '& svg': { color: 'rgba(255, 255, 255, 1)' } }}>
                          <Avatar src={p?.player?.avatar?.path} alt={p?.alias} sx={{ m: '-5px 0 0' }} />
                        </Grid>
                      ))}

                    {inputFields.selected_player?.player?.__typename === 'Footballer' ? <FootballCardModal alias={inputFields.selected_player?.player?.alias} open={openViewCard} handleClose={handleCloseCard} /> : ''}
                  </AvatarGroup>

                  <Grid
                    container
                    className={'company-description'}
                    sx={{
                      position: 'relative',
                      zIndex: '1',
                      p: team?.bios?.length === 0 ? '0' : { xs: '0 15%', md: '0% 20%' },
                    }}
                  >
                    <Grid item xl={12} xs={12} sm={12}>
                      {team?.bios?.find((d: any) => d.language === inputFields.description_language)?.content ? (
                        <Typography
                          variant="body1"
                          id="tag-font"
                          sx={{
                            m: '25px 0 0',
                            fontSize: { xs: '1.7em' },
                            fontWeight: '500',
                            lineHeight: '27px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          <LongTextFormatter max_length={150} content={team?.bios.find((d: any) => d.language === inputFields.description_language)?.content} />
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {team?.social_medias?.length > 0 ? (
                  <Grid sx={{ p: '0', overflow: 'auto', width: '100%', textAlign: 'center' }}>
                    <Stack direction="row" className={'locations'} sx={{ display: 'inline-flex', p: '0 5%', justifyContent: 'center' }}>
                      {team?.social_medias?.map((s: any) => (
                        <Grid key={s?.id} component={'a'} target={'_blank'} href={s?.link} sx={{ width: '80px' }}>
                          <Button variant="text" size="large" sx={{ 'bgcolor': { xs: 'rgba(247, 251, 250, .0)', sm: 'rgba(247, 251, 250, .0)' }, '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': '50px', 'width': { xs: '50px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                            <Typography sx={{ transform: 'scale(1.5)', display: 'inline-flex', alignItems: 'center', fontWeight: '700', textDecoration: 'none', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, 1)' : 'rgba(15,15,15,1)' }}>{ALL_SOCIAL_MEDIAS?.find((m: any) => m?.value === s?.name)?.icon_white}</Typography>
                          </Button>
                        </Grid>
                      ))}
                    </Stack>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid sx={{ mt: { xs: '20px', sm: '0' }, justifyContent: { xs: 'start', sm: 'center' }, overflow: 'auto', width: '100%', display: { xs: 'inline-flex', sm: 'inline-block' } }}>
                  <Grid container className={'profile-games'} sx={{ minWidth: '560px', textAlign: 'center', m: { xs: '0px auto 0', sm: '20px auto 0' }, width: '90%' }}>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                            }}
                          >
                            <NumberFormatter number={NB_GAMES ?? <>0</>} />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Stats_Games' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                            }}
                          >
                            <NumberFormatter number={NB_GAMES_WON ?? <>0</>} />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Stats_Wins' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                            }}
                          >
                            <NumberFormatter number={NB_GAMES_DRAW ?? <>0</>} />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Stats_Draws' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                            }}
                          >
                            <NumberFormatter number={NB_GAMES_LOST ?? <>0</>} />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Stats_Loss' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container className={'profile-rank'} sx={{ minWidth: '560px', textAlign: 'center', m: { xs: '0px auto 0', sm: '20px auto 0' }, width: '90%' }}>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                            }}
                          >
                            <NumberFormatter number={NB_SCORED_GOALS ?? <>0</>} />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Stats_Goals' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                            }}
                          >
                            <NumberFormatter number={NB_CONCEDED_GOALS ?? <>0</>} />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Stats_Conceded' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" sx={{ 'color': 'rgba(199, 240, 11, 1)', 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                              // color: 'rgba(199, 240, 11, 1) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {WORLD_RANK ? (
                              <>
                                <NumberFormatter number={WORLD_RANK} />
                                <Typography variant="caption" sx={{ fontSize: '.4em', textTransform: 'lowercase' }}>{`${intl.formatMessage({ id: WORLD_RANK >= 1 && WORLD_RANK <= 3 ? `Global_Stats_World_Sign_${WORLD_RANK}` : 'Global_Stats_World_Sign' })}`}</Typography>
                              </>
                            ) : (
                              <>&infin;</>
                            )}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              // color: 'rgba(199, 240, 11, 1) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Stats_World' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Button variant="text" size="large" onClick={() => handleOpenDrawer(!openFollowersList)} sx={{ 'color': 'rgba(199, 240, 11, 1)', 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, .08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <Grid>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '2.5em',
                              fontWeight: '700',
                              // color: 'rgba(199, 240, 11, 1) !important',
                            }}
                          >
                            <NumberFormatter number={NB_FOLLOWERS ?? <>0</>} />
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              m: '0',
                              fontSize: '.9em',
                              fontWeight: '200',
                              // color: 'rgba(199, 240, 11, 1) !important',
                              textTransform: 'capitalize',
                            }}
                          >
                            {team?.followers?.length > 1 ? `${intl.formatMessage({ id: 'Global_Stats_Followers' })}` : `${intl.formatMessage({ id: 'Global_Stats_Follower' })}`}
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid id="max-width" sx={{ 'p': { xs: '20px 10% 0', sm: '30px 20% 0' }, 'md': '30px 25% 0', 'textAlign': 'center', 'width': '100%', '& button': { border: 'none !important' } }}>
                  {team?.followers?.find((f: any) => f?.id === loggedPlayer?.id) ? (
                    <LoadingButton className="button-cancel" loading={unfollowedEntity.loading} onClick={() => handleUnfollowTeam(team?.id)} size="small" sx={{ opacity: '.4', m: { xs: '5% 2.5%' } }}>
                      {`${intl.formatMessage({ id: 'Global_Button_Unfollow' })}`}
                    </LoadingButton>
                  ) : (
                    <LoadingButton className="button-green-reverse" loading={followedEntity.loading} onClick={() => handleFollowTeam(team?.id)} size="small" sx={{ m: { xs: '5% 2.5%' } }}>
                      {`${intl.formatMessage({ id: 'Global_Button_Follow' })}`}
                    </LoadingButton>
                  )}

                  <ViewAvailabilitiesModal title={team?.name} owners_ids={[team?.id]} owner_type={team?.__typename} />

                  {loggedPlayer?.email?.status === 'VERIFIED' && team?.captains?.[0]?.player?.player?.username && loggedPlayer?.username && loggedPlayer?.username !== team?.captains?.[0]?.player?.player?.username ? (
                    <>
                      <IconButton className="button-white-reverse" onClick={handleSendMessage} sx={{ border: 'none !important', opacity: '.4', m: { xs: '5% 2.5%' } }}>
                        <ChatsIcon />
                      </IconButton>
                      <MessengerModal
                        open={openSendMessage}
                        handleClose={() => setOpenSendMessage(false)}
                        new_conversation={{
                          title: `${team?.captains?.[0]?.player?.player?.username} x ${loggedPlayer?.username} conversation`,
                          author_id: loggedPlayer?.id,
                          mandatory_participants: _.concat({ id: team?.captains?.[0]?.player?.player?.id, name: team?.captains?.[0]?.player?.player?.username, label: team?.captains?.[0]?.player?.player?.username, avatar: (team?.captains?.[0]?.player || team?.captains?.[0]?.player?.player)?.avatar?.path }),
                          participants: _.concat({ id: loggedPlayer?.id, avatar: loggedPlayer?.avatar?.path }, { id: team?.captains?.[0]?.player?.player?.id, avatar: (team?.captains?.[0]?.player || team?.captains?.[0]?.player?.player)?.avatar?.path }),
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
                <FollowersDrawer open={openFollowersList} trigger={setOpenFollowersList} index={0} followers={team?.followers} followings={null} handleRefetch={handleRefetch} />
              </Grid>

              <Grid className={`${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`} sx={{ bgcolor: 'rgba(15,15,15,1)' }}>
                <AppBar position="sticky">
                  <Tabs value={value} onChange={handleChange} scrollButtons="auto" aria-label="scrollable auto tabs example" variant={isMobile ? 'scrollable' : 'fullWidth'} centered={!isMobile}>
                    <Tab
                      label={`${intl.formatMessage({ id: 'Global_Tabs_Overview' })}`}
                      icon={<OverviewIcon />}
                      {...a11yProps(0)}
                      onClick={() => handleChangeIndex(0)}
                      style={{
                        paddingLeft: '5%',
                        paddingRight: '5%',
                      }}
                    />

                    {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                      <Tab
                        label={`${intl.formatMessage({ id: 'Global_Tabs_Notifications' })}`}
                        icon={<NotificationIcon />}
                        {...a11yProps(1)}
                        onClick={() => handleChangeIndex(1)}
                        style={{
                          paddingLeft: '5%',
                          paddingRight: '5%',
                        }}
                      />
                    ) : (
                      ''
                    )}

                    <Tab
                      label={`${intl.formatMessage({ id: 'Global_Tabs_Calendar' })}`}
                      icon={<VersusIcon />}
                      {...a11yProps(2)}
                      onClick={() => handleChangeIndex(2)}
                      style={{
                        paddingLeft: '5%',
                        paddingRight: '5%',
                      }}
                    />

                    {team?.team_lineups?.length > 0 && (team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) ? (
                      <Tab
                        label={`${team?.team_lineups?.length} ${team?.team_lineups?.length > 1 ? `${intl.formatMessage({ id: 'Global_Tabs_Lineups' })}` : `${intl.formatMessage({ id: 'Global_Tabs_Lineup' })}`}`}
                        icon={<TeamsIcon />}
                        {...a11yProps(3)}
                        onClick={() => handleChangeIndex(3)}
                        style={{
                          paddingLeft: '5%',
                          paddingRight: '5%',
                          whiteSpace: 'nowrap',
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {team?.team_lineups?.length > 0 ? (
                      <Tab
                        label={`${intl.formatMessage({ id: 'Global_Tabs_Statistics' })}`}
                        icon={<StatisticsIcon />}
                        {...a11yProps(4)}
                        onClick={() => handleChangeIndex(4)}
                        style={{
                          paddingLeft: '5%',
                          paddingRight: '5%',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Tabs>
                </AppBar>

                <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
                  <TabPanel value={value} index={0}>
                    {(loadedFootballTeam.error && loadedFootballTeam.called) || loadedFootballTeam.loading ? (
                      <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
                    ) : (
                      <Grid container className={'list-flexs'} sx={{ p: '0px 0 150px', minHeight: '30vh' }}>
                        <Grid item xs={12} md={12} lg={12} className={'tab'} sx={{ 'textAlign': 'center', 'm': '0px auto 0', '& .leaflet-marker-icon': { borderRadius: '100px' }, '& .leaflet-control-attribution': { display: 'none' } }}>
                          {team?.originate_location?.coordinates?.latitude ? (
                            <MapContainer center={[team?.originate_location?.coordinates?.latitude, team?.originate_location?.coordinates?.longitude]} zoom={11} scrollWheelZoom={false} style={{ height: '250px' }}>
                              <TileLayer attribution="" url={REACT_APP_CUSTOM_MAP_URL} />
                              <Marker position={[team?.originate_location?.coordinates?.latitude, team?.originate_location?.coordinates?.longitude]} icon={SelectedIcon} />
                            </MapContainer>
                          ) : (
                            ''
                          )}

                          <Grid container className="players-title" sx={{ p: { xs: '0 5% 0px', sm: '0 5% 10px' } }}>
                            <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', m: '10px auto 0' }}>
                              <Typography sx={{ fontWeight: '700', fontSize: { xs: '1.1em', sm: '1.4em', md: '1.4em' }, p: '0' }}>{`${intl.formatMessage({ id: 'FootballTeam_Squad_Title' })}`}</Typography>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right', m: '10px auto 0' }}>
                              {team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.length <= 1 ? (
                                ''
                              ) : team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                <>
                                  <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleOpenNewTeamPlayer} sx={{ boxShadow: 'none', ml: '10px', whiteSpace: 'nowrap', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                                    <Add />
                                    &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Add_Player' })}`}
                                  </Fab>

                                  {team?.team_lineups?.length > 0 ? (
                                    ''
                                  ) : (
                                    <>
                                      <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleOpenNewTeamLineup} sx={{ boxShadow: 'none', ml: '10px', whiteSpace: 'nowrap', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                                        <Add />
                                        &nbsp;{`${intl.formatMessage({ id: 'Global_Button_New_Lineup' })}`}
                                      </Fab>
                                      <AddTeamLineupModal team_id={team?.id} team_sport={team?.__typename} team_type={team?.type} open={openNewTeamLineup} handleClose={handleCloseNewTeamLineup} goToLineups={handleChangeIndexToLineup} />
                                    </>
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>

                          <Grid container className="players-list" sx={{ overflow: 'auto', mt: { xs: '30px', sm: '20px', md: '0' } }}>
                            <Stack direction="row">
                              {_.uniqBy(team?.players, 'id')?.filter((p: any) => p?.status === 'ACTIVE')?.length > 0 ? (
                                <>
                                  {_.uniqBy(team?.players, 'id')
                                    ?.filter((p: any) => p?.status === 'ACTIVE')
                                    ?.map((p: any, index: number) => (
                                      <Card
                                        key={index}
                                        className={'card'}
                                        sx={{
                                          bgcolor: 'rgba(247, 251, 250, .1)',
                                          p: '0px',
                                          width: { xs: '180px', md: '200px' },
                                          height: { xs: '180px', md: '200px' },
                                          m: '0 10px',
                                          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.9) 70%), url(${p?.player?.cover?.path})`,
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Grid container>
                                          <CardHeader
                                            avatar={team?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : ''}
                                            action={
                                              <>
                                                {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) ||
                                                team?.captains?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id && p?.player?.player?.id === loggedPlayer?.id) ||
                                                team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id && p?.player?.player?.id === loggedPlayer?.id) ||
                                                team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                                  <IconButton id={`fade-button-team-player`} aria-controls={openPlayerSettings ? `fade-button-menu-player` : undefined} aria-haspopup="true" aria-expanded={openPlayerSettings ? 'true' : undefined} onClick={(event: any) => handleOpenPlayerSettings(event, p)}>
                                                    <MoreVert />
                                                  </IconButton>
                                                ) : (
                                                  ''
                                                )}
                                              </>
                                            }
                                            sx={{ p: '0 10px', width: '100%', height: '32px' }}
                                          />
                                          <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid component={Link} to={`/u/${p?.player?.player?.username}`} sx={{ 'textDecoration': 'none', '& *:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                                              <Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} sx={{ m: 'auto', width: { xs: '90px' }, height: { xs: '90px' } }} />
                                              <Typography sx={{ fontWeight: '700' }}>
                                                {p?.team_number ? (
                                                  <Typography component="span" sx={{ fontWeight: '700' }}>
                                                    #{p?.team_number}&nbsp;&nbsp;
                                                  </Typography>
                                                ) : (
                                                  ''
                                                )}
                                                {p?.player?.alias}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    ))}

                                  <Menu
                                    id="fade-button-menu-player"
                                    keepMounted
                                    MenuListProps={{
                                      'aria-labelledby': 'fade-button-team-player',
                                      'dense': true,
                                    }}
                                    anchorEl={anchorPlayerSettings}
                                    open={openPlayerSettings}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                  >
                                    {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                      <Grid>
                                        <MenuItem>
                                          {team?.captains?.find((c: any) => c?.id === inputFields?.selected_player?.id) ? (
                                            <Button
                                              variant="text"
                                              sx={{
                                                '&:hover': {
                                                  background: 'transparent !important',
                                                },
                                              }}
                                              onClick={() => handleDeleteCaptain(inputFields?.selected_player?.id)}
                                              disabled={deletedTeamCaptain.loading}
                                              className={deletedTeamCaptain.loading ? 'button-disabled' : ''}
                                            >
                                              {`${intl.formatMessage({ id: 'FootballTeam_Remove_Captain' })}`}
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="text"
                                              sx={{
                                                '&:hover': {
                                                  background: 'transparent !important',
                                                },
                                              }}
                                              onClick={() => handleAddCaptain(inputFields?.selected_player?.id)}
                                              disabled={newTeamCaptain.loading}
                                              className={newTeamCaptain.loading ? 'button-disabled' : ''}
                                            >
                                              {`${intl.formatMessage({ id: 'FootballTeam_Promote_Captain' })}`}
                                            </Button>
                                          )}
                                        </MenuItem>
                                      </Grid>
                                    ) : (
                                      ''
                                    )}

                                    <MenuItem>
                                      {team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.id === inputFields?.selected_player?.id) ? (
                                        <Button
                                          variant="text"
                                          sx={{
                                            '&:hover': {
                                              background: 'transparent !important',
                                            },
                                          }}
                                          onClick={handleOpenPickNumber}
                                        >
                                          {`${intl.formatMessage({ id: 'FootballTeam_Choose_Number' })}`}
                                        </Button>
                                      ) : (
                                        ''
                                      )}
                                    </MenuItem>

                                    {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.id === inputFields?.selected_player?.id) || team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                      <Grid>
                                        {team?.captains?.find((c: any) => c?.id === inputFields?.selected_player?.id) ? (
                                          ''
                                        ) : (
                                          <MenuItem>
                                            <Button
                                              variant="text"
                                              sx={{
                                                '&:hover': {
                                                  background: 'transparent !important',
                                                },
                                              }}
                                              onClick={() => handleDeleteTeamPlayer(inputFields?.selected_player?.id)}
                                              disabled={deletedTeamPlayer.loading}
                                              className={deletedTeamPlayer.loading ? 'button-disabled' : 'button-red-reversed'}
                                              // startIcon={deletedTeamPlayer.loading ? <CircularProgress size={20} className="orange-loader" /> : <Delete />}
                                            >
                                              {`${intl.formatMessage({ id: 'Global_Button_Delete_Player' })}`}
                                            </Button>
                                          </MenuItem>
                                        )}
                                      </Grid>
                                    ) : (
                                      ''
                                    )}
                                  </Menu>
                                </>
                              ) : (
                                <Card
                                  className={'card'}
                                  sx={{
                                    bgcolor: 'rgba(247, 251, 250, .04)',
                                    color: 'rgba(247, 251, 250, .4)',
                                    width: { xs: '300px', md: '300px' },
                                    height: { xs: '200px', md: '200px' },
                                    m: '0 20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {`${intl.formatMessage({ id: 'Global_No_Players' })}`}
                                </Card>
                              )}

                              {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                <Card
                                  sx={{
                                    'border': 'solid 1px rgba(247, 251, 250, .1)',
                                    'bgcolor': 'rgba(102, 130, 19, 0.04)',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    'display': 'flex',
                                    'p': '0px',
                                    'width': { xs: '180px', md: '200px' },
                                    'height': { xs: '180px', md: '200px' },
                                    'm': '0 30px',
                                    '&:hover': {
                                      bgcolor: 'rgba(102, 130, 19, 0.08)',
                                    },
                                  }}
                                  onClick={handleOpenNewTeamPlayer}
                                >
                                  <Typography sx={{ fontSize: '1.2em', lineHeight: '25px' }}>
                                    {`${intl.formatMessage({ id: 'Global_Button_Add_Player' })}`}
                                    <br />
                                    <span id="tag-font"> {`${intl.formatMessage({ id: 'FootballTeam_New_Player_Quote' })}`}</span>
                                    <br />
                                    <IconButton className="button-green-reverse" sx={{ border: 'none !important' }}>
                                      <Add />
                                    </IconButton>
                                  </Typography>
                                </Card>
                              ) : (
                                ''
                              )}
                            </Stack>

                            <PickTeamPlayerNumberModal open={openPickNumber} team_player_id={inputFields.selected_player?.id} team_id={team?.id} handleClose={handleClosePickNumber} />
                            <AddNewTeamPlayerModal open={openNewTeamPlayer} handleClose={handleCloseNewTeamPlayer} team_id={team?.id} />
                          </Grid>

                          {team?.managers?.filter((p: any) => p?.status === 'AVAILABLE')?.length === 0 && !team?.players?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) && team?.team_founder?.player?.id !== loggedPlayer?.id ? (
                            ''
                          ) : (
                            <>
                              <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, .1)', mt: '20px' }} />
                              <Grid container className="players-title" sx={{ p: { xs: '0 5% 0px', sm: '0 5% 10px' } }}>
                                <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', m: '10px auto 0' }}>
                                  <Typography sx={{ fontWeight: '700', fontSize: { xs: '1.1em', sm: '1.4em', md: '1.4em' }, p: '0' }}>{`${intl.formatMessage({ id: 'FootballTeam_Managers_Title' })}`}</Typography>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right', m: '10px auto 0' }}>
                                  {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                    <>
                                      <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleOpenNewTeamManager} sx={{ boxShadow: 'none', ml: '10px', whiteSpace: 'nowrap', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                                        <Add />
                                        &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Add_Manager' })}`}
                                      </Fab>
                                      <AddNewTeamManagerModal open={openNewTeamManager} handleClose={handleCloseNewTeamManager} team_id={team?.id} />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          )}
                          <Grid container className="managers-list" sx={{ overflow: 'auto', mt: { xs: '30px', sm: '20px', md: '0' } }}>
                            <Stack direction="row">
                              {team?.managers?.filter((p: any) => p?.status === 'AVAILABLE')?.length > 0 ? (
                                <>
                                  {team?.managers
                                    ?.filter((p: any) => p?.status === 'AVAILABLE')
                                    ?.map((p: any, index: number) => (
                                      <Card
                                        key={index}
                                        className={'card'}
                                        sx={{
                                          bgcolor: 'rgba(247, 251, 250, .1)',
                                          p: '0px',
                                          width: { xs: '180px', md: '200px' },
                                          height: { xs: '180px', md: '200px' },
                                          m: '0 10px',
                                          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.9) 70%), url(${p?.cover?.path})`,
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Grid container>
                                          <CardHeader
                                            avatar={team?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : ''}
                                            action={
                                              <>
                                                {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.captains?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id && p?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                                  <IconButton className="button-red-reversed" onClick={() => handleDeleteNewTeamPlayer(p?.id)} disabled={updatedTeamManagers.loading}>
                                                    <Delete />
                                                  </IconButton>
                                                ) : (
                                                  ''
                                                )}
                                              </>
                                            }
                                            sx={{ p: '0 10px', width: '100%', height: '32px' }}
                                          />
                                          <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid component={Link} to={`/u/${p?.username}`} sx={{ 'textDecoration': 'none', '& *:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                                              <Avatar src={p?.avatar?.path} alt={p?.username} sx={{ m: 'auto', width: { xs: '90px' }, height: { xs: '90px' } }} />
                                              <Typography sx={{ fontWeight: '700' }}>{p?.username}</Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    ))}
                                </>
                              ) : team?.managers?.filter((p: any) => p?.status === 'AVAILABLE')?.length === 0 && !team?.players?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) && team?.team_founder?.player?.id !== loggedPlayer?.id ? (
                                ''
                              ) : (
                                <Card
                                  className={'card'}
                                  sx={{
                                    bgcolor: 'rgba(247, 251, 250, .04)',
                                    color: 'rgba(247, 251, 250, .4)',
                                    width: { xs: '300px', md: '300px' },
                                    height: { xs: '200px', md: '200px' },
                                    m: '0 20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {`${intl.formatMessage({ id: 'Global_No_Manager' })}`}
                                </Card>
                              )}

                              {team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                <Card
                                  sx={{
                                    'border': 'solid 1px rgba(247, 251, 250, .1)',
                                    'bgcolor': 'rgba(102, 130, 19, 0.04)',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    'display': 'flex',
                                    'p': '0px',
                                    'width': { xs: '180px', md: '200px' },
                                    'height': { xs: '180px', md: '200px' },
                                    'm': '0 30px',
                                    '&:hover': {
                                      bgcolor: 'rgba(102, 130, 19, 0.08)',
                                    },
                                  }}
                                  onClick={handleOpenNewTeamManager}
                                >
                                  <Typography sx={{ fontSize: '1.2em', lineHeight: '25px' }}>
                                    {`${intl.formatMessage({ id: 'Global_Button_Add_Manager' })}`}
                                    <br />
                                    <span id="tag-font"> {`${intl.formatMessage({ id: 'FootballTeam_Start_New_Manager_Quote' })}`}</span>
                                    <br />
                                    <IconButton className="button-green-reverse" sx={{ border: 'none !important' }}>
                                      <Add />
                                    </IconButton>
                                  </Typography>
                                </Card>
                              ) : (
                                ''
                              )}
                            </Stack>
                          </Grid>

                          <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, .1)', mt: '20px' }} />
                          <Grid container className="next-challenges-title" sx={{ p: '0px 5% 0' }}>
                            <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', m: '10px auto 0' }}>
                              <Typography sx={{ fontWeight: '700', fontSize: { xs: '1.1em', sm: '1.4em', md: '1.4em' }, p: '0' }}>{`${intl.formatMessage({ id: 'FootballTeam_Next_Challenges_Title' })}`}</Typography>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right', m: '10px auto 0' }}>
                              {team?.captains?.length > 0 && (team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) ? (
                                <Fab variant="extended" size="small" className="button-green-reverse" component={Link} to={'/new_challenge'} sx={{ boxShadow: 'none', m: '0 0 0 10px', whiteSpace: 'nowrap', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                                  <Add />
                                  &nbsp;{`${intl.formatMessage({ id: 'Global_Button_New_Challenge' })}`}
                                </Fab>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                          <Grid container className="next-challengs-list" sx={{ overflow: 'auto', mt: { xs: '30px', sm: '20px', md: '20px' } }}>
                            <Stack direction="row">
                              {team?.team_lineups
                                ?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
                                ?.flat()
                                ?.filter((c: any) => c?.start_date > Date.now())?.length > 0 ? (
                                <>
                                  {team?.team_lineups
                                    ?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
                                    ?.flat()
                                    ?.filter((c: any) => c?.start_date > Date.now())
                                    ?.map((challenge: any, index: number) => (
                                      <Card
                                        key={index}
                                        className={'card'}
                                        sx={{
                                          bgcolor: 'rgba(247, 251, 250, .1)',
                                          p: '0px',
                                          width: { xs: '300px', md: '450px' },
                                          height: { xs: '200px', md: '200px' },
                                          m: '0 10px',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <CardMedia
                                          sx={{
                                            height: '-webkit-fill-available',
                                            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${challenge?.arena?.pictures[_.random(0, challenge?.arena?.pictures?.length - 1)]?.path})`,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Grid container sx={{ justifyContent: 'center' }}>
                                            <Typography
                                              component={Link}
                                              to={`/challenge/${challenge?.short_id}`}
                                              sx={{
                                                'lineHeight': '20px',
                                                'fontWeight': '800',
                                                'fontSize': '1.1em',
                                                'textDecoration': 'none',
                                                'color': 'rgba(247, 251, 250, 1)',
                                                '&:hover': {
                                                  color: 'rgba(251, 88, 2, 1)',
                                                },
                                              }}
                                            >
                                              {challenge?.title}
                                            </Typography>
                                            {challenge?.status === 'CANCELLED' ? (
                                              <Typography
                                                className={challenge?.status}
                                                sx={{
                                                  fontSize: '1em',
                                                  fontWeight: '700',
                                                  textTransform: 'uppercase',
                                                  width: '100%',
                                                }}
                                              >
                                                {challenge?.status}
                                              </Typography>
                                            ) : (
                                              ''
                                            )}
                                            <Typography
                                              sx={{
                                                fontSize: '.7em',
                                                textTransform: 'uppercase',
                                                width: '100%',
                                              }}
                                            >
                                              {challenge?.type}
                                            </Typography>

                                            <Typography
                                              variant="caption"
                                              sx={{
                                                mt: '10px',
                                                width: '100%',
                                                color: 'rgba(247, 251, 250, 1)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <Moment format="d MMM yyyy HH:mm">{challenge?.start_date}</Moment>
                                              {challenge?.start_date + ONE_DAY_MILLISEC > challenge?.end_date ? (
                                                <>
                                                  &nbsp;[<Moment format="HH:mm">{challenge?.start_date}</Moment>
                                                  &nbsp;-&gt;&nbsp;
                                                  <Moment format="HH:mm">{challenge?.end_date}</Moment>]
                                                </>
                                              ) : (
                                                <>
                                                  &nbsp;-&nbsp;
                                                  <Moment format="D MMM YYYY">{challenge?.end_date}</Moment>
                                                </>
                                              )}
                                            </Typography>

                                            <Typography
                                              variant="caption"
                                              sx={{
                                                width: '100%',
                                                color: 'rgba(247, 251, 250, 1)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <LocationIcon
                                                sx={{
                                                  color: 'rgba(19, 96, 13, .5)',
                                                }}
                                              />
                                              &nbsp;
                                              {challenge?.complex?.location?.city?.name}, {challenge?.complex?.location?.country?.name}
                                              &nbsp;
                                              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${challenge?.complex?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${challenge?.complex?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                            </Typography>
                                          </Grid>
                                        </CardMedia>
                                      </Card>
                                    ))}
                                </>
                              ) : (
                                <Card
                                  className={'card'}
                                  sx={{
                                    bgcolor: 'rgba(247, 251, 250, .04)',
                                    color: 'rgba(247, 251, 250, .4)',
                                    width: { xs: '300px', md: '300px' },
                                    height: { xs: '200px', md: '200px' },
                                    m: '0 20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {`${intl.formatMessage({ id: 'Global_No_Challenges' })}`}
                                </Card>
                              )}

                              {team?.captains?.length > 0 && (team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) ? (
                                <Card
                                  sx={{
                                    'border': 'solid 1px rgba(247, 251, 250, .1)',
                                    'bgcolor': 'rgba(102, 130, 19, .04)',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    'display': 'flex',
                                    'p': '0',
                                    'm': '0 5%',
                                    'textDecoration': 'none',
                                    'width': { xs: '300px', md: '450px' },
                                    'height': { xs: '200px', md: '200px' },
                                    '&:hover': {
                                      bgcolor: 'rgba(102, 130, 19, 0.08)',
                                    },
                                  }}
                                  component={Link}
                                  to={'/new_challenge'}
                                >
                                  <Typography sx={{ fontSize: '1.2em', lineHeight: '25px' }}>
                                    {`${intl.formatMessage({ id: 'FootballTeam_No_Challenges' })}`}
                                    <br />
                                    <span id="tag-font"> {`${intl.formatMessage({ id: 'FootballTeam_Start_New_Challenge' })}`}</span>
                                    <br />
                                    <IconButton className="button-green-reverse" sx={{ border: 'none !important' }}>
                                      <Add />
                                    </IconButton>
                                  </Typography>
                                </Card>
                              ) : (
                                ''
                              )}
                            </Stack>
                          </Grid>

                          <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, .1)', mt: '20px' }} />
                          <Grid container className="previous-challenges-title" sx={{ p: '0px 5% 0' }}>
                            <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'left', m: '10px auto 0' }}>
                              <Typography sx={{ fontWeight: '700', fontSize: { xs: '1.1em', sm: '1.4em', md: '1.4em' }, p: '0' }}>{`${intl.formatMessage({ id: 'FootballTeam_Last_Challenges' })}`}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container className="previous-challengs-list" sx={{ overflow: 'auto', mt: { xs: '30px', sm: '20px', md: '20px' } }}>
                            <Stack direction="row">
                              {team?.team_lineups
                                ?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
                                ?.flat()
                                ?.filter((c: any) => c?.start_date <= Date.now())?.length > 0 ? (
                                <>
                                  {team?.team_lineups
                                    ?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
                                    ?.flat()
                                    ?.filter((c: any) => c?.start_date <= Date.now())
                                    ?.map((challenge: any, index: number) => (
                                      <Card
                                        key={index}
                                        className={'card'}
                                        sx={{
                                          bgcolor: 'rgba(247, 251, 250, .1)',
                                          p: '0px',
                                          width: { xs: '300px', md: '450px' },
                                          height: { xs: '200px', md: '200px' },
                                          m: '0 10px',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <CardMedia
                                          sx={{
                                            height: '-webkit-fill-available',
                                            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${challenge?.arena?.pictures[_.random(0, challenge?.arena?.pictures?.length - 1)]?.path})`,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Grid container sx={{ justifyContent: 'center' }}>
                                            <Typography
                                              component={Link}
                                              to={`/challenge/${challenge?.short_id}`}
                                              sx={{
                                                'lineHeight': '20px',
                                                'fontWeight': '800',
                                                'fontSize': '1.1em',
                                                'textDecoration': 'none',
                                                'color': 'rgba(247, 251, 250, 1)',
                                                '&:hover': {
                                                  color: 'rgba(251, 88, 2, 1)',
                                                },
                                              }}
                                            >
                                              {challenge?.title}
                                            </Typography>
                                            {challenge?.status === 'CANCELLED' ? (
                                              <Typography
                                                className={challenge?.status}
                                                sx={{
                                                  fontSize: '1em',
                                                  fontWeight: '700',
                                                  textTransform: 'uppercase',
                                                  width: '100%',
                                                }}
                                              >
                                                {challenge?.status}
                                              </Typography>
                                            ) : (
                                              ''
                                            )}
                                            <Typography
                                              sx={{
                                                fontSize: '.7em',
                                                textTransform: 'uppercase',
                                                width: '100%',
                                              }}
                                            >
                                              {challenge?.type}
                                            </Typography>

                                            <Typography
                                              variant="caption"
                                              sx={{
                                                mt: '10px',
                                                width: '100%',
                                                color: 'rgba(247, 251, 250, 1)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <Moment format="d MMM yyyy HH:mm">{challenge?.start_date}</Moment>
                                              {challenge?.start_date + ONE_DAY_MILLISEC > challenge?.end_date ? (
                                                <>
                                                  &nbsp;[<Moment format="HH:mm">{challenge?.start_date}</Moment>
                                                  &nbsp;-&gt;&nbsp;
                                                  <Moment format="HH:mm">{challenge?.end_date}</Moment>]
                                                </>
                                              ) : (
                                                <>
                                                  &nbsp;-&nbsp;
                                                  <Moment format="D MMM YYYY">{challenge?.end_date}</Moment>
                                                </>
                                              )}
                                            </Typography>

                                            <Typography
                                              variant="caption"
                                              sx={{
                                                width: '100%',
                                                color: 'rgba(247, 251, 250, 1)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <LocationIcon
                                                sx={{
                                                  color: 'rgba(19, 96, 13, .5)',
                                                }}
                                              />
                                              &nbsp;
                                              {challenge?.complex?.location?.city?.name}, {challenge?.complex?.location?.country?.name}
                                              &nbsp;
                                              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${challenge?.complex?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${challenge?.complex?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                            </Typography>
                                          </Grid>
                                        </CardMedia>
                                      </Card>
                                    ))}
                                </>
                              ) : team?.captains?.length > 0 && (team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) ? (
                                <Card
                                  sx={{
                                    'border': 'solid 1px rgba(247, 251, 250, .1)',
                                    'bgcolor': 'rgba(102, 130, 19, .04)',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    'display': 'flex',
                                    'p': '0',
                                    'm': '0 5%',
                                    'textDecoration': 'none',
                                    'width': { xs: '300px', md: '450px' },
                                    'height': { xs: '200px', md: '200px' },
                                    '&:hover': {
                                      bgcolor: 'rgba(102, 130, 19, 0.08)',
                                    },
                                  }}
                                  component={Link}
                                  to={'/new_challenge'}
                                >
                                  <Typography sx={{ fontSize: '1.4em', lineHeight: '25px' }}>
                                    {`${intl.formatMessage({ id: 'FootballTeam_No_Challenges' })}`}
                                    <br />
                                    <span id="tag-font">{`${intl.formatMessage({ id: 'FootballTeam_Start_New_Challenge' })}`}</span>
                                    <br />
                                    <IconButton className="button-green-reverse">
                                      <Add />
                                    </IconButton>
                                  </Typography>
                                </Card>
                              ) : (
                                <Card
                                  className={'card'}
                                  sx={{
                                    bgcolor: 'rgba(247, 251, 250, .04)',
                                    color: 'rgba(247, 251, 250, .4)',
                                    width: { xs: '300px', md: '300px' },
                                    height: { xs: '200px', md: '200px' },
                                    m: '0 20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {`${intl.formatMessage({ id: 'Global_No_Challenges' })}`}
                                </Card>
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </TabPanel>

                  {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                    <TabPanel value={value} index={1}>
                      {(loadedFootballTeam.error && loadedFootballTeam.called) || loadedFootballTeam.loading ? (
                        loadedFootballTeam.loading ? (
                          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
                        ) : (
                          <Typography
                            variant="h3"
                            sx={{
                              p: '50px 20px',
                              fontSize: '1.3em',
                              textAlign: 'center',
                              width: '100%',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              height: '90vh',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_Button_No_Notifications' })}`}
                          </Typography>
                        )
                      ) : (
                        <Grid container className={'list-flexs'} sx={{ p: '20px 0 150px', minHeight: '30vh' }}>
                          <ListNotifications dark_mode={true} receiver_id={team?.id} />
                        </Grid>
                      )}
                    </TabPanel>
                  ) : (
                    ''
                  )}

                  <TabPanel value={value} index={2}>
                    {(loadedFootballTeam.error && loadedFootballTeam.called) || loadedFootballTeam.loading ? (
                      <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>Loading calendar, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} />
                    ) : (
                      <Grid container className={'list-flexs'} sx={{ p: '20px 0 150px' }}>
                        {!challenges || challenges?.length === 0 ? (
                          <Typography
                            variant="h3"
                            sx={{
                              p: '50px 20px',
                              fontSize: '1.3em',
                              textAlign: 'center',
                              width: '100%',
                              color: 'rgba(247, 251, 250, 0.3) !important',
                              height: '90vh',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'FootballTeam_No_Challenges' })}`}
                          </Typography>
                        ) : (
                          <>
                            <Grid container sx={{ textAlign: 'center', m: '10px 10% 0' }}>
                              {challenges?.map((challenge: any, index: number) => (
                                <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: { xs: '0', sm: '0 30px' }, m: { xs: '0 0 30px' } }}>
                                  <Card
                                    className={'card'}
                                    sx={{
                                      'bgcolor': 'rgba(247, 251, 250, .1)',
                                      'width': { xs: '100%', md: '100%' },
                                      'height': { xs: '200px', md: '200px' },
                                      'display': 'flex',
                                      'alignItems': 'center',
                                      'opacity': challenge?.end_date > Date.now() ? '1' : '.3',
                                      '&:hover': { opacity: '1' },
                                    }}
                                  >
                                    <CardMedia
                                      sx={{
                                        height: '-webkit-fill-available',
                                        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${challenge?.arena?.pictures[_.random(0, challenge?.arena?.pictures?.length - 1)]?.path})`,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        p: '0 20px',
                                      }}
                                    >
                                      <Grid container sx={{ justifyContent: 'center' }}>
                                        <Typography
                                          component={Link}
                                          to={`/challenge/${challenge?.short_id}`}
                                          sx={{
                                            'lineHeight': '20px',
                                            'fontWeight': '700',
                                            'fontSize': '1.1em',
                                            'textDecoration': 'none',
                                            'color': 'rgba(247, 251, 250, 1)',
                                            '&:hover': {
                                              color: 'rgba(251, 88, 2, 1)',
                                            },
                                          }}
                                        >
                                          {challenge?.title}
                                        </Typography>
                                        {challenge?.status === 'CANCELLED' ? (
                                          <Typography
                                            className={challenge?.status}
                                            sx={{
                                              fontSize: '1em',
                                              fontWeight: '700',
                                              textTransform: 'uppercase',
                                              width: '100%',
                                            }}
                                          >
                                            {challenge?.status}
                                          </Typography>
                                        ) : (
                                          ''
                                        )}
                                        <Typography
                                          sx={{
                                            fontSize: '.7em',
                                            textTransform: 'uppercase',
                                            width: '100%',
                                          }}
                                        >
                                          {challenge?.type}
                                        </Typography>

                                        <Typography
                                          variant="caption"
                                          sx={{
                                            mt: '10px',
                                            width: '100%',
                                            color: 'rgba(247, 251, 250, 1)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Moment format="D MMM YYYY">{challenge?.start_date}</Moment>
                                          {challenge?.start_date + ONE_DAY_MILLISEC > challenge?.end_date ? (
                                            <>
                                              &nbsp;[<Moment format="HH:mm">{challenge?.start_date}</Moment>
                                              &nbsp;-&gt;&nbsp;
                                              <Moment format="HH:mm">{challenge?.end_date}</Moment>]
                                            </>
                                          ) : (
                                            <>
                                              &nbsp;-&nbsp;
                                              <Moment format="D MMM YYYY">{challenge?.end_date}</Moment>
                                            </>
                                          )}
                                        </Typography>

                                        <Typography
                                          variant="caption"
                                          sx={{
                                            width: '100%',
                                            color: 'rgba(247, 251, 250, 1)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <LocationIcon
                                            sx={{
                                              color: 'rgba(19, 96, 13, .5)',
                                            }}
                                          />
                                          &nbsp;
                                          {challenge?.complex?.location?.city?.name}, {challenge?.complex?.location?.country?.name}
                                          &nbsp;
                                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${challenge?.complex?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${challenge?.complex?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                        </Typography>
                                      </Grid>
                                    </CardMedia>
                                  </Card>
                                </Grid>
                              ))}
                              <Grid container sx={{ justifyContent: 'center' }}>
                                {loadedChallenges.data?.findChallengesByTeamID?.length === 0 ? (
                                  ''
                                ) : (
                                  <Fab
                                    sx={{
                                      'color': 'rgba(247, 251, 250, 1)',
                                      'bgcolor': 'transparent',
                                      'boxShadow': 'none',
                                      ':hover': {
                                        bgcolor: 'transparent',
                                        color: 'rgba(168, 202, 65, 1)',
                                      },
                                    }}
                                    variant="extended"
                                    onClick={handleLoadMoreChallenges}
                                  >
                                    {loadedChallenges.loading ? (
                                      <Grid container sx={{ alignItems: 'center' }}>
                                        <CircularProgress className="green-loader" size={15} />
                                        &nbsp;&nbsp;{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
                                      </Grid>
                                    ) : (
                                      <Grid container sx={{ alignItems: 'center' }}>
                                        <Add sx={{ color: 'rgba(168, 202, 65, 1)' }} />
                                        &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Load_More' })}`}
                                      </Grid>
                                    )}
                                  </Fab>
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    )}
                  </TabPanel>

                  {team?.team_lineups?.length > 0 && (team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.players?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) ? (
                    <TabPanel value={value} index={3}>
                      {(loadedFootballTeam.error && loadedFootballTeam.called) || loadedFootballTeam.loading ? (
                        <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
                      ) : (
                        <Grid container className={'list-flexs'} sx={{ p: '50px 0 150px' }}>
                          <Grid item xs={12} md={12} lg={12} className={'tab'} sx={{ textAlign: 'center', m: '10px auto 0' }}>
                            <Grid container sx={{ p: '0 0 20px' }}>
                              <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleOpenNewTeamLineup} sx={{ boxShadow: 'none', ml: '10px', whiteSpace: 'nowrap', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                                <Add />
                                &nbsp;{`${intl.formatMessage({ id: 'Global_Button_New_Lineup' })}`}
                              </Fab>
                              <AddTeamLineupModal team_id={team?.id} team_sport={team?.__typename} team_type={team?.type} open={openNewTeamLineup} handleClose={handleCloseNewTeamLineup} goToLineups={handleChangeIndexToLineup} />
                            </Grid>
                            {team?.team_lineups?.map((lineup: any, index: number) => (
                              <Grid key={index}>
                                <Grid container className="players-title" sx={{ p: '0 5%' }}>
                                  <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', m: '10px auto 0' }}>
                                    <Typography onClick={() => handleOpenViewTeamLineup(lineup?.id)} sx={{ fontWeight: '700', fontSize: { xs: '1.1em', sm: '1.4em', md: '1.4em' }, p: '0' }}>
                                      {lineup?.name}
                                    </Typography>
                                    {/* <Typography variant="caption" sx={{ }}>since <Moment format="dddd D MMM YYYY (HH:mm)">{lineup?.created_at}</Moment></Typography> */}
                                  </Grid>
                                  <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right', m: '10px auto 0' }}>
                                    {team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || lineup?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id ? (
                                      <>
                                        <Fab variant="extended" size="small" className="button-green-reverse" onClick={() => handleOpenEditTeamLineup(lineup?.id)} sx={{ boxShadow: 'none', ml: '10px', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                                          <Edit />
                                          &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Edit_Lineup' })}`}
                                        </Fab>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container className="players-list" sx={{ overflow: 'auto', mt: { xs: '30px', sm: '20px', md: '0' } }}>
                                  <Stack direction="row">
                                    {lineup?.players?.map((p: any, index: number) => (
                                      <Card
                                        key={index}
                                        className={'card'}
                                        sx={{
                                          bgcolor: 'rgba(247, 251, 250, .1)',
                                          p: '0px',
                                          width: { xs: '180px', md: '200px' },
                                          height: { xs: '180px', md: '200px' },
                                          m: '0 10px',
                                          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.9) 70%), url(${p?.player?.cover?.path})`,
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Grid container>
                                          <CardHeader avatar={lineup?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : ''} sx={{ p: '0 10px', width: '100%', height: '32px' }} />
                                          <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid component={Link} to={`/u/${p?.player?.player?.username}`} sx={{ 'textDecoration': 'none', '& *:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                                              <Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} sx={{ m: 'auto', width: { xs: '90px' }, height: { xs: '90px' } }} />
                                              <Typography sx={{ fontWeight: '700' }}>
                                                {p?.team_number ? (
                                                  <Typography component="span" sx={{ fontWeight: '700' }}>
                                                    #{p?.team_number}&nbsp;&nbsp;
                                                  </Typography>
                                                ) : (
                                                  ''
                                                )}
                                                {p?.player?.alias}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    ))}
                                  </Stack>
                                  <Grid container sx={{ justifyContent: 'center' }}>
                                    {lineup?.players?.length === 0 ? (
                                      <Card
                                        sx={{
                                          'border': 'solid 1px rgba(247, 251, 250, .1)',
                                          'bgcolor': 'rgba(102, 130, 19, .04)',
                                          'color': 'rgba(247, 251, 250, 1)',
                                          'justifyContent': 'center',
                                          'alignItems': 'center',
                                          'display': 'flex',
                                          'p': '0',
                                          'm': '0 5%',
                                          'textDecoration': 'none',
                                          'width': { xs: '300px', md: '300px' },
                                          'height': { xs: '200px', md: '200px' },
                                          '&:hover': {
                                            bgcolor: 'rgba(102, 130, 19, 0.08)',
                                          },
                                        }}
                                        onClick={() => handleOpenEditTeamLineup(lineup?.id)}
                                      >
                                        <Typography sx={{ fontSize: '1.4em', lineHeight: '25px' }}>
                                          {`${intl.formatMessage({ id: 'FootballTeam_No_Players' })}`}
                                          <br />
                                          <span id="tag-font">{`${intl.formatMessage({ id: 'FootballTeam_Add_them' })}`}</span>
                                          <br />
                                          <IconButton className="button-green-reverse" sx={{ border: 'none !important' }}>
                                            <Add />
                                          </IconButton>
                                        </Typography>
                                      </Card>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>
                                </Grid>
                                {index === team?.team_lineups?.length - 1 ? '' : <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '5px auto 20px', width: '90%' }} />}
                              </Grid>
                            ))}
                            <Grid container sx={{ justifyContent: 'center' }}>
                              {team?.team_lineups?.length === 0 ? (
                                <Card
                                  sx={{
                                    'border': 'solid 1px rgba(247, 251, 250, .1)',
                                    'bgcolor': 'rgba(102, 130, 19, .04)',
                                    'color': 'rgba(247, 251, 250, 1)',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    'display': 'flex',
                                    'p': '0',
                                    'm': '0 5%',
                                    'textDecoration': 'none',
                                    'width': { xs: '300px', md: '300px' },
                                    'height': { xs: '200px', md: '200px' },
                                    '&:hover': {
                                      bgcolor: 'rgba(102, 130, 19, 0.08)',
                                    },
                                  }}
                                  onClick={handleOpenNewTeamLineup}
                                >
                                  <Typography sx={{ fontSize: '1.4em', lineHeight: '25px' }}>
                                    {`${intl.formatMessage({ id: 'FootballTeam_No_Lineups' })}`}
                                    <br />
                                    <span id="tag-font">{`${intl.formatMessage({ id: 'FootballTeam_Create_Lineup' })}`}</span>
                                    <br />
                                    <IconButton className="button-green-reverse" sx={{ border: 'none !important' }}>
                                      <Add />
                                    </IconButton>
                                  </Typography>
                                </Card>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>

                          <ViewTeamLineupModal open={openViewTeamLineup} handleClose={handleCloseViewTeamLineup} team_lineup_id={inputFields.selected_team_lineup} />
                          <EditTeamLineupModal open={openEditTeamLineup} handleClose={handleCloseEditTeamLineup} team_lineup_id={inputFields?.selected_team_lineup} goToHome={handleChangeIndexToHome} />
                        </Grid>
                      )}
                    </TabPanel>
                  ) : (
                    ''
                  )}

                  {team?.team_lineups?.length > 0 ? (
                    <TabPanel value={value} index={4}>
                      {(loadedFootballTeam.error && loadedFootballTeam.called) || loadedFootballTeam.loading ? (
                        <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>Loading statistics, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} />
                      ) : (
                        <Grid container className={'list-flexs'} sx={{ p: '50px 0 150px' }}>
                          <Grid container className={'actions'} sx={{ textAlign: 'center', m: '10px auto 0' }}>
                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>
                                    <NumberFormatter number={NB_SCORED_GOALS ?? <>0</>} />
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <GoalsIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Goals' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>
                                    <NumberFormatter number={NB_ASSISTS ?? <>0</>} />
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '3em' } }}>
                                  <SucceedPassesIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Assists' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>
                                    <NumberFormatter number={NB_GAMES ?? <>0</>} />
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <GamesPlayedIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Games_Played' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <ManOfTheMatchIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_MOTM' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '3em' } }}>
                                  <SucceedPassesIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Good_Passes' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <TacklesIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Shots' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <AverageDistanceIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KM_Total' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <AverageDistanceIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KM_Average' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <AverageMaxSpeedIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KMH_Max' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>

                            <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                              <Grid container>
                                <Grid item xs={10} sm={10} lg={10}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                  <AverageMaxSpeedIcon />
                                </Grid>
                              </Grid>
                              <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KMH_Average' })}`}</Typography>
                              <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </TabPanel>
                  ) : (
                    ''
                  )}
                </SwipeableViews>

                <ScrollToTop />
              </Grid>
            </CardMedia>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default FootballTeam;
