import React from 'react';
import Moment from 'react-moment';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectPersonalWallets, selectBusinessWallets } from '../../reducers/walletSlice';
import { Skeleton, Typography, AppBar, Tab, Tabs, Snackbar, ListItemIcon, MenuItem, Menu, IconButton, Grid } from '@mui/material';
import { LockOpen, Lock, MoreVert, Delete } from '@mui/icons-material';
import { CardPaymentIcon, WalletIcon, VisaCardIcon, AmexCardIcon, MasterCardIcon, PacCardIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import Modal from '../../component-modals/layout/Modal';
import SpendingCodeModal from '../../component-modals/purchase/SpendingCodeModal';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';
import ListTransactions from './ListTransactions';
import { useIntl } from 'react-intl';

interface IBankCard {
  card: { id: string };
  wallet: { id: string; type: string };
}

const BankCard: React.FC = () => {
  const FIND_BANK_CARD_BY_ID_QUERY = gql`
    query findBankCardByID($card: inputFindBankCard!) {
      findBankCardByID(card: $card) {
        id
        type
        network
        status
        card_number
        expire
        bank_account {
          id
          balance
        }
        wallets {
          id
        }
        sent_txs {
          id
          status
          execution_date
          confirmed_at
        }
        received_txs {
          id
          status
          execution_date
          confirmed_at
        }
      }
    }
  `;

  const LOCK_BANK_CARD_MUTATION = gql`
    mutation lockBankCard($card: inputLockBankCard!) {
      lockBankCard(card: $card) {
        id
        status
      }
    }
  `;

  const UNLOCK_BANK_CARD_MUTATION = gql`
    mutation unlockBankCard($card: inputUnlockBankCard!) {
      unlockBankCard(card: $card) {
        id
        status
      }
    }
  `;

  const DELETE_BANK_CARD_MUTATION = gql`
    mutation deleteBankCard($card: inputDeleteBankCard!) {
      deleteBankCard(card: $card)
    }
  `;

  const intl = useIntl();
  const navigate = useNavigate();
  const location: any = useLocation();
  const state: IBankCard = location?.state;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerPersonalWallets = useAppSelector(selectPersonalWallets);
  const loggedPlayerBusinessWallets = useAppSelector(selectBusinessWallets);

  const [wallet] = React.useState((state?.wallet?.type === 'BUSINESS' ? loggedPlayerBusinessWallets : loggedPlayerPersonalWallets)?.find((wallet: any) => wallet.id === state?.wallet?.id));
  const [bankCard, setBankCard] = React.useState(UNDEFINED_AS_ANY);

  const [openLock, setOpenLock] = React.useState(false);
  const [isLocked, setIsLocked] = React.useState(false);
  const [openLockConfirmation, setOpenLockConfirmation] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(anchorEl);

  const bank_account_tabs = [
    {
      label: 'All TXs',
      icon: '',
      fragment: <ListTransactions txs={_.concat(bankCard?.received_txs, bankCard?.received_txs)?.filter((tx: any) => tx?.id !== undefined)} />,
    },
    {
      label: 'Tx Sent',
      icon: '',
      fragment: <ListTransactions txs={bankCard?.sent_txs?.filter((tx: any) => tx?.id !== undefined)} />,
    },
    {
      label: 'Tx Received',
      icon: '',
      fragment: <ListTransactions txs={bankCard?.received_txs?.filter((tx: any) => tx?.id !== undefined)} />,
    },
  ];

  const [deleteBankCard, deletedBankCard] = useMutation(DELETE_BANK_CARD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [lockBankCard, lockedBankCard] = useMutation(LOCK_BANK_CARD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unlockBankCard, unlockedBankCard] = useMutation(UNLOCK_BANK_CARD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedBankCard = useQuery(FIND_BANK_CARD_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      card: {
        id: state?.card?.id,
        wallet_id: state?.wallet?.id,
      },
    },
  });

  React.useEffect(() => {
    if ((bankCard === undefined && loadedBankCard.data?.findBankCardByID) || !_.isMatch(bankCard, loadedBankCard.data?.findBankCardByID)) {
      setBankCard(loadedBankCard.data?.findBankCardByID);
    }
  }, [bankCard, loadedBankCard]);

  const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleOpenLock = () => {
    setOpenLock(true);
  };

  const handleIsLocked = () => {
    handleClose();

    if (bankCard?.status === 'AVAILABLE') {
      handleLockCard();
    } else {
      handleUnlockCard();
    }
    setIsLocked(true);
  };

  const handleLockCard = async () => {
    if (bankCard?.status === 'AVAILABLE' && !lockedBankCard.called) {
      try {
        const result = await lockBankCard({
          variables: {
            card: {
              id: bankCard?.id,
              wallet_id: wallet?.id,
            },
          },
        });

        if (result.data?.lockBankCard) {
          setBankCard(result.data?.lockBankCard);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleUnlockCard = async () => {
    if (bankCard?.status === 'BLOCKED' && !unlockedBankCard.called) {
      try {
        const result = await unlockBankCard({
          variables: {
            card: {
              id: bankCard?.id,
              wallet_id: wallet?.id,
            },
          },
        });

        if (result.data?.unlockBankCard) {
          setBankCard(result.data?.unlockBankCard);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenLockConfirmation = () => {
    setOpenLockConfirmation(true);
  };

  const handleIsLockedConfirmed = () => {
    handleIsLocked();
  };

  const handleDeleteCard = async () => {
    try {
      handleClose();
      await deleteBankCard({
        variables: {
          card: {
            id: bankCard?.id,
            logged_user_id: loggedPlayer?.id,
            wallet_id: state?.wallet?.id,
          },
        },
      });

      navigate(`/w/wallet`, { replace: true, state: { wallet: { id: wallet?.id, type: wallet?.type } } });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpenLockConfirmation(false);
    setOpenConfirmation(false);
    setOpenDelete(false);
    setOpenLock(false);
    // setIsLocked(false);
    setAnchorEl(null);
  };

  return (
    <Grid container className={'component-preferences bank-card-page dark-mode'} sx={{ display: 'inline-block', height: '100%' }}>
      <Grid item xl={12} xs={12} sm={12}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xl={6} xs={6} sm={6} className={'back-button'} sx={{ textAlign: 'left' }}>
            <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
          </Grid>
          <Grid item xl={6} xs={6} sm={6} className={'options'} sx={{ textAlign: 'right', p: '0 20px' }}>
            <IconButton onClick={handleOpenOptions} disabled={bankCard === undefined}>
              <MoreVert />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={optionsOpen} onClose={handleCloseOptions} PaperProps={{}}>
              {bankCard?.status === 'BLOCKED' ? (
                <MenuItem onClick={handleOpenLock}>
                  <ListItemIcon>
                    <LockOpen />
                  </ListItemIcon>
                  Unlock card
                </MenuItem>
              ) : (
                <MenuItem onClick={handleOpenLock}>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  Temporarily lock card
                </MenuItem>
              )}
              {bankCard?.bank_account && bankCard?.status === 'AVAILABLE' ? (
                <MenuItem
                  component={Link}
                  to={{ pathname: '/bk/order_bank_card' }}
                  state={{
                    wallet: {
                      id: wallet?.id,
                      type: wallet?.type,
                    },
                  }}
                >
                  <ListItemIcon>
                    <CardPaymentIcon />
                  </ListItemIcon>
                  Order new card
                </MenuItem>
              ) : (
                ''
              )}
              <MenuItem onClick={handleOpenDelete}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                Remove card
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Grid item xl={12} xs={12} sm={12} className={'title'} sx={{ p: '0 5%' }}>
          <h2>
            {bankCard?.network}&nbsp;
            {bankCard?.network === 'VISA' ? <VisaCardIcon /> : ''}
            {bankCard?.network === 'AMEX' ? <AmexCardIcon /> : ''}
            {bankCard?.network === 'MASTERCARD' ? <MasterCardIcon /> : ''}
            {bankCard?.network === 'PAC' ? <PacCardIcon /> : ''}
            <Typography
              sx={{
                color: 'rgba(247, 251, 250, .3) !important',
              }}
            >
              (Bank card) {bankCard?.wallets?.length > 1 ? `used by ${bankCard?.wallets?.length} wallets` : ''}
            </Typography>
          </h2>
        </Grid>

        <Grid item xl={12} xs={12} sm={12} className={'label'} sx={{ textAlign: 'center' }}>
          <h1>
            {bankCard?.card_number !== undefined ? (
              `${bankCard?.card_number?.substring(0, 5)}*******${bankCard?.card_number?.substring(bankCard?.card_number?.length - 3, bankCard?.card_number?.length)}`
            ) : (
              <Skeleton
                variant="text"
                sx={{
                  bgcolor: 'rgba(247, 251, 250, .2)',
                  m: 'auto',
                  width: '40%',
                }}
              />
            )}
          </h1>
          <Grid
            sx={{
              fontSize: '.8em',
              color: 'rgba(247, 251, 250, .3)',
            }}
          >
            expires <Moment fromNow>{bankCard?.expire}</Moment>
          </Grid>
          <Typography className={'status'} sx={{ textTransform: 'uppercase' }}>
            {`${intl.formatMessage({ id: 'Global_Status' })}`}:{' '}
            <strong>
              {bankCard?.status !== undefined ? (
                bankCard?.status
              ) : (
                <Skeleton
                  variant="text"
                  sx={{
                    bgcolor: 'rgba(247, 251, 250, .2)',
                    m: 'auto',
                    width: '40%',
                  }}
                />
              )}
            </strong>
          </Typography>
        </Grid>

        <Grid container className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`}>
          <AppBar position="static" sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
            <Tabs value={value} onChange={handleChange} variant={'fullWidth'} centered>
              {bank_account_tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} icon={tab.icon} {...a11yProps(index)} />
              ))}
            </Tabs>
          </AppBar>
          {bank_account_tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {tab.fragment}
            </TabPanel>
          ))}
        </Grid>
      </Grid>

      <Modal title={bankCard?.status === 'BLOCKED' ? `Unlock ${bankCard?.network}` : `Lock ${bankCard?.network}`} open={openLock} icon={<WalletIcon />} content={bankCard?.status === 'BLOCKED' ? `Do you really want to unlock ${bankCard?.network}?` : `Do you really want to lock ${bankCard?.network}?`} actionOneTitle={bankCard?.status === 'BLOCKED' ? `Yes, unlock` : `Yes, lock`} actionOne={handleOpenLockConfirmation} actionTwoTitle={'No, go back'} actionTwo={handleClose} />
      <SpendingCodeModal actionOne={handleIsLockedConfirmed} actionTwo={handleClose} open={openLockConfirmation} />

      <DeleteConfirmationModal title={`Remove ${bankCard?.network}`} open={openDelete} entityId={bankCard?.id} entityName={`${bankCard?.network}`} actionOne={handleOpenConfirmation} actionOneTitle={'Confirm removal'} actionTwo={handleClose} actionTwoTitle={'Cancel'} loading={deletedBankCard.loading} />
      <SpendingCodeModal actionOne={handleDeleteCard} actionTwo={handleClose} open={openConfirmation} />
      {isLocked ? (
        <Snackbar open={isLocked} autoHideDuration={6000} onClose={() => setIsLocked(!isLocked)}>
          <span>{bankCard?.status === 'BLOCKED' ? `${bankCard?.label} is locked.` : `${bankCard?.label} is unlocked.`}</span>
        </Snackbar>
      ) : (
        ''
      )}
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} key={index}>
      {value === index && (
        <Grid item xl={12} xs={12} sm={12} key={index} component={'span'}>
          <Grid key={index}>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default BankCard;
