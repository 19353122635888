import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogContent, DialogActions, Button, Fab, Avatar, Grid, Autocomplete, Chip, Stack, TextField, MenuItem } from '@mui/material';
import { Send, Clear, Close } from '@mui/icons-material';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { ARRAY_AS_ANY, IMAGE_LIST_AS_ANY, MEDIAS_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Controller, useForm } from 'react-hook-form';
import UploadIMGFeature from '../common/UploadIMGFeature';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { handleUpload } from '../../helpers';
import { nanoid } from 'nanoid';
import { useLocation, useNavigate } from 'react-router-dom';
import useEventLog from '../../hooks/useEventLog';

export default function NewFlexModal(props: { handleClose: any; open: any; challenge_id?: string; tournament_id?: string }) {
  const CREATE_FLEX_MUTATION = gql`
    mutation createFlex($flex: inputCreateFlex!) {
      createFlex(flex: $flex) {
        id
        short_id
      }
    }
  `;

  const SEND_CONNECT_COMPANY_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const FIND_ITEM_FLEX_BY_NAME_QUERY = gql`
    query findItemFlexByName($item: inputFindItemFlexByName!) {
      findItemFlexByName(item: $item) {
        ... on Article {
          __typename
          id
          short_id
          name
          pictures {
            path
          }
        }
        ... on Service {
          __typename
          id
          short_id
          name
          pictures {
            path
          }
        }
        ... on Rental {
          __typename
          id
          short_id
          name
          pictures {
            path
          }
        }
      }
    }
  `;

  const FIND_TOURNAMENT_BY_SHORT_ID_QUERY = gql`
    query findTournamentByShortID($short_id: String!) {
      findTournamentByShortID(short_id: $short_id) {
        id
        short_id
        title
        status
        start_date
        end_date
        is_private
        descriptions {
          language
          content
        }

        organisators {
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              logo {
                filename
                path
              }
            }
          }
        }
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        main_location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
        created_at
        updated_at
      }
    }
  `;

  const FIND_CHALLENGE_BY_ID_QUERY = gql`
    query findChallengeByID($challenge: inputFindChallengeByID!) {
      findChallengeByID(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        is_private
        start_date
        end_date
      }
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSendLog } = useEventLog();

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      caption: '',
      pictures: MEDIAS_AS_ANY,
      pictures_urls: ARRAY_AS_ANY,
      flexer_id: '',
      all_authors: ARRAY_AS_ANY,
      co_authors: ARRAY_AS_ANY,

      all_flex_items: ARRAY_AS_ANY,
      selected_flex_items: ARRAY_AS_ANY,
      item_name: '',

      tournament_id: UNDEFINED_AS_ANY,
      challenge_id: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [progress, setProgress] = React.useState(0);
  const [pictures, setPictures] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [flexUrls, setFlexUrls] = React.useState([]);
  const [openSearchItem, setOpenSearchItem] = React.useState(false);
  const [event, setEvent] = React.useState(UNDEFINED_AS_ANY);

  const [createFlex, newFlex] = useMutation(CREATE_FLEX_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendNotification, isSent] = useMutation(SEND_CONNECT_COMPANY_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_SHORT_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    skip: !session?.token?.key || !props.open || !loggedPlayer?.id,
    variables: {
      short_id: inputFields.tournament_id,
    },
  });
  const loadedChallenge = useQuery(FIND_CHALLENGE_BY_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    skip: !session?.token?.key || !props.open || !loggedPlayer?.id,
    variables: {
      challenge: {
        short_id: inputFields.challenge_id,
      },
    },
  });
  const loadedItemFlex = useQuery(FIND_ITEM_FLEX_BY_NAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.open || !loggedPlayer?.id || inputFields.item_name === '',
    variables: { item: { name: inputFields.item_name } },
  });

  const ALL_ITEM_FLEX = loadedItemFlex.data?.findItemFlexByName;
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
    type: loggedPlayer?.__typename,
  });
  const ALL_CO_FLEXERS = _.concat(
    loggedPlayer?.followers?.map((f: any) => {
      return {
        id: f?.id,
        name: f?.username,
        avatar: f?.avatar?.path,
        type: f?.__typename,
      };
    })
  );
  const ALL_SELECTED_ITEMS = _.concat(event, inputFields.selected_flex_items)?.filter((i: any) => i);

  const handleAddItemFlex = (item: any) => {
    const newList = _.concat(item, inputFields.selected_flex_items)?.filter((i: any) => i);
    setValue('selected_flex_items', newList);
  };

  const handleDeleteItemFlex = (item_id: string) => {
    const newList = inputFields.selected_flex_items?.filter((i: any) => i?.id !== item_id);
    setValue('selected_flex_items', newList);
    if (event?.id === item_id) setEvent('');
  };

  const onChangePictures = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setValue('pictures', imageList);
    setPictures(imageList);
  };

  const handleCoPublishers = (event: any, value: any) => {
    const newList = _.concat(value, inputFields.co_authors);
    setValue('co_authors', newList);
  };

  const handleDeleteCoPublisher = (publisher_id: string) => {
    const newList = inputFields.co_authors.filter((a: any) => a?.id !== publisher_id);
    setValue('co_authors', newList);
  };

  const handleCreateFlex = async () => {
    try {
      setProgress(1);

      const pictureUploadPromises = pictures.map(async (pix: any) => {
        await handleUpload(pix.file, flexUrls, setFlexUrls, session, loggedPlayer?.id);
      });
      await Promise.all(pictureUploadPromises);

      const result = await createFlex({
        variables: {
          flex: {
            short_id: nanoid(),
            caption: _.trim(inputFields.caption),
            author_id: inputFields.flexer_id,
            co_authors: inputFields.co_authors.map((a: any) => {
              return { id: a?.id, type: a?.type };
            }),
            pictures: pictures.map((p: any, index: number) => {
              return {
                filename: p.file?.name ? p.file?.name : p.file.filename,
                size: p.file.size,
                type: p.file.type,
                path: flexUrls[index],
              };
            }),
            flexed_items: _.concat(
              event?.id,
              inputFields.selected_flex_items?.map((i: any) => i?.id)
            )?.filter((i: any) => i),
          },
        },
      });

      if (result.data?.createFlex) {
        if (inputFields.co_authors?.length > 0) {
          inputFields.co_authors.map(
            async (author: any) =>
              await sendNotification({
                variables: {
                  notification: {
                    title: 'Co-publish Flex',
                    content: `${ALL_FLEXERS.find((c: any) => c?.id === inputFields.flexer_id)?.name} wants to co-publish a flex with you.`,
                    actions: [`ACCEPT_FLEX_CO_PUBLISH_@_${result.data?.createFlex?.id}_&_${author?.id}`, `VIEW_FLEX_@_${result.data?.createFlex?.short_id}`],
                    sender_id: inputFields.flexer_id,
                    receivers_ids: [author?.id],
                  },
                },
              })
          );
        }

        setPictures([]);
        setFlexUrls([]);

        navigate(`/f/${result.data?.createFlex?.short_id}`, {
          replace: true,
        });

        handleClose();
      }
    } catch (e: any) {
      console.error(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleClose = () => {
    props.handleClose();
    reset();
    setValue('tournament_id', UNDEFINED_AS_ANY);
    setValue('challenge_id', UNDEFINED_AS_ANY);
  };

  React.useEffect(() => {
    (async () => {
      if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);

      if ((inputFields.tournament_id === undefined && location?.pathname?.includes('/tournament/')) || (inputFields.tournament_id && inputFields.tournament_id !== _.split(location?.pathname, '/')[2])) setValue('tournament_id', _.split(location?.pathname, '/')[2]);
      if ((inputFields.challenge_id === undefined && location?.pathname?.includes('/challenge/')) || (inputFields.challenge_id && inputFields.challenge_id !== _.split(location?.pathname, '/')[2])) setValue('challenge_id', _.split(location?.pathname, '/')[2]);

      if (props.open && event === undefined && (loadedTournament.data?.findTournamentByShortID || loadedChallenge.data?.findChallengeByID)) {
        setEvent(loadedTournament.data?.findTournamentByShortID || loadedChallenge.data?.findChallengeByID);
      }

      if (!props.open && event) {
        setEvent(UNDEFINED_AS_ANY);
      }
    })();
  }, [props.open, inputFields, setValue, ALL_FLEXERS, location?.pathname, event, loadedTournament.data?.findTournamentByShortID, loadedChallenge.data?.findChallengeByID]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>

      <DialogContent>
        <form className={'form-root'}>
          <UploadIMGFeature pictures={pictures} setter_pictures={onChangePictures} max_number={1} />

          <Grid id="max-width" sx={{ p: '30px 10% 0' }}>
            <Controller
              name="caption"
              control={control}
              rules={{ required: false }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label={`${intl.formatMessage({ id: 'NewFlexModal_Caption_Label' })}`}
                  fullWidth
                  value={_.trimStart(field.value)}
                  error={inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300)}
                  helperText={inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300) ? `${intl.formatMessage({ id: 'NewFlexModal_Caption_Helper_1' })} ${ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300} max` : ''}
                  multiline
                  minRows={3}
                  sx={{ mb: '20px' }}
                />
              )}
            />

            <Controller
              name="flexer_id"
              control={control}
              render={({ field }: any) => (
                <TextField {...field} label={`${intl.formatMessage({ id: 'NewFlexModal_Flexer_Label' })} ${ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.name}`} select variant="outlined" fullWidth>
                  {ALL_FLEXERS.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar src={option?.avatar} />
                        &nbsp;&nbsp;
                        <strong>{option.name}</strong>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Stack
              direction={'row'}
              sx={{
                display: 'inline-block',
                textAlign: 'center',
                p: '30px 0 0',
              }}
            >
              {inputFields.co_authors.map((f: any, index: number) => (
                <Chip key={index} variant="outlined" label={f?.name} avatar={<Avatar alt={f?.name} src={f?.avatar} />} onDelete={() => handleDeleteCoPublisher(f?.id)} sx={{ m: '5px' }} />
              ))}
            </Stack>
            <Controller
              name="all_authors"
              control={control}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  multiple
                  value={field.value}
                  autoHighlight
                  options={ALL_CO_FLEXERS.filter((cf: any) => inputFields.co_authors.find((a: any) => a?.id === cf?.id) === undefined)}
                  getOptionLabel={(option: any) => option?.name}
                  defaultValue={ARRAY_AS_ANY}
                  filterSelectedOptions
                  onChange={(event: any, value: any) => handleCoPublishers(event, value)}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      container
                      key={option?.id}
                      onClick={(event: any) => handleCoPublishers(event, option)}
                      sx={{
                        alignItems: 'center',
                        p: '10px 15px',
                      }}
                    >
                      <Avatar src={option?.avatar} />
                      &nbsp;&nbsp;
                      <strong>{option?.name}</strong>
                    </Grid>
                  )}
                  renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'NewFlexModal_CoFlexer_Label' })}`} placeholder={`${intl.formatMessage({ id: 'NewFlexModal_CoFlexer_Placeholder' })}`} />}
                />
              )}
            />

            {ALL_SELECTED_ITEMS?.length === 0 ? (
              ''
            ) : (
              <Stack
                direction={'row'}
                sx={{
                  display: 'inline-block',
                  textAlign: 'center',
                  p: '30px 0 0',
                }}
              >
                {ALL_SELECTED_ITEMS.map((f: any, index: number) => (
                  <Chip key={index} variant="outlined" label={f?.name || `${f?.__typename}: ${f?.title}`} avatar={<Avatar alt={f?.name} src={f?.pictures?.[0]?.path} />} onDelete={() => handleDeleteItemFlex(f?.id)} sx={{ m: '5px' }} />
                ))}
              </Stack>
            )}

            {inputFields.selected_flex_items?.length > 9 ? (
              ''
            ) : (
              <Controller
                name="all_flex_items"
                control={control}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    open={openSearchItem}
                    onOpen={() => setOpenSearchItem(true)}
                    onClose={() => setOpenSearchItem(false)}
                    multiple
                    autoHighlight
                    filterSelectedOptions
                    value={field.value}
                    loading={loadedItemFlex.loading}
                    inputValue={inputFields.item_name}
                    onInputChange={(event: any, newInputValue: any) => {
                      setValue('item_name', newInputValue);
                    }}
                    options={(ALL_ITEM_FLEX ? ALL_ITEM_FLEX : [])?.filter((i: any) => inputFields.selected_flex_items?.find((p: any) => p?.id === i?.id) === undefined)}
                    getOptionLabel={(option: any) => option?.id}
                    defaultValue={ARRAY_AS_ANY}
                    renderOption={(props: any, option: any) => (
                      <Grid
                        container
                        key={option?.id}
                        onClick={() => handleAddItemFlex(option)}
                        sx={{
                          alignItems: 'center',
                          p: '10px 15px',
                        }}
                      >
                        <Avatar src={option?.pictures?.[0]?.path} />
                        &nbsp;&nbsp;
                        <Grid>
                          <strong>{option?.name}</strong>
                          <br />
                          <Typography variant="caption">{option?.__typename}</Typography>
                        </Grid>
                      </Grid>
                    )}
                    renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'NewFlexModal_Flexed_Item_Label' })}`} placeholder={`${intl.formatMessage({ id: 'NewFlexModal_Flexed_Item_Placeholder' })}`} />}
                  />
                )}
              />
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton
          onClick={handleCreateFlex}
          disabled
          className="button-disabled"
          // className={(inputFields.caption?.length === 0 && pictures?.length === 0) || inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300) ? 'button-disabled' : 'button-green'}
          // disabled={(inputFields.caption?.length === 0 && pictures?.length === 0) || inputFields.caption?.length > (ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.type === 'Company' ? 25000 : 300)}
          loading={newFlex.loading || isSent.loading || progress > 0}
          loadingPosition="end"
          fullWidth
          endIcon={<Send />}
          sx={{ m: '0 10px' }}
        >
          {/* {`${intl.formatMessage({ id: 'NewFlexModal_Button_Create' })}`} */}
          {`${intl.formatMessage({ id: 'FormNewTeam_Sport_Type_Coming' })}`}
        </LoadingButton>
        <Button onClick={handleClose} className={'actionTwo button-cancel'} fullWidth endIcon={<Clear />}>
          {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NewFlexModal.propTypes = {
  tournament_id: PropTypes.string,
  challenge_id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
};

NewFlexModal.defaultProps = {
  tournament_id: '',
  challenge_id: '',
};
