import React from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import GPSCoordinatorFeature from './GPSCoordinatorFeature';
import { Alert, Typography } from '@mui/material';
import { AppContext } from '../../App';
import { REACT_APP_CUSTOM_MAP_URL } from '../../config';
import { useIntl } from 'react-intl';

interface IGPSMapFeature {
  position: { lat: number; lng: number };
  setPosition?: any;
  setValue?: any;
  element?: any;
  height?: string;
  manual_input?: boolean;
}

const GPSMapFeature = (props: IGPSMapFeature) => {
  const intl = useIntl();
  const appContext = React.useContext(AppContext);

  const center = appContext?.currentPosition ? [appContext?.currentPosition?.latitude, appContext?.currentPosition?.longitude] : [3.4253302, 21.2688288];
  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <>
      {props.position ? (
        ''
      ) : (
        <Alert severity="info" variant="outlined" sx={{ width: '80%', maxWidth: '500px', bgcolor: 'rgb(7,19,24)', color: 'rgb(3, 169, 244)', m: '0px auto 10px' }}>
          <Typography sx={{ textAlign: 'center', fontWeight: '700' }}>
            {`${intl.formatMessage({ id: 'GPSMapFeature_1' })}`}

            <br />
            {`${intl.formatMessage({ id: 'GPSMapFeature_2' })}`}
          </Typography>
        </Alert>
      )}

      <MapContainer center={props.position || center} zoom={3} scrollWheelZoom={false} style={{ height: props?.height || '500px' }}>
        <TileLayer attribution="" url={REACT_APP_CUSTOM_MAP_URL} />

        <Marker position={props.position || center} icon={DefaultIcon}>
          <Popup minWidth={150} maxWidth={290}></Popup>
        </Marker>

        <GPSCoordinatorFeature position={props.position} setPosition={props.setPosition} setValue={props.setValue} element={props.element} />
        <MapCenterer position={props.position} />
      </MapContainer>
    </>
  );
};

function MapCenterer({ position }: any) {
  const map = useMap(); // Access the map instance

  // This will center the map on the new position whenever it changes
  if (position) {
    map.setView(position); // Update the center of the map
  }

  return null; // This component doesn't render anything, it's just for side effects
}

GPSMapFeature.propTypes = {};

GPSMapFeature.defaultProps = {
  manual_input: false,
};

export default GPSMapFeature;
