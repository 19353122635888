import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider, Fab, Grid, Chip, Avatar, Autocomplete, TextField } from '@mui/material';
import { Send, Clear, Close } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';
import { useIntl } from 'react-intl';

interface IAddNewTeamManagerModal {
  open: any;
  handleClose: any;
  team_id: string;
  loading?: boolean;
}

const AddNewTeamManagerModal: React.FC<IAddNewTeamManagerModal> = (props: IAddNewTeamManagerModal) => {
  const ADD_TEAM_MANAGER_TO_SPORT_TEAM_MUTATION = gql`
    mutation addTeamManagerToSportTeam($team: inputAddSportTeamManager!) {
      addTeamManagerToSportTeam(team: $team)
    }
  `;

  const FIND_SPORT_TEAM_BY_TEAM_ID_QUERY = gql`
    query findSportTeamByTeamID($team: inputFindSportTeamByTeamID!) {
      findSportTeamByTeamID(team: $team) {
        ... on FootballTeam {
          id
          type
          players {
            id
            player {
              ... on Footballer {
                id
                alias
              }
            }
          }
        }
      }
    }
  `;

  const SEARCH_PLAYERS_QUERY = gql`
    query searchPlayers($player: inputSearchUsers!) {
      searchPlayers(player: $player) {
        id
        username
        avatar {
          path
        }
        cover {
          path
        }
        owner {
          firstname
          lastname
        }
        bio
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [team, setTeam] = React.useState(UNDEFINED_AS_ANY);
  const [openSearchSportPlayer, setOpenSearchSportPlayer] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      all_sport_players: ARRAY_AS_ANY,
      players: ARRAY_AS_ANY,
      player_alias: '',
    },
  });
  const inputFields = watch();

  const [addTeamManagerToSportTeam, newTeamManagers] = useMutation(ADD_TEAM_MANAGER_TO_SPORT_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportTeam = useQuery(FIND_SPORT_TEAM_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team: {
        id: props.team_id,
      },
    },
  });
  const loadedPlayers = useQuery(SEARCH_PLAYERS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !inputFields.player_alias || inputFields.player_alias === '',
    variables: {
      player: {
        name: inputFields.player_alias,
        limit: 20,
      },
    },
  });

  const ALL_SPORT_PLAYERS = loadedPlayers.data?.searchPlayers;

  const handleSportPlayers = (value: any) => {
    const newList = _.concat(inputFields.players, value);
    setValue('players', newList);
    setOpenSearchSportPlayer(false);
  };

  const handleRemoveSportPlayer = (player_id: string) => {
    const newList = inputFields.players?.filter((p: any) => p?.id !== player_id);
    setValue('players', newList);
  };

  const handleAddNewTeamPlayer = async () => {
    try {
      await addTeamManagerToSportTeam({
        variables: {
          team: {
            new_players_ids: inputFields.players?.map((p: any) => p?.id)?.filter((o: any) => o),
            id: team?.id,
            sport: team?.__typename,
          },
        },
      });

      props.handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && !team && loadedSportTeam.data) {
      setTeam(loadedSportTeam.data?.findSportTeamByTeamID);
    }

    if (!props.open && team) {
      setTeam(UNDEFINED_AS_ANY);
      setValue('players', ARRAY_AS_ANY);
    }
  }, [props, team, loadedSportTeam, setValue]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={props.handleClose}
        disabled={newTeamManagers.loading}
        className={newTeamManagers.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'AddTeamManagerModal_Title' })}`}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid sx={{ p: '20px 0 10px', textAlign: 'center' }}>
            {inputFields.players?.length === 0 ? (
              <Typography
                variant="caption"
                sx={{
                  m: '0px',
                  p: '5px 20px',
                  textAlign: 'center',
                  bgcolor: 'rgba(247, 251, 250, .04)',
                  color: 'rgba(247, 251, 250, 1)',
                  borderRadius: '5px',
                }}
              >
                {`${intl.formatMessage({ id: 'AddTeamManagerModal_No_Manager_Selected' })}`}
              </Typography>
            ) : (
              inputFields.players?.map((p: any) => (
                <Chip
                  key={p?.id}
                  variant="outlined"
                  label={<strong>{p?.username}</strong>}
                  avatar={
                    <Avatar
                      sx={{
                        height: {
                          xs: '50px !important',
                          md: '60px !important',
                          lg: '70px !important',
                        },
                        width: {
                          xs: '50px !important',
                          md: '60px !important',
                          lg: '70px !important',
                        },
                        m: '5px 0',
                      }}
                      alt={p?.username}
                      src={p?.avatar?.path}
                    />
                  }
                  onDelete={() => handleRemoveSportPlayer(p?.id)}
                  sx={{ m: '5px', height: 'auto', color: 'rgba(247, 251, 250, 1)' }}
                />
              ))
            )}
          </Grid>

          <Controller
            name="all_sport_players"
            control={control}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                open={openSearchSportPlayer}
                onOpen={() => setOpenSearchSportPlayer(true)}
                onClose={() => setOpenSearchSportPlayer(false)}
                multiple
                autoHighlight
                filterSelectedOptions
                value={field.value}
                loading={loadedPlayers.loading}
                inputValue={inputFields.player_alias}
                onInputChange={(event: any, newInputValue: any) => {
                  setValue('player_alias', newInputValue);
                }}
                options={(ALL_SPORT_PLAYERS ? ALL_SPORT_PLAYERS : [])?.filter((cf: any) => _.concat(team?.managers, inputFields.players)?.find((p: any) => p?.id === cf?.id) === undefined)}
                getOptionLabel={(option: any) => option?.id}
                defaultValue={ARRAY_AS_ANY}
                renderOption={(props: any, option: any) => (
                  <Grid
                    container
                    key={option?.id}
                    onClick={() => handleSportPlayers(option)}
                    sx={{
                      alignItems: 'center',
                      p: '10px 15px',
                    }}
                  >
                    <Avatar src={option?.avatar?.path} />
                    &nbsp;&nbsp;
                    <strong>{option?.username}</strong>
                  </Grid>
                )}
                renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'AddTeamManagerModal_Players_Label' })}`} placeholder={`${intl.formatMessage({ id: 'AddTeamManagerModal_Players_Placeholder' })}`} />}
              />
            )}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton onClick={handleAddNewTeamPlayer} className={inputFields.players?.length === 0 ? 'button-disabled' : 'button-green'} disabled={inputFields.players?.length === 0} loadingPosition="end" endIcon={<Send />} loading={newTeamManagers.loading || props.loading}>
          {`${intl.formatMessage({ id: 'AddTeamManagerModal_Button_Create' })}`}
        </LoadingButton>
        <Button onClick={props.handleClose} disabled={newTeamManagers.loading} className={newTeamManagers.loading ? 'button-disabled' : 'button-cancel'} endIcon={<Clear />}>
          {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNewTeamManagerModal.propTypes = {
  team_id: PropTypes.any,
  open: PropTypes.any,
  handleClose: PropTypes.any,
  loading: PropTypes.bool,
};

AddNewTeamManagerModal.defaultProps = {
  loading: false,
};

export default React.memo(AddNewTeamManagerModal);
