import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import _ from 'lodash';
import { LANGUAGES } from '../../utilities/utilities';
import { ListItemSecondaryAction, ListItem, ListItemText, List, Checkbox, Grid, Typography, Divider, CircularProgress, IconButton } from '@mui/material';
import BackButton from '../../component-modals/common/BackButton';
import { gql, useMutation, useQuery } from '@apollo/client';
import { selectPlayer, storePlayer } from '../../reducers/playerSlice';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import { Link } from 'react-router-dom';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';
import MenuButton from '../../component-modals/layout/MenuButton';

const Languages: React.FC = () => {
  const SELECT_PREFERED_LANGUAGE_MUTATION = gql`
    mutation selectPreferedLanguage($account: inputSelectPreferedLangue!) {
      selectPreferedLanguage(account: $account)
    }
  `;

  const FIND_PLAYER_BY_ID_QUERY = gql`
    query findPlayerByID($id: ID!) {
      findPlayerByID(id: $id) {
        id
        username
        mobile
        bio
        roles
        permissions
        status
        email {
          address
          status
        }
        profiles {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
        }
        contacts {
          ... on Footballer {
            __typename
            id
            alias
          }
          ... on Basketballer {
            __typename
            id
            alias
          }
        }
        kyc_cases {
          id
          reference
          status
          created_at
          audited_at
          incorporation_file {
            id
            filename
            path
          }
          user_front_id {
            id
            filename
            path
          }
          user_back_id {
            id
            filename
            path
          }
          user_id_selfie {
            id
            filename
            path
          }
          user_residential_proof {
            id
            filename
            path
          }
          pid {
            id_number
            type
            status
          }
        }
        avatar {
          id
          filename
          type
          encoding
          size
          path
        }
        cover {
          id
          filename
          type
          encoding
          size
          path
        }
        social_medias {
          id
          name
          handle
          link
        }
        app_configuration {
          id
          is_dark_mode
          two_facts_mode
          prefered_language
        }
        followings {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
        }
        followers {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
        }
        owner {
          id
          firstname
          lastname
          gender
          emails {
            address
            status
          }
          player_account {
            id
            status
            username
            avatar {
              path
            }
            profiles {
              ... on Footballer {
                __typename
                id
                alias
              }
              ... on Basketballer {
                __typename
                id
                alias
              }
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          complex_owner_account {
            id
            status
            username
            avatar {
              path
            }
            five_complexes {
              id
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          transporter_account {
            id
            status
            username
            avatar {
              path
            }
          }
          health_worker_account {
            id
            status
            username
            avatar {
              path
            }
          }
        }
        locations {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [language, setLanguage] = React.useState(LANGUAGES.find((lang: any) => lang.value === loggedPlayer?.app_configuration?.prefered_language));
  const [selectPreferedLanguage, updatedLanguage] = useMutation(SELECT_PREFERED_LANGUAGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedPlayer = useQuery(FIND_PLAYER_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { id: loggedPlayer?.id },
  });

  const handleToggle = async (selected_language: any) => {
    try {
      await selectPreferedLanguage({
        variables: {
          account: {
            id: loggedPlayer?.id,
            new_language: selected_language?.value,
          },
        },
      });
      handleRefetch();
      setLanguage(selected_language);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedPlayer.refetch({
        id: loggedPlayer?.id,
      });

      if (result) {
        dispatch(storePlayer(result?.data?.findPlayerByID));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid className={'component-preferences settings-page languages-page'}>
      <Grid container>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left', p: { xs: '20px 0px', sm: '20px 43px' } }}>
          <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        </Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
          <MenuButton />
        </Grid>
      </Grid>
      <Grid container sx={{ p: '0 5%' }} id="max-width">
        <Grid className={'title'} item xl={12} xs={12} sm={12}>
          <Typography sx={{ fontSize: '2em', fontWeight: '800', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>{`${intl.formatMessage({ id: 'SwitchLanguageButton_Title' })}`}</Typography>
          <Typography variant="caption" sx={{ color: 'rgba(247, 251, 250, 1)' }}>
            {`${intl.formatMessage({ id: 'SwitchLanguageButton_Current_Lang' })}`}: &nbsp;
            <strong>{LANGUAGES.find((lang: any) => lang.value === loggedPlayer?.app_configuration?.prefered_language)?.label}</strong>
            &nbsp;
            <img
              loading="lazy"
              width="20"
              src={loggedPlayer?.app_configuration?.prefered_language !== 'AU' ? `https://flagcdn.com/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png`}
              srcSet={loggedPlayer?.app_configuration?.prefered_language !== 'AU' ? `https://flagcdn.com/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(loggedPlayer?.app_configuration?.prefered_language, '_', 1)[0].toLowerCase()}.png 2x`}
              alt=""
            />
          </Typography>
        </Grid>
        <Grid className={'languages'} item xl={12} xs={12} sm={12}>
          <List>
            {LANGUAGES.sort((a: any, b: any) => a.label - b.label).map((lang: any, index: number) => (
              <ListItem key={index} disabled={lang.value !== 'FR' && lang.value !== 'GB' && lang.value !== 'NL' && !lang.label?.includes('_')}>
                <ListItemText
                  id={lang.value}
                  primary={
                    <Grid sx={{ fontWeight: lang.label?.includes('_') ? '800' : '500' }}>
                      {_.split(lang.value, '_', 1)[0].toLowerCase() === 'subheader' ? (
                        ''
                      ) : (
                        <img loading="lazy" width="20" src={lang.value !== 'AU' ? `https://flagcdn.com/w20/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png`} srcSet={lang.value !== 'AU' ? `https://flagcdn.com/w40/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(lang.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                      )}{' '}
                      &nbsp;
                      {lang.label?.replaceAll('_', ' ')}
                      {lang.label?.includes('_') ? <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }} /> : ''}
                    </Grid>
                  }
                  sx={{ color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}
                />

                <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
                  {updatedLanguage.loading && !lang.label?.includes('_') && lang?.value === language?.value ? <CircularProgress size={20} className="green-loader" /> : ''}
                  {lang.label?.includes('_') ? (
                    ''
                  ) : (
                    <Checkbox
                      edge="end"
                      disabled={updatedLanguage.loading || (lang.value !== 'FR' && lang.value !== 'GB' && lang.value !== 'NL')}
                      onChange={() => handleToggle(lang)}
                      checked={language !== undefined ? language.value === lang.value : false}
                      inputProps={{
                        'aria-labelledby': lang.value,
                      }}
                      className="green-checkbox"
                      sx={{ color: loggedPlayer?.app_configuration?.is_dark_mode ? (lang.value !== 'FR' && lang.value !== 'GB' && lang.value !== 'NL' ? 'rgba(247, 251, 250, 1)' : 'rgba(247, 251, 250, .1)') : lang.value !== 'FR' && lang.value !== 'GB' && lang.value !== 'NL' ? 'rgba(15,15,15,1)' : 'rgba(15,15,15,.1)' }}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Languages;
