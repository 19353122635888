import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Popper, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useAddressAutofillCore } from '@mapbox/search-js-react';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { COUNTRIES } from '../../utilities/utilities';
import { REACT_APP_MAPBOX_API_KEY } from '../../config';
import { useIntl } from 'react-intl';

interface IAutofillAddressInput {
  address_input: string;
  street_name: string;
  secondary_street_name: string;
  postcode: string;
  county?: string;
  state?: string;
  city: string;
  country: string;
  setter: any;
  setterCountry?: any;
  control: any;
  errors: any;
}

const AutofillAddressInput: React.FC<IAutofillAddressInput> = (props: IAutofillAddressInput) => {
  const intl = useIntl();
  const autofill = useAddressAutofillCore({ accessToken: REACT_APP_MAPBOX_API_KEY });
  const [suggestions, setSuggestions] = React.useState(UNDEFINED_AS_ANY);
  const syntheticEvent: any = {
    currentTarget: document.getElementById('input-address'),
  };

  const [anchorEl, setAnchorEl] = React.useState<any>(syntheticEvent.currentTarget);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectAddress = (address: any) => {
    props.setter(props.street_name, `${address?.address_number || ''} ${address?.street}`);
    // props.setter(props.secondary_street_name, address?.address_level2)
    props.setter(props.county, address?.region);
    props.setter(props.postcode, address?.postcode);
    props.setter(props.city, address?.place);
    props.setter(props.country, COUNTRIES?.find((c: any) => c?.value === address?.country_code?.toUpperCase()) || COUNTRIES[0]);
    props.setterCountry(COUNTRIES?.find((c: any) => c?.value === address?.country_code?.toUpperCase()) || COUNTRIES[0]);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    (async () => {
      if (props.address_input) {
        const response = await autofill.suggest(props.address_input, {
          sessionToken: 'test-123',
        });
        setSuggestions(response.suggestions);
      }
    })();
  }, [props.address_input]);

  React.useEffect(() => {
    // Function to handle clicks outside the pop-up
    const handleClickAway = (event: any) => {
      if (anchorEl && !event.target.classList.contains('autofill-input')) {
        // Clicked outside the pop-up, close it
        setAnchorEl(null);
      }
    };

    // Add the event listener to the document
    document.addEventListener('mousedown', handleClickAway);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, [anchorEl]);

  return (
    <>
      <Controller
        name={props.street_name}
        control={props.control}
        rules={{
          required: false,
          maxLength: 30,
          minLength: 4,
          pattern: /^[a-zA-Z0-9-\s]*$/,
        }}
        render={({ field }: any) => <TextField {...field} id="input-address" ref={inputRef} label={intl.formatMessage({ id: 'Global_Address_Street_Name_1' })} onClick={handleClick} autoComplete="address-line1" placeholder="Write your company's street address" variant="outlined" fullWidth value={field.value} error={props.errors?.street_name !== undefined} helperText={props.errors?.street_name !== undefined ? `${props.errors?.street_name?.message}` : ''} className={'field-bottom-space'} />}
      />

      {suggestions?.length > 0 ? (
        <Popper
          id="basic-Popper"
          open={open}
          anchorEl={anchorEl}
          placement="auto"
          sx={{
            color: 'rgba(15, 15, 15, 1)',
            zIndex: '1000000000',
            bgcolor: 'rgba(255, 255, 255, 1)',
            p: '10px 10px',
            border: 'solid 1px rgba(15, 15, 15, 0.3)',
            borderRadius: '5px',
            maxHeight: '400px',
            overflowY: 'auto',
            maxWidth: '80%',
          }}
        >
          {suggestions?.map((s: any, index: number) => (
            <Grid key={index} className="autofill-input" onClick={() => handleSelectAddress(s)} sx={{ 'p': '10px', '&:hover': { bgcolor: 'rgba(15, 15, 15, 0.1)' } }}>
              <img loading="lazy" src={`https://flagcdn.com/w20/${s?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${s?.country_code?.toLowerCase()}.png 2x`} alt="" />
              &nbsp;&nbsp;
              {s?.full_address}
            </Grid>
          ))}
        </Popper>
      ) : (
        ''
      )}
    </>
  );
};

AutofillAddressInput.propTypes = {
  address_input: PropTypes.string.isRequired,
  street_name: PropTypes.string.isRequired,
  secondary_street_name: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  county: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  setter: PropTypes.any.isRequired,
  setterCountry: PropTypes.any,
  control: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
};

AutofillAddressInput.defaultProps = {
  address_input: '',
};

export default AutofillAddressInput;
