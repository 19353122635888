import React from 'react';
import { Autocomplete, Avatar, Button, Chip, Grid, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import _ from 'lodash';
import BackButton from '../../component-modals/common/BackButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { selectSession } from '../../reducers/sessionSlice';
import { Controller, useForm } from 'react-hook-form';
import { BLOCKCHAIN_CURRENCIES, CURRENCIES, TO_TIMESTAMP } from '../../utilities/utilities';
import { gql, useMutation, useQuery } from '@apollo/client';
import InputDescriptionsFeature from '../../component-modals/common/InputDescriptionsFeature';
import { ARRAY_AS_ANY, DESCRIPTION_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { Delete, Send } from '@mui/icons-material';
import InputTrophiesFeature from '../../component-modals/common/InputTrophiesFeature';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { useIntl } from 'react-intl';

interface IFormEditTournament {
  tournament: { id: string };
}

const FormEditTournament: React.FC = () => {
  const FIND_TOURNAMENT_TO_EDIT_BY_ID_QUERY = gql`
    query findTournamentToEditByID($tournament: inputFindTournamentToEditByID!) {
      findTournamentToEditByID(tournament: $tournament) {
        id
        short_id
        status
        title
        start_date
        end_date
        descriptions {
          content
          language
        }
        cost_per_team {
          value
          currency {
            currency_code
          }
        }
        main_organisator {
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              logo {
                filename
                path
              }
            }
          }
        }
        admins {
          id
          username
          avatar {
            filename
            path
          }
          owner {
            id
            firstname
            lastname
          }
        }
        organisators {
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              label
              logo {
                filename
                path
              }
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
            owner {
              id
              firstname
              lastname
            }
          }
        }
        trophies {
          id
          name
          note
          rank
        }
      }
    }
  `;

  const EDIT_TOURNAMENT_MUTATION = gql`
    mutation editTournament($tournament: inputEditTournament!) {
      editTournament(tournament: $tournament) {
        id
        short_id
      }
    }
  `;

  const DELETE_TOURNAMENT_MUTATION = gql`
    mutation deleteTournament($tournament: inputDeleteTournament!) {
      deleteTournament(tournament: $tournament)
    }
  `;

  const location: any = useLocation();
  const state: IFormEditTournament = location?.state;
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();

  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);
  const [openDeleteTournament, setOpenDeleteTournament] = React.useState(false);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: UNDEFINED_AS_ANY,
      start_date: UNDEFINED_AS_ANY,
      end_date: UNDEFINED_AS_ANY,
      descriptions: UNDEFINED_AS_ANY,
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',

      all_organisators: ARRAY_AS_ANY,
      selected_organisators: UNDEFINED_AS_ANY,
      organisator_name: '',

      main_organisator_id: '',

      all_admins: ARRAY_AS_ANY,
      selected_admins: UNDEFINED_AS_ANY,
      admin_name: '',

      trophies: UNDEFINED_AS_ANY,

      value: -1,
      currency: '',
    },
  });
  const inputFields = watch();

  const navigate = useNavigate();

  const ALL_ORGANISATORS = _.concat(
    loggedPlayer?.followings
      ?.filter((f: any) => f?.__typename !== 'Company' && f?.__typename !== 'FootballTeam')
      ?.map((f: any) => {
        return {
          id: f?.id,
          username: f?.username || f?.name,
          person: {
            id: f?.owner?.id,
            firstname: f?.owner?.firstname || f?.label,
            lastname: f?.owner?.lastname,
          },
          avatar: f?.avatar?.path || f?.logo?.path,
          type: f?.__typename,
        };
      }),
    {
      id: loggedPlayer?.id,
      username: loggedPlayer?.username,
      person: {
        id: loggedPlayer?.owner?.id,
        firstname: loggedPlayer?.owner?.firstname,
        lastname: loggedPlayer?.owner?.lastname,
      },
      avatar: loggedPlayer?.avatar?.path,
      type: loggedPlayer?.__typename,
    }
  );

  const [deleteTournament, deletedTournament] = useMutation(DELETE_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [editTournament, updatedTournament] = useMutation(EDIT_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      tournament: {
        id: state?.tournament?.id,
        player_id: loggedPlayer?.id,
      },
    },
  });

  const handleStartDate = async (date: any) => {
    const d = new Date(date);
    const date_end = new Date(d.setHours(d.getHours() + 2));
    setValue('start_date', date);
    setValue('end_date', date_end);
  };

  const handleEndDate = async (date: any) => {
    setValue('end_date', date);
  };

  const handleAdmins = (event: any, value: any) => {
    const newList = _.concat(value, inputFields.selected_admins);
    setValue('selected_admins', newList);
  };

  const handleDeleteAdmin = (publisher_id: string) => {
    const newList = inputFields.selected_admins.filter((a: any) => a?.id !== publisher_id);
    setValue('selected_admins', newList);
  };

  const handleMainOrganisator = (event: any, value: any) => {
    setValue('main_organisator_id', value?.id);
  };

  const handleOrganisators = (event: any, value: any) => {
    const newList = _.concat(value, inputFields.selected_organisators)?.filter((i: any) => i);
    setValue('selected_organisators', newList);
  };

  const handleDeleteOrganisator = (publisher_id: string) => {
    const newList = inputFields.selected_organisators.filter((a: any) => a?.id !== publisher_id);
    setValue('selected_organisators', newList);
  };

  const handleEditTournament = async () => {
    try {
      const result = await editTournament({
        variables: {
          tournament: {
            logged_player_id: loggedPlayer?.id,
            id: tournament?.id,
            name: inputFields.name,
            start_date: TO_TIMESTAMP(inputFields.start_date?.toString()),
            end_date: TO_TIMESTAMP(inputFields.end_date?.toString()),
            cost_per_team:
              tournament?.status !== 'OPEN'
                ? undefined
                : {
                    value: parseFloat(inputFields.value?.toString()),
                    currency: inputFields.currency,
                  },
            descriptions: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
            main_organisator_id: inputFields.main_organisator_id,
            organisators_ids: inputFields.selected_organisators?.map((p: any) => p?.id) ?? [],
            admins_ids: inputFields.selected_admins?.map((p: any) => p?.id) ?? [],
            trophies: inputFields.trophies?.filter((t: any) => !t?.id) ?? [],
          },
        },
      });

      if (result?.data?.editTournament?.short_id) {
        navigate(`/tournament/${result?.data?.editTournament?.short_id}`, { replace: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteTournament = async () => {
    try {
      const result = await deleteTournament({
        variables: {
          tournament: {
            id: tournament?.id,
            logged_player_id: loggedPlayer?.id,
          },
        },
      });

      if (result.data?.deleteTournament > 0) {
        navigate(`/tournaments`, { replace: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!_.isMatch(tournament, loadedTournament.data?.findTournamentToEditByID) || (tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentToEditByID)) {
      setTournament(loadedTournament.data?.findTournamentToEditByID);
    }

    if (tournament) {
      if (!inputFields.name && tournament?.title) setValue('name', tournament?.title);
      if (!inputFields.start_date && tournament?.start_date) setValue('start_date', new Date(tournament?.start_date));
      if (!inputFields.end_date && tournament?.end_date) setValue('end_date', new Date(tournament?.end_date));
      if (!inputFields.descriptions && tournament?.descriptions) setValue('descriptions', tournament?.descriptions);
      if (inputFields.value < 0 && tournament?.cost_per_team?.value) setValue('value', tournament?.cost_per_team?.value);
      if (inputFields.currency === '' && tournament?.cost_per_team?.value) setValue('currency', tournament?.cost_per_team?.currency?.currency_code ?? 'MAT');
      if (!inputFields.selected_admins && tournament?.admins) {
        setValue(
          'selected_admins',
          tournament?.admins?.map((a: any) => {
            return {
              id: a?.id,
              username: a?.username || a?.name,
              person: {
                id: a?.owner?.id,
                firstname: a?.owner?.firstname || a?.label,
                lastname: a?.owner?.lastname,
              },
              avatar: a?.avatar?.path || a?.logo?.path,
              type: a?.__typename,
            };
          })
        );
      }
      if (!inputFields.selected_organisators && tournament?.organisators) {
        setValue(
          'selected_organisators',
          tournament?.organisators?.map((a: any) => {
            return {
              id: a?.id,
              username: a?.username || a?.name,
              person: {
                id: a?.owner?.id,
                firstname: a?.owner?.firstname || a?.label,
                lastname: a?.owner?.lastname,
              },
              avatar: a?.avatar?.path || a?.logo?.path,
              type: a?.__typename,
            };
          })
        );
      }
      if (!inputFields.trophies && tournament?.trophies) {
        setValue(
          'trophies',
          tournament?.trophies?.map((a: any) => {
            return {
              id: a?.id,
              name: a?.name,
              note: a?.note,
              rank: a?.rank,
            };
          })
        );
      }
      if (inputFields.main_organisator_id === '' && tournament?.main_organisator) setValue('main_organisator_id', tournament?.main_organisator?.id);
    }
  }, [tournament, loadedTournament, setValue, inputFields]);

  React.useEffect(() => {
    if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
    if (inputFields.descriptions?.length > 0 && inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');
  }, [inputFields, setValue]);

  return (
    <Grid container className={'form-page component-challenge new-challenge-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Typography className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
          {`${intl.formatMessage({ id: 'FormEditTournament_Title' })}`}
        </Typography>

        <form className={'form-root'}>
          <Grid id="max-width" sx={{ p: '50px 10% 0' }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
                maxLength: 30,
                minLength: 4,
                pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label={`${intl.formatMessage({ id: 'FormNewTournament_Name' })}`}
                  placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Name_Placeholder' })}`}
                  variant="outlined"
                  fullWidth
                  required
                  className={'field-bottom-space'}
                  value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
                  error={errors?.name?.type !== undefined}
                  helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormNewTournament_Name_Helper_1' })}` : `${intl.formatMessage({ id: 'FormNewTournament_Name_Helper_2' })}`) : ``}` : ''}
                />
              )}
            />

            <Typography
              id="max-width"
              variant="h3"
              sx={{
                fontSize: '1.4em',
                fontWeight: '700',
                m: '40px 0 20px',
              }}
            >
              {`${intl.formatMessage({ id: 'FormNewTournament_Descriptions' })}`}
            </Typography>
            <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

            {tournament?.status === 'OPEN' ? (
              <>
                <Typography
                  id="max-width"
                  variant="h3"
                  sx={{
                    fontSize: '1.4em',
                    fontWeight: '700',
                    m: '40px 0 20px',
                  }}
                >
                  {`${intl.formatMessage({ id: 'FormNewTournament_Cost_Title' })}`}
                </Typography>
                <Controller
                  name="value"
                  control={control}
                  rules={{
                    required: true,
                    min: 0,
                    max: 1000000,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={`${intl.formatMessage({ id: 'FormNewTournament_Cost_Value' })}`}
                      placeholder={'0'}
                      type="number"
                      variant="outlined"
                      value={field.value <= -1 ? 0 : field.value && field.value > 1000000 ? 1000000 : field.value}
                      sx={{
                        mr: {
                          xs: '0',
                          sm: '3%',
                          md: '3%',
                        },
                        width: {
                          xs: '100%',
                          sm: '64%',
                          md: '64%',
                        },
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{_.concat(CURRENCIES, BLOCKCHAIN_CURRENCIES).find((c) => c.value === inputFields.currency)?.value}</InputAdornment>,
                      }}
                      className={'field-bottom-space'}
                    />
                  )}
                />

                <Controller
                  name="currency"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[0-9a-zA-Z]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      SelectProps={{
                        MenuProps: {
                          sx: {
                            zIndex: '1000000000',
                          },
                        },
                      }}
                      label={`${intl.formatMessage({ id: 'FormNewTournament_Cost_Currency' })}`}
                      variant="outlined"
                      sx={{
                        ml: {
                          xs: '0',
                          sm: '3%',
                          md: '3%',
                        },
                        width: {
                          xs: '100%',
                          sm: '30%',
                          md: '30%',
                        },
                      }}
                    >
                      {_.concat(BLOCKCHAIN_CURRENCIES, CURRENCIES).map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </>
            ) : (
              ''
            )}

            <Typography
              id="max-width"
              variant="h3"
              sx={{
                fontSize: '1.4em',
                fontWeight: '700',
                m: '40px 0 20px',
              }}
            >
              {inputFields.trophies?.length || 0} {(inputFields.trophies?.length || 0) > 1 ? `${intl.formatMessage({ id: 'FormNewTournament_Trophies' })}` : `${intl.formatMessage({ id: 'FormNewTournament_Trophy' })}`}
            </Typography>
            <InputTrophiesFeature trophies={inputFields.trophies} setter={setValue} control={control} />

            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
              {`${intl.formatMessage({ id: 'FormNewTournament_Organisator_Title' })}`}
            </Typography>
            {inputFields.main_organisator_id && inputFields.main_organisator_id !== '' ? (
              <Stack
                direction={'row'}
                sx={{
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                <Chip
                  variant="outlined"
                  label={
                    <strong>
                      {ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.person?.firstname} {ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.person?.lastname}
                    </strong>
                  }
                  avatar={<Avatar alt={ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.username} src={ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.avatar} />}
                  sx={{ m: '5px', color: 'rgba(247, 251, 250, 1)' }}
                />
              </Stack>
            ) : (
              ''
            )}

            <Controller
              name="all_organisators"
              control={control}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  multiple
                  value={field.value}
                  autoHighlight
                  options={ALL_ORGANISATORS}
                  getOptionLabel={(option: any) => option?.username}
                  defaultValue={ARRAY_AS_ANY}
                  filterSelectedOptions
                  onChange={(event: any, value: any) => handleMainOrganisator(event, value)}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      container
                      key={option?.id}
                      onClick={(event: any) => handleMainOrganisator(event, option)}
                      sx={{
                        alignItems: 'center',
                        p: '10px 15px',
                      }}
                    >
                      <Avatar src={option?.avatar} />
                      &nbsp;&nbsp;
                      <strong>
                        {option?.person?.firstname} {option?.person?.lastname}
                      </strong>
                      &nbsp;({option?.username})
                    </Grid>
                  )}
                  renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'FormNewTournament_Organisator_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Organisator_Placeholder' })}`} />}
                />
              )}
            />

            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
              {`${intl.formatMessage({ id: 'FormNewTournament_Organisators_Title' })}`}
            </Typography>
            <Stack
              direction={'row'}
              sx={{
                display: 'inline-block',
                textAlign: 'center',
              }}
            >
              {inputFields.selected_organisators?.map((f: any, index: number) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={
                    <strong>
                      {f?.person?.firstname} {f?.person?.lastname}
                    </strong>
                  }
                  avatar={<Avatar alt={f?.username} src={f?.avatar} />}
                  onDelete={() => handleDeleteOrganisator(f?.id)}
                  sx={{ 'm': '5px', 'color': 'rgba(247, 251, 250, 1)', '& .MuiChip-deleteIcon': { color: 'rgba(247, 251, 250, .4)' } }}
                />
              ))}
            </Stack>

            <Controller
              name="all_organisators"
              control={control}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  multiple
                  value={field.value}
                  autoHighlight
                  options={ALL_ORGANISATORS?.filter((cf: any) => inputFields?.selected_organisators?.find((a: any) => a?.id === cf?.id) === undefined)}
                  getOptionLabel={(option: any) => option?.username}
                  defaultValue={ARRAY_AS_ANY}
                  filterSelectedOptions
                  onChange={(event: any, value: any) => handleOrganisators(event, value)}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      container
                      key={option?.id}
                      onClick={(event: any) => handleOrganisators(event, option)}
                      sx={{
                        alignItems: 'center',
                        p: '10px 15px',
                      }}
                    >
                      <Avatar src={option?.avatar} />
                      &nbsp;&nbsp;
                      <strong>
                        {option?.person?.firstname} {option?.person?.lastname}
                      </strong>
                      &nbsp;({option?.username})
                    </Grid>
                  )}
                  renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'FormNewTournament_Organisators_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Organisators_Placeholder' })}`} />}
                />
              )}
            />

            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
              {`${intl.formatMessage({ id: 'FormNewTournament_Managers_Title' })}`}
            </Typography>
            <Stack
              direction={'row'}
              sx={{
                display: 'inline-block',
                textAlign: 'center',
              }}
            >
              {inputFields.selected_admins?.map((f: any, index: number) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={
                    <strong>
                      {f?.person?.firstname} {f?.person?.lastname}
                    </strong>
                  }
                  avatar={<Avatar alt={f?.username} src={f?.avatar} />}
                  onDelete={() => handleDeleteAdmin(f?.id)}
                  sx={{ m: '5px' }}
                />
              ))}
            </Stack>

            <Controller
              name="all_admins"
              control={control}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  multiple
                  value={field.value}
                  autoHighlight
                  options={ALL_ORGANISATORS?.filter((cf: any) => inputFields?.selected_admins?.find((a: any) => a?.id === cf?.id) === undefined)}
                  getOptionLabel={(option: any) => option?.username}
                  defaultValue={ARRAY_AS_ANY}
                  filterSelectedOptions
                  onChange={(event: any, value: any) => handleAdmins(event, value)}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      container
                      key={option?.id}
                      onClick={(event: any) => handleAdmins(event, option)}
                      sx={{
                        alignItems: 'center',
                        p: '10px 15px',
                      }}
                    >
                      <Avatar src={option?.avatar} />
                      &nbsp;&nbsp;
                      <strong>
                        {option?.person?.firstname} {option?.person?.lastname}
                      </strong>
                      &nbsp;({option?.username})
                    </Grid>
                  )}
                  renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'FormNewTournament_Managers_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Managers_Placeholder' })}`} />}
                />
              )}
            />

            <Typography
              id="max-width"
              variant="h3"
              sx={{
                fontSize: '1.4em',
                fontWeight: '700',
                m: '40px 0 20px',
              }}
            >
              {intl.formatMessage({ id: 'FormNewTournament_Dates_Title' })}
            </Typography>
            <Controller
              name="start_date"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field }: any) => (
                <DateTimePicker
                  {...field}
                  value={field.value}
                  disablePast
                  views={['day', 'month', 'hours', 'minutes']}
                  openTo="day"
                  format="d MMM yyyy HH:mm"
                  renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                  ampm={false}
                  sx={{ width: !inputFields.start_date ? '100%' : { xs: '100%', sm: '45%' }, m: !inputFields.start_date ? '0' : { xs: '0', sm: '0 2.5%' } }}
                  className={'field-bottom-space'}
                  onChange={handleStartDate}
                  label={`${intl.formatMessage({ id: 'Global_Dates_Start_Label' })}`}
                />
              )}
            />

            {!inputFields.start_date ? (
              ''
            ) : (
              <>
                <Controller
                  name="end_date"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }: any) => (
                    <DateTimePicker
                      {...field}
                      value={field.value}
                      disablePast
                      views={['day', 'month', 'hours', 'minutes']}
                      minDate={inputFields.start_date}
                      maxDate={new Date(inputFields.start_date || Date.now()).setFullYear(new Date(inputFields.start_date || Date.now()).getFullYear() + 1)}
                      format="d MMM yyyy HH:mm"
                      renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                      ampm={false}
                      sx={{ width: { xs: '100%', sm: '45%' }, m: { xs: '0', sm: '0 2.5%' } }}
                      className={'field-bottom-space'}
                      onChange={handleEndDate}
                      label={`${intl.formatMessage({ id: 'Global_Dates_End_Label' })}`}
                    />
                  )}
                />
              </>
            )}

            <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
              {tournament?.status === 'SCHEDULING_GAMES' || tournament?.status === 'OPEN' || tournament?.status === 'PENDING' ? (
                <Button className={updatedTournament.loading ? 'button-disabled' : 'button-red-reversed'} disabled={updatedTournament.loading} endIcon={<Delete />} onClick={() => setOpenDeleteTournament(true)} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Delete' })}`}
                </Button>
              ) : (
                ''
              )}

              <Button onClick={() => navigate(-1)} className={updatedTournament.loading ? 'button-disabled' : 'button-cancel'} sx={{ mr: '10px' }}>
                {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
              </Button>

              {tournament?.status === 'SCHEDULING_GAMES' || tournament?.status === 'OPEN' || tournament?.status === 'PENDING' ? (
                <LoadingButton
                  onClick={handleEditTournament}
                  loading={updatedTournament.loading}
                  loadingPosition="end"
                  endIcon={<Send />}
                  className={!inputFields.start_date || !inputFields.end_date || inputFields.descriptions?.length === 0 || inputFields.descriptions?.filter((d: any) => d?.content?.length > 0)?.length === 0 ? 'button-disabled' : 'button-green'}
                  disabled={!inputFields.start_date || !inputFields.end_date || inputFields.descriptions?.length === 0 || inputFields.descriptions?.filter((d: any) => d?.content?.length > 0)?.length === 0}
                  sx={{ mr: '10px' }}
                >
                  {`${intl.formatMessage({ id: 'Global_Button_Save_Updates' })}`}
                </LoadingButton>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </form>

        <DeleteConfirmationModal title={`${intl.formatMessage({ id: 'Global_Permanent_Delete_Title' })} ${tournament?.title}`} open={openDeleteTournament} entityId={tournament?.id} entityName={`${tournament?.title}`} loading={deletedTournament.loading} actionOne={handleDeleteTournament} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={() => setOpenDeleteTournament(false)} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} />
      </Grid>
    </Grid>
  );
};

export default FormEditTournament;
