import React from 'react';
import { Alert, Autocomplete, Avatar, Badge, Button, CardMedia, Chip, Fab, FormControlLabel, Grid, IconButton, MenuItem, Stack, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer, selectPlayerActiveProfile, selectPlayerProfiles } from '../../reducers/playerSlice';
import { ARRAY_AS_ANY, CountryType, DATE_AS_ANY, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { customAlphabet } from 'nanoid';
import { CHALLENGE_REG, CHALLENGE_TYPES, COUNTRIES, DURATION_IN_HOURS, TO_TIMESTAMP, WEEKDAYS } from '../../utilities/utilities';
import { CaptainIcon, FivezerLogoIcon, TeamsIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { City } from 'country-state-city';
import { DateTimePicker } from '@mui/x-date-pickers';
import { gql, useMutation, useQuery } from '@apollo/client';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { LoadingButton } from '@mui/lab';
import ViewFieldModal from '../../component-modals/team/ViewFieldModal';
import { Email, Info, Phone, Send } from '@mui/icons-material';
import MenuButton from '../../component-modals/layout/MenuButton';
import Moment from 'react-moment';
import { selectSession } from '../../reducers/sessionSlice';
import { REACT_APP_CUSTOM_MAP_URL } from '../../config';
import { FormattedMessage, useIntl } from 'react-intl';

interface IFormNewChallenge {
  rematch_challenge?: any;
}

const FormNewChallenge: React.FC = () => {
  const CREATE_CHALLENGE_MUTATION = gql`
    mutation createChallenge($challenge: inputCreateChallenge!) {
      createChallenge(challenge: $challenge) {
        id
        short_id
      }
    }
  `;

  const CREATE_RECURRING_CHALLENGE_MUTATION = gql`
    mutation createRecurringChallenge($challenge: inputCreateRecurringChallenge!) {
      createRecurringChallenge(challenge: $challenge) {
        id
        short_id
      }
    }
  `;

  const FIND_COMPLEXES_BY_OPTIONS_QUERY = gql`
    query findComplexesByOptions($options: inputFindComplexesByOptions!) {
      findComplexesByOptions(options: $options) {
        id
        name
        logo {
          path
        }
        cover {
          path
        }
        pictures {
          path
        }
        owners {
          ... on Company {
            __typename
            id
            name
            label
          }
          # ... on ComplexOwner {
          #   __typename
          #   id
          # }
        }
        fields {
          id
          short_id
          name
          pictures {
            path
          }
          rental_asset {
            id
            price {
              value
              currency {
                currency_code
              }
            }
          }
          complex {
            id
            name
            telephone
            email {
              address
            }
          }
        }
        location {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
        }
      }
    }
  `;

  const FIND_SPORT_PLAYERS_BY_TEAM_ID_QUERY = gql`
    query findSportPlayersByTeamID($player: inputFindSportPlayersByTeamID!) {
      findSportPlayersByTeamID(player: $player) {
        ... on Footballer {
          id
          alias
          avatar {
            path
          }
          player {
            id
            username
          }
          player_positions {
            id
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
            team_captain {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
          }
        }
        ... on Basketballer {
          id
          alias
          avatar {
            path
          }
          player {
            id
            username
          }
          player_positions {
            id
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
            team_captain {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
          }
        }
      }
    }
  `;

  const FIND_SPORT_TEAMS_BY_TEAM_NAME_QUERY = gql`
    query findSportTeamsByTeamName($team: inputFindSportTeamsByTeamName!) {
      findSportTeamsByTeamName(team: $team) {
        ... on FootballTeam {
          id
          name
          short_id
          avatar {
            path
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const FIND_SPORT_TEAM_BY_TEAM_ID_QUERY = gql`
    query findSportTeamByTeamID($team: inputFindSportTeamByTeamID!) {
      findSportTeamByTeamID(team: $team) {
        ... on FootballTeam {
          id
          team_lineups {
            id
            name
            players {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
              }
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const SEND_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const SEND_LILENEK_NEW_SPORT_SESSION_MUTATION = gql`
    mutation sendLilenekNewSportSessionEmail($account: inputSendLilenekNewSellEmail!) {
      sendLilenekNewSportSessionEmail(account: $account)
    }
  `;

  const SEND_FIVEZER_NEW_MANUAL_BOOKING_MUTATION = gql`
    mutation sendFivezerNewManualBookingEmail($account: inputSendFivezerNewManualBookingEmail!) {
      sendFivezerNewManualBookingEmail(account: $account)
    }
  `;

  const FIELD_AVAILABILITY_MUTATION = gql`
    mutation fieldAvailability($field: inputFieldAvailability!) {
      fieldAvailability(field: $field)
    }
  `;

  const FIND_FREE_FIELD_BY_COUNTRY_NAME_QUERY = gql`
    query findFreeFielsdByCountry($country_name: String!) {
      findFreeFieldsByCountry(country_name: $country_name) {
        id
        short_id
        name
        size
        type
        sports
        status
        pictures {
          path
        }
        descriptions {
          language
          content
        }
        equipments {
          id
          name
          pictures {
            path
          }
        }
        rental_asset {
          id
          price {
            value
            currency {
              currency_code
            }
          }
        }

        location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const location: any = useLocation();
  const state: IFormNewChallenge = location?.state;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerProfiles = useAppSelector(selectPlayerProfiles);
  const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      challenger: state?.rematch_challenge ? state?.rematch_challenge?.home_team?.team?.id : '',
      name: state?.rematch_challenge ? state?.rematch_challenge?.title : '',
      is_private: state?.rematch_challenge ? state?.rematch_challenge?.is_private : false,
      city: UNDEFINED_AS_ANY,
      city_name: '',
      country: state?.rematch_challenge ? COUNTRIES?.find((c: any) => c?.label === state?.rematch_challenge?.complex?.location?.country?.name) : INITIAL_COUNTRY,
      type: state?.rematch_challenge ? state?.rematch_challenge?.type : 'FRIENDLY',
      sport_type: 'FOOTBALL',
      start_date: DATE_AS_ANY,
      end_date: DATE_AS_ANY,
      last_date: DATE_AS_ANY,
      is_recurring: false,
      week_days: ARRAY_AS_ANY,

      all_opponents: ARRAY_AS_ANY,
      opponent: state?.rematch_challenge ? state?.rematch_challenge?.away_team?.team : UNDEFINED_AS_ANY,
      opponent_name: state?.rematch_challenge ? state?.rematch_challenge?.away_team?.team?.name : '',

      all_complexes: ARRAY_AS_ANY,
      complex: state?.rematch_challenge ? state?.rematch_challenge?.complex : UNDEFINED_AS_ANY,
      complex_name: state?.rematch_challenge ? state?.rematch_challenge?.complex?.name : '',

      field: '',
      selected_field: state?.rematch_challenge ? state?.rematch_challenge?.arena : '',

      is_new_lineup: state?.rematch_challenge ? false : UNDEFINED_AS_ANY,
      all_sport_players: ARRAY_AS_ANY,
      team_lineup: ARRAY_AS_ANY,
      player_alias: '',
      team_lineup_id: state?.rematch_challenge ? state?.rematch_challenge?.home_team?.id : '',
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();
  const intl = useIntl();
  const challenge_nanoid = customAlphabet(CHALLENGE_REG, 21);
  const center_position: any = state?.rematch_challenge ? [state?.rematch_challenge?.complex?.location?.coordinates?.latitude, state?.rematch_challenge?.complex?.location?.coordinates?.longitude] : [48.8559202, 2.2910392];
  const isSmallMobile = useMediaQuery('(max-width:600px)');

  const [country, setCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [city, setCity] = React.useState(UNDEFINED_AS_ANY);
  const [openSearchComplex, setOpenSearchComplex] = React.useState(false);
  const [openSearchOpponent, setOpenSearchOpponent] = React.useState(false);
  const [openSearchSportPlayer, setOpenSearchSportPlayer] = React.useState(false);
  const [openViewField, setOpenViewField] = React.useState(false);

  const [createChallenge, newChallenge] = useMutation(CREATE_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createRecurringChallenge, newChallenges] = useMutation(CREATE_RECURRING_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendLilenekNewSportSessionEmail, sentEmailNotification] = useMutation(SEND_LILENEK_NEW_SPORT_SESSION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendFivezerNewManualBookingEmail, sentManualBookingEmail] = useMutation(SEND_FIVEZER_NEW_MANUAL_BOOKING_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendNotification, isSent] = useMutation(SEND_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [fieldAvailability, isFieldAvailable] = useMutation(FIELD_AVAILABILITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportTeams = useQuery(FIND_SPORT_TEAMS_BY_TEAM_NAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team: {
        name: inputFields.opponent_name,
        type: inputFields.type,
        sport: inputFields.sport_type,
      },
    },
  });
  const loadedComplexes = useQuery(FIND_COMPLEXES_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !country?.label,
    variables: {
      options: {
        name: inputFields.complex_name,
        city: city?.name,
        country: country?.label,
      },
    },
  });
  const loadedFields = useQuery(FIND_FREE_FIELD_BY_COUNTRY_NAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !country?.label,
    variables: {
      country_name: country?.label,
    },
  });

  const CITIES: any = City.getCitiesOfCountry(inputFields?.country?.value || country?.value)?.filter((c: any) => _.startsWith(c?.name, _.capitalize(inputFields.city_name)));
  const SELECTED_PROFILE = loggedPlayerProfiles?.find((p: any) => p?.id === loggedPlayerActiveProfile?.id);
  const ALL_CHALLENGERS = _.concat(
    SELECTED_PROFILE?.player_positions
      ?.filter((p: any) => p?.team_player?.status === 'ACTIVE')
      ?.filter((p: any) => p?.team_captain)
      ?.map((t: any) => t?.team_captain)
  )?.filter((i: any) => i?.id);

  const SELECTED_CHALLENGER = ALL_CHALLENGERS?.find((t: any) => t?.id === inputFields.challenger);
  const ALL_COMPLEXES = loadedComplexes.data?.findComplexesByOptions;
  const ALL_OPPONENTS = loadedSportTeams.data?.findSportTeamsByTeamName?.filter((t: any) => t?.id !== SELECTED_CHALLENGER?.id);

  const loadedSportPlayers = useQuery(FIND_SPORT_PLAYERS_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !inputFields.player_alias || inputFields.player_alias === '' || !SELECTED_CHALLENGER,
    variables: {
      player: {
        alias: inputFields.player_alias,
        team_sport: SELECTED_CHALLENGER?.__typename,
        team_id: SELECTED_CHALLENGER?.id,
        team_type: SELECTED_CHALLENGER?.type,
      },
    },
  });
  const loadedSelectedSportTeam = useQuery(FIND_SPORT_TEAM_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !SELECTED_CHALLENGER?.id,
    variables: {
      team: {
        id: SELECTED_CHALLENGER?.id,
      },
    },
  });

  const ALL_FREE_FIELDS = loadedFields.data?.findFreeFieldsByCountry;
  const ALL_FIELDS = _.concat(ALL_FREE_FIELDS, inputFields?.complex ? inputFields?.complex?.fields : ALL_COMPLEXES?.map((c: any) => c?.fields)?.flat())?.filter((f: any) => f);
  const ALL_SPORT_PLAYERS = loadedSportPlayers.data?.findSportPlayersByTeamID;
  const ALL_TEAM_LINEUPS = loadedSelectedSportTeam.data?.findSportTeamByTeamID?.team_lineups?.filter((t: any) => t?.players?.length > 0);
  const SELECTED_LINEUP = ALL_TEAM_LINEUPS?.find((t: any) => t?.id === inputFields.team_lineup_id);

  const DefaultIcon = L.icon({
    iconUrl: '../../img/marker-icon.png',
    shadowUrl: iconShadow,
  });
  const SelectedIcon = L.icon({
    iconUrl: '../../img/active-marker-icon.png',
    shadowUrl: iconShadow,
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setCountry(country);
      setValue('country', country);
    }
  };

  const onChangeCity = (event: any, city: any) => {
    if (city) {
      setCity(city);
      setValue('city', city);
    }
  };

  const handleChallengeType = async (type: string) => {
    if (type !== null) {
      setValue('type', type);
    }
  };

  const handleStartDate = async (date: any) => {
    const d = new Date(date);
    const date_end = new Date(d.setHours(d.getHours() + 2));
    setValue('start_date', date);
    setValue('end_date', date_end);

    try {
      await handleFieldAvailability(inputFields.selected_field, date, date_end);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEndDate = async (date: any) => {
    setValue('end_date', date);
    try {
      await handleFieldAvailability(inputFields.selected_field, null, date);
    } catch (e) {
      console.log(e);
    }
  };

  const handleLastDate = async (date: any) => {
    setValue('last_date', date);
  };

  const handleComplex = (value: any) => {
    setValue('complex', value);
    setOpenSearchComplex(false);
  };

  const handleSelectComplex = (value: any) => {
    setValue('complex', value);
  };

  const handleRemoveComplex = () => {
    setValue('complex', UNDEFINED_AS_ANY);
    setValue('field', '');
  };

  const handleOpponent = (value: any) => {
    setValue('opponent', value);
    setOpenSearchOpponent(false);
  };

  const handleRemoveOpponent = () => {
    setValue('opponent', UNDEFINED_AS_ANY);
  };

  const handleSelectField = async (selected_field: any) => {
    try {
      const selected_complex = ALL_COMPLEXES?.find((c: any) => c?.fields?.find((f: any) => f?.id === selected_field?.id));
      if (selected_complex) {
        setValue('complex', selected_complex);
      }

      setValue('field', selected_field?.id);
      await handleFieldAvailability(selected_field?.id);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFieldAvailability = async (field_id: any, start_date?: any, end_date?: any) => {
    try {
      if ((inputFields.start_date || start_date) && (inputFields.end_date || end_date) && field_id) {
        await fieldAvailability({
          variables: {
            field: {
              id: field_id,
              start_date: TO_TIMESTAMP((inputFields.start_date || start_date)?.toString()),
              end_date: TO_TIMESTAMP((inputFields.end_date || end_date)?.toString()),
            },
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenViewField = (selected_field: any) => {
    setValue('selected_field', selected_field?.id);
    setOpenViewField(true);
  };

  const handleCloseViewField = () => {
    setOpenViewField(false);
  };

  const handleTeamLineup = (value: any) => {
    const newList = _.concat(inputFields.team_lineup, value);
    setValue('team_lineup', newList);
    setOpenSearchSportPlayer(false);
  };

  const handleRemoveSportPlayer = (player_id: string) => {
    const newList = inputFields.team_lineup?.filter((p: any) => p?.id !== player_id);
    setValue('team_lineup', newList);
  };

  const handleSelectLineup = (value: boolean) => {
    setValue('is_new_lineup', value);
    if (value) {
      setValue('team_lineup_id', '');
    } else {
      setValue('team_lineup', ARRAY_AS_ANY);
    }
  };

  const handleWeekDay = (day: number) => {
    if (inputFields.week_days.includes(day)) {
      const newList = inputFields.week_days?.filter((d: number) => d !== day);
      setValue('week_days', newList);
    } else {
      const newList = _.concat(inputFields.week_days, day);
      setValue('week_days', newList);
    }
  };

  const handleCreateChallenge = async () => {
    try {
      const challenge_duration = DURATION_IN_HOURS(TO_TIMESTAMP(inputFields.start_date?.toString()), TO_TIMESTAMP(inputFields.end_date?.toString()));

      let result: any = undefined;

      if (inputFields.is_recurring) {
        result = await createRecurringChallenge({
          variables: {
            challenge: {
              week_days: _.uniq(_.concat(inputFields.week_days, inputFields.start_date?.getDay())),
              duration: DURATION_IN_HOURS(TO_TIMESTAMP(inputFields.start_date?.toString()), TO_TIMESTAMP(inputFields.end_date?.toString())),
              title: inputFields.name !== '' ? inputFields.name : `${SELECTED_CHALLENGER?.alias || SELECTED_CHALLENGER?.name} vs ${inputFields?.opponent?.name || (!inputFields?.opponent?.id && inputFields.type === 'FRIENDLY') ? '?' : 'Random Mercenaries FC'}`,
              type: inputFields.type,
              sport_type: inputFields.sport_type,
              is_private: inputFields.is_private,
              start_date: TO_TIMESTAMP(inputFields.start_date?.toString()),
              end_date: TO_TIMESTAMP(inputFields.last_date?.toString()),
              home_team:
                !inputFields?.is_new_lineup && inputFields.team_lineup_id !== ''
                  ? {
                      id: inputFields.team_lineup_id,
                    }
                  : {
                      name: SELECTED_CHALLENGER?.name || `Team ${SELECTED_CHALLENGER?.alias}`,
                      players_id: inputFields.team_lineup
                        ?.map((p: any) => {
                          return p?.player_positions?.find((ps: any) => ps?.team_player?.id === SELECTED_CHALLENGER?.id)?.id;
                        })
                        ?.filter((i: any) => i),
                      captains_id: _.concat(
                        loggedPlayerProfiles
                          ?.find((p: any) => p?.id === loggedPlayerActiveProfile?.id)
                          ?.sport_teams?.find((t: any) => t?.id === SELECTED_CHALLENGER?.id)
                          ?.captains?.map((c: any) => c?.id)
                          ?.filter((i: any) => i),
                        SELECTED_CHALLENGER?.captains?.map((c: any) => c?.id)?.filter((i: any) => i)
                      )?.filter((i: any) => i),
                      team_id: SELECTED_CHALLENGER?.alias ? undefined : SELECTED_CHALLENGER?.id,
                    },
              away_team: {
                name: inputFields?.opponent?.name || 'Random Mercenaries FC',
                players_id: [],
                captains_id: inputFields?.opponent?.captains?.map((p: any) => p?.id) || [],
                team_id: inputFields?.opponent?.id,
              },
              complex_id: inputFields.complex?.id,
              arena_id: inputFields.field,
              cost: {
                value: challenge_duration > 0 ? challenge_duration * inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value || 0 : undefined,
                currency: inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.currency?.currency_code || 'MAT',
              },
            },
          },
        });
      } else {
        result = await createChallenge({
          variables: {
            challenge: {
              short_id: challenge_nanoid(),
              title: inputFields.name !== '' ? inputFields.name : `${SELECTED_CHALLENGER?.alias || SELECTED_CHALLENGER?.name} vs ${inputFields?.opponent?.name || (!inputFields?.opponent?.id && inputFields.type === 'FRIENDLY') ? '?' : 'Random Mercenaries FC'}`,
              type: inputFields.type,
              sport_type: inputFields.sport_type,
              is_private: inputFields.is_private,
              start_date: TO_TIMESTAMP(inputFields.start_date?.toString()),
              end_date: TO_TIMESTAMP(inputFields.end_date?.toString()),
              home_team:
                !inputFields?.is_new_lineup && inputFields.team_lineup_id !== ''
                  ? {
                      id: inputFields.team_lineup_id,
                    }
                  : {
                      name: SELECTED_CHALLENGER?.name || `Team ${SELECTED_CHALLENGER?.alias}`,
                      players_id: inputFields.team_lineup
                        ?.map((p: any) => {
                          return p?.player_positions?.find((ps: any) => ps?.team_player?.id === SELECTED_CHALLENGER?.id)?.id;
                        })
                        ?.filter((i: any) => i),
                      captains_id: _.concat(
                        loggedPlayerProfiles
                          ?.find((p: any) => p?.id === loggedPlayerActiveProfile?.id)
                          ?.sport_teams?.find((t: any) => t?.id === SELECTED_CHALLENGER?.id)
                          ?.captains?.map((c: any) => c?.id)
                          ?.filter((i: any) => i),
                        SELECTED_CHALLENGER?.captains?.map((c: any) => c?.id)?.filter((i: any) => i)
                      )?.filter((i: any) => i),
                      team_id: SELECTED_CHALLENGER?.alias ? undefined : SELECTED_CHALLENGER?.id,
                    },
              away_team: {
                name: inputFields?.opponent?.name || 'Random Mercenaries FC',
                players_id: [],
                captains_id: inputFields?.opponent?.captains?.map((p: any) => p?.id) || [],
                team_id: inputFields?.opponent?.id,
              },
              complex_id: inputFields.complex?.id,
              arena_id: inputFields.field,
              cost: {
                value: challenge_duration > 0 ? challenge_duration * inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value || 0 : undefined,
                currency: inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.currency?.currency_code || 'MAT',
              },
            },
          },
        });
      }

      _.concat(
        inputFields.team_lineup?.map((p: any) => p?.player?.id)?.filter((i: any) => i),
        inputFields?.opponent?.captains?.map((c: any) => c?.player?.player?.id)?.filter((i: any) => i),
        ALL_TEAM_LINEUPS?.find((t: any) => t?.id === inputFields?.team_lineup_id)
          ?.players?.map((p: any) => p?.player?.player?.id)
          ?.filter((i: any) => i)
      )
        ?.filter((p: any) => p !== undefined)
        ?.map(async (player_id: any) => {
          try {
            await sendNotification({
              variables: {
                notification: {
                  title: `${intl.formatMessage({ id: 'Notification_New_Challenge_Title' })}`,
                  content: `${intl.formatMessage({ id: 'Notification_New_Challenge_Content_1' })} "${inputFields.name !== '' ? inputFields.name : `${SELECTED_CHALLENGER?.alias || SELECTED_CHALLENGER?.name} vs ${inputFields?.opponent?.name || 'Random Mercenaries FC'}`}" ${intl.formatMessage({ id: 'Notification_New_Challenge_Content_2' })} ${
                    inputFields?.opponent?.name ? `${inputFields?.opponent?.name}${intl.formatMessage({ id: 'Notification_New_Challenge_Content_3' })}` : ''
                  } ${intl.formatMessage({ id: 'Notification_New_Challenge_Content_4' })} ${
                    inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price
                      ? `${intl.formatMessage({ id: 'Notification_New_Challenge_Content_5' })} ${inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.currency?.currency_code} ${inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value / 10} - ${inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.currency?.currency_code} ${
                          inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value / 12
                        }`
                      : `${intl.formatMessage({ id: 'Notification_New_Challenge_Content_6' })}`
                  }`,
                  actions: [`VIEW_CHALLENGE_@_${result?.data?.createChallenge?.short_id || result?.data?.createRecurringChallenge?.short_id}`],
                  sender_id: loggedPlayer?.id,
                  receivers_ids: [player_id],
                },
              },
            });
          } catch (e) {
            console.log(e);
          }
        });

      try {
        await sendLilenekNewSportSessionEmail({
          variables: {
            account: {
              supplier_id: inputFields.complex?.owners?.find((o: any) => o?.__typename === 'Company')?.id,
              order_link: `https://beta.lilenek.com/dashboard`,
              host: window.location.origin,
              language: loggedPlayer?.app_configuration?.prefered_language,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }

      try {
        if ((!inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value || inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value === 0) && inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.name) {
          await sendFivezerNewManualBookingEmail({
            variables: {
              account: {
                start_date: TO_TIMESTAMP(inputFields.start_date?.toString()),
                end_date: TO_TIMESTAMP(inputFields.end_date?.toString()),
                user_firstname: loggedPlayer?.owner?.firstname,
                complex_name: inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.name,
                complex_email: inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.email?.address,
                host: window.location.origin,
                language: loggedPlayer?.app_configuration?.prefered_language,
              },
            },
          });
        }
      } catch (e) {
        console.log(e);
      }

      if (result) {
        navigate(`/challenge/${result?.data?.createChallenge?.short_id || result?.data?.createRecurringChallenge?.short_id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (SELECTED_CHALLENGER?.alias && inputFields.type !== 'MATCHMAKER') {
      setValue('type', 'MATCHMAKER');
    }

    if (inputFields.is_new_lineup === undefined && loadedSelectedSportTeam.called && ALL_TEAM_LINEUPS !== undefined) {
      setValue('is_new_lineup', ALL_TEAM_LINEUPS?.length > 0 ? false : true);
    }
  }, [SELECTED_CHALLENGER, inputFields, setValue, loadedSelectedSportTeam, ALL_TEAM_LINEUPS]);

  return (
    <Grid container className={'form-page component-challenge new-challenge-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid container>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Grid container id="max-width">
          <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        </Grid>

        <Typography id="max-width" className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
          {`${intl.formatMessage({ id: 'FormNewChallenge_Title' })}`}
        </Typography>

        {loggedPlayer?.profiles?.length === 0 ? (
          <Grid id="max-width" sx={{ textAlign: 'center' }}>
            <Typography sx={{ m: '30px 0', p: '0 10%', fontSize: '1.3em' }}>
              <FormattedMessage id="FormNewTeam_Create_Profile_Label" />
            </Typography>

            <Fab variant="extended" size="small" className="button-green" component={Link} sx={{ mt: '20px' }} to={`/new_footballer`}>
              <FormattedMessage id="FormNewTeam_Create_Profile" />
            </Fab>
          </Grid>
        ) : (
          <form className={'form-root'}>
            <Grid id="max-width" sx={{ p: '50px 10% 0' }}>
              {ALL_CHALLENGERS?.length === 0 ? (
                <Grid sx={{ textAlign: 'center', width: '100%' }}>
                  <Typography sx={{ fontSize: '1.3em', mb: '30px' }}>
                    {`${intl.formatMessage({ id: 'FormNewChallenge_No_Team_Caption_1' })}`}
                    <br />
                    {`${intl.formatMessage({ id: 'FormNewChallenge_No_Team_Caption_2' })}`}
                    <br />
                    {`${intl.formatMessage({ id: 'FormNewChallenge_No_Team_Caption_3' })}`}
                  </Typography>
                  <Fab component={Link} to={'/new_team'} className="button-green" variant="extended" size="small">
                    <TeamsIcon />
                    &nbsp;{`${intl.formatMessage({ id: 'FormNewChallenge_No_Team_Button_New_Team' })}`}
                  </Fab>
                </Grid>
              ) : (
                <>
                  <Controller
                    control={control}
                    name="challenger"
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        value={field.value}
                        name="challenger"
                        select
                        label={`${intl.formatMessage({ id: 'FormNewChallenge_Challenger_Label_1' })} ${ALL_CHALLENGERS?.find((t: any) => t?.id === field.value)?.name || ALL_CHALLENGERS?.find((t: any) => t?.id === field.value)?.alias || `${intl.formatMessage({ id: 'FormNewChallenge_Challenger_Label_2' })}`}`}
                        placeholder={`${intl.formatMessage({ id: 'FormNewChallenge_Challenger_Placeholder' })}`}
                        variant="outlined"
                        error={errors?.challenger !== undefined}
                        required
                        fullWidth
                        className={'field-bottom-space'}
                      >
                        {ALL_CHALLENGERS?.map((option: any) => (
                          <MenuItem key={option?.id} value={option?.id} onClick={() => setValue('type', 'FRIENDLY')}>
                            <Grid container sx={{ alignItems: 'center' }}>
                              <Avatar src={option?.avatar?.path} alt={option?.name} />
                              &nbsp;
                              <strong>{option?.name || option?.alias}</strong>
                            </Grid>
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </>
              )}
            </Grid>

            {inputFields.challenger === '' ? (
              ''
            ) : (
              <>
                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 10% 0px' }}>
                  {`${intl.formatMessage({ id: 'FormNewChallenge_Category_Title' })}`}
                </Typography>
                <Typography id="max-width" variant="caption" sx={{ p: '0px 10% 20px', display: 'block' }}>
                  {inputFields.type === 'LEAGUE' ? `${intl.formatMessage({ id: 'FormNewChallenge_Category_Caption_League' })}` : ''}
                  {inputFields.type === 'FRIENDLY' ? `${intl.formatMessage({ id: 'FormNewChallenge_Category_Caption_Friendly' })}` : ''}
                  {inputFields.type === 'MATCHMAKER' ? `${intl.formatMessage({ id: 'FormNewChallenge_Category_Caption_Matchmaker' })}` : ''}
                </Typography>
                <Grid id="max-width" className={'position field-bottom-space'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
                  <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                    {CHALLENGE_TYPES?.filter((c: any) => (SELECTED_CHALLENGER?.type === 'FRIENDLY' || SELECTED_CHALLENGER?.alias ? c?.value !== 'LEAGUE' : true))?.map((type: any) => (
                      <Button
                        key={type.value}
                        value={type.value}
                        onClick={() => handleChallengeType(type.value)}
                        sx={{
                          'height': '110px',
                          'width': '150px',
                          'm': '0 20px 0',
                          'display': 'inline-block',
                          'borderRadius': '10px',
                          'border': inputFields.type === type.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                          'color': inputFields.type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                          '& svg': { fontSize: '40px', color: inputFields.type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                        }}
                      >
                        {type?.icon}
                        <br />
                        {`${intl.formatMessage({ id: type.label })}`}
                      </Button>
                    ))}
                  </Stack>
                </Grid>

                <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'FormNewChallenge_Name_Label' })}`}
                        placeholder={`${SELECTED_CHALLENGER?.name || `${intl.formatMessage({ id: 'FormNewChallenge_Name_Placeholder' })}`} vs `}
                        variant="outlined"
                        fullWidth
                        required
                        className={'field-bottom-space'}
                        value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
                        error={errors?.name?.type !== undefined}
                        helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormNewChallenge_Name_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Name_2' })}`) : ``}` : ''}
                      />
                    )}
                  />

                  {SELECTED_CHALLENGER?.alias ? (
                    ''
                  ) : (
                    <>
                      <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 10px' }}>
                        {`${intl.formatMessage({ id: 'FormNewChallenge_Your_Lineup_Title' })}`}
                      </Typography>

                      {ALL_TEAM_LINEUPS?.length > 0 ? (
                        <Controller
                          name="is_new_lineup"
                          control={control}
                          rules={{
                            required: false,
                          }}
                          render={({ field }: any) => <FormControlLabel {...field} control={<Switch checked={field.value} onChange={(event: any, value: any) => handleSelectLineup(value)} inputProps={{ 'aria-label': 'controlled' }} />} label={inputFields.is_new_lineup ? `${intl.formatMessage({ id: 'FormNewChallenge_Your_Lineup_Label_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Your_Lineup_Label_2' })} (${ALL_TEAM_LINEUPS?.length})`} sx={{ pb: '20px' }} />}
                        />
                      ) : (
                        ''
                      )}

                      {inputFields.is_new_lineup ? (
                        <>
                          {isSmallMobile ? (
                            <Stack
                              direction="row"
                              sx={{
                                justifyContent: 'center',
                                mb: '20px',
                              }}
                            >
                              <Grid>
                                {inputFields.team_lineup?.length === 0 ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      m: '0px',
                                      p: '5px 20px',
                                      textAlign: 'center',
                                      bgcolor: 'rgba(247, 251, 250, .04)',
                                      borderRadius: '5px',
                                    }}
                                  >
                                    {`${intl.formatMessage({ id: 'FormNewChallenge_Team_Selected' })}`}
                                  </Typography>
                                ) : (
                                  inputFields.team_lineup?.map((p: any) => (
                                    <Chip
                                      key={p?.id}
                                      variant="outlined"
                                      label={<strong>{p?.alias}</strong>}
                                      avatar={
                                        <Avatar
                                          sx={{
                                            height: {
                                              xs: '50px !important',
                                              md: '60px !important',
                                              lg: '70px !important',
                                            },
                                            width: {
                                              xs: '50px !important',
                                              md: '60px !important',
                                              lg: '70px !important',
                                            },
                                            m: '5px 0',
                                          }}
                                          alt={p?.alias}
                                          src={p?.avatar?.path}
                                        />
                                      }
                                      onDelete={() => handleRemoveSportPlayer(p?.id)}
                                      sx={{ m: '5px', height: 'auto', border: 'none' }}
                                    />
                                  ))
                                )}
                              </Grid>
                            </Stack>
                          ) : (
                            ''
                          )}

                          <Controller
                            name="all_sport_players"
                            control={control}
                            render={({ field }: any) => (
                              <Autocomplete
                                {...field}
                                open={openSearchSportPlayer}
                                onOpen={() => setOpenSearchSportPlayer(true)}
                                onClose={() => setOpenSearchSportPlayer(false)}
                                multiple
                                autoHighlight
                                filterSelectedOptions
                                value={field.value}
                                loading={loadedSportPlayers.loading}
                                inputValue={inputFields.player_alias}
                                onInputChange={(event: any, newInputValue: any) => {
                                  setValue('player_alias', newInputValue);
                                }}
                                options={(ALL_SPORT_PLAYERS ? ALL_SPORT_PLAYERS : [])?.filter((cf: any) => inputFields.team_lineup?.find((p: any) => p?.id === cf?.id) === undefined)}
                                getOptionLabel={(option: any) => option?.id}
                                defaultValue={ARRAY_AS_ANY}
                                renderOption={(props: any, option: any) => (
                                  <Grid
                                    container
                                    key={option?.id}
                                    onClick={() => handleTeamLineup(option)}
                                    sx={{
                                      alignItems: 'center',
                                      p: '10px 15px',
                                    }}
                                  >
                                    <Avatar src={option?.avatar?.path} />
                                    &nbsp;&nbsp;
                                    <strong>{option?.alias}</strong>
                                  </Grid>
                                )}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label={`${intl.formatMessage({ id: 'FormNewChallenge_New_Lineup_Label' })}`}
                                    placeholder={`${intl.formatMessage({ id: 'FormNewChallenge_New_Lineup_Placeholder' })}`}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          {isSmallMobile ? (
                                            ''
                                          ) : (
                                            <Grid>
                                              {inputFields.team_lineup?.length === 0 ? (
                                                <Typography
                                                  variant="caption"
                                                  sx={{
                                                    m: '0px',
                                                    p: '5px 20px',
                                                    textAlign: 'center',
                                                    bgcolor: 'rgba(247, 251, 250, .04)',
                                                    borderRadius: '5px',
                                                  }}
                                                >
                                                  {`${intl.formatMessage({ id: 'FormNewChallenge_Team_Selected' })}`}
                                                </Typography>
                                              ) : (
                                                inputFields.team_lineup?.map((p: any) => (
                                                  <Chip
                                                    key={p?.id}
                                                    variant="outlined"
                                                    label={<strong>{p?.alias}</strong>}
                                                    avatar={
                                                      <Avatar
                                                        sx={{
                                                          height: {
                                                            xs: '50px !important',
                                                            md: '60px !important',
                                                            lg: '70px !important',
                                                          },
                                                          width: {
                                                            xs: '50px !important',
                                                            md: '60px !important',
                                                            lg: '70px !important',
                                                          },
                                                          m: '5px 0',
                                                        }}
                                                        alt={p?.alias}
                                                        src={p?.avatar?.path}
                                                      />
                                                    }
                                                    onDelete={() => handleRemoveSportPlayer(p?.id)}
                                                    sx={{ m: '5px', height: 'auto', border: 'none' }}
                                                  />
                                                ))
                                              )}
                                            </Grid>
                                          )}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <Controller
                            control={control}
                            name="team_lineup_id"
                            render={({ field }: any) => (
                              <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'FormNewChallenge_Edit_Lineup_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewChallenge_Edit_Lineup_Placeholder' })}`} variant="outlined" error={errors?.team_lineup_id !== undefined} fullWidth className={'field-bottom-space'}>
                                {ALL_TEAM_LINEUPS?.map((option: any) => (
                                  <MenuItem key={option?.id} value={option?.id}>
                                    {option?.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />

                          {inputFields?.team_lineup_id === '' ? (
                            ''
                          ) : (
                            <Grid container sx={{ justifyContent: 'center', bgcolor: 'rgba(247, 251, 250, 0.04)', p: '20px', borderRadius: '10px' }}>
                              {SELECTED_LINEUP?.players?.map((p: any, index: number) => (
                                <Grid key={index}>
                                  <Badge
                                    badgeContent={
                                      SELECTED_LINEUP?.captains?.find((c: any) => c?.id === p?.id) ? (
                                        <>
                                          &nbsp;
                                          <CaptainIcon sx={{ transform: 'scale(.7)' }} />
                                        </>
                                      ) : (
                                        ''
                                      )
                                    }
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                  >
                                    <Chip
                                      label={
                                        <Grid container sx={{ alignItems: 'center', color: 'rgba(247, 251, 250, 1)' }}>
                                          {p?.player?.alias}
                                        </Grid>
                                      }
                                      avatar={<Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} />}
                                      sx={{ m: '0 10px 10px 0', minHeight: '20px', bgcolor: 'rgba(247, 251, 250, .04)' }}
                                    />
                                  </Badge>
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                    {`${intl.formatMessage({ id: 'FormNewChallenge_Against_Title' })}`}
                  </Typography>
                  {isSmallMobile ? (
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'center',
                        mb: '20px',
                      }}
                    >
                      <Grid>
                        {inputFields.opponent === undefined ? (
                          <Typography
                            variant="caption"
                            sx={{
                              m: '0px',
                              p: '5px 20px',
                              textAlign: 'center',
                              bgcolor: 'rgba(247, 251, 250, .04)',
                              borderRadius: '5px',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'FormNewChallenge_Against_Label' })}`}
                          </Typography>
                        ) : (
                          <Chip
                            variant="outlined"
                            label={ALL_OPPONENTS?.find((cf: any) => cf?.id === inputFields.opponent?.id)?.name}
                            avatar={
                              <Avatar
                                sx={{
                                  height: {
                                    xs: '50px !important',
                                    md: '60px !important',
                                    lg: '70px !important',
                                  },
                                  width: {
                                    xs: '50px !important',
                                    md: '60px !important',
                                    lg: '70px !important',
                                  },
                                  m: '5px 0',
                                }}
                                alt={ALL_OPPONENTS?.find((cf: any) => cf?.id === inputFields.opponent?.id)?.name}
                                src={ALL_OPPONENTS?.find((cf: any) => cf?.id === inputFields.opponent?.id)?.avatar?.path}
                              />
                            }
                            onDelete={handleRemoveOpponent}
                            sx={{ m: '5px', height: 'auto', border: 'none' }}
                            size="medium"
                          />
                        )}
                      </Grid>
                    </Stack>
                  ) : (
                    ''
                  )}
                  <Controller
                    name="all_opponents"
                    control={control}
                    render={({ field }: any) => (
                      <Autocomplete
                        {...field}
                        open={openSearchOpponent}
                        onOpen={() => setOpenSearchOpponent(true)}
                        onClose={() => setOpenSearchOpponent(false)}
                        multiple
                        autoHighlight
                        filterSelectedOptions
                        value={field.value}
                        loading={loadedSportTeams.loading}
                        inputValue={inputFields.opponent_name}
                        onInputChange={(event: any, newInputValue: any) => {
                          setValue('opponent_name', newInputValue);
                        }}
                        options={(ALL_OPPONENTS ? ALL_OPPONENTS : [])?.filter((cf: any) => cf?.id !== inputFields.opponent?.id)}
                        getOptionLabel={(option: any) => option?.name}
                        defaultValue={ARRAY_AS_ANY}
                        renderOption={(props: any, option: any) => (
                          <Grid
                            container
                            key={option?.id}
                            onClick={() => handleOpponent(option)}
                            sx={{
                              alignItems: 'center',
                              p: '10px 15px',
                            }}
                          >
                            <Avatar src={option?.avatar?.path} />
                            &nbsp;&nbsp;
                            <strong>{option?.name}</strong>
                          </Grid>
                        )}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={`${intl.formatMessage({ id: 'FormNewChallenge_Opponent_Title' })}`}
                            placeholder={`${intl.formatMessage({ id: 'FormNewChallenge_Opponent_Placeholder' })}`}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  {isSmallMobile ? (
                                    ''
                                  ) : (
                                    <Grid>
                                      {inputFields.opponent === undefined ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            m: '0px',
                                            p: '5px 20px',
                                            textAlign: 'center',
                                            bgcolor: 'rgba(247, 251, 250, .04)',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          {`${intl.formatMessage({ id: 'FormNewChallenge_Against_Label' })}`}
                                        </Typography>
                                      ) : (
                                        <Chip
                                          variant="outlined"
                                          label={ALL_OPPONENTS?.find((cf: any) => cf?.id === inputFields.opponent?.id)?.name}
                                          avatar={
                                            <Avatar
                                              sx={{
                                                height: {
                                                  xs: '50px !important',
                                                  md: '60px !important',
                                                  lg: '70px !important',
                                                },
                                                width: {
                                                  xs: '50px !important',
                                                  md: '60px !important',
                                                  lg: '70px !important',
                                                },
                                                m: '5px 0',
                                              }}
                                              alt={ALL_OPPONENTS?.find((cf: any) => cf?.id === inputFields.opponent?.id)?.name}
                                              src={ALL_OPPONENTS?.find((cf: any) => cf?.id === inputFields.opponent?.id)?.avatar?.path}
                                            />
                                          }
                                          onDelete={handleRemoveOpponent}
                                          sx={{ m: '5px', height: 'auto', border: 'none' }}
                                          size="medium"
                                        />
                                      )}
                                    </Grid>
                                  )}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                    {`${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Title' })}`}
                  </Typography>

                  <Typography>{inputFields.is_private ? `${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Caption_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Caption_2' })}`}</Typography>
                  <Controller
                    name="is_private"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('is_private', value)} inputProps={{ 'aria-label': 'controlled' }} />}
                  />

                  {inputFields.start_date ? (
                    <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
                      {`${intl.formatMessage({ id: 'FormNewChallenge_How_Long_Title' })}`}&nbsp;
                      <Typography component="span" sx={{ color: 'rgba(168, 202, 65, 1)', fontSize: 'inherit' }}>
                        {DURATION_IN_HOURS(TO_TIMESTAMP(inputFields.start_date?.toString()), TO_TIMESTAMP(inputFields.end_date?.toString()))} {DURATION_IN_HOURS(TO_TIMESTAMP(inputFields.start_date?.toString()), TO_TIMESTAMP(inputFields.end_date?.toString())) > 1 ? `${intl.formatMessage({ id: 'FormNewChallenge_How_Long_Hours' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_How_Long_Hour' })}`}
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
                      {`${intl.formatMessage({ id: 'FormNewChallenge_When_Title' })}`}
                    </Typography>
                  )}
                  <Grid sx={{ display: 'inline-flex', alignItems: 'center', p: '0px 0 20px' }}>
                    <Controller
                      name="is_recurring"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('is_recurring', value)} inputProps={{ 'aria-label': 'controlled' }} />}
                    />
                    <Typography>{inputFields.is_recurring ? `${intl.formatMessage({ id: 'FormNewChallenge_Recurring_Label_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Recurring_Label_2' })}`}</Typography>
                  </Grid>
                </Grid>

                {inputFields.is_recurring ? (
                  <>
                    <Grid id="max-width" className={'position field-bottom-space'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
                      <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 20px 0', minWidth: '300px' }}>
                        {WEEKDAYS?.map((day: any) => (
                          <Button
                            key={day.value}
                            value={day.index}
                            onClick={() => handleWeekDay(day.index)}
                            sx={{
                              'height': '110px',
                              'width': '150px',
                              'm': '0 20px 0',
                              'display': 'inline-block',
                              'borderRadius': '10px',
                              'border': inputFields.week_days?.includes(day?.index) ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                              'color': inputFields.week_days?.includes(day?.index) ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                              '& svg': { fontSize: '40px', color: inputFields.week_days?.includes(day?.index) ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                            }}
                          >
                            {`${intl.formatMessage({ id: 'FormNewChallenge_Recurring_Label_3' })}`}
                            <br />
                            {`${intl.formatMessage({ id: day.label })}`}s
                          </Button>
                        ))}
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
                <Grid id="max-width" sx={{ p: '0 10% 0' }}>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => (
                      <DateTimePicker
                        {...field}
                        value={field.value}
                        disablePast
                        views={['day', 'month', 'hours', 'minutes']}
                        openTo="day"
                        format="d MMM yyyy HH:mm"
                        renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                        ampm={false}
                        sx={{ width: !inputFields.start_date ? '100%' : { xs: '100%', sm: '45%' }, m: !inputFields.start_date ? '0' : { xs: '0', sm: '0 2.5%' } }}
                        className={'field-bottom-space'}
                        onChange={handleStartDate}
                        label={inputFields.is_recurring ? `${intl.formatMessage({ id: 'FormNewChallenge_Start_Date_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Start_Date_2' })}`}
                      />
                    )}
                  />

                  {!inputFields.start_date ? (
                    ''
                  ) : (
                    <>
                      <Controller
                        name="end_date"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        render={({ field }: any) => (
                          <DateTimePicker
                            {...field}
                            value={field.value}
                            disablePast
                            views={['day', 'month', 'hours', 'minutes']}
                            minDate={inputFields.start_date}
                            maxDate={new Date(inputFields.start_date || Date.now()).setFullYear(new Date(inputFields.start_date || Date.now()).getFullYear() + 1)}
                            format="d MMM yyyy HH:mm"
                            renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                            ampm={false}
                            sx={{ width: { xs: '100%', sm: '45%' }, m: { xs: '0', sm: '0 2.5%' } }}
                            className={'field-bottom-space'}
                            onChange={handleEndDate}
                            label={inputFields.is_recurring ? `${intl.formatMessage({ id: 'FormNewChallenge_End_Date_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_End_Date_2' })}`}
                          />
                        )}
                      />
                      {inputFields.is_recurring ? (
                        <>
                          <Controller
                            name="last_date"
                            control={control}
                            rules={{
                              required: false,
                            }}
                            render={({ field }: any) => (
                              <DateTimePicker
                                {...field}
                                value={field.value}
                                disablePast
                                views={['day', 'month', 'hours', 'minutes']}
                                minDate={inputFields.end_date}
                                maxDate={new Date(inputFields.start_date || Date.now()).setFullYear(new Date(inputFields.start_date || Date.now()).getFullYear() + 1)}
                                format="d MMM yyyy HH:mm"
                                renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                                ampm={false}
                                sx={{ width: '100%' }}
                                className={'field-bottom-space'}
                                onChange={handleLastDate}
                                label={`${intl.formatMessage({ id: 'FormNewChallenge_Last_Day' })}`}
                              />
                            )}
                          />
                          <Typography sx={{ display: 'inline-block', width: '100%', textAlign: 'center', p: '20px 0 0' }}>
                            {`${intl.formatMessage({ id: 'FormNewChallenge_Last_Day_Label_1' })}`}&nbsp;
                            <strong>
                              {inputFields.week_days?.length} {inputFields.week_days?.length > 1 ? `${intl.formatMessage({ id: 'FormNewChallenge_Last_Day_Label_2' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Last_Day_Label_3' })}`}
                            </strong>
                            &nbsp;{`${intl.formatMessage({ id: 'FormNewChallenge_Last_Day_Label_4' })}`} <strong>{inputFields.last_date ? <Moment format="dddd d MMM yyyy HH:mm">{inputFields.last_date}</Moment> : '...'}</strong>
                          </Typography>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Grid>

                {!inputFields.start_date ? (
                  ''
                ) : (
                  <>
                    <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                      <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                        {`${intl.formatMessage({ id: 'FormNewChallenge_Where_Title' })}`}
                      </Typography>
                      <Controller
                        control={control}
                        name="country"
                        render={({ field }: any) => (
                          <Autocomplete
                            {...field}
                            options={COUNTRIES}
                            value={country}
                            className={'country-select'}
                            onChange={(event: any, value: any) => onChangeCountry(event, value)}
                            autoHighlight
                            getOptionLabel={(option: CountryType) => option.label}
                            isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                            renderOption={(props: any, option: CountryType) => (
                              <Grid
                                component="li"
                                sx={{
                                  '& > img': {
                                    mr: 2,
                                    flexShrink: 0,
                                  },
                                }}
                                {...props}
                              >
                                <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                                {option.label} ({option.value})
                              </Grid>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${intl.formatMessage({ id: 'Global_Address_Country' })}`}
                                value={country}
                                required
                                variant="outlined"
                                className={'field-bottom-space'}
                                error={errors?.country !== undefined}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="city"
                        render={({ field }: any) => (
                          <Autocomplete
                            {...field}
                            options={CITIES}
                            value={city}
                            className={'city-select'}
                            onChange={(event: any, value: any) => onChangeCity(event, value)}
                            onInputChange={(event: any, newInputValue: any) => {
                              setValue('city_name', newInputValue);
                            }}
                            autoHighlight
                            getOptionLabel={(option: any) => option.name}
                            isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                            renderOption={(props: any, option: any) => (
                              <Grid
                                component="li"
                                sx={{
                                  '& > img': {
                                    mr: 2,
                                    flexShrink: 0,
                                  },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Grid>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${intl.formatMessage({ id: 'Global_Address_City_1' })}`}
                                placeholder={`${intl.formatMessage({ id: 'Global_Address_City_2' })}`}
                                value={city}
                                autoComplete="off"
                                variant="outlined"
                                className={'field-bottom-space'}
                                error={errors?.city !== undefined}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        name="all_complexes"
                        control={control}
                        render={({ field }: any) => (
                          <Autocomplete
                            {...field}
                            open={openSearchComplex}
                            onOpen={() => setOpenSearchComplex(true)}
                            onClose={() => setOpenSearchComplex(false)}
                            multiple
                            autoHighlight
                            filterSelectedOptions
                            value={field.value}
                            loading={loadedComplexes.loading}
                            inputValue={inputFields.complex_name}
                            onInputChange={(event: any, newInputValue: any) => {
                              setValue('complex_name', newInputValue);
                            }}
                            options={ALL_COMPLEXES ? ALL_COMPLEXES : []}
                            getOptionLabel={(option: any) => option?.id}
                            defaultValue={ARRAY_AS_ANY}
                            renderOption={(props: any, option: any) => (
                              <Grid
                                container
                                key={option?.id}
                                onClick={() => handleComplex(option)}
                                sx={{
                                  alignItems: 'center',
                                  p: '10px 15px',
                                }}
                              >
                                <Avatar src={option?.logo?.path} alt={option?.name} />
                                &nbsp;&nbsp;
                                <strong>{option?.name}</strong>
                              </Grid>
                            )}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                fullWidth
                                label={`${intl.formatMessage({ id: 'FormNewChallenge_Complexes_Label' })}`}
                                className={'field-bottom-space'}
                                placeholder={inputFields?.complex ? '' : `${intl.formatMessage({ id: 'FormNewChallenge_Complexes_Placeholder' })}`}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <>
                                      <Grid>
                                        {inputFields.complex === undefined ? (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              m: '0px',
                                              p: '5px 20px',
                                              textAlign: 'center',
                                              bgcolor: 'rgba(247, 251, 250, .04)',
                                              borderRadius: '5px',
                                            }}
                                          >
                                            {`${intl.formatMessage({ id: 'FormNewChallenge_Complexes_No_Selected' })}`}
                                          </Typography>
                                        ) : (
                                          <Chip
                                            variant="outlined"
                                            label={<strong>{inputFields?.complex?.name}</strong>}
                                            avatar={
                                              <Avatar
                                                sx={{
                                                  height: {
                                                    xs: '40px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  width: {
                                                    xs: '40px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                }}
                                                alt={inputFields?.complex?.name}
                                                src={inputFields?.complex?.logo?.path}
                                              />
                                            }
                                            onDelete={handleRemoveComplex}
                                            sx={{
                                              m: '5px',
                                              height: 'auto',
                                              border: 'none',
                                            }}
                                          />
                                        )}
                                      </Grid>
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="field"
                        render={({ field }: any) => (
                          <TextField {...field} value={field.value} name="field" select label={`${intl.formatMessage({ id: 'FormNewChallenge_Field_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewChallenge_Field_Placeholder' })}`} variant="outlined" error={errors?.field !== undefined} required fullWidth className={'field-bottom-space'}>
                            {ALL_FIELDS?.map((option: any) => (
                              <MenuItem key={option.id} value={option.id} onClick={() => handleSelectField(option)}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>

                    <Grid sx={{ 'overflow': 'auto', '& .leaflet-control-attribution': { display: 'none' } }}>
                      <MapContainer center={inputFields?.complex?.location?.coordinates ? [inputFields?.complex?.location?.coordinates?.latitude, inputFields?.complex?.location?.coordinates?.longitude] : center_position} zoom={11} scrollWheelZoom={false} style={{ height: '400px' }}>
                        <TileLayer attribution="" url={REACT_APP_CUSTOM_MAP_URL} />
                        {ALL_COMPLEXES?.map((c: any, index: number) => (
                          <Grid key={index}>
                            <Marker position={c?.location?.coordinates ? [c?.location?.coordinates?.latitude, c?.location?.coordinates?.longitude] : center_position} icon={inputFields?.complex?.id === c?.id ? SelectedIcon : DefaultIcon}>
                              <Popup minWidth={150} maxWidth={290}>
                                <Grid onClick={() => handleSelectComplex(c)} container sx={{ 'justifyContent': 'center', 'alignItems': 'center', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                                  <Avatar sx={{ height: '30px', width: '30px' }} src={c?.logo?.path} alt={c?.name} />
                                  &nbsp;
                                  <strong>{c?.name}</strong>
                                </Grid>
                              </Popup>
                            </Marker>
                          </Grid>
                        ))}
                      </MapContainer>
                      <Grid sx={{ mt: '-120px', position: 'relative', zIndex: '1000', width: '100%', height: '200px', backgroundImage: `linear-gradient(to bottom, rgba(15,15,15,0) 0%, rgba(15,15,15,1) 40%)` }}>
                        <Grid sx={{ overflow: 'auto', p: '0 10% 0', textAlign: 'center' }}>
                          <Stack
                            direction="row"
                            sx={{
                              m: '10px 0',
                              display: 'inline-flex',
                            }}
                          >
                            {ALL_FIELDS?.map((f: any, index: number) => (
                              <CardMedia
                                key={index}
                                onClick={() => handleSelectField(f)}
                                sx={{
                                  justifyContent: 'center',
                                  alignItems: 'end',
                                  display: 'flex',
                                  borderRadius: '10px',
                                  border: f?.id === inputFields.field ? (!isFieldAvailable.data?.fieldAvailability ? 'solid 3px rgba(238, 44, 32, 1)' : 'solid 3px rgba(168, 202, 65, 1)') : 'none',
                                  width: { xs: '200px', sm: '300px' },
                                  height: { xs: '150px', sm: '150px' },
                                  m: '0 30px 0 0',
                                  backgroundImage: `linear-gradient(to bottom, rgba(15,15,15,0) 30%, rgba(15,15,15,.8) 60%, rgba(15,15,15,.95) 90%), url(${f?.pictures ? f?.pictures[0]?.path : ''})`,
                                }}
                              >
                                <Typography onClick={() => handleOpenViewField(f)} sx={{ 'textAlign': 'center', 'lineHeight': '15px', 'mb': '20px', 'fontWeight': '700', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                                  {f?.id === inputFields.field ? (
                                    <Typography className="DANGER" component="span" sx={{ width: '100%', display: 'inline-block', fontWeight: '700' }}>
                                      {!isFieldAvailable.data?.fieldAvailability ? `${intl.formatMessage({ id: 'FormNewChallenge_Fields_Already_Booked' })}` : ''}
                                    </Typography>
                                  ) : (
                                    ''
                                  )}
                                  {f?.name}
                                  <br />
                                  <Typography variant="caption">({f?.rental_asset?.price?.value && f?.rental_asset?.price?.value > 0 ? `${f?.rental_asset?.price?.currency?.currency_code} ${f?.rental_asset?.price?.value} / ${intl.formatMessage({ id: 'FormNewChallenge_Fields_Hour' })}` : f?.complex?.name ? `${intl.formatMessage({ id: 'FormNewChallenge_Fields_Manual_Booking' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Fields_Free' })}`})</Typography>
                                </Typography>
                              </CardMedia>
                            ))}
                            <ViewFieldModal open={openViewField} handleClose={handleCloseViewField} field_id={ALL_FIELDS?.find((f: any) => f?.id === inputFields.selected_field)?.short_id} />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>

                    {(!inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value || inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value === 0) && inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.name ? (
                      <Grid container id="max-width" sx={{ p: { xs: '0 5%', sm: '0 10%' } }}>
                        <Alert severity="info" variant="outlined" icon={<Info sx={{ color: 'rgba(3, 169, 244, 1) !important' }} />} sx={{ 'color': 'rgba(3, 169, 244, 1)', 'fontSize': '1.2em', 'textAlign': 'center', 'display': 'inline-block', '& .MuiAlert-icon': { justifyContent: 'center' } }}>
                          <strong>{inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.name}</strong>&nbsp;{`${intl.formatMessage({ id: 'FormNewChallenge_Fields_Manual_Booking_Caption' })}`}
                          <br />
                          <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Fab variant="extended" size="small" className="button-blue" component={'a'} href={`mailto:${inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.email?.address}`} sx={{ bgcolor: 'transparent', boxShadow: 'none', fontSize: 'inherit', m: '5px', textTransform: 'lowercase' }}>
                              <Email sx={{ color: 'rgba(3, 169, 244, 1) !important' }} />
                              &nbsp;
                              {inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.email?.address}
                            </Fab>

                            <Fab variant="extended" size="small" className="button-blue" component={'a'} href={`tel:${inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.telephone}`} sx={{ bgcolor: 'transparent', boxShadow: 'none', fontSize: 'inherit', m: '5px' }}>
                              <Phone sx={{ color: 'rgba(3, 169, 244, 1) !important' }} />
                              &nbsp;
                              {inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.telephone}
                            </Fab>
                          </Grid>
                        </Alert>
                      </Grid>
                    ) : (
                      ''
                    )}

                    <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                      <Button onClick={() => navigate(-1)} className={isSent.loading || sentEmailNotification.loading || sentManualBookingEmail.loading || newChallenge.loading || newChallenges.loading ? 'button-disabled' : 'button-cancel'} sx={{ mr: '10px' }}>
                        {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
                      </Button>
                      <LoadingButton
                        onClick={handleCreateChallenge}
                        loading={isSent.loading || sentEmailNotification.loading || sentManualBookingEmail.loading || newChallenge.loading || newChallenges.loading}
                        loadingPosition="end"
                        endIcon={<Send />}
                        className={!isFieldAvailable.data?.fieldAvailability || (inputFields.complex === undefined && inputFields.field === '') || !inputFields.start_date || !inputFields.end_date ? 'button-disabled' : 'button-green'}
                        disabled={!isFieldAvailable.data?.fieldAvailability || (inputFields.complex === undefined && inputFields.field === '') || !inputFields.start_date || !inputFields.end_date}
                        sx={{ mr: '10px' }}
                      >
                        {`${intl.formatMessage({ id: 'FormNewChallenge_Button_Create' })}`}
                      </LoadingButton>
                    </Grid>
                  </>
                )}
              </>
            )}
          </form>
        )}
      </Grid>
    </Grid>
  );
};

export default FormNewChallenge;
