import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';

interface ILongTextFormatter {
  content: string;
  max_length?: number;
}

const LongTextFormatter: React.FC<ILongTextFormatter> = (props: ILongTextFormatter) => {
  const intl = useIntl();
  const [fullContent, setFullContent] = React.useState(false);
  const MAX_LENGTH = props.max_length ?? 300;
  return (
    <>
      {_.truncate(props.content, { length: fullContent ? props.content?.length : MAX_LENGTH })}
      {props.content?.length > MAX_LENGTH ? (
        <Button variant="text" onClick={() => setFullContent(!fullContent)} sx={{ color: 'inherit !important', textTransform: 'inherit', opacity: '.4' }}>
          {fullContent ? `${intl.formatMessage({ id: 'Global_Button_See_Less' })}` : `${intl.formatMessage({ id: 'Global_Button_See_More' })}`}
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

LongTextFormatter.propTypes = {
  content: PropTypes.string.isRequired,
};

LongTextFormatter.defaultProps = {
  content: '',
};

export default React.memo(LongTextFormatter);
