import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { IntlProvider, useIntl } from 'react-intl';
import _ from 'lodash';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectPersonalWallets, selectBusinessWallets } from '../../reducers/walletSlice';

import { Grow, Skeleton, Typography, AppBar, Tab, Tabs, Snackbar, ListItemIcon, MenuItem, Menu, IconButton, Grid, CircularProgress } from '@mui/material';
import { ContentCopy, LockOpen, Lock, Delete, MoreVert } from '@mui/icons-material';
import { FivezerLogoIcon, WalletIcon } from '../../img/icons/Icons';
import { PAYMENT_METHODS, ALTERNATIVE_PAYMENTS } from '../../utilities/utilities';
import BackButton from '../../component-modals/common/BackButton';
import Modal from '../../component-modals/layout/Modal';
import SpendingCodeModal from '../../component-modals/purchase/SpendingCodeModal';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import AvailableCountriesButton from '../../component-modals/wallet/AvailableCountriesButton';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';
import ListTransactions from './ListTransactions';

interface IPaymentMethod {
  payment_method: { id: string };
  wallet: { id: string; type: string };
}

const PaymentMethod: React.FC = () => {
  const FIND_PAYMENT_METHOD_BY_ID_QUERY = gql`
    query findPaymentMethodByID($payment_method: inputFindPaymentMethod!) {
      findPaymentMethodByID(payment_method: $payment_method) {
        id
        name
        label
        status
        is_default
        required_signatures
        wallets {
          id
          owner {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
              owner {
                firstname
                lastname
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
              owner {
                firstname
                lastname
              }
            }
          }
        }
        payment_providers {
          id
          name
        }
        sent_transactions {
          id
          status
          tx_reference
          note_reference
          value {
            value
            currency {
              currency_code
            }
          }
          maat_value {
            value
            currency {
              currency_code
            }
          }
          deposit_token {
            id
            status
          }
          sender_wallet {
            id
            title
            owner {
              ... on Company {
                __typename
                id
                name
                label
                logo {
                  path
                }
              }
              ... on User {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
              ... on Player {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
            }
          }
          receiver_wallet {
            id
            title
            owner {
              ... on Company {
                __typename
                id
                name
                label
                logo {
                  path
                }
              }
              ... on User {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
              ... on Player {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
            }
          }
          execution_date
          confirmed_at
        }
        received_transactions {
          id
          status
          tx_reference
          note_reference
          value {
            value
            currency {
              currency_code
            }
          }
          maat_value {
            value
            currency {
              currency_code
            }
          }
          deposit_token {
            id
            status
          }
          sender_wallet {
            id
            title
            owner {
              ... on Company {
                __typename
                id
                name
                label
                logo {
                  path
                }
              }
              ... on User {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
              ... on Player {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
            }
          }
          receiver_wallet {
            id
            title
            owner {
              ... on Company {
                __typename
                id
                name
                label
                logo {
                  path
                }
              }
              ... on User {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
              ... on Player {
                __typename
                id
                username
                avatar {
                  path
                }
                owner {
                  firstname
                  lastname
                }
              }
            }
          }
          execution_date
          confirmed_at
        }
        payment_source {
          ... on BankCard {
            __typename
            id
            network
            status
            card_number
          }
          ... on BankAccount {
            __typename
            id
            status
            accepted_currencies
          }
          ... on BlockchainCard {
            __typename
            id
            network
            status
            card_number
          }
          ... on BlockchainAccount {
            __typename
            id
            wallet_id
            status
            accepted_currencies
          }
          ... on Cash {
            __typename
            id
            alternative_name: name
            status
          }
          ... on Gold {
            __typename
            id
            alternative_name: name
            status
          }
          ... on Waste {
            __typename
            id
            alternative_name: name
            status
          }
          ... on MobileMoney {
            __typename
            id
            name
            status
            mobile_number
          }
        }
      }
    }
  `;

  const SELECT_DEFAULT_PAYMENT_METHOD_MUTATION = gql`
    mutation selectDefaultPaymentMethod($payment_method: inputSelectDefaultPM!) {
      selectDefaultPaymentMethod(payment_method: $payment_method)
    }
  `;

  const DELETE_PAYMENT_METHOD_MUTATION = gql`
    mutation deletePaymentMethod($payment_method: inputDeletePaymentMethod!) {
      deletePaymentMethod(payment_method: $payment_method)
    }
  `;

  const CREATE_PAYMENT_PROVIDERS_BY_PAYMENT_METHOD_ID_MUTATION = gql`
    mutation createPaymentProvidersByPaymentMethodID($payment_method: inputCreatePaymentProvidersByPaymentMethodID!) {
      createPaymentProvidersByPaymentMethodID(payment_method: $payment_method)
    }
  `;

  const navigate = useNavigate();
  const location: any = useLocation();
  const state: IPaymentMethod = location?.state;
  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerPersonalWallets = useAppSelector(selectPersonalWallets);
  const loggedPlayerBusinessWallets = useAppSelector(selectBusinessWallets);

  const [wallet] = React.useState((state?.wallet?.type === 'BUSINESS' ? loggedPlayerBusinessWallets : loggedPlayerPersonalWallets)?.find((wallet: any) => wallet.id === state?.wallet?.id));

  const [paymentMethod, setPaymentMethod] = React.useState(UNDEFINED_AS_ANY);
  const [openLock, setOpenLock] = React.useState(false);
  const [isLocked, setIsLocked] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openLockConfirmation, setOpenLockConfirmation] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [copied, setCopied] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(anchorEl);

  const [selectDefaultPaymentMethod, updatedPaymentMethod] = useMutation(SELECT_DEFAULT_PAYMENT_METHOD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deletePaymentMethod, removedPaymentMethod] = useMutation(DELETE_PAYMENT_METHOD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createPaymentProvidersByPaymentMethodID, newPaymentProviders] = useMutation(CREATE_PAYMENT_PROVIDERS_BY_PAYMENT_METHOD_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedPaymentMethod = useQuery(FIND_PAYMENT_METHOD_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      payment_method: {
        id: state?.payment_method?.id,
        wallet_id: wallet?.id,
      },
    },
    pollInterval: loggedPlayer?.id ? 3000 : undefined,
  });

  const ALL_TRANSACTIONS = _.concat(paymentMethod?.sent_transactions, paymentMethod?.received_transactions);
  const PAYMENT_METHOD_TABS = [
    {
      label: 'PaymentMethod_All_Tx',
      icon: '',
      fragment: <ListTransactions txs={ALL_TRANSACTIONS} />,
    },
    {
      label: 'PaymentMethod_Tx_Me',
      icon: '',
      fragment: <ListTransactions txs={ALL_TRANSACTIONS?.filter((tx: any) => tx?.sender_wallet?.owner?.id === loggedPlayer?.id || tx?.receiver_wallet?.owner?.id === loggedPlayer?.id)} />,
    },
    {
      label: 'PaymentMethod_Tx_Others',
      icon: '',
      fragment: <ListTransactions txs={ALL_TRANSACTIONS?.filter((tx: any) => tx?.sender_wallet?.owner?.id !== loggedPlayer?.id && tx?.receiver_wallet?.owner?.id !== loggedPlayer?.id)} />,
    },
  ];

  const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleOpenLock = () => {
    setOpenLock(true);
  };

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const handleIsLocked = () => {
    handleClose();

    if (paymentMethod.status === 'BLOCKED') {
      // REPLACE BY API CALL TO UPDATE PAYMENT METHOD STATUS TO UNLOCKED
      const pm = paymentMethod;
      pm.status = 'available';
      setPaymentMethod(pm);
      console.log('Payment Method' + paymentMethod.label + ' unlocked!');
    } else {
      // REPLACE BY API CALL TO UPDATE PAYMENT METHOD STATUS TO LOCKED
      const pm = paymentMethod;
      pm.status = 'BLOCKED';
      setPaymentMethod(pm);
      console.log('Payment Method' + paymentMethod.label + ' locked!');
    }
    setIsLocked(true);
  };

  const handleCopyID = (ref: string) => {
    navigator.clipboard
      .writeText(ref)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((e) => console.error(e));
  };

  const handleDeletePaymentMethod = async () => {
    try {
      await deletePaymentMethod({
        variables: {
          payment_method: {
            id: paymentMethod?.id,
            wallet_id: wallet?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleOpenConfirmation = () => {
    setOpenDelete(false);
    setOpenConfirmation(true);
  };

  const handleOpenLockConfirmation = () => {
    setOpenLockConfirmation(true);
  };

  const handleIsLockedConfirmed = () => {
    handleIsLocked();
  };

  const handleIsRemovalConfirmed = () => {
    handleDeletePaymentMethod();
  };

  const handleClose = () => {
    setOpenLockConfirmation(false);
    setOpenConfirmation(false);
    setOpenDelete(false);
    setOpenLock(false);
    // setIsLocked(false);
    setAnchorEl(null);
  };

  const handleSelectDefaultPaymentMethod = async () => {
    try {
      await selectDefaultPaymentMethod({
        variables: {
          payment_method: {
            id: paymentMethod?.id,
            wallet_id: wallet?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if ((paymentMethod === undefined && loadedPaymentMethod.called && loadedPaymentMethod.data?.findPaymentMethodByID) || !_.isEqual(paymentMethod, loadedPaymentMethod.data?.findPaymentMethodByID)) {
      setPaymentMethod(loadedPaymentMethod.data?.findPaymentMethodByID);
    }

    (async () => {
      if (!newPaymentProviders.called && paymentMethod?.id) {
        try {
          await createPaymentProvidersByPaymentMethodID({
            variables: {
              payment_method: {
                id: paymentMethod?.id,
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }
    })();

    if (removedPaymentMethod.data?.deletePaymentMethod >= 0) {
      navigate(`/w/wallet`, { replace: true, state: { wallet: { id: wallet?.id, type: wallet?.type } } });
    }
  }, [createPaymentProvidersByPaymentMethodID, newPaymentProviders, loadedPaymentMethod, removedPaymentMethod, paymentMethod, wallet, navigate]);

  return (
    <Grid container className={'component-preferences payment-method-page dark-mode'} sx={{ display: 'inline-block', height: '100%' }}>
      <Grid item xl={12} xs={12} sm={12}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xl={6} xs={6} sm={6} className={'back-button'} sx={{ textAlign: 'left' }}>
            <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
          </Grid>
          <Grid item xl={6} xs={6} sm={6} className={'options'} sx={{ textAlign: 'right', p: '0 20px' }}>
            <IconButton onClick={handleOpenOptions}>
              <MoreVert />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={optionsOpen} onClose={handleCloseOptions} PaperProps={{}}>
              {paymentMethod?.is_default ? '' : <MenuItem onClick={handleSelectDefaultPaymentMethod}>{`${intl.formatMessage({ id: 'PaymentMethod_Select_Default' })}`}</MenuItem>}
              {paymentMethod?.status === 'BLOCKED' ? (
                <MenuItem onClick={handleOpenLock}>
                  <ListItemIcon>
                    <LockOpen />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'PaymentMethod_Unlock' })}`}
                </MenuItem>
              ) : (
                <MenuItem onClick={handleOpenLock}>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'PaymentMethod_Lock' })}`}
                </MenuItem>
              )}
              <MenuItem onClick={handleOpenDelete}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                {`${intl.formatMessage({ id: 'PaymentMethod_Delete' })}`}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <IntlProvider locale="en" defaultLocale="en">
          <Grid item xl={12} xs={12} sm={12} className={'title'} sx={{ p: '0 5%' }}>
            <h1>
              {paymentMethod?.label}
              <Typography
                sx={{
                  color: 'rgba(247, 251, 250, .3) !important',
                }}
              >
                {`${intl.formatMessage({ id: 'PaymentMethod_Title' })}`}
              </Typography>
            </h1>
          </Grid>

          <Grid item xl={12} xs={12} sm={12} className={'label'} sx={{ textAlign: 'center', p: '15px 0' }}>
            <Typography variant="caption" sx={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', '& > svg': { fontSize: '90px' } }}>
              {PAYMENT_METHODS?.find((pm: any) => pm?.name === paymentMethod?.name)?.icon_white}&nbsp;<strong>{ALTERNATIVE_PAYMENTS?.find((pm: any) => pm?.name === paymentMethod?.payment_source?.name)?.name.replaceAll('_', ' ')}</strong>{' '}
              <Typography variant="caption" sx={{ fontSize: '.6em', fontWeight: '700' }}>
                &nbsp;&nbsp;{ALTERNATIVE_PAYMENTS?.find((pm: any) => pm?.name === paymentMethod?.payment_source?.name)?.icon}
              </Typography>
              {copied ? (
                <Grow in={copied} style={{ transformOrigin: '0 0 0' }} {...(copied ? { timeout: 1000 } : {})}>
                  <Typography component="span" sx={{ color: '#1faf92 !important', fontWeight: '100' }}>
                    &nbsp;{`${intl.formatMessage({ id: 'PaymentMethod_Copied' })}`}
                  </Typography>
                </Grow>
              ) : (
                <IconButton onClick={() => handleCopyID(paymentMethod?.id)}>
                  <ContentCopy />
                </IconButton>
              )}
            </Typography>

            <Typography className={'status'} sx={{ textTransform: 'uppercase' }}>
              {`${intl.formatMessage({ id: 'Global_Status' })}`}:{' '}
              <strong className={paymentMethod?.status}>
                {paymentMethod?.status !== undefined ? (
                  paymentMethod?.status
                ) : (
                  <Skeleton
                    variant="text"
                    sx={{
                      bgcolor: 'rgba(247, 251, 250, .2)',
                      m: 'auto',
                      width: '40%',
                    }}
                  />
                )}
              </strong>
            </Typography>
            <Grid container sx={{ justifyContent: 'center', textTransform: 'uppercase' }}>
              {!updatedPaymentMethod.loading ? <Typography variant="caption">{paymentMethod?.is_default ? `${intl.formatMessage({ id: 'PaymentMethod_Default' })}` : ''}</Typography> : <CircularProgress className="green-loader" size={20} />}
            </Grid>

            <AvailableCountriesButton payment_providers={paymentMethod?.payment_providers} payment_source_type={paymentMethod?.payment_source?.__typename} />
          </Grid>
        </IntlProvider>

        <Grid container className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`}>
          <AppBar position="static" sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
            <Tabs value={value} onChange={handleChange} variant={'fullWidth'} scrollButtons="auto" aria-label="scrollable auto tabs" centered>
              {PAYMENT_METHOD_TABS.map((tab, index) => (
                <Tab key={index} label={`${intl.formatMessage({ id: tab.label })}`} icon={tab.icon} {...a11yProps(index)} />
              ))}
            </Tabs>
          </AppBar>
          {PAYMENT_METHOD_TABS.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {tab.fragment}
            </TabPanel>
          ))}
        </Grid>
      </Grid>

      <Modal
        title={paymentMethod?.status === 'BLOCKED' ? `Unlock ${paymentMethod?.label}` : `Lock ${paymentMethod?.label}`}
        open={openLock}
        icon={<WalletIcon />}
        content={paymentMethod?.status === 'BLOCKED' ? `${intl.formatMessage({ id: 'PaymentMethod_Unlock_Label' })} ${paymentMethod?.label}?` : `${intl.formatMessage({ id: 'PaymentMethod_Lock_Label' })} ${paymentMethod?.label}?`}
        actionOneTitle={paymentMethod?.status === 'BLOCKED' ? `${intl.formatMessage({ id: 'PaymentMethod_Button_Unlock' })}` : `${intl.formatMessage({ id: 'PaymentMethod_Button_Lock' })}`}
        actionOne={handleOpenLockConfirmation}
        actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
        actionTwo={handleClose}
      />
      <SpendingCodeModal actionOne={handleIsLockedConfirmed} actionTwo={handleClose} open={openLockConfirmation} />

      <DeleteConfirmationModal title={`${intl.formatMessage({ id: 'Global_Permanent_Delete_Title' })} ${paymentMethod?.label}`} open={openDelete} entityId={paymentMethod?.id} entityName={`${paymentMethod?.label}`} actionOne={handleOpenConfirmation} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={handleClose} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} loading={removedPaymentMethod.loading} />
      <SpendingCodeModal actionOne={handleIsRemovalConfirmed} actionTwo={handleClose} open={openConfirmation} />

      {isLocked ? (
        <Snackbar open={isLocked} autoHideDuration={6000} onClose={() => setIsLocked(!isLocked)}>
          <span>{paymentMethod?.status === 'BLOCKED' ? `${paymentMethod?.label} ${intl.formatMessage({ id: 'PaymentMethod_Is_Lock' })}` : `${paymentMethod?.label} ${intl.formatMessage({ id: 'PaymentMethod_Is_Unlock' })}`}</span>
        </Snackbar>
      ) : (
        ''
      )}
    </Grid>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} key={index}>
      {value === index && (
        <Grid item xl={12} xs={12} sm={12} key={index} component={'span'}>
          <Grid key={index}>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default PaymentMethod;
