import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { Fab, Grid, Typography, Dialog, DialogTitle, DialogContent, CardMedia, Avatar, CardHeader, Stack, Chip, IconButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import Modal from '../layout/Modal';
import Moment from 'react-moment';
import { ChatsIcon } from '../../img/icons/Icons';
import MessengerModal from '../messenger/MessengerModal';
import AddSponsorshipNoteModal from './AddSponsorshipNoteModal';

interface IViewSponsorshipProposalModal {
  sponsorship_proposal_id: string;
  handleClose: any;
  open: boolean;
}

const ViewSponsorshipProposalModal: React.FC<IViewSponsorshipProposalModal> = (props: IViewSponsorshipProposalModal) => {
  const FIND_SPONSORSHIP_PROPOSAL_BY_ID_QUERY = gql`
    query findSponsorshipProposalByID($id: ID!) {
      findSponsorshipProposalByID(id: $id) {
        id
        status
        author {
          id
          company {
            id
            name
            label
            logo {
              filename
              path
            }
          }
        }
        descriptions {
          language
          content
        }
        price {
          value
          currency {
            currency_code
          }
        }
        transactions_dates
        banner {
          id
          type
          size
          encoding
          path
        }
        sponsorship {
          id
          status
          author {
            ... on Player {
              __typename
              id
              username
              avatar {
                filename
                path
              }
            }
            ... on Position {
              __typename
              id
              company {
                id
                name
                label
                logo {
                  filename
                  path
                }
              }
            }
          }
          subject {
            ... on Livestream {
              __typename
              id
              title
              status
            }
            ... on Challenge {
              __typename
              id
              title
              challenge_status: status
            }
            ... on Tournament {
              __typename
              id
              title
            }
            ... on Trophy {
              __typename
              id
              name
              tournament {
                id
              }
            }
          }
          sponsored_notes {
            language
            content
          }
          sponsor_notes {
            language
            content
          }
          proposals {
            id
            status
          }
          payment_plan {
            id
          }
        }
        created_at
        updated_at
      }
    }
  `;

  const ACCEPT_SPONSORSHIP_PROPOSAL_MUTATION = gql`
    mutation acceptSponsorshipProposal($proposal: inputRespondSponsorshipProposal!) {
      acceptSponsorshipProposal(proposal: $proposal)
    }
  `;

  const REJECT_SPONSORSHIP_PROPOSAL_MUTATION = gql`
    mutation rejectSponsorshipProposal($proposal: inputRespondSponsorshipProposal!) {
      rejectSponsorshipProposal(proposal: $proposal)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [proposal, setProposal] = React.useState(UNDEFINED_AS_ANY);
  const [openResponse, setOpenResponse] = React.useState({ isAccepted: false, isOpen: false });
  const [openSendMessage, setOpenSendMessage] = React.useState(false);
  const [openAddNote, setOpenAddNote] = React.useState(false);

  const [acceptSponsorshipProposal, acceptedSponsorship] = useMutation(ACCEPT_SPONSORSHIP_PROPOSAL_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [rejectSponsorshipProposal, rejectedSponsorship] = useMutation(REJECT_SPONSORSHIP_PROPOSAL_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSponsorshipProposal = useQuery(FIND_SPONSORSHIP_PROPOSAL_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.sponsorship_proposal_id || !props.open,
    variables: {
      id: props.sponsorship_proposal_id,
    },
  });

  const handleClose = () => {
    setProposal(UNDEFINED_AS_ANY);
    props.handleClose();
  };

  const handleCloseResponse = () => {
    try {
      setOpenResponse({ isAccepted: false, isOpen: false });
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedSponsorshipProposal.refetch({
        proposal: {
          id: proposal?.id,
          author_id: loggedPlayer?.id,
        },
      });

      if (result.data?.findSponsorshipProposalByID) {
        setProposal(result.data?.findSponsorshipProposalByID);
      }
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  const handleAcceptProposal = async () => {
    try {
      const result = await acceptSponsorshipProposal({
        variables: {
          proposal: {
            id: proposal?.id,
            author_id: loggedPlayer?.id,
          },
        },
      });

      if (result.data?.acceptSponsorshipProposal) {
        await handleRefetch();
        handleCloseResponse();
      }
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  const handleRejectProposal = async () => {
    try {
      const result = await rejectSponsorshipProposal({
        variables: {
          proposal: {
            id: proposal?.id,
            author_id: loggedPlayer?.id,
          },
        },
      });

      if (result.data?.rejectSponsorshipProposal) {
        await handleRefetch();
        handleCloseResponse();
      }
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  const handleSendMessage = () => {
    setOpenSendMessage(true);
  };

  const handleCloseAddNote = async () => {
    try {
      await handleRefetch();
      setOpenAddNote(false);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && ((loadedSponsorshipProposal?.called && loadedSponsorshipProposal?.data?.findSponsorshipProposalByID !== undefined) || !_.isEqual(proposal, loadedSponsorshipProposal?.data?.findSponsorshipProposalByID))) {
      setProposal(loadedSponsorshipProposal?.data?.findSponsorshipProposalByID);
    }

    if (proposal && !props.open) {
      setProposal(UNDEFINED_AS_ANY);
    }
  }, [loadedSponsorshipProposal, proposal, props]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: {
          p: '0',
          width: '85%',
          position: 'absolute',
          top: '5%',
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Grid container>
          <Fab
            size="small"
            onClick={handleClose}
            className="button-cancel"
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              m: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Sponsorship proposal</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={'form-page'} sx={{ bgcolor: 'transparent', p: '0', minHeight: 'auto' }}>
          <CardHeader
            avatar={<Avatar src={proposal?.author?.company?.logo?.path} alt={proposal?.author?.company?.label} sx={{ m: 'auto', height: '70px', width: '70px' }} />}
            title={proposal?.author?.company?.label}
            titleTypographyProps={{ fontSize: '1.2em' }}
            subheader={
              <Typography variant="caption">
                {`${intl.formatMessage({ id: 'Global_Status' })}`}:&nbsp;<strong className={proposal?.status === 'PENDING' ? '' : proposal?.status}>{proposal?.status}</strong>
              </Typography>
            }
            action={
              <IconButton className="button-cancel" onClick={handleSendMessage} sx={{ border: 'none !important' }}>
                <ChatsIcon />
              </IconButton>
            }
            sx={{ p: '0' }}
          />

          <MessengerModal
            open={openSendMessage}
            handleClose={() => setOpenSendMessage(false)}
            new_conversation={{
              title: `${proposal?.author?.company?.label} x ${loggedPlayer?.username} conversation`,
              author_id: loggedPlayer?.id,
              mandatory_participants: _.concat({ id: proposal?.author?.company?.id, name: proposal?.author?.company?.name, label: proposal?.author?.company?.label, avatar: proposal?.author?.company?.logo?.path }),
              participants: _.concat({ id: loggedPlayer?.id, avatar: loggedPlayer?.avatar?.path }, { id: proposal?.author?.company?.id, avatar: proposal?.author?.company?.logo?.path }),
            }}
          />

          <Typography sx={{ fontSize: '1.3em', textAlign: 'center', p: '10px 0' }}>
            {`${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Proposed_Sponsorship' })}`}:&nbsp;
            <strong>
              {proposal?.price?.currency?.currency_code} {proposal?.price?.value}
            </strong>
          </Typography>

          {proposal?.transactions_dates?.length === 0 || !proposal?.transactions_dates ? (
            ''
          ) : (
            <>
              <Typography variant="caption">
                {`${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Payment_Plan_1' })}`}&nbsp;
                <strong>
                  {proposal?.transactions_dates?.length} x {proposal?.price?.currency?.currency_code} {(proposal?.price?.value / proposal?.transactions_dates?.length).toFixed(2)}
                </strong>
                &nbsp;{`${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Payment_Plan_2' })}`}&nbsp;
                <strong>
                  <Moment format="D MMM YYYY">{Math.min(...proposal?.transactions_dates)}</Moment>
                </strong>
                &nbsp;{`${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Payment_Plan_3' })}`}&nbsp;
                <strong>
                  <Moment format="D MMM YYYY">{Math.max(...proposal?.transactions_dates)}</Moment>
                </strong>
                .
              </Typography>
              <Stack direction="row" sx={{ justifyContent: 'center' }}>
                {proposal?.transactions_dates?.map((d: string, index: number) => (
                  <Chip key={index} label={<Moment format="D MMM YYYY">{d}</Moment>} sx={{ m: '10px', color: 'inherit', bgcolor: 'rgba(247, 251, 250, .04)' }} />
                ))}
              </Stack>
            </>
          )}

          <CardMedia
            component={'img'}
            src={proposal?.banner?.path}
            sx={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '20px',
              p: '0',
              height: '200px',
              width: '100%',
            }}
          />

          {proposal?.sponsorship?.payment_plan?.id || (proposal?.sponsorship?.subject?.__typename === 'Livestream' && proposal?.sponsorship?.subject?.status === 'ENDED') ? (
            ''
          ) : (
            <Grid container sx={{ justifyContent: 'center', mt: '20px' }}>
              {proposal?.sponsorship?.subject?.__typename === 'Trophy' && proposal?.sponsorship?.proposals?.find((p: any) => p?.status === 'ACCEPTED') ? (
                ''
              ) : (
                <LoadingButton onClick={() => setOpenResponse({ isAccepted: true, isOpen: true })} disabled={rejectedSponsorship.loading} className={rejectedSponsorship.loading ? 'button-disabled' : 'button-green'} sx={{ boxShadow: 'none', m: '0 10px' }}>
                  {`${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Button_Accept' })}`}
                </LoadingButton>
              )}

              <LoadingButton onClick={() => setOpenResponse({ isAccepted: false, isOpen: true })} disabled={acceptedSponsorship.loading} className={acceptedSponsorship.loading ? 'button-disabled' : 'button-red'} sx={{ boxShadow: 'none', m: '0 10px' }}>
                {`${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Button_Reject' })}`}
              </LoadingButton>

              <Modal
                loading={acceptedSponsorship.loading || rejectedSponsorship.loading}
                open={openResponse.isOpen}
                title=""
                content={openResponse.isAccepted ? `${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Response_1' })}` : `${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Response_2' })}`}
                actionOneTitle={openResponse.isAccepted ? `${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Button_Accept' })}` : `${intl.formatMessage({ id: 'ViewSponsorshipProposalModal_Button_Reject' })}`}
                actionOne={openResponse.isAccepted ? handleAcceptProposal : handleRejectProposal}
                actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
                actionTwo={handleCloseResponse}
              />
            </Grid>
          )}

          {proposal?.status === 'ACCEPTED' && proposal?.sponsorship?.status === 'ACQUIRED' && proposal?.sponsorship?.sponsored_notes?.length === 0 && proposal?.sponsorship?.author?.id === loggedPlayer?.id ? (
            <Grid container sx={{ justifyContent: 'center', mt: '20px' }}>
              <Button className="button-green" onClick={() => setOpenAddNote(true)}>
                Add final note
              </Button>
              <AddSponsorshipNoteModal open={openAddNote} sponsorship_id={proposal?.sponsorship?.id} is_sponsor={proposal?.author?.company?.id === loggedPlayer?.id} handleClose={handleCloseAddNote} />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ViewSponsorshipProposalModal.propTypes = {
  sponsorship_proposal_id: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

ViewSponsorshipProposalModal.defaultProps = {
  sponsorship_proposal_id: '',
};

export default ViewSponsorshipProposalModal;
