import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RootState } from '../redux/store';

export interface PlayerState {
  loggedPlayer: any;
  loggedPlayerProfiles: any[];
  loggedPlayerActiveProfile: any;
}

const initialState: PlayerState = {
  loggedPlayer: {},
  loggedPlayerProfiles: [],
  loggedPlayerActiveProfile: {},
};

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storePlayer: (state: any, action: PayloadAction<any>) => {
      // console.log("state.player", action.payload)
      state.loggedPlayer = action.payload;
    },
    storePlayerProfiles: (state: any, action: PayloadAction<any>) => {
      // console.log("state.player", action.payload)
      state.loggedPlayerProfiles = action.payload;
    },
    storePlayerActiveProfile: (state: any, action: PayloadAction<any>) => {
      // console.log("state.player", action.payload)
      state.loggedPlayerActiveProfile = action.payload;
    },
    switchUserLanguage: (state: any, action: PayloadAction<any>) => {
      // console.log("state.player", action.payload)
      state.loggedPlayer = action.payload;
    },
    removePreviousPlayer: (state: any) => {
      state.loggedPlayer = {
        app_configuration: {
          prefered_language: state.loggedPlayer?.app_configuration?.prefered_language,
        },
      };
    },
  },

  extraReducers: (builder: any) => {
    builder.addCase(PURGE, (state: any) => {
      state.loggedPlayer = {
        app_configuration: {
          prefered_language: state.loggedPlayer?.app_configuration?.prefered_language,
          previous_player: {
            username: state.loggedPlayer?.username,
            avatar: state.loggedPlayer?.avatar,
            email: state.loggedPlayer?.email?.address,
          },
        },
      };
      state.loggedPlayerProfiles = [];
      state.loggedPlayerActiveProfile = {};
    });
  },
});

export const { storePlayer, storePlayerActiveProfile, storePlayerProfiles, switchUserLanguage, removePreviousPlayer } = playerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPlayer = (state: RootState) => state.player.loggedPlayer;
export const selectPlayerProfiles = (state: RootState) => state.player.loggedPlayerProfiles;
export const selectPlayerActiveProfile = (state: RootState) => state.player.loggedPlayerActiveProfile;

export default playerSlice.reducer;
