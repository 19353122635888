import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntlProvider, useIntl } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tabs, Tab, Fab, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { MyBusinessIcon, SingleMemberIcon } from '../../img/icons/Icons';
import SearchPlayers from './SearchPlayers';
import SearchTeams from './SearchTeams';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { translations } from '../../lang/translations';

interface ISearchEngineModal {
  handleClose: any;
  open: boolean;
}

const SearchEngineModal: React.FC<ISearchEngineModal> = (props: ISearchEngineModal) => {
  try {
    const loggedPlayer = useAppSelector(selectPlayer);
    const intl = useIntl();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
      setValue(index);
    };

    return (
      <>
        <Dialog
          open={props.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'} modal-feature`}
          PaperProps={{
            sx: { minWidth: { xs: '70%', md: '50%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
          }}
          onClose={() => props.handleClose()}
          keepMounted
        >
          <IntlProvider
            messages={translations?.find((t: any) => t?.value === (translations?.find((t: any) => t?.value === loggedPlayer?.app_configuration?.prefered_language) ? loggedPlayer?.app_configuration?.prefered_language : _.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1] === 'FR' ? 'FR' : 'GB'))?.content}
            locale={_.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1]}
            defaultLocale={_.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[0] ?? 'en'}
          >
            <Fab
              size="small"
              onClick={props.handleClose}
              className="button-cancel"
              sx={{
                boxShadow: 'none',
                ml: '2%',
              }}
            >
              <Close />
            </Fab>
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', p: '0', textTransform: 'uppercase' }}>
              <AppBar position="static" sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                  <Tab label={`${intl.formatMessage({ id: 'Global_Search_Tab_Players' })}`} icon={<SingleMemberIcon />} {...a11yProps(0)} />
                  <Tab label={`${intl.formatMessage({ id: 'Global_Search_Tab_Teams' })}`} icon={<MyBusinessIcon />} {...a11yProps(1)} />
                </Tabs>
              </AppBar>
            </DialogTitle>
            <DialogContent sx={{ p: '0 0 50px' }}>
              <DialogContentText component={'span'} id="alert-dialog-description">
                <Grid sx={{ width: '100%', p: '20px 10px' }}>
                  <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={value} index={0} dir={'ltr'}>
                      <SearchPlayers handleClose={props.handleClose} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={'ltr'}>
                      <SearchTeams handleClose={props.handleClose} />
                    </TabPanel>
                  </SwipeableViews>
                </Grid>
              </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'start' }}>
              <Button onClick={props.handleClose} className="button-cancel">
                {`${intl.formatMessage({ id: 'Global_Button_Close' })}`}
              </Button>
            </DialogActions>
          </IntlProvider>
        </Dialog>
      </>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Grid>
          <Grid>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

SearchEngineModal.propTypes = {
  handleClose: PropTypes.any,
  open: PropTypes.bool.isRequired,
};

SearchEngineModal.defaultProps = {};

export default React.memo(SearchEngineModal);
