import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { QRCodeSVG } from 'qrcode.react';
import { Fab, Grid, Typography } from '@mui/material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';

export default function ShowDepositToken(props: { tx_id: string; open: boolean; handleClose: any }) {
  const FIND_TRANSACTION_BY_ID_QUERY = gql`
    query findTransactionByID($transaction: inputFindTransactionByID!) {
      findTransactionByID(transaction: $transaction) {
        id
        tx_reference
        status
        deposit_token {
          id
          status
          token
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const [tx, setTx] = React.useState(UNDEFINED_AS_ANY);

  const loadedTransaction = useQuery(FIND_TRANSACTION_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      transaction: {
        id: props?.tx_id,
      },
    },
  });

  React.useEffect(() => {
    if (props?.tx_id !== undefined && tx === undefined && loadedTransaction.called && loadedTransaction.data?.findTransactionByID) {
      setTx(loadedTransaction.data?.findTransactionByID);
    }
  }, [props, loadedTransaction, tx]);

  return (
    <>
      <Grid container sx={{ p: '0 10%', display: props.open ? 'block' : 'none' }}>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid sx={{ m: '20px 0 40px', textAlign: 'center' }}>
            <Typography sx={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '1.3em', display: 'inline-block', width: '100%', mb: '10px' }}>({tx?.deposit_token?.token})</Typography>
            <QRCodeSVG value={tx?.deposit_token?.token} size={256} bgColor={'#ffffff'} fgColor={'#000000'} level={'Q'} includeMargin={false} />
          </Grid>
          <Fab variant="extended" size="small" className="button-cancel" onClick={props.handleClose} sx={{ boxShadow: 'none', bgcolor: 'transparent', p: '0 5px' }}>
            Hide QRCode
          </Fab>
        </Grid>
      </Grid>
    </>
  );
}

ShowDepositToken.defaultProps = {};
