import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Cached, PhotoCamera, Remove } from '@mui/icons-material';
import { Avatar, CardMedia, Dialog, DialogActions, DialogContent, Fab, Grid, Stack, Typography } from '@mui/material';
import { getCroppedImg, blobURLtoDataURL, blobURLtoImageFile, dataURLtoBlobURL, isAspectRatioRespected } from '../../helpers';
import ImageUploading from 'react-images-uploading';
import Cropper from 'react-easy-crop';
import { NULL_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { useIntl } from 'react-intl';

interface IUploadIMGFeature {
  pictures: any;
  setter_pictures: any;
  max_number: number;
  accepted_types?: any;
  ratio?: string;
  media_type?: string;
  hasCover?: boolean;
  current_cover_path?: any;
  current_avatar_path?: any;
}

const UploadIMGFeature: React.FC<IUploadIMGFeature> = (props: IUploadIMGFeature) => {
  const intl = useIntl();
  const [selectedIMG, setSelectedIMG] = React.useState(NULL_AS_ANY);
  const [openCrop, setOpenCrop] = React.useState(false);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

  const ASPECTS = [
    { name: 'avatar', value: 1 / 1 },
    { name: 'square', value: 1 / 1 },
    { name: 'cover', value: 1.91 / 1 },
  ];

  const handleClose = () => {
    setSelectedIMG(NULL_AS_ANY);
    setOpenCrop(false);
  };

  const handleOpenCropImg = async (img: any) => {
    setSelectedIMG(img);
    setOpenCrop(true);
  };

  const handleCropComplete = React.useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(selectedIMG?.path || selectedIMG?.dataURL, croppedAreaPixels, 0);

      const newDataURL: any = await blobURLtoDataURL(croppedImage);
      const newIMGFile: any = await blobURLtoImageFile(croppedImage);
      const newIMG = { file: newIMGFile, dataURL: newDataURL };

      const newList = _.concat(
        newIMG,
        props.pictures.filter((i: any) => i !== selectedIMG)
      );

      props.setter_pictures(newList);

      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <ImageUploading value={props.pictures} multiple onChange={props.setter_pictures} maxNumber={props.max_number} acceptType={props.accepted_types}>
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          <>
            {props.media_type === 'cover' ? (
              <>
                <Grid className="upload__image-wrapper" sx={{ maxHeight: '400px', overflow: 'hidden' }}>
                  {imageList[0]?.dataURL ? (
                    ''
                  ) : (
                    <Grid
                      container
                      sx={{
                        justifyContent: 'left',
                        position: 'relative',
                        zIndex: '100',
                        padding: '20px 0 0 0',
                      }}
                    >
                      <Fab style={isDragging ? { color: 'red' } : undefined} onClick={onImageUpload} variant="extended" size="small" {...dragProps} className="button-green-reverse" sx={{ boxShadow: 'none' }}>
                        {`${intl.formatMessage({ id: 'Global_Button_Add_Cover' })}`}
                        <PhotoCamera />
                      </Fab>
                    </Grid>
                  )}

                  {imageList[0] ? <CardMedia component={'img'} src={imageList[0]?.dataURL} onClick={() => handleOpenCropImg(imageList[0])} alt={'imageList[0]?.file.name'} loading="lazy" sx={{ width: 'fit-content', m: 'auto' }} /> : ''}

                  <Grid
                    container
                    sx={{
                      justifyContent: 'left',
                      // position: 'absolute',
                      zIndex: '100',
                      overflow: 'auto',
                    }}
                  >
                    {imageList[0]?.dataURL ? (
                      <Grid>
                        <ImageAspectRatioChecker dataURL={imageList[0]?.dataURL} desiredRatio={ASPECTS.find((a: any) => a.name === props.ratio)?.value} />

                        {imageList
                          .filter((img: any) => img.dataURL !== undefined)
                          .map((image: any, index: any) => (
                            <Stack
                              key={index}
                              className="image-item"
                              direction="column"
                              spacing={{
                                xs: 1,
                                sm: 2,
                              }}
                              sx={{
                                p: '10px 0',
                              }}
                            >
                              <Grid className="image-item__btn-wrapper">
                                <Fab
                                  size="small"
                                  onClick={() => onImageUpdate(index)}
                                  sx={{
                                    bgcolor: 'transparent',
                                    boxShadow: 'none',
                                    color: 'rgba(0, 0, 0, .3)',
                                  }}
                                >
                                  <Cached />
                                </Fab>
                                <Fab
                                  size="small"
                                  onClick={() => onImageRemove(index)}
                                  sx={{
                                    bgcolor: 'transparent',
                                    boxShadow: 'none',
                                    color: 'rgba(238, 44, 32, .3)',
                                  }}
                                >
                                  <Remove />
                                </Fab>
                              </Grid>
                            </Stack>
                          ))}
                      </Grid>
                    ) : (
                      <Grid container>
                        {props.current_cover_path ? (
                          <CardMedia component={'img'} src={props.current_cover_path} alt={'defaut cover'} loading="lazy" sx={{ width: '100%', m: 'auto' }} />
                        ) : (
                          <Grid
                            className={'empty-cover'}
                            sx={{
                              backgroundImage: 'linear-gradient(to top, #000 0%, #C7C7C7 100%)',
                              height: '300px',
                              width: '100%',
                              position: 'relative',
                            }}
                          ></Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              ''
            )}

            {props.media_type === 'avatar' ? (
              <>
                <Grid
                  className="upload__image-wrapper"
                  sx={{
                    top: { xs: '-100px', sm: '-150px' },
                    position: 'relative',
                  }}
                >
                  {imageList[0] ? (
                    <Grid>
                      {imageList
                        .filter((img: any) => img.dataURL !== undefined)
                        .map((image: any, index: any) => (
                          <div key={index} className="image-item">
                            <Avatar
                              onClick={() => handleOpenCropImg(imageList[0])}
                              src={dataURLtoBlobURL(image?.dataURL)}
                              alt={image?.name}
                              sizes="small"
                              sx={{
                                background: '#FFF',
                                border: 'solid 4px #FFF',
                                height: {
                                  xs: 100,
                                  sm: 150,
                                  md: 200,
                                  xl: 250,
                                },
                                width: {
                                  xs: 100,
                                  sm: 150,
                                  md: 200,
                                  xl: 250,
                                },
                                margin: 'auto',
                              }}
                            />
                            <div className="image-item__btn-wrapper">
                              <Fab
                                size="small"
                                onClick={() => onImageUpdate(index)}
                                sx={{
                                  bgcolor: 'transparent',
                                  boxShadow: 'none',
                                  color: 'rgba(0, 0, 0, .3)',
                                }}
                              >
                                <Cached />
                              </Fab>
                              <Fab
                                size="small"
                                onClick={() => onImageRemove(index)}
                                sx={{
                                  bgcolor: 'transparent',
                                  boxShadow: 'none',
                                  color: 'rgba(238, 44, 32, .3)',
                                }}
                              >
                                <Remove />
                              </Fab>
                            </div>
                          </div>
                        ))}
                    </Grid>
                  ) : (
                    <Avatar
                      src={props.current_avatar_path}
                      alt={'default avatar'}
                      sizes="small"
                      sx={{
                        border: 'solid 2px rgba(247, 251, 250, .5)',
                        bgcolor: 'rgba(247, 251, 250, .5)',
                        height: {
                          xs: 100,
                          sm: 150,
                          md: 200,
                          xl: 250,
                        },
                        width: {
                          xs: 100,
                          sm: 150,
                          md: 200,
                          xl: 250,
                        },
                        margin: 'auto',
                      }}
                    />
                  )}

                  {imageList[0]?.dataURL ? (
                    ''
                  ) : (
                    <Fab
                      style={isDragging ? { color: 'green' } : undefined}
                      onClick={onImageUpload}
                      variant="extended"
                      size="small"
                      {...dragProps}
                      className="button-green-reverse"
                      sx={{
                        mt: '40px',
                        boxShadow: 'none',
                      }}
                    >
                      {`${intl.formatMessage({ id: 'Global_Button_Add_Logo' })}`}
                      <PhotoCamera />
                    </Fab>
                  )}
                </Grid>
              </>
            ) : (
              ''
            )}

            {props.media_type !== 'avatar' && props.media_type !== 'cover' ? (
              <>
                <Grid container sx={{ justifyContent: { xs: 'center', lg: 'center' } }}>
                  <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto', p: '0 10% 20px' }}>
                    {imageList[0] ? (
                      <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ p: '10px 0', justifyContent: { xs: 'start', lg: 'center' } }} className="upload__image-wrapper">
                        {imageList?.map((image: any, index: any) => (
                          <div key={index} className="image-item">
                            {image?.path ? '' : <ImageAspectRatioChecker dataURL={image?.dataURL} desiredRatio={ASPECTS.find((a: any) => a.name === props.ratio)?.value} />}

                            <CardMedia
                              component={'img'}
                              onClick={image?.path ? undefined : () => handleOpenCropImg(image)}
                              src={dataURLtoBlobURL(image?.dataURL) || image?.path}
                              alt={image?.name}
                              sx={{
                                height: {
                                  xs: 125,
                                  sm: 150,
                                  md: 200,
                                },
                                width: 'auto',
                                margin: 'auto',
                              }}
                            />
                            <Grid
                              className="image-item__btn-wrapper"
                              sx={{
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              <Fab
                                size="small"
                                onClick={() => onImageUpdate(index)}
                                sx={{
                                  bgcolor: 'transparent',
                                  boxShadow: 'none',
                                  color: 'rgba(0, 0, 0, .3)',
                                }}
                              >
                                <Cached />
                              </Fab>
                              <Fab
                                size="small"
                                onClick={() => onImageRemove(index)}
                                sx={{
                                  bgcolor: 'transparent',
                                  boxShadow: 'none',
                                  color: 'rgba(238, 44, 32, .3)',
                                }}
                              >
                                <Remove />
                              </Fab>
                            </Grid>
                          </div>
                        ))}
                      </Stack>
                    ) : (
                      ''
                    )}
                  </Grid>
                  {props.pictures.length === 10 ? (
                    ''
                  ) : (
                    <>
                      <Fab
                        style={isDragging ? { color: 'green' } : undefined}
                        className="button-green-reverse"
                        sx={{
                          background: 'transparent',
                          boxShadow: 'none',
                        }}
                        onClick={onImageUpload}
                        variant="extended"
                        size="small"
                        {...dragProps}
                      >
                        {`${intl.formatMessage({ id: 'Global_Button_Add' })}`}&nbsp;{`${intl.formatMessage({ id: props.media_type })}`} {props.max_number > 1 ? (props.pictures.length === 0 ? `(${props.max_number} ${intl.formatMessage({ id: 'Global_Pictures_Max' })})` : `(${10 - props.pictures.length} ${intl.formatMessage({ id: 'Global_Pictures_Left' })})`) : ''}
                        <PhotoCamera />
                      </Fab>
                      {!props.pictures || props.pictures.length === 0 ? (
                        ''
                      ) : (
                        <Typography variant="caption" sx={{ width: '100%', textAlign: 'center' }}>
                          {`${intl.formatMessage({ id: 'Global_Button_Resize' })}`}
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>
              </>
            ) : (
              ''
            )}
          </>
        )}
      </ImageUploading>
      <Dialog
        onClose={handleClose}
        open={openCrop}
        className={'component-modal modal-feature'}
        PaperProps={{
          sx: { bgcolor: 'rgba(15,15,15,.7)', minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, height: 'auto', p: '20px 10px' },
        }}
        sx={{
          zIndex: '1000000000',
        }}
      >
        <DialogContent sx={{ width: '100%', minHeight: { xs: '37vh', sm: '57vh', md: '50vh' }, p: '0 20px' }}>
          <Cropper image={selectedIMG?.dataURL || selectedIMG?.path} crop={crop} zoom={zoom} aspect={ASPECTS.find((a: any) => a.name === props.ratio)?.value} cropShape={props.ratio === 'avatar' ? 'round' : 'rect'} showGrid={!(props.ratio === 'avatar')} onCropChange={setCrop} onCropComplete={handleCropComplete} onZoomChange={setZoom} />
        </DialogContent>
        <DialogActions>
          <Fab variant="extended" size="small" className="button-disabled" sx={{ boxShadow: 'none' }} onClick={handleClose}>
            {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
          </Fab>
          <Fab variant="extended" size="small" className="button-green" sx={{ boxShadow: 'none' }} onClick={showCroppedImage}>
            {`${intl.formatMessage({ id: 'Global_Button_Crop' })}`}
          </Fab>
        </DialogActions>
      </Dialog>
    </>
  );
};

function ImageAspectRatioChecker({ dataURL, desiredRatio }: any) {
  const intl = useIntl();
  const [isMatch, setIsMatch] = React.useState(UNDEFINED_AS_ANY);
  const RATIOS = [
    { name: 'avatar', value: 1 / 1 },
    { name: 'square', value: 1 / 1 },
    { name: 'cover', value: 1.91 / 1 },
  ];

  React.useEffect(() => {
    isAspectRatioRespected(dataURL, desiredRatio)
      .then((result: any) => {
        setIsMatch(result);
      })
      .catch((error: any) => {
        console.log(error.message);
        setIsMatch(false); // Set to false in case of an error
      });
  }, [dataURL, desiredRatio]);

  return (
    <div>
      {isMatch === undefined && <p>Checking...</p>}
      {isMatch === true && ''}
      {isMatch === false && (
        <Typography variant="caption" className="WARNING" sx={{ textAlign: 'center', width: '100%', display: 'inline-block' }}>
          {`${intl.formatMessage({ id: 'Global_Button_Ratio' })}`}&nbsp;{RATIOS?.find((r: any) => r?.value === desiredRatio)?.name}
        </Typography>
      )}
    </div>
  );
}

UploadIMGFeature.propTypes = {
  pictures: PropTypes.any.isRequired,
  setter_pictures: PropTypes.any.isRequired,
  max_number: PropTypes.number.isRequired,
  accepted_types: PropTypes.any,
  media_type: PropTypes.string,
  hasCover: PropTypes.bool,
  current_cover_path: PropTypes.any,
  current_avatar_path: PropTypes.any,
};

UploadIMGFeature.defaultProps = {
  hasCover: false,
  media_type: 'Global_Pictures_Label',
  ratio: 'square',
  accepted_types: ['jpg', 'png', 'jpeg'],
};

export default UploadIMGFeature;
