import React from 'react';
import _ from 'lodash';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Avatar, Button, CardMedia, CircularProgress, Grid, IconButton, MenuItem, Stack, Switch, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Delete, Email, Info, Phone, Send } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import Error from '../../component-modals/common/Error';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { Link } from 'react-router-dom';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';
import { TO_TIMESTAMP } from '../../utilities/utilities';
import { DateTimePicker } from '@mui/x-date-pickers';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { REACT_APP_CUSTOM_MAP_URL } from '../../config';
import ViewFieldModal from '../../component-modals/team/ViewFieldModal';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

interface IFormEditChallenge {
  challenge: { id: string };
}

const FormEditChallenge: React.FC = () => {
  const EDIT_CHALLENGE_MUTATION = gql`
    mutation editChallenge($challenge: inputEditChallenge!) {
      editChallenge(challenge: $challenge) {
        id
        short_id
      }
    }
  `;

  const FIND_CHALLENGE_TO_EDIT_BY_ID_QUERY = gql`
    query findChallengeToEditByID($challenge: inputFindChallengeToEditByID!) {
      findChallengeToEditByID(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        is_private
        start_date
        end_date
        booking {
          id
        }
        arena {
          id
        }
        complex {
          id
          name
          telephone
          email {
            address
          }
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          fields {
            id
            short_id
            name
            type
            size
            rank
            pictures {
              path
            }
            rental_asset {
              id
              price {
                value
                currency {
                  currency_code
                }
              }
            }
            complex {
              id
              name
              telephone
              email {
                address
              }
            }
          }
        }
      }
    }
  `;

  const DELETE_CHALLENGE_MUTATION = gql`
    mutation deleteChallenge($challenge: inputDeleteChallenge!) {
      deleteChallenge(challenge: $challenge)
    }
  `;

  const FIELD_AVAILABILITY_MUTATION = gql`
    mutation fieldAvailability($field: inputFieldAvailability!) {
      fieldAvailability(field: $field)
    }
  `;

  const intl = useIntl();
  const location: any = useLocation();
  const state: IFormEditChallenge = location?.state;
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);
  const [openDeleteChallenge, setOpenDeleteChallenge] = React.useState(false);
  const [openViewField, setOpenViewField] = React.useState(false);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: UNDEFINED_AS_ANY,
      is_private: UNDEFINED_AS_ANY,
      start_date: UNDEFINED_AS_ANY,
      end_date: UNDEFINED_AS_ANY,

      field: '',
      selected_field: '',
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();

  const [editChallenge, updatedChallenge] = useMutation(EDIT_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteChallenge, deletedChallenge] = useMutation(DELETE_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [fieldAvailability, isFieldAvailable] = useMutation(FIELD_AVAILABILITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedChallenge = useQuery(FIND_CHALLENGE_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        id: state?.challenge?.id,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });

  const SelectedIcon = L.icon({
    iconUrl: '../../img/active-marker-icon.png',
    shadowUrl: iconShadow,
  });

  const handleFieldAvailability = async (field_id: any, start_date?: any, end_date?: any) => {
    try {
      if ((inputFields.start_date || start_date) && (inputFields.end_date || end_date) && field_id) {
        await fieldAvailability({
          variables: {
            field: {
              id: field_id,
              start_date: TO_TIMESTAMP((inputFields.start_date || start_date)?.toString()),
              end_date: TO_TIMESTAMP((inputFields.end_date || end_date)?.toString()),
            },
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelectField = async (selected_field: any) => {
    try {
      setValue('field', selected_field?.id);
      await handleFieldAvailability(undefined, undefined, selected_field?.id);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenViewField = (selected_field: any) => {
    setValue('selected_field', selected_field?.id);
    setOpenViewField(true);
  };

  const handleCloseViewField = () => {
    setOpenViewField(false);
  };

  const handleStartDate = async (date: any) => {
    const d = new Date(date);
    const date_end = new Date(d.setHours(d.getHours() + 2));
    setValue('start_date', date);
    setValue('end_date', date_end);

    try {
      await handleFieldAvailability(inputFields.field, date, date_end);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEndDate = async (date: any) => {
    setValue('end_date', date);
    try {
      await handleFieldAvailability(inputFields.field, undefined, date);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditChallenge = async () => {
    try {
      await editChallenge({
        variables: {
          challenge: {
            logged_player_id: loggedPlayer?.id,
            id: challenge?.id,
            title: inputFields.name,
            is_private: inputFields.is_private,
            arena_id: inputFields.field,
            start_date: TO_TIMESTAMP(inputFields.start_date?.toString()),
            end_date: TO_TIMESTAMP(inputFields.end_date?.toString()),
          },
        },
      });

      navigate(`/challenge/${challenge?.short_id}`, { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteChallenge = async () => {
    try {
      const result = await deleteChallenge({
        variables: {
          challenge: {
            id: challenge?.id,
            logged_player_id: loggedPlayer?.id,
          },
        },
      });

      if (result.data?.deleteChallenge > 0) {
        navigate(`/teams`, { replace: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!challenge && loadedChallenge.data?.findChallengeToEditByID) {
      setChallenge(loadedChallenge.data?.findChallengeToEditByID);
    }

    if (challenge) {
      if (!inputFields.name && challenge?.title) setValue('name', challenge?.title);
      if (inputFields.is_private === undefined) setValue('is_private', !challenge?.is_private ? false : true);
      if (!inputFields.field && challenge?.arena?.id) setValue('field', challenge?.arena?.id);

      if (!inputFields.start_date && challenge?.start_date) setValue('start_date', new Date(challenge?.start_date));
      if (!inputFields.end_date && challenge?.end_date) setValue('end_date', new Date(challenge?.end_date));
    }
  }, [challenge, loadedChallenge, inputFields]);

  return (
    <Grid container className={'form-page component-challenge new-challenge-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        {challenge === undefined || loadedChallenge.loading ? (
          <Grid item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
            {challenge === undefined && loadedChallenge.called && !loadedChallenge.loading ? <Error title={`challenge`} status={loadedChallenge.error ? 403 : 404} content={loadedChallenge.error ? '' : `We are sorry but it seems like we can not find this challenge`} /> : ''}

            {loadedChallenge.loading && (challenge === undefined || !loadedChallenge.error) ? <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>Loading challenge, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}
          </Grid>
        ) : (
          <>
            <Typography className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
              {`${intl.formatMessage({ id: 'FormEditChallenge_Title' })}`}
            </Typography>

            <form className={'form-root'}>
              <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={`${intl.formatMessage({ id: 'FormNewChallenge_Name_Label' })}`}
                      placeholder={challenge?.name}
                      variant="outlined"
                      fullWidth
                      required
                      className={'field-bottom-space'}
                      value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
                      error={errors?.name?.type !== undefined}
                      helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormNewChallenge_Name_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Name_2' })}`) : ``}` : ''}
                    />
                  )}
                />

                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                  {`${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Title' })}`}
                </Typography>

                <Typography>{inputFields.is_private ? `${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Caption_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Caption_2' })}`}</Typography>

                <Controller
                  name="is_private"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('is_private', value)} inputProps={{ 'aria-label': 'controlled' }} className={'field-bottom-space'} />}
                />

                <Grid container>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => <DateTimePicker {...field} value={field.value} disablePast views={['day', 'month', 'hours', 'minutes']} openTo="day" format="d MMM yyyy HH:mm" renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />} ampm={false} sx={{ width: { xs: '100%', sm: '45%' }, m: { xs: '0', sm: '0 2.5%' } }} className={'field-bottom-space'} onChange={handleStartDate} label={`${intl.formatMessage({ id: 'FormNewChallenge_Start_Date_2' })}`} />}
                  />

                  <Controller
                    name="end_date"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => (
                      <DateTimePicker
                        {...field}
                        value={field.value}
                        disablePast
                        views={['day', 'month', 'hours', 'minutes']}
                        minDate={inputFields.start_date}
                        maxDate={new Date(inputFields.start_date || Date.now()).setFullYear(new Date(inputFields.start_date || Date.now()).getFullYear() + 1)}
                        format="d MMM yyyy HH:mm"
                        renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                        ampm={false}
                        sx={{ width: { xs: '100%', sm: '45%' }, m: { xs: '0', sm: '0 2.5%' } }}
                        className={'field-bottom-space'}
                        onChange={handleEndDate}
                        label={`${intl.formatMessage({ id: 'FormNewChallenge_End_Date_2' })}`}
                      />
                    )}
                  />
                </Grid>

                <Controller
                  control={control}
                  name="field"
                  render={({ field }: any) => (
                    <TextField {...field} value={field.value} name="field" select label={`${intl.formatMessage({ id: 'FormNewChallenge_Field_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewChallenge_Field_Placeholder' })}`} variant="outlined" error={errors?.field !== undefined} required fullWidth className={'field-bottom-space'}>
                      {challenge?.complex?.fields?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id} onClick={() => handleSelectField(option)}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid sx={{ 'overflow': 'auto', '& .leaflet-control-attribution': { display: 'none' } }}>
                <MapContainer center={[challenge?.complex?.location?.coordinates?.latitude, challenge?.complex?.location?.coordinates?.longitude]} zoom={11} scrollWheelZoom={false} style={{ height: '400px' }}>
                  <TileLayer attribution="" url={REACT_APP_CUSTOM_MAP_URL} />
                  <Grid>
                    <Marker position={[challenge?.complex?.location?.coordinates?.latitude, challenge?.complex?.location?.coordinates?.longitude]} icon={SelectedIcon}>
                      <Popup minWidth={150} maxWidth={290}>
                        <Grid container sx={{ 'justifyContent': 'center', 'alignItems': 'center', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                          <Avatar sx={{ height: '30px', width: '30px' }} src={challenge?.complex?.logo?.path} alt={challenge?.complex?.name} />
                          &nbsp;
                          <strong>{challenge?.complex?.name}</strong>
                        </Grid>
                      </Popup>
                    </Marker>
                  </Grid>
                </MapContainer>
                <Grid sx={{ mt: '-120px', position: 'relative', zIndex: '1000', width: '100%', height: '200px', backgroundImage: `linear-gradient(to bottom, rgba(15,15,15,0) 0%, rgba(15,15,15,1) 40%)` }}>
                  <Grid sx={{ overflow: 'auto', p: '0 10% 0', textAlign: 'center' }}>
                    <Stack
                      direction="row"
                      sx={{
                        m: '10px 0',
                        display: 'inline-flex',
                      }}
                    >
                      {challenge?.complex?.fields?.map((f: any, index: number) => (
                        <CardMedia
                          key={index}
                          onClick={() => handleSelectField(f)}
                          sx={{
                            justifyContent: 'center',
                            alignItems: 'end',
                            display: 'flex',
                            borderRadius: '10px',
                            border: f?.id === inputFields.field ? (!isFieldAvailable.data?.fieldAvailability ? (challenge?.start_date === TO_TIMESTAMP(inputFields.start_date) && challenge?.end_date === TO_TIMESTAMP(inputFields.end_date) ? 'solid 3px rgba(3, 169, 244, 1)' : 'solid 3px rgba(238, 44, 32, 1)') : 'solid 3px rgba(168, 202, 65, 1)') : 'none',
                            width: { xs: '200px', sm: '300px' },
                            height: { xs: '150px', sm: '150px' },
                            m: '0 30px 0 0',
                            backgroundImage: `linear-gradient(to bottom, rgba(15,15,15,0) 30%, rgba(15,15,15,.8) 60%, rgba(15,15,15,.95) 90%), url(${f?.pictures ? f?.pictures[0]?.path : ''})`,
                          }}
                        >
                          <Typography onClick={() => handleOpenViewField(f)} sx={{ 'textAlign': 'center', 'lineHeight': '15px', 'mb': '20px', 'fontWeight': '700', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                            {f?.id === inputFields.field ? (
                              <>
                                {challenge?.arena?.id === f?.id && challenge?.start_date === TO_TIMESTAMP(inputFields.start_date) && challenge?.end_date === TO_TIMESTAMP(inputFields.end_date) ? (
                                  <Typography className="INFO" component="span" sx={{ width: '100%', display: 'inline-block', fontWeight: '700' }}>
                                    {`${intl.formatMessage({ id: 'FormNewChallenge_Fields_Current_Booking' })}`}
                                  </Typography>
                                ) : (
                                  <Typography className="DANGER" component="span" sx={{ width: '100%', display: 'inline-block', fontWeight: '700' }}>
                                    {!isFieldAvailable.data?.fieldAvailability ? `${intl.formatMessage({ id: 'FormNewChallenge_Fields_Already_Booked' })}` : ''}
                                  </Typography>
                                )}
                              </>
                            ) : (
                              ''
                            )}
                            {f?.name}
                            <br />
                            <Typography variant="caption">({f?.rental_asset?.price?.value && f?.rental_asset?.price?.value > 0 ? `${f?.rental_asset?.price?.currency?.currency_code} ${f?.rental_asset?.price?.value} / ${intl.formatMessage({ id: 'FormNewChallenge_Fields_Hour' })}` : f?.complex?.name ? `${intl.formatMessage({ id: 'FormNewChallenge_Fields_Manual_Booking' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Fields_Free' })}`})</Typography>
                          </Typography>
                        </CardMedia>
                      ))}
                      <ViewFieldModal open={openViewField} handleClose={handleCloseViewField} field_id={challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.selected_field)?.short_id} />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

              {(!challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value || challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value === 0) && challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.name ? (
                <Grid container id="max-width" sx={{ p: { xs: '0 5%', sm: '0 10%' } }}>
                  <Alert severity="info" variant="outlined" icon={<Info sx={{ color: 'rgba(3, 169, 244, 1) !important' }} />} sx={{ 'color': 'rgba(3, 169, 244, 1)', 'fontSize': '1.2em', 'textAlign': 'center', 'display': 'inline-block', '& .MuiAlert-icon': { justifyContent: 'center' } }}>
                    <strong>{challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.name}</strong>&nbsp;{`${intl.formatMessage({ id: 'FormNewChallenge_Fields_Manual_Booking_Caption' })}`}
                    <br />
                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                      <Phone sx={{ color: 'rgba(3, 169, 244, 1) !important' }} />
                      :&nbsp;
                      <Grid component={'a'} href={`tel:${challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.telephone}`} sx={{ color: 'inherit' }}>
                        {challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.telephone}
                      </Grid>
                      &nbsp;/&nbsp;
                      <Email sx={{ color: 'rgba(3, 169, 244, 1) !important' }} />
                      :&nbsp;
                      <Grid component={'a'} href={`mailto:${challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.email?.address}`} sx={{ color: 'inherit' }}>
                        {challenge?.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.complex?.email?.address}
                      </Grid>
                    </Grid>
                  </Alert>
                </Grid>
              ) : (
                ''
              )}

              <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                <Button className={updatedChallenge.loading ? 'button-disabled' : 'button-red-reversed'} disabled={updatedChallenge.loading} endIcon={<Delete />} onClick={() => setOpenDeleteChallenge(true)} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Delete' })}`}
                </Button>

                <Button onClick={() => navigate(-1)} className={updatedChallenge.loading ? 'button-disabled' : 'button-cancel'} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
                </Button>
                <LoadingButton onClick={handleEditChallenge} loading={updatedChallenge.loading} loadingPosition="end" endIcon={<Send />} className={inputFields.name === '' ? 'button-disabled' : 'button-green'} disabled={inputFields.name === ''} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Save_Updates' })}`}
                </LoadingButton>
              </Grid>
            </form>

            <DeleteConfirmationModal title={`${intl.formatMessage({ id: 'Global_Permanent_Delete_Title' })} ${challenge?.title}`} open={openDeleteChallenge} entityId={challenge?.id} entityName={`${challenge?.title}`} loading={deletedChallenge.loading} actionOne={handleDeleteChallenge} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={() => setOpenDeleteChallenge(false)} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FormEditChallenge;
