import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Avatar, TextField, MenuItem } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectPlayer, selectPlayerActiveProfile, storePlayerActiveProfile } from '../../reducers/playerSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { useIntl } from 'react-intl';

export default function SwitchActiveProfile(props: { color: any }) {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);
  const [profile, setProfile] = React.useState(loggedPlayerActiveProfile?.id || loggedPlayer?.profiles ? loggedPlayer?.profiles[0]?.id : UNDEFINED_AS_ANY);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = event.target.value;
    setProfile(value);
    const newProfile = loggedPlayer?.profiles?.find((p: any) => p?.id === value);
    dispatch(storePlayerActiveProfile(newProfile || loggedPlayer?.profiles[0]));
  };

  // React.useEffect(() => {
  //   if (!loggedPlayerActiveProfile?.id && loggedPlayer?.profiles) {
  //     dispatch(storePlayerActiveProfile(loggedPlayer?.profiles[0]));
  //   }

  //   if (profile?.id !== loggedPlayerActiveProfile?.id) {
  //     dispatch(storePlayerActiveProfile(loggedPlayer?.profiles?.find((p: any) => p?.id === loggedPlayerActiveProfile?.id)));
  //     setProfile(loggedPlayerActiveProfile?.id);
  //   }
  // }, [loggedPlayer, loggedPlayerActiveProfile, dispatch, profile]);

  return (
    <>
      <TextField
        value={profile}
        select
        label={`${intl.formatMessage({ id: 'Global_As_Footballer' })} ${loggedPlayerActiveProfile?.__typename}`}
        onChange={handleChange}
        sx={{ display: loggedPlayer?.profiles?.length > 0 && loggedPlayer?.profiles !== undefined ? 'block' : 'none' }}
        SelectProps={{
          sx: {
            'color': `${props.color} !important`,
            '& fieldset': {
              border: 'none',
            },
            '& .MuiSelect-select': {
              pb: '0',
              pt: '0',
              mt: '10px',
            },
          },
        }}
        InputLabelProps={{
          sx: {
            color: `${props.color} !important`,
            width: '100%',
            textAlign: 'center',
            overflow: 'visible',
          },
        }}
      >
        {loggedPlayer?.profiles?.map((p: any) => (
          <MenuItem key={p?.id} value={p?.id}>
            <Grid container sx={{ alignItems: 'center', fontSize: '.8em' }}>
              <Avatar variant="rounded" src={p?.avatar?.path} alt={p?.alias} sx={{ width: '25px', height: '25px' }} />
              &nbsp;<strong>{p?.alias}</strong>
            </Grid>
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

SwitchActiveProfile.propTypes = {
  color: PropTypes.string,
};
