import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Grid } from '@mui/material';
import { LipayIcon } from '../../img/icons/Icons';

export default function DEXModal(props: { open: boolean; handleClose: any }) {
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Grid container>
      <Dialog open={props.open} onClose={handleClose} className={'component-modal modal-feature'} PaperProps={{ sx: { minWidth: '80%' } }}>
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
          <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>
            Exchange Currencies{' '}
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              powered by&nbsp;
              <LipayIcon sx={{ fontSize: '3em' }} />
            </Typography>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid sx={{ mt: '30px', fontWeight: '700', textAlign: 'center' }}>Coming soon</Grid>
        </DialogContent>
        <DialogActions sx={{ p: '20px 5% 30px' }}>
          {/* <LoadingButton disabled={true} className={true ? 'button-disabled' : 'button-green'} loadingPosition="end" endIcon={<Send />} loading={false}>
            Submit
          </LoadingButton> */}
          <Button onClick={handleClose} className={'button-cancel'}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

DEXModal.propTypes = {};
