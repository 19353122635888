import React from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import _ from 'lodash';
import { Avatar, Grid, Typography, IconButton, ListItem, ListItemAvatar, Tooltip, Menu, TextField, MenuItem, InputAdornment } from '@mui/material';
import { Delete, MoreVert, Send } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { MOODS } from '../../utilities/utilities';
import { useIntl } from 'react-intl';
import { ListItemContent } from '@mui/joy';
import Moment from 'react-moment';
import LongTextFormatter from '../../component-modals/layout/LongTextFormatter';
import useEventLog from '../../hooks/useEventLog';
import { selectPlayer } from '../../reducers/playerSlice';

interface IFlexComment {
  comment: any;
  handleRefetch?: any;
}

const FlexComment: React.FC<IFlexComment> = (props: IFlexComment) => {
  const EDIT_COMMENT_MUTATION = gql`
    mutation editComment($comment: inputEditComment!) {
      editComment(comment: $comment) {
        id
      }
    }
  `;

  const DELETE_COMMENT_MUTATION = gql`
    mutation deleteComment($comment: inputDeleteComment!) {
      deleteComment(comment: $comment)
    }
  `;

  const intl = useIntl();
  const { handleSendLog } = useEventLog();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      comment_input: UNDEFINED_AS_ANY,
      selected_mood: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const [editComment, updatedComment] = useMutation(EDIT_COMMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteComment, deletedComment] = useMutation(DELETE_COMMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
    setValue('selected_mood', UNDEFINED_AS_ANY);
    setValue('comment_input', UNDEFINED_AS_ANY);
  };

  const handleOpenEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setValue('selected_mood', props.comment?.mood);
    setValue('comment_input', props.comment?.content);
  };

  const handleChangeMood = (value: any) => {
    setValue('selected_mood', value);
  };

  const handleEditComment = async () => {
    try {
      const result = await editComment({
        variables: {
          comment: {
            id: props.comment?.id,
            mood: inputFields.selected_mood,
            content: inputFields.comment_input,
            author_id: loggedPlayer?.id,
          },
        },
      });

      if (result.data?.editComment) {
        handleClose();
        await props.handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleDeleteComment = async () => {
    try {
      const result = await deleteComment({
        variables: {
          comment: {
            id: props.comment?.id,
            author_id: loggedPlayer?.id,
          },
        },
      });

      if (result.data?.deleteComment) {
        handleClose();
        await props.handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  return (
    <ListItem
      sx={{ mb: '15px', bgcolor: 'rgba(247, 251, 250, .04)', borderRadius: '10px', alignItems: 'flex-start' }}
      secondaryAction={
        ALL_FLEXERS?.find((f: any) => f?.id === props.comment?.author?.id) ? (
          <>
            <IconButton id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleOpenEdit}>
              <MoreVert />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              slotProps={{
                paper: {
                  sx: {
                    maxWidth: '900px',
                    minWidth: '90%',
                  },
                },
              }}
            >
              <Grid container>
                <IconButton onClick={handleDeleteComment} disabled={deletedComment.loading} className={deletedComment.loading ? 'button-disabled' : 'button-red-reversed'}>
                  <Delete />
                </IconButton>
              </Grid>
              <Controller
                name="comment_input"
                control={control}
                rules={{ required: false, pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._[]\s-]{5,60}$/ }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    placeholder={`${intl.formatMessage({ id: 'Flex_Comment_Placeholder' })}`}
                    fullWidth
                    value={_.trimStart(field.value)}
                    multiline
                    minRows={1}
                    maxRows={10}
                    disabled={updatedComment.loading}
                    InputProps={{
                      startAdornment: (
                        <Controller
                          control={control}
                          name="selected_mood"
                          render={({ field }: any) => (
                            <TextField {...field} value={field.value} select variant="outlined" SelectProps={{ MenuProps: { sx: { '& li .MuiGrid-root': { justifyContent: 'start', fontWeight: '500', color: 'rgba(15, 15, 15, 1)' } } } }}>
                              {MOODS?.map((option) => (
                                <MenuItem key={option?.value} value={option?.value} onClick={() => handleChangeMood(option?.value)}>
                                  <Grid container sx={{ justifyContent: 'center', fontWeight: '700', color: 'rgba(15, 15, 15, .4)' }}>
                                    {option?.icon}&nbsp;&nbsp;
                                    {intl.formatMessage({ id: option?.label })}
                                  </Grid>
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ justifyContent: 'center', alignItems: 'center', p: { xs: '40px 0 10px', sm: '0' } }}>
                          <IconButton className="button-green-reverse" onClick={handleEditComment}>
                            <Send />
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'color': 'rgba(15, 15, 15, 1)', 'mt': '0', 'mb': '0', '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& textarea': { borderRadius: '10px', bgcolor: 'rgba(247, 251, 250, 0)', p: '0px 0px 0px 5px' } },
                    }}
                    sx={{ bgcolor: 'rgba(247, 251, 250, 0.04)', borderRadius: '10px' }}
                  />
                )}
              />
            </Menu>
          </>
        ) : (
          ''
        )
      }
    >
      <ListItemAvatar>
        <Avatar component={Link} to={props.comment?.author?.username ? `/u/${props.comment?.author?.username}` : `/${props.comment?.author?.name}`} alt={props.comment?.author?.username || props.comment?.author?.name} src={(props.comment?.author?.logo || props.comment?.author?.avatar)?.path} />
      </ListItemAvatar>
      <Grid>
        <Typography variant="caption" sx={{ width: '100%', display: 'inline-flex', color: 'rgba(247, 251, 250, .4)' }}>
          <Tooltip title={`${intl.formatMessage({ id: MOODS?.find((m: any) => m?.value === props.comment?.mood)?.label })}`} placement="right" arrow>
            <Grid>{MOODS?.find((m: any) => m?.value === props.comment?.mood)?.icon}</Grid>
          </Tooltip>
          &nbsp;&nbsp;<Moment fromNow>{props.comment?.created_at}</Moment>
          {props.comment?.created_at < props.comment?.updated_at ? <>&nbsp;(edited)</> : ''}
        </Typography>
        <ListItemContent sx={{ pl: '0px', display: 'inline-block !important', textAlign: 'left' }}>
          <LongTextFormatter content={props.comment?.content} />
        </ListItemContent>
      </Grid>
    </ListItem>
  );
};

export default React.memo(FlexComment);
