import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, Card, CardHeader, Avatar, Button, Stepper, Step, StepButton, StepContent, Box, Stack, Autocomplete, TextField, InputAdornment, IconButton, MenuItem, Chip, useMediaQuery, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Close, VisibilityOff, Visibility, Delete, CalendarMonth, ExpandMore } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ALTERNATIVE_PAYMENTS, CARD_NETWORKS, DEPOSIT_TOKEN_REG, LIPAY_PAYMENT_OPTIONS, PAYMENT_METHODS, PRICE_IN_MAAT, TRANSACTION_REG } from '../../utilities/utilities';
import { Controller, useForm } from 'react-hook-form';
import { selectPersonalWallets } from '../../reducers/walletSlice';
import { LocationIcon, TeamsIcon } from '../../img/icons/Icons';
import { customAlphabet, nanoid } from 'nanoid';
import { selectSession } from '../../reducers/sessionSlice';
import Moment from 'react-moment';
import SpendingCodeModal from './SpendingCodeModal';

export default function PaySportSessionsModal(props: { sessions_ids: string[]; tournament_id?: string; handleClose: any; open: any }) {
  const FIND_SPORT_SESSIONS_BY_ID_QUERY = gql`
    query findSportSessionsByID($session: inputFindSportSessionsByID!) {
      findSportSessionsByID(session: $session) {
        id
        short_id
        title
        status
        type
        start_date
        end_date
        cost {
          value
          currency {
            currency_code
          }
        }
        group_order {
          id
          status
          notes
          total_cost {
            value
            currency {
              currency_code
            }
          }
        }
        challenge {
          id
          short_id
          title
          status
          type
          start_date
          end_date
          home_team {
            id
            name
            players {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
            captains {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
          }
          away_team {
            id
            name
            players {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
            captains {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
          }
          complex {
            id
            name
            pictures {
              id
              path
            }
            owners {
              ... on Company {
                __typename
                id
                name
              }
            }
            location {
              id
              title
              coordinates
              country_code
              street_name
              secondary_street_name
              status
              postcode {
                name
              }
              city {
                name
              }
              county {
                name
              }
              state {
                name
              }
              country {
                name
              }
              continent {
                name
              }
            }
          }
          arena {
            id
            name
            pictures {
              id
              path
            }
          }
        }
        consumers {
          id
          payment_status
          player {
            id
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  id
                  path
                }
                player {
                  id
                  username
                  avatar {
                    id
                    path
                  }
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  id
                  path
                }
                player {
                  id
                  username
                  avatar {
                    id
                    path
                  }
                }
              }
            }
          }
          individual_order {
            id
            status
          }
        }
        payment_plan {
          id
          transactions {
            id
            status
            note_reference
          }
        }
      }
    }
  `;

  const FIND_TOURNAMENT_BY_ID_QUERY = gql`
    query findTournamentByID($id: ID!) {
      findTournamentByID(id: $id) {
        id
        organisators {
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              logo {
                filename
                path
              }
            }
          }
        }
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
      }
    }
  `;

  const FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY = gql`
    query findPaymentMethodsByCompanyAccountID($company: inputFindPaymentMethodsByCompanyAccountID!) {
      findPaymentMethodsByCompanyAccountID(company: $company) {
        id
        name
        label
        status
        payment_source {
          ... on BankCard {
            __typename
            id
            network
          }
          ... on BankAccount {
            __typename
            id
            title
          }
          ... on BlockchainCard {
            __typename
            id
            network
          }
          ... on BlockchainAccount {
            __typename
            id
            title
          }
          ... on Cash {
            __typename
            id
            name
          }
          ... on Gold {
            __typename
            id
            name
          }
          ... on Waste {
            __typename
            id
            name
          }
        }
        wallets {
          id
          title
          is_default
        }
      }
    }
  `;

  const FIND_PAYMENT_METHODS_BY_COMPANIES_USER_ID_QUERY = gql`
    query findPaymentMethodsByCompaniesUserID($company: inputFindPaymentMethodsByCompaniesUserID!) {
      findPaymentMethodsByCompaniesUserID(company: $company) {
        id
        name
        label
        status
        payment_source {
          ... on BankCard {
            __typename
            id
            network
          }
          ... on BankAccount {
            __typename
            id
            title
          }
          ... on BlockchainCard {
            __typename
            id
            network
          }
          ... on BlockchainAccount {
            __typename
            id
            title
          }
          ... on Cash {
            __typename
            id
            name
          }
          ... on Gold {
            __typename
            id
            name
          }
          ... on Waste {
            __typename
            id
            name
          }
        }
        wallets {
          id
          title
          is_default
          owner {
            ... on Company {
              __typename
              id
              name
              label
            }
            ... on User {
              __typename
              id
              username
            }
          }
        }
      }
    }
  `;

  const CREATE_TRANSACTION_BY_SPORT_SESSION_ID_MUTATION = gql`
    mutation createTransactionBySportSessionID($transaction: inputCreateTXBySportSessionID!) {
      createTransactionBySportSessionID(transaction: $transaction) {
        id
        purchase {
          ... on SeatSession {
            __typename
            id
          }
        }
      }
    }
  `;

  const FIND_COMPLEXES_RECOMMENDED_ITEMS_BY_NAME_QUERY = gql`
    query findComplexesRecommendedItemsByName($complex: inputFindComplexesRecommendedItemsByName!) {
      findComplexesRecommendedItemsByName(complex: $complex) {
        ... on Article {
          id
          name
          amount
          pictures {
            path
          }
          price {
            value
            currency {
              currency_code
            }
          }
          product {
            id
          }
          in_stock {
            name
            warehouse {
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
          }
          complex_recommendation {
            id
            name
          }
        }
        ... on Service {
          id
          status
          name
          pictures {
            path
          }
          price {
            value
            currency {
              currency_code
            }
          }
          in_stock {
            name
            warehouse {
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
          }
          complex_recommendation {
            id
            name
          }
        }
        ... on Rental {
          id
          name
          pictures {
            path
          }
          price {
            value
            currency {
              currency_code
            }
          }
          pickup_location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          delivery_locations {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          complex_recommendation {
            id
            name
          }
        }
      }
    }
  `;

  const CREATE_GROUP_ORDER_BY_SPORT_SESSION_MUTATION = gql`
    mutation createGroupOrderBySportSession($order: inputCreateGroupOrderBySportSession!) {
      createGroupOrderBySportSession(order: $order) {
        id
      }
    }
  `;

  const ADD_PAYMENT_TO_GROUP_SNACK_ORDER_MUTATION = gql`
    mutation addPaymentToGroupSnackOrder($order: inputAddPaymentToGroupSnackOrder!) {
      addPaymentToGroupSnackOrder(order: $order)
    }
  `;

  const EXECUTE_DIRECT_FIAT_PAYMENT_TRANSACTION_MUTATION = gql`
    mutation executeDirectFiatPaymentTransaction($transaction: inputExecuteDirectFiatTX!) {
      executeDirectFiatPaymentTransaction(transaction: $transaction)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedAccountPersonalWallets = useAppSelector(selectPersonalWallets);
  const [sportSessions, setSportSessions] = React.useState(UNDEFINED_AS_ANY);
  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);

  const [activeStep, setActiveStep] = React.useState(0);
  const [hidePassword, setHidePassword] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [openSearchSportPlayer, setOpenSearchSportPlayer] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const min_code_length = 10;
  const tx_nanoid = customAlphabet(TRANSACTION_REG, 21);
  const deposit_token_nanoid = customAlphabet(DEPOSIT_TOKEN_REG, 15);
  const isSmallMobile = useMediaQuery('(max-width:600px)');

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      consumers_to_pay: ARRAY_AS_ANY,
      total_sessions_to_pay_in_mat: 0,
      total_orders_to_pay_in_mat: 0,
      payment_method_id: '',
      payment_option: 'MAT',
      spending_password: '',

      item_name: '',
      item_amount: 1,
      selected_item: UNDEFINED_AS_ANY,
      selected_group_items: ARRAY_AS_ANY,
      all_items: ARRAY_AS_ANY,

      total_cost_sessions: 0,
    },
  });
  const inputFields = watch();

  const [executeDirectFiatPaymentTransaction, exectuedTransaction] = useMutation(EXECUTE_DIRECT_FIAT_PAYMENT_TRANSACTION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createTransactionBySportSessionID, newTransaction] = useMutation(CREATE_TRANSACTION_BY_SPORT_SESSION_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createGroupOrderBySportSession, newGroupOrder] = useMutation(CREATE_GROUP_ORDER_BY_SPORT_SESSION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [addPaymentToGroupSnackOrder, updatedGroupOrder] = useMutation(ADD_PAYMENT_TO_GROUP_SNACK_ORDER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportSessions = useQuery(FIND_SPORT_SESSIONS_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      session: {
        sessions_ids: props.sessions_ids,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.tournament_id,
    variables: {
      id: props.tournament_id,
    },
  });
  const loadedPaymentMethods = useQuery(FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      company: {
        logged_account_id: loggedPlayer?.id,
      },
    },
  });
  const loadedComplexPaymentMethods = useQuery(FIND_PAYMENT_METHODS_BY_COMPANIES_USER_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      company: {
        companies_ids: _.uniqBy(
          sportSessions?.map((s: any) => s?.challenge?.complex),
          'id'
        )
          ?.filter((i: any) => i)
          ?.map((c: any) => c?.owners?.find((o: any) => o?.__typename === 'Company')?.id),
        logged_user_id: loggedPlayer?.id,
      },
    },
  });
  const loadedItems = useQuery(FIND_COMPLEXES_RECOMMENDED_ITEMS_BY_NAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      complex: {
        complexes_ids: sportSessions?.map((s: any) => s?.challenge?.complex?.id)?.filter((i: any) => i),
        item_name: inputFields.item_name,
      },
    },
  });

  const ALL_ORGANISATORS = _.concat(tournament?.organisators);
  const ALL_TEAM_PLAYERS = _.concat(tournament?.challenge?.home_team?.players, tournament?.challenge?.away_team?.players)?.filter((i: any) => i);
  const ALL_PAYMENT_METHODS = loadedPaymentMethods.data?.findPaymentMethodsByCompanyAccountID;
  const ALL_COMPLEX_PAYMENT_METHODS = loadedComplexPaymentMethods.data?.findPaymentMethodsByCompaniesUserID;
  const ALL_ITEMS = loadedItems.data?.findComplexesRecommendedItemsByName;
  const ALL_COMPLEXES = _.uniqBy(
    sportSessions?.map((s: any) => s?.challenge?.complex),
    'id'
  )?.filter((i: any) => i);
  const ALL_AVAILABLE_COMPLEXES = ALL_COMPLEXES?.filter((o: any) => !o?.group_order)?.filter((s: any) => s?.challenge?.complex);

  const handlePayForThem = async (team_player: any) => {
    try {
      const newList = _.concat(team_player?.id, inputFields.consumers_to_pay);
      setValue('consumers_to_pay', newList);

      await Promise.all(sportSessions?.filter((s: any) => newList?.find((i: string) => s?.id === i))?.map(async (s: any) => await PRICE_IN_MAAT(s?.cost?.value, s?.cost?.currency?.currency_code)))
        .then((results) => {
          setValue('total_sessions_to_pay_in_mat', _.sum(results));
        })
        .catch((error) => {
          // Handle errors if any of the promises reject
          console.error(error);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveConsumer = async (team_player: any) => {
    try {
      const newList = inputFields.consumers_to_pay?.filter((id: string) => id !== team_player?.id);
      setValue('consumers_to_pay', newList);

      await Promise.all(sportSessions?.filter((s: any) => newList?.find((i: string) => s?.id === i))?.map(async (s: any) => await PRICE_IN_MAAT(s?.cost?.value, s?.cost?.currency?.currency_code)))
        .then((results) => {
          setValue('total_sessions_to_pay_in_mat', _.sum(results));
        })
        .catch((error) => {
          // Handle errors if any of the promises reject
          console.error(error);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleUnselectAllSessions = async () => {
    try {
      setValue('consumers_to_pay', ARRAY_AS_ANY);
      setValue('total_sessions_to_pay_in_mat', 0);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelectAllSessions = async () => {
    try {
      const newList = sportSessions?.map((s: any) => s?.id);
      setValue('consumers_to_pay', newList);

      await Promise.all(sportSessions?.map(async (s: any) => await PRICE_IN_MAAT(s?.cost?.value, s?.cost?.currency?.currency_code)))
        .then((results) => {
          setValue('total_sessions_to_pay_in_mat', _.sum(results));
        })
        .catch((error) => {
          // Handle errors if any of the promises reject
          console.error(error);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleSelectItem = (value: any) => {
    setValue('selected_item', value);
    setOpenSearchSportPlayer(false);
  };

  const handleAddGroupItem = async () => {
    try {
      const newList = _.concat({ item: inputFields.selected_item, amount: inputFields.item_amount }, inputFields.selected_group_items);

      const added_value = await PRICE_IN_MAAT(inputFields.item_amount * inputFields.selected_item?.price?.value, inputFields.selected_item?.price?.currency?.currency_code);
      setValue('total_orders_to_pay_in_mat', inputFields.total_orders_to_pay_in_mat + added_value);

      setValue('selected_item', UNDEFINED_AS_ANY);
      setValue('item_amount', 1);
      setValue('selected_group_items', newList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveGroupItem = async (item_id: string) => {
    try {
      const found_item = inputFields.selected_group_items?.find((i: any) => i?.item?.id === item_id);
      const removed_value = await PRICE_IN_MAAT(found_item?.amount * found_item?.item?.price?.value, found_item?.item?.price?.currency?.currency_code);

      setValue('total_orders_to_pay_in_mat', inputFields.total_orders_to_pay_in_mat - removed_value);

      const newList = inputFields.selected_group_items?.filter((i: any) => i?.item?.id !== item_id);
      setValue('selected_group_items', newList);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaySportSession = async (isVerified: boolean) => {
    try {
      if (isVerified) {
        setProgress(1);

        if (inputFields.spending_password?.length > 0 && loggedAccountPersonalWallets?.length > 0) {
          // CREATE PAYMENT_PLAN if not exist
          // CREATE TRANSACTION
          inputFields.consumers_to_pay?.map(async (sport_session_id: string) => {
            const current_sport_session = sportSessions?.find((s: any) => s?.id === sport_session_id);
            const found_complex_payment_method = ALL_COMPLEX_PAYMENT_METHODS?.find((pm: any) => pm?.wallets?.find((w: any) => ALL_COMPLEXES?.find((c: any) => c?.owners?.find((o: any) => o?.__typename === 'Company' && o?.id === w?.owner?.id))));
            const sender_payment_method = ALL_PAYMENT_METHODS?.find((pm: any) => pm?.id === inputFields.payment_method_id);

            if (!newTransaction.called && sender_payment_method && found_complex_payment_method) {
              try {
                const result = await createTransactionBySportSessionID({
                  variables: {
                    transaction: {
                      bill_id: sport_session_id,
                      team_players_ids: ALL_TEAM_PLAYERS?.map((t: any) => t?.id),
                      tx_reference: `Tx-${tx_nanoid()}-${Date.now()}`,
                      note_reference: inputFields.consumers_to_pay
                        ?.filter((i: string) => i)
                        ?.map((consumer_id: string) => `${consumer_id},`)
                        ?.toString(),
                      type: 'PAYMENT',
                      lipay_payment_option: sender_payment_method?.name === 'LIPAY' ? sender_payment_method?.payment_source?.name : undefined,
                      deposit_token: sender_payment_method?.name === 'LIPAY' ? deposit_token_nanoid() : undefined,
                      original_value: {
                        value: parseFloat((inputFields.total_sessions_to_pay_in_mat + inputFields.total_orders_to_pay_in_mat)?.toString()),
                        currency: 'MAT',
                      },
                      maat_value: {
                        value: parseFloat((inputFields.total_sessions_to_pay_in_mat + inputFields.total_orders_to_pay_in_mat)?.toString()),
                        currency: 'MAT',
                      },
                      tx_fee: {
                        value: 0,
                        currency: 'MAT',
                      },
                      assets: [],
                      sender_token: inputFields.spending_password,
                      sender_wallet_id: sender_payment_method?.wallets?.find((w: any) => w?.is_default === true)?.id,
                      sender_payment_method_id: sender_payment_method?.id,
                      receiver_wallet_id: found_complex_payment_method?.wallets[0]?.id,
                      receiver_payment_method_id: found_complex_payment_method?.id,
                      signatories: [ALL_ORGANISATORS?.find((p: any) => p?.id === loggedPlayer?.id)?.id],
                      sender_payment_source_id: sender_payment_method?.payment_source?.id,
                      receiver_payment_source_id: found_complex_payment_method?.payment_source?.id,
                      execution_date: Date.now(),
                      first_payment_date: Date.now(),
                      last_payment_date: sportSessions?.find((s: any) => s?.id === sport_session_id)?.end_date,
                      tournament_id: tournament?.id,
                    },
                  },
                });

                if (result.data?.createTransactionBySportSessionID[0]?.id) {
                  if (!updatedGroupOrder && ALL_ORGANISATORS?.filter((p: any) => !current_sport_session?.group_order?.notes?.includes(p?.id)) && result.data?.createTransactionBySportSessionID[0]?.id) {
                    try {
                      await addPaymentToGroupSnackOrder({
                        variables: {
                          order: {
                            id: current_sport_session?.group_order?.id,
                            player_id: loggedPlayer?.id,
                          },
                        },
                      });
                    } catch (e) {
                      console.log(e);
                    }
                  }

                  if (inputFields.selected_group_items?.length > 0) {
                    const short_id = nanoid();

                    if (!current_sport_session?.group_order && result.data?.createTransactionBySportSessionID[0]?.id) {
                      await createGroupOrderBySportSession({
                        variables: {
                          order: {
                            sport_session_id: current_sport_session?.id,
                            short_id: short_id,
                            title: `[ORDER] ${short_id}`,
                            reference: `order-${short_id}-${Date.now()}`,
                            notes: `${loggedPlayer?.id},`,
                            supplier_id: current_sport_session?.challenge?.complex?.owners[0]?.id,
                            customer_id: loggedPlayer?.id,
                            articles_bundles:
                              inputFields.selected_group_items?.filter((i: any) => i?.item?.__typename === 'Article')?.length === 0
                                ? []
                                : inputFields.selected_group_items
                                    ?.filter((i: any) => i?.item?.__typename === 'Article')
                                    ?.map((item: any) => {
                                      return {
                                        item_id: item?.item?.id,
                                        item_type: item?.item?.__typename,
                                        item_amount: parseFloat(item?.amount?.toString()),
                                        item_cost: {
                                          value: parseFloat(item?.item?.price?.value.toString()),
                                          currency: item?.item?.price?.currency?.currency_code,
                                        },
                                        item_product_id: item?.item?.product?.id,
                                        item_destination: {
                                          title: current_sport_session?.challenge?.complex?.location?.title,
                                          street_name: current_sport_session?.challenge?.complex?.location?.street_name,
                                          secondary_street_name: current_sport_session?.challenge?.complex?.location?.secondary_street_name,
                                          country_code: current_sport_session?.challenge?.complex?.location?.country_code,
                                          postcode: current_sport_session?.challenge?.complex?.location?.postcode?.name,
                                          city: current_sport_session?.challenge?.complex?.location?.city?.name,
                                          county: current_sport_session?.challenge?.complex?.location?.county?.name,
                                          state: current_sport_session?.challenge?.complex?.location?.state?.name,
                                          country: current_sport_session?.challenge?.complex?.location?.country?.name,
                                          continent: current_sport_session?.challenge?.complex?.location?.continent?.name,
                                        },
                                        origin_location: {
                                          id: item?.item?.in_stock[0]?.warehouse?.location?.id,
                                          title: item?.item?.in_stock[0]?.warehouse?.location?.title,
                                          street_name: item?.item?.in_stock[0]?.warehouse?.location?.street_name,
                                          secondary_street_name: item?.item?.in_stock[0]?.warehouse?.location?.secondary_street_name,
                                          country_code: item?.item?.in_stock[0]?.warehouse?.location?.country_code,
                                          postcode: item?.item?.in_stock[0]?.warehouse?.location?.postcode?.name,
                                          city: item?.item?.in_stock[0]?.warehouse?.location?.city?.name,
                                          county: item?.item?.in_stock[0]?.warehouse?.location?.county?.name,
                                          state: item?.item?.in_stock[0]?.warehouse?.location?.state?.name,
                                          country: item?.item?.in_stock[0]?.warehouse?.location?.country?.name,
                                          continent: item?.item?.in_stock[0]?.warehouse?.location?.continent?.name,
                                        },
                                      };
                                    }),
                            services_bundles:
                              inputFields.selected_group_items?.filter((i: any) => i?.item?.__typename === 'Service')?.length === 0
                                ? []
                                : inputFields.selected_group_items
                                    ?.filter((i: any) => i?.item?.__typename === 'Service')
                                    ?.map((item: any) => {
                                      return {
                                        item_id: item?.item?.id,
                                        item_type: item?.item?.__typename,
                                        item_amount: parseFloat(item?.amount.toString()),
                                        item_cost: {
                                          value: parseFloat(item?.item?.price?.value.toString()),
                                          currency: item?.item?.price?.currency?.currency_code,
                                        },
                                        item_start_date: current_sport_session?.start_date,
                                        item_end_date: current_sport_session?.end_date,
                                        item_destination: {
                                          title: current_sport_session?.challenge?.complex?.location?.title,
                                          street_name: current_sport_session?.challenge?.complex?.location?.street_name,
                                          secondary_street_name: current_sport_session?.challenge?.complex?.location?.secondary_street_name,
                                          country_code: current_sport_session?.challenge?.complex?.location?.country_code,
                                          postcode: current_sport_session?.challenge?.complex?.location?.postcode?.name,
                                          city: current_sport_session?.challenge?.complex?.location?.city?.name,
                                          county: current_sport_session?.challenge?.complex?.location?.county?.name,
                                          state: current_sport_session?.challenge?.complex?.location?.state?.name,
                                          country: current_sport_session?.challenge?.complex?.location?.country?.name,
                                          continent: current_sport_session?.challenge?.complex?.location?.continent?.name,
                                        },
                                        origin_location: {
                                          id: item?.item?.in_stock[0]?.warehouse?.location?.id,
                                          title: item?.item?.in_stock[0]?.warehouse?.location?.title || '',
                                          street_name: item?.item?.in_stock[0]?.warehouse?.location?.street_name || '',
                                          secondary_street_name: item?.item?.in_stock[0]?.warehouse?.location?.secondary_street_name || '',
                                          country_code: item?.item?.in_stock[0]?.warehouse?.location?.country_code || '',
                                          postcode: item?.item?.in_stock[0]?.warehouse?.location?.postcode?.name || '',
                                          city: item?.item?.in_stock[0]?.warehouse?.location?.city?.name || '',
                                          county: item?.item?.in_stock[0]?.warehouse?.location?.county?.name || '',
                                          state: item?.item?.in_stock[0]?.warehouse?.location?.state?.name || '',
                                          country: item?.item?.in_stock[0]?.warehouse?.location?.country?.name || '',
                                          continent: item?.item?.in_stock[0]?.warehouse?.location?.continent?.name || '',
                                        },
                                      };
                                    }),
                            total_cost: {
                              value: parseFloat(_.sum(inputFields.selected_group_items?.map(async (i: any) => (await PRICE_IN_MAAT(i?.item?.price?.value, i?.item?.price?.currency?.currency_code)) * i?.amount))?.toString()) || 0,
                              currency: 'MAT',
                            },
                          },
                        },
                      });
                    }

                    // if (sportSession?.group_order?.id) {
                    //   // await addToGroupOrderBySportSession({})
                    // }
                  }

                  // EXECUTE TRANSACTION
                  try {
                    result.data?.createTransactionBySportSessionID?.map(async (tx_id: any) => {
                      await executeDirectFiatPaymentTransaction({
                        variables: {
                          transaction: {
                            logged_account_id: loggedPlayer?.id,
                            spending_password: inputFields.spending_password,
                            tx_data: {
                              id: tx_id,
                              sender_payment_method_id: sender_payment_method?.id,
                              receiver_payment_method_id: ALL_COMPLEX_PAYMENT_METHODS[0]?.id,
                            },
                          },
                        },
                      });
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }
          });

          handleClose();
        }
      } else {
        console.log('Failed...');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setActiveStep(0);
      setProgress(0);
      setOpenConfirmation(false);
    }
  };

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  React.useEffect(() => {
    (async () => {
      if (props.open && sportSessions === undefined && loadedSportSessions?.data?.findSportSessionsByID) {
        setSportSessions(loadedSportSessions?.data?.findSportSessionsByID?.filter((s: any) => s?.cost?.value > 0)?.filter((s: any) => s?.status === 'PENDING' || s?.status === 'PAYMENT_OVERDUE'));

        (async () => {
          await Promise.all(
            loadedSportSessions?.data?.findSportSessionsByID
              ?.filter((s: any) => s?.cost?.value > 0)
              ?.filter((s: any) => s?.status === 'PENDING' || s?.status === 'PAYMENT_OVERDUE')
              ?.map(async (s: any) => await PRICE_IN_MAAT(s?.cost?.value, s?.cost?.currency?.currency_code))
          )
            .then((results) => {
              setValue('total_cost_sessions', _.sum(results));
            })
            .catch((error) => {
              // Handle errors if any of the promises reject
              console.error(error);
            });
        })();
      }

      if (!props.open && sportSessions !== undefined) {
        setSportSessions(undefined);
      }
    })();
  }, [props, loadedSportSessions, sportSessions, setSportSessions, inputFields]);

  React.useEffect(() => {
    if (!_.isMatch(tournament, loadedTournament.data?.findTournamentByID) || (tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByID)) {
      setTournament(loadedTournament.data?.findTournamentByID);
    }
  }, [tournament, loadedTournament]);

  const steps = [
    {
      label: 'Payment Method',
      content: (
        <>
          <Controller
            name="payment_method_id"
            control={control}
            rules={{
              required: false,
              pattern: /^[0-9a-zA-Z]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                fullWidth
                className={'field-bottom-space'}
                label="Payment method"
                placeholder="Payment method"
                variant="outlined"
                sx={
                  ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_method?.name === 'LIPAY' && ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_source?.__typename?.includes('Blockchain')
                    ? {
                        mr: {
                          xs: '0',
                          sm: '3%',
                          md: '3%',
                        },
                        width: {
                          xs: '100%',
                          sm: '64%',
                          md: '64%',
                        },
                      }
                    : {}
                }
              >
                {ALL_PAYMENT_METHODS?.filter((pm: any) => loggedAccountPersonalWallets?.find((w: any) => w?.id === pm?.wallets[0]?.id))?.map((option: any) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    <Grid
                      container
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      {PAYMENT_METHODS?.find((pm) => pm?.name === option?.name)?.icon_white}
                      &nbsp;&nbsp;
                      <Typography sx={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                        {_.capitalize(option?.payment_source?.name || option?.payment_source?.title || option?.payment_source?.network).replaceAll('_', ' ')}&nbsp;
                        {option?.payment_source?.network ? CARD_NETWORKS?.find((n: any) => n?.name === option?.payment_source?.network)?.icon : ''}
                        {option?.payment_source?.name ? ALTERNATIVE_PAYMENTS?.find((n: any) => n?.name === option?.payment_source?.name)?.icon : ''}
                      </Typography>
                      &nbsp;&nbsp;
                      <Typography variant="caption">from {option?.wallets[0]?.title}</Typography>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          {ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_method?.name === 'LIPAY' && ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_source?.__typename?.includes('Blockchain') ? (
            <Controller
              name="payment_option"
              control={control}
              rules={{
                required: false,
                pattern: /^[0-9a-zA-Z]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  value={field.value}
                  select
                  fullWidth
                  className={'field-bottom-space'}
                  label="Lipay payment option"
                  placeholder="Payment option"
                  variant="outlined"
                  sx={{
                    ml: {
                      xs: '0',
                      sm: '3%',
                      md: '3%',
                    },
                    width: {
                      xs: '100%',
                      sm: '30%',
                      md: '30%',
                    },
                  }}
                >
                  {LIPAY_PAYMENT_OPTIONS.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      label: 'Confirmation',
      content: (
        <>
          <Controller
            name="spending_password"
            control={control}
            rules={{
              required: loggedAccountPersonalWallets.length === 0 ? true : false,
              minLength: min_code_length,
              maxLength: 255,
            }}
            render={({ field }: any) => (
              <Grid sx={{ m: '30px 0' }}>
                <Typography sx={{ textAlign: 'left', m: '10px 0' }}>Spending Password (min {min_code_length} characters)</Typography>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}></Grid>
                <TextField
                  {...field}
                  label="Please enter your spending code"
                  type={!hidePassword ? 'password' : 'text'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                          {!hidePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          />
        </>
      ),
    },
  ];

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { 'minWidth': '70%', 'maxWidth': '80%', 'p': '20px 0px', 'bgcolor': 'rgba(15, 15, 15, 1)', '& p, & span': { color: 'rgba(247, 251, 250, 1)' } },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        disabled={exectuedTransaction.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || newTransaction.loading}
        className={exectuedTransaction.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || newTransaction.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', pb: '0', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>
          <FormattedMessage id="PaySportSessionModal_Pay" />
          &nbsp;
          <FormattedMessage id="PaySportSessionModal_Title" />
        </Typography>
        <Typography variant="caption">
          total: MAT {inputFields.total_cost_sessions} ({sportSessions?.length} {sportSessions?.length > 1 ? 'sessions' : 'session'})
        </Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span" sx={{ p: '0 0 20px' }}>
          <Typography sx={{ fontWeight: '700', fontSize: { xs: '2em', sm: '2.5em', md: '3em' }, textAlign: 'center', background: '-webkit-linear-gradient(280deg, rgba(19, 96, 13, 1) 0%, rgba(168, 202, 65, 1) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>MAT {inputFields.total_sessions_to_pay_in_mat + inputFields.total_orders_to_pay_in_mat}</Typography>
        </DialogContentText>
        <Grid sx={{ bgcolor: 'rgb(33,35,41)', p: '0px 5%', mt: '0px' }}>
          {activeStep === 0 ? (
            <>
              <Grid container sx={{ justifyContent: 'end' }}>
                <Grid item xs={11.5} sm={11} md={10} lg={10} sx={{ bgcolor: 'rgba(15,15,15,1)', p: '20px 0 20px 10%', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                  <Grid container>
                    <Accordion expanded={isExpanded} sx={{ 'width': '100%', 'boxShadow': 'none', '&.MuiPaper-root': { bgcolor: 'transparent !important' } }}>
                      <AccordionSummary onClick={() => setIsExpanded(!isExpanded)} expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header" sx={{ pl: '0' }}>
                        <strong>
                          Selected {inputFields.consumers_to_pay?.length} {inputFields.consumers_to_pay?.length > 1 ? 'sessions' : 'session'}
                        </strong>
                      </AccordionSummary>
                      <Grid container gap={2} sx={{ justifyContent: 'center', pb: '10px' }}>
                        {inputFields.consumers_to_pay?.length === 0 ? (
                          ''
                        ) : (
                          <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleUnselectAllSessions} sx={{ boxShadow: 'none' }}>
                            Unselect all
                          </Fab>
                        )}
                        <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleSelectAllSessions} sx={{ boxShadow: 'none' }}>
                          Select all
                        </Fab>
                      </Grid>
                      <AccordionDetails sx={{ overflow: 'auto', maxHeight: '300px' }}>
                        {sportSessions?.map((p: any) => (
                          <Card key={p?.id} sx={{ bgcolor: 'transparent', boxShadow: 'none', mb: '10px' }}>
                            <CardHeader
                              title={p?.challenge?.title}
                              titleTypographyProps={{ fontWeight: '700', fontSize: '1.1em' }}
                              subheader={
                                <>
                                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CalendarMonth />
                                    &nbsp;<Moment format="D MMMM YYYY HH:mm">{p?.start_date}</Moment>
                                  </Typography>

                                  <Typography sx={{ display: 'flex', alignItems: 'center', mb: '5px' }}>
                                    <LocationIcon />
                                    &nbsp;{p?.challenge?.arena?.name} @ {p?.challenge?.complex?.name}
                                  </Typography>

                                  {sportSessions?.find((c: any) => c?.player?.id === p?.id) ? (
                                    <>
                                      <FormattedMessage id="PaySportSessionModal_Paid_By" />
                                    </>
                                  ) : (
                                    <Button className={inputFields.consumers_to_pay?.find((id: string) => id === p?.id) ? 'button-green-reverse' : 'button-disabled-reverse'} onClick={inputFields.consumers_to_pay?.find((id: string) => id === p?.id) ? () => handleRemoveConsumer(p) : () => handlePayForThem(p)} sx={{ fontSize: '.8em', pt: '2px', pb: '2px' }}>
                                      Pay this session
                                    </Button>
                                  )}
                                </>
                              }
                            />
                          </Card>
                        ))}
                      </AccordionDetails>
                    </Accordion>

                    <Grid container className="group-items">
                      {inputFields.selected_group_items?.length > 0 ? (
                        <Typography sx={{ color: 'rgba(168, 202, 65, 1) !important', mt: '15px', textTransform: 'uppercase', fontSize: '.8em' }}>
                          <FormattedMessage id="PaySportSessionModal_Group_Snacks" />
                          {/* &nbsp;({sportSession?.cost?.currency?.currency_code} {_.sum(inputFields.selected_group_items?.map((i: any) => i?.item?.price?.value)) / ALL_ORGANISATORS?.length} <FormattedMessage id="PaySportSessionModal_Per_Player" />) */}
                        </Typography>
                      ) : (
                        ''
                      )}
                      {inputFields.selected_group_items?.map((item: any, index: number) => (
                        <Grid key={index} sx={{ width: '100%' }}>
                          <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={8} sm={8} md={8} lg={8} sx={{ textAlign: 'left' }}>
                              <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton className="button-red-reversed" onClick={() => handleRemoveGroupItem(item?.item?.id)}>
                                  <Delete />
                                </IconButton>
                                <Avatar
                                  sx={{
                                    height: {
                                      xs: '30px !important',
                                      md: '40px !important',
                                      lg: '50px !important',
                                    },
                                    width: {
                                      xs: '30px !important',
                                      md: '40px !important',
                                      lg: '50px !important',
                                    },
                                    m: '5px 0',
                                  }}
                                  alt={item?.item?.name}
                                  src={item?.item?.pictures?.length > 0 ? item?.item?.pictures[0]?.path : undefined}
                                />
                                &nbsp;
                                <Typography sx={{ lineHeight: '15px' }}>
                                  {item?.item?.name}
                                  <br />
                                  <Typography variant="caption">
                                    ({item?.amount} x {item?.item?.price?.currency?.currency_code} {item?.item?.price?.value})
                                  </Typography>
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'right', pr: '5%' }}></Grid>
                          </Grid>
                          {index === inputFields.selected_group_items?.length - 1 || (inputFields.selected_group_items?.length === 1 && index === 0) ? '' : <Divider sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '5px 0' }} />}
                        </Grid>
                      ))}
                    </Grid>

                    <Grid container className="group-order">
                      {/* {sportSession?.group_order ? (
                            <Typography sx={{ fontWeight: '700', mt: '15px', textTransform: 'uppercase', fontSize: '.6em' }}>
                              {ALL_ORGANISATORS?.filter((p: any) => !sportSession?.group_order?.notes?.includes(p?.player?.player?.id))?.length}&nbsp;
                              {ALL_ORGANISATORS?.filter((p: any) => !sportSession?.group_order?.notes?.includes(p?.player?.player?.id))?.length === ALL_ORGANISATORS?.length ? <FormattedMessage id="PaySportSessionModal_Fully_Paid_Group_Snacks" /> : <FormattedMessage id="PaySportSessionModal_Unpaid_Group_Snacks" />}
                              &nbsp;({sportSession?.group_order?.total_cost?.currency?.currency_code} {sportSession?.group_order?.total_cost?.value / ALL_ORGANISATORS?.length}&nbsp;
                              <FormattedMessage id="PaySportSessionModal_Per_Player" />)
                            </Typography>
                          ) : (
                            ''
                          )} */}

                      {/* {sportSession?.group_order
                            ? ALL_ORGANISATORS?.filter((p: any) => p?.player?.player?.id === loggedPlayer?.id)?.map((team_player: any, index: number) => (
                                <Grid key={index} sx={{ width: '100%' }}>
                                  <Grid container sx={{ alignItems: 'center' }}>
                                    <Grid item xs={8} sm={8} md={8} lg={8} sx={{ textAlign: 'left' }}>
                                      <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar
                                          sx={{
                                            height: {
                                              xs: '30px !important',
                                              md: '40px !important',
                                              lg: '50px !important',
                                            },
                                            width: {
                                              xs: '30px !important',
                                              md: '40px !important',
                                              lg: '50px !important',
                                            },
                                            m: '5px 0',
                                          }}
                                          alt={team_player?.player?.alias}
                                          src={team_player?.player?.avatar?.path}
                                        />
                                        &nbsp;
                                        <Typography sx={{ lineHeight: '15px' }}>{team_player?.player?.alias}</Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'right', pr: '5%' }}>
                                      {ALL_ORGANISATORS?.filter(() => sportSession?.group_order?.notes?.includes(loggedPlayer?.id)) ? (
                                        <Typography variant="caption" className="SUCCESSFUL" sx={{ textTransform: 'uppercase', fontWeight: '700', lineHeight: '15px', display: 'block' }}>
                                          <FormattedMessage id="PaySportSessionModal_Paid" />
                                        </Typography>
                                      ) : (
                                        <Typography variant="caption" className="WARNING" sx={{ textTransform: 'uppercase', fontWeight: '700', lineHeight: '15px', display: 'block' }}>
                                          <FormattedMessage id="PaySportSessionModal_Unpaid" />
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))
                            : ''} */}
                    </Grid>
                  </Grid>
                  <Divider sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '10px 0' }} />

                  <Grid container>
                    <Grid item xs={3} sm={3} md={3} lg={3} sx={{ textAlign: 'left' }}>
                      <Typography>
                        <FormattedMessage id="PaySportSessionModal_Total" />
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9} sx={{ textAlign: 'right', pr: '5%' }}>
                      <Typography sx={{ fontWeight: '700', fontSize: { xs: '1.2em', sm: '1.5em', md: '1.8em' }, background: '-webkit-linear-gradient(280deg, rgba(19, 96, 13, 1) 0%, rgba(168, 202, 65, 1) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>MAT {inputFields.total_sessions_to_pay_in_mat + inputFields.total_orders_to_pay_in_mat}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Typography sx={{ fontWeight: '700', fontSize: '1.2em', p: '30px 10% 15px' }}>
                <FormattedMessage id="PaySportSessionModal_Snacks" />
              </Typography>
              <Grid container sx={{ p: '0 10%' }}>
                {isSmallMobile ? (
                  <Grid container sx={{ m: '0 0 10px', justifyContent: 'center' }}>
                    {!inputFields.selected_item ? (
                      <Typography
                        variant="caption"
                        sx={{
                          m: '0px',
                          p: '5px 20px',
                          textAlign: 'center',
                          bgcolor: 'rgba(0, 0, 0, .1)',
                          borderRadius: '5px',
                          width: '100%',
                          display: 'inline-block',
                        }}
                      >
                        No snack selected
                      </Typography>
                    ) : (
                      <Chip
                        variant="outlined"
                        label={<strong>{inputFields.selected_item?.name}</strong>}
                        avatar={
                          <Avatar
                            sx={{
                              height: {
                                xs: '50px !important',
                                md: '60px !important',
                                lg: '70px !important',
                              },
                              width: {
                                xs: '50px !important',
                                md: '60px !important',
                                lg: '70px !important',
                              },
                              m: '5px 0',
                            }}
                            alt={inputFields.selected_item?.name}
                            src={inputFields.selected_item?.pictures?.length > 0 ? inputFields.selected_item?.pictures[0]?.path : undefined}
                          />
                        }
                        sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                      />
                    )}
                  </Grid>
                ) : (
                  ''
                )}
                <Controller
                  name="all_items"
                  control={control}
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      open={openSearchSportPlayer}
                      onOpen={() => setOpenSearchSportPlayer(true)}
                      onClose={() => setOpenSearchSportPlayer(false)}
                      multiple
                      autoHighlight
                      fullWidth
                      filterSelectedOptions
                      value={field.value}
                      loading={loadedItems.loading}
                      inputValue={inputFields.item_name}
                      onInputChange={(event: any, newInputValue: any) => {
                        setValue('item_name', newInputValue);
                      }}
                      options={(ALL_ITEMS ? ALL_ITEMS : [])?.filter((cf: any) => inputFields.selected_group_items?.find((p: any) => p?.id === cf?.id) === undefined)}
                      getOptionLabel={(option: any) => option?.id}
                      defaultValue={ARRAY_AS_ANY}
                      renderOption={(props: any, option: any) => (
                        <Grid
                          container
                          key={option?.id}
                          onClick={() => handleSelectItem(option)}
                          sx={{
                            alignItems: 'center',
                            p: '10px 15px',
                          }}
                        >
                          <Avatar src={option?.pictures?.length > 0 ? option?.pictures[0]?.path : undefined} sx={{ display: { xs: 'contents', sm: 'block' } }} />
                          <Grid sx={{ lineHeight: '15px', pl: '10px', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                            <strong>{option?.name}</strong>
                            <Typography variant="caption" sx={{ fontSize: '.8em', width: '100%', display: 'inline-block' }}>
                              {option?.amount ? `Available: ${option?.amount}` : undefined || option?.status ? `Disponibility: ${option?.status}` : undefined}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={`Snacks`}
                          placeholder={`Write a product name`}
                          className={'field-bottom-space'}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <>
                                {isSmallMobile ? (
                                  ''
                                ) : (
                                  <Grid>
                                    {!inputFields.selected_item ? (
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          m: '0px',
                                          p: '5px 20px',
                                          textAlign: 'center',
                                          bgcolor: 'rgba(0, 0, 0, .1)',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        No snack selected
                                      </Typography>
                                    ) : (
                                      <Chip
                                        variant="outlined"
                                        label={<strong>{inputFields.selected_item?.name}</strong>}
                                        avatar={
                                          <Avatar
                                            sx={{
                                              height: {
                                                xs: '50px !important',
                                                md: '60px !important',
                                                lg: '70px !important',
                                              },
                                              width: {
                                                xs: '50px !important',
                                                md: '60px !important',
                                                lg: '70px !important',
                                              },
                                              m: '5px 0',
                                            }}
                                            alt={inputFields.selected_item?.name}
                                            src={inputFields.selected_item?.pictures?.length > 0 ? inputFields.selected_item?.pictures[0]?.path : undefined}
                                          />
                                        }
                                        sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                                      />
                                    )}
                                  </Grid>
                                )}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="item_amount"
                  control={control}
                  rules={{
                    required: true,
                    min: 1,
                    max: inputFields.selected_item?.amount,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={`Amount of ${inputFields.selected_item?.name ? inputFields.selected_item?.name : 'snack'}`}
                      placeholder={'0'}
                      type="number"
                      variant="outlined"
                      value={field.value <= 0 ? 0 : field.value && field.value > inputFields.selected_item?.amount ? inputFields.selected_item?.amount : field.value}
                      required
                      fullWidth
                      className={'field-bottom-space'}
                      error={errors?.item_amount !== undefined}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ color: 'rgba(247, 251, 250, 1)' }}>
                            {inputFields.selected_item ? (
                              <>
                                {inputFields.item_amount * inputFields.selected_item?.price?.value}
                                &nbsp;
                                {inputFields.selected_item?.price?.currency?.currency_code}
                              </>
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                {ALL_AVAILABLE_COMPLEXES?.length === 0 ? (
                  ''
                ) : (
                  <Fab variant="extended" size="small" className={!inputFields.selected_item || inputFields.item_amount <= 0 ? 'button-disabled' : 'button-green-reverse'} disabled={!inputFields.selected_item || inputFields.item_amount <= 0} sx={{ m: '10px' }} onClick={handleAddGroupItem}>
                    <TeamsIcon />
                    &nbsp;Add to bill
                  </Fab>
                )}
              </Grid>
              <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', width: '80%', m: '10px auto 30px' }} />
            </>
          ) : (
            ''
          )}
          <Typography sx={{ fontWeight: '700', fontSize: '1.2em', p: '30px 10% 15px' }}>
            <FormattedMessage id="PaySportSessionModal_Payment_Method" />
          </Typography>

          <Grid container className={'dark-stepper'} sx={{ p: '0 5%' }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical" sx={{ width: '100%' }}>
              {steps?.map((step: any, index: number) => (
                <Step key={step?.label}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                    sx={{
                      display: activeStep >= index ? 'inline-block' : 'none',
                    }}
                  >
                    {step?.label}
                  </StepButton>
                  <StepContent>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: activeStep >= index ? 'inline-block' : 'none',
                        width: '100%',
                      }}
                    >
                      {step?.content}
                    </Grid>
                    <Box sx={{ pt: '0px' }}>
                      <Stack direction={'row'}>
                        {activeStep === 0 || activeStep === steps.length - 1 ? (
                          ''
                        ) : (
                          <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                            <FormattedMessage id="PaySportSessionModal_Stepper_Back_Button" />
                          </Button>
                        )}
                        {activeStep === steps.length - 1 ? (
                          ''
                        ) : (
                          <Button onClick={handleNext} className={inputFields.spending_password.length < 10 && (loggedAccountPersonalWallets.length === 0 || inputFields.payment_method_id === '' || inputFields.consumers_to_pay?.length === 0) ? 'button-disabled' : 'button-green-reverse'} disabled={inputFields.spending_password.length < 10 && (loggedAccountPersonalWallets.length === 0 || inputFields.payment_method_id === '' || inputFields.consumers_to_pay?.length === 0)}>
                            {activeStep === steps.length - 1 ? <FormattedMessage id="PaySportSessionModal_Stepper_Finish_Button" /> : <FormattedMessage id="PaySportSessionModal_Stepper_Next_Button" />}
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </DialogContent>
      {activeStep === 0 ? (
        ''
      ) : (
        <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
          <Button className={exectuedTransaction.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || newTransaction.loading ? 'button-disabled' : 'button-cancel'} disabled={exectuedTransaction.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || newTransaction.loading} onClick={handleBack}>
            <FormattedMessage id="PaySportSessionModal_Back_Button" />
          </Button>

          <Button onClick={() => setOpenConfirmation(true)} disabled={loggedAccountPersonalWallets?.length === 0 || inputFields.consumers_to_pay?.length === 0 || inputFields.spending_password === '' || inputFields.spending_password?.length < min_code_length} className={loggedAccountPersonalWallets?.length === 0 || inputFields.consumers_to_pay?.length === 0 || inputFields.spending_password === '' || inputFields.spending_password?.length < min_code_length ? 'button-disabled' : 'button-green'}>
            <FormattedMessage id="PaySportSessionModal_Proceed_Pay_Button" />
          </Button>

          <SpendingCodeModal icon={undefined} actionOne={handlePaySportSession} actionTwo={() => setOpenConfirmation(false)} open={openConfirmation} />
        </DialogActions>
      )}
    </Dialog>
  );
}

PaySportSessionsModal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  secondaryContent: PropTypes.any,
  actionOneTitle: PropTypes.any,
  actionTwoTitle: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.any,
};

PaySportSessionsModal.defaultProps = {
  secondaryContent: '',
  loading: false,
};
