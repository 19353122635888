import React from 'react';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { Fab, AppBar, Tabs, Tab, Dialog, DialogTitle, DialogActions, Typography, Button, Grid, Badge } from '@mui/material';
import { Close, Fingerprint } from '@mui/icons-material';
import { ScanQRCodeIcon, WalletIcon } from '../../img/icons/Icons';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { storePersonalWallets, selectPersonalWallets } from '../../reducers/walletSlice';
import ConfirmPaymentModal from './ConfirmPaymentModal';
import { Link } from 'react-router-dom';
import ShowQRCode from '../features/ShowQRCode';
import ScanQRCode from '../features/ScanQRCode';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

export default function MakePaymentModal(props: { tx_id?: string; index?: number; open: any; handleClose: any }) {
  const FIND_ACCOUNT_PERSONAL_WALLETS_QUERY = gql`
    query findAccountPersonalWallets($account: inputAccountWallets!) {
      findAccountPersonalWallets(account: $account) {
        id
        title
        status
        is_default
        type
        owner {
          ... on Company {
            __typename
            id
            name
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
        }
        bank_accounts {
          id
        }
        blockchain_accounts {
          id
        }
        cards {
          ... on BankCard {
            __typename
            id
          }
          ... on BlockchainCard {
            __typename
            id
          }
        }
        payment_methods {
          id
          name
          status
          payment_source {
            ... on BankCard {
              __typename
              id
              network
            }
            ... on BlockchainCard {
              __typename
              id
              network
            }
            ... on BlockchainAccount {
              __typename
              id
              title
            }
            ... on BankAccount {
              __typename
              id
              title
            }
          }
        }
        sent_transactions {
          id
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedAccountPersonalWallets = useAppSelector(selectPersonalWallets);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [index, setIndex] = React.useState(props.index || 0);
  const [openSignTx, setOpenSignTx] = React.useState(false);
  const [viewQRCode] = React.useState(false);
  const ALL_WALLETS = _.concat(loggedAccountPersonalWallets);
  const ALL_PAYMENT_METHODS = ALL_WALLETS?.map((w: any) => w?.payment_methods)?.flat();
  const ALL_TRANSACTIONS = ALL_WALLETS?.map((w: any) => w?.sent_transactions?.filter((tx: any) => tx?.status === 'INSUFFICIENT_FUNDS' || tx?.status === 'PENDING')).flat();

  const loadedAccountPersonalWallets = useQuery(FIND_ACCOUNT_PERSONAL_WALLETS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      account: { id: loggedPlayer?.id, username: loggedPlayer?.username },
    },
    pollInterval: session.status !== 'ALIVE' || !loggedPlayer?.id ? undefined : 300000,
  });

  const handleClose = () => {
    setIndex(props.index || 0);
    props.handleClose();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setIndex(index);
  };

  React.useEffect(() => {
    if ((loadedAccountPersonalWallets?.called && loadedAccountPersonalWallets?.data?.findAccountPersonalWallets) || !_.isMatch(loggedAccountPersonalWallets, loadedAccountPersonalWallets?.data?.findAccountPersonalWallets)) {
      try {
        dispatch(storePersonalWallets(loadedAccountPersonalWallets?.data?.findAccountPersonalWallets));
      } catch (e) {
        console.log(e);
      }
    }
  }, [loadedAccountPersonalWallets, dispatch, loggedAccountPersonalWallets]);

  return (
    <Dialog open={props.open} onClose={handleClose} className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'} modal-feature`} PaperProps={{ sx: { minWidth: { xs: '100%', md: '40%' }, maxWidth: { xs: '100%', md: '50%' } } }}>
      <Fab size="small" onClick={handleClose} className="button-cancel" sx={{ boxShadow: 'none', ml: '2%', mt: '2%' }}>
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', p: '0', textTransform: 'uppercase' }}>
        <AppBar position="static" sx={{ 'bgcolor': 'transparent', 'boxShadow': 'none', '& .MuiTab-root': { fontSize: 'x-small' } }}>
          <Tabs value={index} onChange={handleChange} variant="fullWidth" scrollButtons={false}>
            <Tab label="" icon={<ScanQRCodeIcon />} {...a11yProps(0)} />
            <Tab
              label=""
              icon={
                <Badge badgeContent={ALL_TRANSACTIONS?.length} color="error">
                  <WalletIcon />
                </Badge>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
      </DialogTitle>
      <Grid container sx={{ pt: '30px', justifyContent: 'center' }}>
        <SwipeableViews axis={'x'} index={index} onChangeIndex={handleChangeIndex} style={{ width: '100%' }}>
          <TabPanel value={index} index={0} dir={'ltr'}>
            <Grid container sx={{ justifyContent: 'center' }}>
              {/* <Fab onClick={() => setViewQRCode(!viewQRCode)} className="button-green-reverse" size="small" sx={{ 'border': 'none !important', 'boxShadow': 'none', '& svg': { color: 'rgba(168, 202, 65, 1) !important' } }}>
                {viewQRCode ? <ScanQRCodeIcon /> : <QRCodeIcon />}
              </Fab> */}
            </Grid>
            {viewQRCode ? (
              <Grid container>
                <ShowQRCode />
              </Grid>
            ) : (
              <Grid container>
                <ScanQRCode />
              </Grid>
            )}
          </TabPanel>

          <TabPanel value={index} index={1} dir={'ltr'}>
            {ALL_WALLETS?.length === 0 || ALL_PAYMENT_METHODS?.length === 0 ? (
              <>
                {ALL_WALLETS?.length === 0 ? (
                  <Grid
                    container
                    sx={{
                      justifyContent: 'center',
                      p: '10% 10%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '1.4em',
                        pb: '30px',
                      }}
                    >
                      {`${intl.formatMessage({ id: 'MakePayment_Need_Wallet_1' })}`}&nbsp;{ALL_PAYMENT_METHODS?.length === 0 ? `${intl.formatMessage({ id: 'MakePayment_Need_Wallet_2' })}` : ''}&nbsp;{`${intl.formatMessage({ id: 'MakePayment_Need_Wallet_3' })}`}
                    </Typography>
                    <Fab
                      variant="extended"
                      size="small"
                      component={Link}
                      to={`/new_wallet`}
                      className="button-green-reverse"
                      sx={{
                        background: 'transparent',
                        boxShadow: 'none',
                        whiteSpace: 'nowrap',
                        m: '0 5px',
                      }}
                    >
                      {`${intl.formatMessage({ id: 'MakePayment_Create_Wallet' })}`}
                      &nbsp;
                      <WalletIcon />
                    </Fab>
                  </Grid>
                ) : (
                  ''
                )}

                {ALL_PAYMENT_METHODS?.length === 0 && ALL_WALLETS?.length > 0 ? (
                  <Grid
                    container
                    sx={{
                      justifyContent: 'center',
                      p: '10% 10%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '1.4em',
                        pb: '30px',
                      }}
                    >
                      {`${intl.formatMessage({ id: 'MakePayment_Need_PM' })}`}
                    </Typography>
                    <Fab
                      variant="extended"
                      size="small"
                      component={Link}
                      to={`/w/wallets`}
                      className="button-green-reverse"
                      sx={{
                        background: 'transparent',
                        boxShadow: 'none',
                        whiteSpace: 'nowrap',
                        m: '0 5px',
                      }}
                    >
                      {`${intl.formatMessage({ id: 'Global_Button_See' })}`}&nbsp;{`${intl.formatMessage({ id: 'MakePayment_Wallets' })}`}&nbsp;
                      <WalletIcon />
                    </Fab>
                  </Grid>
                ) : (
                  ''
                )}
              </>
            ) : (
              <Grid container sx={{ justifyContent: 'center' }}>
                <Fab variant="extended" size="small" className="button-green" sx={{ boxShadow: 'none', m: '0 5px 10px' }} component={Link} to={'/w/wallets'}>
                  <WalletIcon />
                  &nbsp;{`${intl.formatMessage({ id: 'Global_Button_See' })}`}&nbsp;{ALL_WALLETS?.length} {ALL_WALLETS?.length > 1 ? `${intl.formatMessage({ id: 'MakePayment_Wallets' })}` : `${intl.formatMessage({ id: 'MakePayment_Wallet' })}`}
                </Fab>

                <ConfirmPaymentModal open={openSignTx} handleClose={() => setOpenSignTx(false)} transactions={ALL_TRANSACTIONS} />
                <Fab variant="extended" size="small" className="button-green-reverse" sx={{ boxShadow: 'none', m: '0 5px 10px' }} onClick={ALL_TRANSACTIONS?.length > 0 ? () => setOpenSignTx(true) : undefined}>
                  <Fingerprint />
                  &nbsp;{ALL_TRANSACTIONS?.length} {ALL_TRANSACTIONS?.length > 1 ? `${intl.formatMessage({ id: 'MakePayment_Transactions' })}` : `${intl.formatMessage({ id: 'MakePayment_Transaction' })}`}&nbsp;{`${intl.formatMessage({ id: 'MakePayment_To_Sign' })}`}
                </Fab>
              </Grid>
            )}
          </TabPanel>
        </SwipeableViews>
      </Grid>
      <DialogActions sx={{ p: '20px 5% 30px' }}>
        <Button onClick={handleClose} className={'button-cancel'}>
          {`${intl.formatMessage({ id: 'Global_Button_Close' })}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

MakePaymentModal.propTypes = {};
