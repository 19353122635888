import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, Fab, Grid, Stack, Avatar, CircularProgress } from '@mui/material';
import { Close, CalendarMonth, Edit } from '@mui/icons-material';
import { TIMESLOTS, WEEKDAYS } from '../../utilities/utilities';
import { gql, useMutation, useQuery } from '@apollo/client';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';
import { useForm } from 'react-hook-form';
import InputSelectAvailabilitiesFeature from './InputSelectAvailabilitiesFeature';
import { LoadingButton } from '@mui/lab';
import { selectPlayer, selectPlayerActiveProfile } from '../../reducers/playerSlice';
import LoadingComponent from './LoadingComponent';
import { useIntl } from 'react-intl';

export default function ViewAvailabilitiesModal(props: { title?: string; icon?: any; owners_ids: string[]; owner_type?: string; hide_button_text?: boolean }) {
  const FIND_AVAILABILITIES_BY_OWNER_ID_QUERY = gql`
    query findAvailabilitiesByOwnersIDs($owners_ids: [ID!]!) {
      findAvailabilitiesByOwnersIDs(owners_ids: $owners_ids) {
        id
        day
        allday
        timeslots
        team_availabilities {
          ... on FootballTeam {
            __typename
            id
            name
            avatar {
              path
            }
            captains {
              id
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                }
              }
            }
          }
        }
        player_availabilities {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
        }
      }
    }
  `;

  const EDIT_AVAILABILITIES_BY_OWNER_ID_MUTATION = gql`
    mutation editAvailabilitiesByOwnerID($owner: inputEditAvailabilitiesByOwnerID!) {
      editAvailabilitiesByOwnerID(owner: $owner)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);

  const intl = useIntl();
  const [openViewAvailabilities, setOpenViewAvailabilities] = React.useState(false);
  const [openEditAvailabilities, setOpenEditAvailabilities] = React.useState(false);
  const { watch, setValue } = useForm({
    defaultValues: {
      selected_owner: UNDEFINED_AS_ANY,
      day: 'MONDAY',
      hour: 6,
      availabilities: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [editAvailabilitiesByOwnerID, updatedAvailabilities] = useMutation(EDIT_AVAILABILITIES_BY_OWNER_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedAvailabilities = useQuery(FIND_AVAILABILITIES_BY_OWNER_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.owners_ids || props.owners_ids?.length === 0,
    variables: {
      owners_ids: props?.owners_ids,
    },
  });

  const ALL_AVAILABILITIES = loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs;
  let SELECTED_OWNER = ALL_AVAILABILITIES?.find((a: any) => a?.player_availabilities?.id === inputFields.selected_owner || a?.team_availabilities?.id === inputFields.selected_owner)?.team_availabilities || ALL_AVAILABILITIES?.find((a: any) => a?.player_availabilities?.id === inputFields.selected_owner || a?.team_availabilities?.id === inputFields.selected_owner)?.player_availabilities;

  const handleRefetch = async () => {
    try {
      const result = await loadedAvailabilities.refetch({
        owners_ids: props?.owners_ids,
      });

      if (result) {
        SELECTED_OWNER = result.data?.findAvailabilitiesByOwnersIDs?.find((a: any) => a?.player_availabilities?.id === inputFields.selected_owner || a?.team_availabilities?.id === inputFields.selected_owner)?.team_availabilities || result.data?.findAvailabilitiesByOwnersIDs?.find((a: any) => a?.player_availabilities?.id === inputFields.selected_owner || a?.team_availabilities?.id === inputFields.selected_owner)?.player_availabilities;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      if (loggedPlayerActiveProfile?.id === inputFields.selected_owner || loggedPlayerActiveProfile?.sport_teams?.find((t: any) => t?.id === inputFields.selected_owner) || loggedPlayer?.manage_teams?.find((t: any) => t?.id === inputFields.selected_owner)) {
        await handleEditAvailabilities();
      }

      setOpenViewAvailabilities(false);
      setOpenEditAvailabilities(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelectOwner = (owner_id: string) => {
    setValue('selected_owner', owner_id);

    const newList = ALL_AVAILABILITIES.find((a: any) => a.player_availabilities?.id === owner_id || a.team_availabilities?.id === owner_id);
    if (newList) {
      const newList = ALL_AVAILABILITIES?.map((a: any) => {
        return a?.timeslots?.map((t: number) => {
          return {
            day: a.day,
            hour: t,
          };
        });
      }).flat();
      setValue('availabilities', newList);
    }
  };

  const handleEditAvailabilities = async () => {
    try {
      await editAvailabilitiesByOwnerID({
        variables: {
          owner: {
            id: SELECTED_OWNER?.id ?? props.owners_ids[0],
            type: SELECTED_OWNER?.__typename ?? props.owner_type,
            availabilities: _.uniq(inputFields.availabilities?.map((a: any) => a?.day))?.map((day: any) => {
              return {
                day: day,
                allday: false,
                timeslots: inputFields.availabilities?.filter((a: any) => a?.day === day)?.map((a: any) => a?.hour),
              };
            }),
          },
        },
      });

      setOpenEditAvailabilities(false);
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (openViewAvailabilities && !inputFields.selected_owner && loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs && loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs?.length > 0) {
      setValue('selected_owner', loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs[0]?.team_availabilities?.id || loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs[0]?.player_availabilities?.id);
      const owner_id = loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs[0]?.team_availabilities?.id || loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs[0]?.player_availabilities?.id;
      const newList = loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs?.find((a: any) => a.player_availabilities?.id === owner_id || a.team_availabilities?.id === owner_id);
      if (newList) {
        const newList = ALL_AVAILABILITIES?.map((a: any) => {
          return a?.timeslots?.map((t: number) => {
            return {
              day: a.day,
              hour: t,
            };
          });
        }).flat();
        setValue('availabilities', newList);
      }
    }

    if (!openViewAvailabilities && (inputFields.availabilities?.length > 0 || inputFields.selected_owner)) {
      setValue('availabilities', UNDEFINED_AS_ANY);
      setValue('selected_owner', UNDEFINED_AS_ANY);
    }
  }, [inputFields, setValue, loadedAvailabilities, openViewAvailabilities]);

  return (
    <>
      <LoadingButton size="small" className={updatedAvailabilities.loading ? 'button-disabled' : ''} sx={{ boxShadow: 'none', color: 'inherit !important' }} onClick={() => setOpenViewAvailabilities(true)}>
        <CalendarMonth />
        {props.hide_button_text ? '' : <>&nbsp;{`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_3' })}`}</>}
      </LoadingButton>
      <Dialog
        open={openViewAvailabilities}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={'component-modal modal-feature'}
        PaperProps={{
          sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
        }}
      >
        <Grid container>
          <Grid item xs={6} md={6} lg={6}>
            <Fab
              size="small"
              onClick={handleClose}
              className="button-cancel"
              sx={{
                boxShadow: 'none',
                bgcolor: 'transparent',
              }}
            >
              <Close />
            </Fab>
          </Grid>
          <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
            {loggedPlayer?.manage_teams?.find((t: any) => t?.id === inputFields.selected_owner) || loggedPlayer?.profiles?.find((p: any) => p?.id === SELECTED_OWNER?.id || props.owners_ids?.find((id: string) => id === p?.id) || SELECTED_OWNER?.captains?.find((c: any) => c?.player?.id === p?.id)) ? (
              <>
                {openEditAvailabilities ? (
                  <Fab
                    size="small"
                    onClick={handleEditAvailabilities}
                    className="button-green-reverse"
                    variant="extended"
                    sx={{
                      boxShadow: 'none',
                      bgcolor: 'transparent',
                      border: 'none !important',
                      height: 'auto',
                      fontSize: '.6em',
                    }}
                  >
                    <CalendarMonth />
                    &nbsp;{`${intl.formatMessage({ id: 'Global_Button_View' })}`}
                  </Fab>
                ) : (
                  <Fab
                    size="small"
                    onClick={() => setOpenEditAvailabilities(true)}
                    className="button-green-reverse"
                    variant="extended"
                    sx={{
                      boxShadow: 'none',
                      bgcolor: 'transparent',
                      border: 'none !important',
                      height: 'auto',
                      fontSize: '.6em',
                    }}
                  >
                    <Edit />
                    &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Update' })}`}
                  </Fab>
                )}
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>

        {updatedAvailabilities.loading ? (
          <LoadingComponent amount={0} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
        ) : (
          <>
            {openEditAvailabilities ? (
              <>
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
                  <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>
                    {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_5' })}`} {SELECTED_OWNER?.alias} {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_6' })}`}
                  </Typography>
                  {!SELECTED_OWNER ? (
                    ''
                  ) : (
                    <Grid container sx={{ p: '0', overflow: 'auto', width: '100%', justifyContent: 'center' }}>
                      <Avatar src={SELECTED_OWNER?.avatar?.path} alt={SELECTED_OWNER?.id} sx={{ m: '0 10px', width: '60px', height: '60px', border: 'solid 3px rgba(168, 202, 65, 0)' }} />
                    </Grid>
                  )}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" component="span">
                    <Typography id="max-width" variant="h3" sx={{ fontSize: '1em', fontWeight: '700', p: '0px 0 20px', color: 'rgba(247, 251, 250, 1)' }}>
                      {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_1' })}`}
                    </Typography>

                    <InputSelectAvailabilitiesFeature setter={setValue} input={inputFields.availabilities} />
                  </DialogContentText>
                </DialogContent>
              </>
            ) : (
              <>
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
                  <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>
                    {props?.icon || props?.title || SELECTED_OWNER?.alias} {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_3' })}`}
                  </Typography>
                  {!props?.owners_ids || props?.owners_ids?.length === 0 ? (
                    ''
                  ) : (
                    <Grid className={'locations-container'} sx={{ p: '0', overflow: 'auto', width: '100%', textAlign: 'center' }}>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        {_.uniq(loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs?.map((a: any) => a?.player_availabilities || a?.team_availabilities))?.map((a: any, index: number) => (
                          <Avatar key={index} src={a?.avatar?.path} alt={a?.id} sx={{ 'm': '0 10px', 'width': '60px', 'height': '60px', 'opacity': a?.id === inputFields.selected_owner ? '1' : '.3', '&:hover': { border: 'solid 3px rgba(168, 202, 65, 1)' } }} onClick={() => handleSelectOwner(a?.id)} />
                        ))}
                      </Stack>
                    </Grid>
                  )}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" component="span">
                    <Grid className={'timeslots'} sx={{ p: '0', textAlign: 'center' }}>
                      {!props?.owners_ids || props?.owners_ids?.length === 0 || loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs?.filter((a: any) => a?.player_availabilities?.id === inputFields.selected_owner || a?.team_availabilities?.id === inputFields.selected_owner)?.length === 0 ? (
                        <Typography sx={{ p: '20px', textAlign: 'center', textTransform: 'uppercase', color: 'rgba(247, 251, 250, 0.3)' }}>{`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_4' })}`}</Typography>
                      ) : (
                        <Stack direction="row" sx={{ display: 'inline-flex', minWidth: '300px' }}>
                          {_.sortBy(_.uniq(loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs?.filter((a: any) => a?.player_availabilities?.id === inputFields.selected_owner || a?.team_availabilities?.id === inputFields.selected_owner)?.map((a: any) => a?.day)), (a: any) => _.indexOf(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'], a))?.map((day: any, index: number) => (
                            <Stack direction="column" key={index} sx={{ width: '120px', m: '0 10px 0', display: 'inline-block' }}>
                              <Typography sx={{ fontWeight: '700', color: 'rgba(247, 251, 250, 1)' }}>{`${intl.formatMessage({ id: WEEKDAYS.find((d: any) => d.value === day)?.label })}`}</Typography>
                              <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '0 0 10px' }} />
                              {loadedAvailabilities.data?.findAvailabilitiesByOwnersIDs
                                ?.filter((a: any) => a?.player_availabilities?.id === inputFields.selected_owner || a?.team_availabilities?.id === inputFields.selected_owner)
                                ?.filter((a: any) => a?.day === day)
                                ?.map((a: any, index: number) => (
                                  <Grid key={index} sx={{ overflowX: 'auto', maxHeight: '50vh' }}>
                                    {_.sortBy(a?.timeslots, (t: any) => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5]?.indexOf(t))?.map((hour: number, index: number) => (
                                      <Grid key={index}>
                                        <Typography sx={{ color: 'rgba(247, 251, 250, 1)' }}>{TIMESLOTS?.find((t: any) => t?.value === hour)?.label}</Typography>
                                        <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, .08)', m: '0 auto 5px', width: '50%' }} />
                                      </Grid>
                                    ))}
                                  </Grid>
                                ))}
                            </Stack>
                          ))}
                        </Stack>
                      )}
                    </Grid>
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

ViewAvailabilitiesModal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  secondaryContent: PropTypes.any,
  actionOneTitle: PropTypes.any,
  actionTwoTitle: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.any,
};

ViewAvailabilitiesModal.defaultProps = {
  secondaryContent: '',
  loading: false,
  hide_button_text: false,
};
