import React from 'react';
import { AppBar, Avatar, Card, CardContent, CardMedia, Chip, CircularProgress, Fab, Grid, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import SwipeableViews from 'react-swipeable-views';
import { ChallengeIcon, FiltersIcon, FivezerLogoIcon, FivezerWhiteIcon, LocationIcon, SingleMemberIcon, VersusIcon } from '../../img/icons/Icons';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { ARRAY_AS_ANY, DATE_AS_ANY, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import MenuButton from '../../component-modals/layout/MenuButton';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import _ from 'lodash';
import ViewTeamLineupModal from '../../component-modals/team/ViewTeamLineupModal';
import { useForm } from 'react-hook-form';
import { CHALLENGE_TYPES, COUNTRIES, TO_TIMESTAMP } from '../../utilities/utilities';
import ChallengeFiltersModal from '../../component-modals/challenge/ChallengeFiltersModal';
import TopBarActionsButton from '../../component-modals/layout/TopBarActionsButton';
import { AppContext } from '../../App';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

interface IChallenges {
  index?: number;
}

const Challenges: React.FC = () => {
  const FIND_CHALLENGES_BY_OPTIONS_QUERY = gql`
    query findChallengesByOptions($challenge: inputFindChallengesByOptions!) {
      findChallengesByOptions(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        start_date
        end_date
        is_private
        home_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        away_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        complex {
          id
          name
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        arena {
          id
          name
          type
          size
          pictures {
            path
          }
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        booking {
          id
          short_id
          status
          title
          start_date
          end_date
          cost {
            value
            currency {
              currency_code
            }
          }
          consumers {
            id
            payment_status
            transaction {
              id
              status
            }
            player {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
        results {
          id
        }
      }
    }
  `;

  const FIND_OPPONENTS_BY_OPTIONS_QUERY = gql`
    query findOpponentsByOptions($challenge: inputFindOpponentsByOptions!) {
      findOpponentsByOptions(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        start_date
        end_date
        is_private
        home_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        away_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        complex {
          id
          name
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        arena {
          id
          name
          type
          size
          pictures {
            path
          }
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        booking {
          id
          short_id
          status
          title
          start_date
          end_date
          cost {
            value
            currency {
              currency_code
            }
          }
          consumers {
            id
            payment_status
            transaction {
              id
              status
            }
            player {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
        results {
          id
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const location: any = useLocation();
  const state: IChallenges = location?.state;
  const appContext = React.useContext(AppContext);
  const intl = useIntl();

  const [challenges, setChallenges] = React.useState(UNDEFINED_AS_ANY);
  const [opponents, setOpponents] = React.useState(UNDEFINED_AS_ANY);
  const [value, setValueIndex] = React.useState(state?.index ? state?.index : 0);
  const [offsetChallenges] = React.useState(0);
  const [offsetOpponents] = React.useState(0);
  const [openTeamLineup, setOpenTeamLineup] = React.useState(false);
  const [openChallengeFilters, setOpenChallengeFilters] = React.useState(false);
  const [openOpponentFilters, setOpenOpponentFilters] = React.useState(false);

  const { watch, setValue } = useForm({
    defaultValues: {
      selected_team_lineup: '',
      selected_challenge_id: '',

      challenge_city: UNDEFINED_AS_ANY,
      challenge_country: COUNTRIES?.find((c: any) => c?.value === appContext?.currentPosition?.country?.code) || INITIAL_COUNTRY,
      challenge_types: ARRAY_AS_ANY,
      challenge_sport_types: ARRAY_AS_ANY,
      challenge_start_date: DATE_AS_ANY,
      challenge_end_date: DATE_AS_ANY,
      challenge_complex: UNDEFINED_AS_ANY,
      challenge_field: '',

      opponent_city: UNDEFINED_AS_ANY,
      opponent_country: COUNTRIES?.find((c: any) => c?.value === appContext?.currentPosition?.country?.code) || INITIAL_COUNTRY,
      opponent_types: ARRAY_AS_ANY,
      opponent_sport_types: ARRAY_AS_ANY,
      opponent_start_date: DATE_AS_ANY,
      opponent_end_date: DATE_AS_ANY,
      opponent_complex: UNDEFINED_AS_ANY,
      opponent_field: '',
    },
  });
  const inputFields = watch();

  const loadedUpcomingChallenges = useQuery(FIND_CHALLENGES_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        offset: offsetChallenges,
        types: inputFields.challenge_types,
        sport_types: inputFields.challenge_sport_types,
        city: inputFields.challenge_city?.name ? _.deburr(inputFields.challenge_city?.name) : undefined,
        country: inputFields.challenge_country?.label,
        continent: inputFields.challenge_country?.continent,
        start_date: inputFields.challenge_start_date ? TO_TIMESTAMP(inputFields.challenge_start_date?.toString()) : undefined,
        end_date: inputFields.challenge_end_date ? TO_TIMESTAMP(inputFields.challenge_end_date?.toString()) : undefined,
        complex_id: inputFields?.challenge_complex ? inputFields.challenge_complex?.id : undefined,
        field_id: inputFields?.challenge_field ? inputFields.challenge_field : undefined,
      },
    },
  });

  const loadedOpponents = useQuery(FIND_OPPONENTS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        offset: offsetOpponents,
        types: inputFields.opponent_types,
        sport_types: inputFields.opponent_sport_types,
        city: inputFields.opponent_city?.name ? _.deburr(inputFields.opponent_city?.name) : undefined,
        country: inputFields.opponent_country?.label,
        continent: inputFields.opponent_country?.continent,
        start_date: inputFields.opponent_start_date ? TO_TIMESTAMP(inputFields.opponent_start_date?.toString()) : undefined,
        end_date: inputFields.opponent_end_date ? TO_TIMESTAMP(inputFields.opponent_end_date?.toString()) : undefined,
        complex_id: inputFields?.opponent_complex ? inputFields.opponent_complex?.id : undefined,
        field_id: inputFields?.opponent_field ? inputFields.opponent_field : undefined,
      },
    },
  });

  const handleCloseTeamLineup = () => {
    setValue('selected_team_lineup', '');
    setValue('selected_challenge_id', '');
    setOpenTeamLineup(false);
  };

  const handleOpenTeamLineup = (team_lineup_id: string, challenge_id?: string) => {
    setValue('selected_team_lineup', team_lineup_id);
    if (challenge_id) {
      setValue('selected_challenge_id', challenge_id);
    }
    setOpenTeamLineup(true);
  };

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValueIndex(newValue);
    handleChangeIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValueIndex(index);
  };

  const handleRefetchChallenges = async () => {
    try {
      const result = await loadedUpcomingChallenges.refetch({
        challenge: {
          offset: offsetChallenges,
          types: inputFields.challenge_types,
          sport_types: inputFields.challenge_sport_types,
          city: inputFields.challenge_city?.name ? _.deburr(inputFields.challenge_city?.name) : undefined,
          country: inputFields.challenge_country?.label,
          continent: inputFields.challenge_country?.continent,
          start_date: inputFields.challenge_start_date ? TO_TIMESTAMP(inputFields.challenge_start_date?.toString()) : undefined,
          end_date: inputFields.challenge_end_date ? TO_TIMESTAMP(inputFields.challenge_end_date?.toString()) : undefined,
          complex_id: inputFields?.challenge_complex ? inputFields.challenge_complex?.id : undefined,
          field_id: inputFields?.challenge_field ? inputFields.challenge_field : undefined,
        },
      });

      if (result) {
        const newList = result.data?.findChallengesByOptions;
        setChallenges(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefetchOpponents = async () => {
    try {
      const result = await loadedOpponents.refetch({
        challenge: {
          offset: offsetOpponents,
          types: inputFields.opponent_types,
          sport_types: inputFields.opponent_sport_types,
          city: inputFields.opponent_city?.name ? _.deburr(inputFields.opponent_city?.name) : undefined,
          country: inputFields.opponent_country?.label,
          continent: inputFields.opponent_country?.continent,
          start_date: inputFields.opponent_start_date ? TO_TIMESTAMP(inputFields.opponent_start_date?.toString()) : undefined,
          end_date: inputFields.opponent_end_date ? TO_TIMESTAMP(inputFields.opponent_end_date?.toString()) : undefined,
          complex_id: inputFields?.opponent_complex ? inputFields.opponent_complex?.id : undefined,
          field_id: inputFields?.opponent_field ? inputFields.opponent_field : undefined,
        },
      });

      if (result) {
        const newList = result.data?.findOpponentsByOptions;
        setOpponents(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenChallengeFilters = () => {
    setOpenChallengeFilters(true);
  };

  const handleCloseChallengeFilters = async () => {
    setOpenChallengeFilters(false);
    await handleRefetchChallenges();
  };

  const handleOpenOpponentFilters = () => {
    setOpenOpponentFilters(true);
  };

  const handleCloseOpponentFilters = async () => {
    setOpenOpponentFilters(false);
    await handleRefetchOpponents();
  };

  const handleRemoveChallengeFilter = async (element: any) => {
    if (inputFields.challenge_types?.find((e: any) => e === element)) {
      const newList = inputFields.challenge_types?.filter((e: any) => e !== element);
      setValue('challenge_types', newList);
      await handleRefetchChallenges();
    }
  };

  const handleRemoveOpponentFilter = async (element: any) => {
    if (inputFields.opponent_types?.find((e: any) => e === element)) {
      const newList = inputFields.opponent_types?.filter((e: any) => e !== element);
      setValue('opponent_types', newList);
      await handleRefetchOpponents();
    }
  };

  const handleLoadMoreChallenges = async () => {
    try {
      const current_challenges = challenges;

      const result = await loadedUpcomingChallenges.refetch({
        challenge: {
          offset: offsetChallenges + challenges?.length,
          types: inputFields.challenge_types,
          sport_types: inputFields.challenge_sport_types,
          city: inputFields.challenge_city?.name ? _.deburr(inputFields.challenge_city?.name) : undefined,
          country: inputFields.challenge_country?.label,
          continent: inputFields.challenge_country?.continent,
          start_date: inputFields.challenge_start_date ? TO_TIMESTAMP(inputFields.challenge_start_date?.toString()) : undefined,
          end_date: inputFields.challenge_end_date ? TO_TIMESTAMP(inputFields.challenge_end_date?.toString()) : undefined,
          complex_id: inputFields?.challenge_complex ? inputFields.challenge_complex?.id : undefined,
          field_id: inputFields?.challenge_field ? inputFields.challenge_field : undefined,
        },
      });

      if (result) {
        const newList = _.concat(current_challenges, result.data?.findChallengesByOptions);
        setChallenges(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoadMoreOpponents = async () => {
    try {
      const current_opponents = opponents;

      const result = await loadedOpponents.refetch({
        challenge: {
          offset: offsetOpponents + opponents?.length,
          types: inputFields.opponent_types,
          sport_types: inputFields.opponent_sport_types,
          city: inputFields.opponent_city?.name ? _.deburr(inputFields.opponent_city?.name) : undefined,
          country: inputFields.opponent_country?.label,
          continent: inputFields.opponent_country?.continent,
          start_date: inputFields.opponent_start_date ? TO_TIMESTAMP(inputFields.opponent_start_date?.toString()) : undefined,
          end_date: inputFields.opponent_end_date ? TO_TIMESTAMP(inputFields.opponent_end_date?.toString()) : undefined,
          complex_id: inputFields?.opponent_complex ? inputFields.opponent_complex?.id : undefined,
          field_id: inputFields?.opponent_field ? inputFields.opponent_field : undefined,
        },
      });

      if (result) {
        const newList = _.concat(current_opponents, result.data?.findOpponentsByOptions);
        setOpponents(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!challenges && loadedUpcomingChallenges.called && loadedUpcomingChallenges.data?.findChallengesByOptions) {
      setChallenges(loadedUpcomingChallenges.data?.findChallengesByOptions);
    }

    if (!opponents && loadedOpponents.called && loadedOpponents.data?.findOpponentsByOptions) {
      setOpponents(loadedOpponents.data?.findOpponentsByOptions);
    }
  }, [opponents, loadedOpponents, setOpponents, challenges, loadedUpcomingChallenges, setChallenges]);

  return (
    <Grid className={`component-team team-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ p: '0 0 150px', minHeight: '100%' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'Challenges_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid container>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}></Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ position: 'relative', zIndex: '10000', display: 'inline-flex', alignItems: 'center', justifyContent: 'right', p: '20px 43px' }}>
          <TopBarActionsButton />
          <MenuButton />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`}>
        <AppBar position="sticky" sx={{ mt: '0px' }}>
          <Tabs value={value} onChange={handleChange} scrollButtons="auto" aria-label="full width tabs example" variant={'fullWidth'} centered>
            <Tab
              label={`${intl.formatMessage({ id: 'Challenges_Tab_Challenges' })}`}
              icon={<ChallengeIcon />}
              {...a11yProps(0)}
              style={{
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            />
            <Tab
              label={`${intl.formatMessage({ id: 'Challenges_Tab_Opponents' })}`}
              icon={<VersusIcon />}
              {...a11yProps(1)}
              style={{
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={'ltr'}>
            {(loadedUpcomingChallenges.error && loadedUpcomingChallenges.called) || loadedUpcomingChallenges.loading ? (
              <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
            ) : (
              <Grid container className={'list-challenges'} sx={{ p: { xs: '20px 5% 150px', md: '20px 5% 150px', alignItems: 'center' } }}>
                <IconButton onClick={handleOpenChallengeFilters}>
                  <FiltersIcon />
                </IconButton>
                &nbsp;&nbsp;
                <Typography sx={{ 'display': 'inline-flex', 'alignItems': 'baseline', 'fontSize': { xs: '1.1em', sm: '2em' }, 'color': 'rgba(247, 251, 250, 1) !important', 'fontWeight': '800', '& img': { width: 'auto', height: { xs: '20px', sm: '20px', md: '20px', xl: '20px' } } }}>
                  {inputFields.challenge_country?.label}
                  {inputFields.challenge_city?.name ? <>,&nbsp;{inputFields.challenge_city?.name}</> : ''}
                  &nbsp;
                  <img loading="lazy" width="20" src={`https://flagcdn.com/w160/${inputFields.challenge_country?.value?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w320/${inputFields.challenge_country?.value?.toLowerCase()}.png 2x`} alt="" />
                </Typography>
                <Grid container sx={{ p: '0 0 40px', justifyContent: 'start' }}>
                  <Stack direction="row" sx={{ ml: '20px' }}>
                    {_.concat(inputFields.challenge_types)?.map((e: any, index: number) => (
                      <Chip key={index} label={e} onDelete={() => handleRemoveChallengeFilter(e)} sx={{ 'm': '20px 10px 0 0', 'bgcolor': 'rgba(247, 251, 250, 0.04)', 'color': 'rgba(247, 251, 250, 1)', 'fontWeight': '800', '& .MuiChip-deleteIcon': { 'color': 'rgba(238, 44, 32, .4)', '&:hover': { color: 'rgba(238, 44, 32, 1)' } } }} />
                    ))}
                  </Stack>
                  <ChallengeFiltersModal complex={inputFields.challenge_complex} field={inputFields.challenge_field} start_date={inputFields.challenge_start_date} end_date={inputFields.challenge_end_date} country={inputFields.challenge_country} city={inputFields.challenge_city} types={inputFields.challenge_types} setter={setValue} setter_prefix="challenge_" open={openChallengeFilters} handleClose={handleCloseChallengeFilters} />
                </Grid>
                {challenges !== undefined && challenges?.length > 0 ? (
                  <>
                    {challenges?.map((challenge: any) => (
                      <Grid key={challenge?.id} item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ p: { xs: '0 10px', sm: '0 30px' }, display: 'inline-flex', alignItems: 'center' }}>
                        <Grid sx={{ position: 'relative', right: '20px', width: '0' }}>
                          {challenge?.type === 'MATCHMAKER' && challenge?.home_team?.players?.length < 5 ? (
                            <Fab size="medium" className="button-green" sx={{ fontWeight: '700' }} onClick={() => handleOpenTeamLineup(challenge?.home_team?.id, challenge?.id)}>
                              {5 - challenge?.home_team?.players?.length}&nbsp;
                              <SingleMemberIcon />
                            </Fab>
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Card
                          className={'card'}
                          sx={{
                            bgcolor: 'rgba(247, 251, 250, .1)',
                            p: '0px',
                            width: '100%',
                            borderRadius: '20px',
                            m: '0 auto 30px',
                          }}
                        >
                          <CardMedia
                            sx={{
                              backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.3) 0%,rgba(0,0,0,.9) 50%), url(${challenge?.arena?.pictures ? challenge?.arena?.pictures[_.random(0, challenge?.arena?.pictures?.length - 1)]?.path : undefined || challenge?.complex?.pictures ? challenge?.complex?.pictures[_.random(0, challenge?.complex?.pictures?.length - 1)]?.path : undefined})`,
                            }}
                          >
                            <CardContent className={'content'} sx={{ textAlign: 'center', p: '0' }}>
                              <Grid container sx={{ bgcolor: 'rgba(24, 30, 36, 1)', justifyContent: 'center', p: '5px 0' }}>
                                <Grid item xs={5} sm={5} md={5} lg={5} sx={{ color: 'rgba(247, 251, 250, 1)', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: { xs: '.8em', md: '.9em' } }}>
                                  <Moment format="D MMM YYYY">{challenge?.start_date}</Moment>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} sx={{ 'borderRadius': '20px', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'p': { xs: '5px 10px', md: '5px 20px' }, 'm': '4px 0', 'bgcolor': 'rgba(247, 251, 250, 0.3)', '& svg': { color: 'rgba(247, 251, 250, 1)', fontSize: { xs: '.9em', sm: '1.5em' } } }}>
                                  {CHALLENGE_TYPES?.find((c: any) => c?.value === challenge?.type)?.icon}
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5} sx={{ color: 'rgba(247, 251, 250, 1)', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: { xs: '.8em', md: '.9em' } }}>
                                  <Moment format="HH:mm">{challenge?.start_date}</Moment>
                                  &nbsp;&rarr;&nbsp;
                                  <Moment format="HH:mm">{challenge?.end_date}</Moment>
                                </Grid>
                              </Grid>

                              <Grid container component={Link} to={`/challenge/${challenge?.short_id}`} sx={{ p: '40px 0 0', textAlign: 'center', textDecoration: 'none' }}>
                                <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5}>
                                  <Typography sx={{ lineHeight: '18px', fontWeight: '700', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.home_team?.name}</Typography>
                                  {challenge?.home_team?.team?.avatar?.path ? <Avatar src={challenge?.home_team?.team?.avatar?.path} alt={challenge?.home_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.4', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} />}
                                </Grid>
                                <Grid className="separator" item xl={1} xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1)', fontSize: { xs: '40px', sm: '50px' } }} />
                                </Grid>
                                <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5}>
                                  <Typography sx={{ lineHeight: '18px', fontWeight: '700', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.away_team?.name}</Typography>
                                  {challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.4', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} />}
                                </Grid>
                              </Grid>

                              <Typography
                                variant="caption"
                                sx={{
                                  color: 'rgba(247, 251, 250, 1)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <LocationIcon
                                  sx={{
                                    color: 'rgba(31, 175, 146, .5)',
                                  }}
                                />
                                &nbsp;
                                <Grid>
                                  {challenge?.complex?.name ? (
                                    <strong>
                                      {challenge?.complex?.name}
                                      <br />
                                    </strong>
                                  ) : (
                                    ''
                                  )}

                                  <Grid
                                    sx={{
                                      color: 'rgba(247, 251, 250, 1)',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {challenge?.complex?.location?.city?.name || challenge?.arena?.location?.city?.name}, {challenge?.complex?.location?.country?.name || challenge?.arena?.location?.country?.name}
                                    &nbsp;
                                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${challenge?.complex?.location?.country_code?.toLowerCase() || challenge?.arena?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${challenge?.complex?.location?.country_code?.toLowerCase() || challenge?.arena?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                  </Grid>
                                </Grid>
                              </Typography>
                            </CardContent>
                          </CardMedia>
                        </Card>
                        <Grid sx={{ position: 'relative', left: '-20px', width: '0' }}>
                          {challenge?.type === 'MATCHMAKER' && challenge?.away_team?.players?.length < 5 ? (
                            <Fab size="medium" className="button-green" sx={{ fontWeight: '700' }} onClick={() => handleOpenTeamLineup(challenge?.away_team?.id, challenge?.id)}>
                              {5 - challenge?.away_team?.players?.length}&nbsp;
                              <SingleMemberIcon />
                            </Fab>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    <Grid container sx={{ justifyContent: 'center' }}>
                      {loadedUpcomingChallenges.data?.findChallengesByOptions?.length === 0 ? (
                        ''
                      ) : (
                        <Fab className="button-green-reverse" size="small" variant="extended" onClick={handleLoadMoreChallenges}>
                          {`${intl.formatMessage({ id: 'Global_Button_Load_More' })}`}
                        </Fab>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid container sx={{ justifyContent: 'center' }}>
                    {loadedUpcomingChallenges.error ? (
                      <Typography sx={{ minHeight: '40vh', fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>{`${intl.formatMessage({ id: 'Challenges_No_Challenges_Found' })}`}</Typography>
                    ) : (
                      <Typography sx={{ minHeight: '40vh', fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>
                        {`${intl.formatMessage({ id: 'Challenges_No_Challenges_In' })}`} {inputFields.challenge_city?.name ? inputFields.challenge_city?.name : inputFields.challenge_country?.label} {`${intl.formatMessage({ id: 'Challenges_No_Challenges_Yet' })}`}
                      </Typography>
                    )}
                  </Grid>
                )}
                <ViewTeamLineupModal team_lineup_id={inputFields.selected_team_lineup} challenge_id={inputFields.selected_challenge_id} open={openTeamLineup} handleClose={handleCloseTeamLineup} />
              </Grid>
            )}
          </TabPanel>

          <TabPanel value={value} index={1} dir={'ltr'}>
            {(loadedOpponents.error && loadedOpponents.called) || loadedOpponents.loading ? (
              <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
            ) : (
              <Grid container className={'list-opponents'} sx={{ p: { xs: '20px 5% 150px', sm: '20px 5% 150px', alignItems: 'center' } }}>
                <IconButton onClick={handleOpenOpponentFilters}>
                  <FiltersIcon />
                </IconButton>
                &nbsp;&nbsp;
                <Typography sx={{ 'display': 'inline-flex', 'alignItems': 'baseline', 'fontSize': { xs: '1.1em', sm: '2em' }, 'color': 'rgba(247, 251, 250, 1) !important', 'fontWeight': '800', '& img': { width: 'auto', height: { xs: '20px', sm: '20px', md: '20px', xl: '20px' } } }}>
                  {inputFields.opponent_country?.label}
                  {inputFields.opponent_city?.name ? <>,&nbsp;{inputFields.opponent_city?.name}</> : ''}
                  &nbsp;
                  <img loading="lazy" width="20" src={`https://flagcdn.com/w160/${inputFields.opponent_country?.value?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w320/${inputFields.opponent_country?.value?.toLowerCase()}.png 2x`} alt="" />
                </Typography>
                <Grid container sx={{ p: '0 0 40px', justifyContent: 'start' }}>
                  <Stack direction="row" sx={{ ml: '20px' }}>
                    {_.concat(inputFields.opponent_types)?.map((e: any, index: number) => (
                      <Chip key={index} label={e} onDelete={() => handleRemoveOpponentFilter(e)} sx={{ 'm': '20px 10px 0 0', 'bgcolor': 'rgba(247, 251, 250, 0.04)', 'color': 'rgba(247, 251, 250, 1)', 'fontWeight': '800', '& .MuiChip-deleteIcon': { 'color': 'rgba(238, 44, 32, .4)', '&:hover': { color: 'rgba(238, 44, 32, 1)' } } }} />
                    ))}
                  </Stack>
                  <ChallengeFiltersModal complex={inputFields.opponent_complex} field={inputFields.opponent_field} start_date={inputFields.opponent_start_date} end_date={inputFields.opponent_end_date} country={inputFields.opponent_country} city={inputFields.opponent_city} types={inputFields.opponent_types} setter={setValue} setter_prefix="opponent_" open={openOpponentFilters} handleClose={handleCloseOpponentFilters} />
                </Grid>
                {opponents !== undefined && opponents?.length > 0 ? (
                  <>
                    {opponents?.map((challenge: any) => (
                      <Grid key={challenge?.id} item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ p: { xs: '0 10px', sm: '0 30px' }, display: 'inline-flex', alignItems: 'center' }}>
                        <Card
                          className={'card'}
                          sx={{
                            bgcolor: 'rgba(247, 251, 250, .1)',
                            p: '0px',
                            width: '100%',
                            borderRadius: '20px',
                            m: '0 auto 30px',
                          }}
                        >
                          <CardMedia
                            sx={{
                              backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.3) 0%,rgba(0,0,0,.9) 50%), url(${challenge?.arena?.pictures ? challenge?.arena?.pictures[_.random(0, challenge?.arena?.pictures?.length - 1)]?.path : undefined || challenge?.complex?.pictures ? challenge?.complex?.pictures[_.random(0, challenge?.complex?.pictures?.length - 1)]?.path : undefined})`,
                            }}
                          >
                            <CardContent className={'content'} sx={{ textAlign: 'center', p: '0' }}>
                              <Grid container sx={{ bgcolor: 'rgba(24, 30, 36, 1)', justifyContent: 'center', p: '5px 0' }}>
                                <Grid item xs={5} sm={5} md={5} lg={5} sx={{ color: 'rgba(247, 251, 250, 1)', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: { xs: '.8em', md: '.9em' } }}>
                                  <Moment format="D MMM YYYY">{challenge?.start_date}</Moment>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} sx={{ 'borderRadius': '20px', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'p': { xs: '5px 10px', md: '5px 20px' }, 'm': '4px 0', 'bgcolor': 'rgba(247, 251, 250, 0.3)', '& svg': { color: 'rgba(247, 251, 250, 1)', fontSize: { xs: '.9em', sm: '1.5em' } } }}>
                                  {CHALLENGE_TYPES?.find((c: any) => c?.value === challenge?.type)?.icon}
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5} sx={{ color: 'rgba(247, 251, 250, 1)', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: { xs: '.8em', md: '.9em' } }}>
                                  <Moment format="HH:mm">{challenge?.start_date}</Moment>
                                  &nbsp;&rarr;&nbsp;
                                  <Moment format="HH:mm">{challenge?.end_date}</Moment>
                                </Grid>
                              </Grid>

                              <Grid container component={Link} to={`/challenge/${challenge?.short_id}`} sx={{ p: '40px 0 0', textAlign: 'center', textDecoration: 'none' }}>
                                <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5}>
                                  <Typography sx={{ lineHeight: '18px', fontWeight: '700', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.home_team?.name}</Typography>
                                  {challenge?.home_team?.team?.avatar?.path ? <Avatar src={challenge?.home_team?.team?.avatar?.path} alt={challenge?.home_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.4', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} />}
                                </Grid>
                                <Grid className="separator" item xl={1} xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1)', fontSize: { xs: '40px', sm: '50px' } }} />
                                </Grid>
                                <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5}>
                                  <Typography sx={{ lineHeight: '18px', fontWeight: '700', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.away_team?.name}</Typography>
                                  {challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.4', width: { xs: '40px', sm: '50px', md: '60px' }, height: { xs: '40px', sm: '50px', md: '60px' } }} />}
                                </Grid>
                              </Grid>

                              <Typography
                                variant="caption"
                                sx={{
                                  color: 'rgba(247, 251, 250, 1)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <LocationIcon
                                  sx={{
                                    color: 'rgba(31, 175, 146, .5)',
                                  }}
                                />
                                &nbsp;
                                <Grid>
                                  {challenge?.complex?.name ? (
                                    <strong>
                                      {challenge?.complex?.name}
                                      <br />
                                    </strong>
                                  ) : (
                                    ''
                                  )}

                                  <Grid
                                    sx={{
                                      color: 'rgba(247, 251, 250, 1)',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {challenge?.complex?.location?.city?.name || challenge?.arena?.location?.city?.name}, {challenge?.complex?.location?.country?.name || challenge?.arena?.location?.country?.name}
                                    &nbsp;
                                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${challenge?.complex?.location?.country_code?.toLowerCase() || challenge?.arena?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${challenge?.complex?.location?.country_code?.toLowerCase() || challenge?.arena?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                  </Grid>
                                </Grid>
                              </Typography>
                            </CardContent>
                          </CardMedia>
                        </Card>
                      </Grid>
                    ))}
                    <Grid container sx={{ justifyContent: 'center' }}>
                      {loadedOpponents.data?.findOpponentsByOptions?.length === 0 ? (
                        ''
                      ) : (
                        <Fab className="button-green-reverse" size="small" variant="extended" onClick={handleLoadMoreOpponents}>
                          {`${intl.formatMessage({ id: 'Global_Button_Load_More' })}`}
                        </Fab>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid container sx={{ justifyContent: 'center' }}>
                    {loadedOpponents.error ? (
                      <Typography sx={{ minHeight: '40vh', fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>{`${intl.formatMessage({ id: 'Challenges_No_Challenges_Found' })}`}</Typography>
                    ) : (
                      <Typography sx={{ minHeight: '40vh', fontSize: '1.5em', color: 'rgba(247, 251, 250, 0.3) !important' }}>
                        {`${intl.formatMessage({ id: 'Challenges_No_Challenges_In' })}`} {inputFields.opponent_city?.name ? inputFields.opponent_city?.name : inputFields.opponent_country?.label} {`${intl.formatMessage({ id: 'Challenges_No_Challenges_Yet' })}`}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default Challenges;
