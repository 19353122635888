import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { Fab, Grid, Typography, Dialog, DialogTitle, DialogContent, TextField, InputAdornment, MenuItem, Button } from '@mui/material';
import { Close, Delete, Send } from '@mui/icons-material';
import { DESCRIPTION_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { BLOCKCHAIN_CURRENCIES } from '../../utilities/utilities';
import InputDescriptionsFeature from '../common/InputDescriptionsFeature';
import { LoadingButton } from '@mui/lab';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';

interface IEditSponsorshipModal {
  sponsorship_id: string;
  handleClose: any;
  open: boolean;
}

const EditSponsorshipModal: React.FC<IEditSponsorshipModal> = (props: IEditSponsorshipModal) => {
  const FIND_SPONSORSHIP_BY_ID_QUERY = gql`
    query findSponsorshipByID($id: ID!) {
      findSponsorshipByID(id: $id) {
        id
        title
        status
        descriptions {
          language
          content
        }
        price {
          value
          currency {
            currency_code
          }
        }
      }
    }
  `;

  const EDIT_SPONSORSHIP_MUTATION = gql`
    mutation editSponsorship($sponsorship: inputEditSponsorship!) {
      editSponsorship(sponsorship: $sponsorship) {
        id
      }
    }
  `;

  const DELETE_SPONSORSHIP_MUTATION = gql`
    mutation deleteSponsorship($sponsorship: inputDeleteSponsorship!) {
      deleteSponsorship(sponsorship: $sponsorship)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [sponsorship, setSponsorship] = React.useState(UNDEFINED_AS_ANY);
  const [openDelete, setOpenDelete] = React.useState(false);

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      title: UNDEFINED_AS_ANY,
      language: 'GB',
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',
      descriptions: UNDEFINED_AS_ANY,
      value: UNDEFINED_AS_ANY,
      currency: '',
    },
  });
  const inputFields = watch();
  const [deleteSponsorship, deletedSponsorship] = useMutation(DELETE_SPONSORSHIP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [editSponsorship, updatedSponsorship] = useMutation(EDIT_SPONSORSHIP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSponsorship = useQuery(FIND_SPONSORSHIP_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.open || !props.sponsorship_id || props.sponsorship_id === '',
    variables: {
      id: props.sponsorship_id,
    },
  });

  const handleClose = () => {
    setSponsorship(UNDEFINED_AS_ANY);
    reset();
    props.handleClose();
  };

  const handleEditSponsorship = async () => {
    try {
      const result = await editSponsorship({
        variables: {
          sponsorship: {
            id: sponsorship?.id,
            title: inputFields.title,
            author_id: loggedPlayer?.id,
            descriptions: inputFields.descriptions.map((d: any) => {
              return { language: d.language, content: d.content };
            }),
            price: {
              value: parseFloat(inputFields.value.toString()),
              currency: inputFields.currency,
            },
          },
        },
      });

      if (result.data?.editSponsorship) {
        handleClose();
      }
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleDeleteSponsorship = async () => {
    try {
      await deleteSponsorship({
        variables: {
          sponsorship: {
            id: sponsorship?.id,
            author_id: loggedPlayer?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && !sponsorship && loadedSponsorship.data?.findSponsorshipByID) {
      setSponsorship(loadedSponsorship.data?.findSponsorshipByID);
    }

    if (!props.open && sponsorship) {
      setSponsorship(UNDEFINED_AS_ANY);
    }
  }, [props, sponsorship, loadedSponsorship]);

  React.useEffect(() => {
    if (sponsorship) {
      if (!inputFields.title && sponsorship?.title) setValue('title', sponsorship?.title);
      if (!inputFields.descriptions && sponsorship?.descriptions && sponsorship?.descriptions?.length > 0) setValue('descriptions', sponsorship?.descriptions);
      if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
      if (inputFields.descriptions?.length > 0 && inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');
      if (!inputFields.value && sponsorship?.price?.value) setValue('value', sponsorship?.price?.value);
      if (inputFields.currency === '' && sponsorship?.price?.currency?.currency_code) setValue('currency', sponsorship?.price?.currency?.currency_code);
    }
  }, [props, inputFields, setValue, sponsorship]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: {
          p: '0',
          width: '85%',
          position: 'absolute',
          top: '5%',
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Grid container>
          <Fab
            size="small"
            onClick={handleClose}
            className="button-cancel"
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              m: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Edit sponsorship</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={'form-page'} sx={{ bgcolor: 'transparent', p: '0', minHeight: 'auto' }}>
          <form className={'form-root'}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: true,
                maxLength: 30,
                minLength: 4,
                pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
              }}
              render={({ field }: any) => <TextField {...field} label={`Title`} fullWidth value={_.trimStart(field.value)} className={'field-bottom-space'} />}
            />

            <Typography
              variant="h3"
              sx={{
                fontSize: '1.4em',
                fontWeight: '700',
                m: '40px 0 20px',
              }}
            >
              {`${intl.formatMessage({ id: 'NewSponsorshipModal_Descriptions_Title' })}`}
            </Typography>
            <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

            <Typography
              variant="h3"
              sx={{
                fontSize: '1.2em',
                fontWeight: '700',
                m: '40px 0 20px',
              }}
            >
              {`${intl.formatMessage({ id: 'NewSponsorshipModal_Price_Title' })}`}
            </Typography>
            <Controller
              name="value"
              control={control}
              rules={{
                required: true,
                min: 1,
                max: 100000000,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label={`${intl.formatMessage({ id: 'NewSponsorshipModal_Price_Value_1' })}`}
                  placeholder={'0'}
                  required
                  type="number"
                  variant="outlined"
                  value={field.value <= -1 ? 0 : field.value && field.value > 100000000 ? 100000000 : field.value}
                  sx={{
                    mr: {
                      xs: '0',
                      sm: '3%',
                      md: '3%',
                    },
                    width: {
                      xs: '100%',
                      sm: '64%',
                      md: '64%',
                    },
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{inputFields.currency}</InputAdornment>,
                  }}
                  className={'field-bottom-space'}
                />
              )}
            />

            <Controller
              name="currency"
              control={control}
              rules={{
                required: true,
                pattern: /^[0-9a-zA-Z]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  value={field.value}
                  select
                  SelectProps={{
                    MenuProps: {
                      sx: {
                        zIndex: '1000000000',
                      },
                    },
                  }}
                  required
                  label={`${intl.formatMessage({ id: 'NewSponsorshipModal_Price_Currency' })}`}
                  placeholder="Service currency"
                  variant="outlined"
                  sx={{
                    ml: {
                      xs: '0',
                      sm: '3%',
                      md: '3%',
                    },
                    width: {
                      xs: '100%',
                      sm: '30%',
                      md: '30%',
                    },
                  }}
                >
                  {_.concat(BLOCKCHAIN_CURRENCIES).map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${intl.formatMessage({ id: option.label })}`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Grid container sx={{ mt: '40px' }}>
              <Button onClick={handleOpenDelete} size="small" disabled={deletedSponsorship.loading} className={deletedSponsorship.loading ? 'button-disabled' : 'button-red-reversed'} sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                <Delete />
                &nbsp;Delete
              </Button>

              <Button onClick={handleClose} className="button-cancel">{`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}</Button>

              <LoadingButton
                onClick={handleEditSponsorship}
                loading={updatedSponsorship.loading}
                loadingPosition="end"
                endIcon={<Send />}
                disabled={inputFields.title === '' || inputFields.value <= 0 || inputFields.descriptions?.length === 0 || inputFields.descriptions?.[0]?.content?.length === 0}
                className={inputFields.title === '' || inputFields.value <= 0 || inputFields.descriptions?.length === 0 || inputFields.descriptions?.[0]?.content?.length === 0 ? 'button-disabled' : 'button-green'}
              >{`${intl.formatMessage({
                id: 'Global_Button_Save_Updates',
              })}`}</LoadingButton>
            </Grid>

            <DeleteConfirmationModal title={`Remove permanently sponsorship`} open={openDelete} entityId={sponsorship?.id} entityName={`sponsorship`} actionOne={handleDeleteSponsorship} actionOneTitle={'Confirm removal'} actionTwo={handleClose} actionTwoTitle={'Cancel'} />
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

EditSponsorshipModal.propTypes = {
  sponsorship_id: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

EditSponsorshipModal.defaultProps = {
  sponsorship_id: '',
};

export default EditSponsorshipModal;
