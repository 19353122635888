import React from 'react';
import {
  LipayWhiteIcon,
  LipayCashIcon,
  LipayGoldIcon,
  LipayPlasticIcon,
  LipayTyreIcon,
  LipayOrganicIcon,
  VisaCardIcon,
  AmexCardIcon,
  MasterCardIcon,
  PacCardIcon,
  LipayIcon,
  FootBallIcon,
  GymRoomIcon,
  RentEquipmentsIcon,
  SellEquipmentsIcon,
  SnackbarIcon,
  ChangingRoomIcon,
  SteamRoomIcon,
  SaunaIcon,
  PrivateLockerIcon,
  ScoreBoardIcon,
  ShowerIcon,
  SecurityCameraIcon,
  GameStreamingIcon,
  SnapchatWhiteIcon,
  LinkedInIcon,
  FacebookWhiteIcon,
  TikTokIcon,
  TikTokWhiteIcon,
  TwitterIcon,
  TwitterWhiteIcon,
  InstagramIcon,
  InstagramWhiteIcon,
  FacebookIcon,
  LinkedInWhiteIcon,
  SnapchatIcon,
  JoyFaceIcon,
  NeutralFaceIcon,
  LoveFaceIcon,
  DevotionFaceIcon,
  TenderFeelingsFaceIcon,
  WeepingFaceIcon,
  SufferingFaceIcon,
  HighSpiritsFaceIcon,
  LowSpiritsFaceIcon,
  GriefFaceIcon,
  AnxietyFaceIcon,
  DespairFaceIcon,
  DeterminationFaceIcon,
  SulkinessFaceIcon,
  IllTemperFaceIcon,
  MediationFaceIcon,
  ReflectionFaceIcon,
  BlushingFaceIcon,
  ModestyFaceIcon,
  ShynessFaceIcon,
  SelfAttentionFaceIcon,
  FearFaceIcon,
  SurpriseFaceIcon,
  NegationFaceIcon,
  AffirmationFaceIcon,
  PatienceFaceIcon,
  HelplessnessFaceIcon,
  PrideFaceIcon,
  GuiltFaceIcon,
  DisgustFaceIcon,
  ContemptFaceIcon,
  DisdainFaceIcon,
  HatredFaceIcon,
  AngerFaceIcon,
  GoalKeeperIcon,
  DefenderIcon,
  MidfielderIcon,
  StrikerIcon,
  RightFootIcon,
  LeftFootIcon,
  BothFootIcon,
  BasketBallIcon,
  LeagueGameIcon,
  FriendlyGameIcon,
  MatchMakerButtonIcon,
  FootballFieldIcon,
  BankCardsIcon,
  GoldTrophyIcon,
  SilverTrophyIcon,
  BronzeTrophyIcon,
  IronTrophyIcon,
  MTNIcon,
  OrangeMoneyIcon,
  MobileMoneyIcon,
} from '../img/icons/Icons';
import { REACT_APP_API_LAYER_KEY } from '../config';
import { CountryType } from './CommonInterfaces';

export const MIN_CODE_LENGTH = 10;
export const TRANSACTION_REG = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ@';
export const DEPOSIT_TOKEN_REG = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const TRACKING_NUMBER_REG = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const CHALLENGE_REG = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_$';
export const TOURNAMENT_REG = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_$';
export const BOOKING_REG = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ@_$!';
export const CONVERSATION_REG = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_$';
export const SPORT_TEAM_REG = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ@';
export const ONE_DAY_MILLISEC = 86400000;

export const PRICE_IN_MAAT = async (amount: number, currency: string) => {
  const found_currency = currency?.split('_')?.length === 1 ? currency : currency?.split('_')[1];

  // Check if currency is MAT
  if (found_currency === 'MAT') {
    return amount;
  }

  const myHeaders = new Headers();
  myHeaders.append('apikey', REACT_APP_API_LAYER_KEY);

  const apiUrl = `https://api.apilayer.com/fixer/convert?from=${currency}&to=USD&amount=1`;

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    });

    const data = await response.json();
    const exchangeRate = data.result;
    const MAAT_PRICE = 0.04;

    // Check if currency is MAT
    if (currency === 'MAT') {
      return amount;
    }

    // Check if the amount is a valid number
    if (isNaN(amount)) {
      return 0;
    }

    // Convert the amount to USD
    const usdAmount = amount / exchangeRate;

    // Convert the USD amount to MAT using the fixed exchange rate of 0.04
    const matAmount = usdAmount / MAAT_PRICE;

    // Round the MAT amount to 2 decimal places
    const roundedMATAmount = matAmount.toFixed(15);

    // Return the converted MAT amount
    return parseFloat(roundedMATAmount);
  } catch (error) {
    console.error('Failed to fetch exchange rate:', error);
    throw new Error('Failed to fetch exchange rate.');
  }
};

export const MAAT_TO_PRICE = async (amount: number, currency: string) => {
  const found_currency = currency?.split('_')?.length === 1 ? currency : currency?.split('_')[1];

  // Check if currency is MAT
  if (found_currency === 'MAT') {
    return amount;
  }

  const myHeaders = new Headers();
  myHeaders.append('apikey', REACT_APP_API_LAYER_KEY);
  const apiUrl = `https://api.apilayer.com/fixer/convert?from=USD&to=${found_currency}&amount=1`;

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    });

    const data = await response.json();
    const exchangeRate = data.result;
    const MAT_PRICE = 0.04;

    // Check if the amount is a valid number
    if (isNaN(amount)) {
      return 0;
    }

    // Convert the MAT amount to USD
    const usdAmount = amount * MAT_PRICE;

    // Convert the USD amount to the desired fiat currency using the exchange rate
    const fiatAmount = usdAmount * exchangeRate;

    // Round the fiat amount to 2 decimal places
    const roundedFiatAmount = fiatAmount.toFixed(15);

    // Return the converted fiat amount
    return parseFloat(roundedFiatAmount);
  } catch (error) {
    console.error('Failed to fetch exchange rate:', error);
    throw new Error('Failed to fetch exchange rate.');
  }
};

export const EXTRACT_ACCOUNT_NUMBER = (iban: string) => {
  // Remove non-alphanumeric characters from IBAN
  iban = iban.replace(/[^a-zA-Z0-9]/g, '');

  // Account number starts at index 12 for most IBANs
  const accountNumber = iban.substring(12);

  return accountNumber;
};

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  React.useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

export const FILE_TO_BASE64 = (file: any, cb: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(null, reader.result);
  };
  reader.onerror = function (error) {
    cb(error, null);
  };
};

export const GET_PIN_CODE = (amount: number) => {
  let code = '';

  for (let i = 0; i < amount; i++) {
    const num = Math.floor(Math.random() * 10);
    code = code + num.toString();
  }

  return code;
};

export const TO_TIMESTAMP = (strDate: any) => {
  const datum = Date.parse(strDate);
  return datum;
};

export const DURATION_IN_HOURS = (start_date: number, end_date: number) => {
  if (start_date && end_date) {
    const durationInMilliseconds = end_date - start_date;
    const millisecondsPerHour = 1000 * 60 * 60; // 1 hour = 1000 milliseconds * 60 seconds * 60 minutes

    const durationInHours = durationInMilliseconds / millisecondsPerHour;

    return durationInHours;
  }

  return 0;
};

export const TOTAL_ACCOUNT_BALANCE = (accumulator: any, account: any, index: any) => {
  switch (account.type) {
    case 'maat':
      index !== 1 ? (accumulator.balance += account.balance * parseFloat(MAT_DATA.price_usd)) : (accumulator.balance = accumulator.balance * parseFloat(MAT_DATA.price_usd));
      break;
    case 'euro':
      index !== 1 ? (accumulator.balance += account.balance * parseFloat(EUR_DATA.price_usd)) : (accumulator.balance = accumulator.balance * parseFloat(EUR_DATA.price_usd));
      break;
    case 'btc':
      index !== 1 ? (accumulator.balance += account.balance * parseFloat(BTC_DATA.price_usd)) : (accumulator.balance = accumulator.balance * parseFloat(BTC_DATA.price_usd));
      break;
    case 'usd':
      accumulator.balance += account.balance;
      break;
    default:
      return accumulator;
  }
};

export const CM_TO_INCHES = (heightInCm: number) => {
  const inchesPerFoot = 12;
  const inches = heightInCm / 2.54;
  const feet = Math.floor(inches / inchesPerFoot);
  const remainingInches = inches % inchesPerFoot;
  const roundedInches = Math.round(remainingInches);

  if (roundedInches === inchesPerFoot) {
    return `${feet + 1}' 0"`;
  }

  return `${feet}'${roundedInches}`;
};

export const KILO_TO_POUND = (weightInKg: number) => {
  const poundsPerKilo = 2.20462;
  const pounds = weightInKg * poundsPerKilo;
  const roundedPounds = Math.round(pounds * 10) / 10;
  return roundedPounds;
};

export const PUSH_NOTIFICATION = (title: string, message: string) => {
  try {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then((registration: any) => {
            console.log('Service worker registered!', registration);
          })
          .catch((error) => {
            console.log('Service worker registration failed:', error);
          });
      });
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      Notification.requestPermission().then((permission: any) => {
        if (permission === 'granted') {
          navigator.serviceWorker.getRegistration().then((registration: any) => {
            registration?.showNotification(title, {
              body: message,
              icon: '../../public/img/icon-192.png',
            });
          });
        }
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const GOOGLE_MAP_KEY = 'AIzaSyC7an6od_ilOysoGbC9sC6MJ_0-l3DHfeI';

export const GENDERS = [
  {
    value: 'FEMALE',
    label: 'Global_Gender_Female',
  },
  {
    value: 'MALE',
    label: 'Global_Gender_Male',
  },
  {
    value: 'OTHER',
    label: 'Global_Gender_Other',
  },
];

export const CHALLENGE_TYPES = [
  { value: 'FRIENDLY', label: 'Global_Challenge_Type_Friendly', icon: <FriendlyGameIcon /> },
  { value: 'LEAGUE', label: 'Global_Challenge_Type_League', icon: <LeagueGameIcon /> },
  { value: 'MATCHMAKER', label: 'Global_Challenge_Type_Matchmaker', icon: <MatchMakerButtonIcon /> },
];

export const PLAYGROUND_TYPES = [
  { value: 'firm', label: 'Firm ground', img: { path: '../img/', name: 'fg.png' } },
  { value: 'artificial', label: 'Artificial ground', img: { path: '../img/', name: 'ag.png' } },
  { value: 'concrete', label: 'Concrete', img: { path: '../img/', name: 'co.png' } },
];

export const POSITIONS = [
  {
    label: 'Global_Position_Goalkeeper',
    value: 'GK',
    icon: <GoalKeeperIcon />,
  },
  {
    label: 'Global_Position_Defender',
    value: 'DF',
    icon: <DefenderIcon />,
  },
  {
    label: 'Global_Position_Midfielder',
    value: 'MF',
    icon: <MidfielderIcon />,
  },
  {
    label: 'Global_Position_Striker',
    value: 'ST',
    icon: <StrikerIcon />,
  },
];

export const COUNTRY_TO_FLAG = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
};

export const BIG_NUMBER_CONVERTER = (value: any) => {
  let convertedValue = value.toString();

  if (value >= 10000 && value <= 99999) convertedValue = convertedValue.substring(0, 2).concat('K');
  if (value >= 100000 && value <= 999999) convertedValue = convertedValue.substring(0, 3).concat('K');
  if (value >= 1000000 && value <= 9999999) convertedValue = convertedValue.substring(0, 1).concat('M');
  if (value >= 10000000 && value <= 99999999) convertedValue = convertedValue.substring(0, 2).concat('M');
  if (value >= 100000000 && value <= 999999999) convertedValue = convertedValue.substring(0, 3).concat('M');
  if (value >= 1000000000 && value <= 9999999999) convertedValue = convertedValue.substring(0, 1).concat('B');
  if (value >= 10000000000 && value <= 99999999999) convertedValue = convertedValue.substring(0, 2).concat('B');
  if (value >= 100000000000 && value <= 999999999999) convertedValue = convertedValue.substring(0, 3).concat('B');

  return convertedValue;
};

export const COMPLEX_OPTIONS = [
  { value: 'shower', label: 'Shower' },
  { value: 'astro', label: 'Astro' },
  { value: 'natural_grass', label: 'Natural grass' },
  { value: 'snacks', label: 'Snacks' },
  { value: 'lockerrooms', label: 'Locker rooms' },
  { value: 'bar', label: 'Bar' },
];

export const WEEKDAYS = [
  { value: 'MONDAY', label: 'InputSelectAvailabilitiesFeature_Day_1', index: 1 },
  { value: 'TUESDAY', label: 'InputSelectAvailabilitiesFeature_Day_2', index: 2 },
  { value: 'WEDNESDAY', label: 'InputSelectAvailabilitiesFeature_Day_3', index: 3 },
  { value: 'THURSDAY', label: 'InputSelectAvailabilitiesFeature_Day_4', index: 4 },
  { value: 'FRIDAY', label: 'InputSelectAvailabilitiesFeature_Day_5', index: 5 },
  { value: 'SATURDAY', label: 'InputSelectAvailabilitiesFeature_Day_6', index: 6 },
  { value: 'SUNDAY', label: 'InputSelectAvailabilitiesFeature_Day_7', index: 0 },
];

export const TIMESLOTS = [
  { value: 6, label: '6:00-7:00' },
  { value: 7, label: '7:00-8:00' },
  { value: 8, label: '8:00-9:00' },
  { value: 9, label: '9:00-10:00' },
  { value: 10, label: '10:00-11:00' },
  { value: 11, label: '11:00-12:00' },
  { value: 12, label: '12:00-13:00' },
  { value: 13, label: '13:00-14:00' },
  { value: 14, label: '14:00-15:00' },
  { value: 15, label: '15:00-16:00' },
  { value: 16, label: '16:00-17:00' },
  { value: 17, label: '17:00-18:00' },
  { value: 18, label: '18:00-19:00' },
  { value: 19, label: '19:00-20:00' },
  { value: 20, label: '20:00-21:00' },
  { value: 21, label: '21:00-22:00' },
  { value: 22, label: '22:00-23:00' },
  { value: 23, label: '23:00-00:00' },
  { value: 0, label: '00:00-1:00' },
  { value: 1, label: '1:00-2:00' },
  { value: 2, label: '2:00-3:00' },
  { value: 3, label: '3:00-4:00' },
  { value: 4, label: '4:00-5:00' },
  { value: 5, label: '5:00-6:00' },
];

export const TOURNAMENT_PLAYOFF_STAGES = [
  {
    value: 'FINAL_128',
    label: 'Tournament_Finale_128',
    nb_teams: 128,
    organigram: [
      {
        label: 'Match #1 (Final)',
        expanded: true,
        stage: 'FINAL_2',
        children: [
          {
            label: 'Match #1',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #1',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #1',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #1',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #1',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #1',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #2',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #2',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #3',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #4',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #2',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #3',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #5',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #6',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #4',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #7',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #8',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #2',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #3',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #5',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #9',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #10',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #6',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #11',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #12',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #4',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #7',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #13',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #14',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #8',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #15',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #16',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Match #2',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #3',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #5',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #9',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #17',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #18',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #10',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #19',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #20',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #6',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #11',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #21',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #22',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #12',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #23',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #24',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #4',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #7',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #13',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #25',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #26',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #14',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #27',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #28',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #8',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #15',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #29',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #30',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #16',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #31',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #32',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: 'Match #2',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #3',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #5',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #9',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #17',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #33',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #34',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #18',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #35',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #36',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #10',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #19',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #37',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #38',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #20',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #39',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #40',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #6',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #11',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #21',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #41',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #42',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #22',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #43',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #44',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #12',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #23',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #45',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #46',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #24',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #47',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #48',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Match #4',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #7',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #13',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #25',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #49',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #50',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #26',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #51',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #52',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #14',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #27',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #53',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #54',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #28',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #55',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #56',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #8',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #15',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #29',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #57',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #58',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #30',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #59',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #60',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: 'Match #16',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #31',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #61',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #62',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                          {
                            label: 'Match #32',
                            expanded: true,
                            stage: 'FINAL_64',
                            children: [
                              {
                                label: 'Match #63',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                              {
                                label: 'Match #64',
                                expanded: true,
                                stage: 'FINAL_128',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    composition: [
      {
        value: 'FINAL_2',
        amount: 1,
      },
      {
        value: 'FINAL_4',
        amount: 2,
      },
      {
        value: 'FINAL_8',
        amount: 4,
      },
      {
        value: 'FINAL_16',
        amount: 8,
      },
      {
        value: 'FINAL_32',
        amount: 16,
      },
      {
        value: 'FINAL_64',
        amount: 32,
      },
      {
        value: 'FINAL_128',
        amount: 64,
      },
    ],
  }, // 128 teams
  {
    value: 'FINAL_64',
    label: 'Tournament_Finale_64',
    nb_teams: 64,
    organigram: [
      {
        label: 'Match #1 (Final)',
        expanded: true,
        stage: 'FINAL_2',
        children: [
          {
            label: 'Match #1',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #1',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #1',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #1',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #1',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #2',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #2',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #3',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #4',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #2',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #3',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #5',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #6',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #4',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #7',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #8',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Match #2',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #3',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #5',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #9',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #10',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #6',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #11',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #12',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #4',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #7',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #13',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #14',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #8',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #15',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #16',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: 'Match #2',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #3',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #5',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #9',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #17',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #18',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #10',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #19',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #20',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #6',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #11',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #21',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #22',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #12',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #23',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #24',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Match #4',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #7',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #13',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #25',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #26',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #14',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #27',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #28',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Match #8',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #15',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #29',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #30',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                      {
                        label: 'Match #16',
                        expanded: true,
                        stage: 'FINAL_32',
                        children: [
                          {
                            label: 'Match #31',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                          {
                            label: 'Match #32',
                            expanded: true,
                            stage: 'FINAL_64',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    composition: [
      {
        value: 'FINAL_2',
        amount: 1,
      },
      {
        value: 'FINAL_4',
        amount: 2,
      },
      {
        value: 'FINAL_8',
        amount: 4,
      },
      {
        value: 'FINAL_16',
        amount: 8,
      },
      {
        value: 'FINAL_32',
        amount: 16,
      },
      {
        value: 'FINAL_64',
        amount: 32,
      },
      {
        value: 'FINAL_128',
        amount: 0,
      },
    ],
  }, // 64 teams
  {
    value: 'FINAL_32',
    label: 'Tournament_Finale_32',
    nb_teams: 32,
    organigram: [
      {
        label: 'Match #1 (Final)',
        expanded: true,
        stage: 'FINAL_2',
        children: [
          {
            label: 'Match #1',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #1',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #1',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #1',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #2',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                  {
                    label: 'Match #2',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #3',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #4',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Match #2',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #3',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #5',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #6',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                  {
                    label: 'Match #4',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #7',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #8',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: 'Match #2',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #3',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #5',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #9',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #10',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                  {
                    label: 'Match #6',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #11',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #12',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Match #4',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #7',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #13',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #14',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                  {
                    label: 'Match #8',
                    expanded: true,
                    stage: 'FINAL_16',
                    children: [
                      {
                        label: 'Match #15',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                      {
                        label: 'Match #16',
                        expanded: true,
                        stage: 'FINAL_32',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    composition: [
      {
        value: 'FINAL_2',
        amount: 1,
      },
      {
        value: 'FINAL_4',
        amount: 2,
      },
      {
        value: 'FINAL_8',
        amount: 4,
      },
      {
        value: 'FINAL_16',
        amount: 8,
      },
      {
        value: 'FINAL_32',
        amount: 16,
      },
      {
        value: 'FINAL_64',
        amount: 0,
      },
      {
        value: 'FINAL_128',
        amount: 0,
      },
    ],
  }, // 32 teams
  {
    value: 'FINAL_16',
    label: 'Tournament_Finale_16',
    nb_teams: 16,
    organigram: [
      {
        label: 'Match #1 (Final)',
        expanded: true,
        stage: 'FINAL_2',
        children: [
          {
            label: 'Match #1',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #1',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #1',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                  {
                    label: 'Match #2',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                ],
              },
              {
                label: 'Match #2',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #3',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                  {
                    label: 'Match #4',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                ],
              },
            ],
          },
          {
            label: 'Match #2',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #3',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #5',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                  {
                    label: 'Match #6',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                ],
              },
              {
                label: 'Match #4',
                expanded: true,
                stage: 'FINAL_8',
                children: [
                  {
                    label: 'Match #7',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                  {
                    label: 'Match #8',
                    expanded: true,
                    stage: 'FINAL_16',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    composition: [
      {
        value: 'FINAL_2',
        amount: 1,
      },
      {
        value: 'FINAL_4',
        amount: 2,
      },
      {
        value: 'FINAL_8',
        amount: 4,
      },
      {
        value: 'FINAL_16',
        amount: 8,
      },
      {
        value: 'FINAL_32',
        amount: 0,
      },
      {
        value: 'FINAL_64',
        amount: 0,
      },
      {
        value: 'FINAL_128',
        amount: 0,
      },
    ],
  }, // 16 teams
  {
    value: 'FINAL_8',
    label: 'Tournament_Finale_8',
    nb_teams: 8,
    organigram: [
      {
        label: 'Match #1 (Final)',
        expanded: true,
        stage: 'FINAL_2',
        children: [
          {
            label: 'Match #1',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #1',
                expanded: true,
                stage: 'FINAL_8',
              },
              {
                label: 'Match #2',
                expanded: true,
                stage: 'FINAL_8',
              },
            ],
          },
          {
            label: 'Match #2',
            expanded: true,
            stage: 'FINAL_4',
            children: [
              {
                label: 'Match #3',
                expanded: true,
                stage: 'FINAL_8',
              },
              {
                label: 'Match #4',
                expanded: true,
                stage: 'FINAL_8',
              },
            ],
          },
        ],
      },
    ],
    composition: [
      {
        value: 'FINAL_2',
        amount: 1,
      },
      {
        value: 'FINAL_4',
        amount: 2,
      },
      {
        value: 'FINAL_8',
        amount: 4,
      },
      {
        value: 'FINAL_16',
        amount: 0,
      },
      {
        value: 'FINAL_32',
        amount: 0,
      },
      {
        value: 'FINAL_64',
        amount: 0,
      },
      {
        value: 'FINAL_128',
        amount: 0,
      },
    ],
  }, // 8 teams
  {
    value: 'FINAL_4',
    label: 'Tournament_Finale_4',
    nb_teams: 4,
    organigram: [
      {
        label: 'Match #1 (Final)',
        expanded: true,
        stage: 'FINAL_2',
        children: [
          {
            label: 'Match #1',
            expanded: true,
            stage: 'FINAL_4',
          },
          {
            label: 'Match #2',
            expanded: true,
            stage: 'FINAL_4',
          },
        ],
      },
    ],
    composition: [
      {
        value: 'FINAL_2',
        amount: 1,
      },
      {
        value: 'FINAL_4',
        amount: 2,
      },
      {
        value: 'FINAL_8',
        amount: 0,
      },
      {
        value: 'FINAL_16',
        amount: 0,
      },
      {
        value: 'FINAL_32',
        amount: 0,
      },
      {
        value: 'FINAL_64',
        amount: 0,
      },
      {
        value: 'FINAL_128',
        amount: 0,
      },
    ],
  }, // 4 teams
  {
    value: 'FINAL_2',
    label: 'Tournament_Finale_2',
    nb_teams: 2,
    organigram: [
      {
        label: 'Match #1 (Final)',
        expanded: true,
        stage: 'FINAL_2',
      },
    ],
    composition: [
      {
        value: 'FINAL_2',
        amount: 1,
      },
      {
        value: 'FINAL_4',
        amount: 0,
      },
      {
        value: 'FINAL_8',
        amount: 0,
      },
      {
        value: 'FINAL_16',
        amount: 0,
      },
      {
        value: 'FINAL_32',
        amount: 0,
      },
      {
        value: 'FINAL_64',
        amount: 0,
      },
      {
        value: 'FINAL_128',
        amount: 0,
      },
    ],
  }, // 2 teams
];

export const TROPHY_RANKS = [
  {
    label: 'Global_Trophy_Gold',
    value: 'GOLD',
    icon: <GoldTrophyIcon />,
  },
  {
    label: 'Global_Trophy_Silver',
    value: 'SILVER',
    icon: <SilverTrophyIcon />,
  },
  {
    label: 'Global_Trophy_Bronze',
    value: 'BRONZE',
    icon: <BronzeTrophyIcon />,
  },
  {
    label: 'Global_Trophy_Iron',
    value: 'IRON',
    icon: <IronTrophyIcon />,
  },
];

export const FOOTS = [
  {
    label: 'Global_Foots_Right',
    value: 'RF',
    icon: <RightFootIcon />,
  },
  {
    label: 'Global_Foots_Left',
    value: 'LF',
    icon: <LeftFootIcon />,
  },
  {
    label: 'Global_Foots_Both',
    value: 'BF',
    icon: <BothFootIcon />,
  },
];

export const SPORT_TEAM_TYPES = [
  { value: 'LEAGUE', label: 'Global_Sport_Team_Type_1', icon: <LeagueGameIcon /> },
  { value: 'FRIENDLY', label: 'Global_Sport_Team_Type_2', icon: <FriendlyGameIcon /> },
];

export const SPORTS = [
  { value: 'FOOTBALL', label: 'Global_Sport_Type_1', icon: <FootBallIcon /> },
  { value: 'BASKETBALL', label: 'Global_Sport_Type_2', icon: <BasketBallIcon /> },
];

export const PARCEL_UNITS = [
  { value: 'XS', label: 'XS Parcel' },
  { value: 'S', label: 'S Parcel' },
  { value: 'M', label: 'M Parcel' },
  { value: 'L', label: 'L Parcel' },
  { value: 'XL', label: 'XL Parcel' },
  { value: 'XLL', label: 'XLL Parcel' },
];

export const ALL_SOCIAL_MEDIAS = [
  { value: 'INSTAGRAM', label: 'Instagram', icon: <InstagramIcon />, icon_white: <InstagramWhiteIcon /> },
  { value: 'TWITTER', label: 'X (Twitter)', icon: <TwitterIcon />, icon_white: <TwitterWhiteIcon /> },
  { value: 'TIKTOK', label: 'Tik Tok', icon: <TikTokIcon />, icon_white: <TikTokWhiteIcon /> },
  { value: 'FACEBOOK', label: 'Facebook', icon: <FacebookIcon />, icon_white: <FacebookWhiteIcon /> },
  { value: 'LINKEDIN', label: 'LinkedIn', icon: <LinkedInIcon />, icon_white: <LinkedInWhiteIcon /> },
  { value: 'SNAPCHAT', label: 'Snapchat', icon: <SnapchatIcon />, icon_white: <SnapchatWhiteIcon /> },
];

export const COUNTRIES: CountryType[] = [
  { value: 'AD', label: 'Andorra', phone: '376', continent: 'Europe', country_alpha_3: 'AND' },
  {
    value: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
    continent: 'Asia',
    country_alpha_3: 'ARE',
  },
  { value: 'AF', label: 'Afghanistan', phone: '93', continent: 'Asia', country_alpha_3: 'AFG' },
  {
    value: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
    continent: 'North America',
    country_alpha_3: 'ATG',
  },
  {
    value: 'AI',
    label: 'Anguilla',
    phone: '1-264',
    continent: 'North America',
    country_alpha_3: 'AIA',
  },
  { value: 'AL', label: 'Albania', phone: '355', continent: 'Europe', country_alpha_3: 'ALB' },
  { value: 'AM', label: 'Armenia', phone: '374', continent: 'Asia', country_alpha_3: 'ARM' },
  { value: 'AO', label: 'Angola', phone: '244', continent: 'Africa', country_alpha_3: 'AGO' },
  { value: 'AQ', label: 'Antarctica', phone: '672', continent: 'Antarctica', country_alpha_3: 'ATA' },
  {
    value: 'AR',
    label: 'Argentina',
    phone: '54',
    continent: 'South America',
    country_alpha_3: 'ARG',
  },
  {
    value: 'AS',
    label: 'American Samoa',
    phone: '1-684',
    continent: 'Oceania',
    country_alpha_3: 'ASM',
  },
  { value: 'AT', label: 'Austria', phone: '43', continent: 'Europe', country_alpha_3: 'AUT' },
  { value: 'AU', label: 'Australia', phone: '61', continent: 'Oceania', country_alpha_3: 'AUS' },
  { value: 'AW', label: 'Aruba', phone: '297', continent: 'South America', country_alpha_3: 'ABW' },
  { value: 'AX', label: 'Aland Islands', phone: '358', continent: 'Europe', country_alpha_3: 'ALA' },
  { value: 'AZ', label: 'Azerbaijan', phone: '994', continent: 'Europe', country_alpha_3: 'AZE' },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
    continent: 'Europe',
    country_alpha_3: 'BIH',
  },
  {
    value: 'BB',
    label: 'Barbados',
    phone: '1-246',
    continent: 'North America',
    country_alpha_3: 'BRB',
  },
  { value: 'BD', label: 'Bangladesh', phone: '880', continent: 'Asia', country_alpha_3: 'BGD' },
  { value: 'BE', label: 'Belgium', phone: '32', continent: 'Europe', country_alpha_3: 'BEL' },
  { value: 'BF', label: 'Burkina Faso', phone: '226', continent: 'Africa', country_alpha_3: 'BFA' },
  { value: 'BG', label: 'Bulgaria', phone: '359', continent: 'Europe', country_alpha_3: 'BGR' },
  { value: 'BH', label: 'Bahrain', phone: '973', continent: 'Asia', country_alpha_3: 'BHR' },
  { value: 'BI', label: 'Burundi', phone: '257', continent: 'Africa', country_alpha_3: 'BDI' },
  { value: 'BJ', label: 'Benin', phone: '229', continent: 'Africa', country_alpha_3: 'BEN' },
  {
    value: 'BL',
    label: 'Saint Barthelemy',
    phone: '590',
    continent: 'North America',
    country_alpha_3: 'BLM',
  },
  {
    value: 'BM',
    label: 'Bermuda',
    phone: '1-441',
    continent: 'North America',
    country_alpha_3: 'BMU',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
    phone: '673',
    continent: 'Asia',
    country_alpha_3: 'BRN',
  },
  { value: 'BO', label: 'Bolivia', phone: '591', continent: 'South America', country_alpha_3: 'BOL' },
  { value: 'BR', label: 'Brazil', phone: '55', continent: 'South America', country_alpha_3: 'BRA' },
  {
    value: 'BS',
    label: 'Bahamas',
    phone: '1-242',
    continent: 'North America',
    country_alpha_3: 'BHS',
  },
  { value: 'BT', label: 'Bhutan', phone: '975', continent: 'Asia', country_alpha_3: 'BTN' },
  {
    value: 'BV',
    label: 'Bouvet Island',
    phone: '47',
    continent: 'Antarctica',
    country_alpha_3: 'BVT',
  },
  { value: 'BW', label: 'Botswana', phone: '267', continent: 'Africa', country_alpha_3: 'BWA' },
  { value: 'BY', label: 'Belarus', phone: '375', continent: 'Europe', country_alpha_3: 'BLR' },
  { value: 'BZ', label: 'Belize', phone: '501', continent: 'North America', country_alpha_3: 'BLZ' },
  { value: 'CA', label: 'Canada', phone: '1', continent: 'North America', country_alpha_3: 'CAN' },
  {
    value: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
    continent: 'Asia',
    country_alpha_3: 'CCK',
  },
  {
    value: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
    continent: 'Africa',
    country_alpha_3: 'COD',
  },
  {
    value: 'CF',
    label: 'Central African Republic',
    phone: '236',
    continent: 'Africa',
    country_alpha_3: 'CAF',
  },
  {
    value: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
    continent: 'Africa',
    country_alpha_3: 'COG',
  },
  { value: 'CH', label: 'Switzerland', phone: '41', continent: 'Europe', country_alpha_3: 'CHE' },
  { value: 'CI', label: "Cote d'Ivoire", phone: '225', continent: 'Africa', country_alpha_3: 'CIV' },
  { value: 'CK', label: 'Cook Islands', phone: '682', continent: 'Oceania', country_alpha_3: 'COK' },
  { value: 'CL', label: 'Chile', phone: '56', continent: 'South America', country_alpha_3: 'CHL' },
  { value: 'CM', label: 'Cameroon', phone: '237', continent: 'Africa', country_alpha_3: 'CMR' },
  { value: 'CN', label: 'China', phone: '86', continent: 'Asia', country_alpha_3: 'CHN' },
  { value: 'CO', label: 'Colombia', phone: '57', continent: 'South America', country_alpha_3: 'COL' },
  {
    value: 'CR',
    label: 'Costa Rica',
    phone: '506',
    continent: 'North America',
    country_alpha_3: 'CRI',
  },
  { value: 'CU', label: 'Cuba', phone: '53', continent: 'North America', country_alpha_3: 'CUB' },
  { value: 'CV', label: 'Cape Verde', phone: '238', continent: 'Africa', country_alpha_3: 'CPV' },
  { value: 'CW', label: 'Curacao', phone: '599', continent: 'South America', country_alpha_3: 'CUW' },
  { value: 'CX', label: 'Christmas Island', phone: '61', continent: 'Asia', country_alpha_3: 'CXR' },
  { value: 'CY', label: 'Cyprus', phone: '357', continent: 'Europe', country_alpha_3: 'CYP' },
  { value: 'CZ', label: 'Czech Republic', phone: '420', continent: 'Europe', country_alpha_3: 'CZE' },
  { value: 'DE', label: 'Germany', phone: '49', continent: 'Europe', country_alpha_3: 'DEU' },
  { value: 'DJ', label: 'Djibouti', phone: '253', continent: 'Africa', country_alpha_3: 'DJI' },
  { value: 'DK', label: 'Denmark', phone: '45', continent: 'Europe', country_alpha_3: 'DNK' },
  { value: 'DM', label: 'Dominica', phone: '1-767', continent: 'North America', country_alpha_3: 'DMA' },
  {
    value: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
    continent: 'North America',
    country_alpha_3: 'DOM',
  },
  { value: 'DZ', label: 'Algeria', phone: '213', continent: 'Africa', country_alpha_3: 'DZA' },
  {
    value: 'EC',
    label: 'Ecuador',
    phone: '593',
    continent: 'South America',
    country_alpha_3: 'ECU',
  },
  { value: 'EE', label: 'Estonia', phone: '372', continent: 'Europe', country_alpha_3: 'EST' },
  {
    value: 'EG',
    label: 'Egypt',
    phone: '20',
    continent: 'Africa',
    country_alpha_3: 'EGY',
  },
  {
    value: 'EH',
    label: 'Western Sahara',
    phone: '212',
    continent: 'Africa',
    country_alpha_3: 'ESH',
  },
  {
    value: 'ER',
    label: 'Eritrea',
    phone: '291',
    continent: 'Africa',
    country_alpha_3: 'ERI',
  },
  {
    value: 'ES',
    label: 'Spain',
    phone: '34',
    continent: 'Europe',
    country_alpha_3: 'ESP',
  },
  {
    value: 'ET',
    label: 'Ethiopia',
    phone: '251',
    continent: 'Africa',
    country_alpha_3: 'ETH',
  },
  {
    value: 'FI',
    label: 'Finland',
    phone: '358',
    continent: 'Europe',
    country_alpha_3: 'FIN',
  },
  {
    value: 'FJ',
    label: 'Fiji',
    phone: '679',
    continent: 'Oceania',
    country_alpha_3: 'FJI',
  },
  {
    value: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
    continent: 'Oceania',
    country_alpha_3: 'FSM',
  },
  {
    value: 'FO',
    label: 'Faroe Islands',
    phone: '298',
    continent: 'Europe',
    country_alpha_3: 'FRO',
  },
  {
    value: 'FR',
    label: 'France',
    phone: '33',
    continent: 'Europe',
    country_alpha_3: 'FRA',
  },
  { value: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500', continent: '', country_alpha_3: '' },
  {
    value: 'GA',
    label: 'Gabon',
    phone: '241',
    continent: 'Africa',
    country_alpha_3: 'GAB',
  },
  {
    value: 'GB',
    label: 'United Kingdom',
    phone: '44',
    continent: 'Europe',
    country_alpha_3: 'GBR',
  },
  {
    value: 'GD',
    label: 'Grenada',
    phone: '1-473',
    continent: 'North America',
    country_alpha_3: 'GRD',
  },
  {
    value: 'GE',
    label: 'Georgia',
    phone: '995',
    continent: 'Europe',
    country_alpha_3: 'GEO',
  },
  {
    value: 'GF',
    label: 'French Guiana',
    phone: '594',
    continent: 'South America',
    country_alpha_3: 'GUF',
  },
  {
    value: 'GG',
    label: 'Guernsey',
    phone: '44',
    continent: 'Europe',
    country_alpha_3: 'GGY',
  },
  {
    value: 'GH',
    label: 'Ghana',
    phone: '233',
    continent: 'Africa',
    country_alpha_3: 'GHA',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
    phone: '350',
    continent: 'Europe',
    country_alpha_3: 'GIB',
  },
  {
    value: 'GL',
    label: 'Greenland',
    phone: '299',
    continent: 'North America',
    country_alpha_3: 'GRL',
  },
  {
    value: 'GM',
    label: 'Gambia',
    phone: '220',
    continent: 'Africa',
    country_alpha_3: 'GMB',
  },
  {
    value: 'GN',
    label: 'Guinea',
    phone: '224',
    continent: 'Africa',
    country_alpha_3: 'GIN',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
    phone: '590',
    continent: 'North America',
    country_alpha_3: 'GLP',
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea',
    phone: '240',
    continent: 'Africa',
    country_alpha_3: 'GNQ',
  },
  {
    value: 'GR',
    label: 'Greece',
    phone: '30',
    continent: 'Europe',
    country_alpha_3: 'GRC',
  },
  {
    value: 'GT',
    label: 'Guatemala',
    phone: '502',
    continent: 'North America',
    country_alpha_3: 'GTM',
  },
  {
    value: 'GU',
    label: 'Guam',
    phone: '1-671',
    continent: 'Oceania',
    country_alpha_3: 'GUM',
  },
  {
    value: 'GW',
    label: 'Guinea-Bissau',
    phone: '245',
    continent: 'Africa',
    country_alpha_3: 'GNB',
  },
  {
    value: 'GY',
    label: 'Guyana',
    phone: '592',
    continent: 'South America',
    country_alpha_3: 'GUY',
  },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500', continent: '', country_alpha_3: '' },
  {
    value: 'HK',
    label: 'Hong Kong',
    phone: '852',
    continent: 'Asia',
    country_alpha_3: 'HKG',
  },
  {
    value: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
    continent: 'Antarctica',
    country_alpha_3: 'HMD',
  },
  {
    value: 'HN',
    label: 'Honduras',
    phone: '504',
    continent: 'North America',
    country_alpha_3: 'HND',
  },
  {
    value: 'HR',
    label: 'Croatia',
    phone: '385',
    continent: 'Europe',
    country_alpha_3: 'HRV',
  },
  {
    value: 'HT',
    label: 'Haiti',
    phone: '509',
    continent: 'North America',
    country_alpha_3: 'HTI',
  },
  {
    value: 'HU',
    label: 'Hungary',
    phone: '36',
    continent: 'Europe',
    country_alpha_3: 'HUN',
  },
  {
    value: 'ID',
    label: 'Indonesia',
    phone: '62',
    continent: 'Asia',
    country_alpha_3: 'IDN',
  },
  {
    value: 'IE',
    label: 'Ireland',
    phone: '353',
    continent: 'Europe',
    country_alpha_3: 'IRL',
  },
  {
    value: 'IL',
    label: 'Israel',
    phone: '972',
    continent: 'Asia',
    country_alpha_3: 'ISR',
  },
  {
    value: 'IM',
    label: 'Isle of Man',
    phone: '44',
    continent: 'Europe',
    country_alpha_3: 'IMN',
  },
  {
    value: 'IN',
    label: 'India',
    phone: '91',
    continent: 'Asia',
    country_alpha_3: 'IND',
  },
  {
    value: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
    continent: 'Asia',
    country_alpha_3: 'IOT',
  },
  {
    value: 'IQ',
    label: 'Iraq',
    phone: '964',
    continent: 'Asia',
    country_alpha_3: 'IRQ',
  },
  {
    value: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
    continent: 'Asia',
    country_alpha_3: 'IRN',
  },
  {
    value: 'IS',
    label: 'Iceland',
    phone: '354',
    continent: 'Europe',
    country_alpha_3: 'ISL',
  },
  {
    value: 'IT',
    label: 'Italy',
    phone: '39',
    continent: 'Europe',
    country_alpha_3: 'ITA',
  },
  {
    value: 'JE',
    label: 'Jersey',
    phone: '44',
    continent: 'Europe',
    country_alpha_3: 'JEY',
  },
  {
    value: 'JM',
    label: 'Jamaica',
    phone: '1-876',
    continent: 'North America',
    country_alpha_3: 'JAM',
  },
  {
    value: 'JO',
    label: 'Jordan',
    phone: '962',
    continent: 'Asia',
    country_alpha_3: 'JOR',
  },
  {
    value: 'JP',
    label: 'Japan',
    phone: '81',
    continent: 'Asia',
    country_alpha_3: 'JPN',
  },
  {
    value: 'KE',
    label: 'Kenya',
    phone: '254',
    continent: 'Africa',
    country_alpha_3: 'KEN',
  },
  { value: 'XK', label: 'Kosovo', phone: '383', continent: 'Europe', country_alpha_3: 'BIH' },
  {
    value: 'KG',
    label: 'Kyrgyzstan',
    phone: '996',
    continent: 'Asia',
    country_alpha_3: 'KGZ',
  },
  {
    value: 'KH',
    label: 'Cambodia',
    phone: '855',
    continent: 'Asia',
    country_alpha_3: 'KHM',
  },
  {
    value: 'KI',
    label: 'Kiribati',
    phone: '686',
    continent: 'Oceania',
    country_alpha_3: 'KIR',
  },
  {
    value: 'KM',
    label: 'Comoros',
    phone: '269',
    continent: 'Africa',
    country_alpha_3: 'COM',
  },
  {
    value: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
    continent: 'North America',
    country_alpha_3: 'KNA',
  },
  {
    value: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
    continent: 'Asia',
    country_alpha_3: 'PRK',
  },
  {
    value: 'KR',
    label: 'Korea, Republic of',
    phone: '82',
    continent: 'Asia',
    country_alpha_3: 'KOR',
  },
  {
    value: 'KW',
    label: 'Kuwait',
    phone: '965',
    continent: 'Asia',
    country_alpha_3: 'KWT',
  },
  {
    value: 'KY',
    label: 'Cayman Islands',
    phone: '1-345',
    continent: 'North America',
    country_alpha_3: 'CYM',
  },
  {
    value: 'KZ',
    label: 'Kazakhstan',
    phone: '7',
    continent: 'Asia',
    country_alpha_3: 'KAZ',
  },
  {
    value: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
    continent: 'Asia',
    country_alpha_3: 'LAO',
  },
  {
    value: 'LB',
    label: 'Lebanon',
    phone: '961',
    continent: 'Asia',
    country_alpha_3: 'LBN',
  },
  {
    value: 'LC',
    label: 'Saint Lucia',
    phone: '1-758',
    continent: 'North America',
    country_alpha_3: 'LCA',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
    phone: '423',
    continent: 'Europe',
    country_alpha_3: 'LIE',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
    phone: '94',
    continent: 'Asia',
    country_alpha_3: 'LKA',
  },
  {
    value: 'LR',
    label: 'Liberia',
    phone: '231',
    continent: 'Africa',
    country_alpha_3: 'LBR',
  },
  {
    value: 'LS',
    label: 'Lesotho',
    phone: '266',
    continent: 'Africa',
    country_alpha_3: 'LSO',
  },
  {
    value: 'LT',
    label: 'Lithuania',
    phone: '370',
    continent: 'Europe',
    country_alpha_3: 'LTU',
  },
  {
    value: 'LU',
    label: 'Luxembourg',
    phone: '352',
    continent: 'Europe',
    country_alpha_3: 'LUX',
  },
  {
    value: 'LV',
    label: 'Latvia',
    phone: '371',
    continent: 'Europe',
    country_alpha_3: 'LVA',
  },
  {
    value: 'LY',
    label: 'Libya',
    phone: '218',
    continent: 'Africa',
    country_alpha_3: 'LBY',
  },
  {
    value: 'MA',
    label: 'Morocco',
    phone: '212',
    continent: 'Africa',
    country_alpha_3: 'MAR',
  },
  {
    value: 'MC',
    label: 'Monaco',
    phone: '377',
    continent: 'Europe',
    country_alpha_3: 'MCO',
  },
  {
    value: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
    continent: 'Europe',
    country_alpha_3: 'MDA',
  },
  {
    value: 'ME',
    label: 'Montenegro',
    phone: '382',
    continent: 'Europe',
    country_alpha_3: 'MNE',
  },
  {
    value: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
    continent: 'North America',
    country_alpha_3: 'MAF',
  },
  {
    value: 'MG',
    label: 'Madagascar',
    phone: '261',
    continent: 'Africa',
    country_alpha_3: 'MDG',
  },
  {
    value: 'MH',
    label: 'Marshall Islands',
    phone: '692',
    continent: 'Oceania',
    country_alpha_3: 'MHL',
  },
  {
    value: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
    continent: 'Europe',
    country_alpha_3: 'MKD',
  },
  {
    value: 'ML',
    label: 'Mali',
    phone: '223',
    continent: 'Africa',
    country_alpha_3: 'MLI',
  },
  {
    value: 'MM',
    label: 'Myanmar',
    phone: '95',
    continent: 'Asia',
    country_alpha_3: 'MMR',
  },
  {
    value: 'MN',
    label: 'Mongolia',
    phone: '976',
    continent: 'Asia',
    country_alpha_3: 'MNG',
  },
  {
    value: 'MO',
    label: 'Macao',
    phone: '853',
    continent: 'Asia',
    country_alpha_3: 'MAC',
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
    continent: 'Oceania',
    country_alpha_3: 'MNP',
  },
  {
    value: 'MQ',
    label: 'Martinique',
    phone: '596',
    continent: 'North America',
    country_alpha_3: 'MTQ',
  },
  {
    value: 'MR',
    label: 'Mauritania',
    phone: '222',
    continent: 'Africa',
    country_alpha_3: 'MRT',
  },
  {
    value: 'MS',
    label: 'Montserrat',
    phone: '1-664',
    continent: 'North America',
    country_alpha_3: 'MSR',
  },
  {
    value: 'MT',
    label: 'Malta',
    phone: '356',
    continent: 'Europe',
    country_alpha_3: 'MLT',
  },
  {
    value: 'MU',
    label: 'Mauritius',
    phone: '230',
    continent: 'Africa',
    country_alpha_3: 'MUS',
  },
  {
    value: 'MV',
    label: 'Maldives',
    phone: '960',
    continent: 'Asia',
    country_alpha_3: 'MDV',
  },
  {
    value: 'MW',
    label: 'Malawi',
    phone: '265',
    continent: 'Africa',
    country_alpha_3: 'MWI',
  },
  {
    value: 'MX',
    label: 'Mexico',
    phone: '52',
    continent: 'North America',
    country_alpha_3: 'MEX',
  },
  {
    value: 'MY',
    label: 'Malaysia',
    phone: '60',
    continent: 'Asia',
    country_alpha_3: 'MYS',
  },
  {
    value: 'MZ',
    label: 'Mozambique',
    phone: '258',
    continent: 'Africa',
    country_alpha_3: 'MOZ',
  },
  {
    value: 'NA',
    label: 'Namibia',
    phone: '264',
    continent: 'Africa',
    country_alpha_3: 'NAM',
  },
  {
    value: 'NC',
    label: 'New Caledonia',
    phone: '687',
    continent: 'Oceania',
    country_alpha_3: 'NCL',
  },
  {
    value: 'NE',
    label: 'Niger',
    phone: '227',
    continent: 'Africa',
    country_alpha_3: 'NER',
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
    phone: '672',
    continent: 'Oceania',
    country_alpha_3: 'NFK',
  },
  {
    value: 'NG',
    label: 'Nigeria',
    phone: '234',
    continent: 'Africa',
    country_alpha_3: 'NGA',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
    phone: '505',
    continent: 'North America',
    country_alpha_3: 'NIC',
  },
  {
    value: 'NL',
    label: 'Netherlands',
    phone: '31',
    continent: 'Europe',
    country_alpha_3: 'NLD',
  },
  {
    value: 'NO',
    label: 'Norway',
    phone: '47',
    continent: 'Europe',
    country_alpha_3: 'NOR',
  },
  {
    value: 'NP',
    label: 'Nepal',
    phone: '977',
    continent: 'Asia',
    country_alpha_3: 'NPL',
  },
  {
    value: 'NR',
    label: 'Nauru',
    phone: '674',
    continent: 'Oceania',
    country_alpha_3: 'NRU',
  },
  {
    value: 'NU',
    label: 'Niue',
    phone: '683',
    continent: 'Oceania',
    country_alpha_3: 'NIU',
  },
  {
    value: 'NZ',
    label: 'New Zealand',
    phone: '64',
    continent: 'Oceania',
    country_alpha_3: 'NZL',
  },
  {
    value: 'OM',
    label: 'Oman',
    phone: '968',
    continent: 'Asia',
    country_alpha_3: 'OMN',
  },
  { value: 'PS', label: 'Palestine, State of', phone: '970', continent: 'Asia', country_alpha_3: '' },
  {
    value: 'PA',
    label: 'Panama',
    phone: '507',
    continent: 'North America',
    country_alpha_3: 'PAN',
  },
  {
    value: 'PE',
    label: 'Peru',
    phone: '51',
    continent: 'South America',
    country_alpha_3: 'PER',
  },
  {
    value: 'PF',
    label: 'French Polynesia',
    phone: '689',
    continent: 'Oceania',
    country_alpha_3: 'PYF',
  },
  {
    value: 'PG',
    label: 'Papua New Guinea',
    phone: '675',
    continent: 'Oceania',
    country_alpha_3: 'PNG',
  },
  {
    value: 'PH',
    label: 'Philippines',
    phone: '63',
    continent: 'Asia',
    country_alpha_3: 'PHL',
  },
  {
    value: 'PK',
    label: 'Pakistan',
    phone: '92',
    continent: 'Asia',
    country_alpha_3: 'PAK',
  },
  {
    value: 'PL',
    label: 'Poland',
    phone: '48',
    continent: 'Europe',
    country_alpha_3: 'POL',
  },
  {
    value: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
    continent: 'North America',
    country_alpha_3: 'SPM',
  },
  {
    value: 'PN',
    label: 'Pitcairn',
    phone: '870',
    continent: 'Oceania',
    country_alpha_3: 'PCN',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
    phone: '1',
    continent: 'North America',
    country_alpha_3: 'PRI',
  },
  {
    value: 'PT',
    label: 'Portugal',
    phone: '351',
    continent: 'Europe',
    country_alpha_3: 'PRT',
  },
  {
    value: 'PW',
    label: 'Palau',
    phone: '680',
    continent: 'Oceania',
    country_alpha_3: 'PLW',
  },
  {
    value: 'PY',
    label: 'Paraguay',
    phone: '595',
    continent: 'South America',
    country_alpha_3: 'PRY',
  },
  {
    value: 'QA',
    label: 'Qatar',
    phone: '974',
    continent: 'Asia',
    country_alpha_3: 'QAT',
  },
  {
    value: 'RE',
    label: 'Réunion',
    phone: '262',
    continent: 'Africa',
    country_alpha_3: 'REU',
  },
  {
    value: 'RO',
    label: 'Romania',
    phone: '40',
    continent: 'Europe',
    country_alpha_3: 'ROU',
  },
  {
    value: 'RS',
    label: 'Serbia',
    phone: '381',
    continent: 'Europe',
    country_alpha_3: 'SRB',
  },
  {
    value: 'RU',
    label: 'Russian Federation',
    phone: '7',
    continent: 'Europe',
    country_alpha_3: 'RUS',
  },
  {
    value: 'RW',
    label: 'Rwanda',
    phone: '250',
    continent: 'Africa',
    country_alpha_3: 'RWA',
  },
  {
    value: 'SA',
    label: 'Saudi Arabia',
    phone: '966',
    continent: 'Asia',
    country_alpha_3: 'SAU',
  },
  {
    value: 'SB',
    label: 'Solomon Islands',
    phone: '677',
    continent: 'Oceania',
    country_alpha_3: 'SLB',
  },
  {
    value: 'SC',
    label: 'Seychelles',
    phone: '248',
    continent: 'Africa',
    country_alpha_3: 'SYC',
  },
  {
    value: 'SD',
    label: 'Sudan',
    phone: '249',
    continent: 'Africa',
    country_alpha_3: 'SDN',
  },
  {
    value: 'SE',
    label: 'Sweden',
    phone: '46',
    continent: 'Europe',
    country_alpha_3: 'SWE',
  },
  {
    value: 'SG',
    label: 'Singapore',
    phone: '65',
    continent: 'Asia',
    country_alpha_3: 'SGP',
  },
  {
    value: 'SH',
    label: 'Saint Helena',
    phone: '290',
    continent: 'Africa',
    country_alpha_3: 'SHN',
  },
  {
    value: 'SI',
    label: 'Slovenia',
    phone: '386',
    continent: 'Europe',
    country_alpha_3: 'SVN',
  },
  {
    value: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
    continent: 'Europe',
    country_alpha_3: 'SJM',
  },
  {
    value: 'SK',
    label: 'Slovakia',
    phone: '421',
    continent: 'Europe',
    country_alpha_3: 'SVK',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
    phone: '232',
    continent: 'Africa',
    country_alpha_3: 'SLE',
  },
  {
    value: 'SM',
    label: 'San Marino',
    phone: '378',
    continent: 'Europe',
    country_alpha_3: 'SMR',
  },
  {
    value: 'SN',
    label: 'Senegal',
    phone: '221',
    continent: 'Africa',
    country_alpha_3: 'SEN',
  },
  {
    value: 'SO',
    label: 'Somalia',
    phone: '252',
    continent: 'Africa',
    country_alpha_3: 'SOM',
  },
  {
    value: 'SR',
    label: 'Suriname',
    phone: '597',
    continent: 'South America',
    country_alpha_3: 'SUR',
  },
  {
    value: 'SS',
    label: 'South Sudan',
    phone: '211',
    continent: 'Africa',
    country_alpha_3: 'SSD',
  },
  {
    value: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
    continent: 'Africa',
    country_alpha_3: 'STP',
  },
  {
    value: 'SV',
    label: 'El Salvador',
    phone: '503',
    continent: 'North America',
    country_alpha_3: 'SLV',
  },
  {
    value: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
    continent: 'North America',
    country_alpha_3: 'SXM',
  },
  {
    value: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
    continent: 'Asia',
    country_alpha_3: 'SYR',
  },
  {
    value: 'SZ',
    label: 'Eswatini',
    phone: '268',
    continent: 'Africa',
    country_alpha_3: 'SWZ',
  },
  {
    value: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
    continent: 'North America',
    country_alpha_3: 'TCA',
  },
  {
    value: 'TD',
    label: 'Chad',
    phone: '235',
    continent: 'Africa',
    country_alpha_3: 'TCD',
  },
  {
    value: 'TF',
    label: 'French Southern Territories',
    phone: '262',
    continent: 'Antarctica',
    country_alpha_3: 'ATF',
  },
  {
    value: 'TG',
    label: 'Togo',
    phone: '228',
    continent: 'Africa',
    country_alpha_3: 'TGO',
  },
  {
    value: 'TW',
    label: 'Taiwan',
    phone: '886',
    continent: 'Asia',
    country_alpha_3: 'TWN',
  },
  {
    value: 'TH',
    label: 'Thailand',
    phone: '66',
    continent: 'Asia',
    country_alpha_3: 'THA',
  },

  {
    value: 'TJ',
    label: 'Tajikistan',
    phone: '992',
    continent: 'Asia',
    country_alpha_3: 'TJK',
  },
  {
    value: 'TK',
    label: 'Tokelau',
    phone: '690',
    continent: 'Oceania',
    country_alpha_3: 'TKL',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
    phone: '670',
    continent: 'Asia',
    country_alpha_3: 'TLS',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
    phone: '993',
    continent: 'Asia',
    country_alpha_3: 'TKM',
  },
  {
    value: 'TN',
    label: 'Tunisia',
    phone: '216',
    continent: 'Africa',
    country_alpha_3: 'TUN',
  },
  {
    value: 'TO',
    label: 'Tonga',
    phone: '676',
    continent: 'Oceania',
    country_alpha_3: 'TON',
  },
  {
    value: 'TR',
    label: 'Turkey',
    phone: '90',
    continent: 'Asia',
    country_alpha_3: 'TUR',
  },
  {
    value: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
    continent: 'North America',
    country_alpha_3: 'TTO',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
    phone: '688',
    continent: 'Oceania',
    country_alpha_3: 'TUV',
  },
  {
    value: 'TZ',
    label: 'Tanzania, United Republic of',
    phone: '255',
    continent: 'Africa',
    country_alpha_3: 'TZA',
  },
  {
    value: 'UA',
    label: 'Ukraine',
    phone: '380',
    continent: 'Europe',
    country_alpha_3: 'UKR',
  },
  {
    value: 'UG',
    label: 'Uganda',
    phone: '256',
    continent: 'Africa',
    country_alpha_3: 'UGA',
  },
  {
    value: 'UM',
    label: 'United States Minor Outlying Islands',
    phone: '1',
    continent: 'Oceania',
    country_alpha_3: 'UMI',
  },
  {
    value: 'US',
    label: 'United States',
    phone: '1',
    continent: 'North America',
    country_alpha_3: 'USA',
  },
  {
    value: 'UY',
    label: 'Uruguay',
    phone: '598',
    continent: 'South America',
    country_alpha_3: 'URY',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
    phone: '998',
    continent: 'Asia',
    country_alpha_3: 'UZB',
  },
  {
    value: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
    continent: 'Europe',
    country_alpha_3: 'VAT',
  },
  {
    value: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
    continent: 'North America',
    country_alpha_3: 'VCT',
  },
  {
    value: 'VE',
    label: 'Venezuela, Bolivarian Republic of',
    phone: '58',
    continent: 'South America',
    country_alpha_3: 'VEN',
  },
  {
    value: 'VG',
    label: 'Virgin Islands, British',
    phone: '1-284',
    continent: 'North America',
    country_alpha_3: 'VGB',
  },
  {
    value: 'VI',
    label: 'Virgin Islands, U.S.',
    phone: '1-340',
    continent: 'North America',
    country_alpha_3: 'VIR',
  },
  {
    value: 'VN',
    label: 'Viet Nam',
    phone: '84',
    continent: 'Asia',
    country_alpha_3: 'VNM',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
    phone: '678',
    continent: 'Oceania',
    country_alpha_3: 'VUT',
  },
  {
    value: 'WF',
    label: 'Wallis and Futuna',
    phone: '681',
    continent: 'Oceania',
    country_alpha_3: 'WLF',
  },
  {
    value: 'WS',
    label: 'Samoa',
    phone: '685',
    continent: 'Oceania',
    country_alpha_3: 'WSM',
  },
  {
    value: 'YE',
    label: 'Yemen',
    phone: '967',
    continent: 'Asia',
    country_alpha_3: 'YEM',
  },
  {
    value: 'YT',
    label: 'Mayotte',
    phone: '262',
    continent: 'Africa',
    country_alpha_3: 'MYT',
  },
  {
    value: 'ZA',
    label: 'South Africa',
    phone: '27',
    continent: 'Africa',
    country_alpha_3: 'ZAF',
  },
  {
    value: 'ZM',
    label: 'Zambia',
    phone: '260',
    continent: 'Africa',
    country_alpha_3: 'ZMB',
  },
  {
    value: 'ZW',
    label: 'Zimbabwe',
    phone: '263',
    continent: 'Africa',
    country_alpha_3: 'ZWE',
  },
];

export const ID_TYPES = [
  {
    value: 'PASSPORT',
    label: 'Passport',
  },
  {
    value: 'NATIONAL',
    label: 'National ID',
  },
  {
    value: 'DRIVING_LICENCE',
    label: 'Driving Licence',
  },
  {
    value: 'TEMPORARILY',
    label: 'Temporarily ID',
  },
];

export const JOB_POSITION_TYPES = [
  { value: 'FULL_TIME_PERMANENT', label: 'Permanent (full-time)' },
  { value: 'PART_TIME_PERMANENT', label: 'Permanent (part-time)' },
  { value: 'FULL_TIME_TEMPORARY', label: 'Temporary (full-time)' },
  { value: 'PART_TIME_TEMPORARY', label: 'Temporary (part-time)' },
  { value: 'FULL_TIME_INTERNSHIP', label: 'Internship (full-time)' },
  { value: 'PART_TIME_INTERNSHIP', label: 'Internship (part-time)' },
];

export const FIVEZER_PARTNERSHIP_ROLES = [
  { value: 'COMPLEX_OWNER_HEAD', label: 'COMPLEX_OWNER_HEAD' },
  { value: 'COMPLEX_OWNER_MANAGER', label: 'COMPLEX_OWNER_MANAGER' },
  { value: 'COMPLEX_OWNER_ASSISTANT', label: 'COMPLEX_OWNER_ASSISTANT' },
];

export const BANTU_CAPITALS_PARTNERSHIP_ROLES = [
  { value: 'RETAIL_INVESTOR_HEAD', label: 'RETAIL_INVESTOR_HEAD' },
  { value: 'RETAIL_INVESTOR_MANAGER', label: 'RETAIL_INVESTOR_MANAGER' },
  { value: 'RETAIL_INVESTOR_ASSISTANT', label: 'RETAIL_INVESTOR_ASSISTANT' },
  {
    value: 'INSTITUTIONAL_INVESTOR_HEAD',
    label: 'INSTITUTIONAL_INVESTOR_HEAD',
  },
  {
    value: 'INSTITUTIONAL_INVESTOR_MANAGER',
    label: 'INSTITUTIONAL_INVESTOR_MANAGER',
  },
  {
    value: 'INSTITUTIONAL_INVESTOR_ASSISTANT',
    label: 'INSTITUTIONAL_INVESTOR_ASSISTANT',
  },
  { value: 'FINANCIAL_AUDITOR', label: 'FINANCIAL_AUDITOR' },
];

export const HEALTH_PARTNERSHIP_ROLES = [
  { value: 'HEALTH_WORKER_HEAD', label: 'HEALTH_WORKER_HEAD' },
  { value: 'HEALTH_WORKER_MANAGER', label: 'HEALTH_WORKER_MANAGER' },
  { value: 'HEALTH_WORKER_RESEARCHER', label: 'HEALTH_WORKER_RESEARCHER' },
  { value: 'HEALTH_WORKER_ASSISTANT', label: 'HEALTH_WORKER_ASSISTANT' },
];

export const LOGISTICS_PARTNERSHIP_ROLES = [
  { value: 'DRIVER', label: 'DRIVER' },
  { value: 'DELIVERY_AGENT', label: 'DELIVERY_AGENT' },
  { value: 'STOCK_OWNER', label: 'STOCK_OWNER' },
  { value: 'STOCK_MANAGER', label: 'STOCK_MANAGER' },
  { value: 'STOCK_ASSISTANT', label: 'STOCK_ASSISTANT' },
];

export const RISK_MANAGEMENT_PARTNERSHIP_ROLES = [
  { value: 'RISK_MANAGEMENT_HEAD', label: 'RISK_MANAGEMENT_HEAD' },
  { value: 'RISK_MANAGEMENT_MANAGER', label: 'RISK_MANAGEMENT_MANAGER' },
  { value: 'RISK_MANAGEMENT_ASSISTANT', label: 'RISK_MANAGEMENT_ASSISTANT' },
];

export const JOB_POSITION_PERMISSIONS_ADMIN = [
  { value: 'CREATE_AS_ADMIN', label: 'Create admin' },
  { value: 'VIEW_AS_ADMIN', label: 'View as admin' },
  { value: 'UPDATE_AS_ADMIN', label: 'Update as admin' },
  { value: 'DELETE_AS_ADMIN', label: 'Delete as admin' },
];

export const JOB_POSITION_PERMISSIONS = [
  { value: 'CREATE_POSITION', label: 'Create positions' },
  { value: 'VIEW_OWN_POSITION', label: 'View own positions' },
  { value: 'VIEW_ANY_POSITION', label: 'View any positions' },
  { value: 'UPDATE_OWN_POSITION', label: 'Update own positions' },
  { value: 'UPDATE_ANY_POSITION', label: 'Update any positions' },
  { value: 'DELETE_OWN_POSITION', label: 'Delete own positions' },
  { value: 'DELETE_ANY_POSITION', label: 'Delete any positions' },
  { value: 'CREATE_PRODUCT', label: 'Create products' },
  { value: 'VIEW_OWN_PRODUCT', label: 'View own products' },
  { value: 'VIEW_ANY_PRODUCT', label: 'View any products' },
  { value: 'UPDATE_OWN_PRODUCT', label: 'Update own products' },
  { value: 'UPDATE_ANY_PRODUCT', label: 'Update any products' },
  { value: 'DELETE_OWN_PRODUCT', label: 'Delete own products' },
  { value: 'DELETE_ANY_PRODUCT', label: 'Delete any products' },
  { value: 'CREATE_SERVICE', label: 'Create services' },
  { value: 'VIEW_OWN_SERVICE', label: 'View own services' },
  { value: 'VIEW_ANY_SERVICE', label: 'View any services' },
  { value: 'UPDATE_OWN_SERVICE', label: 'Update own services' },
  { value: 'UPDATE_ANY_SERVICE', label: 'Update any services' },
  { value: 'DELETE_OWN_SERVICE', label: 'Delete own services' },
  { value: 'DELETE_ANY_SERVICE', label: 'Delete any services' },
  { value: 'CREATE_NEED', label: 'Create needs' },
  { value: 'VIEW_OWN_NEED', label: 'View own needs' },
  { value: 'VIEW_ANY_NEED', label: 'View any needs' },
  { value: 'UPDATE_OWN_NEED', label: 'Update own needs' },
  { value: 'UPDATE_ANY_NEED', label: 'Update any needs' },
  { value: 'DELETE_OWN_NEED', label: 'Delete own needs' },
  { value: 'DELETE_ANY_NEED', label: 'Delete any needs' },
  { value: 'CREATE_JOB_PROPOSAL', label: 'Create job proposals' },
  { value: 'VIEW_OWN_JOB_PROPOSAL', label: 'View own job proposals' },
  { value: 'VIEW_ANY_JOB_PROPOSAL', label: 'View any job proposals' },
  { value: 'UPDATE_OWN_JOB_PROPOSAL', label: 'Update own job proposals' },
  { value: 'UPDATE_ANY_JOB_PROPOSAL', label: 'Update any job proposals' },
  { value: 'DELETE_OWN_JOB_PROPOSAL', label: 'Delete own job proposals' },
  { value: 'DELETE_ANY_JOB_PROPOSAL', label: 'Delete any job proposals' },
  { value: 'CREATE_ALERT', label: 'Create alerts' },
  { value: 'VIEW_OWN_ALERT', label: 'View own alerts' },
  { value: 'VIEW_ANY_ALERT', label: 'View any alerts' },
  { value: 'UPDATE_OWN_ALERT', label: 'Update own alerts' },
  { value: 'UPDATE_ANY_ALERT', label: 'Update any alerts' },
  { value: 'DELETE_OWN_ALERT', label: 'Delete own alerts' },
  { value: 'DELETE_ANY_ALERT', label: 'Delete any alerts' },
  { value: 'CREATE_QUOTATION', label: 'Create quotations' },
  { value: 'VIEW_OWN_QUOTATION', label: 'View own quotations' },
  { value: 'VIEW_ANY_QUOTATION', label: 'View any quotations' },
  { value: 'UPDATE_OWN_QUOTATION', label: 'Update own quotations' },
  { value: 'UPDATE_ANY_QUOTATION', label: 'Update any quotations' },
  { value: 'DELETE_OWN_QUOTATION', label: 'Delete own quotations' },
  { value: 'DELETE_ANY_QUOTATION', label: 'Delete any quotations' },
];

export const CATEGORIES = [
  { value: 'advertising', label: 'Utilities_Advertising' },
  { value: 'agriculture', label: 'Utilities_Agriculture' },
  { value: 'bank_industry', label: 'Utilities_Bank_Industry' },
  { value: 'communications_industry', label: 'Utilities_Communications' },
  { value: 'construction_industry', label: 'Utilities_Construction' },
  { value: 'creative_industries', label: 'Utilities_Creative_Industries' },
  { value: 'education', label: 'Utilities_Education' },
  { value: 'electronics_industry', label: 'Utilities_Electronics_Industry' },
  { value: 'energy_industry', label: 'Utilities_Energy_Industry' },
  { value: 'entertainment_industry', label: 'Utilities_Entertainment' },
  { value: 'farming', label: 'Utilities_Farming' },
  { value: 'fashion', label: 'Utilities_Fashion' },
  { value: 'finance', label: 'Utilities_Finance' },
  { value: 'food_industry', label: 'Utilities_Food_Industry' },
  { value: 'football_five_industry', label: 'Utilities_Football_Five_Industry' },
  { value: 'green_industry', label: 'Utilities_Green' },
  { value: 'health_industry', label: 'Utilities_Health' },
  { value: 'heavy_industry', label: 'Utilities_Heavy' },
  { value: 'hospitality_industry', label: 'Utilities_Hospitality_Industry' },
  { value: 'human_resources_industry', label: 'Utilities_Human_Resources_Industry' },
  { value: 'information_industry', label: 'Utilities_Information_Industry' },
  { value: 'information_technology', label: 'Utilities_Information_Technology' },
  { value: 'infrastructure', label: 'Utilities_Infrastructure' },
  { value: 'it_industry', label: 'Utilities_IT_Industry' },
  { value: 'logistics_industry', label: 'Utilities_Logistics_Industry' },
  { value: 'light_industry', label: 'Utilities_Light_Industry' },
  { value: 'manufacturing', label: 'Utilities_Manufacturing' },
  { value: 'materials', label: 'Utilities_Materials' },
  { value: 'music_industry', label: 'Utilities_Music_Industry' },
  { value: 'news_medias_industry', label: 'Utilities_News_Medias' },
  { value: 'pharmaceutical_industry', label: 'Utilities_Pharmaceutical_Industry' },
  { value: 'primary_industry', label: 'Utilities_Primary' },
  { value: 'public_industry', label: 'Utilities_Public_Industry' },
  { value: 'publishing_industry', label: 'Utilities_Publishing_Industry' },
  { value: 'retail', label: 'Utilities_Retail' },
  { value: 'robotics', label: 'Utilities_Robotics' },
  { value: 'secondary_industry', label: 'Utilities_Secondary' },
  { value: 'security_industry', label: 'Utilities_Security_Service' },
  { value: 'service_industry', label: 'Utilities_Service' },
  { value: 'space_industry', label: 'Utilities_Space' },
  { value: 'sport_industry', label: 'Utilities_Sport' },
  { value: 'technology_industry', label: 'Utilities_Technology' },
  { value: 'telecom', label: 'Utilities_Telecom' },
  { value: 'tertiary_sector', label: 'Utilities_Tertiary_Sector' },
  { value: 'textiles_industry', label: 'Utilities_Textiles_Industry' },
  { value: 'tourism_industry', label: 'Utilities_Tourism' },
  { value: 'transportation_industry', label: 'Utilities_Transportation_Industry' },
  { value: 'travel_industry', label: 'Utilities_Travel' },
  { value: 'legal_services', label: 'Utilities_Legal_Services' },
  { value: 'consulting', label: 'Utilities_Consulting' },
  { value: 'real_estate', label: 'Utilities_Real_Estate' },
  { value: 'insurance', label: 'Utilities_Insurance' },
  { value: 'waste_management', label: 'Utilities_Waste_Management' },
  { value: 'automotive_industry', label: 'Utilities_Automotive_Industry' },
  { value: 'marine_industry', label: 'Utilities_Marine_Industry' },
  { value: 'biotechnology', label: 'Utilities_Biotechnology' },
  { value: 'media_broadcasting', label: 'Utilities_Media_Broadcasting' },
  { value: 'public_relations', label: 'Utilities_Public_Relations' },
  { value: 'event_management', label: 'Utilities_Event_Management' },
  { value: 'chemical_industry', label: 'Utilities_Chemical_Industry' },
  { value: 'metals_industry', label: 'Utilities_Metals_Industry' },
  { value: 'textile_industry', label: 'Utilities_Textile_Industry' },
  { value: 'plastics_industry', label: 'Utilities_Plastics_Industry' },
  { value: 'paper_pulp_industry', label: 'Utilities_Paper_Pulp_Industry' },
  { value: 'ceramics_industry', label: 'Utilities_Ceramics_Industry' },
  { value: 'glass_industry', label: 'Utilities_Glass_Industry' },
  { value: 'rubber_industry', label: 'Utilities_Rubber_Industry' },
  { value: 'wood_timber_industry', label: 'Utilities_Wood_Timber_Industry' },
  { value: 'aerospace_industry', label: 'Utilities_Aerospace_Industry' },
  { value: 'defense_industry', label: 'Utilities_Defense_Industry' },
  { value: 'mining_industry', label: 'Utilities_Mining_Industry' },
  { value: 'water_treatment_industry', label: 'Utilities_Water_Treatment_Industry' },
  { value: 'renewable_energy_industry', label: 'Utilities_Renewable_Energy_Industry' },
  { value: 'chemical_process_equipment', label: 'Utilities_Chemical_Process_Equipment' },
  { value: 'packaging_industry', label: 'Utilities_Packaging_Industry' },
  { value: 'pharmaceutical_manufacturing', label: 'Utilities_Pharmaceutical_Manufacturing' },
  { value: 'hvac_industry', label: 'Utilities_HVAC_Industry' },
  { value: 'instrumentation_control_systems', label: 'Utilities_Instrumentation_Control_Systems' },
  { value: 'robotics_automation_industry', label: 'Utilities_Robotics_Automation_Industry' },
];

export const SUBCATEGORIES = [
  { value: 'aerospace_company', label: 'Aerospace Company' },
  { value: 'agriculture_company', label: 'Agriculture Company' },
  { value: 'Auditor Partnership', label: 'Auditor Partnership' },

  { value: 'Automotive_company', label: 'Automotive Company' },
  { value: 'Biotechnology_company', label: 'Biotechnology Company' },
  { value: 'cargo_freight_company', label: 'Cargo & Freight Company' },
  { value: 'cause', label: 'Cause' },
  { value: 'chemical_company', label: 'Chemical Company' },
  { value: 'college_university', label: 'College & University' },
  { value: 'Commodity Compliance Partnership', label: 'Utilities_Commodity_Compliance_Partnership' },
  { value: 'community_organization', label: 'Community Organization' },
  { value: 'community_services', label: 'Community Services' },
  { value: 'computer_company', label: 'Computer Company' },
  { value: 'consulting_agency', label: 'Consulting Agency' },
  { value: 'elementary_school', label: 'Elementary School' },
  { value: 'energy_company', label: 'Energy Company' },
  { value: 'finance_company', label: 'Finance Company' },
  { value: 'Fintech Partnership', label: 'Fintech Partnership' },
  { value: 'Fivezer Partnership', label: 'Fivezer Partnership' },

  { value: 'food_beverage_company', label: 'Food & Beverage Company' },
  { value: 'football_league', label: 'Football league' },
  { value: 'government_organization', label: 'Government Organization' },
  { value: 'health_beauty_company', label: 'Health/Beauty Company' },
  { value: 'Health Partnership', label: 'Health Partnership' },

  { value: 'high_school', label: 'High School' },
  { value: 'industrial_company', label: 'Industrial Company' },
  { value: 'insurance_company', label: 'Insurance Company' },
  { value: 'internet_company', label: 'Internet Company' },
  { value: 'labor_union', label: 'Labor Union' },
  { value: 'legal_company', label: 'Legal Company' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'Logistics Partnership', label: 'Logistics Partnership' },

  { value: 'media_news_company', label: 'Media/News Company' },
  { value: 'medical_company', label: 'Medical Company' },
  { value: 'middle_school', label: 'Middle School' },
  { value: 'mining_company', label: 'Mining Company' },
  {
    value: 'non_governmental_organization',
    label: 'Non-Governmental Organization',
  },
  { value: 'non_profit_organization', label: 'Non-Profit Organization' },
  { value: 'organization', label: 'Organization' },
  { value: 'political_organization', label: 'Political Organization' },
  { value: 'political_party', label: 'Political Party' },
  { value: 'preschool', label: 'Preschool' },
  { value: 'religious_organization', label: 'Religious Organization' },
  { value: 'retail_company', label: 'Retail Company' },
  { value: 'Retail Investor Partnership', label: 'Retail Investor Partnership' },
  { value: 'Risk Management Partnership', label: 'Risk Management Partnership' },

  { value: 'school', label: 'School' },
  { value: 'science', label: 'Science' },
  { value: 'Security Partnership', label: 'Security Partnership' },

  { value: 'statistics', label: 'Statistics' },
  { value: 'Talent_Resources_Partnership', label: 'Talent Resources Partnership' },
  { value: 'technology_engineering', label: 'Technology & Engineering' },
  { value: 'telecommunication_company', label: 'Telecommunication Company' },
  { value: 'tobacco_company', label: 'Tobacco Company' },
  { value: 'travel_company', label: 'Travel Company' },
];

export const PRODUCT_CATEGORIES = [
  { value: 'agriculture_food', label: 'Utilities_Agriculture_Food' },
  { value: 'apparel', label: 'Utilities_Apparel' },
  { value: 'appliances', label: 'Utilities_Appliances' },
  { value: 'apps_games', label: 'Utilities_Apps_Games' },
  { value: 'arts_crafts_sewing', label: 'Utilities_Arts_Crafts_Sewing' },
  { value: 'automotive_parts_accessories', label: 'Utilities_Automotive_Parts_Accessories' },
  { value: 'baby', label: 'Utilities_Baby' },
  { value: 'beauty_personal_care', label: 'Utilities_Beauty_Personal_Care' },
  { value: 'books', label: 'Utilities_Books' },
  { value: 'cds_vinyl', label: 'Utilities_CDs_Vinyl' },
  { value: 'cell_phones_accessories', label: 'Utilities_Cell_Phones_Accessories' },
  { value: 'clothing_shoes_jewelry', label: 'Utilities_Clothing_Shoes_and_Jewelry' },
  { value: 'collectibles_fine_art', label: 'Utilities_Collectibles_Fine_Art' },
  { value: 'computers', label: 'Utilities_Computers' },
  { value: 'electronics', label: 'Utilities_Electronics' },
  { value: 'fashion_accessories', label: 'Utilities_Fashion_Accessories' },
  { value: 'food_beverage', label: 'Utilities_Food_Beverage' },
  { value: 'furniture', label: 'Utilities_Furniture' },
  { value: 'gifts_crafts', label: 'Utilities_Gifts_Crafts' },
  { value: 'garden_outdoor', label: 'Utilities_Garden_Outdoor' },
  { value: 'health_household_baby_care', label: 'Utilities_Health_Household_Baby_Care' },
  { value: 'home_appliances', label: 'Utilities_Home_Appliances' },
  { value: 'home_kitchen', label: 'Utilities_Home_Kitchen' },
  { value: 'home_garden', label: 'Utilities_Home_Garden' },
  { value: 'industrial_scientific', label: 'Utilities_Industrial_Scientific' },
  { value: 'lights_lighting', label: 'Utilities_Lights_Lighting' },
  { value: 'luggage_travel_gear', label: 'Utilities_Luggage_Travel_Gear' },
  { value: 'machinery', label: 'Utilities_Machinery' },
  { value: 'movies_tv', label: 'Utilities_Movies_TV' },
  { value: 'musical_instruments', label: 'Utilities_Musical_Instruments' },
  { value: 'office_products', label: 'Utilities_Office_Products' },
  { value: 'packaging_printing', label: 'Utilities_Packaging_Printing' },
  { value: 'pet_supplies', label: 'Utilities_Pet_Supplies' },
  { value: 'shoes_accessories', label: 'Utilities_Shoes_Accessories' },
  { value: 'sports_entertainment', label: 'Utilities_Sports_Entertainment' },
  { value: 'sports_outdoors', label: 'Utilities_Sports_Outdoors' },
  { value: 'textiles_leather_products', label: 'Utilities_Textiles_Leather_Products' },
  { value: 'timepieces_jewelry_eyewear', label: 'Utilities_Timepieces_Jewelry_Eyewear' },
  { value: 'tools_home_improvement', label: 'Utilities_Tools_Home_Improvement' },
  { value: 'toys_hobbies', label: 'Utilities_Toys_Hobbies' },
  { value: 'toys_games', label: 'Utilities_Toys_Games' },
  { value: 'video_games', label: 'Utilities_Video_Games' },
  { value: 'watches', label: 'Utilities_Watches' },
  { value: 'personal_care_appliances', label: 'Utilities_Personal_Care_Appliances' },
  { value: 'baby_clothing', label: 'Utilities_Baby_Clothing' },
  { value: 'software', label: 'Utilities_Software' },
  { value: 'craft_beverages', label: 'Utilities_Craft_Beverages' },
  { value: 'outdoor_recreation', label: 'Utilities_Outdoor_Recreation' },
  { value: 'stationery', label: 'Utilities_Stationery' },
  { value: 'home_decor', label: 'Utilities_Home_Decor' },
  { value: 'adhesives_sealants', label: 'Utilities_Adhesives_Sealants' },
  { value: 'bearings', label: 'Utilities_Bearings' },
  { value: 'conveyor_systems', label: 'Utilities_Conveyor_Systems' },
  { value: 'electrical_components', label: 'Utilities_Electrical_Components' },
  { value: 'fasteners', label: 'Utilities_Fasteners' },
  { value: 'filtration_systems', label: 'Utilities_Filtration_Systems' },
  { value: 'hydraulic_equipment', label: 'Utilities_Hydraulic_Equipment' },
  { value: 'industrial_coatings', label: 'Utilities_Industrial_Coatings' },
  { value: 'instrumentation', label: 'Utilities_Instrumentation' },
  { value: 'lubricants', label: 'Utilities_Lubricants' },
  { value: 'machining_tools', label: 'Utilities_Machining_Tools' },
  { value: 'motors', label: 'Utilities_Motors' },
  { value: 'pneumatic_equipment', label: 'Utilities_Pneumatic_Equipment' },
  { value: 'power_tools', label: 'Utilities_Power_Tools' },
  { value: 'pumps', label: 'Utilities_Pumps' },
  { value: 'raw_materials', label: 'Utilities_Raw_Materials' },
  { value: 'safety_equipment', label: 'Utilities_Safety_Equipment' },
  { value: 'sensors', label: 'Utilities_Sensors' },
  { value: 'valves', label: 'Utilities_Valves' },
  { value: 'welding_equipment', label: 'Utilities_Welding_Equipment' },
];

export const PRODUCT_SUBCATEGORIES = [
  { value: 'appliances', label: 'Appliances' },
  { value: 'skin_cares', label: 'Skin cares' },
];

export const SERVICE_CATEGORIES = [
  { value: 'accounting', label: 'Utilities_Accounting' },
  { value: 'administrative', label: 'Utilities_Administrative' },
  { value: 'assistance_advertising', label: 'Utilities_Assistance_Advertising' },
  { value: 'android_development', label: 'Utilities_Android_Development' },
  { value: 'animation_architecture', label: 'Utilities_Animation_Architecture' },
  { value: 'audiology', label: 'Utilities_Audiology' },
  { value: 'audio_engineering', label: 'Utilities_Audio_Engineering' },
  { value: 'auto_insurance', label: 'Utilities_Auto_Insurance' },
  { value: 'bankruptcy_law', label: 'Utilities_Bankruptcy_Law' },
  { value: 'bartending', label: 'Utilities_Bartending' },
  { value: 'blogging', label: 'Utilities_Blogging' },
  { value: 'bookkeeping', label: 'Utilities_Bookkeeping' },
  { value: 'budgeting', label: 'Utilities_Budgeting' },
  { value: 'business_analytics', label: 'Utilities_Business_Analytics' },
  { value: 'business_law', label: 'Utilities_Business_Law' },
  { value: 'catering_service', label: 'Utilities_Catering_Service' },
  { value: 'coaching_mentoring', label: 'Utilities_Coaching_Mentoring' },
  { value: 'commercial_insurance', label: 'Utilities_Commercial_Insurance' },
  { value: 'commercial_real_estate', label: 'Utilities_Commercial_Real_Estate' },
  { value: 'computer_networking', label: 'Utilities_Computer_Networking' },
  { value: 'computer_repair', label: 'Utilities_Computer_Repair' },
  { value: 'consulting', label: 'Utilities_Consulting' },
  { value: 'content_strategy', label: 'Utilities_Content_Strategy' },
  { value: 'copywriting', label: 'Utilities_Copywriting' },
  { value: 'corporate_events', label: 'Utilities_Corporate_Events' },
  { value: 'corporate_law', label: 'Utilities_Corporate_Law' },
  { value: 'customer_service', label: 'Utilities_Customer_Service' },
  { value: 'customer_support', label: 'Utilities_Customer_Support' },
  { value: 'cybersecurity', label: 'Utilities_Cybersecurity' },
  { value: 'digital_marketing', label: 'Utilities_Digital_Marketing' },
  { value: 'djing', label: 'Utilities_DJing' },
  { value: 'dui_law', label: 'Utilities_DUI_Law' },
  { value: 'editing', label: 'Utilities_Editing' },
  { value: 'email_marketing', label: 'Utilities_Email_Marketing' },
  { value: 'event_photography', label: 'Utilities_Event_Photography' },
  { value: 'event_planning', label: 'Utilities_Event_Planning' },
  { value: 'executive_coaching', label: 'Utilities_Executive_Coaching' },
  { value: 'family_law', label: 'Utilities_Family_Law' },
  { value: 'file_management', label: 'Utilities_File_Management' },
  { value: 'filing', label: 'Utilities_Filing' },
  { value: 'financial_accounting', label: 'Utilities_Financial_Accounting' },
  { value: 'financial_advisory', label: 'Utilities_Financial_Advisory' },
  { value: 'financial_analysis', label: 'Utilities_Financial_Analysis' },
  { value: 'financial_planning', label: 'Utilities_Financial_Planning' },
  { value: 'financial_reporting', label: 'Utilities_Financial_Reporting' },
  { value: 'ghostwriting', label: 'Utilities_Ghostwriting' },
  { value: 'grant_writing', label: 'Utilities_Grant_Writing' },
  { value: 'graphic_design', label: 'Utilities_Graphic_Design' },
  { value: 'growth_marketing', label: 'Utilities_Growth_Marketing' },
  { value: 'headshot_photography', label: 'Utilities_Headshot_Photography' },
  { value: 'health_insurance', label: 'Utilities_Health_Insurance' },
  { value: 'healthcare_consulting', label: 'Utilities_Healthcare_Consulting' },
  { value: 'home_networking', label: 'Utilities_Home_Networking' },
  { value: 'home_owners_insurance', label: 'Utilities_Home_Owners_Insurance' },
  { value: 'hr_consulting', label: 'Utilities_HR_Consulting' },
  { value: 'human_resources', label: 'Utilities_Human_Resources' },
  { value: 'illustration', label: 'Utilities_Illustration' },
  { value: 'immigration_law', label: 'Utilities_Immigration_Law' },
  { value: 'information_management', label: 'Utilities_Information_Management' },
  { value: 'information_security', label: 'Utilities_Information_Security' },
  { value: 'insurance', label: 'Utilities_Insurance' },
  { value: 'interaction_design', label: 'Utilities_Interaction_Design' },
  { value: 'interior_design', label: 'Utilities_Interior_Design' },
  { value: 'ios_development', label: 'Utilities_iOS_Development' },
  { value: 'labor_employment_law', label: 'Utilities_Labor_and_Employment_Law' },
  { value: 'lead_generation', label: 'Utilities_Lead_Generation' },
  { value: 'leadership_development', label: 'Utilities_Leadership_Development' },
  { value: 'life_coaching', label: 'Utilities_Life_Coaching' },
  { value: 'life_insurance', label: 'Utilities_Life_Insurance' },
  { value: 'loans', label: 'Utilities_Loans' },
  { value: 'logo_design', label: 'Utilities_Logo_Design' },
  { value: 'management_consulting', label: 'Utilities_Management_Consulting' },
  { value: 'manufacturing', label: 'Utilities_Manufacturing' },
  { value: 'market_research', label: 'Utilities_Market_Research' },
  { value: 'market_strategy', label: 'Utilities_Market_Strategy' },
  { value: 'marketing', label: 'Utilities_Marketing' },
  { value: 'mobile_marketing', label: 'Utilities_Mobile_Marketing' },
  { value: 'mortgage_lending', label: 'Utilities_Mortgage_Lending' },
  { value: 'nature_photography', label: 'Utilities_Nature_Photography' },
  { value: 'negotiation', label: 'Utilities_Negotiation' },
  { value: 'network_support', label: 'Utilities_Network_Support' },
  { value: 'nonprofit_consulting', label: 'Utilities_Nonprofit_Consulting' },
  { value: 'notary_online_research', label: 'Utilities_Notary_Online_Research' },
  { value: 'outsourcing', label: 'Utilities_Outsourcing' },
  { value: 'packaging_design', label: 'Utilities_Packaging_Design' },
  { value: 'personal_injury_law', label: 'Utilities_Personal_Injury_Law' },
  { value: 'portrait_photography', label: 'Utilities_Portrait_Photography' },
  { value: 'pricing_strategy', label: 'Utilities_Pricing_Strategy' },
  { value: 'print_design', label: 'Utilities_Print_Design' },
  { value: 'product_marketing', label: 'Utilities_Product_Marketing' },
  { value: 'program_management', label: 'Utilities_Program_Management' },
  { value: 'project_management', label: 'Utilities_Project_Management' },
  { value: 'public_speaking', label: 'Utilities_Public_Speaking' },
  { value: 'publicity', label: 'Utilities_Publicity' },
  { value: 'real_estate', label: 'Utilities_Real_Estate' },
  { value: 'recruiting', label: 'Utilities_Recruiting' },
  { value: 'relationship_coaching', label: 'Utilities_Relationship_Coaching' },
  { value: 'residential_real_estate', label: 'Utilities_Residential_Real_Estate' },
  { value: 'sales', label: 'Utilities_Sales' },
  { value: 'sales_training', label: 'Utilities_Sales_Training' },
  { value: 'scriptwriting', label: 'Utilities_Scriptwriting' },
  { value: 'seo', label: 'Utilities_SEO' },
  { value: 'social_media_marketing', label: 'Utilities_Social_Media_Marketing' },
  { value: 'social_media_strategy', label: 'Utilities_Social_Media_Strategy' },
  { value: 'software_development', label: 'Utilities_Software_Development' },
  { value: 'software_testing', label: 'Utilities_Software_Testing' },
  { value: 'strategic_planning', label: 'Utilities_Strategic_Planning' },
  { value: 'tax_law', label: 'Utilities_Tax_Law' },
  { value: 'tax_preparation', label: 'Utilities_Tax_Preparation' },
  { value: 'team_building', label: 'Utilities_Team_Building' },
  { value: 'technical_support', label: 'Utilities_Technical_Support' },
  { value: 'technical_writing', label: 'Utilities_Technical_Writing' },
  { value: 'telecommunications', label: 'Utilities_Telecommunications' },
  { value: 'trade_shows', label: 'Utilities_Trade_Shows' },
  { value: 'training', label: 'Utilities_Training' },
  { value: 'translation', label: 'Utilities_Translation' },
  { value: 'typing', label: 'Utilities_Typing' },
  { value: 'user_experience_design', label: 'Utilities_User_Experience_Design' },
  { value: 'user_experience_writing', label: 'Utilities_User_Experience_Writing' },
  { value: 'ux_research', label: 'Utilities_UX_Research' },
  { value: 'video_animation', label: 'Utilities_Video_Animation' },
  { value: 'video_editing', label: 'Utilities_Video_Editing' },
  { value: 'video_production', label: 'Utilities_Video_Production' },
  { value: 'videography', label: 'Utilities_Videography' },
  { value: 'virtual_assistance', label: 'Utilities_Virtual_Assistance' },
  { value: 'visual_design', label: 'Utilities_Visual_Design' },
  { value: 'wealth_management', label: 'Utilities_Wealth_Management' },
  { value: 'web_design', label: 'Utilities_Web_Design' },
  { value: 'web_development', label: 'Utilities_Web_Development' },
  { value: 'wedding_photography', label: 'Utilities_Wedding_Photography' },
  { value: 'wedding_planning', label: 'Utilities_Wedding_Planning' },
  { value: 'wordpress_design', label: 'Utilities_WordPress_Design' },
  { value: 'writing', label: 'Utilities_Writing' },
  { value: 'community_outreach', label: 'Utilities_Community_Outreach' },
  { value: 'environmental_consulting', label: 'Utilities_Environmental_Consulting' },
  { value: 'legal_consulting', label: 'Utilities_Legal_Consulting' },
  { value: 'risk_management', label: 'Utilities_Risk_Management' },
  { value: 'sustainability_consulting', label: 'Utilities_Sustainability_Consulting' },
  { value: 'industrial_automation', label: 'Industrial_Automation' },
  { value: 'manufacturing_consulting', label: 'Manufacturing_Consulting' },
  { value: 'process_engineering', label: 'Process_Engineering' },
  { value: 'quality_control', label: 'Quality_Control' },
  { value: 'safety_engineering', label: 'Safety_Engineering' },
  { value: 'supply_chain_management', label: 'Supply_Chain_Management' },
  { value: 'industrial_equipment_maintenance', label: 'Industrial_Equipment_Maintenance' },
  { value: 'facility_management', label: 'Facility_Management' },
  { value: 'energy_management', label: 'Energy_Management' },
  { value: 'environmental_compliance', label: 'Environmental_Compliance' },
  { value: 'industrial_design', label: 'Industrial_Design' },
  { value: 'logistics_management', label: 'Logistics_Management' },
  { value: 'materials_management', label: 'Materials_Management' },
  { value: 'plant_engineering', label: 'Plant_Engineering' },
  { value: 'project_engineering', label: 'Project_Engineering' },
  { value: 'industrial_training', label: 'Industrial_Training' },
  { value: 'maintenance_planning', label: 'Maintenance_Planning' },
];

export const SERVICE_SUBCATEGORIES = [{ value: 'accounting', label: 'Accounting' }];

export const ENTITY_TYPES = [
  { value: 'company', label: 'Company' },
  { value: 'service', label: 'Service' },
  { value: 'product', label: 'Product' },
];

export const PRODUCT_UNITS = [
  { value: 'subheader', label: 'UNIVERSAL_UNITS' },
  { value: 'ac', label: 'Acre' },
  { value: 'bbl', label: 'Barrel' },
  { value: 'cm', label: 'Centimeter' },
  { value: 'c', label: 'Cup' },
  { value: 'dp', label: 'Drop' },
  { value: 'ft', label: 'Foot' },
  { value: 'gl', label: 'Gallon' },
  { value: 'gr', label: 'Gram' },
  { value: 'ha', label: 'Hectare' },
  { value: 'l', label: 'Liter' },
  { value: 'cl', label: 'Centiliter' },
  { value: 'ml', label: 'Milliliter' },
  { value: 'in', label: 'Inch' },
  { value: 'kg', label: 'Kilogram' },
  { value: 'km', label: 'Kilometer' },
  { value: 'm', label: 'Meter' },
  { value: 'mg', label: 'Milligram' },
  { value: 'pc', label: 'Piece' },
  { value: 'qt', label: 'Quintal' },
  { value: 'scm', label: 'Square centimeter' },
  { value: 'sf', label: 'Square foot' },
  { value: 'km2', label: 'Square kilometer' },
  { value: 'sqm', label: 'Square meter' },
  { value: 'sqml', label: 'Square millimeter' },
  { value: 'sqi', label: 'Square inch' },
  { value: 'mi2', label: 'Square mile' },
  { value: 'sqyd', label: 'Square yard' },
  { value: 't', label: 'Tonne' },
  { value: 'tspn', label: 'Teaspoon' },
];

export const CLOTHING_UNITS = [
  { value: 'subheader', label: 'NEW_BORN_CLOTHING_SIZES' },
  { value: '', label: '' },
  { value: 'subheader', label: 'BABY_GIRLS_CLOTHING_SIZES' },
  { value: '', label: '' },
  { value: 'subheader', label: 'BABY_BOYS_CLOTHING_SIZES' },
  { value: '', label: '' },
  { value: 'subheader', label: 'GIRLS_CLOTHING_SIZES' },
  { value: '', label: '' },
  { value: 'subheader', label: 'BOYS_CLOTHING_SIZES' },
  { value: '', label: '' },
  { value: 'subheader', label: 'WOMEN_CLOTHING_SIZES' },
  { value: '', label: '' },
  { value: 'subheader', label: 'MEN_CLOTHING_SIZES' },
  {
    value: '35_36_eu_shirt_men',
    label: '35/36 shirt (chest: 80-84cm, waist: 68-72cm)',
  },
  {
    value: '37_38_eu_shirt_men',
    label: '37/38 shirt (chest: 88-92cm, waist: 76-80cm)',
  },
  {
    value: '39_40_eu_shirt_men',
    label: '39/40 shirt (chest: 96-100cm, waist: 84-88cm)',
  },
  {
    value: '41_42_eu_shirt_men',
    label: '41/42 shirt (chest: 104-108cm, waist: 92-96cm)',
  },
  {
    value: '41_42_eu_shirt_men',
    label: '41/42 shirt (chest: 112-116cm, waist: 100-104cm)',
  },
  {
    value: '45_46_eu_shirt_men',
    label: '45/46 shirt (chest: 120-124cm, waist: 108-112cm)',
  },
  {
    value: '42_eu_jacket_men',
    label: '42 jacket (height: 177cm, chest: 84cm, waist: 72cm)',
  },
  {
    value: '44_eu_jacket_men',
    label: '44 jacket (height: 178cm, chest: 88cm, waist: 76cm)',
  },
  {
    value: '46_eu_jacket_men',
    label: '46 jacket (height: 179cm, chest: 92cm, waist: 80cm)',
  },
  {
    value: '48_eu_jacket_men',
    label: '48 jacket (height: 180cm, chest: 96cm, waist: 84cm)',
  },
  {
    value: '50_eu_jacket_men',
    label: '50 jacket (height: 181cm, chest: 100cm, waist: 88cm)',
  },
  {
    value: '52_eu_jacket_men',
    label: '52 jacket (height: 182cm, chest: 104cm, waist: 92cm)',
  },
  {
    value: '54_eu_jacket_men',
    label: '54 jacket (height: 183cm, chest: 108cm, waist: 96cm)',
  },
  {
    value: '56_eu_jacket_men',
    label: '56 jacket (height: 184cm, chest: 112cm, waist: 100cm)',
  },
  {
    value: '58_eu_jacket_men',
    label: '58 jacket (height: 185cm, chest: 116cm, waist: 104cm)',
  },
  {
    value: '60_eu_jacket_men',
    label: '60 jacket (height: 186cm, chest: 120cm, waist: 108cm)',
  },
  {
    value: '62_eu_jacket_men',
    label: '62 jacket (height: 187cm, chest: 124cm, waist: 112cm)',
  },
  { value: '56_eu_hat_men', label: '56cm hat (circumference)' },
  { value: '58_eu_hat_men', label: '58cm hat (circumference)' },
  { value: '60_eu_hat_men', label: '60cm hat (circumference)' },
  { value: '62_eu_hat_men', label: '62cm hat (circumference)' },
];

// MEN
// SHIRTS
// size 35/36	37/38	39/40	41/42	41/42	45/46
// Chest (cm)	80-84	88-92	96-100	104-108	112-116	120-124
// Waist (cm)	68-72	76-80	84-88	92-96	100-104	108-112
// JACKET
// size 42	44	46	48	50	52	54	56	58	60	62
// Height cm	177	178	179	180	181	182	183	184	185	186	187
// Chest cm	84	88	92	96	100	104	108	112	116	120	124
// Waist cm	72	76	80	84	88	92	96	100	104	108	112
// Neckline cm	35	36	37	38	39	40	41	42	43	44	45
// Armlength cm	62	62.5	63	63.5	64	64.5	65	65.5	66	66.5	67
// HEAD
// Head Circumference (cm)	56	58	60	62
// TROUSSERS
// size 42	44-46	48-50	52-54	56-58	60-62
// Waist cm	72	76-80	84-88	92-96	100-104	110-112
// Hips cm	90	93-96	99-102	105-108	111-114	117-120
// Inside leg cm	81	81-82	82-83	83-84	85-85	86-86

// // WOMEN
// size 32	34	36	38	40	42	44	46	48	50	52	54	56	58	60	62
// bust 76	80	84	88	92	96	100	104	110	116	122	128	134	140	146	152
// waist 60 64	68	72	76	80	85	90	96	102	108	114	121	128	135	142
// hips 84	88	92	96	99	102	105	108	113	118	123	128	134	140	146	152
// Neckline 34,75	35,5	36,25	37	38	39	40	41	42	43	44	45	46	47	48	49
// Arm lenght59,4	59,6	59,8	60	60,2	60,4	60,6	60,8	61	61,2	61,4	61,6	61,8	62	62,2	62,4
// Inside leg length	78	78	78	78	78	78	78	78	78	78	78	78	78	78	78	78
// Head Circumference cm	52	54	56	58

export const SHOES_UNITS = [
  { value: 'subheader', label: 'NEW_BORN_SHOES_SIZES' },
  { value: 'subheader', label: '' },
  { value: 'subheader', label: 'BABY_GIRLS_SHOES_SIZES' },
  { value: 'subheader', label: '' },
  { value: 'subheader', label: 'BABY_BOYS_SHOES_SIZES' },
  { value: 'subheader', label: '' },
  { value: 'subheader', label: 'GIRLS_SHOES_SIZES' },
  { value: 'subheader', label: 'BOYS_SHOES_SIZES' },
  { value: '16_eu_shoes_children', label: '16' },
  { value: '17_eu_shoes_children', label: '17' },
  { value: '18_eu_shoes_children', label: '18' },
  { value: '19_eu_shoes_children', label: '19' },
  { value: '20_eu_shoes_children', label: '20' },
  { value: '21_eu_shoes_children', label: '21' },
  { value: '22_eu_shoes_children', label: '22' },
  { value: '23_eu_shoes_children', label: '23' },
  { value: '24_eu_shoes_children', label: '24' },
  { value: '25_eu_shoes_children', label: '25' },
  { value: '26_eu_shoes_children', label: '26' },
  { value: '27_eu_shoes_children', label: '27' },
  { value: '28_eu_shoes_children', label: '28' },
  { value: '29_eu_shoes_children', label: '29' },
  { value: '30_eu_shoes_children', label: '30' },
  { value: '31_eu_shoes_children', label: '31' },
  { value: '32_eu_shoes_children', label: '32' },
  { value: '32_half_eu_shoes_children', label: '32½' },
  { value: '33_eu_shoes_children', label: '33' },
  { value: '34_eu_shoes_children', label: '34' },
  { value: '34_half_eu_shoes_children', label: '34½' },
  { value: '35_eu_shoes_children', label: '35' },
  { value: '35_half_eu_shoes_children', label: '35½' },
  { value: 'subheader', label: 'WOMEN_SHOES_SIZES' },
  { value: '35_eu_shoes_women', label: '35' },
  { value: '35_half_eu_shoes_women', label: '35½' },
  { value: '36_eu_shoes_women', label: '36' },
  { value: '37_eu_shoes_women', label: '37' },
  { value: '37_half_eu_shoes_women', label: '37½' },
  { value: '38_eu_shoes_women', label: '38' },
  { value: '38_half_eu_shoes_women', label: '38½' },
  { value: '39_eu_shoes_women', label: '39' },
  { value: '40_eu_shoes_women', label: '40' },
  { value: '40_half_eu_shoes_women', label: '40½' },
  { value: '41_eu_shoes_women', label: '41' },
  { value: '42_eu_shoes_women', label: '42' },
  { value: '42_half_eu_shoes_women', label: '42½' },
  { value: 'subheader', label: 'MEN_SHOES_SIZES' },
  { value: '37_half_eu_shoes_men', label: '37½' },
  { value: '38_eu_shoes_men', label: '38' },
  { value: '38_half_eu_shoes_men', label: '38½' },
  { value: '39_eu_shoes_men', label: '39' },
  { value: '40_eu_shoes_men', label: '40' },
  { value: '40_half_eu_shoes_men', label: '40½' },
  { value: '41_eu_shoes_men', label: '41' },
  { value: '42_eu_shoes_men', label: '42' },
  { value: '42_half_eu_shoes_men', label: '42½' },
  { value: '43_eu_shoes_men', label: '43' },
  { value: '44_eu_shoes_men', label: '44' },
  { value: '44_half_eu_shoes_men', label: '44½' },
  { value: '45_eu_shoes_men', label: '45' },
  { value: '46_eu_shoes_men', label: '46' },
  { value: '46_half_eu_shoes_men', label: '46½' },
  { value: '47_eu_shoes_men', label: '47' },
  { value: '48_half_eu_shoes_men', label: '48½' },
  { value: '49_half_eu_shoes_men', label: '49½' },
  { value: '51_eu_shoes_men', label: '51' },
];

export const WEIGHT_UNITS = [
  { value: 'gr', label: 'Gram' },
  { value: 'kg', label: 'Kilogram' },
  { value: 'mg', label: 'Milligram' },
  { value: 'qt', label: 'Quintal' },
  { value: 't', label: 'Tonne' },
];

export const LENGTH_UNITS = [
  { value: 'ac', label: 'Acre' },
  { value: 'cm', label: 'Centimeter' },
  { value: 'ft', label: 'Foot' },
  { value: 'ha', label: 'Hectare' },
  { value: 'in', label: 'Inch' },
  { value: 'km', label: 'Kilometer' },
  { value: 'm', label: 'Meter' },
  { value: 'mm', label: 'Millimeter' },
  { value: 'scm', label: 'Square centimeter' },
  { value: 'sf', label: 'Square foot' },
  { value: 'km2', label: 'Square kilometer' },
  { value: 'sqm', label: 'Square meter' },
  { value: 'sqml', label: 'Square millimeter' },
  { value: 'sqi', label: 'Square inch' },
  { value: 'mi2', label: 'Square mile' },
  { value: 'sqyd', label: 'Square yard' },
];

export const SERVICE_UNITS = [
  { value: 'NANOSECOND', label: 'Nanosecond' },
  { value: 'MICROSECOND', label: 'Microsecond' },
  { value: 'MILLISECOND', label: 'Millisecond' },
  { value: 'SECOND', label: 'Second' },
  { value: 'MINUTE', label: 'Minute' },
  { value: 'HOUR', label: 'Hour' },
  { value: 'DAY', label: 'Day' },
  { value: 'WEEK', label: 'Week' },
  { value: 'MONTH', label: 'Month' },
  { value: 'YEAR', label: 'Year' },
  { value: 'DECADE', label: 'Decade' },
  { value: 'CENTURY', label: 'Century' },
];

export const NEED_TYPES = [
  { value: 'BRANDING', label: 'Communication & design' },
  {
    value: 'MARKETING_SALES',
    label: 'Customer experience (marketing & sales)',
  },
  { value: 'FACILITIES', label: 'Facilities' },
  { value: 'FINANCINGS', label: 'Short financings' },
  { value: 'FIRST_NECESSITIES', label: 'First necessities (Urgent)' },
  { value: 'HEALTH', label: 'Health' },
  { value: 'TALENTS', label: 'Human talents' },
  { value: 'INFORMATION', label: 'Information' },
  { value: 'INFRASTRUCTURES', label: 'Infrastructures' },
  { value: 'INVESTORS', label: 'Investors & capitals' },
  { value: 'LOGISTICS', label: 'Logistics' },
  { value: 'PARTNERSHIPS', label: 'Partnerships' },
  { value: 'SPECIFIC', label: 'Specific' },
  { value: 'SUPPLIES_EQUIPMENTS', label: 'Supplies & equipments' },
  { value: 'TOOLS', label: 'Tools' },
  { value: 'EDUCATION', label: 'Trainings' },
];

export const DEAL_TYPES = [
  { value: 'BUSINESS_TO_CONSUMER', label: 'Business to Consumer' },
  { value: 'BUSINESS_TO_BUSINESS', label: 'Business to Business' },
  { value: 'BUSINESS_TO_GOVERNMENT', label: 'Business to Government' },
  { value: 'GOVERNMENT_TO_BUSINESS', label: 'Government to Business' },
  { value: 'GOVERNMENT_TO_GOVERNMENT', label: 'Government to Government' },
];

export const OPPORTUNITY_TYPES = [
  { value: 'SHORT_TERMS', label: 'Short terms' },
  { value: 'START_UP', label: 'Start-up' },
  { value: 'SCALE_UP', label: 'Scale-up' },
  { value: 'GENERATION_WEALTH', label: 'Generation wealth' },
];

export const ALL_LOGISTICS_OPTIONS = [
  { value: 'NONE', label: 'None' },
  { value: 'MENELINKS_LOGISTICS', label: 'Menelinks Logistics' },
];

export const FACILITIES = [
  { value: 'showers', label: 'Showers', icon: <ShowerIcon /> },
  { value: 'scoreboard', label: 'Scoreboard', icon: <ScoreBoardIcon /> },
  {
    value: 'streaming_camera',
    label: 'Streaming camera',
    icon: <GameStreamingIcon />,
  },
  { value: 'sauna', label: 'Sauna', icon: <SaunaIcon /> },
  {
    value: 'changing_room',
    label: 'Changing room',
    icon: <ChangingRoomIcon />,
  },
  { value: 'snack_bar', label: 'Snack bar', icon: <SnackbarIcon /> },
  {
    value: 'security_camera',
    label: 'Security camera',
    icon: <SecurityCameraIcon />,
  },
  {
    value: 'private_locker',
    label: 'Private locker',
    icon: <PrivateLockerIcon />,
  },
  { value: 'steam_room', label: 'Steam room', icon: <SteamRoomIcon /> },
  {
    value: 'rent_equipments',
    label: 'Rent equipments',
    icon: <RentEquipmentsIcon />,
  },
  {
    value: 'sell_equipments',
    label: 'Sell equipments',
    icon: <SellEquipmentsIcon />,
  },
  { value: 'gym', label: 'Gym', icon: <GymRoomIcon /> },
  { value: 'football', label: 'Football', icon: <FootBallIcon /> },
];

export const FIELD_TYPES = [
  { value: 'HYBRID', label: 'Global_Field_Types_1', icon: <FootballFieldIcon /> },
  { value: 'SYNTHETIC', label: 'Global_Field_Types_2', icon: <FootballFieldIcon /> },
  { value: 'ARTIFICIAL_GRASS_2G', label: 'Global_Field_Types_3', icon: <FootballFieldIcon /> },
  { value: 'ARTIFICIAL_GRASS_3G', label: 'Global_Field_Types_4', icon: <FootballFieldIcon /> },
  { value: 'ARTIFICIAL_GRASS_4G', label: 'Global_Field_Types_5', icon: <FootballFieldIcon /> },
  { value: 'ARTIFICIAL_GRASS_5G', label: 'Global_Field_Types_6', icon: <FootballFieldIcon /> },
  { value: 'ARTIFICIAL_GRASS_6G', label: 'Global_Field_Types_7', icon: <FootballFieldIcon /> },
];

export const FIELD_SIZES = [
  { value: 'ONE_ASIDE', label: 'Global_Field_Sizes_1' },
  { value: 'TWO_ASIDE', label: 'Global_Field_Sizes_2' },
  { value: 'THREE_ASIDE', label: 'Global_Field_Sizes_3' },
  { value: 'FOUR_ASIDE', label: 'Global_Field_Sizes_4' },
  { value: 'FIVE_ASIDE', label: 'Global_Field_Sizes_5' },
  { value: 'SIX_ASIDE', label: 'Global_Field_Sizes_6' },
  { value: 'SEVEN_ASIDE', label: 'Global_Field_Sizes_7' },
  { value: 'EIGHT_ASIDE', label: 'Global_Field_Sizes_8' },
  { value: 'NINE_ASIDE', label: 'Global_Field_Sizes_9' },
  { value: 'TEN_ASIDE', label: 'Global_Field_Sizes_10' },
  { value: 'ELEVEN_ASIDE', label: 'Global_Field_Sizes_11' },
];

export const SPORT_TYPES = [
  { value: 'FOOTBALL', label: 'Global_Sport_Type_1', icon: <FootBallIcon /> },
  { value: 'BASKETBALL', label: 'Global_Sport_Type_2', icon: <BasketBallIcon /> },
];

export const LANGUAGES = [
  { value: 'subheader', label: 'OTHER_LANGUAGES' },
  { value: 'AU', label: 'Kiswahili' },
  { value: 'GB', label: 'English' },
  { value: 'CN', label: '中文' },
  { value: 'FR', label: 'Français' },
  { value: 'PT', label: 'Português' },
  { value: 'NL', label: 'Dutch' },
  { value: 'subheader', label: 'AFRICAN_LANGUAGES' },
  { value: 'DZ_ARA', label: 'Algeria (Arabic)' },
  { value: 'DZ_BER', label: 'Algeria (Berber)' },
  { value: 'AO_COK', label: 'Angola (Cokwe/Chokwe)' },
  { value: 'AO_DHI', label: 'Angola (Dhimba/Zemba)' },
  { value: 'AO_GCI', label: 'Angola (Gciriku)' },
  { value: 'AO_HIM', label: 'Angola (Himba/Herero)' },
  { value: 'AO_HOL', label: 'Angola (Holu)' },
  { value: 'AO_KHO', label: 'Angola (Khongo)' },
  { value: 'AO_KHW', label: 'Angola (Khwedam)' },
  { value: 'AO_KIB', label: 'Angola (Kibala)' },
  { value: 'AO_KIK', label: 'Angola (Kikongo)' },
  { value: 'AO_KIL', label: 'Angola (Kilari)' },
  { value: 'AO_KIM', label: 'Angola (Kimbundu)' },
  { value: 'AO_KUN', label: 'Angola (Kung-Ekoka)' },
  { value: 'AO_KUV', label: 'Angola (Kuvale)' },
  { value: 'AO_KWA', label: 'Angola (Kwadi)' },
  { value: 'AO_KWAN', label: 'Angola (Kwandu)' },
  { value: 'AO_KWANG', label: 'Angola (Kwangali)' },
  { value: 'AO_LUB', label: 'Angola (Luba-Kasai)' },
  { value: 'AO_LUC', label: 'Angola (Lucazi)' },
  { value: 'AO_LUI', label: 'Angola (Luimbi)' },
  { value: 'AO_LUN', label: 'Angola (Lunda)' },
  { value: 'AO_LUV', label: 'Angola (Luvale)' },
  { value: 'AO_MAK', label: 'Angola (Makoma)' },
  { value: 'AO_MAS', label: 'Angola (Mashi)' },
  { value: 'AO_MBA', label: 'Angola (Mbangala)' },
  { value: 'AO_MBU', label: 'Angola (Mbukushu)' },
  { value: 'AO_MBUN', label: 'Angola (Mbunda)' },
  { value: 'AO_MBW', label: 'Angola (Mbwela)' },
  { value: 'AO_MPI', label: 'Angola (Mpinda)' },
  { value: 'AO_NDO', label: 'Angola (Ndombe)' },
  { value: 'AO_NGA', label: 'Angola (Ngandyera (Oshiwambo))' },
  { value: 'AO_NGE', label: 'Angola (Ngendelengo)' },
  { value: 'AO_NKA', label: 'Angola (Nkangala)' },
  { value: 'AO_NKU', label: 'Angola (Nkumbi)' },
  { value: 'AO_NOR', label: 'Angola (Northwestern !Kung)' },
  { value: 'AO_NYA', label: 'Angola (Nyaneka)' },
  { value: 'AO_NYE', label: 'Angola (Nyemba)' },
  { value: 'AO_NYEN', label: 'Angola (Nyengo)' },
  { value: 'AO_OSH', label: 'Angola (Kwanyama (Oshiwambo))' },
  { value: 'AO_RUU', label: 'Angola (Ruund)' },
  { value: 'AO_SAM', label: 'Angola (Sama)' },
  { value: 'AO_SON', label: 'Angola (Songo)' },
  { value: 'AO_SUK', label: 'Angola (Suku)' },
  { value: 'AO_UMB', label: 'Angola (Umbundu)' },
  { value: 'AO_YAK', label: 'Angola (Yaka)' },
  { value: 'AO_YAU', label: 'Angola (Yauma)' },
  { value: 'AO_YOM', label: 'Angola (Yombe)' },
  { value: 'BJ_FON', label: 'Benin (Fon)' },
  { value: 'BJ_YOR', label: 'Benin (Yoruba)' },
  { value: 'BJ_YOM', label: 'Benin (Yom)' },
  { value: 'BJ_BAR', label: 'Benin (Bariba)' },
  { value: 'BJ_FUL', label: 'Benin (Fulfulde)' },
  { value: 'BW_TS', label: 'Botswana (Tswana)' },
  { value: 'BW_SEK', label: 'Botswana (Sekalaka)' },
  { value: 'BW_SEH', label: 'Botswana (Seherero)' },
  { value: 'BF_MOS', label: 'Burkina Faso (Mossi)' },
  { value: 'BF_DYU', label: 'Burkina Faso (Dyula)' },
  { value: 'BF_FUL', label: 'Burkina Faso (Fulfulde)' },
  { value: 'BF_MAN', label: 'Burkina Faso (Mande)' },
  { value: 'BF_GUR', label: 'Burkina Faso (Gur)' },
  { value: 'BF_BIS', label: 'Burkina Faso (Bissa)' },
  { value: 'BF_BWA', label: 'Burkina Faso (Bwamu)' },
  { value: 'BF_DAG', label: 'Burkina Faso (Dagara)' },
  { value: 'BF_SAM', label: 'Burkina Faso (Samo)' },
  { value: 'BI_KIR', label: 'Burundi (Kirundi)' },
  { value: 'CM_AGH', label: 'Cameroon (Aghem)' },
  { value: 'CM_AKO', label: 'Cameroon (Akoose)' },
  { value: 'CM_ATO', label: 'Cameroon (Atong)' },
  { value: 'CM_AWI', label: 'Cameroon (Awing)' },
  { value: 'CM_BAB', label: 'Cameroon (Baba)' },
  { value: 'CM_BABA', label: 'Cameroon (Babanki)' },
  { value: 'CM_BAF', label: 'Cameroon (Bafanji)' },
  { value: 'CM_BAFA', label: 'Cameroon (Bafaw-balong)' },
  { value: 'CM_BAFI', label: 'Cameroon (Bafia)' },
  { value: 'CM_BAFU', label: 'Cameroon (Bafut)' },
  { value: 'CM_BAK', label: 'Cameroon (Baka)' },
  { value: 'CM_BAKA', label: 'Cameroon (Bakaka)' },
  { value: 'CM_BAKO', label: 'Cameroon (Bakoko)' },
  { value: 'CM_BAL', label: 'Cameroon (Balundu-bima)' },
  { value: 'CM_BAM', label: 'Cameroon (Bamali)' },
  { value: 'CM_BAMB', label: 'Cameroon (Bambalang)' },
  { value: 'CM_BAMBI', label: 'Cameroon (Bambili-Bambui)' },
  { value: 'CM_BAME', label: 'Cameroon (Bamenyam)' },
  { value: 'CM_BAMU', label: 'Cameroon (Bamukumbit)' },
  { value: 'CM_BAMO', label: 'Cameroon (Bamoun)' },
  { value: 'CM_BAMU', label: 'Cameroon (Bamunka)' },
  { value: 'CM_BAN', label: 'Cameroon (Bana)' },
  { value: 'CM_BANG', label: 'Cameroon (Bangolan)' },
  { value: 'CM_BANK', label: 'Cameroon (Bankon)' },
  { value: 'CM_BAR', label: 'Cameroon (Barombi)' },
  { value: 'CM_BAS', label: 'Cameroon (Bassa)' },
  { value: 'CM_BASS', label: 'Cameroon (Bassossi)' },
  { value: 'CM_BAT', label: 'Cameroon (Batanga)' },
  { value: 'CM_BAT', label: 'Cameroon (Bati)' },
  { value: 'CM_BEB', label: 'Cameroon (Beba)' },
  { value: 'CM_BEBE', label: 'Cameroon (Bebele)' },
  { value: 'CM_BEBI', label: 'Cameroon (Bebil)' },
  { value: 'CM_BEE', label: 'Cameroon (Beezen)' },
  { value: 'CM_BEF', label: 'Cameroon (Befang)' },
  { value: 'CM_BEK', label: 'Cameroon (Bekwel)' },
  { value: 'CM_BET', label: 'Cameroon (Beti)' },
  { value: 'CM_BIK', label: 'Cameroon (Bikya)' },
  { value: 'CM_BIS', label: 'Cameroon (Bishuo)' },
  { value: 'CM_BIT', label: 'Cameroon (Bitare)' },
  { value: 'CM_BOK', label: 'Cameroon (Bokyi)' },
  { value: 'CM_BOM', label: 'Cameroon (Bomwali)' },
  { value: 'CM_BU', label: 'Cameroon (Bu)' },
  { value: 'CM_BUB', label: 'Cameroon (Bubia)' },
  { value: 'CM_BUD', label: 'Cameroon (Buduma)' },
  { value: 'CM_BUL', label: 'Cameroon (Bulu)' },
  { value: 'CM_BUM', label: 'Cameroon (Bum)' },
  { value: 'CM_BUS', label: 'Cameroon (Busuu)' },
  { value: 'CM_BUW', label: 'Cameroon (Buwal)' },
  { value: 'CM_BYE', label: 'Cameroon (Byep)' },
  { value: 'CM_CAK', label: 'Cameroon (Caka)' },
  { value: 'CM_CUV', label: 'Cameroon (Cuvok)' },
  { value: 'CM_DAB', label: 'Cameroon (Daba)' },
  { value: 'CM_DEN', label: 'Cameroon (Denya)' },
  { value: 'CM_DII', label: 'Cameroon (Dii)' },
  { value: 'CM_DOY', label: 'Cameroon (Doyayo)' },
  { value: 'CM_DUA', label: 'Cameroon (Duala)' },
  { value: 'CM_DUG', label: 'Cameroon (Dugun)' },
  { value: 'CM_DUGW', label: 'Cameroon (Dugwor)' },
  { value: 'CM_DUU', label: 'Cameroon (Duupa)' },
  { value: 'CM_DZO', label: 'Cameroon (Dzodinka)' },
  { value: 'CM_ELI', label: 'Cameroon (Elip)' },
  { value: 'CM_ESI', label: 'Cameroon (Esimbi)' },
  { value: 'CM_ETO', label: 'Cameroon (Eton)' },
  { value: 'CM_EWO', label: 'Cameroon (Ewondo)' },
  { value: 'CM_FAN', label: 'Cameroon (Fang)' },
  { value: 'CM_FEF', label: 'Cameroon (Fe fe)' },
  { value: 'CM_FUL', label: 'Cameroon (Fulfulde)' },
  { value: 'CM_GAV', label: 'Cameroon (Gavar)' },
  { value: 'CM_GBA', label: 'Cameroon (Gbaya-Mbodomo)' },
  { value: 'CM_GHO', label: 'Cameroon (Ghomala)' },
  { value: 'CM_GID', label: 'Cameroon (Gidar)' },
  { value: 'CM_GYE', label: 'Cameroon (Gyele)' },
  { value: 'CM_GIM', label: 'Cameroon (Gimme)' },
  { value: 'CM_GIM', label: 'Cameroon (Gimnime)' },
  { value: 'CM_HAU', label: 'Cameroon (Hausa)' },
  { value: 'CM_HDI', label: 'Cameroon (Hdi)' },
  { value: 'CM_ICE', label: 'Cameroon (Iceve-Maci)' },
  { value: 'CM_ISU', label: 'Cameroon (Isu)' },
  { value: 'CM_JIM', label: 'Cameroon (Jimi)' },
  { value: 'CM_KAK', label: 'Cameroon (Kako)' },
  { value: 'CM_KAR', label: 'Cameroon (Karang)' },
  { value: 'CM_KEM', label: 'Cameroon (Kemedzung)' },
  { value: 'CM_KEN', label: 'Cameroon (Kenswei Nsei)' },
  { value: 'CM_KENY', label: 'Cameroon (Kenyang)' },
  { value: 'CM_KOL', label: 'Cameroon (Kol)' },
  { value: 'CM_KOM', label: 'Cameroon (Kom)' },
  { value: 'CM_KOO', label: 'Cameroon (Koonzime)' },
  { value: 'CM_KWA', label: 'Cameroon (Kwasio / Ngumba)' },
  { value: 'CM_KWAK', label: 'Cameroon (Kwakum)' },
  { value: 'CM_KWAN', label: 'Cameroon (Kwanja)' },
  { value: 'CM_KUK', label: 'Cameroon (Kuk)' },
  { value: 'CM_LAB', label: 'Cameroon (La’bi)' },
  { value: 'CM_LAI', label: 'Cameroon (Laimbue)' },
  { value: 'CM_LEF', label: 'Cameroon (Lefa)' },
  { value: 'CM_LIM', label: 'Cameroon (Limbum)' },
  { value: 'CM_NCA', label: 'Cameroon (Ncane)' },
  { value: 'CM_NGI', label: 'Cameroon (Ngiemboon)' },
  { value: 'CM_NGO', label: 'Cameroon (Ngomba)' },
  { value: 'CM_NGI', label: 'Cameroon (North Giziga)' },
  { value: 'CM_NMO', label: 'Cameroon (North Mofu)' },
  { value: 'CM_MAF', label: 'Cameroon (Mafa)' },
  { value: 'CM_MAK', label: 'Cameroon (Makaa)' },
  { value: 'CM_MAL', label: 'Cameroon (Malgbe)' },
  { value: 'CM_MAM', label: 'Cameroon (Mambai)' },
  { value: 'CM_MAMB', label: 'Cameroon (Mambila)' },
  { value: 'CM_MAN', label: 'Cameroon (Manta)' },
  { value: 'CM_MAS', label: 'Cameroon (Massa)' },
  { value: 'CM_MAT', label: 'Cameroon (Matal)' },
  { value: 'CM_MAZ', label: 'Cameroon (Mazagway)' },
  { value: 'CM_MBE', label: 'Cameroon (Mbedam)' },
  { value: 'CM_MBO', label: 'Cameroon (Mbo)' },
  { value: 'CM_MBU', label: 'Cameroon (Mbum)' },
  { value: 'CM_MBUK', label: 'Cameroon (Mbuko)' },
  { value: 'CM_MED', label: 'Cameroon (Medumba)' },
  { value: 'CM_MEF', label: 'Cameroon (Mefele)' },
  { value: 'CM_MEN', label: 'Cameroon (Mendankwe-Nkwen)' },
  { value: 'CM_MENG', label: 'Cameroon (Mengaka)' },
  { value: 'CM_MENGI', label: 'Cameroon (Mengisa)' },
  { value: 'CM_MEN', label: 'Cameroon (Menka)' },
  { value: 'CM_MER', label: 'Cameroon (Merey)' },
  { value: 'CM_MES', label: 'Cameroon (Mesaka)' },
  { value: 'CM_MET', label: 'Cameroon (Meta)' },
  { value: 'CM_MFU', label: 'Cameroon (Mfumte)' },
  { value: 'CM_MMA', label: 'Cameroon (Mmaala)' },
  { value: 'CM_MME', label: 'Cameroon (Mmen)' },
  { value: 'CM_MOK', label: 'Cameroon (Mokpwe)' },
  { value: 'CM_MOF', label: 'Cameroon (Mofu-gudur)' },
  { value: 'CM_MOL', label: 'Cameroon (Moloko)' },
  { value: 'CM_MPA', label: 'Cameroon (Mpade)' },
  { value: 'CM_MPO', label: 'Cameroon (Mpongmpong)' },
  { value: 'CM_MUN', label: 'Cameroon (Mundani)' },
  { value: 'CM_MUS', label: 'Cameroon (Musgu)' },
  { value: 'CM_MUY', label: 'Cameroon (Muyang)' },
  { value: 'CM_NDA', label: 'Cameroon (Nda nda)' },
  { value: 'CM_NDE', label: 'Cameroon (Ndemli)' },
  { value: 'CM_NGA', label: 'Cameroon (Nga ka)' },
  { value: 'CM_NGAM', label: 'Cameroon (Ngamambo)' },
  { value: 'CM_NGE', label: 'Cameroon (Ngemba)' },
  { value: 'CM_NGI', label: 'Cameroon (Ngie)' },
  { value: 'CM_NGO', label: 'Cameroon (Ngombale)' },
  { value: 'CM_NGOS', label: 'Cameroon (Ngoshie)' },
  { value: 'CM_NGOW', label: 'Cameroon (Ngwo)' },
  { value: 'CM_NOM', label: 'Cameroon (Nomaande)' },
  { value: 'CM_NOO', label: 'Cameroon (Noone)' },
  { value: 'CM_NFA', label: 'Cameroon (North Fali)' },
  { value: 'CM_NSO', label: 'Cameroon (Nso)' },
  { value: 'CM_NUG', label: 'Cameroon (Nugunu)' },
  { value: 'CM_NWE', label: 'Cameroon (Nweh)' },
  { value: 'CM_NYO', label: 'Cameroon (Nyong)' },
  { value: 'CM_OKU', label: 'Cameroon (Oku)' },
  { value: 'CM_PAR', label: 'Cameroon (Parkwa)' },
  { value: 'CM_PIN', label: 'Cameroon (Pinyin)' },
  { value: 'CM_POL', label: 'Cameroon (Pol)' },
  { value: 'CM_PSI', label: 'Cameroon (Psikye)' },
  { value: 'CM_SAA', label: 'Cameroon (Saari)' },
  { value: 'CM_SHA', label: 'Cameroon (Sharwa)' },
  { value: 'CM_SO', label: 'Cameroon (So)' },
  { value: 'CM_SFA', label: 'Cameroon (South Fali)' },
  { value: 'CM_SGI', label: 'Cameroon (South Giziga)' },
  { value: 'CM_SUG', label: 'Cameroon (Suga)' },
  { value: 'CM_TIK', label: 'Cameroon (Tikar)' },
  { value: 'CM_TIG', label: 'Cameroon (Tigon Mbembe)' },
  { value: 'CM_TOU', label: 'Cameroon (Toupouri)' },
  { value: 'CM_TUN', label: 'Cameroon (Tunen)' },
  { value: 'CM_TUK', label: 'Cameroon (Tuki)' },
  { value: 'CM_USA', label: 'Cameroon (Usaghade)' },
  { value: 'CM_VAM', label: 'Cameroon (Vame)' },
  { value: 'CM_VEN', label: 'Cameroon (Vengo)' },
  { value: 'CM_VUT', label: 'Cameroon (Vute)' },
  { value: 'CM_WAN', label: 'Cameroon (Wandala)' },
  { value: 'CM_WEH', label: 'Cameroon (Weh)' },
  { value: 'CM_WUS', label: 'Cameroon (Wushi)' },
  { value: 'CM_WUM', label: 'Cameroon (Wumboko)' },
  { value: 'CM_WUZ', label: 'Cameroon (Wuzlam)' },
  { value: 'CM_YAM', label: 'Cameroon (Yamba)' },
  { value: 'CM_YAMB', label: 'Cameroon (Yambeta)' },
  { value: 'CM_YEM', label: 'Cameroon (Yemba)' },
  { value: 'CM_ZUL', label: 'Cameroon (Zulgo-Gemzek)' },
  { value: 'CV_CR', label: 'Cape Verde (Cape Verdean Creole)' },
  { value: 'CF_SAN', label: 'Central African Republic (Sango)' },
  { value: 'TD_CAR', label: 'Chad (Chadian Arabic)' },
  { value: 'KM_COM', label: 'Comoros (Comorian)' },
  { value: 'CG_KIK', label: 'Democratic Republic of the Congo (Kikongo)' },
  { value: 'CG_LIN', label: 'Democratic Republic of the Congo (Lingala)' },
  { value: 'CG_TSH', label: 'Democratic Republic of the Congo (Tshiluba)' },
  { value: 'CD_LIN', label: 'Republic of the Congo (Lingala)' },
  { value: 'CD_KIT', label: 'Republic of the Congo (Kituba)' },
  { value: 'CD_KIK', label: 'Republic of the Congo (Kikongo)' },
  { value: 'CI_DIO', label: 'Ivory Coast (Dioula)' },
  { value: 'CI_GUR', label: 'Ivory Coast (Gur)' },
  { value: 'CI_KRU', label: 'Ivory Coast (Kru)' },
  { value: 'CI_KWA', label: 'Ivory Coast (Kwa)' },
  { value: 'DJ_AFA', label: 'Djibouti (Afar)' },
  { value: 'DJ_SOM', label: 'Djibouti (Somali)' },
  { value: 'EG_EAR', label: 'Egypt (Egyptian Arabic)' },
  { value: 'GQ_ANN', label: 'Equatorial Guinea (Annobonese)' },
  { value: 'GQ_BUB', label: 'Equatorial Guinea (Bubi)' },
  { value: 'GQ_FAN', label: 'Equatorial Guinea (Fang)' },
  { value: 'GQ_IGB', label: 'Equatorial Guinea (Igbo)' },
  { value: 'GQ_PIC', label: 'Equatorial Guinea (Pichinglis)' },
  { value: 'ER_AFA', label: 'Eritrea (Afar)' },
  { value: 'ER_BIL', label: 'Eritrea (Bilen)' },
  { value: 'ER_KUN', label: 'Eritrea (Kunama)' },
  { value: 'ER_NAR', label: 'Eritrea (Nara)' },
  { value: 'ER_SAH', label: 'Eritrea (Saho)' },
  { value: 'ER_TIG', label: 'Eritrea (Tigre)' },
  { value: 'ER_TIGR', label: 'Eritrea (Tigrinya)' },
  { value: 'ER_BEJ', label: 'Eritrea (Beja)' },
  { value: 'ER_GEE', label: 'Eritrea (Ge ez)' },
  { value: 'ET_AFA', label: 'Ethiopia (Afar)' },
  { value: 'ET_AMH', label: 'Ethiopia (Amharic)' },
  { value: 'ET_ORO', label: 'Ethiopia (Oromo)' },
  { value: 'ET_SOM', label: 'Ethiopia (Somali)' },
  { value: 'ET_TIG', label: 'Ethiopia (Tigrinya)' },
  { value: 'GA_FAN', label: 'Gabon (Fang)' },
  { value: 'GM_BAI', label: 'Gambia (Bainouk)' },
  { value: 'GM_JOL', label: 'Gambia (Jola)' },
  { value: 'GM_MAN', label: 'Gambia (Mandinka)' },
  { value: 'GM_MANJ', label: 'Gambia (Manjak)' },
  { value: 'GM_PUL', label: 'Gambia (Pulaar)' },
  { value: 'GM_SER', label: 'Gambia (Serer)' },
  { value: 'GM_SON', label: 'Gambia (Soninke)' },
  { value: 'GM_WOL', label: 'Gambia (Wolof)' },
  { value: 'GH_AKA', label: 'Ghana (Akan)' },
  { value: 'GH_DAG', label: 'Ghana (Dagaare)' },
  { value: 'GH_DAN', label: 'Ghana (Dangme)' },
  { value: 'GH_EWE', label: 'Ghana (Ewe)' },
  { value: 'GH_GA', label: 'Ghana (Ga)' },
  { value: 'GH_GON', label: 'Ghana (Gonja)' },
  { value: 'GH_KAS', label: 'Ghana (Kasem)' },
  { value: 'GH_NZE', label: 'Ghana (Nzema)' },
  { value: 'GN_FUL', label: 'Guinea (Fula)' },
  { value: 'GN_MAN', label: 'Guinea (Mandinka)' },
  { value: 'GN_SUS', label: 'Guinea (Susu)' },
  { value: 'GW_KRI', label: 'Guinea-Bissau (Kriol)' },
  { value: 'KE_SWA', label: 'Kenya (Swahili)' },
  { value: 'LS_SES', label: 'Lesotho (Sesotho)' },
  { value: 'LR_GOL', label: 'Liberia (Gola)' },
  { value: 'LR_KPE', label: 'Liberia (Kpelle)' },
  { value: 'LR_KRU', label: 'Liberia (Kru)' },
  { value: 'LR_MAN', label: 'Liberia (Mande)' },
  { value: 'LR_MEL', label: 'Liberia (Mel)' },
  { value: 'LY_ARA', label: 'Libya (Arabic)' },
  { value: 'LY_TAM', label: 'Libya (Tamazight)' },
  { value: 'MG_MAL', label: 'Madagascar (Malagasy)' },
  { value: 'MW_CHE', label: 'Malawi (Chewa)' },
  { value: 'MW_TUM', label: 'Malawi (Tumbuka)' },
  { value: 'ML_BAM', label: 'Mali (Bambara)' },
  { value: 'ML_FUL', label: 'Mali (Fula)' },
  { value: 'ML_SON', label: 'Mali (Sonrai)' },
  { value: 'ML_SONI', label: 'Mali (Soninke)' },
  { value: 'MR_ARA', label: 'Mauritania (Arabic)' },
  { value: 'MR_PUL', label: 'Mauritania (Pulaar)' },
  { value: 'MR_SON', label: 'Mauritania (Soninke)' },
  { value: 'MR_WOL', label: 'Mauritania (Wolof)' },
  { value: 'MU_MCR', label: 'Mauritius (Mauritian Creole)' },
  { value: 'MA_ARA', label: 'Morocco (Arabic)' },
  { value: 'MA_BER', label: 'Morocco (Berber)' },
  { value: 'MZ_MAK', label: 'Mozambique (Makua)' },
  { value: 'MZ_SEN', label: 'Mozambique (Sena)' },
  { value: 'MZ_NDA', label: 'Mozambique (Ndau)' },
  { value: 'MZ_XIT', label: 'Mozambique (Xitsonga)' },
  { value: 'MZ_SWA', label: 'Mozambique (Swahili)' },
  { value: 'NA_OSH', label: 'Namibia (Oshiwambo)' },
  { value: 'NA_KHO', label: 'Namibia (Khoekhoegowab)' },
  { value: 'NA_OTJ', label: 'Namibia (Otjiherero)' },
  { value: 'NA_RUK', label: 'Namibia (RuKwangali)' },
  { value: 'NA_SIL', label: 'Namibia (siLozi)' },
  { value: 'NE_HAU', label: 'Niger (Hausa)' },
  { value: 'NE_DJE', label: 'Niger (Djerma)' },
  { value: 'NE_TAM', label: 'Niger (Tamajaq)' },
  { value: 'NE_FUL', label: 'Niger (Fulfulde)' },
  { value: 'NG_HAU', label: 'Nigeria (Hausa)' },
  { value: 'NG_IGB', label: 'Nigeria (Igbo)' },
  { value: 'NG_YOR', label: 'Nigeria (Yoruba)' },
  { value: 'NG_EFI', label: 'Nigeria (Efik)' },
  { value: 'NG_EDO', label: 'Nigeria (Edo)' },
  { value: 'NG_ADA', label: 'Nigeria (Adamawa)' },
  { value: 'NG_FUL', label: 'Nigeria (Fulfulde)' },
  { value: 'NG_IDO', label: 'Nigeria (Idoma)' },
  { value: 'NG_KAN', label: 'Nigeria (Kanuri)' },
  { value: 'RW_KIN', label: 'Rwanda (Kinyarwanda)' },
  { value: 'EH_ARA', label: 'Sahrawi Arab Democratic Republic (Arabic)' },
  { value: 'ST_FOR', label: 'São Tomé and Príncipe (Forro)' },
  { value: 'ST_ANG', label: 'São Tomé and Príncipe (Angolar)' },
  { value: 'ST_PRI', label: 'São Tomé and Príncipe (Principense)' },
  { value: 'SN_PUL', label: 'Senegal (Pulaar)' },
  { value: 'SN_TOU', label: 'Senegal (Toucouleur)' },
  { value: 'SN_WOL', label: 'Senegal (Wolof)' },
  { value: 'SC_SCR', label: 'Seychelles (Seychellois Creole)' },
  { value: 'SL_KRI', label: 'Sierra Leone (Krio)' },
  { value: 'SO_ARA', label: 'Somalia (Arabic)' },
  { value: 'SO_MAA', label: 'Somalia (Maay)' },
  { value: 'SO_DAB', label: 'Somalia (Dabarre)' },
  { value: 'SO_TUN', label: 'Somalia (Tunni)' },
  { value: 'SO_CHI', label: 'Somalia (Chimwiini)' },
  { value: 'SO_KIB', label: 'Somalia (Kibajuni)' },
  { value: 'ZA_AFR', label: 'South Africa (Afrikaans)' },
  { value: 'ZA_SND', label: 'South Africa (Southern Ndebele)' },
  { value: 'ZA_PED', label: 'South Africa (Pedi)' },
  { value: 'ZA_SOT', label: 'South Africa (Sotho)' },
  { value: 'ZA_SWA', label: 'South Africa (Swati)' },
  { value: 'ZA_TSO', label: 'South Africa (Tsonga)' },
  { value: 'ZA_TSW', label: 'South Africa (Tswana)' },
  { value: 'ZA_VEN', label: 'South Africa (Venda)' },
  { value: 'ZA_XHO', label: 'South Africa (Xhosa)' },
  { value: 'ZA_ZUL', label: 'South Africa (Zulu)' },
  { value: 'SS_JUB', label: 'South Sudan (Juba Arabic)' },
  { value: 'SS_BAR', label: 'South Sudan (Bari)' },
  { value: 'SS_DIN', label: 'South Sudan (Dinka)' },
  { value: 'SS_LUO', label: 'South Sudan (Luo)' },
  { value: 'SS_MUR', label: 'South Sudan (Murle)' },
  { value: 'SS_NUE', label: 'South Sudan (Nuer)' },
  { value: 'SS_ZAN', label: 'South Sudan (Zande)' },
  { value: 'SD_ARA', label: 'Sudan (Arabic)' },
  { value: 'SZ_SIS', label: 'Eswatini (siSwati)' },
  { value: 'SZ_ZUL', label: 'Eswatini (Zulu)' },
  { value: 'TZ_SWA', label: 'Tanzania (Swahili)' },
  { value: 'TG_EWE', label: 'Togo (Ewe)' },
  { value: 'TG_KAB', label: 'Togo (Kabiye)' },
  { value: 'TN_ARA', label: 'Tunisia (Arabic)' },
  { value: 'TN_BER', label: 'Tunisia (Berber)' },
  { value: 'UG_SWA', label: 'Uganda (Swahili)' },
  { value: 'UG_GAN', label: 'Uganda (Ganda)' },
  { value: 'UG_SOG', label: 'Uganda (Soga)' },
  { value: 'UG_NKO', label: 'Uganda (Nkole)' },
  { value: 'UG_KIG', label: 'Uganda (Kiga)' },
  { value: 'UG_TES', label: 'Uganda (Teso)' },
  { value: 'UG_MAS', label: 'Uganda (Masaba)' },
  { value: 'UG_NYO', label: 'Uganda (Nyoro)' },
  { value: 'ZM_NYA', label: 'Zambia (Nyanja)' },
  { value: 'ZW_SHO', label: 'Zimbabwe (Shona)' },
  { value: 'ZW_NND', label: 'Zimbabwe (Northern Ndebele)' },
  { value: 'ZW_KAL', label: 'Zimbabwe (Kalanga)' },
];

export const CURRENCIES = [
  { value: 'DZD', sign: '', label: 'Algerian dinar', country_code: 'DZ', country_code_3: 'DZA' },
  { value: 'AOA', sign: '', label: 'Angolan kwanza', country_code: 'AO', country_code_3: 'AGO' },
  { value: 'BJ_XOF', sign: '', label: 'Benin West African CFA franc', country_code: 'BJ', country_code_3: 'BEN' },
  { value: 'BWP', sign: '', label: 'Botswana pula', country_code: 'BW', country_code_3: 'BWA' },
  { value: 'BF_XOF', sign: '', label: 'Burkina Faso West African CFA franc', country_code: 'BF', country_code_3: 'BFA' },
  { value: 'BIF', sign: '', label: 'Burundi franc', country_code: 'BI', country_code_3: 'BDI' },
  { value: 'CVE', sign: '', label: 'Cabo Verdean escudo', country_code: 'CV', country_code_3: 'CPV' },
  { value: 'CM_XAF', sign: '', label: 'Cameroon Central African CFA franc', country_code: 'CM', country_code_3: 'CMR' },
  { value: 'CF_XAF', sign: '', label: 'Central African Republic Central African CFA franc', country_code: 'CF', country_code_3: 'CAF' },
  { value: 'TD_XAF', sign: '', label: 'Chad Central African CFA franc', country_code: 'TD', country_code_3: 'TCD' },
  { value: 'KMF', sign: '', label: 'Comorian franc', country_code: 'KM', country_code_3: 'COM' },
  { value: 'CDF', sign: '', label: 'Congolese franc', country_code: 'CD', country_code_3: 'COD' },
  { value: 'XAF', sign: '', label: 'Congo, Republic of the', country_code: 'CG', country_code_3: 'COG' },
  { value: 'CI_XOF', sign: '', label: 'Cote d’Ivoire West African CFA franc', country_code: 'CI', country_code_3: 'CIV' },
  { value: 'DJF', sign: '', label: 'Djiboutian franc', country_code: 'DJ', country_code_3: 'DJI' },
  { value: 'EGP', sign: '', label: 'Egyptian pound', country_code: 'EG', country_code_3: 'EGY' },
  { value: 'GQ_XAF', sign: '', label: 'Equatorial Guinea Central African CFA franc', country_code: 'GQ', country_code_3: 'GNQ' },
  { value: 'ERN', sign: '', label: 'Eritrean nakfa', country_code: 'ER', country_code_3: 'ERI' },
  { value: 'SZL', sign: '', label: 'Swazi lilangeni', country_code: 'SZ', country_code_3: 'SWZ' },
  { value: 'ETB', sign: '', label: 'Ethiopian birr', country_code: 'ET', country_code_3: 'ETH' },
  { value: 'GA_XAF', sign: '', label: 'Gabon Central African CFA franc', country_code: 'GA', country_code_3: 'GAB' },
  { value: 'GMD', sign: '', label: 'Gambian dalasi', country_code: 'GM', country_code_3: 'GMB' },
  { value: 'GHS', sign: '', label: 'Ghanaian cedi', country_code: 'GH', country_code_3: 'GHA' },
  { value: 'GNF', sign: '', label: 'Guinean franc', country_code: 'GN', country_code_3: 'GIN' },
  { value: 'GW_XOF', sign: '', label: 'Guinea-Bissau West African CFA franc', country_code: 'GW', country_code_3: 'GNB' },
  { value: 'KES', sign: '', label: 'Kenyan shilling', country_code: 'KE', country_code_3: 'KEN' },
  { value: 'LSL', sign: '', label: 'Lesotho loti', country_code: 'LS', country_code_3: 'LSO' },
  { value: 'LRD', sign: '', label: 'Liberian dollar', country_code: 'LR', country_code_3: 'LBR' },
  { value: 'LYD', sign: '', label: 'Libyan dinar', country_code: 'LY', country_code_3: 'LBY' },
  { value: 'MGA', sign: '', label: 'Malagasy ariary', country_code: 'MG', country_code_3: 'MDG' },
  { value: 'MWK', sign: '', label: 'Malawian kwacha', country_code: 'MW', country_code_3: 'MWI' },
  { value: 'ML_XOF', sign: '', label: 'Mali West African CFA franc', country_code: 'ML', country_code_3: 'MLI' },
  { value: 'MRU', sign: '', label: 'Mauritanian ouguiya', country_code: 'MR', country_code_3: 'MRT' },
  { value: 'MUR', sign: '', label: 'Mauritian rupee', country_code: 'MU', country_code_3: 'MUS' },
  { value: 'MAD', sign: '', label: 'Moroccan dirham', country_code: 'MA', country_code_3: 'MAR' },
  { value: 'MZN', sign: '', label: 'Mozambican metical', country_code: 'MZ', country_code_3: 'MOZ' },
  { value: 'NAD', sign: '', label: 'Namibian dollar', country_code: 'NA', country_code_3: 'NAM' },
  { value: 'NE_XOF', sign: '', label: 'Niger West African CFA franc', country_code: 'NE', country_code_3: 'NER' },
  { value: 'NGN', sign: '', label: 'Nigerian naira', country_code: 'NG', country_code_3: 'NGA' },
  { value: 'RWF', sign: '', label: 'Rwandan franc', country_code: 'RW', country_code_3: 'RWA' },
  { value: 'SHP', sign: '', label: 'Saint Helena pound', country_code: 'SH', country_code_3: 'SHN' },
  { value: 'STN', sign: '', label: 'Sao Tome and Principe dobra', country_code: 'ST', country_code_3: 'STP' },
  { value: 'SN_XOF', sign: '', label: 'Senegal West African CFA franc', country_code: 'SN', country_code_3: 'SEN' },
  { value: 'SCR', sign: '', label: 'Seychellois rupee', country_code: 'SC', country_code_3: 'SYC' },
  { value: 'SLL', sign: '', label: 'Sierra Leonean leone', country_code: 'SL', country_code_3: 'SLE' },
  { value: 'SOS', sign: '', label: 'Somali shilling', country_code: 'SO', country_code_3: 'SOM' },
  { value: 'ZAR', sign: '', label: 'South African rand', country_code: 'ZA', country_code_3: 'ZAF' },
  { value: 'SSP', sign: '', label: 'South Sudanese pound', country_code: 'SS', country_code_3: 'SSD' },
  { value: 'SDG', sign: '', label: 'Sudanese pound', country_code: 'SD', country_code_3: 'SDN' },
  { value: 'TZS', sign: '', label: 'Tanzanian shilling', country_code: 'TZ', country_code_3: 'TZA' },
  { value: 'TG_XOF', sign: '', label: 'Togo West African CFA franc', country_code: 'TG', country_code_3: 'TGO' },
  { value: 'TND', sign: '', label: 'Tunisian dinar', country_code: 'TN', country_code_3: 'TUN' },
  { value: 'UGX', sign: '', label: 'Ugandan shilling', country_code: 'UG', country_code_3: 'UGA' },
  { value: 'ZMW', sign: '', label: 'Zambian kwacha', country_code: 'ZM', country_code_3: 'ZMB' },
  { value: 'GBP', sign: '', label: 'Pound sterling', country_code: 'GB', country_code_3: 'GBR' },
  { value: 'USD', sign: '$', label: 'United States dollar', country_code: 'US', country_code_3: 'USA' },
  { value: 'EUR', sign: '€', label: 'Euro', country_code: 'EU', country_code_3: 'EU' },
];

export const MOBILE_MONEY_NETWORKS = [
  { name: 'MTN_MOMO_BEN', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'BEN', country_code: 'BN', currency: 'XOF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'MOOV_BEN', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'BEN', country_code: 'BN', currency: 'XOF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'MTN_MOMO_CMR', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'CMR', country_code: 'CM', currency: 'XAF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'ORANGE_CMR', mno: 'Orange', icon: <OrangeMoneyIcon />, country_code_3: 'CMR', country_code: 'CM', currency: 'XAF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'MTN_MOMO_CIV', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'CIV', country_code: 'CI', currency: 'XOF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'ORANGE_CIV', mno: 'Orange', icon: <OrangeMoneyIcon />, country_code_3: 'CIV', country_code: 'CI', currency: 'XOF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'VODACOM_MPESA_COD', mno: 'Vodacom', icon: <MobileMoneyIcon />, country_code_3: 'COD', country_code: 'CD', currency: 'CDF', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'AIRTEL_COD', mno: 'Airtel', icon: <MobileMoneyIcon />, country_code_3: 'COD', country_code: 'CD', currency: 'CDF', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'ORANGE_COD', mno: 'Orange', icon: <OrangeMoneyIcon />, country_code_3: 'COD', country_code: 'CD', currency: 'CDF', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'MTN_MOMO_GHA', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'GHA', country_code: 'GH', currency: 'GHS', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'AIRTELTIGO_GHA', mno: 'AT', icon: <MobileMoneyIcon />, country_code_3: 'GHA', country_code: 'GH', currency: 'GHS', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'VODAFONE_GHA', mno: 'Vodafone', icon: <MobileMoneyIcon />, country_code_3: 'GHA', country_code: 'GH', currency: 'GHS', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'MPESA_KEN', mno: 'MPesa', icon: <MobileMoneyIcon />, country_code_3: 'KEN', country_code: 'KE', currency: 'KES', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'AIRTEL_MWI', mno: 'Airtel', icon: <MobileMoneyIcon />, country_code_3: 'MWI', country_code: 'MW', currency: 'MWK', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'TNM_MWI', mno: 'TNM', icon: <MobileMoneyIcon />, country_code_3: 'MWI', country_code: 'MW', currency: 'MWK', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'VODACOM_MOZ', mno: 'Vodacom', icon: <MobileMoneyIcon />, country_code_3: 'MOZ', country_code: 'MZ', currency: 'MZN', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'AIRTEL_NGA', mno: 'Airtel', icon: <MobileMoneyIcon />, country_code_3: 'NGA', country_code: 'NG', currency: 'NGN', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'MTN_MOMO_NGA', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'NGA', country_code: 'NG', currency: 'NGN', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'AIRTEL_RWA', mno: 'Airtel', icon: <MobileMoneyIcon />, country_code_3: 'RWA', country_code: 'RW', currency: 'RWF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'MTN_MOMO_RWA', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'RWA', country_code: 'RW', currency: 'RWF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'FREE_SEN', mno: 'Free', icon: <MobileMoneyIcon />, country_code_3: 'SEN', country_code: 'SN', currency: 'XOF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'ORANGE_SEN', mno: 'Orange', icon: <OrangeMoneyIcon />, country_code_3: 'SEN', country_code: 'SN', currency: 'XOF', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'ORANGE_SLE', mno: 'Orange', icon: <OrangeMoneyIcon />, country_code_3: 'SLE', country_code: 'SL', currency: 'SLE', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'AIRTEL_TZA', mno: 'Airtel', icon: <MobileMoneyIcon />, country_code_3: 'TZA', country_code: 'TZ', currency: 'TZS', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'VODACOM_TZA', mno: 'Vodacom', icon: <MobileMoneyIcon />, country_code_3: 'TZA', country_code: 'TZ', currency: 'TZS', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'TIGO_TZA', mno: 'Tigo', icon: <MobileMoneyIcon />, country_code_3: 'TZA', country_code: 'TZ', currency: 'TZS', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'HALOTEL_TZA', mno: 'Halotel', icon: <MobileMoneyIcon />, country_code_3: 'TZA', country_code: 'TZ', currency: 'TZS', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'AIRTEL_OAPI_UGA', mno: 'Airtel', icon: <MobileMoneyIcon />, country_code_3: 'UGA', country_code: 'UG', currency: 'UGX', decimals_amount_deposit: 0, decimals_amount_payout: 0 },
  { name: 'MTN_MOMO_UGA', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'UGA', country_code: 'UG', currency: 'UGX', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'AIRTEL_OAPI_ZMB', mno: 'Airtel', icon: <MobileMoneyIcon />, country_code_3: 'ZMB', country_code: 'ZM', currency: 'ZMW', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'MTN_MOMO_ZMB', mno: 'MTN', icon: <MTNIcon />, country_code_3: 'ZMB', country_code: 'ZM', currency: 'ZMW', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
  { name: 'ZAMTEL_ZMB', mno: 'Zamtel', icon: <MobileMoneyIcon />, country_code_3: 'ZMB', country_code: 'ZM', currency: 'ZMW', decimals_amount_deposit: 2, decimals_amount_payout: 2 },
];

export const BLOCKCHAIN_CURRENCIES = [{ value: 'MAT', sign: '∑', label: 'Maât' }];

export const LIPAY_PAYMENT_OPTIONS = [
  { value: 'MAT', label: 'Maât' },
  { value: 'CASH', label: 'Cash' },
  { value: 'GOLD', label: 'Gold' },
  { value: 'PLASTIC_WASTE', label: 'Plastic waste' },
  { value: 'TYRE_WASTE', label: 'Tyre waste' },
  { value: 'ORGANIC_WASTE', label: 'Organic waste' },
];

export const PAYMENT_PROVIDERS = [
  {
    name: 'STRIPE',
    countries: [
      'AL',
      'DZ',
      'AO',
      'AG',
      'AR',
      'AM',
      'AU',
      'AT',
      'AZ',
      'BS',
      'BH',
      'BD',
      'BE',
      'BJ',
      'BT',
      'BO',
      'BA',
      'BW',
      'BN',
      'BG',
      'KH',
      'CA',
      'CL',
      'CO',
      'CR',
      'CI',
      'HR',
      'CY',
      'CZ',
      'DK',
      'DO',
      'EC',
      'EG',
      'SV',
      'EE',
      'ET',
      'FI',
      'FR',
      'GA',
      'GM',
      'DE',
      'GH',
      'GR',
      'GT',
      'GY',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IE',
      'IL',
      'IT',
      'JM',
      'JP',
      'JO',
      'KZ',
      'KE',
      'KW',
      'LA',
      'LV',
      'LI',
      'LT',
      'LU',
      'MO',
      'MG',
      'MY',
      'MT',
      'MU',
      'MX',
      'MD',
      'MC',
      'MN',
      'MA',
      'MZ',
      'NA',
      'NL',
      'NZ',
      'NE',
      'NG',
      'MK',
      'NO',
      'OM',
      'PK',
      'PA',
      'PY',
      'PE',
      'PH',
      'PL',
      'PT',
      'QA',
      'RO',
      'RW',
      'SM',
      'SA',
      'SN',
      'RS',
      'SG',
      'SK',
      'SI',
      'ZA',
      'KR',
      'ES',
      'LK',
      'LC',
      'SE',
      'CH',
      'TW',
      'TZ',
      'TH',
      'TT',
      'TN',
      'TR',
      'AE',
      'GB',
      'UY',
      'UZ',
      'VN',
    ],
  },
  { name: 'FLUTTERWAVE', countries: ['NG', 'GH', 'KE', 'ZA', 'UG', 'US', 'TZ'] },
  {
    name: 'LIPAY_ACCOUNT',
    countries: [
      'AF',
      'AX',
      'AL',
      'DZ',
      'AS',
      'AD',
      'AO',
      'AI',
      'AQ',
      'AG',
      'AR',
      'AM',
      'AW',
      'AU',
      'AT',
      'AZ',
      'BS',
      'BH',
      'BD',
      'BB',
      'BY',
      'BE',
      'BZ',
      'BJ',
      'BM',
      'BT',
      'BO',
      'BQ',
      'BA',
      'BW',
      'BV',
      'BR',
      'IO',
      'BN',
      'BG',
      'BF',
      'BI',
      'KH',
      'CM',
      'CA',
      'CV',
      'KY',
      'CF',
      'TD',
      'CL',
      'CN',
      'CX',
      'CC',
      'CO',
      'KM',
      'CG',
      'CD',
      'CK',
      'CR',
      'HR',
      'CU',
      'CW',
      'CY',
      'CZ',
      'DK',
      'DJ',
      'DM',
      'DO',
      'EC',
      'EG',
      'SV',
      'GQ',
      'ER',
      'EE',
      'ET',
      'FK',
      'FO',
      'FJ',
      'FI',
      'FR',
      'GF',
      'PF',
      'TF',
      'GA',
      'GM',
      'GE',
      'DE',
      'GH',
      'GI',
      'GR',
      'GL',
      'GD',
      'GP',
      'GU',
      'GT',
      'GG',
      'GN',
      'GW',
      'GY',
      'HT',
      'HM',
      'VA',
      'HN',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IR',
      'IQ',
      'IE',
      'IM',
      'IL',
      'IT',
      'JM',
      'JP',
      'JE',
      'JO',
      'KZ',
      'KE',
      'KI',
      'KP',
      'KR',
      'KW',
      'KG',
      'LA',
      'LV',
      'LB',
      'LS',
      'LR',
      'LY',
      'LI',
      'LT',
      'LU',
      'MO',
      'MK',
      'MG',
      'MW',
      'MY',
      'MV',
      'ML',
      'MT',
      'MH',
      'MQ',
      'MR',
      'MU',
      'YT',
      'MX',
      'FM',
      'MD',
      'MC',
      'MN',
      'ME',
      'MS',
      'MA',
      'MZ',
      'MM',
      'NA',
      'NR',
      'NP',
      'NL',
      'NC',
      'NZ',
      'NI',
      'NE',
      'NG',
      'NU',
      'NF',
      'MP',
      'NO',
      'OM',
      'PK',
      'PW',
      'PS',
      'PA',
      'PG',
      'PY',
      'PE',
      'PH',
      'PN',
      'PL',
      'PT',
      'PR',
      'QA',
      'RE',
      'RO',
      'RU',
      'RW',
      'BL',
      'SH',
      'KN',
      'LC',
      'MF',
      'PM',
      'VC',
      'WS',
      'SM',
      'ST',
      'SA',
      'SN',
      'RS',
      'SC',
      'SL',
      'SG',
      'SX',
      'SK',
      'SI',
      'SB',
      'SO',
      'ZA',
      'GS',
      'SS',
      'ES',
      'LK',
      'SD',
      'SR',
      'SJ',
      'SZ',
      'SE',
      'CH',
      'SY',
      'TW',
      'TJ',
      'TZ',
      'TH',
      'TL',
      'TG',
      'TK',
      'TO',
      'TT',
      'TN',
      'TR',
      'TM',
      'TC',
      'TV',
      'UG',
      'UA',
      'AE',
      'GB',
      'US',
      'UM',
      'UY',
      'UZ',
      'VU',
      'VE',
      'VN',
      'VG',
      'VI',
      'WF',
      'EH',
      'YE',
      'ZM',
      'ZW',
    ],
  },
  { name: 'DANAPAY', countries: ['CM', 'BF', 'SN', 'ML', 'CI', 'BJ', 'TG'] },
  { name: 'PAWAPAY', countries: ['CI', 'CM', 'ZM', 'MW', 'UG', 'TZ', 'CD', 'NG', 'GH', 'SN', 'RW', 'MZ', 'KE', 'BJ', 'SL'] },
];

export const PAYMENT_METHODS = [
  { name: 'LIPAY', icon: <LipayIcon />, icon_white: <LipayWhiteIcon /> },
  // { name: 'ALIPAY', icon: <AlipayIcon />, icon_white: <AlipayWhiteIcon /> },
  // { name: 'WECHAT_PAY', icon: <WechatpayIcon />, icon_white: <WechatpayWhiteIcon /> },
  // { name: 'APPLE_PAY', icon: <ApplepayIcon />, icon_white: <ApplepayWhiteIcon /> },
];

export const ALTERNATIVE_PAYMENTS = [
  { name: 'CASH', icon: <LipayCashIcon /> },
  { name: 'GOLD', icon: <LipayGoldIcon /> },
  { name: 'PLASTIC_WASTE', icon: <LipayPlasticIcon /> },
  { name: 'TYRE_WASTE', icon: <LipayTyreIcon /> },
  { name: 'ORGANIC_WASTE', icon: <LipayOrganicIcon /> },
];

export const CARD_NETWORKS = [
  { name: 'VISA', icon: <VisaCardIcon /> },
  { name: 'AMEX', icon: <AmexCardIcon /> },
  { name: 'MASTERCARD', icon: <MasterCardIcon /> },
  { name: 'PAC', icon: <PacCardIcon /> },
  { name: 'DISCOVER', icon: <BankCardsIcon /> },
  { name: 'MAESTRO', icon: <BankCardsIcon /> },
  { name: 'UNION_PAY', icon: <BankCardsIcon /> },
];

export const ACCOUNT_TYPES = ['maat', 'bitcoin', 'euro', 'usd'];

export const BTC_DATA = {
  id: 'bitcoin',
  name: 'Bitcoin',
  symbol: 'BTC',
  price_btc: '1',
  price_usd: '38236.48',
};

export const ETH_DATA = {
  id: 'ethereum',
  name: 'Ethereum',
  symbol: 'ETH',
  price_btc: '0.032',
  price_usd: '1229.74',
};

export const MAT_DATA = {
  id: 'bitcoin',
  name: 'Bitcoin',
  symbol: 'BTC',
  price_btc: '0.16',
  price_usd: '6236.24',
};

export const EUR_DATA = {
  id: 'euro',
  name: 'Euro',
  symbol: 'EUR',
  price_btc: '0.000032',
  price_usd: '1.21',
};

export const USD_DATA = {
  id: 'usd',
  name: 'US Dollar',
  symbol: 'USD',
  price_btc: '0.000027',
  price_usd: '1',
};

export const MOODS = [
  {
    value: 'AFFIRMATION',
    label: 'Utilities_Affirmation',
    icon: <AffirmationFaceIcon />,
    suggestions: ['Utilities_Affirmation_1', 'Utilities_Affirmation_2', 'Utilities_Affirmation_3'],
  },
  {
    value: 'ANGER',
    label: 'Utilities_Anger',
    icon: <AngerFaceIcon />,
    suggestions: ['Utilities_Anger_1', 'Utilities_Anger_2', 'Utilities_Anger_3'],
  },
  {
    value: 'ANXIETY',
    label: 'Utilities_Anxiety',
    icon: <AnxietyFaceIcon />,
    suggestions: ['Utilities_Anxiety_1', 'Utilities_Anxiety_2', 'Utilities_Anxiety_3'],
  },
  {
    value: 'BLUSHING',
    label: 'Utilities_Blushing',
    icon: <BlushingFaceIcon />,
    suggestions: ['Utilities_Blushing_1', 'Utilities_Blushing_2', 'Utilities_Blushing_3'],
  },
  {
    value: 'CONTEMPT',
    label: 'Utilities_Contempt',
    icon: <ContemptFaceIcon />,
    suggestions: ['Utilities_Contempt_1', 'Utilities_Contempt_2', 'Utilities_Contempt_3'],
  },
  {
    value: 'DESPAIR',
    label: 'Utilities_Despair',
    icon: <DespairFaceIcon />,
    suggestions: ['Utilities_Despair_1', 'Utilities_Despair_2', 'Utilities_Despair_3'],
  },
  {
    value: 'DETERMINATION',
    label: 'Utilities_Determination',
    icon: <DeterminationFaceIcon />,
    suggestions: ['Utilities_Determination_1', 'Utilities_Determination_2', 'Utilities_Determination_3'],
  },
  {
    value: 'DEVOTION',
    label: 'Utilities_Devotion',
    icon: <DevotionFaceIcon />,
    suggestions: ['Utilities_Devotion_1', 'Utilities_Devotion_2', 'Utilities_Devotion_3'],
  },
  {
    value: 'DISDAIN',
    label: 'Utilities_Disdain',
    icon: <DisdainFaceIcon />,
    suggestions: ['Utilities_Disdain_1', 'Utilities_Disdain_2', 'Utilities_Disdain_3'],
  },
  {
    value: 'DISGUST',
    label: 'Utilities_Disgust',
    icon: <DisgustFaceIcon />,
    suggestions: ['Utilities_Disgust_1', 'Utilities_Disgust_2', 'Utilities_Disgust_3'],
  },
  {
    value: 'FEAR',
    label: 'Utilities_Fear',
    icon: <FearFaceIcon />,
    suggestions: ['Utilities_Fear_1', 'Utilities_Fear_2', 'Utilities_Fear_3'],
  },
  {
    value: 'GRIEF',
    label: 'Utilities_Grief',
    icon: <GriefFaceIcon />,
    suggestions: ['Utilities_Grief_1', 'Utilities_Grief_2', 'Utilities_Grief_3'],
  },
  {
    value: 'GUILT',
    label: 'Utilities_Guilt',
    icon: <GuiltFaceIcon />,
    suggestions: ['Utilities_Guilt_1', 'Utilities_Guilt_2', 'Utilities_Guilt_3'],
  },
  {
    value: 'HATRED',
    label: 'Utilities_Hatred',
    icon: <HatredFaceIcon />,
    suggestions: ['Utilities_Hatred_1', 'Utilities_Hatred_2', 'Utilities_Hatred_3'],
  },
  {
    value: 'HELPLESSNESS',
    label: 'Utilities_Helplessness',
    icon: <HelplessnessFaceIcon />,
    suggestions: ['Utilities_Helplessness_1', 'Utilities_Helplessness_2', 'Utilities_Helplessness_3'],
  },
  {
    value: 'HIGH_SPIRITS',
    label: 'Utilities_HighSpirits',
    icon: <HighSpiritsFaceIcon />,
    suggestions: ['Utilities_HighSpirits_1', 'Utilities_HighSpirits_2', 'Utilities_HighSpirits_3'],
  },
  {
    value: 'ILL_TEMPER',
    label: 'Utilities_IllTemper',
    icon: <IllTemperFaceIcon />,
    suggestions: ['Utilities_IllTemper_1', 'Utilities_IllTemper_2', 'Utilities_IllTemper_3'],
  },
  {
    value: 'JOY',
    label: 'Utilities_Joy',
    icon: <JoyFaceIcon />,
    suggestions: ['Utilities_Joy_1', 'Utilities_Joy_2', 'Utilities_Joy_3'],
  },
  {
    value: 'LOVE',
    label: 'Utilities_Love',
    icon: <LoveFaceIcon />,
    suggestions: ['Utilities_Love_1', 'Utilities_Love_2', 'Utilities_Love_3'],
  },
  {
    value: 'LOW_SPIRITS',
    label: 'Utilities_LowSpirits',
    icon: <LowSpiritsFaceIcon />,
    suggestions: ['Utilities_LowSpirits_1', 'Utilities_LowSpirits_2', 'Utilities_LowSpirits_3'],
  },
  {
    value: 'MEDIATION',
    label: 'Utilities_Mediation',
    icon: <MediationFaceIcon />,
    suggestions: ['Utilities_Mediation_1', 'Utilities_Mediation_2', 'Utilities_Mediation_3'],
  },
  {
    value: 'MODESTY',
    label: 'Utilities_Modesty',
    icon: <ModestyFaceIcon />,
    suggestions: ['Utilities_Modesty_1', 'Utilities_Modesty_2', 'Utilities_Modesty_3'],
  },
  {
    value: 'NEGATION',
    label: 'Utilities_Negation',
    icon: <NegationFaceIcon />,
    suggestions: ['Utilities_Negation_1', 'Utilities_Negation_2', 'Utilities_Negation_3'],
  },
  {
    value: 'NEUTRAL',
    label: 'Utilities_Neutral',
    icon: <NeutralFaceIcon />,
    suggestions: ['Utilities_Neutral_1', 'Utilities_Neutral_2', 'Utilities_Neutral_3'],
  },
  {
    value: 'PATIENCE',
    label: 'Utilities_Patience',
    icon: <PatienceFaceIcon />,
    suggestions: ['Utilities_Patience_1', 'Utilities_Patience_2', 'Utilities_Patience_3'],
  },
  {
    value: 'PRIDE',
    label: 'Utilities_Pride',
    icon: <PrideFaceIcon />,
    suggestions: ['Utilities_Pride_1', 'Utilities_Pride_2', 'Utilities_Pride_3'],
  },
  {
    value: 'REFLECTION',
    label: 'Utilities_Reflection',
    icon: <ReflectionFaceIcon />,
    suggestions: ['Utilities_Reflection_1', 'Utilities_Reflection_2', 'Utilities_Reflection_3'],
  },
  {
    value: 'SELF_ATTENTION',
    label: 'Utilities_SelfAttention',
    icon: <SelfAttentionFaceIcon />,
    suggestions: ['Utilities_SelfAttention_1', 'Utilities_SelfAttention_2', 'Utilities_SelfAttention_3'],
  },
  {
    value: 'SHYNESS',
    label: 'Utilities_Shyness',
    icon: <ShynessFaceIcon />,
    suggestions: ['Utilities_Shyness_1', 'Utilities_Shyness_2', 'Utilities_Shyness_3'],
  },
  {
    value: 'SUFFERING',
    label: 'Utilities_Suffering',
    icon: <SufferingFaceIcon />,
    suggestions: ['Utilities_Suffering_1', 'Utilities_Suffering_2', 'Utilities_Suffering_3'],
  },
  {
    value: 'SULKINESS',
    label: 'Utilities_Sulkiness',
    icon: <SulkinessFaceIcon />,
    suggestions: ['Utilities_Sulkiness_1', 'Utilities_Sulkiness_2', 'Utilities_Sulkiness_3'],
  },
  {
    value: 'SURPRISE',
    label: 'Utilities_Surprise',
    icon: <SurpriseFaceIcon />,
    suggestions: ['Utilities_Surprise_1', 'Utilities_Surprise_2', 'Utilities_Surprise_3'],
  },
  {
    value: 'TENDER_FEELINGS',
    label: 'Utilities_TenderFeelings',
    icon: <TenderFeelingsFaceIcon />,
    suggestions: ['Utilities_TenderFeelings_1', 'Utilities_TenderFeelings_2', 'Utilities_TenderFeelings_3'],
  },
  {
    value: 'WEEPING',
    label: 'Utilities_Weeping',
    icon: <WeepingFaceIcon />,
    suggestions: ['Utilities_Weeping_1', 'Utilities_Weeping_2', 'Utilities_Weeping_3'],
  },
];

export const RENTAL_ASSET_TYPES = [
  { value: 'Article', label: 'Article' },
  { value: 'Field', label: 'Field' },
  { value: 'Warehouse', label: 'Warehouse' },
];
