import React from 'react';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const LegalMentionsContent: React.FC = () => {
  return (
    <Grid className={'content'} sx={{ whiteSpace: 'pre-line', color: 'inherit' }} item xl={12} xs={12} sm={12}>
      <FormattedMessage id="Footer_Legal_Mentions_Content" />
    </Grid>
  );
};

export default LegalMentionsContent;
