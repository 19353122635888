import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, gql, useMutation } from '@apollo/client';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import _ from 'lodash';
import { Alert, TextField, MenuItem, Menu, Avatar, Grid, Typography, Fab, CardMedia, IconButton, AvatarGroup, CircularProgress, Button, InputAdornment, List, ListItem, SwipeableDrawer, Stack, ListItemButton } from '@mui/material';
import { Home, ArrowBack, Favorite, FavoriteBorder, MoreVert, Send } from '@mui/icons-material';
import { FivezerLogoIcon, FlexIcon, SearchIcon, ShareIcon, TipBoxIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import { MOODS } from '../../utilities/utilities';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import useEventLog from '../../hooks/useEventLog';
import { selectPlayer } from '../../reducers/playerSlice';
import DescriptionsFormatter from '../../component-modals/layout/DescriptionsFormatter';
import EditFlexModal from '../../component-modals/contacts/EditFlexModal';
import FlexComment from './FlexComment';

const Flex: React.FC = () => {
  const FIND_FLEX_BY_ID_QUERY = gql`
    query findFlexByID($short_id: String!) {
      findFlexByID(short_id: $short_id) {
        id
        short_id
        status
        caption
        likes
        liked_by {
          ... on Company {
            __typename
            id
          }
          ... on User {
            __typename
            id
          }
          ... on Player {
            __typename
            id
          }
        }
        pictures {
          path
        }
        reflexed_by {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        comments {
          id
          title
          mood
          content
          likes
          author {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
          created_at
          updated_at
        }
        author {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        co_authors {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        co_author_confirmations {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        flexed_items {
          ... on Article {
            __typename
            id
            short_id
            name
            pictures {
              path
            }
            product {
              id
              short_id
              pictures {
                path
              }
              descriptions {
                language
                content
              }
            }
          }
          ... on Service {
            __typename
            id
            short_id
            name
            pictures {
              path
            }
            descriptions {
              language
              content
            }
          }
          ... on Rental {
            __typename
            id
            short_id
            name
            pictures {
              path
            }
            descriptions {
              language
              content
            }
          }
          ... on Challenge {
            __typename
            id
            short_id
            title
          }
          ... on Tournament {
            __typename
            id
            short_id
            title
          }
        }
      }
    }
  `;

  const LIKE_FLEX_BY_VIEWER_MUTATION = gql`
    mutation likeFlexByViewer($flex: inputLikeFlexByViewer!) {
      likeFlexByViewer(flex: $flex)
    }
  `;

  const UNLIKE_FLEX_BY_VIEWER_MUTATION = gql`
    mutation unlikeFlexByViewer($flex: inputLikeFlexByViewer!) {
      unlikeFlexByViewer(flex: $flex)
    }
  `;

  const INCREASE_FLEX_VIEW_BY_ID_MUTATION = gql`
    mutation increaseFlexViewByID($id: ID!) {
      increaseFlexViewByID(id: $id)
    }
  `;

  const CREATE_COMMENT_ON_FLEX_MUTATION = gql`
    mutation createComment($comment: inputCreateComment!) {
      createComment(comment: $comment) {
        id
      }
    }
  `;

  const CONFIRM_FLEX_CO_PUBLISH_MUTATION = gql`
    mutation confirmCoFlex($flex: inputConfirmCoFlex!) {
      confirmCoFlex(flex: $flex)
    }
  `;

  const REFLEX_BY_VIEWER_MUTATION = gql`
    mutation reFlexByViewer($flex: inputReFlexByViewer!) {
      reFlexByViewer(flex: $flex)
    }
  `;

  const DEFLEX_BY_VIEWER_MUTATION = gql`
    mutation deFlexByViewer($flex: inputReFlexByViewer!) {
      deFlexByViewer(flex: $flex)
    }
  `;

  const { flexId }: any = useParams();

  const navigate = useNavigate();
  const intl = useIntl();
  const { handleSendLog } = useEventLog();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [flex, setFlex] = React.useState(UNDEFINED_AS_ANY);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [activeStep, setActiveStep] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [viewFlexItems, setViewFlexItems] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      flexer_id: '',
      comment_input: '',
      selected_mood: 'NEUTRAL',
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const [reFlexByViewer] = useMutation(REFLEX_BY_VIEWER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deFlexByViewer] = useMutation(DEFLEX_BY_VIEWER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [confirmCoFlex, hasConfirmed] = useMutation(CONFIRM_FLEX_CO_PUBLISH_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createComment, newComment] = useMutation(CREATE_COMMENT_ON_FLEX_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [increaseFlexViewByID, increasedFlex] = useMutation(INCREASE_FLEX_VIEW_BY_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [likeFlexByViewer] = useMutation(LIKE_FLEX_BY_VIEWER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unlikeFlexByViewer] = useMutation(UNLIKE_FLEX_BY_VIEWER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedFlex = useQuery(FIND_FLEX_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { short_id: flexId },
    pollInterval: loggedPlayer?.id ? 3000 : undefined,
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedFlex.refetch({
        short_id: flexId,
      });

      if (result) {
        setFlex(result.data?.findFlexByID);
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleIncreaseFlexView = async () => {
    try {
      const result = await increaseFlexViewByID({
        variables: {
          id: flex?.id,
        },
      });

      if (result) {
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleOpenEdit = () => {
    handleClose();
    setOpenEdit(true);
  };

  const handleCloseEdit = async () => {
    try {
      await handleRefetch();
      setOpenEdit(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleLikeFlex = async () => {
    try {
      const result = await likeFlexByViewer({
        variables: {
          flex: {
            id: flex?.id,
            viewer_id: ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.id,
          },
        },
      });

      if (result.data?.likeFlexByViewer) {
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleUnlikeFlex = async () => {
    try {
      const result = await unlikeFlexByViewer({
        variables: {
          flex: {
            id: flex?.id,
            viewer_id: ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.id,
          },
        },
      });

      if (result.data?.unlikeFlexByViewer) {
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleChangeMood = (value: any) => {
    setValue('selected_mood', value);
  };

  const handleCreateComment = async () => {
    try {
      const result = await createComment({
        variables: {
          comment: {
            entity_id: flex?.id,
            mood: inputFields.selected_mood,
            content: inputFields.comment_input,
            author_id: inputFields.flexer_id,
          },
        },
      });

      if (result.data?.createComment) {
        setValue('comment_input', '');
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleAcceptCoPublishing = async () => {
    try {
      const result = await confirmCoFlex({
        variables: {
          flex: {
            id: flex?.id,
            co_author_id: inputFields.flexer_id,
          },
        },
      });

      if (result.data?.confirmCoFlex) {
        await handleRefetch();
      }
    } catch (e: any) {
      console.error(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleReFlex = async () => {
    try {
      const result = await reFlexByViewer({
        variables: {
          flex: {
            id: flex?.id,
            viewer_id: ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.id,
          },
        },
      });

      if (result.data?.reFlexByViewer) {
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleDeFlex = async () => {
    try {
      const result = await deFlexByViewer({
        variables: {
          flex: {
            id: flex?.id,
            viewer_id: ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.id,
          },
        },
      });

      if (result.data?.deFlexByViewer) {
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  React.useEffect(() => {
    if ((!flex && loadedFlex.data?.findFlexByID) || !_.isMatch(flex, loadedFlex.data?.findFlexByID)) {
      setFlex(loadedFlex.data?.findFlexByID);
    }

    (async () => {
      if (flex && !increasedFlex.called) {
        await handleIncreaseFlexView();
      }
    })();

    if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
  }, [flex, increasedFlex, loadedFlex, inputFields, setValue, ALL_FLEXERS]);

  return (
    <Grid className={'component-profile flex-page dark-mode'} sx={{ minHeight: '100%', pb: '150px' }}>
      <Grid container>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'left',
          }}
        ></Grid>
        <Grid
          item
          xl={8}
          xs={8}
          sm={8}
          sx={{
            p: '0',
            position: 'relative',
            zIndex: '1',
            textAlign: 'center',
          }}
        >
          <IconButton component={Link} to="/" sx={{ 'mb': '0%', '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
          </IconButton>
        </Grid>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'right',
          }}
        >
          <MenuButton />
        </Grid>
      </Grid>

      {(flex?.status === 'PENDING' && ALL_FLEXERS.find((f: any) => f?.id === flex?.author?.id) === undefined && flex?.co_authors.filter((f: any) => ALL_FLEXERS.find((fl: any) => fl?.id === f?.id) !== undefined)?.length === 0) || flex === undefined || loadedFlex.loading ? (
        <Grid sx={{ p: '0 10%' }}>
          {loadedFlex.data?.findFlexByID === undefined && !loadedFlex.loading ? (
            <Grid>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'Flex_Not_Found_1' })}`}&nbsp;
                <Grid component="span" sx={{ fontWeight: '500' }}>
                  {`${intl.formatMessage({ id: 'Flex_Not_Found_2' })}`}
                </Grid>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1.5em',
                  textAlign: 'left',
                  p: '5%',
                }}
              >
                {`${intl.formatMessage({ id: 'Flex_Not_Found_3' })}`}&nbsp;
                <Fab
                  variant="extended"
                  sx={{
                    'p': '0',
                    'fontWeight': '700',
                    'fontSize': '.8em',
                    'boxShadow': 'none',
                    'bgcolor': 'transparent',
                    'color': 'inherit',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'rgba(31, 175, 146, 1)',
                    },
                  }}
                >
                  <SearchIcon sx={{ color: 'rgba(31, 175, 146, 1)' }} />
                  &nbsp;{`${intl.formatMessage({ id: 'Flex_Not_Found_4' })}`}
                </Fab>
                &nbsp;
                {`${intl.formatMessage({ id: 'Flex_Not_Found_5' })}`}
              </Typography>
            </Grid>
          ) : (
            ''
          )}

          {flex?.status === 'PENDING' && ALL_FLEXERS.find((f: any) => f?.id === flex?.author?.id) === undefined && flex?.co_authors.filter((f: any) => ALL_FLEXERS.find((fl: any) => fl?.id === f?.id) !== undefined)?.length === 0 ? (
            <Grid>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'Flex_Not_Found_6' })}`}&nbsp;
                <Grid component="span" sx={{ fontWeight: '500' }}>
                  {`${intl.formatMessage({ id: 'Flex_Not_Found_7' })}`}
                </Grid>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1.5em',
                  textAlign: 'left',
                  p: '5%',
                }}
              >
                {`${intl.formatMessage({ id: 'Flex_Not_Found_8' })}`}&nbsp;
                <Fab
                  component={Link}
                  to="/"
                  variant="extended"
                  sx={{
                    'p': '0',
                    'fontWeight': '700',
                    'fontSize': '.8em',
                    'boxShadow': 'none',
                    'bgcolor': 'transparent',
                    'color': 'inherit',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'rgba(31, 175, 146, 1)',
                    },
                  }}
                >
                  <Home sx={{ color: 'rgba(31, 175, 146, 1)' }} />
                  &nbsp;{`${intl.formatMessage({ id: 'Flex_Not_Found_9' })}`}
                </Fab>
                &nbsp;
                {`${intl.formatMessage({ id: 'Flex_Not_Found_10' })}`}
              </Typography>
            </Grid>
          ) : (
            ''
          )}

          {loadedFlex.loading ? (
            <LoadingComponent
              amount={1}
              text={
                <Typography variant={'h6'} sx={{ textAlign: 'center', fontWeight: '700' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
                </Typography>
              }
              loader={<CircularProgress className="orange-loader" />}
            />
          ) : (
            ''
          )}
        </Grid>
      ) : (
        <Grid>
          <Grid container sx={{ p: '0 2% 5px' }}>
            <Grid item xl={10} xs={10} sm={10} sx={{ display: 'flex', justifyContent: 'start' }}>
              <Grid
                container
                sx={{
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
                <Avatar src={flex?.author?.avatar?.path || flex?.author?.logo?.path} component={Link} to={flex?.author?.__typename === 'Company' ? `/${flex?.author?.name}` : `/u/${flex?.author?.username}`} />
                {flex?.co_authors?.length > 0 ? (
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    &nbsp;&nbsp;X&nbsp;&nbsp;
                    <AvatarGroup max={6}>
                      {_.shuffle(flex?.co_authors).map((author: any) => (
                        <Avatar key={author?.id} alt={author?.name || author?.username} src={author?.avatar?.path || author?.logo?.path} component={Link} to={author?.__typename === 'Company' ? `/${author?.name}` : `/u/${author?.username}`} />
                      ))}
                    </AvatarGroup>
                  </Grid>
                ) : (
                  <Typography
                    component={Link}
                    to={flex?.author?.__typename === 'Company' ? `/${flex?.author?.name}` : `/u/${flex?.author?.username}`}
                    sx={{
                      fontWeight: '700',
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    &nbsp;&nbsp;{flex?.author?.username}
                    {flex?.author?.name}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xl={2} xs={2} sm={2} sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {ALL_FLEXERS.find((f: any) => f?.id === flex?.author?.id) !== undefined ? <MenuItem onClick={handleOpenEdit}>{`${intl.formatMessage({ id: 'Flex_Button_Edit' })}`}</MenuItem> : <></>}
              </Menu>
              {ALL_FLEXERS.find((f: any) => f?.id === flex?.author?.id) !== undefined ? <EditFlexModal open={openEdit} handleClose={handleCloseEdit} flex_id={flex?.id} /> : ''}
            </Grid>
          </Grid>
          <Grid
            container
            className={'images-slider'}
            onDoubleClick={flex?.liked_by.find((f: any) => f?.id === inputFields.flexer_id) ? handleUnlikeFlex : handleLikeFlex}
            sx={{
              height: { xs: 'auto', sm: flex?.pictures?.length === 0 ? 'auto' : '500px' },
              textAlign: 'center',
              overflow: 'hidden',
              display: flex?.pictures?.length === 0 ? 'inline-flex' : 'inline-block',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {flex?.pictures?.length > 0 ? (
              <Grid item xl={12} xs={12} sm={12} sx={{ height: '100%', display: 'inline-block' }}>
                {flex?.pictures?.length > 0 ? (
                  <AutoPlaySwipeableViews axis={'x'} autoplay={false} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                    {flex?.pictures?.map((step: any, index: number) => (
                      <Grid
                        key={index}
                        sx={{
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        {Math.abs(activeStep - index) <= 2 ? <CardMedia component={'img'} sx={{ height: { xs: 'auto', sm: '500px' }, width: { xs: '100%', sm: 'auto' } }} src={step?.path} alt={_.truncate(flex?.caption)} /> : null}
                      </Grid>
                    ))}
                  </AutoPlaySwipeableViews>
                ) : (
                  <Grid
                    className={'empty-cover'}
                    sx={{
                      backgroundImage: flex?.pictures?.length > 0 ? 'linear-gradient(to top, rgb(15,15,15) 0%, #C7C7C7 100%)' : 'linear-gradient(to top, rgb(15,15,15) 70%, #C7C7C7 100%)',
                      height: '300px',
                      width: '100%',
                      position: 'relative',
                    }}
                  ></Grid>
                )}
              </Grid>
            ) : (
              <blockquote cite={flex?.author?.username || flex?.author?.label || flex?.author?.name}>
                <Typography sx={{ fontWeight: '800', fontSize: '1.6em', p: '30px 0 0', maxWidth: '900px', lineHeight: '35px' }}>{flex?.caption}</Typography>- <cite>{flex?.author?.username || flex?.author?.label || flex?.author?.name}</cite>
              </blockquote>
            )}
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }}>
            {flex?.co_author_confirmations?.length >= flex?.co_authors?.length ? (
              ''
            ) : (
              <Alert
                severity="info"
                action={
                  flex?.co_authors?.find((a: any) => a?.id === inputFields.flexer_id) ? (
                    <LoadingButton className="button-blue" loading={hasConfirmed.loading} loadingPosition="end" endIcon={<Send />} onClick={handleAcceptCoPublishing}>
                      {`${intl.formatMessage({ id: 'Flex_Button_Accept' })}`}
                    </LoadingButton>
                  ) : (
                    ''
                  )
                }
              >
                {`${intl.formatMessage({ id: 'Flex_Waiting_Confirmation_1' })}`}&nbsp;
                {flex?.co_authors
                  .filter((a: any) => flex?.co_author_confirmations.find((author_id: any) => author_id === a?.id) === undefined)
                  ?.map((u: any) => (
                    <Typography
                      key={u?.id}
                      variant="caption"
                      sx={{
                        p: '0 2px',
                        fontSize: 'inherit',
                        fontWeight: '700',
                      }}
                    >
                      {u?.name || u?.username},
                    </Typography>
                  ))}
                &nbsp;
                {`${intl.formatMessage({ id: 'Flex_Waiting_Confirmation_2' })}`}
              </Alert>
            )}
          </Grid>
          <Grid id="max-width" container sx={{ p: { xs: '0 5%', lg: '0' } }}>
            <Grid
              item
              xl={6}
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <Fab
                sx={{
                  'bgcolor': 'transparent',
                  'boxShadow': 'none',
                  '&:hover': { bgcolor: 'transparent' },
                }}
              >
                {flex?.liked_by.find((f: any) => f?.id === inputFields.flexer_id) ? (
                  <Favorite
                    sx={{
                      color: 'rgba(238, 44, 32, 1) !important',
                    }}
                    onClick={handleUnlikeFlex}
                  />
                ) : (
                  <FavoriteBorder
                    onClick={handleLikeFlex}
                    sx={{
                      'color': 'rgba(247, 251, 250, .3)',
                      '&:hover': {
                        color: 'rgba(238, 44, 32, .3)',
                      },
                    }}
                  />
                )}
              </Fab>

              <Fab
                sx={{
                  'bgcolor': 'transparent',
                  'boxShadow': 'none',
                  '&:hover': { bgcolor: 'transparent' },
                }}
              >
                {flex?.reflexed_by?.find((f: any) => f?.id === inputFields.flexer_id) ? (
                  <FlexIcon
                    sx={{
                      fontSize: '2.3em',
                      color: 'rgba(31, 175, 146, 1) !important',
                    }}
                    onClick={handleDeFlex}
                  />
                ) : (
                  <FlexIcon
                    onClick={handleReFlex}
                    sx={{
                      'fontSize': '2.3em',
                      'color': 'rgba(247, 251, 250, .3)',
                      '&:hover': {
                        color: 'rgba(31, 175, 146, .3)',
                      },
                    }}
                  />
                )}
              </Fab>

              {flex?.flexed_items?.length > 0 ? (
                <Fab
                  sx={{
                    'bgcolor': 'transparent',
                    'boxShadow': 'none',
                    '&:hover': { bgcolor: 'transparent' },
                  }}
                  onClick={() => setViewFlexItems(true)}
                >
                  <TipBoxIcon
                    sx={{
                      'color': 'rgba(247, 251, 250, .3)',
                      '&:hover': {
                        color: 'rgba(247, 251, 250, .6)',
                      },
                    }}
                  />
                </Fab>
              ) : (
                ''
              )}

              <ShareURLButton
                button={
                  <Fab
                    sx={{
                      'bgcolor': 'transparent',
                      'boxShadow': 'none',
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                  >
                    <ShareIcon
                      sx={{
                        'color': 'rgba(247, 251, 250, .3)',
                        '&:hover': {
                          color: 'rgba(247, 251, 250, .6)',
                        },
                      }}
                    />
                  </Fab>
                }
                url={window.location.href}
                title={`${flex?.title || `${intl.formatMessage({ id: 'Flex_Share_Title' })}`}`}
                text={`${flex?.caption || `${intl.formatMessage({ id: 'Flex_Share_Text' })} `}`}
              />
            </Grid>
            <Grid
              item
              xl={6}
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                p: '20px 0 0',
              }}
            >
              <Controller
                name="flexer_id"
                control={control}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'Flex_Flexer_As' })}`}
                    select
                    variant="outlined"
                    className="no-borders"
                    SelectProps={{
                      IconComponent: undefined,
                      sx: {
                        'color': 'rgba(247, 251, 250, 1)',
                        'border': 'none',
                        'bgcolor': 'transparent',
                        '& #mui-component-select-flexer_id': { p: '10px 0' },
                        '&::-ms-expand': {
                          display: 'none',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'rgba(247, 251, 250, 1)',
                        p: '0 6px',
                        borderRadius: '5px',
                        textAlign: 'center',
                        width: '100%',
                      },
                    }}
                    sx={{ m: '0', p: '0', width: 'auto' }}
                  >
                    {ALL_FLEXERS.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Grid
                          container
                          sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar
                            src={option?.avatar}
                            sx={{
                              width: '25px',
                              height: '25px',
                            }}
                          />
                          &nbsp;&nbsp;
                          <Typography variant="caption">{option.name}</Typography>
                        </Grid>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid id="max-width" className="caption" item xl={12} xs={12} sm={12}>
              {flex?.liked_by?.length === 0 ? (
                ''
              ) : (
                <Typography sx={{ pt: '10px', fontWeight: '700' }}>
                  {flex?.liked_by?.length} {flex?.liked_by?.length > 1 ? `${intl.formatMessage({ id: 'Flex_Likes' })}` : `${intl.formatMessage({ id: 'Flex_Like' })}`}
                </Typography>
              )}

              {flex?.pictures?.length > 0 && flex?.caption ? (
                <Typography sx={{ pt: '15px' }}>
                  <Typography
                    variant="caption"
                    component={Link}
                    to={flex?.author?.__typename === 'Company' ? `/${flex?.author?.name}` : `/u/${flex?.author?.username}`}
                    sx={{
                      fontWeight: '700',
                      fontSize: 'inherit',
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    {flex?.author?.username}
                    {flex?.author?.name}
                  </Typography>
                  &nbsp;&nbsp;
                  {flex?.caption}
                </Typography>
              ) : (
                ''
              )}
            </Grid>
            <Grid id="max-width" className="comments" item xl={12} xs={12} sm={12} sx={{ pt: '20px' }}>
              <Controller
                name="comment_input"
                control={control}
                rules={{ required: false, pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._[]\s-]{5,60}$/ }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    placeholder={`${intl.formatMessage({ id: 'Flex_Comment_Placeholder' })}`}
                    fullWidth
                    value={_.trimStart(field.value)}
                    multiline
                    minRows={1}
                    disabled={newComment.loading}
                    InputProps={{
                      startAdornment: (
                        <Controller
                          control={control}
                          name="selected_mood"
                          render={({ field }: any) => (
                            <TextField {...field} value={field.value} select variant="outlined" SelectProps={{ MenuProps: { sx: { '& li .MuiGrid-root': { justifyContent: 'start', fontWeight: '500', color: 'rgba(15, 15, 15, 1)' } } } }}>
                              {MOODS?.map((option) => (
                                <MenuItem key={option?.value} value={option?.value} onClick={() => handleChangeMood(option?.value)}>
                                  <Grid container sx={{ justifyContent: 'center', fontWeight: '700', color: 'rgba(247, 251, 250, .4)' }}>
                                    {option?.icon}&nbsp;&nbsp;
                                    {intl.formatMessage({ id: option?.label })}
                                  </Grid>
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ justifyContent: 'center', alignItems: 'center', p: { xs: '40px 0 10px', sm: '0' } }}>
                          <IconButton onClick={handleCreateComment}>
                            <Send />
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'color': 'rgba(247, 251, 250, 1)', 'mt': '0', 'mb': '0', '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& textarea': { borderRadius: '10px', bgcolor: 'rgba(247, 251, 250, .04)', p: '10px 0px 10px 5px' } },
                    }}
                    sx={{ mb: '20px', bgcolor: 'rgba(247, 251, 250, 0.04)', borderRadius: '10px' }}
                  />
                )}
              />

              {flex?.comments?.length === 0 ? (
                <Button
                  sx={{
                    color: 'rgba(247, 251, 250, .3) !important',
                    textAlign: 'center',
                    textTransform: 'inherit',
                    width: '100%',
                  }}
                >
                  {`${intl.formatMessage({ id: 'Flex_First_Comment' })}`}
                </Button>
              ) : (
                <List
                  sx={{
                    width: '100%',
                    color: 'rgba(247, 251, 250, 1)',
                  }}
                >
                  {flex?.comments?.map((c: any) => (
                    <FlexComment key={c?.id} comment={c} handleRefetch={handleRefetch} />
                  ))}
                </List>
              )}
            </Grid>
          </Grid>

          <SwipeableDrawer
            anchor={'bottom'}
            open={viewFlexItems}
            onClose={() => setViewFlexItems(false)}
            onOpen={() => setViewFlexItems(true)}
            PaperProps={{
              sx: {
                borderTopRightRadius: '25px',
                borderTopLeftRadius: '25px',
              },
            }}
            ModalProps={{
              keepMounted: false,
              sx: { backdropFilter: 'blur(8px)', zIndex: '1000000000' },
            }}
          >
            <List sx={{ p: '30px 0 10px' }}>
              <Grid container sx={{ maxHeight: '40vh', overflow: 'auto' }}>
                <Stack direction="row" sx={{ width: '100%', display: 'block' }}>
                  {flex?.flexed_items?.map((item: any) => (
                    <ListItem key={item?.id} sx={{ pr: '0' }}>
                      <ListItemButton role={undefined} sx={{ pr: '0' }}>
                        <Avatar
                          alt={item?.name}
                          src={(item?.pictures?.[0] || item?.product?.pictures?.[0])?.path}
                          component={Link}
                          to={item?.__typename === 'Challenge' ? `/challenge/${item?.short_id}` : item?.__typename === 'Tournament' ? `/tournament/${item?.short_id}` : item?.__typename === 'Product' ? `/p/${item?.short_id}` : item?.__typename === 'Article' ? `/p/${item?.short_id}` : item?.__typename === 'Service' ? `/s/${item?.short_id}` : item?.__typename === 'Rental' ? `/r/${item?.short_id}` : ''}
                          sx={{ border: 'solid 1px rgba(15, 15, 15, .1)' }}
                        />
                        <Grid sx={{ p: '0 10px' }}>
                          <Typography
                            component={Link}
                            to={item?.__typename === 'Challenge' ? `/challenge/${item?.short_id}` : item?.__typename === 'Tournament' ? `/tournament/${item?.short_id}` : item?.__typename === 'Product' ? `/p/${item?.short_id}` : item?.__typename === 'Article' ? `/p/${item?.short_id}` : item?.__typename === 'Service' ? `/s/${item?.short_id}` : item?.__typename === 'Rental' ? `/r/${item?.short_id}` : ''}
                            sx={{
                              fontWeight: 700,
                              textDecoration: 'none',
                              color: 'rgba(15, 15, 15, 1)',
                            }}
                          >
                            {item?.name || item?.title}
                          </Typography>
                          <br />
                          <DescriptionsFormatter descriptions={item?.descriptions || item?.product?.descriptions} max_length={100} />
                        </Grid>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Stack>
              </Grid>
            </List>
          </SwipeableDrawer>
        </Grid>
      )}
    </Grid>
  );
};

export default Flex;
