import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Autocomplete, Box } from '@mui/material';
import { Clear, AddLocation } from '@mui/icons-material';
import { COUNTRIES } from '../../utilities/utilities';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { CountryType, INITIAL_COUNTRY } from '../../utilities/CommonInterfaces';
import { LocationIcon } from '../../img/icons/Icons';
import { selectSession } from '../../reducers/sessionSlice';

export default function AddAccountNewLocation(props: { open: boolean; handleClose: any }) {
  const CREATE_ACCOUNT_LOCATION_MUTATION = gql`
    mutation createAccountLocation($account: inputCreateAccountLocation!) {
      createAccountLocation(account: $account) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedUser = useAppSelector(selectPlayer);
  const [newLocationCountry, setNewLocationCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [createAccountLocation, newLocation] = useMutation(CREATE_ACCOUNT_LOCATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const {
    control,
    watch,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      new_location_title: '',
      new_location_street_name: '',
      new_location_secondary_street_name: '',
      new_location_postcode: '',
      new_location_city: '',
      new_location_county: '',
      new_location_state: '',
      new_location_country: INITIAL_COUNTRY,
      new_location_country_code: '',
    },
  });
  const inputFields = watch();

  const handleClose = () => {
    props.handleClose();
    reset();
    resetField('new_location_title');
    resetField('new_location_street_name');
    resetField('new_location_secondary_street_name');
    resetField('new_location_city');
    resetField('new_location_postcode');
    resetField('new_location_state');
    resetField('new_location_county');
    resetField('new_location_country_code');
    resetField('new_location_country');
  };

  const onChangeNewLocationCountry = (event: any, country: CountryType) => {
    if (country) {
      setNewLocationCountry(country);
      setValue('new_location_country', country);
    }
  };

  const handleAddNewAccountLocation = async () => {
    try {
      const result = await createAccountLocation({
        variables: {
          account: {
            id: loggedUser?.id,
            location: {
              title: inputFields.new_location_title,
              street_name: inputFields.new_location_street_name,
              secondary_street_name: inputFields.new_location_secondary_street_name,
              country_code: newLocationCountry?.value,
              postcode: inputFields.new_location_postcode,
              city: inputFields.new_location_city,
              county: inputFields.new_location_county,
              state: inputFields.new_location_state,
              country: newLocationCountry?.label,
              continent: newLocationCountry?.continent,
            },
          },
        },
      });

      if (result) {
        handleClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
        <LocationIcon sx={{ color: 'rgba(31, 175, 146, 1)' }} />
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Add new address</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ m: '20px 0' }}>
          <Controller name="new_location_title" control={control} rules={{ required: false }} render={({ field }: any) => <TextField {...field} label={`Title`} placeholder={`${loggedUser?.username} House, office, etc...`} variant="outlined" className={'field-bottom-space'} required fullWidth error={errors?.new_location_title !== undefined} helperText={''} />} />

          <Controller
            name="new_location_street_name"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label="Street address" placeholder="Write your company's street address" className={'field-bottom-space'} variant="outlined" fullWidth value={field.value} error={errors?.new_location_street_name !== undefined} helperText={errors?.new_location_street_name !== undefined ? `${errors?.new_location_street_name?.message}` : ''} />}
          />

          <Controller
            name="new_location_secondary_street_name"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label="Street address (complement)" placeholder="Complement of company's street address" className={'field-bottom-space'} variant="outlined" fullWidth value={field.value} error={errors?.new_location_secondary_street_name !== undefined} helperText={errors?.new_location_secondary_street_name !== undefined ? `${errors?.new_location_secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} />}
          />

          <Controller
            name="new_location_postcode"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="Postcode"
                placeholder="Postcode"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.new_location_postcode !== undefined}
                helperText={errors?.new_location_postcode !== undefined ? `${errors?.new_location_postcode?.message}` : ''}
                sx={{
                  mr: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="new_location_city"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="City"
                placeholder="City"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.new_location_city !== undefined}
                helperText={errors?.new_location_city !== undefined ? `${errors?.new_location_city?.message}` : ''}
                sx={{
                  ml: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="new_location_county"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="County"
                placeholder="County"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.new_location_county !== undefined}
                helperText={errors?.new_location_county !== undefined ? `${errors?.new_location_county?.message}` : ''}
                sx={{
                  mr: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="new_location_state"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="State"
                placeholder="State"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.new_location_state !== undefined}
                helperText={errors?.new_location_state !== undefined ? `${errors?.new_location_state?.message}` : ''}
                sx={{
                  ml: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="new_location_country"
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                options={COUNTRIES}
                value={newLocationCountry}
                className={'country-select'}
                onChange={(event: any, value: any) => onChangeNewLocationCountry(event, value)}
                autoHighlight
                getOptionLabel={(option: CountryType) => option.label}
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                renderOption={(props: any, option: CountryType) => (
                  <Box
                    component="li"
                    sx={{
                      '& > img': {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                    {option.label} ({option.value})
                  </Box>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Country"
                    value={newLocationCountry}
                    required
                    variant="outlined"
                    error={errors?.new_location_country !== undefined}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: '0px 5% 50px', justifyContent: 'center' }}>
        <LoadingButton
          onClick={handleAddNewAccountLocation}
          className={inputFields.new_location_title === '' || inputFields.new_location_street_name === '' || inputFields.new_location_postcode === '' || inputFields.new_location_city === '' ? 'button-disabled' : 'button-green'}
          disabled={inputFields.new_location_title === '' || inputFields.new_location_street_name === '' || inputFields.new_location_postcode === '' || inputFields.new_location_city === ''}
          loadingPosition="end"
          endIcon={<AddLocation />}
          autoFocus
          loading={newLocation.loading}
        >
          Add address
        </LoadingButton>
        <Button onClick={handleClose} className={'actionTwo button-cancel'} endIcon={<Clear />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
