import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import _ from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardHeader, CardMedia, Chip, Fab, Grid, IconButton, InputAdornment, List, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { selectSession } from '../../reducers/sessionSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Controller, useForm } from 'react-hook-form';
import { Clear, ExpandMore, People, Send } from '@mui/icons-material';
import { MOODS } from '../../utilities/utilities';
import { useIntl } from 'react-intl';
import useEventLog from '../../hooks/useEventLog';
import LivestreamComment from './LivestreamComment';
import { Link } from 'react-router-dom';
import { selectPlayer } from '../../reducers/playerSlice';
import LivestreamVideoPlayer from '../../component-modals/features/LivestreamVideoPlayer';
import { FlexIcon, LiveIcon } from '../../img/icons/Icons';
import Timer from '../../component-modals/common/Timer';
import NewLivestreamClipModal from '../../component-modals/contacts/NewLivestreamClipModal';
import LoginModal from '../../component-modals/profile/LoginModal';
import ViewSponsorshipProposalModal from '../../component-modals/features/ViewSponsorshipProposalModal';
import { REACT_APP_LILENEK_URL } from '../../config';
import SponsoredVideoWrapper from '../../component-modals/layout/SponsoredVideoWrapper';

const Livestream: React.FC = () => {
  const FIND_LIVESTREAM_BY_ID_QUERY = gql`
    query findLivestreamByID($livestream: inputFindLivestreamByID!) {
      findLivestreamByID(livestream: $livestream) {
        id
        room_id
        title
        status
        video_url
        anonymous_participants
        author {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on Position {
            __typename
            id
            role
            company {
              id
              name
              label
              logo {
                path
              }
            }
            employees {
              id
              username
              avatar {
                filename
                path
              }
              owner {
                firstname
              }
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        participants {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        comments {
          id
          title
          mood
          content
          likes
          author {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
          created_at
          updated_at
        }
        sponsorships {
          id
          status
          title
          author {
            ... on Position {
              __typename
              id
            }
            ... on Player {
              __typename
              id
            }
          }
          descriptions {
            language
            content
          }
          price {
            value
            currency {
              currency_code
            }
          }
          subject {
            ... on Livestream {
              __typename
              id
              title
            }
            ... on Challenge {
              __typename
              id
              title
            }
            ... on Trophy {
              __typename
              id
              name
            }
            ... on Tournament {
              __typename
              id
              title
            }
          }
          proposals {
            id
            status
            author {
              id
              company {
                id
                name
                label
                logo {
                  path
                }
              }
            }
            price {
              value
              currency {
                currency_code
              }
            }
            banner {
              path
            }
          }
        }
        created_at
      }
    }
  `;

  const ADD_LIVESTREAM_VIEWER_MUTATION = gql`
    mutation addLivestreamViewer($livestream: inputAddLivestreamViewer!) {
      addLivestreamViewer(livestream: $livestream)
    }
  `;

  const CREATE_COMMENT_MUTATION = gql`
    mutation createComment($comment: inputCreateComment!) {
      createComment(comment: $comment) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const { livestreamId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const { handleSendLog } = useEventLog();

  const [livestream, setLivestream] = React.useState(UNDEFINED_AS_ANY);
  const [openClip, setOpenClip] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openViewSponsorshipProposal, setOpenViewSponsorshipProposal] = React.useState({ sponsorship_proposal_id: '', isOpen: false });

  const { control, watch, setValue } = useForm({
    defaultValues: {
      flexer_id: '',
      comment_input: '',
      selected_mood: 'NEUTRAL',
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const [addLivestreamViewer, newViewer] = useMutation(ADD_LIVESTREAM_VIEWER_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });
  const [createComment, newComment] = useMutation(CREATE_COMMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedLivestream = useQuery(FIND_LIVESTREAM_BY_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      livestream: {
        id: livestreamId,
        comments_order: 'DESC',
      },
    },
    pollInterval: livestream?.status === 'ON_GOING' ? 3000 : undefined,
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedLivestream.refetch({
        livestream: {
          id: livestreamId,
          comments_order: 'DESC',
        },
      });

      if (result) {
        setLivestream(result.data?.findLivestreamByID);
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleChangeMood = (value: any) => {
    setValue('selected_mood', value);
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCreateComment = async () => {
    try {
      const result = await createComment({
        variables: {
          comment: {
            entity_id: livestream?.id,
            mood: inputFields.selected_mood,
            content: inputFields.comment_input,
            author_id: inputFields.flexer_id,
          },
        },
      });

      if (result.data?.createComment) {
        setValue('comment_input', '');
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleCloseViewSponsorshipProposal = async () => {
    try {
      await handleRefetch();
      setOpenViewSponsorshipProposal({ sponsorship_proposal_id: '', isOpen: false });
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if ((!livestream && loadedLivestream.data?.findLivestreamByID) || (livestream && !_.isMatch(livestream, loadedLivestream.data?.findLivestreamByID))) {
      setLivestream(loadedLivestream.data?.findLivestreamByID);
    }

    if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
  }, [loadedLivestream, livestream, inputFields.flexer_id]);

  React.useEffect(() => {
    (async () => {
      if (livestream && (!loggedPlayer?.id || (loggedPlayer?.id && inputFields.flexer_id && !livestream?.participants?.find((p: any) => p?.id === inputFields.flexer_id))) && !newViewer.called) {
        await addLivestreamViewer({
          variables: {
            livestream: {
              id: livestream?.id,
              participant_id: inputFields.flexer_id,
            },
          },
        });
      }
    })();
  }, [livestream, inputFields.flexer_id, loggedPlayer?.id, newViewer.called]);

  return (
    <Grid className={`component-contacts conversation-page ${session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode') : 'dark-mode'}`}>
      <Grid container sx={{ position: 'absolute', left: '0', top: '0' }}>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '0 2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'left',
          }}
        ></Grid>
        <Grid
          item
          xl={8}
          xs={8}
          sm={8}
          sx={{
            p: '0',
            position: 'relative',
            zIndex: '1',
            textAlign: 'center',
          }}
        ></Grid>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '0 2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'right',
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <Clear sx={{ opacity: '.4' }} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container sx={{ maxHeight: '720px' }}>
        <Grid item xs={12} sm={12} md={8} xl={8} sx={{ pt: '40px' }}>
          <SponsoredVideoWrapper sponsors={livestream?.sponsorships}>
            <LivestreamVideoPlayer room_id={livestreamId || ''} status={livestream?.status} video_url={livestream?.video_url} />
          </SponsoredVideoWrapper>
        </Grid>
        <Grid id="max-width" className="comments" item xs={12} sm={12} md={4} xl={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, pt: '40px', pl: '2%', pr: '2%' }}>
          {livestream?.comments?.length === 0 ? (
            <Typography
              variant="h3"
              sx={{
                p: '50px 20px 0',
                fontSize: '1.3em',
                textAlign: 'center',
                color: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15, 15, 15, .5)') : 'rgba(247, 251, 250, .3)',
              }}
            >
              No comments
            </Typography>
          ) : (
            <List
              sx={{
                overflow: 'scroll',
                maxHeight: '550px',
                width: '100%',
                color: 'rgba(247, 251, 250, 1)',
                pt: '0',
              }}
            >
              {livestream?.comments?.map((c: any) => (
                <LivestreamComment key={c?.id} comment={c} status={livestream?.status} handleRefetch={handleRefetch} />
              ))}
            </List>
          )}
        </Grid>
      </Grid>

      <Grid container sx={{ position: 'relative', bgcolor: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(15, 15, 15, 1)' : 'rgba(247, 251, 250, 1)') : 'rgba(15, 15, 15, 1)' }}>
        <Grid
          item
          xl={8}
          xs={8}
          sm={8}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            p: '10px 5% 0',
          }}
        >
          <Fab
            variant="extended"
            component={loggedPlayer?.id ? Link : 'div'}
            onClick={loggedPlayer?.id ? undefined : handleOpenLogin}
            to={livestream?.author?.logo || livestream?.author?.company ? `/${(livestream?.author?.company || livestream?.author)?.name}` : livestream?.author?.avatar ? `/u/${livestream?.author?.username}` : livestream?.author?.__typename === 'FootballTeam' ? `/football_team/${livestream?.author?.short_id}` : ''}
            sx={{ 'bgcolor': 'transparent', 'boxShadow': 'none', '&:hover': { bgcolor: 'transparent' } }}
          >
            <Avatar src={(livestream?.author?.logo || livestream?.author?.company?.logo || livestream?.author?.avatar)?.path} alt={livestream?.author?.name || livestream?.author?.company?.label} />
            &nbsp;<Typography sx={{ fontSize: '1em', textTransform: 'none' }}>{livestream?.author?.name || livestream?.author?.company?.label}</Typography>
          </Fab>

          <Chip
            variant="outlined"
            label={
              <Grid sx={{ display: 'flex', alignItems: 'center', color: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15, 15, 15, 1)') : 'rgba(247, 251, 250, 1)' }}>
                <People />
                &nbsp;{livestream && <strong>{livestream?.participants?.length + (livestream?.anonymous_participants ?? 0)}</strong>}
              </Grid>
            }
          />
          {livestream?.status === 'ON_GOING' ? (
            <>
              <Typography variant="body1" sx={{ fontWeight: '800', display: 'inline-flex', alignItems: 'center', justifyContent: 'start' }}>
                &nbsp;&nbsp;
                <LiveIcon sx={{ fontSize: '3em !important' }} className="is_live" />
                &nbsp;&nbsp;
                <Timer eventTime={livestream?.created_at} />
              </Typography>
            </>
          ) : (
            ''
          )}

          {loggedPlayer?.id && livestream?.status === 'ENDED' ? (
            <>
              <IconButton onClick={() => setOpenClip(true)} sx={{ ml: '10px' }}>
                <FlexIcon />
              </IconButton>
              <NewLivestreamClipModal livestream_id={livestream?.id} video_url={livestream?.video_url} open={openClip} handleClose={() => setOpenClip(false)} />
            </>
          ) : (
            ''
          )}
        </Grid>
        <Grid
          item
          xl={4}
          xs={4}
          sm={4}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            p: '0',
          }}
        >
          {!session?.token?.key ? (
            ''
          ) : (
            <Controller
              name="flexer_id"
              control={control}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  // label={`As`}
                  select
                  variant="outlined"
                  className="no-borders"
                  SelectProps={{
                    IconComponent: undefined,
                    sx: {
                      'color': 'rgba(247, 251, 250, 1)',
                      'border': 'none',
                      'bgcolor': 'transparent',
                      '& #mui-component-select-flexer_id': { p: '10px 0' },
                      '&::-ms-expand': {
                        display: 'none',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgba(247, 251, 250, 1)',
                      p: '0 6px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      width: '100%',
                    },
                  }}
                  sx={{ m: '0', p: '0', width: 'auto' }}
                >
                  {ALL_FLEXERS.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          src={option?.avatar}
                          sx={{
                            width: '25px',
                            height: '25px',
                          }}
                        />
                        &nbsp;&nbsp;
                        <Typography variant="caption">{option.name}</Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
        </Grid>
        <Grid container id="max-width" sx={{ p: '0 5%' }}>
          {livestream?.author?.id === loggedPlayer?.id ? (
            <Accordion sx={{ bgcolor: 'rgba(247, 251, 250, .04)', color: 'rgba(247, 251, 250, 1)', width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'rgba(247, 251, 250, 1)' }} />} aria-controls="panel1-content" id="panel1-header">
                {livestream?.sponsorships?.length} sponsorships
              </AccordionSummary>
              <AccordionDetails>
                <Grid container sx={{ p: '20px 0', justifyContent: 'center' }}>
                  {livestream?.sponsorships?.map((s: any) => (
                    <Grid key={s?.id} item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ p: '20px' }}>
                      <Card sx={{ bgcolor: 'rgba(247, 251, 250, .04)', color: 'rgba(247, 251, 250, 1)' }}>
                        {s?.proposals?.find((p: any) => p?.status === 'ACCEPTED') ? (
                          <Grid component={s?.status === 'ACQUIRED' && s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? Link : 'span'} to={s?.status === 'ACQUIRED' && s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? `${REACT_APP_LILENEK_URL}/${s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name}` : ''} target="_blank">
                            <CardMedia component="img" height="150" src={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.banner?.path} alt={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.label} />
                          </Grid>
                        ) : (
                          ''
                        )}
                        <CardHeader
                          avatar={<Avatar src={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.logo?.path} alt={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.label} />}
                          title={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.label || s?.title}
                          subheader={
                            <>
                              {intl.formatMessage({ id: 'Tournament_Sponsor_For' })}&nbsp;{s?.subject?.__typename}:&nbsp;{_.truncate(s?.subject?.title || s?.subject?.name)}
                            </>
                          }
                          subheaderTypographyProps={{ color: 'rgba(247, 251, 250, .3)' }}
                          component={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? Link : 'div'}
                          to={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? `${REACT_APP_LILENEK_URL}/${s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name}` : ''}
                          target="_blank"
                          sx={{ minHeight: '92px', textDecoration: 'none', color: 'inherit' }}
                        />

                        {s?.author?.id === loggedPlayer?.id ? (
                          <Accordion sx={{ bgcolor: 'rgba(247, 251, 250, .04)', color: 'rgba(247, 251, 250, 1)' }}>
                            <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'rgba(247, 251, 250, 1)' }} />} aria-controls="panel1-content" id="panel1-header">
                              <Typography>
                                Price: {s?.price?.currency?.currency_code} {s?.price?.value}
                                &nbsp;-&nbsp;Proposals: {s?.proposals?.length}
                                <br />
                                <Typography variant="caption" sx={{ color: 'inherit', textTransform: 'uppercase' }}>
                                  {`${intl.formatMessage({ id: 'Global_Status' })}`}:&nbsp;<strong className={s?.status === 'PENDING' ? '' : s?.status}>{s?.status}</strong>
                                </Typography>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography sx={{ textAlign: 'center', fontWeight: '800', fontSize: '1.3em' }}>{s?.title}</Typography>
                              {s?.proposals?.length === 0 ? (
                                <Typography
                                  variant="h3"
                                  sx={{
                                    width: '100%',
                                    p: '20px',
                                    fontSize: '1.3em',
                                    textAlign: 'center',
                                    color: 'rgba(247, 251, 250, .3)',
                                  }}
                                >
                                  No proposals
                                </Typography>
                              ) : (
                                <Grid container sx={{ maxHeight: '300px', overflow: 'scroll' }}>
                                  <Stack direction="column" sx={{ width: '100%' }}>
                                    {s?.proposals?.map((p: any) => (
                                      <CardMedia
                                        key={p?.id}
                                        sx={{
                                          display: 'inline-flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderRadius: '20px',
                                          p: '0',
                                          height: '200px',
                                          width: '100%',
                                          mb: '20px',
                                          textAlign: 'center',
                                          backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15 , .6) 0%,rgba(15, 15, 15 , .8) 95%), url(${p?.banner?.path})`,
                                        }}
                                      >
                                        <Grid>
                                          <CardHeader avatar={<Avatar src={p?.author?.company?.logo?.path} alt={p?.author?.company?.label} />} title={p?.author?.company?.label} titleTypographyProps={{ fontSize: '1.3em', fontWeight: '800' }} component={p?.author?.company?.name ? Link : 'div'} to={p?.author?.company?.name ? `${REACT_APP_LILENEK_URL}/${p?.author?.company?.name}` : ''} target="_blank" sx={{ pt: '0', pb: '0', textDecoration: 'none', color: 'inherit' }} />
                                          <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
                                            {`${intl.formatMessage({ id: 'Global_Status' })}`}:&nbsp;<strong className={p?.status === 'PENDING' ? '' : p?.status}>{p?.status}</strong> - {p?.price?.currency?.currency_code} {p?.price?.value}
                                          </Typography>
                                          <Grid container sx={{ p: '10px 0', justifyContent: 'center' }}>
                                            <Fab onClick={() => setOpenViewSponsorshipProposal({ sponsorship_proposal_id: p?.id, isOpen: true })} variant="extended" size="small" className="button-green" sx={{ boxShadow: 'none', m: '0 10px' }}>
                                              {`${intl.formatMessage({ id: 'Global_Button_View' })}`}
                                            </Fab>
                                          </Grid>
                                        </Grid>
                                      </CardMedia>
                                    ))}
                                  </Stack>

                                  <ViewSponsorshipProposalModal sponsorship_proposal_id={openViewSponsorshipProposal.sponsorship_proposal_id} open={openViewSponsorshipProposal.isOpen} handleClose={handleCloseViewSponsorshipProposal} />
                                </Grid>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          ''
                        )}
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : (
            ''
          )}

          {!session?.token?.key ? (
            ''
          ) : (
            <>
              <Controller
                name="comment_input"
                control={control}
                rules={{ required: false, pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._[]\s-]{5,60}$/ }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    placeholder="Write a comment..."
                    fullWidth
                    value={_.trimStart(field.value)}
                    multiline
                    minRows={1}
                    disabled={newComment.loading}
                    InputProps={{
                      startAdornment: (
                        <Controller
                          control={control}
                          name="selected_mood"
                          render={({ field }: any) => (
                            <TextField {...field} value={field.value} select variant="outlined" SelectProps={{ MenuProps: { sx: { '& li .MuiGrid-root': { justifyContent: 'start', fontWeight: '500', color: 'rgba(15, 15, 15, 1)' } } } }}>
                              {MOODS?.map((option) => (
                                <MenuItem key={option?.value} value={option?.value} onClick={() => handleChangeMood(option?.value)}>
                                  <Grid container sx={{ justifyContent: 'center', fontWeight: '700', color: 'rgba(247, 251, 250, .4)' }}>
                                    {option?.icon}&nbsp;&nbsp;
                                    {intl.formatMessage({ id: option?.label })}
                                  </Grid>
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ justifyContent: 'center', alignItems: 'center', p: { xs: '40px 0 10px', sm: '0' } }}>
                          <IconButton onClick={handleCreateComment}>
                            <Send />
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'color': 'rgba(247, 251, 250, 1)', 'mt': '0', 'mb': '0', '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& textarea': { borderRadius: '10px', bgcolor: 'rgba(247, 251, 250, .04)', p: '10px 0px 10px 5px' } },
                    }}
                    sx={{ mb: '20px', bgcolor: 'rgba(247, 251, 250, 0)', borderRadius: '10px' }}
                  />
                )}
              />
            </>
          )}

          <Grid id="max-width" className="comments" item xs={12} sm={12} md={12} xl={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, pt: session?.token?.key ? '0px' : '0', pl: '5%', pr: '5%', pb: '100px' }}>
            {livestream?.comments?.length === 0 ? (
              <Typography
                variant="h3"
                sx={{
                  p: '20px 20px 0',
                  fontSize: '1.3em',
                  fontWeight: '100',
                  textAlign: 'center',
                  color: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15, 15, 15, .5)') : 'rgba(247, 251, 250, .3)',
                }}
              >
                No comments
              </Typography>
            ) : (
              <List
                sx={{
                  overflow: 'scroll',
                  maxHeight: '720px',
                  width: '100%',
                  color: 'rgba(247, 251, 250, 1)',
                }}
              >
                {livestream?.comments?.map((c: any) => (
                  <LivestreamComment key={c?.id} comment={c} status={livestream?.status} handleRefetch={handleRefetch} />
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </Grid>

      <LoginModal open={openLogin} handleClose={() => setOpenLogin(false)} avatar_size={{ xs: 90, sm: 100, md: 110, lg: 200 }} title_size={{ xs: '1.5rem', sm: '2rem', md: '2.5rem' }} />
    </Grid>
  );
};

export default React.memo(Livestream);
