import React from 'react';
import { useAppSelector } from '../redux/hooks';
import { selectPlayer } from '../reducers/playerSlice';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
// import 'moment/locale/es';
// import 'moment/locale/ar';
// import 'moment/locale/ja';
import 'moment/locale/nl';
// import 'moment/locale/zh-cn';
// import 'moment/locale/zh-hk';
// import 'moment/locale/zh-tw';
import Moment from 'react-moment';
import moment from 'moment';

const MomentLocales: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const newLang = loggedPlayer?.app_configuration?.prefered_language?.toLowerCase() ?? 'en';
  moment.locale(newLang);
  Moment.globalLocale = newLang;

  return <></>;
};

export default MomentLocales;
