import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';

import { Button, Avatar, TextField, MenuItem, Grid, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete, Clear } from '@mui/icons-material';
import { FlexIcon, LogoLilenekIcon } from '../../img/icons/Icons';

import { useAppSelector } from '../../redux/hooks';

import BackButton from '../../component-modals/common/BackButton';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';

interface IFormEditFlex {
  flex?: { id?: string };
}

const FormEditFlex: React.FC = () => {
  const FIND_FLEX_TO_EDIT_BY_ID_QUERY = gql`
    query findFlexToEditByID($id: ID!) {
      findFlexToEditByID(id: $id) {
        id
        short_id
        caption
        pictures {
          id
          path
        }
        author {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
      }
    }
  `;

  const EDIT_FLEX_MUTATION = gql`
    mutation editFlex($flex: inputEditFlex!) {
      editFlex(flex: $flex) {
        id
        short_id
      }
    }
  `;

  const DELETE_FLEX_MUTATION = gql`
    mutation deleteFlex($flex: inputDeleteFlex!) {
      deleteFlex(flex: $flex)
    }
  `;

  const location: any = useLocation();
  const state: IFormEditFlex = location?.state;
  const navigate = useNavigate();

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [flex, setFlex] = React.useState(UNDEFINED_AS_ANY);
  const [progress, setProgress] = React.useState(0);

  const [openDeleteFlex, setOpenDeleteFlex] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      caption: undefined,
      flexer_id: '',
      products_flexed: ARRAY_AS_ANY,
      articles_flexed: ARRAY_AS_ANY,
      services_flexed: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();

  const [editFlex, updatedFlex] = useMutation(EDIT_FLEX_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteFlex, deletedFlex] = useMutation(DELETE_FLEX_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedFlex = useQuery(FIND_FLEX_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { id: state?.flex?.id },
  });

  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const handleClose = () => {
    setOpenDeleteFlex(false);
  };

  const handleEditFlex = async () => {
    try {
      setProgress(1);

      await editFlex({
        variables: {
          flex: {
            id: flex?.id,
            caption: _.trim(inputFields.caption),
          },
        },
      });

      navigate(`/f/${updatedFlex.data?.editFlex?.short_id}`, {
        replace: true,
      });
    } catch (e) {
      console.error(e);
      navigate(`/f/${flex?.short_id}`, {
        replace: true,
      });
    }
  };

  const handleDeleteFlex = async (flex_id: string) => {
    try {
      await deleteFlex({
        variables: {
          flex: {
            logged_user_id: loggedPlayer?.id,
            logged_user_username: loggedPlayer?.username,
            id: flex_id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  React.useEffect(() => {
    (async () => {
      if (flex === undefined && loadedFlex.called && loadedFlex.data?.findFlexToEditByID) {
        setFlex(loadedFlex.data?.findFlexToEditByID);
      }

      if (flex !== undefined) {
        if (inputFields.caption === undefined) setValue('caption', flex?.caption);
        if (inputFields.flexer_id === '') setValue('flexer_id', flex?.author?.id);
      }

      if (deletedFlex.called && deletedFlex.data?.deleteFlex > 0) {
        navigate(`/profile`, { replace: true, state: { flex: { id: flex?.id, is_removed: true } } });
      }
    })();
  }, [flex, loadedFlex, inputFields, setValue, updatedFlex, deletedFlex, navigate]);

  return (
    <Grid container className={'form-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <LogoLilenekIcon sx={{ width: '150px', height: 'auto', p: '20px 0 0 0' }} />
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Typography id="max-width" variant="h1" sx={{ fontSize: { xs: '1.4em', sm: '1.6em', md: '2em' }, fontWeight: '100', p: '0 10%' }}>
          Edit Flex
        </Typography>

        <form className={'form-root'}>
          <Grid id="max-width" sx={{ p: '30px 10% 0' }}>
            <Controller name="caption" control={control} rules={{ required: false }} render={({ field }: any) => <TextField {...field} label="Caption" fullWidth value={_.trimStart(field.value)} multiline minRows={3} sx={{ mb: '20px' }} />} />

            <Controller
              name="flexer_id"
              control={control}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label={`Publish as ${ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.name}`}
                  select
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  sx={{
                    ml: { xs: '0', sm: '3%', md: '3%' },
                    width: {
                      xs: '100%',
                      sm: '64%',
                      md: '64%',
                    },
                  }}
                >
                  {ALL_FLEXERS.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar src={option?.avatar} />
                        &nbsp;&nbsp;{option.name}
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Grid className={'actions'} sx={{ p: '50px 0 0' }}>
              <LoadingButton className={'button-red-reversed'} loading={deletedFlex.loading} loadingPosition="end" endIcon={<Delete />} onClick={() => setOpenDeleteFlex(true)}>
                Delete
              </LoadingButton>
              <Button className={'button-cancel'} onClick={() => navigate(-1)} endIcon={<Clear />}>
                Cancel
              </Button>

              <LoadingButton className={'button-green'} onClick={handleEditFlex} loading={updatedFlex.loading || progress > 0} loadingPosition="end" endIcon={<FlexIcon />} sx={{ m: '0 10px' }}>
                Save updates
              </LoadingButton>
            </Grid>
          </Grid>
        </form>

        <DeleteConfirmationModal open={openDeleteFlex} title={`Delete ${_.truncate(flex?.short_id)}`} entityName={'flex'} entityId={flex?.id} loading={deletedFlex.loading} actionOne={handleDeleteFlex} actionOneTitle={'Delete'} actionTwo={handleClose} actionTwoTitle={'Cancel'} />
      </Grid>
    </Grid>
  );
};

export default FormEditFlex;
