import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

interface ITimer {
  eventTime: number;
}

const Timer: React.FC<ITimer> = (props: ITimer) => {
  // Function to calculate the duration since the eventTime
  const calculateDuration = (eventTime: number): moment.Duration => moment.duration(Math.floor(Date.now() / 1000) - Math.floor(eventTime / 1000), 'seconds');

  const [duration, setDuration] = React.useState<moment.Duration>(calculateDuration(props.eventTime));
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const timerCallback = React.useCallback(() => {
    setDuration(calculateDuration(props.eventTime));
  }, [props.eventTime]);

  // Helper function to pad numbers with leading zeros
  const padNumber = (num: number) => String(num).padStart(2, '0');

  React.useEffect(() => {
    timerRef.current = setInterval(timerCallback, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [props.eventTime, timerCallback]);

  return (
    <Typography component="span" sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
      {duration.days() > 0 ? `${duration.days()} ${duration.days() > 1 ? 'days' : 'day'} ` : ''}
      {duration.hours() > 0 ? `${padNumber(duration.hours())}:` : '00:'}
      {duration.minutes() > 0 ? `${padNumber(duration.minutes())}:` : '00:'}
      {`${padNumber(duration?.seconds() || 0)}`}
    </Typography>
  );
};

export default React.memo(Timer);
