import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Alert, Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ReactCodeInput from 'react-code-input';
import { Send, Sms, Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import AlertPopup from '../common/AlertPopup';
import { GET_PIN_CODE } from '../../utilities/utilities';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { AppContext } from '../../App';
import { FormattedMessage } from 'react-intl';

const ForgotPasswordForm: React.FC = () => {
  const SEND_RESET_PASSWORD_ACCOUNT_MUTATION = gql`
    mutation sendResetPasswordAccount($account: inputSendResetPasswordAccount!) {
      sendResetPasswordAccount(account: $account)
    }
  `;

  const SEND_SMS_MUTATION = gql`
    mutation sendSms($mobile: String!, $content: String!) {
      sendSms(mobile: $mobile, content: $content)
    }
  `;

  const SEND_EMAIL_PIN_MUTATION = gql`
    mutation sendEmailPin($account: inputSendEmailPin!) {
      sendEmailPin(account: $account)
    }
  `;

  const FIND_EXISTING_IDENTIFIER_QUERY = gql`
    query findAccountIdentifier($identifier: String!) {
      findAccountIdentifier(identifier: $identifier)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const appContext = React.useContext(AppContext);
  const { control, watch, reset } = useForm({
    defaultValues: {
      identifier: '',
      pin_code: '',
      password: '',
      password_confirmation: '',
    },
  });
  const inputFields = watch();

  const [hidePassword, setHidePassword] = React.useState(false);
  const [sendSMS, setSendSMS] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const [sendResetPasswordAccount, newPassword] = useMutation(SEND_RESET_PASSWORD_ACCOUNT_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });
  const [sendSms, sentSMS] = useMutation(SEND_SMS_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });
  const [sendEmailPin, sentEmailPin] = useMutation(SEND_EMAIL_PIN_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });
  const isExistingIdentifier = useQuery(FIND_EXISTING_IDENTIFIER_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: { identifier: inputFields.identifier },
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleSendPinCode = async () => {
    try {
      const code = GET_PIN_CODE(6);
      console.log(code);
      // TODO: remove below line
      setPin(code);
      await sendSms({
        variables: {
          mobile: inputFields.identifier,
          content: `Hello friend, Your Fivezer verification code is: ${code}`,
        },
      });
      setPin(code);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSendPin = () => {
    inputFields.identifier?.includes('@') && inputFields.identifier?.includes('.') ? handleSendEmailPin() : handleSendPinCode();
    setSendSMS(true);
    // reset({ pin_code: '' })
  };

  const handleValidatePin = () => {
    if (inputFields.pin_code.length === 6) {
      if (inputFields.pin_code === pin.toString()) {
        handleNext();
        setPin('');
        // reset({ pin_code: '' });
      }
      setPin('');
      // reset({pin_code: ''});
    }
  };

  const handleSendEmailPin = async () => {
    try {
      const code = GET_PIN_CODE(6);
      console.log(code);
      setPin(code);

      await sendEmailPin({
        variables: {
          account: {
            address: inputFields.identifier,
            pin_code: code,
            language: loggedPlayer?.app_configuration?.prefered_language,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendResetPassword = async () => {
    try {
      setIsSubmitted(true);

      if (isExistingIdentifier.data?.findAccountIdentifier) {
        await sendResetPasswordAccount({
          variables: {
            account: {
              identifier: inputFields.identifier,
              new_password: inputFields.password,
              language: loggedPlayer?.app_configuration?.prefered_language,
            },
          },
        });
      }

      reset();
      setPin('');
    } catch (e) {
      console.log(e);
    }
  };

  const steps = [
    {
      label: 'Identifier',
      content: (
        <Grid sx={{ pt: '10px' }}>
          <Controller
            name="identifier"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 40,
              minLength: 4,
              pattern: /[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)/,
            }}
            render={({ field }: any) => <TextField {...field} label="Email/Mobile" type={'text'} placeholder="example@email.com or +0000000000" fullWidth required className={'field-bottom-space'} sx={{ '& .MuiInputBase-root': { color: 'rgba(255,255,255,1) !important' }, '& fieldset': { borderColor: 'rgba(255,255,255,.2) !important' } }} />}
          />
        </Grid>
      ),
    },
    {
      label: 'PIN Verification',
      content: (
        <Grid sx={{ pt: '10px' }}>
          {sentSMS.loading || sentEmailPin.loading || sentEmailPin.data?.sendEmailPin ? (
            <>
              {sentEmailPin.loading ? (
                <Typography variant={'caption'} sx={{ textAlign: 'center', width: '100%', display: 'inline-block', p: '20px 0', fontWeight: '100', color: 'rgba(31, 175, 146, .7)' }}>
                  <CircularProgress className="green-loader" size={15} />
                  &nbsp;&nbsp;please wait...
                </Typography>
              ) : (
                ''
              )}

              {sentEmailPin.data?.sendEmailPin ? (
                <>
                  <Typography sx={{ textAlign: 'center', fontWeight: '700', width: '100%' }}>
                    <FormattedMessage id="ForgotPasswordForm_Is_Email_Submitted" />
                    &nbsp;🎉
                  </Typography>
                  <Controller name="pin_code" control={control} defaultValue="" rules={{ required: true, pattern: /^[0-9]*$/ }} render={({ field }: any) => <ReactCodeInput {...field} name="sms-pin" type="text" fields={6} inputMode="numeric" autoFocus={false} />} />
                </>
              ) : (
                <>
                  {sentEmailPin.error ? (
                    <Typography sx={{ textAlign: 'center', fontWeight: '700', width: '100%' }}>
                      <FormattedMessage id="ForgotPasswordForm_Resend_Email" />
                    </Typography>
                  ) : (
                    ''
                  )}

                  {inputFields.identifier?.includes('@') && inputFields.identifier?.includes('.') ? (
                    <LoadingButton
                      onClick={handleSendPin}
                      disabled={!inputFields.identifier?.includes('@') || !inputFields.identifier?.includes('.')}
                      loading={sentSMS.loading}
                      loadingPosition="end"
                      loadingIndicator={<FormattedMessage id="ForgotPasswordForm_Sending_SMS" />}
                      className={!inputFields.identifier?.includes('@') || !inputFields.identifier?.includes('.') ? 'button-disabled' : 'button-green-reverse'}
                      // startIcon={sendSMS ? <Sms /> : ""}
                      endIcon={sentEmailPin ? <Sms /> : <Send />}
                    >
                      <FormattedMessage id="ForgotPasswordForm_Send_Via_Email" />
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      onClick={handleSendPin}
                      disabled={inputFields.identifier === ''}
                      loading={sentSMS.loading}
                      loadingPosition="end"
                      loadingIndicator={<FormattedMessage id="ForgotPasswordForm_Sending_SMS" />}
                      className={inputFields.identifier === '' ? 'button-disabled' : 'button-green-reverse'}
                      // startIcon={sendSMS ? <Sms /> : ""}
                      endIcon={sendSMS ? <Sms /> : <Send />}
                    >
                      <FormattedMessage id="ForgotPasswordForm_Send_Via_SMS" />
                    </LoadingButton>
                  )}
                </>
              )}
            </>
          ) : (
            <Grid container sx={{ mb: '30px' }}>
              {pin === '' ? (
                <>
                  {inputFields.identifier?.includes('@') && inputFields.identifier?.includes('.') ? (
                    <LoadingButton
                      onClick={handleSendPin}
                      disabled={!inputFields.identifier?.includes('@') || !inputFields.identifier?.includes('.')}
                      loading={sentSMS.loading}
                      loadingPosition="end"
                      loadingIndicator="Sending SMS..."
                      className={!inputFields.identifier?.includes('@') || !inputFields.identifier?.includes('.') ? 'button-disabled' : 'button-green-reverse'}
                      // startIcon={sendSMS ? <Sms /> : ""}
                      endIcon={sentEmailPin ? <Sms /> : <Send />}
                    >
                      Send PIN via Email
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      onClick={handleSendPin}
                      disabled={inputFields.identifier === ''}
                      loading={sentSMS.loading}
                      loadingPosition="end"
                      loadingIndicator="Sending SMS..."
                      className={inputFields.identifier === '' ? 'button-disabled' : 'button-green-reverse'}
                      // startIcon={sendSMS ? <Sms /> : ""}
                      endIcon={sendSMS ? <Sms /> : <Send />}
                    >
                      Send PIN via SMS
                    </LoadingButton>
                  )}
                </>
              ) : (
                <Controller name="pin_code" control={control} defaultValue="" rules={{ required: true, pattern: /^[0-9]*$/ }} render={({ field }: any) => <ReactCodeInput {...field} name="sms-pin" type="text" fields={6} inputMode="numeric" autoFocus={false} />} />
              )}
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      label: 'Validation',
      content: (
        <Grid sx={{ pt: '10px' }}>
          <Grid container className="field-bottom-space">
            {isSubmitted ? (
              <>
                <AlertPopup open={isSubmitted} message={'Your password has been updated, please try to login!'} type={'success'} link={window.location.href} cta={'Login now'} />
                <Typography sx={{ textAlign: 'center', fontWeight: '700', width: '100%' }}>Your password has been updated! 🎉</Typography>
              </>
            ) : (
              <>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="New Password"
                      type={hidePassword ? 'text' : 'password'}
                      fullWidth
                      required
                      className={'field-bottom-space'}
                      sx={{ '& .MuiOutlinedInput-input': { color: 'rgba(247, 251, 250, 1)' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(247, 251, 250, 0.3)' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                              {hidePassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="password_confirmation"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="New Password confirmation"
                      type={hidePassword ? 'text' : 'password'}
                      fullWidth
                      required
                      className={'field-bottom-space'}
                      sx={{ '& .MuiOutlinedInput-input': { color: 'rgba(247, 251, 250, 1)' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(247, 251, 250, 0.3)' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                              {hidePassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <LoadingButton
                  onClick={handleSendResetPassword}
                  loading={newPassword.loading}
                  loadingPosition="end"
                  // startIcon={newPassword.loading ? '' : <AccountCircle />}
                  endIcon={<Send />}
                  fullWidth
                  disabled={inputFields.pin_code === undefined || inputFields.password_confirmation !== inputFields.password || inputFields.password_confirmation === '' || inputFields.password === ''}
                  className={inputFields.pin_code === undefined || inputFields.password_confirmation !== inputFields.password || inputFields.password_confirmation === '' || inputFields.password === '' ? 'button-disabled' : 'button-green'}
                >
                  Reset password
                </LoadingButton>
              </>
            )}
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} sx={{ mb: '30px' }}>
        <Stepper activeStep={activeStep} orientation="vertical" className="dark-stepper">
          {steps
            .filter((s: any, index: number) => index <= activeStep)
            .map((step, index) => (
              <Step key={step.label}>
                <StepLabel optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}>{step.label}</StepLabel>
                <StepContent>
                  <Grid item xs={12} md={12} lg={12}>
                    {step.content}
                  </Grid>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {index > 0 ? (
                        isSubmitted ? (
                          <Button className="button-green" href={`${window.location.href}`} sx={{ mt: 1, mr: 1, width: '100%' }}>
                            Login
                          </Button>
                        ) : (
                          <Button className="button-cancel" disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                            Back
                          </Button>
                        )
                      ) : (
                        ''
                      )}
                      {index === steps.length - 1 ? (
                        ''
                      ) : sendSMS && index === 1 ? (
                        <Button className={inputFields.pin_code.length !== 6 ? 'button-disabled' : 'button-green-reverse'} onClick={handleValidatePin} sx={{ mt: 1, mr: 1 }} disabled={inputFields.pin_code.length !== 6}>
                          PIN Code Verification
                        </Button>
                      ) : (
                        ''
                      )}
                      {index === steps.length - 1 ? (
                        ''
                      ) : index < 1 ? (
                        appContext?.currentPosition === undefined ? (
                          <Alert severity="warning">Turn on your location to fully secure your account</Alert>
                        ) : (
                          <Button
                            onClick={handleNext}
                            sx={{
                              mt: 1,
                              mr: 1,
                            }}
                            disabled={inputFields.identifier === ''}
                            className={inputFields.identifier === '' ? 'button-disabled' : 'button-green-reverse'}
                          >
                            Continue
                          </Button>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  children: PropTypes.node,
};
