import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import storage from './firebase';
import CryptoJS from 'crypto-js';
import { REACT_APP_ENCRYPT_KEY } from '../config';

export const DECRYPT_DATA = (data: string) => {
  const result = CryptoJS.AES.decrypt(data, REACT_APP_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8);
  return result;
};

export const ENCRYPT_DATA = (data: string) => {
  const result = CryptoJS.AES.encrypt(data, REACT_APP_ENCRYPT_KEY).toString();
  return result;
};

export const SEND_NOTIFICATION = async (title: string, content: string, icon: string) => {
  if (Notification.permission === 'granted') {
    new Notification(title, { body: content, icon: icon });
  } else {
    if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        new Notification(title, { body: content, icon: icon });
      }
    }
  }
};

export const dataURLtoBlobURL = (dataURI: any) => {
  if (!dataURI || typeof dataURI !== 'string') {
    console.error('Invalid or missing dataURI:', dataURI);
    return '';
  }

  // Check if the dataURI starts with "data:" (indicating a Data URL)
  if (!dataURI.startsWith('data:')) {
    console.error('Invalid dataURI format. It should start with "data:"');
    console.log('dataURI', dataURI); // Log the invalid dataURI for debugging
    return '';
  }

  // Split the dataURI into metadata and base64 data parts
  const parts = dataURI.split(',');

  if (parts.length !== 2) {
    console.error('Invalid dataURI format. Expected two parts separated by a comma.');
    return '';
  }

  const [metadata, base64Data] = parts;

  // Check if the metadata part includes a valid MIME type
  const mimeMatch = metadata.match(/data:(.*?);/);
  if (!mimeMatch) {
    console.error('Invalid MIME type in dataURI metadata:', metadata);
    return '';
  }

  // Extract the MIME type from the metadata
  const mimeType = mimeMatch[1];

  try {
    // Attempt to decode the base64 data
    const byteString = atob(base64Data);

    // Write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the ArrayBuffer
    const blob = new Blob([ab], { type: mimeType });

    // Create a blob URL and return it
    const url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    console.error('Error decoding base64 data:', error);
    return '';
  }
};

export const dataURLtoBlobURL_PDF = (data: string) => {
  try {
    if (data) {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }
      return new Blob([out], { type: 'application/pdf' });
    }
  } catch (e) {
    console.log(e);
  }
};

export const blobURLtoDataURL = async (blobURL: any) => {
  try {
    const response = await fetch(blobURL);
    const blobData = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        const dataURL = reader.result;
        resolve(dataURL);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(blobData);
    });
  } catch (error) {
    console.error('Error fetching or converting the blob data:', error);
    throw error; // Re-throw the error for handling outside this function, if needed.
  }
};

export const blobURLtoImageFile = async (blobURL: any) => {
  try {
    const response = await fetch(blobURL);
    const blobData = await response.blob();

    // Create a new File object with the blob data
    const imageFile = new File([blobData], `cropped_image-${Date.now()}.jpg`, { type: blobData.type });

    return imageFile;
  } catch (error) {
    console.error('Error fetching or converting the blob data:', error);
    throw error; // Re-throw the error for handling outside this function, if needed.
  }
};

export const createImage = async (url: any) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0, flip = { horizontal: false, vertical: false }) {
  try {
    // const image: any = await createImage(imageSrc);
    const image: any = new Image();
    if (imageSrc.startsWith('data:')) {
      // If imageSrc is a data URL, set the src directly
      image.src = imageSrc;
    } else {
      // If imageSrc is a URL path, load the image asynchronously
      const loadImage = new Promise((resolve, reject) => {
        image.onload = () => resolve(image);
        image.onerror = (error: any) => reject(error);
      });
      image.src = imageSrc;
      await loadImage;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');

    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(canvas, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

    // As Base64 string
    // return croppedCanvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      croppedCanvas.toBlob((file: any) => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg');
    });
  } catch (e) {
    console.log(e);
  }
}

export function isAspectRatioRespected(dataURL: any, desiredRatio: number) {
  const image = new Image();
  image.src = dataURL;

  return new Promise((resolve, reject) => {
    image.addEventListener('load', () => {
      const aspectRatio = parseFloat((image.width / image.height).toFixed(2));

      const isMatch = aspectRatio === desiredRatio;
      resolve(isMatch);
    });

    image.addEventListener('error', () => {
      reject(new Error('Error loading image.'));
    });
  });
}

export const handleUpload = async (img: any, value: string[], urlSetter: any, session: any, loggedUser_id: string) => {
  if (img === undefined || img.name === undefined) return '';

  const storageRef = ref(storage, `${session.app_client.name.toLowerCase()}/${loggedUser_id}/images/${`${Date.now()}-` + img.name}`);

  await uploadBytes(storageRef, img)
    .then(() => {
      // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    })
    .catch((e) => {
      console.log(e);
    });

  await getDownloadURL(storageRef)
    .then((url: string) => {
      const newUrl = value;
      newUrl.push(url);
      urlSetter(newUrl);
    })
    .catch((e) => {
      console.log(e);
    });
};
