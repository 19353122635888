import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useAppSelector } from '../../redux/hooks';
import { selectPersonalWallets } from '../../reducers/walletSlice';
import { DatePicker } from '@mui/x-date-pickers';
import { MenuItem, InputAdornment, TextField, Button, Grid, Typography, Chip, IconButton, useMediaQuery, Autocomplete, CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Clear } from '@mui/icons-material';
import { BankCardIcon, CardPaymentIcon, FivezerLogoIcon } from '../../img/icons/Icons';

import BackButton from '../../component-modals/common/BackButton';
import SpendingCodeModal from '../../component-modals/purchase/SpendingCodeModal';
import { CARD_NETWORKS, COUNTRIES, CURRENCIES, EXTRACT_ACCOUNT_NUMBER, TO_TIMESTAMP } from '../../utilities/utilities';
import { ARRAY_AS_ANY, CountryType, DATE_AS_ANY, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectPlayer } from '../../reducers/playerSlice';
import { Link } from 'react-router-dom';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';
import { ENCRYPT_DATA } from '../../helpers';

interface IFormAddBankCard {
  wallet: { id: string; type: string };
}

const FormAddBankCard: React.FC = () => {
  const CREATE_BANK_CARD_MUTATION = gql`
    mutation createBankCard($card: inputCreateBankCard!) {
      createBankCard(card: $card) {
        id
        status
      }
    }
  `;

  const FIND_FLUTTERWAVE_BANK_CODES_BY_COUNTRY_CODE_QUERY = gql`
    query findFlutterwaveBankCodesByCountryCode($country_code: String!) {
      findFlutterwaveBankCodesByCountryCode(country_code: $country_code) {
        id
        code
        name
      }
    }
  `;

  const FIND_FLUTTERWAVE_BANK_BRANCHES_BY_ID_QUERY = gql`
    query findFlutterwaveBankBranchesByID($bank_id: String!) {
      findFlutterwaveBankBranchesByID(bank_id: $bank_id) {
        id
        branch_code
        branch_name
        swift_code
        bic
        bank_id
      }
    }
  `;

  const location: any = useLocation();
  const navigate = useNavigate();
  const state: IFormAddBankCard = location?.state;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedAccountPersonalWallets = useAppSelector(selectPersonalWallets);

  const [wallet] = React.useState(loggedAccountPersonalWallets?.find((wallet: any) => wallet.id === state?.wallet?.id));

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openSearchCurrency, setOpenSearchCurrency] = React.useState(false);
  const isSmallMobile = useMediaQuery('(max-width:600px)');

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      card_holder: '',
      card_type: 'DEBIT',
      card_network: 'VISA',
      card_number: '',
      card_exp: DATE_AS_ANY,
      card_cvc: '',
      account_iban: '',
      account_bic: '',
      account_bank_number: '',
      all_currencies: ARRAY_AS_ANY,
      currency_name: '',
      accepted_currencies: ARRAY_AS_ANY,
      all_countries: ARRAY_AS_ANY,
      country_name: '',
      country: INITIAL_COUNTRY,
      all_banks: ARRAY_AS_ANY,
      bank_name: '',
      selected_bank: UNDEFINED_AS_ANY,
      all_branches: ARRAY_AS_ANY,
      branch_name: '',
      selected_branch: UNDEFINED_AS_ANY,
    },
  });

  const inputFields = watch();

  const [createBankCard, newBankCard] = useMutation(CREATE_BANK_CARD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedBanks = useQuery(FIND_FLUTTERWAVE_BANK_CODES_BY_COUNTRY_CODE_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      country_code: inputFields.country?.value,
    },
  });
  const loadedBranches = useQuery(FIND_FLUTTERWAVE_BANK_BRANCHES_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      bank_id: inputFields.selected_bank?.id,
    },
  });

  const ALL_PSP_BANKS = loadedBanks.data?.findFlutterwaveBankCodesByCountryCode;
  const ALL_PSP_BRANCHES = loadedBranches.data?.findFlutterwaveBankBranchesByID;

  const handleSelectBranch = (event: any, branch: any) => {
    setValue('selected_branch', branch);
  };

  const handleSelectBank = (event: any, bank: any) => {
    setValue('selected_bank', bank);
    setValue('selected_branch', UNDEFINED_AS_ANY);
  };

  const handleSelectCountry = (event: any, country: any) => {
    setValue('country', country);
    setValue('selected_bank', UNDEFINED_AS_ANY);
    setValue('selected_branch', UNDEFINED_AS_ANY);
    setValue('all_banks', ARRAY_AS_ANY);
  };

  const handleSelectCurrency = (country: any) => {
    const newList = [country];
    setOpenSearchCurrency(false);
    setValue('currency_name', '');
    //_.concat(inputFields.accepted_currencies, country);
    setValue('accepted_currencies', newList);
  };

  const handleUnselectCurrency = (country: any) => {
    const newList = inputFields.accepted_currencies?.filter((c: any) => c?.value !== country?.value);
    setValue('accepted_currencies', newList);
  };

  const handleConfirmation = async (isCodeValide: boolean) => {
    setOpenConfirmation(false);
    if (isCodeValide) {
      try {
        await createBankCard({
          variables: {
            card: {
              type: inputFields.card_type,
              network: inputFields.card_network,
              card_number: inputFields.card_number,
              card_number_encrypted: ENCRYPT_DATA(inputFields.card_number),
              card_holder: inputFields.card_holder,
              card_exp: parseFloat(TO_TIMESTAMP(inputFields.card_exp).toFixed(1)),
              card_cvc: inputFields.card_cvc,
              iban: /[a-zA-Z]/.test(inputFields.account_iban) ? inputFields.account_iban : undefined,
              bic: inputFields.selected_branch?.bic ? inputFields.selected_branch?.bic : inputFields.account_bic,
              bank_code: inputFields.selected_bank?.code,
              branch_code: inputFields.selected_branch?.branch_code,
              swift_code: inputFields.selected_branch?.swift_code,
              account_number: /[a-zA-Z]/.test(inputFields.account_iban) ? EXTRACT_ACCOUNT_NUMBER(inputFields.account_iban) : inputFields.account_iban,
              wallet_id: wallet?.id,
              currencies: inputFields.accepted_currencies?.map((c: any) => c?.value),
              country_code: COUNTRIES?.find((c: any) => c?.value === `${inputFields.account_iban[0]}${inputFields.account_iban[1]}`)?.value || inputFields.country?.value,
              country_name: COUNTRIES?.find((c: any) => c?.value === `${inputFields.account_iban[0]}${inputFields.account_iban[1]}`)?.label || inputFields.country?.label,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onSubmit = async () => {
    setOpenConfirmation(true);
  };

  React.useEffect(() => {
    (async () => {
      if (newBankCard.called && newBankCard.data?.createBankCard) {
        navigate('/w/wallet', {
          state: {
            wallet: {
              id: state?.wallet?.id,
              type: state?.wallet?.type,
            },
            bank_card: { id: newBankCard.data?.createBankCard?.id },
          },
          replace: true,
        });
      }
    })();
  });

  React.useEffect(() => {
    if ((_.startsWith(inputFields.card_number, '51') || _.startsWith(inputFields.card_number, '52') || _.startsWith(inputFields.card_number, '53') || _.startsWith(inputFields.card_number, '54') || _.startsWith(inputFields.card_number, '55')) && inputFields.card_network !== 'MASTERCARD') {
      setValue('card_network', 'MASTERCARD');
    } else if (_.startsWith(inputFields.card_number, '4') && inputFields.card_network !== 'VISA') {
      setValue('card_network', 'VISA');
    } else if ((_.startsWith(inputFields.card_number, '34') || _.startsWith(inputFields.card_number, '37')) && inputFields.card_network !== 'AMEX') {
      setValue('card_network', 'AMEX');
    } else {
      console.log('Nothing...');
    }
  }, [inputFields, setValue]);

  return (
    <Grid className={'form-page'}>
      <Grid container>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
      <Typography variant="h1" sx={{ p: '0 10%', fontSize: { xs: '1.4em', sm: '1.6em', md: '2em' }, fontWeight: '800' }}>
        Add Bank card
        <br />
        <Typography variant="caption">To {wallet?.title}</Typography>
      </Typography>

      <Grid id="max-width" sx={{ p: '30px 10%' }}>
        <form className={'form-root'} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="card_holder"
            control={control}
            rules={{
              required: true,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label="Card owner" placeholder="Input fullname on the card" variant="outlined" fullWidth helperText=" " />}
          />

          <Controller
            name="card_number"
            control={control}
            rules={{ required: true, pattern: /^[0-9]*$/ }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="Card Number"
                placeholder="XXXX XXXX XXXX XXXX"
                variant="outlined"
                value={field.value}
                fullWidth
                required
                helperText=" "
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CardPaymentIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            name="card_type"
            control={control}
            rules={{
              required: true,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                label={`Type`}
                variant="outlined"
                required
                helperText={' '}
                sx={{
                  mr: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
              >
                {['CREDIT', 'DEBIT', 'VIRTUAL_CREDIT', 'VIRTUAL_DEBIT'].map((option: any, index: number) => (
                  <MenuItem key={index} value={option}>
                    {_.capitalize(_.replace(option, '_', ' '))}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="card_network"
            control={control}
            rules={{
              required: true,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                label={`Network`}
                disabled={_.startsWith(inputFields.card_number, '4') || _.startsWith(inputFields.card_number, '51') || _.startsWith(inputFields.card_number, '52') || _.startsWith(inputFields.card_number, '53') || _.startsWith(inputFields.card_number, '54') || _.startsWith(inputFields.card_number, '55') || _.startsWith(inputFields.card_number, '34') || _.startsWith(inputFields.card_number, '37')}
                variant="outlined"
                required
                helperText={' '}
                sx={{
                  ml: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
              >
                {CARD_NETWORKS.map((option: any, index: number) => (
                  <MenuItem key={index} value={option?.name}>
                    {_.capitalize(_.replace(option?.name, '_', ' '))}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="card_exp"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => (
              <DatePicker
                {...field}
                format="MM/yyyy"
                views={['month', 'year']}
                minDate={new Date()}
                value={field.value}
                slotProps={{
                  textField: {
                    label: 'Expiration',
                    required: true,
                    placeholder: 'Input expiration date',
                    InputLabelProps: { shrink: true },
                    sx: {
                      mr: {
                        xs: '0',
                        sm: '3%',
                        md: '5%',
                      },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    },
                  },
                }}
              />
            )}
          />

          <Controller
            name="card_cvc"
            control={control}
            rules={{
              required: true,
              maxLength: 5,
              minLength: 3,
              max: 5,
              pattern: /^[0-9]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="CVC"
                type="number"
                required
                helperText="3-4 digits behind your card"
                placeholder="XXX"
                className={'field-bottom-space'}
                variant="outlined"
                sx={{
                  ml: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BankCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller control={control} name="account_iban" render={({ field }: any) => <TextField {...field} label="IBAN or Account number" type="text" autoComplete="off" placeholder="Input IBAN" variant="outlined" required fullWidth className={'field-bottom-space'} />} />

          <Controller control={control} name="account_bic" render={({ field }: any) => <TextField {...field} label="BIC" type="text" autoComplete="off" placeholder="Input BIC" variant="outlined" required fullWidth className={'field-bottom-space'} />} />

          <Controller
            name="all_currencies"
            control={control}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                open={openSearchCurrency}
                onOpen={() => setOpenSearchCurrency(true)}
                onClose={() => setOpenSearchCurrency(false)}
                multiple
                autoHighlight
                filterSelectedOptions
                value={field.value}
                loading={false}
                inputValue={inputFields.currency_name}
                onInputChange={(event: any, newInputValue: any) => {
                  setValue('currency_name', newInputValue);
                }}
                options={(CURRENCIES ? CURRENCIES : [])?.filter((cf: any) => inputFields?.accepted_currencies?.find((p: any) => p?.value === cf?.value) === undefined)}
                getOptionLabel={(option: any) => option?.value}
                defaultValue={ARRAY_AS_ANY}
                renderOption={(props: any, option: any) => (
                  <Chip
                    key={option?.value}
                    onClick={() => handleSelectCurrency(option)}
                    label={
                      <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                        <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(COUNTRIES?.find((co: any) => co?.value === option?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(COUNTRIES?.find((co: any) => co?.value === option?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                        &nbsp;{option?.label}
                      </Typography>
                    }
                    sx={{ m: '10px 10px 0 0' }}
                  />
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={`Account Currency`}
                    placeholder={`Write a currency name`}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          {isSmallMobile ? (
                            ''
                          ) : (
                            <Grid>
                              {inputFields?.accepted_currencies?.length === 0 ? (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    m: '0px',
                                    p: '5px 20px',
                                    textAlign: 'center',
                                    bgcolor: 'rgba(0, 0, 0, .1)',
                                    borderRadius: '5px',
                                  }}
                                >
                                  No account currency selected
                                </Typography>
                              ) : (
                                inputFields?.accepted_currencies?.map((p: any) => (
                                  <Chip
                                    key={p?.value}
                                    variant="outlined"
                                    label={
                                      <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(COUNTRIES?.find((co: any) => co?.value === p?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(COUNTRIES?.find((co: any) => co?.value === p?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                                        &nbsp;{p?.label}
                                      </Typography>
                                    }
                                    onDelete={() => handleUnselectCurrency(p)}
                                    sx={{ m: '5px', border: 'none', bgcolor: 'rgba(15, 15, 15, .1)' }}
                                  />
                                ))
                              )}
                            </Grid>
                          )}
                        </>
                      ),
                    }}
                    className={'field-bottom-space'}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name="country"
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                options={COUNTRIES}
                value={inputFields.country}
                className={'country-select'}
                onChange={(event: any, value: any) => handleSelectCountry(event, value)}
                autoHighlight
                getOptionLabel={(option: CountryType) => option.label}
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                renderOption={(props: any, option: CountryType) => (
                  <Grid
                    component="li"
                    sx={{
                      '& > img': {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" /> &nbsp;{option?.label}
                  </Grid>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Bank country"
                    value={inputFields.country}
                    required
                    variant="outlined"
                    className={'field-bottom-space'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    // helperText={`Make sure to select the country where your bank is located`}
                  />
                )}
              />
            )}
          />

          {loadedBanks.loading ? (
            <Grid container sx={{ textAlign: 'center', justifyContent: 'center' }}>
              <Grid>
                <CircularProgress className="orange-loader" />
                <br />
                Searching banks
                <br />
                please wait...
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          {loadedBanks.loading || !ALL_PSP_BANKS || ALL_PSP_BANKS?.length === 0 ? (
            ''
          ) : (
            <Controller
              control={control}
              name="all_banks"
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  options={ALL_PSP_BANKS}
                  value={inputFields.selected_bank}
                  onChange={(event: any, value: any) => handleSelectBank(event, value)}
                  autoHighlight
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      component="li"
                      sx={{
                        '& > img': {
                          mr: 2,
                          flexShrink: 0,
                        },
                      }}
                      {...props}
                    >
                      {option.name}
                    </Grid>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Your Bank"
                      value={inputFields.selected_bank}
                      required
                      variant="outlined"
                      className={'field-bottom-space'}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
            />
          )}

          {loadedBranches.loading || loadedBanks.loading || !ALL_PSP_BANKS || ALL_PSP_BANKS?.length === 0 || !ALL_PSP_BRANCHES || ALL_PSP_BRANCHES?.length === 0 ? (
            ''
          ) : (
            <Controller
              control={control}
              name="all_branches"
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  options={ALL_PSP_BRANCHES}
                  value={inputFields.selected_branch}
                  onChange={(event: any, value: any) => handleSelectBranch(event, value)}
                  autoHighlight
                  getOptionLabel={(option: any) => option.branch_name}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      component="li"
                      sx={{
                        '& > img': {
                          mr: 2,
                          flexShrink: 0,
                        },
                      }}
                      {...props}
                    >
                      {option.branch_name}
                    </Grid>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Your Bank branch"
                      value={inputFields.selected_branch}
                      required
                      variant="outlined"
                      className={'field-bottom-space'}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
            />
          )}

          <Typography sx={{ textAlign: 'center', p: '20px 0 40px' }}>
            Do you confirm that the bank account affiliated to your card is located{' '}
            <strong>
              {inputFields.selected_bank ? `at ${inputFields.selected_bank?.name} in ` : 'in '} {inputFields?.country?.label} {<img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(inputFields?.country?.value, '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(inputFields?.country?.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />}
            </strong>{' '}
            ?
          </Typography>

          <Grid container>
            <Grid className={'actions'}>
              <Button className={'button-cancel'} onClick={() => navigate(-1)} endIcon={<Clear />}>
                Cancel
              </Button>

              <LoadingButton
                loading={newBankCard.loading}
                onClick={() => setOpenConfirmation(true)}
                loadingPosition="end"
                endIcon={<CardPaymentIcon />}
                disabled={(ALL_PSP_BRANCHES?.length > 0 && !inputFields.selected_branch) || (ALL_PSP_BANKS?.length > 0 && !inputFields.selected_bank) || inputFields.accepted_currencies?.length === 0 || inputFields.country === undefined || inputFields.card_number === '' || inputFields.card_exp === null || inputFields.card_cvc === '' || inputFields.account_iban === '' || inputFields.account_bic === ''}
                className={(ALL_PSP_BRANCHES?.length > 0 && !inputFields.selected_branch) || (ALL_PSP_BANKS?.length > 0 && !inputFields.selected_bank) || inputFields.accepted_currencies?.length === 0 || inputFields.country === undefined || inputFields.card_number === '' || inputFields.card_exp === null || inputFields.card_cvc === '' || inputFields.account_iban === '' || inputFields.account_bic === '' ? 'button-disabled' : 'button-green'}
              >
                Add card
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <SpendingCodeModal icon={<BankCardIcon />} actionOne={handleConfirmation} actionTwo={() => setOpenConfirmation(false)} open={openConfirmation} />
    </Grid>
  );
};

export default FormAddBankCard;
