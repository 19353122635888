import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Skeleton, Stack, Fab, Card, CardMedia, Typography, MenuItem, Menu, Box, IconButton, Grid, CircularProgress } from '@mui/material';
import { Star, MoreVert, ArrowBack } from '@mui/icons-material';
import { FivezerLogoIcon, LocationIcon } from '../../img/icons/Icons';
import { FIELD_SIZES, FIELD_TYPES, SPORT_TYPES } from '../../utilities/utilities';
import { useAppSelector } from '../../redux/hooks';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectPlayer } from '../../reducers/playerSlice';
import MenuButton from '../../component-modals/layout/MenuButton';
import FormEditFieldModal from '../../component-modals/challenge/FormEditFieldModal';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';
import SelectDescriptionButton from '../../component-modals/layout/SelectDescriptionButton';
import { Helmet } from 'react-helmet';

const Field: React.FC = () => {
  const FIND_FIELD_BY_SHORT_ID_QUERY = gql`
    query findFieldByShortID($short_id: String!) {
      findFieldByShortID(short_id: $short_id) {
        id
        short_id
        name
        size
        type
        sports
        status
        pictures {
          path
        }
        descriptions {
          language
          content
        }
        equipments {
          id
          name
          pictures {
            path
          }
        }
        rental_asset {
          id
          price {
            value
            currency {
              currency_code
            }
          }
        }
        publisher {
          id
          username
          avatar {
            path
          }
        }
        location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const { fieldId }: any = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [sportField, setSportField] = React.useState(UNDEFINED_AS_ANY);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openEdit, setOpenEdit] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      description_language: '',
    },
  });
  const inputFields = watch();

  const loadedField = useQuery(FIND_FIELD_BY_SHORT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !fieldId,
    variables: {
      short_id: fieldId,
    },
    pollInterval: loggedPlayer?.id ? 300000 : undefined,
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedField.refetch({
        short_id: fieldId,
        skip: !session?.token?.key || !fieldId,
      });

      if (result) {
        setSportField(result.data?.findFieldByShortID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenEdit = () => {
    handleClose();
    setOpenEdit(true);
  };

  const handleCloseEdit = async () => {
    try {
      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenEdit(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (!_.isMatch(sportField, loadedField.data?.findFieldByShortID) || (sportField === undefined && loadedField.called && loadedField.data?.findFieldByShortID)) {
      setSportField(loadedField.data?.findFieldByShortID);
    }

    if (sportField?.descriptions !== undefined && sportField?.descriptions?.length > 0) {
      if (inputFields.description_language === '' && sportField?.descriptions[0]?.language !== undefined) setValue('description_language', sportField?.descriptions[0]?.language);
    }
  }, [sportField, loadedField, setValue, inputFields]);

  return (
    <Grid container className={'dark-mode'}>
      <Helmet>
        <title>{sportField ? `${sportField?.name} (${intl.formatMessage({ id: 'Field_Meta_Title' })})` : `${intl.formatMessage({ id: 'Field_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid container sx={{ position: 'relative', zIndex: '10' }}>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'center' }}></Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'right', p: '0 40px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      {loadedField.data?.findFieldByShortID?.id === undefined || loadedField.loading ? (
        <Grid item xl={12} xs={12} sm={12} sx={{ pb: '150px' }}>
          {loadedField.loading ? (
            <LoadingComponent
              amount={1}
              text={
                <Typography variant={'h6'} sx={{ textAlign: 'center', fontWeight: '700' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
                </Typography>
              }
              loader={<CircularProgress className="orange-loader" />}
            />
          ) : (
            ''
          )}
          {loadedField.data?.findFieldByShortID?.id === undefined && !loadedField.loading ? (
            <Grid>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'Field_Not_Found' })}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1.5em',
                  textAlign: 'left',
                  p: '5%',
                }}
              >
                {`${intl.formatMessage({ id: 'Field_Not_Found_Caption' })}`}
              </Typography>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            pb: '150px',
            pt: sportField?.pictures?.length > 0 ? '200px' : '0',
          }}
        >
          <Grid
            item
            xl={12}
            xs={12}
            sm={12}
            className={'cover-container'}
            sx={{
              backgroundImage: 'linear-gradient(to top, rgb(15,15,15) 0%, #C7C7C7 100%)',
              width: '100%',
              maxHeight: '500px',
              overflow: 'hidden',
              position: 'fixed',
              top: '0',
              left: '0',
              zIndex: '0',
            }}
          >
            {sportField?.pictures[_.random(0, sportField?.pictures?.length - 1)]?.path ? (
              <CardMedia
                component="img"
                src={sportField?.pictures[_.random(0, sportField?.pictures?.length - 1)]?.path}
                alt={'cover'}
                sx={{
                  width: {
                    xs: '170%',
                    sm: '110%',
                    md: '110%',
                    lg: '100%',
                  },
                  right: {
                    xs: '35%',
                    sm: '5%',
                    md: '5%',
                    lg: '0%',
                  },
                  position: 'relative',
                }}
              />
            ) : (
              <Box
                className={'empty-cover'}
                sx={{
                  backgroundImage: 'linear-gradient(to top, rgb(15,15,15) 0%, #C7C7C7 100%)',
                  height: '300px',
                  width: '100%',
                  position: 'relative',
                }}
              ></Box>
            )}
          </Grid>

          <Grid
            container
            sx={{
              backgroundImage: 'linear-gradient(to top, rgba(15,15,15, 1) 20%, rgba(15,15,15, .8) 60%, transparent 100%)',
              position: 'relative',
              justifyContent: 'center',
              display: 'flex',
              width: '100%',

              alignItems: 'center',
            }}
          >
            <Grid item xl={12} xs={12} sm={12} sx={{ textAlign: 'center', pb: '0' }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: '30px',
                    sm: '35px',
                    md: '40px',
                  },
                  fontWeight: '700',
                }}
              >
                {sportField?.name}
              </Typography>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '.8em',
                  pt: '10px',
                }}
              >
                {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={sportField?.status}>{sportField?.status}</strong>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={'options'}
            sx={{
              bgcolor: 'rgb(15,15,15)',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <Grid container id="max-width">
              <Grid
                item
                xl={6}
                xs={6}
                sm={6}
                className={'actions-left'}
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  p: '0 5%',
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
              </Grid>

              <Grid
                item
                xl={6}
                xs={6}
                sm={6}
                className={'actions-right'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  p: '0 5%',
                }}
              >
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                  <MoreVert />
                </IconButton>

                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  {sportField?.descriptions?.length > 0 ? <SelectDescriptionButton control={control} descriptions={sportField?.descriptions} /> : ''}

                  {sportField?.publisher?.id !== loggedPlayer?.id ? (
                    ''
                  ) : (
                    <>
                      <MenuItem onClick={handleOpenEdit}>{`${intl.formatMessage({ id: 'Field_Button_Edit' })}`}</MenuItem>
                      <FormEditFieldModal field_id={sportField?.id} open={openEdit} handleClose={handleCloseEdit} />
                    </>
                  )}
                  <ShareURLButton button={<MenuItem>{`${intl.formatMessage({ id: 'Global_Button_Share' })}`}</MenuItem>} url={window.location.href} title={`${sportField?.name}`} text={`${intl.formatMessage({ id: 'Global_Button_Share_Caption_1' })} ${sportField?.name} ${intl.formatMessage({ id: 'Global_Button_Share_Caption_2' })}`} />
                </Menu>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={'info'}
            sx={{
              bgcolor: 'rgb(15,15,15)',
              position: 'relative',
              p: '40px 0 40px',
            }}
          >
            <Grid container id="max-width">
              <Grid
                item
                xl={6}
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    p: { xs: '0 5% 20px', sm: '0 5%' },
                    textAlign: { xs: 'center', sm: 'left' },
                  }}
                >
                  <Typography variant="caption" sx={{ width: '100%' }}>
                    {`${intl.formatMessage({ id: 'Field_Sport_Type_Title' })}`}:&nbsp;
                    <strong>
                      {SPORT_TYPES.filter((t: any) => sportField?.sports?.find((s: any) => s === t?.value))?.map((s: any, index: number) => {
                        if (index === 0) {
                          return `${intl.formatMessage({ id: s?.label })}`;
                        } else {
                          return `, ${intl.formatMessage({ id: s?.label })}`;
                        }
                      })}
                    </strong>
                  </Typography>
                  <Typography variant="caption" sx={{ width: '100%' }}>
                    {`${intl.formatMessage({ id: 'Field_Type_Label' })}`}:&nbsp;<strong>{`${intl.formatMessage({ id: FIELD_TYPES.find((t: any) => t?.value === sportField?.type)?.label ?? 'Global_NA' })}`}</strong>
                  </Typography>
                  <Typography variant="caption" sx={{ width: '100%' }}>
                    {`${intl.formatMessage({ id: 'Field_Size_Label' })}`}:&nbsp;<strong>{`${intl.formatMessage({ id: FIELD_SIZES.find((s: any) => s?.value === sportField?.size)?.label ?? 'Global_NA' })}`}</strong>
                  </Typography>
                  <Typography variant="caption" sx={{ width: '100%' }}>
                    {`${intl.formatMessage({ id: 'Field_Cost_Label' })}`}:&nbsp;<strong>{sportField?.rental_asset?.price?.value ? `${sportField?.rental_asset?.price?.currency?.currency_code} ${sportField?.rental_asset?.price?.value} / hour` : 'FREE'}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xl={6} xs={12} sm={6} className={'primary-info'} sx={{ position: 'relative', textAlign: 'center' }}>
                <Grid item xs={10} sm={10} md={10} lg={10} component="a" href={`geo:${sportField?.location?.coordinates?.latitude},${sportField?.location?.coordinates?.longitude}`} target="_blank" sx={{ textDecoration: 'none' }}>
                  <LocationIcon
                    sx={{
                      color: 'rgba(31, 175, 146, .5)',
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      'display': 'flex',
                      'alignItems': 'center',
                      'justifyContent': 'center',
                      'textDecoration': 'none',
                      'fontWeight': '700',
                      'color': 'rgba(247, 251, 250, 1)',
                      '&:hover': {
                        color: 'rgba(31, 175, 146, 1)',
                      },
                    }}
                  >
                    {sportField?.name}
                  </Typography>
                </Grid>
                <Typography>{sportField?.location?.street_name}</Typography>
                <Typography>{sportField?.location?.secondary_street_name}</Typography>
                <Typography>
                  {sportField?.location?.postcode?.name} {sportField?.location?.county?.name ? `- ${sportField?.location?.county?.name}` : ''}
                </Typography>
                <Typography
                  className={'location'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {sportField?.location?.city?.name}, {sportField?.location?.country?.name}
                  &nbsp;
                  <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${sportField?.location?.country_code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${sportField?.location?.country_code.toLowerCase()}.png 2x`} alt="" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            sx={{
              bgcolor: 'rgb(15,15,15)',
              position: 'relative',
              p: '0 5%',
            }}
          >
            <Grid container id="max-width">
              {sportField?.descriptions?.find((d: any) => d.language === inputFields.description_language)?.content ? <Typography sx={{ textAlign: 'left', p: '30px 10%' }}>{sportField?.descriptions.find((d: any) => d.language === inputFields.description_language)?.content}</Typography> : ''}
            </Grid>
          </Grid>

          {sportField?.equipments?.length === 0 ? (
            ''
          ) : (
            <>
              <Typography
                id="max-width"
                sx={{
                  width: '100%',
                  fontSize: '1.1em',
                  fontWeight: '700',
                  bgcolor: 'rgb(15,15,15)',
                  position: 'relative',
                  p: { xs: '0 5% 10px', md: '0 2% 10px' },
                }}
              >
                {_.upperCase(`${intl.formatMessage({ id: 'Field_Equipments_Title' })}`)}
              </Typography>
              <Grid
                container
                sx={{
                  bgcolor: 'rgb(15,15,15)',
                  position: 'relative',
                  overflow: 'auto',
                  p: '0 5% 20px',
                }}
              >
                {sportField?.equipments?.length === 0 ? (
                  <Typography
                    variant="h3"
                    sx={{
                      width: '100%',
                      p: '30px 20px',
                      fontSize: '1.3em',
                      fontWeight: '100',
                      textAlign: 'center',
                    }}
                  >
                    {`${intl.formatMessage({ id: 'Field_No_Equipments' })}`}
                  </Typography>
                ) : (
                  <Stack direction="row">
                    {sportField?.equipments?.map((facility: any, index: number) => (
                      <Card
                        key={index}
                        sx={{
                          bgcolor: 'rgba(247, 251, 250, .1)',
                          minWidth: 200,
                          m: '0 20px 0 0',
                          p: '20px 20px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {facility?.pictures ? (
                          <CardMedia
                            component="img"
                            src={facility?.pictures[0]?.path}
                            sx={{
                              height: '90px',
                              width: 'auto',
                            }}
                          />
                        ) : (
                          <Skeleton
                            width={150}
                            height={150}
                            sx={{
                              bgcolor: 'rgba(247, 251, 250, .3)',
                              m: 'auto',
                            }}
                          />
                        )}
                        <Grid
                          sx={{
                            p: '0 0 0 10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                width: '100%',
                                textAlign: 'center',
                              }}
                            >
                              {facility?.name}
                            </Typography>
                            <Fab
                              className={'rate'}
                              sx={{
                                'bgcolor': 'transparent',
                                'boxShadow': 'none',
                                ':hover': {
                                  bgcolor: 'transparent',
                                },
                              }}
                            >
                              <Star
                                sx={{
                                  color: 'rgba(245,178,42,1) !important',
                                  position: 'absolute',
                                  fontSize: '3.2em',
                                }}
                              />
                              <Box
                                component="span"
                                sx={{
                                  position: 'relative',
                                  fontWeight: '700',
                                  fontSize: 'x-small',
                                }}
                              >
                                {facility?.rank || 0}
                              </Box>
                            </Fab>
                          </Grid>
                        </Grid>
                      </Card>
                    ))}
                  </Stack>
                )}
              </Grid>
            </>
          )}

          <Grid
            container
            sx={{
              bgcolor: 'rgb(15,15,15)',
              position: 'relative',
              overflow: 'auto',
              p: '0 5% 20px',
            }}
          >
            {sportField?.pictures?.length === 0 ? (
              <Typography
                variant="h3"
                sx={{
                  width: '100%',
                  p: '30px 20px',
                  fontSize: '1.3em',
                  fontWeight: '100',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'Field_No_Pictures' })}`}
              </Typography>
            ) : (
              <Stack direction="row">
                {sportField?.pictures?.map((pix: any, index: number) => (
                  <Card key={index} sx={{ width: 350, m: '0 20px 0 0' }}>
                    <CardMedia component="img" height="200" src={pix?.path} />
                  </Card>
                ))}
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Field;
