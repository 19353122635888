import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';

import { TextField, InputAdornment, Fab, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Stepper, Step, StepLabel, Typography, StepContent, Box, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff, Close, Send, Sms } from '@mui/icons-material';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { translations } from '../../lang/translations';
import { GET_PIN_CODE } from '../../utilities/utilities';
import ReactCodeInput from 'react-code-input';
import AlertPopup from '../common/AlertPopup';
import { selectSession } from '../../reducers/sessionSlice';

interface ISpendingCodeUpdateModal {
  icon: any;
  actionOne: any;
  actionTwo: any;
  open: boolean;
  isConfirmed: boolean;
}

const SpendingCodeUpdateModal: React.FC<ISpendingCodeUpdateModal> = (props: ISpendingCodeUpdateModal) => {
  const UPDATE_PLAYER_SPENDING_PASSWORD_MUTATION = gql`
    mutation updatePlayerSpendingPassword($player: inputUpdateSpendingPassword!) {
      updatePlayerSpendingPassword(player: $player)
    }
  `;

  const RESET_PLAYER_SPENDING_PASSWORD_MUTATION = gql`
    mutation resetPlayerSpendingPassword($player: inputResetSpendingPassword!) {
      resetPlayerSpendingPassword(player: $player)
    }
  `;

  const SEND_SMS_MUTATION = gql`
    mutation sendSms($mobile: String!, $content: String!) {
      sendSms(mobile: $mobile, content: $content)
    }
  `;

  const SEND_EMAIL_PIN_MUTATION = gql`
    mutation sendEmailPin($account: inputSendEmailPin!) {
      sendEmailPin(account: $account)
    }
  `;

  const VERIFY_PLAYER_IDENTIFIER_MATCH_MUTATION = gql`
    mutation verifyPlayerIdentifierMatch($player: inputVerifyAccountIdentifierMatch!) {
      verifyPlayerIdentifierMatch(player: $player)
    }
  `;

  const min_password_length = 10;
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [hidePassword, setHidePassword] = React.useState(true);
  const [hasForgotPassword, setHasForgotPassword] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [hasSendSMS, setHasSendSMS] = React.useState(false);
  const [verifiedMobile, setVerifiedMobile] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [pin, setPin] = React.useState('');

  const { control, watch, reset, setValue } = useForm({
    defaultValues: {
      current_spending_password: '',
      new_spending_password: '',
      new_spending_password_confirmation: '',
      identifier: '',
      pin_code: '',
    },
  });
  const inputFields = watch();

  const [updatePlayerSpendingPassword, updatedUser] = useMutation(UPDATE_PLAYER_SPENDING_PASSWORD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [verifyPlayerIdentifierMatch, isMatching] = useMutation(VERIFY_PLAYER_IDENTIFIER_MATCH_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendSms, sentSMS] = useMutation(SEND_SMS_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendEmailPin, sentEmailPin] = useMutation(SEND_EMAIL_PIN_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [resetPlayerSpendingPassword, resetedUser] = useMutation(RESET_PLAYER_SPENDING_PASSWORD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleUpdateSpendingPassword = async () => {
    try {
      const result = await updatePlayerSpendingPassword({
        variables: {
          player: {
            id: loggedPlayer?.id,
            username: loggedPlayer?.username,
            new_spending_password: inputFields.new_spending_password,
            current_spending_password: inputFields.current_spending_password,
          },
        },
      });

      setIsSubmitted(true);
      setStep(0);
      reset();
      props.actionOne(result.data?.updatePlayerSpendingPassword);
      props.actionTwo();
    } catch (e) {
      // setStep(0);
      // reset();
      // props.actionTwo();
      console.log(e);
    }
  };

  const handleResetSpendingPassword = async () => {
    try {
      const result = await resetPlayerSpendingPassword({
        variables: {
          player: {
            id: loggedPlayer?.id,
            username: loggedPlayer?.username,
            new_spending_password: inputFields.new_spending_password,
          },
        },
      });

      setStep(0);
      reset();
      props.actionOne(result.data?.resetPlayerSpendingPassword);
      props.actionTwo();
    } catch (e) {
      // setStep(0);
      // reset();
      // props.actionTwo();
      console.log(e);
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleStepperMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleStepperNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleStepperBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleSendPin = () => {
    inputFields.identifier?.includes('@') && inputFields.identifier?.includes('.') ? handleSendEmailPin() : handleSendPinCode();
    setHasSendSMS(true);
    // reset({ pin_code: '' })
  };

  const handleSendPinCode = async () => {
    try {
      const code = GET_PIN_CODE(6);
      console.log(code);
      // TODO: remove below line
      setPin(code);

      const result = await verifyPlayerIdentifierMatch({
        variables: {
          player: {
            id: loggedPlayer?.id,
            identifier: inputFields.identifier,
          },
        },
      });

      if (result.data?.verifyPlayerIdentifierMatch) {
        await sendSms({
          variables: {
            mobile: inputFields.identifier,
            content: `Hello friend, Your Lilenek verification code is: ${code}`,
          },
        });
        setPin(code);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSendEmailPin = async () => {
    try {
      const code = GET_PIN_CODE(6);
      console.log(code);
      // TODO: remove below line
      setPin(code);

      const result = await verifyPlayerIdentifierMatch({
        variables: {
          player: {
            id: loggedPlayer?.id,
            identifier: inputFields.identifier,
          },
        },
      });

      if (result.data?.verifyPlayerIdentifierMatch) {
        await sendEmailPin({
          variables: {
            account: {
              address: inputFields.identifier,
              pin_code: code,
              language: loggedPlayer?.app_configuration?.prefered_language,
            },
          },
        });
        setPin(code);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleValidatePin = () => {
    if (inputFields.pin_code.length === 6) {
      if (inputFields.pin_code === pin.toString()) {
        setVerifiedMobile(true);
        handleStepperNext();
        setPin('');
        // reset({ pin_code: '' });
      }
      setPin('');
      // reset({pin_code: ''});
    }
  };

  const handleCancel = () => {
    reset();
    setValue('identifier', '');
    setPin('');
    setActiveStep(0);
    setStep(0);
    props.actionTwo();
  };

  const steps = [
    {
      label: <FormattedMessage id="SpendingCodeModal_Identifier" />,
      content: (
        <Grid sx={{ pt: '10px' }}>
          <Controller
            name="identifier"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 40,
              minLength: 4,
              pattern: /[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)/,
            }}
            render={({ field }: any) => <TextField {...field} label={<FormattedMessage id="SpendingCodeModal_Email_Mobile" />} type={'text'} placeholder="example@email.com or +0000000000" fullWidth required className={'field-bottom-space'} />}
          />
        </Grid>
      ),
    },
    {
      label: <FormattedMessage id="SpendingCodeModal_Pin_Verification" />,
      content: (
        <Grid sx={{ pt: '10px' }}>
          {isMatching.loading || sentSMS.loading || sentEmailPin.loading ? (
            <>
              {sentEmailPin.loading ? (
                <Typography variant={'caption'} sx={{ textAlign: 'center', width: '100%', display: 'inline-block', p: '20px 0', fontWeight: '100', color: 'rgba(31, 175, 146, .7)' }}>
                  <CircularProgress className="green-loader" size={15} />
                  &nbsp;&nbsp;
                  <FormattedMessage id="SpendingCodeModal_Please_Wait" />
                </Typography>
              ) : (
                ''
              )}
            </>
          ) : (
            <Grid container sx={{ mb: '30px' }}>
              {pin === '' ? (
                <>
                  {inputFields.identifier?.includes('@') && inputFields.identifier?.includes('.') ? (
                    <LoadingButton
                      onClick={handleSendPin}
                      disabled={!inputFields.identifier?.includes('@') || !inputFields.identifier?.includes('.')}
                      loading={sentSMS.loading}
                      loadingPosition="end"
                      loadingIndicator={<FormattedMessage id="SpendingCodeModal_Sending_SMS" />}
                      className={!inputFields.identifier?.includes('@') || !inputFields.identifier?.includes('.') ? 'button-disabled' : 'button-green-reverse'}
                      // startIcon={sendSMS ? <Sms /> : ""}
                      endIcon={sentEmailPin ? <Sms /> : <Send />}
                    >
                      <FormattedMessage id="SpendingCodeModal_Send_Via_Email" />
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      onClick={handleSendPin}
                      disabled={inputFields.identifier === ''}
                      loading={sentSMS.loading}
                      loadingPosition="end"
                      loadingIndicator={<FormattedMessage id="SpendingCodeModal_Sending_SMS" />}
                      className={inputFields.identifier === '' ? 'button-disabled' : 'button-green-reverse'}
                      // startIcon={sendSMS ? <Sms /> : ""}
                      endIcon={hasSendSMS ? <Sms /> : <Send />}
                    >
                      <FormattedMessage id="SpendingCodeModal_Send_Via_SMS" />
                    </LoadingButton>
                  )}
                </>
              ) : isMatching.data?.verifyPlayerIdentifierMatch ? (
                <Controller name="pin_code" control={control} defaultValue="" rules={{ required: true, pattern: /^[0-9]*$/ }} render={({ field }: any) => <ReactCodeInput {...field} name="sms-pin" type="text" fields={6} inputMode="numeric" autoFocus={false} />} />
              ) : (
                'Your information does not match with this account'
              )}
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      label: <FormattedMessage id="SpendingCodeModal_Validation" />,
      content: (
        <Grid sx={{ pt: '10px' }}>
          <Grid container className="field-bottom-space">
            {isSubmitted ? (
              <>
                <AlertPopup open={isSubmitted} message={`${(<FormattedMessage id="SpendingCodeModal_Is_Submitted" />)}`} type={'success'} link={window.location.href} cta={undefined} />
                <Typography sx={{ textAlign: 'center', fontWeight: '700', width: '100%' }}>
                  <FormattedMessage id="SpendingCodeModal_Is_Updated" />
                  &nbsp;🎉
                </Typography>
              </>
            ) : (
              <>
                <Controller
                  name="new_spending_password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={<FormattedMessage id="SpendingCodeModal_New_Password" />}
                      type={!hidePassword ? 'text' : 'password'}
                      fullWidth
                      required
                      className={'field-bottom-space'}
                      InputProps={{
                        sx: {
                          'fontWeight': '800',
                          'fontSize': '2em',
                          '& input': { textAlign: 'center' },
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleStepperMouseDownPassword} edge="end">
                              {hidePassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="new_spending_password_confirmation"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={<FormattedMessage id="SpendingCodeModal_New_Password_Confirmation" />}
                      type={!hidePassword ? 'text' : 'password'}
                      fullWidth
                      required
                      className={'field-bottom-space'}
                      InputProps={{
                        sx: {
                          'fontWeight': '800',
                          'fontSize': '2em',
                          '& input': { textAlign: 'center' },
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                              {hidePassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <LoadingButton
                  onClick={handleResetSpendingPassword}
                  loading={resetedUser.loading}
                  loadingPosition="end"
                  // startIcon={newPassword.loading ? '' : <AccountCircle />}
                  endIcon={<Send />}
                  fullWidth
                  className={!verifiedMobile || inputFields.new_spending_password.length < min_password_length || inputFields.new_spending_password_confirmation.length < min_password_length || inputFields.new_spending_password_confirmation !== inputFields.new_spending_password ? 'button-disabled' : 'button-green'}
                  disabled={!verifiedMobile || inputFields.new_spending_password.length < min_password_length || inputFields.new_spending_password_confirmation.length < min_password_length || inputFields.new_spending_password_confirmation !== inputFields.new_spending_password}
                >
                  <FormattedMessage id="SpendingCodeModal_Reset_Password" />
                </LoadingButton>
              </>
            )}
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-transaction-confirmation-modal modal-feature dark-stepper'} PaperProps={{ sx: { p: '20px 5%', textAlign: 'center' } }}>
      <IntlProvider messages={translations?.find((t: any) => t?.value === (translations?.find((t: any) => t?.value === loggedPlayer?.app_configuration?.prefered_language) ? loggedPlayer?.app_configuration?.prefered_language : navigator.language === 'fr' ? 'FR' : 'GB'))?.content} locale={navigator.language} defaultLocale="en">
        <Fab size="small" onClick={handleCancel} sx={{ boxShadow: 'none', bgcolor: 'transparent' }}>
          <Close />
        </Fab>
        <DialogTitle id="alert-dialog-title" sx={{ textTransform: 'uppercase' }}>
          <strong>Update Spending Code</strong>
        </DialogTitle>
        <Grid container></Grid>
        <DialogContent dividers>
          <DialogContentText component={'span'} id="alert-dialog-description">
            <form noValidate autoComplete="off">
              {step >= 1 ? (
                ''
              ) : (
                <Grid container>
                  {hasForgotPassword ? (
                    <>
                      <Stepper className="stepper light" activeStep={activeStep} orientation="vertical" sx={{ width: '100%' }}>
                        {steps
                          .filter((s: any, index: number) => index <= activeStep)
                          .map((step, index) => (
                            <Step key={index}>
                              <StepLabel
                                optional={
                                  index === 2 ? (
                                    <Typography variant="caption">
                                      <FormattedMessage id="SpendingCodeModal_Last_Step" />
                                    </Typography>
                                  ) : null
                                }
                              >
                                {step.label}
                              </StepLabel>
                              <StepContent>
                                <Grid item xs={12} md={12} lg={12}>
                                  {step.content}
                                </Grid>
                                <Box sx={{ mb: 2 }}>
                                  <div>
                                    {index > 0 ? (
                                      isSubmitted ? (
                                        <Button className="button-green" href={`${window.location.href}`} sx={{ mt: 1, mr: 1, width: '100%' }}>
                                          <FormattedMessage id="SpendingCodeModal_Login_Button" />
                                        </Button>
                                      ) : (
                                        <Button disabled={index === 0} onClick={handleStepperBack} sx={{ mt: 1, mr: 1 }}>
                                          <FormattedMessage id="SpendingCodeModal_Back_Button" />
                                        </Button>
                                      )
                                    ) : (
                                      ''
                                    )}
                                    {index === steps.length - 1 ? (
                                      ''
                                    ) : hasSendSMS && index === 1 ? (
                                      <Button className={inputFields.pin_code.length !== 6 ? 'button-disabled' : 'button-green-reverse'} onClick={handleValidatePin} sx={{ mt: 1, mr: 1 }} disabled={inputFields.pin_code.length !== 6}>
                                        <FormattedMessage id="SpendingCodeModal_PIN_Verification_Button" />
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                    {index === steps.length - 1 ? (
                                      ''
                                    ) : index < 1 ? (
                                      <Button
                                        onClick={handleStepperNext}
                                        sx={{
                                          mt: 1,
                                          mr: 1,
                                        }}
                                        disabled={inputFields.identifier === ''}
                                        className={inputFields.identifier === '' ? 'button-disabled' : 'button-green-reverse'}
                                      >
                                        <FormattedMessage id="SpendingCodeModal_Continue_Button" />
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </Box>
                              </StepContent>
                            </Step>
                          ))}
                      </Stepper>
                    </>
                  ) : (
                    <>
                      <Controller
                        name="current_spending_password"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          minLength: min_password_length,
                          maxLength: 255,
                        }}
                        render={({ field }: any) => (
                          // <Grid sx={{ mt: '10px' }}>
                          //   <ReactCodeInput {...field} type={hidePassword ? 'password' : 'text'} fields={6} autoFocus={step === 0} pattern={'/^[0-9]*$/'} inputMode="numeric" className={'spending-code-input'} />
                          //   <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                          //     <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                          //       {hidePassword ? <VisibilityOff /> : <Visibility />}
                          //     </IconButton>
                          //   </Grid>
                          // </Grid>
                          <TextField
                            {...field}
                            label="Enter your current spending code"
                            type={hidePassword ? 'password' : 'text'}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                                    {hidePassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                </Grid>
              )}

              {step <= 0 || hasForgotPassword ? (
                ''
              ) : (
                <Grid>
                  {/* <Typography>Enter your new spending code</Typography> */}
                  <Controller
                    name="new_spending_password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      minLength: min_password_length,
                      maxLength: 255,
                    }}
                    render={({ field }: any) => (
                      // <Grid sx={{ mt: '10px' }}>
                      //   <ReactCodeInput {...field} type={hidePassword ? 'password' : 'text'} fields={6} autoFocus={step > 0} pattern={'/^[0-9]*$/'} inputMode="numeric" className={'spending-code-input'} />
                      // </Grid>
                      <TextField
                        {...field}
                        label="Enter your new spending code"
                        type={hidePassword ? 'password' : 'text'}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                                {hidePassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />

                  {/* <Typography sx={{ mt: '20px' }}>Confirm your new spending code</Typography> */}
                  <Controller
                    name="new_spending_password_confirmation"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      minLength: min_password_length,
                      maxLength: 255,
                    }}
                    render={({ field }: any) => (
                      // <Grid sx={{ mt: '10px' }}>
                      //   <ReactCodeInput {...field} type={hidePassword ? 'password' : 'text'} fields={6} autoFocus={inputFields.new_spending_password.length === password_length} pattern={'/^[0-9]*$/'} inputMode="numeric" className={'spending-code-input'} />
                      //   <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                      //     <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                      //       {hidePassword ? <VisibilityOff /> : <Visibility />}
                      //     </IconButton>
                      //   </Grid>
                      // </Grid>
                      <TextField
                        {...field}
                        label="Confirm your new spending code"
                        type={hidePassword ? 'password' : 'text'}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                                {hidePassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </form>
          </DialogContentText>
        </DialogContent>
        {step === 0 ? (
          <Grid sx={{ mt: '10px' }}>
            {hasForgotPassword ? (
              ''
            ) : (
              <>
                {step <= 0 ? (
                  <Button disabled={inputFields.current_spending_password.length < 1} className={inputFields.current_spending_password.length < 1 ? 'button-disabled' : 'button-green'} onClick={handleNext}>
                    Next
                  </Button>
                ) : (
                  <Button disabled={step <= 0 && inputFields.current_spending_password.length < min_password_length} className={step <= 0 && inputFields.current_spending_password.length < min_password_length ? 'button-disabled' : 'button-green'} onClick={handleBack}>
                    Back
                  </Button>
                )}
              </>
            )}
            <Grid container sx={{ justifyContent: 'center' }}>
              {hasForgotPassword ? (
                <Button onClick={() => setHasForgotPassword(false)} sx={{ mt: '20px', fontSize: '.6em' }}>
                  <FormattedMessage id="SpendingCodeModal_Remember_Spending_Code" />
                </Button>
              ) : (
                <Button onClick={() => setHasForgotPassword(true)} sx={{ mt: '20px', fontSize: '.6em' }}>
                  <FormattedMessage id="SpendingCodeModal_Forgot_Spending_Code" />
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          <DialogActions sx={{ justifyContent: 'center' }}>
            {hasForgotPassword ? (
              <></>
            ) : (
              <>
                <LoadingButton
                  loading={updatedUser.loading}
                  loadingPosition="end"
                  onClick={handleUpdateSpendingPassword}
                  endIcon={<Send />}
                  className={inputFields.current_spending_password.length < 1 || inputFields.new_spending_password.length < min_password_length || inputFields.new_spending_password_confirmation.length < min_password_length || inputFields.new_spending_password_confirmation !== inputFields.new_spending_password ? 'button-disabled' : 'button-green'}
                  disabled={inputFields.current_spending_password.length < 1 || inputFields.new_spending_password.length < min_password_length || inputFields.new_spending_password_confirmation.length < min_password_length || inputFields.new_spending_password_confirmation !== inputFields.new_spending_password}
                >
                  Confirm
                </LoadingButton>

                <Button className={'button-cancel'} onClick={handleBack}>
                  Back
                </Button>

                <Button onClick={handleCancel} className={'actionTwo'}>
                  Cancel
                </Button>
              </>
            )}
          </DialogActions>
        )}
      </IntlProvider>
    </Dialog>
  );
};

SpendingCodeUpdateModal.propTypes = {
  icon: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
};

SpendingCodeUpdateModal.defaultProps = {};

export default SpendingCodeUpdateModal;
