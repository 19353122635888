import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, Card, CardHeader, Avatar, Button, Stepper, Step, StepButton, StepContent, Box, Stack, Autocomplete, TextField, InputAdornment, IconButton, MenuItem, CardMedia, Chip, useMediaQuery } from '@mui/material';
import { Send, Close, VisibilityOff, Visibility, PhotoCamera, Cached, Remove, Delete } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { ARRAY_AS_ANY, CountryType, DATE_AS_ANY, INITIAL_COUNTRY, MEDIAS_AS_ANY, NULL_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ALTERNATIVE_PAYMENTS, CARD_NETWORKS, COUNTRIES, CURRENCIES, DEPOSIT_TOKEN_REG, ID_TYPES, LIPAY_PAYMENT_OPTIONS, PAYMENT_METHODS, PRICE_IN_MAAT, TO_TIMESTAMP, TRANSACTION_REG } from '../../utilities/utilities';
import { Controller, useForm } from 'react-hook-form';
import { selectPersonalWallets } from '../../reducers/walletSlice';
import { DatePicker } from '@mui/x-date-pickers';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { dataURLtoBlobURL, handleUpload } from '../../helpers';
import { BankCardIcon, CardPaymentIcon, SingleMemberIcon, TeamsIcon } from '../../img/icons/Icons';
import { customAlphabet, nanoid } from 'nanoid';
import { selectSession } from '../../reducers/sessionSlice';

export default function PaySportSessionModal(props: { handleClose: any; session_id: string; open: any }) {
  const FIND_SPORT_SESSION_BY_ID_BY_PLAYER_QUERY = gql`
    query findSportSessionByIDByPlayer($session: inputFindSportSessionByIDByPlayer!) {
      findSportSessionByIDByPlayer(session: $session) {
        id
        short_id
        title
        status
        type
        start_date
        end_date
        cost {
          value
          currency {
            currency_code
          }
        }
        group_order {
          id
          status
          notes
          total_cost {
            value
            currency {
              currency_code
            }
          }
        }
        challenge {
          id
          short_id
          title
          status
          type
          start_date
          end_date
          home_team {
            id
            name
            players {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
            captains {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
          }
          away_team {
            id
            name
            players {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  nickname
                  position
                  best_foot
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
            captains {
              id
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    id
                    path
                  }
                  player {
                    id
                    username
                    avatar {
                      id
                      path
                    }
                  }
                }
              }
            }
          }
          complex {
            id
            name
            pictures {
              id
              path
            }
            owners {
              ... on Company {
                __typename
                id
                name
              }
            }
            location {
              id
              title
              coordinates
              country_code
              street_name
              secondary_street_name
              status
              postcode {
                name
              }
              city {
                name
              }
              county {
                name
              }
              state {
                name
              }
              country {
                name
              }
              continent {
                name
              }
            }
          }
          arena {
            id
            name
            pictures {
              id
              path
            }
          }
        }
        consumers {
          id
          payment_status
          player {
            id
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  id
                  path
                }
                player {
                  id
                  username
                  avatar {
                    id
                    path
                  }
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  id
                  path
                }
                player {
                  id
                  username
                  avatar {
                    id
                    path
                  }
                }
              }
            }
          }
          individual_order {
            id
            status
          }
        }
        payment_plan {
          id
          transactions {
            id
            status
            note_reference
          }
        }
      }
    }
  `;

  const CREATE_FIRST_PAYMENT_METHOD_MUTATION = gql`
    mutation createPlayerFirstPaymentMethod($player: inputCreatePlayerFirstPaymentMethod!) {
      createPlayerFirstPaymentMethod(player: $player) {
        id
        title
        type
        status
        cards {
          ... on BankCard {
            __typename
            id
          }
        }
        payment_methods {
          id
        }
      }
    }
  `;

  const CREATE_WALLET_KYC_CASE_MUTATION = gql`
    mutation createWalletKYCCase($case: inputWalletCase!) {
      createWalletKYCCase(case: $case) {
        id
        reference
        status
        level
      }
    }
  `;

  const CREATE_PAYMENT_PROVIDERS_BY_PAYMENT_METHOD_ID_MUTATION = gql`
    mutation createPaymentProvidersByPaymentMethodID($payment_method: inputCreatePaymentProvidersByPaymentMethodID!) {
      createPaymentProvidersByPaymentMethodID(payment_method: $payment_method)
    }
  `;

  const FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY = gql`
    query findPaymentMethodsByCompanyAccountID($company: inputFindPaymentMethodsByCompanyAccountID!) {
      findPaymentMethodsByCompanyAccountID(company: $company) {
        id
        name
        label
        status
        payment_source {
          ... on BankCard {
            __typename
            id
            network
          }
          ... on BankAccount {
            __typename
            id
            title
          }
          ... on BlockchainCard {
            __typename
            id
            network
          }
          ... on BlockchainAccount {
            __typename
            id
            title
          }
          ... on Cash {
            __typename
            id
            name
          }
          ... on Gold {
            __typename
            id
            name
          }
          ... on Waste {
            __typename
            id
            name
          }
        }
        wallets {
          id
          title
          is_default
        }
      }
    }
  `;

  const CREATE_TRANSACTION_BY_SPORT_SESSION_ID_MUTATION = gql`
    mutation createTransactionBySportSessionID($transaction: inputCreateTXBySportSessionID!) {
      createTransactionBySportSessionID(transaction: $transaction) {
        id
        purchase {
          ... on SeatSession {
            __typename
            id
          }
        }
      }
    }
  `;

  const ADD_NEW_LOCATION_MUTATION = gql`
    mutation addAdditionalLocation($account: inputAddAdditionalLocation!) {
      addAdditionalLocation(account: $account) {
        id
      }
    }
  `;

  const ADD_PLAYER_SPENDING_PASSWORD_MUTATION = gql`
    mutation addPlayerSpendingPassword($player: inputAddSpendingPassword!) {
      addPlayerSpendingPassword(player: $player)
    }
  `;

  const FIND_RECEOMMENDED_ITEMS_BY_NAME_QUERY = gql`
    query findRecommendedItemsByName($complex: inputFindRecommendedItemsByName!) {
      findRecommendedItemsByName(complex: $complex) {
        ... on Article {
          id
          name
          amount
          pictures {
            path
          }
          price {
            value
            currency {
              currency_code
            }
          }
          product {
            id
          }
          in_stock {
            name
            warehouse {
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
          }
        }
        ... on Service {
          id
          status
          name
          pictures {
            path
          }
          price {
            value
            currency {
              currency_code
            }
          }
          in_stock {
            name
            warehouse {
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
          }
        }
        ... on Rental {
          id
          name
          pictures {
            path
          }
          price {
            value
            currency {
              currency_code
            }
          }
          pickup_location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          delivery_locations {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
      }
    }
  `;

  const CREATE_INDIVIDUAL_ORDER_BY_SPORT_SESSION_MUTATION = gql`
    mutation createIndividualOrderBySportSession($order: inputCreateIndividualOrderBySportSession!) {
      createIndividualOrderBySportSession(order: $order) {
        id
      }
    }
  `;

  const CREATE_GROUP_ORDER_BY_SPORT_SESSION_MUTATION = gql`
    mutation createGroupOrderBySportSession($order: inputCreateGroupOrderBySportSession!) {
      createGroupOrderBySportSession(order: $order) {
        id
      }
    }
  `;

  const ADD_PAYMENT_TO_GROUP_SNACK_ORDER_MUTATION = gql`
    mutation addPaymentToGroupSnackOrder($order: inputAddPaymentToGroupSnackOrder!) {
      addPaymentToGroupSnackOrder(order: $order)
    }
  `;

  const EXECUTE_DIRECT_FIAT_PAYMENT_TRANSACTION_MUTATION = gql`
    mutation executeDirectFiatPaymentTransaction($transaction: inputExecuteDirectFiatTX!) {
      executeDirectFiatPaymentTransaction(transaction: $transaction)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedAccountPersonalWallets = useAppSelector(selectPersonalWallets);
  const [sportSession, setSportSession] = React.useState(UNDEFINED_AS_ANY);
  const [isProceedingPayment, setIsProceedingPayment] = React.useState(false);
  const [idIssuerCountry, setIdIssuerCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [homeCountry, setHomeCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [pidFrontFile, setPidFrontFile] = React.useState<ImageListType>([]);
  const [pidFrontFileUrl, setPidFrontFileUrl] = React.useState([]);
  const [pidBackFile, setPidBackFile] = React.useState<ImageListType>([]);
  const [pidBackFileUrl, setPidBackFileUrl] = React.useState([]);
  const [idSelfie, setIdSelfie] = React.useState<ImageListType>([]);
  const [idSelfieUrl, setSelfieUrl] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [hidePassword, setHidePassword] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [openSearchSportPlayer, setOpenSearchSportPlayer] = React.useState(false);
  const min_code_length = 10;
  const tx_nanoid = customAlphabet(TRANSACTION_REG, 21);
  const deposit_token_nanoid = customAlphabet(DEPOSIT_TOKEN_REG, 15);
  const isSmallMobile = useMediaQuery('(max-width:600px)');

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      consumers_to_pay: ARRAY_AS_ANY,
      payment_method_id: loggedAccountPersonalWallets?.length === 0 ? 'new_payment_method' : '',
      payment_option: 'MAT',
      spending_password: '',
      wallet_title: '',

      id_type: 'PASSPORT',
      id_number: '',
      id_exp: DATE_AS_ANY,
      id_issuer: '',
      id_issuer_country: INITIAL_COUNTRY,
      pid_front: MEDIAS_AS_ANY,
      pid_back: MEDIAS_AS_ANY,
      id_selfie: NULL_AS_ANY,

      home_street_name: '',
      home_secondary_street_name: '',
      home_postcode: '',
      home_city: '',
      home_county: '',
      home_state: '',
      home_country: INITIAL_COUNTRY,

      payment_method_name: 'LIPAY',
      card_holder: '',
      card_type: 'DEBIT',
      card_network: 'VISA',
      card_number: '',
      card_exp: DATE_AS_ANY,
      card_cvc: '',
      account_iban: '',
      account_number: '',
      all_currencies: ARRAY_AS_ANY,
      accepted_currencies: ARRAY_AS_ANY,
      all_countries: ARRAY_AS_ANY,
      bank_country: INITIAL_COUNTRY,

      item_name: '',
      item_amount: 1,
      selected_item: UNDEFINED_AS_ANY,
      selected_personal_items: ARRAY_AS_ANY,
      selected_group_items: ARRAY_AS_ANY,
      all_items: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();

  const [executeDirectFiatPaymentTransaction, exectuedTransaction] = useMutation(EXECUTE_DIRECT_FIAT_PAYMENT_TRANSACTION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createPlayerFirstPaymentMethod, newWallet] = useMutation(CREATE_FIRST_PAYMENT_METHOD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [addPlayerSpendingPassword, updatedPlayer] = useMutation(ADD_PLAYER_SPENDING_PASSWORD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createPaymentProvidersByPaymentMethodID, newPaymentProviders] = useMutation(CREATE_PAYMENT_PROVIDERS_BY_PAYMENT_METHOD_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createWalletKYCCase, newKYCCase] = useMutation(CREATE_WALLET_KYC_CASE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createTransactionBySportSessionID, newTransaction] = useMutation(CREATE_TRANSACTION_BY_SPORT_SESSION_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [addAdditionalLocation, newLocation] = useMutation(ADD_NEW_LOCATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createIndividualOrderBySportSession, newIndividualOrder] = useMutation(CREATE_INDIVIDUAL_ORDER_BY_SPORT_SESSION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createGroupOrderBySportSession, newGroupOrder] = useMutation(CREATE_GROUP_ORDER_BY_SPORT_SESSION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [addPaymentToGroupSnackOrder, updatedGroupOrder] = useMutation(ADD_PAYMENT_TO_GROUP_SNACK_ORDER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportSession = useQuery(FIND_SPORT_SESSION_BY_ID_BY_PLAYER_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      session: {
        id: props.session_id,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });
  const loadedPaymentMethods = useQuery(FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      company: {
        logged_account_id: loggedPlayer?.id,
      },
    },
  });
  const loadedComplexPaymentMethods = useQuery(FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      company: {
        id: sportSession?.challenge?.complex?.owners?.find((o: any) => o?.__typename === 'Company')?.id,
      },
    },
  });
  const loadedItems = useQuery(FIND_RECEOMMENDED_ITEMS_BY_NAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      complex: {
        id: sportSession?.challenge?.complex?.id,
        item_name: inputFields.item_name,
      },
    },
  });

  const HOME_TEAM_PLAYERS = sportSession?.challenge?.home_team?.players?.filter((p: any) => p);
  const AWAY_TEAM_PLAYERS = sportSession?.challenge?.away_team?.players?.filter((p: any) => p);
  const ALL_TEAM_PLAYERS = _.concat(HOME_TEAM_PLAYERS, AWAY_TEAM_PLAYERS);
  const ALL_PAYMENT_METHODS = loadedPaymentMethods.data?.findPaymentMethodsByCompanyAccountID;
  const ALL_COMPLEX_PAYMENT_METHODS = loadedComplexPaymentMethods.data?.findPaymentMethodsByCompanyAccountID;
  const ALL_ITEMS = loadedItems.data?.findRecommendedItemsByName;

  const handlePayForYourself = (team_player: any) => {
    const newList = _.concat(team_player?.id, inputFields.consumers_to_pay);
    setValue('consumers_to_pay', newList);
  };

  const handlePayForThem = (team_player: any) => {
    const newList = _.concat(team_player?.id, inputFields.consumers_to_pay);

    setValue('consumers_to_pay', newList);
  };

  const handleRemoveConsumer = (team_player: any) => {
    const newList = inputFields.consumers_to_pay?.filter((id: string) => id !== team_player?.id);
    setValue('consumers_to_pay', newList);
  };

  const handlePidFrontFile = async (imageList: ImageListType) => {
    setValue('pid_front', imageList);
    setPidFrontFile(imageList);
  };

  const handlePidBackFile = async (imageList: ImageListType) => {
    setValue('pid_back', imageList);
    setPidBackFile(imageList);
  };

  const handleIdSelfie = async (imageList: ImageListType) => {
    setIdSelfie(imageList);
  };

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setHomeCountry(country);
      setValue('home_country', country);
    }
  };

  const handleIdIssuerCountry = (event: any, country: CountryType) => {
    if (country) {
      setIdIssuerCountry(country);
      setValue('id_issuer_country', country);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleSelectCurrency = (country: any) => {
    const newList = _.concat(inputFields.accepted_currencies, country);
    setValue('accepted_currencies', newList);
  };

  const handleUnselectCurrency = (country: any) => {
    const newList = inputFields.accepted_currencies?.filter((c: any) => c?.value !== country?.value);
    setValue('accepted_currencies', newList);
  };

  const handleSelectBankCountry = (country: any) => {
    setValue('bank_country', country);
  };

  const handleSelectItem = (value: any) => {
    setValue('selected_item', value);
    setOpenSearchSportPlayer(false);
  };

  const handleAddPersonalItem = () => {
    const newList = _.concat({ item: inputFields.selected_item, amount: inputFields.item_amount }, inputFields.selected_personal_items);
    setValue('selected_item', UNDEFINED_AS_ANY);
    setValue('item_amount', 1);
    setValue('selected_personal_items', newList);
  };

  const handleAddGroupItem = () => {
    const newList = _.concat({ item: inputFields.selected_item, amount: inputFields.item_amount }, inputFields.selected_group_items);
    setValue('selected_item', UNDEFINED_AS_ANY);
    setValue('item_amount', 1);
    setValue('selected_group_items', newList);
  };

  const handleRemovePersonalItem = (item_id: string) => {
    const newList = inputFields.selected_personal_items?.filter((i: any) => i?.item?.id !== item_id);
    setValue('selected_personal_items', newList);
  };

  const handleRemoveGroupItem = (item_id: string) => {
    const newList = inputFields.selected_group_items?.filter((i: any) => i?.item?.id !== item_id);
    setValue('selected_group_items', newList);
  };

  const handlePaySportSession = async () => {
    try {
      setProgress(1);

      if (pidFrontFile.length > 0) {
        const pidFrontPromise = await handleUpload(pidFrontFile[0].file, pidFrontFileUrl, setPidFrontFileUrl, session, loggedPlayer.id);
        if (pidFrontPromise) await Promise.all(pidFrontPromise);
      }

      if (pidBackFile.length > 0) {
        const pidBackPromise = await handleUpload(pidBackFile[0].file, pidBackFileUrl, setPidBackFileUrl, session, loggedPlayer.id);
        if (pidBackPromise) await Promise.all(pidBackPromise);
      }

      if (idSelfie.length > 0) {
        const pidSelfiePromise = await handleUpload(idSelfie[0].file, idSelfieUrl, setSelfieUrl, session, loggedPlayer.id);
        if (pidSelfiePromise) await Promise.all(pidSelfiePromise);
      }

      if (loggedAccountPersonalWallets?.length === 0 && inputFields.payment_method_id === 'new_payment_method') {
        try {
          const result = await createPlayerFirstPaymentMethod({
            variables: {
              player: {
                id: loggedPlayer?.id,
                wallet_title: inputFields.wallet_title !== '' ? inputFields.wallet_title : `${loggedPlayer?.owner?.firstname}'s wallet`,
                user_front_id: {
                  filename: pidFrontFile[0]?.file?.name,
                  size: pidFrontFile[0]?.file?.size,
                  type: pidFrontFile[0]?.file?.type,
                  path: pidFrontFileUrl[0] ? pidFrontFileUrl[0] : '',
                },
                user_back_id: {
                  filename: pidBackFile[0]?.file?.name,
                  size: pidBackFile[0]?.file?.size,
                  type: pidBackFile[0]?.file?.type,
                  path: pidBackFileUrl[0] ? pidBackFileUrl[0] : '',
                },
                id_selfie: {
                  filename: idSelfie[0]?.file?.name,
                  size: idSelfie[0]?.file?.size,
                  type: idSelfie[0]?.file?.type,
                  path: idSelfieUrl[0] ? idSelfieUrl[0] : undefined,
                },
                card: {
                  type: inputFields.card_type,
                  network: inputFields.card_network,
                  card_number: inputFields.card_number,
                  card_holder: inputFields.card_holder,
                  card_exp: parseFloat(TO_TIMESTAMP(inputFields.card_exp).toFixed(1)),
                  card_cvc: inputFields.card_cvc,
                  iban: inputFields.account_iban,
                  account_number: inputFields.account_number,
                  currencies: inputFields.accepted_currencies?.map((c: any) => c?.value),
                  country_code: inputFields.bank_country?.value,
                  country_name: inputFields.bank_country?.label,
                },
                payment_method: {
                  name: inputFields.payment_method_name,
                  label: _.capitalize(_.replace(inputFields.payment_method_name, '_', ' ')),
                },
              },
            },
          });

          await addPlayerSpendingPassword({
            variables: {
              player: {
                id: loggedPlayer?.id,
                username: loggedPlayer?.username,
                spending_password: inputFields.spending_password,
              },
            },
          });

          if (!newLocation.called && loggedPlayer?.locations?.length === 0) {
            try {
              await addAdditionalLocation({
                variables: {
                  account: {
                    buyer_id: loggedPlayer?.id,
                    location: {
                      title: `${loggedPlayer?.username}'s address`,
                      street_name: inputFields.home_street_name,
                      secondary_street_name: inputFields.home_secondary_street_name,
                      country_code: inputFields.home_country ? inputFields.home_country?.value : homeCountry?.value,
                      postcode: inputFields.home_postcode,
                      city: inputFields.home_city,
                      county: inputFields.home_county,
                      state: inputFields.home_state,
                      country: inputFields.home_country ? inputFields.home_country?.label : homeCountry?.label,
                      continent: inputFields.home_country ? inputFields.home_country?.continent : homeCountry?.continent,
                    },
                  },
                },
              });
            } catch (e) {
              console.log(e);
            }
          }

          if (!newPaymentProviders.called) {
            try {
              await createPaymentProvidersByPaymentMethodID({
                variables: {
                  payment_method: {
                    id: result.data?.createPlayerFirstPaymentMethod?.payment_methods[0]?.id,
                  },
                },
              });
            } catch (e) {
              console.log(e);
            }
          }

          if (result.data?.createPlayerFirstPaymentMethod?.id) {
            try {
              await createWalletKYCCase({
                variables: {
                  case: {
                    author_id: loggedPlayer?.id,
                    wallet_id: result.data?.createPlayerFirstPaymentMethod?.id,
                    wallet_type: 'PERSONAL',
                    wallet_owner: {
                      type: 'Player',
                      id: loggedPlayer?.id,
                      name: loggedPlayer?.username,
                    },
                    reference: `case-w-*${result.data?.createPlayerFirstPaymentMethod?.id}-u-*${loggedPlayer?.id}-d-*${Date.now()}`,
                    title: `[KYC WALLET] ${result.data?.createPlayerFirstPaymentMethod?.id}`,
                    user_id_type: inputFields.id_type,
                    user_id_number: inputFields.id_number,
                    user_id_exp: parseFloat(TO_TIMESTAMP(inputFields.id_exp).toFixed(1)),
                    user_id_issuer: inputFields.id_issuer,
                    user_id_issuer_country: idIssuerCountry.value,
                    user_front_id: {
                      filename: pidFrontFile[0]?.file?.name,
                      size: pidFrontFile[0]?.file?.size,
                      type: pidFrontFile[0]?.file?.type,
                      path: pidFrontFileUrl[0] ? pidFrontFileUrl[0] : '',
                    },
                    user_back_id: {
                      filename: pidBackFile[0]?.file?.name,
                      size: pidBackFile[0]?.file?.size,
                      type: pidBackFile[0]?.file?.type,
                      path: pidBackFileUrl[0] ? pidBackFileUrl[0] : '',
                    },
                    user_id_selfie: {
                      filename: idSelfie[0]?.file?.name,
                      size: idSelfie[0]?.file?.size,
                      type: idSelfie[0]?.file?.type,
                      path: idSelfieUrl[0] ? idSelfieUrl[0] : '',
                    },
                    user_home: {
                      street_name: inputFields.home_street_name,
                      secondary_street_name: inputFields.home_secondary_street_name,
                      country_code: homeCountry.value,
                      postcode: inputFields.home_postcode,
                      city: inputFields.home_city,
                      county: inputFields.home_county,
                      state: inputFields.home_state,
                      country: homeCountry.label,
                    },
                  },
                },
              });
            } catch (e) {
              console.log(e);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (!newPaymentProviders.called && loggedAccountPersonalWallets?.length > 0 && inputFields.payment_method_id !== 'new_payment_method') {
        try {
          await createPaymentProvidersByPaymentMethodID({
            variables: {
              payment_method: {
                id: inputFields.payment_method_id,
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }

      if (inputFields.spending_password?.length > 0 && (newWallet.data?.createPlayerFirstPaymentMethod || loggedAccountPersonalWallets?.length > 0)) {
        // CREATE PAYMENT_PLAN if not exist
        // CREATE TRANSACTION
        const sender_payment_method = ALL_PAYMENT_METHODS?.find((pm: any) => pm?.id === inputFields.payment_method_id);
        if (!newTransaction.called) {
          try {
            const result = await createTransactionBySportSessionID({
              variables: {
                transaction: {
                  bill_id: sportSession?.id,
                  team_player_id: ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id)?.id,
                  tx_reference: `Tx-${tx_nanoid()}-${Date.now()}`,
                  note_reference: inputFields.consumers_to_pay?.map((consumer_id: string) => `${consumer_id},`)?.toString(),
                  type: 'PAYMENT',
                  lipay_payment_option: sender_payment_method?.name === 'LIPAY' ? sender_payment_method?.payment_source?.name : undefined,
                  deposit_token: sender_payment_method?.name === 'LIPAY' ? deposit_token_nanoid() : undefined,
                  original_value: {
                    value: parseFloat(
                      (
                        (sportSession?.cost?.value ? sportSession?.cost?.value / ALL_TEAM_PLAYERS?.length : 0) * inputFields.consumers_to_pay?.length +
                        (await PRICE_IN_MAAT(sportSession?.group_order?.total_cost?.value || 0, sportSession?.group_order?.total_cost?.currency?.currency_code || 'MAT')) / ALL_TEAM_PLAYERS?.length +
                        (_.sum(inputFields.selected_group_items?.map((i: any) => i?.item?.price?.value)) / ALL_TEAM_PLAYERS?.length) * inputFields.consumers_to_pay?.length +
                        parseFloat(_.sum(inputFields.selected_personal_items?.map((i: any) => i?.item?.price?.value * i?.amount))?.toString())
                      )?.toString()
                    ),
                    currency: sportSession?.cost?.currency?.currency_code,
                  },
                  maat_value: {
                    value: parseFloat(
                      (
                        (sportSession?.cost?.value ? sportSession?.cost?.value / ALL_TEAM_PLAYERS?.length : 0) * inputFields.consumers_to_pay?.length +
                        (await PRICE_IN_MAAT(sportSession?.group_order?.total_cost?.value || 0, sportSession?.group_order?.total_cost?.currency?.currency_code || 'MAT')) / ALL_TEAM_PLAYERS?.length +
                        (_.sum(inputFields.selected_group_items?.map((i: any) => parseFloat(i?.item?.price?.value?.toString()))) / ALL_TEAM_PLAYERS?.length) * inputFields.consumers_to_pay?.length +
                        parseFloat(_.sum(inputFields.selected_personal_items?.map((i: any) => parseFloat(i?.item?.price?.value?.toString()) * parseFloat(i?.amount?.toString())))?.toString())
                      )?.toString()
                    ),
                    currency: 'MAT',
                  },
                  tx_fee: {
                    value: 0,
                    currency: 'MAT',
                  },
                  assets: [],
                  sender_token: inputFields.spending_password,
                  sender_wallet_id: sender_payment_method?.wallets?.find((w: any) => w?.is_default === true)?.id,
                  sender_payment_method_id: sender_payment_method?.id,
                  receiver_wallet_id: ALL_COMPLEX_PAYMENT_METHODS[0]?.wallets[0]?.id,
                  receiver_payment_method_id: ALL_COMPLEX_PAYMENT_METHODS[0]?.id,
                  signatories: inputFields.consumers_to_pay,
                  sender_payment_source_id: sender_payment_method?.payment_source?.id,
                  receiver_payment_source_id: ALL_COMPLEX_PAYMENT_METHODS[0]?.payment_source?.id,
                  execution_date: Date.now(),
                  first_payment_date: Date.now(),
                  last_payment_date: sportSession?.end_date,
                },
              },
            });

            if (!updatedGroupOrder && ALL_TEAM_PLAYERS?.filter((p: any) => !sportSession?.group_order?.notes?.includes(p?.player?.player?.id)) && result.data?.createTransactionBySportSessionID[0]?.purchase?.id) {
              try {
                await addPaymentToGroupSnackOrder({
                  variables: {
                    order: {
                      id: sportSession?.group_order?.id,
                      player_id: loggedPlayer?.id,
                    },
                  },
                });
              } catch (e) {
                console.log(e);
              }
            }

            if (inputFields.selected_personal_items?.length > 0 && result.data?.createTransactionBySportSessionID[0]?.purchase?.id) {
              try {
                const short_id = nanoid();
                await createIndividualOrderBySportSession({
                  variables: {
                    order: {
                      seat_session_id: result.data?.createTransactionBySportSessionID[0]?.purchase?.id,
                      short_id: short_id,
                      title: `[ORDER] ${short_id}`,
                      reference: `order-${short_id}-${Date.now()}`,
                      notes: `For player named ${loggedPlayer?.username}`,
                      supplier_id: sportSession?.challenge?.complex?.owners[0]?.id,
                      customer_id: loggedPlayer?.id,
                      articles_bundles:
                        inputFields.selected_personal_items?.filter((i: any) => i?.item?.__typename === 'Article')?.length === 0
                          ? []
                          : inputFields.selected_personal_items
                              ?.filter((i: any) => i?.item?.__typename === 'Article')
                              ?.map((item: any) => {
                                return {
                                  item_id: item?.item?.id,
                                  item_type: item?.item?.__typename,
                                  item_amount: parseFloat(item?.amount?.toString()),
                                  item_cost: {
                                    value: parseFloat(item?.item?.price?.value.toString()),
                                    currency: item?.item?.price?.currency?.currency_code,
                                  },
                                  item_product_id: item?.item?.product?.id,
                                  item_destination: {
                                    title: sportSession?.challenge?.complex?.location?.title,
                                    street_name: sportSession?.challenge?.complex?.location?.street_name,
                                    secondary_street_name: sportSession?.challenge?.complex?.location?.secondary_street_name,
                                    country_code: sportSession?.challenge?.complex?.location?.country_code,
                                    postcode: sportSession?.challenge?.complex?.location?.postcode?.name,
                                    city: sportSession?.challenge?.complex?.location?.city?.name,
                                    county: sportSession?.challenge?.complex?.location?.county?.name,
                                    state: sportSession?.challenge?.complex?.location?.state?.name,
                                    country: sportSession?.challenge?.complex?.location?.country?.name,
                                    continent: sportSession?.challenge?.complex?.location?.continent?.name,
                                  },
                                  origin_location: {
                                    id: item?.item?.in_stock[0]?.warehouse?.location?.id,
                                    title: item?.item?.in_stock[0]?.warehouse?.location?.title,
                                    street_name: item?.item?.in_stock[0]?.warehouse?.location?.street_name,
                                    secondary_street_name: item?.item?.in_stock[0]?.warehouse?.location?.secondary_street_name,
                                    country_code: item?.item?.in_stock[0]?.warehouse?.location?.country_code,
                                    postcode: item?.item?.in_stock[0]?.warehouse?.location?.postcode?.name,
                                    city: item?.item?.in_stock[0]?.warehouse?.location?.city?.name,
                                    county: item?.item?.in_stock[0]?.warehouse?.location?.county?.name,
                                    state: item?.item?.in_stock[0]?.warehouse?.location?.state?.name,
                                    country: item?.item?.in_stock[0]?.warehouse?.location?.country?.name,
                                    continent: item?.item?.in_stock[0]?.warehouse?.location?.continent?.name,
                                  },
                                };
                              }),
                      services_bundles:
                        inputFields.selected_personal_items?.filter((i: any) => i?.item?.__typename === 'Service')?.length === 0
                          ? []
                          : inputFields.selected_personal_items
                              ?.filter((i: any) => i?.item?.__typename === 'Service')
                              ?.map((item: any) => {
                                return {
                                  item_id: item?.item?.id,
                                  item_type: item?.item?.__typename,
                                  item_amount: parseFloat(item?.amount.toString()),
                                  item_cost: {
                                    value: parseFloat(item?.item?.price?.value.toString()),
                                    currency: item?.item?.price?.currency?.currency_code,
                                  },
                                  item_start_date: sportSession?.start_date,
                                  item_end_date: sportSession?.end_date,
                                  item_destination: {
                                    title: sportSession?.challenge?.complex?.location?.title,
                                    street_name: sportSession?.challenge?.complex?.location?.street_name,
                                    secondary_street_name: sportSession?.challenge?.complex?.location?.secondary_street_name,
                                    country_code: sportSession?.challenge?.complex?.location?.country_code,
                                    postcode: sportSession?.challenge?.complex?.location?.postcode?.name,
                                    city: sportSession?.challenge?.complex?.location?.city?.name,
                                    county: sportSession?.challenge?.complex?.location?.county?.name,
                                    state: sportSession?.challenge?.complex?.location?.state?.name,
                                    country: sportSession?.challenge?.complex?.location?.country?.name,
                                    continent: sportSession?.challenge?.complex?.location?.continent?.name,
                                  },
                                  origin_location: {
                                    id: item?.item?.in_stock[0]?.warehouse?.location?.id,
                                    title: item?.item?.in_stock[0]?.warehouse?.location?.title || '',
                                    street_name: item?.item?.in_stock[0]?.warehouse?.location?.street_name || '',
                                    secondary_street_name: item?.item?.in_stock[0]?.warehouse?.location?.secondary_street_name || '',
                                    country_code: item?.item?.in_stock[0]?.warehouse?.location?.country_code || '',
                                    postcode: item?.item?.in_stock[0]?.warehouse?.location?.postcode?.name || '',
                                    city: item?.item?.in_stock[0]?.warehouse?.location?.city?.name || '',
                                    county: item?.item?.in_stock[0]?.warehouse?.location?.county?.name || '',
                                    state: item?.item?.in_stock[0]?.warehouse?.location?.state?.name || '',
                                    country: item?.item?.in_stock[0]?.warehouse?.location?.country?.name || '',
                                    continent: item?.item?.in_stock[0]?.warehouse?.location?.continent?.name || '',
                                  },
                                };
                              }),
                      total_cost: {
                        value: parseFloat(_.sum(inputFields.selected_personal_items?.map(async (i: any) => (await PRICE_IN_MAAT(i?.item?.price?.value, i?.item?.price?.currency?.currency_code)) * i?.amount))?.toString()) || 0,
                        currency: 'MAT',
                      },
                    },
                  },
                });
              } catch (e) {
                console.log(e);
              }
            }

            if (inputFields.selected_group_items?.length > 0) {
              const short_id = nanoid();

              if (!sportSession?.group_order) {
                await createGroupOrderBySportSession({
                  variables: {
                    order: {
                      sport_session_id: sportSession?.id,
                      short_id: short_id,
                      title: `[ORDER] ${short_id}`,
                      reference: `order-${short_id}-${Date.now()}`,
                      notes: `${loggedPlayer?.id},`,
                      supplier_id: sportSession?.challenge?.complex?.owners[0]?.id,
                      customer_id: loggedPlayer?.id,
                      articles_bundles:
                        inputFields.selected_group_items?.filter((i: any) => i?.item?.__typename === 'Article')?.length === 0
                          ? []
                          : inputFields.selected_group_items
                              ?.filter((i: any) => i?.item?.__typename === 'Article')
                              ?.map((item: any) => {
                                return {
                                  item_id: item?.item?.id,
                                  item_type: item?.item?.__typename,
                                  item_amount: parseFloat(item?.amount?.toString()),
                                  item_cost: {
                                    value: parseFloat(item?.item?.price?.value.toString()),
                                    currency: item?.item?.price?.currency?.currency_code,
                                  },
                                  item_product_id: item?.item?.product?.id,
                                  item_destination: {
                                    title: sportSession?.challenge?.complex?.location?.title,
                                    street_name: sportSession?.challenge?.complex?.location?.street_name,
                                    secondary_street_name: sportSession?.challenge?.complex?.location?.secondary_street_name,
                                    country_code: sportSession?.challenge?.complex?.location?.country_code,
                                    postcode: sportSession?.challenge?.complex?.location?.postcode?.name,
                                    city: sportSession?.challenge?.complex?.location?.city?.name,
                                    county: sportSession?.challenge?.complex?.location?.county?.name,
                                    state: sportSession?.challenge?.complex?.location?.state?.name,
                                    country: sportSession?.challenge?.complex?.location?.country?.name,
                                    continent: sportSession?.challenge?.complex?.location?.continent?.name,
                                  },
                                  origin_location: {
                                    id: item?.item?.in_stock[0]?.warehouse?.location?.id,
                                    title: item?.item?.in_stock[0]?.warehouse?.location?.title,
                                    street_name: item?.item?.in_stock[0]?.warehouse?.location?.street_name,
                                    secondary_street_name: item?.item?.in_stock[0]?.warehouse?.location?.secondary_street_name,
                                    country_code: item?.item?.in_stock[0]?.warehouse?.location?.country_code,
                                    postcode: item?.item?.in_stock[0]?.warehouse?.location?.postcode?.name,
                                    city: item?.item?.in_stock[0]?.warehouse?.location?.city?.name,
                                    county: item?.item?.in_stock[0]?.warehouse?.location?.county?.name,
                                    state: item?.item?.in_stock[0]?.warehouse?.location?.state?.name,
                                    country: item?.item?.in_stock[0]?.warehouse?.location?.country?.name,
                                    continent: item?.item?.in_stock[0]?.warehouse?.location?.continent?.name,
                                  },
                                };
                              }),
                      services_bundles:
                        inputFields.selected_group_items?.filter((i: any) => i?.item?.__typename === 'Service')?.length === 0
                          ? []
                          : inputFields.selected_group_items
                              ?.filter((i: any) => i?.item?.__typename === 'Service')
                              ?.map((item: any) => {
                                return {
                                  item_id: item?.item?.id,
                                  item_type: item?.item?.__typename,
                                  item_amount: parseFloat(item?.amount.toString()),
                                  item_cost: {
                                    value: parseFloat(item?.item?.price?.value.toString()),
                                    currency: item?.item?.price?.currency?.currency_code,
                                  },
                                  item_start_date: sportSession?.start_date,
                                  item_end_date: sportSession?.end_date,
                                  item_destination: {
                                    title: sportSession?.challenge?.complex?.location?.title,
                                    street_name: sportSession?.challenge?.complex?.location?.street_name,
                                    secondary_street_name: sportSession?.challenge?.complex?.location?.secondary_street_name,
                                    country_code: sportSession?.challenge?.complex?.location?.country_code,
                                    postcode: sportSession?.challenge?.complex?.location?.postcode?.name,
                                    city: sportSession?.challenge?.complex?.location?.city?.name,
                                    county: sportSession?.challenge?.complex?.location?.county?.name,
                                    state: sportSession?.challenge?.complex?.location?.state?.name,
                                    country: sportSession?.challenge?.complex?.location?.country?.name,
                                    continent: sportSession?.challenge?.complex?.location?.continent?.name,
                                  },
                                  origin_location: {
                                    id: item?.item?.in_stock[0]?.warehouse?.location?.id,
                                    title: item?.item?.in_stock[0]?.warehouse?.location?.title || '',
                                    street_name: item?.item?.in_stock[0]?.warehouse?.location?.street_name || '',
                                    secondary_street_name: item?.item?.in_stock[0]?.warehouse?.location?.secondary_street_name || '',
                                    country_code: item?.item?.in_stock[0]?.warehouse?.location?.country_code || '',
                                    postcode: item?.item?.in_stock[0]?.warehouse?.location?.postcode?.name || '',
                                    city: item?.item?.in_stock[0]?.warehouse?.location?.city?.name || '',
                                    county: item?.item?.in_stock[0]?.warehouse?.location?.county?.name || '',
                                    state: item?.item?.in_stock[0]?.warehouse?.location?.state?.name || '',
                                    country: item?.item?.in_stock[0]?.warehouse?.location?.country?.name || '',
                                    continent: item?.item?.in_stock[0]?.warehouse?.location?.continent?.name || '',
                                  },
                                };
                              }),
                      total_cost: {
                        value: parseFloat(_.sum(inputFields.selected_group_items?.map(async (i: any) => (await PRICE_IN_MAAT(i?.item?.price?.value, i?.item?.price?.currency?.currency_code)) * i?.amount))?.toString()) || 0,
                        currency: 'MAT',
                      },
                    },
                  },
                });
              }

              if (sportSession?.group_order?.id) {
                // await addToGroupOrderBySportSession({})
              }
            }

            // EXECUTE TRANSACTION
            try {
              await executeDirectFiatPaymentTransaction({
                variables: {
                  transaction: {
                    logged_account_id: loggedPlayer?.id,
                    spending_password: inputFields.spending_password,
                    tx_data: {
                      id: result.data?.createTransactionBySportSessionID[0]?.id,
                      sender_payment_method_id: sender_payment_method?.id,
                      receiver_payment_method_id: ALL_COMPLEX_PAYMENT_METHODS[0]?.id,
                    },
                  },
                },
              });
            } catch (e) {
              console.log(e);
            }
          } catch (e) {
            console.log(e);
          }
        }

        handleClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    props.handleClose();
  };

  React.useEffect(() => {
    (async () => {
      if (props.open && sportSession === undefined && loadedSportSession?.data?.findSportSessionByIDByPlayer) {
        setSportSession(loadedSportSession?.data?.findSportSessionByIDByPlayer);
      }

      if (!props.open && sportSession !== undefined) {
        setSportSession(undefined);
      }
    })();
  }, [props, loadedSportSession, sportSession, setSportSession]);

  React.useEffect(() => {
    if ((_.startsWith(inputFields.card_number, '51') || _.startsWith(inputFields.card_number, '52') || _.startsWith(inputFields.card_number, '53') || _.startsWith(inputFields.card_number, '54') || _.startsWith(inputFields.card_number, '55')) && inputFields.card_network !== 'MASTERCARD') {
      setValue('card_network', 'MASTERCARD');
    } else if (_.startsWith(inputFields.card_number, '4') && inputFields.card_network !== 'VISA') {
      setValue('card_network', 'VISA');
    } else if ((_.startsWith(inputFields.card_number, '34') || _.startsWith(inputFields.card_number, '37')) && inputFields.card_network !== 'AMEX') {
      setValue('card_network', 'AMEX');
    }
  }, [inputFields, setValue]);

  const steps =
    loggedAccountPersonalWallets?.length > 0
      ? [
          {
            label: 'Payment Method',
            content: (
              <>
                <Controller
                  name="payment_method_id"
                  control={control}
                  rules={{
                    required: false,
                    pattern: /^[0-9a-zA-Z]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      fullWidth
                      className={'field-bottom-space'}
                      label="Payment method"
                      placeholder="Payment method"
                      variant="outlined"
                      sx={
                        ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_method?.name === 'LIPAY' && ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_source?.__typename?.includes('Blockchain')
                          ? {
                              mr: {
                                xs: '0',
                                sm: '3%',
                                md: '3%',
                              },
                              width: {
                                xs: '100%',
                                sm: '64%',
                                md: '64%',
                              },
                            }
                          : {}
                      }
                    >
                      {ALL_PAYMENT_METHODS?.filter((pm: any) => loggedAccountPersonalWallets?.find((w: any) => w?.id === pm?.wallets[0]?.id))?.map((option: any) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          <Grid
                            container
                            sx={{
                              alignItems: 'center',
                            }}
                          >
                            {PAYMENT_METHODS?.find((pm) => pm?.name === option?.name)?.icon_white}
                            &nbsp;&nbsp;
                            <Typography sx={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                              {_.capitalize(option?.payment_source?.name || option?.payment_source?.title || option?.payment_source?.network).replaceAll('_', ' ')}&nbsp;
                              {option?.payment_source?.network ? CARD_NETWORKS?.find((n: any) => n?.name === option?.payment_source?.network)?.icon : ''}
                              {option?.payment_source?.name ? ALTERNATIVE_PAYMENTS?.find((n: any) => n?.name === option?.payment_source?.name)?.icon : ''}
                            </Typography>
                            &nbsp;&nbsp;
                            <Typography variant="caption">from {option?.wallets[0]?.title}</Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                {ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_method?.name === 'LIPAY' && ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_source?.__typename?.includes('Blockchain') ? (
                  <Controller
                    name="payment_option"
                    control={control}
                    rules={{
                      required: false,
                      pattern: /^[0-9a-zA-Z]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        value={field.value}
                        select
                        fullWidth
                        className={'field-bottom-space'}
                        label="Lipay payment option"
                        placeholder="Payment option"
                        variant="outlined"
                        sx={{
                          ml: {
                            xs: '0',
                            sm: '3%',
                            md: '3%',
                          },
                          width: {
                            xs: '100%',
                            sm: '30%',
                            md: '30%',
                          },
                        }}
                      >
                        {LIPAY_PAYMENT_OPTIONS.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ) : (
                  ''
                )}
              </>
            ),
          },
          {
            label: 'Confirmation',
            content: (
              <>
                <Controller
                  name="spending_password"
                  control={control}
                  rules={{
                    required: loggedAccountPersonalWallets.length === 0 ? true : false,
                    minLength: min_code_length,
                    maxLength: 255,
                  }}
                  render={({ field }: any) => (
                    <Grid sx={{ m: '30px 0' }}>
                      <Typography sx={{ textAlign: 'left', m: '10px 0' }}>Spending Password (min {min_code_length} characters)</Typography>
                      <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}></Grid>
                      <TextField
                        {...field}
                        label="Please enter your spending code"
                        type={!hidePassword ? 'password' : 'text'}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                                {!hidePassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                />
              </>
            ),
          },
        ]
      : [
          {
            label: 'Wallet Information',
            content: (
              <>
                <Controller
                  name="wallet_title"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 1000,
                    minLength: 4,
                  }}
                  render={({ field }: any) => <TextField {...field} value={field.value} label="Title" placeholder={`${loggedPlayer?.owner?.firstname}'s wallet`} variant="outlined" fullWidth required className={'field-bottom-space'} />}
                />

                {loggedAccountPersonalWallets.length === 0 ? (
                  <Controller
                    name="spending_password"
                    control={control}
                    rules={{
                      required: loggedAccountPersonalWallets.length === 0 ? true : false,
                      minLength: min_code_length,
                      maxLength: 255,
                    }}
                    render={({ field }: any) => (
                      <Grid sx={{ m: '30px 0' }}>
                        <Typography sx={{ textAlign: 'left', m: '10px 0' }}>Spending Password (min {min_code_length} characters)</Typography>
                        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}></Grid>
                        <TextField
                          {...field}
                          label="Please enter your spending code"
                          type={hidePassword ? 'password' : 'text'}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                                  {hidePassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Typography sx={{ fontSize: '0.8em', m: '10px 0' }}>This password will be use to confirm any payment transactions</Typography>
                      </Grid>
                    )}
                  />
                ) : (
                  ''
                )}
              </>
            ),
          },
          {
            label: 'Payment Method',
            content: (
              <>
                <Controller
                  name="payment_method_name"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="Payment provider"
                      placeholder="Input your payment method"
                      select
                      fullWidth
                      variant="outlined"
                      className={'field-bottom-space'}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{PAYMENT_METHODS?.find((pm: any) => pm?.name === field.value)?.icon_white}</InputAdornment>,
                      }}
                    >
                      {PAYMENT_METHODS?.map((pm: any, index: number) => (
                        <MenuItem key={index} value={pm?.name}>
                          {_.capitalize(pm?.name?.replaceAll('_', ' '))}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="card_holder"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} label="Card owner" placeholder="Input fullname on the card" variant="outlined" fullWidth helperText=" " />}
                />

                <Controller
                  name="card_number"
                  control={control}
                  rules={{ required: true, pattern: /^[0-9]*$/ }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="Card Number"
                      placeholder="XXXX XXXX XXXX XXXX"
                      variant="outlined"
                      value={field.value}
                      fullWidth
                      required
                      helperText=" "
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CardPaymentIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="card_type"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      label={`Type`}
                      variant="outlined"
                      required
                      helperText={' '}
                      sx={{
                        mr: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    >
                      {['CREDIT', 'DEBIT', 'VIRTUAL_CREDIT', 'VIRTUAL_DEBIT'].map((option: any, index: number) => (
                        <MenuItem key={index} value={option}>
                          {_.capitalize(_.replace(option, '_', ' '))}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="card_network"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      label={`Network`}
                      disabled={_.startsWith(inputFields.card_number, '4') || _.startsWith(inputFields.card_number, '51') || _.startsWith(inputFields.card_number, '52') || _.startsWith(inputFields.card_number, '53') || _.startsWith(inputFields.card_number, '54') || _.startsWith(inputFields.card_number, '55') || _.startsWith(inputFields.card_number, '34') || _.startsWith(inputFields.card_number, '37')}
                      variant="outlined"
                      required
                      helperText={' '}
                      sx={{
                        ml: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    >
                      {CARD_NETWORKS.map((option: any, index: number) => (
                        <MenuItem key={index} value={option?.name}>
                          {_.capitalize(_.replace(option?.name, '_', ' '))}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="card_exp"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <DatePicker
                      {...field}
                      format="MM/yyyy"
                      views={['month', 'year']}
                      minDate={new Date()}
                      value={field.value}
                      slotProps={{
                        textField: {
                          label: 'Expiration',
                          required: true,
                          placeholder: 'Input expiration date',
                          className: 'field-bottom-space',
                          InputLabelProps: { shrink: true },
                          sx: {
                            mr: {
                              xs: '0',
                              sm: '3%',
                              md: '5%',
                            },
                            width: {
                              xs: '100%',
                              sm: '47%',
                              md: '45%',
                            },
                          },
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="card_cvc"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 5,
                    minLength: 3,
                    max: 5,
                    pattern: /^[0-9]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="CVC"
                      type="number"
                      required
                      helperText="3-4 digits behind your card"
                      placeholder="XXX"
                      variant="outlined"
                      className={'field-bottom-space'}
                      sx={{
                        ml: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BankCardIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller control={control} name="account_number" render={({ field }: any) => <TextField {...field} label="Account Number" type="text" placeholder="Input account number" variant="outlined" fullWidth className={'field-bottom-space'} />} />

                <Controller control={control} name="account_iban" render={({ field }: any) => <TextField {...field} label="IBAN" type="text" placeholder="Input IBAN number" variant="outlined" fullWidth className={'field-bottom-space'} />} />

                {inputFields?.accepted_currencies?.length === 0 ? (
                  ''
                ) : (
                  <Grid container sx={{ justifyContent: 'center', mb: '20px' }}>
                    <Typography sx={{ fontWeight: '700', fontSize: '1.2em', display: 'inline-block', width: '100%', textAlign: 'center' }}>Accepted currencies</Typography>

                    {inputFields?.accepted_currencies?.map((c: any) => (
                      <Chip
                        key={c?.value}
                        label={
                          <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                            <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(COUNTRIES?.find((co: any) => co?.value === c?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(COUNTRIES?.find((co: any) => co?.value === c?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                            &nbsp;{c?.label}
                          </Typography>
                        }
                        sx={{ m: '10px 10px 0 0', bgcolor: 'rgba(167, 173, 175, .3)' }}
                        onDelete={() => handleUnselectCurrency(c)}
                      />
                    ))}
                  </Grid>
                )}
                <Controller
                  name="all_currencies"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField {...field} value={field.value} select label="Account Currency" placeholder="Select your currency" variant="outlined" required fullWidth className={'field-bottom-space'}>
                      {CURRENCIES?.filter((c: any) => inputFields?.accepted_currencies?.find((ac: any) => ac?.value === c?.value) === undefined).map((option: any) => (
                        <MenuItem key={option.value} value={option.value} onClick={() => handleSelectCurrency(option)}>
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(COUNTRIES?.find((c: any) => c?.value === option?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(COUNTRIES?.find((c: any) => c?.value === option?.country_code)?.value || 'EU', '_', 1)[0].toLowerCase()}.png 2x`} alt="" /> &nbsp;{option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                {inputFields?.bank_country ? (
                  <Grid sx={{ textAlign: 'center', mb: '20px' }}>
                    <Chip
                      label={
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(inputFields?.bank_country?.value, '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(inputFields?.bank_country?.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                          &nbsp;{inputFields?.bank_country?.label}
                        </Typography>
                      }
                      sx={{ m: '10px 10px 0 0', bgcolor: 'rgba(167, 173, 175, .3)' }}
                    />
                  </Grid>
                ) : (
                  ''
                )}
                <Controller
                  name="all_countries"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      label={
                        <>
                          Bank Card originate from {inputFields?.bank_country?.label} {<img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(inputFields?.bank_country?.value, '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(inputFields?.bank_country?.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />}
                        </>
                      }
                      helperText={`Make sure to select the country where your bank is located`}
                      variant="outlined"
                      required
                      fullWidth
                      className={'field-bottom-space'}
                    >
                      {COUNTRIES?.filter((c: any) => inputFields?.bank_country?.value !== c?.value).map((option: any) => (
                        <MenuItem key={option.value} value={option?.value} onClick={() => handleSelectBankCountry(option)}>
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" /> &nbsp;{option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </>
            ),
          },
          {
            label: 'KYC Verification',
            content: (
              <>
                <Controller
                  name="id_number"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-z0-9_]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} value={field.value} label="ID #" placeholder="Input your ID number" variant="outlined" fullWidth className={'field-bottom-space'} />}
                />

                <Controller
                  name="id_type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      label="ID Type"
                      placeholder="Select an ID type"
                      variant="outlined"
                      className={'field-bottom-space'}
                      sx={{
                        mr: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    >
                      {ID_TYPES.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="id_exp"
                  control={control}
                  render={({ field }: any) => (
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      views={['day', 'month', 'year']}
                      minDate={new Date()}
                      value={field.value}
                      slotProps={{
                        textField: {
                          label: 'ID Expiration',
                          placeholder: 'Input expiration date',
                          className: 'field-bottom-space',
                          sx: {
                            ml: {
                              xs: '0',
                              sm: '3%',
                              md: '5%',
                            },
                            width: {
                              xs: '100%',
                              sm: '47%',
                              md: '45%',
                            },
                          },
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="id_issuer"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    pattern: /^[a-z0-9_-]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} value={field.value} label="Authority issuer" placeholder="Input authority issuer's name" variant="outlined" fullWidth className={'field-bottom-space'} />}
                />

                <Controller
                  control={control}
                  name="id_issuer_country"
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      options={COUNTRIES}
                      value={idIssuerCountry}
                      className={'country-select'}
                      onChange={(event: any, value: any) => handleIdIssuerCountry(event, value)}
                      autoHighlight
                      getOptionLabel={(option: CountryType) => option.label}
                      isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                      renderOption={(props: any, option: CountryType) => (
                        <Box
                          component="li"
                          sx={{
                            '& > img': {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                          {option.label} ({option.value})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ID issuer country"
                          value={idIssuerCountry}
                          required
                          variant="outlined"
                          className={'field-bottom-space'}
                          error={errors?.id_issuer_country !== undefined}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />

                <Stack direction="row">
                  <Controller
                    name="pid_front"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={() => (
                      <ImageUploading value={pidFrontFile} onChange={handlePidFrontFile} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                          <Grid
                            container
                            sx={{
                              justifyContent: 'center',
                              width: '50%',
                              display: 'inline-flex',
                              mt: '20px',
                            }}
                          >
                            {pidFrontFile.length === 1 ? (
                              ''
                            ) : (
                              <Fab
                                style={
                                  isDragging
                                    ? {
                                        color: 'rgba(31, 175, 146, .3)',
                                      }
                                    : undefined
                                }
                                onClick={onImageUpload}
                                variant="extended"
                                size="small"
                                {...dragProps}
                                className="button-green-reverse"
                                sx={{
                                  background: 'transparent',
                                  boxShadow: 'none',
                                  mb: '20px',
                                }}
                              >
                                Add ID (front) <PhotoCamera />
                              </Fab>
                            )}
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              sx={{
                                overflow: 'auto',
                              }}
                            >
                              {imageList[0] ? (
                                <Stack
                                  direction="row"
                                  spacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                  }}
                                  sx={{
                                    p: '10px 0',
                                    justifyContent: 'center',
                                  }}
                                  className="upload__image-wrapper"
                                >
                                  {imageList?.map((image: any, index: any) => (
                                    <div key={index} className="image-item">
                                      <CardMedia
                                        component={'img'}
                                        src={dataURLtoBlobURL(image?.dataURL)}
                                        alt={image?.name}
                                        sx={{
                                          height: {
                                            xs: 125,
                                            sm: 150,
                                            md: 200,
                                          },
                                          width: 'auto',
                                          margin: 'auto',
                                        }}
                                      />
                                      <Grid
                                        className="image-item__btn-wrapper"
                                        sx={{
                                          justifyContent: 'center',
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Fab size="small" onClick={() => onImageUpdate(index)}>
                                          <Cached />
                                        </Fab>
                                        <Fab size="small" onClick={() => onImageRemove(index)}>
                                          <Remove />
                                        </Fab>
                                      </Grid>
                                    </div>
                                  ))}
                                </Stack>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </ImageUploading>
                    )}
                  />

                  <Controller
                    name="pid_back"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={() => (
                      <ImageUploading value={pidBackFile} onChange={handlePidBackFile} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                          <Grid
                            container
                            sx={{
                              justifyContent: 'center',
                              width: '50%',
                              display: 'inline-flex',
                              mt: '20px',
                            }}
                          >
                            {pidBackFile.length === 1 ? (
                              ''
                            ) : (
                              <Fab
                                style={
                                  isDragging
                                    ? {
                                        color: 'rgba(31, 175, 146, .3)',
                                      }
                                    : undefined
                                }
                                onClick={onImageUpload}
                                variant="extended"
                                size="small"
                                {...dragProps}
                                className="button-green-reverse"
                                sx={{
                                  background: 'transparent',
                                  boxShadow: 'none',
                                  mb: '20px',
                                }}
                              >
                                Add ID (back) <PhotoCamera />
                              </Fab>
                            )}
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              sx={{
                                overflow: 'auto',
                              }}
                            >
                              {imageList[0] ? (
                                <Stack
                                  direction="row"
                                  spacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                  }}
                                  sx={{
                                    p: '10px 0',
                                    justifyContent: 'center',
                                  }}
                                  className="upload__image-wrapper"
                                >
                                  {imageList?.map((image: any, index: any) => (
                                    <div key={index} className="image-item">
                                      <CardMedia
                                        component={'img'}
                                        src={dataURLtoBlobURL(image?.dataURL)}
                                        alt={image?.name}
                                        sx={{
                                          height: {
                                            xs: 125,
                                            sm: 150,
                                            md: 200,
                                          },
                                          width: 'auto',
                                          margin: 'auto',
                                        }}
                                      />
                                      <Grid
                                        className="image-item__btn-wrapper"
                                        sx={{
                                          justifyContent: 'center',
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Fab size="small" onClick={() => onImageUpdate(index)}>
                                          <Cached />
                                        </Fab>
                                        <Fab size="small" onClick={() => onImageRemove(index)}>
                                          <Remove />
                                        </Fab>
                                      </Grid>
                                    </div>
                                  ))}
                                </Stack>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </ImageUploading>
                    )}
                  />
                </Stack>

                <Controller
                  name="id_selfie"
                  control={control}
                  rules={{ required: true }}
                  render={() => (
                    <ImageUploading value={idSelfie} onChange={handleIdSelfie} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                      {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                        <Grid container sx={{ pb: '30px', justifyContent: 'center' }}>
                          {idSelfie.length === 1 ? (
                            ''
                          ) : (
                            <Fab
                              style={isDragging ? { color: 'green' } : undefined}
                              onClick={onImageUpload}
                              variant="extended"
                              size="small"
                              {...dragProps}
                              className="button-green-reverse"
                              sx={{
                                background: 'transparent',
                                boxShadow: 'none',
                                m: '0 5px',
                              }}
                            >
                              Add Selfie <PhotoCamera />
                            </Fab>
                          )}
                          <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
                            {imageList[0] ? (
                              <Stack
                                direction="row"
                                spacing={{
                                  xs: 1,
                                  sm: 2,
                                  md: 4,
                                }}
                                sx={{ p: '10px 0' }}
                                className="upload__image-wrapper"
                              >
                                {imageList?.map((image: any, index: any) => (
                                  <div key={index} className="image-item">
                                    <CardMedia
                                      component={'img'}
                                      src={dataURLtoBlobURL(image?.dataURL)}
                                      alt={image?.name}
                                      sx={{
                                        height: {
                                          xs: 125,
                                          sm: 150,
                                          md: 200,
                                        },
                                        width: 'auto',
                                        margin: 'auto',
                                      }}
                                    />
                                    <Grid
                                      className="image-item__btn-wrapper"
                                      sx={{
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <Fab size="small" onClick={() => onImageUpdate(index)}>
                                        <Cached />
                                      </Fab>
                                      <Fab size="small" onClick={() => onImageRemove(index)}>
                                        <Remove />
                                      </Fab>
                                    </Grid>
                                  </div>
                                ))}
                              </Stack>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </ImageUploading>
                  )}
                />

                <h4>Home address</h4>

                <Controller
                  name="home_street_name"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9-\s]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} label="Street address" placeholder="Write your home's street address" variant="outlined" fullWidth value={field.value} error={errors?.home_street_name !== undefined} className={'field-bottom-space'} helperText={errors?.home_street_name !== undefined ? `${errors?.home_street_name?.message}` : ''} />}
                />

                <Controller
                  name="home_secondary_street_name"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9-\s]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} label="Street address (complement)" placeholder="Complement of home's street address" variant="outlined" fullWidth value={field.value} className={'field-bottom-space'} error={errors?.home_secondary_street_name !== undefined} helperText={errors?.home_secondary_street_name !== undefined ? `${errors?.home_secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} />}
                />

                <Controller
                  name="home_postcode"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9-\s]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="Postcode"
                      placeholder="Home's postcode"
                      variant="outlined"
                      value={field.value}
                      error={errors?.home_postcode !== undefined}
                      className={'field-bottom-space'}
                      helperText={errors?.home_postcode !== undefined ? `${errors?.home_postcode?.message}` : ''}
                      sx={{
                        mr: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    />
                  )}
                />

                <Controller
                  name="home_city"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9-\s]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="City"
                      placeholder="Home's city"
                      variant="outlined"
                      value={field.value}
                      error={errors?.home_city !== undefined}
                      className={'field-bottom-space'}
                      helperText={errors?.home_city !== undefined ? `${errors?.home_city?.message}` : ''}
                      sx={{
                        ml: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    />
                  )}
                />

                <Controller
                  name="home_county"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9-\s]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="County"
                      placeholder="Home's county"
                      variant="outlined"
                      value={field.value}
                      error={errors?.home_county !== undefined}
                      className={'field-bottom-space'}
                      helperText={errors?.home_county !== undefined ? `${errors?.home_county?.message}` : ''}
                      sx={{
                        mr: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    />
                  )}
                />

                <Controller
                  name="home_state"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9-\s]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label="State"
                      placeholder="Home's state"
                      variant="outlined"
                      value={field.value}
                      error={errors?.home_state !== undefined}
                      className={'field-bottom-space'}
                      helperText={errors?.home_state !== undefined ? `${errors?.home_state?.message}` : ''}
                      sx={{
                        ml: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="home_country"
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      options={COUNTRIES}
                      value={homeCountry}
                      className={'country-select'}
                      onChange={(event: any, value: any) => onChangeCountry(event, value)}
                      autoHighlight
                      getOptionLabel={(option: CountryType) => option.label}
                      isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                      renderOption={(props: any, option: CountryType) => (
                        <Box
                          component="li"
                          sx={{
                            '& > img': { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                          {option.label} ({option.value})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Home's country"
                          value={homeCountry}
                          variant="outlined"
                          error={errors?.home_country !== undefined}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />
              </>
            ),
          },
        ]?.filter((s: any) => s !== undefined);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { 'minWidth': '70%', 'maxWidth': '80%', 'p': '20px 0px', 'bgcolor': 'rgba(15, 15, 15, 1)', '& p, & span': { color: 'rgba(247, 251, 250, 1)' } },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        disabled={exectuedTransaction.loading || newIndividualOrder.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || updatedPlayer.loading || newLocation.loading || newWallet.loading || newKYCCase.loading || newPaymentProviders.loading || newTransaction.loading}
        className={exectuedTransaction.loading || newIndividualOrder.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || updatedPlayer.loading || newLocation.loading || newWallet.loading || newKYCCase.loading || newPaymentProviders.loading || newTransaction.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>
          <FormattedMessage id="PaySportSessionModal_Pay" />
          &nbsp;{sportSession ? `"${sportSession?.title}"` : <FormattedMessage id="PaySportSessionModal_Title" />}
        </Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        {isProceedingPayment ? (
          <>
            <DialogContentText id="alert-dialog-description" component="span" sx={{ p: '0 0 20px' }}>
              {activeStep === 0 ? (
                <>
                  <Grid container sx={{ justifyContent: 'end' }}>
                    <Grid item xs={11.5} sm={11} md={10} lg={10} sx={{ bgcolor: 'rgb(33,35,41)', p: '20px 0 20px 10%', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                      <Grid container>
                        <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'left' }}>
                          <Typography>
                            {inputFields.consumers_to_pay?.length} {inputFields.consumers_to_pay?.length > 1 ? <FormattedMessage id="PaySportSessionModal_Players_To_Pay" /> : <FormattedMessage id="PaySportSessionModal_Player_To_Pay" />}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} sx={{ textAlign: 'right', pr: '5%' }}>
                          <Typography variant="caption" sx={{ fontWeight: '700', lineHeight: '15px', display: 'block' }}>
                            {inputFields.consumers_to_pay?.length} x {sportSession?.cost?.currency?.currency_code} {(sportSession?.cost?.value / ALL_TEAM_PLAYERS?.length) * inputFields.consumers_to_pay?.length}
                          </Typography>
                        </Grid>

                        <Grid container className="personal-items">
                          {inputFields.selected_personal_items?.length > 0 ? <Typography sx={{ color: 'rgba(168, 202, 65, 1) !important', mt: '15px', textTransform: 'uppercase', fontSize: '.8em' }}>Personal snacks</Typography> : ''}
                          {inputFields.selected_personal_items?.map((item: any, index: number) => (
                            <Grid key={index} sx={{ width: '100%' }}>
                              <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={8} sm={8} md={8} lg={8} sx={{ textAlign: 'left' }}>
                                  <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton className="button-red-reversed" onClick={() => handleRemovePersonalItem(item?.item?.id)}>
                                      <Delete />
                                    </IconButton>
                                    <Avatar
                                      sx={{
                                        height: {
                                          xs: '30px !important',
                                          md: '40px !important',
                                          lg: '50px !important',
                                        },
                                        width: {
                                          xs: '30px !important',
                                          md: '40px !important',
                                          lg: '50px !important',
                                        },
                                        m: '5px 0',
                                      }}
                                      alt={item?.item?.name}
                                      src={item?.item?.pictures?.length > 0 ? item?.item?.pictures[0]?.path : undefined}
                                    />
                                    &nbsp;
                                    {item?.item?.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'right', pr: '5%' }}>
                                  <Typography variant="caption" sx={{ fontWeight: '700', lineHeight: '15px', display: 'block' }}>
                                    {item?.amount} x {item?.item?.price?.currency?.currency_code} {item?.item?.price?.value}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {index === inputFields.selected_personal_items?.length - 1 || (inputFields.selected_personal_items?.length === 1 && index === 0) ? '' : <Divider sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '5px 0' }} />}
                            </Grid>
                          ))}
                        </Grid>

                        <Grid container className="group-items">
                          {inputFields.selected_group_items?.length > 0 ? (
                            <Typography sx={{ color: 'rgba(168, 202, 65, 1) !important', mt: '15px', textTransform: 'uppercase', fontSize: '.8em' }}>
                              <FormattedMessage id="PaySportSessionModal_Group_Snacks" />
                              &nbsp;({sportSession?.cost?.currency?.currency_code} {_.sum(inputFields.selected_group_items?.map((i: any) => i?.item?.price?.value)) / ALL_TEAM_PLAYERS?.length} <FormattedMessage id="PaySportSessionModal_Per_Player" />)
                            </Typography>
                          ) : (
                            ''
                          )}
                          {inputFields.selected_group_items?.map((item: any, index: number) => (
                            <Grid key={index} sx={{ width: '100%' }}>
                              <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={8} sm={8} md={8} lg={8} sx={{ textAlign: 'left' }}>
                                  <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton className="button-red-reversed" onClick={() => handleRemoveGroupItem(item?.item?.id)}>
                                      <Delete />
                                    </IconButton>
                                    <Avatar
                                      sx={{
                                        height: {
                                          xs: '30px !important',
                                          md: '40px !important',
                                          lg: '50px !important',
                                        },
                                        width: {
                                          xs: '30px !important',
                                          md: '40px !important',
                                          lg: '50px !important',
                                        },
                                        m: '5px 0',
                                      }}
                                      alt={item?.item?.name}
                                      src={item?.item?.pictures?.length > 0 ? item?.item?.pictures[0]?.path : undefined}
                                    />
                                    &nbsp;
                                    <Typography sx={{ lineHeight: '15px' }}>
                                      {item?.item?.name}
                                      <br />
                                      <Typography variant="caption">
                                        ({item?.amount} x {item?.item?.price?.currency?.currency_code} {item?.item?.price?.value})
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'right', pr: '5%' }}>
                                  <Typography variant="caption" sx={{ fontWeight: '700', lineHeight: '15px', display: 'block' }}>
                                    {inputFields.consumers_to_pay?.length} x {item?.item?.price?.currency?.currency_code} {item?.item?.price?.value / ALL_TEAM_PLAYERS?.length}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {index === inputFields.selected_group_items?.length - 1 || (inputFields.selected_group_items?.length === 1 && index === 0) ? '' : <Divider sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '5px 0' }} />}
                            </Grid>
                          ))}
                        </Grid>

                        <Grid container className="group-order">
                          {sportSession?.group_order ? (
                            <Typography sx={{ fontWeight: '700', mt: '15px', textTransform: 'uppercase', fontSize: '.6em' }}>
                              {ALL_TEAM_PLAYERS?.filter((p: any) => !sportSession?.group_order?.notes?.includes(p?.player?.player?.id))?.length}&nbsp;
                              {ALL_TEAM_PLAYERS?.filter((p: any) => !sportSession?.group_order?.notes?.includes(p?.player?.player?.id))?.length === ALL_TEAM_PLAYERS?.length ? <FormattedMessage id="PaySportSessionModal_Fully_Paid_Group_Snacks" /> : <FormattedMessage id="PaySportSessionModal_Unpaid_Group_Snacks" />}
                              &nbsp;({sportSession?.group_order?.total_cost?.currency?.currency_code} {sportSession?.group_order?.total_cost?.value / ALL_TEAM_PLAYERS?.length}&nbsp;
                              <FormattedMessage id="PaySportSessionModal_Per_Player" />)
                            </Typography>
                          ) : (
                            ''
                          )}
                          {sportSession?.group_order
                            ? ALL_TEAM_PLAYERS?.filter((p: any) => p?.player?.player?.id === loggedPlayer?.id)?.map((team_player: any, index: number) => (
                                <Grid key={index} sx={{ width: '100%' }}>
                                  <Grid container sx={{ alignItems: 'center' }}>
                                    <Grid item xs={8} sm={8} md={8} lg={8} sx={{ textAlign: 'left' }}>
                                      <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar
                                          sx={{
                                            height: {
                                              xs: '30px !important',
                                              md: '40px !important',
                                              lg: '50px !important',
                                            },
                                            width: {
                                              xs: '30px !important',
                                              md: '40px !important',
                                              lg: '50px !important',
                                            },
                                            m: '5px 0',
                                          }}
                                          alt={team_player?.player?.alias}
                                          src={team_player?.player?.avatar?.path}
                                        />
                                        &nbsp;
                                        <Typography sx={{ lineHeight: '15px' }}>{team_player?.player?.alias}</Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'right', pr: '5%' }}>
                                      {ALL_TEAM_PLAYERS?.filter(() => sportSession?.group_order?.notes?.includes(loggedPlayer?.id)) ? (
                                        <Typography variant="caption" className="SUCCESSFUL" sx={{ textTransform: 'uppercase', fontWeight: '700', lineHeight: '15px', display: 'block' }}>
                                          <FormattedMessage id="PaySportSessionModal_Paid" />
                                        </Typography>
                                      ) : (
                                        <Typography variant="caption" className="WARNING" sx={{ textTransform: 'uppercase', fontWeight: '700', lineHeight: '15px', display: 'block' }}>
                                          <FormattedMessage id="PaySportSessionModal_Unpaid" />
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))
                            : ''}
                        </Grid>
                      </Grid>
                      <Divider sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '10px 0' }} />

                      <Grid container>
                        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ textAlign: 'left' }}>
                          <Typography>
                            <FormattedMessage id="PaySportSessionModal_Total" />
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} sx={{ textAlign: 'right', pr: '5%' }}>
                          <Typography sx={{ fontWeight: '700', fontSize: { xs: '1.2em', sm: '1.5em', md: '1.8em' }, background: '-webkit-linear-gradient(280deg, rgba(19, 96, 13, 1) 0%, rgba(168, 202, 65, 1) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                            {sportSession?.cost?.currency?.currency_code}{' '}
                            {(sportSession?.cost?.value ? sportSession?.cost?.value / ALL_TEAM_PLAYERS?.length : 0) * inputFields.consumers_to_pay?.length +
                              (ALL_TEAM_PLAYERS?.filter((p: any) => !sportSession?.group_order?.notes?.includes(p?.player?.player?.id)) ? (sportSession?.group_order?.total_cost?.value || 0) / ALL_TEAM_PLAYERS?.length : 0) +
                              (_.sum(inputFields.selected_group_items?.map((i: any) => i?.item?.price?.value)) / ALL_TEAM_PLAYERS?.length) * inputFields?.consumers_to_pay?.length +
                              _.sum(inputFields.selected_personal_items?.map((i: any) => i?.item?.price?.value * i?.amount))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Typography sx={{ fontWeight: '700', fontSize: '1.2em', p: '30px 10% 15px' }}>
                    <FormattedMessage id="PaySportSessionModal_Snacks" />
                  </Typography>
                  <Grid container sx={{ p: '0 10%' }}>
                    {isSmallMobile ? (
                      <Grid container sx={{ m: '0 0 10px', justifyContent: 'center' }}>
                        {!inputFields.selected_item ? (
                          <Typography
                            variant="caption"
                            sx={{
                              m: '0px',
                              p: '5px 20px',
                              textAlign: 'center',
                              bgcolor: 'rgba(0, 0, 0, .1)',
                              borderRadius: '5px',
                              width: '100%',
                              display: 'inline-block',
                            }}
                          >
                            No snack selected
                          </Typography>
                        ) : (
                          <Chip
                            variant="outlined"
                            label={<strong>{inputFields.selected_item?.name}</strong>}
                            avatar={
                              <Avatar
                                sx={{
                                  height: {
                                    xs: '50px !important',
                                    md: '60px !important',
                                    lg: '70px !important',
                                  },
                                  width: {
                                    xs: '50px !important',
                                    md: '60px !important',
                                    lg: '70px !important',
                                  },
                                  m: '5px 0',
                                }}
                                alt={inputFields.selected_item?.name}
                                src={inputFields.selected_item?.pictures?.length > 0 ? inputFields.selected_item?.pictures[0]?.path : undefined}
                              />
                            }
                            sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                          />
                        )}
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Controller
                      name="all_items"
                      control={control}
                      render={({ field }: any) => (
                        <Autocomplete
                          {...field}
                          open={openSearchSportPlayer}
                          onOpen={() => setOpenSearchSportPlayer(true)}
                          onClose={() => setOpenSearchSportPlayer(false)}
                          multiple
                          autoHighlight
                          fullWidth
                          filterSelectedOptions
                          value={field.value}
                          loading={loadedItems.loading}
                          inputValue={inputFields.item_name}
                          onInputChange={(event: any, newInputValue: any) => {
                            setValue('item_name', newInputValue);
                          }}
                          options={(ALL_ITEMS ? ALL_ITEMS : [])?.filter((cf: any) => inputFields.selected_personal_items?.find((p: any) => p?.id === cf?.id) === undefined)}
                          getOptionLabel={(option: any) => option?.id}
                          defaultValue={ARRAY_AS_ANY}
                          renderOption={(props: any, option: any) => (
                            <Grid
                              container
                              key={option?.id}
                              onClick={() => handleSelectItem(option)}
                              sx={{
                                alignItems: 'center',
                                p: '10px 15px',
                              }}
                            >
                              <Avatar src={option?.pictures?.length > 0 ? option?.pictures[0]?.path : undefined} sx={{ display: { xs: 'contents', sm: 'block' } }} />
                              <Grid sx={{ lineHeight: '15px', pl: '10px', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                                <strong>{option?.name}</strong>
                                <Typography variant="caption" sx={{ fontSize: '.8em', width: '100%', display: 'inline-block' }}>
                                  {option?.amount ? `Available: ${option?.amount}` : undefined || option?.status ? `Disponibility: ${option?.status}` : undefined}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={`Snacks`}
                              placeholder={`Write a product name`}
                              className={'field-bottom-space'}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    {isSmallMobile ? (
                                      ''
                                    ) : (
                                      <Grid>
                                        {!inputFields.selected_item ? (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              m: '0px',
                                              p: '5px 20px',
                                              textAlign: 'center',
                                              bgcolor: 'rgba(0, 0, 0, .1)',
                                              borderRadius: '5px',
                                            }}
                                          >
                                            No snack selected
                                          </Typography>
                                        ) : (
                                          <Chip
                                            variant="outlined"
                                            label={<strong>{inputFields.selected_item?.name}</strong>}
                                            avatar={
                                              <Avatar
                                                sx={{
                                                  height: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  width: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  m: '5px 0',
                                                }}
                                                alt={inputFields.selected_item?.name}
                                                src={inputFields.selected_item?.pictures?.length > 0 ? inputFields.selected_item?.pictures[0]?.path : undefined}
                                              />
                                            }
                                            sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                                          />
                                        )}
                                      </Grid>
                                    )}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />

                    <Controller
                      name="item_amount"
                      control={control}
                      rules={{
                        required: true,
                        min: 1,
                        max: inputFields.selected_item?.amount,
                      }}
                      render={({ field }: any) => (
                        <TextField
                          {...field}
                          label={`Amount of ${inputFields.selected_item?.name ? inputFields.selected_item?.name : 'snack'}`}
                          placeholder={'0'}
                          type="number"
                          variant="outlined"
                          value={field.value <= 0 ? 0 : field.value && field.value > inputFields.selected_item?.amount ? inputFields.selected_item?.amount : field.value}
                          required
                          fullWidth
                          className={'field-bottom-space'}
                          error={errors?.item_amount !== undefined}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" sx={{ color: 'rgba(247, 251, 250, 1)' }}>
                                {inputFields.selected_item ? (
                                  <>
                                    {inputFields.item_amount * inputFields.selected_item?.price?.value}
                                    &nbsp;
                                    {inputFields.selected_item?.price?.currency?.currency_code}
                                  </>
                                ) : (
                                  ''
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />

                    {sportSession?.group_order ? (
                      ''
                    ) : (
                      <Fab variant="extended" size="small" className={!inputFields.selected_item ? 'button-disabled' : 'button-green-reverse'} disabled={!inputFields.selected_item} sx={{ m: '10px' }} onClick={handleAddGroupItem}>
                        <TeamsIcon />
                        &nbsp;For the Group
                      </Fab>
                    )}
                    <Fab variant="extended" size="small" className={!inputFields.selected_item ? 'button-disabled' : 'button-green-reverse'} disabled={!inputFields.selected_item} sx={{ m: '10px' }} onClick={handleAddPersonalItem}>
                      {loggedPlayer?.id ? <Avatar src={loggedPlayer?.avatar?.path} alt={loggedPlayer?.username} sx={{ height: '30px', width: '30px' }} /> : <SingleMemberIcon />}&nbsp;For yourself
                    </Fab>
                  </Grid>
                  <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', width: '80%', m: '10px auto 30px' }} />
                </>
              ) : (
                ''
              )}
              <Typography sx={{ fontWeight: '700', fontSize: '1.2em', p: '30px 10% 15px' }}>
                <FormattedMessage id="PaySportSessionModal_Payment_Method" />
              </Typography>

              <Grid container className={'dark-stepper'} sx={{ p: '0 5%' }}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical" sx={{ width: '100%' }}>
                  {steps?.map((step: any, index: number) => (
                    <Step key={step?.label}>
                      <StepButton
                        color="inherit"
                        onClick={handleStep(index)}
                        sx={{
                          display: activeStep >= index ? 'inline-block' : 'none',
                        }}
                      >
                        {step?.label}
                      </StepButton>
                      <StepContent>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: activeStep >= index ? 'inline-block' : 'none',
                            width: '100%',
                          }}
                        >
                          {step?.content}
                        </Grid>
                        <Box sx={{ pt: '0px' }}>
                          <Stack direction={'row'}>
                            {activeStep === 0 || activeStep === steps.length - 1 ? (
                              ''
                            ) : (
                              <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                                <FormattedMessage id="PaySportSessionModal_Stepper_Back_Button" />
                              </Button>
                            )}
                            {activeStep === steps.length - 1 ? (
                              ''
                            ) : (
                              <Button onClick={handleNext} className={inputFields.spending_password.length < 10 && (loggedAccountPersonalWallets.length === 0 || inputFields.payment_method_id === '') ? 'button-disabled' : 'button-green-reverse'} disabled={inputFields.spending_password.length < 10 && (loggedAccountPersonalWallets.length === 0 || inputFields.payment_method_id === '')}>
                                {activeStep === steps.length - 1 ? <FormattedMessage id="PaySportSessionModal_Stepper_Finish_Button" /> : <FormattedMessage id="PaySportSessionModal_Stepper_Next_Button" />}
                              </Button>
                            )}
                          </Stack>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </DialogContentText>
          </>
        ) : (
          <>
            <DialogContentText id="alert-dialog-description" component="span" sx={{ p: '0 0 20px' }}>
              <Typography sx={{ fontWeight: '700', fontSize: { xs: '2em', sm: '2.5em', md: '3em' }, textAlign: 'center', background: '-webkit-linear-gradient(280deg, rgba(19, 96, 13, 1) 0%, rgba(168, 202, 65, 1) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                {sportSession?.cost?.currency?.currency_code} {sportSession?.cost?.value}
              </Typography>
              <Typography sx={{ textAlign: 'center', fontWeight: '700' }}>
                <FormattedMessage id="PaySportSessionModal_Price_per_Player" />
                &nbsp;
                <Typography component="span" sx={{ fontWeight: '700', textAlign: 'center', color: 'rgba(168, 202, 65, 1) !important' }}>
                  {sportSession?.cost?.currency?.currency_code} {sportSession?.cost?.value ? sportSession?.cost?.value / ALL_TEAM_PLAYERS?.length : 0}
                </Typography>
              </Typography>
              <Typography sx={{ fontSize: '.8em', textAlign: 'center', opacity: '.4', p: '30px 10% 0' }}>
                <FormattedMessage id="PaySportSessionModal_Notification" />
              </Typography>
            </DialogContentText>
            <Grid sx={{ bgcolor: 'rgb(24, 30, 36)', p: '30px 5%', mt: '30px' }}>
              <Typography sx={{ fontWeight: '700', fontSize: '1.4em' }}>
                <FormattedMessage id="PaySportSessionModal_Squad" />
              </Typography>

              {sportSession?.consumers?.find((c: any) => c?.player?.player?.player?.id === loggedPlayer?.id) ? (
                <Grid sx={{ textAlign: 'center' }}>
                  <Avatar src={loggedPlayer?.avatar?.path} alt={loggedPlayer?.username} sx={{ width: '60px', height: '60px', m: '20px auto 10px' }} />
                  <Typography sx={{ fontWeight: '700' }}>
                    <FormattedMessage id="PaySportSessionModal_You_Paid" />
                    <br />
                    💰💰💰
                  </Typography>
                </Grid>
              ) : (
                <>
                  {_.concat(HOME_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) ? HOME_TEAM_PLAYERS : [], AWAY_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) ? AWAY_TEAM_PLAYERS : [])?.map((p: any) => (
                    <Card key={p?.id} sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                      <CardHeader
                        avatar={<Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} sx={{ width: { xs: '40px', sm: '60px' }, height: { xs: '40px', sm: '60px' } }} />}
                        title={p?.player?.alias}
                        titleTypographyProps={{ fontWeight: '700', fontSize: '1.1em' }}
                        subheader={
                          sportSession?.consumers?.find((c: any) => c?.player?.id === p?.id) ? (
                            <>
                              <FormattedMessage id="PaySportSessionModal_Paid_By" />
                            </>
                          ) : p?.player?.player?.id === loggedPlayer?.id ? (
                            <Button className={inputFields.consumers_to_pay?.find((id: string) => id === p?.id) ? 'button-green-reverse' : 'button-disabled-reverse'} onClick={inputFields.consumers_to_pay?.find((id: string) => id === p?.id) ? () => handleRemoveConsumer(p) : () => handlePayForYourself(p)} sx={{ fontSize: '.8em', pt: '2px', pb: '2px' }}>
                              <FormattedMessage id="PaySportSessionModal_Pay_For_Yourself" />
                            </Button>
                          ) : (
                            <Button className={inputFields.consumers_to_pay?.find((id: string) => id === p?.id) ? 'button-green-reverse' : 'button-disabled-reverse'} onClick={inputFields.consumers_to_pay?.find((id: string) => id === p?.id) ? () => handleRemoveConsumer(p) : () => handlePayForThem(p)} sx={{ fontSize: '.8em', pt: '2px', pb: '2px' }}>
                              <FormattedMessage id="PaySportSessionModal_Pay_For_Them" />
                            </Button>
                          )
                        }
                      />
                    </Card>
                  ))}
                </>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        {isProceedingPayment ? (
          <>
            <Button
              className={exectuedTransaction.loading || newIndividualOrder.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || updatedPlayer.loading || newLocation.loading || newWallet.loading || newKYCCase.loading || newPaymentProviders.loading || newTransaction.loading ? 'button-disabled' : 'button-cancel'}
              disabled={exectuedTransaction.loading || newIndividualOrder.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || updatedPlayer.loading || newLocation.loading || newWallet.loading || newKYCCase.loading || newPaymentProviders.loading || newTransaction.loading}
              onClick={() => setIsProceedingPayment(false)}
            >
              <FormattedMessage id="PaySportSessionModal_Back_Button" />
            </Button>
            <LoadingButton
              onClick={handlePaySportSession}
              disabled={inputFields.consumers_to_pay?.length === 0 || (activeStep < steps.length - 1 && loggedAccountPersonalWallets?.length === 0) || inputFields.spending_password?.length < min_code_length}
              className={inputFields.consumers_to_pay?.length === 0 || (activeStep < steps.length - 1 && loggedAccountPersonalWallets?.length === 0) || inputFields.spending_password?.length < min_code_length ? 'button-disabled' : 'button-green'}
              loadingPosition="end"
              endIcon={<Send />}
              loading={exectuedTransaction.loading || newIndividualOrder.loading || newGroupOrder.loading || updatedGroupOrder.loading || progress > 0 || updatedPlayer.loading || newLocation.loading || newWallet.loading || newKYCCase.loading || newPaymentProviders.loading || newTransaction.loading}
            >
              <FormattedMessage id="PaySportSessionModal_Pay_Button" />
              &nbsp;&nbsp;
              <strong>
                {sportSession?.cost?.currency?.currency_code}{' '}
                {(sportSession?.cost?.value ? sportSession?.cost?.value / ALL_TEAM_PLAYERS?.length : 0) * inputFields.consumers_to_pay?.length +
                  (ALL_TEAM_PLAYERS?.filter((p: any) => !sportSession?.group_order?.notes?.includes(p?.player?.player?.id)) ? (sportSession?.group_order?.total_cost?.value || 0) / ALL_TEAM_PLAYERS?.length : 0) +
                  (_.sum(inputFields.selected_group_items?.map((i: any) => i?.item?.price?.value)) / ALL_TEAM_PLAYERS?.length) * inputFields.consumers_to_pay?.length +
                  _.sum(inputFields.selected_personal_items?.map((i: any) => i?.item?.price?.value * i?.amount))}
              </strong>
            </LoadingButton>{' '}
          </>
        ) : sportSession?.consumers?.find((c: any) => c?.player?.player?.player?.id === loggedPlayer?.id) ? (
          ''
        ) : (
          <Button
            onClick={() => setIsProceedingPayment(true)}
            disabled={inputFields.consumers_to_pay?.length === 0 || !inputFields.consumers_to_pay?.find((id: string) => id === ALL_TEAM_PLAYERS?.find((tp: any) => tp?.player?.player?.id === loggedPlayer?.id)?.id)}
            className={inputFields.consumers_to_pay?.length === 0 || !inputFields.consumers_to_pay?.find((id: string) => id === ALL_TEAM_PLAYERS?.find((tp: any) => tp?.player?.player?.id === loggedPlayer?.id)?.id) ? 'button-disabled' : 'button-green'}
          >
            <FormattedMessage id="PaySportSessionModal_Proceed_Pay_Button" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

PaySportSessionModal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  secondaryContent: PropTypes.any,
  actionOneTitle: PropTypes.any,
  actionTwoTitle: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.any,
};

PaySportSessionModal.defaultProps = {
  secondaryContent: '',
  loading: false,
};
