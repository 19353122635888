import React from 'react';
import { Fab, Grid, Typography } from '@mui/material';
import InteractiveAnimation from './InteractiveAnimation';
import { SearchIcon } from '../../img/icons/Icons';
import SearchEngineModal from '../contacts/SearchEngineModal';
import { useIntl } from 'react-intl';

export default function Error(props: { title?: string; content?: any; status: number }) {
  const intl = useIntl();
  const [openSearch, setOpenSearch] = React.useState(false);

  const animation400 = {
    v: '5.4.1',
    fr: 25,
    ip: 0,
    op: 164,
    w: 1000,
    h: 1000,
    nm: 'error 405',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'bocca',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { t: 75, s: [500, 500, 0], h: 1 },
                  { t: 88, s: [400, 400, 0], h: 1 },
                  { t: 100, s: [500, 500, 0], h: 1 },
                  { t: 113, s: [600, 600, 0], h: 1 },
                  { t: 125, s: [400, 600, 0], h: 1 },
                  { t: 138, s: [500, 500, 0], h: 1 },
                  { t: 150, s: [600, 400, 0], h: 1 },
                  { t: 163, s: [500, 500, 0], h: 1 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [500, 500, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0.488, -8.002],
                          [-8.782, 0.163],
                          [-246.318, 2.38],
                          [1.102, 9.81],
                          [5.07, -0.197],
                          [89.246, -5.949],
                        ],
                        o: [
                          [-0.771, 12.626],
                          [192.77, -3.569],
                          [4.668, -0.045],
                          [-1.153, -10.272],
                          [-243.938, 9.52],
                          [-3.916, 0.261],
                        ],
                        v: [
                          [-216.734, -3.056],
                          [-188.176, 24.312],
                          [204.505, 21.932],
                          [216.404, 0.513],
                          [198.555, -18.526],
                          [-203.646, -18.526],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.532999973671, 0.156999999402, 0.016000001571, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [552.412, 641.278],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'ombra bocca',
            parent: 1,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [500, 500, 0], ix: 2 },
              a: { a: 0, k: [500, 500, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0.515, -10.583],
                          [-9.262, 0.216],
                          [-259.791, 3.147],
                          [1.161, 12.975],
                          [5.348, -0.262],
                          [94.127, -7.87],
                        ],
                        o: [
                          [-0.813, 16.701],
                          [203.315, -4.721],
                          [4.924, -0.06],
                          [-1.217, -13.587],
                          [-257.281, 12.591],
                          [-4.131, 0.345],
                        ],
                        v: [
                          [-228.589, -4.042],
                          [-198.469, 32.157],
                          [215.69, 29.01],
                          [228.242, 0.68],
                          [209.416, -24.504],
                          [-214.784, -24.504],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.991999966491, 0.722000002394, 0.090000002992, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [545.229, 642.95],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'occhio dx',
            parent: 1,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [716, 393.5, 0], ix: 2 },
              a: { a: 0, k: [716, 393.5, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { t: 0, s: [100, 100, 100], h: 1 },
                  { t: 38, s: [100, 1, 100], h: 1 },
                  { t: 45, s: [100, 100, 100], h: 1 },
                  { t: 52, s: [100, 1, 100], h: 1 },
                  { t: 59, s: [100, 100, 100], h: 1 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [8.464, 29.071],
                          [-31.998, 2.248],
                          [-1.838, -33.986],
                          [27.097, -7.806],
                        ],
                        o: [
                          [-10.836, -37.218],
                          [29.575, -2.077],
                          [1.292, 23.883],
                          [-29.532, 8.506],
                        ],
                        v: [
                          [-48.775, 15.824],
                          [-1.177, -69.851],
                          [58.32, -2.025],
                          [21.431, 63.423],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.532999973671, 0.156999999402, 0.016000001571, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [710.496, 395.118],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'ombra occhio dx',
            parent: 1,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [706, 398, 0], ix: 2 },
              a: { a: 0, k: [706, 398, 0], ix: 1 },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
                x: "var $bm_rt;\n$bm_rt = thisComp.layer('occhio dx').transform.scale;",
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [30.104, -3.871],
                          [-5.599, -33.091],
                          [-29.174, 1.909],
                          [8.355, 36.547],
                        ],
                        o: [
                          [-33.345, 4.288],
                          [5.017, 29.647],
                          [34.238, -2.24],
                          [-6.476, -28.322],
                        ],
                        v: [
                          [-9.707, -66.846],
                          [-57.305, 11.69],
                          [3.382, 68.807],
                          [54.549, -14.488],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.991999966491, 0.722000002394, 0.090000002992, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [707.126, 396.872],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'occhio sx',
            parent: 1,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [381, 396, 0], ix: 2 },
              a: { a: 0, k: [381, 396, 0], ix: 1 },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
                x: "var $bm_rt;\n$bm_rt = thisComp.layer('occhio dx').transform.scale;",
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [30.617, -1.892],
                          [-1.758, 35.992],
                          [-28.324, 3.722],
                          [-0.704, -36.039],
                        ],
                        o: [
                          [-34.483, 2.13],
                          [1.323, -27.095],
                          [33.717, -4.431],
                          [0.618, 31.598],
                        ],
                        v: [
                          [4.735, 68.977],
                          [-57.142, -4.799],
                          [-8.354, -66.676],
                          [58.282, 1.15],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.532999973671, 0.156999999402, 0.016000001571, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [379.73, 395.513],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'ombra occhio sx',
            parent: 1,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [364, 403, 0], ix: 2 },
              a: { a: 0, k: [364, 403, 0], ix: 1 },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
                x: "var $bm_rt;\n$bm_rt = thisComp.layer('occhio dx').transform.scale;",
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [30.104, -3.871],
                          [-5.599, -33.091],
                          [-29.173, 1.909],
                          [8.355, 36.547],
                        ],
                        o: [
                          [-33.345, 4.288],
                          [5.017, 29.647],
                          [34.239, -2.24],
                          [-6.476, -28.322],
                        ],
                        v: [
                          [-9.707, -66.846],
                          [-57.306, 11.69],
                          [3.382, 68.807],
                          [54.549, -14.488],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.991999966491, 0.722000002394, 0.090000002992, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [366.804, 400.442],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'luce testa',
            parent: 9,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [500, 500, 0], ix: 2 },
              a: { a: 0, k: [500, 500, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [2.182, 5.925],
                          [-41.581, -5.658],
                          [4.749, -6.491],
                          [44.234, 6.154],
                        ],
                        o: [
                          [-2.776, -7.54],
                          [42.017, 5.717],
                          [-3.731, 5.099],
                          [-43.772, -6.09],
                        ],
                        v: [
                          [-72.383, 8.014],
                          [4.963, -27.684],
                          [70.41, 28.243],
                          [0.203, 6.824],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.980000035903, 0.917999985639, 0.313999998803, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [575.842, 155.422],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'ombra faccia',
            parent: 9,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [500, 500, 0], ix: 2 },
              a: { a: 0, k: [500, 500, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [-45.596, 0.732],
                          [23.994, -265.853],
                          [-163.108, -95.823],
                          [112.358, 122.602],
                          [-12.909, 159.687],
                          [-24.039, 12.964],
                        ],
                        o: [
                          [-11.824, 5.677],
                          [-16.67, 184.711],
                          [-35.733, -3.438],
                          [-38.998, -42.554],
                          [17.475, -216.181],
                          [83.854, -45.221],
                        ],
                        v: [
                          [227.783, -425.999],
                          [-47.093, -34.509],
                          [193.275, 425.999],
                          [-98.26, 282.015],
                          [-214.874, -35.699],
                          [18.354, -372.451],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.722000002394, 0.090000002992, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [279.245, 498.999],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'faccia',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { t: 75, s: [500, 500, 0], h: 1 },
                  { t: 88, s: [480, 480, 0], h: 1 },
                  { t: 100, s: [500, 500, 0], h: 1 },
                  { t: 113, s: [520, 520, 0], h: 1 },
                  { t: 125, s: [480, 520, 0], h: 1 },
                  { t: 138, s: [500, 500, 0], h: 1 },
                  { t: 150, s: [520, 480, 0], h: 1 },
                  { t: 163, s: [500, 500, 0], h: 1 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [500, 500, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [-8.369, 190.921],
                          [-231.044, -17.565],
                          [-48.091, -155.434],
                          [90.362, -147.071],
                          [189.529, 73.515],
                        ],
                        o: [
                          [10.331, -235.686],
                          [193.074, 14.678],
                          [9.124, 29.49],
                          [-104.578, 170.211],
                          [-159.624, -61.916],
                        ],
                        v: [
                          [-451.525, -32.14],
                          [17.31, -439.099],
                          [400.471, -114.246],
                          [369.532, 224.886],
                          [-163.56, 383.149],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Tracciato 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.995999983245, 0.834999952129, 0.136999990426, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Riempimento 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: {
                      a: 0,
                      k: [514.707, 513.289],
                      ix: 2,
                    },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Trasformazione',
                  },
                ],
                nm: 'Gruppo 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 750,
            st: 0,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 0,
        nm: 'error 404',
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [500, 500, 0], ix: 2 },
          a: { a: 0, k: [500, 500, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        w: 1000,
        h: 1000,
        ip: 0,
        op: 164,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };
  const animation403 = {
    v: '4.8.0',
    meta: { g: 'LottieFiles AE ', a: '', k: '', d: '', tc: '' },
    fr: 25,
    ip: 0,
    op: 75,
    w: 300,
    h: 300,
    nm: 'Checkmark NO',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 3,
        nm: 'control',
        sr: 1,
        ks: {
          o: { a: 0, k: 0, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [150, 150, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 0, s: [100, 100, 100] },
              { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 23, s: [90, 90, 100] },
              { i: { x: [0.562, 0.562, 0.562], y: [0.541, 0.541, 5.587] }, o: { x: [0.193, 0.193, 0.193], y: [0, 0, 0] }, t: 30, s: [107, 107, 100] },
              { i: { x: [0.843, 0.843, 0.843], y: [1, 1, 1] }, o: { x: [0.357, 0.357, 0.357], y: [-4.208, -4.208, -9.352] }, t: 38, s: [98, 98, 100] },
              { t: 58, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 75,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'circle2',
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { i: { x: [0.755], y: [0.386] }, o: { x: [0.433], y: [0] }, t: 6, s: [0] },
              { i: { x: [0.54], y: [1] }, o: { x: [0.703], y: [0.212] }, t: 17, s: [18.803] },
              { t: 28, s: [175] },
            ],
            ix: 10,
          },
          p: { a: 0, k: [2.364, -1.619, 0], ix: 2 },
          a: { a: 0, k: [1.652, -5.348, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              { d: 1, ty: 'el', s: { a: 0, k: [245.305, 245.305], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
              { ty: 'st', c: { a: 0, k: [0.823529411765, 0.247058823529, 0.21568627451, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 21, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'tr', p: { a: 0, k: [1.652, -5.348], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [79.037, 79.037], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 6.8, s: [0] },
                { t: 26, s: [40] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { t: 17, s: [100] },
              ],
              ix: 2,
            },
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 17, s: [88.542] },
                { t: 28, s: [150] },
              ],
              ix: 3,
            },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 0,
        op: 28,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'X2',
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { i: { x: [0.16], y: [1] }, o: { x: [0.333], y: [0] }, t: 7, s: [-310] },
              { t: 26, s: [-2] },
            ],
            ix: 10,
          },
          p: { a: 0, k: [3, 1, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 7, s: [0, 0, 100] },
              { t: 16, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-35.832, -37.188],
                      [36.772, 42.89],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [40.509, -33.451],
                      [-39.569, 39.153],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 26, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.215686289469, 0.823529471603, 0.427451010311, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Shape 1',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 26,
        op: 75,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'circle filled',
        parent: 1,
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 26, s: [0] },
              { t: 30, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [2.364, -1.619, 0], ix: 2 },
          a: { a: 0, k: [1.652, -5.348, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              { d: 1, ty: 'el', s: { a: 0, k: [245.305, 245.305], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
              { ty: 'st', c: { a: 0, k: [0.823529411765, 0.247058823529, 0.21568627451, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 21, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.823529411765, 0.247058823529, 0.21568627451, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [1.652, -5.348], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [79.037, 79.037], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          { ty: 'fl', c: { a: 0, k: [0.823529411765, 0.247058823529, 0.21568627451, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
        ],
        ip: 26,
        op: 75,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: 'circle',
        parent: 1,
        sr: 1,
        ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [2.364, -1.619, 0], ix: 2 }, a: { a: 0, k: [1.652, -5.348, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              { d: 1, ty: 'el', s: { a: 0, k: [245.305, 245.305], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
              { ty: 'st', c: { a: 0, k: [0.823529411765, 0.247058823529, 0.21568627451, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 21, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'tr', p: { a: 0, k: [1.652, -5.348], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [79.037, 79.037], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 3.875, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 26.042, s: [40] },
                { t: 28, s: [0] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { t: 17, s: [100] },
              ],
              ix: 2,
            },
            o: {
              a: 1,
              k: [
                { i: { x: [0.25], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { t: 28, s: [125] },
              ],
              ix: 3,
            },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 0,
        op: 71,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: 'X1',
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { i: { x: [0.16], y: [1] }, o: { x: [0.333], y: [0] }, t: 7, s: [-310] },
              { t: 26, s: [-2] },
            ],
            ix: 10,
          },
          p: { a: 0, k: [3, 1, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 7, s: [0, 0, 100] },
              { t: 16, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-35.832, -37.188],
                      [36.772, 42.89],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [40.509, -33.451],
                      [-39.569, 39.153],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.823529411765, 0.247058823529, 0.21568627451, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 26, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.823529471603, 0.24708406037, 0.215686289469, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Shape 1',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 43,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };
  const animation404 = {
    v: '5.5.10',
    fr: 24,
    ip: 0,
    op: 120,
    w: 1000,
    h: 1000,
    nm: 'Comp 1',
    ddd: 0,
    assets: [],
    fonts: {
      list: [
        {
          fName: 'FranklinGothic-DemiCond',
          fFamily: 'Franklin Gothic Demi Cond',
          fStyle: 'Regular',
          ascent: 66.69921875,
        },
      ],
    },
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 5,
        nm: 'PAGE NOT FOUND 2',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [503.402, 914, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 70,
                  f: 'FranklinGothic-DemiCond',
                  t: 'PAGE NOT FOUND',
                  j: 2,
                  tr: 0,
                  lh: 84,
                  ls: 0,
                  fc: [0.557, 0.596, 0.639],
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
          a: [],
        },
        ip: 0,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 5,
        nm: 'ERROR 404',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 4,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 9,
                s: [25],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 18,
                s: [25],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 23,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 35,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 40,
                s: [25],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 49,
                s: [25],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 54,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 66,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 71,
                s: [25],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 80,
                s: [25],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 85,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 95,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 100,
                s: [25],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 109,
                s: [25],
              },
              { t: 114, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [503.402, 134, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 70,
                  f: 'FranklinGothic-DemiCond',
                  t: 'ERROR 404',
                  j: 2,
                  tr: 0,
                  lh: 84,
                  ls: 0,
                  fc: [0.557, 0.596, 0.639],
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
          a: [],
        },
        ip: 0,
        op: 120,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'Shape Layer 4',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0], y: [1] },
                o: { x: [0.47], y: [0] },
                t: 0,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 20,
                s: [-30.915],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 75,
                s: [-30.915],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 79,
                s: [8],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 85,
                s: [-6.256],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 91,
                s: [2.696],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 96,
                s: [-1.78],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 101,
                s: [1],
              },
              { t: 105, s: [0] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 75,
                s: [272, 704, 0],
                to: [0, -11.333, 0],
                ti: [0, 0.27, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 79,
                s: [272, 636, 0],
                to: [0, -0.27, 0],
                ti: [0, -7.924, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 85,
                s: [272, 702.38, 0],
                to: [0, 4.285, 0],
                ti: [0, -0.124, 0],
              },
              {
                i: { x: 0.667, y: 0.646 },
                o: { x: 0.333, y: 0 },
                t: 91,
                s: [272, 683.546, 0],
                to: [0, 0.27, 0],
                ti: [0, 6.313, 0],
              },
              {
                i: { x: 0.27, y: 1 },
                o: { x: 0.273, y: 1 },
                t: 96,
                s: [272, 707.416, 0],
                to: [0, -5.361, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.27, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 101,
                s: [272, 700, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 105, s: [272, 704, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-270, 124, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [[0, 0]],
                    o: [[0, 0]],
                    v: [[266, -10]],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.937254961799, 0.941176530427, 0.956862804936, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [74.509, -1.469],
                      [0, 0],
                      [0, 0],
                      [-45.992, 0.628],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-74.509, 1.469],
                      [0, 0],
                      [0, 0],
                      [45.992, -0.628],
                    ],
                    v: [
                      [72.997, -317.829],
                      [95.264, -250.422],
                      [1.509, -236.969],
                      [-95.561, -249.52],
                      [-73.293, -316.932],
                      [0.508, -304.372],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [119.5, 0.018],
                      [0, 0],
                      [0, 0],
                      [-95.499, 1.409],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-118.504, -0.018],
                      [0, 0],
                      [0, 0],
                      [95.499, -1.409],
                    ],
                    v: [
                      [124.203, -162.817],
                      [146.941, -93.981],
                      [1, -73.518],
                      [-146.917, -94.055],
                      [-124.351, -162.368],
                      [1.001, -142.091],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.937254965305, 0.941176533699, 0.956862807274, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-36, 121.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Rectangle 3',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -8.837],
                      [0, 0],
                      [112.152, 3.157],
                      [0, 0],
                      [0, 8.837],
                      [0, 0],
                      [-8.837, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 8.837],
                      [0, 0],
                      [-84.765, 0.204],
                      [0, 0],
                      [0, -8.837],
                      [0, 0],
                      [8.837, 0],
                    ],
                    v: [
                      [48, -393.5],
                      [176.5, -4.5],
                      [-4.327, 14.609],
                      [-5.51, 14.699],
                      [-176.5, -4.5],
                      [-48, -393.5],
                      [-32, -409.5],
                      [32, -409.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [1, 0.674509823322, 0.039215687662, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-36, 121.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Rectangle 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-131.444, 0],
                      [0, -24.439],
                      [131.444, 0],
                      [0, 24.439],
                    ],
                    o: [
                      [131.444, 0],
                      [0, 24.439],
                      [-131.444, 0],
                      [0, -24.439],
                    ],
                    v: [
                      [-3.5, -38.5],
                      [234.5, 5.75],
                      [-3.5, 50],
                      [-241.5, 5.75],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.968627512455, 0.572549045086, 0.105882361531, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-36, 121.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Rectangle 2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 4,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 121,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'Shape Layer 5',
        td: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [500, 517, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, -4.903],
                      [125.312, 0],
                      [0, 22.091],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 22.091],
                      [-125.311, 0],
                      [0, -4.574],
                      [0, 0],
                    ],
                    v: [
                      [213.405, 285.559],
                      [226.897, 0],
                      [0, 40],
                      [-226.897, 0],
                      [-214.087, 290.437],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'gf',
                o: { a: 0, k: 100, ix: 10 },
                r: 1,
                bm: 0,
                g: {
                  p: 3,
                  k: {
                    a: 0,
                    k: [0, 0.584, 0.584, 0.584, 0.5, 0.292, 0.292, 0.292, 1, 0, 0, 0],
                    ix: 9,
                  },
                },
                s: { a: 0, k: [0, -39.5], ix: 5 },
                e: { a: 0, k: [0, 57], ix: 6 },
                t: 1,
                nm: 'Gradient Fill 1',
                mn: 'ADBE Vector Graphic - G-Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 196.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 121,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'Shape Layer 3',
        tt: 2,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0, y: 1 },
                o: { x: 0.47, y: 0 },
                t: 0,
                s: [546.5, 781.5, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0, y: 0 },
                o: { x: 0.333, y: 0.333 },
                t: 20,
                s: [546.5, 651.5, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.14, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 74,
                s: [546.5, 651.5, 0],
                to: [0, 0, 0],
                ti: [0, 21.667, 0],
              },
              { t: 82, s: [546.5, 781.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [56, 141.5, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [15, 15], ix: 2 },
                    p: { a: 0, k: [92, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [15, 15], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 2',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.180392161012, 0.180392161012, 0.235294133425, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-36, 146.5], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          i: {
                            x: [0.667, 0.667],
                            y: [1, 1],
                          },
                          o: {
                            x: [0.333, 0.333],
                            y: [0, 0],
                          },
                          t: 5,
                          s: [100, 52],
                        },
                        {
                          i: {
                            x: [0.667, 0.667],
                            y: [1, 1],
                          },
                          o: {
                            x: [0.167, 0.167],
                            y: [0, 0],
                          },
                          t: 11,
                          s: [100, 100],
                        },
                        {
                          i: {
                            x: [0.833, 0.833],
                            y: [1, 1],
                          },
                          o: {
                            x: [0.167, 0.167],
                            y: [0, 0],
                          },
                          t: 73,
                          s: [100, 100],
                        },
                        { t: 78, s: [100, 52] },
                      ],
                      ix: 3,
                    },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 2',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [51, 51], ix: 2 },
                    p: { a: 0, k: [92, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [51, 51], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 2',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.925490260124, 0.937254965305, 0.933333396912, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-36, 141.5], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          i: {
                            x: [0.667, 0.667],
                            y: [1, 1],
                          },
                          o: {
                            x: [0.333, 0.333],
                            y: [0, 0],
                          },
                          t: 5,
                          s: [100, 21],
                        },
                        {
                          i: {
                            x: [0.667, 0.667],
                            y: [1, 1],
                          },
                          o: {
                            x: [0.167, 0.167],
                            y: [0, 0],
                          },
                          t: 11,
                          s: [100, 100],
                        },
                        {
                          i: {
                            x: [0.833, 0.833],
                            y: [1, 1],
                          },
                          o: {
                            x: [0.167, 0.167],
                            y: [0, 0],
                          },
                          t: 73,
                          s: [100, 100],
                        },
                        { t: 78, s: [100, 21] },
                      ],
                      ix: 3,
                    },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tr',
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.53, y: 0 },
                      t: 29,
                      s: [0, 0],
                      to: [-1.47, 0],
                      ti: [-1.294, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 36,
                      s: [-8.817, 0],
                      to: [1.294, 0],
                      ti: [0.53, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 45,
                      s: [7.762, 0],
                      to: [-0.53, 0],
                      ti: [0.373, 0],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 53,
                      s: [-12, 0],
                      to: [-0.373, 0],
                      ti: [-2, 0],
                    },
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 61,
                      s: [5.522, 0],
                      to: [2, 0],
                      ti: [0.92, 0],
                    },
                    { t: 69, s: [0, 0] },
                  ],
                  ix: 2,
                },
                a: { a: 0, k: [0, -9], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ty: 'rc',
                d: 1,
                s: { a: 0, k: [209, 364], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 219, ix: 4 },
                nm: 'Rectangle Path 1',
                mn: 'ADBE Vector Shape - Rect',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.164705887437, 0.180392161012, 0.223529428244, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [5.5, 176], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Rectangle 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 121,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: 'Shape Layer 2',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [500, 519.5, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [453.793, 80], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.498039215803, 0.494133025408, 0.494133025408, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 5, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 196.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 121,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: 'Shape Layer 1',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [500, 519.5, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [453.793, 80], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 2',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'gf',
                o: { a: 0, k: 100, ix: 10 },
                r: 1,
                bm: 0,
                g: {
                  p: 3,
                  k: {
                    a: 0,
                    k: [0, 0.584, 0.584, 0.584, 0.5, 0.292, 0.292, 0.292, 1, 0, 0, 0],
                    ix: 9,
                  },
                },
                s: { a: 0, k: [0, -39.5], ix: 5 },
                e: { a: 0, k: [0, 57], ix: 6 },
                t: 1,
                nm: 'Gradient Fill 1',
                mn: 'ADBE Vector Graphic - G-Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 196.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 121,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
    chars: [
      {
        ch: 'E',
        size: 70,
        style: 'Regular',
        w: 47.71,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.299, 0],
                        [46.826, 0],
                        [46.826, -11.914],
                        [20.947, -11.914],
                        [20.947, -28.32],
                        [41.064, -28.32],
                        [41.064, -39.746],
                        [20.947, -39.746],
                        [20.947, -55.225],
                        [46.826, -55.225],
                        [46.826, -66.65],
                        [6.299, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'E',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'E',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'R',
        size: 70,
        style: 'Regular',
        w: 54.49,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 8.334],
                        [1.253, 2.849],
                        [2.213, 1.644],
                        [2.164, 0.375],
                        [4.069, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.25, -3.385],
                        [0, -3.548],
                        [-1.254, -2.848],
                        [-2.214, -1.643],
                        [-2.165, -0.374],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.299, 0],
                        [20.947, 0],
                        [20.947, -26.367],
                        [28.266, -26.367],
                        [37.181, 0],
                        [53.125, 0],
                        [40.869, -29.15],
                        [50.244, -46.729],
                        [48.364, -56.323],
                        [43.164, -63.062],
                        [36.597, -66.089],
                        [27.246, -66.65],
                        [6.299, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'R',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.123, -0.504],
                        [-0.847, -1.416],
                        [0, -2.213],
                        [5.729, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [2.18, 0],
                        [1.123, 0.505],
                        [0.846, 1.416],
                        [0, 6.413],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.807, -55.859],
                        [31.763, -55.103],
                        [34.717, -52.222],
                        [35.986, -46.777],
                        [27.393, -37.158],
                        [20.947, -37.158],
                        [20.947, -55.859],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'R',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'R',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'O',
        size: 70,
        style: 'Regular',
        w: 56.2,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.134, 5.73],
                        [7.877, 0],
                        [0, -22.884],
                        [-4.07, -5.843],
                        [-8.008, 0],
                        [0, 22.917],
                      ],
                      o: [
                        [-4.134, -5.729],
                        [-16.113, 0],
                        [0, 11.23],
                        [4.069, 5.843],
                        [16.113, 0],
                        [0, -11.426],
                      ],
                      v: [
                        [46.094, -59.082],
                        [28.076, -67.676],
                        [3.906, -33.35],
                        [10.01, -7.739],
                        [28.125, 1.025],
                        [52.295, -33.35],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'O',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.416, -2.962],
                        [3.027, 0],
                        [1.383, 3.093],
                        [0, 8.789],
                        [-1.4, 2.995],
                        [-3.027, 0],
                        [-1.4, -2.979],
                        [0, -8.984],
                      ],
                      o: [
                        [-1.416, 2.962],
                        [-3.06, 0],
                        [-1.384, -3.092],
                        [0, -8.952],
                        [1.399, -2.995],
                        [3.059, 0],
                        [1.399, 2.979],
                        [0, 9.05],
                      ],
                      v: [
                        [34.741, -15.332],
                        [28.076, -10.889],
                        [21.411, -15.527],
                        [19.336, -33.35],
                        [21.436, -51.27],
                        [28.076, -55.762],
                        [34.766, -51.294],
                        [36.865, -33.35],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'O',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'O',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: ' ',
        size: 70,
        style: 'Regular',
        w: 18.65,
        data: {},
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: '4',
        size: 70,
        style: 'Regular',
        w: 52.54,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.49, -27.051],
                        [2.49, -15.674],
                        [26.465, -15.674],
                        [26.465, 0],
                        [41.113, 0],
                        [41.113, -15.674],
                        [48.584, -15.674],
                        [48.584, -27.051],
                        [41.113, -27.051],
                        [41.113, -67.676],
                        [25.195, -67.676],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: '4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [12.842, -26.27],
                        [27.637, -53.711],
                        [27.637, -26.27],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: '4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: '4',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: '0',
        size: 70,
        style: 'Regular',
        w: 51.12,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.336, 5.908],
                        [8.17, 0],
                        [3.467, -5.68],
                        [0, -12.109],
                        [-3.337, -5.908],
                        [-8.171, 0],
                        [-3.467, 5.697],
                        [0, 12.045],
                      ],
                      o: [
                        [-3.337, -5.908],
                        [-7.91, 0],
                        [-3.467, 5.681],
                        [0, 10.515],
                        [3.336, 5.908],
                        [7.91, 0],
                        [3.467, -5.696],
                        [0, -10.547],
                      ],
                      v: [
                        [42.798, -58.813],
                        [25.537, -67.676],
                        [8.472, -59.155],
                        [3.271, -32.471],
                        [8.276, -7.837],
                        [25.537, 1.025],
                        [42.603, -7.52],
                        [47.803, -34.131],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: '0',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.062, -2.33],
                        [2.417, 0],
                        [1.028, 2.477],
                        [0, 10.851],
                        [-1.062, 2.412],
                        [-2.451, 0],
                        [-0.915, -2.851],
                        [0, -10.101],
                      ],
                      o: [
                        [-1.062, 2.33],
                        [-2.516, 0],
                        [-1.029, -2.477],
                        [0, -10.981],
                        [1.062, -2.411],
                        [2.711, 0],
                        [0.915, 2.852],
                        [0, 11.145],
                      ],
                      v: [
                        [30.78, -13.113],
                        [25.562, -9.619],
                        [20.245, -13.334],
                        [18.701, -33.325],
                        [20.293, -53.414],
                        [25.562, -57.031],
                        [31.001, -52.754],
                        [32.373, -33.325],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: '0',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: '0',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'P',
        size: 70,
        style: 'Regular',
        w: 51.12,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.109, 1.416],
                        [-1.758, 3.191],
                        [0, 4.883],
                        [2.18, 3.435],
                        [3.206, 0.977],
                        [5.827, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.445, 0],
                        [3.108, -1.416],
                        [1.758, -3.19],
                        [0, -5.078],
                        [-2.181, -3.434],
                        [-3.207, -0.977],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.299, 0],
                        [20.947, 0],
                        [20.947, -24.658],
                        [26.123, -24.658],
                        [40.454, -26.782],
                        [47.754, -33.691],
                        [50.391, -45.801],
                        [47.119, -58.569],
                        [39.038, -65.186],
                        [25.488, -66.65],
                        [6.299, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'P',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.189, -0.44],
                        [-0.896, -1.55],
                        [0, -2.513],
                        [1.155, -1.86],
                        [4.004, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [2.148, 0],
                        [1.188, 0.441],
                        [0.895, 1.55],
                        [0, 2.969],
                        [-1.156, 1.86],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [25.488, -55.859],
                        [30.493, -55.199],
                        [33.618, -52.213],
                        [34.961, -46.118],
                        [33.228, -38.874],
                        [25.488, -36.084],
                        [20.947, -36.084],
                        [20.947, -55.859],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'P',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'P',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'A',
        size: 70,
        style: 'Regular',
        w: 51.12,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1.953, 0],
                        [11.507, 0],
                        [14.776, -13.77],
                        [33.549, -13.77],
                        [36.874, 0],
                        [51.855, 0],
                        [32.813, -66.65],
                        [18.066, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'A',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [17.596, -25.195],
                        [24.058, -52.502],
                        [30.794, -25.195],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'A',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'A',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'G',
        size: 70,
        style: 'Regular',
        w: 57.96,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [5.957, 0],
                        [1.92, 3.255],
                        [0, 7.976],
                        [-1.97, 3.223],
                        [-3.288, 0],
                        [-0.912, -8.723],
                        [0, 0],
                        [13.639, 0],
                        [4.378, -6.396],
                        [0, -10.97],
                        [-4.443, -5.973],
                        [-7.585, 0],
                        [-3.581, 5.762],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.033, 8.822],
                        [-3.711, 0],
                        [-1.921, -3.255],
                        [0, -8.984],
                        [1.969, -3.223],
                        [5.403, 0],
                        [0, 0],
                        [-1.66, -15.234],
                        [-8.562, 0],
                        [-4.379, 6.396],
                        [0, 10.091],
                        [4.443, 5.974],
                        [7.161, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [29.688, -24.121],
                        [39.648, -24.121],
                        [30.664, -10.889],
                        [22.217, -15.771],
                        [19.336, -32.617],
                        [22.29, -50.928],
                        [30.176, -55.762],
                        [39.648, -42.676],
                        [52.832, -44.824],
                        [29.883, -67.676],
                        [10.474, -58.081],
                        [3.906, -32.031],
                        [10.571, -7.935],
                        [28.613, 1.025],
                        [44.727, -7.617],
                        [46.631, 0],
                        [52.832, 0],
                        [52.832, -35.547],
                        [29.688, -35.547],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'G',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'G',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'N',
        size: 70,
        style: 'Regular',
        w: 56.2,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.762, 0],
                        [17.09, 0],
                        [17.09, -48.242],
                        [37.402, 0],
                        [50.488, 0],
                        [50.488, -66.65],
                        [39.16, -66.65],
                        [39.16, -25.244],
                        [22.021, -66.65],
                        [5.762, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'N',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'N',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'T',
        size: 70,
        style: 'Regular',
        w: 39.16,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1.172, -54.15],
                        [12.109, -54.15],
                        [12.109, 0],
                        [26.758, 0],
                        [26.758, -54.15],
                        [40.381, -54.15],
                        [40.381, -66.65],
                        [-1.172, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'T',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'T',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'F',
        size: 70,
        style: 'Regular',
        w: 44.29,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.299, 0],
                        [20.947, 0],
                        [20.947, -26.367],
                        [40.137, -26.367],
                        [40.137, -37.793],
                        [20.947, -37.793],
                        [20.947, -55.225],
                        [45.85, -55.225],
                        [45.85, -66.65],
                        [6.299, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'F',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'F',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'U',
        size: 70,
        style: 'Regular',
        w: 57.91,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.4, -3.499],
                        [-3.32, -1.936],
                        [-5.795, 0],
                        [-3.532, 2.637],
                        [-0.863, 3.679],
                        [0, 7.487],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.325, -1.985],
                        [1.432, -1.204],
                        [2.441, 0],
                        [1.546, 1.091],
                        [0.488, 2.116],
                        [0, 5.371],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 8.855],
                        [1.399, 3.5],
                        [3.32, 1.936],
                        [6.184, 0],
                        [3.532, -2.637],
                        [0.862, -3.678],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 5.404],
                        [-0.326, 1.986],
                        [-1.433, 1.205],
                        [-2.246, 0],
                        [-1.546, -1.09],
                        [-0.488, -2.116],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.299, -28.564],
                        [8.398, -10.034],
                        [15.479, -1.88],
                        [29.15, 1.025],
                        [43.726, -2.93],
                        [50.317, -12.402],
                        [51.611, -29.15],
                        [51.611, -66.65],
                        [39.355, -66.65],
                        [39.355, -28.564],
                        [38.867, -17.48],
                        [36.23, -12.695],
                        [30.42, -10.889],
                        [24.731, -12.524],
                        [21.68, -17.334],
                        [20.947, -28.564],
                        [20.947, -66.65],
                        [6.299, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'U',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'U',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
      {
        ch: 'D',
        size: 70,
        style: 'Regular',
        w: 56.2,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-3.191, 0.912],
                        [-2.539, 2.67],
                        [-1.563, 4.639],
                        [0, 5.99],
                        [2.571, 4.948],
                        [4.28, 1.839],
                        [6.836, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [4.785, 0],
                        [3.19, -0.911],
                        [2.539, -2.669],
                        [1.563, -4.639],
                        [0, -8.43],
                        [-2.572, -4.948],
                        [-4.281, -1.839],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.299, 0],
                        [23.828, 0],
                        [35.791, -1.367],
                        [44.385, -6.738],
                        [50.537, -17.7],
                        [52.881, -33.643],
                        [49.023, -53.711],
                        [38.745, -63.892],
                        [22.07, -66.65],
                        [6.299, -66.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'D',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.845, -1.092],
                        [-1.225, -3.063],
                        [0, -5.67],
                        [1.11, -3.242],
                        [1.861, -1.303],
                        [3.395, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [3.199, 0],
                        [1.845, 1.092],
                        [1.224, 3.063],
                        [0, 6.126],
                        [-1.11, 3.242],
                        [-1.862, 1.304],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [23.445, -55.225],
                        [31.011, -53.587],
                        [35.615, -47.354],
                        [37.451, -34.254],
                        [35.786, -20.2],
                        [31.329, -13.381],
                        [23.445, -11.426],
                        [20.947, -11.426],
                        [20.947, -55.225],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'D',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'D',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Franklin Gothic Demi Cond',
      },
    ],
  };
  const animation504 = {
    v: '5.5.10',
    fr: 30,
    ip: 0,
    op: 60,
    w: 512,
    h: 512,
    nm: 'Try Again',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'Layer 3 Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [256, 314.669, 0], ix: 2 },
          a: { a: 0, k: [256, 314.669, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 30, s: [0, 0, 100] },
              { t: 38.5, s: [100, 100, 100] },
            ],
            ix: 6,
            x: "var $bm_rt;\nvar n, n, t, t, v, amp, freq, decay;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    amp = $bm_div(effect('Amplitude')('Slider'), 100);\n    freq = effect('Frequency')('Slider');\n    decay = effect('Decay')('Slider');\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}",
          },
        },
        ao: 0,
        ef: [
          { ty: 5, nm: 'Amplitude', np: 3, mn: 'ADBE Slider Control', ix: 1, en: 1, ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 3, ix: 1 } }] },
          { ty: 5, nm: 'Frequency', np: 3, mn: 'ADBE Slider Control', ix: 2, en: 1, ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 2.5, ix: 1 } }] },
          { ty: 5, nm: 'Decay', np: 3, mn: 'ADBE Slider Control', ix: 3, en: 1, ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 5, ix: 1 } }] },
        ],
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [4.753, 0.004],
                      [-0.122, 4.039],
                      [-4.559, -0.014],
                      [0.031, -3.637],
                    ],
                    o: [
                      [-4.836, -0.004],
                      [0.112, -3.729],
                      [4.237, 0.014],
                      [-0.034, 3.987],
                    ],
                    v: [
                      [-0.044, 7.47],
                      [-8.87, -0.097],
                      [0.391, -7.46],
                      [8.96, -0.024],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [455.324, 339.988], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-6.436, -0.475],
                      [2.258, -12.861],
                      [3.659, 0.266],
                    ],
                    o: [
                      [6.027, 0.444],
                      [-2.227, 12.672],
                      [-4.012, -0.293],
                      [0, 0],
                    ],
                    v: [
                      [-9.022, -19.59],
                      [9.021, -18.26],
                      [2.372, 19.59],
                      [-9.022, 18.758],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [459.752, 306.977], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-11.028, 0.81],
                      [-0.834, -4.509],
                      [6.31, -0.519],
                      [-0.636, -14.316],
                      [6.15, -1.14],
                      [0, 0],
                      [4.162, -0.329],
                      [0.004, 0.286],
                      [-11.661, 0.875],
                    ],
                    o: [
                      [0.912, 4.926],
                      [-5.497, 0.452],
                      [0.605, 13.641],
                      [-5.356, 0.993],
                      [0, 0],
                      [-4.852, 0.385],
                      [-0.375, -0.809],
                      [-0.181, -12.017],
                      [10.592, -0.795],
                    ],
                    v: [
                      [20.692, -29.766],
                      [23.185, -16.298],
                      [6.285, -14.906],
                      [8.127, 26.59],
                      [-9, 29.766],
                      [-9, -14.174],
                      [-22.383, -13.116],
                      [-23.004, -14.492],
                      [-11.523, -27.397],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [66.269, 311.646], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 3',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -3.706],
                      [-1.663, 1.623],
                      [0.496, 1.97],
                      [3.076, -0.87],
                      [0.269, -1.429],
                    ],
                    o: [
                      [2.651, -1.343],
                      [2.159, -2.108],
                      [-1.003, -3.985],
                      [-1.352, 0.381],
                      [-0.508, 2.711],
                    ],
                    v: [
                      [-7.68, -4.21],
                      [-0.788, -7.857],
                      [4.157, -15.255],
                      [-4.336, -17.194],
                      [-7.534, -13.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.472, -3.02],
                      [-5.454, -6.342],
                      [4.797, -3.665],
                      [6.299, 8.301],
                      [0, 0],
                      [5.433, -1.146],
                      [-0.11, 7.114],
                      [-0.166, 10.313],
                      [-1.001, 0.116],
                      [-8.922, 0.369],
                      [-2.147, -5.408],
                      [3.712, -4.241],
                    ],
                    o: [
                      [4.958, 5.764],
                      [-4.381, 3.346],
                      [-5.724, -7.544],
                      [0, 0],
                      [-4.761, 1.004],
                      [0.411, -7.745],
                      [0.159, -10.314],
                      [0.021, -1.283],
                      [8.875, -1.025],
                      [5.442, -0.226],
                      [2.138, 5.389],
                      [-2.48, 2.834],
                    ],
                    v: [
                      [7.633, 0.966],
                      [23.012, 18.852],
                      [9.557, 29.133],
                      [-8.081, 5.886],
                      [-8.081, 26.409],
                      [-23.012, 29.556],
                      [-21.9, 7.591],
                      [-21.855, -23.356],
                      [-19.348, -27.01],
                      [7.355, -29.331],
                      [19.891, -21.973],
                      [16.766, -7.106],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [115.047, 311.635], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 4',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 4,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-0.871, 17.784],
                      [5.908, -0.764],
                      [0, 0],
                      [0.739, -0.096],
                      [3.449, 11.455],
                      [6.067, -0.814],
                      [0, 0],
                      [-4.981, 0.987],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0.888, -18.12],
                      [-5.382, 0.696],
                      [0, 0],
                      [-0.738, 0.098],
                      [-3.279, -10.893],
                      [-5.175, 0.695],
                      [0, 0],
                      [4.798, -0.952],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.123, 23.997],
                      [22.745, -29.516],
                      [6.236, -27.382],
                      [6.236, 3.021],
                      [4.02, 3.313],
                      [-5.987, -29.929],
                      [-22.745, -27.68],
                      [-22.745, 29.929],
                      [-8.204, 27.046],
                      [-8.204, 7.641],
                      [3.349, 25.639],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [418.762, 316.126], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 5',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 5,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-5.243, 0.656],
                      [0.802, -18.23],
                      [5.034, -0.983],
                    ],
                    o: [
                      [5.936, -0.743],
                      [-0.782, 17.766],
                      [-4.213, 0.823],
                      [0, 0],
                    ],
                    v: [
                      [-8.13, -26.132],
                      [8.13, -28.167],
                      [5.77, 25.453],
                      [-8.13, 28.167],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [380.834, 315.719], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 6',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 6,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.466, -18.783],
                      [5.647, 0.48],
                      [-1.618, 4.746],
                      [0.821, 1.792],
                      [5.078, 10.452],
                      [-5.912, 2.107],
                      [-1.72, -6.847],
                      [0, 0],
                      [-2.174, 5.637],
                      [-2.879, -0.605],
                      [-4.358, -0.541],
                    ],
                    o: [
                      [-4.049, -0.345],
                      [2.125, -4.993],
                      [0.648, -1.902],
                      [-4.614, -10.065],
                      [6.131, -2.184],
                      [1.839, 7.32],
                      [0, 0],
                      [2.278, -5.597],
                      [0.89, -2.308],
                      [4.166, 0.874],
                      [-8.402, 18.641],
                    ],
                    v: [
                      [-0.874, 29.301],
                      [-15.344, 28.071],
                      [-9.396, 13.788],
                      [-9.558, 7.449],
                      [-24.206, -22.977],
                      [-6.452, -29.301],
                      [-1.173, -8.289],
                      [-0.14, -8.303],
                      [6.659, -25.108],
                      [11.388, -28.022],
                      [24.206, -26.34],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [163.107, 312.134], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 7',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 7,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.493, 7.721],
                      [0.247, 0.019],
                      [1.507, -8.076],
                      [0, 0],
                    ],
                    o: [
                      [-0.247, -0.018],
                      [-1.441, 7.725],
                      [0, 0],
                      [-1.552, -8.029],
                    ],
                    v: [
                      [0.233, -14.082],
                      [-0.508, -14.136],
                      [-4.897, 9.389],
                      [4.77, 9.389],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-4.084, 0.381],
                      [-4.69, -18.437],
                      [5.206, -1.013],
                      [15.003, -2.118],
                      [0.857, -3.483],
                      [5.399, 1.143],
                      [-3.553, 12.559],
                      [-0.467, 2.106],
                      [-8.856, 0.845],
                    ],
                    o: [
                      [4.712, 18.523],
                      [-5.001, 0.974],
                      [-3.946, -11.582],
                      [-0.703, 2.861],
                      [-5.735, -1.214],
                      [3.72, -13.065],
                      [0.587, -2.074],
                      [1.963, -8.853],
                      [4.425, -0.423],
                    ],
                    v: [
                      [11.087, -29.441],
                      [25.059, 25.484],
                      [9.571, 28.498],
                      [-6.201, 20.094],
                      [-8.5, 29.441],
                      [-25.058, 25.935],
                      [-14.201, -12.241],
                      [-12.66, -18.527],
                      [-1.849, -28.231],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [233.78, 313.672], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 8',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 8,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.463, 7.392],
                      [0.259, 0.019],
                      [1.454, -7.772],
                      [0, 0],
                    ],
                    o: [
                      [-0.26, -0.019],
                      [-1.402, 7.491],
                      [0, 0],
                      [-1.566, -7.915],
                    ],
                    v: [
                      [0.099, -13.798],
                      [-0.681, -13.855],
                      [-4.937, 8.899],
                      [4.591, 8.899],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-5.168, 0.539],
                      [-4.715, -18.566],
                      [4.573, -0.874],
                      [6.94, 0.384],
                      [-0.232, -8.456],
                      [5.08, 1.07],
                      [-3.704, 15.791],
                      [-4.484, 0.227],
                    ],
                    o: [
                      [4.694, 18.484],
                      [-4.999, 0.956],
                      [-3.955, -2.587],
                      [-7.623, -0.421],
                      [-6.133, -1.293],
                      [4.315, -16.024],
                      [1.172, -4.995],
                      [5.121, -0.258],
                    ],
                    v: [
                      [10.948, -29.52],
                      [24.923, 25.507],
                      [10.078, 28.345],
                      [3.087, 18.877],
                      [-8.273, 29.521],
                      [-24.922, 26.012],
                      [-12.359, -21.399],
                      [-4.437, -27.979],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [342.92, 315.31], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 9',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 9,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-4.441, -5.359],
                      [2.73, -2.518],
                      [5.699, -5.019],
                      [-2.501, -6.968],
                      [-4.57, -0.443],
                      [-3.605, 4.695],
                      [-0.91, 1.971],
                      [5.743, -0.82],
                      [-0.241, 3.367],
                      [-9.971, 1.321],
                      [8.273, -7.117],
                      [8.792, 10.465],
                      [-2.69, 9.66],
                      [-13.698, -1.616],
                    ],
                    o: [
                      [-3.136, 2.893],
                      [-8.362, -4.234],
                      [-6.47, 5.697],
                      [1.686, 4.694],
                      [5.886, 0.57],
                      [0.87, -1.133],
                      [-5.48, 0.782],
                      [0.242, -3.398],
                      [9.877, -1.308],
                      [1.381, 11.96],
                      [-11.281, 9.705],
                      [-6.88, -8.189],
                      [4.205, -15.101],
                      [6.154, 0.725],
                    ],
                    v: [
                      [21.756, -21.925],
                      [13.111, -13.949],
                      [-5.728, -12.862],
                      [-12.673, 9.718],
                      [-2.829, 16.945],
                      [11.538, 10.561],
                      [13.714, 5.959],
                      [-2.391, 8.257],
                      [-1.686, -1.645],
                      [27.51, -5.513],
                      [15.828, 22.414],
                      [-21.992, 20.823],
                      [-26.201, -6.553],
                      [5.867, -30.503],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [0.054999998504, 0, 0.451000019148, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 10, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [288.577, 315.228], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 10',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 10,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 450,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'Layer 2 Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 15, s: [133] },
              { t: 23.5, s: [0] },
            ],
            ix: 10,
            x: "var $bm_rt;\nvar n, n, t, t, v, amp, freq, decay;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    amp = $bm_div(effect('Amplitude')('Slider'), 100);\n    freq = effect('Frequency')('Slider');\n    decay = effect('Decay')('Slider');\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}",
          },
          p: { a: 0, k: [256.839, 378.218, 0], ix: 2 },
          a: { a: 0, k: [256.839, 378.218, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 15, s: [0, 0, 100] },
              { t: 23.5, s: [100, 100, 100] },
            ],
            ix: 6,
            x: "var $bm_rt;\nvar n, n, t, t, v, amp, freq, decay;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    amp = $bm_div(effect('Amplitude')('Slider'), 100);\n    freq = effect('Frequency')('Slider');\n    decay = effect('Decay')('Slider');\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}",
          },
        },
        ao: 0,
        ef: [
          { ty: 5, nm: 'Amplitude', np: 3, mn: 'ADBE Slider Control', ix: 1, en: 1, ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 3, ix: 1 } }] },
          { ty: 5, nm: 'Frequency', np: 3, mn: 'ADBE Slider Control', ix: 2, en: 1, ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 2.5, ix: 1 } }] },
          { ty: 5, nm: 'Decay', np: 3, mn: 'ADBE Slider Control', ix: 3, en: 1, ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 5, ix: 1 } }] },
        ],
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.223, 1.562],
                      [-1.561, 0],
                      [-0.446, -0.446],
                      [0, 0],
                      [0.224, -1.784],
                      [1.562, 0],
                      [1.339, 0.224],
                      [0, 1.562],
                    ],
                    o: [
                      [2.9, -0.446],
                      [1.786, 0],
                      [0, 0],
                      [0, 2.231],
                      [-2.008, 0.224],
                      [-1.562, 0],
                      [0.223, -1.561],
                      [0, -1.562],
                    ],
                    v: [
                      [-4.853, 9.874],
                      [1.84, 9.204],
                      [5.188, 9.874],
                      [5.188, 13.221],
                      [4.853, 19.245],
                      [-0.502, 19.58],
                      [-4.853, 19.245],
                      [-4.519, 14.56],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.339, 16.067],
                      [-1.561, 0],
                      [-1.339, -0.446],
                      [0.224, -4.463],
                      [0.223, -4.463],
                      [1.338, 0],
                      [0.892, -0.446],
                    ],
                    o: [
                      [2.231, -0.446],
                      [1.786, 0],
                      [0, 2.902],
                      [-0.446, 5.578],
                      [-1.339, -0.446],
                      [-1.116, 0],
                      [0, -1.339],
                    ],
                    v: [
                      [-5.188, -18.911],
                      [0.501, -19.58],
                      [5.188, -18.911],
                      [4.853, -7.865],
                      [3.849, 7.196],
                      [-0.167, 6.526],
                      [-3.18, 7.196],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.102000000898, 0.102000000898, 0.102000000898, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [330.372, 254.271], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 1',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.115, 4.648],
                      [-5.615, 0.056],
                      [-0.892, -0.521],
                      [-1.004, 0],
                      [-0.763, 0.223],
                      [-0.558, 0.855],
                      [0, 0.745],
                      [0.149, 0.52],
                      [2.212, 0.205],
                      [0.576, 0],
                      [0.688, -0.037],
                      [0.464, 0],
                      [0, 0],
                      [2.511, 2.176],
                      [0, 2.566],
                      [-0.094, 0.521],
                      [-3.124, 2.176],
                      [-3.906, 0],
                      [0, 0],
                      [-3.347, -1.859],
                      [-1.451, -3.273],
                      [1.859, 0],
                      [0.168, 0.038],
                      [1.154, 0.409],
                      [0.93, 0],
                      [0.408, -0.056],
                      [0.399, -0.539],
                      [0, -0.706],
                      [-0.131, -0.372],
                      [-1.507, -0.502],
                      [-1.655, -0.148],
                      [-0.595, -0.074],
                      [-1.636, -1.227],
                      [0, -3.569],
                      [3.328, -2.511],
                      [4.574, -0.241],
                      [0.465, 0],
                      [3.254, 1.654],
                    ],
                    o: [
                      [2.882, -0.539],
                      [0.744, 1.804],
                      [1.097, 0.335],
                      [0.818, 0],
                      [0.724, -0.204],
                      [0.595, -0.91],
                      [0, -0.463],
                      [-0.446, -1.507],
                      [-0.428, -0.037],
                      [-0.557, 0],
                      [-1.135, 0.037],
                      [0, 0],
                      [-3.311, -0.112],
                      [-2.287, -1.989],
                      [0, -0.483],
                      [0.651, -4.035],
                      [2.752, -1.914],
                      [0, 0],
                      [3.868, 0.037],
                      [3.533, 1.971],
                      [-5.913, 1.004],
                      [-0.354, 0],
                      [-0.502, -0.873],
                      [-0.874, -0.279],
                      [-0.39, 0],
                      [-0.781, 0.279],
                      [-0.401, 0.539],
                      [0, 0.335],
                      [0.539, 1.543],
                      [0.892, 0.168],
                      [2.175, 0.186],
                      [3.384, 0.409],
                      [2.213, 1.655],
                      [0, 4.11],
                      [-3.013, 2.287],
                      [-0.484, 0.019],
                      [-3.96, 0],
                      [-3.979, -2.213],
                    ],
                    v: [
                      [-18.13, 8.242],
                      [-5.384, 7.349],
                      [-2.929, 10.836],
                      [0.223, 11.338],
                      [2.594, 11.003],
                      [4.518, 9.413],
                      [5.411, 6.93],
                      [5.187, 5.453],
                      [1.199, 2.887],
                      [-0.308, 2.831],
                      [-2.176, 2.887],
                      [-4.574, 2.943],
                      [-4.909, 2.943],
                      [-13.64, -0.488],
                      [-17.07, -7.322],
                      [-16.931, -8.828],
                      [-11.269, -18.144],
                      [-1.283, -21.016],
                      [-1.061, -21.016],
                      [9.762, -18.172],
                      [17.237, -10.306],
                      [5.578, -8.8],
                      [4.797, -8.856],
                      [2.314, -10.78],
                      [-0.391, -11.199],
                      [-1.59, -11.115],
                      [-3.361, -9.887],
                      [-3.961, -8.019],
                      [-3.766, -6.959],
                      [-0.697, -3.891],
                      [3.124, -3.417],
                      [7.279, -3.026],
                      [14.81, -0.572],
                      [18.13, 7.265],
                      [13.137, 17.195],
                      [1.757, 20.988],
                      [0.334, 21.016],
                      [-10.488, 18.534],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'fl', c: { a: 0, k: [0.102000000898, 0.102000000898, 0.102000000898, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [305.059, 253.114], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 2',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -3.57],
                      [0, 0],
                      [0, 2.901],
                      [3.57, 0],
                      [1.115, -0.223],
                    ],
                    o: [
                      [0, 0],
                      [4.239, 0],
                      [-0.223, -2.677],
                      [-1.115, 0],
                      [0.669, 1.785],
                    ],
                    v: [
                      [-3.515, -1.144],
                      [-1.841, -1.144],
                      [4.518, -5.495],
                      [-1.172, -9.511],
                      [-4.519, -9.177],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.008, -0.298],
                      [0, 4.016],
                      [1.116, 9.148],
                      [0, 0],
                      [-2.678, -5.132],
                      [0, -1.785],
                      [2.678, -2.009],
                      [6.47, 0],
                      [0, 0],
                      [0, 0],
                      [0.446, -4.426],
                      [1.154, 0],
                      [0.743, -0.074],
                    ],
                    o: [
                      [0.223, -4.202],
                      [0, -9.148],
                      [0, 0],
                      [5.132, 0],
                      [1.115, 2.232],
                      [0, 2.678],
                      [-3.57, 2.677],
                      [0, 0],
                      [0, 0],
                      [0, 3.347],
                      [-1.302, -0.149],
                      [-0.818, 0],
                      [-2.231, 0],
                    ],
                    v: [
                      [-16.233, 19.887],
                      [-15.898, 7.558],
                      [-17.572, -19.887],
                      [4.183, -19.887],
                      [15.898, -12.189],
                      [17.572, -6.165],
                      [13.555, 0.865],
                      [-1.506, 4.881],
                      [-3.18, 4.881],
                      [-3.18, 7.893],
                      [-3.85, 19.552],
                      [-7.532, 19.328],
                      [-9.874, 19.44],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.102000000898, 0.102000000898, 0.102000000898, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [268.198, 253.686], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 3',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.569, 0],
                      [-0.222, 3.588],
                      [0, 0],
                      [4.017, 0],
                      [0, -4.854],
                      [-0.223, -1.284],
                    ],
                    o: [
                      [3.348, -0.261],
                      [0, 0],
                      [0, -5.373],
                      [-4.017, 0.261],
                      [0, 1.022],
                      [0.67, 3.588],
                    ],
                    v: [
                      [0.335, 8.535],
                      [5.689, 2.762],
                      [5.689, 0.083],
                      [-0.335, -7.977],
                      [-6.359, -0.306],
                      [-6.025, 3.152],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.116, 13.389],
                      [0, 0],
                      [-3.123, 3.348],
                      [-4.463, 0],
                      [0, 0],
                      [-3.348, -3.347],
                      [0, -5.801],
                      [0, 0],
                      [10.487, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, -5.802],
                      [2.902, -3.347],
                      [0, 0],
                      [4.686, 0.224],
                      [3.569, 3.57],
                      [0, 0],
                      [-0.222, 13.835],
                      [0, 0],
                      [-10.71, -0.223],
                    ],
                    v: [
                      [-17.07, 0.167],
                      [-17.07, -1.84],
                      [-12.385, -15.564],
                      [-1.339, -20.585],
                      [-0.335, -20.585],
                      [11.715, -15.229],
                      [17.07, -1.172],
                      [17.07, -0.168],
                      [1.005, 20.585],
                      [0.669, 20.585],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.102000000898, 0.102000000898, 0.102000000898, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [231.28, 253.266], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 4',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 4,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.569, 0],
                      [-0.222, 3.588],
                      [0, 0],
                      [4.017, 0],
                      [0, -4.854],
                      [-0.223, -1.284],
                    ],
                    o: [
                      [3.348, -0.261],
                      [0, 0],
                      [0, -5.373],
                      [-4.017, 0.261],
                      [0, 1.022],
                      [0.67, 3.588],
                    ],
                    v: [
                      [0.335, 8.535],
                      [5.689, 2.762],
                      [5.689, 0.083],
                      [-0.335, -7.977],
                      [-6.359, -0.306],
                      [-6.025, 3.152],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.116, 13.389],
                      [0, 0],
                      [-3.123, 3.348],
                      [-4.463, 0],
                      [0, 0],
                      [-3.348, -3.347],
                      [0, -5.801],
                      [0, 0],
                      [10.487, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, -5.802],
                      [2.902, -3.347],
                      [0, 0],
                      [4.686, 0.224],
                      [3.569, 3.57],
                      [0, 0],
                      [-0.222, 13.835],
                      [0, 0],
                      [-10.71, -0.223],
                    ],
                    v: [
                      [-17.07, 0.167],
                      [-17.07, -1.84],
                      [-12.385, -15.564],
                      [-1.339, -20.585],
                      [-0.335, -20.585],
                      [11.715, -15.229],
                      [17.07, -1.172],
                      [17.07, -0.168],
                      [1.005, 20.585],
                      [0.669, 20.585],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.102000000898, 0.102000000898, 0.102000000898, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [194.865, 253.266], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 5',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 5,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.032, 0],
                      [1.06, -1.025],
                      [0, 0],
                      [-2.162, -2.236],
                      [0, 0],
                      [-2.234, 2.162],
                      [0, 0],
                      [2.162, 2.235],
                      [0, 0],
                      [1.507, 0.025],
                    ],
                    o: [
                      [-1.471, 0],
                      [0, 0],
                      [-2.236, 2.162],
                      [0, 0],
                      [2.162, 2.236],
                      [0, 0],
                      [2.236, -2.162],
                      [0, 0],
                      [-1.047, -1.083],
                      [-0.033, -0.001],
                    ],
                    v: [
                      [2.336, -107.939],
                      [-1.582, -106.352],
                      [-105.635, -5.741],
                      [-105.769, 2.234],
                      [-5.157, 106.289],
                      [2.818, 106.42],
                      [106.871, 5.81],
                      [107.005, -2.165],
                      [6.393, -106.219],
                      [2.433, -107.938],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.294, 0],
                      [0.073, 0.002],
                      [2.348, 2.428],
                      [0, 0],
                      [-5.01, 4.845],
                      [0, 0],
                      [-3.416, -0.07],
                      [-2.347, -2.427],
                      [0, 0],
                      [0.057, -3.376],
                      [2.428, -2.346],
                      [0, 0],
                    ],
                    o: [
                      [-0.072, 0],
                      [-3.376, -0.056],
                      [0, 0],
                      [-4.845, -5.011],
                      [0, 0],
                      [2.426, -2.347],
                      [3.376, 0.057],
                      [0, 0],
                      [2.348, 2.427],
                      [-0.057, 3.375],
                      [0, 0],
                      [-2.375, 2.295],
                    ],
                    v: [
                      [-1.097, 115.007],
                      [-1.315, 115.004],
                      [-10.19, 111.154],
                      [-110.801, 7.1],
                      [-110.501, -10.773],
                      [-6.448, -111.384],
                      [2.551, -114.936],
                      [11.426, -111.085],
                      [112.037, -7.031],
                      [115.589, 1.967],
                      [111.736, 10.842],
                      [7.684, 111.454],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.102000000898, 0.102000000898, 0.102000000898, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [256.221, 255.966], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 6',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 6,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.315, -8.041],
                      [0, 0],
                      [-8.04, -8.316],
                      [0, 0],
                      [-8.315, 8.041],
                      [0, 0],
                      [8.041, 8.316],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-8.315, 8.04],
                      [0, 0],
                      [8.041, 8.315],
                      [0, 0],
                      [8.316, -8.04],
                      [0, 0],
                      [-8.04, -8.315],
                    ],
                    v: [
                      [-12.856, -116.331],
                      [-115.834, -16.759],
                      [-116.332, 12.856],
                      [-16.76, 115.833],
                      [12.855, 116.331],
                      [115.832, 16.759],
                      [116.331, -12.856],
                      [16.758, -115.833],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'fl', c: { a: 0, k: [0.905999995213, 0.776000019148, 0.226999993418, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [256.839, 256], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Group 7',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 7,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 450,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };

  return (
    <>
      <Grid
        container
        sx={{
          p: '30px 10%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.status === 400 ? (
          <Grid sx={{ textAlign: 'center' }}>
            <InteractiveAnimation
              options={{
                loop: true,
                autoplay: true,
                animationData: animation400,
              }}
              height={70}
              width={70}
            />
            <Typography
              sx={{
                fontWeight: '700',
                display: 'block',
                textAlign: 'center',
              }}
            >
              {`${intl.formatMessage({ id: 'Global_Page_Not_Found_1' })}`}
            </Typography>
            <Typography sx={{ display: 'block', textAlign: 'center' }}>{props?.content?.message ? `${props?.content?.message}` : `${intl.formatMessage({ id: 'Global_Page_Not_Found_2' })}`}</Typography>
            <Fab
              variant="extended"
              size="small"
              className="button-green-reverse"
              sx={{
                background: 'transparent',
                boxShadow: 'none',
                whiteSpace: 'nowrap',
                m: '30px 5px 0',
              }}
              href={`${window.location.href}`}
            >
              {`${intl.formatMessage({ id: 'Global_Page_Not_Found_3' })}`}
            </Fab>
          </Grid>
        ) : (
          ''
        )}

        {props.status === 403 ? (
          <Grid sx={{ textAlign: 'center' }}>
            <InteractiveAnimation
              options={{
                loop: true,
                autoplay: true,
                animationData: animation403,
              }}
              height={70}
              width={70}
            />
            <Typography
              sx={{
                fontWeight: '700',
                display: 'block',
                textAlign: 'center',
                textTransform: 'uppercase',
              }}
            >
              {`${intl.formatMessage({ id: 'Global_Page_Not_Found_4' })}`}
            </Typography>
            <Typography sx={{ display: 'block', textAlign: 'center' }}>{`${intl.formatMessage({ id: 'Global_Page_Not_Found_5' })}`}</Typography>
            <Fab
              variant="extended"
              size="small"
              className="button-green-reverse"
              sx={{
                background: 'transparent',
                boxShadow: 'none',
                whiteSpace: 'nowrap',
                m: '30px 5px 0',
              }}
              href={`/`}
            >
              {`${intl.formatMessage({ id: 'Global_Page_Not_Found_6' })}`}
            </Fab>
          </Grid>
        ) : (
          ''
        )}

        {props.status === 404 ? (
          <Grid sx={{ textAlign: 'center' }}>
            <InteractiveAnimation
              options={{
                loop: true,
                autoplay: true,
                animationData: animation404,
              }}
              height={150}
              width={150}
            />
            <Typography
              variant="h2"
              sx={{
                fontSize: '2em',
                fontWeight: '700',
                textAlign: 'center',
              }}
            >
              {props.title}{' '}
              <Grid component="span" sx={{ fontWeight: '500', textTransform: 'uppercase' }}>
                {`${intl.formatMessage({ id: 'Global_Page_Not_Found_7' })}`}
              </Grid>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '1.5em',
                textAlign: 'left',
                display: 'inline-block',
                p: '5% 0',
              }}
            >
              {props.content}, {`${intl.formatMessage({ id: 'Global_Page_Not_Found_8' })}`}{' '}
              <Fab
                variant="extended"
                onClick={() => setOpenSearch(true)}
                sx={{
                  'p': '0',
                  'fontWeight': '700',
                  'fontSize': '.8em',
                  'boxShadow': 'none',
                  'bgcolor': 'transparent',
                  'color': 'inherit',
                  ':hover': {
                    bgcolor: 'transparent',
                    color: 'rgba(251, 88, 2, 1)',
                  },
                }}
              >
                <SearchIcon sx={{ color: 'rgba(251, 88, 2, 1)' }} />
                &nbsp;{`${intl.formatMessage({ id: 'Global_Page_Not_Found_9' })}`}
              </Fab>{' '}
              {`${intl.formatMessage({ id: 'Global_Page_Not_Found_10' })}`}
            </Typography>

            <SearchEngineModal open={openSearch} handleClose={() => setOpenSearch(false)} />
          </Grid>
        ) : (
          ''
        )}

        {props.status === 504 ? (
          <Grid sx={{ textAlign: 'center' }}>
            <InteractiveAnimation
              options={{
                loop: true,
                autoplay: true,
                animationData: animation504,
              }}
              height={150}
              width={150}
            />
            <Typography
              variant="h2"
              sx={{
                fontSize: '2em',
                fontWeight: '700',
                textAlign: 'center',
              }}
            >
              <Grid component="span" sx={{ fontWeight: '500', textTransform: 'uppercase' }}>
                {`${intl.formatMessage({ id: 'Global_Page_Not_Found_11' })}`}&nbsp;<strong>{props.title}</strong>
              </Grid>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '1.5em',
                textAlign: 'center',
                display: 'inline-block',
                p: '5% 0',
              }}
            >
              {props.content !== '' ? `${props.content}, ` : ''}
              {`${intl.formatMessage({ id: 'Global_Page_Not_Found_12' })}`}
            </Typography>

            <SearchEngineModal open={openSearch} handleClose={() => setOpenSearch(false)} />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
}
