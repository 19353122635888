import React from 'react';
import { Grid } from '@mui/material';
import BackButton from '../component-modals/common/BackButton';
import Error from '../component-modals/common/Error';
import { useAppSelector } from '../redux/hooks';
import { selectPlayer } from '../reducers/playerSlice';
import { useIntl } from 'react-intl';

const NoMatch: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();

  return (
    <Grid className={'no-match-page padding-top'} sx={{ p: '0 5%' }}>
      <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ bgcolor: 'rgba(15,15,15,.9)', borderRadius: '10px', p: '20px' }}>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, .1)' : 'rgba(251, 88, 2, .1)'} />
        <Error status={404} content={`${intl.formatMessage({ id: 'Global_Page_Not_Found_Title' })}`} />
      </Grid>
    </Grid>
  );
};

export default NoMatch;
