import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, Fab, Grid, Button, CardMedia, useMediaQuery, Avatar, CircularProgress } from '@mui/material';
import { CalendarMonth, Close } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { FIELD_TYPES } from '../../utilities/utilities';
import { FivezerWhiteIcon, LocationIcon, ScoreBoardIcon, ShareIcon, SponsorshipIcon, VersusIcon } from '../../img/icons/Icons';
import ShareURLButton from '../common/ShareURLButton';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import _ from 'lodash';
import Moment from 'react-moment';
import ViewTeamLineupModal from '../team/ViewTeamLineupModal';
import ViewScoreModal from '../challenge/ViewScoreModal';
import { useForm } from 'react-hook-form';
import ManageScoreByAdminsModal from './ManageScoreByAdminsModal';
import LoadingComponent from '../common/LoadingComponent';
import NewSponsorshipModal from '../features/NewSponsorshipModal';
import EditSponsorshipModal from '../features/EditSponsorshipModal';
import { useIntl } from 'react-intl';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function ViewTournamentGroupGameModal(props: { game_id: string; handleClose: any; next_game: any; open: any }) {
  const FIND_TOURNAMENT_GROUP_GAME_BY_ID_QUERY = gql`
    query findTournamentGroupGameByID($id: ID!) {
      findTournamentGroupGameByID(id: $id) {
        id
        short_id
        title
        status
        type
        start_date
        end_date
        home_team {
          id
          name
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
            }
          }
        }
        away_team {
          id
          name
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
            }
          }
        }
        complex {
          id
          name
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        arena {
          id
          name
          type
          size
          rank
          pictures {
            path
          }
          feedbacks {
            id
          }
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        results {
          id
          status
          home_team {
            id
            name
          }
          away_team {
            id
            name
          }
          captains_confirmations {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
          }
          men_of_the_match {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
          statistics {
            id
            status
            nb_goals
            team_player {
              id
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
        }
        tournament_group_games {
          id
          name
          tournament {
            id
            status
            admins {
              id
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [tournamentGame, setTournamentGame] = React.useState(UNDEFINED_AS_ANY);
  const [openTeamLineup, setOpenTeamLineup] = React.useState(false);
  const [openManageScore, setOpenManageScore] = React.useState(false);
  const [openViewScore, setOpenViewScore] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const [openNewSponsorship, setOpenNewSponsorship] = React.useState({ subject_id: '', subject_title: '', isOpen: false });
  const [openEditSponsorship, setOpenEditSponsorship] = React.useState({ sponsorship_id: '', isOpen: false });

  const { watch, setValue } = useForm({
    defaultValues: {
      selected_team_lineup: '',
    },
  });
  const inputFields = watch();

  const loadedTournamentGame = useQuery(FIND_TOURNAMENT_GROUP_GAME_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.game_id,
    },
  });

  const ALL_ADMINS = tournamentGame?.tournament_group_games[0]?.tournament?.admins;
  const HOME_TEAM = tournamentGame?.home_team;
  const AWAY_TEAM = tournamentGame?.away_team;
  const HOME_TEAM_SCORE = _.sum(tournamentGame?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === HOME_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));
  const AWAY_TEAM_SCORE = _.sum(tournamentGame?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === AWAY_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));

  const handleClose = () => {
    props.handleClose();
  };

  const handleOpenNewSponsorship = (id: string, title: string) => {
    setOpenNewSponsorship({ subject_id: id, subject_title: title, isOpen: true });
  };

  const handleCloseNewSponsorship = async () => {
    try {
      setOpenNewSponsorship({ subject_id: '', subject_title: '', isOpen: false });
      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseEditSponsorship = async () => {
    try {
      setOpenEditSponsorship({ sponsorship_id: '', isOpen: false });
      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseTeamLineup = () => {
    setValue('selected_team_lineup', '');
    setOpenTeamLineup(false);
  };

  const handleOpenTeamLineup = (team_lineup_id: string) => {
    setValue('selected_team_lineup', team_lineup_id);
    setOpenTeamLineup(true);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleCloseManageScore = async () => {
    await handleRefetch();
    setOpenManageScore(false);
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedTournamentGame.refetch({
        id: props.game_id,
      });

      if (result) {
        setTournamentGame(result.data?.findTournamentGroupGameByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && tournamentGame === undefined && loadedTournamentGame.called && loadedTournamentGame.data?.findTournamentGroupGameByID) {
      setTournamentGame(loadedTournamentGame.data?.findTournamentGroupGameByID);
    }

    if (!props.open && tournamentGame) {
      setTournamentGame(UNDEFINED_AS_ANY);
    }
  }, [tournamentGame, loadedTournamentGame, setTournamentGame, props]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 0px 0' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={handleClose}
            className={'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          <>
            <Fab
              size="small"
              variant="extended"
              onClick={() => handleOpenNewSponsorship(tournamentGame?.game?.id, tournamentGame?.game?.title)}
              className={'button-green-reverse'}
              sx={{
                boxShadow: 'none',
                bgcolor: 'transparent',
                mr: '2%',
              }}
            >
              <SponsorshipIcon />
              &nbsp;{`${intl.formatMessage({ id: 'Tournament_Button_New_Sponsorship' })}`}
            </Fab>

            <NewSponsorshipModal subject_id={openNewSponsorship.subject_id} subject_title={openNewSponsorship.subject_title} open={openNewSponsorship.isOpen} handleClose={handleCloseNewSponsorship} />
            <EditSponsorshipModal sponsorship_id={openEditSponsorship.sponsorship_id} open={openEditSponsorship.isOpen} handleClose={handleCloseEditSponsorship} />
          </>
        </Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{tournamentGame?.tournament_group_games[0]?.name ?? ''}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0', pb: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span">
          {!tournamentGame || loadedTournamentGame.loading ? (
            <Grid item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
              {!tournamentGame === undefined && loadedTournamentGame.called && !loadedTournamentGame.loading ? (
                <Typography
                  variant="h3"
                  sx={{
                    p: '30px 20px',
                    fontSize: '1.3em',
                    textAlign: 'center',
                    width: '100%',
                    color: 'rgba(247, 251, 250, 0.3) !important',
                  }}
                >
                  {`${intl.formatMessage({ id: 'Tournament_Button_No_Schedule_Game' })}`}
                </Typography>
              ) : (
                ''
              )}

              {loadedTournamentGame.loading && tournamentGame === undefined ? <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>Loading game, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}
            </Grid>
          ) : (
            <>
              <AutoPlaySwipeableViews axis={'x-reverse'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                <CardMedia
                  sx={{
                    height: '25vh',
                    backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15,0) 0%,rgba(15, 15, 15,1) 70%), url(${tournamentGame?.arena?.pictures[activeStep]?.path})`,
                  }}
                ></CardMedia>
              </AutoPlaySwipeableViews>
              {tournamentGame?.is_private && !ALL_ADMINS?.find((p: any) => p?.id === loggedPlayer?.id) ? (
                <Grid container sx={{ height: '60vh', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', position: 'relative', mt: '-30px', bgcolor: 'rgba(15, 15, 15, 1)' }}>
                  <Typography sx={{ fontSize: '1.3em', textAlign: 'center', position: 'relative' }}>{`${intl.formatMessage({ id: 'ViewTournamentPlayoffGameModal_Private' })}`}</Typography>
                </Grid>
              ) : (
                <Grid item xs={12} md={12} lg={12} sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', position: 'relative', mt: '-60px', bgcolor: 'rgba(15, 15, 15, 1)' }}>
                  <Grid container sx={{ p: '10px 10% 30px', textAlign: 'center' }}>
                    <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'alignItems': 'center', 'justifyContent': 'center', '&:hover': { '& .MuiAvatar-root': { border: tournamentGame?.home_team?.id ? 'solid 3px rgba(199, 240, 11, 1)' : '' } } }} onClick={tournamentGame?.home_team?.id ? () => handleOpenTeamLineup(tournamentGame?.home_team?.id) : undefined}>
                      <Grid sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            lineHeight: '15px',
                            fontWeight: '800',
                            textTransform: 'uppercase',
                            height: '58px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'rgba(247, 251, 250, 1)',
                          }}
                        >
                          {tournamentGame?.home_team?.name}
                        </Typography>
                        {tournamentGame?.home_team?.team?.avatar?.path ? <Avatar src={tournamentGame?.home_team?.team?.avatar?.path} alt={tournamentGame?.home_team?.name} sx={{ m: 'auto', width: { xs: '60px', sm: '80px', md: '100px' }, height: { xs: '60px', sm: '80px', md: '100px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                      </Grid>
                      {tournamentGame?.results?.status === 'CONFIRMED' ? <Typography sx={{ color: HOME_TEAM_SCORE > AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 1) !important', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{HOME_TEAM_SCORE}</Typography> : ''}
                    </Grid>
                    <Grid className="separator" item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '50px', sm: '50px' } }} />
                    </Grid>
                    <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'alignItems': 'center', 'justifyContent': 'center', '&:hover': { '& .MuiAvatar-root': { border: tournamentGame?.away_team?.id ? 'solid 3px rgba(199, 240, 11, 1)' : '' } } }} onClick={tournamentGame?.away_team?.id ? () => handleOpenTeamLineup(tournamentGame?.away_team?.id) : undefined}>
                      {tournamentGame?.results?.status === 'CONFIRMED' ? <>{isSmallMobile ? '' : <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 1) !important', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography>}</> : ''}
                      <Grid sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            lineHeight: '15px',
                            fontWeight: '800',
                            textTransform: 'uppercase',
                            height: '58px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'rgba(247, 251, 250, 1)',
                          }}
                        >
                          {tournamentGame?.away_team?.name}
                        </Typography>
                        {tournamentGame?.away_team?.team?.avatar?.path ? <Avatar src={tournamentGame?.away_team?.team?.avatar?.path} alt={tournamentGame?.away_team?.name} sx={{ m: 'auto', width: { xs: '60px', sm: '80px', md: '100px' }, height: { xs: '60px', sm: '80px', md: '100px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                      </Grid>

                      {tournamentGame?.results?.status === 'CONFIRMED' ? <>{isSmallMobile ? <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography> : ''}</> : ''}
                    </Grid>
                    {tournamentGame?.results?.status === 'CONFIRMED' ? (
                      <Grid container sx={{ justifyContent: 'center' }}>
                        <Button className="button-cancel" onClick={() => setOpenViewScore(true)} sx={{ fontWeight: '600 !important', fontSize: '.8em' }}>
                          {`${intl.formatMessage({ id: 'ViewTournamentPlayoffGameModal_Game_Result' })}`}
                        </Button>
                        <ViewScoreModal challenge_short_id={tournamentGame?.short_id} open={openViewScore} handleClose={() => setOpenViewScore(false)} />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <ViewTeamLineupModal team_lineup_id={inputFields.selected_team_lineup} open={openTeamLineup} handleClose={handleCloseTeamLineup} />
                  </Grid>
                  <Grid container sx={{ minHeight: '40vh', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', bgcolor: 'rgba(24, 30, 36, 1)', pt: '50px' }}>
                    <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ p: '0 5%' }}>
                      <Grid container className="address" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' } }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <LocationIcon />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} component="a" href={`geo:${(tournamentGame?.complex || tournamentGame?.arena)?.location?.coordinates?.latitude},${(tournamentGame?.complex || tournamentGame?.arena)?.location?.coordinates?.longitude}`} target="_blank">
                          <Typography>{(tournamentGame?.complex || tournamentGame?.arena)?.location?.title}</Typography>

                          <Typography
                            sx={{
                              display: (tournamentGame?.complex || tournamentGame?.arena)?.location?.street_name === '' ? 'none' : 'inline-block',
                              width: '100%',
                            }}
                            className={'primary-address'}
                          >
                            {(tournamentGame?.complex || tournamentGame?.arena)?.location?.street_name}
                          </Typography>
                          <Typography
                            sx={{
                              display: (tournamentGame?.complex || tournamentGame?.arena)?.location?.secondary_street_name === '' ? 'none' : 'inline-block',
                              width: '100%',
                            }}
                            className={'secondary-address'}
                          >
                            {(tournamentGame?.complex || tournamentGame?.arena)?.location?.secondary_street_name}
                          </Typography>
                          <Typography
                            sx={{
                              display: 'inline-block',
                              width: '100%',
                            }}
                            className={'postcode'}
                          >
                            {(tournamentGame?.complex || tournamentGame?.arena)?.location?.postcode?.name} - {(tournamentGame?.complex || tournamentGame?.arena)?.location?.city?.name} {(tournamentGame?.complex || tournamentGame?.arena)?.location?.state?.name ? `- ${(tournamentGame?.complex || tournamentGame?.arena)?.location?.state?.name}` : ''}
                          </Typography>
                          <Typography
                            sx={{
                              display: 'inline-block',
                              width: '100%',
                            }}
                            className={'country'}
                          >
                            <Grid container sx={{ alignItems: 'center' }}>
                              {(tournamentGame?.complex || tournamentGame?.arena)?.location?.country?.name}&nbsp;
                              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${(tournamentGame?.complex || tournamentGame?.arena)?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${(tournamentGame?.complex || tournamentGame?.arena)?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                            </Grid>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="dates" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' }, 'pt': '20px' }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CalendarMonth />
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <Typography>
                            <Moment format="ddd D MMM YYYY">{tournamentGame?.start_date}</Moment>
                          </Typography>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} sx={{ display: 'flex', justifyContent: 'end' }}>
                          <Typography>
                            <Moment format="HH:mm">{tournamentGame?.start_date}</Moment>
                          </Typography>
                          &nbsp;&rarr;&nbsp;
                          <Typography>
                            <Moment format="HH:mm">{tournamentGame?.end_date}</Moment>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="field" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' }, 'pt': '20px' }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {FIELD_TYPES?.find((f: any) => f?.value === tournamentGame?.arena?.type)?.icon}
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Typography>
                            {tournamentGame?.arena?.name} ({FIELD_TYPES?.find((f: any) => f?.value === tournamentGame?.arena?.type)?.label})
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ minHeight: '15vh', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', bgcolor: 'rgba(33,35,41, .1)', mt: '30px', p: { xs: '30px 5% 100px', sm: '30px 5% 80px', md: '30px 5%' } }}>
                      <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                        {tournamentGame?.results?.status !== 'CONFIRMED' && tournamentGame?.tournament_group_games[0]?.tournament?.status === 'GROUPS_STAGE_ON_GOING' && tournamentGame?.end_date <= Date.now() && ALL_ADMINS?.find((c: any) => c?.id === loggedPlayer?.id) ? (
                          <>
                            <Button className={'button-orange'} onClick={() => setOpenManageScore(true)} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                              <ScoreBoardIcon />
                              &nbsp;
                              {tournamentGame?.results?.status === 'CONFIRMED' ? `${intl.formatMessage({ id: 'ViewTournamentPlayoffGameModal_Score_Added' })}` : `${intl.formatMessage({ id: 'ViewTournamentPlayoffGameModal_Button_Add_Score' })}`}
                            </Button>
                            <ManageScoreByAdminsModal challenge_short_id={tournamentGame?.short_id} group_game={tournamentGame} open={openManageScore} handleClose={handleCloseManageScore} />
                          </>
                        ) : (
                          ''
                        )}

                        <ShareURLButton
                          button={
                            <Button className={'button-green-reverse'} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                              <ShareIcon />
                              &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Share' })}`}
                            </Button>
                          }
                          title={tournamentGame?.title}
                          url={window.location.href}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

ViewTournamentGroupGameModal.propTypes = {
  game_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

ViewTournamentGroupGameModal.defaultProps = {};
