import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { Fab, Grid, Typography, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { ARRAY_AS_ANY, DESCRIPTION_AS_ANY } from '../../utilities/CommonInterfaces';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import Modal from '../layout/Modal';
import { selectPlayer } from '../../reducers/playerSlice';
import InputDescriptionsFeature from '../common/InputDescriptionsFeature';

interface IAddSponsorshipNoteModal {
  is_sponsor: boolean;
  sponsorship_id: string;
  handleClose: any;
  open: boolean;
}

const AddSponsorshipNoteModal: React.FC<IAddSponsorshipNoteModal> = (props: IAddSponsorshipNoteModal) => {
  const ADD_SPONSORSHIP_NOTE_MUTATION = gql`
    mutation addSponsorshipNote($sponsorship: inputAddSponsorshipNote!) {
      addSponsorshipNote(sponsorship: $sponsorship)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);

  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      language: 'GB',
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',
      descriptions: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();
  const [addSponsorshipNote, addedSponsorshipNote] = useMutation(ADD_SPONSORSHIP_NOTE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  const handleAddSponsorshipNote = async () => {
    try {
      await addSponsorshipNote({
        variables: {
          sponsorship: {
            id: props.sponsorship_id,
            is_sponsor: props.is_sponsor,
            author_id: loggedPlayer?.id,
            descriptions: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  React.useEffect(() => {
    if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
    if (inputFields.descriptions?.length > 0 && inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');
  }, [props, inputFields, setValue]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: {
          p: '0',
          width: '85%',
          position: 'absolute',
          top: '5%',
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Grid container>
          <Fab
            size="small"
            onClick={handleClose}
            className="button-cancel"
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              m: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'AddSponsorshipNoteModal_Title' })}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={'form-page'} sx={{ bgcolor: 'transparent', p: '0', minHeight: 'auto' }}>
          <form className={'form-root'}>
            <Typography
              variant="h3"
              sx={{
                fontSize: '1.4em',
                fontWeight: '700',
                m: '0px 0 20px',
              }}
            >
              {`${intl.formatMessage({ id: 'NewSponsorshipModal_Descriptions_Title' })}`}
            </Typography>
            <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

            <Grid container sx={{ mt: '40px' }}>
              <Button onClick={handleClose} className="button-cancel">{`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}</Button>

              <LoadingButton onClick={handleAddSponsorshipNote} loading={addedSponsorshipNote.loading} loadingPosition="end" endIcon={<Send />} disabled={inputFields.descriptions?.length === 0 || inputFields.descriptions[0]?.content?.length === 0} className={inputFields.descriptions?.length === 0 || inputFields.descriptions[0]?.content?.length === 0 ? 'button-disabled' : 'button-green'}>{`${intl.formatMessage({
                id: 'AddSponsorshipNoteModal_Button_Create',
              })}`}</LoadingButton>
            </Grid>
          </form>
        </Grid>
      </DialogContent>

      <Modal loading={addedSponsorshipNote.loading} open={open} title="" content={'Do you confirm your notes?'} secondaryContent="This action is not reversible" actionOneTitle={'Send notes'} actionOne={handleAddSponsorshipNote} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} actionTwo={() => setOpen(false)} />
    </Dialog>
  );
};

AddSponsorshipNoteModal.propTypes = {
  sponsorship_id: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

AddSponsorshipNoteModal.defaultProps = {
  sponsorship_id: '',
};

export default AddSponsorshipNoteModal;
