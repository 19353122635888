import { CardMedia, Fab, Grid, Skeleton } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { selectPlayer } from '../../reducers/playerSlice';

interface ILivestreamVideoPlayer {
  room_id: string;
  status: string;
  video_url?: string;
}

const LivestreamVideoPlayer: React.FC<ILivestreamVideoPlayer> = (props: ILivestreamVideoPlayer) => {
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const videoRef = React.useRef(UNDEFINED_AS_ANY);
  const [isPlaying, setIsPlaying] = React.useState(false);

  // Function to play the video
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  // Function to pause the video
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // Function to handle video end
  const handleVideoEnd = () => {
    videoRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  React.useEffect(() => {
    if (videoRef.current && videoRef.current?.currentTime >= videoRef.current?.duration) {
      videoRef.current.currentTime = 0;
    }
  }, [videoRef.current?.currentTime]);

  return (
    <>
      {props.room_id && (
        <Grid container sx={{ 'justifyContent': 'center', 'minHeight': '300px', 'maxHeight': '720px', '& video': { width: '100%', maxWidth: '900px', height: 'auto', borderRadius: '20px' } }}>
          {props.status === 'ENDED' ? (
            props?.video_url ? (
              <>
                <CardMedia component={'video'} ref={videoRef} onClick={isPlaying ? pauseVideo : playVideo} onEnded={handleVideoEnd} src={props?.video_url} controls={isPlaying} sx={{ maxWidth: '769px' }} />
                {!isPlaying ? (
                  <Fab variant="extended" className="button-green" onClick={playVideo} sx={{ boxShadow: 'none', position: 'relative', mt: '-250px' }}>
                    Replay
                  </Fab>
                ) : (
                  ''
                )}
              </>
            ) : (
              <Skeleton animation={'pulse'} height={350} width="80%" style={{ backgroundColor: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .1)' : 'rgba(15, 15, 15, .5)') : 'rgba(247, 251, 250, .1)' }} />
            )
          ) : (
            ''
          )}
          {props.status === 'ON_GOING' ? <ReactPlayer url={`http://localhost:8080/hls/${props.room_id}.m3u8`} controls={true} volume={0} muted={true} playing={true} width="100%" height="100%" /> : ''}
        </Grid>
      )}
    </>
  );
};

export default React.memo(LivestreamVideoPlayer);
