import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, CardMedia, Dialog, DialogContent, Grid, MenuItem, Slider, TextField, Typography } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import { selectSession } from '../../reducers/sessionSlice';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { selectPlayer } from '../../reducers/playerSlice';

interface INewLivestreamClipModal {
  livestream_id: string;
  video_url: string;
  handleClose: any;
  open: boolean;
}

const NewLivestreamClipModal: React.FC<INewLivestreamClipModal> = (props: INewLivestreamClipModal) => {
  const CREATE_LIVESTREAM_CLIP_MUTATION = gql`
    mutation createLivestreamClip($clip: inputCreateLivestreamClip!) {
      createLivestreamClip(clip: $clip) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [position, setPosition] = React.useState(0);
  const [duration, setDuration] = React.useState(UNDEFINED_AS_ANY);
  const [positions, setPositions] = React.useState<number[]>([0, 0]);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      flexer_id: '',
      comment_input: '',
      selected_mood: 'NEUTRAL',
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const [createLivestreamClip, newClip] = useMutation(CREATE_LIVESTREAM_CLIP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    setIsPlaying(false);
    setPositions([0, 0]);
    setDuration(UNDEFINED_AS_ANY);
    props.handleClose();
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current?.play().catch((error) => {
          console.error('Error playing video:', error);
          if (error.name === 'NotSupportedError') {
            // Handle the error appropriately, e.g., display a message to the user
            alert('This video format is not supported by your browser.');
          }
        });
        setIsPlaying(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handlePauseVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current?.pause();
        setIsPlaying(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const formatDuration = (value: number) => {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute > 0 ? `${minute}:` : '00:'}${secondLeft < 10 ? `0${secondLeft.toFixed(0)}` : secondLeft.toFixed(0)}`;
  };

  const formatTime = (seconds: number) => {
    // Convert the float value to an integer to ignore the fractional part
    const totalSeconds = Math.floor(seconds);

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    // Pad hours, minutes, and seconds with leading zeros if needed
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(secs).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setPosition(newValue as number);
    if (videoRef.current) {
      videoRef.current.currentTime = newValue as number;
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPositions(newValue as number[]);
  };

  const handleCreateLivestreamClip = async () => {
    try {
      const result = await createLivestreamClip({
        variables: {
          clip: {
            livestream_id: props.livestream_id,
            author_id: inputFields.flexer_id,
            app_name: session?.app_client?.name,
            video_url: props.video_url,
            start_time: formatTime(positions[0]),
            end_time: formatTime(positions[1]),
          },
        },
      });

      if (result.data) {
        setTimeout(function () {
          handleClose();
        }, 2000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!duration && videoRef.current?.duration) {
      setDuration(videoRef.current?.duration);
      if (_.sum(positions) === 0) {
        setPositions([0, parseFloat(videoRef.current?.duration?.toString()) / 2]);
      }
    }
  }, [duration, videoRef.current?.duration]);

  React.useEffect(() => {
    if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
  }, [inputFields, setValue, ALL_FLEXERS]);

  React.useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const updateSlider = () => {
        if (isPlaying) {
          setPosition(video.currentTime);
        }
      };

      const interval = setInterval(updateSlider, 500); // Update every second

      return () => {
        clearInterval(interval); // Cleanup interval on component unmount or when isPlaying changes
      };
    }
  }, [isPlaying, duration]);

  return (
    <Dialog open={props.open} onClose={handleClose} PaperProps={{ sx: { bgcolor: 'rgba(255, 255, 255, 1)', p: '20px 0', minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' } } }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      {newClip.called && newClip.data?.createLivestreamClip?.id ? (
        <>
          <Typography className="SUCCESSFUL" sx={{ fontSize: '1.3em', textAlign: 'center' }}>
            Clipped! 🎉
          </Typography>
        </>
      ) : (
        <DialogContent sx={{ p: '0 20px' }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Controller
              name="flexer_id"
              control={control}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  // label={`As`}
                  select
                  variant="outlined"
                  className="no-borders"
                  SelectProps={{
                    IconComponent: undefined,
                    sx: {
                      'color': 'rgba(15,15,15, 1)',
                      'border': 'none',
                      'bgcolor': 'transparent',
                      '& #mui-component-select-flexer_id': { p: '10px 0' },
                      '&::-ms-expand': {
                        display: 'none',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgba(15,15,15, 1)',
                      p: '0 6px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      width: '100%',
                    },
                  }}
                  sx={{ m: '0', p: '0', width: 'auto' }}
                >
                  {ALL_FLEXERS.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          src={option?.avatar}
                          sx={{
                            width: '25px',
                            height: '25px',
                          }}
                        />
                        &nbsp;&nbsp;
                        <Typography variant="caption">{option.name}</Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <CardMedia
            component={'video'}
            ref={videoRef}
            loop
            onClick={isPlaying ? handlePauseVideo : handlePlayVideo}
            style={{ borderRadius: '20px', maxHeight: '50vh', width: '70%', margin: 'auto' }} // Adjusted to maintain aspect ratio
          >
            <source src={props.video_url} type="video/mp4" />
            <source src={props.video_url} type="video/webm" />
            Your browser does not support the video tag.
          </CardMedia>
          <Slider aria-label="time-indicator" size="small" valueLabelDisplay="on" value={position} min={0} step={0.001} max={duration} onChange={handleSliderChange} sx={{ 'height': '4px', 'color': 'rgba(15,15,15,1)', '& .MuiSlider-thumb': { 'color': 'rgba(15,15,15,1)', '&:hover': { boxShadow: '0px 0px 0px 8px rgba(15,15,15, 0.16)' } } }} />
          {duration ? (
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: -2,
                color: 'rgba(15,15,15,1)',
              }}
            >
              <Typography variant="caption" sx={{ color: 'inherit' }}>
                {formatDuration(position)}
              </Typography>
              <Typography variant="caption" sx={{ color: 'inherit' }}>
                -{formatDuration(duration - position)}
              </Typography>
            </Grid>
          ) : (
            ''
          )}
          <Slider size="small" valueLabelDisplay="on" value={positions} min={0} step={0.001} max={duration} onChange={handleChange} sx={{ 'height': '4px', 'color': 'rgba(15,15,15,1)', '& .MuiSlider-thumb': { 'color': 'rgba(15,15,15,1)', '&:hover': { boxShadow: '0px 0px 0px 8px rgba(15,15,15, 0.16)' } } }} />
          <Grid container sx={{ mt: '30px' }}>
            <LoadingButton onClick={handleCreateLivestreamClip} loading={newClip.loading} loadingPosition="end" endIcon={<Send />} disabled={!props.video_url || _.sum(positions) === 0 || !positions[1]} className={!props.video_url || _.sum(positions) === 0 || !positions[1] ? 'button-disabled' : 'button-green'}>
              Create clip
            </LoadingButton>
            <Button onClick={handleClose} className="button-cancel">
              Cancel
            </Button>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

NewLivestreamClipModal.propTypes = {
  livestream_id: PropTypes.string.isRequired,
  video_url: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

NewLivestreamClipModal.defaultProps = {
  open: false,
  livestream_id: '',
  video_url: '',
};

export default React.memo(NewLivestreamClipModal);
