import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { customAlphabet } from 'nanoid';
import { IntlProvider, useIntl } from 'react-intl';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Chip, MenuItem, Autocomplete, TextField, Fab, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Avatar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close, Send } from '@mui/icons-material';
import { ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { CONVERSATION_REG } from '../../utilities/utilities';
import { useNavigate } from 'react-router-dom';

export default function NewConversationModal(props: { handleClose: any; open: boolean }) {
  const FIND_EXISTING_CONVERSATION_BY_PARTICIPANTS_QUERY = gql`
    query findExistingConversationByParticipants($conversation: inputFindExistingConversationByParticipants!) {
      findExistingConversationByParticipants(conversation: $conversation) {
        id
        short_id
      }
    }
  `;

  const CREATE_CONVERSATION_MUTATION = gql`
    mutation createConversation($conversation: inputCreateConversation!) {
      createConversation(conversation: $conversation) {
        id
        short_id
        title
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const navigate = useNavigate();
  const intl = useIntl();
  const [openSearchParticipant, setOpenSearchParticipant] = React.useState(false);
  const conversation_nanoid = customAlphabet(CONVERSATION_REG, 15);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: '',
      author_id: '',
      description: '',

      participant_name: '',
      selected_participants: ARRAY_AS_ANY,
      all_participants: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();

  const [createConversation, newConversation] = useMutation(CREATE_CONVERSATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedExistingConversation = useQuery(FIND_EXISTING_CONVERSATION_BY_PARTICIPANTS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      conversation: {
        participants: inputFields.selected_participants,
      },
    },
  });

  const ALL_AUTHORS = _.concat(loggedPlayer);
  const ALL_PARTICIPANTS = loggedPlayer?.id === inputFields.author_id ? ALL_AUTHORS?.find((a: any) => a?.id === inputFields.author_id)?.followings?.filter((f: any) => ALL_AUTHORS?.find((a: any) => a?.id === inputFields.author_id)?.followers?.find((fl: any) => fl?.id === f?.id)) : ALL_AUTHORS?.find((a: any) => a?.id === inputFields.author_id)?.connections;

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  const handleNewConversation = async () => {
    try {
      if (loadedExistingConversation.data?.findExistingConversationByParticipants) {
        handleClose();
        navigate(`/c/${loadedExistingConversation.data?.findExistingConversationByParticipants?.short_id}`);
      } else {
        handleCreateConversation();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleParticipant = (value: any) => {
    const newList = _.concat(value?.id, inputFields.selected_participants);
    setValue('selected_participants', newList);
  };

  const handleDeleteParticipant = (participant_id: string) => {
    const newList = inputFields.selected_participants?.filter((p_id: string) => p_id !== participant_id);
    setValue('selected_participants', newList);
  };

  const handleResetParticipant = () => {
    setValue('selected_participants', ARRAY_AS_ANY);
  };

  const handleCreateConversation = async () => {
    try {
      const result = await createConversation({
        variables: {
          conversation: {
            title: inputFields.title,
            short_id: conversation_nanoid(),
            description: inputFields.description,
            app_client_id: session?.app_client?.id,
            author_id: inputFields.author_id,
            participants_ids: _.concat(inputFields.author_id, inputFields.selected_participants),
          },
        },
      });

      navigate(`/c/${result.data?.createConversation?.short_id}`);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'} modal-feature`} PaperProps={{ sx: { p: '20px 0', minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' } } }} keepMounted={false}>
        <IntlProvider locale="en" defaultLocale="en">
          <Fab
            size="small"
            onClick={handleClose}
            className="button-cancel"
            sx={{
              boxShadow: 'none',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: 'center',
              pt: '0',
              pl: '10%',
              pr: '10%',
              textTransform: 'uppercase',
            }}
          >
            <Typography sx={{ fontWeight: '700' }}>{`${intl.formatMessage({ id: 'New_Conversation_Title' })}`}</Typography>
          </DialogTitle>
          <DialogContent sx={{ p: '0 0 100px' }}>
            <DialogContentText component={'span'} id="alert-dialog-description">
              <form>
                <Grid sx={{ p: '50px 10% 0' }}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
                    }}
                    render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'New_Conversation_Title_Label' })}`} fullWidth value={_.trimStart(field.value)} className={'field-bottom-space'} />}
                  />

                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: 400,
                      minLength: 0,
                      pattern: /^[a-zA-Z0-9_'\s]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        multiline
                        minRows={3}
                        maxRows={5}
                        label={`${intl.formatMessage({ id: 'New_Conversation_Description_Label' })}`}
                        placeholder={`${intl.formatMessage({ id: 'New_Conversation_Description_Placeholder' })}`}
                        variant="outlined"
                        fullWidth
                        className={'field-bottom-space'}
                        error={errors?.description?.type !== undefined}
                        helperText={errors?.description?.type !== undefined ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'New_Conversation_Description_Helper_1' })}` : `${intl.formatMessage({ id: 'New_Conversation_Description_Helper_2' })} ${field.value}`) : ''}
                        value={_.replace(field.value, /[^a-zA-Z0-9_'\s]+/g, '')}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="author_id"
                    render={({ field }: any) => (
                      <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'New_Conversation_Author_Label' })}`} variant="outlined" error={errors?.author_id !== undefined} required fullWidth className={'field-bottom-space'}>
                        {ALL_AUTHORS.map((option: any) => (
                          <MenuItem key={option.id} value={option.id} onClick={handleResetParticipant}>
                            <Grid container sx={{ alignItems: 'center' }}>
                              <Avatar src={option.avatar?.path || option?.logo?.path} alt={option.label || option.username} />
                              &nbsp;&nbsp;
                              <strong>{option.label || option.username}</strong>
                            </Grid>
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  {inputFields.author_id === '' || inputFields.author_id === undefined ? (
                    ''
                  ) : (
                    <Controller
                      name="all_participants"
                      control={control}
                      render={({ field }: any) => (
                        <Autocomplete
                          {...field}
                          open={openSearchParticipant}
                          onOpen={() => setOpenSearchParticipant(true)}
                          onClose={() => setOpenSearchParticipant(false)}
                          multiple
                          autoHighlight
                          filterSelectedOptions
                          value={field.value}
                          loading={false}
                          inputValue={inputFields.participant_name}
                          onInputChange={(event: any, newInputValue: any) => {
                            setValue('participant_name', newInputValue);
                          }}
                          options={ALL_PARTICIPANTS ? ALL_PARTICIPANTS?.filter((p: any) => inputFields.selected_participants?.find((p_id: string) => p_id === p?.id) === undefined) : []}
                          getOptionLabel={(option: any) => option?.username || option?.name}
                          defaultValue={ARRAY_AS_ANY}
                          renderOption={(props: any, option: any) => (
                            <Grid
                              container
                              key={option?.id}
                              onClick={() => handleParticipant(option)}
                              sx={{
                                alignItems: 'center',
                                p: '10px 15px',
                              }}
                            >
                              <Avatar src={option?.avatar?.path || option?.logo?.path} />
                              &nbsp;&nbsp;
                              <strong>{option?.username || option?.name}</strong>
                            </Grid>
                          )}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={`${intl.formatMessage({ id: 'New_Conversation_Participants_Label' })}`}
                              placeholder={`${intl.formatMessage({ id: 'New_Conversation_Participants_Placeholder' })}`}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    <Grid>
                                      {inputFields.selected_participants?.length === 0 || inputFields.selected_participants?.length === undefined ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            m: '0px',
                                            p: '5px 20px',
                                            textAlign: 'center',
                                            bgcolor: 'rgba(0, 0, 0, .1)',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          {`${intl.formatMessage({ id: 'New_Conversation_No_Participants' })}`}
                                        </Typography>
                                      ) : (
                                        inputFields.selected_participants?.map((participant_id: any) => (
                                          <Chip
                                            key={participant_id}
                                            variant="outlined"
                                            label={<strong>{ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.name || ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.username}</strong>}
                                            avatar={
                                              <Avatar
                                                sx={{
                                                  height: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  width: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                }}
                                                alt={ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.name || ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.username}
                                                src={ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.logo?.path || ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.avatar?.path}
                                              />
                                            }
                                            onDelete={() => handleDeleteParticipant(participant_id)}
                                            sx={{
                                              m: '5px',
                                              height: 'auto',
                                            }}
                                          />
                                        ))
                                      )}
                                    </Grid>
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                </Grid>
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-start' }}>
            <Button onClick={handleClose} disabled={newConversation.loading} className={newConversation.loading ? 'button-disabled' : 'button-cancel'}>
              {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
            </Button>

            <LoadingButton onClick={handleNewConversation} loading={newConversation.loading} loadingPosition="end" endIcon={<Send />} className={inputFields.author_id === '' || inputFields.selected_participants?.length === 0 ? 'button-disabled' : 'button-green'} disabled={inputFields.author_id === ''}>
              {`${intl.formatMessage({ id: 'New_Conversation_Create' })}`}
            </LoadingButton>
          </DialogActions>
        </IntlProvider>
      </Dialog>
    </>
  );
}

NewConversationModal.propTypes = {
  icon: PropTypes.any,
  handleClose: PropTypes.any,
  open: PropTypes.bool.isRequired,
};

NewConversationModal.defaultProps = {};
