import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Link as MUILink, Stack, IconButton } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import SwitchLanguageButton from '../common/SwitchLanguageButton';
import { FormattedMessage } from 'react-intl';
import { FacebookWhiteIcon, InstagramWhiteIcon } from '../../img/icons/Icons';
import { Link } from 'react-router-dom';

const Footer = (props: any) => {
  const loggedPlayer = useAppSelector(selectPlayer);

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        bgcolor: props.background,
        color: props.color,
        p: {
          xs: '100px 10% 80px',
          sm: '100px 10% 80px',
          md: '100px 10% 0px',
          lg: '100px 0 0px',
        },
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        fontWeight: 100,
      }}
    >
      {/* <Divider variant="middle" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 0.3)' : 'rgba(15, 15, 15, 0.04)', width: '80%', m: '0 auto 30px' }} /> */}
      <Typography variant="caption" sx={{ color: loggedPlayer?.id ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgb(15,15,15)') : '#FFF' }}>
        <MUILink color="inherit" href={window.location.origin} sx={{ 'textDecoration': 'none', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
          Fivezer&copy;&nbsp;{new Date().getFullYear()}
        </MUILink>
        <Typography sx={{ color: 'rgba(168, 202, 65, 1)', display: 'inline-block' }}>&nbsp;x&nbsp;</Typography>
        <FormattedMessage id="Footer_All_Rights" />
        <Typography sx={{ color: 'rgba(168, 202, 65, 1)', display: 'inline-block' }}>&nbsp;x&nbsp;</Typography>
        <Typography component={Link} to="/legal_mentions" sx={{ 'fontSize': 'inherit', 'color': 'inherit', 'textDecoration': 'none', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
          <FormattedMessage id="Footer_Legal_Mentions" />
        </Typography>
        <Typography sx={{ color: 'rgba(168, 202, 65, 1)', display: 'inline-block' }}>&nbsp;x&nbsp;</Typography>
        <SwitchLanguageButton flag_icon={true} />
        <Typography sx={{ color: 'rgba(168, 202, 65, 1)', display: 'inline-block' }}>&nbsp;x&nbsp;</Typography>
        <Typography component={Link} to="/join_complex_network" sx={{ color: 'inherit', fontSize: 'inherit', textDecoration: 'none' }}>
          <FormattedMessage id="Footer_Complex_Listing" />
        </Typography>
      </Typography>

      <Stack direction="row" sx={{ 'width': '100%', 'justifyContent': 'center', '& svg': { fontSize: '.7em' } }}>
        {/* <IconButton href="https://www.instagram.com/fivezersc/" target="_blank" rel="noopener noreferrer" sx={{ m: '0 5px' }}>
    {loggedPlayer?.app_configuration?.is_dark_mode ? <TikTokWhiteIcon sx={{ color: '#FFF' }} /> : <TikTokWhiteIcon sx={{ color: '#FFF' }} />}
  </IconButton> */}

        <IconButton href="https://www.facebook.com/fivezer/" target="_blank" rel="noopener noreferrer" sx={{ m: '0 5px' }}>
          {loggedPlayer?.app_configuration?.is_dark_mode ? <FacebookWhiteIcon sx={{ color: '#FFF' }} /> : <FacebookWhiteIcon sx={{ color: '#FFF' }} />}
        </IconButton>

        <IconButton href="https://www.instagram.com/fivezersc/" target="_blank" rel="noopener noreferrer" sx={{ m: '0 5px' }}>
          {loggedPlayer?.app_configuration?.is_dark_mode ? <InstagramWhiteIcon sx={{ color: '#FFF' }} /> : <InstagramWhiteIcon sx={{ color: '#FFF' }} />}
        </IconButton>
      </Stack>
    </Grid>
  );
};

export default Footer;

Footer.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
};
