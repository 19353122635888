import React from 'react';
import { Avatar, CircularProgress, Grid, SwipeableDrawer, Typography } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import useEventLog from '../../hooks/useEventLog';
import NumberFormatter from '../layout/NumberFormatter';
import { selectPlayer, storePlayer } from '../../reducers/playerSlice';
import { Link } from 'react-router-dom';
import LoadingComponent from '../common/LoadingComponent';

interface IViewParticipantMessenger {
  selected_participant: { id: string; type: string };
  open: boolean;
  handleClose: any;
  handleOpen: any;
}

const ViewParticipantMessenger: React.FC<IViewParticipantMessenger> = (props: IViewParticipantMessenger) => {
  const FIND_PARTICIPANT_BY_ID_QUERY = gql`
    query findParticipantByID($participant: inputFindParticipantByID!) {
      findParticipantByID(participant: $participant) {
        ... on Company {
          __typename
          id
          name
          label
          logo {
            path
          }
          followers {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
        }
        ... on User {
          __typename
          id
          username
          avatar {
            path
          }
          owner {
            firstname
            lastname
          }
          followers {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
        }
        ... on Player {
          __typename
          id
          username
          avatar {
            path
          }
          owner {
            firstname
            lastname
          }
          followers {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
        }
      }
    }
  `;

  const FOLLOW_USER_MUTATION = gql`
    mutation followUser($request: inputRequestFollowing!) {
      followUser(request: $request)
    }
  `;

  const UNFOLLOW_USER_MUTATION = gql`
    mutation unfollowUser($request: inputRequestFollowing!) {
      unfollowUser(request: $request)
    }
  `;

  const FOLLOW_ENTITY_MUTATION = gql`
    mutation followEntity($request: inputEntityFollowing!) {
      followEntity(request: $request)
    }
  `;

  const UNFOLLOW_ENTITY_MUTATION = gql`
    mutation unfollowEntity($request: inputEntityFollowing!) {
      unfollowEntity(request: $request)
    }
  `;

  const FIND_PLAYER_BY_ID_QUERY = gql`
    query findPlayerByID($id: ID!) {
      findPlayerByID(id: $id) {
        id
        username
        mobile
        bio
        roles
        permissions
        status
        email {
          address
          status
        }
        profiles {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
        }
        contacts {
          ... on Footballer {
            __typename
            id
            alias
          }
          ... on Basketballer {
            __typename
            id
            alias
          }
        }
        kyc_cases {
          id
          reference
          status
          created_at
          audited_at
          incorporation_file {
            id
            filename
            path
          }
          user_front_id {
            id
            filename
            path
          }
          user_back_id {
            id
            filename
            path
          }
          user_id_selfie {
            id
            filename
            path
          }
          user_residential_proof {
            id
            filename
            path
          }
          pid {
            id_number
            type
            status
          }
        }
        avatar {
          id
          filename
          type
          encoding
          size
          path
        }
        cover {
          id
          filename
          type
          encoding
          size
          path
        }
        social_medias {
          id
          name
          handle
          link
        }
        app_configuration {
          id
          is_dark_mode
          two_facts_mode
          prefered_language
        }
        followings {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
        }
        followers {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
        }
        owner {
          id
          firstname
          lastname
          date_of_birth
          gender
          emails {
            address
            status
          }
          player_account {
            id
            status
            username
            avatar {
              path
            }
            profiles {
              ... on Footballer {
                __typename
                id
                alias
              }
              ... on Basketballer {
                __typename
                id
                alias
              }
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          complex_owner_account {
            id
            status
            username
            avatar {
              path
            }
            five_complexes {
              id
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          transporter_account {
            id
            status
            username
            avatar {
              path
            }
          }
          health_worker_account {
            id
            status
            username
            avatar {
              path
            }
          }
        }
        locations {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const { handleSendLog } = useEventLog();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [participant, setParticipant] = React.useState(UNDEFINED_AS_ANY);

  const [followEntity, followedEntity] = useMutation(FOLLOW_ENTITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unfollowEntity, unfollowedEntity] = useMutation(UNFOLLOW_ENTITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [followUser, followedUser] = useMutation(FOLLOW_USER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unfollowUser, unfollowedUser] = useMutation(UNFOLLOW_USER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedPlayer = useQuery(FIND_PLAYER_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !loggedPlayer?.id,
    variables: { id: loggedPlayer?.id },
  });
  const loadedParticipant = useQuery(FIND_PARTICIPANT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !loggedPlayer?.id || !props.selected_participant?.id || !props.selected_participant?.type,
    variables: {
      participant: {
        id: props.selected_participant?.id,
        type: props.selected_participant?.type,
      },
    },
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedParticipant.refetch({
        participant: {
          id: props.selected_participant?.id,
          type: props.selected_participant?.type,
        },
      });

      if (result) {
        setParticipant(result.data?.findParticipantByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefetchUser = async () => {
    try {
      const result = await loadedPlayer.refetch({
        id: loggedPlayer?.id,
      });

      if (result) {
        try {
          dispatch(storePlayer(result?.data?.findPlayerByID));
        } catch (e: any) {
          console.error(e);
          handleSendLog(e.toString(), session?.app_client?.id);
        }
      }
    } catch (e: any) {
      console.error(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  const handleFollow = async (toFollow: any) => {
    if (toFollow && toFollow?.id !== loggedPlayer?.id) {
      await followUser({
        variables: {
          request: {
            follower: {
              id: loggedPlayer?.id,
              username: loggedPlayer?.username,
              type: loggedPlayer?.__typename,
            },
            followed: {
              id: toFollow.id,
              username: toFollow.username,
              type: toFollow.__typename,
            },
          },
        },
      });

      await handleRefetchUser();
    }
  };

  const handleUnfollow = async (toUnfollow: any) => {
    if (toUnfollow && toUnfollow?.id !== loggedPlayer?.id) {
      await unfollowUser({
        variables: {
          request: {
            follower: {
              id: loggedPlayer?.id,
              username: loggedPlayer?.username,
              type: loggedPlayer?.__typename,
            },
            followed: {
              id: toUnfollow.id,
              username: toUnfollow.username,
              type: toUnfollow.__typename,
            },
          },
        },
      });

      await handleRefetchUser();
    }
  };

  const handleFollowTeam = async (team_id: string) => {
    try {
      await followEntity({
        variables: {
          request: {
            follower_id: loggedPlayer?.id,
            follower_type: loggedPlayer?.__typename,
            followed_id: team_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUnfollowTeam = async (team_id: string) => {
    try {
      await unfollowEntity({
        variables: {
          request: {
            follower_id: loggedPlayer?.id,
            follower_type: loggedPlayer?.__typename,
            followed_id: team_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && !participant && loadedParticipant.data?.findParticipantByID) {
      setParticipant(loadedParticipant.data?.findParticipantByID);
    }

    if (!props.open && participant) {
      setParticipant(UNDEFINED_AS_ANY);
    }
  }, [props, loadedParticipant, participant]);

  return (
    <SwipeableDrawer anchor={'bottom'} open={props.open} onClose={props.handleClose} onOpen={props.handleOpen} sx={{ 'zIndex': '10000', '& .MuiPaper-root': { p: '30px 0', borderTopRightRadius: '25px', borderTopLeftRadius: '25px' } }}>
      {!participant ? (
        <LoadingComponent
          amount={0}
          text={
            <Typography sx={{ mb: '20px' }}>
              <FormattedMessage id="Global_Button_Loading_Wait" />
            </Typography>
          }
          loader={<CircularProgress className="orange-loader" />}
        />
      ) : (
        <>
          <Grid sx={{ textAlign: 'center', pb: '20px' }}>
            <Avatar component={Link} to={participant?.avatar ? `/u/${participant?.username}` : `/${participant?.name}`} src={(participant?.avatar || participant?.logo)?.path} sx={{ height: '90px', width: '90px', m: 'auto' }} />
            <Typography component={Link} to={participant?.avatar ? `/u/${participant?.username}` : `/${participant?.name}`} sx={{ fontWeight: '800', width: '100%', display: 'inline-block', textDecoration: 'none', color: 'inherit' }}>
              {participant?.name ? participant?.label || participant?.name : `${participant?.owner ? `${participant?.owner?.firstname} ${participant?.owner?.lastname}` : participant?.username}`}
            </Typography>
            <Typography variant="caption" sx={{ width: '100%', display: 'inline-block' }}>
              {participant?.__typename}
            </Typography>

            <Typography variant="caption" sx={{ width: '100%', display: 'inline-block', fontWeight: '800' }}>
              <NumberFormatter number={participant?.followers?.length} />
              &nbsp;{participant?.followers?.length > 1 ? `${intl.formatMessage({ id: 'Global_Stats_Followers' })}` : `${intl.formatMessage({ id: 'Global_Stats_Follower' })}`}
            </Typography>
          </Grid>

          {participant?.__typename === 'Player' ? (
            <Grid container className="follow-user" sx={{ justifyContent: 'center' }}>
              {loggedPlayer?.id === participant?.id ? undefined : loggedPlayer?.followings?.find((f: any) => f?.id === participant?.id) ? (
                <LoadingButton onClick={() => handleUnfollow(participant)} disabled={unfollowedUser.loading} className={unfollowedUser.loading ? 'button-disabled' : 'button-green-reverse'} sx={{ boxShadow: 'none', border: 'none !important', fontSize: '.7em' }}>
                  <FormattedMessage id="Global_Button_Unfollow" />
                </LoadingButton>
              ) : (
                <LoadingButton onClick={() => handleFollow(participant)} disabled={followedUser.loading} className={followedUser.loading ? 'button-disabled' : 'button-green-reverse'} sx={{ boxShadow: 'none', border: 'none !important', fontSize: '.7em' }}>
                  <FormattedMessage id="Global_Button_Follow" />
                </LoadingButton>
              )}
            </Grid>
          ) : (
            ''
          )}

          {participant?.__typename?.includes('Team') ? (
            <Grid container className="follow-company" sx={{ justifyContent: 'center' }}>
              {participant?.followers?.find((f: any) => f?.id === loggedPlayer?.id) ? (
                <LoadingButton className="button-cancel" loading={unfollowedEntity.loading} onClick={() => handleUnfollowTeam(participant?.id)} size="small" sx={{ opacity: '.4' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Unfollow' })}`}
                </LoadingButton>
              ) : (
                <LoadingButton className="button-green-reverse" loading={followedEntity.loading} onClick={() => handleFollowTeam(participant?.id)} size="small">
                  {`${intl.formatMessage({ id: 'Global_Button_Follow' })}`}
                </LoadingButton>
              )}
            </Grid>
          ) : (
            ''
          )}
        </>
      )}
    </SwipeableDrawer>
  );
};

export default React.memo(ViewParticipantMessenger);
