import React from 'react';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { Button, Grid, TextField, CircularProgress, Fab, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Switch, FormControlLabel } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';

export default function EditWalletModal(props: { wallet: any; open: any; handleClose: any }) {
  const EDIT_WALLET_MUTATION = gql`
    mutation editWallet($wallet: inputEditWallet!) {
      editWallet(wallet: $wallet) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const [wallet, setWallet] = React.useState(UNDEFINED_AS_ANY);

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      title: UNDEFINED_AS_ANY,
      is_default: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [editWallet, updatedWallet] = useMutation(EDIT_WALLET_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleEditWallet = async () => {
    try {
      await editWallet({
        variables: {
          wallet: {
            id: wallet?.id,
            owner_id: wallet?.owner?.id,
            title: _.trim(inputFields?.title),
            is_default: inputFields.is_default,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (wallet === undefined) {
      setWallet(props.wallet);
    }

    if (wallet) {
      if (inputFields.title === undefined && wallet?.title) setValue('title', wallet?.title);
      if (inputFields.is_default === undefined && wallet?.is_default) setValue('is_default', wallet?.is_default);
    }

    if (updatedWallet.called && updatedWallet.data?.editWallet && props.open) {
      reset();
      props.handleClose();
    }
  }, [props, inputFields, setValue, updatedWallet, reset, wallet]);

  return (
    <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'} BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, .2)' } }} PaperProps={{ sx: { minWidth: '80%' } }} keepMounted={false} onClose={() => props.handleClose('backdropClick' || 'escapeKeyDown')}>
      <Fab size="small" onClick={props.handleClose} sx={{ boxShadow: 'none', bgcolor: 'transparent', ml: '2%', mt: '2%' }}>
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{wallet?.title}</Typography>
      </DialogTitle>
      <DialogContent>
        {wallet === undefined ? (
          <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} className="green-loader" />
          </Grid>
        ) : (
          <DialogContentText id="alert-dialog-description" component={'div'}>
            <Grid sx={{ p: '30px 0' }}>
              <form>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 5000,
                    minLength: 200,
                  }}
                  render={({ field }: any) => <TextField {...field} label="Title" placeholder={'My bank account'} variant="outlined" fullWidth className={'field-bottom-space'} error={errors?.title?.type !== undefined} helperText={errors?.title?.type !== undefined ? (field.value.length < 200 ? 'Content must be at least 10 characters' : `There is an unauthorized character in ${field.value}`) : ''} />}
                />

                <Controller
                  name="is_default"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={wallet?.is_default}
                  render={({ field }: any) => <FormControlLabel {...field} control={<Switch className="green-checkbox" checked={field.value} />} label={field.value ? `This is ${wallet?.owner?.name}'s default wallet` : `This is not ${wallet?.owner?.name}'s default wallet`} />}
                />
              </form>

              <Grid container sx={{ justifyContent: 'start', mt: '40px' }}>
                <Button className="button-cancel" disabled={updatedWallet.loading} onClick={props.handleClose}>
                  Cancel
                </Button>
                <LoadingButton className="button-green" onClick={handleEditWallet} loading={updatedWallet.loading}>
                  Save updates
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}

EditWalletModal.propTypes = {
  request_id: PropTypes.any,
  open: PropTypes.any,
};

EditWalletModal.defaultProps = {
  loading: false,
};
