import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { Dialog, Grid, Typography } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';

interface IShowPlayerQRCode {
  username: string;
  logo?: string;
  open: boolean;
  handleClose: any;
}

const ShowPlayerQRCode: React.FC<IShowPlayerQRCode> = (props: IShowPlayerQRCode) => {
  const loggedPlayer = useAppSelector(selectPlayer);

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ sx: { bgcolor: '#FFF !important', p: '0', minWidth: '300px' } }} className={'component-transaction-confirmation-modal'}>
      <Grid container sx={{ p: '0' }}>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid sx={{ p: '0px 20px 20px 20px', textAlign: 'center' }}>
            <Typography id="tag-font" variant="caption" sx={{ display: 'block', textAlign: 'center', width: '100%', mb: '0px', fontSize: '3em' }}>
              {props?.username}
            </Typography>
            <QRCodeSVG
              value={`VIEW_PLAYER_@_${props.username}`}
              size={256}
              bgColor={'#ffffff'}
              fgColor={'#000000'}
              level={'Q'}
              includeMargin={false}
              imageSettings={{
                src: props?.logo || loggedPlayer?.avatar?.path,
                x: undefined,
                y: undefined,
                height: 30,
                width: 30,
                excavate: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

ShowPlayerQRCode.propTypes = {
  username: PropTypes.string.isRequired,
  logo: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
};

ShowPlayerQRCode.defaultProps = {
  open: false,
};

export default ShowPlayerQRCode;
