export const english_translations = {
  value: 'GB',
  content: {
    // GLOBAL
    Global_Meta_Title: 'Fivezer x Different Animal, Same Beast!',
    Global_Meta_Description: 'Fivezer is a next-generation Five League, where the best teams from all around the world compete against local and overseas teams, anywhere, anytime. Fivezer is a gateway to the largest competition/friendly games, where each player/team can monitor their performances and stats (individual/collective) by using our various ioT tools (bracelet, balls, nets and more).',
    Global_Button_Pay_Now: 'Pay now',
    Global_Button_Pay_Now_Caption_1: 'bookings must be paid',
    Global_Button_Pay_Now_Caption_2: "You must paid your tournament's fee",
    Global_Status: 'status',
    Global_Pictures_Label: 'pictures',
    Global_Pictures_Left: 'left',
    Global_Pictures_Max: 'Max',
    Global_Button_Add: 'Add',
    Global_Button_Add_Cover: 'Add cover',
    Global_Button_Add_Logo: 'Add logo',
    Global_Button_Add_PDF: 'Add PDF',
    Global_Button_Add_IMG_File: 'Add IMG File',
    Global_Button_Resize: 'click on the picture to resize',
    Global_Button_Cancel: 'Cancel',
    Global_Button_Crop: 'Crop',
    Global_Button_Close: 'Close',
    Global_Button_Ratio: 'Image does not respect the desired aspect ratio',
    Global_Button_Delete: 'Delete',
    Global_Button_Save_Updates: 'Save updates',
    Global_Button_Reset: 'Reset',
    Global_Button_Share: 'Share',
    Global_Button_Share_Caption_1: 'You can find more about',
    Global_Button_Share_Caption_2: 'on',
    Global_Button_Save_Translation: 'Save Translation',
    Global_Button_Loading: 'loading...',
    Global_Button_Loading_Wait: 'loading, please wait...',
    Global_Button_Load_More: 'load more',
    Global_Button_See_More: 'see more',
    Global_Button_See: 'see',
    Global_Button_See_Less: 'see less',
    Global_Button_Follow: 'Follow',
    Global_Button_Unfollow: 'Unfollow',
    Global_Follows_You: 'follows you',
    Global_Button_Next: 'Next',
    Global_Button_Back: 'Previous',
    Global_Button_Done: 'Done',
    Global_Button_View: 'View',
    Global_Button_Update: 'Update',
    Global_Address_Title_1: 'Address title',
    Global_Address_Title_2: 'HQ of, Office of, Address of, etc...',
    Global_Address_Street_Name_1: 'Street name',
    Global_Address_Street_Name_2: '83 peace road',
    Global_Address_Complement_1: 'Street name (complement)',
    Global_Address_Complement_2: 'Complement of street name',
    Global_Address_Complement_3: 'Apartment, suite, building, floor, etc...',
    Global_Address_Postcode_1: 'Postcode',
    Global_Address_Postcode_2: '75000',
    Global_Address_City_1: 'City',
    Global_Address_City_2: 'Peace City',
    Global_Address_County_1: 'County',
    Global_Address_County_2: 'County of peace',
    Global_Address_State_1: 'State',
    Global_Address_State_2: 'State of peace',
    Global_Address_Country: 'Country',
    Global_Address_GPS_1: 'Please locate the address above on the map.',
    Global_Address_GPS_2: 'Click on the marker to move it, and be as accurate as possible!',
    Global_No_Description: 'No descriptions',
    Global_Sport_Team_Type_1: 'League',
    Global_Sport_Team_Type_2: 'Friendly',
    Global_Sport_1: 'Football',
    Global_Sport_2: 'Basketball',
    Global_Social_Media_Title: 'Social Medias',
    Global_Social_Media_Button_1: 'Add social media',
    Global_Select_Translations: 'Translations',
    Global_Stats_Games: 'Games',
    Global_Stats_Games_Played: 'Games played',
    Global_Stats_Wins: 'Wins',
    Global_Stats_Draws: 'Draws',
    Global_Stats_Loss: 'Loss',
    Global_Stats_Goals: 'Goals',
    Global_Stats_Assists: 'Assists',
    Global_Stats_Conceded: 'Conceded',
    Global_Stats_World: 'Rank',
    Global_Stats_World_Sign_1: 'st',
    Global_Stats_World_Sign_2: 'nd',
    Global_Stats_World_Sign_3: 'rd',
    Global_Stats_World_Sign: 'th',
    Global_Stats_Followers: 'Followers',
    Global_Stats_Follower: 'Follower',
    Global_Stats_MOTM: 'Man of the match',
    Global_Stats_Good_Passes: 'Succeed passes',
    Global_Stats_Shots: 'Shots',
    Global_Stats_KM_Total: 'KM in total',
    Global_Stats_KM_Average: 'KM/match in average',
    Global_Stats_KMH_Max: 'KM/hour max speed',
    Global_Stats_KMH_Average: 'KM/hour in average',
    Global_Stats_Clean_Sheet_1: 'Clean',
    Global_Stats_Clean_Sheet_2: 'Sheet',
    Global_Tabs_Overview: 'Overview',
    Global_Tabs_Notifications: 'Notifications',
    Global_Tabs_Calendar: 'Calendar',
    Global_Tabs_Lineups: 'Global_Tabs_Lineups',
    Global_Tabs_Lineup: 'Global_Tabs_Lineup',
    Global_Tabs_Statistics: 'Statistics',
    Global_Button_Add_Player: 'Add player',
    Global_Button_Add_Manager: 'Add manager',
    Global_Button_Delete_Player: 'Delete player',
    Global_Button_New_Lineup: 'New lineup',
    Global_Button_Edit_Lineup: 'Edit lineup',
    Global_Button_New_Challenge: 'New challenge',
    Global_Button_No_Notifications: 'No notifications yet',
    Global_No_Challenges: 'No challenges',
    Global_No_Players: 'No players',
    Global_No_Manager: 'No managers',
    Global_No_Notifications: 'No notifications',
    Global_Sport_Type_1: 'Football',
    Global_Sport_Type_2: 'Basketball',
    Global_No_Trophies: 'No trophies',
    Global_Trophy_Gold: 'Gold',
    Global_Trophy_Silver: 'Silver',
    Global_Trophy_Bronze: 'Bronze',
    Global_Trophy_Iron: 'Iron',
    Global_Trophy_Right: 'Right',
    Global_Trophy_Left: 'Left',
    Global_Trophy_Both: 'Both',
    Global_Since: 'since',
    Global_Challenge_Type_Friendly: 'Friendly',
    Global_Challenge_Type_League: 'League',
    Global_Challenge_Type_Matchmaker: 'Matchmaker',
    Global_Dates_Start_Label: 'Start date',
    Global_Dates_End_Label: 'End date',
    Global_Followers: 'Followers',
    Global_Followings: 'Followings',
    Global_Gender_Female: 'Female',
    Global_Gender_Male: 'Male',
    Global_Gender_Other: 'Other',
    Global_Position_Goalkeeper: 'Goalkeeper',
    Global_Position_Defender: 'Defender',
    Global_Position_Midfielder: 'Midfielder',
    Global_Position_Striker: 'Striker',
    Global_Foots_Right: 'Right',
    Global_Foots_Left: 'Left',
    Global_Foots_Both: 'Both',
    Global_ID_Types_None: 'Select an ID type',
    Global_ID_Types_Passport: 'Passport',
    Global_ID_Types_National: 'National',
    Global_ID_Types_Residential: 'Residential (Temporarily)',
    Global_Permanent_Delete_Title: 'Remove permanently',
    Global_Button_Desactivate: 'Desactivate',
    Global_Button_Activate: 'Activate',
    Global_Field_Types_1: 'Hybrid',
    Global_Field_Types_2: 'Synthetic',
    Global_Field_Types_3: 'Second Generation (2G)',
    Global_Field_Types_4: 'Third Generation (3G)',
    Global_Field_Types_5: 'Fourth Generation (4G)',
    Global_Field_Types_6: 'Fifth Generation (5G)',
    Global_Field_Types_7: 'Sixth Generation (6G)',
    Global_Field_Sizes_1: '1vs1',
    Global_Field_Sizes_2: '2vs2',
    Global_Field_Sizes_3: '3vs3',
    Global_Field_Sizes_4: '4vs4',
    Global_Field_Sizes_5: '5vs5',
    Global_Field_Sizes_6: '6vs6',
    Global_Field_Sizes_7: '7vs7',
    Global_Field_Sizes_8: '8vs8',
    Global_Field_Sizes_9: '9vs9',
    Global_Field_Sizes_10: '10vs10',
    Global_Field_Sizes_11: '11vs11',
    Global_Delete_Confirmation_Caption_1: 'This action is irreversible',
    Global_Delete_Confirmation_Caption_2: 'Do you really want to delete',
    Global_Delete_Confirmation_Caption_3: 'Confirm by writing',
    Global_Delete_Confirmation_Caption_4: 'in the field below:',
    Global_Write_Username: 'Write a username',
    Global_Write_Reset_All: 'Reset all',
    Global_Filters_Title: 'Filters',
    Global_No_User_Found_Title: 'No user found',
    Global_No_Team_Found_Title: 'No team found',
    Global_See_Team: 'See team',
    Global_Write_Team: 'Write an keyword/theme to search',
    Global_Search_Tab_Players: 'Players',
    Global_Search_Tab_Teams: 'Teams',
    Global_Pin_SMS_1: 'Hello',
    Global_Pin_SMS_2: 'Your Fivezer verification code is:',
    Global_Activate_Email_Label_1: 'You must activate your email',
    Global_Activate_Email_Label_2: 'using the verification email we sent you.',
    Global_Activate_Email_Button_1: 'Sending email',
    Global_Activate_Email_Button_2: 'SENT',
    Global_Activate_Email_Button_3: 'RE-SEND',
    Global_As_Footballer: 'As',
    Global_No_Statistics: 'No statistics',
    Global_No_History: 'No transfer history',
    Global_NA: 'N/A',
    Global_Dark_Mode: 'Dark mode',
    Global_Light_Mode: 'Light mode',
    Global_Player: 'player',
    Global_Manager: 'manager',
    Global_Missing_Footballer_Profile_Button_1: 'Add it now',
    Global_Missing_Footballer_Profile_Label_1: 'You are missing a footballer profile',
    Global_Button_Edit: 'Edit',
    Global_Manager_At: 'Manager of',
    Global_Not_Found: 'not found',
    Global_Page_Not_Found_Title: 'Page not found',
    Global_Page_Not_Found_1: 'Sorry about that',
    Global_Page_Not_Found_2: "We couldn't load the information due to low network",
    Global_Page_Not_Found_3: 'Please try again',
    Global_Page_Not_Found_4: 'Access denied',
    Global_Page_Not_Found_5: "You don't have access to this information",
    Global_Page_Not_Found_6: 'Go home',
    Global_Page_Not_Found_7: 'not found',
    Global_Page_Not_Found_8: 'please try to double check the spelling of the name or use our',
    Global_Page_Not_Found_9: 'search',
    Global_Page_Not_Found_10: 'engine to try a new search.',
    Global_Page_Not_Found_11: 'Failed to load',
    Global_Page_Not_Found_12: 'please try to reload the page.',
    Global_Button_New_Livestream: 'New livestream',
    Global_Button_New_Team: 'New team',

    // NOTIFICATIONS MESSAGES
    View: 'View',
    Accept: 'Accept',
    Reject: 'Reject',
    Decline: 'Decline',
    Notification_New_Tournament_Title: 'New Tournament!',
    Notification_New_Tournament_Content_1: 'has been created!',
    Notification_Join_Team_Title: 'wants to join',
    Notification_Join_Team_Content_1: 'sent you a request to join',
    Notification_New_Team_Title: 'A New Team is born!',
    Notification_New_Team_Content_1: 'Congratulation',
    Notification_New_Team_Content_2: 'Your team is live.',
    Notification_New_Footballer_Title: 'A New Footballer is born!',
    Notification_New_Footballer_Content_1: 'Congratulation',
    Notification_New_Footballer_Content_2: 'Your footballer profile is live.',
    Notification_New_Flex_Title: 'Co-publish Flex',
    Notification_New_Flex_Content_1: 'wants to co-publish a flex with you.',
    Notification_New_Challenge_Title: 'New Challenge!',
    Notification_New_Challenge_Content_1: 'Challenge',
    Notification_New_Challenge_Content_2: 'is booked, get your team ready!',
    Notification_New_Challenge_Content_3: "'s captain must accept or decline the challenge!",
    Notification_New_Challenge_Content_4: 'Each player should pay',
    Notification_New_Challenge_Content_5: 'between',
    Notification_New_Challenge_Content_6: 'nothing because this field is free!',
    Notification_ScheduleTournamentModal_Title: 'New booking!',
    Notification_ScheduleTournamentModal_Content_1: 'has been booked, check your dashboard to see it.',
    Notification_Edit_Tournament_Playoff_Modal_Title: 'New booking!',
    Notification_Edit_Tournament_Playoff_Modal_Content_1: 'has been booked, check your dashboard to see it.',
    Notification_View_Team_Lineup_Title: 'wants to join your team!',
    Notification_View_Team_Lineup_Content_1: 'sent you a request to join your team to play',

    // APP COMPONENT
    App_User: 'User',
    App_Home: 'Home',
    App_Teams: 'Teams',
    App_Feeds: 'News',
    App_Challenges: 'Challenges',
    App_Tournaments: 'Tournaments',
    App_New_Challenge: 'New challenge',
    App_Find_Challenge: 'Find a challenge',
    App_Find_Opponent: 'Find an opponent',
    App_Build_Team: 'Build a team',
    App_Join_Team: 'Join a Team',
    App_New_Tournament: 'New Tournament',
    App_Add_PWA_Content: 'Download Fivezer!',
    App_Add_PWA_Safari_Tap: 'Tap',
    App_Add_PWA_Safari_Content: 'and "Add to Home Screen"',
    App_Add_PWA: 'Download',
    App_New_Field: 'Add new field',
    App_Menu_Button_Signup: 'Sign up',
    App_Menu_Button_Login: 'LogIn',
    App_New_Flex: 'New flex',

    // TOPBAR COMPONENT
    TopBar_Login: 'Log In',
    TopBar_Signup: 'Sign up',
    // FOOTER COMPONENT
    Footer_All_Rights: 'All rights reserved.',
    Footer_Legal_Mentions: 'Legal mentions',
    Footer_Legal_Mentions_Content: `
    **1. Introduction:**
       - "Welcome to Fivezer, your gateway to the world of international sports and competition. At Fivezer, we are dedicated to fostering a vibrant sports community where consumers can book sport sessions, and complex owners can efficiently manage bookings and receive payments through our platform. Our mission is to connect athletes and teams from across the globe, providing opportunities for them to showcase their skills and monitor their performance with cutting-edge technology. We invite you to join us on this exhilarating journey as we transform the world of sports."
    
    **2. Vision and Mission:**
       - Fivezer’s vision:
         - "We envision a world where athletes and sports teams, regardless of their location, can compete, connect, and excel on a global stage. Fivezer is the conduit to this vision, uniting the sports community and providing tools for growth and development."
       - Mission Statement: 
         - "Fivezer is committed to creating a seamless and transparent ecosystem where sports enthusiasts, teams, and athletes can engage, compete, and succeed."
    
    **3. Problem Statement:**
       - "Recognizing the need for a streamlined platform that empowers athletes, teams, and sports complexes to connect, compete, and manage their activities efficiently, Fivezer is dedicated to addressing this challenge."
    
    **4. Company Information:**
       - "Fivezer, operated by Africantum, is a registered business with its principal office located in France. You can contact us at contact@thebhub.io."
    
    **5. Terms of Use:**
       - By using Fivezer's services, you agree to abide by the following Terms of Use, which constitute a legally binding agreement between you and Africantum:
    
       **5.1. Account Creation and Management:**
          - "Your Fivezer account is your gateway to the world of sports. We offer a comprehensive suite of tools and features to help you manage your sports activities, connect with fellow athletes and teams, and enhance your performance. By registering and using your account, you gain access to a world of sports opportunities."
    
       **5.2. User Conduct:**
          - Users must comply with all applicable laws and regulations.
          - Prohibited activities may include harassment, cheating, spam, and any illegal activities.
    
       **5.3. Content Submission:**
          - Users are responsible for the content they submit to the platform.
          - Prohibited content may include copyrighted material, hate speech, or harmful content.
    
       **5.4. Privacy and Data Use:**
          - Information about how user data is collected, stored, and used.
          - Consent for data processing and sharing within the platform.
    
       **5.5. Intellectual Property:**
          - Protection of intellectual property rights for both users and the platform.
          - Reporting mechanism for copyright or trademark infringements.
    
       **5.6. Transactions and Payments:**
          - Terms related to payment processing, including fees, refunds, and payment methods.
          - Seller responsibilities for accurate service descriptions and delivery.
    
       **5.7. Dispute Resolution:**
          - Procedures for dispute resolution, including mediation or arbitration.
          - Reporting and resolution of conflicts between users.
    
       **5.8. Termination and Suspension:**
          - Conditions under which accounts may be terminated or suspended.
          - Appeal process for users subject to account actions.
    
       **5.9. Liability and Warranty:**
          - Limitations of liability for the platform and its users.
          - Warranty disclaimers for services and products.
    
       **5.10. Governing Law and Jurisdiction:**
          - Choice of law and jurisdiction for legal matters.
    
       **5.11. Changes to Terms:**
          - The platform's right to update or modify the terms and conditions.
          - Notice to users about changes.
    
       **5.12. Indemnification:**
          - Users agree to indemnify and hold the platform harmless from legal claims or liabilities.
    
       **5.13. Communication and Notifications:**
          - How the platform communicates with users, including email notifications.
    
       **5.14. User Reviews and Feedback:**
          - Policies related to user reviews and feedback on the platform.
    
       **5.15. Security Measures:**
          - User responsibilities for maintaining account security.
          - Platform measures to protect user data.
    
       **5.16. Accessibility and Availability:**
          - Platform accessibility requirements and availability assurances.
    
       **5.17. User Termination Rights:**
          - Conditions under which users can terminate their accounts.
    
       **5.18. Community Guidelines:**
          - Platform-specific community guidelines and rules of conduct.
    
       **5.19. Compliance with Laws:**
          - User commitment to comply with all applicable laws.
    
       **5.20. Miscellaneous:**
          - Any additional legal clauses or disclaimers specific to the platform's mission and user base.
    
    **6. Privacy Policy:**
       - "Fivezer's Privacy Policy outlines how we collect, store, and handle user data in compliance with data protection laws. This includes the use of advanced technology to enhance the sports experience and protect user data. Users can access our full Privacy Policy for more details."
    
    **7. User Responsibilities:**
       - "As a user of Fivezer, you are responsible for adhering to ethical and legal standards in your interactions with other users and within the platform. This includes refraining from engaging in any fraudulent activities, cheating, or misconduct."
    
    **8. Intellectual Property:**
       - "All intellectual property rights on Fivezer, including but not limited to trademarks and copyrights, are owned by Africantum. Users must respect these rights, and any reported violations will be addressed promptly."
    
    **9. Dispute Resolution:**
       - "In the event of disputes, users are encouraged to report issues through Fivezer's designated channels. Disputes may be resolved through mediation or arbitration, as outlined in our dispute resolution process."
    
    **10. Jurisdiction and Governing Law:**
        - "The jurisdiction for legal matters related to Fivezer is France. Any legal disputes will be governed by the laws of France."
    
    **11. Enforcement and Penalties:**
        - "Fivezer reserves the right to enforce these terms and policies. Violations may result in penalties, including account suspension or termination."
    
    **12. Reporting Misconduct:**
        - "If you encounter any misconduct, cheating, or fraudulent activities on Fivezer, please report them immediately to our support team. We take user protection seriously and will investigate and address such issues promptly."
    
    **13. Legal References and Compliance:**
        - "Fivezer is committed to complying with all applicable national and international laws, including but not limited to consumer protection, e-commerce, and data protection regulations."
    
    **14. Data Protection and Privacy:**
        - "Fivezer values the privacy and data protection of its users. We are committed to complying with data protection laws, including the General Data Protection Regulation (GDPR) and any other applicable regulations."
        - "User Data Collection: We collect and process user data for specific purposes, including account management, communication, performance analysis, and security."
        - "Data Security: We employ robust security measures, including encryption, to protect user data from unauthorized access or breaches."
        - "Third-Party Sharing: User data may be shared with trusted third-party service providers for purposes such as payment processing and analytics. We ensure
    
     that these providers also comply with data protection regulations."
        - "User Rights: Users have the right to access, rectify, or delete their personal data held by Fivezer. Requests can be made through our designated channels."
        - "Cookies: We use cookies to enhance user experience. Users can manage cookie preferences through their browser settings."
        - "By using Fivezer's services, you consent to the collection, processing, and use of your data for the specified purposes as described in our Privacy Policy."
  
    `,
    Footer_Complex_Listing: 'Sport complex registration',
    // INPUTDESCRIPTION COMPONENT
    InputDescriptionsFeature_Descriptions_Label: 'Available languages',
    InputDescriptionsFeature_Descriptions_Placeholder: 'Select a language',
    InputDescriptionsFeature_Add_Language: 'Add language',
    InputDescriptionsFeature_Selected_Description_Label_1: 'Current descriptions',
    InputDescriptionsFeature_Selected_Description_Label_2: 'and',
    InputDescriptionsFeature_Selected_Description_Label_3: 'descriptions',
    InputDescriptionsFeature_Selected_Description_Label_4: 'description',
    InputDescriptionsFeature_Selected_Description_Label_5: 'with empty content',
    InputDescriptionsFeature_Selected_Description_Placeholder: 'Select a description',
    InputDescriptionsFeature_Short_Descriptions_Placeholder: 'Input a short description',
    InputDescriptionsFeature_Selected_Description_Caption: '(empty content)',
    InputDescriptionsFeature_Selected_Description_Content: 'Description in',
    InputDescriptionsFeature_Selected_Short_Description_Content: 'Short description in',
    InputDescriptionsFeature_Selected_Description_Content_Warning: 'Dont forget to save your content after each updates',
    InputDescriptionsFeature_African_Languages: 'AFRICAN LANGUAGES',
    InputDescriptionsFeature_Other_Languages: 'OTHER LANGUAGES',
    // LOGINPAGE
    LoginPage_Hello: 'Hi',
    LoginPage_Friend: 'Baller',
    LoginPage_Welcome_Back: 'Welcome back!',
    // LOGIN FORM COMPONENT
    LoginForm_Login_Title: 'Login',
    LoginForm_Identifier: 'Username / Email',
    LoginForm_Password: 'Password',
    LoginForm_Forgot_Password: 'Forgot password?',
    LoginForm_Turn_On_Location: 'Enable your location to fully secure your account',
    LoginForm_Login: 'Login',
    LoginForm_Logging: 'Logging...',
    LoginForm_No_Member_Yet: 'Not member yet?',
    LoginForm_Signup: 'Sign up now',
    LoginForm_Error_Login: 'You have the wrong ID/password, please try again or reset your password',
    LoginForm_Reset_Password: 'Reset password',
    LoginForm_Remember_Password: 'Do you remember your password?',
    LoginForm_Switch_User: 'Switch account',
    // FORGOTPASSWORDFORM COMPONENT
    ForgotPasswordForm_Identifier: 'Identifier',
    ForgotPasswordForm_Email_Mobile: 'Email/Mobile',
    ForgotPasswordForm_Pin_Verification: 'PIN Verification',
    ForgotPasswordForm_Please_Wait: 'please wait...',
    ForgotPasswordForm_Sending_SMS: 'Sending SMS...',
    ForgotPasswordForm_Send_Via_Email: 'Send PIN via Email',
    ForgotPasswordForm_Send_Via_SMS: 'Send PIN via SMS',
    ForgotPasswordForm_Validation: 'Validation',
    ForgotPasswordForm_Is_Submitted: 'Your password has been updated, please try to login!',
    ForgotPasswordForm_Login_Now: 'Login now',
    ForgotPasswordForm_Is_Updated: 'Your password has been updated!',
    ForgotPasswordForm_New_Password: 'New Password',
    ForgotPasswordForm_New_Password_Confirmation: 'New Password confirmation',
    ForgotPasswordForm_Reset_Password: 'Reset password',
    ForgotPasswordForm_Last_Step: 'Last step',
    ForgotPasswordForm_Login_Button: 'Login',
    ForgotPasswordForm_Back_Button: 'Back',
    ForgotPasswordForm_PIN_Verification_Button: 'PIN Code Verification',
    ForgotPasswordForm_Continue_Button: 'Continue',
    ForgotPasswordForm_Turn_On_Location: 'Enable your location to fully secure your account',
    ForgotPasswordForm_Is_Email_Submitted: 'Your email has been sent, please check your inbox',
    ForgotPasswordForm_Resend_Email: 'Something went wrong, please try again',

    // SIGNUPFORM COMPONENT
    SignUpForm_Title: 'Sign Up',
    SignUpForm_Account_Info: 'Account info',
    SignUpForm_Username: 'Username',
    SignUpForm_Username_Taken: 'is taken already',
    SignUpForm_Email_Already_Used: 'is already used on another account',
    SignUpForm_Email_Incorrect: 'Email format incorrect',
    SignUpForm_Email: 'Email',
    SignUpForm_Gender: 'Gender',
    SignUpForm_DOB: 'Date of birth',
    SignUpForm_My_Gender: 'My pronoun is...',
    SignUpForm_Firstname: 'Firstname',
    SignUpForm_Lastname: 'Lastname',
    SignUpForm_New_Password: 'New Password',
    SignUpForm_Mobile_Verification: 'Mobile Verification',
    SignUpForm_Mobile: 'Mobile',
    SignUpForm_Sending_SMS: 'Sending SMS...',
    SignUpForm_Send_SMS: 'Send',
    SignUpForm_SMS_Error: 'SMS could not be sent to',
    SignUpForm_Beta_Notice: 'BETA VERSION: Here is your PIN CODE',
    SignUpForm_Use_International_Number: "Please use international format '+' or '00'",
    SignUpForm_Great: 'Great!',
    SignUpForm_Next_Step: "Next step, will be the customisation of your Football player, let's do it!",
    SignUpForm_We_Sent_Email: "After pressing on 'Create player', we will send you an email to verify your account to",
    SignUpForm_To_Verify: 'to verify your account.',
    SignUpForm_Create_Account_Button: 'Create player',
    SignUpForm_Last_Step: 'Last step',
    SignUpForm_Login_Button: 'Login',
    SignUpForm_Back_Button: 'Back',
    SignUpForm_PIN_Verification_Button: 'PIN Verification',
    SignUpForm_Continue_Button: 'Continue',
    SignUpForm_Turn_On_Location: 'Enable your location to fully secure your account',
    SignUpForm_Already_Member: 'Already a member?',
    SignUpForm_All_Done: "All steps completed - you're all done",
    SignUpForm_Loading_message: 'Creation of your account, please wait...',
    // ONBOARDING COMPONENT
    Onboarding_Create: 'Create',
    Onboarding_Your_Team: 'Your',
    Onboarding_Team: 'Team',
    Onboarding_Create_Content: 'Create your team and your game. Select your logo & team’s name. Choose your warriors. Manage your gang.',
    Onboarding_Book: 'Book',
    Onboarding_Your_Arena: 'Your',
    Onboarding_Arena: 'Arena',
    Onboarding_Book_Content: 'Book your pitch all around the world & defy other teams.',
    Onboarding_Climb: 'Climb the',
    Onboarding_Climb_The: 'The',
    Onboarding_Climb_Ranking: 'Ranking',
    Onboarding_Climb_Content: "Fivezer's ranking is based on the result of every team in the world. Win your games, earn points, be the top in your city and reach the top of the world.",
    Onboarding_Conquer: 'Conquer',
    Onboarding_And_Become: 'And become',
    Onboarding_Legend: 'a legend',
    Onboarding_Conquer_Content: 'Play instinctively. Play to win. Play to be the number one.',
    Onboarding_Next: 'Next',
    Onboarding_Back: 'Back',
    Onboarding_Lets_Ball: "Let's ball",
    // HOME COMPONENT
    Home_Hello: 'Good Day',
    Home_Street: 'Street',
    Home_Culture: 'Culture',
    Home_Football: 'Football',
    Home_Aka: 'AKA',
    Home_The_Biggest: 'THE BIGGEST',
    Home_Sports_Community: 'iSPORTS COMMUNITY',
    Home_Where: 'Where The Best Teams',
    Home_From: 'From All Around The World',
    Home_Compete: 'Challenge Local And Oversea Teams',
    Home_Anywhere: 'Anywhere, Anytime',
    Home_Hashtag: '#DifferentAnimalSameBeast',
    Home_New_Challenge: 'New challenge',
    Home_Throne: 'fields to conquer',
    Home_Find_Challenge: 'Find a challenge',
    Home_Upcoming_Challenges: 'challenges to take',
    Home_Upcoming_Challenge: 'challenge to take',
    Home_Find_Opponent: 'Challenge an opponent',
    Home_Challengers: 'challengers waiting for you',
    Home_Challenger: 'challenger waiting for you',
    Home_Build_Team: 'Build your team',
    Home_Become: 'Become a legend',
    Home_Join_Team: 'Join a Team',
    Home_Teams: 'teams (good vibes only)',
    Home_Team: 'team (good vibes only)',
    Home_Over: 'Over',
    Home_New_Tournament: 'new tournament',
    Home_New_Tournaments: 'new tournaments',
    Home_Prove: 'Titles & reputation, earn them all!',
    Home_Upcoming_No_Challenges: 'Launch the challenge first!',
    Home_Find_No_Opponent: 'Beat them all!',
    Home_Find_No_Tournament: 'Organise your own tournament!',
    Home_View_Feeds: 'Lives, clips & news',
    Home_Enjoy_Feeds: 'Watch worldwide games',

    // PAYSPORTSESSIONMODAL
    PaySportSessionModal_Pay: 'Pay',
    PaySportSessionModal_Title: 'Sport Session',
    PaySportSessionModal_Notification: 'A message has been sent to your players with the amount to pay. You will receive a notification for each payment done.. You can also pay for everyone or select a player who you want to pay for.',
    PaySportSessionModal_Squad: 'Squad',
    PaySportSessionModal_Paid_By: 'Paid by',
    PaySportSessionModal_Pay_For_Yourself: 'Pay for yourself',
    PaySportSessionModal_Pay_For_Them: 'Pay for them',
    PaySportSessionModal_Player_To_Pay: 'player',
    PaySportSessionModal_Players_To_Pay: 'players',
    PaySportSessionModal_Total: 'Total',
    PaySportSessionModal_Price_per_Player: 'Price per player',
    PaySportSessionModal_Proceed_Pay_Button: 'Proceed payment',
    PaySportSessionModal_Pay_Button: 'Pay',
    PaySportSessionModal_Cancel_Button: 'Cancel',
    PaySportSessionModal_Back_Button: 'Back',
    PaySportSessionModal_Payment_Method: 'Payment method',
    PaySportSessionModal_Stepper_Back_Button: 'Back',
    PaySportSessionModal_Stepper_Next_Button: 'Next',
    PaySportSessionModal_Stepper_Finish_Button: 'Finish',
    PaySportSessionModal_Snacks: 'Snacks',
    PaySportSessionModal_Per_Player: 'Per player',
    PaySportSessionModal_Unpaid: 'Unpaid',
    PaySportSessionModal_Paid: 'Paid',
    PaySportSessionModal_Group_Snacks: 'Group snacks',
    PaySportSessionModal_Individual_Snacks: 'Group snacks',
    PaySportSessionModal_Unpaid_Group_Snacks: 'Unpaid Group snacks',
    PaySportSessionModal_Fully_Paid_Group_Snacks: 'Group snacks fully paid',
    PaySportSessionModal_You_Paid: 'You paid!',
    // SPENDINGCODEUPDATEMODAL COMPONENT
    SpendingCodeModal_Forgot_Spending_Code: 'Forgot spending code?',
    SpendingCodeModal_Email_Sent: 'Email sent!',
    SpendingCodeModal_Remember_Spending_Code: 'Do you remember your spending code?',
    SpendingCodeModal_Identifier: 'Identifier',
    SpendingCodeModal_Email_Mobile: 'Email/Mobile',
    SpendingCodeModal_Pin_Verification: 'PIN Verification',
    SpendingCodeModal_Please_Wait: 'please wait...',
    SpendingCodeModal_Sending_SMS: 'Sending SMS...',
    SpendingCodeModal_Send_Via_Email: 'Send PIN via Email',
    SpendingCodeModal_Send_Via_SMS: 'Send PIN via SMS',
    SpendingCodeModal_Validation: 'Validation',
    SpendingCodeModal_Is_Submitted: 'Your password has been updated, please try to login!',
    SpendingCodeModal_Is_Updated: 'Your spending password has been updated!',
    SpendingCodeModal_New_Password: 'New SpendingPassword',
    SpendingCodeModal_New_Password_Confirmation: 'New Spending Password confirmation',
    SpendingCodeModal_Reset_Password: 'Reset spending password',
    SpendingCodeModal_Last_Step: 'Last step',
    SpendingCodeModal_Login_Button: 'Login',
    SpendingCodeModal_Back_Button: 'Back',
    SpendingCodeModal_PIN_Verification_Button: 'PIN Code Verification',
    SpendingCodeModal_Continue_Button: 'Continue',
    // JOINCOMPLEXNETWORK COMPONENT
    JoinComplexNetwork_1: 'Revolutionize Your Complex with Fivezer',
    JoinComplexNetwork_1_bis: 'A Global Playground of Champions!',
    JoinComplexNetwork_2: "Calling all complex owners – Fivezer is here to transform your sports universe! Say hello to the next-generation Five League that brings the world's finest teams to your doorstep, igniting electrifying competitions day and night, no matter where you are!",
    JoinComplexNetwork_3: "Unleash the power of cutting-edge ioT tools – from smart bracelets to wizardry balls – and watch in awe as your players' performances and jaw-dropping stats skyrocket to new heights!",
    JoinComplexNetwork_4: 'Step into the spotlight, dazzle the globe, and make your complex the epicenter of sporting excellence. Embrace fame, glory, and countless opportunities as sponsors, fans, and the media flock to witness the birth of champions!',
    JoinComplexNetwork_5: 'The time is now. The game is Fivezer. Prepare to rewrite the rules of sports as you know it. Join the elite league where greatness knows no boundaries!',
    JoinComplexNetwork_6: 'Fivezer – Fueling the dreams of champions, one complex at a time.',
    JoinComplexNetwork_6_bis: 'Embrace the future. Elevate your legacy!',
    // SENDRECOMMENDATIONMODAL COMPONENT
    SendRecommendationModal_Tell_Them: 'Tell them know about',
    SendRecommendationModal_Do_You_Want: 'Do you want your favorite five to join Fivezer?',
    SendRecommendationModal_We_Add_Them: 'Tell the owner about it, so we can add them in our list of available fields.',
    SendRecommendationModal_Send_Button: 'Send',
    SendRecommendationModal_Email_Button: 'Email',
    SendRecommendationModal_Press_Enter: 'Press enter to add more',

    // TOURNAMENT COMPONENT
    Tournament_Finale_128: 'Round of 128',
    Tournament_Finale_64: 'Round of 64',
    Tournament_Finale_32: 'Round of 32',
    Tournament_Finale_16: 'Round of 16',
    Tournament_Finale_8: 'Quarter-Finals',
    Tournament_Finale_4: 'Semi-Finals',
    Tournament_Finale_2: 'Final',
    Tournament_By: 'organise by',

    // AVAILABILITIES COMPONENT
    InputSelectAvailabilitiesFeature_Title_1: 'Choose time availabilities',
    InputSelectAvailabilitiesFeature_Title_2: 'You can update your disponibilities later',
    InputSelectAvailabilitiesFeature_Title_3: 'availabilities',
    InputSelectAvailabilitiesFeature_Title_4: 'No availabilities',
    InputSelectAvailabilitiesFeature_Title_5: 'Change',
    InputSelectAvailabilitiesFeature_Title_6: 'availabilities',
    InputSelectAvailabilitiesFeature_Day_1: 'Monday',
    InputSelectAvailabilitiesFeature_Day_2: 'Tuesday',
    InputSelectAvailabilitiesFeature_Day_3: 'Wednesday',
    InputSelectAvailabilitiesFeature_Day_4: 'Thursday',
    InputSelectAvailabilitiesFeature_Day_5: 'Friday',
    InputSelectAvailabilitiesFeature_Day_6: 'Saturday',
    InputSelectAvailabilitiesFeature_Day_7: 'Sunday',

    // FORMNEWTEAM COMPONENT
    FormNewTeam_Title: 'New Team',
    FormNewTeam_Create_Profile: 'Create Footballer profile',
    FormNewTeam_Sport_Type_Title: 'Sport',
    FormNewTeam_Sport_Type_Coming: 'Coming soon',
    FormNewTeam_Category_Title: 'Category',
    FormNewTeam_Category_Subtitle_1: 'Only LEAGUE teams can accumulate points and be rank',
    FormNewTeam_Category_Subtitle_2: 'You already have',
    FormNewTeam_Category_Subtitle_3: 'You already play for',
    FormNewTeam_Category_Subtitle_4: 'in the league',
    FormNewTeam_Category_Subtitle_5: 'You already have',
    FormNewTeam_Category_Subtitle_6: 'in the league',
    FormNewTeam_Name_Title: "Team's name",
    FormNewTeam_Name_Placeholder: 'Paris Saint Germain',
    FormNewTeam_Name_1: 'Name must have at least 4 characters',
    FormNewTeam_Name_2: 'You should ONLY use letters, numbers or underscore',
    FormNewTeam_Name_3: 'is taken already',
    FormNewTeam_Shortname_Title: 'Short version (3 letters)',
    FormNewTeam_Shortname_Placeholder: 'PSG',
    FormNewTeam_Shortname_1: 'Name must have at least 3 characters',
    FormNewTeam_Shortname_2: 'You should ONLY use letters, numbers or underscore',
    FormNewTeam_Privacy_Title: 'Privacy ?',
    FormNewTeam_Privacy_1: 'Yes, it is a private team 🤐',
    FormNewTeam_Privacy_2: 'No, it is a team open to the public 🤗',
    FormNewTeam_Bio_Title: 'Bio (multi-languages)',
    FormNewTeam_Area_Title: 'Which area do you represent?',
    FormNewTeam_Area_1: "Area's name",
    FormNewTeam_Area_2: 'São Paulo',
    FormNewTeam_Button_Create: 'Create Team',
    FormNewTeam_Create_Profile_Label: 'You must have at least a footballer profile to create a team',

    // FORMEDITTEAM COMPONENT
    FormEditTeam_Title: 'Edit Team',
    FormEditTeam_See_Profile: 'See profile',
    FormEditTeam_Sport_Type_Title: 'Sport',
    FormEditTeam_Sport_Type_Coming: 'Coming soon',
    FormEditTeam_Name_Title: "Team's name",
    FormEditTeam_Name_Placeholder: 'Paris Saint Germain',
    FormEditTeam_Name_1: 'Name must have at least 4 characters',
    FormEditTeam_Name_2: 'You should ONLY use letters, numbers or underscore',
    FormEditTeam_Name_3: 'is taken already',
    FormEditTeam_Shortname_Title: 'Short version (3 letters)',
    FormEditTeam_Shortname_Placeholder: 'PSG',
    FormEditTeam_Shortname_1: 'Name must have at least 3 characters',
    FormEditTeam_Shortname_2: 'You should ONLY use letters, numbers or underscore',
    FormEditTeam_Privacy_Title: 'Privacy ?',
    FormEditTeam_Privacy_1: 'Yes, it is a private team 🤐',
    FormEditTeam_Privacy_2: 'No, it is a team open to the public 🤗',
    FormEditTeam_Bio_Title: 'Bio (multi-languages)',
    FormEditTeam_Area_Title: 'Which area do you represent?',
    FormEditTeam_Area_1: "Area's name",
    FormEditTeam_Area_2: 'São Paulo',
    FormEditTeam_View_Inactive_1: 'View',
    FormEditTeam_View_Inactive_2: 'past',
    FormEditTeam_View_Inactive_3: 'players',
    FormEditTeam_View_Inactive_4: 'player',
    // FOOTBALLTEAM COMPONENT
    FootballTeam_Error_1: 'We are sorry but it seems like we can not find this team',
    FootballTeam_Alert_1: 'Your team needs at least one captain, otherwise you can not play games',
    FootballTeam_Alert_2: 'Your team needs at least one player and one captain, otherwise you can not play games',
    FootballTeam_Button_Edit: 'Edit Team',
    FootballTeam_Squad_Title: 'Squad',
    FootballTeam_Remove_Captain: 'remove captain',
    FootballTeam_Promote_Captain: 'Promote captain',
    FootballTeam_Choose_Number: 'Choose number',
    FootballTeam_Next_Challenges_Title: 'Next Challenges',
    FootballTeam_No_Challenges: 'No challenges yet',
    FootballTeam_Start_New_Challenge: 'Start one right now',
    FootballTeam_Last_Challenges: 'Last Challenges',
    FootballTeam_No_Players: 'No players yet',
    FootballTeam_Add_them: 'Add them now',
    FootballTeam_No_Lineups: 'No lineups yet',
    FootballTeam_Create_Lineup: 'Create one now',
    FootballTeam_Managers_Title: 'Managers',
    FootballTeam_Start_New_Manager_Quote: `aka Football Manager ${new Date().getFullYear()}`,
    FootballTeam_New_Player_Quote: 'Career Mode',
    // PROFILE COMPONENT
    Profile_Share_1: 'Share profile',
    Profile_Share_2: 'Fivezer profile',
    Profile_Share_3: 'Find',
    Profile_Share_4: "'s Fivezer profile",
    Profile_Button_Edit: 'Edit Profile',
    Profile_Button_New_Footballer: 'New Footballer',
    Profile_Button_New_Team: 'New Team',
    Profile_Button_New_Field: 'New Field',
    Profile_Button_New_Tournament: 'New tournament',
    Profile_Button_Settings: 'Settings',
    Profile_View_Card_1: 'View',
    Profile_View_Card_2: 'FZ card',
    Profile_Tab_Overview: 'Overview',
    Profile_Tab_Team: 'Teams',
    Profile_Tab_Calendar: 'Calendar',
    Profile_Tab_Statistics: 'Statistics',
    Profile_Tab_History: 'Transferts',
    Profile_Tab_Fields: 'Published fields',
    Profile_Tab_Flex: 'Flexs',
    Profile_Create_Team_1: 'To create a team you must verify your email',
    Profile_Create_Team_2: 'Create your team',
    Profile_Pick_Profile: 'Pick a profile',
    Profile_No_Teams: 'No teams',
    Profile_Missing_Profile: "doesn't have a sport profile yet",
    // FOOTBALLCARD COMPONENT
    FootballCard_Button_Share: 'Share card',
    FootballCard_Button_Back: 'Back',
    FootballCard_Button_Jubilee: 'Make your Jubilee',
    FootballCard_Button_Edit: 'Edit Football profile',
    FootballCard_Button_Share_Profile_1: 'Share profile',
    FootballCard_Profile_Status: 'Profile is',
    // FORMNEWTOURNAMENT COMPONENT
    FormNewTournament_Title: 'New tournament',
    FormNewTournament_Name: "Tournament's name",
    FormNewTournament_Name_Placeholder: 'Mamba 5 Tournament',
    FormNewTournament_Name_Helper_1: 'Name must have at least 4 characters',
    FormNewTournament_Name_Helper_2: 'You should ONLY use letters, numbers or underscore',
    FormNewTournament_Sport_Type_Coming: 'Coming soon',
    FormNewTournament_Descriptions: 'Descriptions',
    FormNewTournament_Cost_Title: 'Cost per team',
    FormNewTournament_Cost_Value: 'Cost',
    FormNewTournament_Cost_Currency: 'Currency',
    FormNewTournament_Trophy: 'Trophy',
    FormNewTournament_Trophies: 'Trophies',
    FormNewTournament_Privacy_Title: 'Privacy ?',
    FormNewTournament_Privacy_Content_1: 'Yes, it is a private event 🤐',
    FormNewTournament_Privacy_Content_2: 'No, its open to the public 🤗',
    FormNewTournament_Organisator_Title: 'Main Organisator',
    FormNewTournament_Organisator_Label: 'Main organisator',
    FormNewTournament_Organisator_Placeholder: 'Organisator...',
    FormNewTournament_Organisators_Title: 'Organisators',
    FormNewTournament_Organisators_Label: 'Organisators',
    FormNewTournament_Organisators_Placeholder: 'Organisators...',
    FormNewTournament_Managers_Title: 'Managers',
    FormNewTournament_Managers_Label: 'Managers',
    FormNewTournament_Managers_Placeholder: 'Managers...',
    FormNewTournament_Settings_Title: 'Tournament settings',
    FormNewTournament_Settings_Groups_1: 'Yes, it does have group phase 🤐',
    FormNewTournament_Settings_Groups_2: 'No, it does not have group phase 🤗',
    FormNewTournament_Settings_Playoff_Label: 'Playoff stage',
    FormNewTournament_Settings_Playoff_Content_1: 'Your tournament will have',
    FormNewTournament_Settings_Playoff_Content_2: 'teams',
    FormNewTournament_Settings_Playoff_Content_3: 'with',
    FormNewTournament_Settings_Playoff_Content_4: 'groups of 4 teams where the first 2 teams qualify for the final stage.',
    FormNewTournament_Dates_Title: 'Dates',
    FormNewTournament_Location_Title: 'Main location',
    FormNewTournament_Button_New_Tournament: 'Create Tournament',
    // FORMEDITTOURNAMENT
    FormEditTournament_Title: 'Edit Tournament',
    // TOURNAMENTS COMPONENT
    Tournaments_No_Tournaments_Found: 'No tournaments found, please try again',
    Tournaments_No_Tournament_In: 'No tournaments in',
    Tournaments_No_Tournament_Yet: 'yet',
    Tournaments_Meta_Title: 'Tournaments',
    Tournaments_Candidate: 'candidate',
    Tournaments_Candidates: 'candidates',
    // TOURNAMENT COMPONENT
    Tournament_Meta_Title: 'Tournament',
    Tournament_Not_Found: 'Tournament not found',
    Tournament_Not_Found_Caption: 'We are sorry but it seems like this tournament is not available, please try to double check the spelling of the name.',
    Tournament_Button_Edit: 'Edit tournament',
    Tournament_Button_New_Sponsorship: 'New sponsorship',
    Tournament_Team_Candidates: 'Team candidates',
    Tournament_Team_Selected: 'Team selected',
    Tournament_Register_Unregister: 'Register/Unregister your team',
    Tournament_Select_Unselect: 'Select/Unselect a team',
    Tournament_Validate_Teams: 'Validate teams selection',
    Tournament_Confirm_Teams: 'Do you confim the selected teams?',
    Tournament_Confirm_Teams_Yes: 'Yes',
    Tournament_Confirm_Teams_No: 'No',
    Tournament_Kick_Off_Group_Title: 'Kick-off group games tournament',
    Tournament_Kick_Off_Group_Caption_1: 'Do you confim the kick-off group games of your tournament?',
    Tournament_Kick_Off_Group_Caption_2: 'This action is not reversible, so make sure you have schedule ALL GAMES',
    Tournament_Kick_Off_Group_Button_Go: 'Lets go',
    Tournament_Kick_Off_Group_Button_Wait: 'No, wait',
    Tournament_Kick_Off_Finals_Title: 'Kick-off finals games tournament',
    Tournament_Kick_Off_Finals_Caption_1: 'Do you confim the kick-off finals games of your tournament?',
    Tournament_Kick_Off_Finals_Caption_2: 'This action is not reversible, so make sure you added ALL GAMES SCORES',
    Tournament_Kick_Off_Finals_Button_Go: 'Lets go',
    Tournament_Kick_Off_Finals_Button_Wait: 'No, wait',
    Tournament_Cost_Per_Team: 'Team',
    Tournament_Trophies_Title: 'Trophies',
    Tournament_Trophies_Assign: 'Assign trohy',
    Tournament_Overview_Tab: 'Overview',
    Tournament_Boards_Tab: 'Boards',
    Tournament_Sponsors_Tab: 'Sponsors',
    Tournament_Sponsor_For: 'Sponsor for',
    Tournament_Button_Schedule_Game: 'Schedule game',
    Tournament_Button_No_Schedule_Game: 'No game scheduled',

    ViewTournamentPlayoffGameModal_Button_Add_Score: 'Add final score',
    ViewTournamentPlayoffGameModal_Score_Added: 'Score added',
    ViewTournamentPlayoffGameModal_Game_Result: 'Game results',
    ViewTournamentPlayoffGameModal_Private: 'This is a private event',

    // TOURNAMENTPLAYOFF COMPONENT
    TournamentPlayoff_Click_Schedule: 'click to schedule',
    TournamentPlayoff_Team: 'Team',
    TournamentPlayoff_Match: 'Match',
    // TOURNAMENTGROUP COMPONENT
    Tournament_Groups_No_Games_Yet: 'No games scheduled yet',
    Tournament_Groups_No_Teams: 'No teams',
    Tournament_Groups_Button_Schedule: 'Schedule game',
    Tournament_Groups_Button_Add_Team: 'Add team',
    Tournament_Groups_Games: "'s games",

    // INPUTTROPHIESFEATURE COMPONENT
    InputTrophiesFeature_Rank_Label: 'Trophy rank',
    InputTrophiesFeature_Name_Label: "Trophy's name",
    InputTrophiesFeature_Name_Placeholder: '1st place of FZ World Tournament',
    InputTrophiesFeature_Name_Helper_1: 'Name must have at least 4 characters',
    InputTrophiesFeature_Name_Helper_2: 'You should ONLY use letters, numbers or underscore',
    InputTrophiesFeature_Note_Label: "Trophy's note",
    InputTrophiesFeature_Note_Placeholder: 'An trophy given by sponsors X Y Z',
    InputTrophiesFeature_Add_Trophy: 'Add trophy',
    // GPSMAPFEATURE COMPONENT
    GPSMapFeature_1: 'Please locate the address above on the map',
    GPSMapFeature_2: 'Click on the marker to move it, and be as accurate as possible!',
    // CHALLENGEFILTERSMODAL COMPONENT
    ChallengeFiltersModal_When_Title: 'When',
    ChallengeFiltersModal_Where_Title: 'Where',
    ChallengeFiltersModal_No_Complex_Selected: 'No complex selected',
    ChallengeFiltersModal_Field_Label: 'Field',
    ChallengeFiltersModal_Search_Complex_Label: 'Search Complex',
    ChallengeFiltersModal_Search_Complex_Placeholder: 'Write a complex name',
    ChallengeFiltersModal_Button_Results: 'See results',
    // SETTINGS COMPONENT
    Settings_Title: 'Settings',
    Settings_Account: 'Account',
    Settings_Wallets: 'Wallets',
    Settings_Languages: 'Languages',
    Settings_Helpdesk: 'Helpdesk',
    Settings_Legal_Mentions: 'Legal mentions',
    Settings_Logout: 'Logout',
    Settings_Logging_Out: 'Logging out...',
    Settings_Blocked_Accounts: 'Blocked accounts',
    Settings_Accounts_Title: 'Account',
    Settings_Contact_Us_Title: 'Contact us',
    Settings_Contact_Us_Content: `
            At Fivezer, we are here to assist you with any questions, concerns, or feedback you may have related to our sports community platform. You can reach out to us via email, and our team will respond promptly to your inquiries.

            Customer Support Email:
            For customer support, including account-related issues, technical assistance, or general inquiries, please email us at:
            Email: contact@thebhub.io
            
            Business Inquiries Email:
            If you have business-related inquiries, partnership opportunities, or collaboration proposals related to Fivezer, please contact our business development team at:
            Email: contact@thebhub.io
            
            General Inquiries Email:
            For general questions about Fivezer, our platform, or getting started as a sports enthusiast or team, you can email our general inquiries team at:
            Email: contact@thebhub.io
            
            Feedback and Suggestions Email:
            We value your feedback and suggestions for improving the Fivezer experience. If you have ideas or recommendations, please share them with us at:
            Email: contact@thebhub.io
            
            Response Time:
            We strive to respond to your emails as quickly as possible. Our dedicated teams are here to provide you with the support and information you need to make the most of your Fivezer experience.
            
            Thank you for choosing Fivezer as your go-to destination in the world of international sports and competition. We look forward to assisting you via email.
          `,
    Settings_Legal_Mentions_Title: 'Legal Mentions',

    // FORMNEWFOOTBALLER
    FormNewFootballer_Title: 'New Footballer',
    FormNewFootballer_Existing_Footballer: 'You already have a footballer profile',
    FormNewFootballer_See_Existing_Footballer: 'See profile',
    FormNewFootballer_Alias_Label: 'Footballer name',
    FormNewFootballer_Alias_Placeholder: 'Pelé',
    FormNewFootballer_Nickname_Label: 'Nickname',
    FormNewFootballer_Nickname_Placeholder: 'O Rei',
    FormNewFootballer_Bio_Title: 'Bio (multi-languages)',
    FormNewFootballer_Which_Area: 'Which area do you represent?',
    FormNewFootballer_Area_Title_Label: "Area's name",
    FormNewFootballer_Area_Title_Placeholder: 'São Paulo',
    FormNewFootballer_Position_Title: 'Position',
    FormNewFootballer_Jersey_Title: 'Jersey number',
    FormNewFootballer_Foots_Title: 'Prefered foot',
    FormNewFootballer_Button_Create: 'Create Footballer',
    // FORMEDITFOOTBALLER
    FormEditFootballer_Title: 'Edit Footballer',
    // FORMEDITPROFILE
    FormEditProfile_Title: 'Edit Profile',
    FormEditProfile_Basic_Title: 'Basic Information',
    FormEditProfile_Username_Label: 'Username',
    FormEditProfile_Username_1: 'is taken already',
    FormEditProfile_Username_2: 'Only letters, numbers and _ are allowed',
    FormEditProfile_Email_Label: 'Email',
    FormEditProfile_Email_1: 'is already used on another account',
    FormEditProfile_Firstname_Label: 'Firstname',
    FormEditProfile_Lastname_Label: 'Lastname',
    FormEditProfile_DOB_Label: 'Date of birth',
    FormEditProfile_Bio_Label: 'Bio',
    FormEditProfile_Bio_1: 'You must remove',
    FormEditProfile_Bio_2: 'characters',
    FormEditProfile_Bio_3: 'character',
    FormEditProfile_Mobile_Label: 'Mobile',
    FormEditProfile_Mobile_1: 'Verify PIN Code',
    FormEditProfile_Mobile_2: 'Mobile successfully verified!',
    FormEditProfile_Passwords_Title: 'Passwords',
    FormEditProfile_Passwords_Label: 'New Password',
    FormEditProfile_Passwords_1: 'Write a new account password',
    FormEditProfile_Passwords_2: 'New spending password',
    FormEditProfile_Passwords_3: 'Spending Password updated!',
    FormEditProfile_KYC_Title: 'Personal Identity Verification',
    FormEditProfile_ID_Type_Label: 'ID Type',
    FormEditProfile_ID_Number_Label: 'ID Number',
    FormEditProfile_New_KYC_Title: 'Make a new ID Verification?',
    FormEditProfile_New_KYC_Label_1: 'Yes!',
    FormEditProfile_New_KYC_Label_2: 'No!',
    FormEditProfile_New_KYC_Exp_Label: 'ID Expiration',
    FormEditProfile_New_KYC_Issuer_Country_Label: 'ID issuer country',
    FormEditProfile_New_KYC_Issuer_Label: 'Authority issuer',
    FormEditProfile_New_KYC_Issuer_Placeholder: "Input authority issuer's name",
    FormEditProfile_Preferenced_Sport_Title: 'Prefered sports',
    FormEditProfile_Preferenced_Sport_Label: 'Sports',
    FormEditProfile_Preferenced_Sport_1: 'No sport selected',
    FormEditProfile_Locations_Title: 'Locations',
    FormEditProfile_Button_Add_Location: 'Add address',
    // TOURNAMENTS
    Tournaments_New_Tournaments_Tab: 'Upcoming tournaments',
    Tournaments_Old_Tournaments_Tab: 'Past tournaments',
    // FORMNEWCHALLENGE COMPONENT
    FormNewChallenge_Title: 'New challenge',
    FormNewChallenge_No_Team_Caption_1: "Friendly or not, ONLY team's captain(s) can create a challenge.",
    FormNewChallenge_No_Team_Caption_2: 'So promote a captain in your team and come back',
    FormNewChallenge_No_Team_Caption_3: "And don't worry, even if you are alone, there are tons of players around that will join you!",
    FormNewChallenge_No_Team_Button_New_Team: 'Create your team now',
    FormNewChallenge_Challenger_Label_1: 'As',
    FormNewChallenge_Challenger_Label_2: 'who?',
    FormNewChallenge_Challenger_Placeholder: 'Select your team',
    FormNewChallenge_Category_Title: 'Category',
    FormNewChallenge_Category_Caption_League: 'Ranked game is simple, its you against them, winners gets the points and losers gets nutmegs',
    FormNewChallenge_Category_Caption_Friendly: 'Friendly game is just a game to have fun, no pressure, no points, just fun and nutmegs',
    FormNewChallenge_Category_Caption_Matchmaker: 'Matchmaker is a friendly game that summons mercenaries players to fill in your teams and play together, cool way to make new friends',
    FormNewChallenge_Name_Label: "Challenge's name",
    FormNewChallenge_Name_Placeholder: 'Your team',
    FormNewChallenge_Name_1: 'Name must have at least 4 characters',
    FormNewChallenge_Name_2: 'You should ONLY use letters, numbers or underscore',
    FormNewChallenge_Your_Lineup_Title: 'Your Lineup',
    FormNewChallenge_Your_Lineup_Label_1: 'Make a new lineup',
    FormNewChallenge_Your_Lineup_Label_2: 'Select an existing lineup',
    FormNewChallenge_Team_Selected: 'No teammate selected',
    FormNewChallenge_New_Lineup_Label: 'Compose your new lineup',
    FormNewChallenge_New_Lineup_Placeholder: 'Write a team alias',
    FormNewChallenge_Edit_Lineup_Label: 'Existing lineups',
    FormNewChallenge_Edit_Lineup_Placeholder: 'Select a field',
    FormNewChallenge_Against_Title: 'Against ?',
    FormNewChallenge_Against_Label: 'No opponent selected',
    FormNewChallenge_Opponent_Title: 'Opponent',
    FormNewChallenge_Opponent_Placeholder: 'Write a team name or ID',
    FormNewChallenge_Privacy_Title: 'Privacy ?',
    FormNewChallenge_Privacy_Caption_1: 'Yes, it is private 🤐',
    FormNewChallenge_Privacy_Caption_2: 'No, its open to the public 🤗',
    FormNewChallenge_How_Long_Title: 'How long ?',
    FormNewChallenge_How_Long_Hour: 'hour',
    FormNewChallenge_How_Long_Hours: 'hours',
    FormNewChallenge_When_Title: 'When ?',
    FormNewChallenge_Recurring_Label_1: 'Schedule it multiple times 🖐',
    FormNewChallenge_Recurring_Label_2: 'Schedule it once 👆',
    FormNewChallenge_Recurring_Label_3: 'Every',
    FormNewChallenge_Start_Date_1: 'First day (start)',
    FormNewChallenge_Start_Date_2: 'Start Date',
    FormNewChallenge_End_Date_1: 'First day (end)',
    FormNewChallenge_End_Date_2: 'End Date',
    FormNewChallenge_Last_Day: 'Last Day',
    FormNewChallenge_Last_Day_Label_1: 'You want to play',
    FormNewChallenge_Last_Day_Label_2: 'times',
    FormNewChallenge_Last_Day_Label_3: 'time',
    FormNewChallenge_Last_Day_Label_4: 'per week until',
    FormNewChallenge_Where_Title: 'Where ?',
    FormNewChallenge_Complexes_Label: 'Search Complex',
    FormNewChallenge_Complexes_Placeholder: 'Write a complex name',
    FormNewChallenge_Complexes_No_Selected: 'No complex selected',
    FormNewChallenge_Field_Label: 'Field',
    FormNewChallenge_Field_Placeholder: 'Select a field',
    FormNewChallenge_Fields_Already_Booked: 'ALREADY BOOKED',
    FormNewChallenge_Fields_Current_Booking: 'CURRENT BOOKING',
    FormNewChallenge_Fields_Hour: 'hour',
    FormNewChallenge_Fields_Free: 'FREE',
    FormNewChallenge_Fields_Manual_Booking: 'Manual booking',
    FormNewChallenge_Fields_Manual_Booking_Caption: 'is not register on Fivezer so you must call them to book your session.',
    FormNewChallenge_Button_Create: 'Create challenge',
    // FORMEDITCHALLENGE COMPONENT
    FormEditChallenge_Title: 'Edit challenge',
    // CHALLENGE COMPONENT
    Challenge_Meta_Title: 'Challenge',
    Challenge_Payment_Caption_1: "You haven't pay your seat in this challenge yet",
    Challenge_Button_Accept: 'Accept',
    Challenge_Button_Decline: 'Decline',
    Challenge_Accept_Challenge: 'Do you accept this challenge?',
    Challenge_Button_Edit: 'Edit Challenge',
    Challenge_Private_Event: 'This is a private event',
    Challenge_Button_Results: 'Game results',
    Challenge_Group_Order_Label: 'Group order',
    Challenge_Button_Retry: 'retry payment',
    Challenge_Personal_Order_Label: 'Personal order',
    Challenge_Partcially_Paid_1: "Waiting for opponent's confirmation",
    Challenge_Partcially_Paid_2: 'Add final score',
    Challenge_Button_Rematch: 'Rematch',
    // CHALLENGES COMPONENT
    Challenges_Meta_Title: 'Challenges',
    Challenges_Tab_Challenges: 'Challenges',
    Challenges_Tab_Opponents: 'Opponents',
    Challenges_No_Challenges_Found: 'No challenges found, please try again',
    Challenges_No_Challenges_In: 'No challenges in',
    Challenges_No_Challenges_Yet: 'yet',
    // FIELD COMPONENT
    Field_Meta_Title: 'Field',
    Field_Not_Found: 'Field not found',
    Field_Not_Found_Caption: 'We are sorry but it seems like this field is not available, please try to double check the spelling of the name.',
    Field_Button_Edit: 'Edit field',
    Field_Sport_Type_Title: 'Sport',
    Field_Type_Label: 'Type',
    Field_Size_Label: 'Size',
    Field_Cost_Label: 'Cost',
    Field_Equipments_Title: 'Equipments',
    Field_No_Equipments: 'No equipments',
    Field_No_Pictures: 'No pictures',
    // FORMNEWFIELD MODAL
    FormNewField_Title: 'New field',
    FormNewField_Location: 'Location',
    FormNewField_Descriptions: 'Descriptions (multi-languages)',
    FormNewField_Button_Add_Descriptions: 'Add description',
    FormNewField_No_Description: 'No description',
    FormNewField_Pictures_Title: 'Pictures',
    FormNewField_Name_Label: 'Name',
    FormNewField_Name_Placeholder: "Write field's name",
    FormNewField_Button_Create: 'Add field',
    // FORMEDITFIELD MODAL
    FormEditField_Title: 'Edit field',
    // REQUESTMATCHMAKING COMPONENT
    RequestMatchMaking_Meta_Title: 'Matchmaking',
    RequestMatchMaking_Not_Found_1: 'Matchmaking',
    RequestMatchMaking_Not_Found_2: 'not found',
    RequestMatchMaking_Not_Found_3: 'We are sorry but it seems like this request is not available, please try to double check the spelling of the name or use our',
    RequestMatchMaking_Not_Found_4: 'search',
    RequestMatchMaking_Not_Found_5: 'engine.',
    RequestMatchMaking_Type_Label: 'Type',
    RequestMatchMaking_Button_Leave_Game: 'Leave game',
    RequestMatchMaking_Button_Join_Game: 'Join game',
    RequestMatchMaking_Players: 'players',
    RequestMatchMaking_Player: 'player',
    RequestMatchMaking_Joined_Players: 'have joined',
    RequestMatchMaking_Joined_Player: 'has rejoined',
    // MANAGESCORE MODAL
    ManageScore_Title: 'Final results',
    ManageScore_Intro_1: 'Now that your challenge is done, you can add the scores and also the statistics of your players.',
    ManageScore_Intro_2: 'As a reminder, both captain must confirm the score to validate the statistics.',
    ManageScore_BG_Title_1: 'Final',
    ManageScore_BG_Title_2: 'Results',
    ManageScore_Button_Add_Scores: 'Add scores',
    ManageScore_Button_Unconfirm: 'Unconfirm',
    ManageScore_Button_Confirm: 'Confirm final score',
    ManageScore_Button_Save_Continue: 'Save & continue later',
    ManageScore_Button_Continue: 'Continue',
    ManageScore_Confirm_Score_Content: 'Do you confim the final result of the challenge?',
    ManageScore_Confirm_Score_Button_Yes: 'Yes',
    ManageScore_Confirm_Score_Button_No: 'No',
    ManageScore_Added_Score_Info_1: 'You have added all the goals and assists of',
    ManageScore_Added_Score_Info_2: 'Now you can either confirm the results now',
    ManageScore_Added_Score_Info_3: 'or',
    ManageScore_Added_Score_Info_4: 'you can save and continue later.',
    ManageScore_Scorer_Label: 'Scorer',
    ManageScore_NB_Scorer_Label_1: 'Goals scored by',
    ManageScore_NB_Scorer_Label_2: 'player',
    ManageScore_Selected_Passer_Label_1: 'A player',
    ManageScore_Selected_Passer_Label_2: 'goals',
    ManageScore_Selected_Passer_Label_3: 'goal',
    ManageScore_Selected_Passer_Label_4: '& received',
    ManageScore_Selected_Passer_Label_5: 'assists',
    ManageScore_Selected_Passer_Label_6: 'assist',
    ManageScore_Selected_Scorer_Caption_1: 'You can all the assists',
    ManageScore_Selected_Scorer_Caption_2: 'a player',
    ManageScore_Selected_Scorer_Caption_3: 'received',
    ManageScore_Selected_Scorer_Label: 'Passer',
    ManageScore_NB_Assist_Label_1: 'Assists by',
    ManageScore_NB_Assist_Label_2: 'player',
    ManageScore_Button_Confirm_Scorer: 'Confirm scorer',
    ManageScore_Button_Add_Assist: 'Add assist',
    ManageScore_MOTM_Caption_1: 'Men Of The Match',
    ManageScore_MOTM_Caption_2: 'Man of The Match',
    ManageScore_MOTM_Intro_1: 'If a player from',
    ManageScore_MOTM_Intro_2: 'the opposit team',
    ManageScore_MOTM_Intro_3: "impressed you, name him/her as 'Man Of The Match'.",
    ManageScore_MOTM_Intro_4: 'Your opponent will do the same for your team',
    ManageScore_Selected_MOTM_Caption_1: 'You named',
    ManageScore_Selected_MOTM_Label: 'Man Of The Match',
    ManageScore_No_MOTM: 'NOBODY',
    // SWITCHLANGUAGEBUTTON COMPONENT
    SwitchLanguageButton_Title: 'Select a language',
    SwitchLanguageButton_Current_Lang: 'Current language',
    SwitchLanguageButton_Button_Title: 'Languages',
    // NEWCONVERSATION MODAL
    New_Conversation_Title: 'New conversation',
    New_Conversation_Title_Label: 'Title',
    New_Conversation_Description_Label: 'Topic (optional)',
    New_Conversation_Description_Placeholder: "What's the topic(s)/purpose(s) of the conversation",
    New_Conversation_Description_Helper_1: 'Note must have at least 0 characters',
    New_Conversation_Description_Helper_2: 'There is an unauthorized character in',
    New_Conversation_Author_Label: 'Join as',
    New_Conversation_Participants_Label: 'Participants',
    New_Conversation_Participants_Placeholder: 'Select a participant',
    New_Conversation_No_Participants: 'No participant selected',
    New_Conversation_Create: 'Create conversation',
    // CONVERSATIONS COMPONENT
    Conversations_Meta_Title: 'Conversations',
    Conversations_Conversations_Title: 'Conversations',
    Conversations_Conversation_Title: 'Conversation',
    Conversations_Button_New_Conversation: 'New conversation',
    Conversations_Button_Conversations: 'Conversations',
    Conversations_Button_Add_Contact: 'Add contact',
    Conversations_Button_Contacts: 'Contacts',
    Conversations_Start_Now_Title: 'Start a conversation now!',
    Conversations_Disabled_1: 'This feature is disabled',
    Conversations_Disabled_2: 'Coming back soon',
    // CONVERSATION COMPONENT
    Conversation_Sender_As: 'As',
    Conversation_Button_Edit: 'Edit conversation',
    Conversation_First_Messager: 'Be the first to write a message',
    Conversation_Loding_Messages_1: 'Loading...',
    Conversation_Loding_Messages_2: 'Load previous messages',
    Conversation_Message_Removed: 'Message removed',
    Conversation_Message_Edited: '(edited)',
    Conversation_Content_Placeholder: 'Write a message...',
    // NEWS COMPONENT
    News_Meta_Title: 'Latest news and actualities',
    News_No_News_Title: 'No news',
    // MAKEPAYMENT MODAL
    MakePayment_Transactions: 'Transactions',
    MakePayment_Transaction: 'Transaction',
    MakePayment_To_Sign: 'to sign',
    MakePayment_Wallets: 'wallets',
    MakePayment_Wallet: 'wallet',
    MakePayment_Need_PM: 'You need a payment method to use this feature',
    MakePayment_Create_Wallet: 'Create your wallet',
    MakePayment_Need_Wallet_1: 'You need a wallet',
    MakePayment_Need_Wallet_2: 'Yand a payment method',
    MakePayment_Need_Wallet_3: 'to use this feature',
    // SCANQRCODE FEATURE
    ScanQRCode_Scanned: 'Scanned!',
    ScanQRCode_Scan_QRCode: 'Scan a QRCode',
    ScanQRCode_Scan_Off: 'Scanner currently not available',
    // SHOWQRCODE FEATURE
    ShowQRCode_Source: 'Payment source',
    // WALLETS COMPONENT
    Wallets_Meta_Title: 'Wallets',
    Wallets_SC_Updated: 'Spending code updated!',
    Wallets_How_To: 'How to use my wallets?',
    Wallets_Edit_SC: 'Change spending code',
    Wallets_New_Wallet: 'New wallet',
    Wallets_Exchanges: 'Exchanges',
    Wallets_Wallets_Title: 'wallets',
    Wallets_Wallet_Title: 'wallet',
    Wallets_Own_By: 'own by',
    Wallets_Tab_Personal: 'Personal',
    Wallets_NB_Payment_Method: 'Payment method',
    // WALLET COMPONENT
    Wallet_Meta_Title: 'Wallet',
    Wallet_Meta_Recommened: '(highly recommended)',
    Wallet_How_To: 'How to use my wallet?',
    Wallet_Edit_Button: 'Edit wallet',
    Wallet_Delete_Button: 'Delete wallet',
    Wallet_Lock_Button: 'Temporarily lock wallet',
    Wallet_Own_By: 'own by',
    Wallet_Default: '(default)',
    Wallet_Lipay_Info: 'We highly recommend creating a Lipay account, as it offers cost-effective worldwide payment options and additional features.',
    Wallet_Unavailable_Title: 'Temporarily unavailable',
    Wallet_Unavailable_Label: 'Your wallet is unavailable, please wait until you receive an email/notification from us.',
    Wallet_KYC_Wait_Title: 'KYC Verification',
    Wallet_KYC_Wait_Label_1: 'Your wallet is under our approval process,',
    Wallet_KYC_Wait_Label_2: 'Please wait until you receive an email/notification from us.',
    Wallet_KYC_Wait_Label_3: 'created',
    Wallet_KYC_Failed_Title: 'KYC Verification failed',
    Wallet_KYC_Failed_Label_1: "Your wallet's verification came back unsuccessful,",
    Wallet_KYC_Failed_Label_2: 'Please verify your information before performing a new verification. If your verification fail more than 3 times, please reach out to us via contact@thebhub.io.',
    Wallet_Button_New_KYC: 'New wallet verification',
    Wallet_New_KYC_Title: "'s new verification",
    Wallet_New_BC: 'Add bank card',
    Wallet_New_BA: 'New Lipay account',
    Wallet_New_PM: 'New payment method',
    // PAYMENTMETHOD COMPONENT
    PaymentMethod_Meta_Title: 'Payment method',
    PaymentMethod_All_Tx: 'All TXs',
    PaymentMethod_Tx_Me: 'Tx By me',
    PaymentMethod_Tx_Others: 'Tx By others',
    PaymentMethod_Lock: 'Temporarily lock payment method',
    PaymentMethod_Lock_Label: 'Do you really want to lock',
    PaymentMethod_Unlock: 'Unlock payment method',
    PaymentMethod_Unlock_Label: 'Do you really want to unlock',
    PaymentMethod_Delete: 'Remove payment method',
    PaymentMethod_Select_Default: 'Select as default',
    PaymentMethod_Copied: 'copied!',
    PaymentMethod_Title: '(Payment Method)',
    PaymentMethod_Default: '(default payment method)',
    PaymentMethod_Button_Unlock: 'Unlock',
    PaymentMethod_Button_Lock: 'Lock',
    PaymentMethod_Is_Lock: 'is locked.',
    PaymentMethod_Is_Unlock: 'is unlocked.',
    // ALLTRANSACTIONS COMPONENT
    AllTransactions_Received: 'received',
    AllTransactions_Sent: 'sent',
    AllTransactions_No_Tx: 'No transactions',
    // JOINTEAM PAGE
    Join_Team_Meta_Title: 'Join a team',
    Join_Team_Title: 'Join a team',
    Join_Team_Button_Join: 'Join',
    Join_Team_Button_Sent: 'request sent',
    Join_Team_Button_New_Team: 'New Team',
    // VIEWTEAMLINEUP MODAL
    ViewTeamLineupModal_Request_Sent: 'Request sent!',
    ViewTeamLineupModal_Request_Already_Sent: "You've already sent a request",
    ViewTeamLineupModal_Button_Join_Team: 'Join the team',
    ViewTeamLineupModal_Button_Sending_Request: 'Sending request...',
    // ADDTEAMLINEUP MODAL
    AddTeamLineupModal_Title: 'New team lineup',
    AddTeamLineupModal_Name_Label: 'Name',
    AddTeamLineupModal_No_Teammate_Selected: 'No teammate selected',
    AddTeamLineupModal_Players_Label: 'Compose your new lineup',
    AddTeamLineupModal_Players_Placeholder: 'Write a team alias',
    AddTeamLineupModal_Captains_Label: 'Select your captain(s)',
    AddTeamLineupModal_Captains_Placeholder: 'Write a player alias',
    AddTeamLineupModal_Button_Create: 'Create lineup',
    // ADDTEAMPLAYER MODAL
    AddTeamPlayerModal_Title: 'Add New Team Player',
    AddTeamPlayerModal_Name_Label: 'Name',
    AddTeamPlayerModal_No_Player_Selected: 'No player selected',
    AddTeamPlayerModal_Players_Label: 'Players',
    AddTeamPlayerModal_Players_Placeholder: 'Write a player alias',
    AddTeamPlayerModal_Button_Create: 'Add Player',
    // LISTCONVERSATIONSMESSENGER COMPONENT
    ListConversationsMessenger_All_Contacts_Label: 'Contacts',
    ListConversationsMessenger_All_Contacts_Placeholder: 'Write a name',
    ListConversationsMessenger_Search_Conversations_Placeholder: 'Search in all conversations',
    ListConversationsMessenger_No_Conversations: 'No conversations',
    // NEWCONVERSATIONMESSENGER COMPONENT
    NewConversationMessenger_Title: 'New conversation',
    NewConversationMessenger_Title_Label: 'Title',
    NewConversationMessenger_Description_Label: 'Topic (optional)',
    NewConversationMessenger_Description_Placeholder: "What's the topic(s)/purpose(s) of the conversation",
    NewConversationMessenger_Description_Helper_1: 'Note must have at least 0 characters',
    NewConversationMessenger_Description_Helper_2: 'There is an unauthorized character in',
    NewConversationMessenger_Author_Label: 'Join as',
    NewConversationMessenger_All_Participants_Label: 'Participants',
    NewConversationMessenger_All_Participants_Placeholder: 'Select a participant',
    NewConversationMessenger_No_Participants: 'No participant selected',
    NewConversationMessenger_Button_View_Conversation: 'View conversation existing',
    NewConversationMessenger_Button_Start_Conversation: 'Start conversation',
    // CONVERSATIONMESSENGER COMPONENT
    ConversationMessenger_Sender_As: 'As',
    ConversationMessenger_Edited_Msg: '(edited)',
    ConversationMessenger_Removed_Msg: 'Message removed',
    ConversationMessenger_Write_Msg: 'Write a message...',
    // ADDNEWTEAMMANAGER MODAL
    AddTeamManagerModal_Title: 'Add manager',
    AddTeamManagerModal_No_Manager_Selected: 'No manager selected',
    AddTeamManagerModal_Players_Label: 'Managers',
    AddTeamManagerModal_Players_Placeholder: 'Write a player username',
    AddTeamManagerModal_Button_Create: 'Add manager',
    // TEAMS PAGE
    Teams_Meta_Title: 'Teams',
    Teams_New_Team: 'Create your team',
    Teams_Button_New_Team: 'New team',
    Teams_Verify_Email: 'To create a team you must verify your email',
    // NEWFLEX MODAL
    NewFlexModal_Button_Create: 'Flex now',
    NewFlexModal_No_Flexs: 'No flexs',
    NewFlexModal_Caption_Label: 'Caption',
    NewFlexModal_Caption_Helper_1: 'Your content must be',
    NewFlexModal_Flexer_Label: 'Flex as',
    NewFlexModal_CoFlexer_Label: 'Co-publishers',
    NewFlexModal_CoFlexer_Placeholder: 'Write a username',
    NewFlexModal_Flexed_Item_Label: 'Flex items',
    NewFlexModal_Flexed_Item_Placeholder: "Write an item's name",
    // FLEX COMPONENT
    Flex_Meta_Title: '',
    Flex_Not_Found_1: 'Flex',
    Flex_Not_Found_2: 'not found',
    Flex_Not_Found_3: 'We are sorry but it seems like this flex is not available, please try to double check the spelling of the name or use our',
    Flex_Not_Found_4: 'search',
    Flex_Not_Found_5: 'engine.',
    Flex_Not_Found_6: 'Flex',
    Flex_Not_Found_7: 'coming soon',
    Flex_Not_Found_8: 'A bit more patience, they are finalizing detail but in the meantime you check the latest news on the',
    Flex_Not_Found_9: 'Home',
    Flex_Not_Found_10: 'page.',
    Flex_Button_Edit: 'Edit flex',
    Flex_Button_Accept: 'Accept',
    Flex_Waiting_Confirmation_1: 'Waiting confirmation from',
    Flex_Waiting_Confirmation_2: 'to make your flex visible to the public.',
    Flex_Share_Title: 'Fivezer Flex',
    Flex_Share_Text: 'Find more flex on',
    Flex_Flexer_As: 'As',
    Flex_Likes: 'likes',
    Flex_Like: 'like',
    Flex_Comment_Placeholder: 'Write a comment...',
    Flex_First_Comment: 'Be the first to write a comment',

    // UTILITIES
    Utilities_Affirmation: 'Affirmation',
    Utilities_Affirmation_1: "I'm satisfied with my purchase!",
    Utilities_Affirmation_2: 'Great experience overall.',
    Utilities_Affirmation_3: 'Thank you for your excellent service!',

    Utilities_Anger: 'Anger',
    Utilities_Anger_1: "I'm frustrated with the service.",
    Utilities_Anger_2: 'Disappointed with my experience.',
    Utilities_Anger_3: 'Unhappy with the product quality.',

    Utilities_Anxiety: 'Anxiety',
    Utilities_Anxiety_1: 'Feeling anxious about the purchase.',
    Utilities_Anxiety_2: 'Concerned about the service.',
    Utilities_Anxiety_3: 'Anxiety due to a bad experience.',

    Utilities_Blushing: 'Blushing',
    Utilities_Blushing_1: 'Feeling embarrassed about the purchase.',
    Utilities_Blushing_2: 'Blushing due to an awkward situation.',
    Utilities_Blushing_3: 'Embarrassed about the service received.',

    Utilities_Contempt: 'Contempt',
    Utilities_Contempt_1: 'Feeling contemptuous about the purchase.',
    Utilities_Contempt_2: 'Displeased with the service.',
    Utilities_Contempt_3: 'Contempt towards the product quality.',

    Utilities_Despair: 'Despair',
    Utilities_Despair_1: 'Feeling hopeless about the purchase.',
    Utilities_Despair_2: 'Despair due to a bad experience.',
    Utilities_Despair_3: 'Hopeless about the service received.',

    Utilities_Determination: 'Determination',
    Utilities_Determination_1: 'Determined to resolve an issue.',
    Utilities_Determination_2: 'Motivated to overcome challenges.',
    Utilities_Determination_3: 'Firm resolve to improve the service.',

    Utilities_Devotion: 'Devotion',
    Utilities_Devotion_1: 'Devoted to the brand and its products.',
    Utilities_Devotion_2: 'Loyal and committed to continued support.',
    Utilities_Devotion_3: 'Dedicated to a positive customer experience.',

    Utilities_Disdain: 'Disdain',
    Utilities_Disdain_1: 'Feeling disdainful about the purchase.',
    Utilities_Disdain_2: 'Disdain towards the service provided.',
    Utilities_Disdain_3: 'Displeased with the overall experience.',

    Utilities_Disgust: 'Disgust',
    Utilities_Disgust_1: 'Feeling disgusted with the purchase.',
    Utilities_Disgust_2: 'Disgust towards the service received.',
    Utilities_Disgust_3: 'Unpleasantly surprised by the product quality.',

    Utilities_Fear: 'Fear',
    Utilities_Fear_1: 'Fearful about the purchase outcome.',
    Utilities_Fear_2: 'Anxious about the service quality.',
    Utilities_Fear_3: 'Fear of facing issues with the product.',

    Utilities_Grief: 'Grief',
    Utilities_Grief_1: 'Feeling grief about the purchase.',
    Utilities_Grief_2: 'Saddened by a disappointing experience.',
    Utilities_Grief_3: 'Grieving the poor service received.',

    Utilities_Guilt: 'Guilt',
    Utilities_Guilt_1: 'Feeling guilty about the purchase decision.',
    Utilities_Guilt_2: 'Regretful about the service chosen.',
    Utilities_Guilt_3: 'Guilt about the negative impact on experience.',

    Utilities_Hatred: 'Hatred',
    Utilities_Hatred_1: 'Feeling intense hatred about the purchase.',
    Utilities_Hatred_2: 'Hatred towards the service provider.',
    Utilities_Hatred_3: 'Strong aversion to the overall experience.',

    Utilities_Helplessness: 'Helplessness',
    Utilities_Helplessness_1: 'Feeling helpless about the purchase situation.',
    Utilities_Helplessness_2: 'Helpless in dealing with the service issues.',
    Utilities_Helplessness_3: 'Overwhelmed by a sense of helplessness.',

    Utilities_HighSpirits: 'High Spirits',
    Utilities_HighSpirits_1: 'In high spirits due to the positive purchase.',
    Utilities_HighSpirits_2: 'Energetic and enthusiastic about the service.',
    Utilities_HighSpirits_3: 'Joyful and excited about the overall experience.',

    Utilities_IllTemper: 'Ill-temper',
    Utilities_IllTemper_1: 'Feeling ill-tempered due to the purchase.',
    Utilities_IllTemper_2: 'Irritated by the service provided.',
    Utilities_IllTemper_3: 'In a bad mood due to the overall experience.',

    Utilities_Joy: 'Joy',
    Utilities_Joy_1: 'Experiencing joy and happiness with the purchase.',
    Utilities_Joy_2: 'Joyful and satisfied with the service.',
    Utilities_Joy_3: 'Happy and delighted with the overall experience.',

    Utilities_Love: 'Love',
    Utilities_Love_1: 'Feeling love and appreciation for the purchase.',
    Utilities_Love_2: 'Love and admiration for the service received.',
    Utilities_Love_3: 'Deep affection and fondness for the overall experience.',

    Utilities_LowSpirits: 'Low Spirits',
    Utilities_LowSpirits_1: 'Feeling downhearted about the purchase.',
    Utilities_LowSpirits_2: 'Discouraged by the service quality.',
    Utilities_LowSpirits_3: 'Low spirits due to the overall experience.',

    Utilities_Mediation: 'Mediation',
    Utilities_Mediation_1: 'Open to mediation for resolving issues.',
    Utilities_Mediation_2: 'Willingness to discuss concerns through mediation.',
    Utilities_Mediation_3: 'Seeking a mediated solution for service problems.',

    Utilities_Modesty: 'Modesty',
    Utilities_Modesty_1: 'Modest satisfaction with the purchase.',
    Utilities_Modesty_2: 'Humble acknowledgment of the service.',

    Utilities_Modesty_3: 'Appreciating the experience with modesty.',

    Utilities_Negation: 'Negation',
    Utilities_Negation_1: 'Negating satisfaction with the purchase.',
    Utilities_Negation_2: 'Disagreeing with the service quality.',
    Utilities_Negation_3: 'Expressing negation about the overall experience.',

    Utilities_Neutral: 'Neutral',
    Utilities_Neutral_1: 'Feeling neutral about the purchase.',
    Utilities_Neutral_2: 'Having a neutral stance on the service.',
    Utilities_Neutral_3: 'No strong emotion towards the overall experience.',

    Utilities_Patience: 'Patience',
    Utilities_Patience_1: 'Exercising patience in dealing with issues.',
    Utilities_Patience_2: 'Patiently waiting for service resolution.',
    Utilities_Patience_3: 'Maintaining patience throughout the overall experience.',

    Utilities_Pride: 'Pride',
    Utilities_Pride_1: 'Proud of the purchase decision.',
    Utilities_Pride_2: 'Taking pride in the service received.',
    Utilities_Pride_3: 'Feeling a sense of pride about the overall experience.',

    Utilities_Reflection: 'Reflection',
    Utilities_Reflection_1: 'Reflecting on the purchase choices.',
    Utilities_Reflection_2: 'Engaging in self-reflection after the service.',
    Utilities_Reflection_3: 'Thoughtful reflection on the overall experience.',

    Utilities_SelfAttention: 'Self-attention',
    Utilities_SelfAttention_1: 'Giving self-attention to feedback and concerns.',
    Utilities_SelfAttention_2: 'Focusing on self-improvement after the service.',
    Utilities_SelfAttention_3: 'Valuing self-attention in the overall experience.',

    Utilities_Shyness: 'Shyness',
    Utilities_Shyness_1: 'Feeling shy about expressing feedback.',
    Utilities_Shyness_2: 'Shy but appreciative of the service.',
    Utilities_Shyness_3: 'Expressing feedback with a hint of shyness.',

    Utilities_Suffering: 'Suffering',
    Utilities_Suffering_1: 'Suffering due to the purchase experience.',
    Utilities_Suffering_2: 'Experiencing distress from the service received.',
    Utilities_Suffering_3: 'Enduring suffering throughout the overall experience.',

    Utilities_Sulkiness: 'Sulkiness',
    Utilities_Sulkiness_1: 'Feeling sulkiness about the purchase outcome.',
    Utilities_Sulkiness_2: 'Sulking due to dissatisfaction with the service.',
    Utilities_Sulkiness_3: 'Expressing sulkiness about the overall experience.',

    Utilities_Surprise: 'Surprise',
    Utilities_Surprise_1: 'Surprised by the unexpected aspects of the purchase.',
    Utilities_Surprise_2: 'Positive surprise with the service.',
    Utilities_Surprise_3: 'Expressing surprise about the overall positive experience.',

    Utilities_TenderFeelings: 'Tender Feelings',
    Utilities_TenderFeelings_1: 'Embracing tender feelings towards the purchase.',
    Utilities_TenderFeelings_2: 'Expressing delicate emotions about the service.',
    Utilities_TenderFeelings_3: 'Cherishing tender feelings in the overall experience.',

    Utilities_Weeping: 'Weeping',
    Utilities_Weeping_1: 'Feeling like weeping due to the purchase experience.',
    Utilities_Weeping_2: 'Tears shed over a disappointing service.',
    Utilities_Weeping_3: 'Expressing sorrow through weeping in the overall experience.',

    SelectTeamTournamentModal_Title: 'Select your team',
    RegistrationTournamentModal_Title: 'Select team lineup',
    RegistrationTournamentModal_Teams_Label: 'Existing teams',
    RegistrationTournamentModal_Teams_Label_2: 'registered',
    RegistrationTournamentModal_Button_1: 'Joining tournament...',
    RegistrationTournamentModal_Button_2: 'Register',
    RegistrationTournamentModal_Button_3: 'Leaving tournament...',
    RegistrationTournamentModal_Button_4: 'Unregister',
    RegistrationTournamentModal_New_Team_Label: 'New lineup',
    RegistrationTournamentModal_New_Team_Caption: 'Create a new composition or use an existing one',

    NewSponsorshipModal_Descriptions_Title: 'Descriptions',
    NewSponsorshipModal_Price_Title: 'Sponsorship price',
    NewSponsorshipModal_Price_Value_1: 'Price',
    NewSponsorshipModal_Price_Currency: 'Currency',
    NewSponsorshipModal_Button_Create: 'Submit sponsorship',

    Company_Translations: 'Translations',

    ViewSponsorshipProposalModal_Proposed_Sponsorship: 'Proposed sponsorship',
    ViewSponsorshipProposalModal_Payment_Plan_1: 'You will receive',
    ViewSponsorshipProposalModal_Payment_Plan_2: 'where the first will be on the',
    ViewSponsorshipProposalModal_Payment_Plan_3: 'and the last will be on the',
    ViewSponsorshipProposalModal_Button_Accept: 'Accept',
    ViewSponsorshipProposalModal_Button_Reject: 'Reject',
    ViewSponsorshipProposalModal_Response_1: 'Do you really want to accept this proposal?',
    ViewSponsorshipProposalModal_Response_2: 'Do you really want to reject this proposal?',

    AddSponsorshipNoteModal_Title: 'Add final note',
    AddSponsorshipNoteModal_Button_Create: 'Submit note',
  },
};

{
  /* <FormattedMessage id="" />&nbsp; */
}
