import React from 'react';
import QrScanner from 'qr-scanner';
import _ from 'lodash';
import { Fab, Grid, Grow, Typography } from '@mui/material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import InteractiveAnimation from '../common/InteractiveAnimation';
import { BackCameraIcon, FrontCameraIcon } from '../../img/icons/Icons';
import { FlashOff, FlashOn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

const ScanQRCode: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isAvailable, setIsAvailable] = React.useState(true);
  const [didPerform, setDidPerform] = React.useState(false);
  const [isFront, setIsFront] = React.useState(false);
  const [hasFlash, setHasFlash] = React.useState(UNDEFINED_AS_ANY);
  const [isFlashOn, setIsFlashOn] = React.useState(true);
  const animation = {
    v: '5.5.7',
    meta: { g: 'LottieFiles AE 0.1.20', a: '', k: '', d: '', tc: '' },
    fr: 24,
    ip: 0,
    op: 120,
    w: 800,
    h: 850,
    nm: 'success 2',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Group 1',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [-34.61, -37.71, 0], ti: [26.317, 175.061, 0] },
                  { t: 77, s: [192.34, 473.739, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [775.158, 375.931, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [4.977, 0],
                          [0, 4.974],
                          [6.751, 0],
                          [0, 4.977],
                          [-4.976, 0],
                          [0, 6.742],
                          [-4.977, 0],
                          [0, -4.977],
                          [-6.751, 0],
                          [0, -4.976],
                          [4.974, 0],
                          [0, -6.745],
                        ],
                        o: [
                          [-4.977, 0],
                          [0, -6.745],
                          [-4.976, 0],
                          [0, -4.976],
                          [6.751, 0],
                          [0, -4.977],
                          [4.977, 0],
                          [0, 6.742],
                          [4.974, 0],
                          [0, 4.977],
                          [-6.751, 0],
                          [0, 4.974],
                        ],
                        v: [
                          [-0.001, 30.248],
                          [-9.01, 21.239],
                          [-21.251, 9.004],
                          [-30.26, -0.002],
                          [-21.251, -9.01],
                          [-9.01, -21.239],
                          [-0.001, -30.248],
                          [9.005, -21.239],
                          [21.25, -9.01],
                          [30.26, -0.002],
                          [21.25, 9.004],
                          [9.005, 21.239],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'fl', c: { a: 0, k: [0.984313725490196, 0.30196078431372547, 0.30196078431372547, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [775.158, 375.931], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Group 2',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [49.639, -26.675, 0], ti: [-38.037, 194.374, 0] },
                  { t: 77, s: [697.832, 539.953, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [962.158, 269.931, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [10.818, -6.162],
                          [-6.161, -10.815],
                          [-10.814, 6.161],
                          [6.162, 10.818],
                        ],
                        o: [
                          [10.818, 6.161],
                          [6.162, -10.815],
                          [-10.814, -6.162],
                          [-6.161, 10.818],
                        ],
                        v: [
                          [-25.982, -0.002],
                          [-0.003, 25.977],
                          [25.977, -0.002],
                          [-0.003, -25.982],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [4.977, 0],
                          [0, 4.974],
                          [28.149, 0],
                          [0, 4.977],
                          [-4.977, 0],
                          [0, 28.149],
                          [-4.976, 0],
                          [0, -4.977],
                          [-28.153, 0],
                          [0, -4.977],
                          [4.974, 0],
                          [0, -28.153],
                        ],
                        o: [
                          [-4.976, 0],
                          [0, -28.153],
                          [-4.977, 0],
                          [0, -4.977],
                          [28.149, 0],
                          [0, -4.977],
                          [4.977, 0],
                          [0, 28.149],
                          [4.974, 0],
                          [0, 4.977],
                          [-28.153, 0],
                          [0, 4.974],
                        ],
                        v: [
                          [-0.003, 69.07],
                          [-9.011, 60.06],
                          [-60.061, 9.005],
                          [-69.07, -0.002],
                          [-60.061, -9.01],
                          [-9.011, -60.06],
                          [-0.003, -69.07],
                          [9.003, -60.06],
                          [60.06, -9.01],
                          [69.069, -0.002],
                          [60.06, 9.005],
                          [9.003, 60.06],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.21176470588235294, 0.7843137254901961, 0.4549019607843137, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [962.158, 269.931], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 2',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Group 3',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [-53.224, -13.134, 0], ti: [98.774, 95.751, 0] },
                  { t: 77, s: [80.653, 621.195, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [684.158, 369.93, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [1.138, -1.003],
                          [-1.003, -1.138],
                          [-1.138, 1.003],
                          [1.003, 1.138],
                        ],
                        o: [
                          [1.138, 1.003],
                          [1.003, -1.138],
                          [-1.138, -1.003],
                          [-1.003, 1.138],
                        ],
                        v: [
                          [-3.218, -0.001],
                          [-0.001, 3.216],
                          [3.216, -0.001],
                          [-0.001, -3.218],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [4.977, 0],
                          [0, 4.974],
                          [9.936, 0],
                          [0, 4.977],
                          [-4.976, 0],
                          [0, 9.936],
                          [-4.976, 0],
                          [0, -4.976],
                          [-9.936, 0],
                          [0, -4.977],
                          [4.973, 0],
                          [0, -9.935],
                        ],
                        o: [
                          [-4.976, 0],
                          [0, -9.935],
                          [-4.976, 0],
                          [0, -4.977],
                          [9.936, 0],
                          [0, -4.976],
                          [4.977, 0],
                          [0, 9.936],
                          [4.973, 0],
                          [0, 4.977],
                          [-9.936, 0],
                          [0, 4.974],
                        ],
                        v: [
                          [-0.001, 36.037],
                          [-9.01, 27.028],
                          [-27.029, 9.005],
                          [-36.036, -0.001],
                          [-27.029, -9.01],
                          [-9.01, -27.028],
                          [-0.001, -36.037],
                          [9.005, -27.028],
                          [27.029, -9.01],
                          [36.036, -0.001],
                          [27.029, 9.005],
                          [9.005, 27.028],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.21176470588235294, 0.7843137254901961, 0.4549019607843137, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [684.158, 369.93], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 3',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Group 4',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [5.026, -43.345, 0], ti: [41.383, 101.468, 0] },
                  { t: 77, s: [430.158, 439.93, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [594.158, 457.93, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [1.138, -1.003],
                          [-1.003, -1.138],
                          [-1.138, 1.003],
                          [1.003, 1.138],
                        ],
                        o: [
                          [1.138, 1.003],
                          [1.003, -1.138],
                          [-1.138, -1.003],
                          [-1.003, 1.138],
                        ],
                        v: [
                          [-3.218, -0.001],
                          [-0.001, 3.216],
                          [3.216, -0.001],
                          [-0.001, -3.218],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [4.977, 0],
                          [0, 4.974],
                          [9.936, 0],
                          [0, 4.977],
                          [-4.976, 0],
                          [0, 9.936],
                          [-4.976, 0],
                          [0, -4.976],
                          [-9.936, 0],
                          [0, -4.977],
                          [4.973, 0],
                          [0, -9.935],
                        ],
                        o: [
                          [-4.976, 0],
                          [0, -9.935],
                          [-4.976, 0],
                          [0, -4.977],
                          [9.936, 0],
                          [0, -4.976],
                          [4.977, 0],
                          [0, 9.936],
                          [4.973, 0],
                          [0, 4.977],
                          [-9.936, 0],
                          [0, 4.974],
                        ],
                        v: [
                          [-0.001, 36.037],
                          [-9.01, 27.028],
                          [-27.029, 9.005],
                          [-36.036, -0.001],
                          [-27.029, -9.01],
                          [-9.01, -27.028],
                          [-0.001, -36.037],
                          [9.005, -27.028],
                          [27.029, -9.01],
                          [36.036, -0.001],
                          [27.029, 9.005],
                          [9.005, 27.028],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.21176470588235294, 0.7843137254901961, 0.4549019607843137, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [594.158, 457.93], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 4',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Group 5',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [-44.795, -30.465, 0], ti: [36.63, 115.231, 0] },
                  { t: 77, s: [131.232, 517.209, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [841.158, 531.931, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [1.138, -1.003],
                          [-1.003, -1.138],
                          [-1.138, 1.003],
                          [1.003, 1.138],
                        ],
                        o: [
                          [1.138, 1.003],
                          [1.003, -1.138],
                          [-1.138, -1.003],
                          [-1.003, 1.138],
                        ],
                        v: [
                          [-3.218, -0.002],
                          [-0.001, 3.216],
                          [3.216, -0.002],
                          [-0.001, -3.219],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [4.977, 0],
                          [0, 4.973],
                          [9.936, 0],
                          [0, 4.977],
                          [-4.976, 0],
                          [0, 9.936],
                          [-4.976, 0],
                          [0, -4.976],
                          [-9.936, 0],
                          [0, -4.977],
                          [4.973, 0],
                          [0, -9.936],
                        ],
                        o: [
                          [-4.976, 0],
                          [0, -9.936],
                          [-4.976, 0],
                          [0, -4.977],
                          [9.936, 0],
                          [0, -4.976],
                          [4.977, 0],
                          [0, 9.936],
                          [4.973, 0],
                          [0, 4.977],
                          [-9.936, 0],
                          [0, 4.973],
                        ],
                        v: [
                          [-0.001, 36.037],
                          [-9.01, 27.029],
                          [-27.029, 9.005],
                          [-36.036, -0.002],
                          [-27.029, -9.01],
                          [-9.01, -27.029],
                          [-0.001, -36.037],
                          [9.005, -27.029],
                          [27.029, -9.01],
                          [36.036, -0.002],
                          [27.029, 9.005],
                          [9.005, 27.029],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.9607843137254902, 0.6509803921568628, 0.13725490196078433, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [841.158, 531.931], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 5',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Group 6',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [22.029, 28.147, 0], ti: [-109.69, -13.854, 0] },
                  { t: 77, s: [532.172, 868.884, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [581.172, 695.884, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-2.457, 2.452],
                          [0, 0],
                          [2.434, 2.434],
                          [0, 0],
                          [2.434, -2.44],
                          [0, 0],
                          [0, -1.77],
                          [-1.176, -1.176],
                        ],
                        o: [
                          [2.63, 2.499],
                          [0, 0],
                          [2.434, -2.431],
                          [0, 0],
                          [-2.434, -2.44],
                          [0, 0],
                          [-1.176, 1.173],
                          [0, 1.769],
                          [0, 0],
                        ],
                        v: [
                          [-5.865, 30.797],
                          [3.355, 30.866],
                          [28.431, 5.808],
                          [28.431, -3.327],
                          [3.355, -28.385],
                          [-5.795, -28.385],
                          [-30.871, -3.323],
                          [-32.695, 1.241],
                          [-30.871, 5.812],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [6.275, 0],
                          [4.806, 4.64],
                          [0, 0],
                          [0, 0],
                          [0, 6.581],
                          [-4.581, 4.578],
                          [0, 0],
                          [-9.548, -9.543],
                          [0, 0],
                          [9.542, -9.549],
                          [0, 0],
                        ],
                        o: [
                          [-6.224, 0],
                          [0, 0],
                          [0, 0],
                          [-4.584, -4.587],
                          [0, -6.583],
                          [0, 0],
                          [9.549, -9.537],
                          [0, 0],
                          [9.542, 9.543],
                          [0, 0],
                          [-4.706, 4.705],
                        ],
                        v: [
                          [-1.14, 50.673],
                          [-18.419, 43.718],
                          [-18.531, 43.611],
                          [-43.607, 18.557],
                          [-50.713, 1.241],
                          [-43.61, -16.068],
                          [-18.534, -41.13],
                          [16.094, -41.13],
                          [41.171, -16.068],
                          [41.171, 18.551],
                          [16.091, 43.611],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.21176470588235294, 0.7843137254901961, 0.4549019607843137, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [582.365, 694.69], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 6',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Group 7',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [-10.084, 49.187, 0], ti: [70.673, -79.155, 0] },
                  { t: 77, s: [339.498, 995.123, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [715.172, 231.884, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-2.457, 2.452],
                          [0, 0],
                          [2.434, 2.434],
                          [0, 0],
                          [2.434, -2.44],
                          [0, 0],
                          [0, -1.772],
                          [-1.176, -1.176],
                        ],
                        o: [
                          [2.63, 2.499],
                          [0, 0],
                          [2.434, -2.431],
                          [0, 0],
                          [-2.434, -2.44],
                          [0, 0],
                          [-1.176, 1.173],
                          [0, 1.768],
                          [0, 0],
                        ],
                        v: [
                          [-5.865, 30.796],
                          [3.355, 30.866],
                          [28.431, 5.809],
                          [28.431, -3.327],
                          [3.355, -28.385],
                          [-5.795, -28.385],
                          [-30.871, -3.324],
                          [-32.695, 1.243],
                          [-30.871, 5.811],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [6.275, 0],
                          [4.806, 4.64],
                          [0, 0],
                          [0, 0],
                          [0, 6.58],
                          [-4.581, 4.577],
                          [0, 0],
                          [-9.548, -9.543],
                          [0, 0],
                          [9.542, -9.549],
                          [0, 0],
                        ],
                        o: [
                          [-6.224, 0],
                          [0, 0],
                          [0, 0],
                          [-4.584, -4.587],
                          [0, -6.584],
                          [0, 0],
                          [9.549, -9.537],
                          [0, 0],
                          [9.542, 9.542],
                          [0, 0],
                          [-4.706, 4.705],
                        ],
                        v: [
                          [-1.14, 50.673],
                          [-18.419, 43.717],
                          [-18.531, 43.611],
                          [-43.607, 18.556],
                          [-50.713, 1.243],
                          [-43.61, -16.069],
                          [-18.534, -41.131],
                          [16.094, -41.131],
                          [41.171, -16.069],
                          [41.171, 18.551],
                          [16.091, 43.611],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.21176470588235294, 0.7843137254901961, 0.4549019607843137, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [716.365, 230.69], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 7',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Group 8',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [-44.84, 1.308, 0], ti: [188.364, 9.547, 0] },
                  { t: 77, s: [130.961, 707.849, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [936.178, 627.884, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-2.46, 2.452],
                          [0, 0],
                          [2.435, 2.434],
                          [0, 0],
                          [2.434, -2.44],
                          [0, 0],
                          [0, -1.77],
                          [-1.176, -1.176],
                        ],
                        o: [
                          [2.639, 2.499],
                          [0, 0],
                          [2.435, -2.431],
                          [0, 0],
                          [-2.434, -2.44],
                          [0, 0],
                          [-1.176, 1.173],
                          [0, 1.769],
                          [0, 0],
                        ],
                        v: [
                          [-5.865, 30.797],
                          [3.354, 30.866],
                          [28.43, 5.808],
                          [28.43, -3.327],
                          [3.354, -28.385],
                          [-5.796, -28.385],
                          [-30.872, -3.323],
                          [-32.695, 1.241],
                          [-30.872, 5.812],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [6.275, 0],
                          [4.806, 4.64],
                          [0, 0],
                          [0, 0],
                          [0, 6.581],
                          [-4.581, 4.578],
                          [0, 0],
                          [-9.548, -9.543],
                          [0, 0],
                          [9.543, -9.549],
                          [0, 0],
                        ],
                        o: [
                          [-6.224, 0],
                          [0, 0],
                          [0, 0],
                          [-4.584, -4.587],
                          [0, -6.583],
                          [0, 0],
                          [9.548, -9.537],
                          [0, 0],
                          [9.543, 9.543],
                          [0, 0],
                          [-4.709, 4.705],
                        ],
                        v: [
                          [-1.141, 50.673],
                          [-18.419, 43.718],
                          [-18.531, 43.611],
                          [-43.607, 18.557],
                          [-50.714, 1.241],
                          [-43.611, -16.068],
                          [-18.534, -41.13],
                          [16.091, -41.13],
                          [41.17, -16.068],
                          [41.17, 18.551],
                          [16.091, 43.611],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.21176470588235294, 0.7843137254901961, 0.4549019607843137, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [937.371, 626.69], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 8',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Group 9',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [51.948, 8.358, 0], ti: [-161.709, 26.625, 0] },
                  { t: 77, s: [711.688, 750.148, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [1210.183, 446.882, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-2.461, 2.464],
                          [0, 0],
                          [2.434, 2.434],
                          [0, 0],
                          [2.431, -2.44],
                          [0, 0],
                          [0, -1.772],
                          [-1.176, -1.176],
                        ],
                        o: [
                          [2.627, 2.499],
                          [0, 0],
                          [2.434, -2.431],
                          [0, 0],
                          [-2.441, -2.44],
                          [0, 0],
                          [-1.176, 1.173],
                          [0, 1.768],
                          [0, 0],
                        ],
                        v: [
                          [-5.865, 30.798],
                          [3.352, 30.868],
                          [28.431, 5.81],
                          [28.431, -3.325],
                          [3.355, -28.384],
                          [-5.795, -28.384],
                          [-30.872, -3.322],
                          [-32.695, 1.244],
                          [-30.872, 5.813],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [6.276, 0],
                          [4.807, 4.64],
                          [0, 0],
                          [0, 0],
                          [0, 6.58],
                          [-4.581, 4.577],
                          [0, 0],
                          [-9.549, -9.546],
                          [0, 0],
                          [9.543, -9.549],
                          [0, 0],
                        ],
                        o: [
                          [-6.223, 0],
                          [0, 0],
                          [0, 0],
                          [-4.584, -4.587],
                          [0, -6.584],
                          [0, 0],
                          [9.551, -9.546],
                          [0, 0],
                          [9.543, 9.542],
                          [0, 0],
                          [-4.71, 4.705],
                        ],
                        v: [
                          [-1.141, 50.675],
                          [-18.42, 43.719],
                          [-18.531, 43.613],
                          [-43.607, 18.558],
                          [-50.714, 1.244],
                          [-43.61, -16.067],
                          [-18.534, -41.129],
                          [16.091, -41.126],
                          [41.17, -16.067],
                          [41.17, 18.552],
                          [16.091, 43.613],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.21176470588235294, 0.7843137254901961, 0.4549019607843137, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [1211.376, 445.688], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 9',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Group 10',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [-62.614, 110.25, 0], ti: [97.42, 11.358, 0] },
                  { t: 77, s: [86.197, 828.475, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [793.158, 703.896, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-0.915, 0.915],
                          [0, 0],
                          [0, 0.4],
                          [0.581, 0.58],
                          [0, 0],
                          [0.898, -0.897],
                          [0, 0],
                          [0, -0.401],
                          [-0.584, -0.58],
                        ],
                        o: [
                          [1.029, 0.956],
                          [0, 0],
                          [0.587, -0.583],
                          [0, -0.401],
                          [0, 0],
                          [-0.898, -0.891],
                          [0, 0],
                          [-0.584, 0.58],
                          [0, 0.4],
                          [0, 0],
                        ],
                        v: [
                          [-1.825, 21.292],
                          [1.753, 21.362],
                          [20.348, 2.772],
                          [21.023, 1.033],
                          [20.354, -0.705],
                          [1.75, -19.298],
                          [-1.749, -19.298],
                          [-20.35, -0.708],
                          [-21.022, 1.033],
                          [-20.35, 2.772],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [5.255, 0],
                          [4.011, 3.871],
                          [0, 0],
                          [0, 0],
                          [0, 5.505],
                          [-3.836, 3.836],
                          [0, 0],
                          [-7.988, -7.976],
                          [0, 0],
                          [0, -5.507],
                          [3.842, -3.836],
                          [0, 0],
                        ],
                        o: [
                          [-5.2, 0],
                          [0, 0],
                          [0, 0],
                          [-3.836, -3.83],
                          [0, -5.507],
                          [0, 0],
                          [7.988, -7.982],
                          [0, 0],
                          [3.836, 3.833],
                          [0, 5.505],
                          [0, 0],
                          [-3.947, 3.942],
                        ],
                        v: [
                          [0.055, 40.025],
                          [-14.373, 34.219],
                          [-14.488, 34.107],
                          [-33.089, 15.515],
                          [-39.039, 1.033],
                          [-33.089, -13.453],
                          [-14.488, -32.043],
                          [14.483, -32.046],
                          [33.087, -13.453],
                          [39.04, 1.033],
                          [33.081, 15.521],
                          [14.489, 34.107],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.9607843137254902, 0.6509803921568628, 0.13725490196078433, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [793.158, 702.898], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 10',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Group 11',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [134.568, -54.706, 0], ti: [-5.655, 46.971, 0] },
                  { t: 77, s: [573.156, 503.93, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [581.156, 567.93, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [12.021, 0],
                          [0, -12.012],
                          [-12.024, 0],
                          [0, 12.015],
                        ],
                        o: [
                          [-12.024, 0],
                          [0, 12.015],
                          [12.021, 0],
                          [0, -12.012],
                        ],
                        v: [
                          [0.001, -21.787],
                          [-21.803, -0.001],
                          [0.001, 21.785],
                          [21.805, -0.001],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [21.956, 0],
                          [0, 21.951],
                          [-21.957, 0],
                          [0, -21.947],
                        ],
                        o: [
                          [-21.957, 0],
                          [0, -21.947],
                          [21.956, 0],
                          [0, 21.951],
                        ],
                        v: [
                          [0.001, 39.804],
                          [-39.821, -0.001],
                          [0.001, -39.804],
                          [39.822, -0.001],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.984313725490196, 0.30196078431372547, 0.30196078431372547, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [581.156, 567.93], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 11',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Group 12',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [-10.759, -34.076, 0], ti: [-9.867, 115.403, 0] },
                  { t: 77, s: [335.446, 495.546, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [965.446, 443.546, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [2.059, 0],
                          [0, -2.058],
                          [-2.064, 0],
                          [0, 2.059],
                        ],
                        o: [
                          [-2.064, 0],
                          [0, 2.059],
                          [2.059, 0],
                          [0, -2.058],
                        ],
                        v: [
                          [0.003, -3.735],
                          [-3.739, -0.001],
                          [0.003, 3.735],
                          [3.739, -0.001],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [11.994, 0],
                          [0, 11.995],
                          [-12, 0],
                          [0, -11.994],
                        ],
                        o: [
                          [-12, 0],
                          [0, -11.994],
                          [11.994, 0],
                          [0, 11.995],
                        ],
                        v: [
                          [0.003, 21.752],
                          [-21.757, -0.001],
                          [0.003, -21.752],
                          [21.757, -0.001],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.984313725490196, 0.30196078431372547, 0.30196078431372547, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [965.446, 443.546], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 12',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Group 13',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 59, s: [400, 700, 0], to: [21.9, 68.907, 0], ti: [-132.766, 49.693, 0] },
                  { t: 77, s: [735.084, 845.977, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [1109.157, 578.929, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.187, 0.187, 0.667], y: [1, 1, 1] }, o: { x: [0.733, 0.733, 0.333], y: [0, 0, 0] }, t: 59, s: [30, 30, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 11] }, o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] }, t: 69, s: [30, 30, 100] },
                  { t: 77, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-2.229, 2.229],
                          [0, 0],
                          [0, 1.531],
                          [1.073, 1.071],
                          [0, 0],
                          [2.223, -2.231],
                          [0, 0],
                          [0, -1.528],
                          [-1.073, -1.07],
                        ],
                        o: [
                          [2.229, 2.229],
                          [0, 0],
                          [1.073, -1.07],
                          [0, -1.528],
                          [0, 0],
                          [-2.232, -2.231],
                          [0, 0],
                          [-1.073, 1.071],
                          [0, 1.531],
                          [0, 0],
                        ],
                        v: [
                          [-4.048, 28.978],
                          [4.046, 28.978],
                          [27.844, 5.192],
                          [29.51, 1.157],
                          [27.844, -2.876],
                          [4.043, -26.662],
                          [-4.045, -26.662],
                          [-27.846, -2.876],
                          [-29.508, 1.157],
                          [-27.846, 5.192],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [6.073, 0],
                          [4.628, 4.616],
                          [0, 0],
                          [0, 0],
                          [0, 6.349],
                          [-4.475, 4.476],
                          [0, 0],
                          [-9.249, -9.244],
                          [0, 0],
                          [0, -6.343],
                          [4.475, -4.475],
                          [0, 0],
                        ],
                        o: [
                          [-6.079, 0],
                          [0, 0],
                          [0, 0],
                          [-4.475, -4.475],
                          [0, -6.343],
                          [0, 0],
                          [9.261, -9.25],
                          [0, 0],
                          [4.475, 4.476],
                          [0, 6.349],
                          [0, 0],
                          [-4.627, 4.622],
                        ],
                        v: [
                          [-0.001, 48.656],
                          [-16.781, 41.729],
                          [-16.784, 41.723],
                          [-40.585, 17.934],
                          [-47.527, 1.157],
                          [-40.585, -15.621],
                          [-16.784, -39.406],
                          [16.782, -39.406],
                          [40.583, -15.621],
                          [47.527, 1.157],
                          [40.583, 17.934],
                          [16.782, 41.723],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                  { ty: 'fl', c: { a: 0, k: [0.984313725490196, 0.30196078431372547, 0.30196078431372547, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
                  { ty: 'tr', p: { a: 0, k: [1109.157, 577.772], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
                ],
                nm: 'Group 13',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 59,
            op: 256,
            st: 16,
            bm: 0,
          },
        ],
      },
    ],
    fonts: { list: [{ fName: 'HelveticaRounded-Black', fFamily: 'Helvetica Rounded Black', fStyle: 'Regular', ascent: 74.6078491210938 }] },
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 5,
        nm: 'Successful',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [1], y: [0] }, t: 55, s: [0] },
              { t: 60, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              { i: { x: 0.577, y: 0.896 }, o: { x: 0.499, y: 0 }, t: 55, s: [400, 418, 0], to: [0, -43.167, 0], ti: [0, 43.167, 0] },
              { t: 60, s: [400, 159, 0] },
            ],
            ix: 2,
            x: 'var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\namp = 0.06;\nfreq = 2;\ndecay = 4;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
          },
          a: { a: 0, k: [-0.815, -33.278, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        t: { d: { k: [{ s: { s: 88, f: 'HelveticaRounded-Black', t: '', j: 2, tr: 0, lh: 105.6, ls: 0, fc: [0.125, 1, 0.525] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
        ip: 55,
        op: 235,
        st: -5,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'Shape Layer 3',
        parent: 4,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.865], y: [0] }, t: 43, s: [-225] },
              { t: 60, s: [-180] },
            ],
            ix: 10,
            x: 'var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\namp = 0.06;\nfreq = 2;\ndecay = 4;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
          },
          p: { a: 0, k: [-44, 120, 0], ix: 2 },
          a: { a: 0, k: [77, 2, 0], ix: 1 },
          s: { a: 0, k: [30.158, 30.158, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-56, 6],
                      [30, 92],
                      [210, -88],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              { ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 70, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: { a: 0, k: 0, ix: 1 },
            e: {
              a: 1,
              k: [
                { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 43, s: [0] },
                { t: 55, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 31,
        op: 261,
        st: 31,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'Shape Layer 1',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 48, s: [100] },
              { t: 60, s: [0] },
            ],
            ix: 11,
          },
          r: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 15, s: [0] },
              { t: 48, s: [270] },
            ],
            ix: 10,
          },
          p: { a: 0, k: [400, 418, 0], ix: 2 },
          a: { a: 0, k: [-44, 120, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0.322, 0.322, 0.273], y: [1, 1, 1] }, o: { x: [0.243, 0.243, 0.84], y: [0, 0, 0] }, t: 24, s: [50, 50, 100] },
              { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1.821] }, o: { x: [1, 1, 0.167], y: [0, 0, 0] }, t: 48, s: [115, 115, 100] },
              { t: 60, s: [100, 100, 100] },
            ],
            ix: 6,
            x: 'var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\namp = 0.06;\nfreq = 6;\ndecay = 10;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              { d: 1, ty: 'el', s: { a: 0, k: [246.094, 246.094], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
              {
                ty: 'st',
                c: { a: 0, k: [0.23529411764705882, 0.7372549019607844, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: {
                  a: 1,
                  k: [
                    { i: { x: [0.206], y: [1] }, o: { x: [0.863], y: [0] }, t: 24, s: [60] },
                    { t: 43, s: [4] },
                  ],
                  ix: 5,
                },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              { ty: 'tr', p: { a: 0, k: [-44, 120], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                { i: { x: [0.273], y: [1] }, o: { x: [0.84], y: [0] }, t: 24, s: [0] },
                { t: 48, s: [100] },
              ],
              ix: 1,
            },
            e: { a: 0, k: 0, ix: 2 },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 0,
        op: 61,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'Shape Layer 2',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 48, s: [0] },
              { t: 60, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 540, ix: 10 },
          p: { a: 0, k: [400, 418, 0], ix: 2 },
          a: { a: 0, k: [-44, 120, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1.821] }, o: { x: [1, 1, 0.167], y: [0, 0, 0] }, t: 48, s: [115, 115, 100] },
              { t: 60, s: [100, 100, 100] },
            ],
            ix: 6,
            x: 'var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\namp = 0.06;\nfreq = 6;\ndecay = 6;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time) {\n        n--;\n    }\n}\nif (n == 0) {\n    $bm_rt = t = 0;\n} else {\n    $bm_rt = t = $bm_sub(time, key(n).time);\n}\nif (n > 0) {\n    v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n    $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n} else {\n    $bm_rt = value;\n}',
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              { d: 1, ty: 'el', s: { a: 0, k: [246.094, 246.094], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
              { ty: 'st', c: { a: 0, k: [0.15294117647058825, 0.6823529411764706, 0.3764705882352941, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 6, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'fl', c: { a: 0, k: [0.15294117647058825, 0.6823529411764706, 0.3764705882352941, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
              { ty: 'tr', p: { a: 0, k: [-44, 120], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          { ty: 'tm', s: { a: 0, k: 100, ix: 1 }, e: { a: 0, k: 0, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false },
        ],
        ip: 48,
        op: 240,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'Shape Layer 4',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 45, s: [60] },
              { t: 60, s: [0] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 270, ix: 10 },
          p: { a: 0, k: [400, 418, 0], ix: 2 },
          a: { a: 0, k: [-44, 120, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { i: { x: [0, 0, 0.667], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 45, s: [127, 127, 100] },
              { t: 60, s: [170, 170, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              { d: 1, ty: 'el', s: { a: 0, k: [246.094, 246.094], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
              { ty: 'st', c: { a: 0, k: [0.48627450980392156, 0.788235294117647, 0.615686274509804, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 30, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
              { ty: 'tr', p: { a: 0, k: [-44, 120], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          { ty: 'tm', s: { a: 0, k: 100, ix: 1 }, e: { a: 0, k: 0, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false },
        ],
        ip: 45,
        op: 237,
        st: -3,
        bm: 0,
      },
      { ddd: 0, ind: 6, ty: 0, nm: 'spark', refId: 'comp_0', sr: 1, ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 418, 0], ix: 2 }, a: { a: 0, k: [400, 700, 0], ix: 1 }, s: { a: 0, k: [90, 90, 100], ix: 6 } }, ao: 0, w: 800, h: 1400, ip: 60, op: 238, st: -2, bm: 0 },
    ],
    markers: [],
    chars: [
      {
        ch: 'S',
        size: 88,
        style: 'Regular',
        w: 72.2,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.921],
                        [4.733, 0],
                        [8.258, 0],
                        [0, -5.64],
                        [-24.371, 0],
                        [0, 17.221],
                        [12.186, 3.323],
                        [0, 0],
                        [0, 3.223],
                        [-3.928, 0],
                        [-7.755, 0],
                        [0, 5.64],
                        [13.394, 0],
                        [0, -12.085],
                        [-11.581, -3.323],
                        [0, 0],
                      ],
                      o: [
                        [0, 4.431],
                        [-11.38, 0],
                        [-5.64, 0],
                        [0, 6.546],
                        [15.308, 0],
                        [0, -12.589],
                        [0, 0],
                        [-4.935, -1.309],
                        [0, -3.323],
                        [11.481, 0],
                        [5.74, 0],
                        [0, -12.186],
                        [-20.142, 0],
                        [0, 10.172],
                        [0, 0],
                        [4.23, 1.208],
                      ],
                      v: [
                        [44.916, -20.645],
                        [35.953, -14.603],
                        [15.308, -25.177],
                        [5.539, -15.71],
                        [37.363, 1.712],
                        [67.474, -22.961],
                        [46.93, -44.513],
                        [34.946, -47.736],
                        [27.191, -53.677],
                        [34.744, -58.511],
                        [54.08, -49.649],
                        [64.151, -58.813],
                        [33.435, -74.222],
                        [5.237, -50.757],
                        [23.163, -30.414],
                        [38.571, -25.983],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'S',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'S',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Helvetica Rounded Black',
      },
      {
        ch: 'u',
        size: 88,
        style: 'Regular',
        w: 66.6,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [5.136, 0],
                        [0, 5.74],
                        [0, 0],
                        [6.042, 0],
                        [0, -7.05],
                        [0, 0],
                        [-14.401, 0],
                        [-3.424, 6.244],
                        [0, 0],
                        [-6.143, 0],
                        [0, 6.445],
                        [0, 0],
                        [6.042, 0],
                        [0, -7.05],
                      ],
                      o: [
                        [0, 6.244],
                        [-5.136, 0],
                        [0, 0],
                        [0, -7.05],
                        [-6.042, 0],
                        [0, 0],
                        [0, 11.481],
                        [7.956, 0],
                        [0, 0],
                        [0.705, 6.244],
                        [6.848, 0],
                        [0, 0],
                        [0, -7.05],
                        [-6.042, 0],
                        [0, 0],
                      ],
                      v: [
                        [41.19, -25.278],
                        [33.536, -15.005],
                        [25.882, -23.868],
                        [25.882, -43.707],
                        [15.509, -53.979],
                        [5.136, -43.707],
                        [5.136, -19.034],
                        [25.378, 1.309],
                        [42.499, -8.459],
                        [42.7, -8.459],
                        [51.965, 1.309],
                        [61.935, -8.56],
                        [61.935, -43.707],
                        [51.562, -53.979],
                        [41.19, -43.707],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'u',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'u',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Helvetica Rounded Black',
      },
      {
        ch: 'c',
        size: 88,
        style: 'Regular',
        w: 61.1,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -19.034],
                        [-16.516, 0],
                        [0, 4.23],
                        [4.935, 0],
                        [6.949, 0],
                        [0, 6.848],
                        [-8.459, 0],
                        [-5.338, 0],
                        [0, 4.733],
                        [14.502, 0],
                      ],
                      o: [
                        [0, 19.135],
                        [18.43, 0],
                        [0, -3.625],
                        [-5.74, 0],
                        [-7.251, 0],
                        [0, -5.64],
                        [7.352, 0],
                        [4.129, 0],
                        [0, -7.251],
                        [-16.516, 0],
                      ],
                      v: [
                        [4.028, -26.385],
                        [33.536, 1.309],
                        [57.806, -12.891],
                        [50.052, -20.544],
                        [34.241, -13.193],
                        [24.774, -26.788],
                        [35.147, -39.478],
                        [50.153, -32.126],
                        [57.605, -39.78],
                        [33.536, -53.979],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'c',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'c',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Helvetica Rounded Black',
      },
      {
        ch: 'e',
        size: 88,
        style: 'Regular',
        w: 66.6,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 5.74],
                        [3.625, 0],
                        [7.553, 0],
                        [0.504, 6.647],
                        [0, 0],
                        [0, 6.244],
                        [23.163, 0],
                        [0, -19.034],
                        [-16.516, 0],
                      ],
                      o: [
                        [0, -3.323],
                        [-6.345, 0],
                        [-6.747, 0],
                        [0, 0],
                        [6.647, 0],
                        [0, -7.855],
                        [-16.516, 0],
                        [0, 19.135],
                        [20.041, 0],
                      ],
                      v: [
                        [60.626, -10.373],
                        [54.181, -17.221],
                        [35.852, -10.776],
                        [24.774, -21.753],
                        [54.181, -21.753],
                        [63.748, -30.212],
                        [33.536, -53.979],
                        [4.028, -26.385],
                        [33.536, 1.309],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'e',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-4.633, 0],
                        [0, -7.251],
                      ],
                      o: [
                        [0.302, -7.352],
                        [5.136, 0],
                        [0, 0],
                      ],
                      v: [
                        [24.774, -32.025],
                        [34.543, -41.895],
                        [44.211, -32.025],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'e',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'e',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Helvetica Rounded Black',
      },
      {
        ch: 's',
        size: 88,
        style: 'Regular',
        w: 59.3,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.813],
                        [4.431, 0],
                        [5.841, 0],
                        [0, -3.424],
                        [-13.394, 0],
                        [0, 9.164],
                        [11.38, 2.518],
                        [0, 0],
                        [0, 2.014],
                        [-3.726, 0],
                        [-6.848, 0],
                        [0, 3.625],
                        [12.286, 0],
                        [0, -8.057],
                        [-15.106, -3.223],
                        [0, 0],
                      ],
                      o: [
                        [0, 2.014],
                        [-9.97, 0],
                        [-4.028, 0],
                        [0, 8.762],
                        [21.753, 0],
                        [0, -8.459],
                        [0, 0],
                        [-5.539, -1.208],
                        [0, -2.417],
                        [9.869, 0],
                        [4.028, 0],
                        [0, -8.057],
                        [-20.746, 0],
                        [0, 7.755],
                        [0, 0],
                        [5.74, 1.208],
                      ],
                      v: [
                        [35.449, -14.301],
                        [28.903, -10.172],
                        [10.272, -17.825],
                        [2.921, -11.481],
                        [28.198, 1.309],
                        [56.799, -16.919],
                        [38.37, -32.629],
                        [29.608, -34.543],
                        [23.163, -38.773],
                        [29.004, -42.499],
                        [47.836, -36.053],
                        [54.684, -42.499],
                        [29.608, -53.979],
                        [3.021, -36.859],
                        [22.559, -20.444],
                        [28.702, -19.135],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 's',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 's',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Helvetica Rounded Black',
      },
      {
        ch: 'f',
        size: 88,
        style: 'Regular',
        w: 42.6,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, -4.129],
                        [-4.129, 0],
                        [0, 0],
                        [0, 0],
                        [-6.445, 0],
                        [0, 7.15],
                        [0, 0],
                        [0, 0],
                        [0, 4.129],
                        [4.129, 0],
                        [0, 0],
                        [0, 0],
                        [-1.309, 0],
                        [0, 4.834],
                        [11.884, 0],
                        [0, -11.783],
                      ],
                      o: [
                        [0, 0],
                        [-4.129, 0],
                        [0, 4.129],
                        [0, 0],
                        [0, 0],
                        [0, 7.15],
                        [6.445, 0],
                        [0, 0],
                        [0, 0],
                        [4.129, 0],
                        [0, -4.129],
                        [0, 0],
                        [0, 0],
                        [0, -1.611],
                        [7.855, 0],
                        [0, -4.23],
                        [-11.682, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.272, -52.67],
                        [6.445, -52.67],
                        [0, -46.628],
                        [6.445, -40.585],
                        [10.272, -40.585],
                        [10.272, -9.064],
                        [20.343, 1.309],
                        [30.414, -9.064],
                        [30.414, -40.585],
                        [35.651, -40.585],
                        [42.096, -46.628],
                        [35.651, -52.67],
                        [30.414, -52.67],
                        [30.414, -56.699],
                        [32.73, -59.116],
                        [42.902, -66.367],
                        [28.4, -74.222],
                        [10.272, -56.598],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'f',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'f',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Helvetica Rounded Black',
      },
      {
        ch: 'l',
        size: 88,
        style: 'Regular',
        w: 31.4,
        data: {
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.042, 0],
                        [0, 7.05],
                        [0, 0],
                        [6.042, 0],
                        [0, -7.05],
                      ],
                      o: [
                        [0, 7.05],
                        [6.042, 0],
                        [0, 0],
                        [0, -7.05],
                        [-6.042, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.438, -8.963],
                        [15.811, 1.309],
                        [26.184, -8.963],
                        [26.184, -63.547],
                        [15.811, -73.819],
                        [5.438, -63.547],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'l',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
              ],
              nm: 'l',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
        },
        fFamily: 'Helvetica Rounded Black',
      },
    ],
  };

  const videoRef: any = React.useRef(null);
  const scannerRef: any = React.useRef(null);

  const handleScan = (result: any) => {
    if (result) {
      // window.navigator.vibrate(200); // vibre pendant 200ms
      if ('vibrate' in window.navigator || 'vibrate' in navigator) {
        window.navigator.vibrate([200]);
      }

      setDidPerform(true);

      if (_.split(result, '_@_', 2)[0] === 'VIEW_PLAYER') {
        navigate(`/u/${_.split(result, '_@_', 2)[1]}`);
      }

      if (_.split(result, '_@_', 2)[0] === 'VIEW_FOOTBALL_TEAM') {
        navigate(`/football_team/${_.split(result, '_@_', 2)[1]}`);
      }

      setTimeout(() => {
        setDidPerform(false);
      }, 3500);
    }
  };

  const handleFlash = async (isFlashOn: boolean) => {
    setIsFlashOn(isFlashOn);
    isFlashOn ? await scannerRef.current?.turnFlashOff() : await scannerRef.current?.turnFlashOn();
  };

  React.useEffect(() => {
    (async () => {
      if (videoRef.current) {
        try {
          // Initialize the QR scanner
          scannerRef.current = new QrScanner(
            videoRef.current,
            (result: any) => {
              // Handle the scanned QR code result
              handleScan(result.data);
            },
            {
              preferredCamera: isFront ? 'user' : 'environment',
              highlightScanRegion: true,
            }
          );

          // Start scanning when the component mounts
          await scannerRef.current?.start();

          await scannerRef.current
            ?.hasFlash()
            .then((r: any) => setHasFlash(r))
            .catch((e: any) => console.log(e));
        } catch (e) {
          console.log(e);
          setIsAvailable(false);
        }
      }
    })();

    // Clean up the scanner when the component unmounts
    return () => {
      if (scannerRef.current) {
        scannerRef.current?.destroy();
      }
    };
  }, [videoRef]);

  return (
    <>
      <Grid container sx={{ justifyContent: 'center' }}>
        {isAvailable ? (
          <Grid container>
            {didPerform ? (
              <Grid sx={{ textAlign: 'center', width: '100%' }}>
                <InteractiveAnimation
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: animation,
                  }}
                  height={300}
                  width={300}
                />
                <Grow in={didPerform} style={{ transitionDelay: didPerform ? '200ms' : '0ms' }} {...(didPerform ? { timeout: 1000 } : {})}>
                  <Typography sx={{ position: 'relative', top: '-100px', fontWeight: '700', fontSize: '2em' }}>{`${intl.formatMessage({ id: 'ScanQRCode_Scanned' })}`}</Typography>
                </Grow>
              </Grid>
            ) : (
              <>
                <Grid sx={{ 'width': '100%', '& video': { width: '100% !important', minWidth: '300px', height: '300px', borderRadius: '10px' } }}>
                  <video ref={videoRef} autoPlay playsInline></video>
                </Grid>

                <Grid container sx={{ justifyContent: 'center' }}>
                  <Fab onClick={() => setIsFront(!isFront)} sx={{ bgcolor: 'transparent', boxShadow: 'none', m: '0 10px' }}>
                    {isFront ? <BackCameraIcon /> : <FrontCameraIcon />}
                  </Fab>
                  {hasFlash ? (
                    <Fab onClick={() => handleFlash(!isFlashOn)} sx={{ bgcolor: 'transparent', boxShadow: 'none', m: '0 10px', opacity: '.4' }}>
                      {isFlashOn ? <FlashOn /> : <FlashOff />}
                    </Fab>
                  ) : (
                    ''
                  )}
                </Grid>
              </>
            )}
            <Typography variant="caption" sx={{ textAlign: 'center', width: '100%' }}>
              {`${intl.formatMessage({ id: 'ScanQRCode_Scan_QRCode' })}`}
            </Typography>
          </Grid>
        ) : (
          <Grid sx={{ bgcolor: 'rgba(0, 0, 0, .1)', p: '30px 10%', textAlign: 'center' }}>{`${intl.formatMessage({ id: 'ScanQRCode_Scan_Off' })}`}</Grid>
        )}
      </Grid>
    </>
  );
};

ScanQRCode.defaultProps = {};

export default ScanQRCode;
