import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';

import { Typography, Grid, CircularProgress, Button } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';

const EmailVerification: React.FC = () => {
  const EMAIL_VERIFICATION_MUTATION = gql`
    mutation emailVerification($account: inputEmailVerificationAccount!) {
      emailVerification(account: $account)
    }
  `;

  const location: any = useLocation();
  const navigate = useNavigate();
  const loggedPlayer = useAppSelector(selectPlayer);
  const [emailVerification, verifiedEmail] = useMutation(EMAIL_VERIFICATION_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });

  React.useEffect(() => {
    (async () => {
      if (!verifiedEmail.called && location.pathname.split('/')[2].split('&')[0] && location.pathname.split('/')[2].split('&')[1]) {
        try {
          await emailVerification({
            variables: {
              account: {
                key: location.pathname.split('/')[2].split('&')[1],
                address: location.pathname.split('/')[2].split('&')[0],
                language: loggedPlayer?.app_configuration?.prefered_language,
              },
            },
          });

          // navigate('/', { replace: true });
        } catch (e) {
          console.log(e);
          // navigate('/', { replace: true });
        }
      }
    })();
  }, [emailVerification, location, verifiedEmail, navigate]);

  return (
    <Grid
      container
      className={'no-email-verification'}
      sx={{
        p: '0 10%',
        minHeight: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item xl={12} xs={12} sm={12} sx={{ textAlign: 'left' }}>
        {verifiedEmail.loading ? (
          <Grid container sx={{ justifyContent: 'center' }}>
            <CircularProgress className="orange-loader" />
            <br />
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '1.6em', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>
              Processing your information
              <br />
              please wait...
            </Typography>
          </Grid>
        ) : (
          <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>
            {verifiedEmail.data?.emailVerification ? (
              <Grid>
                <Typography variant="h1" sx={{ fontSize: { xs: '1.4em', sm: '1.8em', md: '2em' }, fontWeight: '700', mb: '30px', textAlign: 'center' }}>
                  🎊 Congratulation! 🎊
                </Typography>
                <Grid item xs={12} md={12} lg={12} className={'content'} sx={{ p: '0 10%' }}>
                  You have successfully verified your email, you can now go to the{' '}
                  <Button
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      border: 'none !important',
                      p: '0',
                      width: 'auto',
                    }}
                    className="button-green-reverse"
                    component={Link}
                    to="/"
                  >
                    Home page
                  </Button>{' '}
                  to explore all the teams, challenges and more surprises that the community has to offer.
                  <br />
                  <br />
                  If you want to play, you can now{' '}
                  <Button
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      border: 'none !important',
                      p: '0',
                      width: 'auto',
                    }}
                    className="button-green-reverse"
                    component={Link}
                    to="/new_footballer"
                  >
                    create your footballer profile
                  </Button>{' '}
                  and enjoy your favorite sport with the world.
                  <br />
                  <br />
                  Warm Welcome {loggedPlayer?.owner?.firstname}!
                  <br />
                  <i>Fivezer Team</i>
                </Grid>
              </Grid>
            ) : (
              <Grid>
                <Typography variant="h1" sx={{ fontSize: { xs: '1.4em', sm: '1.8em', md: '2em' }, fontWeight: '700', mb: '30px', textAlign: 'center' }}>
                  Sorry 😓
                </Typography>
                <Grid item xs={12} md={12} lg={12} className={'content'} sx={{ p: '0 10%' }}>
                  Something went wrong and we couldn&apos;t verifiy your email, you can either go to the{' '}
                  <Button
                    sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      border: 'none !important',
                      p: '0',
                      width: 'auto',
                    }}
                    className="button-green-reverse"
                    component={Link}
                    to="/"
                  >
                    Home page
                  </Button>{' '}
                  and try again later or if you already tried multiple times, you can contact us at contact@thebhub.io and we will help you on your matter.
                  <br />
                  <br />
                  Our apologies {loggedPlayer?.owner?.firstname}!
                  <br />
                  <i>Fivezer Team</i>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EmailVerification;
