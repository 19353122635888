import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, Divider, Fab, Grid, Avatar, TextField, MenuItem, Autocomplete, Chip, Stack, CardMedia } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { Controller, useForm } from 'react-hook-form';
import { ARRAY_AS_ANY, CountryType, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { COUNTRIES, DURATION_IN_HOURS, TO_TIMESTAMP } from '../../utilities/utilities';
import ViewFieldModal from '../team/ViewFieldModal';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { City } from 'country-state-city';
import { LoadingButton } from '@mui/lab';
import { REACT_APP_CUSTOM_MAP_URL } from '../../config';
import { useIntl } from 'react-intl';

export default function EditTournamentPlayoffGameModal(props: { tournament_id: string; tournament_stage_challenge_id: string; current_game?: any; handleClose: any; open: any }) {
  const FIND_TOURNAMENT_BY_ID_QUERY = gql`
    query findTournamentByID($id: ID!) {
      findTournamentByID(id: $id) {
        id
        start_date
        end_date
        is_private
        sport
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        main_location {
          id
          coordinates
          country_code
        }
        selected_teams {
          id
          name
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              shortname
              avatar {
                path
              }
            }
          }
        }
      }
    }
  `;

  const FIELD_AVAILABILITY_MUTATION = gql`
    mutation fieldAvailability($field: inputFieldAvailability!) {
      fieldAvailability(field: $field)
    }
  `;

  const FIND_COMPLEXES_BY_OPTIONS_QUERY = gql`
    query findComplexesByOptions($options: inputFindComplexesByOptions!) {
      findComplexesByOptions(options: $options) {
        id
        name
        logo {
          path
        }
        cover {
          path
        }
        pictures {
          path
        }
        owners {
          ... on Company {
            __typename
            id
            name
            label
          }
          # ... on ComplexOwner {
          #   __typename
          #   id
          # }
        }
        fields {
          id
          short_id
          name
          pictures {
            path
          }
          rental_asset {
            id
            price {
              value
              currency {
                currency_code
              }
            }
          }
        }
        location {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
        }
      }
    }
  `;

  const FIND_FREE_FIELD_BY_COUNTRY_NAME_QUERY = gql`
    query findFreeFielsdByCountry($country_name: String!) {
      findFreeFieldsByCountry(country_name: $country_name) {
        id
        short_id
        name
        size
        type
        sports
        status
        pictures {
          path
        }
        descriptions {
          language
          content
        }
        equipments {
          id
          name
          pictures {
            path
          }
        }
        rental_asset {
          id
          price {
            value
            currency {
              currency_code
            }
          }
        }

        location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const EDIT_TOURNAMENT_PLAYOFF_CHALLENGE_MUTATION = gql`
    mutation editTournamentPlayoffChallenge($challenge: inputEditTournamentPlayoffChallenge!) {
      editTournamentPlayoffChallenge(challenge: $challenge) {
        id
        short_id
      }
    }
  `;

  const FIND_TOURNAMENT_STAGE_CHALLENGE_TO_EDIT_BY_ID_QUERY = gql`
    query findTournamentStageChallengeToEditByID($challenge: inputFindTournamentStageChallengeToEditByID!) {
      findTournamentStageChallengeToEditByID(challenge: $challenge) {
        id
        short_id
        title
        status
        start_date
        end_date
        home_team {
          id
        }
        away_team {
          id
        }
        complex {
          id
          location {
            country_code
          }
        }
        arena {
          id
          location {
            country_code
          }
        }
      }
    }
  `;

  const SEND_LILENEK_NEW_SPORT_SESSION_MUTATION = gql`
    mutation sendLilenekNewSportSessionEmail($account: inputSendLilenekNewSellEmail!) {
      sendLilenekNewSportSessionEmail(account: $account)
    }
  `;

  const SEND_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const center_position: any = [48.8559202, 2.2910392];
  const intl = useIntl();
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);
  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);
  const [country, setCountry] = React.useState<CountryType>(UNDEFINED_AS_ANY);
  const [city, setCity] = React.useState(UNDEFINED_AS_ANY);
  const [openSearchComplex, setOpenSearchComplex] = React.useState(false);
  const [openViewField, setOpenViewField] = React.useState(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      challenger_a: UNDEFINED_AS_ANY,
      challenger_b: UNDEFINED_AS_ANY,
      start_date: UNDEFINED_AS_ANY,
      end_date: UNDEFINED_AS_ANY,

      city: UNDEFINED_AS_ANY,
      city_name: '',
      country: UNDEFINED_AS_ANY,

      all_complexes: ARRAY_AS_ANY,
      complex: UNDEFINED_AS_ANY,
      complex_name: '',

      field: UNDEFINED_AS_ANY,
      selected_field: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const loadedChallenge = useQuery(FIND_TOURNAMENT_STAGE_CHALLENGE_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        id: props.tournament_stage_challenge_id,
        player_id: loggedPlayer?.id,
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.tournament_id,
    },
  });
  const loadedComplexes = useQuery(FIND_COMPLEXES_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      options: {
        name: inputFields.complex_name,
        city: city?.name,
        country: country?.label,
      },
    },
  });
  const loadedFields = useQuery(FIND_FREE_FIELD_BY_COUNTRY_NAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      country_name: country?.label,
    },
  });
  const [fieldAvailability, isFieldAvailable] = useMutation(FIELD_AVAILABILITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [editTournamentPlayoffChallenge, updatedChallenge] = useMutation(EDIT_TOURNAMENT_PLAYOFF_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendLilenekNewSportSessionEmail, sentEmailNotification] = useMutation(SEND_LILENEK_NEW_SPORT_SESSION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendNotification, isSent] = useMutation(SEND_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const PREVIOUS_STAGES_TEAMS = _.uniqBy((props.current_game ?? [])?.children?.map((c: any) => _.concat(c?.data?.game?.home_team, c?.data?.game?.away_team))?.flat(), 'id');
  const ALL_CHALLENGERS = tournament?.selected_teams?.filter((t: any) => (PREVIOUS_STAGES_TEAMS?.length > 0 ? PREVIOUS_STAGES_TEAMS?.find((st: any) => st?.id === t?.id) : true));
  const ALL_COMPLEXES = loadedComplexes.data?.findComplexesByOptions;
  const CITIES: any = City.getCitiesOfCountry(inputFields?.country?.value || country?.value)?.filter((c: any) => _.startsWith(c?.name, _.capitalize(inputFields.city_name)));
  const ALL_FREE_FIELDS = loadedFields.data?.findFreeFieldsByCountry;
  const ALL_FIELDS = _.concat(ALL_FREE_FIELDS, inputFields?.complex ? inputFields?.complex?.fields : ALL_COMPLEXES?.map((c: any) => c?.fields)?.flat())?.filter((f: any) => f);

  const DefaultIcon = L.icon({
    iconUrl: '../../img/marker-icon.png',
    shadowUrl: iconShadow,
  });
  const SelectedIcon = L.icon({
    iconUrl: '../../img/active-marker-icon.png',
    shadowUrl: iconShadow,
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setCountry(country);
      setValue('country', country);
    }
  };

  const onChangeCity = (event: any, city: any) => {
    if (city) {
      setCity(city);
      setValue('city', city);
    }
  };

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  const handleStartDate = async (date: any) => {
    const d = new Date(date);
    const date_end = new Date(d.setHours(d.getHours() + 2));
    setValue('start_date', date);
    setValue('end_date', date_end);

    try {
      await handleFieldAvailability(inputFields.selected_field, date, date_end);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEndDate = async (date: any) => {
    setValue('end_date', date);
    try {
      await handleFieldAvailability(inputFields.selected_field, null, date);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFieldAvailability = async (field_id: any, start_date?: any, end_date?: any) => {
    try {
      if ((inputFields.start_date || start_date) && (inputFields.end_date || end_date) && field_id) {
        await fieldAvailability({
          variables: {
            field: {
              id: field_id,
              start_date: TO_TIMESTAMP((inputFields.start_date || start_date)?.toString()),
              end_date: TO_TIMESTAMP((inputFields.end_date || end_date)?.toString()),
            },
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleComplex = (value: any) => {
    setValue('complex', value);
    setOpenSearchComplex(false);
  };

  const handleSelectComplex = (value: any) => {
    setValue('complex', value);
  };

  const handleRemoveComplex = () => {
    setValue('complex', UNDEFINED_AS_ANY);
    setValue('field', '');
  };

  const handleOpenViewField = (selected_field: any) => {
    setValue('selected_field', selected_field?.id);
    setOpenViewField(true);
  };

  const handleCloseViewField = () => {
    setOpenViewField(false);
  };

  const handleSelectField = async (selected_field: any) => {
    try {
      const selected_complex = ALL_COMPLEXES?.find((c: any) => c?.fields?.find((f: any) => f?.id === selected_field?.id));
      if (selected_complex) {
        setValue('complex', selected_complex);
      }

      setValue('field', selected_field?.id);
      await handleFieldAvailability(selected_field?.id);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditChallenge = async () => {
    try {
      const challenge_duration = DURATION_IN_HOURS(TO_TIMESTAMP(inputFields.start_date?.toString()), TO_TIMESTAMP(inputFields.end_date?.toString()));
      const result = await editTournamentPlayoffChallenge({
        variables: {
          challenge: {
            id: challenge?.id,
            title: `${ALL_CHALLENGERS?.find((c: any) => c?.id === inputFields.challenger_a)?.name ?? 'Team'} vs ${ALL_CHALLENGERS?.find((c: any) => c?.id === inputFields.challenger_b)?.name ?? 'Team'}`,
            type: 'FRIENDLY',
            sport_type: tournament.sport,
            is_private: tournament?.is_private,
            start_date: TO_TIMESTAMP(inputFields.start_date?.toString()),
            end_date: TO_TIMESTAMP(inputFields.end_date?.toString()),
            home_team:
              inputFields.challenger_a === ''
                ? undefined
                : {
                    id: inputFields.challenger_a,
                  },
            away_team:
              inputFields.challenger_b === ''
                ? undefined
                : {
                    id: inputFields.challenger_b,
                  },
            complex_id: inputFields.complex?.id,
            arena_id: inputFields.field,
            cost: {
              value: challenge_duration > 0 ? challenge_duration * inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.value || 0 : undefined,
              currency: inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.rental_asset?.price?.currency?.currency_code || 'MAT',
            },
          },
        },
      });

      try {
        await sendNotification({
          variables: {
            notification: {
              title: `${intl.formatMessage({ id: 'Notification_Edit_Tournament_Playoff_Modal_Title' })}`,
              content: `${inputFields.complex?.fields?.find((f: any) => f?.id === inputFields.field)?.name} ${intl.formatMessage({ id: 'Notification_Edit_Tournament_Playoff_Modal_Content_1' })}`,
              actions: [],
              sender_id: loggedPlayer?.id,
              receivers_ids: [inputFields.complex?.owners?.find((o: any) => o?.__typename === 'Company')?.id],
            },
          },
        });
      } catch (e) {
        console.log(e);
      }

      try {
        await sendLilenekNewSportSessionEmail({
          variables: {
            account: {
              supplier_id: inputFields.complex?.owners?.find((o: any) => o?.__typename === 'Company')?.id,
              order_link: `https://beta.lilenek.com/dashboard`,
              host: window.location.origin,
              language: loggedPlayer?.app_configuration?.prefered_language,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }

      if (result) {
        handleClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (!_.isMatch(tournament, loadedTournament.data?.findTournamentByID) || (tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByID)) {
      setTournament(loadedTournament.data?.findTournamentByID);
    }
  }, [tournament, loadedTournament]);

  React.useEffect(() => {
    if (!challenge && loadedChallenge.data?.findTournamentStageChallengeToEditByID) {
      setChallenge(loadedChallenge.data?.findTournamentStageChallengeToEditByID);
    }

    if (challenge && props.open) {
      if (inputFields.challenger_a === undefined && challenge?.home_team?.id) setValue('challenger_a', challenge?.home_team?.id);
      if (inputFields.challenger_b === undefined && challenge?.away_team?.id) setValue('challenger_b', challenge?.away_team?.id);
      if (!inputFields.start_date && challenge?.start_date) setValue('start_date', new Date(challenge?.start_date));
      if (!inputFields.end_date && challenge?.end_date) setValue('end_date', new Date(challenge?.end_date));
      if (!inputFields.field && challenge?.arena?.id) setValue('field', challenge?.arena?.id);
      if (!inputFields.selected_field && challenge?.field?.id && ALL_FIELDS?.length > 0)
        setValue(
          'selected_field',
          ALL_FIELDS?.find((f: any) => f?.id === challenge?.arena?.id)
        );
      if (!inputFields.complex && challenge?.complex?.id && ALL_COMPLEXES?.length > 0)
        setValue(
          'complex',
          ALL_COMPLEXES?.find((c: any) => c?.id === challenge?.complex?.id)
        );

      if (!inputFields.country && (challenge?.field?.location?.country_code || challenge?.complex?.location?.country_code))
        setValue(
          'country',
          COUNTRIES?.find((c: any) => c?.value === challenge?.field?.location?.country_code || c?.value === challenge?.complex?.location?.country_code)
        );
      if (!country && (challenge?.field?.location?.country_code || challenge?.complex?.location?.country_code)) setCountry(COUNTRIES?.find((c: any) => c?.value === challenge?.field?.location?.country_code || c?.value === challenge?.complex?.location?.country_code) || INITIAL_COUNTRY);
    }

    if (challenge && !props.open) {
      if (inputFields.challenger_a) setValue('challenger_a', UNDEFINED_AS_ANY);
      if (inputFields.challenger_b) setValue('challenger_b', UNDEFINED_AS_ANY);
      if (inputFields.start_date) setValue('start_date', UNDEFINED_AS_ANY);
      if (inputFields.end_date) setValue('end_date', UNDEFINED_AS_ANY);
      if (inputFields.field) setValue('field', UNDEFINED_AS_ANY);
      if (inputFields.selected_field) setValue('selected_field', UNDEFINED_AS_ANY);
      if (inputFields.complex) setValue('complex', UNDEFINED_AS_ANY);
      if (inputFields.country) setValue('country', UNDEFINED_AS_ANY);
      if (country) setCountry(UNDEFINED_AS_ANY);
    }
  }, [props, challenge, loadedChallenge, inputFields, ALL_FIELDS, ALL_COMPLEXES]);

  return (
    <Dialog
      open={props.open}
      onClose={updatedChallenge.loading || sentEmailNotification.loading || isSent.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={updatedChallenge.loading || sentEmailNotification.loading || isSent.loading ? undefined : handleClose}
            disabled={updatedChallenge.loading || sentEmailNotification.loading || isSent.loading}
            className={updatedChallenge.loading || sentEmailNotification.loading || isSent.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}></Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Edit playoff game</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <Controller
          control={control}
          name="challenger_a"
          render={({ field }: any) => (
            <TextField {...field} value={field.value} select label={`challenger A`} placeholder="Select your team" variant="outlined" required sx={{ width: { xs: '100%', sm: '48%' }, m: { xs: '0', sm: '0 2% 0 0' } }} className={'field-bottom-space'}>
              {_.concat(
                { name: 'No team selected', value: '' },
                ALL_CHALLENGERS?.filter((c: any) => c?.id !== inputFields.challenger_b)
              )?.map((option: any) => (
                <MenuItem key={option?.id} value={option?.id ?? ''}>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Avatar src={option?.team?.avatar?.path} alt={option?.name} />
                    &nbsp;
                    <strong>{option?.name}</strong>
                  </Grid>
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={control}
          name="challenger_b"
          render={({ field }: any) => (
            <TextField {...field} value={field.value} select label={`challenger B`} placeholder="Select your team" variant="outlined" required sx={{ width: { xs: '100%', sm: '48%' }, m: { xs: '0', sm: '0 0 0 2%' } }} className={'field-bottom-space'}>
              {_.concat(
                { name: 'No team selected', value: '' },
                ALL_CHALLENGERS?.filter((c: any) => c?.id !== inputFields.challenger_a)
              )?.map((option: any) => (
                <MenuItem key={option?.id} value={option?.id ?? ''}>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Avatar src={option?.team?.avatar?.path} alt={option?.name} />
                    &nbsp;
                    <strong>{option?.name}</strong>
                  </Grid>
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          name="start_date"
          control={control}
          rules={{
            required: false,
          }}
          render={({ field }: any) => <DateTimePicker {...field} value={field.value} disablePast views={['day', 'month', 'hours', 'minutes']} openTo="day" format="d MMM yyyy HH:mm" renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />} ampm={false} sx={{ width: { xs: '100%', sm: '48%' }, m: { xs: '0', sm: '0 2% 0 0' } }} className={'field-bottom-space'} onChange={handleStartDate} label={'Start Date'} />}
        />

        <Controller
          name="end_date"
          control={control}
          rules={{
            required: false,
          }}
          render={({ field }: any) => (
            <DateTimePicker
              {...field}
              value={field.value}
              disablePast
              views={['day', 'month', 'hours', 'minutes']}
              minDate={inputFields.start_date}
              maxDate={new Date(inputFields.start_date || Date.now()).setFullYear(new Date(inputFields.start_date || Date.now()).getFullYear() + 1)}
              format="d MMM yyyy HH:mm"
              renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
              ampm={false}
              sx={{ width: { xs: '100%', sm: '48%' }, m: { xs: '0', sm: '0 0 0 2%' } }}
              className={'field-bottom-space'}
              onChange={handleEndDate}
              label={'End Date'}
            />
          )}
        />

        {!inputFields.start_date || !inputFields.end_date ? (
          ''
        ) : (
          <>
            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
              Where ?
            </Typography>
            <Controller
              control={control}
              name="country"
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  options={COUNTRIES}
                  value={country}
                  className={'country-select'}
                  onChange={(event: any, value: any) => onChangeCountry(event, value)}
                  autoHighlight
                  getOptionLabel={(option: CountryType) => option.label}
                  isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                  renderOption={(props: any, option: CountryType) => (
                    <Grid
                      component="li"
                      sx={{
                        '& > img': {
                          mr: 2,
                          flexShrink: 0,
                        },
                      }}
                      {...props}
                    >
                      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                      {option.label} ({option.value})
                    </Grid>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      value={country}
                      required
                      variant="outlined"
                      className={'field-bottom-space'}
                      error={errors?.country !== undefined}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="city"
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  options={CITIES}
                  value={city}
                  className={'city-select'}
                  onChange={(event: any, value: any) => onChangeCity(event, value)}
                  onInputChange={(event: any, newInputValue: any) => {
                    setValue('city_name', newInputValue);
                  }}
                  autoHighlight
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      component="li"
                      sx={{
                        '& > img': {
                          mr: 2,
                          flexShrink: 0,
                        },
                      }}
                      {...props}
                    >
                      {option?.name}
                    </Grid>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      value={city}
                      autoComplete="off"
                      variant="outlined"
                      className={'field-bottom-space'}
                      error={errors?.city !== undefined}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="all_complexes"
              control={control}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  open={openSearchComplex}
                  onOpen={() => setOpenSearchComplex(true)}
                  onClose={() => setOpenSearchComplex(false)}
                  multiple
                  autoHighlight
                  filterSelectedOptions
                  value={field.value}
                  loading={loadedComplexes.loading}
                  inputValue={inputFields.complex_name}
                  onInputChange={(event: any, newInputValue: any) => {
                    setValue('complex_name', newInputValue);
                  }}
                  options={ALL_COMPLEXES ? ALL_COMPLEXES : []}
                  getOptionLabel={(option: any) => option?.id}
                  defaultValue={ARRAY_AS_ANY}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      container
                      key={option?.id}
                      onClick={() => handleComplex(option)}
                      sx={{
                        alignItems: 'center',
                        p: '10px 15px',
                      }}
                    >
                      <Avatar src={option?.logo?.path} alt={option?.name} />
                      &nbsp;&nbsp;
                      <strong>{option?.name}</strong>
                    </Grid>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      label={`Search Complex`}
                      className={'field-bottom-space'}
                      placeholder={inputFields?.complex ? '' : `Write a complex name`}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Grid>
                              {inputFields.complex === undefined ? (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    m: '0px',
                                    p: '5px 20px',
                                    textAlign: 'center',
                                    bgcolor: 'rgba(0, 0, 0, .1)',
                                    borderRadius: '5px',
                                  }}
                                >
                                  No complex selected
                                </Typography>
                              ) : (
                                <Chip
                                  variant="outlined"
                                  label={<strong>{inputFields?.complex?.name}</strong>}
                                  avatar={
                                    <Avatar
                                      sx={{
                                        height: {
                                          xs: '40px !important',
                                          md: '60px !important',
                                          lg: '70px !important',
                                        },
                                        width: {
                                          xs: '40px !important',
                                          md: '60px !important',
                                          lg: '70px !important',
                                        },
                                      }}
                                      alt={inputFields?.complex?.name}
                                      src={inputFields?.complex?.logo?.path}
                                    />
                                  }
                                  onDelete={handleRemoveComplex}
                                  sx={{
                                    m: '5px',
                                    height: 'auto',
                                    border: 'none',
                                    color: 'rgba(247, 251, 250, 1)',
                                  }}
                                />
                              )}
                            </Grid>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="field"
              render={({ field }: any) => (
                <TextField {...field} value={field.value} name="field" select label="Field" placeholder="Select a field" variant="outlined" error={errors?.field !== undefined} required fullWidth className={'field-bottom-space'}>
                  {ALL_FIELDS?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id} onClick={() => handleSelectField(option)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Grid sx={{ 'overflow': 'auto', '& .leaflet-control-attribution': { display: 'none' } }}>
              <MapContainer center={inputFields?.complex?.location?.coordinates ? [inputFields?.complex?.location?.coordinates?.latitude, inputFields?.complex?.location?.coordinates?.longitude] : center_position} zoom={11} scrollWheelZoom={false} style={{ height: '400px' }}>
                <TileLayer attribution="" url={REACT_APP_CUSTOM_MAP_URL} />
                {ALL_COMPLEXES?.map((c: any, index: number) => (
                  <Grid key={index}>
                    <Marker position={c?.location?.coordinates ? [c?.location?.coordinates?.latitude, c?.location?.coordinates?.longitude] : center_position} icon={inputFields?.complex?.id === c?.id ? SelectedIcon : DefaultIcon}>
                      <Popup minWidth={150} maxWidth={290}>
                        <Grid onClick={() => handleSelectComplex(c)} container sx={{ 'justifyContent': 'center', 'alignItems': 'center', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                          <Avatar sx={{ height: '30px', width: '30px' }} src={c?.logo?.path} alt={c?.name} />
                          &nbsp;
                          <strong>{c?.name}</strong>
                        </Grid>
                      </Popup>
                    </Marker>
                  </Grid>
                ))}
              </MapContainer>
              <Grid sx={{ mt: '-120px', position: 'relative', zIndex: '1000', width: '100%', height: '200px', backgroundImage: `linear-gradient(to bottom, rgba(33,35,41,0) 0%, rgba(33,35,41,1) 40%)` }}>
                <Grid sx={{ overflow: 'auto', p: '0 10% 0', textAlign: 'center' }}>
                  <Stack
                    direction="row"
                    sx={{
                      m: '10px 0',
                      display: 'inline-flex',
                    }}
                  >
                    {ALL_FIELDS?.map((f: any, index: number) => (
                      <CardMedia
                        key={index}
                        onClick={() => handleSelectField(f)}
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'end',
                          display: 'flex',
                          borderRadius: '10px',
                          border: f?.id === inputFields.field ? (!isFieldAvailable.data?.fieldAvailability && inputFields.field !== challenge?.arena?.id ? 'solid 3px rgba(238, 44, 32, 1)' : 'solid 3px rgba(168, 202, 65, 1)') : 'none',
                          width: { xs: '200px', sm: '300px' },
                          height: { xs: '150px', sm: '150px' },
                          m: '0 30px 0 0',
                          backgroundImage: `linear-gradient(to bottom, rgba(15,15,15,0) 30%, rgba(15,15,15,.8) 60%, rgba(15,15,15,.95) 90%), url(${f?.pictures ? f?.pictures[0]?.path : ''})`,
                        }}
                      >
                        <Typography onClick={() => handleOpenViewField(f)} sx={{ 'textAlign': 'center', 'lineHeight': '15px', 'mb': '20px', 'fontWeight': '700', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                          {f?.id === inputFields.field ? (
                            <Typography className={inputFields.field === challenge?.arena?.id ? 'INFO' : 'DANGER'} component="span" sx={{ width: '100%', display: 'inline-block', fontWeight: '700' }}>
                              {!isFieldAvailable.data?.fieldAvailability ? (inputFields.field === challenge?.arena?.id ? 'CURRENT BOOKING' : 'ALREADY BOOKED') : ''}
                            </Typography>
                          ) : (
                            ''
                          )}
                          {f?.name}
                          <br />
                          <Typography variant="caption">({f?.rental_asset?.price?.value ? `${f?.rental_asset?.price?.currency?.currency_code} ${f?.rental_asset?.price?.value} / hour` : 'FREE'})</Typography>
                        </Typography>
                      </CardMedia>
                    ))}
                    <ViewFieldModal open={openViewField} handleClose={handleCloseViewField} field_id={ALL_FIELDS?.find((f: any) => f?.id === inputFields.selected_field)?.short_id} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid id="max-width" sx={{ p: '20px 10% 0', textAlign: 'center' }}>
              {tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) ? (
                <LoadingButton
                  onClick={handleEditChallenge}
                  loading={updatedChallenge.loading || sentEmailNotification.loading || isSent.loading}
                  loadingPosition="end"
                  endIcon={<Send />}
                  className={(!isFieldAvailable.data?.fieldAvailability && inputFields.field !== challenge?.arena?.id) || (inputFields.complex === undefined && inputFields.field === '') || !inputFields.start_date || !inputFields.end_date ? 'button-disabled' : 'button-green'}
                  disabled={(!isFieldAvailable.data?.fieldAvailability && inputFields.field !== challenge?.arena?.id) || (inputFields.complex === undefined && inputFields.field === '') || !inputFields.start_date || !inputFields.end_date}
                  sx={{ mr: '10px' }}
                >
                  Save updates
                </LoadingButton>
              ) : (
                ''
              )}
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

EditTournamentPlayoffGameModal.propTypes = {
  tournament_id: PropTypes.string.isRequired,
  tournament_stage_challenge_id: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
};

EditTournamentPlayoffGameModal.defaultProps = {
  tournament_id: '',
  tournament_stage_challenge_id: '',
};
