import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Fab } from '@mui/material';
import { Close } from '@mui/icons-material';
import ListNotifications from '../../components/profile/ListNotifications';

export default function LatestNotificationsModal(props: { handleClose: any; open: any }) {
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      <Fab size="small" className="button-cancel" onClick={handleClose} sx={{ boxShadow: 'none', ml: '2%', mt: '2%' }}>
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        {/* <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Latest notifications</Typography> */}
      </DialogTitle>
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <ListNotifications dark_mode={false} />
      </DialogContent>
    </Dialog>
  );
}

LatestNotificationsModal.propTypes = {
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

LatestNotificationsModal.defaultProps = {};
