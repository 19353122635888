import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider, Fab } from '@mui/material';
import { Send, Clear, Close } from '@mui/icons-material';
import { WarningIcon } from '../../img/icons/Icons';

export default function Modal(props: { icon?: any; title: string; content: any; secondaryContent?: string; actionOneTitle: string; actionOne: any; actionTwoTitle: string; actionTwo: any; open: any; loading?: boolean }) {
  return (
    <Dialog
      open={props.open}
      onClose={props.actionTwo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={props.actionTwo}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      {props.title ? (
        <>
          <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
            {props.icon}
            <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{props.title}</Typography>
          </DialogTitle>
          <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
        </>
      ) : (
        ''
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Typography sx={{ m: '0 0 20px', color: 'rgba(247, 251, 250, 1)', fontSize: '1.2em' }}>{props.content}</Typography>
          {props.secondaryContent !== '' ? (
            <Typography variant="caption" className="WARNING" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <WarningIcon className="WARNING" sx={{ fontSize: '1.2em' }} />
              {props.secondaryContent}
            </Typography>
          ) : (
            ''
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton onClick={props.actionOne} className={'actionOne button-green'} fullWidth loadingPosition="end" endIcon={<Send />} loading={props.loading}>
          {props.actionOneTitle}
        </LoadingButton>
        <Button onClick={props.actionTwo} className={'actionTwo button-cancel'} fullWidth endIcon={<Clear />}>
          {props.actionTwoTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Modal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  secondaryContent: PropTypes.any,
  actionOneTitle: PropTypes.any,
  actionTwoTitle: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.any,
};

Modal.defaultProps = {
  secondaryContent: '',
  loading: false,
};
