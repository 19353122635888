import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import SwipeableViews from 'react-swipeable-views';
import _ from 'lodash';
import * as bip39 from 'bip39';
import { AppBar, Tab, Tabs, Fab, InputAdornment, IconButton, MenuItem, Alert, CircularProgress, Chip, Stepper, StepButton, StepContent, Step, Checkbox, FormControlLabel, FormControl, FormHelperText, FormGroup, Typography, TextField, Button, Box, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send, Visibility, VisibilityOff } from '@mui/icons-material';
import { FivezerLogoIcon, WalletIcon } from '../../img/icons/Icons';
import { BLOCKCHAIN_CURRENCIES, COUNTRIES } from '../../utilities/utilities';
import BackButton from '../../component-modals/common/BackButton';
import SpendingCodeModal from '../../component-modals/purchase/SpendingCodeModal';

import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectPersonalWallets, selectBusinessWallets } from '../../reducers/walletSlice';
import { ARRAY_AS_ANY, INITIAL_COUNTRY } from '../../utilities/CommonInterfaces';
import { Link } from 'react-router-dom';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';

interface IFormNewBlockchainAccount {
  wallet: { id: string; type: string; owner: any };
}

const FormNewBlockchainAccount: React.FC = () => {
  const CREATE_BLOCKCHAIN_ACCOUNT_MUTATION = gql`
    mutation createBlockchainAccount($account: inputCreateBlockchainAccount!) {
      createBlockchainAccount(account: $account)
    }
  `;

  const VERIFICATION_SPENDING_PASSWORD_MUTATION = gql`
    mutation verificationSpendingPassword($user: inputVerificationSpendingPassword!) {
      verificationSpendingPassword(user: $user)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerPersonalWallets = useAppSelector(selectPersonalWallets);
  const loggedPlayerBusinessWallets = useAppSelector(selectBusinessWallets);

  const location: any = useLocation();
  const state: IFormNewBlockchainAccount = location?.state;

  const [wallet] = React.useState(_.concat(loggedPlayerPersonalWallets, loggedPlayerBusinessWallets).find((wallet: any) => wallet.id === state?.wallet?.id) || _.concat(loggedPlayerPersonalWallets, loggedPlayerBusinessWallets)[0]);
  const nb_words = 24;
  const min_code_length = 10;
  const [mnemonicSentence, setMnemonicSentence] = React.useState<string[]>([]);
  const [mnemonicSentenceRebuilded, setMnemonicSentenceRebuilded] = React.useState<string[]>([]);
  const [mnemonicSentenceRecovered, setMnemonicSentenceRecovered] = React.useState<string[]>([]);
  const [randomWords, setRandomWords] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeRecoverStep, setActiveRecoverStep] = React.useState(0);
  const [tab, setTab] = React.useState(0);
  const [hidePassword, setHidePassword] = React.useState(true);
  const [notices, setNotices] = React.useState({
    notice_1: false,
    notice_2: false,
    notice_3: false,
    save_key: true,
  });
  const { notice_1, notice_2, notice_3, save_key } = notices;
  const error = [notice_1, notice_2].filter((v) => v).length !== 2;

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      title: '',
      currency: 'MAT',
      spending_code: '',
      all_currencies: ARRAY_AS_ANY,
      accepted_currencies: ARRAY_AS_ANY,
      all_countries: ARRAY_AS_ANY,
      country: INITIAL_COUNTRY,
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();

  const [createBlockchainAccount, newBlockchainAccount] = useMutation(CREATE_BLOCKCHAIN_ACCOUNT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [verificationSpendingPassword, verificationResult] = useMutation(VERIFICATION_SPENDING_PASSWORD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNextRecover = () => {
    setActiveRecoverStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackRecover = () => {
    setActiveRecoverStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepRecover = (step: number) => () => {
    setActiveRecoverStep(step);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeIndex = (index: number) => {
    setTab(index < 2 ? index : 1);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue < 2 ? newValue : 1);
  };

  const handleSelectCountry = (country: any) => {
    setValue('country', country);
  };

  const handleUnselectCurrency = (country: any) => {
    const newList = inputFields.accepted_currencies?.filter((c: any) => c?.value !== country?.value);
    setValue('accepted_currencies', newList);
  };

  const handleMnemonicSentence = () => {
    try {
      // 224 = 21 - 256 = 24
      const sentence = bip39.generateMnemonic(256, undefined, mmemonic);
      const isValid = bip39.validateMnemonic(sentence);

      if (isValid) {
        setMnemonicSentence(_.words(sentence));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotices({ ...notices, [event.target.name]: event.target.checked });
  };

  const handleRebuild = (data: string) => {
    const newPPR = mnemonicSentenceRebuilded;
    newPPR.push(data);
    setMnemonicSentenceRebuilded(newPPR);

    const newRW = randomWords.filter((w: any, i: number, r: any) => i !== r.indexOf(data));
    setRandomWords(newRW);
  };

  const handleTakeBack = (data: string) => {
    const newPPR = mnemonicSentenceRebuilded.filter((w: any, i: number, r: any) => i !== r.indexOf(data));
    setMnemonicSentenceRebuilded(newPPR);

    const newRW = randomWords;
    newRW.push(data);
    setRandomWords(newRW);
  };

  const handleRecover = (data: string) => {
    const newPPR = mnemonicSentenceRecovered;
    newPPR.push(data);
    setMnemonicSentenceRecovered(newPPR);

    const newRW = randomWords.filter((w: any, i: number, r: any) => i !== r.indexOf(data));
    setRandomWords(newRW);
  };

  const handleTakeRecoverBack = (data: string) => {
    const newPPR = mnemonicSentenceRecovered.filter((w: any, i: number, r: any) => i !== r.indexOf(data));
    setMnemonicSentenceRecovered(newPPR);

    const newRW = randomWords;
    newRW.push(data);
    setRandomWords(newRW);
  };

  const handleConfirmSpendingCode = async () => {
    try {
      if (activeStep === 0 && !verificationResult.data?.verificationSpendingPassword && inputFields.spending_code?.length >= min_code_length) {
        try {
          const result = await verificationSpendingPassword({
            variables: {
              user: {
                code: inputFields.spending_code,
                logged_user_id: loggedPlayer?.id,
                logged_user_username: loggedPlayer?.username,
              },
            },
          });

          if (result.data?.verificationSpendingPassword === false) {
            setValue('spending_code', '');
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async () => {
    setOpen(true);
  };

  const handleConfirmation = async (isCodeValide: boolean) => {
    try {
      if (isCodeValide) {
        await createBlockchainAccount({
          variables: {
            account: {
              creator_id: loggedPlayer?.id,
              owner_id: state?.wallet?.owner?.id,
              owner_name: state?.wallet?.owner?.username ? state?.wallet?.owner?.username : state?.wallet?.owner?.name,
              wallet_id: wallet?.id,
              title: inputFields.title,
              type: wallet?.type,
              mnemonic_sentence: mnemonicSentenceRecovered?.length >= 15 ? mnemonicSentenceRecovered : mnemonicSentence,
              passphrase: inputFields.spending_code,
              save_key: save_key,
              currencies: inputFields.accepted_currencies?.map((c: any) => c?.value),
              country_code: inputFields.country?.value,
              country_name: inputFields.country?.label,
            },
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const NEW_ACCOUNT_STEPS = [
    {
      label: 'Basic Information',
      content: (
        <Grid>
          <Typography variant="body2" component="span" className={'info'} sx={{ pb: '20px', display: 'inline-block' }}>
            <h2>IMPORTANT: Information about the Mnemonic Sentence (private key)</h2>
            On the following screen, you will see a set of {nb_words} random words.
            <br />
            This is your wallet recovery phrase.
            <br />
            <br />
            It can be entered in any version of Lipay in order to back up or restore your wallet’s funds and private key.
            <br />
            <br />
            Make sure nobody is looking at your screen unless you want them to have access to your funds.
          </Typography>
          <Box className={'fields'} component={'span'}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: true,
                maxLength: 30,
                minLength: 4,
                pattern: /^[a-z0-9_]*$/,
              }}
              render={({ field }: any) => <TextField {...field} label="Account title" placeholder="Input an account title" variant="outlined" fullWidth autoFocus className={'field-bottom-space'} />}
            />

            {inputFields?.accepted_currencies?.length === 0 ? (
              ''
            ) : (
              <Grid container sx={{ justifyContent: 'center', mb: '20px' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '1.2em', display: 'inline-block', width: '100%', textAlign: 'center' }}>Accepted currencies</Typography>

                {inputFields?.accepted_currencies?.map((c: any) => (
                  <Chip
                    key={c?.value}
                    label={
                      <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                        <img loading="lazy" width="20" src={c?.country_code !== 'AU' ? `https://flagcdn.com/w20/${_.split(c?.country_code, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(c?.country_code, '_', 1)[0].toLowerCase()}.png`} srcSet={c?.country_code !== 'AU' ? `https://flagcdn.com/w40/${_.split(c?.country_code, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(c?.country_code, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                        &nbsp;{c?.label}
                      </Typography>
                    }
                    sx={{ m: '10px 10px 0 0' }}
                    onDelete={() => handleUnselectCurrency(c)}
                  />
                ))}
              </Grid>
            )}
            <Controller
              name="all_currencies"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => (
                <TextField {...field} value={field.value} select label="Account Currency" placeholder="Select your currency" variant="outlined" required fullWidth className={'field-bottom-space'}>
                  {BLOCKCHAIN_CURRENCIES.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      [{option.sign}] {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="all_countries"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => (
                <TextField {...field} value={field.value} select label={`Account originate from ${inputFields?.country?.label}`} variant="outlined" required fullWidth className={'field-bottom-space'}>
                  {COUNTRIES?.filter((c: any) => inputFields?.country?.value !== c?.value).map((option: any) => (
                    <MenuItem key={option.value} value={option?.value} onClick={() => handleSelectCountry(option)}>
                      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                      &nbsp;{option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="spending_code"
              control={control}
              defaultValue=""
              rules={{ required: true, minLength: min_code_length, maxLength: 255 }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label="Spending Password"
                  type={hidePassword ? 'password' : 'text'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                          {hidePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <Fab variant="extended" size="small" disabled={inputFields.spending_code?.length < min_code_length} className={inputFields.spending_code?.length < min_code_length ? 'button-disabled' : 'button-green-reverse'} sx={{ boxShadow: 'none' }} onClick={handleConfirmSpendingCode}>
                        Confirm
                      </Fab>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      ),
    },
    {
      label: 'Mnemonic Sentence (private key)',
      content: (
        <Grid>
          <Typography variant="body2" component="span" className={'info'} sx={{ pb: '20px', display: 'inline-block' }}>
            <p className={'content'}>
              Please, make sure you have carefully written down your recovery Mnemonic Sentence somewhere safe. You will need this sentence to use and restore your wallet.
              <br />
              Mnemonic Sentence is case sensitive.
            </p>
          </Typography>
          <Box className={'fields'} component={'span'}>
            <Grid
              className="mnemonic_sentence-container"
              sx={{
                background: '#FFF',
                border: 'solid 1px #bdbdbd',
                borderRadius: '10px',
                p: '3%',
                mb: '20px',
                textAlign: 'center',
              }}
            >
              {mnemonicSentence?.map((word: string, index: number) => (
                <Chip key={index} label={word} sx={{ m: '10px' }} />
              ))}
            </Grid>

            <Box className={'confirmation'}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.3em',
                  m: '30px 3% 0',
                }}
              >
                IMPORTANT
              </Typography>
              <FormControl required error={error} component="fieldset">
                {error ? (
                  <FormHelperText sx={{ m: '0 3%' }}>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      You must check all the boxes to confirm your consent (except optional)
                    </Typography>
                  </FormHelperText>
                ) : (
                  ''
                )}
                <FormGroup sx={{ 'm': '30px 3% 0', '& label': { mb: '20px' } }}>
                  <FormControlLabel control={<Checkbox checked={notice_1} required onChange={handleChange} name="notice_1" className="green-checkbox" />} label="I understand that my secret keys are held securely on this device only, not on the company’s server" />
                  <FormControlLabel control={<Checkbox checked={notice_2} required onChange={handleChange} name="notice_2" className="green-checkbox" />} label="I understand that if this application is moved to another device or deleted, my funds can be only recovered with the backup phrase that I have written down and saved in a secure place." />
                  <FormControlLabel control={<Checkbox checked={save_key} onChange={handleChange} name="save_key" className="green-checkbox" />} label="(OPTIONAL) I accept to temporarly store my Mnemonic Sentence on Lilenek; but in case of a breach, Lilenek is NOT responsible for any stolen assets, and I will be the only responsible." />
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      ),
    },
    {
      label: 'Mnemonic Sentence Verification',
      content: (
        <Grid>
          <Typography variant="body2" component="span" className={'info'} sx={{ pb: '20px', display: 'inline-block' }}>
            <strong>Reconstitution of the Mnemonic Sentence</strong>
            <p>To make sure that you correctly wrote the Mnemonic Sentence, rebuild it in the right order.</p>
          </Typography>
          <Box className={'fields'} component={'span'}>
            <Typography sx={{ textAlign: 'center', p: '10px' }}>Rebuiled Mnemonic Sentence</Typography>

            <Grid
              className="mnemonic_sentence-container"
              sx={{
                background: '#FFF',
                border: 'solid 1px #bdbdbd',
                borderRadius: '10px',
                p: '3%',
                textAlign: 'center',
              }}
            >
              {mnemonicSentenceRebuilded?.map((word: string, index: number) => (
                <Chip key={index} label={word} onClick={() => handleTakeBack(word)} sx={{ m: '10px' }} />
              ))}
            </Grid>
            {_.isEqual(mnemonicSentence, mnemonicSentenceRebuilded) ? (
              <Alert severity="success">
                <strong>It&apos;s a match</strong>
                <br />
                You correctly rebuiled your passphrase
              </Alert>
            ) : (
              <Grid>
                {_.isEqual(mnemonicSentence, mnemonicSentenceRebuilded) === false && mnemonicSentenceRebuilded?.length === mnemonicSentence?.length ? (
                  <Alert severity="error">
                    <strong>It&apos;s not a match</strong>
                    <br />
                    Make sure that the order of the Mnemonic Sentence match what you wrote, if not click on the word to remove it.
                  </Alert>
                ) : (
                  ''
                )}
                <Typography
                  className={'helpText'}
                  variant="caption"
                  sx={{
                    textAlign: 'center',
                    p: '10px',
                    display: 'inline-flex',
                  }}
                >
                  {mnemonicSentenceRebuilded?.length === mnemonicSentence?.length ? '' : 'Click on a word to add/remove it from a section.'}
                </Typography>
              </Grid>
            )}
            {mnemonicSentenceRebuilded?.length === mnemonicSentence?.length ? (
              ''
            ) : (
              <Grid
                className="mnemonicSentence-container"
                sx={{
                  background: '#FFF',
                  border: 'solid 1px #bdbdbd',
                  borderRadius: '10px',
                  p: '3%',
                  textAlign: 'center',
                }}
              >
                {randomWords?.map((word: string, index: number) => (
                  <Chip key={index} label={word} onClick={() => handleRebuild(word)} sx={{ m: '10px' }} />
                ))}
              </Grid>
            )}
          </Box>
          {mnemonicSentenceRebuilded?.length === mnemonicSentence?.length ? (
            <Box className={'confirmation'}>
              <h2>IMPORTANT</h2>
              <FormControl required error={!notice_3} component="fieldset">
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={notice_3} onChange={handleChange} name="notice_3" className="green-checkbox" />} label="I understand that the only way to recover my wallet if my device is lost, broken, stolen, or stop working is to use my wallet recovery phrase." />
                </FormGroup>
              </FormControl>
            </Box>
          ) : (
            ''
          )}
        </Grid>
      ),
    },
  ];

  const RESTORE_ACCOUNT_STEPS = [
    {
      label: 'Basic Information',
      content: (
        <Grid>
          <Typography variant="body2" component="span" className={'info'} sx={{ pb: '20px', display: 'inline-block' }}>
            <h2>IMPORTANT: Information about the Mnemonic Sentence (private key)</h2>
            On the following screen, you will write IN SAME ORDER, the words that your Mnemonic Sentence is composed.
            <br />
            <br />
            It can be entered in any version of Lipay in order to back up or restore your wallet’s funds and private key.
            <br />
            <br />
            Make sure nobody is looking at your screen unless you want them to have access to your funds.
          </Typography>
          <Box className={'fields'} component={'span'}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: true,
                maxLength: 30,
                minLength: 4,
                pattern: /^[a-z0-9_]*$/,
              }}
              render={({ field }: any) => <TextField {...field} label="Account title" placeholder="Input an account title" variant="outlined" fullWidth autoFocus className={'field-bottom-space'} />}
            />

            <Controller
              name="spending_code"
              control={control}
              defaultValue=""
              rules={{ required: true, minLength: min_code_length, maxLength: 255 }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label="Spending Password"
                  type={hidePassword ? 'password' : 'text'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                          {hidePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <Fab variant="extended" size="small" disabled={inputFields.spending_code?.length < min_code_length} className={inputFields.spending_code?.length < min_code_length ? 'button-disabled' : 'button-green-reverse'} sx={{ boxShadow: 'none' }} onClick={handleConfirmSpendingCode}>
                        Confirm
                      </Fab>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      ),
    },
    {
      label: 'Mnemonic Sentence (private key)',
      content: (
        <Grid>
          <Typography variant="body2" component="span" className={'info'} sx={{ pb: '20px', display: 'inline-block' }}>
            <p className={'content'}>
              Please, make sure you have carefully written down your recovery Mnemonic Sentence somewhere safe. You will need this sentence to use and restore your wallet.
              <br />
              Mnemonic Sentence is case sensitive.
            </p>
          </Typography>
          <Box className={'fields'} component={'span'}>
            <Typography sx={{ textAlign: 'center', p: '10px' }}>Rebuiled Mnemonic Sentence</Typography>

            <Grid
              className="mnemonic_sentence-container"
              sx={{
                background: '#FFF',
                border: 'solid 1px #bdbdbd',
                borderRadius: '10px',
                p: '3%',
                mb: '20px',
                textAlign: 'center',
              }}
            >
              {mnemonicSentenceRecovered?.map((word: string, index: number) => (
                <Chip key={index} label={word} onClick={() => handleTakeRecoverBack(word)} sx={{ m: '10px' }} />
              ))}
            </Grid>
            {mnemonicSentenceRecovered?.length >= 15 && mnemonicSentenceRecovered?.length <= 24 ? (
              <Alert severity="success">
                <strong>Your sentence has the right range (15-24 words)</strong>
                <br />
                If you have entered all the words, you can continue to finalize the restoration
              </Alert>
            ) : (
              ''
            )}

            {mnemonicSentenceRecovered?.length > 24 ? (
              <Alert severity="error">
                <strong>Too many words</strong>
                <br />
                Make sure that the order AND amount of words of the Mnemonic Sentence match what you wrote, if not click on the word to remove it.
              </Alert>
            ) : (
              ''
            )}

            <Grid
              className="mnemonicSentence-container"
              sx={{
                background: '#FFF',
                border: 'solid 1px #bdbdbd',
                borderRadius: '10px',
                p: '3%',
                mt: '20px',
                textAlign: 'center',
                maxHeight: '300px',
                overflow: 'auto',
              }}
            >
              {mmemonic?.map((word: string, index: number) => (
                <Chip key={index} label={word} onClick={() => handleRecover(word)} sx={{ m: '10px' }} />
              ))}
            </Grid>
          </Box>
          {mnemonicSentenceRecovered?.length >= 15 && mnemonicSentenceRecovered?.length <= 24 ? (
            <Box className={'confirmation'}>
              <h2>IMPORTANT</h2>
              <FormControl required error={error || !notice_3} component="fieldset">
                {error ? (
                  <FormHelperText sx={{ m: '0 3%' }}>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      You must check all the boxes to confirm your consent (except optional)
                    </Typography>
                  </FormHelperText>
                ) : (
                  ''
                )}
                <FormGroup sx={{ 'm': '30px 3% 0', '& label': { mb: '20px' } }}>
                  <FormControlLabel control={<Checkbox checked={notice_1} required onChange={handleChange} name="notice_1" className="green-checkbox" />} label="I understand that my secret keys are held securely on this device only, not on the company’s server" />
                  <FormControlLabel control={<Checkbox checked={notice_2} required onChange={handleChange} name="notice_2" className="green-checkbox" />} label="I understand that if this application is moved to another device or deleted, my funds can be only recovered with the backup phrase that I have written down and saved in a secure place." />
                  <FormControlLabel control={<Checkbox checked={save_key} onChange={handleChange} name="save_key" className="green-checkbox" />} label="(OPTIONAL) I accept to temporarly store my Mnemonic Sentence on Lilenek; but in case of a breach, Lilenek is NOT responsible for any stolen assets, and I will be the only responsible." />
                  <FormControlLabel control={<Checkbox checked={notice_3} onChange={handleChange} name="notice_3" className="green-checkbox" />} label="I understand that the only way to recover my wallet if my device is lost, broken, stolen, or stop working is to use my wallet recovery phrase." />
                </FormGroup>
              </FormControl>
            </Box>
          ) : (
            ''
          )}
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (mnemonicSentence.length === 0) {
      handleMnemonicSentence();
    }

    if (randomWords.length === 0 && mnemonicSentence.length > 0 && mnemonicSentenceRebuilded.length <= 0) {
      setRandomWords(_.shuffle(mnemonicSentence));
    }
  }, [mnemonicSentence, setRandomWords, randomWords, mnemonicSentenceRebuilded, inputFields, verificationResult, verificationSpendingPassword, loggedPlayer, activeStep, setValue]);

  React.useEffect(() => {
    if (newBlockchainAccount.called && newBlockchainAccount.data?.createBlockchainAccount) {
      navigate('/w/wallet', {
        state: { wallet: { id: wallet?.id, type: wallet?.type } },
        replace: true,
      });
    }
  }, [createBlockchainAccount, newBlockchainAccount, navigate, wallet, inputFields, state, loggedPlayer, mnemonicSentence, mnemonicSentenceRebuilded, mnemonicSentenceRecovered, save_key]);

  return (
    <Grid container className={'form-page component-preferences new-blockchain-account-page'}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Typography variant="h1" sx={{ p: '0 10%', fontSize: { xs: '1.4em', sm: '1.6em', md: '2em' }, fontWeight: '800' }}>
          New Lipay account <Typography variant="caption">({wallet?.title})</Typography>
        </Typography>

        <AppBar
          position="static"
          sx={{
            bgcolor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'rgba(168, 202, 65, 1)',
              },
            }}
          >
            <Tab
              label={`New account`}
              {...a11yProps(0)}
              sx={{
                '&.Mui-selected': {
                  color: 'rgba(168, 202, 65, 1)',
                },
                ':hover': {
                  color: 'rgba(168, 202, 65, 1)',
                },
              }}
            />
            <Tab
              label={`Restore account`}
              {...a11yProps(1)}
              sx={{
                '&.Mui-selected': {
                  color: 'rgba(168, 202, 65, 1)',
                },
                ':hover': {
                  color: 'rgba(168, 202, 65, 1)',
                },
              }}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'} index={tab} onChangeIndex={handleChangeIndex}>
          <TabPanel value={tab} index={0} dir={'ltr'}>
            <Grid id="max-width" sx={{ p: '0 10%' }} item xs={12} md={12} lg={12}>
              <form className={'form-root'} onSubmit={handleSubmit(onSubmit)}>
                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                  {NEW_ACCOUNT_STEPS.map((step: any, index: number) => (
                    <Step key={step.label}>
                      <StepButton
                        color="inherit"
                        onClick={handleStep(index)}
                        sx={{
                          display: activeStep >= index ? 'inline-block' : 'none',
                        }}
                      >
                        {step.label}
                      </StepButton>
                      <StepContent>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: activeStep >= index ? 'inline-block' : 'none',
                          }}
                        >
                          {step.content}
                        </Grid>
                        <Box sx={{ pt: '50px' }}>
                          <Box>
                            {activeStep === 0 ? (
                              ''
                            ) : (
                              <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                                Back
                              </Button>
                            )}
                            <Button
                              onClick={handleNext}
                              className={!verificationResult.data?.verificationSpendingPassword || inputFields.title === '' || (activeStep === 1 && error) || (activeStep === 2 && !notice_3) || (activeStep === 2 && !_.isEqual(mnemonicSentence, mnemonicSentenceRebuilded)) || (activeStep === 2 && mnemonicSentenceRebuilded.length !== mnemonicSentence?.length) ? 'button-disabled' : 'button-green'}
                              disabled={!verificationResult.data?.verificationSpendingPassword || inputFields.title === '' || (activeStep === 1 && error) || (activeStep === 2 && !notice_3) || (activeStep === 2 && !_.isEqual(mnemonicSentence, mnemonicSentenceRebuilded)) || (activeStep === 2 && mnemonicSentenceRebuilded.length !== mnemonicSentence?.length)}
                            >
                              {activeStep === NEW_ACCOUNT_STEPS.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                          </Box>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>

                <Box className={'next-move'} component={'span'}>
                  {activeStep === NEW_ACCOUNT_STEPS.length ? (
                    <Box>
                      <Box className={'content'}>
                        <h2>Perfect! You made it all the way through</h2>
                        With this account, you will enable new features that will help you in your daily activities, save you time and money.
                        <Box
                          className={'actions'}
                          sx={{
                            textAlign: 'center',
                            p: '20px 0',
                          }}
                        >
                          {newBlockchainAccount.loading ? (
                            <Box sx={{ textAlign: 'center' }}>
                              <CircularProgress className="orange-loader" />
                              <br />
                              Processing your information
                              <br />
                              please wait...
                            </Box>
                          ) : (
                            <Box>
                              <LoadingButton className={'button-green'} type="submit" loading={newBlockchainAccount.loading} loadingPosition="end" endIcon={<Send />} sx={{ m: '0 10px' }}>
                                Create Blockchain Account
                              </LoadingButton>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              </form>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1} dir={'ltr'}>
            <Typography sx={{ textAlign: 'center', fontWeight: '700' }}>Coming soon</Typography>

            <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ display: 'none' }}>
              <form className={'form-root'}>
                <Stepper nonLinear activeStep={activeRecoverStep} orientation="vertical">
                  {RESTORE_ACCOUNT_STEPS.map((step: any, index: number) => (
                    <Step key={step.label}>
                      <StepButton
                        color="inherit"
                        onClick={handleStepRecover(index)}
                        sx={{
                          display: activeRecoverStep >= index ? 'inline-block' : 'none',
                        }}
                      >
                        {step.label}
                      </StepButton>
                      <StepContent>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{
                            display: activeRecoverStep >= index ? 'inline-block' : 'none',
                          }}
                        >
                          {step.content}
                        </Grid>
                        <Box sx={{ pt: '50px' }}>
                          <Box>
                            {activeRecoverStep === 0 ? (
                              ''
                            ) : (
                              <Button disabled={activeRecoverStep === 0} onClick={handleBackRecover} className={'button-cancel'}>
                                Back
                              </Button>
                            )}
                            <Button
                              onClick={handleNextRecover}
                              className={
                                !verificationResult.data?.verificationSpendingPassword ||
                                inputFields.title === '' ||
                                (activeRecoverStep === 1 && error) ||
                                (activeRecoverStep === 1 && error) ||
                                (activeRecoverStep === 2 && !notice_3) ||
                                (activeRecoverStep === 1 && !notice_3) ||
                                (activeRecoverStep === 2 && !_.isEqual(mnemonicSentence, mnemonicSentenceRebuilded)) ||
                                (activeRecoverStep === 1 && mnemonicSentenceRebuilded?.length < 15 && mnemonicSentenceRebuilded?.length > 24) ||
                                (activeRecoverStep === 2 && mnemonicSentenceRebuilded.length !== mnemonicSentence?.length) ||
                                (activeRecoverStep === 1 && mnemonicSentenceRebuilded?.length < 15 && mnemonicSentenceRebuilded?.length > 24)
                                  ? 'button-disabled'
                                  : 'button-green'
                              }
                              disabled={
                                !verificationResult.data?.verificationSpendingPassword ||
                                inputFields.title === '' ||
                                (activeRecoverStep === 1 && error) ||
                                (activeRecoverStep === 2 && !notice_3) ||
                                (activeRecoverStep === 1 && !notice_3) ||
                                (activeRecoverStep === 2 && !_.isEqual(mnemonicSentence, mnemonicSentenceRebuilded)) ||
                                (activeRecoverStep === 1 && mnemonicSentenceRebuilded?.length < 15 && mnemonicSentenceRebuilded?.length > 24) ||
                                (activeRecoverStep === 2 && mnemonicSentenceRebuilded.length !== mnemonicSentence?.length) ||
                                (activeRecoverStep === 1 && mnemonicSentenceRebuilded?.length < 15 && mnemonicSentenceRebuilded?.length > 24)
                              }
                            >
                              {activeRecoverStep === RESTORE_ACCOUNT_STEPS.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                          </Box>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>

                <Box className={'next-move'} component={'span'}>
                  {activeRecoverStep === RESTORE_ACCOUNT_STEPS.length ? (
                    <Box>
                      <Box className={'content'}>
                        <h2>Perfect! You made it all the way through</h2>
                        With this account, you will enable new features that will help you in your daily activities, save you time and money.
                        <Box
                          className={'actions'}
                          sx={{
                            textAlign: 'center',
                            p: '20px 0',
                          }}
                        >
                          {newBlockchainAccount.loading ? (
                            <Box sx={{ textAlign: 'center' }}>
                              <CircularProgress className="orange-loader" />
                              <br />
                              Processing your information
                              <br />
                              please wait...
                            </Box>
                          ) : (
                            <Box>
                              <LoadingButton className={'button-green'} type="submit" loading={newBlockchainAccount.loading} loadingPosition="end" endIcon={<Send />} sx={{ m: '0 10px' }}>
                                Restore Blockchain Account
                              </LoadingButton>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              </form>
            </Grid>
          </TabPanel>
        </SwipeableViews>
        <SpendingCodeModal icon={<WalletIcon />} actionOne={handleConfirmation} actionTwo={() => setOpen(false)} open={open} />
      </Grid>
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Grid>
          <Grid>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default FormNewBlockchainAccount;

const mmemonic = [
  'abandon',
  'ability',
  'able',
  'about',
  'above',
  'absent',
  'absorb',
  'abstract',
  'absurd',
  'abuse',
  'access',
  'accident',
  'account',
  'accuse',
  'achieve',
  'acid',
  'acoustic',
  'acquire',
  'across',
  'act',
  'action',
  'actor',
  'actress',
  'actual',
  'adapt',
  'add',
  'addict',
  'address',
  'adjust',
  'admit',
  'adult',
  'advance',
  'advice',
  'aerobic',
  'affair',
  'afford',
  'afraid',
  'again',
  'age',
  'agent',
  'agree',
  'ahead',
  'aim',
  'air',
  'airport',
  'aisle',
  'alarm',
  'album',
  'alcohol',
  'alert',
  'alien',
  'all',
  'alley',
  'allow',
  'almost',
  'alone',
  'alpha',
  'already',
  'also',
  'alter',
  'always',
  'amateur',
  'amazing',
  'among',
  'amount',
  'amused',
  'analyst',
  'anchor',
  'ancient',
  'anger',
  'angle',
  'angry',
  'animal',
  'ankle',
  'announce',
  'annual',
  'another',
  'answer',
  'antenna',
  'antique',
  'anxiety',
  'any',
  'apart',
  'apology',
  'appear',
  'apple',
  'approve',
  'april',
  'arch',
  'arctic',
  'area',
  'arena',
  'argue',
  'arm',
  'armed',
  'armor',
  'army',
  'around',
  'arrange',
  'arrest',
  'arrive',
  'arrow',
  'art',
  'artefact',
  'artist',
  'artwork',
  'ask',
  'aspect',
  'assault',
  'asset',
  'assist',
  'assume',
  'asthma',
  'athlete',
  'atom',
  'attack',
  'attend',
  'attitude',
  'attract',
  'auction',
  'audit',
  'august',
  'aunt',
  'author',
  'auto',
  'autumn',
  'average',
  'avocado',
  'avoid',
  'awake',
  'aware',
  'away',
  'awesome',
  'awful',
  'awkward',
  'axis',
  'baby',
  'bachelor',
  'bacon',
  'badge',
  'bag',
  'balance',
  'balcony',
  'ball',
  'bamboo',
  'banana',
  'banner',
  'bar',
  'barely',
  'bargain',
  'barrel',
  'base',
  'basic',
  'basket',
  'battle',
  'beach',
  'bean',
  'beauty',
  'because',
  'become',
  'beef',
  'before',
  'begin',
  'behave',
  'behind',
  'believe',
  'below',
  'belt',
  'bench',
  'benefit',
  'best',
  'betray',
  'better',
  'between',
  'beyond',
  'bicycle',
  'bid',
  'bike',
  'bind',
  'biology',
  'bird',
  'birth',
  'bitter',
  'black',
  'blade',
  'blame',
  'blanket',
  'blast',
  'bleak',
  'bless',
  'blind',
  'blood',
  'blossom',
  'blouse',
  'blue',
  'blur',
  'blush',
  'board',
  'boat',
  'body',
  'boil',
  'bomb',
  'bone',
  'bonus',
  'book',
  'boost',
  'border',
  'boring',
  'borrow',
  'boss',
  'bottom',
  'bounce',
  'box',
  'boy',
  'bracket',
  'brain',
  'brand',
  'brass',
  'brave',
  'bread',
  'breeze',
  'brick',
  'bridge',
  'brief',
  'bright',
  'bring',
  'brisk',
  'broccoli',
  'broken',
  'bronze',
  'broom',
  'brother',
  'brown',
  'brush',
  'bubble',
  'buddy',
  'budget',
  'buffalo',
  'build',
  'bulb',
  'bulk',
  'bullet',
  'bundle',
  'bunker',
  'burden',
  'burger',
  'burst',
  'bus',
  'business',
  'busy',
  'butter',
  'buyer',
  'buzz',
  'cabbage',
  'cabin',
  'cable',
  'cactus',
  'cage',
  'cake',
  'call',
  'calm',
  'camera',
  'camp',
  'can',
  'canal',
  'cancel',
  'candy',
  'cannon',
  'canoe',
  'canvas',
  'canyon',
  'capable',
  'capital',
  'captain',
  'car',
  'carbon',
  'card',
  'cargo',
  'carpet',
  'carry',
  'cart',
  'case',
  'cash',
  'casino',
  'castle',
  'casual',
  'cat',
  'catalog',
  'catch',
  'category',
  'cattle',
  'caught',
  'cause',
  'caution',
  'cave',
  'ceiling',
  'celery',
  'cement',
  'census',
  'century',
  'cereal',
  'certain',
  'chair',
  'chalk',
  'champion',
  'change',
  'chaos',
  'chapter',
  'charge',
  'chase',
  'chat',
  'cheap',
  'check',
  'cheese',
  'chef',
  'cherry',
  'chest',
  'chicken',
  'chief',
  'child',
  'chimney',
  'choice',
  'choose',
  'chronic',
  'chuckle',
  'chunk',
  'churn',
  'cigar',
  'cinnamon',
  'circle',
  'citizen',
  'city',
  'civil',
  'claim',
  'clap',
  'clarify',
  'claw',
  'clay',
  'clean',
  'clerk',
  'clever',
  'click',
  'client',
  'cliff',
  'climb',
  'clinic',
  'clip',
  'clock',
  'clog',
  'close',
  'cloth',
  'cloud',
  'clown',
  'club',
  'clump',
  'cluster',
  'clutch',
  'coach',
  'coast',
  'coconut',
  'code',
  'coffee',
  'coil',
  'coin',
  'collect',
  'color',
  'column',
  'combine',
  'come',
  'comfort',
  'comic',
  'common',
  'company',
  'concert',
  'conduct',
  'confirm',
  'congress',
  'connect',
  'consider',
  'control',
  'convince',
  'cook',
  'cool',
  'copper',
  'copy',
  'coral',
  'core',
  'corn',
  'correct',
  'cost',
  'cotton',
  'couch',
  'country',
  'couple',
  'course',
  'cousin',
  'cover',
  'coyote',
  'crack',
  'cradle',
  'craft',
  'cram',
  'crane',
  'crash',
  'crater',
  'crawl',
  'crazy',
  'cream',
  'credit',
  'creek',
  'crew',
  'cricket',
  'crime',
  'crisp',
  'critic',
  'crop',
  'cross',
  'crouch',
  'crowd',
  'crucial',
  'cruel',
  'cruise',
  'crumble',
  'crunch',
  'crush',
  'cry',
  'crystal',
  'cube',
  'culture',
  'cup',
  'cupboard',
  'curious',
  'current',
  'curtain',
  'curve',
  'cushion',
  'custom',
  'cute',
  'cycle',
  'dad',
  'damage',
  'damp',
  'dance',
  'danger',
  'daring',
  'dash',
  'daughter',
  'dawn',
  'day',
  'deal',
  'debate',
  'debris',
  'decade',
  'december',
  'decide',
  'decline',
  'decorate',
  'decrease',
  'deer',
  'defense',
  'define',
  'defy',
  'degree',
  'delay',
  'deliver',
  'demand',
  'demise',
  'denial',
  'dentist',
  'deny',
  'depart',
  'depend',
  'deposit',
  'depth',
  'deputy',
  'derive',
  'describe',
  'desert',
  'design',
  'desk',
  'despair',
  'destroy',
  'detail',
  'detect',
  'develop',
  'device',
  'devote',
  'diagram',
  'dial',
  'diamond',
  'diary',
  'dice',
  'diesel',
  'diet',
  'differ',
  'digital',
  'dignity',
  'dilemma',
  'dinner',
  'dinosaur',
  'direct',
  'dirt',
  'disagree',
  'discover',
  'disease',
  'dish',
  'dismiss',
  'disorder',
  'display',
  'distance',
  'divert',
  'divide',
  'divorce',
  'dizzy',
  'doctor',
  'document',
  'dog',
  'doll',
  'dolphin',
  'domain',
  'donate',
  'donkey',
  'donor',
  'door',
  'dose',
  'double',
  'dove',
  'draft',
  'dragon',
  'drama',
  'drastic',
  'draw',
  'dream',
  'dress',
  'drift',
  'drill',
  'drink',
  'drip',
  'drive',
  'drop',
  'drum',
  'dry',
  'duck',
  'dumb',
  'dune',
  'during',
  'dust',
  'dutch',
  'duty',
  'dwarf',
  'dynamic',
  'eager',
  'eagle',
  'early',
  'earn',
  'earth',
  'easily',
  'east',
  'easy',
  'echo',
  'ecology',
  'economy',
  'edge',
  'edit',
  'educate',
  'effort',
  'egg',
  'eight',
  'either',
  'elbow',
  'elder',
  'electric',
  'elegant',
  'element',
  'elephant',
  'elevator',
  'elite',
  'else',
  'embark',
  'embody',
  'embrace',
  'emerge',
  'emotion',
  'employ',
  'empower',
  'empty',
  'enable',
  'enact',
  'end',
  'endless',
  'endorse',
  'enemy',
  'energy',
  'enforce',
  'engage',
  'engine',
  'enhance',
  'enjoy',
  'enlist',
  'enough',
  'enrich',
  'enroll',
  'ensure',
  'enter',
  'entire',
  'entry',
  'envelope',
  'episode',
  'equal',
  'equip',
  'era',
  'erase',
  'erode',
  'erosion',
  'error',
  'erupt',
  'escape',
  'essay',
  'essence',
  'estate',
  'eternal',
  'ethics',
  'evidence',
  'evil',
  'evoke',
  'evolve',
  'exact',
  'example',
  'excess',
  'exchange',
  'excite',
  'exclude',
  'excuse',
  'execute',
  'exercise',
  'exhaust',
  'exhibit',
  'exile',
  'exist',
  'exit',
  'exotic',
  'expand',
  'expect',
  'expire',
  'explain',
  'expose',
  'express',
  'extend',
  'extra',
  'eye',
  'eyebrow',
  'fabric',
  'face',
  'faculty',
  'fade',
  'faint',
  'faith',
  'fall',
  'false',
  'fame',
  'family',
  'famous',
  'fan',
  'fancy',
  'fantasy',
  'farm',
  'fashion',
  'fat',
  'fatal',
  'father',
  'fatigue',
  'fault',
  'favorite',
  'feature',
  'february',
  'federal',
  'fee',
  'feed',
  'feel',
  'female',
  'fence',
  'festival',
  'fetch',
  'fever',
  'few',
  'fiber',
  'fiction',
  'field',
  'figure',
  'file',
  'film',
  'filter',
  'final',
  'find',
  'fine',
  'finger',
  'finish',
  'fire',
  'firm',
  'first',
  'fiscal',
  'fish',
  'fit',
  'fitness',
  'fix',
  'flag',
  'flame',
  'flash',
  'flat',
  'flavor',
  'flee',
  'flight',
  'flip',
  'float',
  'flock',
  'floor',
  'flower',
  'fluid',
  'flush',
  'fly',
  'foam',
  'focus',
  'fog',
  'foil',
  'fold',
  'follow',
  'food',
  'foot',
  'force',
  'forest',
  'forget',
  'fork',
  'fortune',
  'forum',
  'forward',
  'fossil',
  'foster',
  'found',
  'fox',
  'fragile',
  'frame',
  'frequent',
  'fresh',
  'friend',
  'fringe',
  'frog',
  'front',
  'frost',
  'frown',
  'frozen',
  'fruit',
  'fuel',
  'fun',
  'funny',
  'furnace',
  'fury',
  'future',
  'gadget',
  'gain',
  'galaxy',
  'gallery',
  'game',
  'gap',
  'garage',
  'garbage',
  'garden',
  'garlic',
  'garment',
  'gas',
  'gasp',
  'gate',
  'gather',
  'gauge',
  'gaze',
  'general',
  'genius',
  'genre',
  'gentle',
  'genuine',
  'gesture',
  'ghost',
  'giant',
  'gift',
  'giggle',
  'ginger',
  'giraffe',
  'girl',
  'give',
  'glad',
  'glance',
  'glare',
  'glass',
  'glide',
  'glimpse',
  'globe',
  'gloom',
  'glory',
  'glove',
  'glow',
  'glue',
  'goat',
  'goddess',
  'gold',
  'good',
  'goose',
  'gorilla',
  'gospel',
  'gossip',
  'govern',
  'gown',
  'grab',
  'grace',
  'grain',
  'grant',
  'grape',
  'grass',
  'gravity',
  'great',
  'green',
  'grid',
  'grief',
  'grit',
  'grocery',
  'group',
  'grow',
  'grunt',
  'guard',
  'guess',
  'guide',
  'guilt',
  'guitar',
  'gun',
  'gym',
  'habit',
  'hair',
  'half',
  'hammer',
  'hamster',
  'hand',
  'happy',
  'harbor',
  'hard',
  'harsh',
  'harvest',
  'hat',
  'have',
  'hawk',
  'hazard',
  'head',
  'health',
  'heart',
  'heavy',
  'hedgehog',
  'height',
  'hello',
  'helmet',
  'help',
  'hen',
  'hero',
  'hidden',
  'high',
  'hill',
  'hint',
  'hip',
  'hire',
  'history',
  'hobby',
  'hockey',
  'hold',
  'hole',
  'holiday',
  'hollow',
  'home',
  'honey',
  'hood',
  'hope',
  'horn',
  'horror',
  'horse',
  'hospital',
  'host',
  'hotel',
  'hour',
  'hover',
  'hub',
  'huge',
  'human',
  'humble',
  'humor',
  'hundred',
  'hungry',
  'hunt',
  'hurdle',
  'hurry',
  'hurt',
  'husband',
  'hybrid',
  'ice',
  'icon',
  'idea',
  'identify',
  'idle',
  'ignore',
  'ill',
  'illegal',
  'illness',
  'image',
  'imitate',
  'immense',
  'immune',
  'impact',
  'impose',
  'improve',
  'impulse',
  'inch',
  'include',
  'income',
  'increase',
  'index',
  'indicate',
  'indoor',
  'industry',
  'infant',
  'inflict',
  'inform',
  'inhale',
  'inherit',
  'initial',
  'inject',
  'injury',
  'inmate',
  'inner',
  'innocent',
  'input',
  'inquiry',
  'insane',
  'insect',
  'inside',
  'inspire',
  'install',
  'intact',
  'interest',
  'into',
  'invest',
  'invite',
  'involve',
  'iron',
  'island',
  'isolate',
  'issue',
  'item',
  'ivory',
  'jacket',
  'jaguar',
  'jar',
  'jazz',
  'jealous',
  'jeans',
  'jelly',
  'jewel',
  'job',
  'join',
  'joke',
  'journey',
  'joy',
  'judge',
  'juice',
  'jump',
  'jungle',
  'junior',
  'junk',
  'just',
  'kangaroo',
  'keen',
  'keep',
  'ketchup',
  'key',
  'kick',
  'kid',
  'kidney',
  'kind',
  'kingdom',
  'kiss',
  'kit',
  'kitchen',
  'kite',
  'kitten',
  'kiwi',
  'knee',
  'knife',
  'knock',
  'know',
  'lab',
  'label',
  'labor',
  'ladder',
  'lady',
  'lake',
  'lamp',
  'language',
  'laptop',
  'large',
  'later',
  'latin',
  'laugh',
  'laundry',
  'lava',
  'law',
  'lawn',
  'lawsuit',
  'layer',
  'lazy',
  'leader',
  'leaf',
  'learn',
  'leave',
  'lecture',
  'left',
  'leg',
  'legal',
  'legend',
  'leisure',
  'lemon',
  'lend',
  'length',
  'lens',
  'leopard',
  'lesson',
  'letter',
  'level',
  'liar',
  'liberty',
  'library',
  'license',
  'life',
  'lift',
  'light',
  'like',
  'limb',
  'limit',
  'link',
  'lion',
  'liquid',
  'list',
  'little',
  'live',
  'lizard',
  'load',
  'loan',
  'lobster',
  'local',
  'lock',
  'logic',
  'lonely',
  'long',
  'loop',
  'lottery',
  'loud',
  'lounge',
  'love',
  'loyal',
  'lucky',
  'luggage',
  'lumber',
  'lunar',
  'lunch',
  'luxury',
  'lyrics',
  'machine',
  'mad',
  'magic',
  'magnet',
  'maid',
  'mail',
  'main',
  'major',
  'make',
  'mammal',
  'man',
  'manage',
  'mandate',
  'mango',
  'mansion',
  'manual',
  'maple',
  'marble',
  'march',
  'margin',
  'marine',
  'market',
  'marriage',
  'mask',
  'mass',
  'master',
  'match',
  'material',
  'math',
  'matrix',
  'matter',
  'maximum',
  'maze',
  'meadow',
  'mean',
  'measure',
  'meat',
  'mechanic',
  'medal',
  'media',
  'melody',
  'melt',
  'member',
  'memory',
  'mention',
  'menu',
  'mercy',
  'merge',
  'merit',
  'merry',
  'mesh',
  'message',
  'metal',
  'method',
  'middle',
  'midnight',
  'milk',
  'million',
  'mimic',
  'mind',
  'minimum',
  'minor',
  'minute',
  'miracle',
  'mirror',
  'misery',
  'miss',
  'mistake',
  'mix',
  'mixed',
  'mixture',
  'mobile',
  'model',
  'modify',
  'mom',
  'moment',
  'monitor',
  'monkey',
  'monster',
  'month',
  'moon',
  'moral',
  'more',
  'morning',
  'mosquito',
  'mother',
  'motion',
  'motor',
  'mountain',
  'mouse',
  'move',
  'movie',
  'much',
  'muffin',
  'mule',
  'multiply',
  'muscle',
  'museum',
  'mushroom',
  'music',
  'must',
  'mutual',
  'myself',
  'mystery',
  'myth',
  'naive',
  'name',
  'napkin',
  'narrow',
  'nasty',
  'nation',
  'nature',
  'near',
  'neck',
  'need',
  'negative',
  'neglect',
  'neither',
  'nephew',
  'nerve',
  'nest',
  'net',
  'network',
  'neutral',
  'never',
  'news',
  'next',
  'nice',
  'night',
  'noble',
  'noise',
  'nominee',
  'noodle',
  'normal',
  'north',
  'nose',
  'notable',
  'note',
  'nothing',
  'notice',
  'novel',
  'now',
  'nuclear',
  'number',
  'nurse',
  'nut',
  'oak',
  'obey',
  'object',
  'oblige',
  'obscure',
  'observe',
  'obtain',
  'obvious',
  'occur',
  'ocean',
  'october',
  'odor',
  'off',
  'offer',
  'office',
  'often',
  'oil',
  'okay',
  'old',
  'olive',
  'olympic',
  'omit',
  'once',
  'one',
  'onion',
  'online',
  'only',
  'open',
  'opera',
  'opinion',
  'oppose',
  'option',
  'orange',
  'orbit',
  'orchard',
  'order',
  'ordinary',
  'organ',
  'orient',
  'original',
  'orphan',
  'ostrich',
  'other',
  'outdoor',
  'outer',
  'output',
  'outside',
  'oval',
  'oven',
  'over',
  'own',
  'owner',
  'oxygen',
  'oyster',
  'ozone',
  'pact',
  'paddle',
  'page',
  'pair',
  'palace',
  'palm',
  'panda',
  'panel',
  'panic',
  'panther',
  'paper',
  'parade',
  'parent',
  'park',
  'parrot',
  'party',
  'pass',
  'patch',
  'path',
  'patient',
  'patrol',
  'pattern',
  'pause',
  'pave',
  'payment',
  'peace',
  'peanut',
  'pear',
  'peasant',
  'pelican',
  'pen',
  'penalty',
  'pencil',
  'people',
  'pepper',
  'perfect',
  'permit',
  'person',
  'pet',
  'phone',
  'photo',
  'phrase',
  'physical',
  'piano',
  'picnic',
  'picture',
  'piece',
  'pig',
  'pigeon',
  'pill',
  'pilot',
  'pink',
  'pioneer',
  'pipe',
  'pistol',
  'pitch',
  'pizza',
  'place',
  'planet',
  'plastic',
  'plate',
  'play',
  'please',
  'pledge',
  'pluck',
  'plug',
  'plunge',
  'poem',
  'poet',
  'point',
  'polar',
  'pole',
  'police',
  'pond',
  'pony',
  'pool',
  'popular',
  'portion',
  'position',
  'possible',
  'post',
  'potato',
  'pottery',
  'poverty',
  'powder',
  'power',
  'practice',
  'praise',
  'predict',
  'prefer',
  'prepare',
  'present',
  'pretty',
  'prevent',
  'price',
  'pride',
  'primary',
  'print',
  'priority',
  'prison',
  'private',
  'prize',
  'problem',
  'process',
  'produce',
  'profit',
  'program',
  'project',
  'promote',
  'proof',
  'property',
  'prosper',
  'protect',
  'proud',
  'provide',
  'public',
  'pudding',
  'pull',
  'pulp',
  'pulse',
  'pumpkin',
  'punch',
  'pupil',
  'puppy',
  'purchase',
  'purity',
  'purpose',
  'purse',
  'push',
  'put',
  'puzzle',
  'pyramid',
  'quality',
  'quantum',
  'quarter',
  'question',
  'quick',
  'quit',
  'quiz',
  'quote',
  'rabbit',
  'raccoon',
  'race',
  'rack',
  'radar',
  'radio',
  'rail',
  'rain',
  'raise',
  'rally',
  'ramp',
  'ranch',
  'random',
  'range',
  'rapid',
  'rare',
  'rate',
  'rather',
  'raven',
  'raw',
  'razor',
  'ready',
  'real',
  'reason',
  'rebel',
  'rebuild',
  'recall',
  'receive',
  'recipe',
  'record',
  'recycle',
  'reduce',
  'reflect',
  'reform',
  'refuse',
  'region',
  'regret',
  'regular',
  'reject',
  'relax',
  'release',
  'relief',
  'rely',
  'remain',
  'remember',
  'remind',
  'remove',
  'render',
  'renew',
  'rent',
  'reopen',
  'repair',
  'repeat',
  'replace',
  'report',
  'require',
  'rescue',
  'resemble',
  'resist',
  'resource',
  'response',
  'result',
  'retire',
  'retreat',
  'return',
  'reunion',
  'reveal',
  'review',
  'reward',
  'rhythm',
  'rib',
  'ribbon',
  'rice',
  'rich',
  'ride',
  'ridge',
  'rifle',
  'right',
  'rigid',
  'ring',
  'riot',
  'ripple',
  'risk',
  'ritual',
  'rival',
  'river',
  'road',
  'roast',
  'robot',
  'robust',
  'rocket',
  'romance',
  'roof',
  'rookie',
  'room',
  'rose',
  'rotate',
  'rough',
  'round',
  'route',
  'royal',
  'rubber',
  'rude',
  'rug',
  'rule',
  'run',
  'runway',
  'rural',
  'sad',
  'saddle',
  'sadness',
  'safe',
  'sail',
  'salad',
  'salmon',
  'salon',
  'salt',
  'salute',
  'same',
  'sample',
  'sand',
  'satisfy',
  'satoshi',
  'sauce',
  'sausage',
  'save',
  'say',
  'scale',
  'scan',
  'scare',
  'scatter',
  'scene',
  'scheme',
  'school',
  'science',
  'scissors',
  'scorpion',
  'scout',
  'scrap',
  'screen',
  'script',
  'scrub',
  'sea',
  'search',
  'season',
  'seat',
  'second',
  'secret',
  'section',
  'security',
  'seed',
  'seek',
  'segment',
  'select',
  'sell',
  'seminar',
  'senior',
  'sense',
  'sentence',
  'series',
  'service',
  'session',
  'settle',
  'setup',
  'seven',
  'shadow',
  'shaft',
  'shallow',
  'share',
  'shed',
  'shell',
  'sheriff',
  'shield',
  'shift',
  'shine',
  'ship',
  'shiver',
  'shock',
  'shoe',
  'shoot',
  'shop',
  'short',
  'shoulder',
  'shove',
  'shrimp',
  'shrug',
  'shuffle',
  'shy',
  'sibling',
  'sick',
  'side',
  'siege',
  'sight',
  'sign',
  'silent',
  'silk',
  'silly',
  'silver',
  'similar',
  'simple',
  'since',
  'sing',
  'siren',
  'sister',
  'situate',
  'six',
  'size',
  'skate',
  'sketch',
  'ski',
  'skill',
  'skin',
  'skirt',
  'skull',
  'slab',
  'slam',
  'sleep',
  'slender',
  'slice',
  'slide',
  'slight',
  'slim',
  'slogan',
  'slot',
  'slow',
  'slush',
  'small',
  'smart',
  'smile',
  'smoke',
  'smooth',
  'snack',
  'snake',
  'snap',
  'sniff',
  'snow',
  'soap',
  'soccer',
  'social',
  'sock',
  'soda',
  'soft',
  'solar',
  'soldier',
  'solid',
  'solution',
  'solve',
  'someone',
  'song',
  'soon',
  'sorry',
  'sort',
  'soul',
  'sound',
  'soup',
  'source',
  'south',
  'space',
  'spare',
  'spatial',
  'spawn',
  'speak',
  'special',
  'speed',
  'spell',
  'spend',
  'sphere',
  'spice',
  'spider',
  'spike',
  'spin',
  'spirit',
  'split',
  'spoil',
  'sponsor',
  'spoon',
  'sport',
  'spot',
  'spray',
  'spread',
  'spring',
  'spy',
  'square',
  'squeeze',
  'squirrel',
  'stable',
  'stadium',
  'staff',
  'stage',
  'stairs',
  'stamp',
  'stand',
  'start',
  'state',
  'stay',
  'steak',
  'steel',
  'stem',
  'step',
  'stereo',
  'stick',
  'still',
  'sting',
  'stock',
  'stomach',
  'stone',
  'stool',
  'story',
  'stove',
  'strategy',
  'street',
  'strike',
  'strong',
  'struggle',
  'student',
  'stuff',
  'stumble',
  'style',
  'subject',
  'submit',
  'subway',
  'success',
  'such',
  'sudden',
  'suffer',
  'sugar',
  'suggest',
  'suit',
  'summer',
  'sun',
  'sunny',
  'sunset',
  'super',
  'supply',
  'supreme',
  'sure',
  'surface',
  'surge',
  'surprise',
  'surround',
  'survey',
  'suspect',
  'sustain',
  'swallow',
  'swamp',
  'swap',
  'swarm',
  'swear',
  'sweet',
  'swift',
  'swim',
  'swing',
  'switch',
  'sword',
  'symbol',
  'symptom',
  'syrup',
  'system',
  'table',
  'tackle',
  'tag',
  'tail',
  'talent',
  'talk',
  'tank',
  'tape',
  'target',
  'task',
  'taste',
  'tattoo',
  'taxi',
  'teach',
  'team',
  'tell',
  'ten',
  'tenant',
  'tennis',
  'tent',
  'term',
  'test',
  'text',
  'thank',
  'that',
  'theme',
  'then',
  'theory',
  'there',
  'they',
  'thing',
  'this',
  'thought',
  'three',
  'thrive',
  'throw',
  'thumb',
  'thunder',
  'ticket',
  'tide',
  'tiger',
  'tilt',
  'timber',
  'time',
  'tiny',
  'tip',
  'tired',
  'tissue',
  'title',
  'toast',
  'tobacco',
  'today',
  'toddler',
  'toe',
  'together',
  'toilet',
  'token',
  'tomato',
  'tomorrow',
  'tone',
  'tongue',
  'tonight',
  'tool',
  'tooth',
  'top',
  'topic',
  'topple',
  'torch',
  'tornado',
  'tortoise',
  'toss',
  'total',
  'tourist',
  'toward',
  'tower',
  'town',
  'toy',
  'track',
  'trade',
  'traffic',
  'tragic',
  'train',
  'transfer',
  'trap',
  'trash',
  'travel',
  'tray',
  'treat',
  'tree',
  'trend',
  'trial',
  'tribe',
  'trick',
  'trigger',
  'trim',
  'trip',
  'trophy',
  'trouble',
  'truck',
  'true',
  'truly',
  'trumpet',
  'trust',
  'truth',
  'try',
  'tube',
  'tuition',
  'tumble',
  'tuna',
  'tunnel',
  'turkey',
  'turn',
  'turtle',
  'twelve',
  'twenty',
  'twice',
  'twin',
  'twist',
  'two',
  'type',
  'typical',
  'ugly',
  'umbrella',
  'unable',
  'unaware',
  'uncle',
  'uncover',
  'under',
  'undo',
  'unfair',
  'unfold',
  'unhappy',
  'uniform',
  'unique',
  'unit',
  'universe',
  'unknown',
  'unlock',
  'until',
  'unusual',
  'unveil',
  'update',
  'upgrade',
  'uphold',
  'upon',
  'upper',
  'upset',
  'urban',
  'urge',
  'usage',
  'use',
  'used',
  'useful',
  'useless',
  'usual',
  'utility',
  'vacant',
  'vacuum',
  'vague',
  'valid',
  'valley',
  'valve',
  'van',
  'vanish',
  'vapor',
  'various',
  'vast',
  'vault',
  'vehicle',
  'velvet',
  'vendor',
  'venture',
  'venue',
  'verb',
  'verify',
  'version',
  'very',
  'vessel',
  'veteran',
  'viable',
  'vibrant',
  'vicious',
  'victory',
  'video',
  'view',
  'village',
  'vintage',
  'violin',
  'virtual',
  'virus',
  'visa',
  'visit',
  'visual',
  'vital',
  'vivid',
  'vocal',
  'voice',
  'void',
  'volcano',
  'volume',
  'vote',
  'voyage',
  'wage',
  'wagon',
  'wait',
  'walk',
  'wall',
  'walnut',
  'want',
  'warfare',
  'warm',
  'warrior',
  'wash',
  'wasp',
  'waste',
  'water',
  'wave',
  'way',
  'wealth',
  'weapon',
  'wear',
  'weasel',
  'weather',
  'web',
  'wedding',
  'weekend',
  'weird',
  'welcome',
  'west',
  'wet',
  'whale',
  'what',
  'wheat',
  'wheel',
  'when',
  'where',
  'whip',
  'whisper',
  'wide',
  'width',
  'wife',
  'wild',
  'will',
  'win',
  'window',
  'wine',
  'wing',
  'wink',
  'winner',
  'winter',
  'wire',
  'wisdom',
  'wise',
  'wish',
  'witness',
  'wolf',
  'woman',
  'wonder',
  'wood',
  'wool',
  'word',
  'work',
  'world',
  'worry',
  'worth',
  'wrap',
  'wreck',
  'wrestle',
  'wrist',
  'write',
  'wrong',
  'yard',
  'year',
  'yellow',
  'you',
  'young',
  'youth',
  'zebra',
  'zero',
  'zone',
  'zoo',
];
