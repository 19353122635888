import React from 'react';
import PropTypes from 'prop-types';
import { Fab, Dialog, DialogTitle, DialogContent, DialogContentText, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import SearchPlayers from './SearchPlayers';

export default function AddContactModal(props: { handleClose: any; open: boolean }) {
  const handleCancel = () => {
    props.handleClose();
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-transaction-confirmation-modal modal-feature'} PaperProps={{ sx: { p: '20px 0', minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' } } }} keepMounted={false}>
        <Fab
          size="small"
          onClick={handleCancel}
          sx={{
            boxShadow: 'none',
            bgcolor: 'transparent',
            ml: '2%',
          }}
        >
          <Close />
        </Fab>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: 'center',
            pt: '0',
            pl: '10%',
            pr: '10%',
            textTransform: 'uppercase',
          }}
        >
          <Typography sx={{ fontWeight: '700' }}>Add Contact</Typography>
        </DialogTitle>
        <DialogContent sx={{ p: '0 0 50px' }}>
          <DialogContentText component={'span'} id="alert-dialog-description">
            <SearchPlayers handleClose={handleCancel} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

AddContactModal.propTypes = {
  icon: PropTypes.any,
  handleClose: PropTypes.any,
  open: PropTypes.bool.isRequired,
};

AddContactModal.defaultProps = {};
