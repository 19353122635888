import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import { InputAdornment, Input, CardMedia, Stack, Fab, CircularProgress, Stepper, Step, StepContent, StepButton, Autocomplete, FormGroup, FormControlLabel, Checkbox, Grid, TextField, MenuItem, Button, IconButton, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { PictureAsPdf, Cached, Remove, Visibility, VisibilityOff, PhotoCamera } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import _ from 'lodash';
import { COUNTRIES, FILE_TO_BASE64, ID_TYPES, TO_TIMESTAMP } from '../../utilities/utilities';
import { dataURLtoBlobURL, handleUpload } from '../../helpers/index';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { selectTeams } from '../../reducers/teamSlice';
import { selectPersonalWallets, selectBusinessWallets } from '../../reducers/walletSlice';

import { WalletIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { CountryType, DATE_AS_ANY, INITIAL_COUNTRY, MEDIAS_AS_ANY, NULL_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Link } from 'react-router-dom';
import MenuButton from '../../component-modals/layout/MenuButton';
import Document from '../../component-modals/common/Document';

interface IFormNewWallet {
  company: { id: string; name: string };
  wallet_type?: string;
}

const FormNewWallet: React.FC = () => {
  try {
    const CREATE_WALLET_MUTATION = gql`
      mutation createWallet($wallet: inputCreateWallet!) {
        createWallet(wallet: $wallet) {
          id
          title
          type
          status
        }
      }
    `;

    const ADD_PLAYER_SPENDING_PASSWORD_MUTATION = gql`
      mutation addPlayerSpendingPassword($user: inputAddSpendingPassword!) {
        addPlayerSpendingPassword(user: $user)
      }
    `;

    const CREATE_WALLET_KYC_CASE_MUTATION = gql`
      mutation createWalletKYCCase($case: inputWalletCase!) {
        createWalletKYCCase(case: $case) {
          id
          reference
          status
          level
        }
      }
    `;

    const ADD_NEW_LOCATION_MUTATION = gql`
      mutation addAdditionalLocation($account: inputAddAdditionalLocation!) {
        addAdditionalLocation(account: $account) {
          id
        }
      }
    `;

    const location: any = useLocation();
    const state: IFormNewWallet = location?.state;

    const loggedPlayer = useAppSelector(selectPlayer);
    const session = useAppSelector(selectSession);
    const loggedPlayerTeams = useAppSelector(selectTeams);
    const loggedPlayerPersonalWallets = useAppSelector(selectPersonalWallets);
    const loggedPlayerBusinessWallets = useAppSelector(selectBusinessWallets);
    const navigate = useNavigate();

    const [hidePassword, setHidePassword] = React.useState(false);
    const [idIssuerCountry, setIdIssuerCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
    const [homeCountry, setHomeCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
    const [pidFrontFile, setPidFrontFile] = React.useState<ImageListType>([]);
    const [pidFrontFileUrl, setPidFrontFileUrl] = React.useState([]);
    const [pidBackFile, setPidBackFile] = React.useState<ImageListType>([]);
    const [pidBackFileUrl, setPidBackFileUrl] = React.useState([]);
    const [idSelfie, setIdSelfie] = React.useState<ImageListType>([]);
    const [idSelfieUrl, setSelfieUrl] = React.useState([]);
    const [residentialProofFileImg, setResidentialProofFileImg] = React.useState<ImageListType>([]);
    const [residentialProofFilePdf, setResidentialProofFilePdf] = React.useState<any>(null);
    const [residentialFilesUrls, setResidentialFilesUrls] = React.useState([]);
    const [incorporationFileImg, setIncorporationFileImg] = React.useState<ImageListType>([]);
    const [incorporationFilePdf, setIncorporationFilePdf] = React.useState<any>(null);
    const [incorporationFilesUrls, setIncorporationFilesUrls] = React.useState([]);
    const [confirmations, setConfirmations] = React.useState({
      infoAuthenticity: false,
      legalNotice: false,
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const min_code_length = 10;

    const [createWallet, newWallet] = useMutation(CREATE_WALLET_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [addPlayerSpendingPassword, updatedPlayer] = useMutation(ADD_PLAYER_SPENDING_PASSWORD_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [createWalletKYCCase, newKYCCase] = useMutation(CREATE_WALLET_KYC_CASE_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [addAdditionalLocation, newLocation] = useMutation(ADD_NEW_LOCATION_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });

    const ALL_WALLET_OWNERS = _.concat({
      type: loggedPlayer?.__typename,
      id: loggedPlayer?.id,
      name: loggedPlayer?.username,
      label: loggedPlayer?.username,
    }).filter((o: any) => o);

    const {
      control,
      watch,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        wallet_type: state?.company?.id ? 'business' : 'personal',
        wallet_title: state?.wallet_type && state?.company === undefined ? `${state?.wallet_type === 'BUSINESS' ? `Business wallet #${loggedPlayerBusinessWallets.length + 1}` : `[#${loggedPlayerPersonalWallets.length + 1}] ${loggedPlayer?.username}'s wallet`}` : state?.company ? `[#${loggedPlayerBusinessWallets.length + 1}] ${state?.company?.name}'s business wallet` : `[#${loggedPlayerPersonalWallets.length + 1}] ${loggedPlayer?.username}'s wallet`,
        wallet_owner: state?.wallet_type && state?.company === undefined ? (state?.wallet_type === 'BUSINESS' ? loggedPlayerTeams[0]?.id : loggedPlayer?.id) : state?.company !== undefined ? state?.company?.id : loggedPlayer?.id,
        spending_password: '',
        business_registration: '',
        incorporation_pdf_files: UNDEFINED_AS_ANY,
        incorporation_img_files: NULL_AS_ANY,
        id_type: 'PASSPORT',
        id_number: '',
        id_exp: DATE_AS_ANY,
        id_issuer: '',
        id_issuer_country: INITIAL_COUNTRY,
        pid_front: MEDIAS_AS_ANY,
        pid_back: MEDIAS_AS_ANY,
        id_selfie: NULL_AS_ANY,
        home_street_name: '',
        home_secondary_street_name: '',
        home_postcode: '',
        home_city: '',
        home_county: '',
        home_state: '',
        home_country: INITIAL_COUNTRY,
        residential_proof_pdf_files: UNDEFINED_AS_ANY,
      },
    });

    const inputFields = watch();
    const WALLET_TYPES = [
      {
        value: 'personal',
        label: 'Personal',
      },
      {
        value: 'business',
        label: 'Business',
      },
    ];

    React.useEffect(() => {
      if (!inputFields.wallet_owner) {
        setValue('wallet_owner', ALL_WALLET_OWNERS[0]?.id);
      }

      if (inputFields.wallet_type === 'personal' && ALL_WALLET_OWNERS?.find((o: any) => o?.id === inputFields.wallet_owner)?.type === 'Company') {
        setValue('wallet_type', 'business');
      }

      if (inputFields.wallet_type === 'business' && ALL_WALLET_OWNERS?.find((o: any) => o?.id === inputFields.wallet_owner)?.type !== 'Company') {
        setValue('wallet_type', 'personal');
      }
    });

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
      setActiveStep(step);
    };

    const handleIncorporationFileImg = async (imageList: ImageListType) => {
      setIncorporationFileImg(imageList);
    };

    const handleIncorporationFilePdf = ({ target }: any) => {
      if (target.files < 1 || !target.validity.valid) {
        return;
      }
      FILE_TO_BASE64(target.files[0], (err: any, result: any) => {
        if (result) {
          setIncorporationFilePdf({
            dataURL: result,
            file: target.files[0],
          });
        }
      });
    };

    const handleIdIssuerCountry = (country: CountryType) => {
      if (country) {
        setIdIssuerCountry(country);
        setValue('id_issuer_country', country);
      }
    };

    const handlePidFrontFile = async (imageList: ImageListType) => {
      setValue('pid_front', imageList);
      setPidFrontFile(imageList);
    };

    const handlePidBackFile = async (imageList: ImageListType) => {
      setValue('pid_back', imageList);
      setPidBackFile(imageList);
    };

    const handleIdSelfie = async (imageList: ImageListType) => {
      setIdSelfie(imageList);
    };

    const onChangeCountry = (country: CountryType) => {
      if (country) {
        setHomeCountry(country);
        setValue('home_country', country);
      }
    };

    const handleResidentialProofFileImg = async (imageList: ImageListType) => {
      setResidentialProofFileImg(imageList);
    };

    const handleResidentialProofFilePdf = ({ target }: any) => {
      if (target.files < 1 || !target.validity.valid) {
        return;
      }
      FILE_TO_BASE64(target.files[0], (err: any, result: any) => {
        if (result) {
          setResidentialProofFilePdf({
            dataURL: result,
            file: target.files[0],
          });
        }
      });
    };

    const handleConfirmations = (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmations({
        ...confirmations,
        [event.target.name]: event.target.checked,
      });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const handleNewWallet = async () => {
      try {
        setProgress(1);

        const incorporationFiles = _.concat(incorporationFileImg, incorporationFilePdf);
        if (incorporationFiles?.length > 0 && incorporationFiles[0] !== null && incorporationFiles[0] !== undefined) {
          const documentUploadPromises = incorporationFiles?.map(async (o: any) => {
            await handleUpload(o?.file, incorporationFilesUrls, setIncorporationFilesUrls, session, loggedPlayer.id);
          });
          await Promise.all(documentUploadPromises);
        }

        const residentialFiles = _.concat(residentialProofFileImg, residentialProofFilePdf);
        if (residentialFiles?.length > 0 && residentialFiles[0] !== null && residentialFiles[0] !== undefined) {
          const residentialFilesPromises = residentialFiles?.map(async (o: any) => {
            await handleUpload(o?.file, residentialFilesUrls, setResidentialFilesUrls, session, loggedPlayer.id);
          });
          await Promise.all(residentialFilesPromises);
        }

        if (pidFrontFile.length > 0) {
          await handleUpload(pidFrontFile[0].file, pidFrontFileUrl, setPidFrontFileUrl, session, loggedPlayer.id);
        }

        if (pidBackFile.length > 0) {
          await handleUpload(pidBackFile[0].file, pidBackFileUrl, setPidBackFileUrl, session, loggedPlayer.id);
        }

        if (idSelfie?.length > 0) {
          await handleUpload(idSelfie[0]?.file, idSelfieUrl, setSelfieUrl, session, loggedPlayer.id);
        }

        if (confirmations.legalNotice && confirmations.infoAuthenticity) {
          const result = await createWallet({
            variables: {
              wallet: {
                title: inputFields.wallet_title,
                type: inputFields.wallet_type,
                is_default: loggedPlayerPersonalWallets.length === 0 && inputFields.wallet_type === 'personal' ? true : false,
                owner: ALL_WALLET_OWNERS.find((o: any) => o.id === inputFields.wallet_owner),
              },
            },
          });

          if (loggedPlayer?.locations?.length === 0) {
            await addAdditionalLocation({
              variables: {
                account: {
                  buyer_id: inputFields.wallet_owner,
                  location: {
                    title: `${ALL_WALLET_OWNERS.find((o: any) => o.id === inputFields.wallet_owner)?.label}'s address`,
                    street_name: inputFields.home_street_name,
                    secondary_street_name: inputFields.home_secondary_street_name,
                    country_code: inputFields.home_country ? inputFields.home_country?.value : homeCountry?.value,
                    postcode: inputFields.home_postcode,
                    city: inputFields.home_city,
                    county: inputFields.home_county,
                    state: inputFields.home_state,
                    country: inputFields.home_country ? inputFields.home_country?.label : homeCountry?.label,
                    continent: inputFields.home_country ? inputFields.home_country?.continent : homeCountry?.continent,
                  },
                },
              },
            });
          }

          if (loggedPlayerPersonalWallets.length === 0) {
            await addPlayerSpendingPassword({
              variables: {
                user: {
                  id: loggedPlayer?.id,
                  username: loggedPlayer?.username,
                  spending_password: inputFields.spending_password,
                },
              },
            });
          }

          const result_kyc = await createWalletKYCCase({
            variables: {
              case: {
                author_id: loggedPlayer?.id,
                wallet_id: result.data?.createWallet.id,
                wallet_type: result.data?.createWallet.type,
                wallet_owner: ALL_WALLET_OWNERS.find((o: any) => o.id === inputFields.wallet_owner),
                reference: `case-w-*${result.data?.createWallet.id}-u-*${loggedPlayer?.id}-d-*${Date.now()}`,
                title: `[KYC WALLET] ${result.data?.createWallet.id}`,
                business_registration: inputFields.business_registration,
                incorporation_file: _.concat(incorporationFileImg, incorporationFilePdf).map((p: any, index: number) => {
                  return {
                    filename: p?.file?.name ? p?.file?.name : p?.file?.filename,
                    size: p?.file?.size,
                    type: p?.file?.type,
                    path: incorporationFilesUrls[index],
                  };
                }),
                user_id_type: inputFields.id_type,
                user_id_number: inputFields.id_number,
                user_id_exp: parseFloat(TO_TIMESTAMP(inputFields.id_exp).toFixed(1)),
                user_id_issuer: inputFields.id_issuer,
                user_id_issuer_country: idIssuerCountry.value,
                user_front_id: {
                  filename: pidFrontFile[0]?.file?.name,
                  size: pidFrontFile[0]?.file?.size,
                  type: pidFrontFile[0]?.file?.type,
                  path: pidFrontFileUrl[0] ? pidFrontFileUrl[0] : '',
                },
                user_back_id: {
                  filename: pidBackFile[0]?.file?.name,
                  size: pidBackFile[0]?.file?.size,
                  type: pidBackFile[0]?.file?.type,
                  path: pidBackFileUrl[0] ? pidBackFileUrl[0] : '',
                },
                user_id_selfie: {
                  filename: idSelfie[0]?.file?.name,
                  size: idSelfie[0]?.file?.size,
                  type: idSelfie[0]?.file?.type,
                  path: idSelfieUrl[0] ? idSelfieUrl[0] : '',
                },
                user_home: {
                  street_name: inputFields.home_street_name,
                  secondary_street_name: inputFields.home_secondary_street_name,
                  country_code: homeCountry.value,
                  postcode: inputFields.home_postcode,
                  city: inputFields.home_city,
                  county: inputFields.home_county,
                  state: inputFields.home_state,
                  country: homeCountry.label,
                },
                user_residential_proof: _.concat(residentialProofFileImg, residentialProofFilePdf)
                  ?.filter((p: any) => p?.file?.size)
                  ?.map((p: any, index: number) => {
                    return {
                      filename: p?.file?.name ? p?.file?.name : p?.file?.filename,
                      size: p?.file?.size,
                      type: p?.file?.type,
                      path: residentialFilesUrls[index],
                    };
                  }),
              },
            },
          });

          navigate('/kyc_notice', {
            state: {
              kyc_reference: result_kyc.data?.createWalletKYCCase?.reference,
              wallet: {
                id: result.data?.createWallet.id,
                title: result.data?.createWallet.title,
                type: result.data?.createWallet.type,
              },
            },
          });
        }
      } catch (e) {
        console.log(e);
        navigate('/w/wallets');
      }
    };

    const steps = [
      {
        label: 'Wallet Information',
        content: (
          <>
            <Controller
              name="wallet_title"
              control={control}
              rules={{
                required: true,
                maxLength: 1000,
                minLength: 4,
              }}
              render={({ field }: any) => <TextField {...field} value={field.value} label="Title" placeholder="Input your wallet title" variant="outlined" fullWidth required className={'field-bottom-space'} />}
            />

            <Controller
              name="wallet_owner"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  value={field.value}
                  select
                  label={`Wallet owner`}
                  placeholder="Select a owner"
                  disabled={state?.company !== undefined}
                  variant="outlined"
                  required
                  className={'field-bottom-space'}
                  sx={{
                    mr: { xs: '0', sm: '3%', md: '5%' },
                    width: { xs: '100%', sm: '47%', md: '45%' },
                  }}
                >
                  {ALL_WALLET_OWNERS?.map((option: any, index: number) => (
                    <MenuItem key={index} value={option?.id}>
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="wallet_type"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  value={ALL_WALLET_OWNERS?.find((o: any) => o?.id === inputFields.wallet_owner)?.type === 'Company' ? 'business' : 'personal'}
                  select
                  label="Type"
                  placeholder="Select a type"
                  variant="outlined"
                  required
                  disabled
                  className={'field-bottom-space'}
                  sx={{
                    ml: { xs: '0', sm: '3%', md: '5%' },
                    width: { xs: '100%', sm: '47%', md: '45%' },
                  }}
                >
                  {WALLET_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {loggedPlayerPersonalWallets.length + loggedPlayerBusinessWallets.length === 0 ? (
              <Controller
                name="spending_password"
                control={control}
                rules={{
                  required: loggedPlayerPersonalWallets.length + loggedPlayerBusinessWallets.length === 0 ? true : false,
                  minLength: min_code_length,
                  maxLength: 255,
                }}
                render={({ field }: any) => (
                  <Grid sx={{ m: '30px 0' }}>
                    <Typography sx={{ textAlign: 'left', m: '10px 0' }}>Spending Password (min {min_code_length} characters)</Typography>
                    <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}></Grid>
                    <TextField
                      {...field}
                      label="Please enter your spending code"
                      type={hidePassword ? 'password' : 'text'}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                              {hidePassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography sx={{ fontSize: '0.8em', m: '10px 0' }}>This password will be use to confirm any payment transactions</Typography>
                  </Grid>
                )}
              />
            ) : (
              ''
            )}

            {inputFields.wallet_type === 'business' && loggedPlayerTeams?.find((c: any) => c?.id === inputFields.wallet_owner)?.kyc_cases?.find((kyc: any) => kyc?.status === 'SUCCESSFUL') === undefined ? (
              <>
                <Controller
                  name="business_registration"
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-zA-Z0-9_-]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} value={field.value} required={false} label="Business registration #" placeholder="Input your Business registration number" variant="outlined" fullWidth />}
                />

                <h4>Certificate of incorporation</h4>
                <Stack direction="row">
                  <ImageUploading value={incorporationFileImg} multiple onChange={handleIncorporationFileImg} maxNumber={10} acceptType={['jpg', 'jpeg', 'png']}>
                    {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                      <Grid container sx={{ p: '20px 0' }}>
                        <Controller
                          name="incorporation_pdf_files"
                          control={control}
                          rules={{
                            required: true,
                            maxLength: 30,
                            minLength: 4,
                            max: 30,
                            pattern: /^[a-zA-Z0-9_-]*$/,
                          }}
                          render={({ field }: any) => (
                            <label htmlFor="contained-button-file">
                              <Input
                                {...field}
                                value={field.value}
                                inputProps={{
                                  accept: 'application/pdf',
                                }}
                                id="contained-button-file"
                                type="file"
                                onChange={handleIncorporationFilePdf}
                                sx={{
                                  display: 'none',
                                }}
                              />
                              <Fab
                                variant="extended"
                                size="small"
                                className="button-green-reverse"
                                sx={{
                                  background: 'transparent',
                                  boxShadow: 'none',
                                  m: '0 5px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Add PDF <PictureAsPdf />
                              </Fab>
                            </label>
                          )}
                        />
                        {incorporationFileImg.length === 10 ? (
                          ''
                        ) : (
                          <Fab
                            style={isDragging ? { color: 'green' } : undefined}
                            onClick={onImageUpload}
                            variant="extended"
                            size="small"
                            {...dragProps}
                            className="button-green-reverse"
                            sx={{
                              background: 'transparent',
                              boxShadow: 'none',
                              m: '0 5px',
                            }}
                          >
                            Add IMG file ({incorporationFileImg.length === 0 ? `10 max` : `${10 - incorporationFileImg.length} left`}
                            ) <PhotoCamera />
                          </Fab>
                        )}
                        <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
                          {imageList[0] || incorporationFilePdf ? (
                            <Stack
                              direction="row"
                              spacing={{
                                xs: 1,
                                sm: 2,
                                md: 4,
                              }}
                              sx={{ p: '10px 0' }}
                              className="upload__image-wrapper"
                            >
                              {incorporationFilePdf?.file ? (
                                <Grid
                                  container
                                  sx={{
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    pb: '50px',
                                  }}
                                >
                                  <Document url={dataURLtoBlobURL(incorporationFilePdf?.dataURL)} />
                                  <Fab
                                    size="small"
                                    className="button-red-reversed"
                                    sx={{
                                      bgcolor: 'transparent',
                                      boxShadow: 'none',
                                    }}
                                    onClick={() => setIncorporationFilePdf(null)}
                                  >
                                    <Remove sx={{ color: 'rgba(238, 44, 32, 1) !important' }} />
                                  </Fab>
                                </Grid>
                              ) : (
                                ''
                              )}
                              {imageList.map((image: any, index: any) => (
                                <div key={index} className="image-item">
                                  <Document url={dataURLtoBlobURL(image?.dataURL)} />
                                  <Grid
                                    className="image-item__btn-wrapper"
                                    sx={{
                                      justifyContent: 'center',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Fab
                                      size="small"
                                      sx={{
                                        bgcolor: 'transparent',
                                        boxShadow: 'none',
                                      }}
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <Cached sx={{ color: 'rgba(15, 15, 15, .6) !important' }} />
                                    </Fab>
                                    <Fab
                                      size="small"
                                      className="button-red-reversed"
                                      sx={{
                                        bgcolor: 'transparent',
                                        boxShadow: 'none',
                                      }}
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <Remove sx={{ color: 'rgba(238, 44, 32, 1) !important' }} />
                                    </Fab>
                                  </Grid>
                                </div>
                              ))}
                            </Stack>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </ImageUploading>
                </Stack>
              </>
            ) : (
              ''
            )}
          </>
        ),
      },
      loggedPlayer?.kyc_cases?.find((kyc: any) => kyc?.status === 'SUCCESSFUL')
        ? undefined
        : {
            label: 'KYC Verification',
            content: (
              <>
                <Controller
                  name="id_number"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    max: 30,
                    pattern: /^[a-z0-9_]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} value={field.value} label="ID #" placeholder="Input your ID number" variant="outlined" fullWidth className={'field-bottom-space'} />}
                />

                <Controller
                  name="id_type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      label="ID Type"
                      placeholder="Select an ID type"
                      variant="outlined"
                      className={'field-bottom-space'}
                      sx={{
                        mr: { xs: '0', sm: '3%', md: '5%' },
                        width: { xs: '100%', sm: '47%', md: '45%' },
                      }}
                    >
                      {ID_TYPES.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="id_exp"
                  control={control}
                  render={({ field }: any) => (
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      views={['day', 'month', 'year']}
                      minDate={Date.now()}
                      value={field.value}
                      slotProps={{
                        textField: {
                          label: 'ID Expiration',
                          placeholder: 'Input expiration date',
                          className: 'field-bottom-space',
                          sx: {
                            ml: {
                              xs: '0',
                              sm: '3%',
                              md: '5%',
                            },
                            width: {
                              xs: '100%',
                              sm: '47%',
                              md: '45%',
                            },
                          },
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="id_issuer"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    pattern: /^[a-z0-9_-]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} value={field.value} label="Authority issuer" placeholder="Input authority issuer's name" variant="outlined" fullWidth className={'field-bottom-space'} />}
                />

                <Controller
                  control={control}
                  name="id_issuer_country"
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      options={COUNTRIES}
                      value={idIssuerCountry}
                      className={'country-select'}
                      onChange={(event: any, value: any) => field.onChange(handleIdIssuerCountry(value))}
                      autoHighlight
                      getOptionLabel={(option: CountryType) => option.label}
                      isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                      renderOption={(props: any, option: CountryType) => (
                        <Box
                          component="li"
                          sx={{
                            '& > img': { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                          {option.label} ({option.value})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ID issuer country"
                          value={idIssuerCountry}
                          variant="outlined"
                          error={errors?.id_issuer_country !== undefined}
                          className={'field-bottom-space'}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />

                <Stack direction="row">
                  <Controller
                    name="pid_front"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={() => (
                      <ImageUploading value={pidFrontFile} onChange={handlePidFrontFile} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                          <Grid
                            container
                            sx={{
                              justifyContent: 'center',
                              width: '50%',
                              display: 'inline-flex',
                              mt: '20px',
                            }}
                          >
                            {pidFrontFile.length === 1 ? (
                              ''
                            ) : (
                              <Fab
                                style={
                                  isDragging
                                    ? {
                                        color: 'rgba(31, 175, 146, .3)',
                                      }
                                    : undefined
                                }
                                onClick={onImageUpload}
                                variant="extended"
                                size="small"
                                {...dragProps}
                                className="button-green-reverse"
                                sx={{
                                  background: 'transparent',
                                  boxShadow: 'none',
                                  mb: '20px',
                                }}
                              >
                                Add ID (front) <PhotoCamera />
                              </Fab>
                            )}
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              sx={{
                                overflow: 'auto',
                              }}
                            >
                              {imageList[0] ? (
                                <Stack
                                  direction="row"
                                  spacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                  }}
                                  sx={{
                                    p: '10px 0',
                                    justifyContent: 'center',
                                  }}
                                  className="upload__image-wrapper"
                                >
                                  {imageList?.map((image: any, index: any) => (
                                    <div key={index} className="image-item">
                                      <CardMedia
                                        component={'img'}
                                        src={dataURLtoBlobURL(image?.dataURL)}
                                        alt={image?.name}
                                        sx={{
                                          height: {
                                            xs: 125,
                                            sm: 150,
                                            md: 200,
                                          },
                                          width: 'auto',
                                          margin: 'auto',
                                        }}
                                      />
                                      <Grid
                                        className="image-item__btn-wrapper"
                                        sx={{
                                          justifyContent: 'center',
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Fab size="small" onClick={() => onImageUpdate(index)}>
                                          <Cached />
                                        </Fab>
                                        <Fab size="small" onClick={() => onImageRemove(index)}>
                                          <Remove />
                                        </Fab>
                                      </Grid>
                                    </div>
                                  ))}
                                </Stack>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </ImageUploading>
                    )}
                  />

                  <Controller
                    name="pid_back"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={() => (
                      <ImageUploading value={pidBackFile} onChange={handlePidBackFile} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                          <Grid
                            container
                            sx={{
                              justifyContent: 'center',
                              width: '50%',
                              display: 'inline-flex',
                              mt: '20px',
                            }}
                          >
                            {pidBackFile.length === 1 ? (
                              ''
                            ) : (
                              <Fab
                                style={
                                  isDragging
                                    ? {
                                        color: 'rgba(31, 175, 146, .3)',
                                      }
                                    : undefined
                                }
                                onClick={onImageUpload}
                                variant="extended"
                                size="small"
                                {...dragProps}
                                className="button-green-reverse"
                                sx={{
                                  background: 'transparent',
                                  boxShadow: 'none',
                                  mb: '20px',
                                }}
                              >
                                Add ID (back) <PhotoCamera />
                              </Fab>
                            )}
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              sx={{
                                overflow: 'auto',
                              }}
                            >
                              {imageList[0] ? (
                                <Stack
                                  direction="row"
                                  spacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                  }}
                                  sx={{
                                    p: '10px 0',
                                    justifyContent: 'center',
                                  }}
                                  className="upload__image-wrapper"
                                >
                                  {imageList?.map((image: any, index: any) => (
                                    <div key={index} className="image-item">
                                      <CardMedia
                                        component={'img'}
                                        src={dataURLtoBlobURL(image?.dataURL)}
                                        alt={image?.name}
                                        sx={{
                                          height: {
                                            xs: 125,
                                            sm: 150,
                                            md: 200,
                                          },
                                          width: 'auto',
                                          margin: 'auto',
                                        }}
                                      />
                                      <Grid
                                        className="image-item__btn-wrapper"
                                        sx={{
                                          justifyContent: 'center',
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Fab size="small" onClick={() => onImageUpdate(index)}>
                                          <Cached />
                                        </Fab>
                                        <Fab size="small" onClick={() => onImageRemove(index)}>
                                          <Remove />
                                        </Fab>
                                      </Grid>
                                    </div>
                                  ))}
                                </Stack>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </ImageUploading>
                    )}
                  />
                </Stack>
                <Grid container sx={{ pb: '50px', justifyContent: 'center' }}>
                  <Controller
                    name="id_selfie"
                    control={control}
                    rules={{ required: true }}
                    render={() => (
                      <ImageUploading value={idSelfie} onChange={handleIdSelfie} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                          <Grid container sx={{ justifyContent: 'center' }}>
                            {idSelfie.length === 1 ? (
                              ''
                            ) : (
                              <Fab
                                style={isDragging ? { color: 'green' } : undefined}
                                onClick={onImageUpload}
                                variant="extended"
                                size="small"
                                {...dragProps}
                                className="button-green-reverse"
                                sx={{
                                  background: 'transparent',
                                  boxShadow: 'none',
                                  m: '0 5px',
                                }}
                              >
                                Add Selfie <PhotoCamera />
                              </Fab>
                            )}
                            <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
                              {imageList[0] ? (
                                <Stack
                                  direction="row"
                                  spacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                  }}
                                  sx={{ p: '10px 0' }}
                                  className="upload__image-wrapper"
                                >
                                  {imageList.map((image: any, index: any) => (
                                    <div key={index} className="image-item">
                                      <CardMedia
                                        component={'img'}
                                        src={dataURLtoBlobURL(image?.dataURL)}
                                        alt={image?.name}
                                        sx={{
                                          height: {
                                            xs: 125,
                                            sm: 150,
                                            md: 200,
                                          },
                                          width: 'auto',
                                          margin: 'auto',
                                        }}
                                      />
                                      <Grid
                                        className="image-item__btn-wrapper"
                                        sx={{
                                          justifyContent: 'center',
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Fab size="small" onClick={() => onImageUpdate(index)}>
                                          <Cached />
                                        </Fab>
                                        <Fab size="small" onClick={() => onImageRemove(index)}>
                                          <Remove />
                                        </Fab>
                                      </Grid>
                                    </div>
                                  ))}
                                </Stack>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </ImageUploading>
                    )}
                  />
                </Grid>
              </>
            ),
          },
      {
        label: 'Residential Information',
        content: (
          <>
            <Controller
              name="home_street_name"
              control={control}
              rules={{
                required: false,
                maxLength: 30,
                minLength: 4,
                max: 30,
                pattern: /^[a-zA-Z0-9-\s]*$/,
              }}
              render={({ field }: any) => <TextField {...field} label="Street address" placeholder="Write your home's street address" variant="outlined" fullWidth value={field.value} error={errors?.home_street_name !== undefined} className={'field-bottom-space'} helperText={errors?.home_street_name !== undefined ? `${errors?.home_street_name?.message}` : ''} />}
            />

            <Controller
              name="home_secondary_street_name"
              control={control}
              rules={{
                required: false,
                maxLength: 30,
                minLength: 4,
                max: 30,
                pattern: /^[a-zA-Z0-9-\s]*$/,
              }}
              render={({ field }: any) => <TextField {...field} label="Street address (complement)" placeholder="Complement of home's street address" variant="outlined" fullWidth value={field.value} className={'field-bottom-space'} error={errors?.home_secondary_street_name !== undefined} helperText={errors?.home_secondary_street_name !== undefined ? `${errors?.home_secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} />}
            />

            <Controller
              name="home_postcode"
              control={control}
              rules={{
                required: false,
                maxLength: 30,
                minLength: 4,
                max: 30,
                pattern: /^[a-zA-Z0-9-\s]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label="Postcode"
                  placeholder="Home's postcode"
                  variant="outlined"
                  value={field.value}
                  error={errors?.home_postcode !== undefined}
                  className={'field-bottom-space'}
                  helperText={errors?.home_postcode !== undefined ? `${errors?.home_postcode?.message}` : ''}
                  sx={{
                    mr: { xs: '0', sm: '3%', md: '5%' },
                    width: { xs: '100%', sm: '47%', md: '45%' },
                  }}
                />
              )}
            />

            <Controller
              name="home_city"
              control={control}
              rules={{
                required: false,
                maxLength: 30,
                minLength: 4,
                max: 30,
                pattern: /^[a-zA-Z0-9-\s]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label="City"
                  placeholder="Home's city"
                  variant="outlined"
                  value={field.value}
                  error={errors?.home_city !== undefined}
                  className={'field-bottom-space'}
                  helperText={errors?.home_city !== undefined ? `${errors?.home_city?.message}` : ''}
                  sx={{
                    ml: { xs: '0', sm: '3%', md: '5%' },
                    width: { xs: '100%', sm: '47%', md: '45%' },
                  }}
                />
              )}
            />

            <Controller
              name="home_county"
              control={control}
              rules={{
                required: false,
                maxLength: 30,
                minLength: 4,
                max: 30,
                pattern: /^[a-zA-Z0-9-\s]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label="County"
                  placeholder="Home's county"
                  variant="outlined"
                  value={field.value}
                  error={errors?.home_county !== undefined}
                  className={'field-bottom-space'}
                  helperText={errors?.home_county !== undefined ? `${errors?.home_county?.message}` : ''}
                  sx={{
                    mr: { xs: '0', sm: '3%', md: '5%' },
                    width: { xs: '100%', sm: '47%', md: '45%' },
                  }}
                />
              )}
            />

            <Controller
              name="home_state"
              control={control}
              rules={{
                required: false,
                maxLength: 30,
                minLength: 4,
                max: 30,
                pattern: /^[a-zA-Z0-9-\s]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  label="State"
                  placeholder="Home's state"
                  variant="outlined"
                  value={field.value}
                  error={errors?.home_state !== undefined}
                  className={'field-bottom-space'}
                  helperText={errors?.home_state !== undefined ? `${errors?.home_state?.message}` : ''}
                  sx={{
                    ml: { xs: '0', sm: '3%', md: '5%' },
                    width: { xs: '100%', sm: '47%', md: '45%' },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="home_country"
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  options={COUNTRIES}
                  value={homeCountry}
                  className={'country-select'}
                  onChange={(event: any, value: any) => field.onChange(onChangeCountry(value))}
                  autoHighlight
                  getOptionLabel={(option: CountryType) => option.label}
                  isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                  renderOption={(props: any, option: CountryType) => (
                    <Box
                      component="li"
                      sx={{
                        '& > img': { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                      {option.label} ({option.value})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Home's country"
                      value={homeCountry}
                      variant="outlined"
                      error={errors?.home_country !== undefined}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
            />

            <h4>Residential Proof</h4>
            <i>Upload a residential proof (rent or electricity bill for example)</i>
            <Stack direction="row" sx={{ p: '20px 0 50px' }}>
              <ImageUploading value={residentialProofFileImg} multiple onChange={handleResidentialProofFileImg} maxNumber={2} acceptType={['jpg', 'jpeg', 'png']}>
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                  <Grid container>
                    <Controller
                      name="residential_proof_pdf_files"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: 30,
                        minLength: 4,
                        max: 30,
                        pattern: /^[a-zA-Z0-9_-]*$/,
                      }}
                      render={({ field }: any) => (
                        <label htmlFor="contained-button-file">
                          <Input
                            {...field}
                            value={field.value}
                            inputProps={{
                              accept: 'application/pdf',
                            }}
                            id="contained-button-file"
                            type="file"
                            onChange={handleResidentialProofFilePdf}
                            sx={{ display: 'none' }}
                          />
                          <Fab
                            variant="extended"
                            size="small"
                            component="span"
                            className="button-green-reverse"
                            sx={{
                              whiteSpace: 'nowrap',
                              background: 'transparent',
                              boxShadow: 'none',
                              m: '0 5px',
                            }}
                          >
                            Add PDF <PictureAsPdf />
                          </Fab>
                        </label>
                      )}
                    />
                    {residentialProofFileImg.length === 2 ? (
                      ''
                    ) : (
                      <Fab
                        style={isDragging ? { color: 'green' } : undefined}
                        onClick={onImageUpload}
                        variant="extended"
                        size="small"
                        {...dragProps}
                        className="button-green-reverse"
                        sx={{
                          background: 'transparent',
                          boxShadow: 'none',
                          m: '0 5px',
                        }}
                      >
                        Add IMG file ({residentialProofFileImg.length === 0 ? `2 max` : `${2 - residentialProofFileImg.length} left`}
                        ) <PhotoCamera />
                      </Fab>
                    )}
                    <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
                      {imageList[0] || residentialProofFilePdf ? (
                        <Stack
                          direction="row"
                          spacing={{
                            xs: 1,
                            sm: 2,
                            md: 4,
                          }}
                          sx={{ p: '10px 0' }}
                          className="upload__image-wrapper"
                        >
                          {residentialProofFilePdf?.file ? (
                            <Grid
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  height: {
                                    xs: 125,
                                    sm: 150,
                                    md: 200,
                                  },
                                  width: 'auto',
                                  margin: 'auto',
                                  border: 'solid 1px #C7C7C7',
                                  borderRadius: '5px',
                                  p: '5%',
                                }}
                              >
                                {residentialProofFilePdf?.file.name}
                              </Typography>
                              <Fab size="small" onClick={() => setResidentialProofFilePdf(null)}>
                                <Remove />
                              </Fab>
                            </Grid>
                          ) : (
                            ''
                          )}
                          {imageList.map((image: any, index: any) => (
                            <div key={index} className="image-item">
                              <CardMedia
                                component={'img'}
                                src={dataURLtoBlobURL(image?.dataURL)}
                                alt={image?.name}
                                sx={{
                                  height: {
                                    xs: 125,
                                    sm: 150,
                                    md: 200,
                                  },
                                  width: 'auto',
                                  margin: 'auto',
                                }}
                              />
                              <Grid
                                className="image-item__btn-wrapper"
                                sx={{
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                <Fab size="small" onClick={() => onImageUpdate(index)}>
                                  <Cached />
                                </Fab>
                                <Fab size="small" onClick={() => onImageRemove(index)}>
                                  <Remove />
                                </Fab>
                              </Grid>
                            </div>
                          ))}
                        </Stack>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                )}
              </ImageUploading>
            </Stack>
          </>
        ),
      },
    ]?.filter((s: any) => s);

    return (
      <Grid className={'form-page component-preferences'}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        <Typography variant="h1" sx={{ p: '0 10%', fontSize: { xs: '1.4em', sm: '1.6em', md: '2em' }, fontWeight: '700' }}>
          New Wallet
        </Typography>

        <Grid container id="max-width">
          {newWallet.loading || newKYCCase.loading || progress > 0 ? (
            ''
          ) : (
            <>
              <Stepper nonLinear activeStep={activeStep} orientation="vertical" sx={{ width: '100%', p: '30px 10% 0' }}>
                {steps?.map((step: any, index: number) => (
                  <Step key={step?.label}>
                    <StepButton
                      color="inherit"
                      onClick={handleStep(index)}
                      sx={{
                        display: activeStep >= index ? 'inline-block' : 'none',
                      }}
                    >
                      {step?.label}
                    </StepButton>
                    <StepContent>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{
                          display: activeStep >= index ? 'inline-block' : 'none',
                          width: '100%',
                        }}
                      >
                        {step?.content}
                      </Grid>
                      <Box sx={{ pt: '0px' }}>
                        <Stack direction={'row'}>
                          {activeStep === 0 ? (
                            ''
                          ) : (
                            <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                              Back
                            </Button>
                          )}
                          {activeStep === steps.length ? (
                            ''
                          ) : (
                            <Button
                              onClick={handleNext}
                              className={(inputFields.spending_password.length < 10 && loggedPlayerPersonalWallets.length <= 0) || (activeStep === steps?.length - 1 && (inputFields.home_street_name === '' || inputFields.home_postcode === '' || inputFields.home_city === '')) ? 'button-disabled' : 'button-green'}
                              disabled={(inputFields.spending_password.length < 10 && loggedPlayerPersonalWallets.length <= 0) || (activeStep === steps?.length - 1 && (inputFields.home_street_name === '' || inputFields.home_postcode === '' || inputFields.home_city === ''))}
                            >
                              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </>
          )}
          <Grid className={'next-move'} component={'span'} sx={{ width: '100%' }}>
            {activeStep === steps.length ? (
              <Grid sx={{ p: '0 10%' }}>
                {newWallet.loading || newKYCCase.loading || progress > 0 ? (
                  ''
                ) : (
                  <>
                    <Box className={'content'}>
                      <h2>Perfect! You made it all the way through</h2>
                    </Box>
                    <FormGroup className={'confirmations-actions'} sx={{ p: '20px 0' }}>
                      <FormControlLabel control={<Checkbox checked={confirmations.infoAuthenticity} onChange={handleConfirmations} name="infoAuthenticity" className="green-checkbox" />} label="By checking this box, I legally approved that all the information submitted are correct and authentique." />
                      <FormControlLabel control={<Checkbox checked={confirmations.legalNotice} onChange={handleConfirmations} name="legalNotice" className="green-checkbox" />} label={'By checking this box, I legally agreed on the legal terms & privacy terms that this platform is legislated on.'} />
                    </FormGroup>
                  </>
                )}
                <Grid container className={'actions'} sx={{ textAlign: 'center', p: '20px 0' }}>
                  {newWallet.loading || newKYCCase.loading || progress > 0 || updatedPlayer.loading || newLocation.loading ? (
                    <Grid sx={{ textAlign: 'center', width: '100%' }}>
                      <CircularProgress className="orange-loader" />
                      <br />
                      Processing your information
                      <br />
                      please wait...
                    </Grid>
                  ) : (
                    <Box>
                      <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                        Back
                      </Button>
                      <LoadingButton
                        onClick={handleNewWallet}
                        loadingPosition="end"
                        loading={newWallet.loading || newKYCCase.loading || progress > 0 || updatedPlayer.loading || newLocation.loading}
                        disabled={!confirmations.legalNotice || !confirmations.infoAuthenticity || inputFields.home_street_name === '' || inputFields.home_postcode === '' || inputFields.home_city === ''}
                        className={!confirmations.legalNotice || !confirmations.infoAuthenticity || inputFields.home_street_name === '' || inputFields.home_postcode === '' || inputFields.home_city === '' ? 'button-disabled' : 'button-green'}
                        endIcon={<WalletIcon />}
                      >
                        Create wallet
                      </LoadingButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default FormNewWallet;
