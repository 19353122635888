import * as React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { Alert, Box, Stepper, StepLabel, StepContent, Step, Typography, Paper, Button, Grid, TextField, InputAdornment, IconButton, MenuItem, CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AccountCircle, Visibility, VisibilityOff, Send, Sms } from '@mui/icons-material';
import ReactCodeInput from 'react-code-input';
import { useQuery, useMutation, gql } from '@apollo/client';
import { DatePicker } from '@mui/x-date-pickers';
import { GENDERS, GET_PIN_CODE, TO_TIMESTAMP } from '../../utilities/utilities';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { storeSession, storeJWTToken } from '../../reducers/sessionSlice';
import { selectPlayer, storePlayer } from '../../reducers/playerSlice';
import { DATE_AS_ANY } from '../../utilities/CommonInterfaces';
import { AppContext } from '../../App';
import { REACT_APP_CLIENT_NAME } from '../../config';
import { FormattedMessage, useIntl } from 'react-intl';

const SignUpForm = (props: any) => {
  const SIGNUP_PLAYER_MUTATION = gql`
    mutation signUpPlayer($player: inputSignPlayer!) {
      signUpPlayer(player: $player) {
        id
        status
        token {
          key
        }
        app_client {
          name
          configurations {
            id
            is_dark_mode
            two_facts_mode
          }
        }
        account {
          ... on Player {
            id
            username
            email {
              status
              address
            }
            owner {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  `;

  const FIND_PLAYER_USERNAME_QUERY = gql`
    query findPlayerUsername($username: String!) {
      findPlayerUsername(username: $username)
    }
  `;

  const FIND_PLAYER_EMAIL_QUERY = gql`
    query findPlayerEmail($address: String!) {
      findPlayerEmail(address: $address)
    }
  `;

  const SEND_SMS_MUTATION = gql`
    mutation sendSms($mobile: String!, $content: String!) {
      sendSms(mobile: $mobile, content: $content)
    }
  `;

  const SEND_PLAYER_VERIFICATION_EMAIL_MUTATION = gql`
    mutation sendPlayerVerificationEmail($player: inputEmailVerification!) {
      sendPlayerVerificationEmail(player: $player)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const navigate = useNavigate();
  const intl = useIntl();
  const { control, watch, reset } = useForm({
    defaultValues: {
      username: '',
      email: '',
      gender: '',
      other_gender: '',
      date_of_birth: DATE_AS_ANY,
      firstname: '',
      lastname: '',
      password: '',
      mobile: '',
      pin_code: '',
    },
  });
  const inputFields = watch();
  const dispatch = useAppDispatch();
  const appContext = React.useContext(AppContext);

  const [signUpPlayer, newPlayer] = useMutation(SIGNUP_PLAYER_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });
  const [sendSms, sentSMS] = useMutation(SEND_SMS_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });
  const [sendPlayerVerificationEmail, sentPlayerEmailVerification] = useMutation(SEND_PLAYER_VERIFICATION_EMAIL_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });
  const isUsernameTaken = useQuery(FIND_PLAYER_USERNAME_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: { username: inputFields.username },
  });
  const isEmailTaken = useQuery(FIND_PLAYER_EMAIL_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: { address: inputFields.email },
  });

  const [hidePassword, setHidePassword] = React.useState(false);
  const [sendSMS, setSendSMS] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const [activeStep, setActiveStep] = React.useState(0);

  const handleCreatePlayer = async () => {
    try {
      const result = await signUpPlayer({
        variables: {
          player: {
            email: inputFields.email,
            username: _.replace(inputFields.username.toLowerCase(), /[^a-z0-9_.]+/g, '_'),
            gender: inputFields.gender !== 'OTHER' ? inputFields.gender : inputFields.other_gender,
            firstname: inputFields.firstname,
            lastname: inputFields.lastname,
            date_of_birth: TO_TIMESTAMP(inputFields.date_of_birth),
            mobile: inputFields.mobile,
            password: inputFields.password,
            status: 'AVAILABLE',
            coords: {
              latitude: appContext?.currentPosition?.latitude ? appContext?.currentPosition?.latitude : 0.0,
              longitude: appContext?.currentPosition?.longitude ? appContext?.currentPosition?.longitude : 0.0,
              height: appContext?.currentPosition?.accurancy ? appContext?.currentPosition?.accurancy : 0.0,
            },
            app_client: REACT_APP_CLIENT_NAME,
            device: {
              vendor: appContext?.currentDevice?.device?.vendor || 'N/A',
              model: appContext?.currentDevice?.device?.model || 'N/A',
              type: appContext?.currentDevice?.device?.type || 'N/A',
              os_name: appContext?.currentDevice?.os?.name || 'N/A',
              os_version: appContext?.currentDevice?.os?.version || 'N/A',
              browser: appContext?.currentDevice?.browser?.name || 'N/A',
              browser_version: appContext?.currentDevice?.browser?.version || 'N/A',
            },
            language: _.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1],
          },
        },
      });

      try {
        await sendPlayerVerificationEmail({
          variables: {
            player: {
              email: result.data.signUpPlayer.account.email.address,
              sender: {
                id: result.data.signUpPlayer.account.id,
                firstname: result.data.signUpPlayer.account.owner.firstname,
                lastname: result.data.signUpPlayer.account.owner.lastname,
              },
              host: window.location.origin,
              language: _.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1] ?? loggedPlayer?.app_configuration?.prefered_language,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }

      dispatch(storeSession(result.data.signUpPlayer));
      dispatch(storeJWTToken(result.data.signUpPlayer.token.key));
      dispatch(storePlayer(result.data.signUpPlayer.account));
      navigate(props.destination, { replace: true });
    } catch (e) {
      console.error(e);
      reset({ password: '', pin_code: '' });
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const sendPinCode = async () => {
    try {
      const code = GET_PIN_CODE(6);
      console.log(code?.toString());
      // TODO: Remove line bellow
      setPin(code?.toString());
      await sendSms({
        variables: {
          mobile: inputFields.mobile,
          content: `${intl.formatMessage({ id: 'Global_Pin_SMS_1' })} ${inputFields.firstname}, ${intl.formatMessage({ id: 'Global_Pin_SMS_2' })} ${code?.toString()}`,
        },
      });
      setPin(code);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSendSMS = () => {
    sendPinCode();
    setSendSMS(true);
    // reset({ pin_code: '' })
  };

  const handleValidatePin = () => {
    if (inputFields.pin_code.length === 6) {
      if (inputFields.pin_code === pin.toString()) {
        handleNext();
        setPin('');
        // reset({ pin_code: '' });
      }
      setPin('');
      // reset({pin_code: ''});
    }
  };

  const steps = [
    {
      label: `${intl.formatMessage({ id: 'SignUpForm_Account_Info' })}`,
      content: (
        <Grid>
          <Controller
            name="username"
            control={control}
            rules={{
              required: true,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-z0-9_]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'SignUpForm_Username' })}`}
                fullWidth
                required
                autoComplete={'off'}
                className={'field-bottom-space'}
                value={_.replace(field.value?.toLowerCase(), /[^a-z0-9_.]+/g, '_')}
                helperText={
                  isUsernameTaken.data?.findPlayerUsername && isUsernameTaken.called ? (
                    <Typography variant="caption" className="error-msg">
                      {field.value?.toLowerCase()} {`${intl.formatMessage({ id: 'SignUpForm_Username_Taken' })}`}
                    </Typography>
                  ) : (
                    ''
                  )
                }
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 40,
              minLength: 4,
              pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'SignUpForm_Email' })}`}
                type={'email'}
                fullWidth
                autoComplete={'off'}
                required
                error={field.value !== '' && (!field.value?.includes('@') || !_.split(field.value, '@')[1]?.includes('.'))}
                className={'field-bottom-space'}
                helperText={
                  isEmailTaken.data?.findPlayerEmail && isEmailTaken.called ? (
                    <Typography variant="caption" className="error-msg">
                      {field.value} {`${intl.formatMessage({ id: 'SignUpForm_Email_Already_Used' })}`}
                    </Typography>
                  ) : '' || (field.value !== '' && (!field.value?.includes('@') || !_.split(field.value, '@')[1]?.includes('.'))) ? (
                    <FormattedMessage id="SignUpForm_Email_Incorrect" />
                  ) : (
                    ''
                  )
                }
              />
            )}
          />

          <Controller
            name="date_of_birth"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }: any) => (
              <DatePicker
                {...field}
                format="dd/MM/yyyy"
                views={['year', 'month', 'day']}
                disableFuture
                openTo="year"
                maxDate={new Date()}
                value={field.value}
                slotProps={{
                  textField: {
                    label: `${intl.formatMessage({ id: 'SignUpForm_DOB' })}`,
                    required: true,
                    fullWidth: true,
                    placeholder: 'Input your date of birth',
                    className: 'field-bottom-space',
                    //   InputLabelProps: {
                    //   shrink: true,
                    // }
                  },
                }}
              />
            )}
          />

          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field }: any) => (
              <TextField {...field} label={`${intl.formatMessage({ id: 'SignUpForm_Gender' })}`} select fullWidth className={'field-bottom-space'}>
                {GENDERS.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${intl.formatMessage({ id: option.label })}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          {inputFields.gender === 'OTHER' ? <Controller name="other_gender" control={control} defaultValue="" render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'SignUpForm_My_Gender' })} ${inputFields.other_gender ? inputFields.other_gender : ''}`} id="field-other-gender" fullWidth className={'field-bottom-space'} />} /> : ''}

          <Controller
            name="firstname"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
            }}
            render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'SignUpForm_Firstname' })}`} autoComplete={'off'} fullWidth required className={'field-bottom-space'} />}
          />

          <Controller
            name="lastname"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
            }}
            render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'SignUpForm_Lastname' })}`} autoComplete={'off'} fullWidth required className={'field-bottom-space'} />}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'SignUpForm_New_Password' })}`}
                type={hidePassword ? 'text' : 'password'}
                fullWidth
                required
                autoComplete={'off'}
                className={'field-bottom-space'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                        {hidePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      ),
    },
    {
      label: `${intl.formatMessage({ id: 'SignUpForm_Mobile_Verification' })}`,
      content: (
        <Grid>
          <Controller
            name="mobile"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: /^[0-9+]*$/ }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'SignUpForm_Mobile' })}`}
                type="tel"
                fullWidth
                required
                autoFocus
                autoComplete={'off'}
                className={'field-bottom-space'}
                value={field.value}
                inputProps={{
                  inputMode: 'tel',
                  pattern: '[0-9+]*',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LoadingButton
                        onClick={handleSendSMS}
                        disabled={pin?.length > 0 || field.value === '' || (field.value.charAt(0) !== '+' && !field.value.startsWith('0', 1) && field.value.length >= 3) || field.value.length < 8 || field.value.length > 20}
                        loading={sentSMS.loading}
                        loadingPosition="end"
                        loadingIndicator=""
                        className={pin?.length > 0 || field.value === '' || (field.value.charAt(0) !== '+' && !field.value.startsWith('0', 1) && field.value.length >= 3) || field.value.length < 8 || field.value.length > 20 ? 'button-disabled' : 'button-green-reverse'}
                        // startIcon={sendSMS ? <Sms /> : ""}
                        endIcon={sendSMS ? <Sms /> : <Send />}
                      >
                        {sentSMS.loading ? `${intl.formatMessage({ id: 'SignUpForm_Sending_SMS' })}` : `${intl.formatMessage({ id: 'SignUpForm_Send_SMS' })}`}
                      </LoadingButton>
                    </InputAdornment>
                  ),
                }}
                error={sentSMS.error !== undefined}
                helperText={field.value.charAt(0) !== '+' && !field.value.startsWith('0', 1) && field.value.length >= 3 ? `${intl.formatMessage({ id: 'SignUpForm_Use_International_Number' })}` : '' || pin !== '' ? `${intl.formatMessage({ id: 'SignUpForm_Beta_Notice' })} ${pin}` : '' || sentSMS.error ? `${intl.formatMessage({ id: 'SignUpForm_SMS_Error' })} ${inputFields.mobile}` : ''}
              />
            )}
          />
          {pin?.length > 0 ? <Controller name="pin_code" control={control} defaultValue="" rules={{ required: true, pattern: /^[0-9]*$/ }} render={({ field }: any) => <ReactCodeInput {...field} name="sms-pin" type="text" fields={6} inputMode="numeric" autoFocus={false} />} /> : ''}
        </Grid>
      ),
    },
    {
      label: `${intl.formatMessage({ id: 'SignUpForm_Last_Step' })}`,
      content: (
        <Grid sx={{ textAlign: 'center' }}>
          <Typography sx={{ p: '30px 0 50px', fontWeight: '700' }}>
            {`${intl.formatMessage({ id: 'SignUpForm_Great' })}`} 🎉
            <br />
            <br />
            {`${intl.formatMessage({ id: 'SignUpForm_Next_Step' })}`}
            <br />
            <br />
            {`${intl.formatMessage({ id: 'SignUpForm_We_Sent_Email' })}`} {inputFields.email} {`${intl.formatMessage({ id: 'SignUpForm_To_Verify' })}`}
          </Typography>
          <LoadingButton
            onClick={handleCreatePlayer}
            sx={{ width: '60%', m: 'auto' }}
            loading={newPlayer.loading || sentPlayerEmailVerification.loading}
            loadingPosition="end"
            startIcon={newPlayer.loading ? '' : <AccountCircle />}
            endIcon={<Send />}
            fullWidth
            disabled={inputFields.username === '' || inputFields.firstname === '' || inputFields.lastname === '' || inputFields.password === '' || inputFields.email === '' || inputFields.pin_code === undefined}
            className={inputFields.username === '' || inputFields.firstname === '' || inputFields.lastname === '' || inputFields.password === '' || inputFields.email === '' || inputFields.pin_code === undefined ? 'button-disabled' : 'button-green'}
          >
            {`${intl.formatMessage({ id: 'SignUpForm_Create_Account_Button' })}`}
          </LoadingButton>
        </Grid>
      ),
    },
  ];

  return (
    <Grid container className={'form-page dark-mode component-signup'} sx={{ p: { xs: '0 5%', md: '0 10%' } }}>
      <Grid item xs={12} md={12} lg={12}>
        <Helmet>
          <title>{`${intl.formatMessage({ id: 'SignUpForm_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
          <meta name="description" content="Fivezer is a next-generation Five League, where the best teams from all around the world compete against local and overseas teams, anywhere, anytime. Fivezer is a gateway to the largest competition/friendly games, where each player/team can monitor their performances and stats (individual/collective) by using our various ioT tools (bracelet, balls, nets and more)" />
          <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
        </Helmet>

        <form>
          <Grid container>
            {newPlayer.loading || sentPlayerEmailVerification.loading ? (
              <Grid component={'span'} sx={{ textAlign: 'center', width: '100%', display: 'inline-block' }}>
                <CircularProgress className="orange-loader" />
                <br />
                <FormattedMessage id="Global_Button_Loading_Wait" />
              </Grid>
            ) : (
              <>
                <Stepper activeStep={activeStep} orientation="vertical" className="dark-stepper">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      {newPlayer.loading || sentPlayerEmailVerification.loading ? (
                        ''
                      ) : (
                        <StepLabel
                          optional={index === 2 ? <Typography variant="caption">Last step</Typography> : null}
                          sx={{
                            display: activeStep >= index ? 'flex' : 'none',
                            alignItems: 'flex-start',
                            mb: '10px',
                          }}
                        >
                          {step.label}
                        </StepLabel>
                      )}
                      <StepContent>
                        <Grid item xs={12} md={12} lg={12} sx={{ display: activeStep >= index ? 'inline-block' : 'none', width: '100%' }}>
                          {step.content}
                        </Grid>
                        {newPlayer.loading || sentPlayerEmailVerification.loading ? (
                          ''
                        ) : (
                          <Box sx={{ mb: 2 }}>
                            <div>
                              {index > 0 ? (
                                <Button className="button-cancel" disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                  {`${intl.formatMessage({ id: 'Global_Button_Back' })}`}
                                </Button>
                              ) : (
                                ''
                              )}
                              {index === steps.length - 1 ? (
                                ''
                              ) : sendSMS && index === 1 ? (
                                <Button className={inputFields.pin_code.length !== 6 ? 'button-disabled' : 'button-green-reverse'} onClick={handleValidatePin} sx={{ mt: 1, mr: 1 }} disabled={inputFields.pin_code.length !== 6}>
                                  {`${intl.formatMessage({ id: 'SignUpForm_PIN_Verification_Button' })}`}
                                </Button>
                              ) : (
                                ''
                              )}
                              {index === steps.length - 1 ? (
                                ''
                              ) : index < 1 ? (
                                appContext?.currentPosition === undefined ? (
                                  <Alert severity="warning">{`${intl.formatMessage({ id: 'SignUpForm_Turn_On_Location' })}`}</Alert>
                                ) : (
                                  <Button
                                    onClick={handleNext}
                                    sx={{
                                      mt: 1,
                                      mr: 1,
                                    }}
                                    disabled={(activeStep === 0 && (inputFields?.username?.length < 3 || inputFields.email === '' || !inputFields.email?.includes('@') || !_.split(inputFields.email, '@')[1]?.includes('.') || inputFields.lastname === '' || inputFields.firstname === '' || !inputFields.date_of_birth || inputFields.password === '')) || (isUsernameTaken.data?.findPlayerUsername && isUsernameTaken.called) || (isEmailTaken.data?.findPlayerEmail && isEmailTaken.called)}
                                    className={
                                      (activeStep === 0 && (inputFields?.username?.length < 3 || inputFields.email === '' || !inputFields.email?.includes('@') || !_.split(inputFields.email, '@')[1]?.includes('.') || inputFields.lastname === '' || inputFields.firstname === '' || !inputFields.date_of_birth || inputFields.password === '')) || (isUsernameTaken.data?.findPlayerUsername && isUsernameTaken.called) || (isEmailTaken.data?.findPlayerEmail && isEmailTaken.called)
                                        ? 'button-disabled'
                                        : 'button-green-reverse'
                                    }
                                  >
                                    {`${intl.formatMessage({ id: 'SignUpForm_Continue_Button' })}`}
                                  </Button>
                                )
                              ) : (
                                ''
                              )}
                            </div>
                          </Box>
                        )}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>{`${intl.formatMessage({ id: 'SignUpForm_All_Done' })}`}</Typography>
                  </Paper>
                )}
              </>
            )}
          </Grid>
        </form>
        <Grid sx={{ textAlign: 'center', mt: '30px' }}>
          {newPlayer.loading || sentPlayerEmailVerification.loading ? (
            ''
          ) : (
            <Button component={Link} to={'/login'} sx={{ fontSize: '.6em', display: 'inline-block' }}>
              {`${intl.formatMessage({ id: 'SignUpForm_Already_Member' })}`}&nbsp;<strong>{`${intl.formatMessage({ id: 'SignUpForm_Login_Button' })}`}</strong>
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpForm;

SignUpForm.propTypes = {
  destination: PropTypes.string,
};
