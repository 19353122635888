import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { Language } from '@mui/icons-material';
import { COUNTRIES, PAYMENT_PROVIDERS } from '../../utilities/utilities';

export default function AvailableCountriesButton(props: { payment_providers: string[]; payment_source_type?: string }) {
  const ALL_COUNTRIES =
    props?.payment_source_type === 'BlockchainAccount'
      ? PAYMENT_PROVIDERS?.flat()
          ?.map((p: any) => p?.countries)
          ?.flat()
      : _.uniq(
          PAYMENT_PROVIDERS?.filter((p: any) => props?.payment_providers?.find((provider: any) => provider?.name === p?.name))
            ?.flat()
            ?.map((p: any) => p?.countries)
            ?.flat()
        );

  return (
    <>
      <Tooltip
        title={
          <>
            <Typography variant="caption" sx={{ fontWeight: '700', width: '100%', display: 'inline-block', m: '20px 0 10px' }}>
              Available in {ALL_COUNTRIES?.length} {ALL_COUNTRIES?.length > 1 ? 'countries' : 'country'}
            </Typography>
            <Grid container sx={{ justifyContent: 'center', maxHeight: '300px', overflow: 'auto' }}>
              {ALL_COUNTRIES?.map((country: any, index: number) => (
                <Stack key={index} direction="column" sx={{ 'display': 'inline-flex', 'justifyContent': 'center', 'fontSize': '.9em', 'm': '5px', '& img': { width: 'fit-content', m: 'auto' } }}>
                  <img loading="lazy" src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`} alt="" />
                  {COUNTRIES?.find((co: any) => co?.value === country)?.label}
                </Stack>
              ))}
            </Grid>
          </>
        }
        arrow
        TransitionComponent={Zoom}
        leaveDelay={200}
        componentsProps={{
          popper: {
            sx: {
              'width': '300px',

              'borderRadius': '5px',
              '& .MuiTooltip-tooltip': {
                'width': '100%',

                'p': '10px 20px',
                'bgcolor': 'rgba(247, 251, 250, 1)',
                'color': 'rgb(15, 15, 15)',
                'textAlign': 'center',
                '& .MuiTooltip-arrow': {
                  color: 'rgba(247, 251, 250, 1)',
                },
              },
            },
          },
        }}
      >
        <Language />
      </Tooltip>
    </>
  );
}

AvailableCountriesButton.propTypes = {
  payment_providers: PropTypes.any,
};
