import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { QRCodeSVG } from 'qrcode.react';
import { MenuItem, TextField, Grid, Typography } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectPersonalWallets } from '../../reducers/walletSlice';
import { useIntl } from 'react-intl';

const ShowQRCode: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerPersonalWallets = useAppSelector(selectPersonalWallets);
  const intl = useIntl();
  const { control, watch, setValue } = useForm({
    defaultValues: {
      receiver_payment_method_id: '',
    },
  });
  const inputFields = watch();
  const ALL_WALLETS = _.concat(loggedPlayerPersonalWallets);
  const ALL_PAYMENT_METHODS = ALL_WALLETS?.map((w: any) => w?.payment_methods)?.flat();
  const SELECTED_WALLET = ALL_WALLETS?.find((w: any) => w?.payment_methods?.find((pm: any) => pm?.id === inputFields.receiver_payment_method_id));
  const SELECTED_PAYMENT_METHOD = ALL_PAYMENT_METHODS?.find((pm: any) => pm?.id === inputFields.receiver_payment_method_id);

  React.useEffect(() => {
    if (inputFields.receiver_payment_method_id === '') {
      setValue('receiver_payment_method_id', ALL_PAYMENT_METHODS?.find((pm: any) => pm?.name === 'LIPAY')?.id);
    }
  }, [ALL_PAYMENT_METHODS, inputFields, setValue]);

  return (
    <>
      <Grid container sx={{ p: '0 10%' }}>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid sx={{ m: '20px 0 40px', textAlign: 'center' }}>
            <Typography variant="caption" sx={{ display: 'inline-block', width: '100%', mb: '10px' }}>
              ({SELECTED_PAYMENT_METHOD?.name})
            </Typography>
            <QRCodeSVG
              value={inputFields.receiver_payment_method_id}
              size={256}
              bgColor={'#ffffff'}
              fgColor={'#000000'}
              level={'Q'}
              includeMargin={false}
              imageSettings={{
                src: SELECTED_WALLET?.owner?.logo?.path || loggedPlayer?.avatar?.path,
                x: undefined,
                y: undefined,
                height: 30,
                width: 30,
                excavate: true,
              }}
            />
          </Grid>
        </Grid>

        <Controller
          name="receiver_payment_method_id"
          control={control}
          rules={{ required: true }}
          render={({ field }: any) => (
            <TextField {...field} value={field.value} select className={'field-bottom-space'} label={<Grid>{`${intl.formatMessage({ id: 'ShowQRCode_Source' })}`}</Grid>} variant="outlined" fullWidth>
              {ALL_PAYMENT_METHODS?.map((option: any, index: number) => (
                <MenuItem key={index} value={option?.id}>
                  <Grid>
                    <Typography sx={{ fontWeight: '700', width: '100%' }}>{option?.payment_source?.title}</Typography>
                    <Typography variant="caption">({`wallet: ${SELECTED_WALLET?.title}`})</Typography>
                  </Grid>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
    </>
  );
};

ShowQRCode.defaultProps = {};

export default ShowQRCode;
