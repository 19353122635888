import React from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Autocomplete, Box } from '@mui/material';
import { Clear, Send } from '@mui/icons-material';
import { COUNTRIES } from '../../utilities/utilities';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { CountryType, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { LocationIcon } from '../../img/icons/Icons';
import { selectSession } from '../../reducers/sessionSlice';

export default function EditAccountLocation(props: { location_id: string; open: boolean; handleClose: any }) {
  const FIND_ACCOUNT_LOCATION_TO_EDIT_BY_ID_QUERY = gql`
    query findAccountLocationToEditByID($location: inputFindAccountLocationToEditByID!) {
      findAccountLocationToEditByID(location: $location) {
        id
        title
        coordinates
        country_code
        street_name
        secondary_street_name
        status
        postcode {
          name
        }
        city {
          name
        }
        county {
          name
        }
        state {
          name
        }
        country {
          name
        }
        continent {
          name
        }
      }
    }
  `;

  const EDIT_ACCOUNT_LOCATION_MUTATION = gql`
    mutation editAccountLocation($location: inputEditAccountLocation!) {
      editAccountLocation(location: $location) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedUser = useAppSelector(selectPlayer);
  const [location, setLocation] = React.useState(UNDEFINED_AS_ANY);
  const [selectedLocationCountry, setSelectedLocationCountry] = React.useState<CountryType>(UNDEFINED_AS_ANY);
  const [editAccountLocation, updatedLocation] = useMutation(EDIT_ACCOUNT_LOCATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedLocation = useQuery(FIND_ACCOUNT_LOCATION_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      location: {
        id: props.location_id,
      },
    },
  });

  const {
    control,
    watch,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selected_location_title: '',
      selected_location_street_name: '',
      selected_location_secondary_street_name: '',
      selected_location_postcode: '',
      selected_location_city: '',
      selected_location_county: '',
      selected_location_state: '',
      selected_location_country: INITIAL_COUNTRY,
      selected_location_country_code: '',
    },
  });
  const inputFields = watch();

  const handleClose = () => {
    props.handleClose();
    reset();
    resetField('selected_location_title');
    resetField('selected_location_street_name');
    resetField('selected_location_secondary_street_name');
    resetField('selected_location_city');
    resetField('selected_location_postcode');
    resetField('selected_location_state');
    resetField('selected_location_county');
    resetField('selected_location_country_code');
    resetField('selected_location_country');
  };

  const onChangeSelectedLocationCountry = (event: any, country: CountryType) => {
    if (country) {
      setSelectedLocationCountry(country);
      setValue('selected_location_country', country);
    }
  };

  const handleEditAccountLocation = async () => {
    try {
      const result = await editAccountLocation({
        variables: {
          location: {
            id: props.location_id,
            title: inputFields.selected_location_title,
            street_name: inputFields.selected_location_street_name,
            secondary_street_name: inputFields.selected_location_secondary_street_name,
            country_code: selectedLocationCountry?.value,
            postcode: inputFields.selected_location_postcode,
            city: inputFields.selected_location_city,
            county: inputFields.selected_location_county,
            state: inputFields.selected_location_state,
            country: selectedLocationCountry?.label,
            continent: selectedLocationCountry?.continent,
          },
        },
      });

      if (result) {
        handleClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (location === undefined && loadedLocation.data?.findAccountLocationToEditByID) {
      setLocation(loadedLocation.data?.findAccountLocationToEditByID);
    }

    if (loadedLocation.called && location !== undefined) {
      setValue('selected_location_title', location?.title ? location?.title : '');
      setValue('selected_location_street_name', location?.street_name ? location?.street_name : '');
      setValue('selected_location_secondary_street_name', location?.secondary_street_name ? location?.secondary_street_name : '');
      setValue('selected_location_city', location?.city?.name ? location?.city?.name : '');
      setValue('selected_location_postcode', location?.postcode?.name ? location?.postcode?.name : '');
      setValue('selected_location_state', location?.state?.name ? location?.state?.name : '');
      setValue('selected_location_county', location?.county?.name ? location?.county?.name : '');
      setValue('selected_location_country_code', COUNTRIES.find((c: any) => c.value === location?.country_code)?.value || INITIAL_COUNTRY?.value);
      setValue('selected_location_country', COUNTRIES.find((c: any) => c.label === location?.country?.name) || INITIAL_COUNTRY);
      setSelectedLocationCountry(COUNTRIES.find((c: any) => c.label === location?.country?.name) || INITIAL_COUNTRY);
    }

    if (location !== undefined && !props.open) {
      setLocation(undefined);
    }
  }, [location, loadedLocation, setValue, COUNTRIES]);

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
        <LocationIcon sx={{ color: 'rgba(31, 175, 146, 1)' }} />
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Edit address</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ m: '20px 0' }}>
          <Controller name="selected_location_title" control={control} rules={{ required: false }} render={({ field }: any) => <TextField {...field} label={`Title`} placeholder={`${loggedUser?.username} House, office, etc...`} variant="outlined" className={'field-bottom-space'} required fullWidth error={errors?.selected_location_title !== undefined} helperText={''} />} />

          <Controller
            name="selected_location_street_name"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label="Street address" placeholder="Write your company's street address" className={'field-bottom-space'} variant="outlined" fullWidth value={field.value} error={errors?.selected_location_street_name !== undefined} helperText={errors?.selected_location_street_name !== undefined ? `${errors?.selected_location_street_name?.message}` : ''} />}
          />

          <Controller
            name="selected_location_secondary_street_name"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label="Street address (complement)" placeholder="Complement of company's street address" className={'field-bottom-space'} variant="outlined" fullWidth value={field.value} error={errors?.selected_location_secondary_street_name !== undefined} helperText={errors?.selected_location_secondary_street_name !== undefined ? `${errors?.selected_location_secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} />}
          />

          <Controller
            name="selected_location_postcode"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="Postcode"
                placeholder="Postcode"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.selected_location_postcode !== undefined}
                helperText={errors?.selected_location_postcode !== undefined ? `${errors?.selected_location_postcode?.message}` : ''}
                sx={{
                  mr: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="selected_location_city"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="City"
                placeholder="City"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.selected_location_city !== undefined}
                helperText={errors?.selected_location_city !== undefined ? `${errors?.selected_location_city?.message}` : ''}
                sx={{
                  ml: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="selected_location_county"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="County"
                placeholder="County"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.selected_location_county !== undefined}
                helperText={errors?.selected_location_county !== undefined ? `${errors?.selected_location_county?.message}` : ''}
                sx={{
                  mr: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="selected_location_state"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="State"
                placeholder="State"
                variant="outlined"
                className={'field-bottom-space'}
                value={field.value}
                error={errors?.selected_location_state !== undefined}
                helperText={errors?.selected_location_state !== undefined ? `${errors?.selected_location_state?.message}` : ''}
                sx={{
                  ml: {
                    xs: '0',
                    sm: '3%',
                    md: '5%',
                  },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="selected_location_country"
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                options={COUNTRIES}
                value={selectedLocationCountry}
                className={'country-select'}
                onChange={(event: any, value: any) => onChangeSelectedLocationCountry(event, value)}
                autoHighlight
                getOptionLabel={(option: CountryType) => option.label}
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                renderOption={(props: any, option: CountryType) => (
                  <Box
                    component="li"
                    sx={{
                      '& > img': {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                    {option.label} ({option.value})
                  </Box>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Country"
                    value={selectedLocationCountry}
                    required
                    variant="outlined"
                    error={errors?.selected_location_country !== undefined}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: '0px 5% 50px', justifyContent: 'center' }}>
        <LoadingButton
          onClick={handleEditAccountLocation}
          className={inputFields.selected_location_title === '' || inputFields.selected_location_street_name === '' || inputFields.selected_location_postcode === '' || inputFields.selected_location_city === '' ? 'button-disabled' : 'button-green'}
          disabled={inputFields.selected_location_title === '' || inputFields.selected_location_street_name === '' || inputFields.selected_location_postcode === '' || inputFields.selected_location_city === ''}
          loadingPosition="end"
          endIcon={<Send />}
          autoFocus
          loading={updatedLocation.loading}
        >
          Save updates
        </LoadingButton>
        <Button onClick={handleClose} className={'actionTwo button-cancel'} endIcon={<Clear />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
