import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, Avatar, TextField, Card, CardHeader, IconButton, Stack, Chip, CircularProgress } from '@mui/material';
import { Add, Clear, Close, Delete, Send } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { CaptainIcon } from '../../img/icons/Icons';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Controller, useForm } from 'react-hook-form';
import LoadingComponent from '../common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';

export default function EditTeamLineupModal(props: { team_lineup_id: string; handleClose: any; open: any; goToHome?: any }) {
  const FIND_TEAM_LINEUP_TO_EDIT_BY_ID_QUERY = gql`
    query findTeamLineupToEditByID($id: ID!) {
      findTeamLineupToEditByID(id: $id) {
        id
        name
        players {
          id
          status
          team_number
          team_player {
            ... on FootballTeam {
              __typename
              id
              name
            }
          }
          player {
            ... on Footballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
          }
        }
        captains {
          id
          status
          team_number
          player {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
        }
        team {
          ... on FootballTeam {
            __typename
            id
            players {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                  avatar {
                    path
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                  avatar {
                    path
                  }
                }
              }
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                  avatar {
                    path
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                  avatar {
                    path
                  }
                }
              }
            }
            managers {
              id
              status
              username
              avatar {
                path
              }
              cover {
                path
              }
            }
          }
        }
        home_challenges {
          id
          short_id
          title
          status
        }
        away_challenges {
          id
          short_id
          title
          status
        }
      }
    }
  `;

  const EDIT_TEAM_LINEUP_MUTATION = gql`
    mutation editTeamLineup($team_lineup: inputEditTeamLineup!) {
      editTeamLineup(team_lineup: $team_lineup) {
        id
      }
    }
  `;

  const ADD_TEAM_PLAYER_IN_TEAM_LINEUP_MUTATION = gql`
    mutation addTeamPlayerFromTeamLineup($team_lineup: inputAddTeamPlayerInTeamLineup!) {
      addTeamPlayerFromTeamLineup(team_lineup: $team_lineup)
    }
  `;

  const REMOVE_TEAM_PLAYER_IN_TEAM_LINEUP_MUTATION = gql`
    mutation removeTeamPlayerFromTeamLineup($team_lineup: inputRemoveTeamPlayerInTeamLineup!) {
      removeTeamPlayerFromTeamLineup(team_lineup: $team_lineup)
    }
  `;

  const DELETE_TEAM_LINEUP_MUTATION = gql`
    mutation deleteTeamLineup($team_lineup: inputDeleteTeamLineup!) {
      deleteTeamLineup(team_lineup: $team_lineup)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [teamLineup, setTeamLineup] = React.useState(UNDEFINED_AS_ANY);

  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [editTeamLineup, updatedTeamLineup] = useMutation(EDIT_TEAM_LINEUP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteTeamLineup, deletedTeamlineup] = useMutation(DELETE_TEAM_LINEUP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [addTeamPlayerFromTeamLineup, addedTeamPlayer] = useMutation(ADD_TEAM_PLAYER_IN_TEAM_LINEUP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [removeTeamPlayerFromTeamLineup, removedTeamPlayer] = useMutation(REMOVE_TEAM_PLAYER_IN_TEAM_LINEUP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTeamLineup = useQuery(FIND_TEAM_LINEUP_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.team_lineup_id,
    },
  });

  const handleClose = () => {
    props.handleClose();
    setTeamLineup(UNDEFINED_AS_ANY);
    setValue('name', UNDEFINED_AS_ANY);
    reset();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedTeamLineup.refetch({
        id: props.team_lineup_id,
      });

      if (result) {
        setTeamLineup(result.data?.findTeamLineupToEditByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddTeamPlayer = async (team_player_id: string) => {
    try {
      await addTeamPlayerFromTeamLineup({
        variables: {
          team_lineup: {
            id: teamLineup?.id,
            team_player_id: team_player_id,
            is_captain: teamLineup?.team?.captains?.find((c: any) => c?.id === team_player_id) ? true : false,
          },
        },
      });
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleRemoveTeamPlayer = async (team_player_id: string) => {
    try {
      await removeTeamPlayerFromTeamLineup({
        variables: {
          team_lineup: {
            id: teamLineup?.id,
            team_player_id: team_player_id,
          },
        },
      });
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditTeamLineup = async () => {
    try {
      await editTeamLineup({
        variables: {
          team_lineup: {
            logged_player_id: loggedPlayer?.id,
            id: teamLineup?.id,
            name: _.trim(inputFields.name),
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handledeleteTeamLineup = async () => {
    try {
      await deleteTeamLineup({
        variables: {
          team_lineup: {
            logged_player_id: loggedPlayer?.id,
            id: props.team_lineup_id,
            team_id: teamLineup?.team?.id,
          },
        },
      });

      if (props.goToHome) {
        props.goToHome();
      }

      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && teamLineup === undefined && loadedTeamLineup.called && loadedTeamLineup.data?.findTeamLineupToEditByID) {
      setTeamLineup(loadedTeamLineup.data?.findTeamLineupToEditByID);
    }

    if (props.open && teamLineup) {
      if (!inputFields.name && teamLineup?.name) setValue('name', teamLineup?.name);
    }

    if (!props.open && teamLineup) {
      setTeamLineup(UNDEFINED_AS_ANY);
    }
  }, [teamLineup, setValue, inputFields, loadedTeamLineup, setTeamLineup, props]);

  return (
    <Dialog
      open={props.open}
      onClose={updatedTeamLineup.loading || addedTeamPlayer.loading || removedTeamPlayer.loading || deletedTeamlineup.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', m: '0 auto' }}>
          <Fab
            size="small"
            onClick={handleClose}
            disabled={updatedTeamLineup.loading || addedTeamPlayer.loading || removedTeamPlayer.loading || deletedTeamlineup.loading}
            className={updatedTeamLineup.loading || addedTeamPlayer.loading || removedTeamPlayer.loading || deletedTeamlineup.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right', m: '0 auto' }}>
          <IconButton className="button-red-reversed" onClick={handledeleteTeamLineup}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Edit {teamLineup?.name}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      {loadedTeamLineup.loading ? (
        <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>Loading team lineup, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} />
      ) : (
        <>
          <DialogContent sx={{ pl: '0', pr: '0' }}>
            <DialogContentText id="alert-dialog-description" component="span">
              <Grid container sx={{ p: '0 5%' }}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                  }}
                  render={({ field }: any) => <TextField {...field} label="Name" variant="outlined" fullWidth required className={'field-bottom-space'} value={field.value} error={errors?.name?.type !== undefined} helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? 'Name must have at least 4 characters' : 'You should ONLY use letters, numbers or underscore') : ``}` : ''} />}
                />
              </Grid>

              <Typography id="max-width" variant="h3" sx={{ fontSize: '1em', p: '40px 5% 20px', color: 'rgba(247, 251, 250, 1)' }}>
                benched players
              </Typography>
              <Grid sx={{ overflow: 'auto', width: '100%', p: '0 5%' }}>
                <Stack direction="row">
                  {_.uniqBy(teamLineup?.team?.players, 'id')
                    ?.filter((p: any) => p?.status === 'ACTIVE')
                    ?.filter((p: any) => !teamLineup?.players?.find((tp: any) => tp?.id === p?.id))
                    ?.map((p: any) => (
                      <Chip
                        key={p?.id}
                        label={p?.player?.alias}
                        avatar={<Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} />}
                        disabled={addedTeamPlayer.loading || removedTeamPlayer.loading}
                        onDelete={() => handleAddTeamPlayer(p?.id)}
                        deleteIcon={<Add />}
                        sx={{ 'fontSize': '1.1em', 'fontWeight': '700', 'm': '0 20px 0 0', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(247, 251, 250, .1)', '& svg': { 'borderRadius': '100%', 'color': 'rgba(199, 240, 11, 1) !important', '&:hover': { bgcolor: 'rgba(199, 240, 11, 1) !important', color: 'rgba(15, 15, 15, 1) !important' } } }}
                      />
                    ))}
                </Stack>
              </Grid>

              <Typography id="max-width" variant="h3" sx={{ fontSize: '1em', p: '40px 5% 20px', color: 'rgba(247, 251, 250, 1)' }}>
                Starting Line Up
              </Typography>
              <Grid container sx={{ justifyContent: 'center' }}>
                {teamLineup?.players
                  ?.filter((p: any) => p?.status === 'ACTIVE')
                  ?.map((p: any, index: number) => (
                    <Card
                      key={index}
                      className={'card'}
                      sx={{
                        bgcolor: 'rgba(247, 251, 250, .1)',
                        p: '0px',
                        width: { xs: '180px', md: '200px' },
                        height: { xs: '180px', md: '200px' },
                        m: '0 10px',
                        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.9) 70%), url(${p?.player?.cover?.path})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid container>
                        <CardHeader
                          avatar={teamLineup?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : ''}
                          action={
                            <>
                              {teamLineup?.team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || teamLineup?.team?.captains?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || teamLineup?.team_founder?.player?.id === loggedPlayer?.id ? (
                                <IconButton disabled={addedTeamPlayer.loading || removedTeamPlayer.loading} className={addedTeamPlayer.loading || removedTeamPlayer.loading ? 'button-dibsaled' : 'button-red-reversed'} onClick={() => handleRemoveTeamPlayer(p?.id)}>
                                  <Clear />
                                </IconButton>
                              ) : (
                                ''
                              )}
                            </>
                          }
                          sx={{ p: '0 10px', width: '100%', height: '32px' }}
                        />
                        <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Grid>
                            <Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} sx={{ m: 'auto', width: { xs: '90px' }, height: { xs: '90px' } }} />
                            <Typography sx={{ fontWeight: '700' }}>
                              {p?.team_number ? (
                                <Typography component="span" sx={{ fontWeight: '700' }}>
                                  #{p?.team_number}&nbsp;&nbsp;
                                </Typography>
                              ) : (
                                ''
                              )}
                              {p?.player?.alias}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
              </Grid>
            </DialogContentText>
            <DialogActions sx={{ p: '60px 0 0' }}>
              <LoadingButton loading={updatedTeamLineup.loading} loadingPosition="end" endIcon={<Send />} disabled={addedTeamPlayer.loading || removedTeamPlayer.loading} className={addedTeamPlayer.loading || removedTeamPlayer.loading ? 'button-disabled' : 'button-green'} fullWidth onClick={handleEditTeamLineup}>
                save updates
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

EditTeamLineupModal.propTypes = {
  team_lineup_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

EditTeamLineupModal.defaultProps = {};
