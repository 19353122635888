import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, CardMedia, Dialog, DialogContent, Fab, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { Favorite, Send } from '@mui/icons-material';
import NumberFormatter from '../layout/NumberFormatter';
import { selectPlayer } from '../../reducers/playerSlice';
import { ShareIcon } from '../../img/icons/Icons';
import LivestreamVideoPlayer from '../features/LivestreamVideoPlayer';
import { MOODS } from '../../utilities/utilities';
import useEventLog from '../../hooks/useEventLog';
import { useIntl } from 'react-intl';

interface IViewLivestreamModal {
  livestream_id: string;
  handleClose: any;
  open: boolean;
}

const ViewLivestreamModal: React.FC<IViewLivestreamModal> = (props: IViewLivestreamModal) => {
  const FIND_LIVESTREAM_BY_ID_QUERY = gql`
    query findLivestreamByID($livestream: inputFindLivestreamByID!) {
      findLivestreamByID(livestream: $livestream) {
        id
        room_id
        title
        status
        video_url
        anonymous_participants
        author {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on Position {
            __typename
            id
            role
            company {
              id
              name
              label
              logo {
                path
              }
            }
            employees {
              id
              username
              avatar {
                filename
                path
              }
              owner {
                firstname
              }
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        participants {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        liked_by {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        shared_by {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        created_at
      }
    }
  `;

  const LIKE_LIVESTREAM_MUTATION = gql`
    mutation likeLivestream($livestream: inputReactLivestream!) {
      likeLivestream(livestream: $livestream)
    }
  `;

  const DISLIKE_LIVESTREAM_MUTATION = gql`
    mutation dislikeLivestream($livestream: inputReactLivestream!) {
      dislikeLivestream(livestream: $livestream)
    }
  `;

  const SHARE_LIVESTREAM_MUTATION = gql`
    mutation shareLivestream($livestream: inputReactLivestream!) {
      shareLivestream(livestream: $livestream)
    }
  `;

  const UNSHARE_LIVESTREAM_MUTATION = gql`
    mutation unshareLivestream($livestream: inputReactLivestream!) {
      unshareLivestream(livestream: $livestream)
    }
  `;

  const CREATE_COMMENT_MUTATION = gql`
    mutation createComment($comment: inputCreateComment!) {
      createComment(comment: $comment) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const { handleSendLog } = useEventLog();
  const intl = useIntl();
  const [livestream, setLivestream] = React.useState(UNDEFINED_AS_ANY);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState(UNDEFINED_AS_ANY);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      flexer_id: '',
      comment_input: '',
      selected_mood: 'NEUTRAL',
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const [likeLivestream, likedLivestream] = useMutation(LIKE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [dislikeLivestream, dislikedLivestream] = useMutation(DISLIKE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [shareLivestream, sharedLivestream] = useMutation(SHARE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unshareLivestream, unsharedLivestream] = useMutation(UNSHARE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createComment, newComment] = useMutation(CREATE_COMMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedLivestream = useQuery(FIND_LIVESTREAM_BY_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    skip: !props.livestream_id,
    variables: {
      livestream: {
        id: props.livestream_id,
        comments_order: 'ASC',
      },
    },
  });

  const handleClose = () => {
    setLivestream(UNDEFINED_AS_ANY);
    setIsPlaying(false);
    setDuration(UNDEFINED_AS_ANY);
    props.handleClose();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedLivestream.refetch({
        livestream: {
          id: props.livestream_id,
        },
      });

      if (result.data?.findLivestreamByID) {
        setLivestream(result.data?.findLivestreamByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current?.play().catch((error) => {
          console.error('Error playing video:', error);
          if (error.name === 'NotSupportedError') {
            // Handle the error appropriately, e.g., display a message to the user
            alert('This video format is not supported by your browser.');
          }
        });
        setIsPlaying(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handlePauseVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current?.pause();
        setIsPlaying(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleLikeLivestream = async () => {
    try {
      await likeLivestream({
        variables: {
          livestream: {
            id: livestream?.id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDislikeLivestream = async () => {
    try {
      await dislikeLivestream({
        variables: {
          livestream: {
            id: livestream?.id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleShareLivestream = async () => {
    try {
      await shareLivestream({
        variables: {
          livestream: {
            id: livestream?.id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUnshareLivestream = async () => {
    try {
      await unshareLivestream({
        variables: {
          livestream: {
            id: livestream?.id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeMood = (value: any) => {
    setValue('selected_mood', value);
  };

  const handleCreateComment = async () => {
    try {
      const result = await createComment({
        variables: {
          comment: {
            entity_id: livestream?.id,
            mood: inputFields.selected_mood,
            content: inputFields.comment_input,
            author_id: inputFields.flexer_id,
          },
        },
      });

      if (result.data?.createComment) {
        setValue('comment_input', '');
        await handleRefetch();
      }
    } catch (e: any) {
      console.log(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  React.useEffect(() => {
    if (!duration && videoRef.current?.duration) {
      setDuration(videoRef.current?.duration);
    }
  }, [duration, videoRef.current?.duration]);

  React.useEffect(() => {
    if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
  }, [inputFields, setValue, ALL_FLEXERS]);

  React.useEffect(() => {
    if (props.open && !livestream && loadedLivestream.data?.findLivestreamByID) {
      setLivestream(loadedLivestream.data?.findLivestreamByID);
    }

    if (!props.open && livestream) {
      setLivestream(UNDEFINED_AS_ANY);
    }
  }, [props, livestream, loadedLivestream]);

  return (
    <Dialog open={props.open} onClose={handleClose} PaperProps={{ sx: { bgcolor: 'rgba(255, 255, 255, 1)', borderRadius: '20px', p: '20px 0', minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' } } }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      {livestream && (
        <DialogContent sx={{ p: '0 20px' }}>
          {livestream?.status === 'REMOVED' ? (
            <Typography sx={{ width: '100%', textAlign: 'center', textTransform: 'uppercase' }}>
              Status:&nbsp;<strong className={livestream?.status}>{livestream?.status}</strong>
            </Typography>
          ) : (
            ''
          )}

          <Grid container sx={{ alignItems: 'center', justifyContent: 'center', mb: '10px' }}>
            <Avatar src={(livestream?.author?.avatar || livestream?.author?.logo)?.path} alt={livestream?.author?.username || livestream?.author?.label || livestream?.author?.name} sx={{ height: '30px', width: '30px' }} />
            &nbsp;<Typography sx={{ fontWeight: '800' }}>{livestream?.author?.username || livestream?.author?.label || livestream?.author?.name}</Typography>
          </Grid>

          {livestream?.status === 'ON_GOING' ? (
            <LivestreamVideoPlayer room_id={livestream.room_id} status={livestream.status} />
          ) : (
            <>
              <CardMedia
                component={'video'}
                ref={videoRef}
                loop
                controls={isPlaying}
                onClick={isPlaying ? handlePauseVideo : handlePlayVideo}
                style={{ borderRadius: '20px', maxHeight: '50vh', width: '100%', margin: 'auto' }} // Adjusted to maintain aspect ratio
              >
                <source src={livestream?.video_url} type="video/mp4" />
                <source src={livestream?.video_url} type="video/webm" />
                Your browser does not support the video tag.
              </CardMedia>
              <Grid container sx={{ justifyContent: 'center' }}>
                {!isPlaying ? (
                  <Fab variant="extended" className="button-green" onClick={handlePlayVideo} sx={{ boxShadow: 'none', position: 'relative', mt: '-45%' }}>
                    Replay
                  </Fab>
                ) : (
                  ''
                )}
              </Grid>
            </>
          )}
          <Grid container>
            <Grid
              item
              xl={6}
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                p: '0px 0 0',
              }}
            >
              {loggedPlayer?.id ? (
                <>
                  {livestream?.liked_by?.find((p: any) => p?.id === inputFields.flexer_id) ? (
                    <IconButton onClick={handleDislikeLivestream} disabled={dislikedLivestream.loading} className={dislikedLivestream.loading ? 'button-disabled' : 'button-red-reversed'} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em' }}>
                      {livestream?.liked_by?.length > 0 ? (
                        <>
                          <NumberFormatter number={livestream?.liked_by?.length} />
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <Favorite />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleLikeLivestream} disabled={likedLivestream.loading} className={likedLivestream.loading ? 'button-disabled' : 'button-cancel'} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em' }}>
                      {livestream?.liked_by?.length > 0 ? (
                        <>
                          <NumberFormatter number={livestream?.liked_by?.length} />
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <Favorite />
                    </IconButton>
                  )}
                  {livestream?.shared_by?.find((p: any) => p?.id === inputFields.flexer_id) ? (
                    <IconButton onClick={handleUnshareLivestream} disabled={unsharedLivestream.loading} className={unsharedLivestream.loading ? 'button-disabled' : ''} sx={{ 'display': 'inline-block', 'textAlign': 'center', 'fontSize': '1em', 'border': 'none !important', 'color': 'rgba(31, 175, 146, 1)', '&:hover': { bgcolor: 'transparent !important', color: 'rgba(31, 175, 146, 1)' } }}>
                      {livestream?.shared_by?.length > 0 ? (
                        <>
                          <NumberFormatter number={livestream?.shared_by?.length} />
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <ShareIcon sx={{ color: 'inherit !important' }} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleShareLivestream} disabled={sharedLivestream.loading} className={sharedLivestream.loading ? 'button-disabled' : 'button-cancel'} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em' }}>
                      {livestream?.shared_by?.length > 0 ? (
                        <>
                          <NumberFormatter number={livestream?.shared_by?.length} />
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <ShareIcon sx={{ color: 'inherit !important' }} />
                    </IconButton>
                  )}
                </>
              ) : (
                ''
              )}
            </Grid>
            <Grid
              item
              xl={6}
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                p: '0px 0 0',
              }}
            >
              <Controller
                name="flexer_id"
                control={control}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    // label={`As`}
                    select
                    variant="outlined"
                    className="no-borders"
                    SelectProps={{
                      IconComponent: undefined,
                      sx: {
                        'color': 'rgba(15,15,15, 1)',
                        'border': 'none',
                        'bgcolor': 'transparent',
                        '& #mui-component-select-flexer_id': { p: '10px 0' },
                        '&::-ms-expand': {
                          display: 'none',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'rgba(15,15,15, 1)',
                        p: '0 6px',
                        borderRadius: '5px',
                        textAlign: 'center',
                        width: '100%',
                      },
                    }}
                    sx={{ m: '10px 0 0', p: '0', width: 'auto' }}
                  >
                    {ALL_FLEXERS.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Grid
                          container
                          sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar
                            src={option?.avatar}
                            sx={{
                              width: '25px',
                              height: '25px',
                            }}
                          />
                          &nbsp;&nbsp;
                          <Typography variant="caption">{option.name}</Typography>
                        </Grid>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid container>
              {!session?.token?.key ? (
                ''
              ) : (
                <>
                  <Controller
                    name="comment_input"
                    control={control}
                    rules={{ required: false, pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._[]\s-]{5,60}$/ }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        placeholder="Write a comment..."
                        fullWidth
                        value={_.trimStart(field.value)}
                        multiline
                        minRows={1}
                        disabled={newComment.loading}
                        InputProps={{
                          startAdornment: (
                            <Controller
                              control={control}
                              name="selected_mood"
                              render={({ field }: any) => (
                                <TextField {...field} value={field.value} select variant="outlined" SelectProps={{ MenuProps: { sx: { '& li .MuiGrid-root': { justifyContent: 'start', fontWeight: '500', color: 'rgba(15, 15, 15, 1)' } } } }}>
                                  {MOODS?.map((option) => (
                                    <MenuItem key={option?.value} value={option?.value} onClick={() => handleChangeMood(option?.value)}>
                                      <Grid container sx={{ justifyContent: 'center', fontWeight: '700', color: 'rgba(247, 251, 250, .4)' }}>
                                        {option?.icon}&nbsp;&nbsp;
                                        {intl.formatMessage({ id: option?.label })}
                                      </Grid>
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          ),
                          endAdornment: (
                            <InputAdornment position="end" sx={{ justifyContent: 'center', alignItems: 'center', p: { xs: '40px 0 10px', sm: '0' } }}>
                              <IconButton onClick={handleCreateComment}>
                                <Send sx={{ color: 'rgba(247, 251, 250, 1)' }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                          sx: { 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'color': 'rgba(247, 251, 250, 1)', 'mt': '0', 'mb': '0', '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& textarea': { borderRadius: '10px', bgcolor: 'rgba(247, 251, 250, .04)', p: '10px 0px 10px 5px' } },
                        }}
                        sx={{ mb: '20px', bgcolor: 'rgba(247, 251, 250, 0)', borderRadius: '10px' }}
                      />
                    )}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

ViewLivestreamModal.propTypes = {
  livestream_id: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

ViewLivestreamModal.defaultProps = {
  open: false,
  livestream_id: '',
};

export default React.memo(ViewLivestreamModal);
