import { ImageListType } from 'react-images-uploading';
import { COUNTRIES } from './utilities';

export interface CountryType {
  value: string;
  label: string;
  phone?: string;
  continent: string;
  country_alpha_3: string;
}

interface IDescription {
  __typename?: string;
  language?: string;
  content?: string;
}

export const UNDEFINED_AS_ANY: any = undefined;
export const NULL_AS_ANY: any = null;
export const DATE_AS_ANY: Date | null = null;
export const INITIAL_COUNTRY: CountryType = COUNTRIES[0];
export const OBJ_AS_ANY: any = {};
export const ARRAY_AS_ANY: any = [];
export const MEDIAS_AS_ANY: ImageListType | null = [];
export const DESCRIPTION_AS_ANY: IDescription = {};
export const CART_ITEMS_AS_ANY: any = [];
export const IMAGE_LIST_AS_ANY: ImageListType = [];
