import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const ShareURLButton = (props: { button: any; url: string; title: string; text?: string; img?: any }) => {
  const handleShare = async () => {
    try {
      if (window.navigator.share) {
        await window.navigator
          .share({
            // Title that occurs over
            // web share dialog
            title: props.title,
            text: props.text,
            // URL to share
            url: props.url,
            // files: [props.img ? props.img : blob],
          })
          .then(() => {
            console.log('Thanks for sharing!');
          })
          .catch((err: any) => {
            // Handle errors, if occured
            console.log('Error while using Web share API:');
            console.log(err);
          });
      } else {
        // Alerts user if API not available
        alert(`Browser doesn't support this feature! (${props.url})`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return <Grid onClick={handleShare}>{props.button}</Grid>;
};

export default ShareURLButton;

ShareURLButton.propTypes = {
  button: PropTypes.node,
};
