import React from 'react';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';
import { ListItemIcon, Menu, MenuItem, Typography, Badge, Divider, ListItemSecondaryAction, ListItemAvatar, ListItemText, ListItem, List, Avatar, TextField, IconButton, Grid, Card, AvatarGroup, Fab } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { SearchIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import TopBar from '../../component-modals/layout/TopBar';
import NewConversationModal from '../../component-modals/contacts/NewConversationModal';
import AddContactModal from '../../component-modals/contacts/AddContactModal';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectPlayer } from '../../reducers/playerSlice';
import BackButton from '../../component-modals/common/BackButton';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

const ListConversations: React.FC = () => {
  const FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY = gql`
    query findConversationByParticipantsIDs($conversations: inputFindConversationByParticipantsIDs!) {
      findConversationByParticipantsIDs(conversations: $conversations) {
        id
        short_id
        title
        avatar {
          path
        }
        description
        created_at
        edited_at
        participants {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        messages {
          id
          status
          content
          created_at
          viewers {
            ... on Company {
              __typename
              id
              name
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const location = useLocation();
  const intl = useIntl();
  const [conversations, setConversations] = React.useState(UNDEFINED_AS_ANY);
  const [searchInput, setSearchInput] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openNewConversation, setOpenNewConversation] = React.useState(false);
  const [openNewContact, setOpenNewContact] = React.useState(false);
  const [offset] = React.useState(0);

  const loadedConversations = useQuery(FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      conversations: {
        participants: _.concat(loggedPlayer?.id)?.filter((c: any) => c !== undefined || c !== null),
        offset: offset,
      },
    },
    pollInterval: !loggedPlayer?.id ? undefined : 15000,
  });

  const ALL_PARTICIPANTS = _.concat(loggedPlayer);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNewConversation = () => {
    handleClose();
    setOpenNewConversation(true);
  };

  const handleCloseNewConversation = () => {
    setOpenNewConversation(false);
  };

  const handleOpenNewContact = () => {
    handleClose();
    setOpenNewContact(true);
  };

  const handleCloseNewContact = () => {
    setOpenNewContact(false);
  };

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  React.useEffect(() => {
    if (conversations === undefined && loadedConversations.data?.findConversationByParticipantsIDs) {
      setConversations(loadedConversations.data?.findConversationByParticipantsIDs);
    }
  }, [loadedConversations, conversations, setConversations]);

  return (
    <Grid container className={'component-contacts list-conversations-page dark-mode'} sx={{ minHeight: '100%', display: 'block' }}>
      <Grid item xl={12} xs={12} sm={12}>
        <TopBar position={'static'} backgroundColor={'transparent'} color={'#F7FBFA'} logo={<FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />} />

        <Typography variant="caption" sx={{ fontSize: '.8em', p: '0 10% 0' }}>
          {conversations?.length > 1 ? (
            <>
              {conversations?.length} {conversations?.length > 1 ? `${intl.formatMessage({ id: 'Conversations_Conversations_Title' })}` : `${intl.formatMessage({ id: 'Conversations_Conversation_Title' })}`}
            </>
          ) : (
            ''
          )}
        </Typography>

        <Grid container className={'actions'} sx={{ alignItems: 'center' }}>
          <Grid item xl={6} xs={6} sm={6} className={'back-button'} sx={{ textAlign: 'left', p: '0 20px' }}>
            <BackButton />
          </Grid>
          <Grid item xl={6} xs={6} sm={6} className={'menu-icon'} sx={{ textAlign: 'right', p: '0 20px' }}>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <MoreVert />
            </IconButton>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem disabled={loadedConversations.error !== undefined} onClick={loadedConversations.error !== undefined ? undefined : handleOpenNewConversation}>
                <ListItemIcon>
                  <AddCommentOutlinedIcon />
                </ListItemIcon>
                {`${intl.formatMessage({ id: 'Conversations_Button_New_Conversation' })}`}
              </MenuItem>
              {location.pathname === '/conversations' ? (
                ''
              ) : (
                <MenuItem component={Link} to={`/conversations`}>
                  <ListItemIcon>
                    <ChatOutlinedIcon />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'Conversations_Button_Conversations' })}`}
                </MenuItem>
              )}
              <MenuItem onClick={handleOpenNewContact}>
                <ListItemIcon>
                  <PersonAddOutlinedIcon />
                </ListItemIcon>
                {`${intl.formatMessage({ id: 'Conversations_Button_Add_Contact' })}`}
              </MenuItem>
              <MenuItem component={Link} to={`/list_contacts`}>
                <ListItemIcon>
                  <PeopleAltOutlinedIcon />
                </ListItemIcon>
                {`${intl.formatMessage({ id: 'Conversations_Button_Contacts' })}`}
              </MenuItem>
            </Menu>

            <AddContactModal open={openNewContact} handleClose={handleCloseNewContact} />
            <NewConversationModal open={openNewConversation} handleClose={handleCloseNewConversation} />
          </Grid>
        </Grid>
        <Grid container className={'search'} sx={{ p: '0 10%' }}>
          {conversations?.length > 1 ? (
            <TextField
              placeholder="Search in all conversations"
              fullWidth
              value={searchInput}
              onChange={handleSearchQuery}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                sx: {
                  color: 'rgba(247, 251, 250, 1)',
                  border: 'solid 1px rgba(247, 251, 250, .3)',
                  bgcolor: 'rgba(247, 251, 250, .1)',
                },
              }}
              InputLabelProps={{
                sx: {
                  color: 'rgba(247, 251, 250, 1)',
                  bgcolor: 'rgb(15,15,15)',
                  p: '0 6px',
                  borderRadius: '5px',
                },
              }}
            />
          ) : (
            ''
          )}

          {conversations?.length === 0 || conversations?.length === undefined ? (
            <Grid sx={{ textAlign: 'center', width: '100%' }}>
              <Typography sx={{ textAlign: 'center', fontWeight: '100', fontSize: '1.3em', width: '100%' }}>{`${intl.formatMessage({ id: 'Conversations_Start_Now_Title' })}`}</Typography>
              <Fab variant="extended" size="small" sx={{ boxShadow: 'none', mt: '20px', height: 'auto', p: '10px', border: 'none !important' }} className="button-green-reverse" onClick={loadedConversations.error !== undefined ? undefined : handleOpenNewConversation}>
                <AddCommentOutlinedIcon />
                &nbsp;
                {loadedConversations.error !== undefined ? (
                  <>
                    {`${intl.formatMessage({ id: 'Conversations_Disabled_1' })}`}

                    <br />
                    {`${intl.formatMessage({ id: 'Conversations_Disabled_2' })}`}
                  </>
                ) : (
                  `${intl.formatMessage({ id: 'Conversations_Button_New_Conversation' })}`
                )}
              </Fab>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Grid container sx={{ p: '0 10%' }}>
          <List sx={{ width: '100%' }}>
            {conversations
              ?.filter((convo: any) => convo?.title?.includes(searchInput))
              .map((convo: any, index: any) => (
                <Card
                  key={index}
                  component={Link}
                  to={{ pathname: `/c/${convo?.short_id}` }}
                  sx={{
                    display: 'inline-block',
                    bgcolor: 'rgba(247, 251, 250, .04)',
                    boxShadow: 'none',
                    p: '0px',
                    width: '100%',
                    borderRadius: '5px',
                    m: '0 auto 30px',
                    textDecoration: 'none',
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Badge className={'message-unread'} color="error" max={9} badgeContent={convo?.messages?.filter((m: any) => (m?.status === 'UNREAD' || m?.status === 'PARTIALLY_SEEN') && !m?.viewers?.find((u: any) => ALL_PARTICIPANTS?.find((p: any) => p?.id === u?.id)))?.length}>
                        <Avatar alt={`${convo?.title}`} src={`${convo?.avatar?.path}`} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <Grid container component="span">
                          <AvatarGroup max={5} total={convo?.participants?.length} spacing="small" component="span">
                            {convo?.participants?.map((p: any) => (
                              <Avatar key={p?.id} component="span" src={p?.avatar?.path || p?.logo?.path} alt={p?.avatar?.username || p?.logo?.name} />
                            ))}
                          </AvatarGroup>
                        </Grid>
                      }
                      primary={
                        <>
                          {convo?.title ? (
                            <>
                              <strong>{convo?.title}</strong>
                            </>
                          ) : (
                            ''
                          )}
                          {convo?.messages?.length > 0 ? `: ${_.truncate(convo?.messages[0]?.content, { length: 30 })}` : ''}
                        </>
                      }
                      primaryTypographyProps={{ color: 'rgba(247, 251, 250, 1)', fontWeight: '100', pb: '10px' }}
                    />
                    <ListItemSecondaryAction sx={{ color: 'rgba(247, 251, 250, 1)' }}>
                      <Typography variant="caption">
                        <Moment fromNow>{convo?.messages[0]?.created_at || convo?.edited_at || convo?.created_at}</Moment>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </Card>
              ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListConversations;
