import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Stack, Button } from '@mui/material';
import { TIMESLOTS, WEEKDAYS } from '../../utilities/utilities';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface IInputSelectAvailabilitiesFeature {
  setter: any;
  input: any[];
}

const InputSelectAvailabilitiesFeature: React.FC<IInputSelectAvailabilitiesFeature> = (props: IInputSelectAvailabilitiesFeature) => {
  const intl = useIntl();
  const { watch, setValue } = useForm({
    defaultValues: {
      day: 'MONDAY',
    },
  });
  const inputFields = watch();

  const handleWeekday = (day: string) => {
    setValue('day', day);
  };

  const handleDeleteHour = (selected_hour: number) => {
    const newList = props.input?.filter((a: any) => !(a?.hour === selected_hour && a?.day === inputFields.day));
    props.setter('availabilities', newList);
  };

  const handleAddAvailability = async (selected_hour: number) => {
    if (props.input?.find((a: any) => a?.day === inputFields.day && a?.hour === selected_hour) === undefined) {
      const newList = _.concat(props.input, { day: inputFields.day, hour: selected_hour })?.filter((i: any) => i);
      props.setter('availabilities', newList);
    }
  };

  const handleClick = (selected_hour: number) => {
    if (inputFields.day) {
      if (props.input?.find((a: any) => a?.day === inputFields.day && a?.hour === selected_hour) === undefined) {
        handleAddAvailability(selected_hour);
      } else {
        handleDeleteHour(selected_hour);
      }
    }
  };

  return (
    <>
      <Grid className={'weekdays'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
        <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
          {WEEKDAYS.map((day: any) => (
            <Button
              key={day.value}
              value={day.value}
              onClick={() => handleWeekday(day.value)}
              sx={{
                'height': '50px',
                'width': '120px',
                'm': '0 10px 0',
                'display': 'inline-block',
                'borderRadius': '10px',
                'border': inputFields.day === day.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                'color': inputFields.day === day.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                '& svg': { fontSize: '40px', color: inputFields.day === day.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
              }}
            >
              {`${intl.formatMessage({ id: day.label })}`}
            </Button>
          ))}
        </Stack>
      </Grid>

      <Grid className={'timeslots'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
        <Stack direction="row" sx={{ display: 'inline-flex', p: '20px 5% 0 0', minWidth: '300px' }}>
          {TIMESLOTS?.map((hour: any) => (
            <Button
              key={hour.value}
              value={hour.value}
              onClick={() => handleClick(hour.value)}
              sx={{
                'height': '50px',
                'width': '120px',
                'm': '0 10px 0',
                'display': 'inline-block',
                'borderRadius': '10px',
                'border': props.input?.find((a: any) => a?.hour === hour.value && a?.day === inputFields.day) ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                'color': props.input?.find((a: any) => a?.hour === hour.value && a?.day === inputFields.day) ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                '& svg': { fontSize: '40px', color: props.input?.find((a: any) => a?.hour === hour.value && a?.day === inputFields.day) ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
              }}
            >
              {hour.label}
            </Button>
          ))}
        </Stack>
      </Grid>
    </>
  );
};

InputSelectAvailabilitiesFeature.propTypes = {
  input: PropTypes.any.isRequired,
  setter: PropTypes.any.isRequired,
};

InputSelectAvailabilitiesFeature.defaultProps = {
  input: [],
  setter: undefined,
};

export default InputSelectAvailabilitiesFeature;
