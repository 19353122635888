import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Button, Divider, Fab, Grid } from '@mui/material';
import { Send, Clear, Close } from '@mui/icons-material';
import { JubileeIcon } from '../../img/icons/Icons';

export default function MakeJubileeModal(props: { profile_id: string; handleClose: any; open: any }) {
  const handleClose = () => {
    props.handleClose();
  };

  const handleStartJubilee = () => {
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <>
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
          <JubileeIcon sx={{ color: 'initial !important', fontSize: '2em' }} />
          <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>🎉 Commemorating Your Legendary Career with NFTs! 🎉</Typography>
        </DialogTitle>
        <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      </>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Typography sx={{ m: { xs: '0 5% 20px', sm: '0 10% 20px' }, color: 'rgba(247, 251, 250, 1)', fontSize: { xs: '1em', sm: '1.2em' } }}>
            Congratulations on an extraordinary career that has left an indelible mark on the world of sports. As you approach this incredible milestone, we&apos;re thrilled to introduce a unique opportunity to celebrate your journey like never before.
            <br />
            <br />
            We&apos;re excited to present the idea of transforming your remarkable career statistics into NFTs (Non-Fungible Tokens), creating digital collectibles that capture the essence of your achievements on and off the field.
            <br />
            <br />
            <br />
            <strong>Why Consider NFTs for Your Career Stats?</strong>
            <br />
            <br />
            <strong>Ownership</strong>: Each NFT will represent a specific moment in your career, allowing you and your fans to own a piece of history.
            <br />
            <strong>Versatility</strong>: You&apos;ll have the flexibility to keep, trade, or gift these NFTs, sharing your legacy with admirers around the world.
            <br />
            <strong>Timeless Impact</strong>: Embracing NFTs will ensure your legacy continues to inspire across generations.
            <br />
            <br />
            We&apos;re eager to discuss this concept further and answer any queries you may have. Please reach out to us at your convenience.
            <br />
            <br />
            Your journey has been nothing short of awe-inspiring, and this NFT jubilee is a befitting tribute to your greatness.
          </Typography>
        </DialogContentText>
        <Grid container sx={{ p: { xs: '20px 5% 30px', sm: '20px 20% 30px', md: '20px 30% 30px' }, justifyContent: 'center' }}>
          <LoadingButton onClick={handleStartJubilee} className={'actionOne button-green'} fullWidth loadingPosition="end" endIcon={<Send />} sx={{ m: '10px' }}>
            Coming soon{/* Start jubilee */}
          </LoadingButton>
          <Button onClick={handleClose} className={'actionTwo button-cancel'} fullWidth endIcon={<Clear />} sx={{ m: '10px' }}>
            Cancel
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

MakeJubileeModal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  secondaryContent: PropTypes.any,
  actionOneTitle: PropTypes.any,
  actionTwoTitle: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.any,
};

MakeJubileeModal.defaultProps = {
  secondaryContent: '',
  loading: false,
};
