import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider, Fab, Grid, Stack, Card, CardMedia, Box, Skeleton, MenuItem, TextField } from '@mui/material';
import { Send, Clear, Close, Star } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import { FIELD_SIZES, FIELD_TYPES, LANGUAGES, SPORT_TYPES } from '../../utilities/utilities';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';
import { useIntl } from 'react-intl';
import { LocationIcon } from '../../img/icons/Icons';

export default function ViewFieldModal(props: { field_id: string; handleSelectField?: any; handleClose: any; open: any }) {
  const FIND_FIELD_BY_SHORT_ID_QUERY = gql`
    query findFieldByShortID($short_id: String!) {
      findFieldByShortID(short_id: $short_id) {
        id
        short_id
        name
        size
        type
        sports
        status
        pictures {
          path
        }
        descriptions {
          language
          content
        }
        equipments {
          id
          name
          pictures {
            path
          }
        }
        rental_asset {
          id
          price {
            value
            currency {
              currency_code
            }
          }
        }
        complex {
          id
          short_id
          name
          telephone
          email {
            address
          }
          cover {
            path
          }
          logo {
            path
          }
          location {
            id
            country_code
            street_name
            secondary_street_name
            city {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          owners {
            ... on ComplexOwner {
              __typename
              id
              username
            }
            ... on Company {
              __typename
              id
              name
              positions {
                permissions
                employees {
                  id
                  username
                }
              }
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [sportField, setSportField] = React.useState(UNDEFINED_AS_ANY);
  const { control, watch, setValue } = useForm({
    defaultValues: {
      description_language: '',
    },
  });
  const inputFields = watch();

  const loadedField = useQuery(FIND_FIELD_BY_SHORT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.field_id || !props.open,
    variables: {
      short_id: props.field_id,
    },
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSelectField = () => {
    props.handleSelectField();
    handleClose();
  };

  React.useEffect(() => {
    if (props.open && sportField === undefined && loadedField.called && loadedField.data?.findFieldByShortID) {
      setSportField(loadedField.data?.findFieldByShortID);
    }

    if (sportField?.descriptions !== undefined && sportField?.descriptions?.length > 0) {
      if (inputFields.description_language === '' && sportField?.descriptions[0]?.language !== undefined) setValue('description_language', sportField?.descriptions[0]?.language);
    }

    if (!props.open && sportField) {
      setSportField(UNDEFINED_AS_ANY);
    }
  }, [setValue, inputFields, sportField, loadedField, setSportField, props]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{sportField?.name}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent>
        {sportField && (
          <DialogContentText id="alert-dialog-description" component="span">
            <Grid
              container
              sx={{
                justifyContent: 'start',
                alignItems: 'center',
                p: '0 5%',
                color: 'rgba(247, 251, 250, 1)',
              }}
            >
              <Typography variant="caption" sx={{ width: '100%' }}>
                Sport:{' '}
                <strong>
                  {SPORT_TYPES.filter((t: any) => sportField?.sports?.find((s: any) => s === t?.value))?.map((s: any, index: number) => {
                    if (index === 0) {
                      return `${intl.formatMessage({ id: s?.label })}`;
                    } else {
                      return `, ${`${intl.formatMessage({ id: s?.label })}`}`;
                    }
                  })}
                </strong>
              </Typography>
              <Typography variant="caption" sx={{ width: '100%' }}>
                Type: <strong>{`${intl.formatMessage({ id: FIELD_TYPES?.find((t: any) => t?.value === sportField?.type)?.label })}`}</strong>
              </Typography>
              <Typography variant="caption" sx={{ width: '100%' }}>
                Size: <strong>{`${intl.formatMessage({ id: FIELD_SIZES?.find((s: any) => s?.value === sportField?.size)?.label })}`}</strong>
              </Typography>
              <Typography variant="caption" sx={{ width: '100%' }}>
                Price: <strong>{sportField?.rental_asset?.price?.value ? `${sportField?.rental_asset?.price?.currency?.currency_code} ${sportField?.rental_asset?.price?.value} / hour` : 'FREE'}</strong>
              </Typography>

              {sportField?.complex && (
                <Grid container>
                  <Grid
                    item
                    xl={6}
                    xs={6}
                    sm={6}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: '0 5%',
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ width: '100%' }} variant="caption">
                        Email: <strong>{sportField?.complex?.email?.address}</strong>
                      </Typography>
                      <Typography sx={{ width: '100%' }} variant="caption">
                        Telephone: <strong>{sportField?.complex?.telephone}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xl={6} xs={6} sm={6} className={'primary-info'} sx={{ position: 'relative', textAlign: 'center' }}>
                    <LocationIcon />
                    <Typography>{sportField?.complex?.location?.street_name}</Typography>
                    <Typography>{sportField?.complex?.location?.secondary_street_name}</Typography>
                    <Typography>
                      {sportField?.complex?.location?.postcode?.name} {sportField?.complex?.location?.county?.name ? `- ${sportField?.complex?.location?.county?.name}` : ''}
                    </Typography>
                    <Typography
                      className={'location'}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {sportField?.complex?.location?.city?.name}, {sportField?.complex?.location?.country?.name}&nbsp;
                      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${sportField?.complex?.location?.country_code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${sportField?.complex?.location?.country_code.toLowerCase()}.png 2x`} alt="" />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid
              sx={{
                bgcolor: 'rgb(15,15,15)',
                position: 'relative',
                p: '0 5%',
              }}
            >
              {sportField?.descriptions?.length > 0 ? (
                <Controller
                  control={control}
                  name="description_language"
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      value={field.value}
                      select
                      label="Translations"
                      placeholder="Select a language"
                      variant="outlined"
                      className="select-translation"
                      SelectProps={{
                        sx: {
                          color: 'rgba(247, 251, 250, 1)',
                          border: 'solid 1px rgba(247, 251, 250, .3)',
                          bgcolor: 'rgba(247, 251, 250, .1)',
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'rgba(247, 251, 250, 1)',
                          bgcolor: 'rgb(15,15,15)',
                          p: '0 6px',
                          borderRadius: '5px',
                        },
                      }}
                      sx={{
                        minWidth: '25%',
                        maxWidth: '50%',
                        m: '0 1%',
                      }}
                    >
                      {LANGUAGES.filter((l: any) => sportField?.descriptions?.map((d: any) => d?.language).includes(l.value)).map((option: any, index: number) => (
                        <MenuItem key={index} value={option.value}>
                          <img loading="lazy" width="20" src={option.value !== 'AU' ? `https://flagcdn.com/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png`} srcSet={option.value !== 'AU' ? `https://flagcdn.com/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                          &nbsp;
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ) : (
                ''
              )}

              {sportField?.descriptions?.find((d: any) => d.language === inputFields.description_language)?.content ? <Typography sx={{ textAlign: 'left', p: '30px 10%', color: 'rgba(247, 251, 250, 1)' }}>{sportField?.descriptions.find((d: any) => d.language === inputFields.description_language)?.content}</Typography> : ''}
            </Grid>

            <Typography
              sx={{
                width: '100%',
                fontSize: '1.1em',
                fontWeight: '700',
                position: 'relative',
                color: 'rgba(247, 251, 250, 1)',
                p: { xs: '40px 5% 10px', md: '40px 2% 10px' },
              }}
            >
              {_.upperCase('Equipments')}
            </Typography>
            <Grid
              container
              sx={{
                position: 'relative',
                overflow: 'auto',
                p: '0 5% 20px',
              }}
            >
              {sportField?.equipments?.length === 0 ? (
                <Typography
                  variant="h3"
                  sx={{
                    width: '100%',
                    p: '10px 20px',
                    fontSize: '.9em',
                    textAlign: 'center',
                    color: 'rgba(247, 251, 250, 1)',
                  }}
                >
                  No equipments
                </Typography>
              ) : (
                <Stack direction="row">
                  {sportField?.equipments?.map((facility: any, index: number) => (
                    <Card
                      key={index}
                      sx={{
                        bgcolor: 'rgba(247, 251, 250, .1)',
                        minWidth: 200,
                        m: '0 20px 0 0',
                        p: '20px 20px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {facility?.pictures ? (
                        <CardMedia
                          component="img"
                          src={facility?.pictures[0]?.path}
                          sx={{
                            height: '90px',
                            width: 'auto',
                          }}
                        />
                      ) : (
                        <Skeleton
                          width={150}
                          height={150}
                          sx={{
                            bgcolor: 'rgba(247, 251, 250, .3)',
                            m: 'auto',
                          }}
                        />
                      )}
                      <Grid
                        sx={{
                          p: '0 0 0 10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'rgba(247, 251, 250, 1)',
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >
                            {facility?.name}
                          </Typography>
                          <Fab
                            className={'rate'}
                            sx={{
                              'bgcolor': 'transparent',
                              'boxShadow': 'none',
                              ':hover': {
                                bgcolor: 'transparent',
                              },
                            }}
                          >
                            <Star
                              sx={{
                                color: 'rgba(245,178,42,1) !important',
                                position: 'absolute',
                                fontSize: '3.2em',
                              }}
                            />
                            <Box
                              component="span"
                              sx={{
                                position: 'relative',
                                fontWeight: '700',
                                fontSize: 'x-small',
                              }}
                            >
                              {facility?.rank || 0}
                            </Box>
                          </Fab>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </Stack>
              )}
            </Grid>

            <Grid
              container
              sx={{
                position: 'relative',
                overflow: 'auto',
                p: '0 5% 20px',
              }}
            >
              {sportField?.pictures?.length === 0 ? (
                <Typography
                  variant="h3"
                  sx={{
                    width: '100%',
                    p: '30px 20px',
                    fontSize: '1.3em',
                    fontWeight: '100',
                    textAlign: 'center',
                  }}
                >
                  No pictures
                </Typography>
              ) : (
                <Stack direction="row">
                  {sportField?.pictures?.map((pix: any, index: number) => (
                    <Card key={index} sx={{ width: 350, m: '0 20px 0 0' }}>
                      <CardMedia component="img" height="200" src={pix?.path} />
                    </Card>
                  ))}
                </Stack>
              )}
            </Grid>
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        {props?.handleSelectField ? (
          <LoadingButton onClick={handleSelectField} className={'button-green'} loadingPosition="end" endIcon={<Send />}>
            Select this field
          </LoadingButton>
        ) : (
          ''
        )}

        <Button onClick={handleClose} className={'button-cancel'} endIcon={<Clear />}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ViewFieldModal.propTypes = {
  field_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

ViewFieldModal.defaultProps = {};
