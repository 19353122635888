import React from 'react';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { SwipeableDrawer, Typography, Fab, Grid, TextField, ListItem, ListItemAvatar, Avatar, List, Divider, CircularProgress } from '@mui/material';
import { FiltersIcon } from '../../img/icons/Icons';
import { LoadingButton } from '@mui/lab';
import LoadingComponent from '../common/LoadingComponent';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

interface ISearchTeams {
  handleClose: any;
}

const SearchTeams: React.FC<ISearchTeams> = (props: ISearchTeams) => {
  try {
    const FIND_SPORT_TEAMS_BY_OPTIONS_QUERY = gql`
      query findSportTeamsByOptions($team: inputFindSportTeamsByOptions!) {
        findSportTeamsByOptions(team: $team) {
          ... on FootballTeam {
            id
            short_id
            name
            shortname
            type
            avatar {
              path
            }
            cover {
              path
            }
            availabilities {
              day
              timeslots
            }
            players {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                    avatar {
                      path
                    }
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                    avatar {
                      path
                    }
                  }
                }
              }
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
            received_notifications {
              id
              status
              sender {
                ... on Player {
                  __typename
                  id
                }
              }
            }
            originate_location {
              id
              title
              coordinates
              country_code
              street_name
              secondary_street_name
              status
              postcode {
                name
              }
              city {
                name
              }
              county {
                name
              }
              state {
                name
              }
              country {
                name
              }
              continent {
                name
              }
            }
            created_at
            updated_at
          }
        }
      }
    `;

    const session = useAppSelector(selectSession);
    const intl = useIntl();
    const [openFilters, setOpenFilters] = React.useState(false);
    const { control, watch } = useForm({
      defaultValues: {
        name: '',
        offset: 0,
      },
    });
    const inputFields = watch();

    const loadedSportTeam = useQuery(FIND_SPORT_TEAMS_BY_OPTIONS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key,
      variables: {
        team: {
          name: inputFields.name,
          offset: inputFields.offset,
        },
      },
    });

    const handleClose = () => {
      props.handleClose();
    };

    const handleOpenFilters = async () => {
      try {
        setOpenFilters(true);
      } catch (e) {
        console.log(e);
      }
    };

    return (
      <>
        <Grid container sx={{ justifyContent: 'center', mt: '20px' }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Controller name="name" control={control} rules={{ required: true }} render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'Global_Write_Team' })}`} variant="outlined" sx={{ width: { xs: '80%', md: '70%' }, mb: '40px' }} value={_.trimStart(field.value)} className={'field-bottom-space'} />} />
            <Fab onClick={handleOpenFilters} className="button-cancel" sx={{ boxShadow: 'none' }}>
              <FiltersIcon />
            </Fab>
          </Grid>

          <SwipeableDrawer anchor={'left'} open={openFilters} onClose={() => setOpenFilters(false)} onOpen={() => setOpenFilters(true)} sx={{ zIndex: '10000' }} PaperProps={{ sx: { width: '50%' } }}>
            <Grid sx={{ p: '20px', height: '100px' }}>
              <Typography sx={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>{`${intl.formatMessage({ id: 'Global_Filters_Title' })}`}</Typography>
            </Grid>
          </SwipeableDrawer>
        </Grid>
        <Grid container sx={{ mt: '20px' }}>
          {loadedSportTeam.loading ? (
            <LoadingComponent amount={0} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
          ) : (
            <>
              <Grid container sx={{ mt: '0px' }}>
                {loadedSportTeam.data?.findSportTeamsByOptions?.length === 0 ? <Typography sx={{ textAlign: 'center', display: 'inline-block', width: '100%', color: 'rgba(247, 251, 250, .3)' }}>{`${intl.formatMessage({ id: 'Global_No_Team_Found_Title' })}`}</Typography> : ''}
                <List sx={{ width: '100%' }}>
                  {loadedSportTeam.data?.findSportTeamsByOptions?.map((team: any, index: number) => (
                    <Grid key={index}>
                      <ListItem
                        alignItems="flex-start"
                        secondaryAction={
                          <LoadingButton component={Link} to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : undefined || team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''} className="button-green-reverse" sx={{ boxShadow: 'none', border: 'none !important', fontSize: '.7em' }}>
                            {`${intl.formatMessage({ id: 'Global_See_Team' })}`}
                          </LoadingButton>
                        }
                        sx={{
                          'display': { xs: 'inline-block', sm: 'flex' },
                          'p': { xs: '10px', sm: '' },
                          '& .MuiListItemSecondaryAction-root': {
                            position: { xs: 'relative', sm: 'absolute' },
                            top: { xs: '0', sm: '50%' },
                            right: { xs: '0', sm: '' },
                            textAlign: { xs: 'center', sm: '' },
                            transform: { xs: 'initial', sm: '' },
                          },
                        }}
                      >
                        <ListItemAvatar sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={handleClose}>
                          <Avatar alt={team?.name} src={team?.avatar?.path} component={Link} to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : undefined || team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''} sx={{ m: { xs: 'auto', sm: '' } }} />
                        </ListItemAvatar>
                        <Grid container sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                          <Grid item xs={12} md={12} lg={12} onClick={handleClose}>
                            <Typography component={Link} to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : undefined || team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''} sx={{ fontWeight: '700', textDecoration: 'none', color: 'rgba(247, 251, 250, 1)', display: 'inline-block', width: '100%' }}>
                              {team?.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: 'rgba(247, 251, 250, 1)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: { xs: 'center', sm: 'start' },
                              }}
                            >
                              {_.replace(team?.__typename, 'Team', ` Team`)}&nbsp;x&nbsp;
                              {team?.originate_location?.city?.name}, {team?.originate_location?.country?.name}
                              &nbsp;
                              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${team?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${team?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider variant="inset" sx={{ bgcolor: 'rgba(247, 251, 250, .1)', m: { xs: 'auto', sm: '0 0 0 72px' } }} />
                    </Grid>
                  ))}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default React.memo(SearchTeams);
