import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SpeedDial, SpeedDialAction, IconButton, Badge } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { ChatsIcon, MenuActionsIcon, NotificationIcon, RecommendationIcon, ScanQRCodeIcon, SearchIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import SearchEngineModal from '../contacts/SearchEngineModal';
import LatestNotificationsModal from '../common/LatestNotificationsModal';
import SendRecommendationModal from '../features/SendRecommendationModal';
import { gql, useQuery } from '@apollo/client';
import { selectPlayer } from '../../reducers/playerSlice';
import { Link } from 'react-router-dom';
import MakePaymentModal from '../purchase/MakePaymentModal';
import { selectSession } from '../../reducers/sessionSlice';

const TopBarActionsButton = (props: any) => {
  const FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY = gql`
    query findConversationByParticipantsIDs($conversations: inputFindConversationByParticipantsIDs!) {
      findConversationByParticipantsIDs(conversations: $conversations) {
        id
        short_id
        title
        messages {
          id
          status
          viewers {
            ... on Company {
              __typename
              id
            }
            ... on User {
              __typename
              id
            }
            ... on Player {
              __typename
              id
            }
            ... on ComplexOwner {
              __typename
              id
            }
            ... on Transporter {
              __typename
              id
            }
            ... on Passenger {
              __typename
              id
            }
            ... on Patient {
              __typename
              id
            }
            ... on HealthWorker {
              __typename
              id
            }
          }
        }
      }
    }
  `;

  const FIND_NOTIFICATIONS_QUERY = gql`
    query findNotificationsByReceiverID($receiver: inputFindNotificationsByReceiverID!) {
      findNotificationsByReceiverID(receiver: $receiver) {
        id
        title
        status
        icon
        content
        actions
        created_at
        received_at
        receivers {
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
        }
        sender {
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Company {
            __typename
            # id
            # name
            # label
            # logo {
            #   path
            # }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [open, setOpen] = React.useState(false);
  const [hidden] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const [openRecommendation, setOpenRecommendation] = React.useState(false);
  const [openScan, setOpenScan] = React.useState(false);

  const loadedConversations = useQuery(FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      conversations: {
        participants: _.concat(loggedPlayer?.id)?.filter((c: any) => c !== undefined || c !== null),
        offset: 0,
      },
    },
    pollInterval: !loggedPlayer?.id ? undefined : 300000,
  });
  const loadedNotifications = useQuery(FIND_NOTIFICATIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !loggedPlayer?.id,
    variables: {
      receiver: {
        id: loggedPlayer?.id,
        offset: 0,
      },
    },
    pollInterval: !loggedPlayer?.id ? undefined : 300000,
  });

  const handleSearch = () => {
    setOpenSearch(true);
  };

  const handleViewNotifications = () => {
    setOpenNotifications(true);
  };

  const handleRecommendation = () => {
    setOpenRecommendation(true);
  };

  const handleScan = () => {
    setOpenScan(true);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const ALL_PARTICIPANTS = _.concat(loggedPlayer);
  const ALL_UNREAD_MESSAGES = _.sum(loadedConversations.data?.findConversationByParticipantsIDs?.map((c: any) => c?.messages?.filter((m: any) => (m?.status === 'UNREAD' || m?.status === 'PARTIALLY_SEEN') && !m?.viewers?.find((u: any) => ALL_PARTICIPANTS?.find((p: any) => p?.id === u?.id)))?.length));
  const UNSEEN_NOTIFICATION = loadedNotifications.data?.findNotificationsByReceiverID.filter((n: any) => n?.status === 'UNSEEN');
  const ALL_UNSEEN_NOTIFICATIONS = _.sum(UNSEEN_NOTIFICATION?.length);
  const ALL_EVENTS = ALL_UNSEEN_NOTIFICATIONS + ALL_UNREAD_MESSAGES;

  const ALL_ACTIONS = [
    {
      icon: (
        <IconButton component={'div'} edge="start" sx={{ 'm': '0 2.5px', '&:hover': { bgcolor: 'transparent !important' } }} onClick={handleScan}>
          <ScanQRCodeIcon sx={{ 'color': `rgba(247, 251, 250, 1) !important`, '&:hover': { color: `${props?.colorHover} !important` } }} />
        </IconButton>
      ),
      name: 'Scan',
    },
    {
      icon: (
        <IconButton component={'div'} edge="start" sx={{ 'm': '0 2.5px', '&:hover': { bgcolor: 'transparent !important' } }} onClick={handleViewNotifications} aria-label={`show ${ALL_UNSEEN_NOTIFICATIONS} new notifications`}>
          <Badge badgeContent={ALL_UNSEEN_NOTIFICATIONS} max={9} color="error">
            <NotificationIcon sx={{ 'color': `rgba(247, 251, 250, 1) !important`, '&:hover': { color: `${props?.colorHover} !important` } }} />
          </Badge>
        </IconButton>
      ),
      name: 'Notifications',
    },
    {
      icon: (
        <IconButton component={'div'} edge="start" onClick={handleSearch} sx={{ 'm': '0 2.5px', '&:hover': { bgcolor: 'transparent !important' } }}>
          <SearchIcon sx={{ 'color': `rgba(247, 251, 250, 1) !important`, '&:hover': { color: `${props?.colorHover} !important` } }} />
        </IconButton>
      ),
      name: 'Search',
    },
    {
      icon: (
        <IconButton edge="start" sx={{ 'm': '0 2.5px', '&:hover': { bgcolor: 'transparent !important' } }} component={Link} to={'/conversations'}>
          <Badge badgeContent={ALL_UNREAD_MESSAGES} max={9} color="error">
            <ChatsIcon sx={{ 'color': `rgba(247, 251, 250, 1) !important`, '&:hover': { color: `${props?.colorHover} !important` } }} />
          </Badge>
        </IconButton>
      ),
      name: 'Conversations',
    },
    {
      icon: (
        <IconButton component={'div'} edge="start" sx={{ 'm': '0 2.5px', '&:hover': { bgcolor: 'transparent !important' } }} onClick={handleRecommendation}>
          <RecommendationIcon sx={{ 'color': `rgba(247, 251, 250, 1) !important`, '&:hover': { color: `${props?.colorHover} !important` } }} />
        </IconButton>
      ),
      name: 'Recommendation',
    },
  ];

  return (
    <>
      <Backdrop open={open} onClick={handleOpen} className="modal-feature" sx={{ height: '100vh', zIndex: '10000' }} />
      <SpeedDial
        ariaLabel="SpeedDial openIcon"
        direction="down"
        sx={{ height: 'auto', maxHeight: '35px', width: '35px', zIndex: '10000' }}
        FabProps={{ color: 'inherit', size: 'small', sx: { 'color': 'rgba(251, 88, 2, 1)', 'boxShadow': 'none', 'bgcolor': 'transparent !important', '&:hover': { bgcolor: open ? 'rgba(247, 251, 250, .3) !important' : 'rgba(247, 251, 250, .3) !important' } } }}
        hidden={hidden}
        icon={
          <Badge badgeContent={open ? 0 : ALL_EVENTS} color="error">
            <MenuActionsIcon />
          </Badge>
        }
        onClick={handleOpen}
        open={open}
      >
        {ALL_ACTIONS.map((menuItem: any) => (
          <SpeedDialAction
            key={menuItem.name}
            icon={menuItem.icon}
            FabProps={{
              sx: {
                'background': 'transparent',
                'boxShadow': 'none',
                '&:hover': {
                  'bgcolor': 'transparent !important',
                  '& svg:hover': {
                    color: 'rgba(251, 88, 2, 1) !important',
                  },
                },
                '&.MuiSpeedDialAction-fab span': {
                  p: '0',
                },
              },
            }}
          />
        ))}
      </SpeedDial>

      <MakePaymentModal open={openScan} handleClose={() => setOpenScan(false)} />
      <SearchEngineModal open={openSearch} handleClose={() => setOpenSearch(false)} />
      <LatestNotificationsModal open={openNotifications} handleClose={() => setOpenNotifications(false)} />
      <SendRecommendationModal open={openRecommendation} handleClose={() => setOpenRecommendation(false)} />
    </>
  );
};

export default TopBarActionsButton;

TopBarActionsButton.propTypes = {
  children: PropTypes.node,
  menuActions: PropTypes.arrayOf(Object).isRequired,
};

TopBarActionsButton.defaultProps = {
  menuActions: [{}],
};
