import React from 'react';
import Moment from 'react-moment';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';
import { Typography, Badge, ListItemSecondaryAction, ListItemAvatar, ListItemText, ListItem, List, Avatar, TextField, Grid, Card, AvatarGroup, Autocomplete } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { Controller, useForm } from 'react-hook-form';
import { Search } from '@mui/icons-material';
import { selectPlayer } from '../../reducers/playerSlice';
import { useIntl } from 'react-intl';

interface IListConversationsMessenger {
  handleOpenConversation: any;
  handleViewParticipant: any;
}

const ListConversationsMessenger: React.FC<IListConversationsMessenger> = (props: IListConversationsMessenger) => {
  const FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY = gql`
    query findConversationByParticipantsIDs($conversations: inputFindConversationByParticipantsIDs!) {
      findConversationByParticipantsIDs(conversations: $conversations) {
        id
        short_id
        title
        avatar {
          path
        }
        description
        created_at
        edited_at
        participants {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        messages {
          id
          status
          content
          created_at
          viewers {
            ... on Company {
              __typename
              id
              name
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
        }
      }
    }
  `;

  const FIND_CONVERSATION_PARTICIPANTS_BY_NAME_QUERY = gql`
    query findConversationParticipantsByName($name: String!) {
      findConversationParticipantsByName(name: $name) {
        ... on Company {
          id
          name
          label
          logo {
            path
          }
        }
        ... on User {
          id
          username
          avatar {
            path
          }
        }
        ... on Player {
          id
          username
          avatar {
            path
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const loggedPlayer = useAppSelector(selectPlayer);
  const [conversations, setConversations] = React.useState(UNDEFINED_AS_ANY);
  const [searchInput, setSearchInput] = React.useState('');
  const [offset] = React.useState(0);
  const [openSearchSportPlayer, setOpenSearchSportPlayer] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      all_participants: ARRAY_AS_ANY,
      participant_name: '',
    },
  });
  const inputFields = watch();

  const loadedConversations = useQuery(FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || _.concat(loggedPlayer?.id)?.filter((c: any) => c)?.length === 0,
    variables: {
      conversations: {
        participants: _.concat(loggedPlayer?.id)?.filter((c: any) => c),
        offset: offset,
      },
    },
    pollInterval: !loggedPlayer?.id ? undefined : 15000,
  });

  const loadedParticipants = useQuery(FIND_CONVERSATION_PARTICIPANTS_BY_NAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || inputFields.participant_name === '',
    variables: {
      name: inputFields.participant_name,
    },
  });

  const ALL_PARTICIPANTS = loadedParticipants.data?.findConversationParticipantsByName?.filter((p: any) => p?.__typename === 'Player');

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleViewParticipant = (id: string, type: string) => {
    props.handleViewParticipant(id, type);
  };

  React.useEffect(() => {
    if ((!conversations && loadedConversations.data?.findConversationByParticipantsIDs) || (conversations && conversations?.length !== loadedConversations.data?.findConversationByParticipantsIDs?.length)) {
      setConversations(loadedConversations.data?.findConversationByParticipantsIDs);
    }
  }, [loadedConversations, conversations, setConversations]);

  return (
    <Grid container className={'component-contacts list-conversations-page'} sx={{ minHeight: '100%', display: 'block' }}>
      <Grid item xl={12} xs={12} sm={12}>
        <Grid container id="max-width" sx={{ p: { xs: '20px 10% 0', lg: '20px 0 0' } }}>
          <Controller
            name="all_participants"
            control={control}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                open={openSearchSportPlayer}
                onOpen={() => setOpenSearchSportPlayer(true)}
                onClose={() => setOpenSearchSportPlayer(false)}
                multiple
                autoHighlight
                filterSelectedOptions
                value={field.value}
                loading={loadedParticipants.loading}
                inputValue={inputFields.participant_name}
                onInputChange={(event: any, newInputValue: any) => {
                  setValue('participant_name', newInputValue);
                }}
                options={_.concat(ALL_PARTICIPANTS)?.filter((i: any) => i)}
                getOptionLabel={(option: any) => option?.__typename}
                defaultValue={ARRAY_AS_ANY}
                renderOption={(props: any, option: any) => (
                  <Grid
                    container
                    key={option?.id}
                    onClick={() => handleViewParticipant(option?.id, option?.__typename)}
                    sx={{
                      alignItems: 'center',
                      p: '10px 15px',
                    }}
                  >
                    <Avatar src={(option?.avatar || option?.logo)?.path} />
                    &nbsp;&nbsp;
                    <strong>{option?.label || option?.name || option?.username}</strong>
                  </Grid>
                )}
                sx={{ width: '100%' }}
                renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'ListConversationsMessenger_All_Contacts_Label' })}`} placeholder={`${intl.formatMessage({ id: 'ListConversationsMessenger_All_Contacts_Placeholder' })}`} />}
              />
            )}
          />
        </Grid>

        <Grid id="max-width" container sx={{ p: { xs: '0 10%', lg: '0' } }}>
          {conversations?.length > 1 ? (
            <TextField
              placeholder={`${intl.formatMessage({ id: 'ListConversationsMessenger_Search_Conversations_Placeholder' })}`}
              fullWidth
              value={searchInput}
              onChange={handleSearchQuery}
              InputProps={{
                startAdornment: <Search sx={{ opacity: '.4' }} />,
                sx: {
                  color: 'rgba(255,255,255, 1)',
                },
              }}
              InputLabelProps={{
                sx: {
                  color: 'rgba(255,255,255, 1)',
                  p: '0 6px',
                  borderRadius: '5px',
                },
              }}
            />
          ) : (
            ''
          )}

          {conversations?.length === 0 || conversations?.length === undefined ? (
            <Grid sx={{ textAlign: 'center', width: '100%' }}>
              <Typography sx={{ textAlign: 'center', fontWeight: '100', fontSize: '1.3em', width: '100%', opacity: '.4' }}>{`${intl.formatMessage({ id: 'ListConversationsMessenger_No_Conversations' })}`}</Typography>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Grid id="max-width" container sx={{ p: { xs: '20px 10%', lg: '20px 0 0' } }}>
          <List sx={{ width: '100%' }}>
            {conversations
              ?.filter((convo: any) => convo?.title?.includes(searchInput))
              .map((convo: any, index: any) => (
                <Card
                  key={index}
                  onClick={() => props.handleOpenConversation(convo?.short_id)}
                  sx={{
                    'display': 'inline-block',

                    'p': '0px',
                    'width': '100%',
                    'borderRadius': '5px',
                    'm': '0 auto 30px',
                    'textDecoration': 'none',
                    'boxShadow': 'none',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <ListItem alignItems="flex-start" sx={{ bgcolor: 'rgba(15, 15, 15, .4) !important' }}>
                    <ListItemAvatar>
                      <Badge className={'message-unread'} color="error" max={9} badgeContent={convo?.messages?.filter((m: any) => (m?.status === 'UNREAD' || m?.status === 'PARTIALLY_SEEN') && !m?.viewers?.find((u: any) => ALL_PARTICIPANTS?.find((p: any) => p?.id === u?.id)))?.length}>
                        <Avatar alt={`${convo?.title}`} src={`${convo?.avatar?.path}`} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <Grid container component="span">
                          <AvatarGroup max={5} total={convo?.participants?.length} spacing="small" component="span">
                            {convo?.participants?.map((p: any) => (
                              <Avatar key={p?.id} component="span" src={p?.avatar?.path || p?.logo?.path} alt={p?.avatar?.username || p?.logo?.name} />
                            ))}
                          </AvatarGroup>
                        </Grid>
                      }
                      primary={
                        <>
                          {convo?.title ? (
                            <>
                              <strong>{convo?.title}</strong>
                            </>
                          ) : (
                            ''
                          )}
                          {convo?.messages?.length > 0 ? `: ${_.truncate(convo?.messages[0]?.content, { length: 30 })}` : ''}
                        </>
                      }
                      primaryTypographyProps={{ color: 'rgba(255, 255, 255, 1)', fontWeight: '100', pb: '10px' }}
                    />
                    <ListItemSecondaryAction sx={{ color: 'rgba(255, 255, 255, 1)' }}>
                      <Typography variant="caption">
                        <Moment fromNow>{convo?.messages[0]?.created_at || convo?.edited_at || convo?.created_at}</Moment>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Card>
              ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ListConversationsMessenger);
