import React from 'react';
import _ from 'lodash';
import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material';
import { selectPlayer } from '../../reducers/playerSlice';
import { useAppSelector } from '../../redux/hooks';

export default function LoadingComponent(props: { text?: any; loader?: any; dark_mode?: boolean; amount?: number }) {
  const loggedUser = useAppSelector(selectPlayer);
  const bgcolor = (props.dark_mode !== undefined ? props.dark_mode : loggedUser?.app_configuration?.is_dark_mode) ? 'rgba(251, 88, 2, .1)' : 'rgba(15, 15, 15, .1)';
  const animation = 'pulse';
  const borderRadius = '10px';

  return (
    <>
      <Grid id="max-width" sx={{ width: '100%', m: '30px 0' }}>
        <Grid sx={{ 'textAlign': 'center', '& p': { color: 'rgba(251, 88, 2, 1) !important' } }}>
          {props?.loader}
          <Grid sx={{ opacity: '.4' }}>{props?.text}</Grid>
        </Grid>
        {_.times(props?.amount || 0)?.map((t: any, index: number) => (
          <Card key={index} sx={{ bgcolor: 'transparent', boxShadow: 'none', m: '20px 10%' }}>
            <CardHeader avatar={<Skeleton animation={animation} variant="circular" width={40} height={40} style={{ backgroundColor: bgcolor }} />} title={<Skeleton animation={animation} height={10} width="80%" style={{ backgroundColor: bgcolor }} />} subheader={<Skeleton animation={animation} height={10} width="40%" style={{ backgroundColor: bgcolor }} />} />
            <Skeleton sx={{ height: 190 }} animation={animation} variant="rectangular" style={{ backgroundColor: bgcolor, borderRadius: borderRadius }} />
            <CardContent>
              <>
                <Skeleton animation={animation} height={10} style={{ backgroundColor: bgcolor }} />
                <Skeleton animation={animation} height={10} width="80%" style={{ backgroundColor: bgcolor }} />
              </>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </>
  );
}
