import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton(props: { color: any; hoverColor?: string; hoverBGColor?: string }) {
  const navigate = useNavigate();

  const handleNavBack = () => {
    navigate(-1);
  };

  return (
    <IconButton onClick={handleNavBack} sx={{ 'color': props.color, '&:hover': { color: props.hoverColor, bgcolor: props.hoverBGColor } }} className={'button-back'}>
      <ArrowBackIcon />
    </IconButton>
  );
}

BackButton.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverBGColor: PropTypes.string,
};
