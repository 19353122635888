import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

interface INumberFormatter {
  number: number;
}

const NumberFormatter: React.FC<INumberFormatter> = (props: INumberFormatter) => {
  const formatNumber = (num: number) => {
    if (!_.isNumber(num)) {
      return 0;
    }

    if (num < 1000) {
      return num.toString();
    } else if (num < 1000000) {
      const formatted = (num / 1000).toFixed(1);
      return formatted.endsWith('.0') ? `${formatted.slice(0, -2)}K` : `${formatted}K`;
    } else if (num < 1000000000) {
      const formatted = (num / 1000000).toFixed(1);
      return formatted.endsWith('.0') ? `${formatted.slice(0, -2)}M` : `${formatted}M`;
    } else {
      const formatted = (num / 1000000000).toFixed(1);
      return formatted.endsWith('.0') ? `${formatted.slice(0, -2)}B` : `${formatted}B`;
    }
  };

  return <span>{formatNumber(props.number)}</span>;
};

NumberFormatter.propTypes = {
  number: PropTypes.number.isRequired,
};

NumberFormatter.defaultProps = {
  number: 0,
};

export default NumberFormatter;
