import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, AvatarGroup, Badge, Avatar } from '@mui/material';
import { Close, Edit, Send } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { CaptainIcon } from '../../img/icons/Icons';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import EditTeamLineupModal from './EditTeamLineupModal';
import { selectSession } from '../../reducers/sessionSlice';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

export default function ViewTeamLineupModal(props: { team_lineup_id: string; challenge_id?: string; handleClose: any; open: any }) {
  const FIND_TEAM_LINEUP_BY_ID_QUERY = gql`
    query findTeamLineupByID($id: ID!) {
      findTeamLineupByID(id: $id) {
        id
        name
        players {
          id
          status
          team_number
          team_player {
            ... on FootballTeam {
              __typename
              id
              name
            }
          }
          player {
            ... on Footballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
          }
        }
        captains {
          id
          status
          team_number
          player {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
        }
        team {
          ... on FootballTeam {
            __typename
            id
            short_id
            status
            name
            shortname
            type
            avatar {
              path
            }
            cover {
              path
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
        home_challenges {
          id
          short_id
          title
          status
        }
        away_challenges {
          id
          short_id
          title
          status
        }
        received_notifications {
          id
          status
          created_at
          sender {
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
        }
      }
    }
  `;

  const SEND_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [teamLineup, setTeamLineup] = React.useState(UNDEFINED_AS_ANY);
  const [openEditTeamLineup, setOpenEditTeamLineup] = React.useState(false);
  const [sendNotification, isSent] = useMutation(SEND_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTeamLineup = useQuery(FIND_TEAM_LINEUP_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.team_lineup_id,
    },
  });

  const ALL_CHALLENGES = _.concat(teamLineup?.home_challenges, teamLineup?.away_challenges)?.filter((c: any) => c?.id);
  const SELECTED_CHALLENGE = ALL_CHALLENGES?.find((c: any) => c?.id === props?.challenge_id);
  const SELECTED_PROFILE = loggedPlayer?.profiles?.find((p: any) => (teamLineup?.team?.__typename === 'FootballTeam' ? p?.__typename === 'Footballer' : teamLineup?.team?.__typename === 'BasketballTeam' ? p?.__typename === 'Basketballer' : undefined));

  const handleClose = () => {
    props.handleClose();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedTeamLineup.refetch({
        id: props.team_lineup_id,
      });

      if (result) {
        setTeamLineup(result.data?.findTeamLineupByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendRequestJoinTeam = async () => {
    try {
      if (SELECTED_CHALLENGE?.status === 'ACCEPTED') {
        teamLineup?.team?.captains?.map(async (c: any) => {
          try {
            await sendNotification({
              variables: {
                notification: {
                  title: `${loggedPlayer?.username} ${intl.formatMessage({ id: 'Notification_View_Team_Lineup_Title' })}`,
                  content: `${loggedPlayer?.username} ${intl.formatMessage({ id: 'Notification_View_Team_Lineup_Content_1' })} ${SELECTED_CHALLENGE?.title}.`,
                  actions: [`VIEW_CHALLENGE_@_${SELECTED_CHALLENGE?.short_id}`, `ACCEPT_NEW_TEAM_LINEUP_PLAYER_@_${SELECTED_PROFILE?.id}_&_${teamLineup?.id}`, `DECLINE_NEW_TEAM_LINEUP_PLAYER_@_${SELECTED_PROFILE?.id}_&_${teamLineup?.id}`],
                  sender_id: loggedPlayer?.id,
                  receivers_ids: [c?.player?.player?.id, teamLineup?.id],
                },
              },
            });
          } catch (e) {
            console.log(e);
          }
        });
      }

      // handleClose()
    } catch (e) {
      // handleClose()
      console.log(e);
    }
  };

  const handleOpenEditTeamLineup = () => {
    setOpenEditTeamLineup(true);
  };

  const handleCloseEditTeamLineup = async () => {
    try {
      await handleRefetch();
      setOpenEditTeamLineup(false);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && teamLineup === undefined && loadedTeamLineup.called && loadedTeamLineup.data?.findTeamLineupByID) {
      setTeamLineup(loadedTeamLineup.data?.findTeamLineupByID);
    }

    if (!props.open && teamLineup) {
      setTeamLineup(UNDEFINED_AS_ANY);
    }
  }, [teamLineup, loadedTeamLineup, setTeamLineup, props]);

  return (
    <Dialog
      open={props.open}
      onClose={isSent.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={handleClose}
            disabled={isSent.loading}
            className={isSent.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          {teamLineup?.team?.captains?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) && teamLineup?.team?.status === 'ACTIVE' ? (
            <Fab
              size="small"
              variant="extended"
              onClick={handleOpenEditTeamLineup}
              disabled={isSent.loading}
              className={isSent.loading ? 'button-disabled' : 'button-green-reverse'}
              sx={{
                boxShadow: 'none',
                bgcolor: 'transparent',
                mr: '2%',
              }}
            >
              <Edit />
              &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Edit' })}`}
            </Fab>
          ) : (
            ''
          )}

          {teamLineup?.team?.short_id && teamLineup?.team?.status === 'ACTIVE' ? (
            <Fab
              size="small"
              variant="extended"
              component={Link}
              to={`/football_team/${teamLineup?.team?.short_id}`}
              disabled={!loggedPlayer?.id}
              className={!loggedPlayer?.id ? 'button-disabled' : 'button-cancel'}
              sx={{
                boxShadow: 'none',
                bgcolor: 'transparent',
                ml: '2%',
              }}
            >
              {`${intl.formatMessage({ id: 'Global_Button_View' })}`}&nbsp;{_.replace(teamLineup?.team?.__typename, 'Team', ' Team')}
            </Fab>
          ) : (
            ''
          )}
        </Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{teamLineup?.name}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid
            container
            sx={{
              justifyContent: 'start',
              alignItems: 'center',
              p: '20px',
              color: 'rgba(247, 251, 250, 1)',
              backgroundImage: `url(/img/${teamLineup?.team?.__typename === 'FootballTeam' ? 'football' : teamLineup?.team?.__typename === 'BasketballTeam' ? 'basketball' : undefined}-field.png)`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              minHeight: '340px',
            }}
          >
            <Grid container className="strikers" sx={{ justifyContent: 'center', p: '15px 0' }}>
              <AvatarGroup spacing={-20}>
                {teamLineup?.players
                  ?.filter((p: any) => p?.player?.position === 'ST')
                  ?.map((p: any, index: number) => (
                    <Badge key={index} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={teamLineup?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : undefined} sx={{ display: 'block' }}>
                      <Avatar alt={p?.player?.alias} src={p?.player?.avatar?.path} sx={{ m: 'auto !important' }} />
                      <Typography variant="caption">{_.truncate(p?.player?.alias, { length: 20 })}</Typography>
                    </Badge>
                  ))}
              </AvatarGroup>
            </Grid>
            <Grid container className="midfielders" sx={{ justifyContent: 'center', p: '15px 0' }}>
              <AvatarGroup spacing={-20}>
                {teamLineup?.players
                  ?.filter((p: any) => p?.status === 'ACTIVE')
                  ?.filter((p: any) => p?.player?.position === 'MF')
                  ?.map((p: any, index: number) => (
                    <Badge key={index} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={teamLineup?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : undefined} sx={{ display: 'block' }}>
                      <Avatar alt={p?.player?.alias} src={p?.player?.avatar?.path} sx={{ m: 'auto !important' }} />
                      <Typography variant="caption">{_.truncate(p?.player?.alias, { length: 20 })}</Typography>
                    </Badge>
                  ))}
              </AvatarGroup>
            </Grid>
            <Grid container className="defenders" sx={{ justifyContent: 'center', p: '15px 0' }}>
              <AvatarGroup spacing={-20}>
                {teamLineup?.players
                  ?.filter((p: any) => p?.status === 'ACTIVE')
                  ?.filter((p: any) => p?.player?.position === 'DF')
                  ?.map((p: any, index: number) => (
                    <Badge key={index} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={teamLineup?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : undefined} sx={{ display: 'block' }}>
                      <Avatar alt={p?.player?.alias} src={p?.player?.avatar?.path} sx={{ m: 'auto !important' }} />
                      <Typography variant="caption">{_.truncate(p?.player?.alias, { length: 20 })}</Typography>
                    </Badge>
                  ))}
              </AvatarGroup>
            </Grid>
            <Grid container className="keeper" sx={{ justifyContent: 'center', p: '15px 0' }}>
              <AvatarGroup spacing={-20}>
                {teamLineup?.players
                  ?.filter((p: any) => p?.status === 'ACTIVE')
                  ?.filter((p: any) => p?.player?.position === 'GK')
                  ?.map((p: any, index: number) => (
                    <Badge key={index} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={teamLineup?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : undefined} sx={{ display: 'block' }}>
                      <Avatar alt={p?.player?.alias} src={p?.player?.avatar?.path} sx={{ m: 'auto !important' }} />
                      <Typography variant="caption">{_.truncate(p?.player?.alias, { length: 20 })}</Typography>
                    </Badge>
                  ))}
              </AvatarGroup>
            </Grid>
          </Grid>

          <EditTeamLineupModal open={openEditTeamLineup} handleClose={handleCloseEditTeamLineup} team_lineup_id={teamLineup?.id} />
        </DialogContentText>
        <DialogActions sx={{ p: '20px 0 0' }}>
          {SELECTED_CHALLENGE?.status === 'ACCEPTED' && SELECTED_PROFILE?.id && !teamLineup?.players?.find((p: any) => p?.player?.id === SELECTED_PROFILE?.id) ? (
            <>
              {isSent.data?.sendNotification ? (
                <Typography className="SUCCESSFUL" sx={{ textAlign: 'center', width: '100%', fontWeight: '700' }}>
                  {`${intl.formatMessage({ id: 'ViewTeamLineupModal_Request_Sent' })}`} 🎉
                </Typography>
              ) : teamLineup?.received_notifications?.find((n: any) => n?.sender?.id === loggedPlayer?.id)?.status === 'HANDLED' ? (
                <Typography className="SUCCESSFUL" sx={{ textAlign: 'center', width: '100%', fontWeight: '700' }}>
                  {`${intl.formatMessage({ id: 'ViewTeamLineupModal_Request_Already_Sent' })}`} 🎉
                </Typography>
              ) : (
                <LoadingButton loading={isSent.loading} loadingPosition="end" endIcon={<Send />} className={'button-green'} fullWidth onClick={handleSendRequestJoinTeam}>
                  {isSent.loading ? `${intl.formatMessage({ id: 'ViewTeamLineupModal_Button_Sending_Request' })}` : `${intl.formatMessage({ id: 'ViewTeamLineupModal_Button_Join_Team' })}`}
                </LoadingButton>
              )}
            </>
          ) : (
            ''
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

ViewTeamLineupModal.propTypes = {
  team_lineup_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

ViewTeamLineupModal.defaultProps = {};
