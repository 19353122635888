import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider, Fab, Grid, Chip, Avatar, Autocomplete, TextField } from '@mui/material';
import { Send, Clear, Close } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';
import { useIntl } from 'react-intl';

export default function AddNewTeamPlayerModal(props: { open: any; handleClose: any; team_id: string; loading?: boolean }) {
  const ADD_SPORT_PLAYERS_TO_SPORT_TEAM_MUTATION = gql`
    mutation addSportPlayersToSportTeam($team: inputAddSportPlayersToSportTeam!) {
      addSportPlayersToSportTeam(team: $team)
    }
  `;

  const FIND_SPORT_TEAM_BY_TEAM_ID_QUERY = gql`
    query findSportTeamByTeamID($team: inputFindSportTeamByTeamID!) {
      findSportTeamByTeamID(team: $team) {
        ... on FootballTeam {
          id
          type
          players {
            id
            player {
              ... on Footballer {
                id
                alias
              }
            }
          }
        }
      }
    }
  `;

  const FIND_SPORT_PLAYERS_BY_ALIAS_QUERY = gql`
    query findSportPlayersByAlias($player: inputFindSportPlayersByAlias!) {
      findSportPlayersByAlias(player: $player) {
        ... on Footballer {
          id
          alias
          avatar {
            path
          }
          player {
            id
            username
          }
          player_positions {
            id
            team_number
            status
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
            team_captain {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
          }
        }
        ... on Basketballer {
          id
          alias
          avatar {
            path
          }
          player {
            id
            username
          }
          player_positions {
            id
            team_number
            status
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
            team_captain {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [team, setTeam] = React.useState(UNDEFINED_AS_ANY);
  const [openSearchSportPlayer, setOpenSearchSportPlayer] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      all_sport_players: ARRAY_AS_ANY,
      sport_players: ARRAY_AS_ANY,
      player_alias: '',
    },
  });
  const inputFields = watch();

  const [addSportPlayersToSportTeam, newTeamPlayers] = useMutation(ADD_SPORT_PLAYERS_TO_SPORT_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportTeam = useQuery(FIND_SPORT_TEAM_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team: {
        id: props.team_id,
      },
    },
  });
  const loadedSportPlayers = useQuery(FIND_SPORT_PLAYERS_BY_ALIAS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      player: {
        alias: inputFields.player_alias,
        team_sport: team?.__typename,
        team_id: team?.id,
        team_type: team?.type,
      },
    },
  });

  const ALL_SPORT_PLAYERS = loadedSportPlayers.data?.findSportPlayersByAlias?.filter((p: any) => !p?.player_positions?.find((po: any) => po?.team_player?.id === team?.id))?.filter((p: any) => (team?.type === 'LEAGUE' ? !p?.player_positions?.find((po: any) => po?.status === 'ACTIVE' && po?.team_player?.type === 'LEAGUE' && 'LEAGUE' === team?.type) : true));

  const handleSportPlayers = (value: any) => {
    const newList = _.concat(inputFields.sport_players, value);
    setValue('sport_players', newList);
    setOpenSearchSportPlayer(false);
  };

  const handleRemoveSportPlayer = (player_id: string) => {
    const newList = inputFields.sport_players?.filter((p: any) => p?.id !== player_id);
    setValue('sport_players', newList);
  };

  const handleAddNewTeamPlayer = async () => {
    try {
      await addSportPlayersToSportTeam({
        variables: {
          team: {
            new_sport_players_ids: inputFields.sport_players?.map((p: any) => p?.id)?.filter((o: any) => o),
            id: team?.id,
            team_sport: team?.__typename,
          },
        },
      });

      props.handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && !team && loadedSportTeam.data) {
      setTeam(loadedSportTeam.data?.findSportTeamByTeamID);
    }

    if (!props.open && team) {
      setTeam(UNDEFINED_AS_ANY);
      setValue('sport_players', ARRAY_AS_ANY);
    }
  }, [props, team, loadedSportTeam, setValue]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={props.handleClose}
        disabled={newTeamPlayers.loading}
        className={newTeamPlayers.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'AddTeamPlayerModal_Title' })}`}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid sx={{ p: '20px 0 10px', textAlign: 'center' }}>
            {inputFields.sport_players?.length === 0 ? (
              <Typography
                variant="caption"
                sx={{
                  m: '0px',
                  p: '5px 20px',
                  textAlign: 'center',
                  bgcolor: 'rgba(247, 251, 250, .04)',
                  color: 'rgba(247, 251, 250, 1)',
                  borderRadius: '5px',
                }}
              >
                {`${intl.formatMessage({ id: 'AddTeamPlayerModal_No_Player_Selected' })}`}
              </Typography>
            ) : (
              inputFields.sport_players?.map((p: any) => (
                <Chip
                  key={p?.id}
                  variant="outlined"
                  label={<strong>{p?.alias}</strong>}
                  avatar={
                    <Avatar
                      sx={{
                        height: {
                          xs: '50px !important',
                          md: '60px !important',
                          lg: '70px !important',
                        },
                        width: {
                          xs: '50px !important',
                          md: '60px !important',
                          lg: '70px !important',
                        },
                        m: '5px 0',
                      }}
                      alt={p?.alias}
                      src={p?.avatar?.path}
                    />
                  }
                  onDelete={() => handleRemoveSportPlayer(p?.id)}
                  sx={{ m: '5px', height: 'auto', color: 'rgba(247, 251, 250, 1)' }}
                />
              ))
            )}
          </Grid>

          <Controller
            name="all_sport_players"
            control={control}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                open={openSearchSportPlayer}
                onOpen={() => setOpenSearchSportPlayer(true)}
                onClose={() => setOpenSearchSportPlayer(false)}
                multiple
                autoHighlight
                filterSelectedOptions
                value={field.value}
                loading={loadedSportPlayers.loading}
                inputValue={inputFields.player_alias}
                onInputChange={(event: any, newInputValue: any) => {
                  setValue('player_alias', newInputValue);
                }}
                options={(ALL_SPORT_PLAYERS ? ALL_SPORT_PLAYERS : [])?.filter(
                  (cf: any) =>
                    _.concat(
                      team?.players?.map((p: any) => p?.player),
                      inputFields.sport_players
                    )?.find((p: any) => p?.id === cf?.id) === undefined
                )}
                getOptionLabel={(option: any) => option?.id}
                defaultValue={ARRAY_AS_ANY}
                renderOption={(props: any, option: any) => (
                  <Grid
                    container
                    key={option?.id}
                    onClick={() => handleSportPlayers(option)}
                    sx={{
                      alignItems: 'center',
                      p: '10px 15px',
                    }}
                  >
                    <Avatar src={option?.avatar?.path} />
                    &nbsp;&nbsp;
                    <strong>{option?.alias}</strong>
                  </Grid>
                )}
                renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'AddTeamPlayerModal_Players_Label' })}`} placeholder={`${intl.formatMessage({ id: 'AddTeamPlayerModal_Players_Placeholder' })}`} />}
              />
            )}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton onClick={handleAddNewTeamPlayer} className={inputFields.sport_players?.length === 0 ? 'button-disabled' : 'button-green'} disabled={inputFields.sport_players?.length === 0} loadingPosition="end" endIcon={<Send />} loading={newTeamPlayers.loading || props.loading}>
          {`${intl.formatMessage({ id: 'AddTeamPlayerModal_Button_Create' })}`}
        </LoadingButton>
        <Button onClick={props.handleClose} disabled={newTeamPlayers.loading} className={newTeamPlayers.loading ? 'button-disabled' : 'button-cancel'} endIcon={<Clear />}>
          {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddNewTeamPlayerModal.propTypes = {
  team_id: PropTypes.any,
  open: PropTypes.any,
  handleClose: PropTypes.any,
  loading: PropTypes.bool,
};

AddNewTeamPlayerModal.defaultProps = {
  loading: false,
};
