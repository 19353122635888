import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { Button, Avatar, Fab, TextField, Grid, Typography, Stack, Chip, Autocomplete } from '@mui/material';
import { Cached, Clear, Delete, PhotoCamera, Remove, Send } from '@mui/icons-material';
import { LogoLilenekIcon } from '../../img/icons/Icons';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import BackButton from '../../component-modals/common/BackButton';
import { LoadingButton } from '@mui/lab';
import { dataURLtoBlobURL, handleUpload } from '../../helpers';
import { selectSession } from '../../reducers/sessionSlice';
import { selectPlayer } from '../../reducers/playerSlice';

interface IConversation {
  conversation: any;
}

const EditConversation: React.FC = () => {
  const FIND_CONVERSATION_TO_EDIT_BY_ID_QUERY = gql`
    query findConversationToEditByID($conversation: inputFindConversationToEditByID!) {
      findConversationToEditByID(conversation: $conversation) {
        id
        short_id
        title
        avatar {
          id
          filename
          type
          size
          path
        }
        description
        author {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        participants {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
      }
    }
  `;

  const EDIT_CONVERSATION_MUTATION = gql`
    mutation editConversation($conversation: inputEditConversation!) {
      editConversation(conversation: $conversation) {
        id
      }
    }
  `;

  const DELETE_CONVERSATION_MUTATION = gql`
    mutation deleteConversation($conversation: inputDeleteConversation!) {
      deleteConversation(conversation: $conversation)
    }
  `;

  const navigate = useNavigate();
  const location = useLocation();
  const state: IConversation = location?.state;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [conversation, setConversation] = React.useState(UNDEFINED_AS_ANY);

  const [openDelete, setOpenDelete] = React.useState(false);
  const [openSearchParticipant, setOpenSearchParticipant] = React.useState(false);
  const [avatar, setAvatar] = React.useState<ImageListType>([]);
  const [avatarUrl, setAvatarUrl] = React.useState([]);

  const [deleteConversation, deletedConversation] = useMutation(DELETE_CONVERSATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [editConversation, updatedConversation] = useMutation(EDIT_CONVERSATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedConversation = useQuery(FIND_CONVERSATION_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      conversation: {
        id: state?.conversation?.id,
        participants: _.concat(loggedPlayer?.id),
      },
    },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      description: UNDEFINED_AS_ANY,
      participant_name: '',
      selected_participants: UNDEFINED_AS_ANY,
      all_participants: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();

  const ALL_AUTHORS = _.concat(loggedPlayer);
  const ALL_PARTICIPANTS = loggedPlayer?.id === conversation?.author?.id ? ALL_AUTHORS?.find((a: any) => a?.id === conversation?.author?.id)?.followings?.filter((f: any) => ALL_AUTHORS?.find((a: any) => a?.id === conversation?.author?.id)?.followers?.find((fl: any) => fl?.id === f?.id)) : ALL_AUTHORS?.find((a: any) => a?.id === conversation?.author?.id)?.connections;
  const FULL_LIST = _.concat(ALL_AUTHORS, ALL_PARTICIPANTS);

  const handleDeleteParticipant = (participant_id: string) => {
    const newList = inputFields?.selected_participants?.filter((p_id: any) => p_id !== participant_id);
    setValue('selected_participants', newList);
  };

  const handleParticipant = (value: any) => {
    const newList = _.concat(value?.id, inputFields.selected_participants);
    setValue('selected_participants', newList);
  };

  const onChangeAvatar = async (imageList: ImageListType) => {
    setAvatar(imageList);
  };

  const handleUpdateConversation = async () => {
    try {
      await handleUpload(avatar[0]?.file, avatarUrl, setAvatarUrl, session, loggedPlayer.id);

      await editConversation({
        variables: {
          conversation: {
            id: conversation?.id,
            participant_id: state?.conversation?.participant_id,
            title: inputFields.title,
            description: inputFields.description,
            avatar: {
              id: conversation?.avatar?.id,
              filename: avatar[0]?.file ? avatar[0].file.name : '',
              type: avatar[0]?.file ? avatar[0].file.type : '',
              size: avatar[0]?.file ? avatar[0].file.size : 0,
              path: avatarUrl[0],
            },
            participants_ids: inputFields.selected_participants,
          },
        },
      });

      navigate(`/c/${conversation?.short_id}`, { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteConversation = async () => {
    try {
      handleClose();
      await deleteConversation({
        variables: {
          conversation: {
            id: conversation?.id,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  React.useEffect(() => {
    if (conversation === undefined && loadedConversation.data?.findConversationToEditByID) {
      setConversation(loadedConversation.data?.findConversationToEditByID);
    }

    if (conversation) {
      if (inputFields.title === '') setValue('title', conversation?.title);
      if (inputFields.description === undefined && conversation?.description !== undefined) setValue('description', conversation?.description);
      if (inputFields?.selected_participants === undefined && conversation?.participants?.length > 0)
        setValue(
          'selected_participants',
          conversation?.participants?.map((p: any) => p?.id)
        );
    }

    if (deletedConversation.data?.deleteConversation > 0) {
      navigate('/conversations', { replace: true });
    }
  }, [conversation, setConversation, loadedConversation, deletedConversation, navigate]);

  return (
    <Grid className={'component-contacts edit-conversation-page form-page'}>
      <Grid item xs={12} md={12} lg={12} sx={{ padding: '0 0 100px 0' }}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <LogoLilenekIcon sx={{ width: '150px', height: 'auto', p: '20px 0 0 0' }} />
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h1" className={'title padding-left padding-right'}>
            Edit Conversation
          </Typography>
        </Grid>

        <Grid container>
          <Grid className={'search'} item xl={12} xs={12} sm={12}>
            <Grid container sx={{ pb: '40px' }}>
              <LoadingButton className={updatedConversation.loading ? 'button-disabled' : 'button-red-reversed'} disabled={updatedConversation.loading} loading={deletedConversation.loading} endIcon={<Delete />} size="small" onClick={handleOpenDelete}>
                Delete
              </LoadingButton>
              <Button className={updatedConversation.loading || deletedConversation.loading ? 'button-disabled' : 'button-cancel'} disabled={updatedConversation.loading} endIcon={<Clear />} size="small" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <LoadingButton className={deletedConversation.loading ? 'button-disabled' : 'button-green'} disabled={deletedConversation.loading} loading={updatedConversation.loading} size="small" onClick={handleUpdateConversation} endIcon={<Send />}>
                Save updates
              </LoadingButton>
            </Grid>

            <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ textAlign: 'center', p: '10px 0' }}>
              <ImageUploading multiple={false} value={avatar} onChange={onChangeAvatar} maxNumber={1}>
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                  <Grid
                    className="upload__image-wrapper"
                    sx={{
                      top: '0',
                      position: 'relative',
                    }}
                  >
                    {imageList[0] ? (
                      <Grid>
                        {imageList
                          .filter((img) => img.dataURL !== undefined)
                          .map((image: any, index: any) => (
                            <div key={index} className="image-item">
                              <Avatar
                                src={dataURLtoBlobURL(image?.dataURL)}
                                alt={image?.name}
                                sizes="small"
                                sx={{
                                  background: '#FFF',
                                  border: 'solid 4px #FFF',
                                  height: {
                                    xs: 100,
                                    sm: 150,
                                    md: 200,
                                    xl: 250,
                                  },
                                  width: {
                                    xs: 100,
                                    sm: 150,
                                    md: 200,
                                    xl: 250,
                                  },
                                  margin: 'auto',
                                }}
                              />
                              <div className="image-item__btn-wrapper">
                                <Fab size="small" onClick={() => onImageUpdate(index)}>
                                  <Cached />
                                </Fab>
                                <Fab size="small" onClick={() => onImageRemove(index)}>
                                  <Remove />
                                </Fab>
                              </div>
                            </div>
                          ))}
                      </Grid>
                    ) : (
                      <Avatar
                        src={conversation?.avatar?.path}
                        alt={'default avatar'}
                        sizes="small"
                        sx={{
                          border: 'solid 2px rgba(247, 251, 250, .5)',
                          bgcolor: 'rgba(247, 251, 250, .5)',
                          height: {
                            xs: 100,
                            sm: 150,
                            md: 200,
                            xl: 250,
                          },
                          width: {
                            xs: 100,
                            sm: 150,
                            md: 200,
                            xl: 250,
                          },
                          margin: 'auto',
                        }}
                      />
                    )}

                    {avatar[0]?.dataURL ? (
                      ''
                    ) : (
                      <Fab
                        style={isDragging ? { color: 'green' } : undefined}
                        onClick={onImageUpload}
                        variant="extended"
                        size="small"
                        {...dragProps}
                        className="button-green-reverse"
                        sx={{
                          mt: '40px',
                          boxShadow: 'none',
                        }}
                      >
                        Add logo <PhotoCamera />
                      </Fab>
                    )}
                  </Grid>
                )}
              </ImageUploading>
            </Stack>

            <Controller
              name="title"
              control={control}
              rules={{
                required: true,
                maxLength: 30,
                minLength: 4,
                pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
              }}
              render={({ field }: any) => <TextField {...field} label="Title" fullWidth value={_.trimStart(field.value)} className={'field-bottom-space'} />}
            />

            <Controller
              name="description"
              control={control}
              rules={{
                required: false,
                maxLength: 400,
                minLength: 0,
                pattern: /^[a-zA-Z0-9_'\s]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  multiline
                  minRows={3}
                  maxRows={5}
                  label="Topic (optional)"
                  placeholder="What's the topic(s)/purpose(s) of the conversation"
                  variant="outlined"
                  fullWidth
                  className={'field-bottom-space'}
                  error={errors?.description?.type !== undefined}
                  helperText={errors?.description?.type !== undefined ? (field.value.length < 4 ? 'Note must have at least 0 characters' : `There is an unauthorized character in ${field.value}`) : ''}
                  value={_.replace(field.value, /[^a-zA-Z0-9_'\s]+/g, '')}
                />
              )}
            />

            <Controller
              name="all_participants"
              control={control}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  open={openSearchParticipant}
                  onOpen={() => setOpenSearchParticipant(true)}
                  onClose={() => setOpenSearchParticipant(false)}
                  multiple
                  autoHighlight
                  filterSelectedOptions
                  value={field.value}
                  loading={false}
                  inputValue={inputFields.participant_name}
                  onInputChange={(event: any, newInputValue: any) => {
                    setValue('participant_name', newInputValue);
                  }}
                  options={FULL_LIST ? FULL_LIST?.filter((p: any) => inputFields.selected_participants?.find((p_id: string) => p_id === p?.id) === undefined) : []}
                  getOptionLabel={(option: any) => option?.username || option?.name}
                  defaultValue={ARRAY_AS_ANY}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      container
                      key={option?.id}
                      onClick={() => handleParticipant(option)}
                      sx={{
                        alignItems: 'center',
                        p: '10px 15px',
                      }}
                    >
                      <Avatar src={option?.avatar?.path || option?.logo?.path} />
                      &nbsp;&nbsp;
                      <strong>{option?.username || option?.label}</strong>
                    </Grid>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      label={`Participants`}
                      placeholder={`Select a participant`}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Grid>
                              {inputFields.selected_participants?.length === 0 || inputFields.selected_participants?.length === undefined ? (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    m: '0px',
                                    p: '5px 20px',
                                    textAlign: 'center',
                                    bgcolor: 'rgba(0, 0, 0, .1)',
                                    borderRadius: '5px',
                                  }}
                                >
                                  No participant selected
                                </Typography>
                              ) : (
                                inputFields.selected_participants?.map((participant_id: any) => (
                                  <Chip
                                    key={participant_id}
                                    variant="outlined"
                                    label={<strong>{FULL_LIST?.find((pa: any) => pa?.id === participant_id)?.label || FULL_LIST?.find((pa: any) => pa?.id === participant_id)?.username}</strong>}
                                    avatar={
                                      <Avatar
                                        sx={{
                                          height: {
                                            xs: '50px !important',
                                            md: '60px !important',
                                            lg: '70px !important',
                                          },
                                          width: {
                                            xs: '50px !important',
                                            md: '60px !important',
                                            lg: '70px !important',
                                          },
                                        }}
                                        alt={FULL_LIST?.find((pa: any) => pa?.id === participant_id)?.label || FULL_LIST?.find((pa: any) => pa?.id === participant_id)?.username}
                                        src={FULL_LIST?.find((pa: any) => pa?.id === participant_id)?.logo?.path || FULL_LIST?.find((pa: any) => pa?.id === participant_id)?.avatar?.path}
                                      />
                                    }
                                    onDelete={() => handleDeleteParticipant(participant_id)}
                                    sx={{
                                      m: '5px',
                                      height: 'auto',
                                      p: '10px 0',
                                    }}
                                  />
                                ))
                              )}
                            </Grid>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid className={'contacts'} item xl={12} xs={12} sm={12}>
            {/* {inputFields?.selected_participants?.length <= 0 ? (
              <Grid container>
                You don't have any contact yet, <a href="/add_contact">add a new contact</a> using a username.
              </Grid>
            ) : (
              <Stack direction="row">
                {inputFields?.selected_participants?.map((p: any) => (
                  <Chip key={p?.id} onDelete={() => handleDeleteParticipant(p?.id)} label={`${p?.username || p?.label}`} avatar={<Avatar src={p?.avatar?.path || p?.logo?.path} alt={`${p?.username || p?.label}`} />} sx={{ 'height': 'auto', 'p': '10px 0', 'm': '0 30px 10px 0', '& .MuiAvatar-root': { width: '40px', height: '40px' } }} />
                ))}
              </Stack>
            )} */}
          </Grid>
        </Grid>

        <DeleteConfirmationModal title={`Remove ${conversation?.title}`} open={openDelete} entityId={conversation?.id} entityName={`${conversation?.title}`} actionOne={handleDeleteConversation} actionOneTitle={'Delete'} actionTwo={handleClose} actionTwoTitle={'Cancel'} />
      </Grid>
    </Grid>
  );
};

export default EditConversation;
