import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Avatar, CardMedia, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import Moment from 'react-moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';
import { useIntl } from 'react-intl';

interface ITeamHistory {
  player_id: string;
}

const TeamHistory: React.FC<ITeamHistory> = (props: ITeamHistory) => {
  const FIND_PLAYER_POSITIONS_ID_QUERY = gql`
    query findPlayerPositionsByID($player: inputFindPlayerPositionsByID!) {
      findPlayerPositionsByID(player: $player) {
        id
        status
        team_number
        team_player {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            shortname
            type
            avatar {
              path
            }
            cover {
              path
            }
            originate_location {
              id
              coordinates
              country_code
              city {
                name
              }
              country {
                name
              }
              continent {
                name
              }
            }
          }
        }
        joined_at
        left_at
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [positions, setPositions] = React.useState(UNDEFINED_AS_ANY);

  const loadedPlayerPositions = useQuery(FIND_PLAYER_POSITIONS_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      player: {
        id: props.player_id,
      },
    },
  });

  React.useEffect(() => {
    if (!positions && loadedPlayerPositions.data?.findPlayerPositionsByID) {
      setPositions(loadedPlayerPositions.data?.findPlayerPositionsByID);
    }
  }, [positions, loadedPlayerPositions]);

  return (
    <Grid container id="max-width" className={'component-history-player'}>
      {!positions || positions?.length === 0 || loadedPlayerPositions.loading ? (
        loadedPlayerPositions.loading ? (
          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
        ) : (
          <Typography
            variant="h3"
            sx={{
              p: '40px 100px 0',
              fontSize: '1.3em',
              textAlign: 'center',
              color: 'rgba(247, 251, 250, 0.3)',
              display: 'inline-block',
              width: '100%',
              minHeight: '20vh',
            }}
          >
            {`${intl.formatMessage({ id: 'Global_No_History' })}`}
          </Typography>
        )
      ) : (
        <Stack direction="column" sx={{ width: '100%', p: { xs: '0 5%', sm: '0' } }}>
          {positions
            ?.filter((p: any) => p?.team_player)
            ?.map((p: any, index: number) => (
              <CardMedia
                key={index}
                sx={{
                  display: { xs: 'inline-block', sm: 'inline-flex' },
                  justifyContent: 'start',
                  alignItems: 'center',
                  m: '0 0 30px',
                  p: '35px 30px',
                  borderRadius: '10px',
                  width: '100%',
                  bgcolor: 'rgba(247, 251, 250, .04)',
                  backgroundImage: p?.team_player?.cover?.path ? `linear-gradient(to bottom, rgba(15, 15, 15 , .6) 0%,rgba(15, 15, 15 , 1) 95%), url(${p?.team_player?.cover?.path})` : '',
                }}
              >
                <Avatar src={p?.team_player?.avatar?.path} alt={p?.team_player?.name} component={Link} to={p?.team_player?.__typename === 'FootballTeam' ? `/football_team/${p?.team_player?.short_id}` : p?.team_player?.__typename === 'BasketballTeam' ? `/basketball_team/${p?.team_player?.short_id}` : ''} sx={{ width: '50px', height: '50px', m: { xs: 'auto', sm: '0' } }} />
                <Grid sx={{ textAlign: { xs: 'center', sm: 'left' }, pl: '10px', width: '100%' }}>
                  <Typography
                    component={Link}
                    to={p?.team_player?.__typename === 'FootballTeam' ? `/football_team/${p?.team_player?.short_id}` : p?.team_player?.__typename === 'BasketballTeam' ? `/basketball_team/${p?.team_player?.short_id}` : ''}
                    sx={{
                      'fontWeight': '800',
                      'fontSize': '1.3em',
                      'width': '100%',
                      'display': 'inline-block',
                      'color': 'inherit',
                      'textDecoration': 'none',
                      '&:hover': {
                        color: 'rgba(251, 88, 2, 1)',
                      },
                    }}
                  >
                    {p?.team_player?.name}
                  </Typography>

                  <Grid container>
                    <Grid item xs={12} md={6} lg={6} sx={{ color: 'rgba(102, 130, 19, 1)' }}>
                      {p?.left_at > 0 && p?.left_at > p?.joined_at ? (
                        ''
                      ) : (
                        <>
                          {p?.team_player ? `${intl.formatMessage({ id: 'Global_Player' })}` : `${intl.formatMessage({ id: 'Global_Manager' })}`} {`${intl.formatMessage({ id: 'Global_Since' })}`}&nbsp;
                        </>
                      )}
                      <Moment format="MMM YYYY">{p?.joined_at}</Moment>
                      {p?.left_at > 0 && p?.left_at > p?.joined_at ? (
                        <>
                          &nbsp;-&nbsp;<Moment format="MMM YYYY">{p?.left_at}</Moment>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} id="secondary-font" sx={{ height: '0px', textAlign: 'right', fontWeight: '700', m: { xs: '-150px 0 0', sm: '-100px 0 0', md: '-90px 0 0' }, fontSize: { xs: '5em', sm: '6em', md: '6.5em' }, WebkitTextStroke: '1px rgba(255, 255, 255, 1)', color: 'transparent' }}>
                      {p?.team_number ? `#${p?.team_number}` : ''}
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: { xs: 'inline-block', sm: 'inline-flex' } }}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'rgba(247, 251, 250, 1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: { xs: 'center', sm: 'start' },
                      }}
                    >
                      {_.replace(p?.team_player?.__typename, 'Team', ` Team`)}
                    </Typography>
                    <Grid sx={{ display: { xs: 'none', sm: 'block' } }}>&nbsp;-&nbsp;</Grid>
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'rgba(247, 251, 250, 1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: { xs: 'center', sm: 'start' },
                      }}
                    >
                      {p?.team_player?.originate_location?.city?.name}, {p?.team_player?.originate_location?.country?.name}
                      &nbsp;
                      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${p?.team_player?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${p?.team_player?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                    </Typography>
                  </Grid>
                </Grid>
              </CardMedia>
            ))}
        </Stack>
      )}
    </Grid>
  );
};

export default TeamHistory;
