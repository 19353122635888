import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMutation, gql } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputAdornment, Grid, TextField, MenuItem, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Clear, Send } from '@mui/icons-material';
import { ALL_SOCIAL_MEDIAS } from '../../utilities/utilities';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';

export default function AddSocialMediaModal(props: { owner_id: string; handleClose: any; open: any; loading?: boolean }) {
  const ADD_SOCIAL_MEDIA_MUTATION = gql`
    mutation addSocialMedia($media: inputAddSocialMedia!) {
      addSocialMedia(media: $media)
    }
  `;

  const session = useAppSelector(selectSession);
  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: 'INSTAGRAM',
      handle: '',
      link: '',
    },
  });
  const inputFields = watch();

  const [addSocialMedia, newSocialMedia] = useMutation(ADD_SOCIAL_MEDIA_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    props.handleClose();
    reset();
  };

  const handleAddSocialMedia = async () => {
    try {
      await addSocialMedia({
        variables: {
          media: {
            owner_id: props.owner_id,
            name: inputFields.name,
            handle: inputFields.handle,
            link: inputFields.link,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Add social media</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ m: '20px 0' }}>
          <Controller
            control={control}
            name="name"
            render={({ field }: any) => (
              <TextField {...field} value={field.value} select label="Name" variant="outlined" error={errors?.name !== undefined} required fullWidth className={'field-bottom-space'}>
                {ALL_SOCIAL_MEDIAS.map((option: any, index: number) => (
                  <MenuItem key={index} value={option?.value}>
                    <Grid container sx={{ alignItems: 'center' }}>
                      {ALL_SOCIAL_MEDIAS?.find((m: any) => m?.value === option?.value)?.icon}&nbsp;&nbsp;<strong>{option?.label}</strong>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="handle"
            control={control}
            rules={{
              required: false,
              minLength: 3,
              maxLength: 255,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="Handle"
                helperText={"You don't need to add @ at the beginning"}
                fullWidth
                value={_.trimStart(field.value?.replaceAll('@', ''))}
                className={'field-bottom-space'}
                sx={{ '& .MuiFormHelperText-root': { color: 'inherit' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ '& .MuiTypography-root': { color: 'rgba(247, 251, 250, .3)' } }}>
                      @
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            name="link"
            control={control}
            rules={{
              required: false,
              minLength: 3,
              maxLength: 255,
            }}
            render={({ field }: any) => <TextField {...field} label="Link" fullWidth value={_.trimStart(field.value)} placeholder={'https://example.com'} className={'field-bottom-space'} />}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton onClick={handleAddSocialMedia} className={'actionOne button-green'} type="submit" loadingPosition="end" endIcon={<Send />} autoFocus loading={newSocialMedia.loading}>
          Add social media
        </LoadingButton>
        <Button onClick={handleClose} className={'actionTwo button-cancel'} endIcon={<Clear />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddSocialMediaModal.propTypes = {
  owner_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

AddSocialMediaModal.defaultProps = {
  loading: false,
};
