import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useMutation, gql, useQuery } from '@apollo/client';
import { FIELD_SIZES, FIELD_TYPES, SPORT_TYPES, COUNTRIES } from '../../utilities/utilities';
import { Fab, Typography, Grid, MenuItem, TextField, Button, Dialog, DialogTitle, Divider, DialogContent, Autocomplete } from '@mui/material';
import { Close, Delete, Send } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { ARRAY_AS_ANY, CountryType, DESCRIPTION_AS_ANY, IMAGE_LIST_AS_ANY, INITIAL_COUNTRY, MEDIAS_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import UploadIMGFeature from '../common/UploadIMGFeature';
import { handleUpload } from '../../helpers';
import { selectPlayer } from '../../reducers/playerSlice';
import GPSMapFeature from '../common/GPSMapFeature';
import AutofillAddressInput from '../layout/AutofillAddressInput';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { useNavigate } from 'react-router-dom';
import InputDescriptionsFeature from '../common/InputDescriptionsFeature';
import { useIntl } from 'react-intl';

interface IFormEditFieldModal {
  field_id: string;
  open: any;
  handleClose: any;
}

const FormEditFieldModal: React.FC<IFormEditFieldModal> = (props: IFormEditFieldModal) => {
  const FIND_FIELD_TO_EDIT_BY_PLAYER_ID_QUERY = gql`
    query findFieldToEditByPlayerID($id: ID!) {
      findFieldToEditByPlayerID(id: $id) {
        id
        short_id
        name
        size
        type
        sports
        status
        pictures {
          id
          path
        }
        descriptions {
          language
          content
        }
        equipments {
          id
          name
          pictures {
            path
          }
        }
        rental_asset {
          id
          price {
            value
            currency {
              currency_code
            }
          }
        }
        publisher {
          id
          username
          avatar {
            path
          }
        }
        location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const EDIT_FIELD_BY_PLAYER_MUTATION = gql`
    mutation editFieldByPlayer($field: inputEditFieldByPlayer!) {
      editFieldByPlayer(field: $field) {
        id
        name
      }
    }
  `;

  const DELETE_FIELD_MUTATION = gql`
    mutation deleteFieldByPlayer($field: inputDeleteFieldByPlayer!) {
      deleteFieldByPlayer(field: $field)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const navigate = useNavigate();
  const intl = useIntl();

  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      has_description: false,
      language: 'GB',
      descriptions: ARRAY_AS_ANY,
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',

      field_name: '',
      field_type: '',
      field_sport: 'FOOTBALL',
      field_size: 'FIVE_ASIDE',
      field_equipments: ARRAY_AS_ANY,
      field_pictures: MEDIAS_AS_ANY,

      field_position: UNDEFINED_AS_ANY,
      address_title: UNDEFINED_AS_ANY,
      street_name: UNDEFINED_AS_ANY,
      secondary_street_name: UNDEFINED_AS_ANY,
      postcode: UNDEFINED_AS_ANY,
      city: UNDEFINED_AS_ANY,
      county: UNDEFINED_AS_ANY,
      state: UNDEFINED_AS_ANY,
      country: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [field, setField] = React.useState(UNDEFINED_AS_ANY);
  const [openDeleteField, setOpenDeleteField] = React.useState(false);
  const [country, setCountry] = React.useState<CountryType>(UNDEFINED_AS_ANY);
  const [progress, setProgress] = React.useState(0);
  const [fieldPicturesUrls, setFieldPicturesUrls] = React.useState([]);

  const [editFieldByPlayer, updatedField] = useMutation(EDIT_FIELD_BY_PLAYER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteFieldByPlayer, deletedField] = useMutation(DELETE_FIELD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const loadedField = useQuery(FIND_FIELD_TO_EDIT_BY_PLAYER_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.field_id,
    },
  });

  const handleCloseOpen = () => {
    setOpenDeleteField(false);
  };

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setCountry(country);
      setValue('country', country);
    }
  };

  const onChangePictures = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setValue('field_pictures', imageList);
  };

  const handleClose = () => {
    props.handleClose();
    handleReset();
  };

  const handleReset = () => {
    reset();
    setProgress(0);
    setFieldPicturesUrls([]);
    setField(UNDEFINED_AS_ANY);
    setValue('field_pictures', MEDIAS_AS_ANY);
  };

  const handleEditField = async () => {
    try {
      setProgress(1);

      const pictureUploadPromises = inputFields.field_pictures
        ?.filter((p: any) => p?.file !== undefined)
        .map(async (pix: any) => {
          await handleUpload(pix.file, fieldPicturesUrls, setFieldPicturesUrls, session, loggedPlayer?.id);
        });
      if (pictureUploadPromises) await Promise.all(pictureUploadPromises);

      await editFieldByPlayer({
        variables: {
          field: {
            id: field?.id,
            name: inputFields.field_name,
            type: inputFields.field_type,
            size: inputFields.field_size,
            sports: _.concat(inputFields.field_sport),
            equipments: inputFields.field_equipments,
            descriptions: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
            pictures: _.concat(
              (inputFields.field_pictures || field?.pictures)
                ?.filter((p: any) => p?.file === undefined)
                ?.map((p: any) => {
                  return {
                    id: p?.id,
                    filename: p.file ? (p.file?.name ? p.file?.name : p.file?.filename) : '',
                    size: p.file ? p.file?.size : 0,
                    type: p.file ? p.file?.type : '',
                    path: p.path,
                  };
                }),
              (inputFields.field_pictures || field?.pictures)
                ?.filter((p: any) => p?.file !== undefined)
                ?.map((p: any, index: number) => {
                  return {
                    id: null,
                    filename: p.file ? (p.file?.name ? p.file?.name : p.file?.filename) : '',
                    size: p.file ? p.file?.size : 0,
                    type: p.file ? p.file?.type : '',
                    path: fieldPicturesUrls[index],
                  };
                })
            ),
            location: {
              id: field?.location?.id,
              coordinations: {
                latitude: inputFields.field_position?.lat,
                longitude: inputFields.field_position?.lng,
              },
              title: inputFields.field_name,
              street_name: inputFields.street_name,
              secondary_street_name: inputFields.secondary_street_name,
              postcode: inputFields.postcode,
              city: inputFields.city,
              county: inputFields.county,
              state: inputFields.state,
              country_code: country.value,
              country: country.label,
              continent: country.continent,
            },
          },
        },
      });

      handleClose();
    } catch (e) {
      console.error(e);
    } finally {
      handleReset();
    }
  };

  const handleDeleteField = async () => {
    try {
      await deleteFieldByPlayer({
        variables: {
          field: {
            logged_player_id: loggedPlayer?.id,
            id: field?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
    } finally {
      handleReset();
      navigate('/profile', { replace: true });
    }
  };

  React.useEffect(() => {
    if (props.open && field === undefined && loadedField.called && loadedField.data?.findFieldToEditByPlayerID) {
      setField(loadedField.data?.findFieldToEditByPlayerID);
    }

    if (!props.open && field) {
      setField(UNDEFINED_AS_ANY);
    }

    if (field !== undefined) {
      if (inputFields.field_name === '') setValue('field_name', field?.name);
      if (inputFields.field_sport === '') setValue('field_sport', field?.sports[0]);
      if (inputFields.field_size === '') setValue('field_size', field?.size);
      if (inputFields.field_type === '') setValue('field_type', field?.type);
      if (inputFields.field_equipments?.length === 0 && field?.equipments?.length > 0)
        setValue(
          'field_equipments',
          field?.equipments?.map((e: any) => e?.name)
        );

      if (inputFields.descriptions?.length === 0 && field?.descriptions?.length > 0) setValue('descriptions', field?.descriptions);
      if (inputFields.field_pictures?.length === 0 && field?.pictures?.length > 0) setValue('field_pictures', field?.pictures);

      if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
      if (inputFields.descriptions?.length > 0 && inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');

      if (inputFields.street_name === undefined && field?.location?.street_name) setValue('street_name', field?.location?.street_name);
      if (inputFields.secondary_street_name === undefined && field?.location?.secondary_street_name) setValue('secondary_street_name', field?.location?.secondary_street_name);
      if (inputFields.postcode === undefined && field?.location?.postcode?.name) setValue('postcode', field?.location?.postcode?.name);
      if (inputFields.city === undefined && field?.location?.city?.name) setValue('city', field?.location?.city?.name);
      if (inputFields.county === undefined && field?.location?.county?.name) setValue('county', field?.location?.county?.name);
      if (inputFields.state === undefined && field?.location?.state?.name) setValue('state', field?.location?.state?.name);
      if (country === undefined && COUNTRIES?.find((c: any) => c?.label === field?.location?.country?.name)) {
        setCountry(COUNTRIES?.find((c: any) => c?.label === field?.location?.country?.name) || INITIAL_COUNTRY);
        setValue('country', COUNTRIES?.find((c: any) => c?.label === field?.location?.country?.name) || INITIAL_COUNTRY);
      }
      if (!inputFields.field_position && field?.location?.coordinates) setValue('field_position', { lat: field?.location?.coordinates?.latitude, lng: field?.location?.coordinates?.longitude });
    }
  }, [props, field, loadedField, inputFields, setValue]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '80%', md: '50%' }, maxWidth: { xs: '90%', md: '60%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'FormEditField_Title' })}`}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent>
        <Grid className={'fields'}>
          <Controller
            name="field_name"
            control={control}
            rules={{
              required: true,
              maxLength: 100,
              minLength: 4,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'FormNewField_Name_Label' })}`}
                placeholder={`${intl.formatMessage({ id: 'FormNewField_Name_Label' })}`}
                fullWidth
                required
                className={'field-bottom-space'}
                error={inputFields.field_name?.length > 100 || errors.field_name !== undefined}
                helperText={inputFields.field_name?.length > 80 ? (inputFields.field_name?.length < 101 ? `${100 - inputFields.field_name?.length} ${98 >= inputFields.field_name?.length ? 'characters' : 'character'} left` : `You must remove ${inputFields.field_name?.length - 100} ${inputFields.field_name?.length <= 101 ? 'character' : 'characters'}`) : ''}
              />
            )}
          />

          <Controller
            name="field_sport"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => (
              <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'Field_Sport_Type_Title' })}`} variant="outlined" fullWidth className={'field-bottom-space'}>
                {SPORT_TYPES.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${intl.formatMessage({ id: option.label })}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="field_size"
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                label={`${intl.formatMessage({ id: 'Field_Size_Label' })}`}
                placeholder="Select a size"
                variant="outlined"
                error={errors?.field_size !== undefined}
                fullWidth
                sx={{
                  mr: { xs: '0', sm: '3%', md: '3%' },
                  width: {
                    xs: '100%',
                    sm: '40%',
                    md: '40%',
                  },
                }}
                className={'field-bottom-space'}
              >
                {FIELD_SIZES.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${intl.formatMessage({ id: option.label })}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="field_type"
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                label={`${intl.formatMessage({ id: 'Field_Type_Label' })}`}
                placeholder="Select a type"
                variant="outlined"
                error={errors?.field_type !== undefined}
                fullWidth
                sx={{
                  ml: { xs: '0', sm: '3%', md: '3%' },
                  width: {
                    xs: '100%',
                    sm: '54%',
                    md: '54%',
                  },
                }}
                className={'field-bottom-space'}
              >
                {FIELD_TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {`${intl.formatMessage({ id: option.label })}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Typography
            variant="h3"
            sx={{
              fontSize: '1.4em',
              fontWeight: '700',
              m: '40px 0 20px',
            }}
          >
            {`${intl.formatMessage({ id: 'FormNewField_Pictures_Title' })}`}
          </Typography>
          <UploadIMGFeature pictures={inputFields?.field_pictures || []} setter_pictures={onChangePictures} max_number={10} accepted_types={['jpg', 'png', 'jpeg', 'jpeg']} />

          <Grid container sx={{ justifyContent: 'start' }}>
            {inputFields.has_description ? (
              <Fab variant="extended" size="small" className="button-red-reversed" onClick={() => setValue('has_description', false)} sx={{ m: '40px 0 0', boxShadow: 'none', bgcolor: 'transparent' }}>
                {`${intl.formatMessage({ id: 'FormNewField_No_Description' })}`}
              </Fab>
            ) : (
              <Fab variant="extended" size="small" className="button-green-reverse" onClick={() => setValue('has_description', true)} sx={{ m: '40px 0 0', boxShadow: 'none', borderColor: 'transparent !important' }}>
                {`${intl.formatMessage({ id: 'FormNewField_Button_Add_Descriptions' })}`}
              </Fab>
            )}
          </Grid>

          {inputFields.has_description ? (
            <>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '20px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormNewField_Descriptions' })}`}
              </Typography>
              <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />
            </>
          ) : (
            ''
          )}

          <Typography
            variant="h3"
            sx={{
              fontSize: '1.4em',
              fontWeight: '700',
              m: '40px 0 20px',
            }}
          >
            {`${intl.formatMessage({ id: 'FormNewField_Location' })}`}
          </Typography>
          <AutofillAddressInput control={control} errors={errors} address_input={inputFields.street_name} setter={setValue} street_name="street_name" secondary_street_name={'secondary_street_name'} postcode={'postcode'} county={'county'} state={'state'} city={'city'} country={'country'} setterCountry={setCountry} />

          <Controller
            name="secondary_street_name"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'Global_Address_Complement_1' })}`} autoComplete="address-line2" placeholder={`${intl.formatMessage({ id: 'Global_Address_Complement_2' })}`} variant="outlined" fullWidth value={field.value} error={errors?.secondary_street_name !== undefined} className={'field-bottom-space'} />}
          />

          <Controller
            name="postcode"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'Global_Address_Postcode_1' })}`}
                placeholder={`${intl.formatMessage({ id: 'Global_Address_Postcode_2' })}`}
                autoComplete="postal-code"
                variant="outlined"
                value={_.replace(field.value, /[^a-zA-Z0-9-\s]+/g, '')}
                error={errors?.postcode !== undefined}
                helperText={errors?.postcode !== undefined ? `${errors?.postcode?.message}` : ''}
                className={'field-bottom-space'}
                sx={{
                  mr: { xs: '0', sm: '3%', md: '5%' },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'Global_Address_City_1' })}`}
                placeholder={`${intl.formatMessage({ id: 'Global_Address_City_2' })}`}
                autoComplete="address-level2"
                variant="outlined"
                value={field.value}
                error={errors?.city !== undefined}
                helperText={errors?.city !== undefined ? `${errors?.city?.message}` : ''}
                className={'field-bottom-space'}
                sx={{
                  ml: { xs: '0', sm: '3%', md: '5%' },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="county"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'Global_Address_County_1' })}`}
                placeholder={`${intl.formatMessage({ id: 'Global_Address_County_2' })}`}
                variant="outlined"
                value={field.value}
                error={errors?.county !== undefined}
                helperText={errors?.county !== undefined ? `${errors?.county?.message}` : ''}
                className={'field-bottom-space'}
                sx={{
                  mr: { xs: '0', sm: '3%', md: '5%' },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            name="state"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label={`${intl.formatMessage({ id: 'Global_Address_State_1' })}`}
                autoComplete="address-level1"
                placeholder={`${intl.formatMessage({ id: 'Global_Address_State_2' })}`}
                variant="outlined"
                value={field.value}
                error={errors?.state !== undefined}
                helperText={errors?.state !== undefined ? `${errors?.state?.message}` : ''}
                className={'field-bottom-space'}
                sx={{
                  ml: { xs: '0', sm: '3%', md: '5%' },
                  width: {
                    xs: '100%',
                    sm: '47%',
                    md: '45%',
                  },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="country"
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                options={COUNTRIES}
                value={country}
                className={'country-select'}
                onChange={(event: any, value: any) => onChangeCountry(event, value)}
                autoHighlight
                getOptionLabel={(option: CountryType) => option.label}
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                renderOption={(props: any, option: CountryType) => (
                  <Grid
                    component="li"
                    sx={{
                      '& > img': {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                    {option.label} ({option.value})
                  </Grid>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={`${intl.formatMessage({ id: 'Global_Address_Country' })}`}
                    value={country}
                    required
                    variant="outlined"
                    className={'field-bottom-space'}
                    error={errors?.country !== undefined}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          />

          <GPSMapFeature position={inputFields.field_position} element={'field_position'} setValue={setValue} height="250px" />

          <Grid sx={{ mt: '40px' }}>
            <LoadingButton disabled={progress > 0 || updatedField.loading} className={progress > 0 || updatedField.loading ? 'button-disabled' : 'button-red-reversed'} loading={deletedField.loading} loadingPosition="end" endIcon={<Delete />} onClick={() => setOpenDeleteField(true)}>
              {`${intl.formatMessage({ id: 'Global_Button_Delete' })}`}
            </LoadingButton>

            <Button onClick={handleClose} disabled={progress > 0 || updatedField.loading} className={progress > 0 || updatedField.loading ? 'button-disabled' : 'button-cancel'}>
              {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
            </Button>

            <LoadingButton onClick={handleEditField} loading={progress > 0 || updatedField.loading} loadingPosition="end" endIcon={<Send />} disabled={inputFields.field_name === '' || inputFields.field_size === '' || inputFields.field_type === '' || inputFields?.field_pictures?.length === 0} className={inputFields.field_name === '' || inputFields.field_size === '' || inputFields.field_type === '' || inputFields?.field_pictures?.length === 0 ? 'button-disabled' : 'button-green'}>
              {`${intl.formatMessage({ id: 'Global_Button_Save_Updates' })}`}
            </LoadingButton>
          </Grid>

          <DeleteConfirmationModal open={openDeleteField} title={`${intl.formatMessage({ id: 'Global_Permanent_Delete_Title' })} ${field?.name}`} entityName={field?.name} entityId={field?.id} actionOne={handleDeleteField} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={handleCloseOpen} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

FormEditFieldModal.propTypes = {
  field_id: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

FormEditFieldModal.defaultProps = {
  open: false,
};

export default FormEditFieldModal;
