import React from 'react';
import { useNavigate } from 'react-router-dom';
import { InputLabel, Box } from '@mui/material';
import BackButton from '../../component-modals/common/BackButton';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';

const FormSignIn2F: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (loggedPlayer?.id) {
      navigate('/profile', { replace: true });
    }
  }, []);

  return (
    <Box className={'form-page component-profile'}>
      <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

      <h1 className={'title'}>2-Facts Verification</h1>

      <Box className={'padding-left padding-right-xs'}>
        <form className={'form-root'} noValidate autoComplete="off">
          <InputLabel>Verification Code</InputLabel>
        </form>
      </Box>
    </Box>
  );
};

export default FormSignIn2F;
