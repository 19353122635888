import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Grid, Button, Typography, CardMedia, IconButton } from '@mui/material';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import MenuButton from '../../component-modals/layout/MenuButton';

interface IKYCNotice {
  kyc_reference: string;
  wallet: { id: string; title: string; type: string };
}

const KYCNotice: React.FC = () => {
  const location: any = useLocation();
  const state: IKYCNotice = location?.state;

  return (
    <Grid container className={'component-preferences kyc-notice-page'} sx={{ p: '0 5%' }}>
      <CardMedia
        component="img"
        // image={'../../img/kyc_notice.png'}
        sx={{
          '& img': { opacity: '.4' },
          'width': '100%',
          'height': '100%',
          // 'position': 'absolute',
          'left': '0%',
          'top': '0',
          'zIndex': '-1',
          'bgcolor': 'rgba(15,15,15,1)',
        }}
      />
      <Grid container>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      <Grid container>
        <Grid className={'actions'} item xl={12} xs={12} sm={12} sx={{ textAlign: 'right', p: '0' }}>
          <Button className="button-green-reverse" component={Link} to={'/w/wallets'}>
            All Wallets
          </Button>
        </Grid>
        <Grid className={'title'} item xl={12} xs={12} sm={12}>
          <h1>Wallet {`${state?.wallet?.title ? `(${state?.wallet?.title})` : ''}`} successfully created!</h1>
        </Grid>
        <Grid className={'content'} item xl={12} xs={12} sm={12}>
          {state?.kyc_reference ? (
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1.4em',
                width: { xs: '60%', md: '70%' },
                m: '5% 0 0 auto',
              }}
            >
              Your information are going through our security verification processes (KYC/AML), you shall receive a notification shortly.
              <br />
              <br />
              Thank you for your patience!
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: '1.4em',
                width: { xs: '60%', md: '70%' },
                m: '5% 0 0 auto',
              }}
            >
              Your wallet has been created but you won&apos;t be able to use it until you proceed to our ID verifications, to do the verification, go to{' '}
              <Typography
                sx={{ fontSize: '1em', textDecoration: 'none', color: 'inherit', fontWeight: '700' }}
                component={Link}
                to={'/w/wallet'}
                state={{
                  wallet: {
                    id: state?.wallet?.id,
                    type: state?.wallet?.type,
                  },
                }}
              >
                your wallet
              </Typography>
              .<br />
              <br />
              Thank you!
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KYCNotice;
