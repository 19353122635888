import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { Snackbar, Alert, CircularProgress, Grid, TextField, InputAdornment, IconButton, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff, Login } from '@mui/icons-material';
import { useMutation, gql } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { storeSession, storeJWTToken } from '../../reducers/sessionSlice';
import { removePreviousPlayer, selectPlayer, storePlayer } from '../../reducers/playerSlice';
import ForgotPasswordForm from './ForgotPasswordForm';
import { FormattedMessage } from 'react-intl';
import { AppContext } from '../../App';
import useEventLog from '../../hooks/useEventLog';
import { REACT_APP_CLIENT_NAME } from '../../config';

interface ILoginForm {
  destination: string;
  handleClose?: any;
}

const LoginForm: React.FC<ILoginForm> = (props: ILoginForm) => {
  const LOGIN_PLAYER_MUTATION = gql`
    mutation loginPlayer($player: inputLoginPlayer!) {
      loginPlayer(player: $player) {
        id
        status
        created_at
        expire_at
        token {
          key
        }
        app_client {
          id
          name
          configurations {
            id
            is_dark_mode
            two_facts_mode
          }
        }
        account {
          ... on Player {
            __typename
            id
            username
            owner {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const appContext = React.useContext(AppContext);
  const navigate = useNavigate();
  const { handleSendLog } = useEventLog();

  const { control, watch, reset, setValue } = useForm({
    defaultValues: {
      identifier: loggedPlayer?.app_configuration?.previous_player?.username || '',
      password: '',
    },
  });
  const inputFields = watch();

  const dispatch = useAppDispatch();

  const [loginPlayer, newLoggedPlayer] = useMutation(LOGIN_PLAYER_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });

  const [resetPassword, setResetPassword] = React.useState(false);
  const [hidePassword, setHidePassword] = React.useState(false);
  const [hasLoggedFailed, setHasLoggedFailed] = React.useState(false);

  const handleRemovePreviousUser = () => {
    try {
      setValue('identifier', '');
      dispatch(removePreviousPlayer());
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleLogin = async () => {
    try {
      await loginPlayer({
        variables: {
          player: {
            identifier: inputFields.identifier,
            password: inputFields.password,
            coords: {
              latitude: appContext?.currentPosition?.latitude || 11.0051704, // appContext?.currentPosition?.latitude,
              longitude: appContext?.currentPosition?.longitude || 11.0051704, // appContext?.currentPosition?.longitude,
              height: appContext?.currentPosition?.accurancy !== undefined ? appContext?.currentPosition?.accurancy : 0,
            },
            app_client: REACT_APP_CLIENT_NAME,
            device: {
              vendor: appContext?.currentDevice?.device.vendor,
              model: appContext?.currentDevice?.device.model,
              type: appContext?.currentDevice?.device.type,
              os_name: appContext?.currentDevice?.os.name,
              os_version: appContext?.currentDevice?.os.version,
              browser: appContext?.currentDevice?.browser.name,
              browser_version: appContext?.currentDevice?.browser.version,
            },
          },
        },
      });
    } catch (e: any) {
      reset({ identifier: '', password: '' });
      setHasLoggedFailed(true);

      handleSendLog(e.toString());
    } finally {
      if (newLoggedPlayer?.data === null) {
        setHasLoggedFailed(true);
      }
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setHasLoggedFailed(false);
  };

  React.useEffect(() => {
    if (newLoggedPlayer.data !== undefined && newLoggedPlayer.error === undefined) {
      dispatch(storeSession(newLoggedPlayer.data?.loginPlayer));
      dispatch(storeJWTToken(newLoggedPlayer.data?.loginPlayer?.token?.key));
      dispatch(storePlayer(newLoggedPlayer.data?.loginPlayer?.account));

      navigate(props.destination, { replace: true });

      if (props.handleClose) {
        props.handleClose();
      }
    }
  }, [newLoggedPlayer, dispatch, navigate, props]);

  return (
    <Grid container className={''}>
      {!resetPassword ? (
        <Grid item xs={12} md={12} lg={12}>
          <FormattedMessage id="LoginForm_Login_Title">
            {(title: any) => (
              <Helmet>
                <title>{`${title}`} x Fivezer #DifferentAnimalSameBeast</title>
                <meta name="description" content={`${(<FormattedMessage id="Global_Meta_Description">{(content: any) => <>{content}</>}</FormattedMessage>)}`} />
                <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
              </Helmet>
            )}
          </FormattedMessage>

          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              {loggedPlayer?.app_configuration?.previous_player?.username ? (
                <></>
              ) : (
                <>
                  <Controller
                    name="identifier"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[a-zA-Z0-9+-@._]*$/,
                    }}
                    render={({ field }: any) => <TextField {...field} label={<FormattedMessage id="LoginForm_Identifier" />} value={field.value.toLowerCase()} fullWidth required className={'field-bottom-space'} />}
                  />
                </>
              )}
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="LoginForm_Password" />}
                    type={hidePassword ? 'text' : 'password'}
                    fullWidth
                    autoComplete={'off'}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                            {hidePassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Grid sx={{ textAlign: 'left', p: '0 0 30px' }}>
                {loggedPlayer?.app_configuration?.previous_player?.username ? (
                  <Button className="button-cancel" onClick={handleRemovePreviousUser} sx={{ fontSize: '.6em' }}>
                    <FormattedMessage id="LoginForm_Switch_User" />
                  </Button>
                ) : (
                  ''
                )}

                <Button className="button-cancel" onClick={() => setResetPassword(!resetPassword)} sx={{ fontSize: '.6em' }}>
                  <FormattedMessage id="LoginForm_Forgot_Password" />
                </Button>
              </Grid>

              {appContext?.currentPosition === undefined ? (
                <Alert severity="info">
                  <FormattedMessage id="LoginForm_Turn_On_Location" />
                </Alert>
              ) : (
                <LoadingButton
                  onClick={inputFields.identifier === '' || inputFields.password === '' || appContext?.currentPosition === undefined ? undefined : handleLogin}
                  loading={newLoggedPlayer.loading}
                  loadingPosition="end"
                  endIcon={newLoggedPlayer.loading ? <CircularProgress size={20} /> : <Login />}
                  fullWidth
                  disabled={inputFields.identifier === '' || inputFields.password === '' || appContext?.currentPosition === undefined}
                  className={inputFields.identifier === '' || inputFields.password === '' || appContext?.currentPosition === undefined ? 'button-disabled' : 'button-green'}
                >
                  {newLoggedPlayer.loading ? <FormattedMessage id="LoginForm_Logging" /> : <FormattedMessage id="LoginForm_Login" />}
                </LoadingButton>
              )}

              <Grid sx={{ textAlign: 'center', mt: '30px' }}>
                <Button className="button-cancel" component={Link} to={'/signup'} sx={{ fontSize: '.6em', display: 'inline-block' }}>
                  <FormattedMessage id="LoginForm_No_Member_Yet" />
                  &nbsp;
                  <strong>
                    <FormattedMessage id="LoginForm_Signup" />
                  </strong>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Snackbar open={hasLoggedFailed} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
              <FormattedMessage id="LoginForm_Error_Login" />
            </Alert>
          </Snackbar>
        </Grid>
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <FormattedMessage id="LoginForm_Reset_Password">
            {(title: any) => (
              <Helmet>
                <title>{`${title}`} x Fivezer #DifferentAnimalSameBeast</title>
                <meta name="description" content={`${(<FormattedMessage id="Global_Meta_Description">{(content: any) => <>{content}</>}</FormattedMessage>)}`} />
                <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
              </Helmet>
            )}
          </FormattedMessage>

          <ForgotPasswordForm />

          <Grid sx={{ textAlign: 'center', p: '0 0 30px' }}>
            <Button className="button-cancel" onClick={() => setResetPassword(!resetPassword)} sx={{ fontSize: '.6em' }}>
              <FormattedMessage id="LoginForm_Remember_Password" />
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  destination: PropTypes.string.isRequired,
};
