import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RootState } from '../redux/store';

export interface SessionState {
  session: any;
  token: string;
}

const initialState: SessionState = {
  session: {},
  token: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    storeSession: (state: any, action: PayloadAction<string>) => {
      // console.log("state.session_id", action.payload)
      state.session = action.payload;
    },
    storeJWTToken: (state: any, action: PayloadAction<string>) => {
      // console.log("state.token", action.payload)
      state.token = action.payload;
    },
    logoutSession: (state: any, action: PayloadAction<string>) => {
      // console.log("state.session_id", action.payload)
      state.session = action.payload;
    },
  },

  extraReducers: (builder: any) => {
    builder.addCase(PURGE, (state: any) => {
      state.session = {};
      state.token = '';
    });
  },
});

export const { storeSession, storeJWTToken } = sessionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSession = (state: RootState) => state.session.session;
export const selectToken = (state: RootState) => state.session.token;

export default sessionSlice.reducer;
