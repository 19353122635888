import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider, Fab, Grid, Stack } from '@mui/material';
import { Send, Clear, Close } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';

export default function PickTeamPlayerNumberModal(props: { open: any; handleClose: any; team_id: string; team_player_id: string; loading?: boolean }) {
  const ADD_SPORT_PLAYERS_TO_SPORT_TEAM_MUTATION = gql`
    mutation pickJerseyNumberBySportPlayerID($team_player: inputPickJerseyNumberBySportPlayerID!) {
      pickJerseyNumberBySportPlayerID(team_player: $team_player)
    }
  `;

  const FIND_SPORT_TEAM_BY_TEAM_ID_QUERY = gql`
    query findSportTeamByTeamID($team: inputFindSportTeamByTeamID!) {
      findSportTeamByTeamID(team: $team) {
        ... on FootballTeam {
          id
          type
          players {
            id
            team_number
            player {
              ... on Footballer {
                id
                alias
              }
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const [team, setTeam] = React.useState(UNDEFINED_AS_ANY);

  const { watch, setValue } = useForm({
    defaultValues: {
      jersey_number: -1,
    },
  });
  const inputFields = watch();

  const [pickJerseyNumberBySportPlayerID, updatedTeamPlayer] = useMutation(ADD_SPORT_PLAYERS_TO_SPORT_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportTeam = useQuery(FIND_SPORT_TEAM_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team: {
        id: props.team_id,
      },
    },
  });

  const ALL_NUMBERS = _.range(100)?.filter(
    (n: number) =>
      !team?.players
        ?.map((p: any) => p?.team_number)
        ?.filter((n: any) => n)
        ?.find((i: any) => i === n)
  );

  const handleJerseyNumber = async (number: number) => {
    setValue('jersey_number', number);
  };

  const handleAddNewTeamPlayer = async () => {
    try {
      await pickJerseyNumberBySportPlayerID({
        variables: {
          team_player: {
            id: props.team_player_id,
            jersey_number: inputFields.jersey_number,
            team_id: team?.id,
            team_sport: team?.__typename,
          },
        },
      });

      setValue('jersey_number', -1);
      props.handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && !team && loadedSportTeam.data) {
      setTeam(loadedSportTeam.data?.findSportTeamByTeamID);
    }

    if (!props.open && team) {
      setTeam(UNDEFINED_AS_ANY);
      setValue('jersey_number', -1);
    }
  }, [props, team, loadedSportTeam, setValue]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={props.handleClose}
        disabled={updatedTeamPlayer.loading}
        className={updatedTeamPlayer.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Choose your number</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid className={'jersey-number'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
            <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
              {ALL_NUMBERS?.map((number: number) => (
                <Button key={number} value={number} onClick={() => handleJerseyNumber(number)} sx={{ display: 'flex', alignItems: 'center', fontWeight: '700', fontSize: '25px', height: '60px', width: '60px', m: '0 10px 0', borderRadius: '10px', border: inputFields.jersey_number === number ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)', color: inputFields.jersey_number === number ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' }}>
                  {number}
                </Button>
              ))}
            </Stack>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton onClick={handleAddNewTeamPlayer} className={inputFields.jersey_number < 0 ? 'button-disabled' : 'button-green'} disabled={inputFields.jersey_number < 0} loadingPosition="end" endIcon={<Send />} loading={updatedTeamPlayer.loading || props.loading}>
          Pick number {inputFields.jersey_number > -1 ? inputFields.jersey_number : ''}
        </LoadingButton>
        <Button onClick={props.handleClose} disabled={updatedTeamPlayer.loading} className={updatedTeamPlayer.loading ? 'button-disabled' : 'button-cancel'} endIcon={<Clear />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PickTeamPlayerNumberModal.propTypes = {
  team_id: PropTypes.any,
  open: PropTypes.any,
  handleClose: PropTypes.any,
  loading: PropTypes.bool,
};

PickTeamPlayerNumberModal.defaultProps = {
  loading: false,
};
