import React from 'react';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { ListItem, ListItemButton, FormLabel, FormControl, SwipeableDrawer, List, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import EditMessageModal from './EditMessageModal';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';

function MessageOptionsDrawer(props: { open: boolean; handleClose: any; message: any }) {
  const DELETE_MESSAGE_MUTATION = gql`
    mutation deleteMessage($message: inputDeleteMessage!) {
      deleteMessage(message: $message)
    }
  `;

  const session = useAppSelector(selectSession);
  const [drawer, setDrawer] = React.useState({ bottom: props.open });
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const time_limit = 5000 * 60000;
  const [deleteMessage, deletedMessage] = useMutation(DELETE_MESSAGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const toggleDrawer = (anchor: 'bottom', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const action: any = event;
    if (action && action.type === 'keydown' && (action.key === 'Tab' || action.key === 'Shift')) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleClose = () => {
    setOpenEdit(false);
    setOpenDelete(false);
    setDrawer({ bottom: false });
    props.handleClose();
  };

  const handleDeleteMessage = async () => {
    try {
      await deleteMessage({
        variables: {
          message: {
            id: props.message?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  return (
    <>
      <SwipeableDrawer
        anchor={'bottom'}
        open={props.open}
        onClose={handleClose}
        onOpen={toggleDrawer('bottom', true)}
        PaperProps={{
          sx: {
            borderTopRightRadius: '25px',
            borderTopLeftRadius: '25px',
          },
        }}
        ModalProps={{
          keepMounted: false,
          sx: { backdropFilter: 'blur(8px)' },
        }}
      >
        <List sx={{ p: '30px 0 10px' }}>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel component="legend" sx={{ p: '0 5% 20px', textAlign: 'center' }}>
              <Typography variant="caption" sx={{ fontSize: '.8em', width: '100%', display: 'inline-block' }}>
                Message:
              </Typography>
              {_.truncate(props?.message?.content)}
            </FormLabel>
            <ListItem>
              <ListItemButton onClick={() => setOpenEdit(true)} className={props?.message?.created_at + time_limit < Date.now() || deletedMessage.loading ? 'button-disabled' : ''} disabled={props?.message?.created_at + time_limit < Date.now() || deletedMessage.loading}>
                <Edit />
                &nbsp;Edit
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => setOpenDelete(true)} className={props?.message?.created_at + time_limit < Date.now() || deletedMessage.loading ? 'button-disabled' : 'button-red-reversed'} disabled={props?.message?.created_at + time_limit < Date.now() || deletedMessage.loading}>
                <Delete />
                &nbsp;Delete
              </ListItemButton>
            </ListItem>
          </FormControl>
        </List>

        <EditMessageModal open={openEdit} message_id={props?.message?.id} content={props?.message?.content} handleClose={handleClose} />
        <DeleteConfirmationModal title={`Remove message`} open={openDelete} entityId={props?.message?.id} entityName={`${_.truncate(props?.message?.content, { length: 5 })}`} actionOne={handleDeleteMessage} actionOneTitle={'Delete'} actionTwo={handleClose} actionTwoTitle={'Cancel'} loading={deletedMessage.loading} />
      </SwipeableDrawer>
    </>
  );
}

export default MessageOptionsDrawer;
