import React from 'react';
import { Alert, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { gql, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

const EmailVerificationAlert: React.FC = () => {
  const SEND_PLAYER_VERIFICATION_EMAIL_MUTATION = gql`
    mutation sendPlayerVerificationEmail($player: inputEmailVerification!) {
      sendPlayerVerificationEmail(player: $player)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();
  const [sendPlayerVerificationEmail, sentPlayerEmailVerification] = useMutation(SEND_PLAYER_VERIFICATION_EMAIL_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const resendPlayerVerificationEmail = async () => {
    try {
      await sendPlayerVerificationEmail({
        variables: {
          player: {
            email: loggedPlayer?.email?.address,
            sender: {
              id: loggedPlayer?.id,
              firstname: loggedPlayer?.owner?.firstname,
              lastname: loggedPlayer?.owner?.lastname,
            },
            host: window.location.origin,
            language: loggedPlayer?.app_configuration?.prefered_language,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loggedPlayer?.email?.status === 'NOT_VERIFIED' ? (
        <Alert
          severity="warning"
          sx={{
            'display': { xs: 'inline-block', sm: 'flex' },
            'textAlign': 'center',
            '& .MuiAlert-icon': { display: { xs: 'block', sm: 'flex' } },
            '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' } },
          }}
          action={
            <LoadingButton size="small" onClick={resendPlayerVerificationEmail} endIcon={<Send />} disabled={sentPlayerEmailVerification.called || sentPlayerEmailVerification.loading} loading={sentPlayerEmailVerification.loading} loadingPosition="end" loadingIndicator={<CircularProgress size={20} className="green-loader" />} sx={{ 'bgcolor': 'rgba(255,161,46, .8)', 'fontWeight': '700', 'boxShadow': 'none', '&:hover': { bgcolor: 'rgba(255,161,46, 1) !important' } }}>
              {sentPlayerEmailVerification.loading ? `${intl.formatMessage({ id: 'Global_Activate_Email_Button_1' })}` : sentPlayerEmailVerification.called ? `${intl.formatMessage({ id: 'Global_Activate_Email_Button_2' })}` : `${intl.formatMessage({ id: 'Global_Activate_Email_Button_3' })}`}
            </LoadingButton>
          }
        >
          {`${intl.formatMessage({ id: 'Global_Activate_Email_Label_1' })}`}&nbsp;({loggedPlayer?.email?.address})&nbsp;{`${intl.formatMessage({ id: 'Global_Activate_Email_Label_2' })}`}
        </Alert>
      ) : (
        ''
      )}
    </>
  );
};

EmailVerificationAlert.propTypes = {};

export default EmailVerificationAlert;
