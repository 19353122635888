import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Fab, ListItemSecondaryAction, ListItemAvatar, ListItemText, ListItem, List, Avatar, Grid, Typography, IconButton } from '@mui/material';
import { BlockedListIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { Link } from 'react-router-dom';
import MenuButton from '../../component-modals/layout/MenuButton';

const BlockedList: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);

  const handleUnlockUser = (userId: any) => {
    // REPLACE BY API CALL TO CHANGE STATUS OF USER (FROM BLOCKED TO NORMAL)
    console.log('Unlock userId', userId);
  };

  return (
    <Grid container className={'component-preferences settings-page blocked-list-page'}>
      <Grid item xl={12} xs={12} sm={12}>
        <Grid container>
          <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left', p: { xs: '20px 0px', sm: '20px 43px' } }}>
            <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
          </Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
            </IconButton>
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
        </Grid>

        <Grid sx={{ width: '70%', m: 'auto', p: '40px 0 100px' }}>
          <Grid className={'title'} item xl={12} xs={12} sm={12}>
            <Typography variant="h1" sx={{ fontSize: '2em', fontWeight: 'bold', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)' }}>
              Blocked List
            </Typography>
          </Grid>
          <Grid className={'user-list'} item xl={12} xs={12} sm={12}>
            <List>
              {loggedPlayer?.contacts?.map((user: any) => (
                <ListItem key={user.id}>
                  <ListItemAvatar>
                    <Avatar alt={user.username} src={user.avatar.path} />
                  </ListItemAvatar>
                  <ListItemText id={user.id} primary={`${user.firstname} ${user.lastname}`} />
                  <ListItemSecondaryAction>
                    <Fab variant="extended" onClick={() => handleUnlockUser(user.id)}>
                      <BlockedListIcon />
                      Unblock {user.firstname}
                    </Fab>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlockedList;
