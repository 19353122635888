import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { FivezerLogoIcon, LogoFaceBlackIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import LoginForm from '../../component-modals/profile/LoginForm';
import TopBar from '../../component-modals/layout/TopBar';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectPlayer } from '../../reducers/playerSlice';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LoginPage: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const navigate = useNavigate();
  const intl = useIntl();

  React.useEffect(() => {
    if (loggedPlayer?.id) {
      navigate('/profile', { replace: true });
    }
  }, []);

  return (
    <Grid className={'form-page component-profile'} sx={{ bgcolor: 'rgba(15,15,15,1)', padding: '0 0 150px 0', height: '100%' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'Global_Meta_Title' })}`}</title>
        <meta name="description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta name="linkedin:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta name="linkedin:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta name="twitter:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta name="twitter:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta property="og:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta property="og:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <TopBar
        position={'static'}
        backgroundColor={'transparent'}
        color={'#000'}
        logo={
          <LogoFaceBlackIcon
            sx={{
              display: 'none',
              height: 'auto',
              width: { xs: '10%', sm: '10%', md: '10%' },
              margin: 'auto',
            }}
          />
        }
      />

      <Grid id="max-width" sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid className={'logo'} sx={{ padding: '0 10%', textAlign: 'center' }}>
            {loggedPlayer?.app_configuration?.previous_player?.username ? (
              <Avatar
                className="avatar"
                imgProps={{
                  referrerPolicy: 'no-referrer',
                }}
                alt={loggedPlayer?.app_configuration?.previous_player?.username}
                src={loggedPlayer?.app_configuration?.previous_player?.avatar?.path}
                sx={{
                  m: 'auto',
                  width: { xs: 100, sm: 130, md: 160, lg: 250 },
                  height: { xs: 100, sm: 130, md: 160, lg: 250 },
                  borderWidth: '6px',
                  bgcolor: 'rgba(247, 251, 250, .3)',
                }}
              />
            ) : (
              <FivezerLogoIcon
                sx={{
                  height: { xs: '50px', sm: '60px', md: '80px', lg: '80px' },
                  width: 'auto',
                  m: '0 auto 30px',
                  color: 'rgba(199, 240, 11, 1)',
                }}
              />
            )}
          </Grid>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
              fontWeight: '100 !important',
              display: 'inline-block',
              padding: '0 10% 50px',
              textAlign: 'center',
              width: '100%',
            }}
            className={'title'}
          >
            <FormattedMessage id="LoginPage_Hello" />
            &nbsp;
            <Box sx={{ fontWeight: '800', display: 'inline-block' }}>{loggedPlayer?.app_configuration?.previous_player?.username ? <>{loggedPlayer?.app_configuration?.previous_player?.username}</> : <FormattedMessage id="LoginPage_Friend" />}</Box>
            <br />
            <FormattedMessage id="LoginPage_Welcome_Back" />
          </Typography>
          <Grid
            className={'padding-left padding-right'}
            sx={{
              m: 'auto',
              width: { xs: '90%', sm: '80%', md: '70%' },
            }}
          >
            <LoginForm destination="/" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
