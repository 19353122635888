import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CardMedia } from '@mui/material';

export default function Document(props: { url: any; scale?: string }) {
  const [isPDF, setIsPDF] = React.useState(false);

  return (
    <Grid container sx={{ justifyContent: 'center', minHeight: isPDF ? { xs: '500px', sm: '200px' } : '', height: isPDF ? '100%' : 'inherit' }}>
      {isPDF ? <iframe title={props.url} src={props.url + '#toolbar=0&view=fitH'} height="100%" width="100%" allowFullScreen={true} style={{ border: 'none', width: '100%' }}></iframe> : <CardMedia component="img" sx={{ transform: `scale(${props?.scale})` }} image={props.url} alt={props.url} onError={() => setIsPDF(true)} />}
    </Grid>
  );
}

Document.propTypes = {
  colorType: PropTypes.string,
};
