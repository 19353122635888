import React from 'react';
import PropTypes from 'prop-types';
import { Fab, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import ListConversationsMessenger from './ListConversationsMessenger';
import NewConversationMessengerModal from './NewConversationMessengerModal';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import ConversationMessenger from './ConversationMessenger';
import ViewParticipantMessenger from './ViewParticipantMessenger';

interface IMessengerModal {
  handleClose: any;
  open: boolean;
  new_conversation?: {
    title: string;
    author_id: string;
    mandatory_participants: { id: string; name: string; label: string; avatar: string }[];
    participants: { id: string; avatar: string }[];
  };
}

const MessengerModal: React.FC<IMessengerModal> = (props: IMessengerModal) => {
  const [isNewConversation, setIsNewConversation] = React.useState(UNDEFINED_AS_ANY);
  const [isViewConversations, setIsViewConversations] = React.useState(UNDEFINED_AS_ANY);
  const [isViewConversation, setIsViewConversation] = React.useState(UNDEFINED_AS_ANY);
  const [selectedConversation, setSelectedConversation] = React.useState(UNDEFINED_AS_ANY);
  const [selectedParticipant, setSelectedParticipant] = React.useState(UNDEFINED_AS_ANY);
  const [openViewParticipant, setOpenViewParticipant] = React.useState(false);

  const handleClose = () => {
    props.handleClose();
  };

  const handleCloseNewConversation = () => {
    setIsNewConversation(false);
    setIsViewConversation(false);
    setIsViewConversations(true);
  };

  const handleOpenConversation = (conversation_id: string) => {
    setSelectedConversation(conversation_id);
    setIsViewConversation(true);
    setIsViewConversations(false);
    setIsNewConversation(false);
  };

  const handleCloseConversation = () => {
    setSelectedConversation(UNDEFINED_AS_ANY);
    setIsViewConversation(false);
    setIsViewConversations(true);
    setIsNewConversation(false);
  };

  const handleOpenViewParticipant = (id: string, type: string) => {
    setSelectedParticipant({ id: id, type: type });
    setOpenViewParticipant(true);
  };

  const handleCloseViewParticipant = () => {
    setSelectedParticipant(UNDEFINED_AS_ANY);
    setOpenViewParticipant(false);
  };

  React.useEffect(() => {
    if (props.open && isNewConversation === UNDEFINED_AS_ANY) {
      setIsNewConversation(props.new_conversation ? true : false);
      setIsViewConversations(props.new_conversation ? false : true);
      setIsViewConversation(false);
    }

    if (!props.open && isNewConversation !== UNDEFINED_AS_ANY) {
      setIsNewConversation(UNDEFINED_AS_ANY);
    }
  }, [props, isNewConversation]);

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-transaction-confirmation-modal modal-feature'} PaperProps={{ sx: { p: '0', minWidth: { xs: '70%', md: '60%' }, maxWidth: { xs: '90%', md: '70%' }, minHeight: '40vh' } }} sx={{ '& .MuiDialog-container': { alignItems: 'baseline' } }} keepMounted={false}>
      <DialogTitle sx={{ p: '0' }}>
        <Fab
          size="small"
          onClick={handleClose}
          className="button-cancel"
          sx={{
            boxShadow: 'none',
            bgcolor: 'transparent',
            ml: '2%',
          }}
        >
          <Close />
        </Fab>
      </DialogTitle>
      <DialogContent sx={{ p: '0' }}>
        {isViewConversations ? <ListConversationsMessenger handleOpenConversation={handleOpenConversation} handleViewParticipant={handleOpenViewParticipant} /> : ''}
        {isNewConversation ? <NewConversationMessengerModal new_conversation={props.new_conversation} handleClose={handleCloseNewConversation} handleOpenConversation={handleOpenConversation} /> : ''}
        {isViewConversation ? <ConversationMessenger conversation_id={selectedConversation} handleCloseConversation={handleCloseConversation} handleViewParticipant={handleOpenViewParticipant} /> : ''}

        <ViewParticipantMessenger open={openViewParticipant} selected_participant={selectedParticipant} handleClose={handleCloseViewParticipant} handleOpen={() => setOpenViewParticipant(true)} />
      </DialogContent>
    </Dialog>
  );
};

MessengerModal.propTypes = {
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

MessengerModal.defaultProps = {};

export default React.memo(MessengerModal);
