import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery, gql } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider, TextField, MenuItem, Chip, Rating, Stack, CardMedia } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { MOODS } from '../../utilities/utilities';
import { StarIcon } from '../../img/icons/Icons';
import { selectSession } from '../../reducers/sessionSlice';

export default function GiveSportSessionFeedbackModal(props: { challenge_short_id: string }) {
  const FIND_CHALLENGE_BY_ID_QUERY = gql`
    query findChallengeByID($challenge: inputFindChallengeByID!) {
      findChallengeByID(challenge: $challenge) {
        id
        complex {
          id
        }
        arena {
          id
          name
          rank
          pictures {
            path
          }
          feedbacks {
            id
          }
        }
        booking {
          id
          feedbacks {
            id
            author {
              ... on Player {
                __typename
                id
              }
            }
          }
          consumers {
            id
            payment_status
            transaction {
              id
              status
              tx_reference
              note_reference
              execution_date
              confirmed_at
            }
            player {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const FIND_FEEDBACKS_BY_SESSION_SPORT_ID_QUERY = gql`
    query findFeedbacksBySportSessionID($sport_session: inputFindFeedbacksBySportSessionID!) {
      findFeedbacksBySportSessionID(sport_session: $sport_session) {
        id
      }
    }
  `;

  const CREATE_FEEDBACK_MUTATION = gql`
    mutation createFeedback($feedback: inputCreateFeedback!) {
      createFeedback(feedback: $feedback)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);
  const [open, setOpen] = React.useState(UNDEFINED_AS_ANY);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selected_mood: 'NEUTRAL',
      rank: 2.5,
      comments: ARRAY_AS_ANY,
      additional_comment: '',
      field_rank: 2.5,
      facility_rank: 2.5,
    },
  });
  const inputFields = watch();

  const [createFeedback, newFeedback] = useMutation(CREATE_FEEDBACK_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const loadedChallenge = useQuery(FIND_CHALLENGE_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        short_id: props.challenge_short_id,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });
  const loadedFeedbacks = useQuery(FIND_FEEDBACKS_BY_SESSION_SPORT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      sport_session: {
        id: challenge?.booking?.id,
      },
    },
  });

  const ALL_FEEDBACKS = challenge?.booking?.feedbacks;
  const MOOD_MARKS = [
    {
      value: 0,
      label: 'A nightmare...',
    },
    {
      value: 0.5,
      label: 'Very bad...',
    },
    {
      value: 1,
      label: 'Annoying',
    },
    {
      value: 1.5,
      label: 'Bad',
    },
    {
      value: 2,
      label: 'Not good',
    },
    {
      value: 2.5,
      label: 'Ok',
    },
    {
      value: 3,
      label: 'Fine',
    },
    {
      value: 3.5,
      label: 'Nice',
    },
    {
      value: 4,
      label: 'Pleasant',
    },
    {
      value: 4.5,
      label: 'Very Good!',
    },
    {
      value: 5,
      label: 'Perfect!',
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setChallenge(UNDEFINED_AS_ANY);
  };

  const handleAddComment = (value: string) => {
    const newList = _.concat(value, inputFields.comments);
    setValue('comments', newList);
  };

  const handleRemoveComment = (value: string) => {
    const newList = inputFields.comments?.filter((c: string) => c !== value);
    setValue('comments', newList);
  };

  const handleChangeMood = (value: any) => {
    setValue('selected_mood', value);
    if (inputFields.selected_mood !== value) {
      setValue('comments', ARRAY_AS_ANY);
    }
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedChallenge.refetch({
        challenge: {
          short_id: props.challenge_short_id,
          logged_player_id: loggedPlayer?.id,
        },
      });

      if (result) {
        setChallenge(result.data?.findChallengeByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitFeedback = async () => {
    try {
      await createFeedback({
        variables: {
          feedback: {
            title: `Feedback from ${loggedPlayer?.username}`,
            mood: inputFields?.selected_mood,
            rank: parseFloat(inputFields?.rank?.toString()),
            comments: inputFields?.comments,
            additional_comment: inputFields?.additional_comment,
            author_id: loggedPlayer?.id,
            subject_id: challenge?.booking?.id,
            field: {
              id: challenge?.arena?.id,
              rank: (inputFields.field_rank + challenge?.arena?.rank) / 2,
            },
          },
        },
      });

      await handleRefetch();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (open === undefined && !challenge && loadedChallenge.called && loadedChallenge.data?.findChallengeByID) {
      setChallenge(loadedChallenge.data?.findChallengeByID);
    }

    if (open === false && challenge) {
      setChallenge(UNDEFINED_AS_ANY);
    }
  }, [challenge, loadedChallenge, setChallenge, open]);

  React.useEffect(() => {
    if (!loadedFeedbacks?.error && challenge && open === undefined && !ALL_FEEDBACKS?.find((f: any) => f?.author?.id === loggedPlayer?.id)) {
      setOpen(true);
    }

    if (challenge && open && ALL_FEEDBACKS?.find((f: any) => f?.author?.id === loggedPlayer?.id)) {
      setOpen(false);
    }
  }, [loadedFeedbacks, ALL_FEEDBACKS, inputFields, setValue, open, setOpen]);

  return (
    <Dialog open={true || open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'}>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontSize: '1.1em' }}>Your feedback on your game</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent className={'form-page'} sx={{ bgcolor: 'transparent', minHeight: 'auto' }}>
        <form>
          <Controller
            name="rank"
            control={control}
            rules={{
              required: true,
              min: 0,
              max: 5,
            }}
            render={({ field }: any) => (
              <Grid container sx={{ justifyContent: 'center' }}>
                <Rating
                  {...field}
                  name="hover-feedback"
                  value={field.value}
                  precision={0.5}
                  size="large"
                  onChange={(event: any, newValue: any) => {
                    setValue('rank', newValue ? newValue : 0);
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                  emptyIcon={<StarIcon style={{ opacity: 0.2 }} fontSize="inherit" />}
                />
                {field.value !== null && (
                  <Grid container sx={{ justifyContent: 'center' }}>
                    My sport experience was&nbsp;<strong>{MOOD_MARKS?.find((m: any) => m?.value === field.value)?.label?.toLowerCase()}</strong>
                  </Grid>
                )}
              </Grid>
            )}
          />

          <Grid sx={{ 'textAlign': 'center', 'mt': '30px', '& svg': { fontSize: '6em' } }}>{MOODS?.find((m: any) => m?.value === inputFields.selected_mood)?.icon}</Grid>

          <Grid container sx={{ p: '30px 10% 0' }}>
            <Controller
              control={control}
              name="selected_mood"
              render={({ field }: any) => (
                <TextField
                  {...field}
                  value={field.value}
                  select
                  label="Current Mood"
                  variant="outlined"
                  SelectProps={{
                    sx: {
                      '& fieldset': {
                        borderColor: 'rgba(247, 251, 250, 0.3) !important',
                      },
                    },
                    MenuProps: { sx: { '& li .MuiGrid-root': { justifyContent: 'start', fontWeight: '500' } } },
                  }}
                  error={errors?.selected_mood !== undefined}
                  required
                  fullWidth
                  className={'field-bottom-space'}
                >
                  {MOODS?.map((option) => (
                    <MenuItem key={option.value} value={option.value} onClick={() => handleChangeMood(option.value)}>
                      <Grid container sx={{ justifyContent: 'center', fontWeight: '700' }}>
                        {option.icon}&nbsp;&nbsp;
                        {option.label}
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid container sx={{ m: '10px 0 30px', justifyContent: 'center' }}>
            {MOODS?.find((m: any) => m?.value === inputFields.selected_mood)?.suggestions?.map((sentence: string, index: number) => (
              <Chip
                key={index}
                label={sentence}
                sx={{
                  'm': '10px 5px',
                  'bgcolor': inputFields.comments?.find((c: string) => c === sentence) ? 'rgba(199, 240, 11, .3)' : '',
                  'color': inputFields.comments?.find((c: string) => c === sentence) ? '#FFF' : '',
                  'fontWeight': inputFields.comments?.find((c: string) => c === sentence) ? '700' : '',
                  '&:hover': {
                    bgcolor: inputFields.comments?.find((c: string) => c === sentence) ? 'rgba(199, 240, 11, .3)' : '',
                  },
                }}
                onClick={() => handleAddComment(sentence)}
                onDelete={inputFields.comments?.find((c: string) => c === sentence) ? () => handleRemoveComment(sentence) : undefined}
              />
            ))}
          </Grid>

          <Grid container sx={{ overflow: 'auto', justifyContent: 'center' }}>
            <Stack direction="row">
              {challenge?.arena?.pictures?.map((p: any, index: number) => (
                <CardMedia
                  key={index}
                  component="img"
                  src={p?.path}
                  sx={{
                    height: '100px',
                    m: '0 10px 10px',
                  }}
                />
              ))}
            </Stack>
          </Grid>
          <Controller
            name="field_rank"
            control={control}
            rules={{
              required: true,
              min: 0,
              max: 5,
            }}
            render={({ field }: any) => (
              <Grid container sx={{ justifyContent: 'center' }}>
                <Rating
                  {...field}
                  name="hover-feedback"
                  value={field.value}
                  precision={0.5}
                  size="large"
                  onChange={(event: any, newValue: any) => {
                    setValue('field_rank', newValue ? newValue : 0);
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                  emptyIcon={<StarIcon style={{ opacity: 0.2 }} fontSize="inherit" />}
                />
                {field.value !== null && (
                  <Grid container sx={{ justifyContent: 'center' }}>
                    {challenge?.arena?.name} was&nbsp;<strong>{MOOD_MARKS?.find((m: any) => m?.value === field.value)?.label?.toLowerCase()}</strong>
                  </Grid>
                )}
              </Grid>
            )}
          />

          <Controller
            name="additional_comment"
            control={control}
            defaultValue=""
            rules={{
              required: false,
              maxLength: 255,
              minLength: 4,
              pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._[]\s-]{5,60}$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="Suggestions"
                placeholder="Tell us how we make it better for you..."
                multiline
                rows={3}
                fullWidth
                error={inputFields.additional_comment.length > 255}
                helperText={inputFields.additional_comment.length > 240 ? (inputFields.additional_comment.length < 256 ? `${255 - inputFields.additional_comment.length} ${240 >= inputFields.additional_comment.length ? 'characters' : 'character'} left` : `You must remove ${inputFields.additional_comment.length - 255} ${inputFields.additional_comment.length <= 256 ? 'character' : 'characters'}`) : ''}
                className={'field-bottom-space'}
                sx={{
                  'mt': '20px',
                  '& fieldset': {
                    borderColor: 'rgba(247, 251, 250, 0.3) !important',
                  },
                }}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton onClick={handleSubmitFeedback} disabled={inputFields.additional_comment === '' && inputFields?.comments?.length === 0} className={inputFields.additional_comment === '' && inputFields?.comments?.length === 0 ? 'button-disabled' : 'button-green'} loadingPosition="end" endIcon={<Send />} loading={newFeedback.loading}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

GiveSportSessionFeedbackModal.propTypes = {
  entity: PropTypes.any,
  open: PropTypes.bool,
};

GiveSportSessionFeedbackModal.defaultProps = {};
