import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tabs, Tab, Avatar, Button, Grid, Divider, Typography, Drawer, Stack, Card, CardHeader } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

function FollowersDrawer(props: { open: boolean; trigger: any; followers?: any; followings?: any; handleRefetch?: any; index: number }) {
  const FOLLOW_ENTITY_MUTATION = gql`
    mutation followEntity($request: inputEntityFollowing!) {
      followEntity(request: $request)
    }
  `;

  const UNFOLLOW_ENTITY_MUTATION = gql`
    mutation unfollowEntity($request: inputEntityFollowing!) {
      unfollowEntity(request: $request)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [drawer, setDrawer] = React.useState({ right: props.open });
  const [value, setValue] = React.useState(UNDEFINED_AS_ANY);

  const [followEntity, followedEntity] = useMutation(FOLLOW_ENTITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unfollowEntity, unfollowedEntity] = useMutation(UNFOLLOW_ENTITY_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const ALL_FOLLOWERS = props.followers ? props.followers : loggedPlayer?.followers;
  const ALL_FOLLOWINGS = props.followings ? props.followings : loggedPlayer?.followings;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const toggleDrawer = (anchor: 'right', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const action: any = event;

    if (action && action.type === 'keydown' && (action.key === 'Tab' || action.key === 'Shift')) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
    props.trigger(open);
  };

  const handleFollow = async (toFollow: any) => {
    if (toFollow && toFollow?.id !== loggedPlayer?.id) {
      await followEntity({
        variables: {
          request: {
            follower_id: loggedPlayer?.id,
            follower_type: loggedPlayer?.__typename,
            followed_id: toFollow.id,
          },
        },
      });

      if (props.handleRefetch) {
        await props.handleRefetch();
      }
    }
  };

  const handleUnfollow = async (toUnfollow: any) => {
    if (toUnfollow && toUnfollow?.id !== loggedPlayer?.id) {
      await unfollowEntity({
        variables: {
          request: {
            follower_id: loggedPlayer?.id,
            follower_type: loggedPlayer?.__typename,
            followed_id: toUnfollow.id,
          },
        },
      });

      if (props.handleRefetch) {
        await props.handleRefetch();
      }
    }
  };

  React.useEffect(() => {
    if (value === undefined && props.open) {
      setValue(props.index);
    }

    if (value !== undefined && !props.open) {
      setValue(UNDEFINED_AS_ANY);
    }
  }, [props, value]);

  return (
    <Drawer anchor={'right'} open={props.open} onClose={toggleDrawer('right', false)} /*onOpen={toggleDrawer('right', true)}*/ PaperProps={{ sx: { borderTopLeftRadius: '15px', bgcolor: 'rgba(15,15,15,1)', color: '#FFF', maxWidth: '70vw' } }} ModalProps={{ sx: { backdropFilter: 'blur(8px)', zIndex: '1000000000' } }}>
      <Grid className={`${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`} sx={{ 'minWidth': '200px', 'maxWidth': '100%', '& .react-swipeable-view-container': { minHeight: '100vh' } }}>
        <AppBar position="sticky" sx={{ bgcolor: 'rgba(33, 36, 42, 1)', boxShadow: 'none' }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label={`${intl.formatMessage({ id: 'Global_Followers' })}`} {...a11yProps(0)} />
            {props.followings === null ? '' : <Tab label={`${intl.formatMessage({ id: 'Global_Followings' })}`} {...a11yProps(1)} />}
          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={'rtl'}>
            <Stack direction="column" sx={{ p: '30px 0 10px', width: '100%' }}>
              {ALL_FOLLOWERS?.length === 0 ? (
                <Typography sx={{ fontSize: '1.3em', fontWeight: '100', textAlign: 'center', color: 'rgba(247, 251, 250, 0.3)' }}>No followers</Typography>
              ) : (
                <>
                  {ALL_FOLLOWERS?.map((u: any) => (
                    <Card key={u?.id} sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                      <CardHeader
                        avatar={
                          <Avatar
                            alt={u?.username || u?.label}
                            src={u?.avatar?.path || u?.logo?.path}
                            sx={{
                              m: { xs: 'auto', sm: '0' },
                              textDecoration: 'none',
                              textTransform: 'uppercase',
                            }}
                            component={Link}
                            onClick={() => props.trigger(false)}
                            to={u?.__typename === 'FootballTeam' ? `/football_team/${u?.short_id}` : u?.username ? `/u/${u?.username}` : `/${u?.name}`}
                          />
                        }
                        title={
                          <Typography
                            component={Link}
                            onClick={() => props.trigger(false)}
                            to={u?.__typename === 'FootballTeam' ? `/football_team/${u?.short_id}` : u?.username ? `/u/${u?.username}` : `/${u?.name}`}
                            sx={{
                              textDecoration: 'none',
                              fontWeight: '800',
                              color: 'rgba(247, 251, 250,1)',
                            }}
                          >
                            {u?.username || u?.label || u?.name}
                          </Typography>
                        }
                        action={
                          loggedPlayer?.id === u?.id ? (
                            <Button disabled></Button>
                          ) : (
                            <>
                              {ALL_FOLLOWINGS?.find((f: any) => f?.id === u?.id) ? (
                                <Button size="small" disabled={unfollowedEntity.loading} className={unfollowedEntity.loading ? 'button-disabled' : 'button-green-reverse'} aria-label="follow" onClick={() => handleUnfollow(u)} sx={{ fontSize: '.6em', border: 'none !important' }}>
                                  {`${intl.formatMessage({ id: 'Global_Button_Unfollow' })}`}
                                </Button>
                              ) : (
                                <Button size="small" disabled={followedEntity.loading} className={followedEntity.loading ? 'button-disabled' : 'button-green-reverse'} aria-label="follow" onClick={() => handleFollow(u)} sx={{ fontSize: '.6em', border: 'none !important' }}>
                                  {`${intl.formatMessage({ id: 'Global_Button_Follow' })}`}
                                </Button>
                              )}
                            </>
                          )
                        }
                        sx={{
                          '& .MuiCardHeader-content': {
                            pr: { xs: '0', sm: '10px' },
                            textAlign: { xs: 'center', sm: 'right' },
                          },
                          '& .MuiCardHeader-action': {
                            alignSelf: 'center',
                            justifyContent: 'center',
                            display: { xs: 'flex', sm: 'flex' },
                            pt: { xs: '10px', sm: '0' },
                          },
                          '& .MuiCardHeader-avatar': {
                            mr: { xs: '0', sm: '16px' },
                          },
                          'display': { xs: 'block', sm: 'flex' },
                        }}
                      />

                      <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, .1)' }} />
                    </Card>
                  ))}
                </>
              )}
            </Stack>
          </TabPanel>
          {props.followings === null ? (
            ''
          ) : (
            <TabPanel value={value} index={1} dir={'rtl'}>
              <Stack direction="column" sx={{ p: '30px 0 10px', width: '100%' }}>
                {ALL_FOLLOWINGS?.length === 0 ? (
                  <Typography sx={{ fontSize: '1.3em', fontWeight: '100', textAlign: 'center', color: 'rgba(247, 251, 250, 0.3)' }}>No followings</Typography>
                ) : (
                  <>
                    {ALL_FOLLOWINGS?.map((u: any) => (
                      <Card key={u?.id} sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                        <CardHeader
                          avatar={
                            <Avatar
                              alt={u?.username || u?.label}
                              src={u?.avatar?.path || u?.logo?.path}
                              sx={{
                                m: { xs: 'auto', sm: '0' },
                                textDecoration: 'none',
                                textTransform: 'uppercase',
                              }}
                              component={Link}
                              onClick={() => props.trigger(false)}
                              to={u?.__typename === 'FootballTeam' ? `/football_team/${u?.short_id}` : u?.username ? `/u/${u?.username}` : `/${u?.name}`}
                            />
                          }
                          title={
                            <Typography
                              component={Link}
                              onClick={() => props.trigger(false)}
                              to={u?.__typename === 'FootballTeam' ? `/football_team/${u?.short_id}` : u?.username ? `/u/${u?.username}` : `/${u?.name}`}
                              sx={{
                                textDecoration: 'none',
                                fontWeight: '800',
                                color: 'rgba(247, 251, 250,1)',
                              }}
                            >
                              {u?.username || u?.label || u?.name}
                            </Typography>
                          }
                          action={
                            loggedPlayer?.id === u?.id ? (
                              <Button disabled></Button>
                            ) : (
                              <>
                                {ALL_FOLLOWINGS?.find((f: any) => f?.id === u?.id) ? (
                                  <Button size="small" disabled={unfollowedEntity.loading} className={unfollowedEntity.loading ? 'button-disabled' : 'button-green-reverse'} aria-label="follow" onClick={() => handleUnfollow(u)} sx={{ fontSize: '.6em', border: 'none !important' }}>
                                    {`${intl.formatMessage({ id: 'Global_Button_Unfollow' })}`}
                                  </Button>
                                ) : (
                                  <Button size="small" disabled={followedEntity.loading} className={followedEntity.loading ? 'button-disabled' : 'button-green-reverse'} aria-label="follow" onClick={() => handleFollow(u)} sx={{ fontSize: '.6em', border: 'none !important' }}>
                                    {`${intl.formatMessage({ id: 'Global_Button_Follow' })}`}
                                  </Button>
                                )}
                              </>
                            )
                          }
                          sx={{
                            '& .MuiCardHeader-content': {
                              pr: { xs: '0', sm: '10px' },
                              textAlign: { xs: 'center', sm: 'right' },
                            },
                            '& .MuiCardHeader-action': {
                              alignSelf: 'center',
                              justifyContent: 'center',
                              display: { xs: 'flex', sm: 'flex' },
                              pt: { xs: '10px', sm: '0' },
                            },
                            '& .MuiCardHeader-avatar': {
                              mr: { xs: '0', sm: '16px' },
                            },
                            'display': { xs: 'block', sm: 'flex' },
                          }}
                        />

                        <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, .1)' }} />
                      </Card>
                    ))}
                  </>
                )}
              </Stack>
            </TabPanel>
          )}
        </SwipeableViews>
      </Grid>
    </Drawer>
  );
}

export default FollowersDrawer;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
