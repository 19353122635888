import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid, IconButton, Snackbar } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { DownloadAppIcon, ShareIOSIcon } from '../../img/icons/Icons';

const TriggerPWA: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = React.useState<any>(null);
  const [openPWA, setOpenPWA] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(true);
  const [isSafari] = React.useState(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

  const n: any = navigator;
  const isPWAAddedToHomeScreen = window.matchMedia('(display-mode: standalone)').matches;

  React.useEffect(() => {
    if (isSafari && /iPhone|iPad|iPod|iOS|Android/i.test(navigator.userAgent)) {
      // setOpenPWA(false);
      setOpenPWA(isSafari);
    }

    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();
      setDeferredPrompt(event);

      setOpenPWA(n.userAgentData.mobile ? true : false);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsConnected(false);
  };

  const handleTriggerPWA = () => {
    if (deferredPrompt) {
      setOpenPWA(false);
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the installation prompt.');
        } else {
          console.log('User dismissed the installation prompt.');
        }

        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      {!navigator.onLine && (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isConnected} onClose={handleClose}>
          <Alert severity="warning" variant="filled">
            You are offline &nbsp;&nbsp;
            <IconButton size="small" onClick={() => setIsConnected(false)} sx={{ p: '0' }}>
              <Clear sx={{ color: 'rgba(247, 251, 250, 0.3)' }} />
            </IconButton>
          </Alert>
        </Snackbar>
      )}

      {isPWAAddedToHomeScreen ? (
        ''
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={openPWA}
          onClose={() => setOpenPWA(false)}
          message={
            <>
              <IconButton size="small" onClick={() => setOpenPWA(false)} sx={{ p: '0' }}>
                <Clear sx={{ color: 'rgba(247, 251, 250, 0.3)' }} />
              </IconButton>
              &nbsp;
              {isSafari ? (
                <Grid sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <FormattedMessage id="App_Add_PWA_Safari_Tap" />
                  &nbsp;
                  <ShareIOSIcon sx={{ color: 'red' }} />
                  &nbsp;
                  <FormattedMessage id="App_Add_PWA_Safari_Content" />
                </Grid>
              ) : (
                <>
                  &nbsp;
                  <FormattedMessage id="App_Add_PWA_Content" />
                </>
              )}
            </>
          }
          action={
            isSafari ? (
              ''
            ) : (
              <>
                <IconButton className="download-button" onClick={handleTriggerPWA} size="small" sx={{ m: '0 10px', color: 'rgba(247, 251, 250, 1) !important', fontWeight: '700' }}>
                  <DownloadAppIcon />
                </IconButton>
              </>
            )
          }
        />
      )}
    </>
  );
};

TriggerPWA.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.number,
};

export default TriggerPWA;
