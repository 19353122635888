import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import io, { Socket } from 'socket.io-client';
import { Grid, Typography, IconButton, AppBar, Tabs, Tab, Card, Avatar, CardHeader, CircularProgress, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuButton from '../../component-modals/layout/MenuButton';
import { gql, useQuery } from '@apollo/client';
import { selectSession } from '../../reducers/sessionSlice';
import { ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { OndemandVideo, Share } from '@mui/icons-material';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerLogoIcon, LiveIcon, NewsIcon } from '../../img/icons/Icons';
import InfiniteVideoScroll from '../../component-modals/layout/InfiniteVideoScroll';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import Moment from 'react-moment';
import { useIntl } from 'react-intl';
import { REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI } from '../../config';

const Livestreams: React.FC = () => {
  const FIND_LIVESTREAMS_BY_OPTIONS_QUERY = gql`
    query findLivestreamsByOptions($options: inputFindLivestreamsByOptions!) {
      findLivestreamsByOptions(options: $options) {
        id
        title
        room_id
        status
        anonymous_participants
        video_url
        author {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on Position {
            __typename
            id
            role
            company {
              id
              name
              label
              logo {
                path
              }
            }
            employees {
              id
              username
              avatar {
                filename
                path
              }
              owner {
                firstname
              }
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
        }
        participants {
          ... on Company {
            __typename
            id
          }
          ... on User {
            __typename
            id
          }
          ... on Player {
            __typename
            id
          }
        }
        liked_by {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        shared_by {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        comments {
          id
          title
          mood
          content
          likes
          author {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
          created_at
          updated_at
        }
        created_at
      }
    }
  `;

  const FIND_NEWS_BY_OPTIONS_QUERY = gql`
    query findNewsByOptions($options: inputFindNewsByOptions!) {
      findNewsByOptions(options: $options) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const isMobile = useMediaQuery('(max-width:650px)');
  const [replayLivestreams, setReplayLivestreams] = React.useState(ARRAY_AS_ANY);
  const [livestreams, setLivestreams] = React.useState(ARRAY_AS_ANY);
  const [offsetLive, setOffsetLive] = React.useState(0);
  const [offsetReplay, setOffsetReplay] = React.useState(0);
  const socketRef = React.useRef<Socket | null>(null);
  const [value, setValue] = React.useState(0);
  const [news, setNews] = React.useState(ARRAY_AS_ANY);
  const [offset] = React.useState(0);

  if (news?.length === 0) {
    fetch('https://free-football-soccer-videos.p.rapidapi.com/', {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': 'pcSRu8EJskmshvFH2v1xbneOCTItp1AwPMGjsnLaf6MfsLhTJU',
        'X-RapidAPI-Host': 'free-football-soccer-videos.p.rapidapi.com',
      },
    })
      .then((response) => response.json())
      .then((result: any) => setNews(_.slice(result, 0, 21)));
  }

  const loadedNews = useQuery(FIND_NEWS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      options: {
        offset: offset,
      },
    },
  });
  const loadedLivestreams = useQuery(FIND_LIVESTREAMS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      options: {
        city: '',
        country: '',
        app_name: 'FIVEZER',
        status: 'ON_GOING',
        offset: offsetLive,
      },
    },
    pollInterval: 3000,
  });

  const loadedReplayLivestreams = useQuery(FIND_LIVESTREAMS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      options: {
        city: '',
        country: '',
        app_name: 'FIVEZER',
        status: 'ENDED',
        offset: offsetReplay,
      },
    },
    pollInterval: 3000,
  });

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  React.useEffect(() => {
    socketRef.current = io(REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI);
    // socketRef.current = io('http://localhost:5001');

    socketRef.current.on('disconnect', () => {
      console.log('Socket.io disconnected');
    });

    return () => {
      socketRef.current?.disconnect();
    };
  }, []);

  React.useEffect(() => {
    if (livestreams?.length === 0 && loadedLivestreams.data?.findLivestreamsByOptions?.length > 0) {
      setLivestreams(loadedLivestreams.data?.findLivestreamsByOptions);
      setOffsetLive(_.add(offsetLive, loadedLivestreams.data?.findLivestreamsByOptions?.length));
    }
  }, [loadedLivestreams.data?.findLivestreamsByOptions, livestreams]);

  React.useEffect(() => {
    if (replayLivestreams?.length === 0 && loadedReplayLivestreams.data?.findLivestreamsByOptions?.length > 0) {
      setReplayLivestreams(loadedReplayLivestreams.data?.findLivestreamsByOptions);
      setOffsetReplay(_.add(offsetReplay, loadedReplayLivestreams.data?.findLivestreamsByOptions?.length));
    }
  }, [loadedReplayLivestreams.data?.findLivestreamsByOptions, replayLivestreams]);

  return (
    <Grid className={`component-contacts conversation-page ${session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode') : 'dark-mode'}`} sx={{ minHeight: '100vh' }}>
      <Grid container>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'left',
          }}
        ></Grid>
        <Grid
          item
          xl={8}
          xs={8}
          sm={8}
          sx={{
            p: '0',
            position: 'relative',
            zIndex: '1',
            textAlign: 'center',
          }}
        >
          <IconButton component={Link} to="/" sx={{ 'mb': '0%', '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'right',
          }}
        >
          <MenuButton />
        </Grid>
      </Grid>

      <Grid container className={`${session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component') : 'dark-mode-tabs-component'}`}>
        <AppBar position="sticky">
          <Tabs
            value={value}
            onChange={handleChange}
            scrollButtons={false}
            aria-label="scrollable auto tabs example"
            variant={isMobile ? 'scrollable' : 'fullWidth'}
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(31, 175, 146, 1)' : 'rgba(31, 175, 146, 1)',
              },
            }}
          >
            <Tab
              label={`Feeds`}
              icon={<NewsIcon />}
              {...a11yProps(0)}
              style={{
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            />
            <Tab
              label={`Lives`}
              icon={<LiveIcon />}
              {...a11yProps(1)}
              style={{
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            />
            <Tab
              label={`Clips`}
              icon={<OndemandVideo />}
              {...a11yProps(2)}
              style={{
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            />
          </Tabs>
        </AppBar>
      </Grid>
      <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <Grid container>
            {loadedNews.loading ? (
              <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
            ) : (
              <>
                {news?.length === 0 ? ( // || loadedNews.error
                  <Typography
                    variant="h3"
                    sx={{
                      p: '50px 20px 90vh',
                      fontSize: '1.3em',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {`${intl.formatMessage({ id: 'News_No_News_Title' })}`}
                  </Typography>
                ) : (
                  <Grid container sx={{ p: { xs: '0', sm: '0' }, overflowY: 'auto', height: '90vh' }}>
                    {news?.map((n: any, index: number) => (
                      <Grid key={index} item xs={12} sm={6} md={4} sx={{ p: '0', m: '10px 0' }}>
                        <Card sx={{ p: { xs: '0 0 20px', sm: '0 5% 20px' }, borderRadius: '10px', bgcolor: 'rgba(247, 251, 250, 0)', boxShadow: 'none' }}>
                          <CardHeader
                            avatar={<Avatar src={n?.thumbnail} alt={n?.title} />}
                            action={
                              <ShareURLButton
                                button={
                                  <IconButton aria-label="settings">
                                    <Share />
                                  </IconButton>
                                }
                                url={window.location.href}
                                title={n?.title}
                                text={n?.title}
                              />
                            }
                            title={n?.title}
                            titleTypographyProps={{ color: 'rgba(247, 251, 250, 1)', fontWeight: '800' }}
                            subheader={<Moment fromNow>{n?.date}</Moment>}
                            subheaderTypographyProps={{ color: 'rgba(247, 251, 250, .4)' }}
                          />

                          <Grid dangerouslySetInnerHTML={{ __html: n?.videos[0]?.embed }} sx={{ 'height': '70vh', '& iframe': { height: '70vh !important' } }} />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {socketRef.current?.id && livestreams?.length > 0 ? (
            <Grid item xl={12} xs={12} sm={12} sx={{ height: '100%', display: 'inline-block' }}>
              <InfiniteVideoScroll elements={livestreams} />
            </Grid>
          ) : (
            <Typography
              variant="h3"
              sx={{
                width: '100%',
                p: '50px 20px 90vh',
                fontSize: '1.3em',
                textAlign: 'center',
                color: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15, 15, 15, .5)') : 'rgba(247, 251, 250, .3)',
              }}
            >
              No lives
            </Typography>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {replayLivestreams?.length > 0 ? (
            <Grid item xl={12} xs={12} sm={12} sx={{ height: '100%', display: 'inline-block' }}>
              <InfiniteVideoScroll elements={replayLivestreams} />
            </Grid>
          ) : (
            <Typography
              variant="h3"
              sx={{
                width: '100%',
                p: '50px 20px 90vh',
                fontSize: '1.3em',
                textAlign: 'center',
                color: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15, 15, 15, .5)') : 'rgba(247, 251, 250, .3)',
              }}
            >
              No replays
            </Typography>
          )}
        </TabPanel>
      </SwipeableViews>
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default React.memo(Livestreams);
