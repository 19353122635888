import React from 'react';
import Lottie from 'react-lottie-player';
import { Grid } from '@mui/material';

export default function InteractiveAnimation(props: { options: any; height?: any; width?: any; canvasContext?: string }) {
  return (
    <Grid container sx={{ justifyContent: 'center' }}>
      <Lottie loop={props.options?.loop || true} animationData={props.options?.animationData} play={props.options?.autoplay || true} style={{ width: props.width || 100, height: props.height || 100 }} />
    </Grid>
  );
}
