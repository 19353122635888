import React from 'react';
import _ from 'lodash';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { selectPlayer, storePlayer, selectPlayerActiveProfile, storePlayerActiveProfile, storePlayerProfiles, selectPlayerProfiles } from '../../reducers/playerSlice';
import { selectedTeam, selectTeams } from '../../reducers/teamSlice';
import { storePersonalWallets } from '../../reducers/walletSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { AppContext } from '../../App';

export default function SessionController() {
  try {
    const FIND_PLAYER_BY_ID_QUERY = gql`
      query findPlayerByID($id: ID!) {
        findPlayerByID(id: $id) {
          id
          username
          mobile
          bio
          roles
          permissions
          status
          updated_at
          email {
            address
            status
          }
          profiles {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              avatar {
                path
              }
            }
          }
          manage_teams {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
            }
          }
          contacts {
            ... on Footballer {
              __typename
              id
              alias
            }
            ... on Basketballer {
              __typename
              id
              alias
            }
          }
          kyc_cases {
            id
            reference
            status
            created_at
            audited_at
            incorporation_file {
              id
              filename
              path
            }
            user_front_id {
              id
              filename
              path
            }
            user_back_id {
              id
              filename
              path
            }
            user_id_selfie {
              id
              filename
              path
            }
            user_residential_proof {
              id
              filename
              path
            }
            pid {
              id_number
              type
              status
            }
          }
          avatar {
            id
            filename
            type
            encoding
            size
            path
          }
          cover {
            id
            filename
            type
            encoding
            size
            path
          }
          social_medias {
            id
            name
            handle
            link
          }
          app_configuration {
            id
            is_dark_mode
            two_facts_mode
            prefered_language
          }
          followings {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
              owner {
                id
                firstname
                lastname
              }
            }
          }
          followers {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              avatar {
                path
                filename
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
          owner {
            id
            firstname
            lastname
            date_of_birth
            gender
            emails {
              address
              status
            }
            player_account {
              id
              status
              username
              avatar {
                path
              }
              profiles {
                ... on Footballer {
                  __typename
                  id
                  alias
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                }
              }
              social_medias {
                id
                name
                handle
                link
              }
            }
            complex_owner_account {
              id
              status
              username
              avatar {
                path
              }
              five_complexes {
                id
              }
              social_medias {
                id
                name
                handle
                link
              }
            }
            transporter_account {
              id
              status
              username
              avatar {
                path
              }
            }
            health_worker_account {
              id
              status
              username
              avatar {
                path
              }
            }
          }
          locations {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
      }
    `;

    const FIND_PLAYER_PROFILES_QUERY = gql`
      query findPlayerProfiles($player_id: ID!) {
        findPlayerProfiles(player_id: $player_id) {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
            cover {
              path
            }
            availabilities {
              day
              timeslots
            }
            player {
              id
              username
            }
            statistics {
              id
              nb_goals
              pace
              shooting
              passing
              dribbling
              defending
              physical
              goal_keeping
            }
            given_assists {
              id
            }
            motm_awards {
              id
            }
            player_positions {
              id
              status
              team_number
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                  type
                  status
                  avatar {
                    path
                  }
                  players {
                    id
                    status
                    team_number
                    team_player {
                      ... on FootballTeam {
                        __typename
                        id
                        name
                      }
                    }
                    player {
                      ... on Footballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                      ... on Basketballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                    }
                  }
                  captains {
                    id
                    status
                    team_number
                    player {
                      ... on Footballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                      ... on Basketballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                    }
                  }
                }
              }
              team_captain {
                ... on FootballTeam {
                  __typename
                  id
                  name
                  type
                  avatar {
                    path
                  }
                  players {
                    id
                    status
                    team_number
                    team_player {
                      ... on FootballTeam {
                        __typename
                        id
                        name
                      }
                    }
                    player {
                      ... on Footballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                      ... on Basketballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                    }
                  }
                  captains {
                    id
                    status
                    team_number
                    player {
                      ... on Footballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                      ... on Basketballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            sport_teams {
              ... on FootballTeam {
                __typename
                id
                name
                type
                status
                avatar {
                  path
                }
                players {
                  id
                  status
                  team_number
                  team_player {
                    ... on FootballTeam {
                      __typename
                      id
                      name
                    }
                  }
                  player {
                    ... on Footballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                    ... on Basketballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                  }
                }
                captains {
                  id
                  status
                  team_number
                  player {
                    ... on Footballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                    ... on Basketballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
            cover {
              path
            }
            availabilities {
              day
              timeslots
            }
            player {
              id
              username
            }
            statistics {
              id
              nb_goals
              pace
              shooting
              passing
              dribbling
              defending
              physical
              goal_keeping
            }
            given_assists {
              id
            }
            motm_awards {
              id
            }
            player_positions {
              id
              team_number
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                  type
                }
              }
              team_captain {
                ... on FootballTeam {
                  __typename
                  id
                  name
                  type
                }
              }
            }
            sport_teams {
              ... on FootballTeam {
                __typename
                id
                name
                type
                avatar {
                  path
                }
                players {
                  id
                  status
                  team_number
                  team_player {
                    ... on FootballTeam {
                      __typename
                      id
                      name
                    }
                  }
                  player {
                    ... on Footballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                    ... on Basketballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                  }
                }
                captains {
                  id
                  status
                  team_number
                  player {
                    ... on Footballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                    ... on Basketballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const FIND_ACCOUNT_PERSONAL_WALLETS_QUERY = gql`
      query findAccountPersonalWallets($account: inputAccountWallets!) {
        findAccountPersonalWallets(account: $account) {
          id
          title
          status
          is_default
          type
          owner {
            ... on Company {
              __typename
              id
              name
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
          bank_accounts {
            id
          }
          blockchain_accounts {
            id
          }
          cards {
            ... on BankCard {
              __typename
              id
            }
            ... on BlockchainCard {
              __typename
              id
            }
          }
          payment_methods {
            id
            name
            status
            payment_providers {
              id
              name
              status
              available_currencies
            }
            payment_source {
              ... on BankCard {
                __typename
                id
                network
              }
              ... on BlockchainCard {
                __typename
                id
                network
              }
              ... on BlockchainAccount {
                __typename
                id
                title
              }
              ... on BankAccount {
                __typename
                id
                title
              }
            }
          }
          sent_transactions {
            id
          }
        }
      }
    `;

    const SELECT_PREFERED_LANGUAGE_MUTATION = gql`
      mutation selectPreferedLanguage($account: inputSelectPreferedLangue!) {
        selectPreferedLanguage(account: $account)
      }
    `;

    const UPDATE_LATEST_POSITION_MUTATION = gql`
      mutation updatePlayerLatestPosition($account: inputUpdateAccountCoordinates!) {
        updatePlayerLatestPosition(account: $account)
      }
    `;

    const session = useAppSelector(selectSession);
    const dispatch = useAppDispatch();
    const appContext = React.useContext(AppContext);
    const loggedPlayer = useAppSelector(selectPlayer);
    const loggedPlayerProfiles = useAppSelector(selectPlayerProfiles);
    const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);
    const loggedPlayerTeams = useAppSelector(selectTeams);
    const loggedPlayerselectedTeam = useAppSelector(selectedTeam);
    const [offLineUser, setOffLineUser] = React.useState(UNDEFINED_AS_ANY);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [updatePlayerLatestPosition] = useMutation(UPDATE_LATEST_POSITION_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const [selectPreferedLanguage, updatedLanguage] = useMutation(SELECT_PREFERED_LANGUAGE_MUTATION, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
    });
    const loadedPlayer = useQuery(FIND_PLAYER_BY_ID_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key,
      variables: { id: loggedPlayer?.id },
      pollInterval: session.status !== 'ALIVE' || !loggedPlayer?.id ? undefined : 3000,
    });
    const loadedProfiles = useQuery(FIND_PLAYER_PROFILES_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key,
      variables: { player_id: loggedPlayer?.id },
      pollInterval: session.status !== 'ALIVE' || !loggedPlayer?.id ? undefined : 3000,
    });
    const loadedAccountPersonalWallets = useQuery(FIND_ACCOUNT_PERSONAL_WALLETS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key,
      variables: {
        account: { id: loggedPlayer?.id, username: loggedPlayer?.username },
      },
      pollInterval: session.status !== 'ALIVE' || !loggedPlayer?.id ? undefined : 300000,
    });

    React.useEffect(() => {
      // Get the user's browser language
      const userLang = window.navigator.language || 'en';

      // Set the lang attribute of the HTML tag
      document.documentElement.lang = userLang;
    }, []);

    React.useEffect(() => {
      if (session.status !== 'ALIVE' && pathname !== '/' && pathname !== '/login' && pathname !== '/signup' && pathname !== '/forgot_password' && pathname !== '/join_complex_network' && pathname !== '/legal_mentions' && !pathname?.includes('/tournament/') && !pathname?.includes('/challenge/') && !pathname?.includes('/ls/') && !pathname?.includes('/livestreams') && !pathname?.includes('/feeds')) {
        navigate('/login');
      }

      if (!loggedPlayer?.updated_at || (loggedPlayer?.updated_at && loggedPlayer?.updated_at + 10800000 < Date.now())) {
        (async () => {
          try {
            await updatePlayerLatestPosition({
              variables: {
                account: {
                  id: loggedPlayer?.id,
                  latest_coordinates: {
                    latitude: appContext?.currentPosition?.latitude,
                    longitude: appContext?.currentPosition?.longitude,
                  },
                },
                skip: !loggedPlayer?.id || !appContext?.currentPosition?.latitude || !appContext?.currentPosition?.longitude,
              },
            });
          } catch (e) {
            console.log(e);
          }
        })();
      }

      if (!_.isMatch(loggedPlayer, loadedPlayer?.data?.findPlayerByID) || (loadedPlayer?.called && loadedPlayer?.data?.findPlayerByID !== undefined)) {
        try {
          dispatch(storePlayer(loadedPlayer?.data?.findPlayerByID));
        } catch (e) {
          console.log(e);
        }
      }

      if (!_.isMatch(loggedPlayerProfiles, loadedProfiles.data?.findPlayerProfiles) || (loadedProfiles?.called && loadedProfiles.data?.findPlayerProfiles !== undefined)) {
        try {
          dispatch(storePlayerProfiles(loadedProfiles.data?.findPlayerProfiles));
        } catch (e) {
          console.log(e);
        }
      }

      if (
        (loggedPlayerActiveProfile?.id === undefined && loggedPlayer?.profiles) ||
        !_.isMatch(
          loggedPlayerActiveProfile,
          loggedPlayerProfiles?.find((p: any) => p?.id === loggedPlayerActiveProfile?.id)
        ) ||
        !(loggedPlayerActiveProfile?.player_positions === loggedPlayerProfiles?.find((p: any) => p?.id === loggedPlayerActiveProfile?.id)?.player_positions)
      ) {
        try {
          if (!loggedPlayerActiveProfile) {
            dispatch(storePlayerActiveProfile(loggedPlayer?.profiles[0]));
          } else {
            dispatch(storePlayerActiveProfile(loggedPlayerProfiles?.find((p: any) => p?.id === loggedPlayerActiveProfile?.id)));
          }
        } catch (e) {
          console.log(e);
        }
      }
    }, [session, navigate, pathname, loggedPlayer, loadedPlayer, dispatch, loggedPlayerTeams, loadedProfiles, loggedPlayerselectedTeam, loggedPlayerActiveProfile]);

    React.useEffect(() => {
      if (loadedAccountPersonalWallets.called && loadedAccountPersonalWallets.data?.findAccountPersonalWallets) {
        try {
          dispatch(storePersonalWallets(loadedAccountPersonalWallets.data?.findAccountPersonalWallets));
        } catch (e) {
          console.log(e);
        }
      }
    }, [loadedAccountPersonalWallets, dispatch]);

    React.useEffect(() => {
      (async () => {
        if (((!loggedPlayer?.id && !offLineUser) || !_.isMatch(loggedPlayer, offLineUser)) && loggedPlayer?.app_configuration?.prefered_language) {
          setOffLineUser(loggedPlayer);
        }

        if (!updatedLanguage.called && offLineUser?.app_configuration?.prefered_language && loggedPlayer?.id && !_.isMatch(offLineUser?.app_configuration?.prefered_language, loggedPlayer?.app_configuration?.prefered_language)) {
          try {
            await selectPreferedLanguage({
              variables: {
                account: {
                  id: loggedPlayer?.id,
                  new_language: offLineUser?.app_configuration?.prefered_language,
                },
              },
            });

            setOffLineUser({
              app_configuration: {
                prefered_language: offLineUser?.app_configuration?.prefered_language,
              },
            });
          } catch (e) {
            console.log(e);
          }
        }
      })();
    }, [offLineUser, loggedPlayer, updatedLanguage, selectPreferedLanguage]);

    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
}
