import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, Divider, Fab, Grid, List, ListItem, IconButton, Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import { Add, Close, Delete } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';

export default function AssignSelectedTeamsTournamentModal(props: { tournament_id: string; group_id: string; handleClose: any; handleAssignTeam: any; handleRemoveTeam: any; open: any }) {
  const FIND_TOURNAMENT_BY_ID_QUERY = gql`
    query findTournamentByID($id: ID!) {
      findTournamentByID(id: $id) {
        id
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        selected_teams {
          id
          name
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              shortname
              avatar {
                path
              }
            }
          }
        }
        phase_groups {
          id
          name
          teams {
            id
            name
            players {
              id
              status
              team_number
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
              }
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
          rank {
            id
            points
            goals
            team {
              id
              name
              players {
                id
                status
                team_number
                team_player {
                  ... on FootballTeam {
                    __typename
                    id
                    name
                  }
                }
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    avatar {
                      path
                    }
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    avatar {
                      path
                    }
                    player {
                      id
                    }
                  }
                }
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
          games {
            id
            short_id
            title
            status
            start_date
            end_date
            home_team {
              id
              name
            }
            away_team {
              id
              name
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);

  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.tournament_id,
    },
  });

  const SELECTED_GROUP = tournament?.phase_groups?.find((g: any) => g?.id === props.group_id);
  const SELECTED_TEAMS = tournament?.phase_groups
    ?.filter((g: any) => g?.id !== SELECTED_GROUP?.id)
    ?.map((t: any) => t?.rank)
    ?.flat()
    ?.map((r: any) => r?.team);

  const handleClose = () => {
    props.handleClose();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedTournament.refetch({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        skip: !session?.token?.key,
        id: props.tournament_id,
      });

      if (result) {
        setTournament(result.data?.findTournamentByID);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddTeam = async (team_id: string) => {
    try {
      await props.handleAssignTeam(team_id);
      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveTeam = async (team_id: string) => {
    try {
      await props.handleRemoveTeam(team_id);
      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (props.open && tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByID) {
      setTournament(loadedTournament.data?.findTournamentByID);
    }

    if (!props.open && tournament) {
      setTournament(UNDEFINED_AS_ANY);
    }
  }, [tournament, loadedTournament, setTournament, props]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={handleClose}
            // disabled={isSent.loading}
            // className={isSent.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}></Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{SELECTED_GROUP?.name}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <List>
          {tournament?.selected_teams
            ?.filter((st: any) => !SELECTED_TEAMS?.find((t: any) => t?.id === st?.id))
            ?.map((t: any) => (
              <ListItem
                key={t?.id}
                secondaryAction={
                  SELECTED_GROUP?.teams?.find((st: any) => st?.id === t?.id) ? (
                    SELECTED_GROUP?.games?.filter((ga: any) => ga?.home_team?.id === t?.id || ga?.away_team?.id === t?.id)?.length === 0 ? (
                      <IconButton className="button-red-reversed" onClick={() => handleRemoveTeam(t?.id)}>
                        <Delete />
                      </IconButton>
                    ) : (
                      ''
                    )
                  ) : (
                    <IconButton className="button-green-reverse" onClick={() => handleAddTeam(t?.id)}>
                      <Add />
                    </IconButton>
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar src={t?.team?.avatar?.path} alt={t?.team?.name} />
                </ListItemAvatar>
                <ListItemText
                  primary={t?.name}
                  // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

AssignSelectedTeamsTournamentModal.propTypes = {
  tournament_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

AssignSelectedTeamsTournamentModal.defaultProps = {};
