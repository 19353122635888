import { CircularProgress, Fab, Grid } from '@mui/material';
import React from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { LocationIcon } from '../../img/icons/Icons';

const GPSCoordinatorFeature = (props: { position: any; setPosition?: any; setValue?: any; element?: any }) => {
  const map = useMap();
  const [draggable, setDraggable] = React.useState(false);
  const markerRef = React.useRef(null);
  const [defaultPosition] = React.useState(map.getCenter());
  const [isSearching, setIsSearching] = React.useState(false);

  const findCurrentLocation = async () => {
    try {
      setIsSearching(true);
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (props.setValue) {
            props.setValue(props.element, { lat: position.coords?.latitude, lng: position.coords?.longitude });
          }
          if (props.setPosition) {
            props.setPosition({ lat: position.coords?.latitude, lng: position.coords?.longitude });
          }
          map.flyTo({ lat: position.coords?.latitude, lng: position.coords?.longitude }, map.getZoom());
        },
        (error: any) => {
          console.log(error);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const eventHandlers = React.useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;
        if (marker !== null) {
          if (props.setValue) {
            props.setValue(props.element, marker.getLatLng());
          }
          if (props.setPosition) {
            props.setPosition(marker.getLatLng());
          }
          map.flyTo(marker.getLatLng(), map.getZoom());
        }
      },
    }),
    []
  );

  const toggleDraggable = React.useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  React.useEffect(() => {
    if (props.position && isSearching) {
      setIsSearching(false);
    }
  }, [props, isSearching]);

  return (
    <Marker draggable={draggable} eventHandlers={eventHandlers} position={props.position || defaultPosition} ref={markerRef}>
      <Popup minWidth={90}>
        {isSearching ? (
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
            Searching position&nbsp;
            <CircularProgress size={20} className="green-loader" />
          </Grid>
        ) : (
          <Fab variant="extended" size="small" className="button-grey" sx={{ boxShadow: 'none', fontSize: '.9em', m: '5px' }} onClick={findCurrentLocation}>
            <LocationIcon />
            &nbsp;Find my position
          </Fab>
        )}
        <Fab variant="extended" size="small" className="button-grey" sx={{ boxShadow: 'none', fontSize: '.9em' }} onClick={toggleDraggable}>
          {draggable ? 'You can move it now' : 'Click here to unlock the Marker'}
        </Fab>
      </Popup>
    </Marker>
  );
};

export default GPSCoordinatorFeature;

GPSCoordinatorFeature.propTypes = {};
