import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import _ from 'lodash';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { dataURLtoBlobURL, handleUpload } from '../../helpers/index';
import { TO_TIMESTAMP, COUNTRIES, FILE_TO_BASE64, ID_TYPES } from '../../utilities/utilities';
import { Fab, Grid, CardMedia, Stack, Autocomplete, MenuItem, TextField, Input, Step, Stepper, StepButton, StepContent, FormGroup, FormControlLabel, Checkbox, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { PictureAsPdf, Close, Clear, PhotoCamera, Remove, Cached, Send } from '@mui/icons-material';
import { CountryType, DATE_AS_ANY, INITIAL_COUNTRY, MEDIAS_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import Document from '../common/Document';

export default function NewKYCModal(props: { title: string; handleClose: any; open: boolean; wallet: any }) {
  const CREATE_WALLET_KYC_CASE_MUTATION = gql`
    mutation createWalletKYCCase($case: inputWalletCase!) {
      createWalletKYCCase(case: $case) {
        id
        reference
        status
        level
      }
    }
  `;

  const ADD_NEW_LOCATION_MUTATION = gql`
    mutation addAdditionalLocation($account: inputAddAdditionalLocation!) {
      addAdditionalLocation(account: $account) {
        id
      }
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const [progress, setProgress] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [incorporationFileImg, setIncorporationFileImg] = React.useState<ImageListType>([]);
  const [incorporationFilePdf, setIncorporationFilePdf] = React.useState<any>(null);
  const [incorporationFilesUrls, setIncorporationFilesUrls] = React.useState([]);
  const [idIssuerCountry, setIdIssuerCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [homeCountry, setHomeCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [pidFrontFile, setPidFrontFile] = React.useState<ImageListType>([]);
  const [pidFrontFileUrl, setPidFrontFileUrl] = React.useState([]);
  const [pidBackFile, setPidBackFile] = React.useState<ImageListType>([]);
  const [pidBackFileUrl, setPidBackFileUrl] = React.useState([]);
  const [idSelfie, setIdSelfie] = React.useState<ImageListType>([]);
  const [idSelfieUrl, setSelfieUrl] = React.useState([]);
  const [residentialProofFileImg, setResidentialProofFileImg] = React.useState<ImageListType>([]);
  const [residentialProofFilePdf, setResidentialProofFilePdf] = React.useState<any>(null);
  const [residentialFilesUrls, setResidentialFilesUrls] = React.useState([]);
  const [confirmations, setConfirmations] = React.useState({
    infoAuthenticity: false,
    legalNotice: false,
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      id_type: 'PASSPORT',
      id_number: '',
      id_exp: DATE_AS_ANY,
      id_issuer: '',
      id_issuer_country: INITIAL_COUNTRY,
      pid_front: MEDIAS_AS_ANY,
      pid_back: MEDIAS_AS_ANY,
      id_selfie: MEDIAS_AS_ANY,
      home_street_name: '',
      home_secondary_street_name: '',
      home_postcode: '',
      home_city: '',
      home_county: '',
      home_state: '',
      home_country: INITIAL_COUNTRY,
      business_registration: '',
      incorporation_pdf_files: UNDEFINED_AS_ANY,
      incorporation_img_files: MEDIAS_AS_ANY,
      residential_proof_pdf_files: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();
  const [createWalletKYCCase, newKYCCase] = useMutation(CREATE_WALLET_KYC_CASE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [addAdditionalLocation, newLocation] = useMutation(ADD_NEW_LOCATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    setActiveStep(0);
    setProgress(0);
    setConfirmations({ infoAuthenticity: false, legalNotice: false });
    reset();
    setIncorporationFileImg([]);
    setIncorporationFilePdf(null);
    setResidentialProofFileImg([]);
    setResidentialProofFilePdf(null);
    props.handleClose();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleConfirmations = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmations({
      ...confirmations,
      [event.target.name]: event.target.checked,
    });
  };

  const handleIncorporationFileImg = async (imageList: ImageListType) => {
    setIncorporationFileImg(imageList);
  };

  const handleIncorporationFilePdf = ({ target }: any) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    FILE_TO_BASE64(target.files[0], (err: any, result: any) => {
      if (result) {
        setIncorporationFilePdf({
          dataURL: result,
          file: target.files[0],
        });
      }
    });
  };

  const handleResidentialProofFileImg = async (imageList: ImageListType) => {
    setResidentialProofFileImg(imageList);
  };

  const handleResidentialProofFilePdf = ({ target }: any) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    FILE_TO_BASE64(target.files[0], (err: any, result: any) => {
      if (result) {
        setResidentialProofFilePdf({
          dataURL: result,
          file: target.files[0],
        });
      }
    });
  };

  const handleIdIssuerCountry = (country: CountryType) => {
    if (country) {
      setIdIssuerCountry(country);
      setValue('id_issuer_country', country);
    }
  };

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setHomeCountry(country);
      setValue('home_country', country);
    }
  };

  const handlePidFrontFile = async (imageList: ImageListType) => {
    setValue('pid_front', imageList);
    setPidFrontFile(imageList);
  };

  const handlePidBackFile = async (imageList: ImageListType) => {
    setValue('pid_back', imageList);
    setPidBackFile(imageList);
  };

  const handleIdSelfie = async (imageList: ImageListType) => {
    setIdSelfie(imageList);
  };

  const onSubmit = async () => {
    try {
      setProgress(1);

      const incorporationFiles = _.concat(incorporationFileImg, incorporationFilePdf);
      if (incorporationFiles.length > 0 && incorporationFiles[0] !== null) {
        const documentUploadPromises = incorporationFiles.map(async (o: any) => {
          await handleUpload(o?.file, incorporationFilesUrls, setIncorporationFilesUrls, session, loggedPlayer.id);
        });
        await Promise.all(documentUploadPromises);
      }

      const residentialFiles = _.concat(residentialProofFileImg, residentialProofFilePdf);
      if (residentialFiles.length > 0 && residentialFiles[0] !== null) {
        const documentUploadPromises = residentialFiles.map(async (o: any) => {
          await handleUpload(o?.file, residentialFilesUrls, setResidentialFilesUrls, session, loggedPlayer.id);
        });
        await Promise.all(documentUploadPromises);
      }

      if (pidFrontFile.length > 0) {
        const pidFrontPromise = await handleUpload(pidFrontFile[0].file, pidFrontFileUrl, setPidFrontFileUrl, session, loggedPlayer.id);
        if (pidFrontPromise) await Promise.all(pidFrontPromise);
      }

      if (pidBackFile.length > 0) {
        const pidBackPromise = await handleUpload(pidBackFile[0].file, pidBackFileUrl, setPidBackFileUrl, session, loggedPlayer.id);
        if (pidBackPromise) await Promise.all(pidBackPromise);
      }

      if (idSelfie.length > 0) {
        const pidSelfiePromise = await handleUpload(idSelfie[0].file, idSelfieUrl, setSelfieUrl, session, loggedPlayer.id);
        if (pidSelfiePromise) await Promise.all(pidSelfiePromise);
      }

      await createWalletKYCCase({
        variables: {
          case: {
            author_id: loggedPlayer?.id,
            wallet_id: props.wallet?.id,
            wallet_type: props.wallet?.type,
            wallet_owner: {
              type: props.wallet?.owner?.__typename,
              id: props.wallet?.owner?.id,
              name: props.wallet?.owner?.name || props.wallet?.owner?.username,
            },
            reference: `case-w-*${props.wallet?.id}-u-*${loggedPlayer?.id}-d-*${Date.now()}`,
            title: `[KYC WALLET] ${props.wallet?.id}`,
            business_registration: inputFields.business_registration,
            incorporation_file: _.concat(incorporationFileImg, incorporationFilePdf).map((p: any, index: number) => {
              return {
                filename: p?.file?.name ? p?.file?.name : p?.file?.filename,
                size: p?.file?.size,
                type: p?.file?.type,
                path: incorporationFilesUrls[index],
              };
            }),
            user_id_type: inputFields.id_type,
            user_id_number: inputFields.id_number,
            user_id_exp: parseFloat(TO_TIMESTAMP(inputFields.id_exp).toFixed(1)),
            user_id_issuer: inputFields.id_issuer,
            user_id_issuer_country: idIssuerCountry.value,
            user_front_id: {
              filename: pidFrontFile[0]?.file?.name,
              size: pidFrontFile[0]?.file?.size,
              type: pidFrontFile[0]?.file?.type,
              path: pidFrontFileUrl[0] ? pidFrontFileUrl[0] : '',
            },
            user_back_id: {
              filename: pidBackFile[0]?.file?.name,
              size: pidBackFile[0]?.file?.size,
              type: pidBackFile[0]?.file?.type,
              path: pidBackFileUrl[0] ? pidBackFileUrl[0] : '',
            },
            user_id_selfie: {
              filename: idSelfie[0]?.file?.name,
              size: idSelfie[0]?.file?.size,
              type: idSelfie[0]?.file?.type,
              path: idSelfieUrl[0] ? idSelfieUrl[0] : '',
            },
            user_home: {
              street_name: inputFields.home_street_name,
              secondary_street_name: inputFields.home_secondary_street_name,
              country_code: homeCountry.value,
              postcode: inputFields.home_postcode,
              city: inputFields.home_city,
              county: inputFields.home_county,
              state: inputFields.home_state,
              country: homeCountry.label,
            },
            user_residential_proof: _.concat(residentialProofFileImg, residentialProofFilePdf).map((p: any, index: number) => {
              return {
                filename: p?.file?.name ? p?.file?.name : p?.file?.filename,
                size: p?.file?.size,
                type: p?.file?.type,
                path: residentialFilesUrls[index],
              };
            }),
          },
        },
      });

      try {
        await addAdditionalLocation({
          variables: {
            account: {
              buyer_id: loggedPlayer?.id,
              location: {
                title: `${loggedPlayer?.username}'s address`,
                street_name: inputFields.home_street_name,
                secondary_street_name: inputFields.home_secondary_street_name,
                country_code: inputFields.home_country ? inputFields.home_country?.value : homeCountry?.value,
                postcode: inputFields.home_postcode,
                city: inputFields.home_city,
                county: inputFields.home_county,
                state: inputFields.home_state,
                country: inputFields.home_country ? inputFields.home_country?.label : homeCountry?.label,
                continent: inputFields.home_country ? inputFields.home_country?.continent : homeCountry?.continent,
              },
            },
          },
        });
      } catch (e) {
        console.log(e);
        handleClose();
      }

      handleClose();
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  const steps = [
    {
      label: 'KYC Verification',
      content: (
        <Grid>
          <Controller
            name="id_number"
            control={control}
            rules={{
              required: true,
              maxLength: 30,
              minLength: 4,
              pattern: /^[a-z0-9_]*$/,
            }}
            render={({ field }: any) => <TextField {...field} value={field.value} label="ID #" placeholder="Input your ID number" variant="outlined" fullWidth className={'field-bottom-space'} />}
          />

          <Controller
            name="id_type"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                label="ID Type"
                placeholder="Select an ID type"
                variant="outlined"
                className={'field-bottom-space'}
                sx={{
                  mr: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
              >
                {ID_TYPES.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="id_exp"
            control={control}
            render={({ field }: any) => (
              <DatePicker
                {...field}
                format="dd/MM/yyyy"
                views={['day', 'month', 'year']}
                minDate={new Date()}
                value={field.value}
                slotProps={{
                  textField: {
                    label: 'ID Expiration',
                    placeholder: 'Input expiration date',
                    className: 'field-bottom-space',
                    sx: {
                      ml: {
                        xs: '0',
                        sm: '3%',
                        md: '5%',
                      },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    },
                  },
                }}
              />
            )}
          />

          <Controller name="id_issuer" control={control} rules={{ required: true, maxLength: 30, minLength: 4 }} render={({ field }: any) => <TextField {...field} value={field.value} className={'field-bottom-space'} label="Authority issuer" placeholder="Input authority issuer's name" variant="outlined" fullWidth />} />

          <Controller
            control={control}
            name="id_issuer_country"
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                options={COUNTRIES}
                value={idIssuerCountry}
                className={'country-select'}
                onChange={(event: any, value: any) => handleIdIssuerCountry(value)}
                autoHighlight
                getOptionLabel={(option: CountryType) => option.label}
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                renderOption={(props: any, option: CountryType) => (
                  <Grid
                    component="li"
                    sx={{
                      '& > img': { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                    {option.label} ({option.value})
                  </Grid>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ID issuer country"
                    value={idIssuerCountry}
                    variant="outlined"
                    className={'field-bottom-space'}
                    error={errors?.id_issuer_country !== undefined}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          />

          <Stack direction="row" className={'field-bottom-space'}>
            <Controller
              name="pid_front"
              control={control}
              rules={{
                required: true,
              }}
              render={() => (
                <ImageUploading value={pidFrontFile} onChange={handlePidFrontFile} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                  {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    <Grid
                      container
                      sx={{
                        justifyContent: 'center',
                        width: '50%',
                        display: 'inline-flex',
                        mt: '20px',
                      }}
                    >
                      {pidFrontFile.length === 1 ? (
                        ''
                      ) : (
                        <Fab
                          style={
                            isDragging
                              ? {
                                  color: 'rgba(31, 175, 146, .3)',
                                }
                              : undefined
                          }
                          onClick={onImageUpload}
                          variant="extended"
                          size="small"
                          {...dragProps}
                          className="button-green-reverse"
                          sx={{
                            background: 'transparent',
                            boxShadow: 'none',
                            mb: '20px',
                          }}
                        >
                          Add ID (front) <PhotoCamera />
                        </Fab>
                      )}
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{
                          overflow: 'auto',
                        }}
                      >
                        {imageList[0] ? (
                          <Stack
                            direction="row"
                            spacing={{
                              xs: 1,
                              sm: 2,
                              md: 4,
                            }}
                            sx={{
                              p: '10px 0',
                              justifyContent: 'center',
                            }}
                            className="upload__image-wrapper"
                          >
                            {imageList?.map((image: any, index: any) => (
                              <div key={index} className="image-item">
                                <CardMedia
                                  component={'img'}
                                  src={dataURLtoBlobURL(image?.dataURL)}
                                  alt={image?.name}
                                  sx={{
                                    height: {
                                      xs: 125,
                                      sm: 150,
                                      md: 200,
                                    },
                                    width: 'auto',
                                    margin: 'auto',
                                  }}
                                />
                                <Grid
                                  className="image-item__btn-wrapper"
                                  sx={{
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Fab size="small" onClick={() => onImageUpdate(index)}>
                                    <Cached />
                                  </Fab>
                                  <Fab size="small" onClick={() => onImageRemove(index)}>
                                    <Remove />
                                  </Fab>
                                </Grid>
                              </div>
                            ))}
                          </Stack>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  )}
                </ImageUploading>
              )}
            />

            <Controller
              name="pid_back"
              control={control}
              rules={{
                required: true,
              }}
              render={() => (
                <ImageUploading value={pidBackFile} onChange={handlePidBackFile} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                  {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    <Grid
                      container
                      sx={{
                        justifyContent: 'center',
                        width: '50%',
                        display: 'inline-flex',
                        mt: '20px',
                      }}
                    >
                      {pidBackFile.length === 1 ? (
                        ''
                      ) : (
                        <Fab
                          style={
                            isDragging
                              ? {
                                  color: 'rgba(31, 175, 146, .3)',
                                }
                              : undefined
                          }
                          onClick={onImageUpload}
                          variant="extended"
                          size="small"
                          {...dragProps}
                          className="button-green-reverse"
                          sx={{
                            background: 'transparent',
                            boxShadow: 'none',
                            mb: '20px',
                          }}
                        >
                          Add ID (back) <PhotoCamera />
                        </Fab>
                      )}
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{
                          overflow: 'auto',
                        }}
                      >
                        {imageList[0] ? (
                          <Stack
                            direction="row"
                            spacing={{
                              xs: 1,
                              sm: 2,
                              md: 4,
                            }}
                            sx={{
                              p: '10px 0',
                              justifyContent: 'center',
                            }}
                            className="upload__image-wrapper"
                          >
                            {imageList?.map((image: any, index: any) => (
                              <div key={index} className="image-item">
                                <CardMedia
                                  component={'img'}
                                  src={dataURLtoBlobURL(image?.dataURL)}
                                  alt={image?.name}
                                  sx={{
                                    height: {
                                      xs: 125,
                                      sm: 150,
                                      md: 200,
                                    },
                                    width: 'auto',
                                    margin: 'auto',
                                  }}
                                />
                                <Grid
                                  className="image-item__btn-wrapper"
                                  sx={{
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Fab size="small" onClick={() => onImageUpdate(index)}>
                                    <Cached />
                                  </Fab>
                                  <Fab size="small" onClick={() => onImageRemove(index)}>
                                    <Remove />
                                  </Fab>
                                </Grid>
                              </div>
                            ))}
                          </Stack>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  )}
                </ImageUploading>
              )}
            />
          </Stack>
          <Controller
            name="id_selfie"
            control={control}
            rules={{ required: true }}
            render={() => (
              <ImageUploading value={idSelfie} onChange={handleIdSelfie} maxNumber={1} acceptType={['jpg', 'jpeg', 'png']}>
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                  <Grid container sx={{ justifyContent: 'center' }}>
                    {idSelfie.length === 1 ? (
                      ''
                    ) : (
                      <Fab
                        style={isDragging ? { color: 'green' } : undefined}
                        onClick={onImageUpload}
                        variant="extended"
                        size="small"
                        {...dragProps}
                        className="button-green-reverse"
                        sx={{
                          background: 'transparent',
                          boxShadow: 'none',
                          m: '0 5px',
                        }}
                      >
                        Add Selfie <PhotoCamera />
                      </Fab>
                    )}
                    <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
                      {imageList[0] ? (
                        <Stack
                          direction="row"
                          spacing={{
                            xs: 1,
                            sm: 2,
                            md: 4,
                          }}
                          sx={{ p: '10px 0' }}
                          className="upload__image-wrapper"
                        >
                          {imageList.map((image: any, index: any) => (
                            <div key={index} className="image-item">
                              <CardMedia
                                component={'img'}
                                src={dataURLtoBlobURL(image?.dataURL)}
                                alt={image?.name}
                                sx={{
                                  height: {
                                    xs: 125,
                                    sm: 150,
                                    md: 200,
                                  },
                                  width: 'auto',
                                  margin: 'auto',
                                }}
                              />
                              <Grid
                                className="image-item__btn-wrapper"
                                sx={{
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                <Fab size="small" onClick={() => onImageUpdate(index)}>
                                  <Cached />
                                </Fab>
                                <Fab size="small" onClick={() => onImageRemove(index)}>
                                  <Remove />
                                </Fab>
                              </Grid>
                            </div>
                          ))}
                        </Stack>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                )}
              </ImageUploading>
            )}
          />

          {props?.wallet?.type === 'BUSINESS' ? (
            <Grid>
              <Controller
                name="business_registration"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9_-]*$/,
                }}
                render={({ field }: any) => <TextField {...field} value={field.value} className={'field-bottom-space'} required={false} label="Business registration #" placeholder="Input your Business registration number" variant="outlined" fullWidth />}
              />

              <h4>Certificate of incorporation</h4>
              <Stack direction="row">
                <ImageUploading value={incorporationFileImg} multiple onChange={handleIncorporationFileImg} maxNumber={10} acceptType={['jpg', 'jpeg', 'png']}>
                  {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                    <Grid container sx={{ p: '20px 0' }}>
                      <Controller
                        name="incorporation_pdf_files"
                        control={control}
                        rules={{
                          required: true,
                          maxLength: 30,
                          minLength: 4,
                          max: 30,
                          pattern: /^[a-zA-Z0-9_-]*$/,
                        }}
                        render={({ field }: any) => (
                          <label htmlFor="contained-button-files">
                            <Input
                              {...field}
                              value={field.value}
                              inputProps={{
                                accept: 'application/pdf',
                              }}
                              id="contained-button-files"
                              type="file"
                              onChange={handleIncorporationFilePdf}
                              sx={{
                                display: 'none',
                              }}
                            />
                            <Fab
                              variant="extended"
                              size="small"
                              className="button-green-reverse"
                              sx={{
                                background: 'transparent',
                                boxShadow: 'none',
                                m: '0 5px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Add PDF <PictureAsPdf />
                            </Fab>
                          </label>
                        )}
                      />
                      {incorporationFileImg.length === 10 ? (
                        ''
                      ) : (
                        <Fab
                          style={isDragging ? { color: 'green' } : undefined}
                          onClick={onImageUpload}
                          variant="extended"
                          size="small"
                          {...dragProps}
                          className="button-green-reverse"
                          sx={{
                            background: 'transparent',
                            boxShadow: 'none',
                            m: '0 5px',
                          }}
                        >
                          Add IMG file ({incorporationFileImg.length === 0 ? `10 max` : `${10 - incorporationFileImg.length} left`}
                          ) <PhotoCamera />
                        </Fab>
                      )}
                      <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
                        {imageList[0] || incorporationFilePdf ? (
                          <Stack
                            direction="row"
                            spacing={{
                              xs: 1,
                              sm: 2,
                              md: 4,
                            }}
                            sx={{ p: '10px 0' }}
                            className="upload__image-wrapper"
                          >
                            {incorporationFilePdf?.file ? (
                              <Grid
                                container
                                sx={{
                                  textAlign: 'center',
                                  justifyContent: 'center',
                                  pb: '50px',
                                }}
                              >
                                <Document url={dataURLtoBlobURL(incorporationFilePdf?.dataURL)} />
                                <Fab
                                  size="small"
                                  className="button-red-reversed"
                                  sx={{
                                    bgcolor: 'transparent',
                                    boxShadow: 'none',
                                  }}
                                  onClick={() => setIncorporationFilePdf(null)}
                                >
                                  <Remove sx={{ color: 'rgba(238, 44, 32, 1) !important' }} />
                                </Fab>
                              </Grid>
                            ) : (
                              ''
                            )}
                            {imageList.map((image: any, index: any) => (
                              <div key={index} className="image-item">
                                <Document url={dataURLtoBlobURL(image?.dataURL)} />
                                <Grid
                                  className="image-item__btn-wrapper"
                                  sx={{
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Fab
                                    size="small"
                                    sx={{
                                      bgcolor: 'transparent',
                                      boxShadow: 'none',
                                    }}
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    <Cached sx={{ color: 'rgba(15, 15, 15, .6) !important' }} />
                                  </Fab>
                                  <Fab
                                    size="small"
                                    className="button-red-reversed"
                                    sx={{
                                      bgcolor: 'transparent',
                                      boxShadow: 'none',
                                    }}
                                    onClick={() => onImageRemove(index)}
                                  >
                                    <Remove sx={{ color: 'rgba(238, 44, 32, 1) !important' }} />
                                  </Fab>
                                </Grid>
                              </div>
                            ))}
                          </Stack>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  )}
                </ImageUploading>
              </Stack>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      ),
    },
    {
      label: 'Residential Information',
      content: (
        <Grid>
          <Controller
            name="home_street_name"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label="Street address" placeholder="Write your home's street address" variant="outlined" fullWidth value={field.value} error={errors?.home_street_name !== undefined} helperText={errors?.home_street_name !== undefined ? `${errors?.home_street_name?.message}` : ''} className={'field-bottom-space'} />}
          />

          <Controller
            name="home_secondary_street_name"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => <TextField {...field} label="Street address (complement)" placeholder="Complement of home's street address" variant="outlined" fullWidth value={field.value} error={errors?.home_secondary_street_name !== undefined} helperText={errors?.home_secondary_street_name !== undefined ? `${errors?.home_secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} className={'field-bottom-space'} />}
          />

          <Controller
            name="home_postcode"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="Postcode"
                placeholder="Home's postcode"
                variant="outlined"
                value={field.value}
                error={errors?.home_postcode !== undefined}
                className={'field-bottom-space'}
                helperText={errors?.home_postcode !== undefined ? `${errors?.home_postcode?.message}` : ''}
                sx={{
                  mr: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
              />
            )}
          />

          <Controller
            name="home_city"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="City"
                placeholder="Home's city"
                variant="outlined"
                value={field.value}
                error={errors?.home_city !== undefined}
                className={'field-bottom-space'}
                helperText={errors?.home_city !== undefined ? `${errors?.home_city?.message}` : ''}
                sx={{
                  ml: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
              />
            )}
          />

          <Controller
            name="home_county"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="County"
                placeholder="Home's county"
                variant="outlined"
                value={field.value}
                error={errors?.home_county !== undefined}
                className={'field-bottom-space'}
                helperText={errors?.home_county !== undefined ? `${errors?.home_county?.message}` : ''}
                sx={{
                  mr: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
              />
            )}
          />

          <Controller
            name="home_state"
            control={control}
            rules={{
              required: false,
              maxLength: 30,
              minLength: 4,
              max: 30,
              pattern: /^[a-zA-Z0-9-\s]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="State"
                placeholder="Home's state"
                variant="outlined"
                value={field.value}
                error={errors?.home_state !== undefined}
                className={'field-bottom-space'}
                helperText={errors?.home_state !== undefined ? `${errors?.home_state?.message}` : ''}
                sx={{
                  ml: { xs: '0', sm: '3%', md: '5%' },
                  width: { xs: '100%', sm: '47%', md: '45%' },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="home_country"
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                options={COUNTRIES}
                value={homeCountry}
                className={'country-select'}
                onChange={(event: any, value: any) => onChangeCountry(event, value)}
                autoHighlight
                getOptionLabel={(option: CountryType) => option.label}
                isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                renderOption={(props: any, option: CountryType) => (
                  <Grid
                    component="li"
                    sx={{
                      '& > img': { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                    {option.label} ({option.value})
                  </Grid>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Home's country"
                    value={homeCountry}
                    variant="outlined"
                    className={'field-bottom-space'}
                    error={errors?.home_country !== undefined}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          />

          <h4>Residential Proof</h4>
          <i>Upload a residential proof (rent or electricity bill for example)</i>
          <Stack direction="row" className={'field-bottom-space'} sx={{ pt: '20px' }}>
            <ImageUploading value={residentialProofFileImg} multiple onChange={handleResidentialProofFileImg} maxNumber={2} acceptType={['jpg', 'jpeg', 'png']}>
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                <Grid container sx={{ justifyContent: 'center' }}>
                  <Controller
                    name="residential_proof_pdf_files"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      max: 30,
                      pattern: /^[a-zA-Z0-9_-]*$/,
                    }}
                    render={({ field }: any) => (
                      <label htmlFor="contained-button-file">
                        <Input
                          {...field}
                          value={field.value}
                          inputProps={{
                            accept: 'application/pdf',
                          }}
                          id="contained-button-file"
                          type="file"
                          onChange={handleResidentialProofFilePdf}
                          sx={{ display: 'none' }}
                        />
                        <Fab
                          variant="extended"
                          size="small"
                          component="span"
                          className="button-green-reverse"
                          sx={{
                            whiteSpace: 'nowrap',
                            background: 'transparent',
                            boxShadow: 'none',
                            m: '0 5px',
                          }}
                        >
                          Add PDF <PictureAsPdf />
                        </Fab>
                      </label>
                    )}
                  />
                  {residentialProofFileImg.length === 2 ? (
                    ''
                  ) : (
                    <Fab
                      style={isDragging ? { color: 'green' } : undefined}
                      onClick={onImageUpload}
                      variant="extended"
                      size="small"
                      {...dragProps}
                      className="button-green-reverse"
                      sx={{
                        background: 'transparent',
                        boxShadow: 'none',
                        m: '0 5px',
                      }}
                    >
                      Add IMG file ({residentialProofFileImg.length === 0 ? `2 max` : `${2 - residentialProofFileImg.length} left`}
                      ) <PhotoCamera />
                    </Fab>
                  )}
                  <Grid item xs={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
                    {imageList[0] || residentialProofFilePdf ? (
                      <Stack
                        direction="row"
                        spacing={{
                          xs: 1,
                          sm: 2,
                          md: 4,
                        }}
                        sx={{ p: '10px 0' }}
                        className="upload__image-wrapper"
                      >
                        {residentialProofFilePdf?.file ? (
                          <Grid
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                height: {
                                  xs: 125,
                                  sm: 150,
                                  md: 200,
                                },
                                width: 'auto',
                                margin: 'auto',
                                border: 'solid 1px #C7C7C7',
                                borderRadius: '5px',
                                p: '5%',
                              }}
                            >
                              {residentialProofFilePdf?.file.name}
                            </Typography>
                            <Fab size="small" onClick={() => setResidentialProofFilePdf(null)}>
                              <Remove />
                            </Fab>
                          </Grid>
                        ) : (
                          ''
                        )}
                        {imageList.map((image: any, index: any) => (
                          <div key={index} className="image-item">
                            <CardMedia
                              component={'img'}
                              src={dataURLtoBlobURL(image?.dataURL)}
                              alt={image?.name}
                              sx={{
                                height: {
                                  xs: 125,
                                  sm: 150,
                                  md: 200,
                                },
                                width: 'auto',
                                margin: 'auto',
                              }}
                            />
                            <Grid
                              className="image-item__btn-wrapper"
                              sx={{
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              <Fab size="small" onClick={() => onImageUpdate(index)}>
                                <Cached />
                              </Fab>
                              <Fab size="small" onClick={() => onImageRemove(index)}>
                                <Remove />
                              </Fab>
                            </Grid>
                          </div>
                        ))}
                      </Stack>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              )}
            </ImageUploading>
          </Stack>
        </Grid>
      ),
    },
  ];

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature dark-stepper'}
      PaperProps={{
        sx: {
          p: '20px 0',
          width: '85%',
          position: 'absolute',
          top: '5%',
        },
      }}
      // onClose={( ) => props.handleClose()}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Grid container>
          <Fab
            size="small"
            onClick={handleClose}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={'form-page'} sx={{ bgcolor: 'transparent', p: '0', minHeight: 'auto' }}>
          <form className={'form-root'} onSubmit={handleSubmit(onSubmit)}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                    sx={{
                      display: activeStep >= index ? 'inline-block' : 'none',
                    }}
                  >
                    {step.label}
                  </StepButton>
                  <StepContent>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: activeStep >= index ? 'inline-block' : 'none',
                      }}
                    >
                      {step.content}
                    </Grid>
                    <Grid sx={{ pt: '0px' }}>
                      <Stack direction={'row'}>
                        {activeStep === 0 ? (
                          ''
                        ) : (
                          <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                            Back
                          </Button>
                        )}
                        {activeStep === steps.length ? (
                          ''
                        ) : (
                          <Button
                            onClick={handleNext}
                            className={inputFields.id_number === '' || inputFields.id_type === '' || inputFields.id_exp === null || inputFields.id_issuer === '' || idSelfie?.length === 0 || pidFrontFile?.length === 0 || pidBackFile?.length === 0 || (props?.wallet?.type === 'BUSINESS' ? inputFields.business_registration === '' : inputFields.id_number === '') ? 'button-disabled' : 'button-green'}
                            disabled={inputFields.id_number === '' || inputFields.id_type === '' || inputFields.id_exp === null || inputFields.id_issuer === '' || idSelfie?.length === 0 || pidFrontFile?.length === 0 || pidBackFile?.length === 0 || (props?.wallet?.type === 'BUSINESS' ? inputFields.business_registration === '' : inputFields.id_number === '')}
                          >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            <Grid className={'next-move'} component={'span'}>
              {activeStep === steps.length ? (
                <Grid>
                  <Grid className={'content'}>
                    <h2>Perfect! You made it all the way through</h2>
                  </Grid>
                  <FormGroup className={'confirmations-actions'} sx={{ p: '20px 0' }}>
                    <FormControlLabel control={<Checkbox checked={confirmations.infoAuthenticity} onChange={handleConfirmations} name="infoAuthenticity" className="green-checkbox" />} label="By checking this box, I legally approved that all the information submitted are correct and authentique." />
                    <FormControlLabel control={<Checkbox checked={confirmations.legalNotice} onChange={handleConfirmations} name="legalNotice" className="green-checkbox" />} label={'By checking this box, I legally agreed on the legal terms & privacy terms that this platform is legislated on.'} />
                  </FormGroup>
                  <Grid
                    className={'actions'}
                    sx={{
                      textAlign: 'center',
                      p: '20px 0',
                    }}
                  >
                    {newKYCCase.loading || newLocation.loading || progress > 0 ? (
                      <Grid sx={{ textAlign: 'center' }}>
                        <CircularProgress className="orange-loader" />
                        <br />
                        Processing your information
                        <br />
                        please wait...
                      </Grid>
                    ) : (
                      <Grid>
                        <Button onClick={handleClose} className={'actionTwo button-cancel'} endIcon={<Clear />}>
                          Cancel
                        </Button>
                        <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                          Back
                        </Button>
                        <LoadingButton type="submit" loadingPosition="end" loading={progress > 0 || newKYCCase.loading || newLocation.loading} disabled={!confirmations.legalNotice || !confirmations.infoAuthenticity} className={!confirmations.legalNotice || !confirmations.infoAuthenticity ? 'button-disabled' : 'button-green'} endIcon={<Send />}>
                          Submit verification
                        </LoadingButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

NewKYCModal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  secondaryContent: PropTypes.any,
  actionOneTitle: PropTypes.any,
  actionTwoTitle: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.any,
};

NewKYCModal.defaultProps = {
  secondaryContent: '',
  loading: false,
};
