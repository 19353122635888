import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, Divider, Fab, Grid, Avatar, TextField, MenuItem } from '@mui/material';
import { Close } from '@mui/icons-material';
import _ from 'lodash';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../layout/Modal';
import { TROPHY_RANKS } from '../../utilities/utilities';
import { selectPlayer } from '../../reducers/playerSlice';

export default function AssignTournamentTrophyModal(props: { tournament_id: string; trophy_id: string; handleClose: any; open: any }) {
  const FIND_TOURNAMENT_BY_ID_QUERY = gql`
    query findTournamentByID($id: ID!) {
      findTournamentByID(id: $id) {
        id
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        trophies {
          id
          name
          note
          rank
          winners {
            ... on TeamPlayer {
              __typename
              id
            }
            ... on TeamLineup {
              __typename
              id
            }
            ... on FootballTeam {
              __typename
              id
            }
          }
        }
        phase_playoffs {
          id
          stage_name
          position
          game {
            id
            short_id
            title
            status
            start_date
            end_date
            home_team {
              id
              name
              team {
                ... on FootballTeam {
                  __typename
                  id
                  name
                  avatar {
                    path
                  }
                }
              }
            }
            away_team {
              id
              name
              team {
                ... on FootballTeam {
                  __typename
                  id
                  name
                  avatar {
                    path
                  }
                }
              }
            }
            results {
              id
              statistics {
                id
                nb_goals
                team_player {
                  id
                  team_player {
                    ... on FootballTeam {
                      __typename
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const ASSIGN_TOURNAMENT_TROPHY_TO_TEAM_MUTATION = gql`
    mutation assignTournamentTrophyToTeam($trophy: inputAssignTournamentTrophyToTeam!) {
      assignTournamentTrophyToTeam(trophy: $trophy)
    }
  `;

  const END_TOURNAMENT_MUTATION = gql`
    mutation endTournament($tournament: inputKickStartTournament!) {
      endTournament(tournament: $tournament)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const { control, watch, reset } = useForm({
    defaultValues: {
      selected_team: '',
    },
  });
  const inputFields = watch();

  const [endTournament, endedTournament] = useMutation(END_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [assignTournamentTrophyToTeam, assignedTrophy] = useMutation(ASSIGN_TOURNAMENT_TROPHY_TO_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.tournament_id,
    },
  });

  const SELECTED_TROPHY = tournament?.trophies?.find((t: any) => t?.id === props.trophy_id);
  const SELECTED_TEAMS = tournament?.phase_playoffs
    ?.filter((s: any) => s?.stage_name === 'FINAL_2' || s?.stage_name === 'FINAL_4')
    ?.map((s: any) => _.concat(s?.game?.home_team, s?.game?.away_team)?.filter((t: any) => t))
    ?.flat();

  const handleClose = () => {
    props.handleClose();
    reset();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedTournament.refetch({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        skip: !session?.token?.key,
        id: props.tournament_id,
      });

      if (result) {
        setTournament(result.data?.findTournamentByID);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAssignTrophyToWinner = async () => {
    try {
      await assignTournamentTrophyToTeam({
        variables: {
          trophy: {
            id: props.trophy_id,
            tournament_id: props.tournament_id,
            team_lineup_id: inputFields.selected_team,
          },
        },
      });

      if (tournament?.trophies?.filter((t: any) => t?.winners?.length === 0)?.length === 1) {
        await endTournament({
          context: {
            headers: {
              'Authorization': `Bearer ${session?.token?.key}`,
              'X-Anonymous-Access': 'false',
            },
          },
          variables: {
            tournament: {
              id: tournament?.id,
              logged_player_id: loggedPlayer?.id,
            },
          },
        });
      }

      await handleRefetch();
      setIsConfirmed(false);
    } catch (e) {
      console.error(e);
    } finally {
      handleClose();
    }
  };

  React.useEffect(() => {
    if (props.open && tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByID) {
      setTournament(loadedTournament.data?.findTournamentByID);
    }

    if (!props.open && tournament) {
      setTournament(UNDEFINED_AS_ANY);
    }
  }, [tournament, loadedTournament, setTournament, props]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={handleClose}
            disabled={assignedTrophy.loading || endedTournament.loading}
            className={assignedTrophy.loading || endedTournament.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}></Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Grid sx={{ '& .MuiSvgIcon-root': { fontSize: '4em' } }}>
          {TROPHY_RANKS?.find((r: any) => r.value === SELECTED_TROPHY?.rank)?.icon}
          <Typography sx={{ fontSize: '1.3em', fontWeight: '700' }}>{SELECTED_TROPHY?.name}</Typography>
          <Typography variant="caption">{SELECTED_TROPHY?.note}</Typography>
        </Grid>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <Controller
          control={control}
          name="selected_team"
          render={({ field }: any) => (
            <TextField {...field} value={field.value} select label={`Team`} placeholder="Select your team" variant="outlined" required fullWidth className={'field-bottom-space'}>
              {SELECTED_TEAMS?.map((option: any) => (
                <MenuItem key={option?.id} value={option?.id}>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Avatar src={option?.team?.avatar?.path} alt={option?.name} />
                    &nbsp;
                    <strong>{option?.name}</strong>
                  </Grid>
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Grid container sx={{ justifyContent: 'center' }}>
          <Fab variant="extended" size="small" disabled={SELECTED_TROPHY?.winners?.length > 0 || !inputFields.selected_team || inputFields.selected_team === ''} className={SELECTED_TROPHY?.winners?.length > 0 || !inputFields.selected_team || inputFields.selected_team === '' ? 'button-disabled' : 'button-green'} onClick={() => setIsConfirmed(true)}>
            Assign trophy
          </Fab>
          <Modal
            open={isConfirmed}
            title={''}
            content={
              <>
                Do you confim <strong>{SELECTED_TEAMS?.find((t: any) => t?.id === inputFields.selected_team)?.name}</strong> to receive <strong>{SELECTED_TROPHY?.name}</strong>?
              </>
            }
            secondaryContent={'This actions is irreversable'}
            actionOneTitle={'Yes'}
            actionOne={handleAssignTrophyToWinner}
            actionTwoTitle={'No'}
            actionTwo={() => setIsConfirmed(false)}
            loading={assignedTrophy.loading || endedTournament.loading}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

AssignTournamentTrophyModal.propTypes = {
  tournament_id: PropTypes.string.isRequired,
  trophy_id: PropTypes.string.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
};

AssignTournamentTrophyModal.defaultProps = {
  tournament_id: '',
};
