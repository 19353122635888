import React from 'react';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Avatar, CircularProgress, Fab, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from '@mui/material';
import { KeyboardDoubleArrowRight, Close, ContentCopy } from '@mui/icons-material';
import Moment from 'react-moment';
import { PAYMENT_METHODS, ALTERNATIVE_PAYMENTS } from '../../utilities/utilities';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';
import { useIntl } from 'react-intl';
import { selectPersonalWallets } from '../../reducers/walletSlice';
import { Link } from 'react-router-dom';

export default function ViewTransactionModal(props: { tx_id: string; open: any; handleClose: any }) {
  const FIND_TRANSACTION_BY_ID_QUERY = gql`
    query findTransactionByID($transaction: inputFindTransactionByID!) {
      findTransactionByID(transaction: $transaction) {
        id
        tx_reference
        status
        type
        value {
          value
          currency {
            currency_code
          }
        }
        maat_value {
          value
          currency {
            currency_code
          }
        }
        tx_fee {
          value
          currency {
            currency_code
          }
        }
        assets {
          ... on TokenInvoice {
            __typename
            id
            token_id
          }
        }
        sender_wallet {
          id
          title
          owner {
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
              owner {
                firstname
                lastname
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
          }
        }
        sender_payment_method {
          id
          name
          label
          payment_source {
            ... on BankCard {
              __typename
              id
              network
            }
            ... on BlockchainCard {
              __typename
              id
              network
            }
            ... on BankAccount {
              __typename
              id
              title
            }
            ... on BlockchainAccount {
              __typename
              id
              title
            }
            ... on Cash {
              __typename
              id
              name
            }
            ... on Gold {
              __typename
              id
              name
            }
            ... on Waste {
              __typename
              id
              name
            }
          }
        }
        receiver_wallet {
          id
          title
          owner {
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
              owner {
                firstname
                lastname
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
          }
        }
        receiver_payment_method {
          id
          name
          label
          payment_source {
            ... on BankCard {
              __typename
              id
              network
            }
            ... on BlockchainCard {
              __typename
              id
              network
            }
            ... on BankAccount {
              __typename
              id
              title
            }
            ... on BlockchainAccount {
              __typename
              id
              title
            }
            ... on Cash {
              __typename
              id
              name
            }
            ... on Gold {
              __typename
              id
              name
            }
            ... on Waste {
              __typename
              id
              name
            }
          }
        }
        receiver_blockchain_account {
          id
          title
          wallets {
            id
            type
          }
        }
        signatories {
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        execution_date
        confirmed_at
      }
    }
  `;

  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedUserPersonalWallets = useAppSelector(selectPersonalWallets);
  const [transaction, setTransaction] = React.useState(UNDEFINED_AS_ANY);
  const [copied, setCopied] = React.useState(false);
  const loadedTransaction = useQuery(FIND_TRANSACTION_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      transaction: {
        id: props.tx_id,
      },
    },
  });

  const ALL_WALLETS = _.concat(loggedUserPersonalWallets);

  const handleCopyReference = (ref: string) => {
    navigator.clipboard
      .writeText(ref)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((e) => console.error(e));
  };

  React.useEffect(() => {
    if (transaction === undefined && loadedTransaction.called && loadedTransaction.data?.findTransactionByID) {
      setTransaction(loadedTransaction.data?.findTransactionByID);
    }

    if ((!props?.open && transaction !== undefined) || (!props?.open && transaction?.id !== props?.tx_id)) {
      setTransaction(undefined);
    }
  }, [props, transaction, loadedTransaction]);

  return (
    <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'} PaperProps={{ sx: { minWidth: '70%' } }} BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, .2)' } }} keepMounted={false} onClose={props.handleClose}>
      <Fab size="small" onClick={props.handleClose} sx={{ boxShadow: 'none', bgcolor: 'transparent', ml: '2%', mt: '2%' }}>
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', pb: '0', textTransform: 'uppercase' }}>
        <Typography sx={{ fontSize: '1.3em', fontWeight: '700' }}>Transaction</Typography>
        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          ref: <strong>{_.truncate(transaction?.tx_reference, { length: 21 })}</strong>&nbsp;
          {copied ? (
            <Typography sx={{ color: '#1faf92', fontWeight: '100' }}>copied!</Typography>
          ) : (
            <IconButton onClick={() => handleCopyReference(transaction?.tx_reference)}>
              <ContentCopy />
            </IconButton>
          )}
        </Typography>
        <Typography variant="caption" sx={{ pb: '0px', display: 'inline-block', width: '100%' }}>
          {`${intl.formatMessage({ id: 'Global_Status' })}`}:{' '}
          <Typography variant="caption" sx={{ fontWeight: '700' }} className={transaction?.status === 'PENDING' ? '' : transaction?.status}>
            {transaction?.status?.replaceAll('_', ' ')}
          </Typography>
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loadedTransaction.loading || transaction === undefined ? (
          <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} className="green-loader" />
          </Grid>
        ) : (
          <DialogContentText id="alert-dialog-description" component={'div'}>
            <Grid container sx={{ pb: '30px' }}>
              <Grid item xl={5.5} xs={11.5} sm={5.5}>
                <Typography variant="caption" sx={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
                  Sender
                </Typography>
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Avatar src={transaction?.sender_wallet?.owner?.username ? transaction?.sender_wallet?.owner?.avatar?.path : transaction?.sender_wallet?.owner?.logo?.path} />
                  <Typography sx={{ p: '0 0 0 10px', fontWeight: '700' }}>{`${transaction?.sender_wallet?.owner?.username ? transaction?.sender_wallet?.owner?.owner?.firstname : transaction?.sender_wallet?.owner?.label} ${transaction?.sender_wallet?.owner?.username ? transaction?.sender_wallet?.owner?.owner?.lastname : ''}`}</Typography>
                </Grid>
                <Typography variant="caption" sx={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', '& svg': { fontSize: '3em' } }}>
                  via&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === transaction?.sender_payment_method?.name)?.icon}&nbsp;<strong>{ALTERNATIVE_PAYMENTS?.find((pm: any) => pm?.name === transaction?.sender_payment_method?.payment_source?.name)?.name.replaceAll('_', ' ')}</strong>{' '}
                  <Typography variant="caption" sx={{ fontSize: '.6em', fontWeight: '700' }}>
                    &nbsp;&nbsp;{ALTERNATIVE_PAYMENTS?.find((pm: any) => pm?.name === transaction?.sender_payment_method?.payment_source?.name)?.icon}
                  </Typography>
                </Typography>
              </Grid>

              <Grid className="separator" item xl={1} xs={12} sm={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <KeyboardDoubleArrowRight sx={{ m: '0 20px', color: 'rgba(31, 175, 146, 1)', transform: { xs: 'rotate(90deg)', sm: 'rotate(0deg)' } }} />
              </Grid>

              <Grid item xl={5.5} xs={11.5} sm={5.5}>
                <Typography variant="caption" sx={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
                  Receiver
                </Typography>
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Avatar src={transaction?.receiver_wallet?.owner?.username ? transaction?.receiver_wallet?.owner?.avatar?.path : transaction?.receiver_wallet?.owner?.logo?.path} />
                  <Typography sx={{ p: '0 0 0 10px', fontWeight: '700' }}>{`${transaction?.receiver_wallet?.owner?.username ? transaction?.receiver_wallet?.owner?.owner?.firstname : transaction?.receiver_wallet?.owner?.label} ${transaction?.receiver_wallet?.owner?.username ? transaction?.receiver_wallet?.owner?.owner?.lastname : ''}`}</Typography>
                </Grid>
                <Typography variant="caption" sx={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', '& svg': { fontSize: '3em' } }}>
                  via&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === transaction?.receiver_payment_method?.name)?.icon}
                </Typography>
              </Grid>
              <Grid item xl={12} xs={12} sm={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '1.4em', fontWeight: '700' }}>
                  {_.split(transaction?.value?.currency?.currency_code, '_')?.length > 1 ? _.split(transaction?.value?.currency?.currency_code, '_')[1] : transaction?.value?.currency?.currency_code} {transaction?.value?.value?.toLocaleString()}
                  {transaction?.receiver_blockchain_account?.id ? (
                    <>
                      <br />
                      <Typography variant="caption" sx={{ textTransform: 'none' }}>
                        Funds sent to&nbsp;
                        <Typography
                          variant="caption"
                          component={ALL_WALLETS?.find((w: any) => w?.id === transaction?.receiver_wallet?.id) ? Link : 'span'}
                          to={`/bc/account`}
                          state={ALL_WALLETS?.find((w: any) => w?.id === transaction?.receiver_wallet?.id) ? { account: { id: transaction?.receiver_blockchain_account?.id }, wallet: { id: transaction?.receiver_blockchain_account?.wallets?.[0]?.id, type: transaction?.receiver_blockchain_account?.wallets?.[0]?.type } } : undefined}
                          sx={{ fontWeight: '800', textDecoration: 'none', color: 'inherit' }}
                        >
                          {transaction?.receiver_blockchain_account?.title}
                        </Typography>
                      </Typography>
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Grid>
                  {transaction?.value?.currency?.currency_code !== 'MAT' ? (
                    <Typography variant="caption">
                      {transaction?.maat_value?.currency?.currency_code} {transaction?.maat_value?.value?.toLocaleString()}&nbsp;&nbsp;-&nbsp;&nbsp;
                    </Typography>
                  ) : (
                    ''
                  )}
                  {transaction?.tx_fee ? (
                    <Typography variant="caption">
                      fee: {transaction?.tx_fee?.currency?.currency_code} {transaction?.tx_fee?.value?.toLocaleString()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid>
                  <Typography variant="caption">
                    created: <Moment format="D/MM/yyyy - HH:mm:ss">{transaction?.execution_date}</Moment>
                  </Typography>
                  {transaction?.confirmed_at ? (
                    <Typography variant="caption">
                      &nbsp;|&nbsp;confirmed: <Moment format="D/MM/yyyy - HH:mm:ss">{transaction?.execution_date}</Moment>
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}

ViewTransactionModal.propTypes = {
  deal_id: PropTypes.any,
  open: PropTypes.any,
};

ViewTransactionModal.defaultProps = {
  loading: false,
};
