import React from 'react';
import { CardMedia, Grid } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { REACT_APP_LILENEK_URL } from '../../config';
import { gql, useMutation } from '@apollo/client';

interface ISponsoredVideoWrapper {
  children: React.ReactNode;
  sponsors: any;
}

const SponsoredVideoWrapper: React.FC<ISponsoredVideoWrapper> = (props: ISponsoredVideoWrapper) => {
  const CLICK_SPONSORSHIP_PROPOSAL_MUTATION = gql`
    mutation clickSponsorshipProposal($id: ID!) {
      clickSponsorshipProposal(id: $id)
    }
  `;

  const [clickSponsorshipProposal] = useMutation(CLICK_SPONSORSHIP_PROPOSAL_MUTATION, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
  });

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 30000,
    cssEase: 'linear',
  };

  const handleClickBanner = async (banner_id: string) => {
    try {
      await clickSponsorshipProposal({
        variables: {
          id: banner_id,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid className="slider-container">
      {props.sponsors && props.sponsors?.length > 0 && (
        <Slider {...settings}>
          {props.sponsors
            ?.filter((s: any) => s?.proposals?.find((p: any) => p?.status === 'ACCEPTED'))
            ?.map((s: any, index: number) => (
              <Grid key={index} component={Link} to={`${REACT_APP_LILENEK_URL}/${s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name}`} target="_blank" onClick={() => handleClickBanner(s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.id)}>
                <CardMedia component="img" src={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.banner?.path} sx={{ height: '100px' }} />
              </Grid>
            ))}
        </Slider>
      )}
      {props.children}
      {props.sponsors && props.sponsors?.length > 0 && (
        <Slider {...settings}>
          {props.sponsors
            ?.filter((s: any) => s?.proposals?.find((p: any) => p?.status === 'ACCEPTED'))
            ?.map((s: any, index: number) => (
              <Grid key={index} component={Link} to={`${REACT_APP_LILENEK_URL}/${s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name}`} target="_blank" onClick={() => handleClickBanner(s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.id)}>
                <CardMedia component="img" src={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.banner?.path} sx={{ height: '100px' }} />
              </Grid>
            ))}
        </Slider>
      )}
    </Grid>
  );
};

export default React.memo(SponsoredVideoWrapper);
