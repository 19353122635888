import React from 'react';
import { Link, BrowserRouter, Routes, Route } from 'react-router-dom';
import _ from 'lodash';
import { Slide, Badge, Button, List, ListItemButton, SwipeableDrawer, CssBaseline, Grid, BottomNavigation, BottomNavigationAction, Paper, Avatar, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AddBoxOutlined } from '@mui/icons-material';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { translations } from './lang/translations';
import { HomeIcon, SingleMemberIcon, VersusIcon, ChallengeIcon, SearchIcon, FieldIcon, NewsIcon, FlexIcon } from './img/icons/Icons';

import { useAppSelector } from './redux/hooks';
import { selectSession } from './reducers/sessionSlice';
import { selectPlayer, selectPlayerActiveProfile } from './reducers/playerSlice';
import ScrollToTop from './component-modals/common/ScrollTop';
import SessionController from './component-modals/common/SessionController';
import { UAParser, IResult } from 'ua-parser-js';
import ipLocation from 'iplocation';

import Home from './components/Home';
import NoMatch from './components/NoMatch';
import Profile from './components/profile/Profile';
import ProfileEditForm from './components/profile/ProfileEditForm';
import LoginPage from './components/profile/LoginPage';
import FormSignIn2F from './components/profile/FormSignIn2F';
import SignUpPage from './components/profile/SignUpPage';
import OnBoarding from './components/profile/OnBoarding';
import FormNewWallet from './components/wallet/FormNewWallet';
import KYCNotice from './components/wallet/KYCNotice';
import Wallets from './components/wallet/Wallets';
import Wallet from './components/wallet/Wallet';
import FormAddBankCard from './components/wallet/FormAddBankCard';
import FormAddPaymentMethod from './components/wallet/FormAddPaymentMethod';
import EditConversation from './components/contacts/EditConversation';
import Conversation from './components/contacts/Conversation';
import ListContacts from './components/contacts/ListContacts';
import Flex from './components/flex_news/Flex';
import FormEditFlex from './components/flex_news/FormEditFlex';
import Settings from './components/preferences/Settings';
import Account from './components/preferences/Account';
import Languages from './components/preferences/Languages';
import ContactUs from './components/preferences/ContactUs';
import LegalMentions from './components/preferences/LegalMentions';
import BlockedList from './components/preferences/BlockedList';
import BankCard from './components/wallet/BankCard';
import PaymentMethod from './components/wallet/PaymentMethod';
import FormNewBlockchainAccount from './components/wallet/FormNewBlockchainAccount';
import BlockchainAccount from './components/wallet/BlockchainAccount';
import SwitchActiveProfile from './component-modals/common/SwitchActiveProfile';
import FormNewFootballer from './components/profile/FormNewFootballer';
import FootballCard from './components/profile/FootballCard';
import FormNewTeam from './components/teams/FormNewTeam';
import Teams from './components/teams/Teams';
import FormEditTeam from './components/teams/FormEditTeam';
import Challenges from './components/challenges/Challenges';
import FormNewChallenge from './components/challenges/FormNewChallenge';
import FootballTeam from './components/teams/FootballTeam';
import JoinTeam from './components/teams/JoinTeam';
import FormEditFootballer from './components/profile/FormEditFootballer';
import Challenge from './components/challenges/Challenge';
import FormNewTournament from './components/tournaments/FormNewTournament';
import TriggerPWA from './component-modals/common/TriggerPWA';
import FormEditChallenge from './components/challenges/FormEditChallenge';
import JoinComplexNetwork from './components/profile/JoinComplexNetwork';
import ListConversations from './components/contacts/ListConversations';
import EmailVerification from './components/profile/EmailVerification';
import { UNDEFINED_AS_ANY } from './utilities/CommonInterfaces';
import Field from './components/challenges/Field';
import NewFieldModal from './component-modals/challenge/FormNewFieldModal';
import Tournament from './components/tournaments/Tournament';
import FormEditTournament from './components/tournaments/FormEditTournament';
import Tournaments from './components/tournaments/Tournaments';
import RequestMatchMaking from './components/challenges/RequestMatchMaking';
import { REACT_APP_IP_INFO_TOKEN } from './config';
import EmailVerificationAlert from './component-modals/common/EmailVerificationAlert';
import MomentLocales from './lang/MomentLocales';
import MissingProfileAlert from './component-modals/common/MissingProfileAlert';
import NewFlexModal from './component-modals/contacts/NewFlexModal';
import FormNewLivestream from './components/contacts/FormNewLivestream';
import Livestream from './components/contacts/Livestream';
import Livestreams from './components/contacts/Livestreams';

interface IAppContent {
  currentPosition: any;
  currentDevice: any;
  registration: any;
}

const App: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);
  const session = useAppSelector(selectSession);

  const [device, setDevice] = React.useState<IResult>();
  const [currentPosition, setCurrentPosition] = React.useState<any>();
  const [value, setValue] = React.useState('home');
  const [openBottomMenu, setOpenBottomMenu] = React.useState(false);
  const [newField, setNewField] = React.useState(false);
  const [registration, setRegistration] = React.useState(UNDEFINED_AS_ANY);
  const [openNewFlex, setOpenNewFlex] = React.useState(false);

  const trigger = useScrollTrigger();
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const isMobile = useMediaQuery('(min-width:601px) and (max-width:767px)');
  const isTablet = useMediaQuery('(min-width:768px) and (max-width:1023px)');

  const handleChange = (event: React.ChangeEvent<any>, newValue: string) => {
    setValue(newValue);
  };

  const handleOpenNewField = () => {
    setNewField(true);
  };

  React.useEffect(() => {
    (async () => {
      try {
        const my_ip = await fetch(`https://ipinfo.io?token=${REACT_APP_IP_INFO_TOKEN}`);
        const ip_data = await my_ip.json();
        const location = await ipLocation(ip_data.ip);

        if (!currentPosition) {
          navigator.geolocation.getCurrentPosition(
            (position: any) => {
              setCurrentPosition(location || position.coords);
            },
            () => {
              setCurrentPosition(location);
            }
          );
        }

        if (!device) {
          const parser = new UAParser();
          setDevice(parser.getResult());
        }
      } catch (e) {
        // console.error(e);
        if (!currentPosition) {
          navigator.geolocation.getCurrentPosition(
            (position: any) => {
              setCurrentPosition(position.coords);
            },
            () => {
              setCurrentPosition(location);
            }
          );
        }
      }

      try {
        const r = await navigator.serviceWorker.getRegistration();
        setRegistration(r);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [currentPosition, device, registration]);

  React.useEffect(() => {
    if (session?.status && value !== window.location.pathname) {
      if (window.location.pathname === '/' && value !== 'home') {
        setValue('home');
      }

      if (window.location.pathname !== '/') {
        setValue(window.location.pathname?.replaceAll('/', ''));
      }
    }
  }, [value, session]);

  return (
    <BrowserRouter>
      <IntlProvider
        messages={translations?.find((t: any) => t?.value === (translations?.find((t: any) => t?.value === loggedPlayer?.app_configuration?.prefered_language) ? loggedPlayer?.app_configuration?.prefered_language : _.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1] === 'FR' ? 'FR' : 'GB'))?.content}
        locale={_.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1]}
        defaultLocale={_.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[0] ?? 'en'}
      >
        <AppContext.Provider value={{ currentPosition: currentPosition, currentDevice: device, registration: registration }}>
          <Grid container sx={{ height: '100vh', justifyContent: 'center' }}>
            <ScrollToTop />
            <CssBaseline />
            <SessionController />
            <TriggerPWA />
            <MomentLocales />

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                textAlign: 'center',
                position: 'fixed',
                width: '80%',
                zIndex: '120000000',
                display: window.location.pathname === '/onboarding' || window.location.pathname?.includes('/email_verification') ? 'none' : 'block',
              }}
            >
              <EmailVerificationAlert />
              <MissingProfileAlert />
            </Grid>

            <Grid className={'main-container'} sx={{ minHeight: '100vh' }}>
              <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/two_facts" element={<FormSignIn2F />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/onboarding" element={<OnBoarding />} />
                <Route path="/join_complex_network" element={<JoinComplexNetwork />} />

                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/edit" element={<ProfileEditForm />} />
                <Route path="/u/:userId" element={<Profile />} />
                <Route path="/u/:userId/edit" element={<ProfileEditForm />} />
                <Route path="/legal_mentions" element={<LegalMentions />} />

                <Route path="/new_footballer" element={<FormNewFootballer />} />
                <Route path="/football_card/:alias" element={<FootballCard />} />
                <Route path="/footballer_profile/edit" element={<FormEditFootballer />} />

                <Route path="/teams" element={<Teams />} />
                <Route path="/new_team" element={<FormNewTeam />} />
                <Route path="/football_team/:teamId" element={<FootballTeam />} />
                <Route path="/football_team/edit" element={<FormEditTeam />} />
                <Route path="/join_team" element={<JoinTeam />} />

                <Route path="/challenges" element={<Challenges />} />
                <Route path="/new_challenge" element={<FormNewChallenge />} />
                <Route path="/challenge/:challengeId" element={<Challenge />} />
                <Route path="/challenge/edit" element={<FormEditChallenge />} />
                <Route path="/find_challenge" element={<Challenges />} />
                <Route path="/find_opponent" element={<Challenges />} />

                <Route path="/new_livestream" element={<FormNewLivestream />} />
                <Route path="/ls/:livestreamId" element={<Livestream />} />
                <Route path="/livestreams" element={<Livestreams />} />

                <Route path="/feeds" element={<Livestreams />} />
                <Route path="/tournaments" element={<Tournaments />} />
                <Route path="/new_tournament" element={<FormNewTournament />} />
                <Route path="/tournament/:tournamentId" element={<Tournament />} />
                <Route path="/tournament/edit" element={<FormEditTournament />} />
                <Route path="/fd/:fieldId" element={<Field />} />

                <Route path="/f/:flexId" element={<Flex />} />
                <Route path="/f/flex/edit" element={<FormEditFlex />} />

                <Route path="/r/:matchmakingId" element={<RequestMatchMaking />} />

                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/languages" element={<Languages />} />
                <Route path="/settings/contact_us" element={<ContactUs />} />
                <Route path="/settings/blocked_accounts" element={<BlockedList />} />
                <Route path="/settings/wallets" element={<Wallets />} />
                <Route path="/settings/account" element={<Account />} />

                <Route path="/new_wallet" element={<FormNewWallet />} />
                <Route path="/kyc_notice" element={<KYCNotice />} />
                <Route path="/w/wallet" element={<Wallet />} />
                <Route path="/w/wallets" element={<Wallets />} />
                <Route path="/bk/add_bank_card" element={<FormAddBankCard />} />
                <Route path="/bk/card" element={<BankCard />} />

                <Route path="/bc/new_blockchain_account" element={<FormNewBlockchainAccount />} />
                <Route path="/bc/account" element={<BlockchainAccount />} />

                <Route path="/pm/add_payment_method" element={<FormAddPaymentMethod />} />
                <Route path="/pm/payment_method" element={<PaymentMethod />} />

                <Route path="/conversations" element={<ListConversations />} />
                <Route path="/c/:conversationId" element={<Conversation />} />
                <Route path="/c/conversation/edit" element={<EditConversation />} />
                <Route path="/list_contacts" element={<ListContacts />} />

                <Route path="/email_verification/:key" element={<EmailVerification />} />

                {/* MUST STAY AT LAST POSITION */}

                <Route path="*" element={<NoMatch />} />
              </Routes>
            </Grid>

            {(isSmallMobile || isMobile || isTablet) && window.location.pathname !== '/onboarding' ? (
              <Slide appear={false} direction="up" in={!trigger}>
                <Paper
                  sx={{
                    position: 'fixed',
                    zIndex: '1100',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    borderRadius: '0',
                  }}
                  elevation={3}
                  className={'bottom-nav'}
                >
                  <BottomNavigation value={value} onChange={handleChange} sx={{ 'bgcolor': 'rgba(15,15,15,1)', '& .MuiSvgIcon-root': { fontSize: { xs: '1.1em', sm: '1.4em', md: '1.5em' } }, '& a.MuiBottomNavigationAction-root': { 'minWidth': '20px', '& .MuiBottomNavigationAction-label': { fontSize: { xs: '.6em', sm: '.8em', md: '.8em' } } } }}>
                    <BottomNavigationAction label={<FormattedMessage id="App_Home" />} value="home" component={Link} to="/" icon={<HomeIcon />} />
                    <BottomNavigationAction
                      label={<FormattedMessage id="App_Challenges" />}
                      value="challenges"
                      component={Link}
                      to={session?.status ? `/challenges` : '/login'}
                      sx={{
                        display: session?.status ? 'inline-flex' : 'none',
                      }}
                      icon={
                        <Badge badgeContent={0} color="error">
                          <ChallengeIcon />
                        </Badge>
                      }
                    />
                    <BottomNavigationAction
                      label={
                        <Typography id="tag-font" sx={{ fontSize: { xs: '1em', sm: '1.2em', md: '1.4em' } }}>
                          {_.shuffle(['#DifferentAnimal', '#SameBeast'])[0]}
                        </Typography>
                      }
                      value="home"
                      onClick={() => setOpenBottomMenu(true)}
                      sx={{
                        display: session?.status ? 'inline-flex' : 'none',
                      }}
                      icon={<AddBoxOutlined sx={{ height: { xs: '20px', md: '25px' }, width: 'auto', color: 'rgba(102, 130, 19, 1)' }} />}
                    />
                    <BottomNavigationAction
                      label={<FormattedMessage id="App_Feeds" />}
                      value="feeds"
                      component={Link}
                      to={session?.status ? `/feeds` : '/login'}
                      sx={{
                        display: session?.status ? 'inline-flex' : 'none',
                      }}
                      icon={<NewsIcon />}
                    />
                    <BottomNavigationAction
                      label={<>{loggedPlayerActiveProfile?.role || loggedPlayer?.username || <FormattedMessage id="App_User" />}</>}
                      // showLabel={loggedPlayer?.id !== undefined}
                      value="profile"
                      component={Link}
                      to={session?.status ? `/profile` : '/login'}
                      icon={
                        session?.status ? (
                          <Avatar
                            src={loggedPlayer?.avatar?.path}
                            sx={{
                              background: '#F7FBFA',
                              border: 'solid 1px #F7FBFA',
                              height: {
                                xs: '30px',
                                sm: '32px',
                                md: '35px',
                                xl: '40px',
                              },
                              width: {
                                xs: '30px',
                                sm: '32px',
                                md: '35px',
                                xl: '40px',
                              },
                              margin: 'auto',
                            }}
                          />
                        ) : (
                          <SingleMemberIcon />
                        )
                      }
                      sx={{ textAlign: 'center' }}
                    />
                  </BottomNavigation>
                </Paper>
              </Slide>
            ) : (
              ''
            )}

            <SwipeableDrawer
              className="modal-feature"
              anchor={'bottom'}
              open={openBottomMenu}
              onClose={() => setOpenBottomMenu(false)}
              onOpen={() => setOpenBottomMenu(true)}
              ModalProps={{
                keepMounted: false,
                sx: { backdropFilter: 'blur(8px)' },
              }}
              PaperProps={{
                sx: {
                  borderTopRightRadius: '25px',
                  borderTopLeftRadius: '25px',
                },
              }}
            >
              <Grid>
                <Grid container sx={{ pt: '20px' }}>
                  <SwitchActiveProfile color={'#FFF'} />
                </Grid>
                <List onClick={() => setOpenBottomMenu(false)} onKeyDown={() => setOpenBottomMenu(false)} sx={{ p: '0 0 50px' }}>
                  <ListItemButton divider>
                    <Button
                      variant="text"
                      onClick={handleOpenNewField}
                      fullWidth
                      sx={{
                        'color': '#FFF !important',
                        'fontWeight': '700',
                        '&:hover': { bgcolor: 'transparent !important', color: `${loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                      }}
                      startIcon={<FieldIcon />}
                    >
                      <FormattedMessage id="App_New_Field" />
                    </Button>
                  </ListItemButton>
                  <ListItemButton divider>
                    <Button
                      variant="text"
                      component={Link}
                      to={session?.status ? '/find_challenge' : '/'}
                      state={{ index: 0 }}
                      fullWidth
                      sx={{
                        'color': '#FFF !important',
                        'fontWeight': '700',
                        '&:hover': { bgcolor: 'transparent !important', color: `${loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                      }}
                      startIcon={<SearchIcon />}
                    >
                      <FormattedMessage id="App_Find_Challenge" />
                    </Button>
                  </ListItemButton>
                  <ListItemButton divider>
                    <Button
                      variant="text"
                      component={Link}
                      to={session?.status ? '/new_challenge' : '/'}
                      fullWidth
                      sx={{
                        'color': '#FFF !important',
                        'fontWeight': '700',
                        '&:hover': { bgcolor: 'transparent !important', color: `${loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                      }}
                      startIcon={<VersusIcon />}
                    >
                      <FormattedMessage id="App_New_Challenge" />
                    </Button>
                  </ListItemButton>
                  <ListItemButton>
                    <Button
                      variant="text"
                      onClick={() => setOpenNewFlex(true)}
                      fullWidth
                      sx={{
                        'color': '#FFF !important',
                        'fontWeight': '700',
                        '&:hover': { bgcolor: 'transparent !important', color: `${loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                      }}
                      startIcon={<FlexIcon />}
                    >
                      <FormattedMessage id="App_New_Flex" />
                    </Button>
                  </ListItemButton>
                </List>

                {/* <Grid container sx={{ alignItems: 'center', color: 'rgba(15,15,15,0.4)' }}>
                  <DarkModeSwitch />
                  <SwitchLanguageButton />
                </Grid> */}
              </Grid>
            </SwipeableDrawer>

            <NewFieldModal open={newField} handleClose={() => setNewField(false)} />
            <NewFlexModal open={openNewFlex} handleClose={() => setOpenNewFlex(false)} />
          </Grid>
        </AppContext.Provider>
      </IntlProvider>
    </BrowserRouter>
  );
};

export const AppContext = React.createContext<IAppContent>(UNDEFINED_AS_ANY);

export default App;
