import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@mui/material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import SelectDescriptionButton from './SelectDescriptionButton';
import { useForm } from 'react-hook-form';

interface IDescriptionsFormatter {
  descriptions: { language: string; content: string }[];
  max_length?: number;
  dark_mode?: boolean;
}

const DescriptionsFormatter: React.FC<IDescriptionsFormatter> = (props: IDescriptionsFormatter) => {
  const { control, watch, setValue } = useForm({
    defaultValues: {
      full_content: false,
      selected_content: UNDEFINED_AS_ANY,
      description_language: '',
    },
  });
  const inputFields = watch();

  const MAX_LENGTH = props?.max_length || 300;

  React.useEffect(() => {
    if (!inputFields.selected_content && props.descriptions?.length > 0 && props.descriptions?.[0]) {
      setValue('selected_content', props.descriptions?.[0]?.content);
    }

    if (props.descriptions?.length > 0 && inputFields.selected_content && props.descriptions?.find((d: any) => d?.language === inputFields.description_language)?.content !== inputFields.selected_content) {
      setValue('selected_content', props.descriptions?.find((d: any) => d?.language === inputFields.description_language)?.content);
    }

    if (inputFields.description_language === '' && props?.descriptions[0]?.language !== undefined) setValue('description_language', props?.descriptions[0]?.language);
  }, [inputFields.selected_content, inputFields.description_language]);

  return (
    <>
      {props.descriptions?.length > 1 ? (
        <>
          <SelectDescriptionButton control={control} descriptions={props.descriptions} show_label={false} dark_mode={props.dark_mode} />
          &nbsp;&nbsp;
        </>
      ) : (
        ''
      )}
      {_.truncate(inputFields?.selected_content, { length: inputFields.full_content ? inputFields?.selected_content?.length : MAX_LENGTH })}
      {inputFields?.selected_content?.length > MAX_LENGTH ? (
        <Button variant="text" onClick={() => setValue('full_content', !inputFields.full_content)} sx={{ color: 'inherit !important', textTransform: 'inherit', opacity: '.4' }}>
          {inputFields.full_content ? 'see less' : 'see more'}
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

DescriptionsFormatter.propTypes = {
  descriptions: PropTypes.array.isRequired,
  max_length: PropTypes.number,
  dark_mode: PropTypes.bool,
};

DescriptionsFormatter.defaultProps = {
  descriptions: [],
  max_length: undefined,
  dark_mode: false,
};

export default React.memo(DescriptionsFormatter);
