import React from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { Autocomplete, Button, Avatar, Stack, TextField, Fab, Grid, Typography, InputAdornment, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Clear, Send } from '@mui/icons-material';
import { FivezerIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import BackButton from '../../component-modals/common/BackButton';
import { ARRAY_AS_ANY, CountryType, DESCRIPTION_AS_ANY, IMAGE_LIST_AS_ANY, INITIAL_COUNTRY } from '../../utilities/CommonInterfaces';
import { COUNTRIES, FOOTS, POSITIONS } from '../../utilities/utilities';
import { Link } from 'react-router-dom';
import UploadIMGFeature from '../../component-modals/common/UploadIMGFeature';
import { handleUpload } from '../../helpers';
import InputDescriptionsFeature from '../../component-modals/common/InputDescriptionsFeature';
import InputSelectAvailabilitiesFeature from '../../component-modals/common/InputSelectAvailabilitiesFeature';
import { useIntl } from 'react-intl';

const FormNewFootballer: React.FC = () => {
  const CREATE_FOOTBALLER_MUTATION = gql`
    mutation createFootballer($player: inputCreateFootballer!) {
      createFootballer(player: $player) {
        id
        alias
      }
    }
  `;

  const SEND_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const FIND_FOOTBALLER_ALIAS_QUERY = gql`
    query findFootballerAlias($alias: String!) {
      findFootballerAlias(alias: $alias) {
        id
      }
    }
  `;

  const ADD_NEW_LOCATION_MUTATION = gql`
    mutation addAdditionalLocation($account: inputAddAdditionalLocation!) {
      addAdditionalLocation(account: $account) {
        id
      }
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      alias: '',
      nickname: '',
      descriptions: ARRAY_AS_ANY,
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',
      address_title: '',
      street_name: '',
      secondary_street_name: '',
      postcode: '',
      city: '',
      county: '',
      state: '',
      country: INITIAL_COUNTRY,
      position: 'GK',
      best_foot: 'RF',
      jersey_number: 0,
      day: 'MONDAY',
      hour: 6,
      availabilities: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();

  const [country, setCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [progress, setProgress] = React.useState(0);
  const [avatar, setAvatar] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [avatarUrl, setAvatarUrl] = React.useState([]);
  const [cover, setCover] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [coverUrl, setCoverUrl] = React.useState([]);

  const [createFootballer, newFootballer] = useMutation(CREATE_FOOTBALLER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendNotification, isSent] = useMutation(SEND_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [addAdditionalLocation, newLocation] = useMutation(ADD_NEW_LOCATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const existingAlias = useQuery(FIND_FOOTBALLER_ALIAS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { alias: inputFields.alias },
  });

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setCountry(country);
      setValue('country', country);
    }
  };

  const handleBestFoot = async (newBestFoot: string) => {
    if (newBestFoot !== null) {
      setValue('best_foot', newBestFoot);
    }
  };

  const onChangeAvatar = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setAvatar(imageList);
  };

  const onChangeCover = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setCover(imageList);
  };

  const handlePosition = async (position: string) => {
    if (position !== null) {
      setValue('position', position);
    }
  };

  const handleJerseyNumber = async (number: number) => {
    setValue('jersey_number', number);
  };

  const handleCreateFootballer = async () => {
    try {
      setProgress(1);

      if (avatar[0]?.file) {
        const avatarPromise = await handleUpload(avatar[0]?.file, avatarUrl, setAvatarUrl, session, loggedPlayer.id);
        if (avatarPromise) await Promise.all(avatarPromise);
      }

      if (cover[0]?.file) {
        const coverPromise = await handleUpload(cover[0]?.file, coverUrl, setCoverUrl, session, loggedPlayer.id);
        if (coverPromise) await Promise.all(coverPromise);
      }

      const result = await createFootballer({
        variables: {
          player: {
            player_id: loggedPlayer?.id,
            alias: inputFields.alias,
            nickname: inputFields.nickname,
            gender: loggedPlayer?.owner?.gender,
            position: inputFields.position,
            best_foot: inputFields.best_foot,
            jersey_number: parseInt(inputFields.jersey_number.toString()),
            bios: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
            avatar: {
              filename: avatar[0]?.file ? avatar[0].file.name : '',
              type: avatar[0]?.file ? avatar[0].file.type : '',
              size: avatar[0]?.file ? avatar[0].file.size : 0,
              path: avatarUrl[0],
            },
            cover: {
              filename: cover[0]?.file ? cover[0].file.name : '',
              type: cover[0]?.file ? cover[0].file.type : '',
              size: cover[0]?.file ? cover[0].file.size : 0,
              path: coverUrl[0],
            },
            originate_location: {
              title: inputFields.address_title,
              street_name: inputFields.street_name,
              secondary_street_name: inputFields.secondary_street_name,
              postcode: inputFields.postcode,
              city: inputFields.city,
              county: inputFields.county,
              state: inputFields.state,
              country_code: country.value,
              country: country.label,
              continent: country.continent,
            },
            availabilities: _.uniq(inputFields.availabilities?.map((a: any) => a?.day))?.map((day: any) => {
              return {
                day: day,
                allday: false,
                timeslots: inputFields.availabilities?.filter((a: any) => a?.day === day)?.map((a: any) => a?.hour),
              };
            }),
          },
        },
      });

      if (!newLocation.called && loggedPlayer?.locations?.length === 0) {
        await addAdditionalLocation({
          variables: {
            account: {
              buyer_id: loggedPlayer?.id,
              location: {
                title: `${loggedPlayer?.username}'s address`,
                street_name: inputFields.street_name,
                secondary_street_name: inputFields.secondary_street_name,
                country_code: country?.value,
                postcode: inputFields.postcode,
                city: inputFields.city,
                county: inputFields.county,
                state: inputFields.state,
                country: country?.label,
                continent: country?.continent,
              },
            },
          },
        });
      }

      if (result.data?.createFootballer !== undefined) {
        try {
          await sendNotification({
            variables: {
              notification: {
                title: `${intl.formatMessage({ id: 'Notification_New_Footballer_Title' })}`,
                content: `${intl.formatMessage({ id: 'Notification_New_Footballer_Content_1' })} ${result.data?.createFootballer?.alias}! ${intl.formatMessage({ id: 'Notification_New_Footballer_Content_2' })}`,
                actions: [`VIEW_PLAYER_PROFILE_@_${loggedPlayer?.username}`],
                sender_id: loggedPlayer?.id,
                receivers_ids: [loggedPlayer?.id],
              },
            },
          });
        } catch (e) {
          console.log(e);
        }

        navigate(`/profile`, {
          replace: true,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
    if (inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');
  }, [inputFields, setValue]);

  return (
    <Grid container className={'form-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerIcon sx={{ width: '50px', height: 'auto', p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Typography className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
          {`${intl.formatMessage({ id: 'FormNewFootballer_Title' })}`}
        </Typography>

        {loggedPlayer?.profiles?.find((p: any) => p?.__typename === 'Footballer') ? (
          <Grid sx={{ textAlign: 'center' }}>
            <Typography sx={{ m: '30px 0' }}>{`${intl.formatMessage({ id: 'FormNewFootballer_Existing_Footballer' })}`}</Typography>
            <Avatar src={loggedPlayer?.profiles?.find((p: any) => p?.__typename === 'Footballer')?.avatar?.path} alt={loggedPlayer?.profiles?.find((p: any) => p?.__typename === 'Footballer')?.alias} sx={{ width: '150px', height: '150px', m: 'auto' }} component={Link} replace to={`/profile`} />
            <Fab variant="extended" size="small" className="button-green" component={Link} sx={{ mt: '20px' }} replace to={`/profile`}>
              {`${intl.formatMessage({ id: 'FormNewFootballer_See_Existing_Footballer' })}`}
            </Fab>
          </Grid>
        ) : (
          <form className={'form-root'}>
            <UploadIMGFeature pictures={cover} setter_pictures={onChangeCover} max_number={1} ratio="cover" media_type="cover" />

            <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ textAlign: 'center', p: '10px 0', height: avatar?.length > 0 && cover?.length > 0 ? { xs: '70px', sm: '100px', md: '200px' } : 'auto' }}>
              <UploadIMGFeature pictures={avatar} setter_pictures={onChangeAvatar} hasCover={cover?.length > 0} max_number={1} ratio="avatar" media_type="avatar" />
            </Stack>

            <Grid id="max-width" sx={{ p: '50px 10% 0' }}>
              <Controller
                name="alias"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-z0-9_]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormNewFootballer_Alias_Label' })}`}
                    placeholder={`${intl.formatMessage({ id: 'FormNewFootballer_Alias_Placeholder' })}`}
                    variant="outlined"
                    fullWidth
                    required
                    className={'field-bottom-space'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ '& p': { color: 'rgba(247, 251, 250, 0.3)' } }}>
                          @
                        </InputAdornment>
                      ),
                    }}
                    value={_.replace(field.value.toLowerCase(), /[^a-z0-9_]+/g, '_')}
                    error={errors?.alias?.type !== undefined}
                    helperText={errors?.alias !== undefined || existingAlias.data?.findFootballerAlias?.length > 0 ? `${errors?.alias?.type ? (field.value.length < 4 ? 'Name must have at least 4 characters' : 'You should ONLY use letters, numbers or underscore') : `${field.value} is taken already`}` : ''}
                  />
                )}
              />

              <Controller
                name="nickname"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  // pattern: /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF0-9_]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormNewFootballer_Nickname_Label' })}`}
                    placeholder={`${intl.formatMessage({ id: 'FormNewFootballer_Nickname_Placeholder' })}`}
                    variant="outlined"
                    fullWidth
                    className={'field-bottom-space'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ color: 'rgba(247, 251, 250, 0.3)' }}>
                          {inputFields.alias} aka
                        </InputAdornment>
                      ),
                    }}
                    value={field.value}
                    error={errors?.nickname?.type !== undefined}
                    helperText={errors?.nickname !== undefined ? `${errors?.nickname?.type ? (field.value.length < 4 ? 'Name must have at least 4 characters' : 'You should ONLY use letters, numbers or underscore') : ``}` : ''}
                  />
                )}
              />

              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormNewFootballer_Bio_Title' })}`}
              </Typography>
              <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

              <Typography sx={{ fontWeight: '700', p: '30px 0 20px' }}>{`${intl.formatMessage({ id: 'FormNewFootballer_Which_Area' })}`}</Typography>
              <Controller
                name="address_title"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'FormNewFootballer_Area_Title_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewFootballer_Area_Title_Placeholder' })}`} variant="outlined" fullWidth required value={field.value} error={errors?.address_title !== undefined} helperText={errors?.address_title !== undefined ? `${errors?.address_title?.message}` : ''} className={'field-bottom-space'} />}
              />

              <Controller
                control={control}
                name="country"
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    options={COUNTRIES}
                    value={country}
                    className={'country-select'}
                    onChange={(event: any, value: any) => onChangeCountry(event, value)}
                    autoHighlight
                    getOptionLabel={(option: CountryType) => option.label}
                    isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                    renderOption={(props: any, option: CountryType) => (
                      <Grid
                        component="li"
                        sx={{
                          '& > img': {
                            mr: 2,
                            flexShrink: 0,
                          },
                        }}
                        {...props}
                      >
                        <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                        {option.label} ({option.value})
                      </Grid>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${intl.formatMessage({ id: 'Global_Address_Country' })}`}
                        value={country}
                        required
                        variant="outlined"
                        className={'field-bottom-space'}
                        error={errors?.country !== undefined}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
              />

              {/* <Controller
                name="street_name"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label="Street address" autoComplete="address-line1" placeholder="Write your company's street address" variant="outlined" fullWidth value={field.value} error={errors?.street_name !== undefined} helperText={errors?.street_name !== undefined ? `${errors?.street_name?.message}` : ''} className={'field-bottom-space'} />}
              />

              <Controller
                name="secondary_street_name"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label="Street address (complement)" autoComplete="address-line2" placeholder="Complement of company's street address" variant="outlined" fullWidth value={field.value} error={errors?.secondary_street_name !== undefined} helperText={errors?.secondary_street_name !== undefined ? `${errors?.secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} className={'field-bottom-space'} />}
              /> */}

              <Controller
                name="postcode"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'Global_Address_Postcode_1' })}`}
                    required
                    placeholder={`${intl.formatMessage({ id: 'Global_Address_Postcode_2' })}`}
                    autoComplete="postal-code"
                    variant="outlined"
                    value={_.replace(field.value, /[^a-zA-Z0-9-\s]+/g, '')}
                    error={errors?.postcode !== undefined}
                    helperText={errors?.postcode !== undefined ? `${errors?.postcode?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      mr: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="city"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'Global_Address_City_1' })}`}
                    required
                    placeholder={`${intl.formatMessage({ id: 'Global_Address_City_2' })}`}
                    autoComplete="address-level2"
                    variant="outlined"
                    value={field.value}
                    error={errors?.city !== undefined}
                    helperText={errors?.city !== undefined ? `${errors?.city?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      ml: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              {/* <Controller
                name="county"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label="County"
                    placeholder="Company's county"
                    variant="outlined"
                    value={field.value}
                    error={errors?.county !== undefined}
                    helperText={errors?.county !== undefined ? `${errors?.county?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      mr: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="state"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label="State"
                    autoComplete="address-level1"
                    placeholder="Company's state"
                    variant="outlined"
                    value={field.value}
                    error={errors?.state !== undefined}
                    helperText={errors?.state !== undefined ? `${errors?.state?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      ml: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              /> */}
            </Grid>
            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 20px' }}>
              {`${intl.formatMessage({ id: 'FormNewFootballer_Position_Title' })}`}
            </Typography>
            <Grid id="max-width" className={'position'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
              <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                {POSITIONS.map((position: any) => (
                  <Button
                    key={position.value}
                    value={position.value}
                    onClick={() => handlePosition(position.value)}
                    sx={{
                      'height': '110px',
                      'width': '150px',
                      'm': '0 20px 0',
                      'display': 'inline-block',
                      'borderRadius': '10px',
                      'border': inputFields.position === position.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                      'color': inputFields.position === position.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                      '& svg': { fontSize: '40px', color: inputFields.position === position.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                    }}
                  >
                    {position?.icon}
                    <br />
                    {`${intl.formatMessage({ id: position.label })}`}
                  </Button>
                ))}
              </Stack>
            </Grid>

            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 20px' }}>
              {`${intl.formatMessage({ id: 'FormNewFootballer_Jersey_Title' })}`}
            </Typography>
            <Grid className={'jersey-number'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
              <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                {_.range(100).map((number: number) => (
                  <Button key={number} value={number} onClick={() => handleJerseyNumber(number)} sx={{ display: 'flex', alignItems: 'center', fontWeight: '700', fontSize: '25px', height: '60px', width: '60px', m: '0 10px 0', borderRadius: '10px', border: inputFields.jersey_number === number ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)', color: inputFields.jersey_number === number ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' }}>
                    {number}
                  </Button>
                ))}
              </Stack>
            </Grid>

            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 20px' }}>
              {`${intl.formatMessage({ id: 'FormNewFootballer_Foots_Title' })}`}
            </Typography>
            <Grid id="max-width" className={'foot'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
              <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                {FOOTS.map((best_foot: any) => (
                  <Button
                    key={best_foot.value}
                    value={best_foot.value}
                    onClick={() => handleBestFoot(best_foot.value)}
                    sx={{
                      'height': '90px',
                      'width': '100px',
                      'm': '0 20px 0',
                      'display': 'inline-block',
                      'borderRadius': '10px',
                      'border': inputFields.best_foot === best_foot.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                      'color': inputFields.best_foot === best_foot.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                      '& svg': { fontSize: '40px', color: inputFields.best_foot === best_foot.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                    }}
                  >
                    {best_foot?.icon}
                    <br />
                    {`${intl.formatMessage({ id: best_foot.label })}`}
                  </Button>
                ))}
              </Stack>
            </Grid>

            <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 0px' }}>
              {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_1' })}`}
            </Typography>
            <Typography id="max-width" variant="caption" sx={{ p: '0px 10% 20px', display: 'block' }}>
              {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_2' })}`}
            </Typography>
            <InputSelectAvailabilitiesFeature setter={setValue} input={inputFields.availabilities} />

            <Grid id="max-width" className={'actions'} sx={{ p: '80px 10% 0' }}>
              <Button className={newFootballer.loading || isSent.loading || progress > 0 ? 'button-disabled' : 'button-cancel'} disabled={newFootballer.loading || isSent.loading || progress > 0} onClick={() => navigate(-1)} endIcon={<Clear />}>
                {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
              </Button>

              <LoadingButton
                onClick={handleCreateFootballer}
                className={inputFields.availabilities?.length === 0 || inputFields.alias === '' || inputFields.address_title === '' || inputFields.city === '' || inputFields.postcode === '' ? 'button-disabled' : 'button-green'}
                disabled={inputFields.availabilities?.length === 0 || inputFields.alias === '' || inputFields.address_title === '' || inputFields.city === '' || inputFields.postcode === ''}
                loading={newFootballer.loading || isSent.loading || progress > 0}
                loadingPosition="end"
                endIcon={<Send />}
                sx={{ m: '0 10px' }}
              >
                {`${intl.formatMessage({ id: 'FormNewFootballer_Button_Create' })}`}
              </LoadingButton>
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
};

export default FormNewFootballer;
