import { useState, useEffect, useCallback } from 'react';

export default function useLongPress(action: any, reaction: any, ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId: any;
    if (startLongPress) {
      timerId = setTimeout(action, ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [action, ms, startLongPress]);

  const start = useCallback(() => {
    setStartLongPress(true);
    console.log('start press....');
  }, []);

  const stop = useCallback(() => {
    setStartLongPress(false);
    reaction();
    console.log('stop press....');
  }, [reaction]);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}
