import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, TextField } from '@mui/material';
import { Send, Clear } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';

export default function EditMessageModal(props: { message_id: string; content: any; open: any; handleClose: any }) {
  const EDIT_MESSAGE_MUTATION = gql`
    mutation editMessage($message: inputEditMessage!) {
      editMessage(message: $message) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const { control, watch, setValue } = useForm({
    defaultValues: {
      content: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const [editMessage, updatedMessage] = useMutation(EDIT_MESSAGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    setValue('content', UNDEFINED_AS_ANY);
    props.handleClose();
  };

  const handleEditMessage = async () => {
    try {
      await editMessage({
        variables: {
          message: {
            id: props.message_id,
            content: inputFields.content,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && inputFields.content === undefined && props.content) {
      setValue('content', props.content);
    }

    if (!props.open && inputFields.content !== UNDEFINED_AS_ANY) {
      setValue('content', UNDEFINED_AS_ANY);
    }
  }, [setValue, props, inputFields]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Edit message</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent>
        <Controller
          name="content"
          control={control}
          rules={{
            required: true,
            maxLength: 30,
            minLength: 4,
            pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
          }}
          render={({ field }: any) => (
            <TextField
              {...field}
              placeholder="Write a message..."
              fullWidth
              multiline
              disabled={updatedMessage.loading}
              value={_.trimStart(field.value)}
              variant="outlined"
              sx={{
                '& input': { bgcolor: 'rgba(247, 251, 250, 0)', borderRadius: '10px', pl: '15px', pr: '15px' },
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <LoadingButton onClick={handleEditMessage} disabled={inputFields.content === props.content || (inputFields.content === '' && props.content !== '')} className={inputFields.content === props.content || (inputFields.content === '' && props.content !== '') ? 'button-disabled' : 'button-green'} loadingPosition="end" endIcon={<Send />} loading={updatedMessage.loading}>
          Save updates
        </LoadingButton>
        <Button onClick={handleClose} disabled={updatedMessage.loading} className={updatedMessage.loading ? 'button-disabled' : 'button-cancel'} endIcon={<Clear />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditMessageModal.propTypes = {
  icon: PropTypes.any,
  message_id: PropTypes.any,
  content: PropTypes.any,
  open: PropTypes.any,
};
