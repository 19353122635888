import React from 'react';
import _ from 'lodash';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { selectPlayer } from '../../reducers/playerSlice';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Avatar, Collapse, Divider, Fab, Grid, IconButton, List, ListItem, ListItemIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Add, CalendarMonth, Delete } from '@mui/icons-material';
import AssignSelectedTeamsTournamentModal from './AssignSelectedTeamsTournamentModal';
import { useForm } from 'react-hook-form';
import ScheduleTournamentGroupGameModal from './ScheduleTournamentGroupGameModal';
import Moment from 'react-moment';
import ViewTournamentGroupGameModal from './ViewTournamentGroupGameModal';
import { useIntl } from 'react-intl';

export default function TournamentGroupsTable(props: { tournament_id: string }) {
  const FIND_TOURNAMENT_BY_ID_QUERY = gql`
    query findTournamentByID($id: ID!) {
      findTournamentByID(id: $id) {
        id
        status
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        main_location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
        selected_teams {
          id
          name
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
        }
        phase_groups {
          id
          name
          teams {
            id
            name
            players {
              id
              status
              team_number
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
              }
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
          rank {
            id
            points
            goals
            team {
              id
              name
              team {
                ... on FootballTeam {
                  __typename
                  id
                  short_id
                  name
                  shortname
                  avatar {
                    path
                  }
                }
              }
              players {
                id
                status
                team_number
                team_player {
                  ... on FootballTeam {
                    __typename
                    id
                    name
                  }
                }
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    avatar {
                      path
                    }
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    avatar {
                      path
                    }
                    player {
                      id
                    }
                  }
                }
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
            tournament_group {
              id
              games {
                id
                short_id
                title
                status
                start_date
                end_date
                home_team {
                  id
                  name
                }
                away_team {
                  id
                  name
                }
                complex {
                  id
                  name
                  location {
                    id
                    title
                    coordinates
                    country_code
                    street_name
                    secondary_street_name
                    status
                    postcode {
                      name
                    }
                    city {
                      name
                    }
                    county {
                      name
                    }
                    state {
                      name
                    }
                    country {
                      name
                    }
                    continent {
                      name
                    }
                  }
                }
                arena {
                  id
                  name
                  location {
                    id
                    title
                    coordinates
                    country_code
                    street_name
                    secondary_street_name
                    status
                    postcode {
                      name
                    }
                    city {
                      name
                    }
                    county {
                      name
                    }
                    state {
                      name
                    }
                    country {
                      name
                    }
                    continent {
                      name
                    }
                  }
                }
                results {
                  id
                  status
                  home_team {
                    id
                    name
                    team {
                      ... on FootballTeam {
                        __typename
                        id
                        name
                      }
                    }
                  }
                  away_team {
                    id
                    name
                    team {
                      ... on FootballTeam {
                        __typename
                        id
                        name
                      }
                    }
                  }
                  statistics {
                    id
                    status
                    nb_goals
                    team_player {
                      id
                      team_player {
                        ... on FootballTeam {
                          __typename
                          id
                          name
                        }
                      }
                    }
                    player {
                      ... on Footballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                      ... on Basketballer {
                        __typename
                        id
                        alias
                        player {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            games {
              id
              results {
                id
                status
                home_team {
                  id
                  name
                  team {
                    ... on FootballTeam {
                      __typename
                      id
                      name
                    }
                  }
                }
                away_team {
                  id
                  name
                  team {
                    ... on FootballTeam {
                      __typename
                      id
                      name
                    }
                  }
                }
                statistics {
                  id
                  status
                  nb_goals
                  team_player {
                    id
                    team_player {
                      ... on FootballTeam {
                        __typename
                        id
                        name
                      }
                    }
                  }
                  player {
                    ... on Footballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                    ... on Basketballer {
                      __typename
                      id
                      alias
                      player {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          games {
            id
            short_id
            title
            results {
              id
              status
              home_team {
                id
                name
                team {
                  ... on FootballTeam {
                    __typename
                    id
                    name
                  }
                }
              }
              away_team {
                id
                name
                team {
                  ... on FootballTeam {
                    __typename
                    id
                    name
                  }
                }
              }
              statistics {
                id
                status
                nb_goals
                team_player {
                  id
                  team_player {
                    ... on FootballTeam {
                      __typename
                      id
                      name
                    }
                  }
                }
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const CREATE_TOURNAMENT_RANK_MUTATION = gql`
    mutation createTournamentRank($tournament_rank: inputCreateTournamentRank!) {
      createTournamentRank(tournament_rank: $tournament_rank) {
        id
      }
    }
  `;

  const DELETE_TOURNAMENT_RANK_MUTATION = gql`
    mutation deleteTournamentRank($tournament_rank: inputDeleteTournamentRank!) {
      deleteTournamentRank(tournament_rank: $tournament_rank)
    }
  `;

  const DELETE_TOURNAMENT_GROUP_CHALLENGE_MUTATION = gql`
    mutation deleteTournamentGroupChallenge($challenge: inputDeleteTournamentGroupChallenge!) {
      deleteTournamentGroupChallenge(challenge: $challenge)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);
  const [openAddTeamLineup, setOpenAddTeamLineup] = React.useState(false);
  const [openScheduleTournamentGame, setOpenScheduleTournamentGame] = React.useState(false);
  const [openViewGame, setOpenViewGame] = React.useState(false);
  const [gameID, setGameID] = React.useState(UNDEFINED_AS_ANY);

  const { watch, setValue } = useForm({
    defaultValues: {
      group_id: '',
    },
  });
  const inputFields = watch();

  const [createTournamentRank, newTournamentRank] = useMutation(CREATE_TOURNAMENT_RANK_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteTournamentRank, deletedTournamentRank] = useMutation(DELETE_TOURNAMENT_RANK_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteTournamentGroupChallenge, deletedChallenge] = useMutation(DELETE_TOURNAMENT_GROUP_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      id: props.tournament_id,
    },
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedTournament.refetch({
        context: {
          headers: {
            'X-Anonymous-Access': 'true',
          },
        },
        id: props.tournament_id,
      });

      if (result) {
        setTournament(result.data?.findTournamentByID);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenViewGame = (game_id: string) => {
    setGameID(game_id);
    setOpenViewGame(true);
  };

  const handleCloseViewGame = async () => {
    try {
      await handleRefetch();
      setGameID(UNDEFINED_AS_ANY);
      setOpenViewGame(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveTeam = async (team_lineup_id: string, group_id?: string) => {
    try {
      await deleteTournamentRank({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament_rank: {
            player_id: loggedPlayer?.id,
            tournament_id: tournament?.id,
            team_lineup_id: team_lineup_id,
            group_id: group_id || inputFields.group_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddTeam = async (team_lineup_id: string) => {
    try {
      await createTournamentRank({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament_rank: {
            player_id: loggedPlayer?.id,
            tournament_id: tournament?.id,
            team_lineup_id: team_lineup_id,
            group_id: inputFields.group_id,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenAddTournamentGroupGame = (group_id: string) => {
    setValue('group_id', group_id);
    setOpenScheduleTournamentGame(true);
  };

  const handleOpenAddTeamLineup = (group_id: string) => {
    setValue('group_id', group_id);
    setOpenAddTeamLineup(true);
  };

  const handleRemoveTournamentGroupGame = async (game_id: string) => {
    try {
      await deleteTournamentGroupChallenge({
        variables: {
          challenge: {
            id: game_id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseModals = async () => {
    try {
      await handleRefetch();
    } catch (e) {
      console.error(e);
    } finally {
      setValue('group_id', '');
      setOpenAddTeamLineup(false);
      setOpenScheduleTournamentGame(false);
    }
  };

  React.useEffect(() => {
    if (!_.isMatch(tournament, loadedTournament.data?.findTournamentByID) || (tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByID)) {
      setTournament(loadedTournament.data?.findTournamentByID);
    }
  }, [tournament, loadedTournament]);

  const Row = (props: { rank: any; index: number }) => {
    const { rank, index } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
          <TableCell component="th" scope="row" sx={{ borderBottom: 'transparent' }}>
            <Grid container sx={{ alignItems: 'center' }}>
              {tournament?.status === 'SCHEDULING_GAMES' && rank?.tournament_group?.games?.filter((ga: any) => ga?.home_team?.id === rank?.team?.id || ga?.away_team?.id === rank?.team?.id)?.length === 0 ? (
                <IconButton disabled={newTournamentRank.loading || deletedTournamentRank.loading} className={newTournamentRank.loading || deletedTournamentRank.loading ? 'button-disabled' : 'button-red-reversed'} size="small" onClick={() => handleRemoveTeam(rank?.team?.id, rank?.tournament_group?.id)} sx={{ boxShadow: 'none' }}>
                  <Delete />
                </IconButton>
              ) : (
                ''
              )}
              <Typography sx={{ color: 'rgba(15,15,15,.4) !important', p: '0 10px 0 5px' }}>{index}</Typography>
              <Avatar src={rank?.team?.team?.avatar?.path} alt={rank?.team?.name} sx={{ width: '30px', height: '30px' }} />
              &nbsp;
              <strong>{rank?.team?.name}</strong>
            </Grid>
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: 'transparent' }}>
            {rank?.games?.length}
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: 'transparent' }}>
            {rank?.games?.filter((g: any) => _.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === rank?.team?.team?.id)?.map((s: any) => s?.nb_goals)) > _.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id !== rank?.team?.team?.id)?.map((s: any) => s?.nb_goals)))?.length}
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: 'transparent' }}>
            {rank?.games?.filter((g: any) => _.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === rank?.team?.team?.id)?.map((s: any) => s?.nb_goals)) === _.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id !== rank?.team?.team?.id)?.map((s: any) => s?.nb_goals)))?.length}
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: 'transparent' }}>
            {rank?.games?.filter((g: any) => _.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === rank?.team?.team?.id)?.map((s: any) => s?.nb_goals)) < _.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id !== rank?.team?.team?.id)?.map((s: any) => s?.nb_goals)))?.length}
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: 'transparent' }}>
            {rank?.points}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ bgcolor: 'rgba(15, 15, 15, 0.05)', p: '10px' }}>
              <Typography variant="h6">
                {rank?.team?.name}
                {`${intl.formatMessage({ id: 'Tournament_Groups_Games' })}`}
              </Typography>
              <List>
                {rank?.tournament_group?.games
                  ?.filter((ga: any) => ga?.home_team?.id === rank?.team?.id || ga?.away_team?.id === rank?.team?.id)
                  ?.map((g: any) => (
                    <ListItem key={g?.id} sx={{ '& p': { fontSize: 'inherit', color: 'rgba(15, 15, 15, 1) !important' } }}>
                      <ListItemIcon>
                        {g?.results || g?.start_date <= Date.now() ? (
                          ''
                        ) : (
                          <IconButton disabled={deletedChallenge.loading} className={deletedChallenge.loading ? 'button-disabled' : 'button-red-reversed'} size="small" onClick={() => handleRemoveTournamentGroupGame(g?.id)} sx={{ boxShadow: 'none' }}>
                            <Delete sx={{ color: 'rgba(254, 0, 0, 1) !important' }} />
                          </IconButton>
                        )}
                      </ListItemIcon>
                      <Grid container onClick={() => handleOpenViewGame(g?.id)} sx={{ display: 'inline-block' }}>
                        <strong>{g?.results?.home_team?.name ? `${g?.results?.home_team?.name} ${_.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === g?.results?.home_team?.team?.id)?.map((s: any) => s?.nb_goals))} vs ${_.sum(g?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === g?.results?.away_team?.team?.id)?.map((s: any) => s?.nb_goals))} ${g?.results?.away_team?.name}` : g?.title}</strong>
                        <br />
                        <Moment format="ddd D MMM YYYY (HH:mm)">{g?.start_date}</Moment>
                        <Grid container>
                          <Typography>{(g?.complex || g?.arena)?.location?.title}</Typography>&nbsp;-&nbsp;
                          <Typography>
                            <Grid container component={'span'} sx={{ alignItems: 'center' }}>
                              {(g?.complex || g?.arena)?.location?.country?.name}&nbsp;
                              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${(g?.complex || g?.arena)?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${(g?.complex || g?.arena)?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                            </Grid>
                          </Typography>
                        </Grid>
                        <Divider />
                      </Grid>
                    </ListItem>
                  ))}

                {rank?.tournament_group?.games?.filter((ga: any) => ga?.home_team?.id === rank?.team?.id || ga?.away_team?.id === rank?.team?.id)?.length === 0 ? (
                  <Typography
                    variant="h3"
                    sx={{
                      p: '30px 20px',
                      fontSize: '1.3em',
                      textAlign: 'center',
                      width: '100%',
                      color: 'rgba(15, 15, 15, .3) !important',
                    }}
                  >
                    {`${intl.formatMessage({ id: 'Tournament_Groups_No_Games_Yet' })}`}
                  </Typography>
                ) : (
                  ''
                )}
              </List>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Grid container sx={{ justifyContent: 'center' }}>
      {_.orderBy(tournament?.phase_groups, 'name')?.map((g: any) => (
        <Grid key={g?.id} item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ p: '0 10px' }}>
          <TableContainer sx={{ bgcolor: 'rgba(247, 251, 250, 1)', mb: '20px', p: '10px 5px', borderRadius: '10px' }}>
            <Typography sx={{ color: 'rgb(15, 15, 15) !important', textTransform: 'uppercase', fontWeight: '900', textAlign: 'center', display: 'inline-block', width: '100%' }}>{g?.name}</Typography>

            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ '& .MuiTableCell-root': { color: 'rgba(15, 15, 15, .4) !important' } }}>
                  <TableCell></TableCell>
                  <TableCell align="right">P</TableCell>
                  <TableCell align="right">W</TableCell>
                  <TableCell align="right">D</TableCell>
                  <TableCell align="right">L</TableCell>
                  <TableCell align="right">Pts</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {g?.rank?.map((rank: any, index: number) => (
                  <Row key={rank?.id} rank={rank} index={index + 1} />
                ))}
              </TableBody>
            </Table>

            {g?.rank?.length === 0 ? (
              <Typography
                variant="h3"
                sx={{
                  p: '30px 20px',
                  fontSize: '1.3em',
                  textAlign: 'center',
                  width: '100%',
                  color: 'rgba(15, 15, 15, .3) !important',
                }}
              >
                {`${intl.formatMessage({ id: 'Tournament_Groups_No_Teams' })}`}
              </Typography>
            ) : (
              ''
            )}

            {g?.rank?.length < 4 && tournament?.status === 'SCHEDULING_GAMES' ? (
              <Grid container gap={1} sx={{ p: '10px', justifyContent: 'center' }}>
                {g?.rank?.length < 4 ? (
                  <Fab variant="extended" disabled={newTournamentRank.loading || deletedTournamentRank.loading} className={newTournamentRank.loading || deletedTournamentRank.loading ? 'button-disabled' : 'button-green-reverse'} size="small" onClick={() => handleOpenAddTeamLineup(g?.id)} sx={{ boxShadow: 'none' }}>
                    <Add />
                    &nbsp;{`${intl.formatMessage({ id: 'Tournament_Groups_Button_Add_Team' })}`}
                  </Fab>
                ) : (
                  ''
                )}

                {g?.rank?.length > 0 ? (
                  <Fab variant="extended" disabled={newTournamentRank.loading || deletedTournamentRank.loading} className={newTournamentRank.loading || deletedTournamentRank.loading ? 'button-disabled' : 'button-green-reverse'} size="small" onClick={() => handleOpenAddTournamentGroupGame(g?.id)} sx={{ boxShadow: 'none' }}>
                    <CalendarMonth />
                    &nbsp;{`${intl.formatMessage({ id: 'Tournament_Groups_Button_Schedule' })}`}
                  </Fab>
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              ''
            )}
          </TableContainer>
        </Grid>
      ))}

      <ViewTournamentGroupGameModal open={openViewGame} game_id={gameID} next_game={undefined} handleClose={handleCloseViewGame} />

      {tournament?.status === 'SCHEDULING_GAMES' ? (
        <>
          <ScheduleTournamentGroupGameModal open={openScheduleTournamentGame} handleClose={handleCloseModals} tournament_id={tournament?.id} tournament_group_id={inputFields.group_id} />
          <AssignSelectedTeamsTournamentModal tournament_id={tournament?.id} group_id={inputFields.group_id} open={openAddTeamLineup} handleClose={handleCloseModals} handleAssignTeam={handleAddTeam} handleRemoveTeam={handleRemoveTeam} />
        </>
      ) : (
        ''
      )}
    </Grid>
  );
}
