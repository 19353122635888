import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Fab, MenuItem, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { LANGUAGES } from '../../utilities/utilities';
import { FormattedMessage } from 'react-intl';
import { TranslateIcon } from '../../img/icons/Icons';

interface ISelectDescriptionButton {
  control: any;
  descriptions: any[];
  dark_mode?: boolean;
  show_label?: boolean;
}

const SelectDescriptionButton: React.FC<ISelectDescriptionButton> = (props: ISelectDescriptionButton) => {
  return (
    <>
      <Fab variant="extended" size="small" sx={{ 'textTransform': 'capitalize', 'bgcolor': 'transparent', 'boxShadow': 'none !important', '&:hover': { bgcolor: props.dark_mode ? 'rgba(255,255,255,.06)' : 'rgba(15,15,15,.04)' } }}>
        <TranslateIcon sx={{ fontSize: '1.3em', opacity: '.6', color: props.dark_mode ? 'rgba(255,255,255,1)' : 'rgba(15,15,15,1)' }} />
        &nbsp;&nbsp;
        <Controller
          control={props.control}
          name="description_language"
          render={({ field }: any) => (
            <TextField
              {...field}
              value={field.value}
              select
              label={<FormattedMessage id="Company_Translations" />}
              placeholder="Select a language"
              variant="outlined"
              SelectProps={{
                sx: {
                  'bgcolor': 'transparent',
                  'color': props.dark_mode ? 'rgba(255,255,255,1)' : 'rgba(15,15,15,1)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none !important',
                  },
                  '& .MuiSelect-icon': {
                    display: 'none',
                  },
                  '& .MuiSelect-outlined': {
                    'p': '0 !important',
                    '& span': {
                      display: props.show_label ? 'inline' : 'none',
                    },
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  display: 'none',
                },
              }}
            >
              {LANGUAGES.filter((l: any) => props?.descriptions?.map((d: any) => d?.language).includes(l.value)).map((option: any, index: number) => (
                <MenuItem key={index} value={option.value}>
                  <img loading="lazy" width="20" src={option.value !== 'AU' ? `https://flagcdn.com/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png`} srcSet={option.value !== 'AU' ? `https://flagcdn.com/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                  &nbsp;
                  <span>{option.label}</span>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Fab>
    </>
  );
};

SelectDescriptionButton.propTypes = {
  control: PropTypes.any.isRequired,
  descriptions: PropTypes.any.isRequired,
  dark_mode: PropTypes.bool,
  show_label: PropTypes.bool,
};

SelectDescriptionButton.defaultProps = {
  dark_mode: false,
  descriptions: [],
  show_label: true,
};

export default React.memo(SelectDescriptionButton);
