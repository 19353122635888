import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Fab, Grid, MenuItem, TextField, Avatar, useMediaQuery, Chip, CircularProgress } from '@mui/material';
import { Send, Close, Add, Clear } from '@mui/icons-material';
import { ARRAY_AS_ANY, NULL_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerWhiteIcon, VersusIcon } from '../../img/icons/Icons';
import Modal from '../layout/Modal';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

export default function ManageScoreModal(props: { challenge_short_id: string; handleClose: any; open: any }) {
  const FIND_CHALLENGE_BY_ID_QUERY = gql`
    query findChallengeByID($challenge: inputFindChallengeByID!) {
      findChallengeByID(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        home_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        away_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        booking {
          id
          consumers {
            id
            payment_status
            transaction {
              id
              status
              tx_reference
              note_reference
              execution_date
              confirmed_at
            }
            player {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
        results {
          id
          status
          home_team {
            id
            name
          }
          away_team {
            id
            name
          }
          captains_confirmations {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
          }
          men_of_the_match {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
          statistics {
            id
            status
            nb_goals
            given_assists {
              id
              nb_assists
              giver {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
              receiver {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
            pace
            shooting
            passing
            dribbling
            defending
            physical
            goal_keeping
            team_player {
              id
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const INIT_CHALLENGE_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation initChallengeScoreByChallengeID($challenge: inputInitChallengeScoreByChallengeID!) {
      initChallengeScoreByChallengeID(challenge: $challenge)
    }
  `;

  const SAVE_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation saveScoreByChallengeID($score: inputSaveScoreByChallengeID!) {
      saveScoreByChallengeID(score: $score)
    }
  `;

  const CONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation confirmScoreByChallengeID($score: inputConfirmationScoreByChallengeID!) {
      confirmScoreByChallengeID(score: $score)
    }
  `;

  const UNCONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation unconfirmScoreByChallengeID($score: inputConfirmationScoreByChallengeID!) {
      unconfirmScoreByChallengeID(score: $score)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);
  const [isDone, setIsDone] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const {
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selected_scorer: UNDEFINED_AS_ANY,
      nb_goals: 0,
      selected_passer: UNDEFINED_AS_ANY,
      nb_assists: 0,
      selected_passers: ARRAY_AS_ANY,

      all_goals: UNDEFINED_AS_ANY,

      selected_motm: NULL_AS_ANY,
    },
  });
  const inputFields = watch();

  const [initChallengeScoreByChallengeID, newScore] = useMutation(INIT_CHALLENGE_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [saveScoreByChallengeID, savedScore] = useMutation(SAVE_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [confirmScoreByChallengeID, confirmedScore] = useMutation(CONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unconfirmScoreByChallengeID, unconfirmedScore] = useMutation(UNCONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedChallenge = useQuery(FIND_CHALLENGE_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        short_id: props.challenge_short_id,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });

  const HOME_TEAM = challenge?.home_team;
  const AWAY_TEAM = challenge?.away_team;
  const SELECTED_TEAM = AWAY_TEAM?.captains?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) ? AWAY_TEAM : HOME_TEAM;
  const OPPONENT_TEAM = AWAY_TEAM?.id === SELECTED_TEAM?.id ? HOME_TEAM : AWAY_TEAM;

  const HOME_TEAM_SCORE = _.sum(challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === HOME_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));
  const AWAY_TEAM_SCORE = _.sum(challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === AWAY_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));

  const SELECTED_TEAM_STATS = challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === SELECTED_TEAM?.team?.id);

  const handleClose = () => {
    setIsConfirmed(false);
    setIsDone(false);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
    setValue('selected_passers', ARRAY_AS_ANY);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
    resetField('selected_scorer');
    setValue('nb_goals', 0);
    props.handleClose();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedChallenge.refetch({
        challenge: {
          short_id: props.challenge_short_id,
          logged_player_id: loggedPlayer?.id,
        },
      });

      if (result) {
        setChallenge(result.data?.findChallengeByID);
        const newList = result.data?.findChallengeByID?.results?.statistics
          ?.filter((s: any) => s?.team_player?.team_player?.id === SELECTED_TEAM?.team?.id)
          ?.map((s: any) => {
            return {
              id: s?.id,
              player_id: s?.player?.id,
              nb_goals: s?.nb_goals,
              given_assists: s?.given_assists?.map((a: any) => {
                return {
                  id: a?.id,
                  player_receiver_id: a?.receiver?.id,
                  player_giver_id: a?.giver?.id,
                  nb_assists: a?.nb_assists,
                };
              }),
              pace: s?.pace,
              shooting: s?.shooting,
              passing: s?.passing,
              dribbling: s?.dribbling,
              defending: s?.defending,
              physical: s?.physical,
              goal_keeping: s?.goal_keeping,
            };
          });
        setValue('all_goals', newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInitScore = async () => {
    try {
      if (!challenge?.results?.id) {
        await initChallengeScoreByChallengeID({
          variables: {
            challenge: {
              id: challenge?.id,
              logged_player_id: loggedPlayer?.id,
            },
          },
        });

        await handleRefetch();
      }
    } catch (e) {
      await handleRefetch();
      console.log(e);
    }
  };

  const handleAddNewAssist = () => {
    const newPasser = {
      player_receiver_id: inputFields.selected_scorer?.player?.id,
      player_giver_id: inputFields.selected_passer?.player?.id,
      nb_assists: parseFloat((inputFields.nb_assists > inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) ? inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) : inputFields.nb_assists).toString()),
    };
    const newList = _.concat(newPasser, inputFields.selected_passers);
    setValue('selected_passers', newList);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
  };

  const handleRemoveAssist = (player: any) => {
    const newList = inputFields.selected_passers?.filter((p: any) => p?.player_giver_id !== player?.player?.id);
    setValue('selected_passers', newList);
  };

  const handleAddScorer = () => {
    const existing_stats = SELECTED_TEAM_STATS?.find((s: any) => s?.player?.id === inputFields.selected_scorer?.player?.id);
    const newScorer = {
      id: existing_stats?.id,
      player_id: inputFields.selected_scorer?.player?.id,
      nb_goals: parseFloat(inputFields.nb_goals.toString()),
      given_assists: inputFields.selected_passers,
      pace: existing_stats?.pace || 0.1,
      shooting: existing_stats?.shooting || 0.1,
      passing: existing_stats?.passing || 0.1,
      dribbling: existing_stats?.dribbling || 0.1,
      defending: existing_stats?.defending || 0.1,
      physical: existing_stats?.physical || 0.1,
      goal_keeping: existing_stats?.goal_keeping || 0.1,
    };
    const newList = _.concat(newScorer, inputFields.all_goals)?.filter((i: any) => i);
    setValue('all_goals', newList);
    setValue('selected_passers', ARRAY_AS_ANY);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
    resetField('selected_scorer');
    setValue('nb_goals', 0);
  };

  const handleRemoveScorer = (player: any) => {
    const newList = player?.player?.id ? inputFields.all_goals?.filter((p: any) => p?.player?.id !== player?.player?.id) : inputFields.all_goals?.filter((p: any) => p?.player_id !== player?.player_id);
    setValue('all_goals', newList);
  };

  const handleRemoveMOTM = () => {
    setValue('selected_motm', UNDEFINED_AS_ANY);
  };

  const handleConfirmScore = () => {
    setIsConfirmed(true);
  };

  const handleSaveScore = async () => {
    try {
      await saveScoreByChallengeID({
        variables: {
          score: {
            logged_player_id: loggedPlayer?.id,
            id: challenge?.results?.id,
            challenge_id: challenge?.id,
            team_lineup_id: SELECTED_TEAM?.id,
            motm_id: inputFields.selected_motm?.player?.id,
            statistics: inputFields?.all_goals,
          },
        },
      });

      await handleRefetch();

      handleClose();
    } catch (e) {
      handleClose();
      console.log(e);
    }
  };

  const handlePublishScore = async () => {
    try {
      setIsConfirmed(false);

      await saveScoreByChallengeID({
        variables: {
          score: {
            logged_player_id: loggedPlayer?.id,
            id: challenge?.results?.id,
            challenge_id: challenge?.id,
            team_lineup_id: SELECTED_TEAM?.id,
            motm_id: inputFields.selected_motm?.player?.id,
            statistics: inputFields?.all_goals,
          },
        },
      });

      await confirmScoreByChallengeID({
        variables: {
          score: {
            logged_player_id: loggedPlayer?.id,
            id: challenge?.results?.id,
            challenge_id: challenge?.id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  const handleUnpublishScore = async () => {
    try {
      setIsConfirmed(false);

      await unconfirmScoreByChallengeID({
        variables: {
          score: {
            logged_player_id: loggedPlayer?.id,
            id: challenge?.results?.id,
            challenge_id: challenge?.id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      await handleRefetch();
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && !challenge && loadedChallenge.called && loadedChallenge.data?.findChallengeByID) {
      setChallenge(loadedChallenge.data?.findChallengeByID);
    }

    if (props.open && challenge && challenge?.results?.statistics?.length > 0 && !inputFields.all_goals && SELECTED_TEAM) {
      const newList = challenge?.results?.statistics
        ?.filter((s: any) => s?.team_player?.team_player?.id === SELECTED_TEAM?.team?.id)
        ?.map((s: any) => {
          return {
            id: s?.id,
            player_id: s?.player?.id,
            nb_goals: s?.nb_goals,
            given_assists: s?.given_assists?.map((a: any) => {
              return {
                id: a?.id,
                player_receiver_id: a?.receiver?.id,
                player_giver_id: a?.giver?.id,
                nb_assists: a?.nb_assists,
              };
            }),
            pace: s?.pace,
            shooting: s?.shooting,
            passing: s?.passing,
            dribbling: s?.dribbling,
            defending: s?.defending,
            physical: s?.physical,
            goal_keeping: s?.goal_keeping,
          };
        });

      if (!inputFields.all_goals) {
        setValue('all_goals', newList);
      }
    }

    if (props.open && challenge && challenge?.results?.men_of_the_match?.find((p: any) => SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === p?.id)) && inputFields.selected_motm === null) {
      setValue(
        'selected_motm',
        SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === challenge?.results?.men_of_the_match?.find((p: any) => SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === p?.id))?.id)
      );
    }

    if (!props.open && challenge) {
      setChallenge(UNDEFINED_AS_ANY);
    }
  }, [challenge, loadedChallenge, setChallenge, props, handleInitScore, newScore, setValue, inputFields]);

  return (
    <Dialog
      open={props.open}
      onClose={newScore.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={newScore.loading || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading ? undefined : handleClose}
        disabled={newScore.loading || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading}
        className={newScore.loading || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'ManageScore_Title' })}`}</Typography>
        {challenge?.results?.captains_confirmations?.length > 0 ? (
          <Grid sx={{ width: '100%', textAlign: 'center' }}>
            <Typography variant="caption">Confirmed by</Typography>
            {challenge?.results?.captains_confirmations?.map((p: any, index: number) => (
              <Grid key={index} sx={{ m: '0 10px' }}>
                <Avatar src={p?.avatar?.path} alt={p?.alias} sx={{ m: 'auto' }} />
                <Typography variant="caption">{p?.alias}</Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          ''
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid
            container
            sx={{
              justifyContent: 'start',
              alignItems: 'center',
              p: '0',
              color: 'rgba(247, 251, 250, 1)',
            }}
          >
            {!challenge?.results?.id ? (
              <Grid container>
                <Typography>
                  {`${intl.formatMessage({ id: 'ManageScore_Intro_1' })}`}
                  <br />
                  <br />
                  {`${intl.formatMessage({ id: 'ManageScore_Intro_2' })}`}
                </Typography>
              </Grid>
            ) : (
              <>
                <Typography
                  component="span"
                  className="secondary-font"
                  sx={{
                    pt: '50px',
                    fontSize: {
                      xs: '8em',
                      sm: '9em',
                      md: '13em',
                      lg: '15em',
                    },
                    position: 'absolute',
                    top: '0',
                    fontStyle: 'italic',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    color: 'transparent',
                    width: '100%',
                    display: 'inline-block',
                    lineHeight: {
                      xs: '100px',
                      sm: '110px',
                      md: '160px',
                      lg: '180px',
                    },
                  }}
                >
                  <Grid sx={{ textAlign: 'left', position: 'relative', left: '-70px', WebkitTextStroke: '2px rgba(199, 240, 11, .1)' }}>{`${intl.formatMessage({ id: 'ManageScore_BG_Title_2' })}`}</Grid>
                  <Grid sx={{ textAlign: 'center', position: 'relative', WebkitTextStroke: '2px rgba(199, 240, 11, .1)' }}>{`${intl.formatMessage({ id: 'ManageScore_BG_Title_2' })}`}</Grid>
                </Typography>

                <Grid container>
                  <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ display: { xs: 'inline-block', sm: 'inline-flex' }, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid sx={{ textAlign: 'center' }}>
                      <Typography sx={{ fontWeight: '700', lineHeight: '15px', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.home_team?.name}</Typography>
                      {challenge?.home_team?.team?.avatar?.path ? <Avatar src={challenge?.home_team?.team?.avatar?.path} alt={challenge?.home_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                    </Grid>
                    <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{SELECTED_TEAM?.id === challenge?.home_team?.id ? _.sum(inputFields.all_goals?.map((p: any) => parseInt(p?.nb_goals.toString()))) : <>{HOME_TEAM_SCORE || <>&infin;</>}</>}</Typography>
                  </Grid>
                  <Grid className="separator" item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '50px', sm: '50px' } }} />
                  </Grid>
                  <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ display: { xs: 'inline-block', sm: 'inline-flex' }, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{SELECTED_TEAM?.id === challenge?.away_team?.id ? _.sum(inputFields.all_goals?.map((p: any) => parseInt(p?.nb_goals.toString()))) : <>{AWAY_TEAM_SCORE || <>&infin;</>}</>}</Typography>
                    <Grid sx={{ textAlign: 'center' }}>
                      {isSmallMobile ? <>{challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}</> : ''}
                      <Typography sx={{ fontWeight: '700', lineHeight: '15px', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.away_team?.name}</Typography>
                      {isSmallMobile ? '' : <>{challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}</>}
                    </Grid>
                  </Grid>
                </Grid>

                {isDone ? (
                  <>
                    {savedScore.loading || confirmedScore.loading || unconfirmedScore.loading ? (
                      <Typography sx={{ fontSize: { xs: '1.1em', sm: '1.3em' }, p: '20px 10%', textAlign: 'center', width: '100%' }}>
                        {`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
                        <br />
                        <CircularProgress className="orange-loader" />
                      </Typography>
                    ) : (
                      <Typography sx={{ fontSize: { xs: '1.1em', sm: '1.3em' }, p: '0 10%' }}>
                        {`${intl.formatMessage({ id: 'ManageScore_Added_Score_Info_1' })}`}&nbsp;<strong>{SELECTED_TEAM?.name}</strong>
                        <br />
                        {`${intl.formatMessage({ id: 'ManageScore_Added_Score_Info_2' })}`}&nbsp;<i>{`${intl.formatMessage({ id: 'ManageScore_Added_Score_Info_3' })}`}</i>&nbsp;{`${intl.formatMessage({ id: 'ManageScore_Added_Score_Info_4' })}`}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Grid container sx={{ justifyContent: 'center' }}>
                      {inputFields.all_goals?.map((p: any, index: number) => (
                        <Chip
                          key={index}
                          label={`${SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_id || player?.player?.id === p?.player_id)?.player?.alias} x ${p?.nb_goals} ${_.sum(p?.given_assists?.map((a: any) => a?.nb_assists)) > 0 ? `(${_.sum(p?.given_assists?.map((a: any) => a?.nb_assists))} ${_.sum(p?.given_assists?.map((a: any) => a?.nb_assists)) > 1 ? 'assists' : 'assist'})` : ''}`}
                          avatar={<Avatar src={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_id || player?.player?.id === p?.player_id)?.player?.avatar?.path} alt={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_id || player?.player?.id === p?.player_id)?.player?.alias} />}
                          onDelete={savedScore.loading || challenge?.results?.status === 'CONFIRMED' || (challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id)) ? undefined : () => handleRemoveScorer(p)}
                          sx={{ 'fontSize': '1em', 'm': '0 10px 10px 0', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, .4)', '& svg': { '&:hover': { color: 'rgba(238, 44, 32, 1) !important' } } }}
                        />
                      ))}
                    </Grid>

                    {loadedChallenge.loading || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading ? (
                      <>
                        <Typography sx={{ fontSize: { xs: '1.1em', sm: '1.3em' }, p: '20px 10%', textAlign: 'center', width: '100%' }}>
                          {`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
                          <br />
                          <CircularProgress className="orange-loader" />
                        </Typography>
                      </>
                    ) : (
                      <Grid container sx={{ mt: { xs: '30px', sm: '40px' }, bgcolor: 'rgba(33, 36, 42, .9)', position: 'relative' }}>
                        {SELECTED_TEAM?.players?.filter((p: any) => !inputFields.all_goals?.find((ps: any) => ps?.player_id === p?.id || ps?.player_id === p?.player?.id || ps?.player_id === p?.player_id))?.length === 0 ? (
                          ''
                        ) : (
                          <>
                            <Controller
                              control={control}
                              name="selected_scorer"
                              render={({ field }: any) => (
                                <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'ManageScore_Scorer_Label' })}`} variant="outlined" error={errors?.selected_scorer !== undefined} required fullWidth className={'field-bottom-space'}>
                                  {SELECTED_TEAM?.players
                                    ?.filter((p: any) => !inputFields.all_goals?.find((ps: any) => ps?.player_id === p?.id || ps?.player_id === p?.player?.id || ps?.player_id === p?.player_id))
                                    ?.map((option: any) => (
                                      <MenuItem key={option?.id} value={option}>
                                        <Grid container sx={{ alignItems: 'center' }}>
                                          <Avatar src={option?.player?.avatar?.path} alt={option?.player?.alias} />
                                          &nbsp;
                                          <strong>{option?.player?.alias}</strong>
                                        </Grid>
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            />

                            <Controller
                              name="nb_goals"
                              control={control}
                              rules={{
                                required: false,
                                min: 0,
                                max: 100,
                              }}
                              render={({ field }: any) => (
                                <TextField
                                  {...field}
                                  label={`${intl.formatMessage({ id: 'ManageScore_NB_Scorer_Label_1' })} ${inputFields.selected_scorer?.player?.alias ? inputFields.selected_scorer?.player?.alias : `${intl.formatMessage({ id: 'ManageScore_NB_Scorer_Label_2' })}`}`}
                                  placeholder={'0'}
                                  type="number"
                                  variant="outlined"
                                  className={'field-bottom-space'}
                                  value={field.value <= 0 ? 0 : field.value && field.value > 100 ? 100 : field.value}
                                  fullWidth
                                  error={errors?.nb_goals !== undefined}
                                />
                              )}
                            />

                            {inputFields.selected_passers?.length > 0 ? (
                              <Typography sx={{ width: '100%', pb: '20px' }}>
                                {inputFields.selected_scorer?.player?.alias ? inputFields.selected_scorer?.player?.alias : `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_1' })}`} scored {inputFields.nb_goals} {inputFields.nb_goals > 1 ? `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_2' })}` : `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_3' })}`}{' '}
                                {inputFields.selected_passers?.length > 0 ? `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_4' })} ${_.sum(inputFields.selected_passers?.map((p: any) => parseInt(p?.nb_assists?.toString())))} ${_.sum(inputFields.selected_passers?.map((p: any) => parseInt(p?.nb_assists?.toString()))) > 1 ? `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_5' })}` : `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_6' })}`}` : ''}
                              </Typography>
                            ) : (
                              ''
                            )}

                            {inputFields.selected_passers?.map((p: any, index: number) => (
                              <Chip
                                key={index}
                                label={`${SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_giver_id || player?.player?.id === p?.player_giver_id)?.player?.alias} x ${p?.nb_assists} ${p?.nb_assists > 1 ? `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_5' })}` : `${intl.formatMessage({ id: 'ManageScore_Selected_Passer_Label_6' })}`}`}
                                avatar={<Avatar src={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_giver_id || player?.player?.id === p?.player_giver_id)?.player?.avatar?.path} alt={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_giver_id || player?.player?.id === p?.player_giver_id)?.player?.alias} />}
                                onDelete={() => handleRemoveAssist(p)}
                                sx={{ 'm': '0 10px 0 0', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(247, 251, 250, .1)', '& svg': { '&:hover': { color: 'rgba(238, 44, 32, 1) !important' } } }}
                              />
                            ))}

                            {inputFields.selected_scorer ? (
                              <>
                                <Typography variant="caption" sx={{ width: '100%', p: '20px 0 10px' }}>
                                  {`${intl.formatMessage({ id: 'ManageScore_Selected_Scorer_Caption_1' })}`}&nbsp;{inputFields.selected_scorer?.player?.alias ? inputFields.selected_scorer?.player?.alias : `${intl.formatMessage({ id: 'ManageScore_Selected_Scorer_Caption_2' })}`}&nbsp;{`${intl.formatMessage({ id: 'ManageScore_Selected_Scorer_Caption_3' })}`}
                                </Typography>
                                <Controller
                                  control={control}
                                  name="selected_passer"
                                  render={({ field }: any) => (
                                    <TextField
                                      {...field}
                                      value={field.value}
                                      select
                                      label={`${intl.formatMessage({ id: 'ManageScore_Selected_Scorer_Label' })}`}
                                      variant="outlined"
                                      error={errors?.selected_passer !== undefined}
                                      sx={{
                                        mr: { xs: '0', sm: '3%', md: '5%' },
                                        width: {
                                          xs: '100%',
                                          sm: '47%',
                                          md: '45%',
                                        },
                                      }}
                                      className={'field-bottom-space'}
                                    >
                                      {SELECTED_TEAM?.players
                                        ?.filter((p: any) => !inputFields.selected_passers?.find((ps: any) => ps?.player_giver_id === p?.id || p?.player?.id === ps?.player_giver_id))
                                        ?.filter((p: any) => p?.id !== inputFields.selected_scorer?.id)
                                        ?.map((option: any) => (
                                          <MenuItem key={option?.id} value={option}>
                                            <Grid container sx={{ alignItems: 'center' }}>
                                              <Avatar src={option?.player?.avatar?.path} alt={option?.player?.alias} />
                                              &nbsp;
                                              <strong>{option?.player?.alias}</strong>
                                            </Grid>
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  )}
                                />

                                <Controller
                                  name="nb_assists"
                                  control={control}
                                  rules={{
                                    required: false,
                                    min: 0,
                                    max: inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)),
                                  }}
                                  render={({ field }: any) => (
                                    <TextField
                                      {...field}
                                      label={`${intl.formatMessage({ id: 'ManageScore_NB_Assist_Label_1' })} ${inputFields.selected_passer?.player?.alias ? inputFields.selected_passer?.player?.alias : `${intl.formatMessage({ id: 'ManageScore_NB_Assist_Label_2' })}`}`}
                                      placeholder={'0'}
                                      type="number"
                                      variant="outlined"
                                      className={'field-bottom-space'}
                                      value={field.value <= 0 ? 0 : field.value && field.value > inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) ? inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) : field.value}
                                      sx={{
                                        ml: { xs: '0', sm: '3%', md: '5%' },
                                        width: {
                                          xs: '100%',
                                          sm: '47%',
                                          md: '45%',
                                        },
                                      }}
                                      error={errors?.nb_assists !== undefined}
                                    />
                                  )}
                                />
                              </>
                            ) : (
                              ''
                            )}
                            <Fab onClick={handleAddScorer} size="small" disabled={inputFields.nb_goals === 0} className={inputFields.nb_goals === 0 ? 'button-disabled' : 'button-green-reverse'} variant="extended" sx={{ boxShadow: 'none', mr: '20px' }}>
                              <Add />
                              &nbsp;{`${intl.formatMessage({ id: 'ManageScore_Button_Confirm_Scorer' })}`}
                            </Fab>
                            {inputFields.selected_passer ? (
                              <Fab size="small" disabled={inputFields.nb_assists === 0} className={inputFields.nb_assists === 0 ? 'button-disabled' : 'button-green-reverse'} variant="extended" sx={{ boxShadow: 'none', mr: '20px' }} onClick={handleAddNewAssist}>
                                <Add />
                                &nbsp;{`${intl.formatMessage({ id: 'ManageScore_Button_Add_Assist' })}`}
                              </Fab>
                            ) : (
                              ''
                            )}
                          </>
                        )}

                        {challenge?.results?.status === 'CONFIRMED' ? (
                          <Grid sx={{ width: '100%', textAlign: 'center', p: '30px 0% 10px' }}>
                            <Typography variant="caption">{challenge?.results?.men_of_the_match?.length > 1 ? `${intl.formatMessage({ id: 'ManageScore_MOTM_Caption_1' })}` : `${intl.formatMessage({ id: 'ManageScore_MOTM_Caption_2' })}`}</Typography>
                            {challenge?.results?.men_of_the_match?.map((p: any, index: number) => (
                              <Grid key={index} sx={{ m: '0 10px' }}>
                                <Avatar src={p?.avatar?.path} alt={p?.alias} sx={{ m: 'auto' }} />
                                <Typography variant="caption">{p?.alias}</Typography>
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <Typography variant="caption" sx={{ p: '30px 0% 10px' }}>
                            {`${intl.formatMessage({ id: 'ManageScore_MOTM_Intro_1' })}`}&nbsp;<strong>{OPPONENT_TEAM?.name || `${intl.formatMessage({ id: 'ManageScore_MOTM_Intro_2' })}`}</strong>&nbsp;{`${intl.formatMessage({ id: 'ManageScore_MOTM_Intro_3' })}`}
                            <br />
                            {`${intl.formatMessage({ id: 'ManageScore_MOTM_Intro_4' })}`}
                          </Typography>
                        )}

                        {inputFields.selected_motm?.id ? (
                          <>
                            <Typography component="span" sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                              {`${intl.formatMessage({ id: 'ManageScore_Selected_MOTM_Caption_1' })}`}&nbsp;
                              <Chip
                                label={inputFields.selected_motm?.player?.alias}
                                avatar={<Avatar src={inputFields.selected_motm?.player?.avatar?.path} alt={inputFields.selected_motm?.player?.alias} />}
                                sx={{ 'fontSize': '1em', 'm': '0 10px 10px 0', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, .4)', '& svg': { '&:hover': { color: 'rgba(238, 44, 32, 1) !important' } } }}
                                onDelete={challenge?.results?.status === 'CONFIRMED' || (challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id)) ? undefined : handleRemoveMOTM}
                              />
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Controller
                              control={control}
                              name="selected_motm"
                              render={({ field }: any) => (
                                <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'ManageScore_Selected_MOTM_Label' })}`} variant="outlined" error={errors?.selected_motm !== undefined} fullWidth className={'field-bottom-space'}>
                                  {_.concat(UNDEFINED_AS_ANY, OPPONENT_TEAM?.players ?? [])?.map((option: any, index: number) => (
                                    <MenuItem key={index} value={option}>
                                      <Grid container sx={{ alignItems: 'center' }}>
                                        <Avatar src={option?.player?.avatar?.path} alt={option?.player?.alias} />
                                        &nbsp;
                                        <strong>{option?.player?.alias || `${intl.formatMessage({ id: 'ManageScore_No_MOTM' })}`}</strong>
                                      </Grid>
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          </>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>

      {challenge?.results?.status === 'CONFIRMED' || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading ? (
        <></>
      ) : (
        <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center', textAlign: 'center', display: isSmallMobile ? 'inline-block' : 'inline-flex' }}>
          {!challenge?.results?.id ? (
            <>
              <LoadingButton onClick={handleInitScore} loading={newScore.loading} fullWidth={isSmallMobile} className={'button-green'} loadingPosition="end" endIcon={<Add />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                {`${intl.formatMessage({ id: 'ManageScore_Button_Add_Scores' })}`}
              </LoadingButton>
            </>
          ) : (
            <>
              {isDone ? (
                <>
                  {challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? (
                    ''
                  ) : (
                    <Button className={newScore.loading || savedScore.loading ? 'button-disabled' : 'button-cancel'} disabled={newScore.loading || savedScore.loading} fullWidth={isSmallMobile} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }} onClick={() => setIsDone(false)}>
                      {`${intl.formatMessage({ id: 'Global_Button_Back' })}`}
                    </Button>
                  )}

                  {challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? (
                    <LoadingButton onClick={handleUnpublishScore} className={'button-red-reversed'} fullWidth={isSmallMobile} loading={savedScore.loading} loadingPosition="end" endIcon={<Clear />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                      {`${intl.formatMessage({ id: 'ManageScore_Button_Unconfirm' })}`}
                    </LoadingButton>
                  ) : (
                    <>
                      <LoadingButton onClick={handleConfirmScore} className={'button-green'} fullWidth={isSmallMobile} loadingPosition="end" endIcon={<Send />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                        {`${intl.formatMessage({ id: 'ManageScore_Button_Confirm' })}`}
                      </LoadingButton>
                      <Modal open={isConfirmed} title={''} content={`${intl.formatMessage({ id: 'ManageScore_Confirm_Score_Content' })}`} actionOneTitle={`${intl.formatMessage({ id: 'ManageScore_Confirm_Score_Button_Yes' })}`} actionOne={handlePublishScore} actionTwoTitle={`${intl.formatMessage({ id: 'ManageScore_Confirm_Score_Button_No' })}`} actionTwo={() => setIsConfirmed(false)} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? (
                    <LoadingButton onClick={handleUnpublishScore} className={'button-red-reversed'} fullWidth={isSmallMobile} loading={savedScore.loading} loadingPosition="end" endIcon={<Clear />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                      {`${intl.formatMessage({ id: 'ManageScore_Button_Unconfirm' })}`}
                    </LoadingButton>
                  ) : (
                    <>
                      <LoadingButton onClick={handleSaveScore} disabled={inputFields.all_goals?.length === 0} className={inputFields.all_goals?.length === 0 ? 'button-disabled' : 'button-green-reverse'} fullWidth={isSmallMobile} loading={savedScore.loading} loadingPosition="end" endIcon={<Send />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                        {`${intl.formatMessage({ id: 'ManageScore_Button_Save_Continue' })}`}
                      </LoadingButton>

                      <Button onClick={() => setIsDone(true)} fullWidth={isSmallMobile} disabled={newScore.loading || savedScore.loading || inputFields.all_goals?.length === 0} className={newScore.loading || savedScore.loading || inputFields.all_goals?.length === 0 ? 'button-disabled' : 'button-green'} endIcon={<Send />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                        {`${intl.formatMessage({ id: 'ManageScore_Button_Continue' })}`}
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

ManageScoreModal.propTypes = {
  challenge_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

ManageScoreModal.defaultProps = {};
