import React from 'react';
import PropTypes from 'prop-types';

import { TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DeleteForever, Warning, Delete } from '@mui/icons-material';
import { useIntl } from 'react-intl';
export default function DeleteConfirmationModal(props: any) {
  const intl = useIntl();
  const [value, setValue] = React.useState('');

  const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setValue('');
    props.actionTwo();
  };

  const handleConfirmation = () => {
    setValue('');
    props.actionOne(props.entityId);
  };

  return (
    <>
      <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'component-modal modal-feature'} slotProps={{ backdrop: { sx: { bgcolor: 'rgba(238, 44, 32, .8)' } } }}>
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '30px', textTransform: 'uppercase' }}>
          <DeleteForever
            sx={{
              color: 'rgba(238, 44, 32, 1) !important',
              width: '50px',
              height: '50px',
            }}
          />
          <Typography
            component="span"
            sx={{
              fontWeight: '700',
              display: 'inline-block',
              width: '100%',
              fontSize: '1.1em',
            }}
          >
            {props.title}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              opacity: '.5',
              justifyContent: 'center',
              width: '100%',
              display: 'inline-flex',
            }}
          >
            <Warning sx={{ width: '20px', height: '20px', color: 'rgba(245, 178, 42, 1) !important' }} />
            {`${intl.formatMessage({ id: 'Global_Delete_Confirmation_Caption_1' })}`}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: '0 10%' }}>
          <DialogContentText id="alert-dialog-description" component="span">
            <Typography sx={{ pb: '20px', display: 'inline-block', color: 'rgba(247, 251, 250, 1)' }} component="span">
              {`${intl.formatMessage({ id: 'Global_Delete_Confirmation_Caption_2' })}`}&nbsp;{props.entityName}?
              <br />
              <br />
              {`${intl.formatMessage({ id: 'Global_Delete_Confirmation_Caption_3' })}`}&nbsp;<strong>{props.entityName}</strong>&nbsp;{`${intl.formatMessage({ id: 'Global_Delete_Confirmation_Caption_4' })}`}
            </Typography>

            <TextField value={value} onChange={handleValue} fullWidth variant="outlined" placeholder={`${props.entityName}`} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: '20px 10% 30px' }}>
          <LoadingButton onClick={handleConfirmation} loading={props.loading} loadingPosition="end" endIcon={<Delete />} className={value !== props.entityName ? 'button-disabled' : 'button-red'} disabled={value !== props.entityName}>
            {props.actionOneTitle}
          </LoadingButton>
          <Button onClick={handleClose} className={'button-cancel'}>
            {props.actionTwoTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteConfirmationModal.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
  entityId: PropTypes.any,
  entityName: PropTypes.string,
  actionOne: PropTypes.any,
  actionOneTitle: PropTypes.string,
  actionTwo: PropTypes.any,
  actionTwoTitle: PropTypes.string,
  open: PropTypes.bool,
  loading: PropTypes.bool,
};

DeleteConfirmationModal.defaultProps = {
  loading: false,
};
