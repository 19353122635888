import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogContent, Fab, Grid, TextField, Chip } from '@mui/material';
import { Send, Close } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Controller, useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';

export default function SendRecommendationModal(props: { handleClose: any; open: any }) {
  const SEND_FIVEZER_OWNER_RECOMMENDATION_MUTATION = gql`
    mutation sendFivezerOwnerRecommendation($account: inputSendFZComplexRecommendation!) {
      sendFivezerOwnerRecommendation(account: $account)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const { control, watch, setValue } = useForm({
    defaultValues: {
      email: '',
      list_emails: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();
  const [sendFivezerOwnerRecommendation, sentRecommendation] = useMutation(SEND_FIVEZER_OWNER_RECOMMENDATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    setValue('email', '');
    setValue('list_emails', ARRAY_AS_ANY);
    props.handleClose();
  };

  const handleAddEmail = () => {
    if (_.isString(inputFields.email) && emailRegex.test(inputFields.email)) {
      const newList = _.uniq(_.concat(inputFields.list_emails, inputFields.email));
      setValue('list_emails', newList);
    }

    setValue('email', '');
  };

  const handleAddMoreEmail = (event: any) => {
    if (event.key === 'Enter') {
      if (_.isString(inputFields.email) && emailRegex.test(inputFields.email)) {
        const newList = _.uniq(_.concat(inputFields.list_emails, inputFields.email));
        setValue('list_emails', newList);
      }

      setValue('email', '');
    }
  };

  const handleRemoveEmail = (email: string) => {
    const newList = inputFields.list_emails?.filter((e: string) => e !== email);
    setValue('list_emails', newList);
  };

  const handleSendRecommendation = async () => {
    try {
      handleClose();

      await sendFivezerOwnerRecommendation({
        variables: {
          account: {
            emails: _.uniq(_.concat(inputFields.list_emails, inputFields.email)?.filter((email: string) => _.isString(email) && emailRegex.test(email))),
            sender: {
              id: loggedPlayer?.id,
              firstname: loggedPlayer?.owner?.firstname,
              lastname: loggedPlayer?.owner?.lastname,
            },
            host: window.location.origin,
            language: loggedPlayer?.app_configuration?.prefered_language,
          },
        },
      });
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogContent>
        <Typography sx={{ fontSize: '1.3em', textAlign: 'center', fontWeight: '700' }}>
          <FormattedMessage id="SendRecommendationModal_Do_You_Want" />
        </Typography>
        <Typography sx={{ fontSize: '1em', textAlign: 'center' }}>
          <FormattedMessage id="SendRecommendationModal_We_Add_Them" />
        </Typography>
        <Controller
          name="email"
          control={control}
          rules={{ required: false }}
          render={({ field }: any) => (
            <TextField {...field} label={<>{inputFields.list_emails?.length >= 11 ? 'You must send those invitations' : <FormattedMessage id="SendRecommendationModal_Email_Button" />}</>} disabled={inputFields.list_emails?.length >= 11} fullWidth value={_.trim(field.value)} onBlur={handleAddEmail} onKeyDown={handleAddMoreEmail} helperText={<FormattedMessage id="SendRecommendationModal_Press_Enter" />} sx={{ 'm': '20px 0', '& .MuiFormHelperText-root': { color: 'rgba(247, 251, 250, 0.3)' } }} />
          )}
        />

        <Grid container sx={{ justifyContent: 'center' }}>
          {inputFields.list_emails?.map((email: string, index: number) => (
            <Chip key={index} label={email} onDelete={() => handleRemoveEmail(email)} sx={{ m: '5px', bgcolor: 'rgba(247, 251, 250, .8)' }} />
          ))}
        </Grid>

        <Grid container sx={{ p: { xs: '20px 5% 30px', sm: '20px 20% 30px', md: '20px 30% 30px' }, justifyContent: 'center' }}>
          <LoadingButton onClick={handleSendRecommendation} className={inputFields.list_emails?.length === 0 ? 'button-disabled' : 'button-green'} disabled={inputFields.list_emails?.length === 0} fullWidth loadingPosition="end" loading={sentRecommendation.loading} endIcon={<Send />} sx={{ m: '10px' }}>
            <FormattedMessage id="SendRecommendationModal_Send_Button" />
          </LoadingButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

SendRecommendationModal.propTypes = {
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

SendRecommendationModal.defaultProps = {};
