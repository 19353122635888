import React from 'react';
import { Stack, Autocomplete, Button, Fab, Grid, IconButton, Switch, TextField, Typography, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Card, Avatar, CardHeader } from '@mui/material';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { selectPlayer } from '../../reducers/playerSlice';
import { useAppSelector } from '../../redux/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { ARRAY_AS_ANY, CountryType, DESCRIPTION_AS_ANY, IMAGE_LIST_AS_ANY, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Controller, useForm } from 'react-hook-form';
import { selectSession } from '../../reducers/sessionSlice';
import { Add, Clear, Delete, ExpandMore, Send } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ALL_SOCIAL_MEDIAS, COUNTRIES, SPORT_TYPES } from '../../utilities/utilities';
import { LoadingButton } from '@mui/lab';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import GPSMapFeature from '../../component-modals/common/GPSMapFeature';
import UploadIMGFeature from '../../component-modals/common/UploadIMGFeature';
import { handleUpload } from '../../helpers';
import InputDescriptionsFeature from '../../component-modals/common/InputDescriptionsFeature';
import InputSelectAvailabilitiesFeature from '../../component-modals/common/InputSelectAvailabilitiesFeature';
import AddSocialMediaModal from '../../component-modals/common/AddSocialMediaModal';
import { useIntl } from 'react-intl';

interface IFormEditTeam {
  team: { id: string };
}

const FormEditTeam: React.FC = () => {
  const EDIT_FOOTBALL_TEAM_MUTATION = gql`
    mutation editFootballTeam($team: inputEditFootballTeam!) {
      editFootballTeam(team: $team) {
        id
        short_id
        name
      }
    }
  `;

  const FIND_SPORT_TEAM_TO_EDIT_BY_TEAM_ID_QUERY = gql`
    query findSportTeamToEditByTeamID($team: inputFindSportTeamByTeamID!) {
      findSportTeamToEditByTeamID(team: $team) {
        ... on FootballTeam {
          id
          short_id
          name
          shortname
          type
          is_private
          bios {
            language
            content
          }
          avatar {
            id
            filename
            size
            encoding
            type
            path
          }
          cover {
            id
            filename
            size
            encoding
            type
            path
          }
          social_medias {
            id
            name
            handle
            link
          }
          availabilities {
            day
            timeslots
          }
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                cover {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                cover {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          managers {
            id
            username
          }
          captains {
            id
            status
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          originate_location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
      }
    }
  `;

  const FIND_FOOTBALL_TEAM_ALIAS_QUERY = gql`
    query findFootballTeamName($name: String!) {
      findFootballTeamName(name: $name) {
        id
      }
    }
  `;

  const DELETE_FOOTBALL_TEAM_MUTATION = gql`
    mutation deleteFootballTeam($team: inputDeleteFootballTeam!) {
      deleteFootballTeam(team: $team)
    }
  `;

  const DELETE_SOCIAL_MEDIA_MUTATION = gql`
    mutation deleteSocialMedia($media: inputDeleteSocialMedia!) {
      deleteSocialMedia(media: $media)
    }
  `;

  const RESUME_TEAM_PLAYER_BY_ID_MUTATION = gql`
    mutation resumeTeamPlayer($team_player: inputResumeTeamPlayer!) {
      resumeTeamPlayer(team_player: $team_player)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const location: any = useLocation();
  const state: IFormEditTeam = location?.state;
  const intl = useIntl();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: UNDEFINED_AS_ANY,
      shortname: UNDEFINED_AS_ANY,
      is_private: UNDEFINED_AS_ANY,
      descriptions: ARRAY_AS_ANY,
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_description_content: '',
      address_title: UNDEFINED_AS_ANY,
      position: UNDEFINED_AS_ANY,
      street_name: UNDEFINED_AS_ANY,
      secondary_street_name: UNDEFINED_AS_ANY,
      postcode: UNDEFINED_AS_ANY,
      city: UNDEFINED_AS_ANY,
      county: UNDEFINED_AS_ANY,
      state: UNDEFINED_AS_ANY,
      country: INITIAL_COUNTRY,
      type: 'LEAGUE',
      day: 'MONDAY',
      hour: 6,
      availabilities: UNDEFINED_AS_ANY,
      sport_type: 'FOOTBALL',
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();

  const [team, setTeam] = React.useState(UNDEFINED_AS_ANY);
  const [country, setCountry] = React.useState<CountryType>(INITIAL_COUNTRY);
  const [progress, setProgress] = React.useState(0);
  const [avatar, setAvatar] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [avatarUrl, setAvatarUrl] = React.useState([]);
  const [cover, setCover] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [coverUrl, setCoverUrl] = React.useState([]);
  const [openDeleteTeam, setOpenDeleteTeam] = React.useState(false);
  const [addSocialMedia, setAddSocialMedia] = React.useState(false);

  const [resumeTeamPlayer, resumedTeamPlayer] = useMutation(RESUME_TEAM_PLAYER_BY_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteFootballTeam, deletedFootballTeam] = useMutation(DELETE_FOOTBALL_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [editFootballTeam, updatedFootballTeam] = useMutation(EDIT_FOOTBALL_TEAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteSocialMedia, removedSocialMedia] = useMutation(DELETE_SOCIAL_MEDIA_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportTeam = useQuery(FIND_SPORT_TEAM_TO_EDIT_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team: {
        id: state?.team?.id,
        logged_user_id: loggedPlayer?.id,
      },
    },
  });
  const existingFootballName = useQuery(FIND_FOOTBALL_TEAM_ALIAS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { name: _.trim(inputFields.name) },
  });

  const handleResumeTeamPlayer = async (team_player_id: string) => {
    try {
      await resumeTeamPlayer({
        variables: {
          team_player: {
            id: team_player_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setCountry(country);
      setValue('country', country);
    }
  };

  const onChangeAvatar = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setAvatar(imageList);
  };

  const onChangeCover = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setCover(imageList);
  };

  const handleClose = () => {
    setOpenDeleteTeam(false);
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedSportTeam.refetch({
        team: {
          id: state?.team?.id,
        },
      });

      if (result) {
        setTeam(result.data?.findSportTeamToEditByTeamID);
        reset();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseSocialMedia = async () => {
    try {
      setAddSocialMedia(false);
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteSocialMedia = async (media_id: string) => {
    try {
      await deleteSocialMedia({
        variables: {
          media: {
            id: media_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteTeam = async () => {
    try {
      await deleteFootballTeam({
        variables: {
          team: {
            logged_player_id: loggedPlayer?.id,
            id: team?.id,
          },
        },
      });

      setTeam(UNDEFINED_AS_ANY);
      navigate(`/teams`, {
        replace: true,
      });
    } catch (e) {
      console.log(e);
      handleClose();
    }
  };

  const handleCreateTeam = async () => {
    try {
      setProgress(1);

      if (avatar[0]?.file) {
        const avatarPromise = await handleUpload(avatar[0]?.file, avatarUrl, setAvatarUrl, session, loggedPlayer.id);
        if (avatarPromise) await Promise.all(avatarPromise);
      }

      if (cover[0]?.file) {
        const coverPromise = await handleUpload(cover[0]?.file, coverUrl, setCoverUrl, session, loggedPlayer.id);
        if (coverPromise) await Promise.all(coverPromise);
      }

      await editFootballTeam({
        variables: {
          team: {
            id: team?.id,
            player_id: loggedPlayer?.id,
            name: inputFields.name,
            is_private: inputFields.is_private || false,
            shortname: inputFields.shortname,
            bios: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
            avatar: {
              id: team?.avatar?.id,
              filename: avatar[0]?.file ? avatar[0].file.name : '',
              type: avatar[0]?.file ? avatar[0].file.type : '',
              size: avatar[0]?.file ? avatar[0].file.size : 0,
              path: avatarUrl[0],
            },
            cover: {
              id: team?.cover?.id,
              filename: cover[0]?.file ? cover[0].file.name : '',
              type: cover[0]?.file ? cover[0].file.type : '',
              size: cover[0]?.file ? cover[0].file.size : 0,
              path: coverUrl[0],
            },
            originate_location: {
              id: team?.originate_location?.id,
              coordinations: {
                latitude: inputFields.position?.lat,
                longitude: inputFields.position?.lng,
              },
              title: inputFields.address_title,
              street_name: inputFields.street_name || '',
              secondary_street_name: inputFields.secondary_street_name || '',
              postcode: inputFields.postcode,
              city: inputFields.city,
              county: inputFields.county || '',
              state: inputFields.state || '',
              country_code: country.value,
              country: country.label,
              continent: country.continent,
            },
            availabilities: _.uniq(inputFields.availabilities?.map((a: any) => a?.day))?.map((day: any) => {
              return {
                day: day,
                allday: false,
                timeslots: inputFields.availabilities?.filter((a: any) => a?.day === day)?.map((a: any) => a?.hour),
              };
            }),
          },
        },
      });

      await handleRefetch();

      navigate(`/football_team/${team?.short_id}`, {
        replace: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (team === undefined) {
      setTeam(loadedSportTeam.data?.findSportTeamToEditByTeamID);
    }

    if (inputFields.name === UNDEFINED_AS_ANY && team?.name) setValue('name', team?.name);
    if (inputFields.shortname === UNDEFINED_AS_ANY && team?.shortname) setValue('shortname', team?.shortname);
    if (inputFields.is_private === undefined && team?.is_private !== undefined) setValue('is_private', team?.is_private);
    if (inputFields.descriptions.length === 0 && team?.bios?.length > 0) {
      setValue(
        'descriptions',
        team?.bios.filter((d: any) => d.language !== '')
      );
    }
    if (inputFields.availabilities === UNDEFINED_AS_ANY && team?.availabilities) {
      const newList = team?.availabilities
        ?.map((a: any) => {
          return a?.timeslots?.map((t: number) => {
            return {
              day: a.day,
              hour: t,
            };
          });
        })
        .flat();
      setValue('availabilities', newList);
    }
    if (inputFields.address_title === UNDEFINED_AS_ANY && team?.originate_location?.title) setValue('address_title', team?.originate_location?.title);
    if (inputFields.position === undefined && team?.originate_location?.coordinates) setValue('position', { lat: team?.originate_location?.coordinates?.latitude, lng: team?.originate_location?.coordinates?.longitude });
    if (inputFields.postcode === UNDEFINED_AS_ANY && team?.originate_location?.postcode?.name) setValue('postcode', team?.originate_location?.postcode?.name);
    if (inputFields.city === UNDEFINED_AS_ANY && team?.originate_location?.city?.name) setValue('city', team?.originate_location?.city?.name);
    if (country === INITIAL_COUNTRY && team?.originate_location?.country?.name && team?.originate_location?.country?.name !== INITIAL_COUNTRY?.label) {
      setValue('country', COUNTRIES?.find((c: any) => c?.label === team?.originate_location?.country?.name) || COUNTRIES[0]);
      setCountry(COUNTRIES?.find((c: any) => c?.label === team?.originate_location?.country?.name) || COUNTRIES[0]);
    }
    if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
    if (inputFields.descriptions.length === 0 && team?.bios?.length > 0) {
      setValue(
        'descriptions',
        team?.bios.filter((d: any) => d.language !== '')
      );
    }
    if (inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');
  }, [inputFields, setValue, loadedSportTeam, team, country]);

  return (
    <Grid container className={'form-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Typography className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
          {`${intl.formatMessage({ id: 'FormEditTeam_Title' })}`}
        </Typography>

        {updatedFootballTeam.loading || deletedFootballTeam.loading ? (
          <Grid sx={{ textAlign: 'center', p: '10%' }}>
            <CircularProgress className="orange-loader" />
            <Typography
              variant="h3"
              sx={{
                fontSize: '1.3em',
                textAlign: 'center',
                width: '100%',
                color: 'rgba(247, 251, 250, 0.3) !important',
              }}
            >
              {`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
            </Typography>
          </Grid>
        ) : (
          <>
            {loggedPlayer?.profiles?.length === 0 ? (
              <Grid sx={{ textAlign: 'center' }}>
                <Typography sx={{ m: '30px 0', p: '0 10%' }}>You must have at least a footballer profile to create a team, create your profile from your profile.</Typography>
                <Fab variant="extended" size="small" className="button-green" component={Link} sx={{ mt: '20px' }} to={`/profile`}>
                  {`${intl.formatMessage({ id: 'FormEditTeam_See_Profile' })}`}
                </Fab>
              </Grid>
            ) : (
              <form className={'form-root'}>
                <UploadIMGFeature pictures={cover} setter_pictures={onChangeCover} current_cover_path={team?.cover?.path} max_number={1} ratio="cover" media_type="cover" />

                <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ textAlign: 'center', p: '10px 0', height: avatar?.length > 0 && cover?.length > 0 ? { xs: '70px', sm: '100px', md: '200px' } : 'auto' }}>
                  <UploadIMGFeature pictures={avatar} setter_pictures={onChangeAvatar} hasCover={cover?.length > 0} current_avatar_path={team?.avatar?.path} max_number={1} ratio="avatar" media_type="avatar" />
                </Stack>

                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 20px' }}>
                  {`${intl.formatMessage({ id: 'FormEditTeam_Sport_Type_Title' })}`}
                </Typography>
                <Grid id="max-width" className={'position field-bottom-space'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
                  <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                    {SPORT_TYPES.map((type: any) => (
                      <Button
                        key={type.value}
                        value={type.value}
                        sx={{
                          'height': '110px',
                          'width': '150px',
                          'm': '0 20px 0',
                          'display': 'inline-block',
                          'borderRadius': '10px',
                          'border': inputFields.sport_type === type.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                          'color': inputFields.sport_type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                          '& svg': { fontSize: '40px', color: inputFields.sport_type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                        }}
                      >
                        {type?.icon}
                        <br />
                        {`${intl.formatMessage({ id: type.label })}`}
                        {type.value === 'FOOTBALL' ? (
                          ''
                        ) : (
                          <>
                            <br />
                            <Typography sx={{ fontSize: '.7em' }}> {`${intl.formatMessage({ id: 'FormEditTeam_Sport_Type_Coming' })}`}</Typography>
                          </>
                        )}
                      </Button>
                    ))}
                  </Stack>
                </Grid>

                <Grid id="max-width" sx={{ p: '50px 10% 0' }}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'FormEditTeam_Name_Title' })}`}
                        placeholder={`${intl.formatMessage({ id: 'FormEditTeam_Name_Placeholder' })}`}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                        className={'field-bottom-space'}
                        value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
                        error={errors?.name?.type !== undefined}
                        helperText={errors?.name !== undefined || (inputFields.type === 'LEAGUE' && existingFootballName.data?.findFootballTeamName?.length > 0 && team?.name !== field.value) ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormEditTeam_Name_1' })}` : `${intl.formatMessage({ id: 'FormEditTeam_Name_2' })}`) : `${_.trim(field.value)} ${intl.formatMessage({ id: 'FormEditTeam_Name_3' })}`}` : ''}
                      />
                    )}
                  />

                  <Controller
                    name="shortname"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: 3,
                      minLength: 3,
                      pattern: /^[A-Za-z0-9_]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'FormEditTeam_Shortname_Title' })}`}
                        placeholder={`${intl.formatMessage({ id: 'FormEditTeam_Shortname_Placeholder' })}`}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        className={'field-bottom-space'}
                        value={field.value?.toUpperCase()}
                        error={errors?.shortname?.type !== undefined}
                        helperText={errors?.shortname !== undefined ? `${errors?.shortname?.type ? (field.value.length < 3 ? `${intl.formatMessage({ id: 'FormEditTeam_Shortname_1' })}` : `${intl.formatMessage({ id: 'FormEditTeam_Shortname_2' })}`) : ``}` : ''}
                      />
                    )}
                  />

                  {team?.players?.length === 0 ? (
                    ''
                  ) : (
                    <Accordion sx={{ bgcolor: 'rgba(247, 251, 250, .04)', borderRadius: '10px' }}>
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header" sx={{ color: 'rgba(247, 251, 250, 1)' }}>
                        {`${intl.formatMessage({ id: 'FormEditTeam_View_Inactive_1' })}`}&nbsp;{team?.players?.length}&nbsp;{`${intl.formatMessage({ id: 'FormEditTeam_View_Inactive_2' })}`}&nbsp;{team?.players?.length > 1 ? `${intl.formatMessage({ id: 'FormEditTeam_View_Inactive_3' })}` : `${intl.formatMessage({ id: 'FormEditTeam_View_Inactive_4' })}`}
                      </AccordionSummary>
                      <AccordionDetails sx={{ display: 'inline-block', width: '100%' }}>
                        {team?.players?.map((p: any, index: number) => (
                          <Grid key={index} sx={{ display: 'inline-block', m: '5px' }}>
                            <Card
                              key={index}
                              className={'card'}
                              sx={{
                                bgcolor: 'rgba(247, 251, 250, .1)',
                                p: '0px',
                                width: { xs: '180px', md: '200px' },
                                height: { xs: '180px', md: '200px' },
                                m: '0 10px',
                                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.9) 70%), url(${p?.player?.cover?.path})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Grid container>
                                <CardHeader
                                  action={
                                    <>
                                      {p?.status === 'INACTIVE' && (team?.managers?.find((m: any) => m?.id === loggedPlayer?.id) || team?.captains?.filter((p: any) => p?.status === 'ACTIVE')?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) || team?.team_founder?.player?.id === loggedPlayer?.id) ? (
                                        <IconButton disabled={resumedTeamPlayer.loading} className={resumedTeamPlayer.loading ? 'button-disabled' : 'button-green'} onClick={() => handleResumeTeamPlayer(p?.id)}>
                                          <Add />
                                        </IconButton>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  }
                                  sx={{ p: '0 10px', width: '100%', height: '32px' }}
                                />
                                <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Grid sx={{ color: 'rgba(247, 251, 250, 1)' }}>
                                    <Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} sx={{ m: 'auto', width: { xs: '90px' }, height: { xs: '90px' } }} />
                                    <Typography sx={{ fontWeight: '700' }}>
                                      {p?.team_number ? (
                                        <Typography component="span" sx={{ fontWeight: '700' }}>
                                          #{p?.team_number}&nbsp;&nbsp;
                                        </Typography>
                                      ) : (
                                        ''
                                      )}
                                      {p?.player?.alias}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                    {`${intl.formatMessage({ id: 'FormEditTeam_Privacy_Title' })}`}
                  </Typography>

                  <Typography>{inputFields.is_private ? `${intl.formatMessage({ id: 'FormEditTeam_Privacy_1' })}` : `${intl.formatMessage({ id: 'FormEditTeam_Privacy_2' })}`}</Typography>
                  <Controller
                    name="is_private"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('is_private', value)} inputProps={{ 'aria-label': 'controlled' }} />}
                  />

                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: '1.4em',
                      fontWeight: '700',
                      m: '40px 0 20px',
                    }}
                  >
                    {`${intl.formatMessage({ id: 'FormEditTeam_Bio_Title' })}`}
                  </Typography>
                  <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: '1.4em',
                      fontWeight: '700',
                      m: '40px 0 20px',
                    }}
                  >
                    {`${intl.formatMessage({ id: 'Global_Social_Media_Title' })}`}
                  </Typography>
                  <Fab
                    variant="extended"
                    className="button-green-reverse"
                    size="small"
                    sx={{
                      background: 'transparent',
                      boxShadow: 'none',
                    }}
                    onClick={() => setAddSocialMedia(true)}
                  >
                    <Add />
                    {`${intl.formatMessage({ id: 'Global_Social_Media_Button_1' })}`}
                  </Fab>
                  <AddSocialMediaModal open={addSocialMedia} handleClose={handleCloseSocialMedia} owner_id={team?.id} />
                  <Grid className={'social_medias-container'} sx={{ overflow: 'auto', width: '100%', mt: '30px' }}>
                    <Stack direction="row" className={'social_medias'} sx={{ display: 'inline-flex', p: '0 10%' }}>
                      {team?.social_medias?.map((s: any) => (
                        <Grid container key={s?.id} sx={{ justifyContent: 'center', alignItems: 'center', m: '0 20px', minHeight: '150px', minWidth: '150px', bgcolor: 'rgba(255, 255, 255, .04)', border: 'solid 1px rgba(255, 255, 255, .2)', borderRadius: '4px' }}>
                          <IconButton className="button-red-reversed" onClick={() => handleDeleteSocialMedia(s?.id)} disabled={removedSocialMedia.loading}>
                            <Delete />
                          </IconButton>
                          <Grid sx={{ width: '100%', textAlign: 'center' }}>
                            {ALL_SOCIAL_MEDIAS?.find((m: any) => m?.value === s?.name)?.icon}
                            <Typography variant="caption" sx={{ width: '100%', display: 'inline-block' }}>
                              {s?.name}
                            </Typography>
                            <Typography sx={{ 'fontWeight': '700', 'textDecoration': 'none', 'color': 'rgba(255,255,255,1)', '&:hover': { color: 'rgba(251, 88, 2,1)' } }} component={'a'} target={'_blank'} href={s?.link}>
                              @{s?.handle}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Stack>
                  </Grid>

                  <Typography sx={{ fontWeight: '700', p: '30px 0 20px' }}>{`${intl.formatMessage({ id: 'FormEditTeam_Area_Title' })}`}</Typography>
                  <Controller
                    name="address_title"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      max: 30,
                      pattern: /^[a-zA-Z0-9-\s]*$/,
                    }}
                    render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'FormEditTeam_Area_1' })}`} placeholder={`${intl.formatMessage({ id: 'FormEditTeam_Area_2' })}`} variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required value={field.value} error={errors?.address_title !== undefined} helperText={errors?.address_title !== undefined ? `${errors?.address_title?.message}` : ''} className={'field-bottom-space'} />}
                  />

                  <Controller
                    control={control}
                    name="country"
                    render={({ field }: any) => (
                      <Autocomplete
                        {...field}
                        options={COUNTRIES}
                        value={country}
                        className={'country-select'}
                        onChange={(event: any, value: any) => onChangeCountry(event, value)}
                        autoHighlight
                        getOptionLabel={(option: CountryType) => option.label}
                        isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                        renderOption={(props: any, option: CountryType) => (
                          <Grid
                            component="li"
                            sx={{
                              '& > img': {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                            {option.label} ({option.value})
                          </Grid>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${intl.formatMessage({ id: 'Global_Address_Country' })}`}
                            value={country}
                            required
                            variant="outlined"
                            className={'field-bottom-space'}
                            error={errors?.country !== undefined}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {/* <Controller
                name="street_name"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label="Street address" autoComplete="address-line1" placeholder="Write your team's street address" InputLabelProps={{ shrink: true }} variant="outlined" fullWidth value={field.value} error={errors?.street_name !== undefined} helperText={errors?.street_name !== undefined ? `${errors?.street_name?.message}` : ''} className={'field-bottom-space'} />}
              />

              <Controller
                name="secondary_street_name"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label="Street address (complement)" autoComplete="address-line2" InputLabelProps={{ shrink: true }} placeholder="Complement of team's street address" variant="outlined" fullWidth value={field.value} error={errors?.secondary_street_name !== undefined} helperText={errors?.secondary_street_name !== undefined ? `${errors?.secondary_street_name?.message}` : 'Apartment, suite, building, floor, etc...'} className={'field-bottom-space'} />}
              /> */}

                  <Controller
                    name="postcode"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[a-zA-Z0-9-\s]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'Global_Address_Postcode_1' })}`}
                        required
                        placeholder={`${intl.formatMessage({ id: 'Global_Address_Postcode_2' })}`}
                        autoComplete="postal-code"
                        variant="outlined"
                        value={_.replace(field.value, /[^a-zA-Z0-9-\s]+/g, '')}
                        error={errors?.postcode !== undefined}
                        InputLabelProps={{ shrink: true }}
                        helperText={errors?.postcode !== undefined ? `${errors?.postcode?.message}` : ''}
                        className={'field-bottom-space'}
                        sx={{
                          mr: { xs: '0', sm: '3%', md: '5%' },
                          width: {
                            xs: '100%',
                            sm: '47%',
                            md: '45%',
                          },
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[a-zA-Z0-9-\s]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'Global_Address_City_1' })}`}
                        required
                        placeholder={`${intl.formatMessage({ id: 'Global_Address_City_2' })}`}
                        autoComplete="address-level2"
                        variant="outlined"
                        value={field.value}
                        error={errors?.city !== undefined}
                        InputLabelProps={{ shrink: true }}
                        helperText={errors?.city !== undefined ? `${errors?.city?.message}` : ''}
                        className={'field-bottom-space'}
                        sx={{
                          ml: { xs: '0', sm: '3%', md: '5%' },
                          width: {
                            xs: '100%',
                            sm: '47%',
                            md: '45%',
                          },
                        }}
                      />
                    )}
                  />

                  {/* <Controller
                name="county"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label="County"
                    placeholder="Team's county"
                    variant="outlined"
                    value={field.value}
                    error={errors?.county !== undefined} InputLabelProps={{ shrink: true }}
                    helperText={errors?.county !== undefined ? `${errors?.county?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      mr: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="state"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label="State"
                    autoComplete="address-level1"
                    placeholder="Team's state"
                    variant="outlined"
                    value={field.value}
                    error={errors?.state !== undefined} InputLabelProps={{ shrink: true }}
                    helperText={errors?.state !== undefined ? `${errors?.state?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      ml: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              /> */}

                  <GPSMapFeature position={inputFields.position} element={'position'} setValue={setValue} height="300px" />
                </Grid>

                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '60px 10% 0px' }}>
                  {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_1' })}`}
                </Typography>
                <Typography id="max-width" variant="caption" sx={{ p: '0px 10% 20px', display: 'block' }}>
                  {`${intl.formatMessage({ id: 'InputSelectAvailabilitiesFeature_Title_2' })}`}
                </Typography>
                <InputSelectAvailabilitiesFeature setter={setValue} input={inputFields.availabilities} />

                <Grid id="max-width" className={'actions'} sx={{ p: '80px 10% 0' }}>
                  <LoadingButton disabled={updatedFootballTeam.loading || progress > 0} className={updatedFootballTeam.loading || progress > 0 ? 'button-disabled' : 'button-red-reversed'} loading={deletedFootballTeam.loading} loadingPosition="end" endIcon={<Delete />} onClick={() => setOpenDeleteTeam(true)}>
                    {`${intl.formatMessage({ id: 'Global_Button_Delete' })}`}
                  </LoadingButton>

                  <Button className={updatedFootballTeam.loading || progress > 0 ? 'button-disabled' : 'button-cancel'} disabled={updatedFootballTeam.loading || progress > 0} onClick={() => navigate(-1)} endIcon={<Clear />}>
                    {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
                  </Button>

                  <LoadingButton
                    onClick={handleCreateTeam}
                    className={!inputFields.position || inputFields.availabilities?.length === 0 || inputFields.name === '' || inputFields.address_title === '' || inputFields.city === '' || inputFields.postcode === '' ? 'button-disabled' : 'button-green'}
                    disabled={!inputFields.position || inputFields.availabilities?.length === 0 || inputFields.name === '' || inputFields.address_title === '' || inputFields.city === '' || inputFields.postcode === ''}
                    loading={updatedFootballTeam.loading || progress > 0}
                    loadingPosition="end"
                    endIcon={<Send />}
                    sx={{ m: '0 10px' }}
                  >
                    {`${intl.formatMessage({ id: 'Global_Button_Save_Updates' })}`}
                  </LoadingButton>
                </Grid>
                <DeleteConfirmationModal open={openDeleteTeam} title={`Delete ${_.truncate(team?.name)}`} entityName={team?.name} entityId={team?.id} loading={deletedFootballTeam.loading} actionOne={handleDeleteTeam} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={handleClose} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} />
              </form>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FormEditTeam;
