import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD746ktxrZu-044bCfAlNt4phNRo50Uk60',
  authDomain: 'thebhub-storage.firebaseapp.com',
  projectId: 'thebhub-storage',
  storageBucket: 'thebhub-storage.appspot.com',
  messagingSenderId: '762081727617',
  appId: '1:762081727617:web:01c5ed1b554b5c3f1c6980',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export default storage;
