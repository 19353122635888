import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation, gql } from '@apollo/client';
import _ from 'lodash';

import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectPersonalWallets } from '../../reducers/walletSlice';

import { InputAdornment, TextField, MenuItem, Grid, Typography, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';
import { FivezerLogoIcon, WalletIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import SpendingCodeModal from '../../component-modals/purchase/SpendingCodeModal';
import { CURRENCIES, BLOCKCHAIN_CURRENCIES, PAYMENT_METHODS, ALTERNATIVE_PAYMENTS, CARD_NETWORKS } from '../../utilities/utilities';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { Link } from 'react-router-dom';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';

interface IFormAddPaymentMethod {
  wallet: { id: string; type: string };
}

const FormAddPaymentMethod: React.FC = () => {
  const FIND_ACCOUNT_PERSONAL_WALLET_BY_ID_QUERY = gql`
    query findAccountPersonalWalletByID($account: inputAccountWalletByID!) {
      findAccountPersonalWalletByID(account: $account) {
        id
        title
        status
        type
        is_default
        owner {
          ... on User {
            __typename
            id
            username
            owner {
              firstname
              lastname
            }
          }
          ... on Player {
            __typename
            id
            username
            owner {
              firstname
              lastname
            }
          }
          ... on Company {
            __typename
            id
            name
            label
          }
        }
        bank_accounts {
          id
          type
          title
          accepted_currencies
          account_number
        }
        blockchain_accounts {
          id
          type
          title
          wallet_id
          wallet_addresses
          status
          accepted_currencies
        }
        beneficiaries {
          id
          firstname
          lastname
        }
        cards {
          ... on BankCard {
            __typename
            id
            network
            status
            card_number
          }
          ... on BlockchainCard {
            __typename
            id
            network
            status
            card_number
          }
        }
        payment_methods {
          id
          name
          is_default
          payment_source {
            ... on BankCard {
              __typename
              id
              network
              status
              card_number
            }
            ... on BankAccount {
              __typename
              id
              status
              accepted_currencies
            }
            ... on BlockchainCard {
              __typename
              id
              network
              status
              card_number
            }
            ... on BlockchainAccount {
              __typename
              id
              wallet_id
              status
              accepted_currencies
            }
            ... on Cash {
              __typename
              id
              name
              status
            }
            ... on Gold {
              __typename
              id
              name
              status
            }
            ... on Waste {
              __typename
              id
              name
              status
            }
          }
          associates {
            ... on User {
              __typename
              id
              username
            }
          }
        }
        sent_transactions {
          id
          tx_reference
        }
        received_transactions {
          id
          tx_reference
        }
        donations {
          id
        }
        kyc_cases {
          id
          status
        }
      }
    }
  `;

  const FIND_ALTERNATIVE_PAYMENTS_BY_WALLET_ID_QUERY = gql`
    query findAlternativePaymentsByWalletID($wallet: inputFindAlternativePayments!) {
      findAlternativePaymentsByWalletID(wallet: $wallet) {
        id
        title
        alternative_payment_sources {
          ... on Cash {
            __typename
            id
            name
            status
          }
          ... on Gold {
            __typename
            id
            name
            status
          }
          ... on Waste {
            __typename
            id
            name
            status
          }
        }
      }
    }
  `;

  const CREATE_PAYMENT_METHOD_MUTATION = gql`
    mutation createPaymentMethod($payment_method: inputCreatePaymentMethod!) {
      createPaymentMethod(payment_method: $payment_method) {
        id
        name
        status
      }
    }
  `;

  const CREATE_PAYMENT_PROVIDERS_BY_PAYMENT_METHOD_ID_MUTATION = gql`
    mutation createPaymentProvidersByPaymentMethodID($payment_method: inputCreatePaymentProvidersByPaymentMethodID!) {
      createPaymentProvidersByPaymentMethodID(payment_method: $payment_method)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedAccountPersonalWallets = useAppSelector(selectPersonalWallets);

  const location: any = useLocation();
  const state: IFormAddPaymentMethod = location?.state;

  const [createPaymentMethod, newPaymentMethod] = useMutation(CREATE_PAYMENT_METHOD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createPaymentProvidersByPaymentMethodID, newPaymentProviders] = useMutation(CREATE_PAYMENT_PROVIDERS_BY_PAYMENT_METHOD_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedUserWallet = useQuery(FIND_ACCOUNT_PERSONAL_WALLET_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      account: {
        id: loggedPlayer?.id,
        username: loggedPlayer?.username,
        wallet_id: state?.wallet?.id,
      },
    },
  });
  const loadedUserAlternativePayments = useQuery(FIND_ALTERNATIVE_PAYMENTS_BY_WALLET_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      wallet: { id: state?.wallet?.id },
    },
  });

  const ALL_WALLETS = _.concat(loggedAccountPersonalWallets);

  const [wallet, setWallet] = React.useState(ALL_WALLETS?.find((wallet: any) => wallet?.id === state?.wallet?.id));
  const [alternativePayments, setAlternativePayments] = React.useState(UNDEFINED_AS_ANY);
  const [open, setOpen] = React.useState(false);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: 'LIPAY',
      payment_source: '',
      blockchain_account_id: '',
      cashout_account_id: '',
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();

  const ALL_SOURCES = _.concat(
    inputFields.name === 'LIPAY' && ALL_WALLETS?.map((w: any) => w?.blockchain_accounts)?.flat()?.length > 0
      ? ALTERNATIVE_PAYMENTS?.map((a: any, index: number) => {
          if (alternativePayments?.find((s: any) => a?.name === s?.name)) {
            const alternative = alternativePayments?.find((s: any) => a?.name === s?.name);
            return { type: alternative?.__typename, id: alternative?.id, name: alternative?.name };
          } else {
            return { type: ALTERNATIVE_PAYMENTS[index]?.name, id: ALTERNATIVE_PAYMENTS[index]?.name, name: ALTERNATIVE_PAYMENTS[index]?.name };
          }
        })
      : [],
    wallet?.blockchain_accounts,
    wallet?.bank_accounts,
    wallet?.cards
  );

  const handleOpenConfirmation = () => {
    setOpen(true);
  };

  const handleCloseConfirmation = () => {
    setOpen(false);
  };

  const handleConfirmation = async (isCodeValide: boolean) => {
    if (isCodeValide) {
      try {
        const result = await createPaymentMethod({
          variables: {
            payment_method: {
              name: inputFields.name,
              label: _.capitalize(_.replace(inputFields.name, '_', ' ')),
              source_id: ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.id,
              source_type: ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.__typename || ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.name,
              wallet_id: wallet?.id,
              source_name: ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.name,
              blockchain_account_id: inputFields.blockchain_account_id !== '' ? inputFields.blockchain_account_id : undefined,
              cashout_account_id: inputFields.cashout_account_id !== '' ? inputFields.cashout_account_id : undefined,
            },
          },
        });

        await createPaymentProvidersByPaymentMethodID({
          variables: {
            payment_method: {
              id: result.data?.createPaymentMethod?.id,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onSubmit = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (loadedUserWallet.called && !_.isEqual(wallet, state?.wallet?.type === 'BUSINESS' ? loadedUserWallet.data?.findUserBusinessWalletByID : loadedUserWallet.data?.findAccountPersonalWalletByID)) {
      setWallet(state?.wallet?.type === 'BUSINESS' ? loadedUserWallet.data?.findUserBusinessWalletByID : loadedUserWallet.data?.findAccountPersonalWalletByID);
    }

    if (loadedUserAlternativePayments.called && loadedUserAlternativePayments.data?.findAlternativePaymentsByWalletID !== undefined && (alternativePayments === undefined || !_.isEqual(alternativePayments, loadedUserAlternativePayments.data?.findAlternativePaymentsByWalletID?.alternative_payment_sources))) {
      setAlternativePayments(loadedUserAlternativePayments.data?.findAlternativePaymentsByWalletID?.alternative_payment_sources);
    }

    if (ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.name === undefined && inputFields.blockchain_account_id !== '') {
      setValue('blockchain_account_id', '');
    }

    if (newPaymentMethod.called && newPaymentMethod.data?.createPaymentMethod && newPaymentProviders.called) {
      navigate('/w/wallet', {
        state: {
          wallet: {
            id: state?.wallet?.id,
            type: state?.wallet?.type,
          },
        },
        replace: true,
      });
    }
  }, [inputFields, navigate, setValue, createPaymentMethod, newPaymentMethod, state, wallet, loadedUserWallet, ALL_SOURCES, alternativePayments, loadedUserAlternativePayments]);

  return (
    <Grid className={'form-page component-preferences new-payment-method-page'}>
      <Grid container>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
      <Typography variant="h1" sx={{ p: '0 10%', fontSize: { xs: '1.4em', sm: '1.6em', md: '2em' }, fontWeight: '800' }}>
        New Payment method
      </Typography>

      <Typography className={'content'} sx={{ p: '0 10%' }}>
        Link one of your bank card to a payment provider to enable this payment option during your transation processes.
      </Typography>

      <Grid id="max-width" sx={{ p: '30px 10%' }}>
        <form className={'form-root'} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field }: any) => (
              <TextField
                {...field}
                label="Payment provider"
                placeholder="Input your payment method"
                select
                fullWidth
                variant="outlined"
                className={'field-bottom-space'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{PAYMENT_METHODS?.find((pm: any) => pm?.name === field.value)?.icon}</InputAdornment>,
                }}
              >
                {PAYMENT_METHODS?.map((pm: any, index: number) => (
                  <MenuItem key={index} value={pm?.name}>
                    {_.capitalize(pm?.name?.replaceAll('_', ' '))}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="payment_source"
            render={({ field }: any) => (
              <TextField {...field} select label="Payment source" variant="outlined" fullWidth placeholder="Input your payment method" className={'field-bottom-space'}>
                {ALL_SOURCES?.filter((source: any) => wallet?.payment_methods?.find((pm: any) => pm?.name === inputFields.name && pm?.payment_source?.id === source?.id) === undefined)
                  .filter((s: any) => s?.id !== undefined && ((s?.__typename !== 'BlockchainAccount' && inputFields.name !== 'LIPAY') || (s?.__typename === 'BlockchainAccount' && inputFields.name === 'LIPAY' && wallet?.blockchain_accounts?.length > 0) || (s?.name !== undefined && inputFields.name === 'LIPAY' && wallet?.blockchain_accounts?.length > 0) || (s?.network !== undefined && inputFields.name === 'LIPAY')))
                  ?.map((source: any, index: number) => (
                    <MenuItem key={index} value={source?.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      {inputFields.name === 'LIPAY' ? (
                        <Grid>
                          {source?.name === 'CASH' ? (
                            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                              {ALTERNATIVE_PAYMENTS?.find((ap: any) => ap?.name === source?.name)?.icon}&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === 'LIPAY')?.icon}&nbsp;Cash deposit
                            </Typography>
                          ) : (
                            ''
                          )}
                          {source?.name === 'GOLD' ? (
                            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                              {ALTERNATIVE_PAYMENTS?.find((ap: any) => ap?.name === source?.name)?.icon}&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === 'LIPAY')?.icon}&nbsp;Gold deposit
                            </Typography>
                          ) : (
                            ''
                          )}
                          {source?.name === 'PLASTIC_WASTE' ? (
                            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                              {ALTERNATIVE_PAYMENTS?.find((ap: any) => ap?.name === source?.name)?.icon}&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === 'LIPAY')?.icon}&nbsp;Plastic Waste deposit
                            </Typography>
                          ) : (
                            ''
                          )}
                          {source?.name === 'TYRE_WASTE' ? (
                            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                              {ALTERNATIVE_PAYMENTS?.find((ap: any) => ap?.name === source?.name)?.icon}&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === 'LIPAY')?.icon}&nbsp;Tyre Waste deposit
                            </Typography>
                          ) : (
                            ''
                          )}
                          {source?.name === 'ORGANIC_WASTE' ? (
                            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                              {ALTERNATIVE_PAYMENTS?.find((ap: any) => ap?.name === source?.name)?.icon}&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === 'LIPAY')?.icon}&nbsp;Organic Waste deposit
                            </Typography>
                          ) : (
                            ''
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}
                      {BLOCKCHAIN_CURRENCIES.find((c: any) => source?.accepted_currencies?.find((ac: any) => ac === c.value))?.sign}
                      {CARD_NETWORKS?.find((n: any) => n?.name === source?.network)?.icon}
                      &nbsp;&nbsp;
                      {source?.title ? <>{source?.title}&nbsp;&nbsp;</> : ''}
                      {source?.account_number ? `${source?.account_number?.substring(0, 5)}***${source?.account_number?.substring(source?.account_number?.length - 3, source?.account_number?.length)}` : ''}
                      {source?.card_number ? `${source?.card_number?.substring(0, 5)}***${source?.card_number?.substring(source?.card_number?.length - 3, source?.card_number?.length)}` : ''}
                      {source?.wallet_addresses ? `${source?.wallet_addresses[0]?.substring(0, 9)}***${source?.wallet_addresses[0]?.substring(source?.wallet_addresses[0]?.length - 4, source?.wallet_addresses[0]?.length)}` : ''}
                      {BLOCKCHAIN_CURRENCIES.find((c: any) => source?.accepted_currencies?.find((ac: any) => ac === c.value))?.sign ? (
                        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', fontStyle: 'italic' }}>
                          &nbsp;&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === 'LIPAY')?.icon}&nbsp;Account
                        </Typography>
                      ) : (
                        ''
                      )}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />

          {ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.name && wallet?.blockchain_accounts?.length > 0 ? (
            <Controller
              control={control}
              name="blockchain_account_id"
              render={({ field }: any) => (
                <TextField {...field} select label="Blockchain account" variant="outlined" fullWidth placeholder="Input your payment method" className={'field-bottom-space'}>
                  {ALL_SOURCES?.filter((source: any) => source?.__typename === 'BlockchainAccount' && source?.id !== undefined)?.map((source: any, index: number) => (
                    <MenuItem key={index} value={source?.id}>
                      {source?.title ? <>{source?.title}&nbsp;&nbsp;</> : ''}
                      {BLOCKCHAIN_CURRENCIES.find((c: any) => source?.accepted_currencies?.find((ac: any) => ac === c.value))?.sign}
                      {CARD_NETWORKS?.find((n: any) => n?.name === source?.network)?.icon ? <>{CARD_NETWORKS?.find((n: any) => n?.name === source?.network)?.icon}&nbsp;&nbsp;</> : ''}
                      {source?.card_number ? `${source?.card_number?.substring(0, 5)}***${source?.card_number?.substring(source?.card_number?.length - 3, source?.card_number?.length)}` : ''}
                      {source?.wallet_id ? `${source?.wallet_id?.substring(0, 9)}***${source?.wallet_id?.substring(source?.wallet_id?.length - 4, source?.wallet_id?.length)}` : ''}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ) : (
            ''
          )}

          {inputFields.name === 'LIPAY' && wallet?.bank_accounts?.length > 0 ? (
            <Controller
              control={control}
              name="cashout_account_id"
              render={({ field }: any) => (
                <TextField {...field} select label="Bank account (cashout)" variant="outlined" fullWidth placeholder="Input your payment method" className={'field-bottom-space'} disabled={wallet?.blockchain_accounts?.length === 0} helperText={wallet?.blockchain_accounts?.length === 0 ? 'You need to create a Lipay Account' : ''}>
                  {ALL_SOURCES?.filter((source: any) => source?.__typename === 'BankAccount' && source?.id !== undefined)?.map((source: any, index: number) => (
                    <MenuItem key={index} value={source?.id}>
                      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${_.split(CURRENCIES.find((c: any) => source?.accepted_currencies?.find((ac: any) => ac === c.value))?.country_code, '_', 1)[0].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${_.split(CURRENCIES.find((c: any) => source?.accepted_currencies?.find((ac: any) => ac === c.value))?.country_code, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                      &nbsp;&nbsp;
                      {source?.title}
                      &nbsp;&nbsp;
                      {source?.account_number ? `${source?.account_number?.substring(0, 9)}***${source?.account_number?.substring(source?.account_number?.length - 4, source?.account_number?.length)}` : ''}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ) : (
            ''
          )}

          <LoadingButton
            loading={newPaymentMethod.loading || newPaymentProviders.loading}
            loadingPosition="end"
            className={inputFields.payment_source === '' || inputFields.name === '' || (ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.name !== undefined && inputFields.blockchain_account_id === '') ? 'button-disabled' : 'button-form button-green'}
            disabled={inputFields.payment_source === '' || inputFields.name === '' || (ALL_SOURCES?.find((s: any) => s?.id === inputFields.payment_source)?.name !== undefined && inputFields.blockchain_account_id === '')}
            onClick={handleOpenConfirmation}
            type="submit"
            endIcon={<Send />}
          >
            Add payment method
          </LoadingButton>
        </form>
      </Grid>
      <SpendingCodeModal icon={<WalletIcon />} actionOne={handleConfirmation} actionTwo={handleCloseConfirmation} open={open} />
    </Grid>
  );
};

export default FormAddPaymentMethod;
