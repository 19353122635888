import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import ReactCodeInput from 'react-code-input';
import _ from 'lodash';
import { Typography, Alert, Box, Autocomplete, FormControlLabel, Switch, MenuItem, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Stack, Fab, InputAdornment, Button, IconButton, Snackbar, Grid, TextField, CircularProgress, Chip, useMediaQuery } from '@mui/material';
import { Add, Delete, Visibility, VisibilityOff, Send, Sms, Close, Edit } from '@mui/icons-material';
import PasswordIcon from '@mui/icons-material/Password';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers';
import { WalletIcon, LocationIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import { ALL_SOCIAL_MEDIAS, COUNTRIES, GET_PIN_CODE, MIN_CODE_LENGTH, SPORTS, TO_TIMESTAMP } from '../../utilities/utilities';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import BackButton from '../../component-modals/common/BackButton';
import SpendingCodeUpdateModal from '../../component-modals/purchase/SpendingCodeUpdateModal';
import AddSocialMediaModal from '../../component-modals/common/AddSocialMediaModal';
import AddAccountNewLocation from '../../component-modals/profile/AddAccountNewLocation';
import { ARRAY_AS_ANY, CountryType, DATE_AS_ANY, IMAGE_LIST_AS_ANY, INITIAL_COUNTRY, MEDIAS_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import EditAccountLocation from '../../component-modals/profile/EditAccountLocation';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { Link } from 'react-router-dom';
import UploadIMGFeature from '../../component-modals/common/UploadIMGFeature';
import { handleUpload } from '../../helpers';
import { useIntl } from 'react-intl';

const ProfileEditForm: React.FC = () => {
  const FIND_PLAYER_TO_EDIT_BY_ID_QUERY = gql`
    query findPlayerToEditByID($id: ID!) {
      findPlayerToEditByID(id: $id) {
        id
        username
        mobile
        bio
        roles
        permissions
        prefered_sports
        status
        email {
          address
          status
        }
        profiles {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
        }
        contacts {
          ... on Footballer {
            __typename
            id
            alias
          }
          ... on Basketballer {
            __typename
            id
            alias
          }
        }
        kyc_cases {
          id
          reference
          status
          created_at
          audited_at
          incorporation_file {
            id
            filename
            path
          }
          user_front_id {
            id
            filename
            path
          }
          user_back_id {
            id
            filename
            path
          }
          user_id_selfie {
            id
            filename
            path
          }
          user_residential_proof {
            id
            filename
            path
          }
          pid {
            id_number
            type
            status
          }
        }
        avatar {
          id
          filename
          type
          encoding
          size
          path
        }
        cover {
          id
          filename
          type
          encoding
          size
          path
        }
        social_medias {
          id
          name
          handle
          link
        }
        app_configuration {
          id
          is_dark_mode
          two_facts_mode
          prefered_language
        }
        followings {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
            owner {
              id
              firstname
              lastname
            }
          }
        }
        followers {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
              filename
            }
          }
        }
        owner {
          id
          firstname
          lastname
          date_of_birth
          gender
          emails {
            address
            status
          }
          player_account {
            id
            status
            username
            avatar {
              path
            }
            profiles {
              ... on Footballer {
                __typename
                id
                alias
              }
              ... on Basketballer {
                __typename
                id
                alias
              }
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          complex_owner_account {
            id
            status
            username
            avatar {
              path
            }
            five_complexes {
              id
            }
            social_medias {
              id
              name
              handle
              link
            }
          }
          transporter_account {
            id
            status
            username
            avatar {
              path
            }
          }
          health_worker_account {
            id
            status
            username
            avatar {
              path
            }
          }
        }
        locations {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const EDIT_PLAYER_MUTATION = gql`
    mutation editPlayer($player: inputEditPlayer!) {
      editPlayer(player: $player) {
        id
        status
        username
        mobile
        bio
        roles
        permissions
        email {
          status
          address
        }
        avatar {
          id
          filename
          type
          encoding
          size
          path
        }
        cover {
          id
          filename
          type
          encoding
          size
          path
        }
        owner {
          id
          firstname
          lastname
          emails {
            status
            address
          }
        }
        kyc_cases {
          id
          reference
          status
          pid {
            id_number
            type
            status
          }
        }
      }
    }
  `;

  const FIND_PLAYER_USERNAME_QUERY = gql`
    query findPlayerUsername($username: String!) {
      findPlayerUsername(username: $username)
    }
  `;

  const FIND_PLAYER_EMAIL_QUERY = gql`
    query findPlayerEmail($address: String!) {
      findPlayerEmail(address: $address)
    }
  `;

  const SEND_SMS_MUTATION = gql`
    mutation sendSms($mobile: String!, $content: String!) {
      sendSms(mobile: $mobile, content: $content)
    }
  `;

  const CREATE_ACCOUNT_KYC_CASE_MUTATION = gql`
    mutation createAccountKYCCase($case: inputAccountCase!) {
      createAccountKYCCase(case: $case) {
        id
        reference
        status
        level
      }
    }
  `;

  const DELETE_SOCIAL_MEDIA_MUTATION = gql`
    mutation deleteSocialMedia($media: inputDeleteSocialMedia!) {
      deleteSocialMedia(media: $media)
    }
  `;

  const DELETE_ACCOUNT_LOCATION_MUTATION = gql`
    mutation deleteAccountLocation($location: inputDeleteAccountLocation!) {
      deleteAccountLocation(location: $location)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const navigate = useNavigate();
  const intl = useIntl();

  const [player, setPlayer] = React.useState(UNDEFINED_AS_ANY);
  const [avatar, setAvatar] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [avatarUrl, setAvatarUrl] = React.useState([]);
  const [cover, setCover] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [coverUrl, setCoverUrl] = React.useState([]);

  const [pidFrontFile, setPidFrontFile] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [pidFrontFileUrl, setPidFrontFileUrl] = React.useState([]);
  const [pidBackFile, setPidBackFile] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [pidBackFileUrl, setPidBackFileUrl] = React.useState([]);
  const [pidSelfie, setPidSelfie] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [pidSelfieUrl, setPidSelfieUrl] = React.useState([]);
  const [pidIssuerCountry, setPidIssuerCountry] = React.useState<CountryType>(COUNTRIES[0]);
  // const loadedPidPictures = ((_.reduce(services.map((o: any) => { return o.pictures.length }), (sum, n) => { return sum + n; }, 0) + serviceUrls.length) > 0) && serviceUrls.length === _.reduce(services.map((o: any) => { return o.pictures.length }), (sum, n) => { return sum + n; }, 0)
  const [progress, setProgress] = React.useState(0);
  const [addSocialMedia, setAddSocialMedia] = React.useState(false);
  const [addAccountLocation, setAddAccountLocation] = React.useState(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      username: UNDEFINED_AS_ANY,
      email: player?.email.address,
      firstname: player?.owner.firstname,
      lastname: player?.owner.lastname,
      date_of_birth: DATE_AS_ANY,
      mobile: player?.mobile,
      bio: UNDEFINED_AS_ANY,
      prefered_sports: UNDEFINED_AS_ANY,
      all_sports: ARRAY_AS_ANY,
      current_pid_id_number: '',
      current_pid_type: '',
      pid_id_number: '',
      pid_type: '',
      pid_exp: DATE_AS_ANY,
      pid_issuer: '',
      pid_issuer_country: INITIAL_COUNTRY || undefined,
      pid_front: MEDIAS_AS_ANY,
      pid_back: MEDIAS_AS_ANY,
      pid_selfie: MEDIAS_AS_ANY,
      social_media_name: '',
      social_media_handle: '',
      social_media_link: '',
      pin_code: '',
      password: '',
      selected_location: '',
    },
  });
  const inputFields = watch();

  const [createAccountKYCCase, newKYCCase] = useMutation(CREATE_ACCOUNT_KYC_CASE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendSms, sentSMS] = useMutation(SEND_SMS_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [editPlayer, updatedPlayer] = useMutation(EDIT_PLAYER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteSocialMedia, removedSocialMedia] = useMutation(DELETE_SOCIAL_MEDIA_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteAccountLocation, deletedLocation] = useMutation(DELETE_ACCOUNT_LOCATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const isUsernameTaken = useQuery(FIND_PLAYER_USERNAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { username: inputFields.username },
  });
  const loadedUser = useQuery(FIND_PLAYER_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { id: loggedPlayer?.id },
  });
  const isEmailTaken = useQuery(FIND_PLAYER_EMAIL_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { address: inputFields.email },
  });

  const [newSpendingPassword, setNewSpendingPassword] = React.useState(false);
  const [spendingPasswordUpdated, setSpendingPasswordUpdated] = React.useState(false);
  const [hidePassword, setHidePassword] = React.useState(false);
  const [hasSendSMS, setHasSendSMS] = React.useState(false);
  const [verifiedMobile, setVerifiedMobile] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const [isCancelled, setIsCancelled] = React.useState(false);
  const [newKYC, setNewKYC] = React.useState(false);
  const [editAccountLocation, setOpenEditAccountLocation] = React.useState(false);
  const [openDeleteAccountLocation, setOpenDeleteAccountLocation] = React.useState(false);
  const isSmallMobile = useMediaQuery('(max-width:600px)');

  const handleRefetch = async () => {
    try {
      const result = await loadedUser.refetch({
        id: loggedPlayer?.id,
      });

      if (result) {
        setPlayer(result.data?.findPlayerToEditByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteSocialMedia = async (media_id: string) => {
    try {
      await deleteSocialMedia({
        variables: {
          media: {
            id: media_id,
          },
        },
      });

      handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseSocialMedia = async () => {
    try {
      setAddSocialMedia(false);
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleIdIssuerCountry = (event: any, country: CountryType) => {
    if (country) {
      setValue('pid_issuer_country', country);
      setPidIssuerCountry(country);
    }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSpendingPasswordUpdated(false);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const sendPinCode = async () => {
    try {
      const code = GET_PIN_CODE(6);
      // TODO: REMOVE BOTH LINES BELOW
      console.log(code?.toString());
      setPin(code?.toString());

      await sendSms({
        variables: {
          mobile: inputFields.mobile,
          content: `${intl.formatMessage({ id: 'Global_Pin_SMS_1' })} ${inputFields.firstname}, ${intl.formatMessage({ id: 'Global_Pin_SMS_2' })} ${code?.toString()}`,
        },
      });
      setPin(code);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSendSMS = () => {
    sendPinCode();
    setHasSendSMS(true);
    // reset({ pin_code: '' })
  };

  const handleValidatePin = () => {
    if (inputFields.pin_code.length === 6) {
      if (inputFields.pin_code === pin.toString()) {
        setVerifiedMobile(true);
        setPin('');
        // reset({ pin_code: '' });
      }
      setPin('');
      // reset({pin_code: ''});
    }
  };

  const handleCancel = () => {
    if (
      _.isEqual(inputFields, {
        username: loggedPlayer?.username,
        email: loggedPlayer?.email.address,
        firstname: loggedPlayer?.owner.firstname,
        lastname: loggedPlayer?.owner.lastname,
        mobile: loggedPlayer?.mobile,
        bio: loggedPlayer?.bio,
        avatar: loggedPlayer?.avatar,
        cover: loggedPlayer?.cover,
        pid_id_number: loggedPlayer?.pid?.id_number,
        pid_type: loggedPlayer?.pid?.type,
        pid_files: loggedPlayer?.pid?.files,
        pin_code: '',
        password: '',
      })
    ) {
      navigate('/profile', { replace: true });
    } else {
      setIsCancelled(true);
    }
  };

  const handleClose = () => {
    navigate('/profile', { replace: true });
  };

  const onChangeAvatar = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setAvatar(imageList);
  };

  const onChangeCover = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setCover(imageList);
  };

  const handlePidFrontFile = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setValue('pid_front', imageList);
    setPidFrontFile(imageList);
  };

  const handlePidBackFile = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setValue('pid_back', imageList);
    setPidBackFile(imageList);
  };

  const handleIdSelfie = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setValue('pid_selfie', imageList);
    setPidSelfie(imageList);
  };

  const handlePreferedSport = (option: any) => {
    const newList = _.concat(option?.label, inputFields.prefered_sports)?.filter((i: any) => i);
    setValue('prefered_sports', newList);
  };

  const handleRemovePreferedSport = (option: any) => {
    const newList = inputFields.prefered_sports?.filter((sport: string) => option !== sport);
    setValue('prefered_sports', newList);
  };

  const handleCloseAddNewLocation = async () => {
    try {
      setAddAccountLocation(false);
      handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenEditAccountLocation = (location_id: string) => {
    setValue('selected_location', location_id);
    setOpenEditAccountLocation(true);
  };

  const handleCloseEditAccountLocation = () => {
    handleRefetch();
    setValue('selected_location', '');
    setOpenEditAccountLocation(false);
    handleRefetch();
  };

  const handleOpenDeleteLocation = (location_id: string) => {
    setValue('selected_location', location_id);
    setOpenDeleteAccountLocation(true);
  };

  const handleCloseDeleteLocation = () => {
    setValue('selected_location', '');
    setOpenDeleteAccountLocation(false);
  };

  const handleDeleteAccountLocation = async () => {
    try {
      await deleteAccountLocation({
        variables: {
          location: {
            id: inputFields.selected_location,
            account_id: loggedPlayer?.id,
          },
        },
      });
      handleCloseDeleteLocation();
      handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async () => {
    try {
      setProgress(1);

      if (avatar[0]?.file) {
        const avatarPromise = await handleUpload(avatar[0]?.file, avatarUrl, setAvatarUrl, session, loggedPlayer.id);
        if (avatarPromise) await Promise.all(avatarPromise);
      }

      if (cover[0]?.file) {
        const coverPromise = await handleUpload(cover[0]?.file, coverUrl, setCoverUrl, session, loggedPlayer.id);
        if (coverPromise) await Promise.all(coverPromise);
      }

      if (pidFrontFile.length > 0) {
        const pidFrontPromise = await handleUpload(pidFrontFile[0].file, pidFrontFileUrl, setPidFrontFileUrl, session, loggedPlayer.id);
        if (pidFrontPromise) await Promise.all(pidFrontPromise);
      }

      if (pidBackFile.length > 0) {
        const pidBackPromise = await handleUpload(pidBackFile[0].file, pidBackFileUrl, setPidBackFileUrl, session, loggedPlayer.id);
        if (pidBackPromise) await Promise.all(pidBackPromise);
      }

      if (pidSelfie.length > 0) {
        const pidSelfiePromise = await handleUpload(pidSelfie[0].file, pidSelfieUrl, setPidSelfieUrl, session, loggedPlayer.id);
        if (pidSelfiePromise) await Promise.all(pidSelfiePromise);
      }

      await editPlayer({
        variables: {
          player: {
            id: player?.id,
            username: inputFields.username.toLowerCase(),
            email: inputFields.email,
            mobile: inputFields.mobile,
            bio: _.truncate(inputFields.bio, { length: 300 }),
            prefered_sports: inputFields.prefered_sports,
            avatar: {
              id: player?.avatar.id,
              filename: avatar[0]?.file ? avatar[0].file.name : '',
              type: avatar[0]?.file ? avatar[0].file.type : '',
              size: avatar[0]?.file ? avatar[0].file.size : 0,
              path: avatarUrl[0],
            },
            cover: {
              id: player?.cover.id,
              filename: cover[0]?.file ? cover[0].file.name : '',
              type: cover[0]?.file ? cover[0].file.type : '',
              size: cover[0]?.file ? cover[0].file.size : 0,
              path: coverUrl[0],
            },
            password: inputFields.password,
            owner: {
              id: player?.owner.id,
              firstname: inputFields.firstname,
              lastname: inputFields.lastname,
              date_of_birth: TO_TIMESTAMP(inputFields.date_of_birth),
            },
          },
        },
      });

      if (inputFields.pid_id_number !== '' && inputFields.pid_type !== '' && inputFields.pid_exp !== null && inputFields.pid_issuer !== '' && inputFields.pid_issuer_country !== undefined && newKYC) {
        await createAccountKYCCase({
          variables: {
            case: {
              author_id: player?.id,
              author_typename: player?.__typename,
              reference: `case-u-*${player?.id}-d-*${Date.now()}`,
              title: `[KYC ACCOUNT] ${player?.id}`,
              user_id_type: inputFields.pid_type,
              user_id_number: inputFields.pid_id_number,
              user_id_exp: parseFloat(TO_TIMESTAMP(inputFields.pid_exp).toFixed(1)),
              user_id_issuer: inputFields.pid_issuer,
              user_id_issuer_country: pidIssuerCountry.value,
              user_front_id: {
                filename: pidFrontFile[0]?.file?.name,
                size: pidFrontFile[0]?.file?.size,
                type: pidFrontFile[0]?.file?.type,
                path: pidFrontFileUrl[0] ? pidFrontFileUrl[0] : '',
              },
              user_back_id: {
                filename: pidBackFile[0]?.file?.name,
                size: pidBackFile[0]?.file?.size,
                type: pidBackFile[0]?.file?.type,
                path: pidBackFileUrl[0] ? pidBackFileUrl[0] : '',
              },
              user_id_selfie: {
                filename: pidSelfie[0]?.file?.name,
                size: pidSelfie[0]?.file?.size,
                type: pidSelfie[0]?.file?.type,
                path: pidSelfieUrl[0] ? pidSelfieUrl[0] : '',
              },
            },
          },
        });
      }

      await handleRefetch();

      navigate('/profile', { replace: true });
    } catch (e) {
      reset({ password: '' });
    }
  };

  React.useEffect(() => {
    if (player === undefined && loadedUser.data?.findPlayerToEditByID) {
      setPlayer(loadedUser.data?.findPlayerToEditByID);
    }

    if (player) {
      if (inputFields.username === UNDEFINED_AS_ANY) setValue('username', player?.username);
      if (inputFields.email === undefined) setValue('email', player?.email.address);
      if (inputFields.firstname === undefined) setValue('firstname', player?.owner.firstname);
      if (inputFields.lastname === undefined) setValue('lastname', player?.owner.lastname);
      if (!inputFields.date_of_birth && player?.owner?.date_of_birth) setValue('date_of_birth', new Date(player?.owner?.date_of_birth));
      if (inputFields.bio === undefined && player?.bio) setValue('bio', player?.bio);
      if (inputFields.prefered_sports === undefined && player?.prefered_sports) setValue('prefered_sports', player?.prefered_sports);
      if (inputFields.mobile === undefined) setValue('mobile', player?.mobile);
      if (inputFields.current_pid_id_number === '' && player?.kyc_cases[0]?.pid?.id_number !== undefined) setValue('current_pid_id_number', player?.kyc_cases[0]?.pid?.id_number);
      if (inputFields.current_pid_type === 'NONE' && player?.kyc_cases[0]?.pid?.type !== undefined) setValue('current_pid_type', player?.kyc_cases[0]?.pid?.type);
      if (inputFields.current_pid_id_number === '' && inputFields.current_pid_type === '') setValue('current_pid_type', 'NONE');
      if (inputFields.pid_issuer_country === undefined) setValue('pid_issuer_country', COUNTRIES[0]);
      if (player?.kyc_cases.length === 0 && !newKYC) setNewKYC(true);
      if (!newKYC && inputFields.pid_id_number !== '') setValue('pid_id_number', '');
    }
  }, [inputFields, setValue, newKYC, player, loadedUser]);

  return (
    <Grid container className="form-page" sx={{ pl: '0', pr: '0' }}>
      <Grid item xs={12} md={12} lg={12} sx={{ padding: '0 0 100px 0' }}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h1" sx={{ p: '0 10%', fontSize: { xs: '1.4em', sm: '1.6em', md: '2em' } }}>
            {`${intl.formatMessage({ id: 'FormEditProfile_Title' })}`}
          </Typography>
        </Grid>

        {!player || updatedPlayer.loading || newKYCCase.loading ? (
          <Grid sx={{ textAlign: 'center', p: '10%' }}>
            <CircularProgress className="orange-loader" />
            <Typography
              variant="h3"
              sx={{
                fontSize: '1.3em',
                textAlign: 'center',
                width: '100%',
                color: 'rgba(247, 251, 250, 0.3) !important',
              }}
            >
              {`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
            </Typography>
          </Grid>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <UploadIMGFeature pictures={cover} setter_pictures={onChangeCover} current_cover_path={player?.cover?.path} max_number={1} ratio="cover" media_type="cover" />

            <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ textAlign: 'center', p: '10px 0', height: avatar?.length > 0 && cover?.length > 0 ? { xs: '70px', sm: '100px', md: '200px' } : 'auto' }}>
              <UploadIMGFeature pictures={avatar} setter_pictures={onChangeAvatar} hasCover={cover?.length > 0} current_avatar_path={player?.avatar?.path} max_number={1} ratio="avatar" media_type="avatar" />
            </Stack>

            <Grid id="max-width" sx={{ p: '0 10%' }}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '-40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormEditProfile_Basic_Title' })}`}
              </Typography>
              <Controller
                name="username"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  max: 30,
                  pattern: /^[a-z0-9_]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormEditProfile_Username_Label' })}`}
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={field?.value?.toLowerCase()}
                    error={errors?.username?.type !== undefined}
                    className={'field-bottom-space'}
                    helperText={(isUsernameTaken.data?.findUserUsername && isUsernameTaken.called && field.value !== player?.username ? `${field.value.toLowerCase()} ${intl.formatMessage({ id: 'FormEditProfile_Username_1' })}` : '') || (errors?.username?.type !== undefined ? `${intl.formatMessage({ id: 'FormEditProfile_Username_2' })}` : '')}
                  />
                )}
              />

              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  maxLength: 40,
                  minLength: 2,
                  pattern: /[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormEditProfile_Email_Label' })}`}
                    type={'email'}
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    className={'field-bottom-space'}
                    helperText={
                      inputFields?.email !== player?.email.address && isEmailTaken.data?.findPlayerEmail && isEmailTaken.called ? (
                        <Typography variant="caption" className="error-msg">
                          {field.value} {`${intl.formatMessage({ id: 'FormEditProfile_Email_1' })}`}
                        </Typography>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
              />

              <Controller
                name="firstname"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 2,
                  // pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
                }}
                render={({ field }: any) => <TextField {...field} value={field.value} label={`${intl.formatMessage({ id: 'FormEditProfile_Firstname_Label' })}`} fullWidth required InputLabelProps={{ shrink: true }} className={'field-bottom-space'} />}
              />

              <Controller
                name="lastname"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 2,
                  // pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
                }}
                render={({ field }: any) => <TextField {...field} value={field.value} label={`${intl.formatMessage({ id: 'FormEditProfile_Lastname_Label' })}`} fullWidth required InputLabelProps={{ shrink: true }} className={'field-bottom-space'} />}
              />

              <Controller
                name="date_of_birth"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }: any) => (
                  <DatePicker
                    {...field}
                    format="dd/MM/yyyy"
                    views={['year', 'month', 'day']}
                    disableFuture
                    openTo="year"
                    maxDate={new Date()}
                    value={field.value}
                    slotProps={{
                      textField: {
                        label: `${intl.formatMessage({ id: 'FormEditProfile_DOB_Label' })}`,
                        required: true,
                        placeholder: 'Input your date of birth',
                        className: 'field-bottom-space',
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="bio"
                control={control}
                rules={{
                  required: false,
                  maxLength: 300,
                  minLength: 0,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormEditProfile_Bio_Label' })}`}
                    fullWidth
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    value={_.truncate(field?.value, {
                      length: 300,
                    })}
                    error={field?.value?.length > 300}
                    className={'field-bottom-space'}
                    helperText={field?.value?.length > 300 ? `${intl.formatMessage({ id: 'FormEditProfile_Bio_1' })} ${field?.value?.length - 300} ${field?.value?.length - 300 > 1 ? `${intl.formatMessage({ id: 'FormEditProfile_Bio_2' })}` : `${intl.formatMessage({ id: 'FormEditProfile_Bio_3' })}`}` : ''}
                  />
                )}
              />

              <Controller
                name="mobile"
                control={control}
                rules={{
                  required: false,
                  pattern: /^[0-9+]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormEditProfile_Mobile_Label' })}`}
                    type="tel"
                    fullWidth
                    value={field.value}
                    className={'field-bottom-space'}
                    inputProps={{
                      inputMode: 'tel',
                      pattern: '[0-9+]*',
                    }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {inputFields.mobile !== loggedPlayer.mobile ? (
                            <LoadingButton
                              onClick={handleSendSMS}
                              disabled={inputFields.pin_code?.length === 6 || verifiedMobile || field.value === '' || (field.value?.charAt(0) !== '+' && !field.value?.startsWith('0', 1) && field.value?.length >= 3) || field.value?.length < 8 || field.value?.length > 20}
                              className={inputFields.pin_code?.length === 6 || verifiedMobile || field.value === '' || (field.value?.charAt(0) !== '+' && !field.value?.startsWith('0', 1) && field.value?.length >= 3) || field.value?.length < 8 || field.value?.length > 20 ? 'button-disabled' : 'button-green-reverse'}
                              loading={sentSMS.loading}
                              loadingPosition="end"
                              loadingIndicator={`${intl.formatMessage({ id: 'SignUpForm_Sending_SMS' })}`}
                              // startIcon={sendSMS ? <Sms /> : ""}
                              endIcon={hasSendSMS ? <Sms /> : <Send />}
                            >
                              {`${intl.formatMessage({ id: 'SignUpForm_Send_SMS' })}`}
                            </LoadingButton>
                          ) : (
                            ''
                          )}
                        </InputAdornment>
                      ),
                    }}
                    helperText={field.value?.charAt(0) !== '+' && !field.value?.startsWith('0', 1) && field.value?.length >= 3 ? `${intl.formatMessage({ id: 'SignUpForm_Use_International_Number' })}` : ''}
                  />
                )}
              />

              {inputFields.mobile !== loggedPlayer.mobile ? (
                <Grid container>
                  {inputFields.pin_code?.length === 6 && verifiedMobile ? (
                    ''
                  ) : (
                    <>
                      <Controller
                        name="pin_code"
                        control={control}
                        rules={{
                          required: false,
                          pattern: /^[0-9]*$/,
                        }}
                        render={({ field }: any) => <ReactCodeInput {...field} name="sms-pin" type="password" fields={6} inputMode="numeric" autoFocus={false} />}
                      />
                    </>
                  )}

                  {!verifiedMobile && (sentSMS.loading || !sentSMS.called) ? (
                    <LoadingButton className={inputFields.pin_code?.length !== 6 ? 'button-disabled' : 'button-green-reverse'} loadingPosition="end" loadingIndicator={`${intl.formatMessage({ id: 'Global_Button_Loading' })}`} endIcon={sentSMS.loading && inputFields.pin_code?.length === 6 ? <CircularProgress size={20} className="green-loader" /> : <Send />} onClick={handleValidatePin} sx={{ mt: 1, mr: 1 }} disabled={inputFields.pin_code?.length !== 6}>
                      {`${intl.formatMessage({ id: 'FormEditProfile_Mobile_1' })}`}
                    </LoadingButton>
                  ) : (
                    ''
                  )}

                  {verifiedMobile ? (
                    <Alert severity="success" sx={{ width: '100%' }}>
                      Mobile successfully verified!
                    </Alert>
                  ) : (
                    ''
                  )}
                </Grid>
              ) : (
                ''
              )}

              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormEditProfile_Passwords_Title' })}`}
              </Typography>
              <Controller
                name="password"
                control={control}
                rules={{ required: false }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormEditProfile_Passwords_Label' })}`}
                    type={hidePassword ? 'text' : 'password'}
                    placeholder={`${intl.formatMessage({ id: 'FormEditProfile_Passwords_1' })}`}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                            {hidePassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Button className={inputFields.password?.length < MIN_CODE_LENGTH ? 'button-disabled' : 'button-green-reverse'} disabled={inputFields.password?.length < MIN_CODE_LENGTH} onClick={() => setNewSpendingPassword(!newSpendingPassword)} endIcon={<PasswordIcon />}>
                {`${intl.formatMessage({ id: 'FormEditProfile_Passwords_2' })}`}
              </Button>
              <SpendingCodeUpdateModal icon={<WalletIcon />} actionOne={setSpendingPasswordUpdated} actionTwo={() => setNewSpendingPassword(false)} open={newSpendingPassword} isConfirmed={spendingPasswordUpdated} />
              <Snackbar
                open={spendingPasswordUpdated}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                  {`${intl.formatMessage({ id: 'FormEditProfile_Passwords_3' })}`}
                </Alert>
              </Snackbar>

              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormEditProfile_KYC_Title' })}`}
              </Typography>
              {player?.kyc_cases?.length !== undefined && player?.kyc_cases?.length > 0 ? (
                <Grid>
                  <Controller
                    name="current_pid_type"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'FormEditProfile_ID_Type_Label' })}`}
                        id="pid_type"
                        fullWidth
                        select
                        disabled
                        className={'field-bottom-space'}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            'WebkitTextFillColor': 'rgba(247, 251, 250, .4) !important',
                            '& input': {
                              WebkitTextFillColor: 'rgba(247, 251, 250, .4) !important',
                            },
                          },
                        }}
                        SelectProps={{
                          sx: {
                            '.MuiSelect-outlined.Mui-disabled': {
                              WebkitTextFillColor: 'rgba(247, 251, 250, .4) !important',
                            },
                          },
                        }}
                      >
                        {[
                          {
                            value: 'NONE',
                            label: 'Global_ID_Types_None',
                          },
                          {
                            value: 'PASSPORT',
                            label: 'Global_ID_Types_Passport',
                          },
                          {
                            value: 'NATIONAL',
                            label: 'Global_ID_Types_National',
                          },
                          {
                            value: 'TEMPORARILY',
                            label: 'Global_ID_Types_Residential',
                          },
                        ].map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {`${intl.formatMessage({ id: option.label })}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    name="current_pid_id_number"
                    control={control}
                    rules={{
                      required: false,
                      pattern: /^[a-zA-Z0-9]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label={`${intl.formatMessage({ id: 'FormEditProfile_ID_Number_Label' })}`}
                        fullWidth
                        disabled
                        className={'field-bottom-space'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          'WebkitTextFillColor': 'rgba(247, 251, 250, .4) !important',
                          '& input': {
                            WebkitTextFillColor: 'rgba(247, 251, 250, .4) !important',
                          },
                          '& .MuiFormHelperText-root.Mui-disabled': {
                            WebkitTextFillColor: 'initial',
                          },
                        }}
                        helperText={
                          <Typography variant="caption" sx={{ color: 'rgba(247, 251, 250, .4)' }}>
                            {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={player?.kyc_cases[0]?.pid?.status}>{player?.kyc_cases[0]?.pid?.status ? _.upperCase(_.replace(player?.kyc_cases[0]?.pid?.status, '_', ' ')) : 'NONE'}</strong>
                          </Typography>
                        }
                      />
                    )}
                  />
                </Grid>
              ) : (
                ''
              )}
              <FormControlLabel
                label={
                  <span>
                    {`${intl.formatMessage({ id: 'FormEditProfile_New_KYC_Title' })}`} <strong>{newKYC ? `${intl.formatMessage({ id: 'FormEditProfile_New_KYC_Label_1' })}` : `${intl.formatMessage({ id: 'FormEditProfile_New_KYC_Label_2' })}`}</strong>
                  </span>
                }
                sx={{ mb: '30px' }}
                control={
                  <Switch
                    checked={newKYC}
                    className="green-checkbox"
                    onChange={() => setNewKYC(!newKYC)}
                    inputProps={{
                      'aria-label': 'controlled',
                    }}
                  />
                }
              />
            </Grid>

            {newKYC ? (
              <Grid id="max-width" sx={{ p: '0 10%' }}>
                <Controller
                  name="pid_id_number"
                  control={control}
                  rules={{
                    required: inputFields.pid_id_number !== '' ? true : false,
                    pattern: /^[a-zA-Z0-9]*$/,
                  }}
                  render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'FormEditProfile_ID_Number_Label' })}`} fullWidth className={'field-bottom-space'} InputLabelProps={{ shrink: true }} />}
                />

                <Controller
                  name="pid_type"
                  control={control}
                  rules={{
                    required: inputFields.pid_id_number !== '' ? true : false,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={`${intl.formatMessage({ id: 'FormEditProfile_ID_Type_Label' })}`}
                      id="pid_type"
                      select
                      required={inputFields.pid_id_number !== '' ? true : false}
                      InputLabelProps={{ shrink: true }}
                      className={'field-bottom-space'}
                      sx={{
                        mr: {
                          xs: '0',
                          sm: '3%',
                          md: '5%',
                        },
                        width: {
                          xs: '100%',
                          sm: '47%',
                          md: '45%',
                        },
                        mt: '0',
                      }}
                    >
                      {[
                        {
                          value: 'NONE',
                          label: 'Global_ID_Types_None',
                        },
                        {
                          value: 'PASSPORT',
                          label: 'Global_ID_Types_Passport',
                        },
                        {
                          value: 'NATIONAL',
                          label: 'Global_ID_Types_National',
                        },
                        {
                          value: 'TEMPORARILY',
                          label: 'Global_ID_Types_Residential',
                        },
                      ].map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {`${intl.formatMessage({ id: option.label })}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="pid_exp"
                  control={control}
                  rules={{
                    required: inputFields.pid_id_number !== '' ? true : false,
                  }}
                  render={({ field }: any) => (
                    <DatePicker
                      {...field}
                      format="dd/MM/yyyy"
                      views={['day', 'month', 'year']}
                      minDate={new Date()}
                      value={field.value}
                      slotProps={{
                        textField: {
                          label: `${intl.formatMessage({ id: 'FormEditProfile_New_KYC_Exp_Label' })}`,
                          required: inputFields.pid_id_number !== '' ? true : false,
                          placeholder: 'Input expiration date',
                          InputLabelProps: {
                            shrink: true,
                          },
                          sx: {
                            ml: {
                              xs: '0',
                              sm: '3%',
                              md: '5%',
                            },
                            width: {
                              xs: '100%',
                              sm: '47%',
                              md: '45%',
                            },
                          },
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="pid_issuer_country"
                  control={control}
                  rules={{
                    required: inputFields.pid_id_number !== '' ? true : false,
                  }}
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      options={COUNTRIES}
                      value={pidIssuerCountry}
                      className={'country-select'}
                      onChange={(event: any, value: any) => handleIdIssuerCountry(event, value)}
                      autoHighlight
                      getOptionLabel={(option: CountryType) => option.label}
                      sx={{ display: 'inline' }}
                      isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                      renderOption={(props: any, option: CountryType) => (
                        <Box
                          component="li"
                          sx={{
                            '& > img': {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                          {option.label} ({option.value})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${intl.formatMessage({ id: 'FormEditProfile_New_KYC_Issuer_Country_Label' })}`}
                          value={pidIssuerCountry}
                          variant="outlined"
                          className={'field-bottom-space'}
                          required={inputFields.pid_id_number !== '' ? true : false}
                          error={errors?.pid_issuer_country !== undefined}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="pid_issuer"
                  control={control}
                  rules={{
                    required: inputFields.pid_id_number !== '' ? true : false,
                  }}
                  render={({ field }: any) => <TextField {...field} value={field.value} required={inputFields.pid_id_number !== '' ? true : false} label={`${intl.formatMessage({ id: 'FormEditProfile_New_KYC_Issuer_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormEditProfile_New_KYC_Issuer_Placeholder' })}`} variant="outlined" fullWidth InputLabelProps={{ shrink: true }} />}
                />

                <Stack direction="row" className={'field-bottom-space'} sx={{ justifyContent: 'center' }}>
                  <Controller
                    name="pid_front"
                    control={control}
                    rules={{
                      required: inputFields.pid_id_number !== '' ? true : false,
                    }}
                    render={() => <UploadIMGFeature media_type="Front ID" pictures={pidFrontFile} setter_pictures={handlePidFrontFile} max_number={1} accepted_types={['jpg', 'jpeg', 'png']} />}
                  />

                  <Controller
                    name="pid_back"
                    control={control}
                    rules={{
                      required: inputFields.pid_id_number !== '' ? true : false,
                    }}
                    render={() => <UploadIMGFeature media_type="Back ID" pictures={pidBackFile} setter_pictures={handlePidBackFile} max_number={1} accepted_types={['jpg', 'jpeg', 'png']} />}
                  />
                </Stack>
                <Controller
                  name="pid_selfie"
                  control={control}
                  rules={{
                    required: inputFields.pid_id_number !== '' ? true : false,
                  }}
                  render={() => <UploadIMGFeature media_type="Selfie ID" pictures={pidSelfie} setter_pictures={handleIdSelfie} max_number={1} accepted_types={['jpg', 'jpeg', 'png']} />}
                />
              </Grid>
            ) : (
              ''
            )}

            <Grid id="max-width" sx={{ p: '0 10%' }}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormEditProfile_Preferenced_Sport_Title' })}`}
              </Typography>
              {isSmallMobile ? (
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'center',
                    mb: '20px',
                  }}
                >
                  <Grid>
                    {inputFields.prefered_sports?.length === 0 ? (
                      <Typography
                        variant="caption"
                        sx={{
                          m: '0px',
                          p: '5px 20px',
                          textAlign: 'center',
                          bgcolor: 'rgba(0, 0, 0, .1)',
                          borderRadius: '5px',
                        }}
                      >
                        {`${intl.formatMessage({ id: 'FormEditProfile_Preferenced_Sport_1' })}`}
                      </Typography>
                    ) : (
                      inputFields.prefered_sports?.map((sport: any, index: number) => <Chip key={index} variant="outlined" label={sport} onDelete={() => handleRemovePreferedSport(sport)} sx={{ 'fontSize': '1.2em', 'm': '5px', 'p': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(247, 251, 250, .1) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }} size="medium" />)
                    )}
                  </Grid>
                </Stack>
              ) : (
                ''
              )}
              <Controller
                name="all_sports"
                control={control}
                rules={{ required: false }}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    multiple
                    autoHighlight
                    disablePortal
                    options={SPORTS?.filter((s: any) => !inputFields.prefered_sports?.find((sp: any) => sp === s?.label))}
                    fullWidth
                    onChange={(event: any, value: any) => setValue('prefered_sports', _.concat(value, inputFields.prefered_sports))}
                    getOptionLabel={(option: any) => option?.label}
                    renderOption={(props: any, option: any) => (
                      <Grid
                        container
                        key={option?.id}
                        onClick={() => handlePreferedSport(option)}
                        sx={{
                          alignItems: 'center',
                          p: '10px 15px',
                        }}
                      >
                        {`${intl.formatMessage({ id: option?.label })}`}
                      </Grid>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${intl.formatMessage({ id: 'FormEditProfile_Preferenced_Sport_Label' })}`}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              {isSmallMobile ? (
                                ''
                              ) : (
                                <Grid>
                                  {inputFields.prefered_sports?.length === 0 ? (
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        m: '0px',
                                        p: '5px 20px',
                                        textAlign: 'center',
                                        bgcolor: 'rgba(0, 0, 0, .1)',
                                        borderRadius: '5px',
                                      }}
                                    >
                                      {`${intl.formatMessage({ id: 'FormEditProfile_Preferenced_Sport_1' })}`}
                                    </Typography>
                                  ) : (
                                    inputFields.prefered_sports?.map((sport: any, index: number) => <Chip key={index} variant="outlined" label={`${intl.formatMessage({ id: sport })}`} onDelete={() => handleRemovePreferedSport(sport)} sx={{ 'fontSize': '1.2em', 'm': '5px', 'p': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(247, 251, 250, .1) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }} size="medium" />)
                                  )}
                                </Grid>
                              )}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />

              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'Global_Social_Media_Title' })}`}
              </Typography>
              <Fab
                variant="extended"
                className="button-green-reverse"
                size="small"
                sx={{
                  background: 'transparent',
                  boxShadow: 'none',
                }}
                onClick={() => setAddSocialMedia(true)}
              >
                <Add />
                &nbsp;
                {`${intl.formatMessage({ id: 'Global_Social_Media_Button_1' })}`}
              </Fab>
              <Grid container>
                <AddSocialMediaModal open={addSocialMedia} handleClose={handleCloseSocialMedia} owner_id={player?.id} />

                <Grid className={'social_medias-container'} sx={{ overflow: 'auto', width: '100%', mt: '30px' }}>
                  <Stack direction="row" className={'social_medias'} sx={{ display: 'inline-flex', p: '0 10%' }}>
                    {player?.social_medias?.map((s: any) => (
                      <Grid container key={s?.id} sx={{ justifyContent: 'center', alignItems: 'center', m: '0 20px', minHeight: '150px', minWidth: '150px', bgcolor: 'rgba(255, 255, 255, .04)', border: 'solid 1px rgba(255, 255, 255, .2)', borderRadius: '4px' }}>
                        <IconButton className="button-red-reversed" onClick={() => handleDeleteSocialMedia(s?.id)} disabled={removedSocialMedia.loading}>
                          <Delete />
                        </IconButton>
                        <Grid sx={{ width: '100%', textAlign: 'center' }}>
                          {ALL_SOCIAL_MEDIAS?.find((m: any) => m?.value === s?.name)?.icon}
                          <Typography variant="caption" sx={{ width: '100%', display: 'inline-block' }}>
                            {s?.name}
                          </Typography>
                          <Typography sx={{ 'fontWeight': '700', 'textDecoration': 'none', 'color': 'rgba(255,255,255,1)', '&:hover': { color: 'rgba(251, 88, 2,1)' } }} component={'a'} target={'_blank'} href={s?.link}>
                            @{s?.handle}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Stack>
                </Grid>
              </Grid>

              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormEditProfile_Locations_Title' })}`}
              </Typography>
              <Fab
                variant="extended"
                className="button-green-reverse"
                size="small"
                sx={{
                  background: 'transparent',
                  boxShadow: 'none',
                }}
                onClick={() => setAddAccountLocation(true)}
              >
                <Add />
                &nbsp;
                {`${intl.formatMessage({ id: 'FormEditProfile_Button_Add_Location' })}`}
              </Fab>

              <AddAccountNewLocation open={addAccountLocation} handleClose={handleCloseAddNewLocation} />
              <EditAccountLocation location_id={inputFields.selected_location} open={editAccountLocation} handleClose={handleCloseEditAccountLocation} />
              <DeleteConfirmationModal
                title={`${intl.formatMessage({ id: 'Global_Permanent_Delete_Title' })} ${player?.locations.find((l: any) => l?.id === inputFields.selected_location)?.title}`}
                open={openDeleteAccountLocation}
                entityId={inputFields.selected_location}
                loading={deletedLocation.loading}
                entityName={`${player?.locations.find((l: any) => l?.id === inputFields.selected_location)?.title}`}
                actionOne={handleDeleteAccountLocation}
                actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`}
                actionTwo={handleCloseDeleteLocation}
                actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
              />
            </Grid>

            <Grid className={'locations-container'} sx={{ overflow: 'auto', mt: '30px', textAlign: 'center' }}>
              <Stack direction="row" className={'locations'} sx={{ display: 'inline-flex', p: '0 10%' }}>
                {player?.locations?.map((location: any, index: number) => (
                  <Grid
                    key={index}
                    sx={{
                      bgcolor: 'rgba(247, 251, 250, .04)',
                      width: '250px',
                      textAlign: 'center',
                      border: 'solid 1px rgba(247, 251, 250, .2)',
                      borderRadius: '4px',
                      p: '15px',
                      m: '0 20px',
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xl={6}
                        xs={6}
                        sm={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                        }}
                      >
                        <Fab
                          size="small"
                          className="button-red-reversed"
                          sx={{
                            background: 'transparent',
                            boxShadow: 'none',
                          }}
                          onClick={() => handleOpenDeleteLocation(location?.id)}
                        >
                          <Delete />
                        </Fab>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        xs={6}
                        sm={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <Fab
                          size="small"
                          className="button-green-reverse"
                          sx={{
                            background: 'transparent',
                            boxShadow: 'none',
                          }}
                          onClick={() => handleOpenEditAccountLocation(location?.id)}
                        >
                          <Edit />
                        </Fab>
                      </Grid>
                    </Grid>
                    <LocationIcon sx={{ color: 'rgba(31, 175, 146, 0.3) !important' }} />
                    <Typography
                      variant="h3"
                      sx={{
                        pb: '5px',
                        fontSize: '.9em',
                        fontWeight: '700',
                      }}
                    >
                      {location?.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        pb: '10px',
                        fontSize: '.9em',
                        fontWeight: '700',
                      }}
                    ></Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        display: location?.street_name === '' ? 'none' : 'inline-block',
                        width: '100%',
                      }}
                      className={'primary-address'}
                    >
                      {location?.street_name}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        display: location?.secondary_street_name === '' ? 'none' : 'inline-block',
                        width: '100%',
                      }}
                      className={'secondary-address'}
                    >
                      {location?.secondary_street_name}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'inline-block',
                        width: '100%',
                      }}
                      className={'postcode'}
                    >
                      {location?.postcode?.name} - {location?.city?.name} {location?.state?.name ? `- ${location?.state?.name}` : ''}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                      className={'country'}
                    >
                      {location?.country?.name}
                      &nbsp;
                      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                    </Typography>
                  </Grid>
                ))}
              </Stack>
            </Grid>

            <Grid id="max-width" sx={{ p: '50px 10% 0' }}>
              <Button className="button-cancel" onClick={handleCancel} disabled={progress > 0 || updatedPlayer.loading || newKYCCase.loading || sentSMS.loading || removedSocialMedia.loading}>
                <Close />
                &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
              </Button>

              <LoadingButton
                type="submit"
                loading={progress > 0 || updatedPlayer.loading || newKYCCase.loading || sentSMS.loading || removedSocialMedia.loading}
                loadingPosition="end"
                className={(inputFields?.email !== player?.email.address && isEmailTaken.data?.findPlayerEmail && isEmailTaken.called) || inputFields.username === '' || inputFields.firstname === '' || inputFields.lastname === '' || inputFields.email === '' || !inputFields.date_of_birth || (inputFields.mobile !== loggedPlayer.mobile && !verifiedMobile) ? 'button-disabled' : 'button-green'}
                endIcon={<Send />}
                disabled={(inputFields?.email !== player?.email.address && isEmailTaken.data?.findPlayerEmail && isEmailTaken.called) || inputFields.username === '' || inputFields.firstname === '' || inputFields.lastname === '' || inputFields.email === '' || !inputFields.date_of_birth || (inputFields.mobile !== loggedPlayer.mobile && !verifiedMobile)}
              >
                {`${intl.formatMessage({ id: 'Global_Button_Save_Updates' })}`}
              </LoadingButton>
            </Grid>
          </form>
        )}
        <Dialog open={isCancelled} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Cancel updates'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">You didn&apos;t save your last updates, do you still want to leave?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsCancelled(!isCancelled)}>Back</Button>
            <Button onClick={handleClose} autoFocus>
              Yes, cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default ProfileEditForm;
