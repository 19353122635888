import React from 'react';

import { Grid, Typography } from '@mui/material';
import { FivezerLogoIcon, LogoFaceWhiteIcon } from '../../img/icons/Icons';
import TopBar from '../../component-modals/layout/TopBar';
import SignUpForm from '../../component-modals/profile/SignUpForm';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const SignUpPage: React.FC = () => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const navigate = useNavigate();
  const intl = useIntl();

  React.useEffect(() => {
    if (loggedPlayer?.id) {
      navigate('/profile', { replace: true });
    }
  }, []);

  return (
    <Grid className={'form-page dark-mode component-profile'} sx={{ padding: '0 0 150px 0' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'Global_Meta_Title' })}`}</title>
        <meta name="description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta name="linkedin:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta name="linkedin:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta name="twitter:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta name="twitter:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta property="og:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta property="og:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <TopBar
        position={'static'}
        backgroundColor={'transparent'}
        color={'#000'}
        logo={
          <LogoFaceWhiteIcon
            sx={{
              display: 'none',
              height: 'auto',
              width: { xs: '10%', sm: '10%', md: '10%' },
              margin: 'auto',
            }}
          />
        }
      />
      <Typography
        variant="h1"
        sx={{
          fontSize: {
            xs: '2em',
            sm: '2em',
            md: '3em',
            lg: '4em',
          },
          fontWeight: '100 !important',
          display: 'inline-block',
          padding: '0 10% 0px !important',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <FivezerLogoIcon sx={{ height: { xs: '50px', sm: '60px', md: '80px', lg: '80px' }, width: 'auto' }} />
        <Typography className="tag-font" sx={{ fontSize: '.5em', opacity: '.2', top: '-20px', position: 'relative' }}>
          Are you a different Animal, and the same Beast?
        </Typography>
      </Typography>

      <Grid
        id="max-width"
        sx={{
          paddingTop: '20px',
          m: 'auto',
          width: {
            xs: '95%',
            sm: '80%',
            md: '100%',
          },
        }}
      >
        <SignUpForm destination="/onboarding" />
      </Grid>
    </Grid>
  );
};

export default SignUpPage;
