import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Stack, CardMedia, Box, Alert, Button, IconButton, MenuItem, Menu, Fade, Grid, Avatar, Typography } from '@mui/material';
import { MoreVert, Edit } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQuery, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerIcon, JubileeIcon } from '../../img/icons/Icons';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import MakeJubileeModal from '../../component-modals/profile/MakeJubileeModal';
import { selectSession } from '../../reducers/sessionSlice';
import _ from 'lodash';
import { useIntl } from 'react-intl';

const FootballCard: React.FC = () => {
  const FIND_FOOTBALLER_BY_ALIAS_QUERY = gql`
    query findFootballerByAlias($alias: String!) {
      findFootballerByAlias(alias: $alias) {
        id
        alias
        status
        avatar {
          path
        }
        cover {
          path
        }
        nickname
        position
        best_foot
        jersey_number
        bios {
          language
          content
        }
        originate_location {
          title
          country_code
          country {
            name
          }
          continent {
            name
          }
        }
        player {
          id
          owner {
            firstname
            lastname
          }
        }
        statistics {
          id
          nb_goals
          pace
          shooting
          passing
          dribbling
          defending
          physical
          goal_keeping
        }
        given_assists {
          id
          nb_assists
        }
        player_positions {
          id
          player_team_lineup {
            id
            home_challenges {
              id
              results {
                id
                winner_team {
                  id
                }
              }
            }
            away_challenges {
              id
              results {
                id
                winner_team {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const { alias } = useParams();
  const location: any = useLocation();
  const navigate = useNavigate();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [profile, setProfile] = React.useState(UNDEFINED_AS_ANY);
  const [openJubilee, setOpenJubilee] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const loadedProfile = useQuery(FIND_FOOTBALLER_BY_ALIAS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      alias: alias,
    },
    pollInterval: loggedPlayer?.id ? 3000 : undefined,
  });

  const SELECTED_LINEUPS = profile?.player_positions?.map((p: any) => p?.player_team_lineup)?.flat();
  const NB_GOALS = _.sum(profile?.statistics?.map((s: any) => s?.nb_goals));
  const NB_ASSISTS = _.sum(profile?.given_assists?.map((a: any) => a?.nb_assists));
  const NB_GAMES = SELECTED_LINEUPS?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
    ?.flat()
    ?.filter((g: any) => g?.results)?.length;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenJubilee = () => {
    setAnchorEl(null);
    setOpenJubilee(true);
  };

  React.useEffect(() => {
    if (!profile && loadedProfile.data?.findFootballerByAlias) {
      setProfile(loadedProfile.data?.findFootballerByAlias);
    }
  }, [loadedProfile, location, profile]);

  return (
    <Grid className={`profile-page`} sx={{ p: '0', height: '100%', bgcolor: '#000 !important' }}>
      <Helmet>
        <title>{`${profile?.alias ? profile?.alias : 'Footballer'}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={profile?.bios ? profile?.bios[0]?.content : ''} />
        <link rel="canonical" href={`${window.location.origin}/football_card/${profile?.alias ? profile?.alias : ''}`} />
      </Helmet>

      <CardMedia
        src={profile ? profile?.cover?.path : ''}
        sx={{
          backgroundImage: `linear-gradient(to top, rgba(15, 15, 15, .5) 0%, rgba(15, 15, 15, .8) 100%), url(${profile?.cover?.path})`,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              'textAlign': 'right',
              'p': '0px 30px 0',
              'display': 'inline-flex',
              'alignItems': 'center',
              'justifyContent': 'end',
              '& svg': { color: '#FFF !important' },
            }}
          >
            <Grid container sx={{ justifyContent: 'start' }}>
              <FivezerIcon sx={{ height: { xs: '50px', md: '60px' }, width: 'auto' }} onClick={() => navigate(-1)} />
            </Grid>
            <Box>
              <IconButton id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreVert />
              </IconButton>
            </Box>

            <Menu
              id="fade-menu"
              keepMounted
              MenuListProps={{
                'aria-labelledby': 'fade-button',
                'dense': true,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => navigate(-1)}>
                <Button
                  variant="text"
                  sx={{
                    '&:hover': {
                      background: 'transparent !important',
                    },
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  {`${intl.formatMessage({ id: 'FootballCard_Button_Back' })}`}
                </Button>
              </MenuItem>

              {loggedPlayer?.id !== profile?.player?.id && profile !== undefined ? (
                <ShareURLButton
                  button={
                    <MenuItem>
                      <Button
                        variant="text"
                        sx={{
                          '&:hover': {
                            background: 'transparent !important',
                          },
                        }}
                        startIcon={
                          <Avatar
                            src={profile?.avatar?.path}
                            sx={{
                              height: { xs: '20px' },
                              width: { xs: '20px' },
                            }}
                          />
                        }
                      >
                        {`${intl.formatMessage({ id: 'FootballCard_Button_Share_Profile_1' })}`}
                      </Button>
                    </MenuItem>
                  }
                  title={profile?.alias}
                  url={window.location.href}
                />
              ) : (
                <Grid>
                  <MenuItem component={Link} to="/footballer_profile/edit" state={{ profile: { id: profile?.id, type: profile?.__typename } }}>
                    <Button
                      variant="text"
                      sx={{
                        '&:hover': {
                          background: 'transparent !important',
                        },
                      }}
                      startIcon={<Edit />}
                    >
                      {`${intl.formatMessage({ id: 'FootballCard_Button_Edit' })}`}
                    </Button>
                  </MenuItem>
                  {profile?.status === 'ACTIVE' ? (
                    <>
                      <MenuItem>
                        <Button
                          variant="text"
                          sx={{
                            '&:hover': {
                              background: 'transparent !important',
                            },
                          }}
                          startIcon={<JubileeIcon />}
                          onClick={handleOpenJubilee}
                        >
                          {`${intl.formatMessage({ id: 'FootballCard_Button_Jubilee' })}`}
                        </Button>
                      </MenuItem>
                      <MakeJubileeModal profile_id={profile?.id} open={openJubilee} handleClose={() => setOpenJubilee(false)} />
                      <ShareURLButton
                        button={
                          <MenuItem>
                            <Button
                              variant="text"
                              sx={{
                                '&:hover': {
                                  background: 'transparent !important',
                                },
                              }}
                              startIcon={
                                <Avatar
                                  src={profile?.avatar?.path}
                                  sx={{
                                    height: { xs: '20px' },
                                    width: { xs: '20px' },
                                  }}
                                />
                              }
                            >
                              {`${intl.formatMessage({ id: 'FootballCard_Button_Share' })}`}
                            </Button>
                          </MenuItem>
                        }
                        title={profile?.alias}
                        url={window.location.href}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              )}
            </Menu>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            m: 'auto',
            width: '100%',
            height: '100%',
            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,.3) 30%, rgba(0,0,0,1) 100%)`,
            p: {
              xs: '0px 5% 50px',
              sm: '0px 5% 50px',
              md: '0px 20% 50px',
              lm: '0px 20% 50px',
              lx: '0px 20% 50px',
            },
          }}
        >
          {profile?.status === 'INACTIVE' ? (
            <Grid container sx={{ justifyContent: 'center' }}>
              <Alert severity="error">
                {`${intl.formatMessage({ id: 'FootballCard_Profile_Status' })}`} {profile?.status}
              </Alert>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} className={'avatar'} sx={{ height: { xs: '250px', sm: '350px', md: '450px', xl: '580px' }, width: { xs: '150px', sm: '250px', md: '350px', xl: '450px' }, display: 'block', mb: { xs: '-150px', sm: '-200px', md: '-270px', xl: '-350px' } }}>
            <CardMedia sx={{ backgroundImage: `url(../../img/card-frame.png)`, height: 'inherit', width: 'inherit', pt: { xs: '40px', sm: '60px', md: '60px', xl: '60px' }, m: '0 0 0 auto' }}>
              <Avatar
                src={profile?.avatar?.path || ''}
                sx={{
                  'boxSizing': 'border-box',
                  'height': { xs: 100, sm: 150, md: 200, xl: 250 },
                  'width': { xs: 100, sm: 150, md: 200, xl: 250 },
                  'm': 'auto',
                  '& img': { p: '5px', borderRadius: '100%' },
                }}
              />
              <Grid container sx={{ textAlign: 'center', p: { xs: '20px 15% 0', sm: '10px 15% 0', md: '30px 15% 0', xl: '60px 15% 0' } }}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Stack direction="column">
                    <Typography id="secondary-font" sx={{ color: 'rgb(15,15,15)', fontWeight: '700', fontSize: { xs: '.7em', sm: '.9em', md: '1.1em', xl: '1.5em' } }}>
                      0&nbsp;&nbsp;PAC
                    </Typography>
                    <Typography id="secondary-font" sx={{ color: 'rgb(15,15,15)', fontWeight: '700', fontSize: { xs: '.7em', sm: '.9em', md: '1.1em', xl: '1.5em' } }}>
                      0&nbsp;&nbsp;SHO
                    </Typography>
                    <Typography id="secondary-font" sx={{ color: 'rgb(15,15,15)', fontWeight: '700', fontSize: { xs: '.7em', sm: '.9em', md: '1.1em', xl: '1.5em' } }}>
                      0&nbsp;&nbsp;PAS
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Stack direction="column">
                    <Typography id="secondary-font" sx={{ color: 'rgb(15,15,15)', fontWeight: '700', fontSize: { xs: '.7em', sm: '.9em', md: '1.1em', xl: '1.5em' } }}>
                      0&nbsp;&nbsp;DRI
                    </Typography>
                    <Typography id="secondary-font" sx={{ color: 'rgb(15,15,15)', fontWeight: '700', fontSize: { xs: '.7em', sm: '.9em', md: '1.1em', xl: '1.5em' } }}>
                      0&nbsp;&nbsp;DEF
                    </Typography>
                    <Typography id="secondary-font" sx={{ color: 'rgb(15,15,15)', fontWeight: '700', fontSize: { xs: '.7em', sm: '.9em', md: '1.1em', xl: '1.5em' } }}>
                      0&nbsp;&nbsp;PHY
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </CardMedia>
          </Grid>
          <Grid container className={'goals'} sx={{ p: '0' }}>
            <Grid sx={{ textAlign: 'center', width: '130px', whiteSpace: 'nowrap' }}>
              <Typography id="secondary-font" sx={{ fontSize: { xs: '.8em', sm: '1.4em', md: '1.8em', xl: '2.1em' }, fontWeight: '300', textTransform: 'uppercase', lineHeight: '10px' }}>
                {`${intl.formatMessage({ id: 'Global_Stats_Goals' })}`}
              </Typography>
              <Typography id="secondary-font" sx={{ fontSize: { xs: '2em', sm: '2.5em', md: '2.8em', xl: '3.2em' }, fontWeight: '700', textTransform: 'uppercase' }}>
                {NB_GOALS ?? <>0</>}
              </Typography>
            </Grid>
          </Grid>
          {profile?.position === 'GK' ? (
            <Grid container className={'Clean Sheets'} sx={{ p: { xs: '10px 0px', sm: '30px 0px', md: '35px 0px', xl: '40px 0px' } }}>
              <Grid sx={{ textAlign: 'center', width: '130px', whiteSpace: 'nowrap' }}>
                <Typography id="secondary-font" sx={{ fontSize: { xs: '.8em', sm: '1.4em', md: '1.8em', xl: '2.1em' }, fontWeight: '300', textTransform: 'uppercase', lineHeight: '23px' }}>
                  {`${intl.formatMessage({ id: 'Global_Stats_Clean_Sheet_1' })}`}
                  <br />
                  {`${intl.formatMessage({ id: 'Global_Stats_Clean_Sheet_2' })}`}
                </Typography>
                <Typography id="secondary-font" sx={{ fontSize: { xs: '2em', sm: '2.5em', md: '2.8em', xl: '3.2em' }, fontWeight: '700', textTransform: 'uppercase' }}>
                  0
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={'assists'} sx={{ p: { xs: '10px 0px', sm: '30px 0px', md: '35px 0px', xl: '40px 0px' } }}>
              <Grid sx={{ textAlign: 'center', width: '130px', whiteSpace: 'nowrap' }}>
                <Typography id="secondary-font" sx={{ fontSize: { xs: '.8em', sm: '1.4em', md: '1.8em', xl: '2.1em' }, fontWeight: '300', textTransform: 'uppercase', lineHeight: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Stats_Assists' })}`}
                </Typography>
                <Typography id="secondary-font" sx={{ fontSize: { xs: '2em', sm: '2.5em', md: '2.8em', xl: '3.2em' }, fontWeight: '700', textTransform: 'uppercase' }}>
                  {NB_ASSISTS ?? <>0</>}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container className={'games'} sx={{ pb: { xs: '50px', sm: '50px', md: '50px', xl: '50px' } }}>
            <Grid sx={{ textAlign: 'center', width: '130px', whiteSpace: 'nowrap' }}>
              <Typography id="secondary-font" sx={{ fontSize: { xs: '.8em', sm: '1.4em', md: '1.8em', xl: '2.1em' }, fontWeight: '300', textTransform: 'uppercase', lineHeight: '10px' }}>
                {`${intl.formatMessage({ id: 'Global_Stats_Games' })}`}
              </Typography>
              <Typography id="secondary-font" sx={{ fontSize: { xs: '2em', sm: '2.5em', md: '2.8em', xl: '3.2em' }, fontWeight: '700', textTransform: 'uppercase' }}>
                {NB_GAMES ?? <>0</>}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={'profile-info'}
            sx={{
              p: '0',
            }}
          >
            <Grid sx={{ 'p': '0 10px', '& img': { width: 'auto', height: { xs: '20px', sm: '30px', md: '30px', xl: '30px' } } }}>
              <img loading="lazy" width="20" src={`https://flagcdn.com/w160/${profile?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w320/${profile?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
            </Grid>
            <Typography id="secondary-font" variant="h1" sx={{ m: '0', fontWeight: '700', fontSize: { xs: '2.8em', sm: '5em', md: '5em', xl: '5em' }, textTransform: 'uppercase', fontStyle: 'italic', lineHeight: { xs: '40px', sm: '80px', md: '80px', xl: '80px' }, position: 'relative', zIndex: '2' }}>
              {`${profile?.player?.owner?.firstname || ''}`}
              <br />
              {`${profile?.player?.owner?.lastname || ''}`}
            </Typography>
            <Typography variant="caption" sx={{ m: '0', fontSize: '.6em', fontWeight: '100', display: 'inline-flex', alignItems: 'baseline', position: 'relative', zIndex: '2' }}>
              {profile?.nickname ? (
                <>
                  &nbsp;aka&nbsp;<Typography sx={{ fontSize: '1.5em' }}>{profile?.nickname}</Typography>
                </>
              ) : (
                ''
              )}
            </Typography>
            <Typography id="secondary-font" variant="h2" sx={{ m: { xs: '-190px 30% 0', sm: '-270px 20% 0', md: '-270px 20% 0', xl: '-270px 20% 0' }, textAlign: 'right', fontWeight: '700', textTransform: 'uppercase', fontSize: { xs: '12em', sm: '17em', md: '17em', xl: '17em' }, WebkitTextStroke: '5px rgba(102, 130, 19, 1)', color: 'transparent', position: 'relative', zIndex: '1' }}>
              {`${profile?.jersey_number || ''}`}
            </Typography>
          </Grid>
        </Grid>
      </CardMedia>
    </Grid>
  );
};

export default FootballCard;
