import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, AvatarGroup, Badge, Avatar, TextField, MenuItem } from '@mui/material';
import { Add, Close, Delete, Send } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { CaptainIcon } from '../../img/icons/Icons';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer, selectPlayerActiveProfile } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import AddTeamLineupModal from '../team/AddTeamLineupModal';
import AddSportTeamModal from '../team/AddSportTeamModal';

export default function RegistrationTournamentModal(props: { tournament_id: string; team_candidates: any[]; selected_teams: any[]; handleClose: any; open: any }) {
  const FIND_TEAM_LINEUPS_BY_SPORT_QUERY = gql`
    query findTeamLineupsBySport($team_lineup: inputFindTeamLineupsBySport!) {
      findTeamLineupsBySport(team_lineup: $team_lineup) {
        id
        name
        players {
          id
          status
          team_number
          team_player {
            ... on FootballTeam {
              __typename
              id
              name
            }
          }
          player {
            ... on Footballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
          }
        }
        captains {
          id
          status
          team_number
          player {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
        }
        team {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            shortname
            type
            avatar {
              path
            }
            cover {
              path
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const REGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION = gql`
    mutation registerTeamLineupInTournament($tournament: inputRegistrationTeamLineupInTournament!) {
      registerTeamLineupInTournament(tournament: $tournament)
    }
  `;

  const UNREGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION = gql`
    mutation unregisterTeamLineupInTournament($tournament: inputRegistrationTeamLineupInTournament!) {
      unregisterTeamLineupInTournament(tournament: $tournament)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);

  const intl = useIntl();
  const [teamLineups, setTeamLineups] = React.useState(UNDEFINED_AS_ANY);
  const [openNewTeamLineup, setOpenNewTeamLineup] = React.useState(false);
  const [openNewTeam, setOpenNewTeam] = React.useState(false);

  const { control, watch } = useForm({
    defaultValues: {
      selected_sport_team: '',
      team_lineup_id: '',
    },
  });
  const inputFields = watch();

  const loadedTeamLineups = useQuery(FIND_TEAM_LINEUPS_BY_SPORT_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team_lineup: {
        player_id: loggedPlayer?.id,
        sport_type: 'FOOTBALL',
      },
    },
  });

  const [registerTeamLineupInTournament, registeredTeam] = useMutation(REGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unregisterTeamLineupInTournament, unregisteredTeam] = useMutation(UNREGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  // const ALL_TEAM_LINEUPS = loadedTeamLineups.data?.findTeamLineupsBySport?.filter((t: any) => !props.selected_teams?.find((st: any) => st?.id === t?.id));
  const SELECTED_TEAM_LINEUP = loadedTeamLineups.data?.findTeamLineupsBySport?.find((t: any) => t?.id === inputFields.team_lineup_id);

  const handleClose = () => {
    props.handleClose();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedTeamLineups.refetch({
        team_lineup: {
          player_id: loggedPlayer?.id,
          sport_type: 'FOOTBALL',
        },
      });

      if (result) {
        setTeamLineups(result.data?.findTeamLineupsBySport?.filter((t: any) => !props.selected_teams?.find((st: any) => st?.id === t?.id)));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenNewTeamLineup = () => {
    setOpenNewTeamLineup(true);
  };

  const handleCloseNewTeamLineup = async () => {
    try {
      await handleRefetch();
      setOpenNewTeamLineup(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenNewTeam = () => {
    setOpenNewTeam(true);
  };

  const handleCloseNewTeam = async () => {
    try {
      await handleRefetch();
      setOpenNewTeam(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRegisterTeamLineup = async () => {
    try {
      await registerTeamLineupInTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: props.tournament_id,
            team_lineup_id: SELECTED_TEAM_LINEUP?.id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUnregisterTeamLineup = async () => {
    try {
      await unregisterTeamLineupInTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: props.tournament_id,
            team_lineup_id: SELECTED_TEAM_LINEUP?.id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (!teamLineups && loadedTeamLineups.data?.findTeamLineupsBySport) {
      setTeamLineups(loadedTeamLineups.data?.findTeamLineupsBySport?.filter((t: any) => !props.selected_teams?.find((st: any) => st?.id === t?.id)));
    }
  }, [teamLineups, props, loadedTeamLineups]);

  return (
    <Dialog
      open={props.open}
      onClose={registeredTeam.loading || unregisteredTeam.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={registeredTeam.loading || unregisteredTeam.loading ? undefined : handleClose}
            disabled={registeredTeam.loading || unregisteredTeam.loading}
            className={registeredTeam.loading || unregisteredTeam.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}></Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: loggedPlayerActiveProfile?.sport_teams?.length === 0 ? 'FormNewTeam_Title' : 'RegistrationTournamentModal_Title' })}`}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid sx={{ textAlign: 'center', p: '0 0 20px' }}>
            {loggedPlayerActiveProfile?.sport_teams?.length === 0 ? (
              <>
                <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleOpenNewTeam} sx={{ boxShadow: 'none', ml: '10px', whiteSpace: 'nowrap', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                  <Add />
                  &nbsp;{`${intl.formatMessage({ id: 'Global_Button_New_Team' })}`}
                </Fab>
                <AddSportTeamModal open={openNewTeam} handleClose={handleCloseNewTeam} />
              </>
            ) : (
              <>
                <Fab variant="extended" size="small" className="button-green-reverse" onClick={handleOpenNewTeamLineup} sx={{ boxShadow: 'none', ml: '10px', whiteSpace: 'nowrap', fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>
                  <Add />
                  &nbsp;{`${intl.formatMessage({ id: 'Global_Button_New_Lineup' })}`}
                </Fab>
                <Typography variant="caption" sx={{ color: 'rgba(247, 251, 250, 0.3)', width: '100%', display: 'inline-block' }}>
                  {`${intl.formatMessage({ id: 'RegistrationTournamentModal_New_Team_Caption' })}`}
                </Typography>
                <AddTeamLineupModal open={openNewTeamLineup} handleClose={handleCloseNewTeamLineup} />
              </>
            )}
          </Grid>

          {teamLineups?.length === 0 || loggedPlayerActiveProfile?.sport_teams?.length === 0 ? (
            ''
          ) : (
            <Controller
              control={control}
              name="team_lineup_id"
              render={({ field }: any) => (
                <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'RegistrationTournamentModal_Teams_Label' })}`} variant="outlined" required fullWidth className={'field-bottom-space'}>
                  {teamLineups?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id} disabled={option?.players?.length <= 0}>
                      <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Avatar src={option?.team?.avatar?.path} alt={option?.team?.name} />
                        &nbsp;&nbsp;
                        <strong>{option?.name}</strong>
                        {props.team_candidates?.find((tc: any) => tc?.id === option?.id) ? (
                          <Typography variant="caption" className="ACCEPTED">
                            &nbsp;{`${intl.formatMessage({ id: 'RegistrationTournamentModal_Teams_Label_2' })}`}
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid container sx={{ justifyContent: 'center' }}>
                        <AvatarGroup spacing={-20}>
                          {option?.players?.map((p: any, index: number) => (
                            <Badge key={index} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={option?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : undefined} sx={{ display: 'block' }}>
                              <Avatar alt={p?.player?.alias} src={p?.player?.avatar?.path} sx={{ m: 'auto !important' }} />
                              <Typography variant="caption">{_.truncate(p?.player?.alias, { length: 20 })}</Typography>
                            </Badge>
                          ))}
                        </AvatarGroup>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
        </DialogContentText>
        <DialogActions sx={{ p: '20px 0 0' }}>
          {SELECTED_TEAM_LINEUP?.id && props.tournament_id && !props.team_candidates?.find((t: any) => t?.id === SELECTED_TEAM_LINEUP?.id) ? (
            <>
              <LoadingButton loading={registeredTeam.loading} loadingPosition="end" endIcon={<Send />} className={'button-green'} fullWidth onClick={handleRegisterTeamLineup}>
                {registeredTeam.loading ? `${intl.formatMessage({ id: 'RegistrationTournamentModal_Button_1' })}` : `${intl.formatMessage({ id: 'RegistrationTournamentModal_Button_2' })}`}
              </LoadingButton>
            </>
          ) : (
            ''
          )}

          {SELECTED_TEAM_LINEUP?.id && props.tournament_id && props.team_candidates?.find((t: any) => t?.id === SELECTED_TEAM_LINEUP?.id) && !props.selected_teams?.find((t: any) => t?.id === SELECTED_TEAM_LINEUP?.id) ? (
            <>
              <LoadingButton loading={registeredTeam.loading} loadingPosition="end" endIcon={<Delete />} className={'button-red-reversed'} fullWidth onClick={handleUnregisterTeamLineup}>
                {unregisteredTeam.loading ? `${intl.formatMessage({ id: 'RegistrationTournamentModal_Button_3' })}` : `${intl.formatMessage({ id: 'RegistrationTournamentModal_Button_4' })}`}
              </LoadingButton>
            </>
          ) : (
            ''
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

RegistrationTournamentModal.propTypes = {
  tournament_id: PropTypes.string.isRequired,
  team_candidates: PropTypes.array.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
};

RegistrationTournamentModal.defaultProps = {};
