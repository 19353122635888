import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import SwipeableViews from 'react-swipeable-views';
import _ from 'lodash';
import { autoPlay } from 'react-swipeable-views-utils';
import { MenuItem, Menu, Grid, Typography, Fab, CardMedia, IconButton, CircularProgress, Button, Box, Fade, Avatar, Card } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { FivezerLogoIcon, SearchIcon, ShareIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import MenuButton from '../../component-modals/layout/MenuButton';
import BackButton from '../../component-modals/common/BackButton';
import Moment from 'react-moment';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { ONE_DAY_MILLISEC, SPORT_TYPES } from '../../utilities/utilities';

const RequestMatchMaking: React.FC = () => {
  const FIND_REQUEST_MATCHMAKING_BY_SHORT_ID_QUERY = gql`
    query findRequestMatchMakingByShortID($short_id: String!) {
      findRequestMatchMakingByShortID(short_id: $short_id) {
        id
        short_id
        status
        start_date
        end_date
        sport
        field {
          id
          name
          pictures {
            path
          }
          rental_asset {
            id
            price {
              value
              currency {
                currency_code
              }
            }
          }
          complex {
            id
            name
            logo {
              path
            }
          }
        }
        candidates {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
            player {
              id
              username
              avatar {
                path
              }
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
            player {
              id
              username
              avatar {
                path
              }
            }
          }
        }
      }
    }
  `;

  const JOIN_REQUEST_MATCHMAKING_MUTATION = gql`
    mutation joinRequestMatchMaking($request: inputManageRequestMatchMaking!) {
      joinRequestMatchMaking(request: $request)
    }
  `;

  const LEAVE_REQUEST_MATCHMAKING_MUTATION = gql`
    mutation leaveRequestMatchMaking($request: inputManageRequestMatchMaking!) {
      leaveRequestMatchMaking(request: $request)
    }
  `;

  const { matchmakingId }: any = useParams();
  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [requestMatch, setRequestMatch] = React.useState(UNDEFINED_AS_ANY);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [activeStep, setActiveStep] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [joinRequestMatchMaking, joinedPlayer] = useMutation(JOIN_REQUEST_MATCHMAKING_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [leaveRequestMatchMaking, removedPlayer] = useMutation(LEAVE_REQUEST_MATCHMAKING_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedRequest = useQuery(FIND_REQUEST_MATCHMAKING_BY_SHORT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      short_id: matchmakingId,
    },
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedRequest.refetch({
        short_id: matchmakingId,
      });

      if (result) {
        setRequestMatch(result.data?.findRequestMatchMakingByShortID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleJoinRequestMatchMaking = async () => {
    try {
      await joinRequestMatchMaking({
        variables: {
          request: {
            id: requestMatch?.id,
            profile_id: loggedPlayer?.profiles?.find((p: any) => _.toLower(p?.__typename)?.includes(_.toLower(requestMatch?.sport)))?.id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleLeaveRequestMatchMaking = async () => {
    try {
      await leaveRequestMatchMaking({
        variables: {
          request: {
            id: requestMatch?.id,
            profile_id: loggedPlayer?.profiles?.find((p: any) => _.toLower(p?.__typename)?.includes(_.toLower(requestMatch?.sport)))?.id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (requestMatch === undefined && loadedRequest.data?.findRequestMatchMakingByShortID) {
      setRequestMatch(loadedRequest.data?.findRequestMatchMakingByShortID);
    }
  }, [requestMatch, loadedRequest]);

  return (
    <Grid className={'component-profile flex-page dark-mode'} sx={{ minHeight: '100%', pb: '150px' }}>
      <Helmet>
        <title>{requestMatch ? `${requestMatch?.field?.name} (${intl.formatMessage({ id: 'RequestMatchMaking_Meta_Title' })})` : `${intl.formatMessage({ id: 'RequestMatchMaking_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      {(requestMatch?.status === 'PENDING' && requestMatch === undefined) || loadedRequest.loading ? (
        <Grid sx={{ p: '25% 10% 0' }}>
          {loadedRequest.data?.findFlexByID === undefined && !loadedRequest.loading ? (
            <Grid>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'RequestMatchMaking_Not_Found_1' })}`}&nbsp;
                <Grid component="span" sx={{ fontWeight: '500' }}>
                  {`${intl.formatMessage({ id: 'RequestMatchMaking_Not_Found_2' })}`}
                </Grid>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1.5em',
                  textAlign: 'left',
                  p: '5%',
                }}
              >
                {`${intl.formatMessage({ id: 'RequestMatchMaking_Not_Found_3' })}`}&nbsp;
                <Fab
                  variant="extended"
                  sx={{
                    'p': '0',
                    'fontWeight': '700',
                    'fontSize': '.8em',
                    'boxShadow': 'none',
                    'bgcolor': 'transparent',
                    'color': 'inherit',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'rgba(31, 175, 146, 1)',
                    },
                  }}
                >
                  <SearchIcon sx={{ color: 'rgba(31, 175, 146, 1)' }} />
                  &nbsp;{`${intl.formatMessage({ id: 'RequestMatchMaking_Not_Found_4' })}`}
                </Fab>
                &nbsp;{`${intl.formatMessage({ id: 'RequestMatchMaking_Not_Found_5' })}`}
              </Typography>
            </Grid>
          ) : (
            ''
          )}

          {loadedRequest.loading ? <LoadingComponent amount={1} text={<Typography sx={{ textAlign: 'center' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            m: 'auto',
            width: '100%',
            backgroundImage: {
              xs: `linear-gradient(to bottom, rgba(15,15,15,0) 2%, rgba(15,15,15,1) 35%)`,
              sm: `linear-gradient(to bottom, rgba(15,15,15,0) 2%, rgba(15,15,15,1) 40%)`,
              md: `linear-gradient(to bottom, rgba(15,15,15,0) 5%, rgba(15,15,15,1) 50%)`,
              lm: `linear-gradient(to bottom, rgba(15,15,15,0) 20%, rgba(15,15,15,1) 60%)`,
            },
            p: {
              xs: '0px 0% 50px',
              sm: '0px 0% 50px',
              md: '0px 0% 50px',
              lm: '0px 0% 50px',
              lx: '0px 0% 50px',
            },
          }}
        >
          <AutoPlaySwipeableViews axis={'x-reverse'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents style={{ width: '100%' }}>
            <CardMedia
              sx={{
                height: '30vh',
                backgroundSize: { xs: '150%', sm: '100%' },
                backgroundPosition: 'top',
                backgroundImage: {
                  xs: `linear-gradient(to bottom, rgba(15,15,15,0) 2%, rgba(15,15,15,1) 95%), url(${requestMatch?.field?.pictures[activeStep]?.path})`,
                  sm: `linear-gradient(to bottom, rgba(15,15,15,0) 2%, rgba(15,15,15,1) 95%), url(${requestMatch?.field?.pictures[activeStep]?.path})`,
                  md: `linear-gradient(to bottom, rgba(15,15,15,0) 5%, rgba(15,15,15,1) 95%), url(${requestMatch?.field?.pictures[activeStep]?.path})`,
                  lm: `linear-gradient(to bottom, rgba(15,15,15,0) 20%, rgba(15,15,15,1) 95%), url(${requestMatch?.field?.pictures[activeStep]?.path})`,
                },
              }}
            >
              <Grid container>
                <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'center' }}></Grid>
                <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
                  <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                    <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'right', p: '0 40px' }}>
                  <MenuButton />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', p: '0' }}>
                  <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{
                    textAlign: 'right',
                    p: '0',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <>
                    <Box>
                      <IconButton id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                        <MoreVert />
                      </IconButton>
                    </Box>

                    <Menu
                      id="fade-menu"
                      keepMounted
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                        'dense': true,
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <ShareURLButton
                        button={
                          <MenuItem>
                            <Button
                              variant="text"
                              sx={{
                                '&:hover': {
                                  background: 'transparent !important',
                                },
                              }}
                              startIcon={<ShareIcon />}
                            >
                              {`${intl.formatMessage({ id: 'Global_Button_Share' })}`}
                            </Button>
                          </MenuItem>
                        }
                        url={window.location.href}
                        title={`Request for ${requestMatch?.field?.name}`}
                        text={`You can find more about ${requestMatch?.field?.name}`}
                      />
                    </Menu>
                  </>
                </Grid>
              </Grid>
            </CardMedia>
          </AutoPlaySwipeableViews>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Typography sx={{ textAlign: 'center', fontSize: '2.1em', fontWeight: '800' }}>{requestMatch?.field?.name}</Typography>
              {requestMatch?.field?.complex ? (
                <Typography sx={{ textAlign: 'center', fontSize: '.9em', fontWeight: '500', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <Avatar src={requestMatch?.field?.complex?.logo?.path} alt={requestMatch?.field?.complex?.name} />
                  &nbsp;{requestMatch?.field?.complex?.name}
                </Typography>
              ) : (
                ''
              )}
              <Grid container>
                <Typography sx={{ fontSize: '1.8em', fontWeight: '800', textAlign: 'center', display: 'inline-block', width: '100%', mt: '30px' }}>
                  {requestMatch?.field?.rental_asset?.price?.value / (requestMatch?.candidates?.length > 0 ? requestMatch?.candidates?.length : 1)} {requestMatch?.field?.rental_asset?.price?.currency?.currency_code}/{`${intl.formatMessage({ id: 'RequestMatchMaking_Player' })}`}
                </Typography>
                <Typography variant="caption" sx={{ textAlign: 'center', textTransform: 'uppercase', width: '100%' }}>
                  {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={requestMatch?.status === 'PENDING' ? '' : requestMatch?.status}>{requestMatch?.status?.replaceAll('_', ' ')}</strong>
                </Typography>

                <Grid container sx={{ m: '0', justifyContent: 'center' }}>
                  <Typography sx={{ textAlign: 'center', fontWeight: '700', width: '100%' }}>{requestMatch?.challenge?.title}</Typography>
                  <Typography variant="caption" sx={{ textAlign: 'center', textTransform: 'uppercase', width: '100%' }}>
                    {`${intl.formatMessage({ id: 'RequestMatchMaking_Type_Label' })}`}: {`${intl.formatMessage({ id: SPORT_TYPES?.find((s: any) => s?.value === requestMatch?.sport)?.label ?? 'Global_NA' })}`}
                  </Typography>
                  <Typography>
                    <Moment format="DD MMMM YYYY">{requestMatch?.start_date}</Moment>
                    {requestMatch?.start_date + ONE_DAY_MILLISEC > requestMatch?.end_date ? (
                      <>
                        &nbsp;[<Moment format="HH:mm">{requestMatch?.start_date}</Moment>
                        &nbsp;-&gt;&nbsp;
                        <Moment format="HH:mm">{requestMatch?.end_date}</Moment>]
                      </>
                    ) : (
                      <>
                        &nbsp;-&nbsp;
                        <Moment format="D MMM YYYY">{requestMatch?.end_date}</Moment>
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {requestMatch?.status === 'OPEN' ? (
                <Grid container sx={{ mt: '30px', justifyContent: 'center' }}>
                  {requestMatch?.candidates?.find((c: any) => c?.id === loggedPlayer?.profiles?.find((p: any) => _.toLower(p?.__typename)?.includes(_.toLower(requestMatch?.sport)))?.id) ? (
                    <LoadingButton onClick={handleLeaveRequestMatchMaking} disabled={joinedPlayer.loading} className={joinedPlayer.loading ? 'button-disabled' : 'button-red-reversed'}>
                      {`${intl.formatMessage({ id: 'RequestMatchMaking_Button_Leave_Game' })}`}
                    </LoadingButton>
                  ) : (
                    <LoadingButton onClick={handleJoinRequestMatchMaking} disabled={removedPlayer.loading} className={removedPlayer.loading ? 'button-disabled' : 'button-green-reverse'}>
                      {`${intl.formatMessage({ id: 'RequestMatchMaking_Button_Join_Game' })}`}
                    </LoadingButton>
                  )}
                </Grid>
              ) : (
                ''
              )}

              <Grid container sx={{ width: '100%', p: '30px 5%' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '1.3em', textTransform: 'uppercase', textAlign: 'center', width: '100%', mb: '10px' }}>
                  {requestMatch?.candidates?.length} {requestMatch?.candidates?.length > 1 ? `${intl.formatMessage({ id: 'RequestMatchMaking_Players' })}` : `${intl.formatMessage({ id: 'RequestMatchMaking_Player' })}`}&nbsp;{requestMatch?.candidates?.length > 1 ? `${intl.formatMessage({ id: 'RequestMatchMaking_Joined_Players' })}` : `${intl.formatMessage({ id: 'RequestMatchMaking_Joined_Player' })}`}
                </Typography>

                {requestMatch?.candidates?.map((p: any) => (
                  <Grid key={p?.id} item xs={12} sm={6} md={6} lg={4} xl={4} sx={{ p: '10px' }}>
                    <Card
                      sx={{
                        bgcolor: 'rgba(255, 255, 255, .04)',
                        p: '10px',
                        width: '100%',
                        borderRadius: '20px',
                        m: '0px auto',
                        textAlign: 'center',
                        boxShadow: 'none',
                      }}
                    >
                      <Grid component={Link} to={`/u/${p?.player?.username}`} sx={{ display: 'inline-block', textDecoration: 'none' }}>
                        <Avatar alt={p?.alias || p?.player?.username} src={p?.avatar?.path || p?.player?.avatar?.path} sx={{ m: 'auto', width: '80px', height: '80px' }} />
                        <Typography sx={{ fontWeight: '800', fontSize: '1.3em' }}>{p?.alias || p?.player?.username}</Typography>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default RequestMatchMaking;
