import React from 'react';
import { Fab, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Clear } from '@mui/icons-material';

export default function AlertPopup(props: { open: boolean; message: any; type?: any; cta?: any; link: string }) {
  const [openAlert, setOpenAlert] = React.useState<boolean | undefined>(undefined);

  const handleClose = () => {
    setOpenAlert(false);
  };

  React.useEffect(() => {
    if (props.open && openAlert === undefined) {
      setOpenAlert(true);
    }
  }, [props, openAlert, setOpenAlert]);

  return (
    <>
      <Snackbar
        open={openAlert}
        message={props.message}
        autoHideDuration={6000}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& *': { boxShadow: 'none' },
          '& .MuiPaper-root ': {
            bgcolor: props.type === 'info' ? 'rgba(49, 161, 224, .8)' : undefined || props.type === 'success' ? 'rgba(81, 154, 82, .8)' : undefined || props.type === 'warning' ? 'rgba(246, 138, 49, .8)' : undefined || props.type === 'error' ? 'rgba(238, 44, 32, .8)' : undefined,
          },
        }}
        action={
          <Fab component={Link} to={props.link} variant={props.cta ? 'extended' : 'circular'} size="small" sx={{ 'boxShadow': 'none', 'bgcolor': 'rgba(247, 251, 250, 0.3)', 'color': 'rgba(247, 251, 250, 1)', 'fontWeight': '700', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.5)' } }}>
            {props.cta || <Clear />}
          </Fab>
        }
        onClose={handleClose}
      />
    </>
  );
}
