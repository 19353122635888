import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { customAlphabet } from 'nanoid';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { Chip, MenuItem, Autocomplete, TextField, Grid, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Avatar, AvatarGroup } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';
import { ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import { ChatsIcon } from '../../img/icons/Icons';
import { selectSession } from '../../reducers/sessionSlice';
import { CONVERSATION_REG } from '../../utilities/utilities';
import useEventLog from '../../hooks/useEventLog';
import { selectPlayer } from '../../reducers/playerSlice';
import { useIntl } from 'react-intl';

interface INewConversationMessengerModal {
  handleClose: any;
  handleOpenConversation: any;
  new_conversation?: {
    title: string;
    author_id: string;
    mandatory_participants: { id: string; name: string; label: string; avatar: string }[];
    participants: { id: string; avatar: string }[];
  };
}

const NewConversationMessengerModal: React.FC<INewConversationMessengerModal> = (props: INewConversationMessengerModal) => {
  const FIND_EXISTING_CONVERSATION_BY_PARTICIPANTS_QUERY = gql`
    query findExistingConversationByParticipants($conversation: inputFindExistingConversationByParticipants!) {
      findExistingConversationByParticipants(conversation: $conversation) {
        id
        short_id
      }
    }
  `;

  const CREATE_CONVERSATION_MUTATION = gql`
    mutation createConversation($conversation: inputCreateConversation!) {
      createConversation(conversation: $conversation) {
        id
        short_id
        title
      }
    }
  `;

  const { handleSendLog } = useEventLog();
  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [openSearchParticipant, setOpenSearchParticipant] = React.useState(false);
  const conversation_nanoid = customAlphabet(CONVERSATION_REG, 15);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: props.new_conversation?.title || '',
      author_id: props.new_conversation?.author_id || '',
      description: '',

      participant_name: '',
      selected_participants: ARRAY_AS_ANY,
      all_participants: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();

  const [createConversation, newConversation] = useMutation(CREATE_CONVERSATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedExistingConversation = useQuery(FIND_EXISTING_CONVERSATION_BY_PARTICIPANTS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || inputFields.author_id === '' || !inputFields.author_id,
    variables: {
      conversation: {
        participants: _.concat(
          inputFields.author_id,
          inputFields.selected_participants,
          props.new_conversation?.mandatory_participants?.map((p: any) => p?.id)
        )?.filter((i: any) => i),
      },
    },
  });

  const ALL_AUTHORS = _.concat(loggedPlayer)?.filter((a: any) => !props.new_conversation?.mandatory_participants?.find((p: any) => p?.id == a?.id));
  const ALL_PARTICIPANTS = loggedPlayer?.id === inputFields.author_id ? ALL_AUTHORS?.find((a: any) => a?.id === inputFields.author_id)?.followings?.filter((f: any) => ALL_AUTHORS?.find((a: any) => a?.id === inputFields.author_id)?.followers?.find((fl: any) => fl?.id === f?.id)) : ALL_AUTHORS?.find((a: any) => a?.id === inputFields.author_id)?.connections;

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  const handleViewConversation = (short_id: string) => {
    props.handleOpenConversation(`${short_id}`);
  };

  const handleNewConversation = async () => {
    try {
      if (loadedExistingConversation.data?.findExistingConversationByParticipants) {
        props.handleOpenConversation(`${loadedExistingConversation.data?.findExistingConversationByParticipants?.short_id}`);
      } else {
        handleCreateConversation();
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleParticipant = (value: any) => {
    const newList = _.concat(value?.id, inputFields.selected_participants);
    setValue('selected_participants', newList);
  };

  const handleDeleteParticipant = (participant_id: string) => {
    const newList = inputFields.selected_participants?.filter((p_id: string) => p_id !== participant_id);
    setValue('selected_participants', newList);
  };

  const handleResetParticipant = () => {
    setValue('selected_participants', ARRAY_AS_ANY);
  };

  const handleCreateConversation = async () => {
    try {
      const result = await createConversation({
        variables: {
          conversation: {
            title: inputFields.title,
            short_id: conversation_nanoid(),
            description: inputFields.description,
            app_client_id: session?.app_client?.id,
            author_id: inputFields.author_id,
            participants_ids: _.concat(
              inputFields.author_id,
              inputFields.selected_participants,
              props.new_conversation?.mandatory_participants?.map((p: any) => p?.id)
            )?.filter((i: any) => i),
          },
        },
      });

      props.handleOpenConversation(`${result.data?.createConversation?.short_id}`);
    } catch (e: any) {
      console.error(e);
      handleSendLog(e.toString(), session?.app_client?.id);
    }
  };

  return (
    <>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          textAlign: 'center',
          p: '0 10%',
          textTransform: 'uppercase',
        }}
      >
        <ChatsIcon />
        <Typography sx={{ fontWeight: '700' }}>{`${intl.formatMessage({ id: 'NewConversationMessenger_Title' })}`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: '0 0 20px' }}>
        <DialogContentText component={'span'} id="alert-dialog-description">
          <form>
            <Grid sx={{ p: '20px 10% 0' }}>
              {loadedExistingConversation.data?.findExistingConversationByParticipants ? (
                ''
              ) : (
                <>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                      pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
                    }}
                    render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'NewConversationMessenger_Title_Label' })}`} fullWidth value={_.trimStart(field.value)} className={'field-bottom-space'} />}
                  />

                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: 400,
                      minLength: 0,
                      pattern: /^[a-zA-Z0-9_'\s]*$/,
                    }}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        multiline
                        minRows={3}
                        maxRows={5}
                        label={`${intl.formatMessage({ id: 'NewConversationMessenger_Description_Label' })}`}
                        placeholder={`${intl.formatMessage({ id: 'NewConversationMessenger_Description_Placeholder' })}`}
                        variant="outlined"
                        fullWidth
                        className={'field-bottom-space'}
                        error={errors?.description?.type !== undefined}
                        helperText={errors?.description?.type !== undefined ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'NewConversationMessenger_Description_Helper_1' })}` : `${intl.formatMessage({ id: 'NewConversationMessenger_Description_Helper_2' })} ${field.value}`) : ''}
                        value={_.replace(field.value, /[^a-zA-Z0-9_'\s]+/g, '')}
                      />
                    )}
                  />
                </>
              )}

              <Controller
                control={control}
                name="author_id"
                render={({ field }: any) => (
                  <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'NewConversationMessenger_Author_Label' })}`} variant="outlined" error={errors?.author_id !== undefined} required fullWidth className={'field-bottom-space'}>
                    {ALL_AUTHORS.map((option: any) => (
                      <MenuItem key={option.id} value={option.id} onClick={handleResetParticipant}>
                        <Grid container sx={{ alignItems: 'center' }}>
                          <Avatar src={option.avatar?.path || option?.logo?.path} alt={option.label || option.username} />
                          &nbsp;&nbsp;
                          <strong>{option.label || option.username}</strong>
                        </Grid>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {props.new_conversation?.mandatory_participants || inputFields.author_id === '' || inputFields.author_id === undefined ? (
                ''
              ) : (
                <Controller
                  name="all_participants"
                  control={control}
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      open={openSearchParticipant}
                      onOpen={() => setOpenSearchParticipant(true)}
                      onClose={() => setOpenSearchParticipant(false)}
                      multiple
                      autoHighlight
                      filterSelectedOptions
                      value={field.value}
                      loading={false}
                      inputValue={inputFields.participant_name}
                      onInputChange={(event: any, newInputValue: any) => {
                        setValue('participant_name', newInputValue);
                      }}
                      options={ALL_PARTICIPANTS ? ALL_PARTICIPANTS?.filter((p: any) => inputFields.selected_participants?.find((p_id: string) => p_id === p?.id) === undefined) : []}
                      getOptionLabel={(option: any) => option?.username || option?.name}
                      defaultValue={ARRAY_AS_ANY}
                      renderOption={(props: any, option: any) => (
                        <Grid
                          container
                          key={option?.id}
                          onClick={() => handleParticipant(option)}
                          sx={{
                            alignItems: 'center',
                            p: '10px 15px',
                          }}
                        >
                          <Avatar src={option?.avatar?.path || option?.logo?.path} />
                          &nbsp;&nbsp;
                          <strong>{option?.username || option?.name}</strong>
                        </Grid>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={`${intl.formatMessage({ id: 'NewConversationMessenger_All_Participants_Label' })}`}
                          placeholder={`${intl.formatMessage({ id: 'NewConversationMessenger_All_Participants_Placeholder' })}`}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <React.Fragment>
                                <Grid>
                                  {inputFields.selected_participants?.length === 0 || inputFields.selected_participants?.length === undefined ? (
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        m: '0px',
                                        p: '5px 20px',
                                        textAlign: 'center',
                                        bgcolor: 'rgba(0, 0, 0, .1)',
                                        borderRadius: '5px',
                                      }}
                                    >
                                      {`${intl.formatMessage({ id: 'NewConversationMessenger_No_Participants' })}`}
                                    </Typography>
                                  ) : (
                                    inputFields.selected_participants?.map((participant_id: any) => (
                                      <Chip
                                        key={participant_id}
                                        variant="outlined"
                                        label={<strong>{ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.name || ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.username}</strong>}
                                        avatar={
                                          <Avatar
                                            sx={{
                                              height: {
                                                xs: '50px !important',
                                                md: '60px !important',
                                                lg: '70px !important',
                                              },
                                              width: {
                                                xs: '50px !important',
                                                md: '60px !important',
                                                lg: '70px !important',
                                              },
                                            }}
                                            alt={ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.name || ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.username}
                                            src={ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.logo?.path || ALL_PARTICIPANTS?.find((pa: any) => pa?.id === participant_id)?.avatar?.path}
                                          />
                                        }
                                        onDelete={() => handleDeleteParticipant(participant_id)}
                                        sx={{
                                          m: '5px',
                                          height: 'auto',
                                        }}
                                      />
                                    ))
                                  )}
                                </Grid>
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              )}

              {props.new_conversation?.mandatory_participants ? (
                <Grid container sx={{ justifyContent: 'center' }}>
                  <AvatarGroup>
                    {props.new_conversation?.mandatory_participants?.map((p: any) => (
                      <Grid key={p?.id} sx={{ textAlign: 'center', color: 'rgba(247, 251, 250, 1)' }}>
                        <Avatar src={p?.avatar} alt={p?.name} sx={{ height: '70px', width: '70px', m: 'auto !important' }} />
                        <strong>{p?.name}</strong>
                      </Grid>
                    ))}
                  </AvatarGroup>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', p: '20px 10px' }}>
        <Button onClick={handleClose} disabled={newConversation.loading} className={newConversation.loading ? 'button-disabled' : 'button-cancel'}>
          {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
        </Button>

        {loadedExistingConversation.data?.findExistingConversationByParticipants ? (
          <Button className="button-green" onClick={() => handleViewConversation(loadedExistingConversation.data?.findExistingConversationByParticipants?.short_id)}>
            {`${intl.formatMessage({ id: 'NewConversationMessenger_Button_View_Conversation' })}`}
          </Button>
        ) : (
          <LoadingButton onClick={handleNewConversation} loading={newConversation.loading} loadingPosition="end" endIcon={<Send />} className={inputFields.author_id === '' || (inputFields.selected_participants?.length === 0 && !props.new_conversation?.mandatory_participants) ? 'button-disabled' : 'button-green'} disabled={inputFields.author_id === '' || (inputFields.selected_participants?.length === 0 && !props.new_conversation?.mandatory_participants)}>
            {`${intl.formatMessage({ id: 'NewConversationMessenger_Button_Start_Conversation' })}`}
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

NewConversationMessengerModal.propTypes = {
  handleClose: PropTypes.any.isRequired,
};

NewConversationMessengerModal.defaultProps = {};

export default React.memo(NewConversationMessengerModal);
