import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Fab, Grid, MenuItem, TextField, Avatar, useMediaQuery, Chip, CircularProgress } from '@mui/material';
import { Send, Close, Add, Clear } from '@mui/icons-material';
import { ARRAY_AS_ANY, NULL_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerWhiteIcon, ScoreBoardIcon, VersusIcon } from '../../img/icons/Icons';
import Modal from '../layout/Modal';
import { selectSession } from '../../reducers/sessionSlice';

export default function ManageScoreByAdminsModal(props: { challenge_short_id: string; handleClose: any; current_stage_game?: any; next_stage_game?: any; group_game?: any; open: any }) {
  const FIND_CHALLENGE_BY_ID_QUERY = gql`
    query findChallengeByID($challenge: inputFindChallengeByID!) {
      findChallengeByID(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        home_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        away_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        booking {
          id
          consumers {
            id
            payment_status
            transaction {
              id
              status
              tx_reference
              note_reference
              execution_date
              confirmed_at
            }
            player {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
        results {
          id
          status
          home_team {
            id
            name
          }
          away_team {
            id
            name
          }
          captains_confirmations {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
          }
          men_of_the_match {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
          statistics {
            id
            status
            nb_goals
            given_assists {
              id
              nb_assists
              giver {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
              receiver {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
            pace
            shooting
            passing
            dribbling
            defending
            physical
            goal_keeping
            team_player {
              id
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
        }
        tournament_group_games {
          id
          tournament {
            id
            admins {
              id
            }
          }
        }
        tournament_playoff_games {
          id
          tournament {
            id
            admins {
              id
            }
          }
        }
      }
    }
  `;

  const INIT_CHALLENGE_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation initChallengeScoreByChallengeID($challenge: inputInitChallengeScoreByChallengeID!) {
      initChallengeScoreByChallengeID(challenge: $challenge)
    }
  `;

  const SAVE_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation saveScoreByChallengeID($score: inputSaveScoreByChallengeID!) {
      saveScoreByChallengeID(score: $score)
    }
  `;

  const CONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation confirmScoreByChallengeID($score: inputConfirmationScoreByChallengeID!) {
      confirmScoreByChallengeID(score: $score)
    }
  `;

  const UNCONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION = gql`
    mutation unconfirmScoreByChallengeID($score: inputConfirmationScoreByChallengeID!) {
      unconfirmScoreByChallengeID(score: $score)
    }
  `;

  const MANAGE_TOURNAMENT_GROUP_RANKS_MUTATION = gql`
    mutation manageTournamentGroupRanks($tournament_group: inputManageTournamentGroupRanks!) {
      manageTournamentGroupRanks(tournament_group: $tournament_group)
    }
  `;

  const MANAGE_TOURNAMENT_NEXT_STAGE_MUTATION = gql`
    mutation manageTournamentNextStage($tournament_stage: inputManageTournamentNextStage!) {
      manageTournamentNextStage(tournament_stage: $tournament_stage)
    }
  `;

  const KICK_END_STAGES_TOURNAMENT_MUTATION = gql`
    mutation kickEndStagesTournament($tournament: inputKickStartTournament!) {
      kickEndStagesTournament(tournament: $tournament)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);
  const [isDone, setIsDone] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const {
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selected_team_id: '',

      selected_scorer: UNDEFINED_AS_ANY,
      nb_goals: 0,
      selected_passer: UNDEFINED_AS_ANY,
      nb_assists: 0,
      selected_passers: ARRAY_AS_ANY,

      all_home_team_goals: UNDEFINED_AS_ANY,
      selected_home_team_motm: NULL_AS_ANY,

      all_away_team_goals: UNDEFINED_AS_ANY,
      selected_away_team_motm: NULL_AS_ANY,
    },
  });
  const inputFields = watch();

  const [kickEndStagesTournament, endedTournament] = useMutation(KICK_END_STAGES_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [manageTournamentGroupRanks, updatedGroup] = useMutation(MANAGE_TOURNAMENT_GROUP_RANKS_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [manageTournamentNextStage, updatedStage] = useMutation(MANAGE_TOURNAMENT_NEXT_STAGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [initChallengeScoreByChallengeID, newScore] = useMutation(INIT_CHALLENGE_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [saveScoreByChallengeID, savedScore] = useMutation(SAVE_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [confirmScoreByChallengeID, confirmedScore] = useMutation(CONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unconfirmScoreByChallengeID, unconfirmedScore] = useMutation(UNCONFIRM_SCORE_BY_CHALLENGE_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedChallenge = useQuery(FIND_CHALLENGE_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        short_id: props.challenge_short_id,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });

  const HOME_TEAM = challenge?.home_team;
  const AWAY_TEAM = challenge?.away_team;
  const ALL_TEAMS = _.concat(HOME_TEAM, AWAY_TEAM);

  const SELECTED_TEAM = ALL_TEAMS?.find((t: any) => t?.id === inputFields.selected_team_id);
  const OPPONENT_TEAM = AWAY_TEAM?.id === SELECTED_TEAM?.id ? HOME_TEAM : AWAY_TEAM;

  const HOME_TEAM_SCORE = _.sum(challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === HOME_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));
  const AWAY_TEAM_SCORE = _.sum(challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === AWAY_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));

  const SELECTED_TEAM_STATS = challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === SELECTED_TEAM?.team?.id);

  const handleClose = () => {
    setIsConfirmed(false);
    setIsDone(false);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
    setValue('selected_passers', ARRAY_AS_ANY);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
    resetField('selected_scorer');
    setValue('nb_goals', 0);

    props.handleClose();
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedChallenge.refetch({
        challenge: {
          short_id: props.challenge_short_id,
          logged_player_id: loggedPlayer?.id,
        },
      });

      if (result) {
        setChallenge(result.data?.findChallengeByID);
        const newList = result.data?.findChallengeByID?.results?.statistics
          ?.filter((s: any) => s?.team_player?.team_player?.id === SELECTED_TEAM?.team?.id)
          ?.map((s: any) => {
            return {
              id: s?.id,
              player_id: s?.player?.id,
              nb_goals: s?.nb_goals,
              given_assists: s?.given_assists?.map((a: any) => {
                return {
                  id: a?.id,
                  player_receiver_id: a?.receiver?.id,
                  player_giver_id: a?.giver?.id,
                  nb_assists: a?.nb_assists,
                };
              }),
              pace: s?.pace,
              shooting: s?.shooting,
              passing: s?.passing,
              dribbling: s?.dribbling,
              defending: s?.defending,
              physical: s?.physical,
              goal_keeping: s?.goal_keeping,
            };
          });

        if (SELECTED_TEAM?.id === challenge?.home_team?.id) {
          setValue('all_home_team_goals', newList);
        }

        if (SELECTED_TEAM?.id === challenge?.away_team?.id) {
          setValue('all_away_team_goals', newList);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInitScore = async () => {
    try {
      if (!challenge?.results?.id) {
        await initChallengeScoreByChallengeID({
          variables: {
            challenge: {
              id: challenge?.id,
              logged_player_id: loggedPlayer?.id,
            },
          },
        });

        await handleRefetch();
      }
    } catch (e) {
      await handleRefetch();
      console.log(e);
    }
  };

  const handleAddNewAssist = () => {
    const newPasser = {
      player_receiver_id: inputFields.selected_scorer?.player?.id,
      player_giver_id: inputFields.selected_passer?.player?.id,
      nb_assists: parseFloat((inputFields.nb_assists > inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) ? inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) : inputFields.nb_assists).toString()),
    };
    const newList = _.concat(newPasser, inputFields.selected_passers);
    setValue('selected_passers', newList);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
  };

  const handleRemoveAssist = (player: any) => {
    const newList = inputFields.selected_passers?.filter((p: any) => p?.player_giver_id !== player?.player?.id);
    setValue('selected_passers', newList);
  };

  const handleAddScorer = () => {
    const existing_stats = SELECTED_TEAM_STATS?.find((s: any) => s?.player?.id === inputFields.selected_scorer?.player?.id);
    const newScorer = {
      id: existing_stats?.id,
      player_id: inputFields.selected_scorer?.player?.id,
      nb_goals: parseFloat(inputFields.nb_goals.toString()),
      given_assists: inputFields.selected_passers,
      pace: existing_stats?.pace || 0.1,
      shooting: existing_stats?.shooting || 0.1,
      passing: existing_stats?.passing || 0.1,
      dribbling: existing_stats?.dribbling || 0.1,
      defending: existing_stats?.defending || 0.1,
      physical: existing_stats?.physical || 0.1,
      goal_keeping: existing_stats?.goal_keeping || 0.1,
    };

    if (SELECTED_TEAM?.id === challenge?.home_team?.id) {
      const newList = _.concat(newScorer, inputFields.all_home_team_goals)?.filter((i: any) => i);
      setValue('all_home_team_goals', newList);
    }

    if (SELECTED_TEAM?.id === challenge?.away_team?.id) {
      const newList = _.concat(newScorer, inputFields.all_away_team_goals)?.filter((i: any) => i);
      setValue('all_away_team_goals', newList);
    }

    setValue('selected_passers', ARRAY_AS_ANY);
    setValue('nb_assists', 0);
    setValue('selected_passer', UNDEFINED_AS_ANY);
    resetField('selected_passer');
    resetField('selected_scorer');
    setValue('nb_goals', 0);
  };

  const handleRemoveScorer = (player: any) => {
    if (SELECTED_TEAM?.id === challenge?.home_team?.id) {
      const newList = player?.player?.id ? inputFields.all_home_team_goals?.filter((p: any) => p?.player?.id !== player?.player?.id) : inputFields.all_home_team_goals?.filter((p: any) => p?.player_id !== player?.player_id);
      setValue('all_home_team_goals', newList);
    }

    if (SELECTED_TEAM?.id === challenge?.away_team?.id) {
      const newList = player?.player?.id ? inputFields.all_away_team_goals?.filter((p: any) => p?.player?.id !== player?.player?.id) : inputFields.all_away_team_goals?.filter((p: any) => p?.player_id !== player?.player_id);
      setValue('all_away_team_goals', newList);
    }
  };

  const handleRemoveMOTM = () => {
    if (SELECTED_TEAM?.id === challenge?.home_team?.id) {
      setValue('selected_home_team_motm', UNDEFINED_AS_ANY);
    }

    if (SELECTED_TEAM?.id === challenge?.away_team?.id) {
      setValue('selected_away_team_motm', UNDEFINED_AS_ANY);
    }
  };

  const handleConfirmScore = () => {
    setIsConfirmed(true);
  };

  const handleSaveScore = async () => {
    try {
      if (SELECTED_TEAM?.id) {
        await saveScoreByChallengeID({
          variables: {
            score: {
              logged_player_id: loggedPlayer?.id,
              id: challenge?.results?.id,
              challenge_id: challenge?.id,
              team_lineup_id: SELECTED_TEAM?.id,
              motm_id: SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.selected_home_team_motm?.player?.id : inputFields.selected_away_team_motm?.player?.id,
              statistics: SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields?.all_home_team_goals : inputFields?.all_away_team_goals,
            },
          },
        });
      }

      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleContinue = async () => {
    try {
      await handleSaveScore();
    } catch (e) {
      console.error(e);
    } finally {
      setIsDone(true);
    }
  };

  const handlePublishScore = async () => {
    try {
      setIsConfirmed(false);

      if (SELECTED_TEAM?.id) {
        await saveScoreByChallengeID({
          variables: {
            score: {
              logged_player_id: loggedPlayer?.id,
              id: challenge?.results?.id,
              challenge_id: challenge?.id,
              team_lineup_id: SELECTED_TEAM?.id,
              motm_id: SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.selected_home_team_motm?.player?.id : inputFields.selected_away_team_motm?.player?.id,
              statistics: SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields?.all_home_team_goals : inputFields?.all_away_team_goals,
            },
          },
        });

        await confirmScoreByChallengeID({
          variables: {
            score: {
              logged_player_id: loggedPlayer?.id,
              id: challenge?.results?.id,
              challenge_id: challenge?.id,
            },
          },
        });
      }

      if (props.group_game && !props.current_stage_game && !props.next_stage_game) {
        // GROUP GAME: Update points
        await manageTournamentGroupRanks({
          variables: {
            tournament_group: {
              challenge_id: challenge?.id,
              id: challenge?.tournament_group_games[0]?.id,
              tournament_id: challenge?.tournament_group_games[0]?.tournament?.id,
              player_id: loggedPlayer?.id,
            },
          },
        });

        // IF all group games have results, we update tournament status to PLAYOFFS_STAGE_ON_GOING
      }

      if (!props.group_game && props.current_stage_game && props.next_stage_game) {
        // PLAYOFF GAME: Create/Update next games with this winner
        await manageTournamentNextStage({
          variables: {
            tournament_stage: {
              id: props.current_stage_game?.id,
              next_stage_id: props.next_stage_game?.data?.id,
              player_id: loggedPlayer?.id,
            },
          },
        });
      }

      if (props.current_stage_game?.stage_name === 'FINAL_2' && !props.next_stage_game) {
        // IF props.current_stage_game is FINAL_2 AND props.next_stage_game is undefined AND tournament HAS trophies, we update tournament status to TROPHY_CEREMONY ELSE to OVER
        await kickEndStagesTournament({
          context: {
            headers: {
              'Authorization': `Bearer ${session?.token?.key}`,
              'X-Anonymous-Access': 'false',
            },
          },
          variables: {
            tournament: {
              id: props.current_stage_game?.tournament?.id,
              logged_player_id: loggedPlayer?.id,
            },
          },
        });
      }

      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  const handleUnpublishScore = async () => {
    try {
      setIsConfirmed(false);

      await unconfirmScoreByChallengeID({
        variables: {
          score: {
            logged_player_id: loggedPlayer?.id,
            id: challenge?.results?.id,
            challenge_id: challenge?.id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      await handleRefetch();
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props.open && !challenge && loadedChallenge.called && loadedChallenge.data?.findChallengeByID) {
      setChallenge(loadedChallenge.data?.findChallengeByID);
    }

    if (props.open && challenge && challenge?.results?.statistics?.length > 0 && !inputFields.all_home_team_goals && !inputFields.all_away_team_goals && SELECTED_TEAM) {
      const newList = challenge?.results?.statistics
        ?.filter((s: any) => s?.team_player?.team_player?.id === SELECTED_TEAM?.team?.id)
        ?.map((s: any) => {
          return {
            id: s?.id,
            player_id: s?.player?.id,
            nb_goals: s?.nb_goals,
            given_assists: s?.given_assists?.map((a: any) => {
              return {
                id: a?.id,
                player_receiver_id: a?.receiver?.id,
                player_giver_id: a?.giver?.id,
                nb_assists: a?.nb_assists,
              };
            }),
            pace: s?.pace,
            shooting: s?.shooting,
            passing: s?.passing,
            dribbling: s?.dribbling,
            defending: s?.defending,
            physical: s?.physical,
            goal_keeping: s?.goal_keeping,
          };
        });

      if (!inputFields.all_home_team_goals && !inputFields.all_away_team_goals) {
        if (SELECTED_TEAM?.id === challenge?.home_team?.id) {
          setValue('all_home_team_goals', newList);
        }

        if (SELECTED_TEAM?.id === challenge?.away_team?.id) {
          setValue('all_away_team_goals', newList);
        }
      }
    }

    if (props.open && challenge && challenge?.results?.men_of_the_match?.find((p: any) => SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === p?.id)) && (inputFields.selected_home_team_motm === null || inputFields.selected_away_team_motm === null)) {
      if (SELECTED_TEAM?.id === challenge?.home_team?.id) {
        setValue(
          'selected_home_team_motm',
          SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === challenge?.results?.men_of_the_match?.find((p: any) => SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === p?.id))?.id)
        );
      }

      if (SELECTED_TEAM?.id === challenge?.away_team?.id) {
        setValue(
          'selected_away_team_motm',
          SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === challenge?.results?.men_of_the_match?.find((p: any) => SELECTED_TEAM?.players?.find((tp: any) => tp?.player?.id === p?.id))?.id)
        );
      }
    }

    if (!props.open && challenge) {
      setChallenge(UNDEFINED_AS_ANY);
    }
  }, [challenge, loadedChallenge, setChallenge, props, handleInitScore, newScore, setValue, inputFields]);

  return (
    <Dialog
      open={props.open}
      onClose={newScore.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={newScore.loading || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading || updatedGroup.loading || updatedStage.loading || endedTournament.loading ? undefined : handleClose}
        disabled={newScore.loading || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading || updatedGroup.loading || updatedStage.loading || endedTournament.loading}
        className={newScore.loading || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading || updatedGroup.loading || updatedStage.loading || endedTournament.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Final results</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid
            container
            sx={{
              justifyContent: 'start',
              alignItems: 'center',
              p: '0',
              color: 'rgba(247, 251, 250, 1)',
            }}
          >
            {!challenge?.results?.id ? (
              <Grid container>
                <Typography>
                  Now that your challenge is done, you can add the scores and also the statistics of your players.
                  <br />
                  <br />
                  As a reminder, both captain must confirm the score to validate the statistics.
                </Typography>
              </Grid>
            ) : (
              <>
                <Typography
                  component="span"
                  className="secondary-font"
                  sx={{
                    pt: '50px',
                    fontSize: {
                      xs: '8em',
                      sm: '9em',
                      md: '13em',
                      lg: '15em',
                    },
                    position: 'absolute',
                    top: '0',
                    fontStyle: 'italic',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    color: 'transparent',
                    width: '100%',
                    display: 'inline-block',
                    lineHeight: {
                      xs: '100px',
                      sm: '110px',
                      md: '160px',
                      lg: '180px',
                    },
                  }}
                >
                  <Grid sx={{ textAlign: 'left', position: 'relative', left: '-70px', WebkitTextStroke: '2px rgba(199, 240, 11, .1)' }}>Final</Grid>
                  <Grid sx={{ textAlign: 'center', position: 'relative', WebkitTextStroke: '2px rgba(199, 240, 11, .1)' }}>Results</Grid>
                </Typography>

                <Controller
                  control={control}
                  name="selected_team_id"
                  render={({ field }: any) => (
                    <TextField {...field} value={field.value} select label="Selected Team" placeholder="Select a field" variant="outlined" error={errors?.selected_scorer !== undefined} required fullWidth className={'field-bottom-space'} sx={{ mt: '20px' }}>
                      {ALL_TEAMS?.map((option: any) => (
                        <MenuItem key={option?.id} value={option.id}>
                          <Grid container sx={{ alignItems: 'center' }}>
                            <Avatar src={option?.team?.avatar?.path} alt={option?.team?.name} />
                            &nbsp;
                            <strong>{option?.team?.name}</strong>
                          </Grid>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                {!SELECTED_TEAM?.id ? (
                  ''
                ) : (
                  <>
                    <Grid container>
                      <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ opacity: SELECTED_TEAM?.id === challenge?.home_team?.id ? '1' : '.2', display: { xs: 'inline-block', sm: 'inline-flex' }, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid sx={{ textAlign: 'center' }}>
                          <Typography sx={{ fontWeight: '700', lineHeight: '15px', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.home_team?.name}</Typography>
                          {challenge?.home_team?.team?.avatar?.path ? <Avatar src={challenge?.home_team?.team?.avatar?.path} alt={challenge?.home_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                        </Grid>
                        <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{SELECTED_TEAM?.id ? _.sum(inputFields.all_home_team_goals?.map((p: any) => parseInt(p?.nb_goals.toString()))) : <>{HOME_TEAM_SCORE || <>&infin;</>}</>}</Typography>
                      </Grid>
                      <Grid className="separator" item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '50px', sm: '50px' } }} />
                      </Grid>
                      <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ opacity: SELECTED_TEAM?.id === challenge?.away_team?.id ? '1' : '.2', display: { xs: 'inline-block', sm: 'inline-flex' }, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{SELECTED_TEAM?.id ? _.sum(inputFields.all_away_team_goals?.map((p: any) => parseInt(p?.nb_goals.toString()))) : <>{AWAY_TEAM_SCORE || <>&infin;</>}</>}</Typography>
                        <Grid sx={{ textAlign: 'center' }}>
                          {isSmallMobile ? <>{challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}</> : ''}
                          <Typography sx={{ fontWeight: '700', lineHeight: '15px', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.away_team?.name}</Typography>
                          {isSmallMobile ? '' : <>{challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}</>}
                        </Grid>
                      </Grid>
                    </Grid>

                    {isDone ? (
                      <>
                        {savedScore.loading || confirmedScore.loading || unconfirmedScore.loading || updatedGroup.loading || updatedStage.loading || endedTournament.loading ? (
                          <Typography sx={{ fontSize: { xs: '1.1em', sm: '1.3em' }, p: '20px 10%', textAlign: 'center', width: '100%' }}>
                            Saving scores, please wait...
                            <br />
                            <CircularProgress className="orange-loader" />
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: { xs: '1.1em', sm: '1.3em' }, p: '0 10%' }}>
                            You have added all the goals and assists of <strong>{SELECTED_TEAM?.name}</strong>
                            <br />
                            Now you can either confirm the results now <i>or</i> you can save and continue later.
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid container sx={{ justifyContent: 'center' }}>
                          {(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.all_home_team_goals : inputFields.all_away_team_goals)?.map((p: any, index: number) => (
                            <Chip
                              key={index}
                              label={`${SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_id || player?.player?.id === p?.player_id)?.player?.alias} x ${p?.nb_goals} ${_.sum(p?.given_assists?.map((a: any) => a?.nb_assists)) > 0 ? `(${_.sum(p?.given_assists?.map((a: any) => a?.nb_assists))} ${_.sum(p?.given_assists?.map((a: any) => a?.nb_assists)) > 1 ? 'assists' : 'assist'})` : ''}`}
                              avatar={<Avatar src={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_id || player?.player?.id === p?.player_id)?.player?.avatar?.path} alt={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_id || player?.player?.id === p?.player_id)?.player?.alias} />}
                              onDelete={savedScore.loading || challenge?.results?.status === 'CONFIRMED' || (challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id)) ? undefined : () => handleRemoveScorer(p)}
                              sx={{ 'fontSize': '1em', 'm': '0 10px 10px 0', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, .4)', '& svg': { '&:hover': { color: 'rgba(238, 44, 32, 1) !important' } } }}
                            />
                          ))}
                        </Grid>

                        {savedScore.loading || confirmedScore.loading || unconfirmedScore.loading || updatedGroup.loading || updatedStage.loading || endedTournament.loading ? (
                          <>
                            <Typography sx={{ fontSize: { xs: '1.1em', sm: '1.3em' }, p: '20px 10%', textAlign: 'center', width: '100%' }}>
                              Saving scores, please wait...
                              <br />
                              <CircularProgress className="orange-loader" />
                            </Typography>
                          </>
                        ) : (
                          <Grid container sx={{ mt: { xs: '30px', sm: '40px' }, bgcolor: 'rgba(33, 36, 42, .9)', position: 'relative' }}>
                            {SELECTED_TEAM?.players?.filter((p: any) => !(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.all_home_team_goals : inputFields.all_away_team_goals)?.find((ps: any) => ps?.player_id === p?.id || ps?.player_id === p?.player?.id || ps?.player_id === p?.player_id))?.length === 0 ? (
                              ''
                            ) : (
                              <>
                                <Controller
                                  control={control}
                                  name="selected_scorer"
                                  render={({ field }: any) => (
                                    <TextField {...field} value={field.value} select label="Scorer" placeholder="Select a field" variant="outlined" error={errors?.selected_scorer !== undefined} required fullWidth className={'field-bottom-space'}>
                                      {SELECTED_TEAM?.players
                                        ?.filter((p: any) => !(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.all_home_team_goals : inputFields.all_away_team_goals)?.find((ps: any) => ps?.player_id === p?.id || ps?.player_id === p?.player?.id || ps?.player_id === p?.player_id))
                                        ?.map((option: any) => (
                                          <MenuItem key={option?.id} value={option}>
                                            <Grid container sx={{ alignItems: 'center' }}>
                                              <Avatar src={option?.player?.avatar?.path} alt={option?.player?.alias} />
                                              &nbsp;
                                              <strong>{option?.player?.alias}</strong>
                                            </Grid>
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  )}
                                />

                                <Controller
                                  name="nb_goals"
                                  control={control}
                                  rules={{
                                    required: false,
                                    min: 0,
                                    max: 100,
                                  }}
                                  render={({ field }: any) => <TextField {...field} label={`Goals scored by ${inputFields.selected_scorer?.player?.alias ? inputFields.selected_scorer?.player?.alias : 'player'}`} placeholder={'0'} type="number" variant="outlined" className={'field-bottom-space'} value={field.value <= 0 ? 0 : field.value && field.value > 100 ? 100 : field.value} fullWidth error={errors?.nb_goals !== undefined} />}
                                />

                                {inputFields.selected_passers?.length > 0 ? (
                                  <Typography sx={{ width: '100%', pb: '20px' }}>
                                    {inputFields.selected_scorer?.player?.alias ? inputFields.selected_scorer?.player?.alias : `A player`} scored {inputFields.nb_goals} {inputFields.nb_goals > 1 ? 'goals' : 'goal'} {inputFields.selected_passers?.length > 0 ? `& received ${_.sum(inputFields.selected_passers?.map((p: any) => parseInt(p?.nb_assists?.toString())))} ${_.sum(inputFields.selected_passers?.map((p: any) => parseInt(p?.nb_assists?.toString()))) > 1 ? 'assists' : 'assist'}` : ''}
                                  </Typography>
                                ) : (
                                  ''
                                )}

                                {inputFields.selected_passers?.map((p: any, index: number) => (
                                  <Chip
                                    key={index}
                                    label={`${SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_giver_id || player?.player?.id === p?.player_giver_id)?.player?.alias} x ${p?.nb_assists} ${p?.nb_assists > 1 ? 'assists' : 'assist'}`}
                                    avatar={<Avatar src={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_giver_id || player?.player?.id === p?.player_giver_id)?.player?.avatar?.path} alt={SELECTED_TEAM?.players?.find((player: any) => player?.id === p?.player_giver_id || player?.player?.id === p?.player_giver_id)?.player?.alias} />}
                                    onDelete={() => handleRemoveAssist(p)}
                                    sx={{ 'm': '0 10px 0 0', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(247, 251, 250, .1)', '& svg': { '&:hover': { color: 'rgba(238, 44, 32, 1) !important' } } }}
                                  />
                                ))}

                                {inputFields.selected_scorer ? (
                                  <>
                                    <Typography variant="caption" sx={{ width: '100%', p: '20px 0 10px' }}>
                                      You can all the assists {inputFields.selected_scorer?.player?.alias ? inputFields.selected_scorer?.player?.alias : `a player`} received
                                    </Typography>
                                    <Controller
                                      control={control}
                                      name="selected_passer"
                                      render={({ field }: any) => (
                                        <TextField
                                          {...field}
                                          value={field.value}
                                          select
                                          label="Passer"
                                          placeholder="Select a field"
                                          variant="outlined"
                                          error={errors?.selected_passer !== undefined}
                                          sx={{
                                            mr: { xs: '0', sm: '3%', md: '5%' },
                                            width: {
                                              xs: '100%',
                                              sm: '47%',
                                              md: '45%',
                                            },
                                          }}
                                          className={'field-bottom-space'}
                                        >
                                          {SELECTED_TEAM?.players
                                            ?.filter((p: any) => !inputFields.selected_passers?.find((ps: any) => ps?.player_giver_id === p?.id || p?.player?.id === ps?.player_giver_id))
                                            ?.filter((p: any) => p?.id !== inputFields.selected_scorer?.id)
                                            ?.map((option: any) => (
                                              <MenuItem key={option?.id} value={option}>
                                                <Grid container sx={{ alignItems: 'center' }}>
                                                  <Avatar src={option?.player?.avatar?.path} alt={option?.player?.alias} />
                                                  &nbsp;
                                                  <strong>{option?.player?.alias}</strong>
                                                </Grid>
                                              </MenuItem>
                                            ))}
                                        </TextField>
                                      )}
                                    />

                                    <Controller
                                      name="nb_assists"
                                      control={control}
                                      rules={{
                                        required: false,
                                        min: 0,
                                        max: inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)),
                                      }}
                                      render={({ field }: any) => (
                                        <TextField
                                          {...field}
                                          label={`Assists by ${inputFields.selected_passer?.player?.alias ? inputFields.selected_passer?.player?.alias : 'player'}`}
                                          placeholder={'0'}
                                          type="number"
                                          variant="outlined"
                                          className={'field-bottom-space'}
                                          value={field.value <= 0 ? 0 : field.value && field.value > inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) ? inputFields.nb_goals - _.sum(inputFields.selected_passers?.map((p: any) => p?.nb_assists)) : field.value}
                                          sx={{
                                            ml: { xs: '0', sm: '3%', md: '5%' },
                                            width: {
                                              xs: '100%',
                                              sm: '47%',
                                              md: '45%',
                                            },
                                          }}
                                          error={errors?.nb_assists !== undefined}
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  ''
                                )}
                                <Fab onClick={handleAddScorer} size="small" disabled={inputFields.nb_goals === 0} className={inputFields.nb_goals === 0 ? 'button-disabled' : 'button-green-reverse'} variant="extended" sx={{ boxShadow: 'none', mr: '20px' }}>
                                  <Add />
                                  &nbsp;Confirm scorer
                                </Fab>
                                {inputFields.selected_passer ? (
                                  <Fab size="small" disabled={inputFields.nb_assists === 0} className={inputFields.nb_assists === 0 ? 'button-disabled' : 'button-green-reverse'} variant="extended" sx={{ boxShadow: 'none', mr: '20px' }} onClick={handleAddNewAssist}>
                                    <Add />
                                    &nbsp;Add assist
                                  </Fab>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            {challenge?.results?.status === 'CONFIRMED' ? (
                              <Grid sx={{ width: '100%', textAlign: 'center', p: '30px 0% 10px' }}>
                                <Typography variant="caption">{challenge?.results?.men_of_the_match?.length > 1 ? 'Men Of The Match' : 'Man of The Match'}</Typography>
                                {challenge?.results?.men_of_the_match?.map((p: any, index: number) => (
                                  <Grid key={index} sx={{ m: '0 10px' }}>
                                    <Avatar src={p?.avatar?.path} alt={p?.alias} sx={{ m: 'auto' }} />
                                    <Typography variant="caption">{p?.alias}</Typography>
                                  </Grid>
                                ))}
                              </Grid>
                            ) : (
                              <Typography variant="caption" sx={{ p: '30px 0% 10px' }}>
                                If a player from <strong>{OPPONENT_TEAM?.name || 'the opposit team'}</strong> impressed you, name him/her as &quot;Man Of The Match&quot;.
                                <br />
                                Your opponent will do the same for your team
                              </Typography>
                            )}

                            {(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.selected_home_team_motm : inputFields.selected_away_team_motm)?.id ? (
                              <>
                                <Typography component="span" sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                  You named&nbsp;
                                  <Chip
                                    label={(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.selected_home_team_motm : inputFields.selected_away_team_motm)?.player?.alias}
                                    avatar={<Avatar src={(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.selected_home_team_motm : inputFields.selected_away_team_motm)?.player?.avatar?.path} alt={(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.selected_home_team_motm : inputFields.selected_away_team_motm)?.player?.alias} />}
                                    sx={{ 'fontSize': '1em', 'm': '0 10px 10px 0', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, .4)', '& svg': { '&:hover': { color: 'rgba(238, 44, 32, 1) !important' } } }}
                                    onDelete={challenge?.results?.status === 'CONFIRMED' || (challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id)) ? undefined : handleRemoveMOTM}
                                  />
                                </Typography>
                              </>
                            ) : (
                              <>
                                {SELECTED_TEAM?.id === challenge?.home_team?.id ? (
                                  <Controller
                                    control={control}
                                    name="selected_home_team_motm"
                                    render={({ field }: any) => (
                                      <TextField {...field} value={field.value} select label="Man Of The Match" variant="outlined" error={errors?.selected_home_team_motm !== undefined} fullWidth className={'field-bottom-space'}>
                                        {_.concat(UNDEFINED_AS_ANY, OPPONENT_TEAM?.players)?.map((option: any, index: number) => (
                                          <MenuItem key={index} value={option}>
                                            <Grid container sx={{ alignItems: 'center' }}>
                                              <Avatar src={option?.player?.avatar?.path} alt={option?.player?.alias} />
                                              &nbsp;
                                              <strong>{option?.player?.alias || 'NOBODY'}</strong>
                                            </Grid>
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                ) : (
                                  ''
                                )}

                                {SELECTED_TEAM?.id === challenge?.away_team?.id ? (
                                  <Controller
                                    control={control}
                                    name="selected_away_team_motm"
                                    render={({ field }: any) => (
                                      <TextField {...field} value={field.value} select label="Man Of The Match" variant="outlined" error={errors?.selected_away_team_motm !== undefined} fullWidth className={'field-bottom-space'}>
                                        {_.concat(UNDEFINED_AS_ANY, OPPONENT_TEAM?.players)?.map((option: any, index: number) => (
                                          <MenuItem key={index} value={option}>
                                            <Grid container sx={{ alignItems: 'center' }}>
                                              <Avatar src={option?.player?.avatar?.path} alt={option?.player?.alias} />
                                              &nbsp;
                                              <strong>{option?.player?.alias || 'NOBODY'}</strong>
                                            </Grid>
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>

      {challenge?.results?.status === 'CONFIRMED' || savedScore.loading || confirmedScore.loading || unconfirmedScore.loading || updatedGroup.loading || updatedStage.loading || endedTournament.loading ? (
        <></>
      ) : (
        <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center', textAlign: 'center', display: isSmallMobile ? 'inline-block' : 'inline-flex' }}>
          {!challenge?.results?.id ? (
            <>
              <LoadingButton onClick={handleInitScore} loading={newScore.loading} fullWidth={isSmallMobile} className={'button-green'} loadingPosition="end" endIcon={<ScoreBoardIcon />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                Add scores
              </LoadingButton>
            </>
          ) : (
            <>
              {isDone ? (
                <>
                  {challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? (
                    ''
                  ) : (
                    <Button className={newScore.loading || savedScore.loading ? 'button-disabled' : 'button-cancel'} disabled={newScore.loading || savedScore.loading} fullWidth={isSmallMobile} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }} onClick={() => setIsDone(false)}>
                      Back
                    </Button>
                  )}

                  {challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? (
                    <LoadingButton onClick={handleUnpublishScore} className={'button-red-reversed'} fullWidth={isSmallMobile} loading={savedScore.loading} loadingPosition="end" endIcon={<Clear />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                      Unconfirm
                    </LoadingButton>
                  ) : (
                    <>
                      {HOME_TEAM_SCORE === AWAY_TEAM_SCORE && props.current_stage_game ? (
                        <Typography sx={{ mb: '20px', fontWeight: '800' }} className="WARNING">
                          Draw is not possible in playoff stages
                        </Typography>
                      ) : (
                        <>
                          <LoadingButton onClick={handleConfirmScore} className={'button-green'} fullWidth={isSmallMobile} loadingPosition="end" endIcon={<ScoreBoardIcon />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                            Confirm final score
                          </LoadingButton>
                          <Modal open={isConfirmed} title={''} content={'Do you confim the final result of the challenge?'} actionOneTitle={'Yes'} actionOne={handlePublishScore} actionTwoTitle={'No'} actionTwo={() => setIsConfirmed(false)} />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? (
                    <LoadingButton onClick={handleUnpublishScore} className={'button-red-reversed'} fullWidth={isSmallMobile} loading={savedScore.loading} loadingPosition="end" endIcon={<Clear />} sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}>
                      Unconfirm
                    </LoadingButton>
                  ) : (
                    <>
                      {SELECTED_TEAM?.name ? (
                        <>
                          <LoadingButton
                            onClick={handleSaveScore}
                            disabled={(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.all_home_team_goals : inputFields.all_away_team_goals)?.length === 0}
                            className={(SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.all_home_team_goals : inputFields.all_away_team_goals)?.length === 0 ? 'button-disabled' : 'button-green-reverse'}
                            fullWidth={isSmallMobile}
                            loading={savedScore.loading}
                            loadingPosition="end"
                            endIcon={<Send />}
                            sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}
                          >
                            Save {SELECTED_TEAM?.name} scores
                          </LoadingButton>

                          <Button
                            onClick={handleContinue}
                            fullWidth={isSmallMobile}
                            disabled={newScore.loading || savedScore.loading || (SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.all_home_team_goals : inputFields.all_away_team_goals)?.length === 0}
                            className={newScore.loading || savedScore.loading || (SELECTED_TEAM?.id === challenge?.home_team?.id ? inputFields.all_home_team_goals : inputFields.all_away_team_goals)?.length === 0 ? 'button-disabled' : 'button-green'}
                            endIcon={<Send />}
                            sx={{ mb: '20px', ml: isSmallMobile ? '0 !important' : '10px !important' }}
                          >
                            Continue
                          </Button>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

ManageScoreByAdminsModal.propTypes = {
  challenge_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

ManageScoreByAdminsModal.defaultProps = {};
