import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { FormGroup, FormControlLabel, Switch, Typography, CircularProgress } from '@mui/material';
import { LightMode, DarkMode } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

const DarkModeSwitch: React.FC = () => {
  const UPDATE_APP_THEME_MODE_MUTATION = gql`
    mutation updateUserAppThemeMode($configuration: inputUpdateThemeMode!) {
      updateUserAppThemeMode(configuration: $configuration)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedUser = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [checked, setChecked] = React.useState(loggedUser?.app_configuration?.is_dark_mode);
  const [updateUserAppThemeMode, updatedConfiguration] = useMutation(UPDATE_APP_THEME_MODE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setChecked(event.target.checked);

      await updateUserAppThemeMode({
        variables: {
          configuration: {
            id: loggedUser?.app_configuration?.id,
            logged_user_id: loggedUser?.id,
            is_dark_mode: event.target.checked,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormGroup sx={{ display: session?.status !== 'ALIVE' ? 'none' : 'block' }}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            icon={<LightMode sx={{ color: 'rgba(245, 178, 42, 1)' }} />}
            checkedIcon={<DarkMode sx={{ color: 'rgba(247, 251, 250, .6)' }} />}
            sx={{
              '& .MuiSwitch-switchBase': { bgcolor: 'rgba(0, 0, 0, 1) !important', p: '2px', m: checked ? '5px 0 0 8px' : '5px 8px 0 0' },
              '& .MuiSwitch-switchBase:hover': { bgcolor: 'rgba(0, 0, 0, 1) !important' },
              '& .MuiSwitch-track': { bgcolor: checked ? 'rgba(0, 0, 0, .5) !important' : 'rgba(245, 178, 42, .6) !important', opacity: checked ? '' : '1' },
            }}
          />
        }
        label={
          updatedConfiguration.loading || (updatedConfiguration.called && loggedUser?.app_configuration?.is_dark_mode !== checked) ? (
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', color: 'rgba(0, 0, 0, .3)' }}>
              <CircularProgress sx={{ color: 'rgba(0, 0, 0, .3)' }} size={15} />
              &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Loading' })}`}
            </Typography>
          ) : (
            <Typography variant="caption" sx={{ color: 'inherit !important' }}>
              {checked ? `${intl.formatMessage({ id: 'Global_Dark_Mode' })}` : `${intl.formatMessage({ id: 'Global_Light_Mode' })}`}
            </Typography>
          )
        }
        labelPlacement="top"
      />
    </FormGroup>
  );
};

DarkModeSwitch.propTypes = {};

export default DarkModeSwitch;
