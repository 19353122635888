import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardHeader, CardMedia, Fab, Grid, IconButton, List, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import MenuButton from '../../component-modals/layout/MenuButton';
import { Link, useLocation } from 'react-router-dom';
import io, { Socket } from 'socket.io-client';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { Edit, ExpandMore, Stop } from '@mui/icons-material';
import moment from 'moment';
import LivestreamComment from './LivestreamComment';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerLogoIcon, LiveIcon, MicrophoneIcon } from '../../img/icons/Icons';
import Timer from '../../component-modals/common/Timer';
import { REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI, REACT_APP_LILENEK_URL } from '../../config';
import NewSponsorshipModal from '../../component-modals/features/NewSponsorshipModal';
import EditSponsorshipModal from '../../component-modals/features/EditSponsorshipModal';
import { useIntl } from 'react-intl';
import ViewSponsorshipProposalModal from '../../component-modals/features/ViewSponsorshipProposalModal';

const FormNewLivestream: React.FC = () => {
  const CREATE_LIVESTREAM_MUTATION = gql`
    mutation createLivestream($livestream: inputCreateLivestream!) {
      createLivestream(livestream: $livestream) {
        id
        room_id
        status
        created_at
      }
    }
  `;

  const STOP_LIVESTREAM_MUTATION = gql`
    mutation stopLivestream($livestream: inputStopLivestream!) {
      stopLivestream(livestream: $livestream)
    }
  `;

  const FIND_LIVESTREAM_BY_ID_QUERY = gql`
    query findLivestreamByID($livestream: inputFindLivestreamByID!) {
      findLivestreamByID(livestream: $livestream) {
        id
        room_id
        title
        status
        anonymous_participants
        participants {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        comments {
          id
          title
          mood
          content
          likes
          author {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
          created_at
          updated_at
        }
        created_at
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const location = useLocation();
  const intl = useIntl();
  const [livestream, setLivestream] = useState(UNDEFINED_AS_ANY);
  const [socket, setSocket] = useState<Socket | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const localStreamRef = useRef<HTMLVideoElement | null>(null);
  const [openNewSponsorship, setOpenNewSponsorship] = React.useState({ subject_id: '', subject_title: '', isOpen: false });
  const [openEditSponsorship, setOpenEditSponsorship] = React.useState({ sponsorship_id: '', isOpen: false });
  const [openViewSponsorshipProposal, setOpenViewSponsorshipProposal] = React.useState({ sponsorship_proposal_id: '', isOpen: false });

  const { control, watch, setValue } = useForm({
    defaultValues: {
      streamer_id: '',
      room_id: '',
      is_streaming: false,
    },
  });
  const inputFields = watch();

  const ALL_STREAMERS = _.concat({ id: loggedPlayer?.id, name: loggedPlayer?.username, label: loggedPlayer?.username, avatar: loggedPlayer?.avatar?.path });
  const SELECTED_STREAMER = ALL_STREAMERS?.find((s: any) => s?.id === inputFields.streamer_id);

  const [createLivestream, newLive] = useMutation(CREATE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [stopLivestream, endedLive] = useMutation(STOP_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedLivestream = useQuery(FIND_LIVESTREAM_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !loggedPlayer?.id || !livestream?.id,
    variables: {
      livestream: {
        id: livestream?.id,
      },
    },
    pollInterval: inputFields.is_streaming ? 3000 : undefined,
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedLivestream.refetch({
        livestream: {
          id: livestream?.id,
          comments_order: 'DESC',
        },
      });

      if (result) {
        setLivestream(result.data?.findLivestreamByID);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleInitStream = async () => {
    if (socket) {
      try {
        socket?.emit('startStream', SELECTED_STREAMER?.id, SELECTED_STREAMER?.name, session?.app_client?.name);
        await handleStartStream();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleStartStream = async () => {
    if (socket && mediaRecorderRef.current) {
      try {
        mediaRecorderRef.current.ondataavailable = (event: any) => {
          if (event.data.size > 0) {
            console.log('Streaming data:', event.data);
            socket?.emit('stream', event.data);
          }
        };

        mediaRecorderRef.current?.start(1000); // Send data every 1 second
        setValue('is_streaming', true);
      } catch (error) {
        console.error('Error accessing media devices.', error);
      }
    }
  };

  const handleCreateLivestream = async (room_id: string) => {
    try {
      const result = await createLivestream({
        variables: {
          livestream: {
            room_id: room_id,
            title: `${ALL_STREAMERS?.find((s: any) => s?.id === inputFields.streamer_id)?.label}'s live (${moment().format('D/MM/YYYY @ HH:mm')})`,
            app_client_id: session?.app_client?.id,
            author_id: inputFields.streamer_id,
            subject_id: location?.state?.challenge?.id,
          },
        },
      });

      if (result.data?.createLivestream) {
        setLivestream(result.data?.createLivestream);
      } else {
        setValue('is_streaming', false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleStopLivestream = async (id: string, video_url: string) => {
    try {
      if (!endedLive.called) {
        const result = await stopLivestream({
          variables: {
            livestream: {
              id: id,
              video_url: video_url,
            },
          },
        });

        if (result.data?.stopLivestream > 0) {
          setLivestream(UNDEFINED_AS_ANY);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleStopStream = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current?.stop();
      localStreamRef.current = null;
      setValue('is_streaming', false);
    }

    if (socket && inputFields.room_id) {
      socket.emit('stopStream', inputFields.room_id);
      console.log(`Stopped streaming in room: ${inputFields.room_id}`);
      setValue('room_id', '');
      // setLivestream(UNDEFINED_AS_ANY);
      await handleStopLivestream(livestream?.id, '');
    }
  };

  const handleOpenNewSponsorship = (id: string, title: string) => {
    setOpenNewSponsorship({ subject_id: id, subject_title: title, isOpen: true });
  };

  const handleCloseNewSponsorship = async () => {
    try {
      setOpenNewSponsorship({ subject_id: '', subject_title: '', isOpen: false });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseEditSponsorship = async () => {
    try {
      setOpenEditSponsorship({ sponsorship_id: '', isOpen: false });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseViewSponsorshipProposal = async () => {
    try {
      await handleRefetch();
      setOpenViewSponsorshipProposal({ sponsorship_proposal_id: '', isOpen: false });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newSocket = io(REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI);
    // const newSocket = io('http://localhost:5001');
    setSocket(newSocket);

    newSocket.on('connect', async () => {
      console.log('Socket.io connected');
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      if (localStreamRef.current) {
        localStreamRef.current.srcObject = mediaRecorderRef.current?.stream;
        console.log('Local stream obtained and set.');
      }
    });

    newSocket.on('roomCreated', async (id) => {
      try {
        setValue('room_id', id);
        console.log(`Room created with ID: ${id}`);
      } catch (e) {
        console.log(e);
        console.log(`Failed to create room with ID: ${id}`);
      }
    });

    newSocket.on('disconnect', () => {
      console.log('Socket.io disconnected');
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (inputFields.room_id && !livestream && inputFields.is_streaming) {
        await handleCreateLivestream(inputFields.room_id);
      }
    })();
  }, [inputFields.room_id, inputFields.is_streaming, livestream]);

  useEffect(() => {
    socket?.on('getVideoURL', async (url: string) => await handleStopLivestream(livestream?.id, url));

    if ((livestream?.status === 'ON_GOING' && livestream?.participants === undefined && loadedLivestream.data?.findLivestreamByID) || (livestream?.status === 'ON_GOING' && loadedLivestream.data?.findLivestreamByID && !_.isMatch(livestream, loadedLivestream.data?.findLivestreamByID))) {
      setLivestream(loadedLivestream.data?.findLivestreamByID);
    }
  }, [livestream, loadedLivestream]);

  useEffect(() => {
    if (inputFields.streamer_id === '') setValue('streamer_id', ALL_STREAMERS[0]?.id);
  }, [inputFields.streamer_id]);

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      if (inputFields.is_streaming) {
        handleStopStream();
        event.preventDefault();
        event.returnValue = ''; // Modern browsers will display a default message
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [inputFields.is_streaming]);

  return (
    <Grid className={'dark-mode'}>
      <Grid container>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'left',
          }}
        ></Grid>
        <Grid
          item
          xl={8}
          xs={8}
          sm={8}
          sx={{
            p: '0',
            position: 'relative',
            zIndex: '1',
            textAlign: 'center',
          }}
        >
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
          </IconButton>
        </Grid>
        <Grid
          item
          xl={2}
          xs={2}
          sm={2}
          sx={{
            p: '2%',
            position: 'relative',
            zIndex: '1',
            textAlign: 'right',
          }}
        >
          <MenuButton />
        </Grid>
      </Grid>

      <Grid container sx={{ justifyContent: 'center', p: '20px 0' }}>
        {location?.state?.challenge?.title ? <Typography sx={{ fontSize: '1.3em', fontWeight: '800', pb: '20px' }}>Event: {location?.state?.challenge?.title}</Typography> : ''}
        <Grid container sx={{ justifyContent: 'center' }}>
          <Controller
            name="streamer_id"
            control={control}
            render={({ field }: any) => (
              <TextField
                {...field}
                select
                variant="outlined"
                className="no-borders"
                SelectProps={{
                  IconComponent: undefined,
                  sx: {
                    'color': 'rgba(247, 251, 250, 1)',
                    'border': 'none',
                    'bgcolor': 'transparent',
                    '& #mui-component-select-flexer_id': { p: '10px 0' },
                    '&::-ms-expand': {
                      display: 'none',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgba(247, 251, 250, 1)',
                    p: '0 6px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    width: '100%',
                  },
                }}
                sx={{ m: '0', p: '0', width: 'auto' }}
              >
                {ALL_STREAMERS.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Grid
                      container
                      sx={{
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        src={option?.avatar}
                        sx={{
                          width: '25px',
                          height: '25px',
                        }}
                      />
                      &nbsp;&nbsp;
                      <Typography variant="caption">{option.label}</Typography>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          {!inputFields.is_streaming ? (
            <LoadingButton size="small" loading={newLive.loading} loadingPosition="end" disabled={!location?.state?.challenge?.id || inputFields.streamer_id === '' || !inputFields.streamer_id} className={!location?.state?.challenge?.id || inputFields.streamer_id === '' || !inputFields.streamer_id ? 'button-disabled' : 'button-green-reverse'} endIcon={<MicrophoneIcon sx={{ fontSize: '2em !important' }} />} onClick={handleInitStream}>
              Start Streaming
            </LoadingButton>
          ) : (
            <LoadingButton size="small" loading={endedLive.loading} className="button-green-reverse" endIcon={<Stop sx={{ fontSize: '2em !important', color: 'rgba(254, 0, 0, 1)' }} />} onClick={handleStopStream} disabled={!inputFields.is_streaming}>
              Stop Streaming
            </LoadingButton>
          )}

          {livestream?.id ? (
            <>
              <LoadingButton onClick={() => handleOpenNewSponsorship(livestream?.id, livestream?.title)} className="button-green-reverse">{`${intl.formatMessage({ id: 'Tournament_Button_New_Sponsorship' })}`}</LoadingButton>
              <NewSponsorshipModal subject_id={openNewSponsorship.subject_id} subject_title={openNewSponsorship.subject_title} open={openNewSponsorship.isOpen} handleClose={handleCloseNewSponsorship} />
              <EditSponsorshipModal sponsorship_id={openEditSponsorship.sponsorship_id} open={openEditSponsorship.isOpen} handleClose={handleCloseEditSponsorship} />
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      {inputFields.is_streaming ? (
        <Typography variant="body1" sx={{ fontWeight: '800', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', m: '10px 0' }}>
          <LiveIcon sx={{ fontSize: '1.5em !important' }} className="is_live" />
          &nbsp;
          <Timer eventTime={livestream?.created_at} />
        </Typography>
      ) : (
        ''
      )}

      <Grid container>
        <Grid item xs={12} sm={12} md={inputFields.is_streaming ? 8 : 12} xl={inputFields.is_streaming ? 8 : 12}>
          <Grid sx={{ justifyContent: 'center', textAlign: 'center' }}>
            <video ref={localStreamRef} autoPlay playsInline muted style={{ width: '100%', maxWidth: '900px', height: 'auto', borderRadius: '20px' }} />
          </Grid>
        </Grid>
        {inputFields.is_streaming ? (
          <>
            <Grid item xs={12} sm={12} md={4} xl={4} sx={{ overflow: 'auto', maxHeight: '600px' }}>
              <Accordion sx={{ width: '100%', bgcolor: 'rgba(247, 251, 250, .04)', color: 'rgba(247, 251, 250, 1)' }}>
                <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'rgba(247, 251, 250, 1)' }} />} aria-controls="panel1-content" id="panel1-header">
                  {livestream?.comments?.length || 0} comments
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container sx={{ justifyContent: 'center' }}>
                    {livestream?.comments?.length === 0 ? (
                      <Typography
                        variant="h3"
                        sx={{
                          p: '50px 20px 0',
                          fontSize: '1.3em',
                          fontWeight: '100',
                          textAlign: 'center',
                          color: session?.token?.key ? (loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15, 15, 15, .5)') : 'rgba(247, 251, 250, .3)',
                        }}
                      >
                        No comments
                      </Typography>
                    ) : (
                      <List
                        sx={{
                          width: '100%',
                          color: 'rgba(247, 251, 250, 1)',
                          p: '0 5%',
                        }}
                      >
                        {livestream?.comments?.map((c: any) => (
                          <LivestreamComment key={c?.id} comment={c} status={livestream?.status} />
                        ))}
                      </List>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ width: '100%', bgcolor: 'rgba(247, 251, 250, .04)', color: 'rgba(247, 251, 250, 1)' }}>
                <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'rgba(247, 251, 250, 1)' }} />} aria-controls="panel1-content" id="panel1-header">
                  {livestream?.sponsorships?.length || 0} sponsorships
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid container sx={{ p: '20px 0', justifyContent: 'center' }}>
                      {livestream?.sponsorships?.map((s: any) => (
                        <Grid key={s?.id} item xs={12} sm={12} md={6} lg={6} xl={3} sx={{ p: '20px' }}>
                          <Card sx={{ bgcolor: 'rgba(247, 251, 250, .04)', color: 'rgba(247, 251, 250, 1)' }}>
                            {s?.proposals?.find((p: any) => p?.status === 'ACCEPTED') ? (
                              <Grid component={s?.status === 'ACQUIRED' && s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? Link : 'span'} to={s?.status === 'ACQUIRED' && s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? `${REACT_APP_LILENEK_URL}/${s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name}` : ''} target="_blank">
                                <CardMedia component="img" height="150" src={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.banner?.path} alt={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.label} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <CardHeader
                              avatar={<Avatar src={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.logo?.path} alt={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.label} />}
                              title={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.label || s?.title}
                              subheader={
                                <>
                                  {intl.formatMessage({ id: 'Tournament_Sponsor_For' })}&nbsp;{s?.subject?.__typename}:&nbsp;{_.truncate(s?.subject?.title || s?.subject?.name)}
                                </>
                              }
                              subheaderTypographyProps={{ color: 'rgba(247, 251, 250, .3)' }}
                              component={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? Link : 'div'}
                              to={s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name ? `${REACT_APP_LILENEK_URL}/${s?.proposals?.find((p: any) => p?.status === 'ACCEPTED')?.author?.company?.name}` : ''}
                              target="_blank"
                              sx={{ minHeight: '92px', textDecoration: 'none', color: 'inherit' }}
                            />

                            {s?.author?.id === loggedPlayer?.id ? (
                              <Accordion sx={{ bgcolor: 'rgba(247, 251, 250, .04)', color: 'rgba(247, 251, 250, 1)' }}>
                                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                                  <Typography>
                                    Price: {s?.price?.currency?.currency_code} {s?.price?.value}
                                    &nbsp;-&nbsp;Proposals: {s?.proposals?.length}
                                    <br />
                                    <Typography variant="caption" sx={{ color: 'inherit', textTransform: 'uppercase' }}>
                                      {`${intl.formatMessage({ id: 'Global_Status' })}`}:&nbsp;<strong className={s?.status === 'PENDING' ? '' : s?.status}>{s?.status}</strong>
                                    </Typography>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {s?.status === 'PENDING' ? (
                                    <Grid container>
                                      <Fab onClick={() => setOpenEditSponsorship({ sponsorship_id: s?.id, isOpen: true })} variant="extended" size="small" className={'button-cancel'} sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                                        <Edit />
                                        &nbsp;Edit
                                      </Fab>
                                    </Grid>
                                  ) : (
                                    ''
                                  )}

                                  <Typography sx={{ textAlign: 'center', fontWeight: '800', fontSize: '1.3em' }}>{s?.title}</Typography>
                                  {s?.proposals?.length === 0 ? (
                                    <Typography
                                      variant="h3"
                                      sx={{
                                        width: '100%',
                                        p: '20px',
                                        fontSize: '1.3em',
                                        textAlign: 'center',
                                        color: 'rgba(247, 251, 250, .3)',
                                      }}
                                    >
                                      No proposals
                                    </Typography>
                                  ) : (
                                    <Grid container sx={{ maxHeight: '300px', overflow: 'scroll' }}>
                                      <Stack direction="column" sx={{ width: '100%' }}>
                                        {s?.proposals?.map((p: any) => (
                                          <CardMedia
                                            key={p?.id}
                                            sx={{
                                              display: 'inline-flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              borderRadius: '20px',
                                              p: '0',
                                              height: '200px',
                                              width: '100%',
                                              mb: '20px',
                                              textAlign: 'center',
                                              backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15 , .6) 0%,rgba(15, 15, 15 , .8) 95%), url(${p?.banner?.path})`,
                                            }}
                                          >
                                            <Grid>
                                              <CardHeader avatar={<Avatar src={p?.author?.company?.logo?.path} alt={p?.author?.company?.label} />} title={p?.author?.company?.label} titleTypographyProps={{ fontSize: '1.3em', fontWeight: '800' }} component={p?.author?.company?.name ? Link : 'div'} to={p?.author?.company?.name ? `${REACT_APP_LILENEK_URL}/${p?.author?.company?.name}` : ''} target="_blank" sx={{ pt: '0', pb: '0', textDecoration: 'none', color: 'inherit' }} />
                                              <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
                                                {`${intl.formatMessage({ id: 'Global_Status' })}`}:&nbsp;<strong className={p?.status === 'PENDING' ? '' : p?.status}>{p?.status}</strong> - {p?.price?.currency?.currency_code} {p?.price?.value}
                                              </Typography>
                                              <Grid container sx={{ p: '10px 0', justifyContent: 'center' }}>
                                                <Fab onClick={() => setOpenViewSponsorshipProposal({ sponsorship_proposal_id: p?.id, isOpen: true })} variant="extended" size="small" className="button-green" sx={{ boxShadow: 'none', m: '0 10px' }}>
                                                  {`${intl.formatMessage({ id: 'Global_Button_View' })}`}
                                                </Fab>
                                              </Grid>
                                            </Grid>
                                          </CardMedia>
                                        ))}
                                      </Stack>

                                      <ViewSponsorshipProposalModal sponsorship_proposal_id={openViewSponsorshipProposal.sponsorship_proposal_id} open={openViewSponsorshipProposal.isOpen} handleClose={handleCloseViewSponsorshipProposal} />
                                    </Grid>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              ''
                            )}
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(FormNewLivestream);
