import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Moment from 'react-moment';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { Skeleton, Avatar, IconButton, TextField, InputAdornment, Fab, Dialog, Grid, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Send, KeyboardDoubleArrowRight, Visibility, VisibilityOff, Close, ArrowForward, ArrowBack, Delete } from '@mui/icons-material';
import { QRCodeIcon, WalletIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { PAYMENT_METHODS, ALTERNATIVE_PAYMENTS } from '../../utilities/utilities';
import ShowDepositToken from '../features/ShowDepositToken';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

export default function ConfirmPaymentModal(props: { transactions: any[]; handleClose: any; open: boolean }) {
  const FIND_TRANSACTION_BY_ID_QUERY = gql`
    query findTransactionByID($transaction: inputFindTransactionByID!) {
      findTransactionByID(transaction: $transaction) {
        id
        tx_reference
        status
        type
        value {
          value
          currency {
            currency_code
          }
        }
        maat_value {
          value
          currency {
            currency_code
          }
        }
        tx_fee {
          value
          currency {
            currency_code
          }
        }
        assets {
          ... on TokenInvoice {
            __typename
            id
            token_id
          }
        }
        sender_wallet {
          id
          title
          owner {
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
              owner {
                firstname
                lastname
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
          }
        }
        sender_payment_method {
          id
          name
          label
          payment_source {
            ... on BankCard {
              __typename
              id
              network
            }
            ... on BlockchainCard {
              __typename
              id
              network
            }
            ... on BankAccount {
              __typename
              id
              title
            }
            ... on BlockchainAccount {
              __typename
              id
              title
            }
            ... on Cash {
              __typename
              id
              name
            }
            ... on Gold {
              __typename
              id
              name
            }
            ... on Waste {
              __typename
              id
              name
            }
          }
        }
        receiver_wallet {
          id
          title
          owner {
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
              owner {
                firstname
                lastname
              }
            }
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
          }
        }
        receiver_payment_method {
          id
          name
          label
          payment_source {
            ... on BankCard {
              __typename
              id
              network
            }
            ... on BlockchainCard {
              __typename
              id
              network
            }
            ... on BankAccount {
              __typename
              id
              title
            }
            ... on BlockchainAccount {
              __typename
              id
              title
            }
            ... on Cash {
              __typename
              id
              name
            }
            ... on Gold {
              __typename
              id
              name
            }
            ... on Waste {
              __typename
              id
              name
            }
          }
        }
        signatories {
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        payment_plan {
          id
          status
          bill {
            ... on Deal {
              __typename
              id
              title
            }
            ... on BrokerDeal {
              __typename
              id
              title
            }
            ... on Order {
              __typename
              id
              title
            }
            ... on SportSession {
              __typename
              id
              title
            }
            ... on Donation {
              __typename
              id
              title
            }
            ... on RequestFinancing {
              __typename
              id
              title
            }
          }
        }
        execution_date
        confirmed_at
      }
    }
  `;

  const VERIFICATION_SPENDING_PASSWORD_MUTATION = gql`
    mutation verificationSpendingPassword($user: inputVerificationSpendingPassword!) {
      verificationSpendingPassword(user: $user)
    }
  `;

  const EXECUTE_CRYPTO_TRANSACTION_PAYMENT_MUTATION = gql`
    mutation executeCryptoPaymentTransaction($transaction: inputExecuteCryptoTX!) {
      executeCryptoPaymentTransaction(transaction: $transaction)
    }
  `;

  const CANCEL_TRANSACTION_PAYMENT_MUTATION = gql`
    mutation cancelPaymentTransaction($transaction: inputCancelTX!) {
      cancelPaymentTransaction(transaction: $transaction)
    }
  `;

  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedUser = useAppSelector(selectPlayer);
  const min_code_length = 10;
  const [index, setIndex] = React.useState(0);
  const [tx, setTx] = React.useState(UNDEFINED_AS_ANY);
  const [hidePassword, setHidePassword] = React.useState(true);
  const [didTry, setDidTry] = React.useState(false);
  const [showToken, setShowToken] = React.useState(false);
  const { control, watch, setValue } = useForm({
    defaultValues: {
      spending_code: '',
    },
  });
  const inputFields = watch();

  const [cancelPaymentTransaction, cancelledTx] = useMutation(CANCEL_TRANSACTION_PAYMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [executeCryptoPaymentTransaction, executedCryptoTx] = useMutation(EXECUTE_CRYPTO_TRANSACTION_PAYMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [verificationSpendingPassword, verificationResult] = useMutation(VERIFICATION_SPENDING_PASSWORD_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTransaction = useQuery(FIND_TRANSACTION_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      transaction: {
        id: props?.transactions ? props?.transactions[index]?.id : '',
      },
    },
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleNext = () => {
    if (index < props.transactions?.length - 1) {
      setIndex(index + 1);
      setTx(undefined);
      setShowToken(false);
    }
  };

  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
      setTx(undefined);
      setShowToken(false);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleConfirmSpendingCode = async () => {
    try {
      if (inputFields.spending_code?.length >= min_code_length) {
        try {
          const result = await verificationSpendingPassword({
            variables: {
              user: {
                code: inputFields.spending_code,
                logged_user_id: loggedUser?.id,
                logged_user_username: loggedUser?.username,
              },
            },
          });

          setDidTry(result.data?.verificationSpendingPassword);
          if (result.data?.verificationSpendingPassword === false) {
            setValue('spending_code', '');
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log('tx', tx);
  const handleExecuteTransaction = async () => {
    try {
      const result = await executeCryptoPaymentTransaction({
        variables: {
          transaction: {
            logged_user_id: loggedUser?.id,
            tx_data: {
              id: tx?.id,
              tx_reference: tx?.tx_reference,
              note_reference: tx?.note_reference,
              type: tx?.type,
              original_value: {
                value: parseFloat(tx?.value?.value?.toString()),
                currency: tx?.value?.currency?.currency_code,
              },
              maat_value: {
                value: parseFloat(tx?.maat_value?.value?.toString()),
                currency: tx?.maat_value?.currency?.currency_code,
              },
              tx_fee: {
                value: parseFloat(tx?.tx_fee?.value?.toString()),
                currency: tx?.tx_fee?.currency?.currency_code,
              },
              assets: tx?.assets,

              sender_wallet_id: tx?.sender_wallet?.id,
              sender_payment_method_id: tx?.sender_payment_method?.id,

              receiver_wallet_id: tx?.receiver_wallet?.id,
              receiver_payment_method_id: tx?.receiver_payment_method?.id,
              signatories: tx?.signatories?.map((s: any) => s?.id),
              execution_date: tx?.execution_date,
            },
            spending_password: inputFields.spending_code,
          },
        },
      });

      if (result.data?.executeCryptoPaymentTransaction) {
        const refreshed_tx = await loadedTransaction.refetch({
          transaction: {
            id: tx?.id,
          },
        });

        setTx(refreshed_tx.data?.findTransactionByID);

        // TODO: UPDATE PRODUCT/ARTICLE STOCK FROM HERE
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelTransaction = async () => {
    try {
      await cancelPaymentTransaction({
        variables: {
          transaction: {
            id: tx?.id,
            logged_user_id: loggedUser?.id,
            spending_password: inputFields.spending_code,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (props?.transactions !== undefined && tx === undefined && loadedTransaction.called && loadedTransaction.data?.findTransactionByID) {
      setTx(loadedTransaction.data?.findTransactionByID);
    }
  }, [props, tx, loadedTransaction]);

  return (
    <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ sx: { minWidth: { xs: '70%', md: '40%' }, p: '0 0 30px' } }} className={'component-transaction-confirmation-modal modal-feature'}>
      <Fab size="small" onClick={handleClose} sx={{ boxShadow: 'none', bgcolor: 'transparent', ml: '2%', mt: '2%' }}>
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ pt: '0', fontWeight: '700', textAlign: 'center', textTransform: 'uppercase' }}>
        Payment confirmation
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid sx={{ minHeight: '250px', width: '100%' }}>
            <Typography variant="caption" sx={{ textAlign: 'center', width: '100%', display: 'inline-block', pb: '0' }}>
              REF: <strong>{_.truncate(tx?.tx_reference, { length: 21 })}</strong>
            </Typography>
            {tx?.payment_plan?.bill ? (
              <Typography sx={{ fontSize: '.8em', textAlign: 'center' }}>
                CONTEXT:{' '}
                <strong>
                  ({tx?.payment_plan?.bill?.__typename}) {tx?.payment_plan?.bill?.title}
                </strong>
              </Typography>
            ) : (
              ''
            )}
            <Typography variant="caption" sx={{ textAlign: 'center', pb: '10px', display: 'inline-block', width: '100%' }}>
              {`${intl.formatMessage({ id: 'Global_Status' })}`}:{' '}
              <Typography variant="caption" sx={{ fontWeight: '700' }} className={tx?.status === 'PENDING' ? '' : tx?.status}>
                {tx?.status?.replaceAll('_', ' ')}
              </Typography>
            </Typography>
            {loadedTransaction.loading || verificationResult.loading ? (
              <Grid container sx={{ justifyContent: 'center' }}>
                <CircularProgress className="orange-loader" />
              </Grid>
            ) : (
              <Grid container>
                <Grid item xl={5.5} xs={11.5} sm={5.5}>
                  <Typography variant="caption" sx={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
                    Sender
                  </Typography>
                  <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tx?.sender_wallet?.owner ? <Avatar src={tx?.sender_wallet?.owner?.username ? tx?.sender_wallet?.owner?.avatar?.path : tx?.sender_wallet?.owner?.logo?.path} /> : <Skeleton className="black-skeloton" variant="circular" width="30px" height="30px" />}
                    {tx?.sender_wallet?.owner ? <Typography sx={{ p: '0 0 0 10px', fontWeight: '700' }}>{`${tx?.sender_wallet?.owner?.username ? tx?.sender_wallet?.owner?.owner?.firstname : tx?.sender_wallet?.owner?.label} ${tx?.sender_wallet?.owner?.username ? tx?.sender_wallet?.owner?.owner?.lastname : ''}`}</Typography> : <Skeleton width={'100%'} className="black-skeloton" sx={{ m: 'auto' }} />}
                  </Grid>
                  <Typography variant="caption" sx={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', '& svg': { fontSize: '3em' } }}>
                    via&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === tx?.sender_payment_method?.name)?.icon}&nbsp;<strong>{ALTERNATIVE_PAYMENTS?.find((pm: any) => pm?.name === tx?.sender_payment_method?.payment_source?.name)?.name.replaceAll('_', ' ')}</strong>{' '}
                    <Typography variant="caption" sx={{ fontSize: '.6em', fontWeight: '700' }}>
                      &nbsp;&nbsp;{ALTERNATIVE_PAYMENTS?.find((pm: any) => pm?.name === tx?.sender_payment_method?.payment_source?.name)?.icon}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid className="separator" item xl={1} xs={12} sm={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <KeyboardDoubleArrowRight sx={{ m: '0 20px', color: 'rgba(31, 175, 146, 1, 1)', transform: { xs: 'rotate(90deg)', sm: 'rotate(0deg)' } }} />
                </Grid>

                <Grid item xl={5.5} xs={11.5} sm={5.5}>
                  <Typography variant="caption" sx={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
                    Receiver
                  </Typography>
                  <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tx?.receiver_wallet?.owner ? <Avatar src={tx?.receiver_wallet?.owner?.username ? tx?.receiver_wallet?.owner?.avatar?.path : tx?.receiver_wallet?.owner?.logo?.path} /> : <Skeleton className="black-skeloton" variant="circular" width="30px" height="30px" />}
                    {tx?.receiver_wallet?.owner ? <Typography sx={{ p: '0 0 0 10px', fontWeight: '700' }}>{`${tx?.receiver_wallet?.owner?.username ? tx?.receiver_wallet?.owner?.owner?.firstname : tx?.receiver_wallet?.owner?.label} ${tx?.receiver_wallet?.owner?.username ? tx?.receiver_wallet?.owner?.owner?.lastname : ''}`}</Typography> : <Skeleton width={'100%'} className="black-skeloton" sx={{ m: 'auto' }} />}
                  </Grid>
                  <Typography variant="caption" sx={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', '& svg': { fontSize: '3em' } }}>
                    via&nbsp;{PAYMENT_METHODS?.find((pm: any) => pm?.name === tx?.receiver_payment_method?.name)?.icon}
                  </Typography>
                </Grid>
                <Grid item xl={12} xs={12} sm={12} sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: '1.4em', fontWeight: '700' }}>
                    {tx?.value?.value} {tx?.value?.currency?.currency_code}
                  </Typography>
                  <Grid>
                    {tx?.value?.currency?.currency_code !== 'MAT' ? (
                      <Typography variant="caption">
                        {tx?.maat_value?.value} {tx?.maat_value?.currency?.currency_code}&nbsp;&nbsp;-&nbsp;&nbsp;
                      </Typography>
                    ) : (
                      ''
                    )}
                    {tx?.tx_fee ? (
                      <Typography variant="caption">
                        fee: {tx?.tx_fee?.value} {tx?.tx_fee?.currency?.currency_code}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid>
                    <Typography variant="caption">
                      created: <Moment format="D/MM/yyyy - HH:mm:ss">{tx?.execution_date}</Moment>
                    </Typography>
                    {tx?.confirmed_at ? (
                      <Typography variant="caption">
                        &nbsp;|&nbsp;confirmed: <Moment format="D/MM/yyyy - HH:mm:ss">{tx?.execution_date}</Moment>
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>

                <ShowDepositToken tx_id={tx?.id} open={showToken} handleClose={() => setShowToken(false)} />
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item xl={6} xs={6} sm={6} sx={{ display: 'flex', justifyContent: 'start' }}>
              {index === 0 ? (
                ''
              ) : (
                <Fab size="small" sx={{ boxShadow: 'none' }} disabled={executedCryptoTx.loading} className={executedCryptoTx.loading ? 'button-disabled' : 'button-green-reverse'} onClick={handlePrevious}>
                  <ArrowBack />
                </Fab>
              )}
            </Grid>
            <Grid item xl={6} xs={6} sm={6} sx={{ display: 'flex', justifyContent: 'end' }}>
              {index === props.transactions?.length - 1 ? (
                ''
              ) : (
                <Fab size="small" sx={{ boxShadow: 'none' }} disabled={executedCryptoTx.loading} className={executedCryptoTx.loading ? 'button-disabled' : 'button-green-reverse'} onClick={handleNext}>
                  <ArrowForward />
                </Fab>
              )}
            </Grid>
          </Grid>

          {didTry ? (
            ''
          ) : (
            <Grid container sx={{ p: '0 10%' }}>
              <Controller
                name="spending_code"
                control={control}
                defaultValue=""
                rules={{ required: true, minLength: min_code_length, maxLength: 255 }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label="Please enter your spending code"
                    type={hidePassword ? 'password' : 'text'}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                            {hidePassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: didTry ? 'center' : 'start' }}>
        {didTry ? (
          <Grid>
            <LoadingButton disabled={executedCryptoTx.loading || tx?.status === 'CANCELLED' || tx?.payment_plan?.status !== 'PENDING'} className={executedCryptoTx.loading || tx?.status === 'CANCELLED' || tx?.payment_plan?.status !== 'PENDING' ? 'button-disabled' : 'button-red'} onClick={handleCancelTransaction} loading={cancelledTx.loading} loadingPosition="end" endIcon={<Delete />} sx={{ mr: '10px' }}>
              Cancel
            </LoadingButton>

            {tx?.sender_payment_method?.payment_source?.name === 'CASH' || tx?.sender_payment_method?.payment_source?.name === 'GOLD' || tx?.sender_payment_method?.payment_source?.name === 'TYRE_WASTE' || tx?.sender_payment_method?.payment_source?.name === 'PLASTIC_WASTE' || tx?.sender_payment_method?.payment_source?.name === 'ORGANIC_WASTE' ? (
              <Button className="button-green" onClick={() => setShowToken(true)} sx={{ display: showToken ? 'none' : 'inline-flex' }}>
                <QRCodeIcon />
                &nbsp;Show Token
              </Button>
            ) : (
              <LoadingButton disabled={cancelledTx.loading || !tx?.receiver_wallet?.owner || !tx?.sender_wallet?.owner || tx?.status === 'CANCELLED'} className={cancelledTx.loading || !tx?.receiver_wallet?.owner || !tx?.sender_wallet?.owner || tx?.status === 'CANCELLED' ? 'button-disabled' : 'button-green'} onClick={handleExecuteTransaction} loading={executedCryptoTx.loading} loadingPosition="end" endIcon={<Send />} sx={{ mr: '10px' }}>
                Confirm Transfer!
              </LoadingButton>
            )}
          </Grid>
        ) : (
          <LoadingButton disabled={inputFields.spending_code?.length < min_code_length} className={inputFields.spending_code?.length < min_code_length ? 'button-disabled' : 'button-green-reverse'} onClick={handleConfirmSpendingCode} loading={verificationResult.loading} endIcon={<Send />} sx={{ mr: '10px' }}>
            Confirm code
          </LoadingButton>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmPaymentModal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  sender: PropTypes.any,
  receiver: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.bool.isRequired,
};

ConfirmPaymentModal.defaultProps = {
  icon: <WalletIcon />,
};
