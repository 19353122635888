import React from 'react';
import { Box, Avatar, Button, Card, CardContent, CardMedia, Fade, Grid, IconButton, Menu, MenuItem, Skeleton, Typography, AvatarGroup, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer, selectPlayerActiveProfile } from '../../reducers/playerSlice';
import BackButton from '../../component-modals/common/BackButton';
import { MoreVert, Send } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FivezerLogoIcon, LocationIcon, TeamsIcon } from '../../img/icons/Icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import Moment from 'react-moment';
import _ from 'lodash';
import MenuButton from '../../component-modals/layout/MenuButton';
import { LoadingButton } from '@mui/lab';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';
import { SPORT_TEAM_TYPES } from '../../utilities/utilities';
import FootballCardModal from '../../component-modals/profile/FootballCardModal';

const JoinTeam: React.FC = () => {
  const FIND_SPORT_TEAMS_BY_OPTIONS_QUERY = gql`
    query findSportTeamsByOptions($team: inputFindSportTeamsByOptions!) {
      findSportTeamsByOptions(team: $team) {
        ... on FootballTeam {
          id
          short_id
          name
          shortname
          type
          avatar {
            path
          }
          cover {
            path
          }
          availabilities {
            day
            timeslots
          }
          players {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                  avatar {
                    path
                  }
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                  avatar {
                    path
                  }
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          received_notifications {
            id
            status
            sender {
              ... on Player {
                __typename
                id
              }
            }
          }
          originate_location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          created_at
          updated_at
        }
      }
    }
  `;

  const SEND_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);
  const intl = useIntl();
  const [teams, setTeams] = React.useState(ARRAY_AS_ANY);
  const [selectedProfile, setSelectedProfile] = React.useState(UNDEFINED_AS_ANY);
  const [openViewCard, setOpenViewCard] = React.useState(false);
  const [offset] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [sendNotification, isSent] = useMutation(SEND_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportTeam = useQuery(FIND_SPORT_TEAMS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team: {
        name: '',
        offset: offset,
      },
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectProfile = (player: any) => {
    setSelectedProfile({ type: player?.__typename, alias: player?.alias });
    setOpenViewCard(true);
  };

  const handleUnselectProfile = () => {
    setSelectedProfile(UNDEFINED_AS_ANY);
    setOpenViewCard(false);
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedSportTeam.refetch({
        team: {
          name: '',
          offset: offset,
        },
      });

      if (result) {
        const array = _.slice(teams, teams?.length - offset);
        const newList = _.concat(array, result.data?.findSportTeamsByOptions);
        setTeams(newList);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendJoinTeamRequest = async (team: any) => {
    try {
      const SELECTED_PROFILE = loggedPlayer?.profiles?.find((p: any) => (team?.__typename === 'FootballTeam' ? p?.__typename === 'Footballer' : team?.__typename === 'BasketballTeam' ? p?.__typename === 'Basketballer' : undefined));

      await sendNotification({
        variables: {
          notification: {
            title: `${loggedPlayer?.username} ${intl.formatMessage({ id: 'Notification_Join_Team_Title' })} ${team?.name}!`,
            content: `${loggedPlayer?.username} ${intl.formatMessage({ id: 'Notification_Join_Team_Content_1' })} ${team?.name}.`,
            actions: [`VIEW_PLAYER_PROFILE_@_${loggedPlayer?.username}`, `ACCEPT_NEW_TEAM_PLAYER_@_${SELECTED_PROFILE?.id}_&_${team?.id}`, `DECLINE_NEW_TEAM_PLAYER_@_${SELECTED_PROFILE?.id}_&_${team?.id}`],
            sender_id: loggedPlayer?.id,
            receivers_ids: _.concat(
              team?.captains?.map((c: any) => c?.player?.player?.id),
              team?.id
            ),
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (teams?.length === 0 && loadedSportTeam.data?.findSportTeamsByOptions && loadedSportTeam.data?.findSportTeamsByOptions?.length > 0) {
      setTeams(loadedSportTeam.data?.findSportTeamsByOptions);
    }
  }, [teams, loadedSportTeam]);

  return (
    <Grid className={`component-team team-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ p: '0 0 150px', minHeight: '100%' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'Join_Team_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
        <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
          <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container>
          <Grid container sx={{ justifyContent: 'end', p: '0 43px' }}>
            <MenuButton />
          </Grid>
          <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', p: '0' }}>
            <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
          </Grid>

          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            sx={{
              textAlign: 'right',
              p: '20px 30px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Box>
              <IconButton id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreVert />
              </IconButton>
            </Box>

            <Menu
              id="fade-menu"
              keepMounted
              MenuListProps={{
                'aria-labelledby': 'fade-button',
                'dense': true,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {loggedPlayer?.id ? (
                <Grid>
                  {loggedPlayer?.email?.status !== 'VERIFIED' || loggedPlayer?.profiles?.length === 0 ? (
                    ''
                  ) : (
                    <MenuItem component={Link} to="/new_team">
                      <Button
                        variant="text"
                        sx={{
                          '&:hover': {
                            background: 'transparent !important',
                          },
                        }}
                        startIcon={<TeamsIcon />}
                      >
                        {`${intl.formatMessage({ id: 'Join_Team_Button_New_Team' })}`}
                      </Button>
                    </MenuItem>
                  )}
                </Grid>
              ) : (
                ''
              )}
            </Menu>
          </Grid>
        </Grid>

        <Typography className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
          {`${intl.formatMessage({ id: 'Join_Team_Title' })}`}
        </Typography>

        <Grid container sx={{ p: { xs: '0', sm: '0' } }}>
          {!teams || teams?.length === 0 ? (
            <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
          ) : (
            <>
              {teams?.map((team: any) => (
                <Grid key={team?.id} item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: '0 30px' }}>
                  <Card
                    className={'card'}
                    sx={{
                      bgcolor: 'rgba(247, 251, 250, .1)',
                      p: '0px',
                      width: '100%',
                      borderRadius: '20px',
                      m: '0 auto 30px',
                    }}
                  >
                    <CardMedia
                      sx={{
                        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${team?.cover?.path})`,
                      }}
                    >
                      <CardContent className={'content'} sx={{ textAlign: 'center', p: '30px 0 !important' }}>
                        {team?.avatar?.path ? (
                          <Avatar
                            src={team?.avatar?.path}
                            component={Link}
                            to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                            sx={{
                              m: 'auto',
                              width: '100px',
                              height: '100px',
                            }}
                          />
                        ) : (
                          <Skeleton className="white-skeloton" variant="circular" width="100px" height="100px" sx={{ m: 'auto' }} />
                        )}
                        {teams?.length === 0 ? (
                          ''
                        ) : (
                          <>
                            <Typography
                              component={Link}
                              to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                              sx={{
                                'fontWeight': '800',
                                'fontSize': '1.9em',
                                'textDecoration': 'none',
                                'color': 'rgba(247, 251, 250, 1)',
                                '&:hover': {
                                  color: 'rgba(251, 88, 2, 1)',
                                },
                              }}
                            >
                              {team?.name}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: '600',
                                fontSize: '.8em',
                                textTransform: 'uppercase',
                              }}
                            >
                              {`${intl.formatMessage({ id: SPORT_TEAM_TYPES.find((t: any) => t.value === team?.type)?.label })}`}&nbsp;{_.replace(team?.__typename, 'Team', ' Team')}
                            </Typography>
                            <AvatarGroup sx={{ justifyContent: 'center', alignItems: 'center' }} max={6}>
                              {team?.players?.map((p: any, index: number) => (
                                <Grid key={index} sx={{ '& svg': { color: 'rgba(255, 255, 255, 1)' } }}>
                                  <Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} onClick={() => handleSelectProfile(p?.player)} />
                                </Grid>
                              ))}
                            </AvatarGroup>
                            <Typography
                              variant="caption"
                              sx={{
                                color: 'rgba(247, 251, 250, 1)',
                                width: '100%',
                                display: 'inline-block',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Since' })}`}&nbsp;<Moment format="D MMM YYYY">{team?.created_at}</Moment>
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: 'rgba(247, 251, 250, 1)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <LocationIcon
                                sx={{
                                  color: 'rgba(31, 175, 146, .5)',
                                }}
                              />
                              &nbsp;
                              {team?.originate_location?.city?.name}, {team?.originate_location?.country?.name}
                              &nbsp;
                              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${team?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${team?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                            </Typography>
                          </>
                        )}
                        {loggedPlayerActiveProfile?.player_positions?.find((p: any) => p?.team_player?.type === 'LEAGUE' && team?.type === 'LEAGUE') || team?.players?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id && p?.status === 'ACTIVE') || team?.received_notifications?.find((n: any) => n?.sender?.id === loggedPlayer?.id && n?.status === 'SENT') ? (
                          ''
                        ) : (
                          <LoadingButton disabled={loggedPlayer?.profiles?.length === 0} className={loggedPlayer?.profiles?.length === 0 ? 'button-disabled' : 'button-green-reverse'} loading={isSent.loading} loadingPosition="end" endIcon={<Send />} size="small" sx={{ mt: '20px' }} onClick={() => handleSendJoinTeamRequest(team)}>
                            {`${intl.formatMessage({ id: 'Join_Team_Button_Join' })}`}&nbsp;{team?.name}
                          </LoadingButton>
                        )}
                        {team?.received_notifications?.find((n: any) => n?.sender?.id === loggedPlayer?.id && n?.status === 'SENT') ? (
                          <Button className="button-cancel" sx={{ mt: '20px' }}>
                            {`${intl.formatMessage({ id: 'Join_Team_Button_Sent' })}`}&nbsp;🎉
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardContent>
                    </CardMedia>
                  </Card>
                </Grid>
              ))}
              {selectedProfile?.type === 'Footballer' ? <FootballCardModal alias={selectedProfile?.alias} open={openViewCard} handleClose={handleUnselectProfile} /> : ''}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JoinTeam;
